import XLSX from 'xlsx';

class XLSXToDataModel {
  static getXLSXWorkBook(data: string): XLSX.WorkBook {
    try {
      return XLSX.read(data, { type: 'binary' });
    } catch (error) {
      throw Error('Unable to read the xlsx file.');
    }
  }

  static readSheetAsCSV(workBook: XLSX.WorkBook, sheetName: string): string | undefined {
    const workSheet = workBook.Sheets[sheetName];
    if (workSheet !== undefined) {
      return XLSX.utils.sheet_to_csv(workSheet, { blankrows: false });
    }
  }
}

export default XLSXToDataModel;
