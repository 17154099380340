import React, { useState } from 'react';
import { defineMessages, FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import * as _ from 'lodash';
import * as log from 'loglevel';
import Dialog from '@react/react-spectrum/Dialog';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { Tree } from 'primereact/tree';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource } from '../../../services/orgMaster/UResource';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import Analytics from '../../../Analytics/Analytics';
import { EditState, ObjectTypes, OrgOperation } from '../../../services/orgMaster/OrgMaster';
import { ExpandedNodes } from '../../../services/treeTableUtils/ExpandedNodesUtil';
import HierarchyManager from '../../../services/organization/HierarchyManager';
import { CommandService } from '../../../services/Commands/CommandService';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';

const localeMessages = defineMessages({
  delete: {
    id: 'productAllocation.delProduct.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'productAllocation.delProduct.cancel',
    defaultMessage: 'Cancel',
  },
  ok: {
    id: 'productAllocation.delProduct.ok',
    defaultMessage: 'OK',
  },
});

export interface OrgAndProdToUpdate {
  prodIdToDelete: string;
  org: UOrgMaster;
}

interface DelProductDialogProps {
  prodToDelete: UProduct; // product selected in the product allocation page, which we are trying to delete
  compartmentId: string; // current orgId
  update: () => void; // callback to the Product Allocation component
}

/**
 * Given a prodToDelete, this component will search for any products allocated downward.
 * It will display an org hierarchy of nodes containing such allocated products,
 * so that the user can see and confirm a cascade of product deletions.
 */
function DelProductDialogInternal(props: DelProductDialogProps): JSX.Element {
  const [expandedNodes, setExpandedNodes] = useState<ExpandedNodes>({}); // map of orgId->boolean. every branch should be expanded.
  const orgsAndProdToUpdate: OrgAndProdToUpdate[] = [];
  const intl = useIntl();
  const { formatMessage } = intl;
  /**
   * Recursively find orgs with products allocated from srcProductId using depth first search (DFS).
   * Also populate the lists 'orgsToUpdate' and 'expandedNodes' as it finds orgs with products to delete.
   *
   * @return array of TreeNode for each org in currentLevelOrgIds that has a
   * product allocated from srcProductId. Empty array if no matches found.
   */
  const getOrgsWithAllocations = (currentLevelOrgs: UOrgMaster[], srcProductId: string): TreeNode[] => {
    if (!currentLevelOrgs || !srcProductId) {
      // undefined input
      return [];
    }

    const orgsWithAllocatedProducts: TreeNode[] = [];

    currentLevelOrgs.forEach((org) => {
      const allocatedProd = org.products.find((prod) => prod.sourceProductId === srcProductId);
      if (allocatedProd) {
        // this org has a product allocated from srcProductId, now recursively check if its children have products allocated from this.
        const childrenWithAllocatedProducts = getOrgsWithAllocations(org.childrenRefs, allocatedProd.id);
        // save this matching org and its children as a TreeNode
        const node: TreeNode = {
          key: org.id,
          label: org.organization.name,
          children: childrenWithAllocatedProducts,
        };
        orgsWithAllocatedProducts.push(node);

        // Add to list of products to be deleted
        log.debug(`Will delete product ${allocatedProd.id} of org ${org.organization.name}`);
        orgsAndProdToUpdate.push({
          prodIdToDelete: allocatedProd.id,
          org,
        });
        expandedNodes[org.id] = true;
      }
    });

    return orgsWithAllocatedProducts;
  };

  /* Populate orgsAndProdToUpdate and treeData */

  // Add prodToDelete to list of orgs & prods to update
  const compartment = HierarchyManager.getOrg(props.compartmentId) as UOrgMaster;
  orgsAndProdToUpdate.push({
    prodIdToDelete: props.prodToDelete.id,
    org: compartment,
  });
  expandedNodes[compartment.organization.id] = true;
  log.debug(`Will delete product ${props.prodToDelete.id} of org ${compartment.organization.name}`);

  // Create treeData for display purposes.
  // Recursively find child orgs with products allocated from prodToDelete
  const childrenWithAllocs = getOrgsWithAllocations(compartment.childrenRefs, props.prodToDelete.id);

  // save this org and its children as treeData (the top TreeNode to display)
  const treeData: TreeNode = {
    key: compartment.organization.id,
    label: compartment.organization.name,
    children: childrenWithAllocs,
  };

  // TODO: TreeTable expandedKeys isn't working desired here nor on AddProductDialog. Why not?
  log.debug(`nodes to expand ${JSON.stringify(expandedNodes)}`);

  /**
   * Walks the list of orgsToUpdate, deleting their respective product.
   * Passes the updated list of UOrgMasters to the save callback.
   */
  const onSave = (): void => {
    Analytics.fireCTAEvent('delete product dialog save clicked');

    // Iterate over orgsToUpdate, removing the product from each org and marking org editState as UPDATE.
    orgsAndProdToUpdate.forEach((orgAndProdToUpdate) => {
      const deletedProducts: UProduct[] = _.remove(orgAndProdToUpdate.org.products, [
        'id',
        orgAndProdToUpdate.prodIdToDelete,
      ]);
      if (deletedProducts && deletedProducts.length > 0) {
        deletedProducts.forEach((deletedProd) => {
          CommandService.addEdit(
            orgAndProdToUpdate.org,
            deletedProd,
            ObjectTypes.PRODUCT,
            OrgOperation.DELETE,
            undefined,
            'REMOVE_PRODUCT',
            [deletedProd.name, CmdDescriptionUtils.getPathname(deletedProd.orgId)]
          );
          log.debug(
            `Deleted ${deletedProd.name} ${deletedProd.id} from org ${orgAndProdToUpdate.org.organization.name}`
          );
          // eslint-disable-next-line no-param-reassign
          orgAndProdToUpdate.org.organization.editState = EditState.UPDATE;
        });
      } else {
        // This should never happen! deleted should always be an array of one.
        log.error(`Failed to find ${orgAndProdToUpdate.prodIdToDelete} in ${orgAndProdToUpdate.org.organization.name}`);
      }
    });
    props.update();
  };

  // Cannot delete products with 0 grant value due to https://jira.corp.adobe.com/browse/CLAS-207
  if (_.find(props.prodToDelete.resources, (res: UResource): boolean => res.grantedQuantity === '0')) {
    return (
      <Dialog
        variant="error"
        className="DelProductDialog__container"
        title={
          <div className="AddProductDialog__title">
            <FormattedMessage
              id="productAllocation.delProduct.zeroError.title"
              defaultMessage="Unable to Delete {product}"
              values={{ product: props.prodToDelete.name }}
            />
          </div>
        }
        confirmLabel={formatMessage(localeMessages.ok)}
        {...props}
        role="alertdialog"
        data-testid="del-product-dialog-grant-zero"
      >
        <FormattedMessage
          id="productAllocation.delProduct.zeroError.message"
          defaultMessage="Unable to delete product with a value of 0 for any resource.  Please set grant values to a non-zero value before deleting"
        />
      </Dialog>
    );
  }

  return (
    <Dialog
      variant="destructive"
      className="DelProductDialog__container"
      title={
        <div className="AddProductDialog__title" data-testid="del-product-dialog-title">
          <FormattedMessage
            id="productAllocation.delProduct.title"
            defaultMessage="Delete {product}"
            values={{
              product: props.prodToDelete.name,
            }}
          />
        </div>
      }
      confirmLabel={formatMessage(localeMessages.delete)}
      onConfirm={onSave}
      cancelLabel={formatMessage(localeMessages.cancel)}
      onCancel={(): void => Analytics.fireCTAEvent('delete product dialog canceled')}
      {...props}
      role="dialog"
      data-testid="del-product-dialog"
    >
      <span data-testid="del-product-dialog-content">
        {orgsAndProdToUpdate.length === 1 ? (
          // Display just a single org
          <>
            <FormattedMessage
              id="productAllocation.delProduct.singleprod.description"
              defaultMessage="{product} will be deleted from the org {org}."
              values={{
                product: props.prodToDelete.name,
                org: orgsAndProdToUpdate[0].org.organization.name,
              }}
            />
          </>
        ) : (
          // Display whole tree to be deleted
          <>
            <FormattedMessage
              id="productAllocation.delProduct.manyprods.description"
              defaultMessage="{product} will be deleted from the following parent org and the child orgs that have allocations of this product."
              values={{ product: props.prodToDelete.name }}
            />
            <div data-testid="del-product-dialog-treetable">
              <Tree
                className="DelProductDialog__Tree"
                value={[treeData]}
                expandedKeys={expandedNodes}
                onToggle={(e: { originalEvent: Event; value: ExpandedNodes }): void => {
                  setExpandedNodes(e.value);
                }}
              />
            </div>
          </>
        )}
      </span>
    </Dialog>
  );
}

function DelProductDialog(props: Omit<DelProductDialogProps, 'ref'>): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <DelProductDialogInternal {...props} />
    </IntlProvider>
  );
}

export default DelProductDialog;
