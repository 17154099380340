import React from 'react';
import * as _ from 'lodash';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as log from 'loglevel';
import Wait from '@react/react-spectrum/Wait';
import Heading from '@react/react-spectrum/Heading';
import { Tab, TabList } from '@react/react-spectrum/TabList';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import ProductTable from './ProductTable/ProductTable';
import { LoadOrgDataService } from '../../services/orgMaster/LoadOrgDataService';
import CompartmentPolicy, { messages } from './CompartmentPolicy/CompartmentPolicy';
import OrganizationTemplate from './OrganizationTemplate/OrganizationTemplate';
import AdminTable from './AdminTable/AdminTable';
import UserGroupsTab from './UserGroupsTab/UserGroupsTab';
import DomainsTable from './DomainsTable/DomainsTable';
import './EditCompartment.css';
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import CompartmentActionButtons from './CompartmentActionButtons';
import CompartmentInfo from './CompartmentInfo';
import Utils from '../../services/utils/Utils';
import Analytics from '../../Analytics/Analytics';
import '../common.css';
import { CommandService } from '../../services/Commands/CommandService';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import DirectoryTable from './DirectoryTable/DirectoryTable';
import ExpirationBanner from '../../components/ExpirationBanner/ExpirationBanner';
import { LicenseTuple } from '../../services/orgMaster/LicenseTuple';
import UserGroupSharingService from '../../providers/UserGroupSharingService';

interface EditCompartmentState {
  errorMessage: string;
  tabSelected: number;
  loading: boolean;
  tabToIndexMap: Map<TabSelected, number>; // map to store mapping between a tab and the index at which the tab should be displayed. for e.g. DOMAIN -> 2 means that Domains tab will be displayed 3rd
  licenseTupleWithHighestPriority?: LicenseTuple;
  isUserGroupSharingEnabled: boolean;
}

interface EditCompartmentProps extends WrappedComponentProps {
  updateCompartmentCallback: () => void;
  selectedOrg: UOrgMaster;
}

enum TabSelected {
  'PRODUCT',
  'USERGROUP',
  'ADMIN',
  'DOMAIN',
  'POLICY',
  'POLICY_TEMPLATE',
  'DIRECTORY',
}

const EDIT_TITLE_TESTID_PREFIX: string = 'edit-compartment-heading_';

class EditCompartment extends React.Component<EditCompartmentProps, EditCompartmentState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted
  private mounted = false;

  public constructor(props: any) {
    super(props);
    this.state = {
      errorMessage: '', // if not empty, show the alert box. The errorMessage is defined if the loading of compartment policy fails on componentDidMount/componentDidUpdate
      tabSelected: 0,
      loading: false,
      tabToIndexMap: this.getTabToIndexMap(),
      licenseTupleWithHighestPriority: undefined,
      isUserGroupSharingEnabled: false,
    };
  }

  async componentDidMount(): Promise<void> {
    this.mounted = true;
    await this.load();
  }

  async componentDidUpdate(prevProps: EditCompartmentProps): Promise<void> {
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id) {
      await this.load();
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
    this.abortController.abort();
  }

  private async load(): Promise<void> {
    const isUserGroupSharingEnabled = await UserGroupSharingService.hasSharedUserGroupsFeature(
      this.props.selectedOrg.id,
      this.abortController.signal
    );
    if (this.mounted) {
      this.setState({
        isUserGroupSharingEnabled: isUserGroupSharingEnabled,
      });
    }

    if (!this.props.selectedOrg.orgDetailsLoaded || !this.props.selectedOrg.policiesLoaded) {
      if (this.mounted) {
        this.setState({ loading: true });
      }
      const promisesToLoad: Promise<void>[] = [];
      promisesToLoad.push(this.loadOrgDetails());
      promisesToLoad.push(this.loadPolicies());
      promisesToLoad.push(this.loadContracts());
      await Promise.all(promisesToLoad);
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
    // NOTE IMP: reloading tabToIndexMap must be called after loading policies and org details because currently tabs are displayed based on the org's type
    if (this.mounted) {
      this.setState({
        tabToIndexMap: this.getTabToIndexMap(),
        licenseTupleWithHighestPriority: this.props.selectedOrg.getHigestPriorityLicenseTupleInTheOrg(),
      });
    }
    this.updateTabIndex();
  }

  /**
   * Returns map of tab x -> index, denotes tab x should be displayed at position index
   * @returns map of all tabs to the positions at which they should be displayed
   */
  private getTabToIndexMap = (): Map<TabSelected, number> => {
    const tabToIndexMap = new Map<TabSelected, number>();
    const { selectedOrg } = this.props;

    let index: number = 0;
    const showProductsTab = true;
    if (showProductsTab) {
      tabToIndexMap.set(TabSelected.PRODUCT, index++);
    }

    const showUserGroupsTab = !selectedOrg.isReadOnlyOrg();
    if (showUserGroupsTab) {
      tabToIndexMap.set(TabSelected.USERGROUP, index++);
    }

    const showAdminsTab = true;
    if (showAdminsTab) {
      tabToIndexMap.set(TabSelected.ADMIN, index++);
    }

    const showDomainsTab = !selectedOrg.isReadOnlyOrg();
    if (showDomainsTab) {
      tabToIndexMap.set(TabSelected.DOMAIN, index++);
    }

    const showDirectoriesTab = !selectedOrg.isReadOnlyOrg();
    if (showDirectoriesTab) {
      tabToIndexMap.set(TabSelected.DIRECTORY, index++);
    }

    const showPoliciesTab = !selectedOrg.isReadOnlyOrg();
    if (showPoliciesTab) {
      tabToIndexMap.set(TabSelected.POLICY, index++);
    }

    const showPolicyTemplateTab = !selectedOrg.isReadOnlyOrg();
    if (showPolicyTemplateTab) {
      tabToIndexMap.set(TabSelected.POLICY_TEMPLATE, index++);
    }

    return tabToIndexMap;
  };

  /**
   * select the first tab if the selectedTab no > #tabs displayed.
   * This scenario can occur when user navigates to a Type T org for which the selected tab might not be displayed
   * NOTE: this fn is needed to highlight the selected tab in the TabList
   */
  private updateTabIndex = (): void => {
    if (this.mounted) {
      this.setState((prevState) => {
        if (prevState) {
          const tabCount = this.state.tabToIndexMap.size;
          // check if tabSelected >= no of tabs currently displayed
          if (prevState.tabSelected >= tabCount) {
            return {
              tabSelected: 0,
            };
          }
        }
        return null;
      });
    }
  };

  /**
   * We load the policies in EditCompartment to determine if certain workflows like editing org name, and creating child orgs
   * need to be blocked based on the policies for the compartments
   */
  private async loadPolicies(): Promise<void> {
    try {
      await LoadOrgDataService.loadPolicies(this.props.selectedOrg.id, this.abortController.signal);
    } catch (error: any) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      if (this.abortController.signal.aborted) return;
      const { formatMessage } = this.props.intl;
      if (this.mounted) {
        this.setState({ errorMessage: formatMessage(messages.FailedToLoadPolicies, { error: error.message }) });
      }
    }
  }

  /**
   * We load org details here to determine if the org is a READ ONLY org or not as certain workflows are dependent
   * on this criteria
   */
  private async loadOrgDetails(): Promise<void> {
    try {
      // display wait till org details are loaded
      await LoadOrgDataService.loadOrgDetails(this.props.selectedOrg.id, this.abortController.signal);
    } catch (error) {
      if (this.abortController.signal.aborted) return;
      // NOTE: If unable to fetch org details, the org is assumed to be Editable and
      // banyan svc makes sure that the commands are not executed for such an org
      log.error(`Unable to fetch org details for org ${this.props.selectedOrg.id}`);
    }
  }

  /**
   * Load contracts so as to tag products in the product table
   */
  private async loadContracts(): Promise<void> {
    try {
      await LoadOrgDataService.loadContracts(this.props.selectedOrg.id, this.abortController.signal);
    } catch (error) {
      if (this.abortController.signal.aborted) return;
      // it's OK if contracts don't load because showing contract-type tags is best-effort. No need to show an error if it fails.
      log.error(`Unable to fetch org contracts for org ${this.props.selectedOrg.id}`);
    }
  }

  private getTabContent = (): React.ReactNode => {
    // NOTE: If a tab is NOT displayed, tabToIndexMap.get() returns undefined which will not match the tabSelected
    switch (this.state.tabSelected) {
      case this.state.tabToIndexMap.get(TabSelected.USERGROUP):
        return (
          <UserGroupsTab
            update={this.props.updateCompartmentCallback}
            selectedOrg={this.props.selectedOrg}
            isUserGroupSharingEnabled={this.state.isUserGroupSharingEnabled}
          />
        );
      case this.state.tabToIndexMap.get(TabSelected.ADMIN):
        return <AdminTable update={this.props.updateCompartmentCallback} selectedOrg={this.props.selectedOrg} />;
      case this.state.tabToIndexMap.get(TabSelected.DOMAIN):
        return <DomainsTable selectedOrg={this.props.selectedOrg} />;
      case this.state.tabToIndexMap.get(TabSelected.DIRECTORY):
        return <DirectoryTable selectedOrg={this.props.selectedOrg} />;
      case this.state.tabToIndexMap.get(TabSelected.POLICY):
        return <CompartmentPolicy update={this.props.updateCompartmentCallback} selectedOrg={this.props.selectedOrg} />;
      case this.state.tabToIndexMap.get(TabSelected.POLICY_TEMPLATE):
        return (
          <OrganizationTemplate update={this.props.updateCompartmentCallback} selectedOrg={this.props.selectedOrg} />
        );
      case this.state.tabToIndexMap.get(TabSelected.PRODUCT):
      default:
        return (
          <ProductTable
            update={this.props.updateCompartmentCallback}
            selectedOrg={this.props.selectedOrg}
            productsLoaded={this.productLoadedCallback}
          />
        );
    }
  };

  /**
   * VERY IMPORTANT: Products for an org are loaded by ProductTable component which is a child component of this class.
   * This method is called by the ProductTable component once the products are loaded. Compliance phase for the org is then determined from the
   * licenses
   */
  private productLoadedCallback = (): void => {
    this.setState({ licenseTupleWithHighestPriority: this.props.selectedOrg.getHigestPriorityLicenseTupleInTheOrg() });
  };

  public render(): React.ReactNode {
    if (this.state.loading) {
      return <Wait className="Load_wait" />;
    }
    const { licenseTupleWithHighestPriority } = this.state;
    return (
      <div className="EditCompartment" data-testid="edit-compartment-panel">
        {!_.isNil(licenseTupleWithHighestPriority) && licenseTupleWithHighestPriority.shouldShowExpireMessages() && (
          <div>
            <ExpirationBanner licenseTuple={licenseTupleWithHighestPriority} />
          </div>
        )}
        {this.props.selectedOrg.name.length > 72 ? (
          /*
            The number 72 was found empirically.
            It is equal to the number of characters that can fit in the width defined by EditCompartment_headingTooltip.
            The tooltip is shown when organization name is long and truncated.
          */
          <OverlayTrigger placement="top">
            <Heading className="EditCompartment_heading">{this.props.selectedOrg.name}</Heading>
            <Tooltip className="EditCompartment_headingTooltip">{this.props.selectedOrg.name}</Tooltip>
          </OverlayTrigger>
        ) : (
          <Heading
            className="EditCompartment_heading"
            data-testid={`${EDIT_TITLE_TESTID_PREFIX}${this.props.selectedOrg.name}`}
          >
            {this.props.selectedOrg.name}
          </Heading>
        )}

        {!_.isEmpty(this.state.errorMessage) && (
          <div>
            <AlertBanner variant="error" closeable closeTime={20000}>
              {this.state.errorMessage}
            </AlertBanner>
          </div>
        )}
        <CompartmentActionButtons update={this.props.updateCompartmentCallback} selectedOrg={this.props.selectedOrg} />
        <CompartmentInfo selectedOrg={this.props.selectedOrg} />
        {CommandService.isDeleted(this.props.selectedOrg.id, this.props.selectedOrg.id) ? (
          <div className="EditCompartment_orgDeleteMessage">
            <FormattedMessage
              id="EditCompartment.message.pendingDelete"
              defaultMessage="This org is pending deletion"
            />
          </div>
        ) : (
          <div data-testid="EditCompartment-content">
            <TabList
              defaultSelectedIndex={this.state.tabSelected}
              onChange={(num: number): void => {
                Analytics.fireCTAEvent(`Tab changed to ${TabSelected[num]}`);
                if (this.abortController.signal.aborted) return;
                if (this.mounted) {
                  this.setState({ tabSelected: num });
                }
              }}
            >
              {this.state.tabToIndexMap.has(TabSelected.PRODUCT) && (
                <Tab data-testid="edit-compartment-products-tab">
                  <FormattedMessage id="EditCompartment.tab.Products" defaultMessage="Products" />
                </Tab>
              )}
              {this.state.tabToIndexMap.has(TabSelected.USERGROUP) && (
                <Tab data-testid="edit-compartment-user-group-tab">
                  <FormattedMessage id="EditCompartment.tab.UserGroups" defaultMessage="User Groups" />
                </Tab>
              )}
              {this.state.tabToIndexMap.has(TabSelected.ADMIN) && (
                <Tab data-testid="edit-compartment-admins-tab">
                  <FormattedMessage id="EditCompartment.tab.Admins" defaultMessage="Admins" />
                </Tab>
              )}
              {this.state.tabToIndexMap.has(TabSelected.DOMAIN) && (
                <Tab data-testid="edit-compartment-domains-tab">
                  <FormattedMessage id="EditCompartment.tab.Domains" defaultMessage="Domains" />
                </Tab>
              )}
              {this.state.tabToIndexMap.has(TabSelected.DIRECTORY) && (
                <Tab data-testid="edit-compartment-directories-tab">
                  <FormattedMessage id="EditCompartment.tab.Directories" defaultMessage="Directories" />
                </Tab>
              )}
              {this.state.tabToIndexMap.has(TabSelected.POLICY) && (
                <Tab data-testid="edit-compartment-policies-tab">
                  <FormattedMessage id="EditCompartment.tab.Policies" defaultMessage="Policies" />
                </Tab>
              )}
              {this.state.tabToIndexMap.has(TabSelected.POLICY_TEMPLATE) && (
                <Tab data-testid="edit-compartment-policy-template-tab">
                  <FormattedMessage id="EditCompartment.tab.Templates" defaultMessage="Policy templates" />
                </Tab>
              )}
            </TabList>
            {this.getTabContent()}
          </div>
        )}
      </div>
    );
  }
}
export default injectIntl(EditCompartment);
export { EDIT_TITLE_TESTID_PREFIX };
