/**
 * Stores info about a file and its contents.
 */
export interface FileData {
  fileName: string;
  fileExtension: string;
  data: string;
}

/**
 * Uploading a file in the browser requires the following steps
 *  - Create a file input element
 *  - Click on the file input element which opens the import dialog
 *  - User selects a file using the dialog
 *  - Have a callback on the file input element which executes when the user selects a file
 *  - The callback references the file input element, retrieves the file from the element, and reads the data from it
 *  - The file input element should be removed after a period of time
 */

class Upload {
  /**
   * Retrieves the file extension from a file name.
   */
  private static getFileExtension(fileName: string): string {
    const extension: string | undefined = fileName.split('.').pop();
    if (extension === undefined) {
      return '';
    }
    return extension;
  }

  /**
   * Opens the import file dialog
   *  - supportedTypes: array of strings representing the mime types of the files supported for import
   *  - loadCallback: executed when the user has selected a file and opened it through the import file dialog. The html file input element is passed to the callback.
   */
  private static openImportDialog(supportedTypes: string[], loadCallback: (fileInput: HTMLInputElement) => void): void {
    // create the file input element
    const fileInput: HTMLInputElement = document.createElement('input');
    /* Reference: https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
    /*
      Note: Use width: 0.1px and height: 0.1px instead of display: none if you are sending directly
      to the back-end through html (without JavaScript)
    */
    fileInput.setAttribute('style', 'display: none; opacity: 0; overflow: hidden; position: absolute; z-index: -1;');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', supportedTypes.join(', '));

    // set callback which gets executed when the user selects and opens a file through the dialog
    fileInput.onchange = (): void => {
      loadCallback(fileInput);
    };

    // click the file input element to open the import dialog
    fileInput.click();

    // remove the file input element after a period of time
    setTimeout((): void => {
      fileInput.remove();
    }, 100);
  }

  /**
   * Takes the file selected by the file input element and reads its contents into a string.  And provides the contents and info as FileData.
   * If there is no file selected by the file input element, then FileData is returned with empty values.
   */
  static readFileFromInput(fileInput: HTMLInputElement): Promise<FileData> {
    // file input element can select multiple elements but we are only interested in the first file (Upload only supports one file)
    const { files } = fileInput;
    if (files && files.length > 0) {
      const file: File | null = files.item(0);
      if (file) {
        return Upload.readFile(file);
      }
    }
    return Promise.resolve({ fileName: '', fileExtension: '', data: '' });
  }

  /**
   * Reads a file into a string
   */
  private static readFile(file: File): Promise<FileData> {
    return new Promise<FileData>((resolve: (fileData: FileData) => void): void => {
      const fileReader: FileReader = new FileReader();
      fileReader.onload = async (): Promise<void> => {
        resolve({
          fileName: file.name,
          fileExtension: Upload.getFileExtension(file.name),
          data: fileReader.result as string,
        });
      };
      if (Upload.getFileExtension(file.name).toUpperCase() === 'XLSX') {
        // 'xlsx' library expects the data to be in binary form i.e. multi byte characters like 'é' should be represented in
        // individual bytes as 'Ã©' (2 bytes). The conversion from multibyte into a single byte is handled by the 'xlsx' library.
        // readAsBinaryString reads multibyte characters as individual bytes
        fileReader.readAsBinaryString(file as Blob);
      } else {
        // readAsText reads characters as per its encoding i.e. multibyte characters are read as one
        // For more info on readAsBinaryString vs readAsText please see:
        // 1) https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result
        // 2) https://stackoverflow.com/questions/9346052/difference-between-readasbinarystring-and-readastext-using-filereader
        fileReader.readAsText(file as Blob);
      }
    });
  }

  /**
   * Given an array of mime types for supported files, opens an import dialog and returns the info and contents of the file selected by the user.
   */
  static uploadFile(supportedTypes: string[]): Promise<FileData> {
    return new Promise<FileData>((resolve: (data: FileData) => void): void => {
      Upload.openImportDialog(supportedTypes, async (fileInput: HTMLInputElement): Promise<void> => {
        resolve(await Upload.readFileFromInput(fileInput));
      });
    });
  }
}
export default Upload;
