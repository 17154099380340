import React from 'react';
import * as _ from 'lodash';
import { SelectOption } from '@react/react-spectrum/Select';
import Wait from '@react/react-spectrum/Wait';
import Checkbox from '@react/react-spectrum/Checkbox';
import { Column } from 'primereact/column';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { TreeTable } from 'primereact/treetable';
import { defineMessages, IntlProvider, WrappedComponentProps } from 'react-intl';
import Button from '@react/react-spectrum/Button';
import StatusLight from '@react/react-spectrum/StatusLight';
import GoUrl, { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import BanyanCompartmentAPI from '../../../providers/BanyanCompartmentAPI';
import { UOrgData } from '../../../services/orgMaster/UOrg';
import '../SearchOrganizationContent/SearchOrganizationContent.css';
import './SelectOrganization.css';
import { ExpandedNodes } from '../../../services/treeTableUtils/ExpandedNodesUtil';
import ContractSwitchProvider from '../../../providers/ContractSwitchProvider';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { Org } from '../../../services/organization/Org';
import { MESSAGES } from '../../Messages';
import EligibilityStatus from '../../../services/orgMaster/EligibilityStatus';
import Utils from '../../../services/utils/Utils';
import { ErrorCodesData } from '../../../services/Codes/ErrorCodes';

interface SelectOrganizationInternalProps extends WrappedComponentProps {
  infoTitle: string;
  infoMessage?: string;
  goUrlKey: GoUrlKeys;
  goUrlTarget: string;
  rootOrg: SelectOption | undefined;
  selectedOrgs: Org[];
  addSelectedOrgs: (allOrgs: Org[]) => void;
  removeSelectedOrgs: (allOrgs: Org[]) => void;
  allowMultipleSelection: boolean;
  label: string; // label is used by the Step Control to display the message on each step
  isContractSwitchMode: boolean; // prop to know if this component should check contract switch or contract rollback eligibility
}

interface SelectOrganizationInternalState {
  hierarchyAsTreeNode: TreeNode | null;
  expandedOrgs: ExpandedNodes;
  allOrgsSelected: boolean;
}

interface OrgEligibility {
  isEligible: boolean | null;
  eligibilityFailureReasons: string[];
  eligibilityCheckInProgress: boolean;
}

const messages = defineMessages({
  EligibleForContractConversion: {
    id: 'OrgMigrationAA.SelectOrganization.EligibleForContractConversion',
    defaultMessage: 'Eligible for converting to allocation',
  },
  NotEligibleForContractConversion: {
    id: 'OrgMigrationAA.SelectOrganization.NotEligibleForContractConversion',
    defaultMessage: 'Not eligible for converting to allocation',
  },
  EligibleForContractRollback: {
    id: 'OrgMigrationAA.SelectOrganization.EligibleForContractRollback',
    defaultMessage: 'Eligible for rollback to ETLA contract',
  },
  NotEligibleForContractRollback: {
    id: 'OrgMigrationAA.SelectOrganization.NotEligibleForContractRollback',
    defaultMessage: 'Not eligible for rollback to ETLA contract',
  },
});

class SelectOrganizationInternal extends React.Component<
  SelectOrganizationInternalProps,
  SelectOrganizationInternalState
> {
  abortController: AbortController;
  constructor(props: SelectOrganizationInternalProps) {
    super(props);
    this.state = {
      hierarchyAsTreeNode: null,
      expandedOrgs: {},
      allOrgsSelected: false,
    };
    this.abortController = new AbortController();
    // User can click check eligibility multiple times, make API calls only once within a span of 0.5 second (determined empirically).
    const DEBOUNCE_TIME = 500;
    this.checkOrgEligibilityDebounced = _.debounce((orgId: string) => this.checkOrgEligibility(orgId), DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.populateHierarchyAsTreeNode();
  }

  componentWillUnmount = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  };

  checkOrgEligibilityDebounced = (orgId: string): void => {
    this.checkOrgEligibility(orgId);
  };

  populateOrgHierarchy = (orgId: string): TreeNode | null => {
    const orgData = _.find(this.orgHierarchy, (org: UOrgData): boolean => org.id === orgId);
    if (orgData) {
      const childrenList = _.filter(this.orgHierarchy, (org: UOrgData): boolean => org.parentOrgId === orgId);
      // sort child org list in ascending order by name
      const sortedChildList = _.orderBy(childrenList, ['name']);
      const childTreeNodes: TreeNode[] = [];
      _.forEach(sortedChildList, (org: UOrgData): void => {
        const childTreeNode = this.populateOrgHierarchy(org.id as string);
        if (childTreeNode) {
          childTreeNodes.push(childTreeNode);
        }
      });
      return {
        key: orgId,
        data: {
          name: orgData.name,
          id: orgId,
          eligibility: {
            eligibilityFailureReasons: [],
            isEligible: null,
            eligibilityCheckInProgress: false,
          },
        },
        children: childTreeNodes,
      };
    }
    return null;
  };

  orgHierarchy: UOrgData[] = [];

  populateHierarchyAsTreeNode = async (): Promise<void> => {
    if (this.props.rootOrg) {
      this.orgHierarchy = await BanyanCompartmentAPI.getHierarchy(this.props.rootOrg.value);
      const hierarchyAsTreeNode = this.populateOrgHierarchy(this.props.rootOrg.value);
      this.setState({
        hierarchyAsTreeNode,
        expandedOrgs: { [this.props.rootOrg.value]: true },
      });
    }
  };

  private toggleOrgSelection = (orgId: string): void => {
    this.setState((prevState: SelectOrganizationInternalState): Pick<SelectOrganizationInternalState, never> => {
      const { expandedOrgs } = prevState;
      let { allOrgsSelected } = prevState;

      const orgDetails = this.findOrgDetails(orgId);
      if (!orgDetails) {
        return {};
      }

      if (this.isOrgSelected(orgId)) {
        this.props.removeSelectedOrgs([orgDetails]);
        allOrgsSelected = false;
      } else {
        // when multiple selection are not allowed, erase all prior org selections
        if (!this.props.allowMultipleSelection && this.props.selectedOrgs.length > 0) {
          this.props.removeSelectedOrgs(this.props.selectedOrgs);
          allOrgsSelected = false;
        }
        this.props.addSelectedOrgs([orgDetails]);
        expandedOrgs[orgId] = true;
      }
      return {
        expandedOrgs,
        errorMsg: undefined,
        allOrgsSelected,
      };
    });
  };

  /**
   * Uncheck all selected orgs
   */
  private unCheckAllOrgSelection = (selectedOrgs: ExpandedNodes): void => {
    Object.entries(selectedOrgs).forEach(([key]) => {
      // eslint-disable-next-line no-param-reassign
      delete selectedOrgs[key];
    });
  };

  private findOrgDetails = (orgId: string): Org | undefined => {
    const uOrgData = _.find(this.orgHierarchy, (org: UOrgData): boolean => {
      return org.id === orgId;
    });
    if (uOrgData) {
      return {
        name: uOrgData.name as string,
        id: uOrgData.id as string,
      };
    }
  };

  /**
   * @returns true if the org is not eligible for contract switch else false
   */
  private isOrgNotEligibleForContractSwitch = (node: TreeNode): boolean => {
    const orgEligibility: OrgEligibility = node.data.eligibility;
    return orgEligibility.isEligible === false;
  };

  /**
   * Toggles selection for all orgs displayed in the tree view
   */
  private toggleAllOrgSelection = (): void => {
    this.setState((prevState: SelectOrganizationInternalState): Pick<SelectOrganizationInternalState, never> | {} => {
      const { hierarchyAsTreeNode } = prevState;
      if (hierarchyAsTreeNode) {
        const { expandedOrgs } = prevState;
        const allConcernedOrgs: Org[] = [];

        const orgsToConsider: TreeNode[] = [hierarchyAsTreeNode];
        let currentNode: TreeNode | undefined = hierarchyAsTreeNode;
        while (currentNode) {
          if (prevState.allOrgsSelected) {
            if (currentNode.key !== hierarchyAsTreeNode.key) {
              // un-expand every node except root node
              delete expandedOrgs[currentNode.key];
            }
          } else {
            expandedOrgs[currentNode.key] = true;
          }
          const orgDetails = this.findOrgDetails(currentNode.key);
          if (orgDetails) {
            if (prevState.allOrgsSelected) {
              // When deselecting orgs, no need to check org eligibility. Just deselect the org
              allConcernedOrgs.push(orgDetails);
            } else if (orgDetails && !this.isOrgNotEligibleForContractSwitch(currentNode)) {
              // do NOT select org if the org is not eligible for contract switch
              allConcernedOrgs.push(orgDetails);
            }
          }
          currentNode.children.forEach((childNode) => orgsToConsider.push(childNode));
          currentNode = orgsToConsider.pop();
        }

        if (prevState.allOrgsSelected) {
          this.props.removeSelectedOrgs(allConcernedOrgs);
        } else {
          this.props.addSelectedOrgs(allConcernedOrgs);
        }

        return {
          expandedOrgs,
          allOrgsSelected: !prevState.allOrgsSelected,
          errorMsg: undefined,
        };
      }
      return {};
    });
  };

  private isOrgSelected = (orgId: string | undefined): boolean => {
    return _.find(this.props.selectedOrgs, (org: Org): boolean => orgId === org.id) !== undefined;
  };

  /**
   * Check box displayed as the first column in the Tree Table
   */
  orgSelectionCheckBox = (node: TreeNode): React.ReactNode => {
    const isOrgSelected = this.isOrgSelected(node.key);
    return (
      <Checkbox
        checked={isOrgSelected}
        onClick={(): void => {
          this.toggleOrgSelection(node.key);
        }}
        key={node.data.id}
        // disable the checkbox when the check eligibility has failed
        disabled={node.data.eligibility.isEligible === false}
      />
    );
  };

  /**
   * For a list of eligibility failures, converts each failure code to localized message
   * and returns a localized messages as ordered list
   *
   * FYI: Following are just examples should NOT be considered as actual localized messages for the error codes.
   * for e.g.
   * 1) [{"errorCode": 'NOT_A_GLOBAL_ADMIN'}, {errorCode: 'NOT_A_VALID_ORG', errorParams: [123]}]
   * => <ol>
   *      <li>Not  a global admin</li>
   *      <li>Org 123 is not a valid org</li>
   *    </ol>
   * 2) [] => <></>
   */
  getEligibilityFailureReasonsAsOrderedList = (eligibilityFailureReasons: string[]): React.ReactNode => {
    if (eligibilityFailureReasons && eligibilityFailureReasons.length > 0) {
      const failureReasonsElems = eligibilityFailureReasons.map((errMsg: string) => <li>{errMsg}</li>); // list of li elems
      return <ol className="SelectOrganization__treeTable__eligibilityFailureList">{failureReasonsElems}</ol>;
    }
    return <></>;
  };

  getEligibilityStatusMessage = (isEligible: boolean | null): string => {
    const { formatMessage } = this.props.intl;
    if (this.props.isContractSwitchMode) {
      // Contract Switch mode
      if (isEligible) {
        return formatMessage(messages.EligibleForContractConversion);
      }
      return formatMessage(messages.NotEligibleForContractConversion);
    }

    // Contract Rollback mode
    if (isEligible) {
      return formatMessage(messages.EligibleForContractRollback);
    }
    return formatMessage(messages.NotEligibleForContractRollback);
  };

  checkOrgEligibility = async (orgId: string): Promise<void> => {
    const { hierarchyAsTreeNode } = this.state;
    if (hierarchyAsTreeNode) {
      const eligibility: OrgEligibility = {
        eligibilityFailureReasons: [],
        isEligible: null,
        eligibilityCheckInProgress: true,
      };
      // mark eligibility check to be in progress for the current org
      if (this.updateTreeNode(orgId, eligibility, hierarchyAsTreeNode)) {
        this.setState({ hierarchyAsTreeNode });
      }
      try {
        let eligibilityStatus: EligibilityStatus;
        if (this.props.isContractSwitchMode) {
          eligibilityStatus = await ContractSwitchProvider.checkContractSwitchEligibilityForOrg(
            orgId,
            this.abortController
          );
        } else {
          eligibilityStatus = await ContractSwitchProvider.checkContractRollbackEligibilityForOrg(
            orgId,
            this.abortController
          );
        }
        eligibility.isEligible = eligibilityStatus.eligible;
        eligibility.eligibilityFailureReasons = this.getLocalizedErrorCodes(
          eligibilityStatus.eligibilityFailureReasons
        );
      } catch (err) {
        eligibility.eligibilityFailureReasons = [err.message];
        eligibility.isEligible = false;
      }
      eligibility.eligibilityCheckInProgress = false;
      if (this.updateTreeNode(orgId, eligibility, hierarchyAsTreeNode)) {
        // set state only when the update of eligibility details was successful
        this.setState({ hierarchyAsTreeNode });

        // if the org is not eligible for contract switch and is currently selected, unselect the org
        if (eligibility.isEligible === false) {
          this.unselectOrgWhenEligibilityCheckFails(orgId);
        }
      }
    }
  };

  getLocalizedErrorCodes = (errorCodes: ErrorCodesData[]): string[] => {
    if (errorCodes) {
      return errorCodes.map((errCode: ErrorCodesData) => Utils.localizeErrorData(errCode));
    }
    return [];
  };

  unselectOrgWhenEligibilityCheckFails = (orgId: string): void => {
    const org = _.find(this.props.selectedOrgs, (eachOrg: Org) => eachOrg.id === orgId);
    if (org) {
      this.props.removeSelectedOrgs([org]);
    }
  };

  /**
   * Navigate through the TreeNode in DFS order and update the org 'orgId' with eligibility details.
   * NOTE: 'hierarchyAsTreeNode' is updated inside this function.
   * @param orgId org for which eligibility details are to be updated
   * @param eligibility eligibilit details for org
   * @returns true if updating the orgs eligibility details is successful else false
   */
  updateTreeNode = (orgId: string, eligibility: OrgEligibility, hierarchyAsTreeNode: TreeNode): boolean => {
    if (hierarchyAsTreeNode.key === orgId) {
      const { data } = hierarchyAsTreeNode;
      data.eligibility = eligibility;
      return true;
    }
    for (let i = 0; i < hierarchyAsTreeNode.children.length; i++) {
      if (this.updateTreeNode(orgId, eligibility, hierarchyAsTreeNode.children[i])) {
        return true;
      }
    }
    return false;
  };

  getCheckEligibilityBtn = (node: TreeNode): React.ReactNode => {
    return (
      <div className="SelectOrganization__CheckEligibilityContainer">
        <Button
          key={node.data.id}
          quiet
          variant="action"
          onClick={(): void => {
            // check eligibility only if eligibility is not checked before
            if (node.data.eligibility.isEligible === null) {
              this.checkOrgEligibilityDebounced(node.data.id);
            }
          }}
          className="SelectOrganization__treeTable__checkEligibilityOption"
        >
          <span className={node.data.eligibility.isEligible !== null ? '' : 'spectrum-Link'}>Check eligibility</span>
        </Button>
        {node.data.eligibility.eligibilityCheckInProgress && (
          <Wait size="S" className="SelectOrganization__CheckEligibilityWait" />
        )}
      </div>
    );
  };

  getEligibilityStatus = (node: TreeNode): React.ReactNode => {
    const eligibilityData: OrgEligibility = node.data.eligibility;
    if (node.data.eligibility.isEligible === null) {
      return <span key={node.data.id}>{node.data.eligibility.eligibilityStatus}</span>;
    }
    return (
      <StatusLight
        className="SelectOrganization__treeTable__eligibilityStatusCont"
        variant={this.getStatusLightVariant(node.data.eligibility.isEligible)}
      >
        <div>
          <span>{this.getEligibilityStatusMessage(eligibilityData.isEligible)}</span>
          {this.getEligibilityFailureReasonsAsOrderedList(eligibilityData.eligibilityFailureReasons)}
        </div>
      </StatusLight>
    );
  };

  getStatusLightVariant = (isEligible: boolean): 'positive' | 'negative' => {
    if (isEligible) {
      return 'positive';
    }
    return 'negative';
  };

  /**
   * All selectedOrgs are passed in to this component via 'selectedOrgs'. Get 'selectedOrgs' as ExpandedNodes
   */
  getSelectedOrgsAsExpandedNodes = (): ExpandedNodes => {
    const exNodes: ExpandedNodes = {};
    _.forEach(this.props.selectedOrgs, (org: Org): void => {
      exNodes[org.id] = true;
    });
    return exNodes;
  };

  render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <div>
          <div>
            <div className="SearchOrganizationContent__descriptionContainer">
              <div className="SearchOrganizationContent__descriptionHeader">{this.props.infoTitle}</div>
              <div className="SearchOrganizationContent__description">
                {this.props.infoMessage ? this.props.infoMessage : ''}
              </div>
              <GoUrl
                goUrlKey={this.props.goUrlKey}
                target={this.props.goUrlTarget}
                className="SearchOrganizationContent__description"
              >
                {' '}
                {/* Need to update URL here... */}
                {formatMessage(MESSAGES.HelpLearnMore)}
              </GoUrl>
            </div>
          </div>
          <div>
            {this.state.hierarchyAsTreeNode !== null ? (
              <div className="SelectOrganization__TreeTableContainer">
                <TreeTable
                  value={[this.state.hierarchyAsTreeNode]}
                  selectionMode="single"
                  selectionKeys={this.getSelectedOrgsAsExpandedNodes()}
                  expandedKeys={this.state.expandedOrgs}
                  propagateSelectionUp={false}
                  propagateSelectionDown={false}
                  scrollable={false}
                  onToggle={(e: { originalEvent: Event; value: ExpandedNodes }): void => {
                    this.setState({ expandedOrgs: e.value });
                  }}
                  metaKeySelection
                >
                  <Column
                    body={this.orgSelectionCheckBox}
                    className="SelectOrganization_treeTable_selectColumn"
                    header={
                      <Checkbox
                        disabled={!this.props.allowMultipleSelection}
                        checked={this.state.allOrgsSelected}
                        onClick={(): void => this.toggleAllOrgSelection()}
                      />
                    }
                  />
                  <Column field="name" header="ORGANIZATION NAME" expander />
                  <Column
                    header="ELIGIBILITY STATUS"
                    className="SelectOrganization_treeTable_eligibilityStatusColumn"
                    body={this.getEligibilityStatus}
                    field="eligibility.eligibilityStatus"
                  />
                  <Column
                    field=""
                    className="SelectOrganization_treeTable_checkEligibilityColumn"
                    body={this.getCheckEligibilityBtn}
                  />
                </TreeTable>
              </div>
            ) : (
              <Wait className="Load_wait" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function SelectOrganization(props: Omit<SelectOrganizationInternalProps, 'ref'>): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <SelectOrganizationInternal {...props} />
    </IntlProvider>
  );
}

export default SelectOrganization;
