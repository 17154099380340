import * as _ from 'lodash';
import * as log from 'loglevel';
import { ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from '../orgMaster/OrgMaster';
import { UBasicData } from '../orgMaster/UBasic';
import { UProductProfile, UProductProfileData } from '../orgMaster/UProductProfile';
import { UUserGroup, UUserGroupData } from '../orgMaster/UUserGroup';
import { UAdmin } from '../orgMaster/UAdmin';
import { UOrgMaster } from '../orgMaster/UOrgMaster';
import { UProduct, UProductData } from '../orgMaster/UProduct';
import { UOrg, UOrgData } from '../orgMaster/UOrg';
import { OrganizationPolicy, UCompartmentPolicies, UCompartmentPoliciesData } from '../orgMaster/UCompartmentPolicy';
import TempIdGenerator from '../utils/TempIdGenerator';
import UserProfileService from '../authentication/UserProfileService';
import HierarchyManager from '../organization/HierarchyManager';
import OrgTreeCache from '../../Compartments/OrgTree/OrgTreeCache';
import CommandInterface from './CommandInterface';
import OrgPickerController from '../organization/OrgPickerController';
import { CmdDescriptionCodes, CmdDescriptionCodesData } from '../Codes/MessageImages';
import Utils from '../utils/Utils';
import { FulfillableItemType, UResource } from '../orgMaster/UResource';
import UContractSwitch, { UContractSwitchData } from '../orgMaster/UContractSwitch';
import UContractRollback, { UContractRollbackData } from '../orgMaster/UContractRollback';
import CmdDescriptionUtils from '../Codes/CmdDescriptionUtils';
import { UUserGroupShare, UUserGroupShareData } from '../orgMaster/UUserGroupShare';

export class OrgEdits {
  isReverted: boolean; // if the org was reverted, this flag would be set, that is ignore all the edits
  commands: CommandInterface[]; // The unique identifier for edits is elem.id and elemType
  constructor() {
    this.isReverted = false;
    this.commands = [];
  }
}

export class CommandService {
  private static readonly COMMAND_MAP_FOR_ORGS = 'CommandMapForOrgs';
  private static readonly COMMAND_MAP_FOR_ORG_MAPPER = 'CommandMapForOrgMapper';

  // Map to store commands
  // the key is a JSON string of [orgId, rootOrgId, userId]
  // so all the edits are tied up to the org on which the change was made, the root org, and the user who made the change
  private static commandMap = new Map<string, OrgEdits>();
  private static commandsForOrgMapper = new Map<string, CommandInterface[]>();

  private static getFilteredKeyList(): string[][] {
    const allKeys: string[] = Array.from(CommandService.commandMap.keys());
    const allKeysParsed: string[][] = _.map(allKeys, (key) => JSON.parse(key));
    return _.filter(allKeysParsed, (key: string[]) => key[1] === this.getRootOrgId() && key[2] === this.getUserId());
  }

  /**
   * Clear edits for this root org and user id
   */
  public static clearEdits(): void {
    CommandService.undoEditsInExistingOrgs();
    CommandService.removeParentChildRelationshipOfNewOrgs();
    const keysForThisRootOrgAndUserId = this.getFilteredKeyList();
    _.forEach(keysForThisRootOrgAndUserId, (key: string[]) => {
      CommandService.commandMap.delete(JSON.stringify(key));
    });
    CommandService.commandsForOrgMapper.clear();
    TempIdGenerator.cleanTempIdsInSessionStorage();
    CommandService.saveToSessionStorage();
    OrgTreeCache.clear();
  }

  private static undoEditsInExistingOrgs() {
    const editedOrgIds = CommandService.getAllEditedOrgIds();
    const existingEditedOrgIDs = _.filter(editedOrgIds, (orgId) => !_.startsWith(orgId, TEMP_ID_PREFIX));
    for (let i = 0; i < existingEditedOrgIDs.length; i++) {
      const editedOrg = HierarchyManager.getOrg(existingEditedOrgIDs[i]);
      if (editedOrg) {
        const editsInOrg = CommandService.getAllEditsForTheOrg(existingEditedOrgIDs[i]);
        for (let j = 0; j < editsInOrg.length; j++) {
          const edit = editsInOrg[j];
          CommandService.undoSingleEdit(editedOrg, edit);
        }
      }
    }
  }

  /**
   * Checks if the particular type of update action is reverting the element back to its original value for that update.
   * For example: enabling and then disabling overallocation button will result in no update.
   * For elements on which we can do multiple types of updates, we need this method to decide if a specific type of update
   * action is making a change or reverting the data. For now, Products and Organizations can have multiple update actions on them.
   * example: updating product quantities and enabling/disabling overallocation
   *  NOTE: For now only product cases are handled here. Will be adding more cases in future PRs.
   * @param command
   * @param elemType
   * @param cmdDescriptionCode
   * @param cmdDescriptionParams
   * @private
   */
  private static isUpdateSameAsOriginal(
    command: CommandInterface,
    elemType: ObjectTypes,
    cmdDescriptionCode?: string,
    cmdDescriptionParams?: string[]
  ): boolean {
    switch (elemType) {
      case ObjectTypes.PRODUCT: {
        const origElem = command.originalElem as UProduct;
        const editedElem = command.elem as UProduct;
        if (cmdDescriptionCode === 'UPDATE_PRODUCT_QUANTITY') {
          const resourceList: string = CommandService.computeProductResourcesImage(
            editedElem.resources,
            origElem.resources,
            OrgOperation.UPDATE
          );
          // no change in quantities
          if (resourceList === '') {
            return true;
          }
          // update the parameters with the quantities
          cmdDescriptionParams?.push(resourceList);
          return false;
        }
        if (cmdDescriptionCode === 'UPDATE_PRODUCT_OVERALLOC') {
          return editedElem.allowExceedQuotas === origElem.allowExceedQuotas;
        }
        if (cmdDescriptionCode === 'UPDATE_PRODUCT_OVERUSE') {
          return editedElem.allowExceedUsage === origElem.allowExceedUsage;
        }
        break;
      }
      case ObjectTypes.ORGANIZATION: {
        const origElem = command.originalElem as UOrg;
        const editedElem = command.elem as UOrg;
        if (cmdDescriptionCode === 'UPDATE_ORG_NAME') {
          return editedElem.name === origElem.name;
        }
        if (cmdDescriptionCode === 'REPARENT_ORG') {
          return editedElem.parentOrgId === origElem.parentOrgId;
        }
        break;
      }
      default: {
        return false;
      }
    }
    return false;
  }

  /**
   * retrieves the previously saved original org path name in the command description.
   * @param command
   * @param msgIndex
   * @return the original org path name
   */
  private static retrieveOriginalOrgPathNameForReparent(command: CommandInterface, msgIndex: number): string {
    // retrieve the original org path name. This param is defined at CMD_DESCRIPTION_CODES.REPARENT_ORG
    return command.messageData.cmdDescription[msgIndex].cmdDescriptionParams[0];
  }

  /**
   * Computes the resource list params for Product Create or Update command description image
   * For e.g. for 1000 Users and 100 GB resourceList, returns "1000:Users,100:GB"
   * NOTE: we dont use space as any type of separator as some units have spaces in them
   * If the operation is UPDATE it compares the values with the original quantities and builds the string only if there
   * is a change and also the string will contain only the exact change. It returns an empty value if there is no change in quantities
   * If the operation is CREATE, it does not do any comparison.
   * @param changedResList
   * @param oldResList
   * @param operation
   * @private
   */
  private static computeProductResourcesImage(
    changedResList: UResource[],
    oldResList: UResource[],
    operation: OrgOperation
  ): string {
    if (changedResList) {
      const computedResListArray: string[] = [];
      changedResList
        .filter((resource: UResource): boolean => resource.fulfillableItemType === FulfillableItemType.QUOTA)
        .forEach((resource: UResource) => {
          if (!Utils.canParseInt(resource.grantedQuantity)) {
            // for non numeric quantities like 'UNLIMITED', add it to the res list only for CREATE
            if (operation === OrgOperation.CREATE) {
              computedResListArray.push(
                `${Utils.localizeUnlimitedValue(resource.grantedQuantity)}:${Utils.localizedResourceUnit(
                  resource.unit
                )}`
              );
            }
            // you can never UPDATE this value, so return
            return;
          }
          // if it is a numeric quantity, compare and check if this value was updated by the user
          if (operation === OrgOperation.UPDATE) {
            const oldRes = _.find(oldResList, ['code', resource.code]);
            if (oldRes?.grantedQuantity !== resource.grantedQuantity) {
              computedResListArray.push(
                `${Utils.localizeUnlimitedValue(resource.grantedQuantity)}:${Utils.localizedResourceUnit(
                  resource.unit
                )}`
              );
            }
          } else {
            // for CREATE, add the numeric quantity to the res list
            computedResListArray.push(
              `${Utils.localizeUnlimitedValue(resource.grantedQuantity)}:${Utils.localizedResourceUnit(resource.unit)}`
            );
          }
        });
      // resource list can be empty when the user has changed the numbers back to original and there is no update as such
      if (computedResListArray.length > 0) return _.join(computedResListArray, ',');
    }
    return '';
  }

  /**
   * compares the edited list org policies with the original one to compute the command description params
   * for every edited policy, we compute the following string-
   * 'name of the policy:allowed=true/false:locked=true/false'
   * We combine all the computed strings as a single one separated by commas and store it as the parameter.
   * 'name of the policy1:allowed:lock,name of the policy2:not allowed:unlock'
   * @param editedPolicies
   * @param originalPolicies
   * @param cmdDescriptionParams
   * @private
   */
  private static updatePolicyParams(
    editedPolicies: UCompartmentPolicies,
    originalPolicies: UCompartmentPolicies,
    cmdDescriptionParams: string[]
  ): void {
    const computedResListArray: string[] = [];
    _.forEach(editedPolicies.policies, (editedPolicy: OrganizationPolicy): void => {
      const matchingOriginalPolicy = _.find(originalPolicies.policies, ['name', editedPolicy.name]);
      let param: string = `${editedPolicy.name}`;
      // check if value was changed
      if (editedPolicy.value !== matchingOriginalPolicy?.value) {
        const allowedVal: string = editedPolicy.value ? 'allowed' : 'not_allowed';
        param += `:${allowedVal}`;
      }
      // check if locked by was changed
      if (editedPolicy.lockedBy !== matchingOriginalPolicy?.lockedBy) {
        const lockedVal: string = editedPolicy.lockedBy ? 'locked' : 'unlocked';
        param += `:${lockedVal}`;
      }
      computedResListArray.push(param);
    });
    cmdDescriptionParams.push(_.join(computedResListArray, ','));
  }

  /**
   * updates command params with resource list for Product Create and Update
   * @param productElem
   * @param cmdDescriptionParams
   * @param operation
   * @param originalProduct
   * @private
   */
  private static updateCmdDescriptionParamsWithResourceList(
    productElem: UProduct,
    operation: OrgOperation,
    cmdDescriptionParams: string[],
    originalProduct?: UProduct
  ): void {
    const oldResList = originalProduct ? originalProduct.resources : [];
    const resourceList = CommandService.computeProductResourcesImage(productElem.resources, oldResList, operation);
    cmdDescriptionParams.push(resourceList);
  }

  public static undoSingleEdit(org: UOrgMaster, edit: CommandInterface) {
    CommandService.handleReparentUndoRedo(org, edit, true);
    org.undo(edit.operation, edit.elem, edit.elemType, edit.originalElem);
  }

  /**
   * handle undo and redo of reparent operation
   * @org UOrgMaster
   * @param org
   * @param edit
   * @param undo
   */
  public static handleReparentUndoRedo(org: UOrgMaster, edit: CommandInterface, undo: boolean) {
    // handle undo/redo of reparent operation
    if (edit.elemType === ObjectTypes.ORGANIZATION && edit.operation === OrgOperation.UPDATE) {
      const newParentId = undo ? (edit.originalElem as UOrg).parentOrgId : (edit.elem as UOrg).parentOrgId;
      if (org.getParentOrgMaster()?.id !== newParentId) {
        // if reparent, then remove the org reference from previous parent and add org reference to the current parent
        HierarchyManager.reparentOrg(HierarchyManager.getOrg(newParentId), org);
      }
    }
  }

  private static removeParentChildRelationshipOfNewOrgs() {
    const allEdits: CommandInterface[] = CommandService.getAllEdits();
    const editsForOrgCreation = _.filter(
      allEdits,
      (edit: CommandInterface): boolean =>
        edit.operation === OrgOperation.CREATE && edit.elemType === ObjectTypes.ORGANIZATION
    );
    if (editsForOrgCreation) {
      _.forEach(editsForOrgCreation, (newOrgEdit) => {
        const newOrg = newOrgEdit.elem as UOrg;
        const newOrgMaster = HierarchyManager.getOrg(newOrg.id);
        if (newOrgMaster) {
          const parentOfNewOrg = newOrgMaster?.getParentOrgMaster();
          if (parentOfNewOrg) {
            // find the new org org whose parent is an existing org.
            if (!parentOfNewOrg.isNewOrg()) {
              HierarchyManager.removeOrgCompletely(newOrgMaster);
            }
          }
        }
      });
    }
  }

  // check if updated value of the element is same as the original value
  private static isElemSameAsOriginal(
    orgId: string,
    elem: UBasicData,
    originalElem: UBasicData | undefined,
    elemType: ObjectTypes
  ): boolean {
    if (originalElem === undefined) {
      // this case should never be hit as originalElem should always be defined for UPDATE operation
      // and this method is only called when UPDATE edit exists already
      return false;
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId); // this represents the original org
    if (org?.isNewOrg()) {
      return false; // nothing to check if the org is new.
    }

    return this.areElemsSame(elemType, elem, originalElem);
  }

  private static areElemsSame(elemType: ObjectTypes, elem: UBasicData, originalElem: UBasicData) {
    switch (elemType) {
      /* eslint-disable no-case-declarations */
      // Only compare only the properties that can be updated. For example for a user group only its name, profiles and admins can be updated.
      // Do not compare the unnecessary fields like adminsLoaded and so on. That can lead to incorrect comparisions as the admins can
      // be loaded in the future without updating the original element
      case ObjectTypes.ADMIN:
        const originalAdmin = originalElem as UAdmin;
        const currentAdmin = elem as UAdmin;
        // Note: isEqualInEdits does not actually compare whether UAdmins are equal but if they are both edited or not edited.
        // This is fine because this method (isElemSameAsOriginal) is only used to check an element no longer has any edits and therefore remove the command.
        // If this method is ever used for a different purpose, UAdmin might need to use a method to actual check for equality.
        return currentAdmin.isEqualInEdits(originalAdmin);
      case ObjectTypes.USER_GROUP:
        const originalUserGroup = new UUserGroup(originalElem as UUserGroupData);
        const currentUserGroup = new UUserGroup(elem as UUserGroupData);
        return currentUserGroup.isEqual(originalUserGroup);
      case ObjectTypes.USER_GROUP_SHARE:
        const originalUserGroupShare = new UUserGroupShare(originalElem as UUserGroupShareData);
        const currentUserGroupShare = new UUserGroupShare(elem as UUserGroupShareData);
        return currentUserGroupShare.isEqual(originalUserGroupShare);
      case ObjectTypes.PRODUCT:
        const originalProduct = new UProduct(originalElem as UProductData);
        const currentProduct = new UProduct(elem as UProductData);
        return currentProduct.isEqual(originalProduct);
      case ObjectTypes.PRODUCT_PROFILE:
        const originalProfile = new UProductProfile(originalElem as UProductProfileData);
        const currentProfile = new UProductProfile(elem as UProductProfileData);
        return currentProfile.isEqual(originalProfile);
      case ObjectTypes.COMPARTMENT_POLICY:
        const editedPolicies = new UCompartmentPolicies(elem as UCompartmentPoliciesData);
        const originalPolicies = new UCompartmentPolicies(originalElem as UCompartmentPoliciesData);
        return editedPolicies.isEqual(originalPolicies);
      case ObjectTypes.ORGANIZATION:
        const originalOrg = new UOrg(originalElem as UOrgData);
        const currentOrg = new UOrg(elem as UOrgData);
        return currentOrg.isEqual(originalOrg);
      case ObjectTypes.CONTRACT_SWITCH:
        const editedContSwitch = new UContractSwitch(elem as UContractSwitchData);
        const originalContSwitch = new UContractSwitch(originalElem as UContractSwitchData);
        return editedContSwitch.isEqual(originalContSwitch);
      case ObjectTypes.CONTRACT_ROLLBACK:
        const editedContRollback = new UContractRollback(elem as UContractRollbackData);
        const originalContRollback = new UContractRollback(originalElem as UContractRollbackData);
        return editedContRollback.isEqual(originalContRollback);
      default:
      /* eslint-enable no-case-declarations */
    }
    return false;
  }

  /**
   * Compare the original policies with current policies and get the difference.
   * @param editedPolicies
   * @param org
   * @private
   */
  private static preprocessPolicies(editedPolicies: UCompartmentPolicies, org: UOrgMaster) {
    const edits: CommandInterface[] = CommandService.getElementEdits(org.id, ObjectTypes.COMPARTMENT_POLICY);
    // if there has been no edit on policies so far, then original policy is same as the one stored in org
    // if there has been an edit, then refer to the originalElem (original element) stored in the edit
    const originalPolicies = _.isEmpty(edits) ? org.compartmentPolicy : (edits[0].originalElem as UCompartmentPolicies);
    return UCompartmentPolicies.removeEditsIfSameAsOriginal(editedPolicies, originalPolicies);
  }

  /**
   * Convenience method for generating cmdDescriptionParams for UAdmin
   * @param admin admin being modified by command
   * @returns cmdDescriptionParams to fulfill ADD_ADMIN_ROLES and UPDATE_ADMIN_ROLES as defined in MessageImages.ts or JobExecution.
   *          Specifically: [email, org pathname, roles].
   */
  private static buildAdminCmdDescParams(admin: UAdmin): string[] {
    return [admin.email, CmdDescriptionUtils.getPathname(admin.orgId), admin.updatedRoleNames()];
  }

  /**
   * Convenience method to add command edits for admins
   * @param org org the admin is a member of
   * @param editedAdmin admin either being created, updated, or deleted
   * @param operation CREATE, UPDATE, DELETE
   * @param originalAdmin version of admin before a particular edit has been done (this is not necessarily the original admin without any command edits)
   */
  public static addAdminEdit(
    org: UOrgMaster,
    editedAdmin: UAdmin,
    operation: OrgOperation,
    originalAdmin?: UAdmin
  ): void {
    switch (operation) {
      case OrgOperation.CREATE:
        CommandService.addEdit(
          org,
          editedAdmin,
          ObjectTypes.ADMIN,
          operation,
          undefined,
          'ADD_ADMIN_ROLES',
          CommandService.buildAdminCmdDescParams(editedAdmin)
        );
        break;
      case OrgOperation.UPDATE:
        CommandService.addEdit(
          org,
          editedAdmin,
          ObjectTypes.ADMIN,
          operation,
          originalAdmin,
          'UPDATE_ADMIN_ROLES',
          CommandService.buildAdminCmdDescParams(editedAdmin)
        );

        break;
      case OrgOperation.DELETE:
        CommandService.addEdit(
          org,
          editedAdmin,
          ObjectTypes.ADMIN,
          operation,
          undefined,
          'DELETE_ALL_ROLES_FOR_ADMIN',
          [editedAdmin.email, CmdDescriptionUtils.getPathname(editedAdmin.orgId)]
        );
        break;
      default:
        log.error(`unsupported operation: ${operation}`);
    }
  }

  /**
   * Checks for any admin edits associated with elements being deleted and removes or modifies the admin edits (commands).
   * @param elemType type of element being deleted
   * @param editedElem element being deleted
   * @param org organization the element belongs to
   * @param orgEdits edits associated with the organization
   */
  private static checkAndRemoveAdminEdits(
    elemType: ObjectTypes,
    editedElem: UBasicData,
    org: UOrgMaster,
    orgEdits: OrgEdits
  ): void {
    // admin edits only need to be removed if a product, profile, or user group is being removed
    if (
      elemType === ObjectTypes.PRODUCT ||
      elemType === ObjectTypes.PRODUCT_PROFILE ||
      elemType === ObjectTypes.USER_GROUP
    ) {
      const adminEditList: CommandInterface[] = CommandService.getElementEdits(org.id, ObjectTypes.ADMIN);
      _.forEach(adminEditList, (adminEdit: CommandInterface): void => {
        const uAdmin = adminEdit.elem as UAdmin;
        if (editedElem.id) {
          // clear edits on targets associated with the deleted element for the admin
          if (elemType === ObjectTypes.PRODUCT) {
            uAdmin.clearProductAdminEditFor(editedElem.id);
          } else if (elemType === ObjectTypes.PRODUCT_PROFILE) {
            const profile = editedElem as UProductProfileData;
            if (profile.productId) {
              uAdmin.clearProfileAdminEditFor(editedElem.id, profile.productId);
            }
          } else if (elemType === ObjectTypes.USER_GROUP) {
            uAdmin.clearUserGroupAdminEditFor(editedElem.id);
          }
        }
        /* eslint-disable no-param-reassign */
        // update listed roles for admin command message (some roles listed in the command message might be removed)
        // (this is done unconditionally because the UAdmin.updatedRoleNames method checks if roles have edits anyways)
        adminEdit.messageData.cmdDescription[0].cmdDescriptionParams = CommandService.buildAdminCmdDescParams(uAdmin);
        /* eslint-enable no-param-reassign */
        // if there are no more edits on the UAdmin, remove the entire command for the UAdmin
        if (!uAdmin.hasAnyEdits()) {
          _.remove(orgEdits.commands, adminEdit);
        }
      });
    }
  }

  /**
   * Adds an edit given elemType (string for the type of element being edits), elem (object added/deleted/updated), orgId
   * If the object id exists before, then the edit is updated
   *
   * Two ways of sending the editedElem and originalElem:
   * 1. Make copy of the element before updating it and pass that as originalElem (updated one will be editedElem)
   * 2. Make edits in a different copy and pass that as editedElem. (unchanged one will be originalElem)
   * @param org
   * @param editedElem
   * @param elemType
   * @param operation
   * @param originalElem - This would be undefined for edits of type CREATE and DELETE.
   * @param cmdDescriptionCode
   * @param cmdDescriptionParams
   */
  public static addEdit(
    org: UOrgMaster,
    editedElem: UBasicData,
    elemType: ObjectTypes,
    operation: OrgOperation,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    originalElem: UBasicData | undefined = undefined,
    cmdDescriptionCode: CmdDescriptionCodes,
    cmdDescriptionParams: string[] = []
  ): void {
    if (!CommandService.commandMap.has(this.key(org.id))) {
      CommandService.commandMap.set(this.key(org.id), new OrgEdits());
    }
    const orgEdits = CommandService.commandMap.get(this.key(org.id)) as OrgEdits;
    if (orgEdits.isReverted) {
      // remove the previous edits if the org was reverted.
      orgEdits.commands = [];
      orgEdits.isReverted = false; // set isReverted to false, as there are more edits on the org now
    }
    const command: CommandInterface = {
      elem: editedElem,
      elemType,
      operation,
      lastUpdatedAt: new Date().getTime(),
      messageData: {
        cmdDescription: [
          {
            cmdDescriptionCode,
            cmdDescriptionParams,
          },
        ],
      },
    };
    // check if the update exists before for the given elem
    const elemIndex: number = _.findIndex(orgEdits.commands, (edit: CommandInterface): boolean => {
      return edit.elem.id === editedElem.id && edit.elemType === elemType; // elemType check is necessary as UOrg and UCompartmentPolicy both have org id as elem id.
    });
    let removeOrgIdFromCommandMap = false;
    switch (operation) {
      case OrgOperation.UPDATE:
        if (elemType === ObjectTypes.COMPARTMENT_POLICY) {
          // only the changed policies should be recorded in the command.
          // eslint-disable-next-line no-param-reassign
          command.elem = CommandService.preprocessPolicies(editedElem as UCompartmentPolicies, org);
        }
        if (elemIndex === -1) {
          // add update if it does not exist
          // set original element if the element is updated for the first time
          const originalOrg = HierarchyManager.getOrg(org.id);
          if (originalOrg) {
            command.originalElem = originalElem;
          }
          // for products , when quantities are updated, get the right user friendly string to store in command
          // description
          if (elemType === ObjectTypes.PRODUCT && cmdDescriptionCode === 'UPDATE_PRODUCT_QUANTITY') {
            CommandService.updateCmdDescriptionParamsWithResourceList(
              editedElem as UProduct,
              OrgOperation.UPDATE,
              cmdDescriptionParams,
              originalElem as UProduct
            );
          }
          if (elemType === ObjectTypes.COMPARTMENT_POLICY) {
            CommandService.updatePolicyParams(
              command.elem as UCompartmentPolicies,
              command.originalElem as UCompartmentPolicies,
              cmdDescriptionParams
            );
          }
          orgEdits.commands.push(command);
        } else if (
          orgEdits.commands[elemIndex].operation !== OrgOperation.CREATE &&
          CommandService.isElemSameAsOriginal(org.id, command.elem, orgEdits.commands[elemIndex].originalElem, elemType)
        ) {
          // check if updated value of the element is same as the original value
          // if yes, delete the edit
          const existingCommand = orgEdits.commands[elemIndex];
          _.remove(orgEdits.commands, existingCommand);
        } else {
          // if the element exists before and its not same as original, just update its value (overwrite the earlier value of element)
          // this is required so that if a newly created element is updated, the operation type is not change. So the operation type for newly create element should be CREATE (and not UPDATE). In other words, CREATE overrides UPDATE.
          // Note, DELETE also overrides UPDATE
          orgEdits.commands[elemIndex].elem = command.elem;
          if (orgEdits.commands[elemIndex].operation !== OrgOperation.CREATE) {
            // check if this type of update has already been made on the element by searching
            // for the corresponding message code
            const msgIndex = _.findIndex(
              orgEdits.commands[elemIndex].messageData.cmdDescription,
              (image: CmdDescriptionCodesData): boolean => image.cmdDescriptionCode === cmdDescriptionCode
            );
            // If it was not found, the new type of update on the same element is added to the message codes
            if (msgIndex === -1) {
              // for products , when quantities are updated, get the right user friendly string to store in command
              // description
              if (elemType === ObjectTypes.PRODUCT && cmdDescriptionCode === 'UPDATE_PRODUCT_QUANTITY') {
                CommandService.updateCmdDescriptionParamsWithResourceList(
                  editedElem as UProduct,
                  OrgOperation.UPDATE,
                  cmdDescriptionParams,
                  originalElem as UProduct
                );
              }
              orgEdits.commands[elemIndex].messageData.cmdDescription.push({
                cmdDescriptionCode,
                cmdDescriptionParams,
              });
            } else {
              // If its the same type of update action, then check if that action is reverting the element
              // back to the original state for that action
              const shouldBeRemoved = CommandService.isUpdateSameAsOriginal(
                orgEdits.commands[elemIndex],
                elemType,
                cmdDescriptionCode,
                cmdDescriptionParams
              );
              if (shouldBeRemoved) {
                // if yes, then remove that update from the command description
                _.pullAt(orgEdits.commands[elemIndex].messageData.cmdDescription, [msgIndex]);
              } else {
                // if not, update the command description parameters
                if (elemType === ObjectTypes.ORGANIZATION && cmdDescriptionCode === 'REPARENT_ORG') {
                  // the user might reparent the same org multiple times. While doing so, the original org pathname
                  // will be lost if we replace the parameter with the most recent previous state of the pathname.
                  // We retrieve the original one and save it in the new description parameters
                  const originalOrgPathName = CommandService.retrieveOriginalOrgPathNameForReparent(
                    orgEdits.commands[elemIndex],
                    msgIndex
                  );
                  // use it in the new new parameters
                  cmdDescriptionParams.splice(0, 1, originalOrgPathName);
                }
                if (elemType === ObjectTypes.COMPARTMENT_POLICY) {
                  CommandService.updatePolicyParams(
                    command.elem as UCompartmentPolicies,
                    orgEdits.commands[elemIndex].originalElem as UCompartmentPolicies,
                    cmdDescriptionParams
                  );
                }
                orgEdits.commands[elemIndex].messageData.cmdDescription[msgIndex].cmdDescriptionParams =
                  cmdDescriptionParams;
              }
            }
          } else {
            // If the element is updated after CREATE
            if (elemType === ObjectTypes.PRODUCT) {
              // for products , when quantities are updated, get the right user friendly string to store in command
              // description
              CommandService.updateCmdDescriptionParamsWithResourceList(
                editedElem as UProduct,
                OrgOperation.CREATE,
                cmdDescriptionParams
              );
            }
            orgEdits.commands[elemIndex].messageData.cmdDescription[0].cmdDescriptionParams = cmdDescriptionParams;
          }
        }
        break;
      case OrgOperation.DELETE:
        if (elemType === ObjectTypes.ORGANIZATION) {
          if (elemIndex !== -1 && orgEdits.commands[elemIndex].operation === OrgOperation.CREATE) {
            // delete the entry from command map if the org was a new one
            removeOrgIdFromCommandMap = true;
          } else {
            // if the org is deleted, remove all the previous edits(if they exist) on that org (except policy edits, see bug https://jira.corp.adobe.com/browse/BANY-1064)
            const nonPolicyCommands = _.remove(
              orgEdits.commands,
              (cmd) => cmd.elemType !== ObjectTypes.COMPARTMENT_POLICY
            );

            _.forEach(nonPolicyCommands, (edit) => {
              CommandService.undoSingleEdit(org, edit);
            });
            // now keep only the delete Org command
            orgEdits.commands.push(command);
          }
        } else if (elemIndex === -1) {
          orgEdits.commands.push(command);
        } else if (orgEdits.commands[elemIndex].operation === OrgOperation.CREATE) {
          // Remove the elem if it was created
          _.remove(orgEdits.commands, orgEdits.commands[elemIndex]);
        } else {
          // else update the operation type and elem
          orgEdits.commands[elemIndex].elem = editedElem;
          // DELETE overrides UPDATE. So if the element was updated before being deleted, the final operation will be DELETE (and not UPDATE)
          orgEdits.commands[elemIndex].operation = OrgOperation.DELETE;
          // change command description here
          orgEdits.commands[elemIndex].messageData.cmdDescription = [
            {
              cmdDescriptionCode,
              cmdDescriptionParams,
            },
          ];
        }
        if (elemType === ObjectTypes.PRODUCT) {
          // if the product is deleted, remove its profiles edits (because the product no longer exists, and so do any profiles edits that were created on the new product)
          const profileEditList: CommandInterface[] = CommandService.getElementEdits(
            org.id,
            ObjectTypes.PRODUCT_PROFILE
          );
          _.forEach(profileEditList, (profileEdit): void => {
            const profile = profileEdit.elem as UProductProfileData;
            if (profile.productId === editedElem.id) {
              // remove this profile edit if it belonged to the deleted product
              _.remove(orgEdits.commands, profileEdit);
            }
          });
        }
        CommandService.checkAndRemoveAdminEdits(elemType, editedElem, org, orgEdits);
        break;
      case OrgOperation.CREATE:
        if (elemType === ObjectTypes.PRODUCT) {
          const prodelem = editedElem as UProduct;
          const reslist = CommandService.computeProductResourcesImage(prodelem.resources, [], OrgOperation.CREATE);
          cmdDescriptionParams.push(reslist);
        }
        orgEdits.commands.push(command);
        break;
      default:
        log.error('Unknown operation type.'); // nothing to do in this case
    }
    CommandService.updateCommandMap(org.id, orgEdits, removeOrgIdFromCommandMap);

    // Note: DO NOT return from addEdit method without calling the following method.
    // Without calling org.addEdit, the edits will not be reflected in the org.
    org.addEdit(editedElem, elemType, operation, command.originalElem); // also send the original image. first reset to original image and then apply edits specially for case of policies;
  }

  public static addEditForOrgMapper(command: CommandInterface): void {
    const existingCommands: CommandInterface[] | undefined = this.getEditsForOrgMapper();
    // check if the command already exists
    if (!this.commandExistForOrgMapper(command)) {
      const commands: CommandInterface[] = existingCommands || [];
      commands.push(command);
      CommandService.commandsForOrgMapper.set(this.getUserId(), commands);
      CommandService.saveToSessionStorage();
    }
  }

  /**
   * @returns true if a command already exists else false
   */
  private static commandExistForOrgMapper(command: CommandInterface): boolean {
    const edits: CommandInterface[] = this.getEditsForOrgMapper();
    // filter existing commands with same type
    const commandsWithSameType: CommandInterface[] = _.filter(
      edits,
      (cmd: CommandInterface): boolean => cmd.elemType === command.elemType
    );
    return _.some(commandsWithSameType, (cmd: CommandInterface) =>
      this.areElemsSame(cmd.elemType, cmd.elem, command.elem)
    );
  }

  public static getEditsForOrgMapper(): CommandInterface[] {
    const edits: CommandInterface[] | undefined = CommandService.commandsForOrgMapper.get(this.getUserId());
    return edits || [];
  }

  /**
   * Retrieves all edits for the given org
   * @param orgId
   */
  public static getAllEditsForTheOrg(orgId: string): CommandInterface[] {
    const orgEdits: OrgEdits | undefined = CommandService.commandMap.get(this.key(orgId)) as OrgEdits;
    const orgCommands = orgEdits?.commands ?? [];
    const otherUserGroupShareCommands = CommandService.getUserGroupShareEditsFromOtherOrgs(orgId);
    return orgCommands.concat(otherUserGroupShareCommands);
  }

  /**
   * Retrieves all edits for the given org
   * @param orgId
   */
  public static getAllEditsForTheOrgExcludingUndo(orgId: string): CommandInterface[] {
    if (!CommandService.commandMap.has(this.key(orgId))) {
      return [];
    }
    const orgEdits: OrgEdits = CommandService.commandMap.get(this.key(orgId)) as OrgEdits;
    if (orgEdits.isReverted) {
      return [];
    }
    return orgEdits.commands;
  }

  /**
   * Retrieves edits for the given element type in a specific org
   * @param orgId
   * @param elemType
   */
  public static getElementEdits(orgId: string, elemType: ObjectTypes): CommandInterface[] {
    const commands: CommandInterface[] = CommandService.getAllEditsForTheOrg(orgId);
    return _.filter(commands, (edit: CommandInterface): boolean => edit.elemType === elemType);
  }

  public static getEditForSingleElement(orgId: string, elemType: ObjectTypes, elemId: string): CommandInterface[] {
    const commands: CommandInterface[] = CommandService.getAllEditsForTheOrg(orgId);
    return _.filter(commands, (edit: CommandInterface): boolean => {
      if (edit.elemType === elemType && edit.elem.id === elemId) {
        return true;
      }
      if (edit.elemType === ObjectTypes.USER_GROUP_SHARE && elemType === ObjectTypes.USER_GROUP) {
        const userGroupShare = edit.elem as UUserGroupShare;
        return userGroupShare.sourceGroupId === elemId || userGroupShare.targetGroupId === elemId;
      }
      return false;
    });
  }

  public static applyEditForSingleElement(org: UOrgMaster, elemID: string, elemType: ObjectTypes) {
    const singleElementEdit = CommandService.getEditForSingleElement(org.id, elemType, elemID);
    if (!_.isEmpty(singleElementEdit)) {
      singleElementEdit.forEach((edit) => {
        org.addEdit(edit.elem, edit.elemType, edit.operation, edit.originalElem);
      });
    }
  }

  public static applyPolicyEdit(org: UOrgMaster) {
    const policyEdits = CommandService.getElementEdits(org.id, ObjectTypes.COMPARTMENT_POLICY);
    if (!_.isEmpty(policyEdits)) {
      org.addEdit(policyEdits[0].elem, policyEdits[0].elemType, policyEdits[0].operation, policyEdits[0].originalElem);
    }
  }

  public static getAllEditedOrgIds(): string[] {
    const normallyEditedOrgIds = _.map(this.getFilteredKeyList(), (key) => key[0]);
    const touchedOrgIds = new Set<string>(normallyEditedOrgIds);
    normallyEditedOrgIds.forEach((orgId) => {
      const orgCommands: CommandInterface[] = CommandService.getAllEditsForTheOrg(orgId);
      orgCommands.forEach((command) => {
        if (command.elemType === ObjectTypes.USER_GROUP_SHARE) {
          const userGroupShare = command.elem as UUserGroupShare;
          touchedOrgIds.add(userGroupShare.sourceOrgId);
          touchedOrgIds.add(userGroupShare.targetOrgId);
        }
      });
    });
    return Array.from(touchedOrgIds);
  }

  /**
   * Retrieves all the edits across all the orgs in the loaded hierarchy
   */
  public static getAllEdits(): CommandInterface[] {
    const orgIds: string[] = this.getAllEditedOrgIds();
    let commands: CommandInterface[] = [];
    _.forEach(orgIds, (orgId: string): void => {
      const orgCommands: CommandInterface[] = CommandService.getAllEditsForTheOrg(orgId);
      commands = _.concat(commands, orgCommands);
    });
    return commands;
  }

  public static getUserGroupShareEditsFromOtherOrgs(currentOrgId: string): CommandInterface[] {
    const currentOrgKey = this.key(currentOrgId);
    const allOrgKeys: string[] = Array.from(this.commandMap.keys());
    const otherOrgKeys = _.filter(allOrgKeys, (key: string): boolean => key !== currentOrgKey);

    let commands: CommandInterface[] = [];
    _.forEach(otherOrgKeys, (otherOrgKey: string): void => {
      const orgCommands: CommandInterface[] = CommandService.commandMap.get(otherOrgKey)?.commands ?? [];
      commands = _.concat(
        commands,
        _.filter(orgCommands, (edit: CommandInterface): boolean => {
          if (edit.elemType === ObjectTypes.USER_GROUP_SHARE) {
            const userGroupShare = edit.elem as UUserGroupShare;
            return userGroupShare.sourceOrgId === currentOrgId || userGroupShare.targetOrgId === currentOrgId;
          }
          return false;
        })
      );
    });
    return commands;
  }

  public static isCreated(orgId: string, elemId: string): boolean {
    const allEdits: CommandInterface[] = CommandService.getAllEditsForTheOrg(orgId);
    const findEdit = _.find(allEdits, (edit: CommandInterface): boolean => edit.elem.id === elemId);
    if (!findEdit) {
      return false;
    }
    return findEdit.operation === OrgOperation.CREATE;
  }

  public static isUpdated(orgId: string, elemId: string): boolean {
    const allEdits: CommandInterface[] = CommandService.getAllEditsForTheOrgExcludingUndo(orgId);
    const findEdit = _.find(allEdits, (edit: CommandInterface): boolean => edit.elem.id === elemId);
    if (!findEdit) {
      return false;
    }
    return findEdit.operation === OrgOperation.UPDATE;
  }

  public static isDeleted(orgId: string, elemId: string): boolean {
    const allEdits: CommandInterface[] = CommandService.getAllEditsForTheOrg(orgId);
    const findEdit = _.find(allEdits, (edit: CommandInterface): boolean => edit.elem.id === elemId);
    if (!findEdit) {
      return false;
    }
    return findEdit.operation === OrgOperation.DELETE;
  }

  private static saveToSessionStorage(): void {
    sessionStorage.setItem(CommandService.COMMAND_MAP_FOR_ORGS, JSON.stringify(Array.from(CommandService.commandMap)));
    sessionStorage.setItem(
      CommandService.COMMAND_MAP_FOR_ORG_MAPPER,
      JSON.stringify(Array.from(CommandService.commandsForOrgMapper))
    );
    TempIdGenerator.saveTempIdsToSessionStorage();
  }

  /**
   * Populate edits from session storage when the application is first loaded.
   */
  public static initializeFromSessionStorage(): void {
    const commandsMapOrUndefined: string | null = sessionStorage.getItem(CommandService.COMMAND_MAP_FOR_ORGS);
    if (commandsMapOrUndefined) {
      CommandService.commandMap = new Map(JSON.parse(commandsMapOrUndefined) as Map<string, OrgEdits>);
    }
    const commandsForOrgMapperOrUndefined: string | null = sessionStorage.getItem(
      CommandService.COMMAND_MAP_FOR_ORG_MAPPER
    );
    if (commandsForOrgMapperOrUndefined) {
      CommandService.commandsForOrgMapper = new Map(
        JSON.parse(commandsForOrgMapperOrUndefined) as Map<string, CommandInterface[]>
      );
    }
    TempIdGenerator.initializeTempIdsFromSessionStorage();
    CommandService.cleanUpEdits();
  }

  public static redoAllEditsInOrg(org: UOrgMaster) {
    if (!CommandService.isReverted(org.id)) {
      const editsInOrg = CommandService.getAllEditsForTheOrg(org.id);
      for (let j = 0; j < editsInOrg.length; j++) {
        const edit = editsInOrg[j];
        org.addEdit(edit.elem, edit.elemType, edit.operation, edit.originalElem);
      }
    }
  }

  private static addNewOrgs(newOrgsToAdd: UOrg[], allCreatedOrgs: UOrg[]) {
    _.forEach(newOrgsToAdd, (childOrg) => {
      HierarchyManager.addOrg(new UOrgMaster(childOrg, true));

      // find children of the org that was just added. Add those children too if any.
      const childrenOfNewlyAddedOrg = _.filter(allCreatedOrgs, (newOrg) => newOrg.parentOrgId === childOrg.id);
      if (childrenOfNewlyAddedOrg !== undefined) {
        CommandService.addNewOrgs(childrenOfNewlyAddedOrg, allCreatedOrgs);
      }
    });
  }

  public static addParentChildRelationshipForNewOrgs() {
    const allEdits: CommandInterface[] = CommandService.getAllEdits();
    const editsForOrgCreation = _.filter(
      allEdits,
      (edit: CommandInterface): boolean =>
        edit.operation === OrgOperation.CREATE && edit.elemType === ObjectTypes.ORGANIZATION
    );
    if (editsForOrgCreation) {
      const newOrgList = _.map(editsForOrgCreation, (edit) => edit.elem as UOrg);
      const allOrgMasters = HierarchyManager.getOrgs();
      _.forEach(allOrgMasters, (orgMaster: UOrg): void => {
        // find children of this org from the new org list
        // we need to filter and add selectively because an org can not be added to the hierarchy before its parent org.
        const childrenOrgs = _.filter(newOrgList, (newOrg) => newOrg.parentOrgId === orgMaster.id);
        CommandService.addNewOrgs(childrenOrgs, newOrgList);
      });
    }
  }

  /**
   * Clean up edits older than 30 days
   * This is called when the App is loaded and commands are initialized
   */
  private static cleanUpEdits(): void {
    const keys: string[] = Array.from(CommandService.commandMap.keys());
    _.forEach(keys, (key: string) => {
      const orgEdits: OrgEdits = CommandService.commandMap.get(key) as OrgEdits;
      _.remove(orgEdits.commands, (command: CommandInterface) => {
        const currentTime = new Date().getTime();
        const previousTime = command.lastUpdatedAt;
        const threshold = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        return previousTime + threshold < currentTime;
      });
      if (_.isEmpty(orgEdits.commands)) {
        CommandService.commandMap.delete(key);
      } else {
        CommandService.commandMap.set(key, orgEdits);
      }
    });
    CommandService.saveToSessionStorage();
  }

  public static setIsReverted(orgId: string, inputValue: boolean): void {
    if (!CommandService.commandMap.has(this.key(orgId))) {
      return;
    }
    const orgEdits: OrgEdits = CommandService.commandMap.get(this.key(orgId)) as OrgEdits;
    orgEdits.isReverted = inputValue;
    CommandService.updateCommandMap(orgId, orgEdits);
  }

  public static isReverted(orgId: string): boolean {
    if (!CommandService.commandMap.has(this.key(orgId))) {
      return false;
    }
    const orgEdits: OrgEdits = CommandService.commandMap.get(this.key(orgId)) as OrgEdits;
    return orgEdits.isReverted;
  }

  private static updateCommandMap(orgId: string, orgEdits: OrgEdits, removeOrgIdFromCommandMap: boolean = false): void {
    if (removeOrgIdFromCommandMap) {
      CommandService.commandMap.delete(this.key(orgId));
    } else {
      CommandService.commandMap.set(this.key(orgId), orgEdits);
    }
    CommandService.saveToSessionStorage();
  }

  private static getUserId(): string {
    return UserProfileService.getUserId() as string;
  }

  private static getRootOrgId(): string | undefined {
    return OrgPickerController.getActiveOrgId();
  }

  private static key(orgId: string): string {
    return JSON.stringify([orgId, this.getRootOrgId(), this.getUserId()]);
  }

  public static filterReparentCommands(commands: CommandInterface[]): CommandInterface[] {
    return commands.filter((command) => {
      if (command.operation === OrgOperation.UPDATE && command.elemType === ObjectTypes.ORGANIZATION) {
        const updatedOrg = new UOrg(command.elem);
        const oldOrg = command.originalElem as UOrg | undefined;
        if (oldOrg !== undefined && oldOrg.parentOrgId !== updatedOrg.parentOrgId) {
          return true;
        }
      }
      return false;
    });
  }

  static getEditsForJobExecutionPage(): CommandInterface[] {
    return this.getEditsForAllOrgsInHierarchyExcludingUndo().concat(CommandService.getEditsForOrgMapper());
  }

  /**
   * get all edits of orgs in the hierarchy
   */
  static getEditsForAllOrgsInHierarchyExcludingUndo(): CommandInterface[] {
    const orgList = HierarchyManager.getOrgs();
    return _.flatMap(orgList, (org) => CommandService.getAllEditsForTheOrgExcludingUndo(org.id));
  }

  static anyEdits(): boolean {
    return CommandService.getEditsForJobExecutionPage().length > 0;
  }

  /**
   * return true if only reparent edits
   */
  static isOnlyReparentEdits(): boolean {
    const commands: CommandInterface[] = CommandService.getEditsForAllOrgsInHierarchyExcludingUndo();
    const reparentCommand: CommandInterface[] = CommandService.filterReparentCommands(commands);
    return reparentCommand.length === commands.length;
  }

  /**
   * returns true if no reparent edits
   */
  static doesReparentEditsExist(): boolean {
    const commands: CommandInterface[] = CommandService.getEditsForAllOrgsInHierarchyExcludingUndo();
    const reparentCommand: CommandInterface[] = CommandService.filterReparentCommands(commands);
    return reparentCommand.length !== 0;
  }

  static hasEditsForUserGroupSharing(): boolean {
    const commands: CommandInterface[] = CommandService.getEditsForAllOrgsInHierarchyExcludingUndo();
    return commands.some((command) => command.elemType === ObjectTypes.USER_GROUP_SHARE);
  }

  static getNumOfDeletedAdmins(orgId: string): number {
    const adminEdits = CommandService.getElementEdits(orgId, ObjectTypes.ADMIN);
    if (_.isEmpty(adminEdits)) {
      return 0;
    }
    const deletedAdminEdits = _.filter(adminEdits, (edit) => edit.operation === OrgOperation.DELETE);
    if (_.isEmpty(deletedAdminEdits)) {
      return 0;
    }
    return deletedAdminEdits.length;
  }

  static getNumOfDeletedUserGroup(orgId: string): number {
    const ugEdits = CommandService.getElementEdits(orgId, ObjectTypes.USER_GROUP);
    if (_.isEmpty(ugEdits)) {
      return 0;
    }
    const deletedUGEdits = _.filter(ugEdits, (edit) => edit.operation === OrgOperation.DELETE);
    if (_.isEmpty(deletedUGEdits)) {
      return 0;
    }
    return deletedUGEdits.length;
  }

  public static getEditsForOperation(edits: CommandInterface[], operation: OrgOperation): CommandInterface[] {
    return _.filter(edits, (edit: CommandInterface): boolean => edit.operation === operation);
  }

  /**
   * This method should only be called by tests, thanks to this mono-static singleton design.
   */
  static reinitForTests() {
    CommandService.commandMap = new Map<string, OrgEdits>();
    CommandService.commandsForOrgMapper = new Map<string, CommandInterface[]>();
  }
}
