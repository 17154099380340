/**
 * Enumerates the different types of orgs.
 * Possible values are from https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-organizations-entities/src/main/java/com/adobe/jil/organizations/entities/enums/OrganizationType.java
 */
export enum OrgType {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
  VIP = 'VIP',
  RESELLER = 'RESELLER',
  ENTERPRISE = 'ENTERPRISE',
  DISTRIBUTOR = 'DISTRIBUTOR',
  DEVELOPER = 'DEVELOPER',
  CLASSROOM = 'CLASSROOM',
}

/**
 * Data associated with an org. This is the data returned
 * from the banyansvc for /manageable api call.
 *   - id: OrgId.
 *   - name: Name of the org.
 *   - type: Type of org.
 *   - userId: user id associated with this org (T2E vs non-T2E)
 *   - delegationGroupMigrationStatus: Status involving migration.
 */
export interface Org {
  id: string;
  name: string;
  type?: OrgType;
  userId?: string;
}

/**
 * Data associated with an ancestor of an org.  This is the data returned
 * from the banyansvc for /ancestors api call.
 *   - id: OrgId.
 *   - name: Name of the org.
 *   - parentOrgId: Id of parent org if parent exists.
 */
export interface UOrgAncestor {
  id: string;
  name: string;
  parentOrgId?: string;
}
