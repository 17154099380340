import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Textfield from '@react/react-spectrum/Textfield';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import * as _ from 'lodash';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import Alert from '@react/react-spectrum/Alert';
import { UAdmin } from '../../../../services/orgMaster/UAdmin';
import AdminSection from '../../Widgets/AdminSection/AdminSection';
import '../UserGroupDialogContent.css';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import { UUserGroup, UUserGroupLicenseGroup } from '../../../../services/orgMaster/UUserGroup';
import ProfileSection from './ProfileSection/ProfileSection';
import Analytics from '../../../../Analytics/Analytics';
import { UProductProfile } from '../../../../services/orgMaster/UProductProfile';
import { CommandService } from '../../../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import { MAX_NAME_ALLOWED_LENGTH } from '../../Constants';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import Utils from '../../../../services/utils/Utils';
import AdminPermission from '../../../../services/authentication/AdminPermission';

export enum EditUserGroupDialogContext {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export interface EditUserGroupDialogContentProps extends WrappedComponentProps {
  userGroup: UUserGroup;
  update: () => void;
  context: EditUserGroupDialogContext;
  selectedOrg: UOrgMaster;
}

interface EditUserGroupDialogContentState {
  userGroup: UUserGroup;
  userGroupAdmins: UAdmin[];
  isSubmitting: boolean;
  isLoading: boolean;
  errMsg: string | undefined;
  isSystemAdmin?: boolean;
}

const messages = defineMessages({
  AddUserGroup: {
    id: 'EditCompartment.UserGroups.EditUG.AddUserGroup',
    defaultMessage: 'Add User Group',
  },
  EditUserGroup: {
    id: 'EditCompartment.UserGroups.EditUG.EditUserGroup',
    defaultMessage: 'Edit User Group',
  },
  Save: {
    id: 'EditCompartment.UserGroups.EditUG.Save',
    defaultMessage: 'Save',
  },
  Cancel: {
    id: 'EditCompartment.UserGroups.EditUG.Cancel',
    defaultMessage: 'Cancel',
  },
  UserGroupNameErrorNonEmpty: {
    id: 'EditCompartment.UserGroups.EditUG.UserGroupName.Error.NonEmpty',
    defaultMessage: 'User group name should be non empty.',
  },
  UserGroupNameErrorUnique: {
    id: 'EditCompartment.UserGroups.EditUG.UserGroupName.Error.Unique',
    defaultMessage: 'User group name should be unique across the organization.',
  },
  UserGroupNameErrorMaxLength: {
    id: 'EditCompartment.UserGroups.EditUG.UserGroupName.Error.MaxLimit',
    defaultMessage: 'User group name should be less than 255 characters.',
  },
  UserGroupName: {
    id: 'EditCompartment.UserGroups.EditUG.UserGroupName',
    defaultMessage: 'User group name',
  },
  Error: { id: 'EditUserGroupDialog.ERROR', defaultMessage: 'ERROR' },
});

class EditUserGroupDialogContent extends React.Component<
  EditUserGroupDialogContentProps,
  EditUserGroupDialogContentState
> {
  private usrGrpNameRef: HTMLInputElement | undefined;
  private editedAdmins: UAdmin[] = []; // array that contains edited admins, these admins are saved when save is clicked, else discarded
  private originalUserGroupAdmins: UAdmin[];
  constructor(props: EditUserGroupDialogContentProps) {
    super(props);
    const updatedUserGroup: UUserGroup | undefined = _.find(
      this.props.selectedOrg.userGroups,
      (userGroup: UUserGroup): boolean => userGroup.id === this.props.userGroup.id
    );
    const userGroup: UUserGroup = _.cloneDeep(updatedUserGroup) || _.cloneDeep(this.props.userGroup); // for ADD, updatedUserGroup wont exist, so use the one provided in pros (a new user group)
    this.originalUserGroupAdmins = _.cloneDeep(this.props.selectedOrg.getAdminsForUserGroup(userGroup.id));
    this.state = {
      userGroup,
      userGroupAdmins: _.cloneDeep(this.originalUserGroupAdmins),
      isSubmitting: true,
      isLoading: false,
      errMsg: undefined,
      isSystemAdmin: false,
    };
  }

  async componentDidMount(): Promise<void> {
    if (!this.props.userGroup.adminsLoaded || !this.props.userGroup.profilesLoaded) {
      this.setState({ isLoading: true });
      try {
        await LoadOrgDataService.loadAdminForUserGroupV2(this.props.selectedOrg.id, this.props.userGroup.id);
        await LoadOrgDataService.loadProfilesForUserGroup(this.props.selectedOrg.id, this.props.userGroup.id);
        const isSystemAdmin = await AdminPermission.isSystemAdminOfOrg(this.props.selectedOrg.id);
        this.setState({ isSystemAdmin });
      } catch (error) {
        this.setState({
          isLoading: false,
          errMsg: error.message,
        });
      }
      const updatedUserGroup: UUserGroup | undefined = _.find(
        this.props.selectedOrg.userGroups,
        (userGroup: UUserGroup): boolean => userGroup.id === this.props.userGroup.id
      );
      const userGroup: UUserGroup = _.cloneDeep(updatedUserGroup) || _.cloneDeep(this.props.userGroup); // for ADD, updatedUserGroup wont exist, so use the one provided in pros (a new user group)
      this.originalUserGroupAdmins = _.cloneDeep(this.props.selectedOrg.getAdminsForUserGroup(userGroup.id));
      this.setState({
        isLoading: false,
        userGroup,
        userGroupAdmins: _.cloneDeep(this.originalUserGroupAdmins),
      });
      // auto focus the user group name input field
      if (this.usrGrpNameRef) {
        this.usrGrpNameRef.focus();
      }
    }
  }

  /*
   * Updates the usergroup state with usergroup's modified name
   */
  onUserGroupNameChange = (newName: string): void => {
    const { userGroup } = this.state;
    userGroup.name = newName;
    this.setState({ userGroup });
  };

  /**
   * Add admin to be edited if it isn't already set as edited (admin needs to add user group as target or remove user group as target)
   * Remove admin to be edited if it already is set as edited (admin was already set to add or remove user group as target, but now that operation is undone)
   */
  updateEditedAdmins = (admin: UAdmin): void => {
    const adminIndex: number = _.findIndex(this.editedAdmins, (a: UAdmin): boolean => a.id === admin.id);
    if (adminIndex >= 0) {
      this.editedAdmins.splice(adminIndex, 1);
    } else {
      this.editedAdmins.push(admin);
    }
  };

  /*
   * Adds the selected admin as user group admin and updates the user group state
   */
  onAddAdmin = (newAdminEmail: string): void => {
    if (_.find(this.state.userGroupAdmins, ['email', newAdminEmail])) {
      return;
    }
    const selectedAdmin: UAdmin | undefined = _.find(this.props.selectedOrg.getAdmins(), ['email', newAdminEmail]);
    if (selectedAdmin) {
      this.setState((prevState: EditUserGroupDialogContentState): Pick<EditUserGroupDialogContentState, never> => {
        const { userGroupAdmins } = prevState;
        const admin: UAdmin = _.cloneDeep(selectedAdmin);
        admin.addUserGroupAdminFor(prevState.userGroup.id, prevState.userGroup.name);
        this.updateEditedAdmins(admin);
        userGroupAdmins.push(admin);
        return { userGroupAdmins };
      });
    }
  };

  /*
   * Removes the selected admin from the user group admin list and updates the usergroup state
   */
  onDeleteAdmin = (admin: UAdmin): void => {
    const userGroupAdmin: UAdmin | undefined = _.find(
      this.state.userGroupAdmins,
      (a: UAdmin): boolean => a.id === admin.id
    );
    if (userGroupAdmin) {
      this.setState((prevState: EditUserGroupDialogContentState): Pick<EditUserGroupDialogContentState, never> => {
        userGroupAdmin.removeUserGroupAdminFor(prevState.userGroup.id);
        this.updateEditedAdmins(userGroupAdmin);
        return {
          userGroupAdmins: _.filter(prevState.userGroupAdmins, (a: UAdmin): boolean => a.id !== admin.id),
        };
      });
    }
  };

  // Callback from the tag list items field removing profile from the user group
  // We remove the deleted profile id and update the usergroup state here
  onDeleteProfile = (profile: UUserGroupLicenseGroup): void => {
    const { userGroup } = this.state;
    _.remove(userGroup.profiles, ['id', profile.id]);
    this.setState({
      userGroup,
    });
  };

  // Callback from the combobox field choosing profile to assign to the user group
  // We add the profile id for the selected profile and update the usergroup state here
  onSelectProfile = (newProfileSelection: UProductProfile): void => {
    const { userGroup } = this.state;
    const newProfile = newProfileSelection;
    userGroup.profiles.push({ id: newProfile.id, name: newProfile.name, productId: newProfile.productId });
    this.setState({
      userGroup,
    });
  };

  // Return the list of user group names in the compartment excluding the edited user group
  private getUserGroupNames = (): string[] => {
    let userGroupNames: string[] = _.map(this.props.selectedOrg.userGroups, 'name');
    userGroupNames = _.map(userGroupNames, (userGroupName: string): string => _.toLower(userGroupName));
    // exclude the edited usergroup name from the list
    _.pull(userGroupNames, _.toLower(this.props.userGroup.name));
    return userGroupNames;
  };

  // Returns the validation state of the textfield for usergroup name input
  // When the user is typing we return 'undefined' to hide the green check
  // Only when the textfield is out of focus we show if the input is valid/invalid
  private getValidationState = (userGroupNameIsInvalid: boolean): 'invalid' | 'valid' | undefined => {
    if (this.state.isSubmitting) {
      return undefined;
    }
    if (userGroupNameIsInvalid) {
      return 'invalid';
    }
    return 'valid';
  };

  userGroupNameExists = (): boolean => {
    const userGroupNames = this.getUserGroupNames();
    return _.includes(userGroupNames, _.toLower(this.state.userGroup.name));
  };

  // User group name has to be unique across the org and nonEmpty
  isUserGroupNameInvalid = (): boolean => {
    if (_.isEmpty(this.state.userGroup.name) || this.state.userGroup.name.length > MAX_NAME_ALLOWED_LENGTH) {
      return true;
    }
    return this.userGroupNameExists();
  };

  // Checks if the user group has been updated minus any changes related to admins
  private isUserGroupUpdated(): boolean {
    // User group is considered updated if its name has changed or if profiles associated with it have changed
    return (
      !_.isEqual(this.props.userGroup.name, this.state.userGroup.name) ||
      !_.isEmpty(
        Utils.difference(
          this.props.userGroup.profiles,
          this.state.userGroup.profiles,
          (p1: UUserGroupLicenseGroup, p2: UUserGroupLicenseGroup): boolean => p1.id === p2.id
        )
      )
    );
  }

  // Checks whether the save dialog should be disabled
  private confirmDisabled(): boolean {
    return (
      this.isUserGroupNameInvalid() ||
      (!this.isUserGroupUpdated() &&
        _.isEmpty(
          Utils.difference(
            this.originalUserGroupAdmins,
            this.state.userGroupAdmins,
            (a1: UAdmin, a2: UAdmin): boolean => a1.id === a2.id
          )
        ))
    );
  }

  /*
   * Locates the current edited user group object and updates the user group field object on the
   *  compartment object (copy) and passes it to the save callback
   */
  onSave = (): void => {
    if (this.props.context === EditUserGroupDialogContext.ADD) {
      Analytics.fireCTAEvent('add user group dialog save clicked');
      CommandService.addEdit(
        this.props.selectedOrg,
        this.state.userGroup,
        ObjectTypes.USER_GROUP,
        OrgOperation.CREATE,
        undefined,
        'CREATE_USER_GROUP',
        [this.state.userGroup.name, CmdDescriptionUtils.getPathname(this.state.userGroup.orgId)]
      );
    } else {
      if (this.isUserGroupUpdated()) {
        // if the only update is add/delete admins, then do not mark the user group as updated
        // as add/delete of admin is a separate command
        CommandService.addEdit(
          this.props.selectedOrg,
          this.state.userGroup,
          ObjectTypes.USER_GROUP,
          OrgOperation.UPDATE,
          _.cloneDeep(this.props.userGroup),
          'UPDATE_USER_GROUP',
          [this.state.userGroup.name, CmdDescriptionUtils.getPathname(this.state.userGroup.orgId)]
        );
      }
      Analytics.fireCTAEvent('edit user group dialog save clicked');
    }
    // add all the admin changes
    _.forEach(this.editedAdmins, (admin: UAdmin): void => {
      const originalAdmin: UAdmin | undefined = _.find(
        this.props.selectedOrg.getAdmins(),
        (a: UAdmin): boolean => a.id === admin.id
      );
      if (originalAdmin) {
        CommandService.addAdminEdit(
          this.props.selectedOrg,
          _.cloneDeep(admin),
          OrgOperation.UPDATE,
          _.cloneDeep(originalAdmin)
        );
      }
    });
    this.props.update();
  };

  private onCancel = (): void => {
    if (this.props.context === EditUserGroupDialogContext.ADD) {
      Analytics.fireCTAEvent('add user group dialog canceled');
    } else {
      Analytics.fireCTAEvent('edit user group dialog canceled');
    }
  };

  public render(): React.ReactNode {
    // exclude admins that are already in the userGroup
    const { formatMessage } = this.props.intl;
    const userGroupNameIsInvalid: boolean = this.isUserGroupNameInvalid();
    const validationState: 'valid' | 'invalid' | undefined = this.getValidationState(userGroupNameIsInvalid);
    return (
      <Dialog
        className="UserGroupDialogContent__container"
        title={
          this.props.context === EditUserGroupDialogContext.ADD
            ? formatMessage(messages.AddUserGroup)
            : formatMessage(messages.EditUserGroup)
        }
        onConfirm={this.onSave}
        onCancel={this.onCancel}
        confirmLabel={formatMessage(messages.Save)}
        cancelLabel={formatMessage(messages.Cancel)}
        {...this.props}
        confirmDisabled={this.confirmDisabled()}
        role="dialog"
        data-testid="addedit-usergroup-dialog"
      >
        {this.state.errMsg && (
          <div>
            <Alert header={formatMessage(messages.Error)} variant="error">
              {this.state.errMsg}
            </Alert>
          </div>
        )}
        {this.state.isLoading ? (
          <Wait className="Load_wait" />
        ) : (
          <div>
            <FieldLabel position="left" label={formatMessage(messages.UserGroupName)} id="edit-ug-dialog-name">
              <Textfield
                disabled={Utils.isTargetGroup(this.state.userGroup)}
                onChange={(newName: string): void => this.onUserGroupNameChange(newName)}
                onBlur={(): void => this.setState({ isSubmitting: false })}
                onFocus={(): void => this.setState({ isSubmitting: true })}
                value={this.state.userGroup.name}
                validationState={validationState}
                ref={(ref: any): void => {
                  // store the ref for the input field to auto focus programatically.
                  // autoFocus won't work on a TextField which is rendered asynchronously. autoFocus could be tried on v3 of TextField
                  this.usrGrpNameRef = ref;
                }}
                aria-describedby="editUserGrpName_errorMessage"
                aria-labelledby="edit-ug-dialog-name"
              />
            </FieldLabel>
            {!this.state.isSubmitting && _.isEmpty(this.state.userGroup.name) && (
              <FieldLabel
                label={formatMessage(messages.UserGroupNameErrorNonEmpty)}
                id="editUserGrpName_errorMessage"
                className="UserGroupDialogContent__errorMessage"
                labelFor="editUserGrpName_errorMessage"
              />
            )}
            {this.state.userGroup.name.length > MAX_NAME_ALLOWED_LENGTH && (
              <FieldLabel
                label={formatMessage(messages.UserGroupNameErrorMaxLength)}
                id="editUserGrpName_errorMessage"
                className="UserGroupDialogContent__errorMessage"
                labelFor="editUserGrpName_errorMessage"
              />
            )}
            {this.userGroupNameExists() && (
              <FieldLabel
                label={formatMessage(messages.UserGroupNameErrorUnique)}
                id="editUserGrpName_errorMessage"
                className="UserGroupDialogContent__errorMessage"
                labelFor="editUserGrpName_errorMessage"
              />
            )}
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage
                  id="EditCompartment.UserGroups.EditUserGroup.ProductProfiles"
                  defaultMessage="Product Profiles"
                />
              </Heading>
              <Rule variant="medium" />
              <ProfileSection
                disabled={!(this.state.isSystemAdmin || AdminPermission.isRWGlobalAdminOfActiveOrg())}
                profiles={this.state.userGroup.profiles}
                onDeleteProfile={this.onDeleteProfile}
                onSelectProfile={this.onSelectProfile}
                selectedOrg={this.props.selectedOrg}
              />
            </section>
            {!this.state.errMsg && (
              <section>
                <Heading variant="subtitle1">
                  <FormattedMessage id="EditCompartment.UserGroups.EditUserGroup.Admins" defaultMessage="Admins" />
                </Heading>
                <Rule variant="medium" />
                <AdminSection
                  admins={this.state.userGroupAdmins}
                  disabled={Utils.isTargetGroup(this.state.userGroup)}
                  labelledby="user-group-admins"
                  onDeleteAdmin={this.onDeleteAdmin}
                  onAddAdmin={this.onAddAdmin}
                  selectedOrg={this.props.selectedOrg}
                />
              </section>
            )}
          </div>
        )}
      </Dialog>
    );
  }
}
export default injectIntl(EditUserGroupDialogContent);
