import * as _ from 'lodash';
import React, { Key } from 'react';
import { ComboBox, Item } from '@adobe/react-spectrum';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import CountryList from '../../../services/countries/CountryList';
import { Country } from '../../../providers/ImsProvider';
import '../../common.css';

interface CountryCodeProps extends WrappedComponentProps {
  countryCode: string; // current org country code for 'edit'. Parent's code for 'add'
  isDisabled?: boolean;
  updateCountryCode: (countryCode: string) => void; // callback to update org country
  updateValidationState: (validationState: 'valid' | 'invalid' | undefined) => void; // callback to update validation state
}

interface CountryCodeState {
  validationState?: 'valid' | 'invalid'; // current validation state
  loadingState?: 'loading'; // loading or null
  errorMessage?: string; // string to store error shown to the user
  countryList: Country[];
}

const messages = defineMessages({
  Country: {
    id: 'Organizations.Add.Country',
    defaultMessage: 'Country/Region',
  },
  InvalidCountryName: {
    id: 'Organizations.Add.InvalidCountryName',
    defaultMessage: 'Country/Region is invalid',
  },
  CountryListError: {
    id: 'Organizations.Add.CountryListError',
    defaultMessage: 'Could not retrieve the list of countries',
  },
});

class CountryCode extends React.Component<CountryCodeProps, CountryCodeState> {
  formatMessage = this.props.intl.formatMessage;

  constructor(props: CountryCodeProps) {
    super(props);
    this.state = {
      loadingState: 'loading',
      countryList: [],
    };
  }

  /**
   * load list of countries
   */
  async componentDidMount(): Promise<void> {
    try {
      const countryList = await CountryList.getCountryList();
      this.setState({ countryList });
      this.props.updateValidationState('valid'); // enable Save button now that countries have loaded
    } catch (error) {
      this.setState({
        errorMessage: this.formatMessage(messages.CountryListError),
        validationState: 'invalid',
      });
    }
    this.setState({
      loadingState: undefined,
    });
  }

  private onSelectionChange = (key: Key): void => {
    if (!_.isEmpty(key)) {
      this.props.updateCountryCode(key.toString()); // send selected Country Code to caller
      this.props.updateValidationState('valid'); // enable Save button
      this.setState({
        validationState: 'valid',
      });
    } else {
      // Show error due to empty input field
      this.props.updateValidationState('invalid'); // disable Save button
      this.setState({
        validationState: 'invalid',
        errorMessage: this.formatMessage(messages.InvalidCountryName),
      });
    }
  };

  private static countryToString(country: Country): string {
    return `${country.countryName} (${country.countryCode})`;
  }

  public render(): React.ReactNode {
    const { validationState, errorMessage, countryList, loadingState } = this.state;
    return (
      <ComboBox
        label={this.formatMessage(messages.Country)}
        defaultItems={countryList}
        defaultSelectedKey={this.props.countryCode}
        onSelectionChange={this.onSelectionChange}
        validationState={validationState}
        errorMessage={errorMessage}
        isDisabled={this.props.isDisabled || this.state.countryList?.length === 0}
        loadingState={loadingState}
        isRequired
        necessityIndicator="label"
        minHeight="6em" // reserve space for error message. minHeight works with top labels. side labels need height.
      >
        {(item) => <Item key={item.countryCode}>{CountryCode.countryToString(item)}</Item>}
      </ComboBox>
    );
  }
}

export default injectIntl(CountryCode);
