import configurations from '../configurations/config';
import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';

const url = `${configurations.banyansvc.url}`;

/**
 * The properties that are provided in the org.
 */
export interface VerificationResult {
  orgId: string;
  name: string;
  exists: boolean;
  rootName: string;
}

/**
 * Verifies if an org id exist.
 */
export class VerificationProvider {
  /**
   * Verifies communication with BanyanSvc.
   */
  static async isBanyanSvcUp(): Promise<boolean> {
    try {
      const response: Response = await fetch(`${url}/isRunning`, {
        method: 'GET',
        headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      });

      return response.ok;
    } catch (error) {
      return false;
    }
  }
}
