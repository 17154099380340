import { AuthWindow } from '../services/authentication/IMS';
import OrgPickerController from '../services/organization/OrgPickerController';
import UserProfileService from '../services/authentication/UserProfileService';

declare const window: AuthWindow;

/**
 * Refer to https://wiki.corp.adobe.com/display/BANY/Banyan+Analytics for details
 */
class Analytics {
  private static readonly CTA = 'CTA';
  private static readonly POLICY = 'Policy';
  private static readonly NEW_PRODUCT_ALLOCATION = 'NewProductAllocation';
  private static readonly UPDATE_PRODUCT_ALLOCATION = 'UpdateProductAllocation';
  public static readonly REVIEW_PENDING_CHANGE = 'Review Pending Change';

  /**
   * send event for when a CTA (action button) is clicked
   * @param eventDetail describe what that action does
   */
  public static fireCTAEvent = (eventDetail: string): void => {
    Analytics.fireEventHelper(Analytics.CTA, eventDetail);
  };

  /**
   * send event for when policy is updated
   * @param eventDetail policy name that is updated
   */
  public static firePolicyEvent = (eventDetail: string): void => {
    Analytics.fireEventHelper(Analytics.POLICY, eventDetail);
  };

  /**
   * send event for when new product is allocated
   * @param eventDetail name of product that is allocated
   */
  public static fireAddProductEvent = (eventDetail: string): void => {
    Analytics.fireEventHelper(Analytics.NEW_PRODUCT_ALLOCATION, eventDetail);
  };

  /**
   * send event for when new product is allocated
   * @param eventDetail name of product that is allocated
   */
  public static fireUpdateProductEvent = (eventDetail: string): void => {
    Analytics.fireEventHelper(Analytics.UPDATE_PRODUCT_ALLOCATION, eventDetail);
  };

  private static fireEventHelper = (eventName: string, eventDetail: string): void => {
    // eventName must be same as the event name defined in Launch rule. See https://experience.adobe.com/#/@banyananalytics/launch
    // You will need permission to access Launch. For permission details refer https://wiki.corp.adobe.com/display/BANY/Banyan+Analytics
    dispatchEvent(new CustomEvent(eventName, { detail: eventDetail }));
  };

  public static setData = () => {
    if (window.digitalData === undefined) {
      window.digitalData = {};
    }
    const activeOrg = OrgPickerController.getActiveOrg();
    if (activeOrg) {
      window.digitalData.orgName = activeOrg.name;
      window.digitalData.orgId = activeOrg.id;
    }
    window.digitalData.userId = UserProfileService.getUserId();
    window.digitalData.userEmail = UserProfileService.getUserEmail();
  };
}

export default Analytics;
