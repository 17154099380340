import * as _ from 'lodash';
import config from '../configurations/config';
import { Org, UOrgAncestor } from '../services/organization/Org';
import { AdminRole } from '../services/authentication/IMS';
import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';
import OrgInfo from '../services/organization/OrgInfo';
import Utils from '../services/utils/Utils';
import { MESSAGES } from '../Compartments/Messages';

const url = `${config.banyansvc.url}`;

export interface OrgIdToParentIdMap {
  [orgId: string]: string;
}

export interface OrgReadOnlyMap {
  [orgId: string]: boolean;
}

class OrganizationListProvider {
  /**
   * retrieves the list of organizations and allows filtering by root orgs, admin roles the user has, and editable orgs
   * NOTE: Orgs with certain types are not editable e.g. type="DIRECT" are not editable and are READ ONLY
   * For more information on org types and its behavior please see:
   * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=BANY&title=Banyan+Specification#BanyanSpecification-TeamConsoles
   *
   * @param rootOrgs specifies whether to filter out child orgs
   * @param adminRoles array of admin roles to filter out orgs
   * @param editableOrgs specified whether to filter out READ ONLY orgs
   * @throws if failure to retrieve orgs from banyansvc or if failed to parse JSON
   */
  static async getOrganizations(rootOrgs: boolean, adminRoles: AdminRole[], editableOrgs: boolean): Promise<Org[]> {
    const searchParams: URLSearchParams = new URLSearchParams();
    if (rootOrgs) {
      searchParams.append('root_orgs', 'true');
    }
    if (editableOrgs) {
      searchParams.append('editable_orgs', 'true');
    }
    if (adminRoles.length > 0) {
      searchParams.append('admin_roles', adminRoles.join(','));
    }

    const organizationsUrl = `${url}/manageable?${searchParams.toString()}`;
    const response: Response = await fetch(organizationsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrganizationsApiError);
    return respBody;
  }

  /**
   * Retrieves information about multiple orgs
   *
   * @param orgIds ids of the orgs to retrieve information for (Limit of 100 orgIds can be given at a time)
   * @param fullPathName if true, the fullPathName will be included in the info (name includes org name and names of all parents to root)
   * @param parent if true, the parent org id will be included in the info
   * @param root if true, the root org id will be included in the info
   * @param allocations if true, a boolean reporting whether the orgs have directly allocated products will be included in the info
   * @param adminPermissions if true, a boolean reporting whether the user has RW Global Admin permissions (explicit or implicit) on the orgs will be included in the info
   * @param editableOrg if true, a boolean reporting whether the orgs are readOnly will be included in the info
   * @param nameIsGloballyUnique if true, a boolean reporting whether the org names are globally unique amongst enterprise orgs will be included in the info
   */
  static async getOrgInfo(
    orgIds: string[],
    fullPathName: boolean,
    parent: boolean,
    root: boolean,
    allocations: boolean,
    adminPermissions: boolean,
    editableOrg: boolean,
    nameIsGloballyUnique: boolean
  ): Promise<OrgInfo[]> {
    const params: URLSearchParams = new URLSearchParams();
    if (fullPathName) {
      params.append('full_path_name', 'true');
    }
    if (parent) {
      params.append('parent', 'true');
    }
    if (root) {
      params.append('root', 'true');
    }
    if (allocations) {
      params.append('allocations', 'true');
    }
    if (adminPermissions) {
      params.append('admin_permissions', 'true');
    }
    if (editableOrg) {
      params.append('editable_org', 'true');
    }
    if (nameIsGloballyUnique) {
      params.append('name_globally_unique', 'true');
    }

    const response: Response = await fetch(`${url}/org-info?${params.toString()}`, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(orgIds),
    });
    const respBody: OrgInfo[] = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrgInfoApiError);
    return respBody;
  }

  /**
   * Retrieves list of ancestors for given orgId, in hierarchical order (parent orgs are listed before the child orgs).
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing list of UOrgData for each ancestor of the given org id
   * @throws Error 'orgId cannot be empty'
   */
  static async getAncestors(orgId: string, signal?: AbortSignal): Promise<UOrgAncestor[]> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const getAncestorsUrl: string = `${url}/${orgId}/ancestors`;
    const response = await fetch(getAncestorsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetAncestorsApiError);
    return respBody;
  }

  /**
   * Return the closest ancestor org for which the user has explicit admin roles for a given org, if there exists one
   * May return the given org if the user is an explicit global admin/viewer of that org.
   * @param orgId id of the org
   * @param signal AbortSignal
   * @returns Promise containing a Org which is the closest ancestor
   * @throws Error 'orgId cannot be empty'
   */
  static async getClosestAncestor(orgId: string, signal?: AbortSignal): Promise<Org> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }

    const getOrgsUrl: string = `${url}/${orgId}/closestAncestor`;
    const response = await fetch(getOrgsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrgApiError, { orgId });
    return respBody;
  }

  static async getMinimalOrg(orgId: string, signal?: AbortSignal): Promise<Org> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const getOrgsUrl: string = `${url}/${orgId}/minimal`;
    const response = await fetch(getOrgsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetOrgApiError, { orgId });
    return respBody;
  }
}
export default OrganizationListProvider;
