import React, { useEffect, useState } from 'react';
import Wait from '@react/react-spectrum/Wait';
import { IntlProvider } from 'react-intl';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { FOOTER_ID } from '../../../components/Footer/Footer';
import '../../common.css';

interface ScrollableContentProps {
  uniqueId: string; // uniqueId for child component. A unique string representing child component's purpose. Like for user group table its "EditCompartment_UserGroupTableID"
  children: JSX.Element; // the content to be displayed inside the scrolling container
  enableInfiniteScroll?: boolean; // set this to true to enable infinite scrolling. This is used when there are more than one page to load. It can be turned off when search is on. Default is undefined, which is similar to false, that is infinite scrolling disabled by default.
  hasMorePages?: boolean; // intended for infinite scrolling.
  onScroll?: any; // intended for infinite scrolling callback. The onScroll callback is called when enableInfiniteScroll is set to true and hasMorePages is set to true
  className?: string; // to apply any styles on the container
  height?: string; // the max height in pixels, for example '50px'. If defined, the max height of the component remains fixed, that is it will "not" be updated by useEffect hook.
  dataTestId?: string;
}

/**
 * This component is reponsible for two functionalities:
 * 1) Set dynamic heights based on available space between container top and page footer
 * 2) Infinite Scrolling - load more pages as you scroll
 * @param props
 * @constructor
 */
function ScrollableContentInternal(props: ScrollableContentProps): JSX.Element {
  const SCROLLABLE_CONTAINER_ID = 'ContainerID_';
  const [loading, setLoading] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<string | number | undefined>(props.height);
  const { enableInfiniteScroll, uniqueId, children, height, dataTestId } = props;

  useEffect(() => {
    // if the height is not fixed, set up the height to space available between container top and window bottom.
    if (height === undefined) {
      const adjustContainerHeight = (): void => {
        const element: HTMLElement | null = document.getElementById(uniqueId);
        const container: HTMLElement | null = document.getElementById(SCROLLABLE_CONTAINER_ID + uniqueId);
        if (container && element) {
          const footerHTML: HTMLElement | null = document.getElementById(FOOTER_ID);
          const footerHeight = footerHTML ? footerHTML.offsetHeight : 0;
          const top = element.getBoundingClientRect().top + container.scrollTop; // include the amount of scrolling
          // if the content goes beyond footer, limit it to window bottom minus extras (like footer and some space)
          if (top + element.scrollHeight > window.innerHeight - footerHeight) {
            setMaxHeight(`${window.innerHeight - (top + footerHeight)}px`);
          } else if (enableInfiniteScroll) {
            // if the content ends before the footer, set the container height slightly less than the height of content to trigger scrollbar when there are more than one page to load
            setMaxHeight(`${element.clientHeight - 5}px`);
          } else {
            setMaxHeight(undefined);
          }
        }
      };
      adjustContainerHeight();
      window.addEventListener('resize', adjustContainerHeight);
      return function cleanUp(): void {
        window.removeEventListener('resize', adjustContainerHeight);
      };
    }
  }, [enableInfiniteScroll, uniqueId, children, height]);

  return (
    <div
      onScroll={async (event: React.UIEvent<HTMLElement>): Promise<void> => {
        const container = event.target as Element;
        // reference https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight and https://stackoverflow.com/questions/876115/how-can-i-determine-if-a-div-is-scrolled-to-the-bottom
        const bottom: boolean = container.scrollHeight <= Math.ceil(container.clientHeight + container.scrollTop);
        if (bottom && props.onScroll && props.enableInfiniteScroll && props.hasMorePages && !loading) {
          setLoading(true);
          await props.onScroll();
          setLoading(false);
        }
      }}
      style={{ maxHeight }}
      className={`${props.className} EditCompartment__ScrollableComponent`}
      id={SCROLLABLE_CONTAINER_ID + props.uniqueId}
      data-testid={dataTestId}
    >
      <div id={props.uniqueId}>{props.children}</div>
      {loading && <Wait className="Load_wait" />}
    </div>
  );
}

function ScrollableContent(props: Omit<ScrollableContentProps, 'ref'>): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ScrollableContentInternal {...props} />
    </IntlProvider>
  );
}

export default ScrollableContent;
