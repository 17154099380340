import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import { ObjectTypes, OrgOperation } from '../../services/orgMaster/OrgMaster';
import OrgSelectionUtil from '../../services/treeTableUtils/OrgSelectionUtil';
import { CommandService } from '../../services/Commands/CommandService';
import OrgTreeCache from '../OrgTree/OrgTreeCache';
import HierarchyManager from '../../services/organization/HierarchyManager';
import CmdDescriptionUtils from '../../services/Codes/CmdDescriptionUtils';

export default class OrgDeletionService {
  public static deleteOrgHelper = (orgToDelete: UOrgMaster): void => {
    const parentOrg: UOrgMaster | undefined = orgToDelete.getParentOrgMaster();
    if (!parentOrg) {
      throw Error('unable to delete root org');
    }
    if (orgToDelete.isNewOrg()) {
      HierarchyManager.removeOrgCompletely(orgToDelete);
    }
    CommandService.addEdit(
      orgToDelete,
      orgToDelete.organization,
      ObjectTypes.ORGANIZATION,
      OrgOperation.DELETE,
      undefined,
      'DELETE_ORG',
      [CmdDescriptionUtils.getPathname(orgToDelete.id)]
    );
    OrgSelectionUtil.updateOrgSelection(parentOrg.id);
    // update the org tree when a new org is deleted
    // because it's not possible to change just part of the Prime React tree by, for example, deleting a node
    OrgTreeCache.clear();
  };
}
