import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import './DeepLinkParser.css';

interface AdobeAgentWithNoOrgMessageProps {
  displayMessage?: string; // show this message if provided
}

function AdobeAgentWithNoOrgMessageInternal(props: AdobeAgentWithNoOrgMessageProps): JSX.Element {
  return (
    <div className="AdobeAgentWithNoOrgMessage">
      {props.displayMessage ? (
        props.displayMessage
      ) : (
        <FormattedMessage
          id="AdobeAgentWithNoOrgMessageInternal.message"
          defaultMessage="You can not access this page as there are no orgs"
        />
      )}
    </div>
  );
}

function AdobeAgentWithNoOrgMessage(props: AdobeAgentWithNoOrgMessageProps): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <AdobeAgentWithNoOrgMessageInternal {...props} />
    </IntlProvider>
  );
}

export default AdobeAgentWithNoOrgMessage;
