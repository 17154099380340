import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, IntlProvider, useIntl } from 'react-intl';
import OrgPickerController from '../../services/organization/OrgPickerController';
import AlertBanner from '../AlertBanner/AlertBanner';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import HeaderConsts from '../BanyanShell/HeaderConstants';
import Compartments from '../../Compartments/Compartments';
import ProductAllocation from '../../ProductAllocation/ProductAllocation';
import JobExecution from '../../JobExecution/JobExecution';
import './DeepLinkParser.css';
import AdminPermission from '../../services/authentication/AdminPermission';
import OrganizationListProvider from '../../providers/OrganizationListProvider';
import AdobeAgentWithNoOrgMessage from './AdobeAgentWithNoOrgMessage';
import NotFound from '../NotFound/NotFound';
import UserProfileService from '../../services/authentication/UserProfileService';
import Authentication from '../../services/authentication/Authentication';
import { Org, UOrgAncestor } from '../../services/organization/Org';
import OrgSelectionUtil from '../../services/treeTableUtils/OrgSelectionUtil';
import InsightsForGlobalAdmin from '../../Insights/InsightsForGlobalAdmin';
import SupportForGlobalAdmin from '../../Support/SupportForGlobalAdmin';

const messages = defineMessages({
  CannotAccessThisOrg: {
    id: 'DeepLinkParser.Error.Message',
    defaultMessage: 'You do not have permission to view org with ID {orgId}. Redirected to current organization.',
  },
  Error: {
    id: 'DeepLinkParser.Error.Message.Error',
    defaultMessage: 'Error',
  },
});

interface DeepLinkParserInterface {
  setDeepLinkingError: (orgId: string) => void;
  deepLinkingError: string;
  renderCallback: () => void;
  endpoint: string;
}

function DeepLinkParserInternal(props: DeepLinkParserInterface): JSX.Element {
  // @ts-ignore
  let { orgId }: { orgId: string } = useParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { deepLinkingError, setDeepLinkingError, renderCallback, endpoint } = props;
  const [componentToRender, setComponentToRender] = useState<JSX.Element>(<Wait centered size="L" />);

  useEffect(() => {
    const redirectAndSetError = (error: string): void => {
      setDeepLinkingError(error);
      navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(endpoint));
    };

    // load the org if user is Adobe Agent and org is not listed in manageable org list.
    // Note, at this point manageable org list is already loaded as the load happens in App.tsx (in updateState, isError methods)
    // prior to rendering this component.
    (async (): Promise<void> => {
      const orgList: Org[] = OrgPickerController.getOrgDataList();
      const orgInfo = _.find(orgList, ['id', orgId]);
      if (orgInfo) {
        // select the org if it exists in manageable org list
        OrgPickerController.selectActiveOrg(orgId);
        if (orgInfo.userId && !_.isEqual(orgInfo.userId, UserProfileService.getUserId())) {
          // "userId" could be of a T2E user profile, different from current user profile. Hence, profile switch
          await Authentication.switchProfile(orgInfo.userId);
        }
      } else if (await AdminPermission.isAdobeAgent()) {
        // If adobe agent, switch profile to T3 since orgId is not part of manageable orgs
        await Authentication.switchProfile(UserProfileService.getUserProfile()?.authId);
        // load org if user is Adobe Agent and org exists
        try {
          // if url contains query param gotoRoot
          const urLParams = new URLSearchParams(window.location.search);
          const isGotoRoot = urLParams.has('gotoRoot');
          const originalOrgId = orgId;
          if (isGotoRoot) {
            const ancestors: UOrgAncestor[] = await OrganizationListProvider.getAncestors(orgId);
            if (ancestors.length !== 0) {
              orgId = ancestors[0].id;
            }
          }
          OrgPickerController.urlParamOrg = await OrganizationListProvider.getMinimalOrg(orgId);
          OrgPickerController.selectActiveOrg(orgId);
          OrgSelectionUtil.updateOrgSelection(originalOrgId);
          navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(endpoint));
          // re render App to reflect the selected org in org picker (banyan shell)
          // This is required to populate urlParamOrg into the banyan shell's 'imsOrgs'.
          // This seems like a work-around for a bug because BanyanShell.setActiveTab() _should_ trigger a render and update to 'imsOrgs'
          renderCallback();
        } catch (error) {
          // if user is not Adobe Agent, and org is not present in manageable list, show permission error and redirect
          redirectAndSetError(error.message); // error from API call
        }
      } else {
        try {
          const closestAncestor = await OrganizationListProvider.getClosestAncestor(orgId);
          OrgPickerController.selectActiveOrg(closestAncestor.id);
          await OrgPickerController.setAncestorsForActiveOrg(closestAncestor);
          await OrgPickerController.setUserProfileForActiveOrg(closestAncestor);
          OrgSelectionUtil.updateOrgSelection(orgId);
          navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(endpoint));
        } catch (error) {
          redirectAndSetError(formatMessage(messages.CannotAccessThisOrg, { orgId })); // can not get ancestors for the org
        }
      }

      const isAdobeAgentWithNoOrgs = await AdminPermission.isAdobeAgentWithNoOrgs();
      if (endpoint === HeaderConsts.ORGANIZATIONS_URL) {
        if (isAdobeAgentWithNoOrgs) {
          setComponentToRender(<AdobeAgentWithNoOrgMessage />);
        } else {
          setComponentToRender(<Compartments />);
        }
      } else if (endpoint === HeaderConsts.PRODUCT_ALLOCATION_URL) {
        if (isAdobeAgentWithNoOrgs) {
          setComponentToRender(<AdobeAgentWithNoOrgMessage />);
        } else {
          setComponentToRender(<ProductAllocation />);
        }
      } else if (endpoint === HeaderConsts.JOB_EXECUTION_URL) {
        setComponentToRender(<JobExecution />);
      } else if (endpoint === HeaderConsts.INSIGHTS) {
        if (isAdobeAgentWithNoOrgs) {
          setComponentToRender(<AdobeAgentWithNoOrgMessage />);
        } else {
          setComponentToRender(<InsightsForGlobalAdmin />);
        }
      } else if (endpoint === HeaderConsts.SUPPORT) {
        if (isAdobeAgentWithNoOrgs) {
          setComponentToRender(<AdobeAgentWithNoOrgMessage />);
        } else {
          setComponentToRender(<SupportForGlobalAdmin />);
        }
      } else {
        setComponentToRender(<NotFound />); // should not happen!
      }
    })();
  }, [orgId, endpoint, history, setDeepLinkingError, renderCallback, formatMessage]);

  if (!_.isEmpty(deepLinkingError)) {
    return (
      <React.Fragment>
        <AlertBanner
          className="AlertBanner__DeepLinkParser"
          style={{ width: '100%' }}
          header={formatMessage(messages.Error)}
          variant="error"
          closeable
          closeTime={60000} // Close the alert banner after 1 minute
          onClose={(): void => {
            setDeepLinkingError('');
          }}
        >
          {/* show localized error message */}
          {deepLinkingError}
        </AlertBanner>
        {componentToRender}
      </React.Fragment>
    );
  }

  // no error, all good, render the component
  return componentToRender;
}

function DeepLinkParser(props: Omit<DeepLinkParserInterface, 'ref'>): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <DeepLinkParserInternal {...props} />
    </IntlProvider>
  );
}

export default DeepLinkParser;
