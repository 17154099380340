/**
 * Enumeration values for available environments in IMS
 *
 * Ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-id/IEnvironment.ts
 */
export enum Environment {
  /**
   * Stage environment
   */
  STAGE = 'stg1',
  /**
   * Prod environment
   */
  PROD = 'prod',
}

/**
 * interface for token property of AdobeIMS
 * as retrieved via getAccessToken()
 *
 * Ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-id/custom-types/CustomTypes.ts
 */
export interface TokenInformation {
  token: string;
  expire: Date;
  /**
   * represents the session identifier
   */
  sid: string;
  token_type?: string; // banyanui doesn't use this field as of this comment
}

/**
 * The AdobeId object that is placed on the window object
 * to initialize and configure IMS.
 *
 * Ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-id/IAdobeIdData.ts
 */
export interface AdobeId {
  client_id: string;
  scope: string;
  locale: string;
  environment: Environment;
  useLocalStorage: boolean;
  onAccessToken: (tokenInformation: TokenInformation) => void;
  onReauthAccessToken: (reauthTokenInformation: TokenInformation) => void;
  onError: (error: any, msg: string) => void; // Refer https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-id/IErrorType.ts for "error" type
  onAccessTokenHasExpired: () => void;
  onReady: (appState: any) => void; // Ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-id/custom-types/CustomThinTypes.ts
}

/**
 * Methods and properties of the IMS object
 *
 * For complete spec, refer https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-ims/AdobeIMS.ts
 */
export interface AdobeIMS {
  initialize(): void;
  isSignedInUser(): boolean;
  getProfile(): Promise<any>;
  avatarUrl(userId: string): string;
  getAccessToken(): TokenInformation | null;
  signOut(externalParameters?: object): void;
  signIn(externalParameters?: object, contextToBePassedOnRedirect?: any): void;
  switchProfile(userId: string, externalParameters?: object): Promise<any>;
  refreshToken(externalParameters?: object): Promise<any>;
  // Following methods are not used by banyanui as of this comment
  // getReauthAccessToken(): TokenInformation | null;
  // enableLogging(): void;
  // disableLogging(): void;
  // signUp(requestedParameters?: object, contextToBePassedOnRedirect?: any): void;
  // listSocialProviders(): Promise<any>;
  // authorizeToken(token?: string, externalParameters?: object, contextToBePassedOnRedirect?: any): void;
  // validateToken(): Promise<boolean>;
  // setStandAloneToken(standaloneToken: any): boolean;
  // reAuthenticate(requestedParameters?: object, reauth?: string, contextToBePassedOnRedirect?: any): void;
  // getTransitoryAuthorizationCode(tacRequest: any, externalParameters?: object): Promise<any>;
  // signInWithSocialProvider(providerName: string, externalParameters?: any, contextToBePassedOnRedirect?: any): void;
  // getReleaseFlags(): Promise<any>;
}

/**
 * UserProfile data (information about the user)
 * from the getUserProfile call of IMS.
 */
export interface UserProfile {
  email: string;
  name: string;
  roles: UserRole[];
  userId: string;
  authId: string;
  account_type: string;
  preferred_languages: string[] | null;
}

/**
 * UserRole data (information about a users role)
 */
export interface UserRole {
  named_role: string; // same as AdminRole but casing might not match
  organization: string;
  principal: string;
  target: string;
  target_data: object;
  target_type: string;
}

/**
 * Enumerates types of admins associated with orgs including elements within the org
 */
export enum OrgAdminType {
  ORG_ADMIN = 'ORG_ADMIN',
  COMPARTMENT_ADMIN = 'COMPARTMENT_ADMIN',
  COMPARTMENT_VIEWER = 'COMPARTMENT_VIEWER',
  DEPLOYMENT_ADMIN = 'DEPLOYMENT_ADMIN',
  LICENSE_ADMIN = 'LICENSE_ADMIN',
  PRODUCT_ADMIN = 'PRODUCT_ADMIN',
  USER_GROUP_ADMIN = 'USER_GROUP_ADMIN',
  STORAGE_ADMIN = 'STORAGE_ADMIN',
  SUPPORT_ADMIN = 'SUPPORT_ADMIN',
  CONTRACT_ADMIN = 'CONTRACT_ADMIN',
}
// The declaration for PRODUCT_ADMIN, LICENSE_ADMIN, and USER_GROUP_ADMIN may be causing sonarlint issues with the
// destructured declarations below.  This issue is fine because we are not redeclaring and are instead using the variables to remove enums from the OrgLevelAdminType.

// AdminTypeName is used to display a user friendly admin name.
// for e.g. "Add admin user vandegri@adobe.com in "DV ABC Corp/orgSc" with role Global Viewer"
export enum AdminTypeName {
  SYSTEM_ADMIN = 'System Admin',
  GLOBAL_ADMIN = 'Global Admin',
  GLOBAL_VIEWER = 'Global Viewer',
  PRODUCT_ADMIN = 'Product Admin',
  PRODUCT_PROFILE_ADMIN = 'Product Profile Admin',
  USER_GROUP_ADMIN = 'User Group Admin',
  DEPLOYMENT_ADMIN = 'Deployment Admin',
  SUPPORT_ADMIN = 'Support Admin',
  STORAGE_ADMIN = 'Storage Admin',
  CONTRACT_ADMIN = 'Contract Admin',
}

/**
 * Enumerates types of admins associated with orgs that only target orgs (org level) excluding the elements within the org
 * Note: Because OrgLevelAdminType is a subset of OrgAdminType its declared in 2 parts a type part and an object part.
 * The type part allows typing against the enumerated values.
 * The object part contains the enumerated values and allows operations against them.
 * Both of these parts are exported under a single name "OrgLevelAdminType"
 */
type NonOrgLevelAdminTypes =
  | typeof OrgAdminType.PRODUCT_ADMIN
  | typeof OrgAdminType.LICENSE_ADMIN
  | typeof OrgAdminType.USER_GROUP_ADMIN
  | typeof OrgAdminType.CONTRACT_ADMIN;
type OrgLevelAdminType = Exclude<OrgAdminType, NonOrgLevelAdminTypes>;
/* eslint-disable @typescript-eslint/no-redeclare */
// type OrgLevelAdminType is not really a redeclaration it is both declaring a type and an object for that type
const { PRODUCT_ADMIN, LICENSE_ADMIN, USER_GROUP_ADMIN, CONTRACT_ADMIN, ...OrgLevelAdminType } = OrgAdminType;
/* eslint-enable @typescript-eslint/no-redeclare */
export { OrgLevelAdminType };

/**
 * Enumerates types of admins not associated with orgs
 */
export enum AgentAdminType {
  ADOBE_AGENT_ADMIN = 'ADOBE_AGENT_ADMIN',
  ADOBE_AGENT_PROVISIONER = 'ADOBE_AGENT_PROVISIONER',
  ADOBE_AGENT_PROFESSIONAL_SERVICES = 'ADOBE_AGENT_PROFESSIONAL_SERVICES',
  ADOBE_AGENT_CUSTOMER_CARE = 'ADOBE_AGENT_CUSTOMER_CARE',
  ADOBE_AGENT_READ = 'ADOBE_AGENT_READ',
  ADOBE_AGENT_RESELLER_LICENSING = 'ADOBE_AGENT_RESELLER_LICENSING',
}

/**
 * Union of enumerations for all possible AdminTypes (associated with or without orgs)
 */
// Reference to admin roles listed in JIL: https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-organizations-entities/src/main/java/com/adobe/jil/organizations/entities/enums/AdminRoleType.java
export type AdminRole = AgentAdminType | OrgAdminType;

/**
 * Enumerates user type numbers.
 * Also see https://git.corp.adobe.com/bps/bps-platform/blob/master/bps-entities/src/main/java/com/adobe/bps/entities/jil/users/User.java
 */
export enum UserType {
  TYPE_ONE = 'TYPE1',
  TYPE_TWO = 'TYPE2',
  TYPE_TWOE = 'TYPE2E', // type2 enhanced
  TYPE_THREE = 'TYPE3',
}

/**
 * Enumerates the name of user types.
 */
export enum UserTypeName {
  ADOBE_ID = 'Adobe ID',
  BUSINESS_ID = 'Business ID',
  ENTERPRISE_ID = 'Enterprise ID',
  FEDERATED_ID = 'Federated ID',
}

/**
 * Typed window object specific to authentication.
 * Declares the properties necessary to be attached to the window for authentication.
 *   - adobeid is to be added to the window object to configure IMS.  It is optional because
 *     it is undefined until added and doesn't need to be assigned if IMS won't be used.
 *   - adobeIMS should exist on the window object after logging in, but won't be attached
 *     to the window object until that happens.
 */
export class AuthWindow extends Window {
  adobeid?: AdobeId;
  adobeIMS?: AdobeIMS;
  aptrinsic: any; // gainsight's function to send user id
  console: any;
  digitalData: any; // for sending login information like org name to OMEGA (Adobe Analytics)
}
