import { SelectOption } from '@react/react-spectrum/Select';
import * as _ from 'lodash';
import * as log from 'loglevel';
import React from 'react';
import OrgReparentProductsErrorMessages from '../../components/OrgReparentProductsMessages/OrgReparentProductsErrorMessages';
import AdminPermission from '../../services/authentication/AdminPermission';
import { CommandService } from '../../services/Commands/CommandService';
import CommandInterface from '../../services/Commands/CommandInterface';
import OrgInfo from '../../services/organization/OrgInfo';
import OrgInfoService from '../../services/organization/OrgInfoService';
import { ObjectTypes, OrgOperation } from '../../services/orgMaster/OrgMaster';
import { UOrgData } from '../../services/orgMaster/UOrg';
import OrgReparentProductsCheckService from '../../services/reparent/OrgReparentProductsCheckService';
import {
  OrgReparentProductsReport,
  OrgReparentProductsReportUtils,
  OrgReparentSharedGroupsReport,
  OrgReparentSharedGroupsReportUtils,
  ReparentIds,
} from '../../services/reparent';
import AdobeAgentPaths from '../components/AdobeAgentPaths';
import TempIdGenerator from '../../services/utils/TempIdGenerator';
import { UContractSwitchData } from '../../services/orgMaster/UContractSwitch';
import UserGroupSharingService from '../../providers/UserGroupSharingService';
import { OrgReparentSharedGroupsErrorMessages } from '../../components/OrgReparentSharedGroupsErrorMessages';
import OrgReparentSharedGroupsCheckProvider from '../../providers/OrgReparentSharedGroupsCheckProvider';

class OrgHierarchyHelper {
  /**
   * 1) Validate that the 'selectedOrgs' and 'selectedParentOrgs' are not read only orgs
   * 2) Validate user has GA permissions on the orgs
   * 3) Validate org name is globally unique when reparenting to standalone
   * 4) Validate reparent with product related issues
   *
   * NOTE: 'selectedParentOrgs' must have a single org. All other orgs are ignored.
   *
   * @param selectedOrgs selected child orgs
   * @param selectedParentOrgs selected parent org
   * @returns error messages as ReactNode elements in case of any errors else undefined
   */
  static async validateOrgAndUserPermissions(
    selectedOrgs: SelectOption[],
    selectedParentOrgs?: SelectOption[],
    searchedChildOrgsByECCIDOnly?: boolean
  ): Promise<React.ReactNode> {
    const orgsToReparentIds: string[] = _.map(selectedOrgs, (orgOption: SelectOption): string => orgOption.value);

    // since there is only at most 1 selected parent we are retrieving from index 0 of selectedParentOrgs (if there is no parent, destinationOrg will be undefined)
    const destinationOrgId: string | undefined =
      !_.isEmpty(selectedParentOrgs) && selectedParentOrgs ? selectedParentOrgs[0].value : undefined;

    const orgsToRequestForInfo: string[] = [...orgsToReparentIds];
    // only retrieve information for destinationOrgId if we aren't re-parenting to standalone
    if (destinationOrgId) {
      orgsToRequestForInfo.push(destinationOrgId);
    }

    let infoForOrgs: OrgInfo[] = [];
    try {
      infoForOrgs = await OrgInfoService.getReparentInfo(orgsToRequestForInfo, !destinationOrgId);
    } catch (error) {
      log.error('failed to load org info to verify org re-parent');
      return <p>An error occurred while validating reparenting request</p>;
    }
    const infoForOrgsToReparent: OrgInfo[] = _.filter(
      infoForOrgs,
      (info: OrgInfo): boolean => info.orgId !== destinationOrgId
    );
    const infoForDestinationOrg: OrgInfo | undefined = _.find(
      infoForOrgs,
      (info: OrgInfo): boolean => info.orgId === destinationOrgId
    );

    // check that standalone orgs-to-reparent have either global admin or system admin permissions
    // check that non-standalone orgs-to-reparent and the destination-org have global admin permissions

    // find all orgs that lack RW GA permissions and are either non-standalone orgs or is the destination-org
    const orgsLackingGAPermission: string[] = _.map(
      _.filter(
        infoForOrgs,
        (info: OrgInfo): boolean =>
          !info.hasRWGAPermission && (!_.isEmpty(info.parentOrgId) || info.orgId === destinationOrgId)
      ),
      (info: OrgInfo): string => info.orgId
    );

    // find all orgs that are standalone and lack both Global Admin permissions and System Admin permissions
    // VERY IMPORTANT: When an Adobe Agent searches for orgs using ECC ID, do NOT check for Sys or Global admin permissions of the user on the child org.
    // As per BANY1502, Adobe Agents need not be Sys or Global admins of the child org to perform org mapping if a matching ECC ID is found
    // between child and parent org contracts. The check for if a matching ECC ID is present between contracts is performed in the backend inside
    // OrganizationCommandExecutor::reparentInteranal.
    // UI should allow the command to be submitted without any checks

    // when user searched by ECC Id and feature flag is enabled, set standaloneOrgsLackingPermission=[] (meaning no child orgs lack Sys or Global admin permissions)
    let standaloneOrgsLackingPermission: string[] = [];
    if (!searchedChildOrgsByECCIDOnly) {
      for (const info of infoForOrgs) {
        if (
          !info.parentOrgId &&
          !info.hasRWGAPermission &&
          !(await AdminPermission.isSystemAdminOfOrg(info.orgId)) &&
          info.orgId !== destinationOrgId
        ) {
          standaloneOrgsLackingPermission.push(info.orgId);
        }
      }
    }
    // if there are any orgs listed that are lacking permissions, render the error messages listed the effected orgs
    if (!_.isEmpty(orgsLackingGAPermission) || !_.isEmpty(standaloneOrgsLackingPermission)) {
      const errorMessageElem: React.ReactNode = (
        <div>
          {!_.isEmpty(orgsLackingGAPermission) && (
            <div>
              You lack global admin permissions on the following orgs
              {_.map(
                orgsLackingGAPermission,
                (orgId: string): React.ReactNode => (
                  <div key={orgId}>{orgId}</div>
                )
              )}
            </div>
          )}
          {!_.isEmpty(standaloneOrgsLackingPermission) && (
            <div>
              You lack either global admin or system admin permissions on the following orgs
              {_.map(
                standaloneOrgsLackingPermission,
                (orgId: string): React.ReactNode => (
                  <div key={orgId}>{orgId}</div>
                )
              )}
            </div>
          )}
        </div>
      );
      return errorMessageElem;
    }

    // check whether the destination-org is read-only (read-only orgs cannot have children)
    if (infoForDestinationOrg?.isReadOnlyOrg) {
      return <div>The parent org {destinationOrgId} is read-only and cannot have children reparented to it</div>;
    }

    // check whether the orgs being reparented to standalone all have globally unique names
    let orgsWithoutGloballyUniqueNames: string[] = [];
    if (!destinationOrgId) {
      orgsWithoutGloballyUniqueNames = _.map(
        _.filter(infoForOrgs, (info: OrgInfo): boolean => info.isNameGloballyUnique === false),
        (info: OrgInfo): string => info.orgId
      );
      if (!_.isEmpty(orgsWithoutGloballyUniqueNames)) {
        return (
          <div>
            The following orgs must have globally unique names before becomming standalone
            {_.map(
              orgsWithoutGloballyUniqueNames,
              (orgId: string): React.ReactNode => (
                <div key={orgId}>{orgId}</div>
              )
            )}
          </div>
        );
      }
    }

    // check re-parent with products and user groups related issues
    const multipleReparentIds: ReparentIds[] = _.map(
      orgsToReparentIds,
      (orgId: string): ReparentIds => ({ orgToReparentId: orgId, destinationOrgId: destinationOrgId || '' })
    );

    return await this.generateReparentReport(multipleReparentIds, infoForOrgsToReparent);
  }

  public static generateReparentReport = async (
    multipleReparentIds: ReparentIds[],
    infoForOrgsToReparent: OrgInfo[]
  ): Promise<JSX.Element | JSX.Element[] | undefined> => {
    let productsReport: OrgReparentProductsReport | undefined;
    let sharedGroupsReport: OrgReparentSharedGroupsReport | undefined;
    try {
      // direct allocated products are needed regardless of if re-parent with products is enabled or disabled
      // if there are no directly allocated products (products that would be re-parented), then we don't need to check for issues or restriction regarding re-parent with products
      // check for issues or user info regarding re-parent with products
      if (_.some(infoForOrgsToReparent, (info: OrgInfo): boolean => info.hasDirectAllocations === true)) {
        productsReport = await OrgReparentProductsCheckService.multipleOrgReparentProductsCheck(multipleReparentIds);
      }

      const multipleReparentIdsWithSharedGroups: ReparentIds[] = [];
      for (let reparentIds of multipleReparentIds) {
        let isUserGroupSharingEnabled = await UserGroupSharingService.hasSharedUserGroupsFeature(
          reparentIds.orgToReparentId
        );

        if (isUserGroupSharingEnabled) {
          multipleReparentIdsWithSharedGroups.push(reparentIds); // only check orgs that have user group sharing enabled
        }
      }

      // check that the reparent is valid
      if (!_.isEmpty(multipleReparentIdsWithSharedGroups)) {
        sharedGroupsReport = await OrgReparentSharedGroupsCheckProvider.orgReparentSharedGroupsCheck(
          multipleReparentIdsWithSharedGroups
        );
      }
    } catch (error) {
      log.error('Failed to load reparent with products or user groups reports.', error);
      return <p>An error occurred while validating reparenting request</p>;
    }

    const errorReports = [];

    if (productsReport && OrgReparentProductsReportUtils.hasErrors(productsReport)) {
      errorReports.push(<OrgReparentProductsErrorMessages report={productsReport} />);
    }
    if (sharedGroupsReport && OrgReparentSharedGroupsReportUtils.hasErrors(sharedGroupsReport)) {
      errorReports.push(<OrgReparentSharedGroupsErrorMessages report={sharedGroupsReport} />);
    }

    if (!_.isEmpty(errorReports)) {
      return errorReports;
    }

    return undefined;
  };

  public static generateAndAddOrgUpdateCommand = (
    selectedPath: AdobeAgentPaths,
    selectedOrgs: SelectOption[],
    selectedParentOrgs?: SelectOption[]
  ): void => {
    /*
     * *** Make Child Org Standalone ***
     * make the parentOrgId blank for all the child orgs
     */
    // Add to the Commands
    selectedOrgs.forEach((org): void => {
      const orgName = org.label as string;
      const parentOrgName =
        selectedParentOrgs && selectedParentOrgs.length > 0 ? (selectedParentOrgs[0].label as string) : undefined;
      const elem: UOrgData = {
        // elem is a UOrgData type
        id: org.value,
        parentOrgId:
          selectedPath === AdobeAgentPaths.MoveStandaloneOrgsIntoAHeirarchy && selectedParentOrgs
            ? selectedParentOrgs[0].value
            : '',
        name: orgName,
        parentOrgName,
      };
      const command: CommandInterface = {
        elem,
        elemType: ObjectTypes.ORGANIZATION,
        operation: OrgOperation.UPDATE,
        lastUpdatedAt: new Date().getTime(),
        messageData: {
          cmdDescription: [
            {
              cmdDescriptionCode: 'REPARENT_ORG',
              cmdDescriptionParams: [orgName, parentOrgName || ''],
            },
          ],
        },
      };
      CommandService.addEditForOrgMapper(command);

      if (selectedPath === AdobeAgentPaths.MoveStandaloneOrgsIntoAHeirarchy) {
        const NEW_CONTRACT_ID = TempIdGenerator.getTempIdAndIncrement();
        const contractSwitchCommand = OrgHierarchyHelper.generateContractSwitchCommand(
          NEW_CONTRACT_ID,
          org.value,
          orgName,
          true
        );
        // NOTE: this command will convert an eligible ETLA contract on the child org to an allocation contract
        // and allocate products from Parent org. Please see: https://wiki.corp.adobe.com/display/BANY/Converting+ETLA+contracts+to+enable+allocations#ConvertingETLAcontractstoenableallocations-SupportforGlobalAdmins
        CommandService.addEditForOrgMapper(contractSwitchCommand);
      }
    });
  };

  public static generateContractSwitchCommand = (
    contractId: string,
    orgId: string,
    orgName: string,
    verboseErrorMessage: boolean
  ): CommandInterface => {
    const elem: UContractSwitchData = {
      orgName,
      orgId,
      id: contractId,
      verboseErrorMessage, // setting verboseErrorMessage: false will make banyan svc return a generic error message for contract switch
    };
    return {
      elem,
      elemType: ObjectTypes.CONTRACT_SWITCH,
      operation: OrgOperation.CREATE,
      lastUpdatedAt: new Date().getTime(),
      messageData: {
        cmdDescription: [
          {
            cmdDescriptionCode: 'CONVERT_ETLA_CONTRACT',
            cmdDescriptionParams: [elem.orgName],
          },
        ],
      },
    };
  };

  /**
   * Add elements in 'newElements' to the 'originalList' if not already present.
   */
  public static addSelectedValues(orginalList: SelectOption[], newElements: SelectOption[]): SelectOption[] {
    _.forEach(newElements, (newSelectedOrg: SelectOption): void => {
      if (!_.find(orginalList, (selectedOrg): boolean => selectedOrg.value === newSelectedOrg.value)) {
        orginalList.push(newSelectedOrg);
      }
    });
    return orginalList;
  }

  /**
   * removes the elements from the 'originallist'
   */
  public static removeSelectedValues(orginalList: SelectOption[], toBeRemovedOrgs: string[]): SelectOption[] {
    // Remove the Orgs that are unselected by the user
    _.forEach(toBeRemovedOrgs, (toBeRemovedOrg: string): void => {
      // eslint-disable-next-line no-param-reassign
      orginalList = _.remove(orginalList, (org: SelectOption): boolean => org.value !== toBeRemovedOrg);
    });
    return orginalList;
  }
}

export default OrgHierarchyHelper;
