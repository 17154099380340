import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import './AddProductDialog.css';
import * as _ from 'lodash';
import { Column } from 'primereact/column';
import Dialog from '@react/react-spectrum/Dialog';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { Grid, GridColumn, GridRow } from '@react/react-spectrum/Grid';
import Textfield from '@react/react-spectrum/Textfield';
import { TreeTable } from 'primereact/treetable';

import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import EditNumberInput from '../EditNumberInput/EditNumberInput';
import { ObjectTypes, OrgOperation, CAP_UNLIMITED } from '../../../services/orgMaster/OrgMaster';
import { ResourceCalculationUtils } from '../../services/calculation/ResourceCalculation';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource } from '../../../services/orgMaster/UResource';
import { ExpandedNodes } from '../../../services/treeTableUtils/ExpandedNodesUtil';
import Analytics from '../../../Analytics/Analytics';
import TempIdGenerator from '../../../services/utils/TempIdGenerator';
import HierarchyManager from '../../../services/organization/HierarchyManager';
import { CommandService } from '../../../services/Commands/CommandService';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';
import Utils from '../../../services/utils/Utils';

const localeMessages = defineMessages({
  save: {
    id: 'productAllocation.addProduct.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'productAllocation.addProduct.cancel',
    defaultMessage: 'Cancel',
  },
  placeholderMinValue: {
    id: 'productAllocation.addProduct.input.placeholderMinValue',
    defaultMessage: 'Value cannot be less than {min}',
  },
});

// represents the info about the ancestor product
export interface AncestorProductInfo {
  ancestorProduct: UProduct; // the ancestor product
  orgPathFromAncestorProduct: UOrgMaster[]; // path from the root org to the org containing that product
}

interface AddProductDialogProps {
  orgList: UOrgMaster[];
  rootProduct: UProduct; // root product selected in the product allocation page, which we are trying to add
  compartmentId: string; // current orgId
  update: () => void; // callback to the Product Allocation component
}

function AddProductDialogInternal(props: AddProductDialogProps): JSX.Element {
  const intl = useIntl();
  const { formatMessage } = intl;
  const compartment = useRef<UOrgMaster>(HierarchyManager.getOrg(props.compartmentId) as UOrgMaster).current; // current org

  /**
   * Recursively get the data in the format which needs to be fed to the treetable for display
   * @param orgPathFromAncestorProduct
   * @param index
   */
  const getTreeTableData = (orgPathFromAncestorProduct: UOrgMaster[], index: number): TreeNode => {
    const currentOrg = orgPathFromAncestorProduct[index];
    let childArray: TreeNode[] = [];
    if (index + 1 < orgPathFromAncestorProduct.length) {
      childArray = [getTreeTableData(orgPathFromAncestorProduct, index + 1)];
    }
    return {
      key: currentOrg.organization.id,
      data: {
        name: currentOrg.organization.name,
      },
      children: childArray,
    };
  };

  /**
   * finds the path back from the root Org to the current org by tracing backwards.
   */
  const findOrgPathFromRoot = (): UOrgMaster[] => {
    const orgPath: UOrgMaster[] = [];
    let currentOrg: UOrgMaster | undefined = compartment;
    while (currentOrg) {
      orgPath.push(currentOrg);
      currentOrg = HierarchyManager.getOrg(currentOrg.organization.parentOrgId);
    }
    return _.reverse(orgPath);
  };

  /**
   * finds the next immediate parent product of the product to be added for a given org.
   * returns the ancestor product and the path from root org upto the org containing the ancestor product.
   */
  const findAncestorProduct = (): AncestorProductInfo => {
    const pathFromRoot = findOrgPathFromRoot(); // path from root org to current org

    let ancestorProd: UProduct = props.rootProduct; // product selected in dropdown
    let ancestorProdIndex = _.findIndex(
      pathFromRoot,
      (org: UOrgMaster): boolean => org.organization.id === ancestorProd.orgId
    );
    if (ancestorProdIndex < 0) {
      // No product was found in pathFromRoot that is an _exact_ match for the dropdown's selected product.
      // (The dropdown's selected product must be in another branch). Fallback to finding a _similar_ product in pathFromRoot.
      ancestorProdIndex = _.findIndex(pathFromRoot, (org: UOrgMaster): boolean => {
        // Does this org have a similar product?
        const matchingProduct = _.find(org.products, (prod: UProduct) => {
          return prod.isMatchingProduct(props.rootProduct);
        });
        return !!matchingProduct;
      });
    }
    let updatedOrgPathFromAncestorProduct: UOrgMaster[] = pathFromRoot;
    const maxLength = pathFromRoot.length;

    if (ancestorProdIndex < 0) {
      throw Error('Product does not exist in hierarchy');
    }

    for (let count = ancestorProdIndex; count < maxLength; count++) {
      let result: UProduct | undefined = _.find(pathFromRoot[count].products, ['sourceProductId', ancestorProd.id]);
      // if no product is found by sourceProductId, then check the case for purchase products
      if (!result) {
        const purchaseResult = _.find(pathFromRoot[count].products, (orgProduct: UProduct): boolean => {
          return orgProduct.isMatchingProduct(props.rootProduct);
        });
        if (purchaseResult && purchaseResult.isPurchase()) {
          result = purchaseResult;
        }
      }
      if (result) {
        ancestorProd = result;
        ancestorProdIndex = count;
      }
    }
    updatedOrgPathFromAncestorProduct = _.slice(pathFromRoot, ancestorProdIndex + 1, maxLength);
    return {
      ancestorProduct: ancestorProd,
      orgPathFromAncestorProduct: updatedOrgPathFromAncestorProduct,
    };
  };

  /**
   * Initialize finalAncestorProductForValues as a clone of an ancestor product with granted quantity values set to empty string
   */
  const initFinalAncestorProductForValues = (ancestorProduct: UProduct): UProduct => {
    const finalAncestorProductForValues: UProduct = _.cloneDeep(ancestorProduct);
    const resources: UResource[] = ResourceCalculationUtils.getSortedQuotaResources(finalAncestorProductForValues);
    _.forEach(resources, (res: UResource): void => {
      if (res.grantedQuantity !== 'UNLIMITED') {
        res.grantedQuantity = '';
      }
      res.provisionedQuantity = 0;
    });
    return finalAncestorProductForValues;
  };

  /**
   * Initialize expanded nodes to all be expanded
   */
  const initExpandedNodes = (orgPath: UOrgMaster[]): ExpandedNodes => {
    const expandedNodes: ExpandedNodes = {};
    _.forEach(orgPath, (org: UOrgMaster): void => {
      expandedNodes[org.id] = true;
    });
    return expandedNodes;
  };

  const ancestorProductInfo = useRef<AncestorProductInfo>(findAncestorProduct()).current; // stores the info about the ancestor product
  const finalAncestorProductForDisplay = useRef<UProduct>(ancestorProductInfo.ancestorProduct).current; // the actual ancestor product model
  const { orgPathFromAncestorProduct } = useRef<AncestorProductInfo>(ancestorProductInfo).current; // (destructure) path from root org to org containing ancestor product
  const finalAncestorProductForValues = useRef<UProduct>(
    initFinalAncestorProductForValues(finalAncestorProductForDisplay)
  ).current; // clone of the actual ancestor product model (with quota resource granted quantities cleared)
  const quotaResourcesForDisplay = useRef<UResource[]>(
    ResourceCalculationUtils.getSortedQuotaResources(finalAncestorProductForDisplay)
  ).current; // quota resources list for rendering
  const quotaResourcesForValues = useRef<UResource[]>(
    ResourceCalculationUtils.getSortedQuotaResources(finalAncestorProductForValues)
  ).current;
  const treeTableData = useRef<TreeNode>(getTreeTableData(orgPathFromAncestorProduct, 0)).current; // data fed into treetable display

  const hasEmptyInputs = (): boolean => {
    let emptyInput = false;
    _.forEach(quotaResourcesForValues, (res: UResource): void => {
      if (res.grantedQuantity !== 'UNLIMITED') {
        if (res.grantedQuantity === '') {
          emptyInput = true;
        }
      }
    });
    return emptyInput;
  };

  // initialize state
  const [saveDisabled, setSaveDisabled] = useState(hasEmptyInputs()); // allow confirm in situations where there is no editable grant
  const [expandedNodes, setExpandedNodes] = useState<ExpandedNodes>(initExpandedNodes(orgPathFromAncestorProduct)); // map of orgId->boolean. every branch should be expanded.

  /**
   * When the save button is clicked by the user, we add the new product to all the needed orgs
   */
  const onSave = (): void => {
    Analytics.fireCTAEvent('add product dialog save clicked');
    Analytics.fireAddProductEvent(props.rootProduct.name);
    let ancestorProduct = finalAncestorProductForValues;
    let sourceProductId = finalAncestorProductForValues.id;
    let orgWeAreAddingTo;
    let count = 0;
    // add the products in all the needed parent orgs and the current org
    while (count < orgPathFromAncestorProduct.length) {
      const productToBeAdded = _.cloneDeep(ancestorProduct);
      orgWeAreAddingTo = orgPathFromAncestorProduct[count];
      productToBeAdded.productProfiles = [];
      productToBeAdded.orgId = orgWeAreAddingTo.id;
      productToBeAdded.sourceProductId = sourceProductId;
      productToBeAdded.setId(TempIdGenerator.getTempIdAndIncrement());
      productToBeAdded.profilesLoaded = true;
      productToBeAdded.orgId = orgWeAreAddingTo.id;
      // For child license, initial value of overuse policy will be as follows:
      // 1. true (i.e. on), if overuse policy is "customer_controllable" in offer,
      // (Why? We can infer true because an offer must include "overdelegation_allowed" FI in order to set it to "customer_controllable" PI data. i.e. it's impossible to have customer_controllable PI and not have overdelegation_allowed FI).
      // 2. Else same as that of parent,
      // (Why? Because if overuse is NOT "customer_controllable", we know that new allocations should have the same, unchangeable policy value as its parent).
      productToBeAdded.allowExceedUsage =
        productToBeAdded.productAttributes.overUsePolicyConfigurable || ancestorProduct.allowExceedUsage;
      orgWeAreAddingTo.products.push(productToBeAdded);
      sourceProductId = productToBeAdded.id;
      ancestorProduct = productToBeAdded;
      count++;
      CommandService.addEdit(
        orgWeAreAddingTo,
        productToBeAdded,
        ObjectTypes.PRODUCT,
        OrgOperation.CREATE,
        undefined,
        'ADD_PRODUCT',
        [productToBeAdded.name, CmdDescriptionUtils.getPathname(productToBeAdded.orgId)]
      );
    }
    props.update();
  };

  /**
   * Check if any resource input is empty.
   */
  const checkForEmptyInputs = (): void => {
    setSaveDisabled(hasEmptyInputs());
  };

  /**
   * take in the user input resource quantity value every time it is changed and store it.
   * Also check for any empty resource inputs.
   * @param resource
   * @param newVal
   */
  const onResourceWithQuantityChanged = (resource: UResource, newVal: string): void => {
    const indexOfResourceInArray = _.findIndex(quotaResourcesForValues, ['code', resource.code]);
    if (Number.isNaN(_.parseInt(newVal, 10))) {
      quotaResourcesForValues[indexOfResourceInArray].grantedQuantity = '';
    } else {
      quotaResourcesForValues[indexOfResourceInArray].grantedQuantity = newVal;
    }
    checkForEmptyInputs();
  };

  /**
   * returns the available resource quantity for a particular resource of the ancestor product.
   * If the resourceCalculationMap is undefined for reasons when the parent product could not be matched with sourceProductId(happens if its a purchased product),
   * we show the granted quantity as the available quantity.
   * @param res
   */
  const getAvailableQuantity = (res: UResource): string => {
    const resourceCalculationMap = ResourceCalculationUtils.populateResourceUsages(
      props.orgList,
      finalAncestorProductForValues,
      res
    );
    const resourceCalculationData = resourceCalculationMap.get(finalAncestorProductForValues.orgId);
    if (resourceCalculationData) {
      const avlQtty = resourceCalculationData.localLicensedQuantity;
      if (avlQtty) {
        return avlQtty.toString();
      }
    }
    return res.grantedQuantity;
  };

  const renderResourceGrid = (): React.ReactNode => {
    // auto focus the first editable resource
    let autoFocusResource: boolean = true;
    return _.map(quotaResourcesForDisplay, (resource: UResource): React.ReactNode => {
      let elem;
      if (resource.grantedQuantity === 'UNLIMITED') {
        elem = (
          <Textfield
            className="AddProductDialog__ResourceText"
            disabled
            placeholder={Utils.localizedUnlimited()}
            aria-labelledby="productAllocation.addProduct.input.description"
            data-testid={`${resource.unit}-add-product-dialog-grant-text`}
          />
        );
      } else {
        let minVal;
        if (UResource.isSeatResource(resource.code)) {
          minVal = 1;
        } else {
          minVal = 0;
        }
        elem = (
          <EditNumberInput
            className="AddProductDialog__ResourceInput"
            min={minVal}
            max={500000}
            placeholder={formatMessage(localeMessages.placeholderMinValue, { min: minVal })}
            onChange={(value: string): void => onResourceWithQuantityChanged(resource, value)}
            onFocus={(): void => {
              // unset validation state on focus
              checkForEmptyInputs();
            }}
            labelledby="productAllocation.addProduct.input.description"
            data-testid={`${resource.unit}-add-product-dialog-grant-input`}
            autoFocus={autoFocusResource}
          />
        );
        // other resources should not be auto focused
        autoFocusResource = false;
      }
      const avlQuantity = getAvailableQuantity(resource);
      return (
        <Grid key={resource.code}>
          <GridRow>
            <GridColumn>
              <span className="AddProductDialog__ResourceName">{Utils.localizedResourceName(resource.name())}</span>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              <div>{elem}</div>
            </GridColumn>
            <GridColumn className="AddProductDialog__UnitColumn">
              <span data-testid={`${resource.unit}-add-product-dialog-grant-label`}>
                {Utils.localizedResourceUnit(resource.unit)} (
                {_.isEqual(avlQuantity, CAP_UNLIMITED) ? (
                  <FormattedMessage
                    id="productAllocation.addProduct.input.unlimitedAvailable"
                    defaultMessage="UNLIMITED available"
                  />
                ) : (
                  <FormattedMessage
                    id="productAllocation.addProduct.input.available"
                    defaultMessage="{value} available"
                    values={{ value: avlQuantity }}
                  />
                )}
                )
              </span>
            </GridColumn>
          </GridRow>
          <br />
        </Grid>
      );
    });
  };

  return (
    <Dialog
      className="AddProductDialog__container"
      title={
        <div className="AddProductDialog__title" data-testid="add-product-dialog-title">
          <FormattedMessage
            id="productAllocation.addProduct.title"
            defaultMessage="Adding {productName} to {orgName}"
            values={{
              productName: finalAncestorProductForValues.name,
              orgName: compartment.organization.name,
            }}
          />
        </div>
      }
      confirmLabel={formatMessage(localeMessages.save)}
      onCancel={(): void => Analytics.fireCTAEvent('add product dialog canceled')}
      onConfirm={onSave}
      confirmDisabled={saveDisabled}
      cancelLabel={formatMessage(localeMessages.cancel)}
      {...props}
      role="dialog"
      data-testid="add-product-dialog"
    >
      <span data-testid="add-product-dialog-content">
        <FormattedMessage
          id="productAllocation.addProduct.input.description"
          defaultMessage="Enter the resource values you would like to allocate:"
        />
        <br />
        <br />
        <div>{renderResourceGrid()}</div>
        <hr />
        {orgPathFromAncestorProduct.length === 1 ? (
          // Display just a single org
          <>
            <br />
            <span data-testid="add-product-dialog-orgname">
              <FormattedMessage
                id="productAllocation.addProduct.singleprod.description"
                defaultMessage="{product} will be added to the org {org}."
                values={{
                  product: props.rootProduct.name,
                  org: orgPathFromAncestorProduct[0].organization.name,
                }}
              />
            </span>
          </>
        ) : (
          // Display whole treey to be allocated
          <>
            <FormattedMessage
              id="productAllocation.addProduct.tree.description"
              defaultMessage="In order to entitle the current organization, the product will be added to the following organizations:"
            />
            <div className="AddProductDialog__TreeTable" data-testid="add-product-dialog-treetable">
              <TreeTable
                value={[treeTableData]}
                onToggle={(e: { originalEvent: Event; value: ExpandedNodes }): void => {
                  setExpandedNodes(e.value);
                }}
                expandedKeys={expandedNodes}
              >
                <Column field="name" expander className="AddProductDialog__TreeTableColumn" />
              </TreeTable>
            </div>
          </>
        )}
      </span>
    </Dialog>
  );
}

function AddProductDialog(props: Omit<AddProductDialogProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <AddProductDialogInternal {...props} />
    </IntlProvider>
  );
}

export default AddProductDialog;
