import { ContentEntryProvider, defineContents } from '@pandora/react-content-provider';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PageNavContentModel, PageSizeSelectorContentModel } from '@pandora/react-pagination';
import { LocaleSettings } from '../services/locale/LocaleSettings';

// construct the model for pagination content strings for Pandora components with localization and pass it to the
// content entry provider
const PandoraPaginationContentProvider = ({ children }: any) => {
  const { formatMessage } = useIntl();
  const messages = defineMessages({
    PandoraPageNavNext: {
      id: 'Pandora.PageNav.next',
      defaultMessage: 'Next',
    },
    PandoraPageNavNextPage: {
      id: 'Pandora.PageNav.nextPage',
      defaultMessage: 'Next Page',
    },
    PandoraPageNavPagination: {
      id: 'Pandora.PageNav.pagination',
      defaultMessage: 'Page navigation',
    },
    PandoraPageNavPrevious: {
      id: 'Pandora.PageNav.previous',
      defaultMessage: 'Previous',
    },
    PandoraPageNavPreviousPage: {
      id: 'Pandora.PageNav.previousPage',
      defaultMessage: 'Previous page',
    },
    PandoraPageSizeSelector: {
      id: 'Pandora.PageSizeSelector.itemsPerPage',
      defaultMessage: 'Items per page',
    },
  });

  const pandoraContentModel = defineContents('PandoraContentModel', {
    pageNavContent: {
      id: 'pageNavContent',
      type: PageNavContentModel,
    },
    pageSizeSelectorContent: {
      id: 'pageSizeSelectorContent',
      type: PageSizeSelectorContentModel,
    },
  });

  const pandoraContentValue = {
    pageNavContent: {
      next: formatMessage(messages.PandoraPageNavNext),
      nextPage: formatMessage(messages.PandoraPageNavNextPage),
      pagination: formatMessage(messages.PandoraPageNavPagination),
      previous: formatMessage(messages.PandoraPageNavPrevious),
      previousPage: formatMessage(messages.PandoraPageNavPreviousPage),
    },
    pageSizeSelectorContent: {
      itemsPerPage: formatMessage(messages.PandoraPageSizeSelector),
    },
  };

  return (
    <ContentEntryProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      model={pandoraContentModel}
      value={pandoraContentValue as any}
    >
      {children}
    </ContentEntryProvider>
  );
};

export default PandoraPaginationContentProvider;
