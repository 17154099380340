import * as _ from 'lodash';
import { OrgReparentProductsReportUtils } from './OrgReparentProductsReportUtils';
import OrgReparentProductsCheckProvider from '../../providers/OrgReparentProductsCheckProvider';
import { OrgReparentProductsReport, ReparentIds } from './types';

class OrgReparentProductsCheckService {
  /**
   * Check for issues or user info in re-parenting products for multiple org reparents
   * (This method is primarily for the OrgMigrationForAdobeAgent page)
   */
  static async multipleOrgReparentProductsCheck(
    multipleReparentIds: ReparentIds[]
  ): Promise<OrgReparentProductsReport> {
    const MAX_REQUESTS = 100; // banyansvc reparentProductsCheck has a limit of 100 pairs of org-to-reparent ids and destination-org ids

    // break apart reparentIds into groups
    const groupedReparentIds: ReparentIds[][] = _.chunk(multipleReparentIds, MAX_REQUESTS);
    let report: OrgReparentProductsReport = OrgReparentProductsReportUtils.emptyOrgReparentProductsReport();
    const promises: Promise<OrgReparentProductsReport>[] = [];

    // for each group call provider (so we don't go over the request limit)
    _.forEach(groupedReparentIds, (reparentIdsGroup: ReparentIds[]): void => {
      promises.push(OrgReparentProductsCheckProvider.orgReparentProductsCheck(reparentIdsGroup));
    });

    // merge all results into one single report
    const results: OrgReparentProductsReport[] = await Promise.all(promises);
    _.forEach(results, (currentReport: OrgReparentProductsReport): void => {
      report = OrgReparentProductsReportUtils.mergeReports(report, currentReport);
    });
    return report;
  }

  /**
   * Check for issues or user info in re-parenting products for a single org re-parent
   * (This method is primarily for the OrgTree)
   */
  static async orgReparentProductsCheck(
    orgToReparentId: string,
    destinationOrgId: string
  ): Promise<OrgReparentProductsReport> {
    return await OrgReparentProductsCheckProvider.orgReparentProductsCheck([{ orgToReparentId, destinationOrgId }]);
  }
}
export default OrgReparentProductsCheckService;
