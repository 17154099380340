import { BanyanToastType } from '../../components/BanyanToast/BanyanToastType';
import ToastList from '../../components/BanyanToast/ToastList';

/**
 * Service for displaying a toast message,
 * Note: You must have a ToastList element in the html, this service
 * then controls it handles when it is displayed.
 */

class ToastMaster {
  private static toastList: ToastList | undefined; // ToastList controlled by ToastMaster

  /**
   * Sets a toastList to be controlled by the ToastMaster
   * Note: A ToastList component attaches itself automatically to the ToastMaster.
   * Note: Once attached, you cannot attach another toast list. (This means you can only have 1 ToastList element)
   */
  static attachToastList(toastList: ToastList): void {
    if (!ToastMaster.toastList && toastList) {
      ToastMaster.toastList = toastList;
    } else {
      throw new Error('ToastList already attached to ToastMaster.  Cannot attach more than once');
    }
  }

  static detachToastList(): void {
    ToastMaster.toastList = undefined;
  }

  /**
   * Displays a toast
   *  - toastMessage: The message to display in the toast.
   *  - toastType: The display type for the toast (error, info, etc) (Default: info).
   *  - showTime: How long to display the toast for in milliseconds (Default: 10 seconds).
   */
  static showToast(toastMessage: string, toastType: BanyanToastType, showTime?: number): void {
    if (ToastMaster.toastList) {
      ToastMaster.toastList.addToast(toastMessage, toastType, showTime);
    } else {
      throw new Error('Unable to show toastList.  No toastList attached to ToastMaster');
    }
  }

  static showToastWithAction = (
    toastMessage: string,
    toastType: BanyanToastType,
    actionLabel: string,
    onAction: () => void
  ): void => {
    if (ToastMaster.toastList) {
      ToastMaster.toastList.addToastWithAction(toastMessage, toastType, actionLabel, onAction);
    } else {
      throw new Error('Unable to show toastList.  No toastList attached to ToastMaster');
    }
  };
}
export default ToastMaster;
