import * as _ from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import AdminPermission from '../../services/authentication/AdminPermission';
import FloodgateService from '../../services/floodgate/FloodgateService';

/**
 * exc-sdk define Workspace https://excsdk.corp.adobe.com/#!/SuperComponents/Shell
 */
export interface Workspace {
  uid: string;
  name: string;
  url: string;
}

// worksace but with messageId for localization instead of actual name
export interface WorkspaceLocale {
  uid: WorkspaceUIDs;
  messageDescriptor: MessageDescriptor;
  url: string;
  displayOrder: number; // index denotes the ordering of the workspaces labels in the shell header
}

// localizations for BanyanShell workspace tabs
const localeMessages = defineMessages({
  compartmentStructure: {
    id: 'shell.tabs.compartments',
    defaultMessage: 'Organizations',
  },
  productAllocation: {
    id: 'shell.tabs.productAllocation',
    defaultMessage: 'Product allocation',
  },
  jobExecution: {
    id: 'shell.tabs.jobExecution',
    defaultMessage: 'Job execution',
  },
  orgMigrationForGlobalAdmin: {
    id: 'shell.tabs.orgMigrationForGlobalAdmin',
    defaultMessage: 'Organization mapper',
  },
  orgMigrationForAdobeAgent: {
    id: 'shell.tabs.orgMigrationForAdobeAgent',
    defaultMessage: 'Adobe agent',
  },
  // insights
  insightsForGlobalAdmin: {
    id: 'shell.tabs.insightsForGlobalAdmin',
    defaultMessage: 'Insights',
  },
  supportForGlobalAdmin: {
    id: 'shell.tabs.supportForGlobalAdmin',
    defaultMessage: 'Support',
  },
});

// UIDs for workspaces are defined here.  For any new workspaces, the UID should be added and defined here
export enum WorkspaceUIDs {
  COMPARTMENTS = 'compartments',
  PRODUCT_ALLOCATION = 'prodalloc',
  JOB_EXECUTION = 'jobexec',
  ORG_MIGRATION_GLOBAL_ADMIN = 'orgmigrationforglobaladmin',
  ORG_MIGRATION_ADOBE_AGENT = 'orgmigrationforadobeagent',
  INSIGHTS = 'insightsforglobaladmin',
  SUPPORT = 'supportforglobaladmin',
}

class HeaderConsts {
  public static readonly ORGANIZATIONS_URL: string = '/organizations';
  public static readonly PRODUCT_ALLOCATION_URL: string = '/product-allocation';
  public static readonly JOB_EXECUTION_URL: string = '/job-execution';
  public static readonly ORG_MAPPER_FOR_ADOBE_AGENT: string = '/org-mapper-adobe-agent';
  public static readonly ORG_MAPPER_FOR_GLOBAL_ADMIN: string = '/org-mapper';
  public static readonly INSIGHTS: string = '/insights';
  public static readonly SUPPORT: string = '/support';

  public static readonly COMPARTMENTS_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.COMPARTMENTS,
    messageDescriptor: localeMessages.compartmentStructure,
    url: HeaderConsts.ORGANIZATIONS_URL,
    displayOrder: 0,
  };

  public static readonly PRODUCT_ALLOCATION_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.PRODUCT_ALLOCATION,
    messageDescriptor: localeMessages.productAllocation,
    url: HeaderConsts.PRODUCT_ALLOCATION_URL,
    displayOrder: 1,
  };

  public static readonly JOB_EXECUTION_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.JOB_EXECUTION,
    messageDescriptor: localeMessages.jobExecution,
    url: HeaderConsts.JOB_EXECUTION_URL,
    displayOrder: 2,
  };

  public static readonly ORG_MIGRATION_GLOBAL_ADMIN_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.ORG_MIGRATION_GLOBAL_ADMIN,
    messageDescriptor: localeMessages.orgMigrationForGlobalAdmin,
    url: HeaderConsts.ORG_MAPPER_FOR_GLOBAL_ADMIN,
    displayOrder: 3,
  };

  public static readonly ORG_MIGRATION_ADOBE_AGENT_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.ORG_MIGRATION_ADOBE_AGENT,
    messageDescriptor: localeMessages.orgMigrationForAdobeAgent,
    url: HeaderConsts.ORG_MAPPER_FOR_ADOBE_AGENT,
    displayOrder: 4,
  };

  public static readonly INSIGHTS_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.INSIGHTS,
    messageDescriptor: localeMessages.insightsForGlobalAdmin,
    url: HeaderConsts.INSIGHTS,
    displayOrder: 5,
  };

  public static readonly SUPPORT_WORKSPACE_LOCALE: WorkspaceLocale = {
    uid: WorkspaceUIDs.SUPPORT,
    messageDescriptor: localeMessages.supportForGlobalAdmin,
    url: HeaderConsts.SUPPORT,
    displayOrder: 6,
  };

  /**
   * Allow access and display different shell tabs depending on admin permissions
   * If OrgPickerController is not loaded before calling this method, then this method will only provide workspaces for non Global permissions
   */
  public static async getWorkspacesLocale(): Promise<WorkspaceLocale[]> {
    // if the user can access banyan, the user should be global admin for at least one org unless they are adobe agent
    const workspacesLocale: WorkspaceLocale[] = [];
    const hasAdobeAgentPriviledge = await AdminPermission.hasAdobeAgentPrivilege();
    // adobe agents can always access Org Migration for Adobe Agent
    if (hasAdobeAgentPriviledge) {
      workspacesLocale.push(HeaderConsts.ORG_MIGRATION_ADOBE_AGENT_WORKSPACE_LOCALE);
    }

    // users with global permissions on selected root org can always access compartments and product allocations page. Adobe Agent have read only permissions on organizations and product allocation page
    if (hasAdobeAgentPriviledge || AdminPermission.isROorRWGlobalAdminOfActiveOrg()) {
      workspacesLocale.push(HeaderConsts.COMPARTMENTS_WORKSPACE_LOCALE);
      workspacesLocale.push(HeaderConsts.PRODUCT_ALLOCATION_WORKSPACE_LOCALE);
    }

    // users that can edit anything can access the job execution page
    if (hasAdobeAgentPriviledge || AdminPermission.isRWGlobalAdminOfActiveOrg()) {
      workspacesLocale.push(HeaderConsts.JOB_EXECUTION_WORKSPACE_LOCALE);
    }

    // users with global admin permissions on selected root org specifically can access Org Migration for Global Admin
    if (AdminPermission.isRWGlobalAdminOfActiveOrg()) {
      workspacesLocale.push(HeaderConsts.ORG_MIGRATION_GLOBAL_ADMIN_WORKSPACE_LOCALE);
    }

    if (hasAdobeAgentPriviledge || AdminPermission.isROorRWGlobalAdminOfActiveOrg()) {
      workspacesLocale.push(HeaderConsts.INSIGHTS_WORKSPACE_LOCALE);
    }

    //support tab appears if the feature flag was enabled
    if (FloodgateService.isFeatureEnabled(FloodgateService.SUPPORT_TAB)) {
      if (AdminPermission.isROorRWGlobalAdminOfActiveOrg()) {
        workspacesLocale.push(HeaderConsts.SUPPORT_WORKSPACE_LOCALE);
      }
    }
    return _.sortBy(workspacesLocale, ['displayOrder']);
  }
}

export default HeaderConsts;
