import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as log from 'loglevel';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';
import Wait from '@react/react-spectrum/Wait';
import { GridColumn } from '@react/react-spectrum/Grid';
import Button from '@react/react-spectrum/Button';

import HeaderConsts from '../../../components/BanyanShell/HeaderConstants';
import BanyanCompartmentAPI from '../../../providers/BanyanCompartmentAPI';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import { ReportInfoData } from '../../../services/orgMaster/ReportInfo';
import withRouter, { RouteComponentProps } from '../../../services/utils/withRouter';

import '../common.css';
import ReportInfoTable from './ReportInfoTable';
import GoUrl, { GoUrlKeys } from '../../../components/GoUrl/GoUrl';

interface ExportOrgReportProps extends RouteComponentProps, WrappedComponentProps {}

interface ExportOrgReportState {
  reportInfoData: ReportInfoData[];
  reportsLoaded: boolean;
  rootOrgId: string | undefined;
  nextPageAvailable: boolean;
}

const messages = defineMessages({
  ExportReportIntroductionMessage: {
    id: 'InsightsForGA.ExportReports.ExportReportIntroductionMessage',
    defaultMessage: 'Export reports provide organization details in the specified format.',
  },
  ExportReportGenerateReportMessage: {
    id: 'InsightsForGA.ExportReports.ExportReportGenerateReportMessage',
    defaultMessage: 'You can generate Export reports from organizations page.',
  },
  ExportReportLearnMore: {
    id: 'InsightsForGA.ExportReports.ExportReportLearnMore',
    defaultMessage: 'Learn more',
  },
  ExportReportOrganizationsLabel: {
    id: 'InsightsForGA.ExportReports.ExportReportOrganizationsLabel',
    defaultMessage: 'Organizations',
  },
});

const MAX_REPORTS_PER_PAGE = 20;

class ExportOrgReport extends Component<ExportOrgReportProps, ExportOrgReportState> {
  constructor(props: ExportOrgReportProps) {
    super(props);
    this.state = {
      reportInfoData: [],
      rootOrgId: OrgPickerController.getActiveOrgId(),
      reportsLoaded: false,
      nextPageAvailable: true,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.fetchReportData();
  }

  async componentDidUpdate(): Promise<void> {
    if (this.state.rootOrgId !== OrgPickerController.getActiveOrgId()) {
      await this.fetchReportData();
    }
  }

  /**
   * This function fetches the reports for the org in the org picker
   */
  fetchReportData = async (): Promise<void> => {
    const orgId = OrgPickerController.getActiveOrgId();
    this.setState({ reportsLoaded: false, rootOrgId: orgId });
    let reportsData: ReportInfoData[] = [];
    try {
      if (orgId) {
        reportsData = await BanyanCompartmentAPI.getReportsForOrg(orgId);
      }
    } catch (error) {
      log.error(`Unable to fetch export reports for org ${orgId}`);
    }
    const nextPageAvailable = reportsData.length === MAX_REPORTS_PER_PAGE;
    this.setState({ reportInfoData: reportsData, reportsLoaded: true, nextPageAvailable });
  };

  navigateToOrganizationsPage = (): void => {
    this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.ORGANIZATIONS_URL));
  };

  loadNextPage = async (): Promise<void> => {
    let { nextPageAvailable } = this.state;
    const { reportInfoData, rootOrgId } = this.state;
    if (nextPageAvailable && reportInfoData.length > 0 && rootOrgId) {
      const lastReportBaseKey = reportInfoData[reportInfoData.length - 1].reportBaseKey;
      const nextReportsPage = await BanyanCompartmentAPI.getReportsForOrg(rootOrgId, lastReportBaseKey);
      if (nextReportsPage.length > 0) {
        reportInfoData.push(...nextReportsPage);
        if (nextReportsPage.length >= MAX_REPORTS_PER_PAGE) {
          nextPageAvailable = true;
        } else {
          nextPageAvailable = false;
        }
      } else {
        nextPageAvailable = false;
      }
      this.setState({ nextPageAvailable, reportInfoData });
    }
  };

  render = (): React.ReactNode => {
    const { formatMessage } = this.props.intl;
    const learnMoreLabel = formatMessage(messages.ExportReportLearnMore);

    return (
      <div className="Insights__headerDiv">
        <Heading className="App__header" data-testid="export-reports-heading">
          <FormattedMessage id="InsightsForGA.ExportReports.ExportReportsTitle" defaultMessage="Export Reports" />
        </Heading>
        <Rule variant="small" />
        {!this.state.reportsLoaded && (
          <div className="Insights_body">
            <Wait size="M" centered />
          </div>
        )}
        {this.state.reportsLoaded &&
          (this.state.reportInfoData.length < 1 ? (
            <GridColumn className="grid_reports_list">
              <div>
                <div className="Storage_Reports_default">
                  <div>
                    <div>
                      {formatMessage(messages.ExportReportIntroductionMessage)}
                      &nbsp;{' '}
                      <GoUrl goUrlKey={GoUrlKeys.exportReports} target="gac_exportReport">
                        {learnMoreLabel}
                      </GoUrl>
                    </div>
                    <div>
                      {formatMessage(messages.ExportReportGenerateReportMessage)}
                      &nbsp;{' '}
                      <span
                        onClick={this.navigateToOrganizationsPage}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(): void => {}}
                        className="ExportReport__ALink"
                      >
                        {formatMessage(messages.ExportReportOrganizationsLabel)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </GridColumn>
          ) : (
            <div className="Insights_body">
              <div className="Insights_container">
                <div>
                  {formatMessage(messages.ExportReportIntroductionMessage)}
                  &nbsp;
                  <span>
                    <FormattedMessage
                      id="InsightsForGA.ExportReports.ExportReportsIntroductionPart2"
                      defaultMessage="A report is available in the Global Admin Console for 90 days."
                    />{' '}
                    <GoUrl goUrlKey={GoUrlKeys.exportReports} target="gac_exportReport">
                      {learnMoreLabel}
                    </GoUrl>
                  </span>
                </div>
                <Button onClick={this.fetchReportData} className="Insights__btn">
                  <FormattedMessage id="InsightsForGA.ExportReports.RefreshBtn" defaultMessage="Refresh" />
                </Button>
              </div>
              <ReportInfoTable
                reportInfoData={this.state.reportInfoData}
                nextPageAvailable={this.state.nextPageAvailable}
                loadNextPage={this.loadNextPage}
              />
            </div>
          ))}
      </div>
    );
  };
}

export default injectIntl(withRouter(ExportOrgReport));
