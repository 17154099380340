import React, { useState, useEffect } from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, FormattedMessage, useIntl, IntlProvider } from 'react-intl';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import Analytics from '../../Analytics/Analytics';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';

import BanyanCompartmentAPI from '../../providers/BanyanCompartmentAPI';

const messages = defineMessages({
  CheckingUserPermission: {
    id: 'EditCompartment.GoToAdminConsoleDialog.CheckingUserPermission',
    defaultMessage: 'Checking user permission',
  },
  PermissionCheckFailed: {
    id: 'EditCompartment.GoToAdminConsoleDialog.PermissionCheckFailed',
    defaultMessage: 'Permission check failed',
  },
  OK: {
    id: 'EditCompartment.GoToAdminConsoleDialog.OK',
    defaultMessage: 'OK',
  },
});

interface GoToAdminConsoleDialogProps {
  onClose: () => void;
  url: string;
  selectedOrg: UOrgMaster;
}

function GoToAdminConsoleDialogInternal(props: GoToAdminConsoleDialogProps): JSX.Element {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [isUserSysAdmin, setIsUserSysAdmin] = useState<boolean | undefined>(undefined);
  const abortController = new AbortController();

  useEffect(() => {
    if (isUserSysAdmin === undefined) {
      (async (): Promise<void> => {
        const isUserSysAdminCurrently = await BanyanCompartmentAPI.isCurrentUserSystemAdminOf(props.selectedOrg.id);
        if (abortController.signal.aborted) return;
        setIsUserSysAdmin(isUserSysAdminCurrently);
        if (isUserSysAdminCurrently) {
          props.onClose(); // close the dialog programmatically if user has system admin rights and take the user to admin console
          Analytics.fireCTAEvent(`Open Admin Console link clicked`);
          window.open(props.url);
        }
      })();
    }
    return function cleanUp(): void {
      abortController.abort();
    };
  }, []); // we dont want to run this multiple times, so there is nothing in the dependency array. By doing so this useEffect acts like "componentDidMount" lifecycle hook which runs only once.

  return (
    <Dialog
      title={
        // show "Checking user permission" while the loading spinner is shown
        isUserSysAdmin === undefined
          ? formatMessage(messages.CheckingUserPermission)
          : formatMessage(messages.PermissionCheckFailed) // the dialog is only shown when the user is not a system admin
      }
      confirmLabel={isUserSysAdmin === undefined ? '' : formatMessage(messages.OK)} // hide the confirm button when permission check is running
      onClose={props.onClose}
      variant="information"
    >
      {/* adding a height to the content container will stop the flickering of scrollbar. Fixes https://jira.corp.adobe.com/browse/CCE-4121928 */}
      <div style={{ height: '100px' }}>
        {isUserSysAdmin === undefined ? (
          <Wait className="Load_wait" />
        ) : (
          /* the dialog is only shown when user does not have system admin rights */
          <FormattedMessage
            id="EditCompartment.GoToAdminConsoleDialog.SystemAdminRightRequired"
            defaultMessage="You must have System Administrator rights to open Admin Console."
          />
        )}
      </div>
    </Dialog>
  );
}

function GoToAdminConsoleDialog(props: GoToAdminConsoleDialogProps): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <GoToAdminConsoleDialogInternal {...props} />
    </IntlProvider>
  );
}

export default GoToAdminConsoleDialog;
