import React from 'react';
import { FormattedMessage } from 'react-intl';
import GoUrl, { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import SearchOrg, { SearchOrgProps } from '../SearchOrg/SearchOrg';
import './SearchOrganizationContent.css';

interface SearchOrganizationContentProps extends SearchOrgProps {
  infoMessage: string;
  goUrlKey: GoUrlKeys;
  goUrlTarget: string;
  label: string; // label is used by the Step Control to display the message on each step
}

function SearchOrganizationContent(props: SearchOrganizationContentProps): JSX.Element {
  return (
    <div>
      <div>
        <div className="SearchOrganizationContent__descriptionContainer">
          <div className="SearchOrganizationContent__descriptionHeader">Search an organization</div>
          <div className="SearchOrganizationContent__description">{props.infoMessage}</div>
          <GoUrl
            goUrlKey={props.goUrlKey}
            target={props.goUrlTarget}
            className="SearchOrganizationContent__description"
          >
            {' '}
            {/* Need to update URL here... */}
            <FormattedMessage id="help.LearnMore" defaultMessage="Learn more" />
          </GoUrl>
        </div>
      </div>
      <SearchOrg {...props} />
    </div>
  );
}

export default SearchOrganizationContent;
