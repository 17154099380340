import React from 'react';
import { Table, TBody, TH, THead, TR } from '@react/react-spectrum/Table';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Button from '@react/react-spectrum/Button';
import * as _ from 'lodash';
import { FormattedMessage, IntlProvider } from 'react-intl';
import './ProfileTable.css';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../../../services/orgMaster/UProduct';
import { UProductProfile } from '../../../../services/orgMaster/UProductProfile';
import ProfileRow from './ProfileRow/ProfileRow';
import '../../../common.css';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import AdminPermission from '../../../../services/authentication/AdminPermission';
import Analytics from '../../../../Analytics/Analytics';
import { LocaleSettings } from '../../../../services/locale/LocaleSettings';
import EditProdProfileDialogContent, { ProfileDialogContext } from './ProfileRow/EditProdProfileDialogContent';
import AddProfileNotSupportedDialog from './AddProfileNotSupporteDialog';
import ScrollableContent from '../../Widgets/ScrollableContent';
import { ProductAttributes } from '../../../../services/orgMaster/ProductAttributes';
import ProfileDialogService from './ProfileRow/ProfileDialogService';

const PROFILE_TESTID = 'profile-testid-';

interface ProfileTableProps {
  update: () => void;
  productId: string;
  selectedOrg: UOrgMaster;
}

interface ProfileTableState {
  product: UProduct;
  profiles: UProductProfile[];
  errorMessage: string;
}

interface EditProdProfilePropsIntl extends ProfileTableProps {
  profile: UProductProfile;
  context: string;
  attributes: ProductAttributes;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function EditProdProfileDialogContentIntl(props: Omit<EditProdProfilePropsIntl, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditProdProfileDialogContent {...props} context={ProfileDialogContext.ADD} />
    </IntlProvider>
  );
}

class ProfileTable extends React.Component<ProfileTableProps, ProfileTableState> {
  abortController: AbortController;

  constructor(props: ProfileTableProps) {
    super(props);
    const editedProduct = _.find(
      this.props.selectedOrg.products,
      (product: UProduct) => product.id === this.props.productId
    );
    this.state = {
      product: editedProduct as UProduct,
      profiles: (editedProduct as UProduct).productProfiles,
      errorMessage: '',
    };
    this.abortController = new AbortController();
  }

  private async loadProfiles(): Promise<void> {
    try {
      if (!this.state.product.profilesLoaded) {
        await LoadOrgDataService.loadProfiles(
          this.props.selectedOrg.id,
          this.state.product.id,
          false,
          this.abortController.signal
        );
      }
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      const updatedProduct = _.find(
        this.props.selectedOrg.products,
        (product: UProduct) => product.id === this.state.product.id
      );
      const profilesToLoadAdmins = _.filter(updatedProduct?.productProfiles, (profile) => !profile.adminsLoaded);
      const promises = _.map(profilesToLoadAdmins, (profile) =>
        ProfileDialogService.loadProfileAdminsV2(this.props.selectedOrg, profile)
      );
      await Promise.all(promises);
      this.setState({
        product: updatedProduct as UProduct,
        errorMessage: '', // reset error message
        profiles: (updatedProduct as UProduct).productProfiles,
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      this.setState({ errorMessage: `unable to load profiles ${error}` });
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async componentDidMount(): Promise<void> {
    await this.loadProfiles();
  }

  async componentDidUpdate(): Promise<void> {
    const product: UProduct | undefined = _.find(
      this.props.selectedOrg.products,
      (eachProduct: UProduct) => eachProduct.id === this.props.productId
    );
    const isProfileListChange = !product || !_.isEqual(this.state.profiles, product.productProfiles);
    if (isProfileListChange || !this.state.product.profilesLoaded) {
      await this.loadProfiles();
    }
  }

  // returns true if there are more pages to load
  private morePagesToLoad(): boolean {
    return this.state.product.currentProfilePageIndex + 1 < this.state.product.totalProfilePageCount;
  }

  loadNextPage = async (): Promise<void> => {
    await LoadOrgDataService.loadNextPageProfile(this.state.product.id, this.props.selectedOrg.id);
    const updatedProduct = _.find(this.props.selectedOrg.products, (p: UProduct) => p.id === this.state.product.id);
    this.setState({
      product: updatedProduct as UProduct,
      profiles: (updatedProduct as UProduct).productProfiles,
    }); // rerender with updated values
  };

  public render(): React.ReactNode {
    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in profile table if we are unable to load the profile data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }
    const sortedProfiles = _.sortBy(this.state.profiles, ['name']);

    // if profiles are not loaded, show wait
    return this.state.product.profilesLoaded ? (
      <div>
        <ScrollableContent
          onScroll={this.loadNextPage}
          enableInfiniteScroll={this.state.product.totalProfilePageCount > 1}
          uniqueId="EditCompartment_ProfileTableID"
          className="EditCompartment_tabContent"
          hasMorePages={this.morePagesToLoad()}
          height="200px"
        >
          <React.Fragment>
            {this.state.profiles.length > 0 ? (
              <Table data-testid="profile-table">
                {/* With THead, we can not apply dynamic height which is required for scrolling.
              because React Spectrum's THead inserts its own TR whose style can not be modified programmatically */}
                <THead>
                  <TH>
                    <FormattedMessage id="ProfileTable.Header.ProductProfile" defaultMessage="PRODUCT PROFILE" />
                  </TH>
                  <TH>
                    <FormattedMessage id="ProfileTable.Header.EntitledUsers" defaultMessage="ENTITLED USERS" />
                  </TH>
                  <TH>
                    <FormattedMessage id="ProfileTable.Header.Quota" defaultMessage="QUOTA" />
                  </TH>
                  <TH>
                    <FormattedMessage id="ProfileTable.Header.AdminCount" defaultMessage="ADMIN COUNT" />
                  </TH>
                  <TH>
                    <FormattedMessage id="ProfileTable.Header.Notifications" defaultMessage="NOTIFICATIONS" />
                  </TH>
                  <TH />
                </THead>
                <TBody>
                  {sortedProfiles.map(
                    (profile: UProductProfile): React.ReactNode => (
                      <TR key={profile.id} data-testid={`${PROFILE_TESTID}${profile.name}`}>
                        <ProfileRow
                          profile={profile}
                          update={this.props.update}
                          attributes={this.state.product.productAttributes}
                          selectedOrg={this.props.selectedOrg}
                        />
                      </TR>
                    )
                  )}
                </TBody>
              </Table>
            ) : (
              <div>
                <FormattedMessage id="ProductTable.NoProfiles" defaultMessage="No Profiles" />
              </div>
            )}
          </React.Fragment>
        </ScrollableContent>
        <div className="EditCompartment__tabSection--rightAligned EditCompartment__margin--top">
          <FormattedMessage
            id="EditCompartment.ProfileTableCountDisplay"
            description="Display current profile count and total profile count"
            defaultMessage="Displaying {currentProfileCount} of {totalProfileCount} profiles"
            values={{
              currentProfileCount: this.state.product.productProfiles.length,
              totalProfileCount: this.state.product.totalProfileCount + this.state.product.getNewUserProfiles(),
            }}
          />
        </div>
        {!AdminPermission.readOnlyMode() &&
          !this.props.selectedOrg.isReadOnlyOrg() &&
          !this.state.product.productAttributes.featureRestrictedLicense && (
            <ModalTrigger>
              <Button
                quiet
                variant="action"
                className="EditCompartment__margin--top spectrum-Link"
                data-testid="add-profile"
                onClick={(): void => Analytics.fireCTAEvent('add product profile dialog opened')}
              >
                <FormattedMessage id="ProductTable.AddProfile" defaultMessage="Add Profile" />
              </Button>
              {this.state.product.productAttributes.sharedDeviceLicense ? (
                <AddProfileNotSupportedDialog productId={this.state.product.id} selectedOrg={this.props.selectedOrg} />
              ) : (
                <EditProdProfileDialogContentIntl
                  {...this.props}
                  context={ProfileDialogContext.ADD}
                  update={this.props.update}
                  profile={UProductProfile.getNewProfileObject(this.state.product, this.props.selectedOrg.id)}
                  attributes={this.state.product.productAttributes}
                  selectedOrg={this.props.selectedOrg}
                />
              )}
            </ModalTrigger>
          )}
      </div>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}
export default ProfileTable;
export { PROFILE_TESTID };
