/* eslint-disable  prefer-destructuring, prefer-template */
import * as _ from 'lodash';
import React, { Fragment } from 'react';
import Alert from '@react/react-spectrum/Alert';
import Button from '@react/react-spectrum/Button';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';
import { Table, TBody, TD, TH, THead, TR } from '@react/react-spectrum/Table';
import Wait from '@react/react-spectrum/Wait';
import * as log from 'loglevel';
import './JobExecution.css';
import '../App.css';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Dialog from '@react/react-spectrum/Dialog';
import AlertIcon from '@react/react-spectrum/Icon/Alert';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import StatusLight from '@react/react-spectrum/StatusLight';
import CheckmarkCircle from '@react/react-spectrum/Icon/CheckmarkCircle';
import CancelIcon from '@react/react-spectrum/Icon/Cancel';
import InfoOutline from '@react/react-spectrum/Icon/InfoOutline';
import Progress from '@react/react-spectrum/Progress';
import {
  defineMessages,
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import BanyanJobs from '../services/jobs/BanyanJobs';
import BanyanJobsApi from '../providers/BanyanJobsApi';
import { CancelPromise, CancelStatus } from '../services/utils/CancelPromise';
import { ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from '../services/orgMaster/OrgMaster';
import {
  CommandStatus,
  CommandValue,
  ElemInfoWrap,
  JobInfo,
  JobProgressResponse,
  JobResultState,
  JobResultStatus,
} from '../services/jobs/JobData';
import { LoadOrgDataService } from '../services/orgMaster/LoadOrgDataService';
import { UBasicData } from '../services/orgMaster/UBasic';
import { UOrg, UOrgData } from '../services/orgMaster/UOrg';
import { Update } from '../services/orgMaster/UOrgMaster';
import { UProduct, UProductData } from '../services/orgMaster/UProduct';
import { UAdmin } from '../services/orgMaster/UAdmin';
import { UUserGroup } from '../services/orgMaster/UUserGroup';
import { UProductProfile } from '../services/orgMaster/UProductProfile';
import UContractReparent from '../services/orgMaster/UContractReparent';
import { OrganizationPolicy, UCompartmentPolicies } from '../services/orgMaster/UCompartmentPolicy';
import { FulfillableItemType, UResource } from '../services/orgMaster/UResource';
import AdminPermission from '../services/authentication/AdminPermission';
import HeaderConstants from '../components/BanyanShell/HeaderConstants';
import Analytics from '../Analytics/Analytics';
import OrgPickerController from '../services/organization/OrgPickerController';
import { OrgAdminType } from '../services/authentication/IMS';
import Utils from '../services/utils/Utils';
import { CommandService } from '../services/Commands/CommandService';
import CommandInterface from '../services/Commands/CommandInterface';
import { GoUrlKeys } from '../components/GoUrl/GoUrl';
import GoHelpBubble from '../components/HelpBubble/HelpBubble';
import Constants from '../constants/Constants';
import ScrollableContent from '../Compartments/EditCompartment/Widgets/ScrollableContent';
import AdobeAgentWithNoOrgMessage from '../components/DeepLinking/AdobeAgentWithNoOrgMessage';
import HierarchyManager from '../services/organization/HierarchyManager';
import { ClearOrgDataWrapper } from '../services/Commands/ClearOrgDataWrapper';
import UContractSwitch from '../services/orgMaster/UContractSwitch';
import UAllocationRetrofit from '../services/orgMaster/UAllocationRetrofit';
import UContractRollback from '../services/orgMaster/UContractRollback';
import UAllocationRollback from '../services/orgMaster/UAllocationRollback';
import CmdDescriptionUtils from '../services/Codes/CmdDescriptionUtils';
import { CmdDescriptionCodesData } from '../services/Codes/MessageImages';
import { ErrorCodesData } from '../services/Codes/ErrorCodes';
import withRouter, { RouteComponentProps } from '../services/utils/withRouter';

/*
   There are three types of jobs run/shown on this page:
   1. A diff job - triggered via 'make it so' to determine if there are pending changes to be submitted to banyansvc
   2. An UPDATE_ORG job - triggered via the 'start' button
   3. The 'most recent' UPDATE_ORG job from DB, shown when navigating to the page directly, not via 'make it so'.
 */

interface ObjectShadow {
  id: string;
  name: string; // pathname for org, simple name for user, user group, product profile, product
  parentId: string; // parent if applicable.  Used for orgs.
}
// ObjectShadows is used to track data for not-yet-created, deleted, renamed, and moved objects so that readable messages can be displayed for them
class ObjectShadows {
  objectShadows: { [key: string]: ObjectShadow };

  constructor() {
    this.objectShadows = {};
  }
  public add(id: string, name: string, parentId: string = ''): void {
    this.objectShadows[id] = { id, name, parentId };
  }
  public remove(id: string): void {
    delete this.objectShadows[id];
  }

  // Get the pathname of an org.  Only used for orgs
  // This performs the computation using the union of the shadow cache and the global model tree.
  // Values from the model tree are preferred and used if found.
  public getPathname(id: string): string {
    // Prefer using the global model.  If that doesn't work out, use the shadow cache.
    const thisOrg = HierarchyManager.getOrg(id);
    if (thisOrg) {
      const pathname = thisOrg.getPathname();
      this.add(id, pathname); // cache result so it is known during data reload
      return pathname;
    }
    if (this.objectShadows[id]) {
      return this.objectShadows[id].name;
    }
    return id; // couldn't find
  }

  // get from object shadow cache by id.  Can be any object type (that is, id of org or product).
  public getName(id: string): string {
    if (this.objectShadows[id]) return this.objectShadows[id].name;
    return '';
  }
}

enum JobPageState {
  NoEdits, // NoEdits on page, -> LoadLast
  RunDiff, // Run Diff to look for changes, -> Pending, -> NoEdits
  Pending, // Pending changes exist  -> JobRunning (on Start)  -> NoEdits (Discard)
  JobRunning, // Job in execution  -> Cancel (on Cancel)   -> JobCompleted (on job completion)
  JobCompleted, // Jab has completed, implicit -> RunDiff or NoEdits
  Cancel, // Cancel in progress,  -> JobCompleted
  LoadLast, // LoadLast job,  -> JobCompleted
  Nothing, // got here due to error.  Figure out what to do.
  Loading, // page is loading after org change
  Timeout, // Polling for job progress timed-out on UI as no update from server (i.e., state of job on server is `RUNNING` for more than `Constants.JOB_PROGRESS_POLLING_TIMEOUT_IN_MS` time)
  ErrorInCreateJob, // An error occurred while creating job
  ErrorInGetJob, // An error occurred while getting jobs
}

// This structure is to store persistent state used on the Job Execution page.  State is copied into this structure
// stored statically when the Job Execution page is unmounted, and copied back in when the state is constructed.
interface JobExecutionPersistentState {
  editsInUI: CommandValue[]; // accumulating list of edits
  versionAtDiff: number; // edit version at point diff was run
  updateOrgJobId: string | null; // Used to 1) indicate that UPDATE_ORG job is running 2) cancel a job 3) indicate cancellation was requested
  objectShadows: ObjectShadows; // additional information for mutated objects so messages can reference old or new information
  diffJobProgress: JobProgressResponse | null; // status of diff job
  taskDetails: CommandValue[]; // combined diff results and execution results
  jobPageState: JobPageState; // state of the job page
  recentJobList: JobInfo[]; // list of recent update jobs
  mostRecentJobState: JobInfo | null; // state of last job run
}

// types for storing the results of past jobs (loaded on demand)
interface JobResults {
  results: CommandValue[];
}

interface JobResultsMap {
  [key: string]: JobResults;
}

interface JobExecutionProps extends RouteComponentProps, WrappedComponentProps {}

// React state for this page
interface JobExecutionState {
  initializingMsg: string;

  // Information for batch edits in client
  diffJobProgress: JobProgressResponse | null;
  editsInUI: CommandValue[]; // accumulating list of edits
  versionAtDiff: number; // edit version at point diff was run

  updateOrgJobId: string | null; // Used to 1) indicate that UPDATE_ORG job is running 2) cancel a job 3) indicate cancellation was requested
  // Values are null, 'cancelling', 'pending', and actual job ids.

  mostRecentJobState: JobInfo | null;

  taskDetails: CommandValue[]; // combined diff results and execution results
  jobPageState: JobPageState; // state of the job page
  recentJobList: JobInfo[]; // list of recent update jobs
  recentJobListTaskDetails: JobResultsMap; // details of recent jobs when fetched
  isLoadedAllRecentJobs: boolean; // boolean to denote if all the recent jobs have been loaded

  errMsg: string | undefined;

  objectShadows: ObjectShadows; // additional information for mutated objects so messages can reference old or new information

  isRWAdobeAgent: boolean;
}

class JobExecution extends React.Component<JobExecutionProps, JobExecutionState> {
  abortController = new AbortController(); // used to terminate activities when unmounting
  mounted: boolean = false;

  private static persistentState: JobExecutionPersistentState = {
    editsInUI: [],
    versionAtDiff: 0,
    objectShadows: new ObjectShadows(),
    diffJobProgress: null,
    taskDetails: [],
    recentJobList: [],
    jobPageState: JobPageState.Nothing,
    mostRecentJobState: null,
    updateOrgJobId: null,
  };

  private static removeRightmostSegment(s: string): string {
    const last = s.lastIndexOf('/');
    if (last === -1) return s;
    return s.substring(0, last);
  }

  constructor(props: JobExecutionProps) {
    super(props);
    this.state = {
      initializingMsg: '',
      diffJobProgress: JobExecution.persistentState.diffJobProgress,
      editsInUI: JobExecution.persistentState.editsInUI,
      versionAtDiff: JobExecution.persistentState.versionAtDiff,

      updateOrgJobId: JobExecution.persistentState.updateOrgJobId,

      mostRecentJobState: JobExecution.persistentState.mostRecentJobState,

      taskDetails: JobExecution.persistentState.taskDetails,
      jobPageState: JobExecution.persistentState.jobPageState,
      recentJobList: JobExecution.persistentState.recentJobList,
      recentJobListTaskDetails: {},
      isLoadedAllRecentJobs: false,

      errMsg: undefined,

      objectShadows: JobExecution.persistentState.objectShadows,

      isRWAdobeAgent: false,
    };
  }

  // Strings
  messages = defineMessages({
    error: { id: 'JobExecution.ERROR', defaultMessage: 'ERROR' },
    failed_to_parse: { id: 'JobExecution.FailedToParse', defaultMessage: 'Failed to parse job details.' },
    state_pending: { id: 'JobExecution.state.pending', defaultMessage: 'Pending' },
    create_organization: {
      id: 'JobExecution.cmd.CreateOrganization',
      defaultMessage: 'Create organization "{orgPathname}"',
    },
    update_org_name: {
      id: 'JobExecution.cmd.UpdateOrgName',
      defaultMessage: 'Update organization name "{oldPath}" from "{old}" to "{new}"',
    },
    update_org: { id: 'JobExecution.cmd.UpdateOrg', defaultMessage: 'Update organization "{orgPathname}"' },
    delete_org: { id: 'JobExecution.cmd.DeleteOrg', defaultMessage: 'Delete organization "{orgPathname}"' },
    reparent_org: {
      id: 'JobExecution.cmd.Reparent',
      defaultMessage: 'Change parent of organization "{oldOrgPathname}" to "{newOrgParent}"',
    },
    recent_jobs: { id: 'JobExecution.RecentJobs', defaultMessage: 'Recent Jobs' },
    no_jobs_submitted: { id: 'JobExecution.NoJobsSubmitted', defaultMessage: 'No jobs submitted' },
    add_product: {
      id: 'JobExecution.cmd.AddProduct',
      defaultMessage: 'Add product "{productName}" in "{orgPathname}" with {resourceList}',
    },
    update_product: {
      id: 'JobExecution.cmd.UpdateProduct',
      defaultMessage: 'Update product "{productName}" in "{orgPathname}" to have {resourceList}',
    },
    remove_product: {
      id: 'JobExecution.cmd.RemoveProduct',
      defaultMessage: 'Remove product "{productName}" in "{orgPathname}"',
    },
    reparent_product: {
      id: 'JobExecution.cmd.ReparentProduct',
      defaultMessage: 'Change product allocation for "{productName}" in "{orgPathname}" to come from "{newOrgParent}"',
    },
    reparent_contract: {
      id: 'JobExecution.cmd.ReparentContract',
      defaultMessage:
        'Change product allocations in "{orgPathname}" to come from "{newOrgParent}" ({displayProductNames})',
    },
    error_illegal_op: { id: 'JobExecution.cmd.ErrorIllegalOp', defaultMessage: 'Error: illegal operation' },
    add_admin: {
      id: 'JobExecution.cmd.AddAdmin',
      defaultMessage: 'Add admin user {uuseremail} in "{orgPathname}" with role {rolename}',
    },
    add_admin_to_product: {
      id: 'JobExecution.cmd.AddAdmin.product',
      defaultMessage: 'Add admin user {uuseremail} in "{orgPathname}" with role {rolename} to product "{productname}"',
    },
    add_admin_to_pp: {
      id: 'JobExecution.cmd.AddAdmin.pp',
      defaultMessage: 'Add admin user {uuseremail} in "{orgPathname}" with role {rolename} to profile "{profilename}"',
    },
    add_admin_to_ug: {
      id: 'JobExecution.cmd.AddAdmin.ug',
      defaultMessage:
        'Add admin user {uuseremail} in "{orgPathname}" with role {rolename} to user group "{usergroupname}"',
    },
    remove_admin: {
      id: 'JobExecution.cmd.RemoveAdmin',
      defaultMessage: 'Remove admin user {uuseremail} in "{orgPathname}" role {rolename}',
    },
    remove_admin_from_product: {
      id: 'JobExecution.cmd.RemoveAdmin.product',
      defaultMessage:
        'Remove admin user {uuseremail} in "{orgPathname}" with role {rolename} from product "{productname}"',
    },
    remove_admin_from_pp: {
      id: 'JobExecution.cmd.RemoveAdmin.pp',
      defaultMessage:
        'Remove admin user {uuseremail} in "{orgPathname}" with role {rolename} from profile "{profilename}"',
    },
    remove_admin_from_ug: {
      id: 'JobExecution.cmd.RemoveAdmin.ug',
      defaultMessage:
        'Remove admin user {uuseremail} in "{orgPathname}" with role {rolename} from user group "{usergroupname}"',
    },
    add_admin_roles: {
      id: 'JobExecution.cmd.AddAdminUserWithRoles',
      defaultMessage: 'Add admin {uadminemail} in "{orgPathname}" with roles {rolenames}',
    },
    update_admin_roles: {
      id: 'JobExecution.cmd.UpdateAdminRoles',
      defaultMessage: 'Update admin {uadminemail} in "{orgPathname}" with roles {rolenames}',
    },
    delete_all_roles_for_admin: {
      id: 'JobExecution.cmd.DeleteAllRolesForAdmin',
      defaultMessage: 'Remove all admin roles {uadminemail} in "{orgPathname}"',
    },
    create_ug: {
      id: 'JobExecution.cmd.CreateUserGroup',
      defaultMessage: 'Create User Group "{uusergroupname}" in "{orgPathname}"',
    },
    update_ug: {
      id: 'JobExecution.cmd.UpdateUserGroup',
      defaultMessage: 'Update User Group "{uusergroupname}" in "{orgPathname}"',
    },
    delete_ug: {
      id: 'JobExecution.cmd.DeleteUserGroup',
      defaultMessage: 'Delete User Group "{uusergroupname}" in "{orgPathname}"',
    },
    add_pp_to_ug: {
      id: 'JobExecution.cmd.AddProductProfileFromUG',
      defaultMessage: 'added product profile "{profileName}"',
    },
    delete_pp_from_ug: {
      id: 'JobExecution.cmd.DeleteProductProfileFromUG',
      defaultMessage: 'deleted product profile "{profileName}"',
    },
    create_pp: {
      id: 'JobExecution.cmd.CreateProductProfile',
      defaultMessage: 'Create Product Profile "{uppname}" of product "{productName}" in "{orgPathname}"',
    },
    update_pp: {
      id: 'JobExecution.cmd.UpdateProductProfile',
      defaultMessage: 'Update Product Profile "{uppname}" of product "{productName}" in "{orgPathname}"',
    },
    delete_pp: {
      id: 'JobExecution.cmd.DeleteProductProfile',
      defaultMessage: 'Delete Product Profile "{uppname}" of product "{productName}" in "{orgPathname}"',
    },
    create_pol: {
      id: 'JobExecution.cmd.CreatePolicy',
      defaultMessage: 'Set Organization Policy in "{orgPathname}" with non-default value(s): {policyImage}',
    },
    update_pol: {
      id: 'JobExecution.cmd.UpdatePolicy',
      defaultMessage: 'Update Organization Policy in "{orgPathname}": {policyImage}',
    },
    pol_claim_domains: {
      id: 'JobExecution.pol.claimDomains',
      defaultMessage: 'Claim domains',
    },
    pol_create_children: {
      id: 'JobExecution.pol.createChildren',
      defaultMessage: 'Create child organizations',
    },
    pol_rename_org: {
      id: 'JobExecution.pol.renameOrg',
      defaultMessage: 'Rename organization',
    },
    pol_delete_org: {
      id: 'JobExecution.pol.deleteOrg',
      defaultMessage: 'Delete organization',
    },
    pol_allow_sync: {
      id: 'JobExecution.pol.allowSync',
      defaultMessage: 'Use directory sync',
    },
    pol_manage_admins: {
      id: 'JobExecution.pol.manageAdmins',
      defaultMessage: 'Add or delete administrators',
    },
    pol_inherit_admins: {
      id: 'JobExecution.pol.inheritAdmins',
      defaultMessage: 'Inherit system admins from parent',
    },
    pol_inherit_users: {
      id: 'JobExecution.pol.inheritUsers',
      defaultMessage: 'Inherit users from directories managed by the parent organization',
    },
    pol_non_parent_alloc: {
      id: 'JobExecution.pol.nonParentAlloc',
      defaultMessage: 'Allocate product resources to organizations other than child organizations',
    },
    pol_adobeid: {
      id: 'JobExecution.pol.adobeid',
      defaultMessage: 'Add Adobe ID users',
    },
    pol_manage_user_groups: {
      id: 'JobExecution.pol.manageUserGroups',
      defaultMessage: 'Manage User Groups',
    },
    pol_dup_directories: {
      id: 'JobExecution.pol.dupDirectories',
      defaultMessage: 'Duplicate user groups to another organization',
    },
    pol_change_id: {
      id: 'JobExecution.pol.changeId',
      defaultMessage: 'Change identity configuration',
    },
    pol_move_dir: {
      id: 'JobExecution.pol.moveDir',
      defaultMessage: 'Move a directory',
    },
    pol_manage_products: {
      id: 'JobExecution.pol.manageProducts',
      defaultMessage: 'Manage products',
    },
    pol_inherit_sharing: {
      id: 'JobExecution.pol.inheritSharing',
      defaultMessage: 'Inherit sharing policy from parent when organization is created',
    },
    pol_change_asset_sharing: {
      id: 'JobExecution.pol.changeAssetSharing',
      defaultMessage: 'System or Storage admin can change asset sharing settings',
    },
    pol_inherit_workspaces_policy: {
      id: 'JobExecution.pol.inheritWorkspacesPolicy',
      defaultMessage: 'Inherit workspace policy from parent when organization is created',
    },
    pol_set_workspaces_policy: {
      id: 'JobExecution.pol.setWorkspacesPolicy',
      defaultMessage: 'System or Storage admin can change workspace policies',
    },
    pol_value_allowed: {
      id: 'JobExecution.pol.value.allowed',
      defaultMessage: 'allowed',
    },
    pol_value_notAllowed: {
      id: 'JobExecution.pol.value.notAllowed',
      defaultMessage: 'not allowed',
    },
    pol_gettingLocked: {
      id: 'JobExecution.pol.gettingLocked',
      defaultMessage: 'lock',
    },
    pol_gettingUnlocked: {
      id: 'JobExecution.pol.gettingUnlocked',
      defaultMessage: 'unlock',
    },
    warning: {
      id: 'JobExecution.Warning',
      defaultMessage: 'Warning',
    },
    cancel: {
      id: 'JobExecution.Cancel',
      defaultMessage: 'Cancel',
    },
    ok: {
      id: 'JobExecution.OK',
      defaultMessage: 'OK',
    },
    command_status_not_run_as_job_create_failed: {
      id: 'JobExecution.status.notRunAsJobCreateFailed',
      defaultMessage: 'Not run because job could not be created',
    },
    command_status_succeeded: {
      id: 'JobExecution.status.SUCCEEDED',
      defaultMessage: 'SUCCEEDED',
    },
    command_status_success_with_notes: {
      id: 'JobExecution.status.SUCCEEDED.Notes',
      defaultMessage: 'SUCCEEDED WITH NOTES',
    },
    command_status_pending: {
      id: 'JobExecution.status.PENDING',
      defaultMessage: 'PENDING',
    },
    command_status_failed: {
      id: 'JobExecution.status.FAILED',
      defaultMessage: 'FAILED',
    },
    command_status_not_run_due_to_error: {
      id: 'JobExecution.status.NOTRUN.Error',
      defaultMessage: 'Not run due to error in a different command', // The command as seen on JobExecution page are not run in the same order at the backend, so displaying "not run due to prior error" does not make sense to the user. It needs to be simply "Not run due to error in a different command". Fixes BANY 501
    },
    command_status_not_run_due_to_cancel: {
      id: 'JobExecution.status.NOTRUN.Cancel',
      defaultMessage: 'Not run due to job cancellation',
    },
    command_progress: {
      id: 'JobExecution.status.Commands.Progress',
      defaultMessage: '(Changes: {commandsRun} of {commands} run, errors: {errors})',
    },
    job_state_completed: {
      id: 'JobExecution.status.Job.Completed',
      defaultMessage: 'Job completed',
    },
    job_state_running: {
      id: 'JobExecution.status.Job.Running',
      defaultMessage: 'Job running',
    },
    job_state_cancelling: {
      id: 'JobExecution.status.Job.Cancelling',
      defaultMessage: 'Job cancelling',
    },
    job_status_succeeded: {
      id: 'JobExecution.jobStatus.SUCCEEDED',
      defaultMessage: 'SUCCEEDED',
    },
    job_status_failed: {
      id: 'JobExecution.jobStatus.FAILED',
      defaultMessage: 'FAILED',
    },
    r_system_admin: {
      id: 'JobExecution.adminrole.SystemAdmin',
      defaultMessage: 'System Admin',
    },
    r_global_admin: {
      id: 'JobExecution.adminrole.GlobalAdmin',
      defaultMessage: 'Global Admin',
    },
    r_global_admin_ro: {
      id: 'JobExecution.adminrole.GlobalAdminRO',
      defaultMessage: 'Global Viewer',
    },
    r_deploy_admin: {
      id: 'JobExecution.adminrole.DeployAdmin',
      defaultMessage: 'Deployment Admin',
    },
    r_pp_admin: {
      id: 'JobExecution.adminrole.ProdProf',
      defaultMessage: 'Product Profile Admin',
    },
    r_prod_admin: {
      id: 'JobExecution.adminrole.ProdAdmin',
      defaultMessage: 'Product Admin',
    },
    r_ug_admin: {
      id: 'JobExecution.adminrole.UserGroup',
      defaultMessage: 'User Group Admin',
    },
    r_storage_admin: {
      id: 'JobExecution.adminrole.StorageAdmin',
      defaultMessage: 'Storage Admin',
    },
    r_support_admin: {
      id: 'JobExecution.adminrole.SupportAdmin',
      defaultMessage: 'Support Admin',
    },
    discard_msg1: {
      id: 'JobExecution.confirm.AreYouSure',
      defaultMessage: 'Are you sure you want to discard all edits?',
    },
    discard_msg2: {
      id: 'JobExecution.confirm.OnceDiscarded',
      defaultMessage: 'Once discarded, edits are not recoverable.',
    },
    discard_msg3: {
      id: 'JobExecution.confirm.DeleteRules',
      defaultMessage:
        'Organizations cannot be deleted if they have entitled users, Stock purchases, or instantiated storage products.',
    },
    PaneHelp: {
      id: 'Organizations.JobExecution.Helptext',
      defaultMessage:
        'Most changes made in the Global Admin Console are batched and executed as a job.  Edits are in the Pending state' +
        ' and shown here.  After reviewing pending changes, click Submit changes to indicate your approval and to begin' +
        ' execution of the changes.  Results of the current job and recently executed jobs are displayed below. ',
    },
    AdobeAgentWithNoOrgMessage: {
      id: 'Organizations.JobExecution.AdobeAgentWithNoOrgMessage.Message',
      defaultMessage: 'Select an org to see its jobs. Or make changes on the Adobe agent page.',
    },
    ConvertETLAContractForOrg: {
      id: 'Organizations.JobExecution.ConvertETLAContractForOrg.Message',
      defaultMessage: 'Integrate products in Org "{orgName}" into organization hierarchy',
    },
    /** TODO: Remove RetrofitAllocationsForOrg after 30 days as Retrofit command will be executed as part of Contract Switch command (ConvertETLAContractForOrg) */
    RetrofitAllocationsForOrg: {
      id: 'Organizations.JobExecution.RetrofitAllocationsForOrg.Message',
      defaultMessage: 'Retrofit allocations for org "{orgName}"',
    },
    RollbackAllocationContractToETLA: {
      id: 'Organizations.JobExecution.RollbackAllocationContractToETLA.Message',
      defaultMessage: 'Rollback allocation contract to ETLA contract for org "{orgName}"',
    },
    RollbackAllocationsForOrg: {
      id: 'Organizations.JobExecution.RollbackAllocationsForOrg.Message',
      defaultMessage: 'Rollback allocations for org "{orgName}"',
    },
  });

  componentWillUnmount(): void {
    this.mounted = false;

    // Persist data that may be needed on return to this page
    JobExecution.persistentState.editsInUI = this.state.editsInUI;
    JobExecution.persistentState.objectShadows = this.state.objectShadows;
    JobExecution.persistentState.versionAtDiff = this.state.versionAtDiff;
    JobExecution.persistentState.diffJobProgress = this.state.diffJobProgress;
    JobExecution.persistentState.taskDetails = this.state.taskDetails;
    JobExecution.persistentState.jobPageState = this.state.jobPageState;
    JobExecution.persistentState.recentJobList = this.state.recentJobList;
    JobExecution.persistentState.mostRecentJobState = this.state.mostRecentJobState;
    JobExecution.persistentState.updateOrgJobId = this.state.updateOrgJobId;

    this.abortController.abort();
  }

  /**
   * Wrapper for setState with abortController to avoid updating the state of the component after it has been unmounted.
   * Note, this wrapper does not support updating states through (state) => {newState} function. Call abortController explicitly in those cases.
   * @param state
   * @param callback
   */
  private setStateWrapper(state: Pick<JobExecutionState, never>, callback?: () => void): void {
    if (this.mounted) {
      this.setState(state, callback);
    }
  }

  /**
   * Consider reloading all data.  This is mostly to respond to the org picker selecting a different org.
   * We use OrgMaster tree which monitors current and new values of selected org
   */
  async componentDidUpdate(): Promise<void> {
    if (LoadOrgDataService.willRefresh()) {
      this.setStateWrapper({ jobPageState: JobPageState.Loading, diffJobTasks: [] });
      await LoadOrgDataService.initializeOrUpdateOrgMasterTree();
      this.setStateWrapper({ jobPageState: JobPageState.Nothing, editsInUI: [] });
      await this.componentDidMount(); // re-initialize page
    }
  }

  /**
   * Initialize component depending on:
   * A) runDiff==true means navigation was from Compartments or Product Allocation page.
   *     If so, initialize the page by running GET_ORG_DIFF.
   * B) topLevelDiffJobs means navigation was from Top Level orgs page.
   * C) Otherwise user clicked the tab navigation.
   */
  async componentDidMount(): Promise<void> {
    this.mounted = true;

    // When entering this page, the following logic should be followed:
    //  1. If the page state is set to loading, then load the last job
    //  2. If there are edits, display them
    //  3. If no job, no edits since last diff, no last diff, then query for previous job in this org and show results from that.
    //  4. If entry from top level migration, use changes supplied from that page.  Discard any other edits.
    //  5. If no job is running and there have been no edits since last DIFF run, continue to show same diff list.
    await LoadOrgDataService.initializeOrUpdateOrgMasterTree();

    const commands = CommandService.getEditsForJobExecutionPage();

    if (this.state.jobPageState === JobPageState.Loading) {
      // Nothing.  Wait for load complete
      this.setStateWrapper({ jobPageState: JobPageState.LoadLast });
    } else if (commands.length > 0) {
      const editsInUI: CommandValue[] = _.map(commands, (update: CommandInterface): CommandValue => {
        return {
          status: CommandStatus.PENDING,
          elem: update.elem,
          elemType: update.elemType,
          operation: update.operation,
          messageData: update.messageData,
        };
      });
      this.setStateWrapper({
        editsInUI, // show 'pending' tasks
        taskDetails: editsInUI,
        jobPageState: JobPageState.Pending, // enable Start button
        mostRecentJobState: null, // reset most recent job because diff would to be shown
      });
    } else if (this.state.editsInUI.length <= 0 || commands.length === 0) {
      // clean up state before loading data for recent job
      this.setStateWrapper({
        editsInUI: [],
        taskDetails: [],
        mostRecentJobState: null,
        recentJobList: [],
      });
      // Case 4.
      // C. Navigation to this page was via tabbed navigation, not "review..." button,
      // no edits and nothing running, then show last job
      this.loadMostRecentJob();
    }
    // None of the above is case 6.

    const isRWAdobeAgent = await AdminPermission.isRWAdobeAgent();
    this.setStateWrapper({ isRWAdobeAgent });
  }

  /**
   * UPDATE_ORG job can start if
   * 1. UPDATE_ORG job isn't already running && topLevelMoveJob exists
   * 2. OR An UPDATE_ORG job isn't already running AND there are pending changes to make (i.e. diff job results exist)
   *      AND The diff job completed successfully
   */
  private isOkToStart = (): boolean => {
    return this.state.jobPageState === JobPageState.Pending; // results in and ready to go.
  };

  /**
   * Remove unnecessary data from an elem prior to submitting to banyansvc. See BANY-1396
   * Currently this method only removes the product profiles from UProduct.
   *
   * @param command Command containing the elem to remove unnecessary data from
   * @returns Elem with unnecessary data removed
   */
  private static minimizeElemForUpdates(command: CommandValue): UBasicData {
    if (command.elemType === ObjectTypes.PRODUCT) {
      // Specifically for UProducts, remove the product profiles as these aren't used for product related commands
      const product: UProductData = _.cloneDeep(command.elem) as UProductData;
      if (!_.isEmpty(product.productProfiles)) {
        product.productProfiles = [];
        return product;
      }
    }
    return command.elem;
  }

  /**
   * Submit an UPDATE_ORG job using the edits stored in the UI
   * NOTE: if the user navigates away the job will continue to run.
   */
  private startUpdateOrgJob = async (): Promise<void> => {
    try {
      Analytics.fireCTAEvent('start clicked');
      log.info('STARTING UPDATE JOB');
      if (!this.isOkToStart()) {
        // this shouldn't be possible because start button uses the same logic
        log.error('Cannot start update job');
        return;
      }

      this.setStateWrapper({
        jobPageState: JobPageState.JobRunning, // show the spinner ASAP as the response from the service for create job call may take a while and disable the 'start' button ASA (The start button is shown only when jobPageState is PENDING, so setting the state to RUNNING would disable the start button)
        updateOrgJobId: '', // reset job id
        mostRecentJobState: null, // reset the mostRecent job
      });
      const updatesToMake: Update[] = _.map(this.state.editsInUI, (command) => {
        return {
          elem: JobExecution.minimizeElemForUpdates(command),
          elemType: command.elemType,
          operation: command.operation,
          messageData: command.messageData,
        };
      });
      // todo: if updatesToMake is empty, return now & show user a message.

      const activeOrgId = OrgPickerController.getActiveOrgId();
      const jobId = await BanyanJobs.createJob({
        dryRun: false,
        updates: updatesToMake,
        createdInOrg: !_.isNil(activeOrgId) ? activeOrgId : '',
      });
      // throw new Error('testing');
      this.setStateWrapper({
        updateOrgJobId: jobId, // store job ID so that it can be cancelled.
        jobPageState: JobPageState.JobRunning,
      });
      ClearOrgDataWrapper.clearEdits(); // clear out existing edits, if create job was successful

      const runningOrgUpdateJob: CancelPromise<JobProgressResponse | CancelStatus> = BanyanJobs.getJobProgress(
        jobId,
        activeOrgId as string,
        this.abortController.signal,
        this.handleOrgUpdateResponse
      );
      this.handleOrgUpdateCompletion(runningOrgUpdateJob);
    } catch (error) {
      this.setStateWrapper({
        errMsg: error.message,
        jobPageState: JobPageState.ErrorInCreateJob,
        updateOrgJobId: '',
        mostRecentJobState: null,
      });
    }
  };

  private backToEditing = async (): Promise<void> => {
    Analytics.fireCTAEvent('continue editing');
    const isAdobeAgentWithNoOrgs = await AdminPermission.isAdobeAgentWithNoOrgs();
    if (isAdobeAgentWithNoOrgs) {
      this.props.navigate(HeaderConstants.ORG_MAPPER_FOR_ADOBE_AGENT);
    } else {
      this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConstants.ORGANIZATIONS_URL));
    }
  };

  /**
   * Recent job list should not include the currently running job
   * @param recentJobList
   * @private
   */
  private removeCurrentJobFromRecentJobList(recentJobList: JobInfo[]): void {
    _.remove(recentJobList, (job: JobInfo) => job.id === this.state.mostRecentJobState?.id);
  }

  private updateJobInfoInRecentJobs(progressResponse: JobProgressResponse): void {
    if (this.mounted) {
      this.setState((state): any => {
        // Update job list data if we have it.
        const jobId: string = progressResponse.result.jobInfo.id;
        const recentJobs = state.recentJobList;
        if (recentJobs) {
          const match: number = recentJobs.findIndex((job) => job.id === jobId);
          if (match >= 0) {
            recentJobs[match] = progressResponse.result.jobInfo;
          } else {
            // add
            recentJobs.unshift(progressResponse.result.jobInfo);
          }
        }
        this.removeCurrentJobFromRecentJobList(recentJobs);
        return {
          recentJobList: _.cloneDeep(recentJobs),
        };
      });
    }
  }

  /**
   * Handle UPDATE_ORG job responses resulting from polling or completion.
   *
   * Updates the state so that the UI can render progress as it happens.
   *
   * @param progressResponse response from poll-for-progress or the final response.
   */
  private handleOrgUpdateResponse = (progressResponse: JobProgressResponse): void => {
    if (this.mounted) {
      this.setState((state): any => {
        // Update job list data if we have it.
        const isJobComplete: boolean = progressResponse.result.jobInfo.jobDone;

        return {
          mostRecentJobState: progressResponse.result.jobInfo,
          taskDetails: progressResponse.result.values,
          jobPageState: isJobComplete ? JobPageState.JobCompleted : state.jobPageState,
        };
      });
    }
  };

  /**
   *
   * @param runningJob
   * @param refreshDataAfterUpdate boolean to denote whether the data should be refreshed after update or not.
   * 1. When the job is submitted and it completes, the data should be refreshed.
   * 2. When the job is already running and it completes, then also data should be refreshed.
   * 3. But when only loading the last completed job, the data does not need to be refreshed.
   */
  private handleOrgUpdateCompletion = async (
    runningJob: CancelPromise<JobProgressResponse | CancelStatus>,
    refreshDataAfterUpdate: boolean = true
  ): Promise<void> => {
    try {
      // wait for UPDATE_ORG to finish
      const result: JobProgressResponse | CancelStatus | null = await runningJob.promise;
      const cancelStatus: CancelStatus = result as CancelStatus;
      if (cancelStatus.cancelled) {
        log.info('UPDATE_ORG job was cancelled');
        this.setStateWrapper({ jobPageState: JobPageState.Cancel });
      } else if (cancelStatus.timedout) {
        log.info(
          `UPDATE_ORG job progress polling timed out as no updates from server for more than ${
            Constants.JOB_PROGRESS_POLLING_TIMEOUT_IN_MS / 60000
          } mins`
        );
        this.setStateWrapper({ jobPageState: JobPageState.Timeout });
      } else {
        const jobStatus: JobProgressResponse = result as JobProgressResponse;
        this.handleOrgUpdateResponse(jobStatus);
      }
      if (refreshDataAfterUpdate) {
        // force refresh of tree. ATTENTION: the backend async microservices (like Fulfillment Needed) may not have completed yet
        ClearOrgDataWrapper.clearAllOrgs();
        await LoadOrgDataService.initializeOrUpdateOrgMasterTree();
      }
    } catch (error) {
      log.error('handleOrgUpdateCompletion failed', error.message);
      this.setStateWrapper({ jobPageState: JobPageState.Nothing }); // figure out empirically
    }
    log.info('UPDATE_ORG complete.');
    this.setStateWrapper({
      updateOrgJobId: null, // disable 'cancel' button
      diffJobProgress: null, // clear out old 'diff job' response to disable the 'start' button
    });
  };

  /**
   * This is run when the user navigates directly to the page (not via 'make it so').
   * This looks up the most recent update_org job from the DB (which may or may not still be running).
   */
  private loadMostRecentJob = async (): Promise<void> => {
    this.setStateWrapper({
      jobPageState: JobPageState.LoadLast,
      taskDetails: [], // clear out as we are about to load it
    });

    log.debug('Querying DB for most recent UPDATE_ORG job');
    let jobId;
    try {
      jobId = await BanyanJobs.getLatestUpdateJob();
    } catch (error) {
      this.setStateWrapper({
        errMsg: error.message,
        jobPageState: JobPageState.ErrorInGetJob,
      });
      return;
    }
    log.debug(`Most recent UPDATE_ORG job ID: ${jobId}`);
    // now get the details of the job, which may or may not still be running.
    if (!_.isEmpty(jobId)) {
      const jobresults = await BanyanJobsApi.getJobProgress(jobId, {
        firstIndex: 0,
        maxValues: 50000,
      });
      let jobPageState = JobPageState.JobCompleted;
      // check if the last job is still running, update the jobPageState
      if (JobExecution.isJobRunning(jobresults.result.jobInfo.jobState)) {
        jobPageState = JobPageState.JobRunning;
      }
      this.setStateWrapper({ jobPageState, updateOrgJobId: jobId, taskDetails: jobresults.result.values });

      // NOTE: when the job is still running, taskDetails will have list of completed and incomplete commands
      // The status for the incomplete commands will be fetched by polling via the below call to getJobProgress
      // when the job is completed (cancelled or completed), no polling will be done
      const jobProgress: CancelPromise<JobProgressResponse | CancelStatus> = BanyanJobs.getJobProgress(
        jobId,
        OrgPickerController.getActiveOrgId() as string,
        this.abortController.signal,
        this.handleOrgUpdateResponse
      );
      this.handleOrgUpdateCompletion(jobProgress, false);
    } else {
      this.setStateWrapper({ jobPageState: JobPageState.NoEdits });
    }
  };

  /**
   * This is run when the user navigates directly to the page (not via 'make it so'), and when past jobs are
   * selected to be displayed.
   * This looks up the most recent update_org job from the DB (which may or may not still be running).
   */
  private loadJobById = async (jobId: string): Promise<void> => {
    // now get the details of the job, which may or may not still be running.
    if (!_.isEmpty(jobId)) {
      // see if we have already gotten results for this job
      const currentResultsList: JobResultsMap = this.state.recentJobListTaskDetails;
      if (currentResultsList[jobId] && currentResultsList[jobId].results.length > 0) {
        return;
      }
      // Don't already have results.  Initiate load of results.
      const jobProgress: CancelPromise<JobProgressResponse | CancelStatus> = BanyanJobs.getJobProgress(
        jobId,
        OrgPickerController.getActiveOrgId() as string,
        this.abortController.signal,
        this.handleLoadJobResponse
      );
      this.handleInitialLoadJobResponse(jobProgress);
    }
  };

  // Handle actual results data from loadJobById and later iteractions.
  // This to handle the command results for jobs in 'Recent Jobs' category on Job Execution page
  private handleLoadJobResponse = (progressResponse: JobProgressResponse): void => {
    this.updateJobInfoInRecentJobs(progressResponse);
    // Pass a function to setState() to ensure that the latest taskDetails is used.  see https://reactjs.org/docs/faq-state.html
    if (this.mounted) {
      this.setState((state): any => {
        const currentResultsList: JobResultsMap = _.cloneDeep(state.recentJobListTaskDetails);
        currentResultsList[progressResponse.result.jobInfo.id] = {
          results: [],
        };
        currentResultsList[progressResponse.result.jobInfo.id].results = progressResponse.result.values;
        return {
          recentJobListTaskDetails: currentResultsList,
        };
      });
    }
  };

  // Handle response that comes back from the initial call in loadJobById.
  private handleInitialLoadJobResponse = async (
    job: CancelPromise<JobProgressResponse | CancelStatus>
  ): Promise<void> => {
    try {
      const result: JobProgressResponse | CancelStatus | null = await job.promise;
      const cancelStatus: CancelStatus = result as CancelStatus;
      if (cancelStatus.cancelled) {
        log.info('job load was cancelled');
      } else if (cancelStatus.timedout) {
        log.info(
          `job load progress polling timed out as no updates from server for more than ${
            Constants.JOB_PROGRESS_POLLING_TIMEOUT_IN_MS / 60000
          } mins`
        );
      } else {
        this.handleLoadJobResponse(result as JobProgressResponse);
      }
    } catch (error) {
      // promise was rejected. todo show error to user
      log.error(`handleInitialLoadJobResponse: ${error.message}`);
    }
  };

  /**
   * If an UPDATE_ORG job is running, submits the cancellation request to banyansvc.
   * Subsequently handleOrgUpdateResponse() will the UI with the 'cancelling' then 'cancelled' status.
   */
  private cancelUpdateOrgJob = async (): Promise<void> => {
    Analytics.fireCTAEvent('Job cancelled');
    const jobId = this.state.updateOrgJobId;
    this.setStateWrapper({
      updateOrgJobId: '', // disable the 'cancel' button ASAP to avoid double-submissions.
      jobPageState: JobPageState.Cancel,
    });
    if (jobId) {
      log.info(`Cancelling job ${jobId}`);
      try {
        await BanyanJobsApi.cancelJob(jobId);
      } catch (error) {
        // webapp was unreachable, the job ID couldn't be found in the DB, etc.
        this.setStateWrapper({
          updateOrgJobId: jobId, // restore jobId to make cancel button clickable
          errMsg: error.message,
          jobPageState: JobPageState.JobCompleted,
        });
      }
    }
  };

  /**
   * Called to revert local changes without running update job
   */
  private discardEdits = async (): Promise<void> => {
    if (this.isOkToStart()) {
      // Changes are present.
      // Revert them.
      const hasEditsForUserGroupSharing = CommandService.hasEditsForUserGroupSharing();
      ClearOrgDataWrapper.clearEdits();
      if (hasEditsForUserGroupSharing) {
        // If user group shares have been edited, we may lack the context to re-establish their past state
        // across org boundaries. The nuclear option is to clear the cached org data in the tree so that it
        // is reloaded from the server.
        ClearOrgDataWrapper.clearAllOrgs();
      }
      this.setStateWrapper({
        editsInUI: [], // Clear the diff output.
        taskDetails: [],
        jobPageState: JobPageState.NoEdits,
      });
    }
  };

  /**
   * Convert a JobValue into a user-visible string.
   * Examples:
   * "Add product {{elemName}} in compartment {{orgName}}"
   * "Update user with email {{email}} in compartment {{orgName}}"
   * "{{status}} :  {{errorMessage}}"
   * @param val An entry from JobProgressResponse.result.value[]
   *
   * This function is called for each entry that comes back from the service reporting progress.
   * There are three distinct cases:
   *    - the set of values that come back from diff
   *        The information available to generate the message is the UObject itself with the one server-provided
   *        supplement of oldParentId in the case of reparent.  The edited model is available for lookup.
   *    - the set of values that come back from update
   *        The service sends back a number of fields to help display meaningful user information including:
   *          orgPathname - in alomst all operation this is the newest pathname for the affected organization
   *          oldOrgPathname - in cases where the org name changed and the old name is known, this is included
   *          oldParentId - in cases of reparent where the oldOrgPathname is not known, this is sent instead
   *        Timing on the reload of the model may make the model unavailable for looking up information to
   *        display so the display should be self-contained where possible.
   *    - the set of values that come back from the "last" update
   *        The last update may have been run some time ago so needs to be displayed solely based on informatio
   *        in the database entries that are returned.
   *
   *  There is also a shadow cache where some ids are stored so that the display can be refreshed after changes are made.
   *  For example, the model ids may be gone when the final change reports come in so the update of the diff list
   *  depends on the cached model ids in the shadow cache.
   */
  // TODO: add param completed:boolean to drive past-tense phrases
  private jobValueToString(val: CommandValue): string {
    const { formatMessage } = this.props.intl;
    let message = '';
    const jobValue: CommandValue = val as CommandValue;
    // see str-en.json banyan.message.<ELEM>
    const info = jobValue.elem as ElemInfoWrap;

    switch (jobValue.elemType) {
      case ObjectTypes.ORGANIZATION: {
        const uorg = jobValue.elem as UOrg;
        // const parentOrgId: string = uorg.parentOrgId; // not used at the moment
        const orgId = uorg.id;
        let oldOrgPathname; // in case of rename or reparent, the is the "before" value
        let originalOrg; // in case an org has been renamed, originalOrg would be used to compute old name

        const orgPathname = _.result(info, 'elemInfo.orgPathname', (): string => {
          const pathName = this.state.objectShadows.getPathname(orgId);
          if (pathName === orgId) {
            // no pathName is available if pathName === orgId
            // trying to get orgName else orgId is used as default
            return _.result(info, 'name', orgId);
          }
          return pathName;
        });

        // As of this comment, service includes `elemInfo` as part of response only when `jobValue.operation` === `OrgOperation.REPARENT`.
        // todo: Service to include `elemInfo` as part of response when an org is renamed.
        // `elemInfo` is the only way to retrieve old path name once a job is submitted. When a response arrives, there's not sufficient information available for this.state.objectShadows.getPathname(originalOrg.parentOrgId) to generate correct old path.
        if (info && info.elemInfo && info.elemInfo.oldOrgPathname) {
          oldOrgPathname = info.elemInfo.oldOrgPathname; // fix bug https://jira.corp.adobe.com/browse/BANY-794) [Dont include "'/' + uorg.name;"]
        }
        let newOrgParent = ''; // new parent in case of reparent
        let uorg2: UOrgData;
        switch (jobValue.operation) {
          case OrgOperation.CREATE:
            message = formatMessage(this.messages.create_organization, { orgPathname }); // `Create organization "${orgPathname}"`,
            break;
          case OrgOperation.UPDATE: {
            const orgEdits = CommandService.getElementEdits(orgId, ObjectTypes.ORGANIZATION);
            const updateOrgEdit = _.filter(orgEdits, (edit) => edit.operation === OrgOperation.UPDATE);
            originalOrg = !_.isEmpty(updateOrgEdit) ? (updateOrgEdit[0].originalElem as UOrg) : undefined;
            if (!oldOrgPathname && originalOrg?.parentOrgId) {
              // if oldOrgPathname does not exist and originalOrg.parentOrgId exists, use data retrieved from "/ancestors" API to get full org path
              // (oldOrgPathname is set by backend in UPDATE command)
              oldOrgPathname = this.state.objectShadows.getPathname(originalOrg.parentOrgId) + '/' + originalOrg?.name;
            }
            if (oldOrgPathname !== undefined && uorg.name !== originalOrg?.name) {
              // `Update organization "${oldOrgPathname}" from "${originalOrg?.name}" to "${uorg.name}"`;
              // Show above message when org update involves only org rename and oldOrgPathname exists
              message = formatMessage(this.messages.update_org_name, {
                oldPath: oldOrgPathname,
                old: originalOrg?.name,
                new: uorg.name,
              });
            } else {
              message = formatMessage(this.messages.update_org, { orgPathname }); // `Update organization "${orgPathname}"`,
            }
            break;
          }
          case OrgOperation.DELETE:
            message = formatMessage(this.messages.delete_org, { orgPathname }); // `Delete organization "${orgPathname}"`,
            break;
          case OrgOperation.REPARENT:
            uorg2 = jobValue.elem as UOrgData;
            if (uorg2 && uorg2.parentOrgName) newOrgParent = uorg2.parentOrgName;
            else newOrgParent = orgPathname; // fix bug https://jira.corp.adobe.com/browse/BANY-794) [Dont include "'/' + uorg2.name;"]
            // TODO this message doesn't work in error cases or historical cases because the org tree has been restored to otherwise changed
            // Need string old-parent-org-pathname from command.
            message = formatMessage(this.messages.reparent_org, { oldOrgPathname, newOrgParent }); // `Change parent of organization "${oldOrgPathname}" to "${newOrgParent}"`;
            break;
          default:
        }
        break;
      }

      case ObjectTypes.PRODUCT: {
        const uprod = jobValue.elem as UProduct;
        const productName = uprod.name;
        const orgId: string = uprod.orgId;
        const orgParent: string = uprod.sourceProductOrgId;

        const orgPathname = info?.elemInfo?.orgPathname ?? this.state.objectShadows.getPathname(orgId);

        const resourceList = uprod.resources ? this.localizedProductResourcesImage(uprod.resources) : '';
        switch (jobValue.operation) {
          case OrgOperation.CREATE:
            message = formatMessage(this.messages.add_product, { productName, orgPathname, resourceList }); // `Add product "${productName}" in "${orgPathname}" with ${resourceList}`;
            break;
          case OrgOperation.UPDATE:
            message = formatMessage(this.messages.update_product, { productName, orgPathname, resourceList }); // `Update product "${productName}" in "${orgPathname}" to have ${resourceList}`;
            break;
          case OrgOperation.DELETE:
            message = formatMessage(this.messages.remove_product, { productName, orgPathname }); // `Remove product "${productName}" in "${orgPathname}"`;
            break;
          case OrgOperation.REPARENT:
            message = formatMessage(this.messages.reparent_product, {
              productName,
              orgPathname,
              newOrgParent: orgParent,
            }); // `Re-parent product "${productName}" in "${orgPathname}" to "${newOrgParent}"`
            break;
          default:
            message = formatMessage(this.messages.error_illegal_op); // 'Error: illegal operation';
        }
        break;
      }

      case ObjectTypes.ADMIN: {
        const uAdmin = jobValue.elem as UAdmin;
        const uadminemail = uAdmin.email;
        const orgId = uAdmin.orgId;
        const orgPathname = info?.elemInfo?.orgPathname ?? this.state.objectShadows.getPathname(orgId);

        const roleNames: string = uAdmin.updatedRoleNames();
        const localizedRoleNames: string[] = [];
        _.forEach(roleNames.split(UAdmin.ROLE_SEPARATOR), (role: string): void => {
          const localizedRoleName: string = this.localizedOrgAdminTypeImage(role as OrgAdminType);
          localizedRoleNames.push(localizedRoleName);
        });
        const rolenames = localizedRoleNames.join(UAdmin.ROLE_SEPARATOR);
        switch (jobValue.operation) {
          case OrgOperation.CREATE:
            message = formatMessage(this.messages.add_admin_roles, { uadminemail, orgPathname, rolenames }); // `Add admin ${uAdmin.email} in "${orgPathname}" with roles ${rolenames}`
            break;
          case OrgOperation.UPDATE:
            message = formatMessage(this.messages.update_admin_roles, { uadminemail, orgPathname, rolenames }); // `Update admin ${uAdmin.email} in "${orgPathname}" roles ${rolenames}`
            break;
          case OrgOperation.DELETE:
            message = formatMessage(this.messages.delete_all_roles_for_admin, { uadminemail, orgPathname }); // `Remove all admin roles for ${uAdmin.email} in "${orgPathname}"`
            break;
          default:
            message = formatMessage(this.messages.error_illegal_op);
        }
        break;
      }

      case ObjectTypes.USER_GROUP: {
        const uusergroup = jobValue.elem as UUserGroup;
        const orgId: string = uusergroup.orgId;
        const orgPathname = info?.elemInfo?.orgPathname ?? this.state.objectShadows.getPathname(orgId);
        const uusergroupname = uusergroup.name;
        switch (jobValue.operation) {
          case OrgOperation.CREATE:
            message = formatMessage(this.messages.create_ug, { uusergroupname, orgPathname }); // `Create User Group "${uusergroup.name}" in "${orgPathname}"`;
            break;
          case OrgOperation.UPDATE:
            message = formatMessage(this.messages.update_ug, { uusergroupname, orgPathname }); // `Update User Group "${uusergroup.name}" in "${orgPathname}"`;
            break;
          case OrgOperation.DELETE:
            message = formatMessage(this.messages.delete_ug, { uusergroupname, orgPathname }); // `Delete User Group "${uusergroup.name}" in "${orgPathname}"`;
            break;
          default:
            message = formatMessage(this.messages.error_illegal_op); // 'Error: illegal operation';
        }
        break;
      }

      case ObjectTypes.PRODUCT_PROFILE: {
        const upp = jobValue.elem as UProductProfile;
        const orgId: string = upp.orgId;

        const orgPathname = info?.elemInfo?.orgPathname ?? this.state.objectShadows.getPathname(orgId);

        // Try to find product name
        let productName = '';
        const org = HierarchyManager.getOrg(orgId);
        if (org && org.productsLoaded) {
          const product: UProduct | undefined = _.find(org.products, {
            id: upp.productId,
          });
          productName = product ? product.name : 'Unknown product';
        }
        if (productName.length > 0) {
          // cache name for later use in case info is gone
          this.state.objectShadows.add(upp.productId, productName);
        } else {
          productName = this.state.objectShadows.getName(upp.productId);
        }
        const uppname = upp.name;
        switch (jobValue.operation) {
          case OrgOperation.CREATE:
            message = formatMessage(this.messages.create_pp, { uppname, productName, orgPathname }); // `Create Product Profile "${upp.name}" of product "${productName}" in "${orgPathname}"`;
            break;
          case OrgOperation.UPDATE:
            message = formatMessage(this.messages.update_pp, { uppname, productName, orgPathname }); // `Update Product Profile "${upp.name}" of product "${productName}" in "${orgPathname}"`;
            break;
          case OrgOperation.DELETE:
            message = formatMessage(this.messages.delete_pp, { uppname, productName, orgPathname }); // `Delete Product Profile "${upp.name}" of product "${productName}" in "${orgPathname}"`;
            break;
          default:
            message = formatMessage(this.messages.error_illegal_op); // 'Error: illegal operation';
        }
        break;
      }

      case ObjectTypes.COMPARTMENT_POLICY: {
        const ucp = jobValue.elem as UCompartmentPolicies;
        const orgId: string = ucp.orgId;

        const orgPathname = _.result(info, 'elemInfo.orgPathname', (): string =>
          this.state.objectShadows.getPathname(orgId)
        );
        const policyImage: string = this.localizedPolicySetImage(ucp);
        switch (jobValue.operation) {
          case OrgOperation.CREATE:
            message = formatMessage(this.messages.create_pol, { orgPathname, policyImage }); // `Set Organization Policy in "${orgPathname}" with non-default`;
            break;
          case OrgOperation.UPDATE:
            message = formatMessage(this.messages.update_pol, { orgPathname, policyImage }); // `Update Organization Policy in "${orgPathname}"`;
            break;
          default:
            message = formatMessage(this.messages.error_illegal_op); // 'Error: illegal operation';
        }
        break;
      }

      case ObjectTypes.CONTRACT_REPARENT: {
        const uContractReparent = jobValue.elem as UContractReparent;
        const orgPathname =
          info?.elemInfo?.orgPathname ?? this.state.objectShadows.getPathname(uContractReparent.orgId);
        message = formatMessage(this.messages.reparent_contract, {
          contractId: uContractReparent.id,
          orgPathname,
          orgParent: uContractReparent.sourceOrgId,
          displayProductNames: uContractReparent.productNamesCommandParam(),
        });
        break;
      }

      case ObjectTypes.CONTRACT_SWITCH: {
        const contractSwitch = jobValue.elem as UContractSwitch;
        message = formatMessage(this.messages.ConvertETLAContractForOrg, { orgName: contractSwitch.orgName });
        break;
      }

      case ObjectTypes.ALLOCATION_RETROFIT: {
        const contractSwitch = jobValue.elem as UAllocationRetrofit;
        message = formatMessage(this.messages.RetrofitAllocationsForOrg, { orgName: contractSwitch.orgName });
        break;
      }

      case ObjectTypes.CONTRACT_ROLLBACK: {
        const contractRollback = jobValue.elem as UContractRollback;
        message = formatMessage(this.messages.RollbackAllocationContractToETLA, { orgName: contractRollback.orgName });
        break;
      }

      case ObjectTypes.ALLOCATION_ROLLBACK: {
        const allocationRollback = jobValue.elem as UAllocationRollback;
        message = formatMessage(this.messages.RollbackAllocationsForOrg, { orgName: allocationRollback.orgName });
        break;
      }

      default: {
        const { name = '' } = jobValue.elem as any;
        message = `Unknown object type ${jobValue.elemType} ${name}`;
      }
    }
    return message;
  }

  /**
   * Returns a user friendly string message describing the product resources
   * For e.g. for 1000 Users and 100 GB resourceList, returns "1,000 User, 100 GB"
   * NOTE: the value 1000 is formatted as per the locale selected by the user
   * @param resourceList resource list of the Product
   */
  localizedProductResourcesImage(resourceList: UResource[]): string {
    if (resourceList) {
      const { formatNumber } = this.props.intl;
      return resourceList
        .filter((resource: UResource): boolean => resource.fulfillableItemType === FulfillableItemType.QUOTA)
        .map((resource: UResource): string => {
          if (!Utils.canParseInt(resource.grantedQuantity)) {
            return `${Utils.localizeUnlimitedValue(resource.grantedQuantity)} ${Utils.localizedResourceUnit(
              resource.unit
            )}`;
          }
          return `${formatNumber(parseInt(resource.grantedQuantity, 10))} ${Utils.localizedResourceUnit(
            resource.unit
          )}`;
        })
        .join(', ')
        .trim();
    }
    return '';
  }

  // Compute a localized image of the compartment policies that are modified
  localizedPolicySetImage(ucp: UCompartmentPolicies): string {
    let result: string = '';
    if (ucp.policies) {
      const isNewOrg = ucp.orgId.startsWith(TEMP_ID_PREFIX);
      const policyEdits = CommandService.getElementEdits(ucp.orgId, ObjectTypes.COMPARTMENT_POLICY);
      const originalUCompartmentPolicies = _.isEmpty(policyEdits)
        ? undefined
        : (policyEdits[0].originalElem as UCompartmentPolicies);
      if ('claimDomains' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.claimDomains,
          this.messages.pol_claim_domains,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('createChildren' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.createChildren,
          this.messages.pol_create_children,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('manageAdmins' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.manageAdmins,
          this.messages.pol_manage_admins,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('renameOrgs' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.renameOrgs,
          this.messages.pol_rename_org,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('deleteOrgs' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.deleteOrgs,
          this.messages.pol_delete_org,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('inheritAdmins' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.inheritAdmins,
          this.messages.pol_inherit_admins,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('inheritUsers' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.inheritUsers,
          this.messages.pol_inherit_users,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('injectGroup' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.injectGroup,
          this.messages.pol_dup_directories,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('allowAdobeID' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.allowAdobeID,
          this.messages.pol_adobeid,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('manageUserGroups' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.manageUserGroups,
          this.messages.pol_manage_user_groups,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('changeIdentityConfig' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.changeIdentityConfig,
          this.messages.pol_change_id,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('directoryMove' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.directoryMove,
          this.messages.pol_move_dir,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('manageProducts' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.manageProducts,
          this.messages.pol_manage_products,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('nonChildAllocation' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.nonChildAllocation,
          this.messages.pol_non_parent_alloc,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('setSharingPolicy' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.setSharingPolicy,
          this.messages.pol_change_asset_sharing,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('inheritSharingPolicy' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.inheritSharingPolicy,
          this.messages.pol_inherit_sharing,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('setWorkspacesPolicy' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.setWorkspacesPolicy,
          this.messages.pol_set_workspaces_policy,
          originalUCompartmentPolicies,
          isNewOrg
        );
      if ('inheritWorkspacesPolicy' in ucp.policies)
        result += this.localizedPolicyImage(
          ucp.policies.inheritWorkspacesPolicy,
          this.messages.pol_inherit_workspaces_policy,
          originalUCompartmentPolicies,
          isNewOrg
        );
    }
    return result;
  }

  // return null string if policy is default value
  localizedPolicyImage(
    policy: OrganizationPolicy,
    message: object,
    originalUCompartmentPolicies: UCompartmentPolicies | undefined,
    isNewOrg: boolean
  ): string {
    const { formatMessage } = this.props.intl;
    let messageToReturn = "'" + formatMessage(message) + '=';
    let isValueModified: boolean = true;
    let isLockModified: boolean = true;
    if (
      (originalUCompartmentPolicies &&
        policy.name &&
        originalUCompartmentPolicies.policies[policy.name] &&
        originalUCompartmentPolicies.policies[policy.name].value === policy.value) ||
      (isNewOrg && policy.value === policy.defaultValue)
    ) {
      isValueModified = false;
    }
    if (
      (originalUCompartmentPolicies &&
        policy.name &&
        originalUCompartmentPolicies.policies[policy.name] &&
        originalUCompartmentPolicies.policies[policy.name].lockedBy === policy.lockedBy) ||
      (isNewOrg && policy.lockedBy === undefined)
    ) {
      isLockModified = false;
    }
    if (isValueModified) {
      const policyValueLocalized = policy.value
        ? formatMessage(this.messages.pol_value_allowed)
        : formatMessage(this.messages.pol_value_notAllowed);
      messageToReturn += policyValueLocalized;
    }
    if (isLockModified) {
      const policyLockLocalized = policy.lockedBy
        ? formatMessage(this.messages.pol_gettingLocked)
        : formatMessage(this.messages.pol_gettingUnlocked);
      messageToReturn += isValueModified ? ', ' + policyLockLocalized : policyLockLocalized;
    }
    return messageToReturn + "' ";
  }

  static formatDateTime(d: Date): React.ReactNode {
    return (
      <React.Fragment>
        <FormattedDate value={d} />
        &nbsp;&nbsp;
        <FormattedTime value={d} />
      </React.Fragment>
    );
  }

  // Return a user display duration string of the form "43 s" for times less than 1 minute, and "3.4 m" for times one minute or more.
  static formatDuration(createTime: string, updateTime: string, status: JobResultState): string {
    let durationMS: number;
    if (JobExecution.isJobRunning(status)) durationMS = Date.now().valueOf() - new Date(createTime).valueOf();
    else durationMS = new Date(updateTime).valueOf() - new Date(createTime).valueOf();
    let durationMin = durationMS / 60000;
    if (durationMin >= 1) {
      durationMin = Math.trunc(durationMin * 10) / 10;
      // todo localize `m` and `s`, once https://git.corp.adobe.com/Project-Banyan/banyanui/pull/512 has been merged.
      return durationMin.toString() + 'm';
    }

    const durationSec = Math.round(((durationMS % 86400000) % 3600000) / 1000);
    return durationSec.toString() + 's';
  }

  displayJobSteps(jobInfo: JobInfo): React.ReactNode {
    const jobId = jobInfo.id;
    const commandsInfo = this.state.recentJobListTaskDetails[jobId];
    return (
      <div>
        {!Utils.isEmptyString(jobId) && (
          <span className="JobExecution__jobNumber">
            <FormattedMessage id="JobExecution.jobId" defaultMessage="Job Number" /> {`: ${jobId}`}
          </span>
        )}
        {commandsInfo && (
          <ScrollableContent
            className="JobExecution__recentJobsSteps"
            uniqueId={`JobExecution__recentJobsStepsID_${jobId}`}
            height="350px"
            dataTestId={`JobExecution__recentJobsStepsID_${jobId}`}
          >
            <Table className="JobExecution__ChangesTable">
              <TBody>
                {commandsInfo.results
                  .sort((a, b) => _.get(a, 'executionOrder', 0) - _.get(b, 'executionOrder', 0))
                  .map((val: CommandValue, index): React.ReactNode => {
                    return this.getCommandResultInfo(val, index, jobInfo, false);
                  })}
              </TBody>
            </Table>
          </ScrollableContent>
        )}
        {(commandsInfo === undefined || commandsInfo.results.length === 0) && <Wait size="M" />}
      </div>
    );
  }

  /**
   * A job is running if it is in RUNNING, WAITING, WAITINGFORDURATION, CANCELLING or WAITINGANDCANCELLING status
   * @param jobResultState JobResultState
   */
  static isJobRunning(jobResultState: JobResultState): boolean {
    return (
      jobResultState === JobResultState.RUNNING ||
      jobResultState === JobResultState.CANCELLING ||
      jobResultState === JobResultState.WAITING ||
      jobResultState === JobResultState.WAITINGFORDURATION ||
      jobResultState === JobResultState.WAITINGANDCANCELLING
    );
  }

  /**
   * Given jobInfo, this returns the job icon and error message if any
   */
  getJobStateDetails = (job: JobInfo): React.ReactNode => {
    if (JobExecution.isJobRunning(job.jobState)) {
      return <Wait size="S" />;
    }
    if (job.jobStatus === JobResultStatus.CANCELLED) {
      return (
        <span>
          <CancelIcon size="S" />
          &nbsp;
          <FormattedMessage id="JobExecution.recentJobs.cancelled" defaultMessage="Job was cancelled" />
        </span>
      );
    }
    if (
      job.jobDone &&
      (_.isEqual(job.jobStatus, JobResultStatus.FAILED) ||
        _.isEqual(job.jobStatus, JobResultStatus.COMPLETED_WITH_ERRORS))
    ) {
      return (
        <span>
          <AlertIcon
            size="S"
            className="JobExecution__recentJobs__alertIcon"
            data-testid={`recent-job-failed-${job.id}`}
          />
          {job.errorData && ' ' + Utils.localizeErrorData(job.errorData)}
        </span>
      );
    }
    if (job.jobDone && _.isEqual(job.jobStatus, JobResultStatus.SUCCEEDED)) {
      return (
        <CheckmarkCircle
          size="S"
          className="JobExecution__recentJobs__checkMark"
          alt="Job succeeded"
          data-testid={`recent-job-succeeded-${job.id}`}
        />
      );
    }
    return null;
  };

  /**
   * Given the Command data and index , the method will return the Command status info and icons
   * to be displayed in the status column
   * @param val
   * @param index
   * @param jobInfo
   * @param useTestId
   */
  getCommandResultInfo(val: CommandValue, index: number, jobInfo: JobInfo | null, useTestId: boolean): React.ReactNode {
    const msgdata = val.messageData;
    let displayElem;
    // Most of the commands have only one string description. Cases like different types of updates on a single entity can
    // have more than one description(ex: org name change and org country code change in same command)
    if (msgdata && msgdata.cmdDescription && msgdata.cmdDescription.length > 0) {
      displayElem = val.executionOrder ? (
        // When the job has been started and there is an execution order assigned, we show number labels for the descriptions
        <ul className="JobExecution__recentJobsSteps__commandDescrpnsNumberList">
          {_.map(msgdata.cmdDescription, (descrpn: CmdDescriptionCodesData, i): React.ReactNode => {
            return (
              <li key={i}>
                <span className="JobExecution__recentJobsSteps__commandDescrpnsNumberItemLabel">
                  {msgdata.cmdDescription.length > 1 ? `${val.executionOrder}.${i + 1}` : `${val.executionOrder}`}
                </span>
                <span className="JobExecution__recentJobsSteps__commandDescrpn_spacing">
                  {CmdDescriptionUtils.computeAndLocalizeCommandDescriptionsFromStoredImages(descrpn)}
                </span>
              </li>
            );
          })}
        </ul>
      ) : (
        // We show a list with bullet points for each description when there are pending changes and job has not been started yet
        <ul className="JobExecution__recentJobsSteps__commandDescrpnsList">
          {_.map(msgdata.cmdDescription, (descrpn: CmdDescriptionCodesData, i): React.ReactNode => {
            return (
              <li key={i} className="JobExecution__recentJobsSteps__commandDescrpnsListItem">
                <span
                  className="JobExecution__recentJobsSteps__commandDescrpn_spacing"
                  data-testid="cmd-description-text"
                >
                  {CmdDescriptionUtils.computeAndLocalizeCommandDescriptionsFromStoredImages(descrpn)}
                </span>
              </li>
            );
          })}
        </ul>
      );
    } else {
      // If there are no stored command images. This can happen when we introduce new commands
      // in the future.
      displayElem = val.executionOrder ? (
        <ul className="JobExecution__recentJobsSteps__commandDescrpnsNumberList">
          <li>
            <span className="JobExecution__recentJobsSteps__commandDescrpnsNumberItemLabel">{val.executionOrder}</span>
            <span>{this.jobValueToString(val)}</span>
          </li>
        </ul>
      ) : (
        <ul className="JobExecution__recentJobsSteps__commandDescrpnsList">
          <li className="JobExecution__recentJobsSteps__commandDescrpnsListItem">{this.jobValueToString(val)}</li>
        </ul>
      );
    }

    return (
      <TR key={index} data-testid={useTestId ? `job-execution-pending-change-${index}` : ''}>
        <TD className="JobExecution__recentJobsSteps__commandInfo">{displayElem}</TD>
        <TD className="JobExecution__recentJobsSteps__commandStatus">
          <StatusLight variant={this.getStatusLightVariant(val.status)}>
            {this.mapAndLocalizeCommandStatus(val, jobInfo)} &nbsp;
            {msgdata?.errorData ? Utils.localizeErrorData(msgdata.errorData) : ''}
          </StatusLight>
          {msgdata?.warningData && msgdata.warningData.length > 0 && (
            <div className="JobExecution__recentJobsSteps__commandNotes">
              {_.map(msgdata.warningData, (warn: ErrorCodesData): React.ReactNode => {
                return (
                  <Fragment>
                    <InfoOutline className="JobExecution__recentJobsSteps__commandNotes__icon" size="XS" /> &nbsp;
                    <span>{Utils.localizeErrorData(warn)}</span>
                    <br />
                  </Fragment>
                );
              })}
            </div>
          )}
        </TD>
      </TR>
    );
  }

  /** This method returns the job progress and status to be displayed in the job execution page.
   *  - For every job, calculate the number of commands which were run, the number which resulted in errors and the total number
   * of commands. This will be the progress of the job.
   * ex: Changes: 5 of 8 run, errors: 2
   *     Changes: 8 of 8 run, errors: 1
   * the job status info can be running/completed/cancelling.
   *  - We also show a progress bar while the job is running. The bar will represent the progress in terms of the
   *  number of commands run out of the total commands along with the percentage
   *  - for a cancelled/failed job or a job which has been completed with some error messages, we do not show any extra
   * job status message
   *
   * @param jobInfo - For a current job, can be null if it is not populated with the right job info data
   * @param isCurrentJob - determines if it is the current job in the GAC UI, so that we know how to get data about the
   *                       job tasks
   */
  getJobProgressInfo(jobInfo: JobInfo | null, isCurrentJob: boolean): React.ReactNode {
    let errors = 0;
    let commandsRun = 0;
    let jobStatusMsg;
    let commandProgressMsg;
    let isJobComplete = false;
    const { formatMessage } = this.props.intl;

    if (!jobInfo) {
      return;
    }
    const jobState = jobInfo.jobState;
    isJobComplete = jobState === JobResultState.COMPLETED;

    if (isJobComplete) {
      // If the job has any error data or if the job has been cancelled or the job failed, then we dont show any
      // job status message as there is already some data displayed about the job for these cases.
      if (
        !(
          jobInfo.errorData ||
          jobInfo.jobStatus === JobResultStatus.CANCELLED ||
          // The "FAILED" status is deprecated and will be removed soon.
          jobInfo.jobStatus === JobResultStatus.FAILED
        )
      ) {
        jobStatusMsg = formatMessage(this.messages.job_state_completed);
      }
    } else if (
      // The job is 'running' if it has the following states
      jobState === JobResultState.RUNNING ||
      jobState === JobResultState.WAITING ||
      jobState === JobResultState.WAITINGFORDURATION
    ) {
      jobStatusMsg = formatMessage(this.messages.job_state_running);
    } else if (jobState === JobResultState.CANCELLING || jobState === JobResultState.WAITINGANDCANCELLING) {
      // when job cancel is in progress
      jobStatusMsg = formatMessage(this.messages.job_state_cancelling);
    }

    // get the commands information from the state task details for the current job
    // and from the recent jobs list other wise
    const commandsInfo = isCurrentJob
      ? this.state.taskDetails
      : this.state.recentJobListTaskDetails[jobInfo.id]?.results;
    _.forEach(commandsInfo, (task: CommandValue): void => {
      if (task.status === CommandStatus.FAILED) {
        errors++;
        commandsRun++;
      } else if (task.status === CommandStatus.SUCCEEDED) {
        commandsRun++;
      }
    });

    if (commandsInfo?.length > 0) {
      commandProgressMsg = formatMessage(this.messages.command_progress, {
        commandsRun,
        commands: commandsInfo.length,
        errors,
      });
    }

    return (
      <span>
        {jobStatusMsg} &nbsp;
        {commandProgressMsg} &nbsp;
        {!isJobComplete ? (
          <Progress
            className="JobExecution__jobProgressBar"
            min={0}
            max={commandsInfo?.length}
            value={commandsRun}
            size="S"
            showPercent
          />
        ) : (
          ''
        )}
      </span>
    );
  }
  /**
   * Given a ISO8601 basic notation timestamp, convert it to extended notation.
   *
   * Why: Safari and old versions of IE do not support basic notation of ISO 8601 format
   *
   * Ref: http://farai.github.io/blog/2015/02/16/javascript-nan-for-new-date-object-in-ie-and-safari/
   * TODO: Update service to return extended notation instead of basic notation and remove regex code from UI
   */
  static toExtendedISO8601(basicISO8601: string): string {
    return basicISO8601?.replace(/([+-]\d\d)(\d\d)$/, '$1:$2');
  }

  render(): React.ReactNode {
    // Compute the current state for the display
    const okToStart = this.isOkToStart();
    const canCancel: boolean =
      !_.isEmpty(this.state.updateOrgJobId) && this.state.jobPageState === JobPageState.JobRunning;
    const { formatMessage } = this.props.intl;
    const recentJobList = this.state.recentJobList;

    if (!CommandService.anyEdits() && OrgPickerController.getOrgDataList().length === 0) {
      // no jobs to display if there are no edits and no activeorg is selected
      return <AdobeAgentWithNoOrgMessage displayMessage={formatMessage(this.messages.AdobeAgentWithNoOrgMessage)} />;
    }
    return (
      <div className="App__content" data-testid="job-execution-page">
        {(this.state.isRWAdobeAgent || !AdminPermission.readOnlyMode()) && (
          <React.Fragment>
            <div className="JobExecution__buttonBar">
              <Heading className="App__header">
                <FormattedMessage id="JobExecution.title.Jobexecution" defaultMessage="Job execution and history" />
                <GoHelpBubble goUrlKey={GoUrlKeys.jobExecution} classNameToUse="HelpBubble__helpBubble_left">
                  <>{formatMessage(this.messages.PaneHelp)}</>
                </GoHelpBubble>
              </Heading>
              <div className="App__headerButtons">
                <Button
                  variant="secondary"
                  onClick={this.cancelUpdateOrgJob}
                  disabled={!canCancel}
                  data-testid="job-execution-cancel-running-job"
                >
                  <FormattedMessage id="JobExecution.button.CancelRunningJob" defaultMessage="Cancel Running Job" />
                </Button>
                <ModalTrigger>
                  <Button
                    variant="secondary"
                    disabled={!okToStart}
                    onClick={(): void => Analytics.fireCTAEvent('Discard Pending Changes clicked')}
                    data-testid="job-execution-discard-pending-changes"
                  >
                    <FormattedMessage
                      id="JobExecution.button.DiscardPendingChanges"
                      defaultMessage="Discard Pending Changes"
                    />
                  </Button>
                  <Dialog
                    variant="destructive"
                    title={formatMessage(this.messages.warning)}
                    confirmLabel={formatMessage(this.messages.ok)}
                    onConfirm={(): void => {
                      this.discardEdits();
                    }}
                    cancelLabel={formatMessage(this.messages.cancel)}
                    data-testid="discard-pending-changes-confirm-dialog"
                  >
                    {formatMessage(this.messages.discard_msg1) /* // FormattedMessage in this context doesn't work */}
                    <br />
                    <br />
                    {formatMessage(this.messages.discard_msg2)}
                    <br />
                    <br />
                    {formatMessage(this.messages.discard_msg3)}
                  </Dialog>
                </ModalTrigger>
                <Button variant="secondary" onClick={this.backToEditing} data-testid="job-execution-return-to-edit">
                  <FormattedMessage id="JobExecution.button.ContinueEditing" defaultMessage="Return to edit" />
                </Button>
                <Button
                  variant="cta"
                  onClick={this.startUpdateOrgJob}
                  disabled={!okToStart}
                  data-testid="job-execution-submit-change"
                >
                  <FormattedMessage id="JobExecution.button.Start" defaultMessage="Submit changes" />
                </Button>
              </div>
            </div>
            <Rule variant="small" />
          </React.Fragment>
        )}
        {this.state.jobPageState === JobPageState.Nothing && (
          // JobPageState.Nothing is initial state of JobExecution page.
          // Show loading symbol while waiting for GET /hierarchy call to finish.
          // Fix https://jira.corp.adobe.com/browse/BANY-819
          <Heading variant="subtitle1">
            <Wait size="M" />
          </Heading>
        )}
        {this.state.jobPageState === JobPageState.Loading && (
          <Heading variant="subtitle1">
            <Wait size="M" />
          </Heading>
        )}
        {!(this.state.jobPageState === JobPageState.Loading) && (
          <div className="JobExecution__wrapper">
            {/* new combined table */}

            <div>
              {this.state.jobPageState === JobPageState.RunDiff && (
                <Heading variant="subtitle1">
                  <FormattedMessage id="JobExecution.Analyzing" defaultMessage="Analyzing changes ..." />
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.Pending && (
                <Heading variant="subtitle1">
                  <FormattedMessage id="JobExecution.PendingChanges" defaultMessage="Pending changes" /> (
                  {this.state.taskDetails.length})
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.NoEdits && (
                <Heading variant="subtitle1">
                  <FormattedMessage id="JobExecution.NoChanges" defaultMessage="No changes detected" />
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.JobRunning && (
                <div>
                  <Heading variant="subtitle1">
                    <FormattedMessage id="JobExecution.JobRunning" defaultMessage="Job running" />
                  </Heading>
                  {/* display wait till the create job call is successful */}
                  {_.isEmpty(this.state.updateOrgJobId) && <Wait size="S" />}
                </div>
              )}
              {this.state.jobPageState === JobPageState.Cancel && (
                <div>
                  <Heading variant="subtitle1">
                    <FormattedMessage id="JobExecution.JobCancelling" defaultMessage="Job running - Cancelling" />
                  </Heading>
                </div>
              )}
              {this.state.jobPageState === JobPageState.Timeout && (
                <Heading variant="subtitle1">
                  <FormattedMessage
                    id="JobExecution.JobProgressPollingTimeout"
                    defaultMessage="Job running - Job progress polling timed-out. Refresh to retrieve current job state"
                  />
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.LoadLast && (
                <Heading variant="subtitle1">
                  <FormattedMessage
                    id="JobExecution.GatheringDetails"
                    defaultMessage="Gathering details of most recent job..."
                  />
                  <Wait size="S" data-testid="spinner" />
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.JobCompleted && (
                <Heading variant="subtitle1">
                  <FormattedMessage id="JobExecution.LastJobcompleted" defaultMessage="Last Job completed" />
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.ErrorInCreateJob && (
                <Heading variant="subtitle1">
                  <FormattedMessage id="JobExecution.ErrorInCreateJob" defaultMessage="Error in creating job" />
                  <AlertIcon size="S" className="JobExecution__jobFailed__alertIcon" />
                </Heading>
              )}
              {this.state.jobPageState === JobPageState.ErrorInGetJob && (
                <Heading variant="subtitle1">
                  <FormattedMessage id="JobExecution.ErrorInGetJob" defaultMessage="Error while loading job data" />
                  <AlertIcon size="S" className="JobExecution__jobFailed__alertIcon" />
                </Heading>
              )}
              {(this.state.jobPageState === JobPageState.JobRunning ||
                this.state.jobPageState === JobPageState.Cancel ||
                this.state.jobPageState === JobPageState.JobCompleted) &&
                this.state.mostRecentJobState && (
                  <span>
                    {JobExecution.formatDateTime(
                      new Date(JobExecution.toExtendedISO8601(this.state.mostRecentJobState.updatedAt))
                    )}
                    &nbsp;&nbsp; (
                    <FormattedMessage id="JobExecution.runtime" defaultMessage="runtime" />{' '}
                    {JobExecution.formatDuration(
                      JobExecution.toExtendedISO8601(this.state.mostRecentJobState.createdAt),
                      JobExecution.toExtendedISO8601(this.state.mostRecentJobState.updatedAt),
                      this.state.mostRecentJobState.jobState
                    )}
                    ) &nbsp;&nbsp; <FormattedMessage id="JobExecution.submittedBy" defaultMessage="Submitted by" />
                    {': '}
                    {this.state.mostRecentJobState.ownerEmail} &nbsp;&nbsp;
                    {this.getJobStateDetails(this.state.mostRecentJobState)}&nbsp;&nbsp;
                    {this.getJobProgressInfo(this.state.mostRecentJobState, true)}
                  </span>
                )}
              {this.state.mostRecentJobState && !Utils.isEmptyString(this.state.mostRecentJobState.id) ? (
                <span className="JobExecution__jobNumber">
                  <FormattedMessage id="JobExecution.jobId" defaultMessage="Job Number" />{' '}
                  {`: ${this.state.mostRecentJobState.id}`}
                </span>
              ) : null}
              {
                /* Show table only when changes exist */
                this.state.taskDetails.length > 0 && (
                  <Table className="JobExecution__ChangesTable" data-testid="JobExecution__ChangesTable">
                    <THead>
                      <TH className="JobExecution__recentJobsSteps__commandInfo">
                        <span className="JobExecution__recentJobsSteps__commandInfoHeading">
                          <FormattedMessage id="JobExecution.tableheader.CommandInfo" defaultMessage="Change" />
                        </span>
                      </TH>
                      <TH className="JobExecution__recentJobsSteps__commandStatus">
                        <FormattedMessage id="JobExecution.tableheader.CommandStatus" defaultMessage="Status" />
                      </TH>
                    </THead>
                    <TBody>
                      {this.state.taskDetails
                        .sort((a, b) => _.get(a, 'executionOrder', 0) - _.get(b, 'executionOrder', 0))
                        .map((val: CommandValue, index): React.ReactNode => {
                          return this.getCommandResultInfo(val, index, this.state.mostRecentJobState, true);
                        })}
                    </TBody>
                  </Table>
                )
              }
              <br />
              <br />
            </div>

            {/*
        CHANGES - PENDING CHANGES - results of DIFF job - Active execution - or completed execution
        */}
            {this.state.errMsg && (
              <div>
                <Alert header={formatMessage(this.messages.error)} variant="error">
                  {this.state.errMsg}
                </Alert>
              </div>
            )}

            {this.state.initializingMsg && (
              <Heading variant="subtitle1">
                <Wait size="S" /> {this.state.initializingMsg}
              </Heading>
            )}
          </div>
        )}
        <Accordion
          className="JobExecution__recentJobs__Accordion"
          multiselectable
          onChange={async (): Promise<void> => {
            Analytics.fireCTAEvent('recent_jobs accordion clicked');
            if (!this.state.isLoadedAllRecentJobs) {
              // Get recent jobs list
              let recentJobs;
              try {
                recentJobs = await BanyanJobs.getRecentUpdateJobs();
                for (let i = 0; i < recentJobs.length; i++) {
                  if (recentJobs[i].jobState !== JobResultState.COMPLETED) {
                    // continue polling for the running jobs such that the job icon (spinner) can be updated
                    // even if user does not expand the accordion for that job
                    this.loadJobById(recentJobs[i].id);
                  }
                }
                this.removeCurrentJobFromRecentJobList(recentJobs);
              } catch (error) {
                this.setStateWrapper({
                  errMsg: error.message,
                  jobPageState: JobPageState.ErrorInGetJob,
                });
                return;
              }
              this.setStateWrapper({ recentJobList: recentJobs, isLoadedAllRecentJobs: true });
            }
          }}
        >
          <AccordionItem
            header={
              <div className="JobExecution__recentJobs__Accordion_heading">
                {formatMessage(this.messages.recent_jobs)}
              </div>
            }
          >
            {!this.state.isLoadedAllRecentJobs && this.state.jobPageState !== JobPageState.ErrorInGetJob && (
              <Wait size="M" data-testid="spinner" />
            )}
            {this.state.isLoadedAllRecentJobs && _.isEmpty(recentJobList) && (
              <div>{formatMessage(this.messages.no_jobs_submitted)}</div>
            )}
            {this.state.isLoadedAllRecentJobs && recentJobList && (
              <div>
                <br />
                {recentJobList.map((val: JobInfo): React.ReactNode => {
                  const jobInfo = (
                    <div className="JobExecution__recentJobs">
                      {JobExecution.formatDateTime(new Date(JobExecution.toExtendedISO8601(val.createdAt)))}{' '}
                      &nbsp;&nbsp; (
                      <FormattedMessage id="JobExecution.recentJobs.runTime" defaultMessage="runtime" /> &nbsp;
                      {JobExecution.formatDuration(
                        JobExecution.toExtendedISO8601(val.createdAt),
                        JobExecution.toExtendedISO8601(val.updatedAt),
                        val.jobState
                      )}
                      ) &nbsp;&nbsp;
                      <FormattedMessage id="JobExecution.recentJobs.ownerEmail.by" defaultMessage="by" />: &nbsp;
                      {val.ownerEmail} &nbsp;&nbsp;
                      {this.getJobStateDetails(val)}&nbsp;&nbsp;
                      {this.getJobProgressInfo(val, false)}
                    </div>
                  );
                  return (
                    <Accordion
                      key={val.id}
                      onChange={async (e): Promise<void> => {
                        if (e != null) {
                          if (this.state.recentJobListTaskDetails[val.id] === undefined) {
                            // only trigger polling if it isn't already attached
                            await this.loadJobById(val.id);
                          }
                        }
                      }}
                      data-testid={`recentJobs_accordian_${val.id}`}
                    >
                      <AccordionItem header={jobInfo}>{this.displayJobSteps(val)}</AccordionItem>
                    </Accordion>
                  );
                })}
              </div>
            )}
          </AccordionItem>
        </Accordion>
      </div>
    );
  }

  getStatusLightVariant = (status: string): 'positive' | 'negative' | 'neutral' => {
    switch (status) {
      case 'SUCCEEDED':
        return 'positive';
      case 'FAILED':
        return 'negative';
      default:
        return 'neutral'; // can't localize unknown
    }
  };

  // Return the localized status of a job command step.  Note that this is not from a well-defined set of values.
  mapAndLocalizeCommandStatus(command: CommandValue, jobInfo: JobInfo | null): string {
    const { formatMessage } = this.props.intl;

    // ------- if job create operation failed ---------
    if (this.state.jobPageState === JobPageState.ErrorInCreateJob) {
      return formatMessage(this.messages.command_status_not_run_as_job_create_failed);
    }

    // ------- if command status is present (that is, command completed) -------
    if (command.status === CommandStatus.SUCCEEDED) {
      const data = command.messageData;
      return data?.warningData && data?.warningData.length > 0
        ? formatMessage(this.messages.command_status_success_with_notes)
        : formatMessage(this.messages.command_status_succeeded);
    }
    if (command.status === CommandStatus.FAILED) {
      return formatMessage(this.messages.command_status_failed);
    }

    // ------- if job state is completed or cancelled (that is, job finished) -----
    if (jobInfo) {
      // the order of these checks is important because CANCELLED jobs are also COMPLETED jobs.
      if (jobInfo.jobStatus === JobResultStatus.CANCELLED) {
        return formatMessage(this.messages.command_status_not_run_due_to_cancel);
      }
      // check for COMPLETED jobs are not CANCELLED
      // if the job is COMPLETED and there is no command status then it can be because of error in some other command or the job failed entirely
      if (jobInfo.jobState === JobResultState.COMPLETED) {
        return formatMessage(this.messages.command_status_not_run_due_to_error);
      }
    }

    // -------- else no command status and job not finished yet ------
    return formatMessage(this.messages.command_status_pending);
  }

  localizedOrgAdminTypeImage(adminType: OrgAdminType): string {
    const { formatMessage } = this.props.intl;
    switch (adminType) {
      case OrgAdminType.ORG_ADMIN:
        return formatMessage(this.messages.r_system_admin);
      case OrgAdminType.COMPARTMENT_ADMIN:
        return formatMessage(this.messages.r_global_admin);
      case OrgAdminType.COMPARTMENT_VIEWER:
        return formatMessage(this.messages.r_global_admin_ro);
      case OrgAdminType.DEPLOYMENT_ADMIN:
        return formatMessage(this.messages.r_deploy_admin);
      case OrgAdminType.LICENSE_ADMIN:
        return formatMessage(this.messages.r_pp_admin);
      case OrgAdminType.PRODUCT_ADMIN:
        return formatMessage(this.messages.r_prod_admin);
      case OrgAdminType.USER_GROUP_ADMIN:
        return formatMessage(this.messages.r_ug_admin);
      case OrgAdminType.STORAGE_ADMIN:
        return formatMessage(this.messages.r_storage_admin);
      case OrgAdminType.SUPPORT_ADMIN:
        return formatMessage(this.messages.r_support_admin);
      default:
        return `Unknown Admin Type: ${adminType}`;
    }
  }
}

export default injectIntl(withRouter(JobExecution));
export const TestJobExecution = JobExecution;
