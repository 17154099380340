import * as _ from 'lodash';
import { OrgOperation, ErrorData } from './OrgMaster';

/* eslint-disable import/no-cycle */
import { UBasic, UBasicData } from './UBasic';
import { UOrgMaster } from './UOrgMaster';
/* eslint-enable import/no-cycle */

/**
 * Defines policies for an org.
 */

/**
 * Defines type of an Organization policy as defined in backend.
 */
export class OrganizationPolicy {
  name?: string;
  type?: string;
  value?: any;
  defaultValue?: any;
  lockedBy?: string;
  updatedBy?: string;
  updatedAt?: string;

  constructor(
    name?: string,
    type?: string,
    value?: any,
    defaultValue?: any,
    lockedBy?: string,
    // Following fields "updatedBy" and "updatedAt" to be removed as part of BANY-1664
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updatedBy?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updatedAt?: string
  ) {
    this.name = name;
    this.type = type;
    this.value = value;
    this.defaultValue = defaultValue;
    this.lockedBy = lockedBy;
    this.updatedBy = '';
    this.updatedAt = '';
  }
}

/**
 * Policies that currently do not support modification.
 * Ref: https://jira.corp.adobe.com/browse/BANY-810
 */
export const policiesNotSupportedYet = new Set(['claimDomains', 'changeIdentityConfig']);

/**
 * Represent a key-value map, where key would be policy name and value would be instance of OrganizationPolicy
 */
export interface PolicyMap {
  [policyName: string]: OrganizationPolicy; // map for OrganizationPolicy.name => OrganizationPolicy
}

/**
 * Mutable object for UCompartmentPolicies.
 * Provides object containing only properties for UCompartmentPolicies.
 * Represents UCompartmentPolicies object that can be retrieved from back-end.
 */
export interface UCompartmentPoliciesData extends UBasicData {
  orgId?: string;
  policies?: PolicyMap;
}

/**
 * UCompartmentPolicies that also contains the methods and functionality.
 */
export class UCompartmentPolicies extends UBasic implements UCompartmentPoliciesData {
  public static readonly POLICY_PREFIX = 'POLICY_';
  orgId: string = '';
  policies: PolicyMap = {};

  /**
   * Constructs a UCompartmentPolicies from either UCompartmentPoliciesData or creates a copy of another UCompartmentPolicies object.
   */
  constructor(compartmentPolicy?: UCompartmentPoliciesData) {
    super(compartmentPolicy);
    if (compartmentPolicy) {
      this.orgId = compartmentPolicy.orgId || this.orgId;
      this.policies = _.cloneDeep(compartmentPolicy.policies) || {};
    }
  }

  /**
   * JSON representation of UCompartmentPolicies.
   */
  toJSON(): object {
    return _.assign(super.toJSON(), {
      orgId: this.orgId,
      policies: this.policies,
    });
  }

  /**
   * Retrieves the compartment policy or null if it is deleted.
   */
  getCleanCopy(): UCompartmentPolicies | null {
    if (this.isDeleted()) {
      return null;
    }
    return this;
  }

  /**
   * Define default policies for a new org
   *
   * TODO: These default policies to be fetched from banyansvc.
   */
  setDefaultPolicies(orgId?: string): void {
    if (orgId) {
      this.orgId = orgId; // This is required for setting policies on new org.
    }

    this.policies.createChildren = {
      name: 'createChildren',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.manageAdmins = {
      name: 'manageAdmins',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.renameOrgs = {
      name: 'renameOrgs',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.deleteOrgs = {
      name: 'deleteOrgs',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.inheritAdmins = {
      name: 'inheritAdmins',
      type: 'boolean',
      value: false,
      defaultValue: false,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.inheritUsers = {
      name: 'inheritUsers',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.injectGroup = {
      name: 'injectGroup',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.claimDomains = {
      name: 'claimDomains',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.allowAdobeID = {
      name: 'allowAdobeID',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.manageUserGroups = {
      name: 'manageUserGroups',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.changeIdentityConfig = {
      name: 'changeIdentityConfig',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.directoryMove = {
      name: 'directoryMove',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.manageProducts = {
      name: 'manageProducts',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.nonChildAllocation = {
      name: 'nonChildAllocation',
      type: 'boolean',
      value: false,
      defaultValue: false,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.setSharingPolicy = {
      name: 'setSharingPolicy',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.inheritSharingPolicy = {
      name: 'inheritSharingPolicy',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.setWorkspacesPolicy = {
      name: 'setWorkspacesPolicy',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
    this.policies.inheritWorkspacesPolicy = {
      name: 'inheritWorkspacesPolicy',
      type: 'boolean',
      value: true,
      defaultValue: true,
      lockedBy: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
    };
  }

  isEqual(otherPolicies: UCompartmentPolicies): boolean {
    const policyNames = Object.keys(this.policies);
    return !_.find(
      policyNames,
      (policyName) =>
        this.policies[policyName].value !== otherPolicies.policies[policyName].value ||
        this.policies[policyName].lockedBy !== otherPolicies.policies[policyName].lockedBy
    );
  }

  /**
   * Method for adding a policy to an org or executing other operations.
   * Delete and Update operations will set delete or update state for given elem.
   * Created operation will add the given elem to the given org.
   *   - org: An org that the elem should be imported to or the elem should associated with to perform an operation.
   *   - elem: UCompartmentPolicy to perform the opration on.
   *   - operation: The operation to perform.
   * This method returns an error object or an array of errors, or a null value denoting success.
   */
  static importElement(org: UOrgMaster, elem: UBasicData, operation: OrgOperation): ErrorData | ErrorData[] | null {
    return UBasic.importElement(
      org,
      elem,
      operation,
      (): UBasic => org.compartmentPolicy,
      (e: UBasicData): ErrorData | ErrorData[] | null => {
        org.addCompartmentPolicy(new UCompartmentPolicies(e));
        return null;
      }
    );
  }

  public static removeEditsIfSameAsOriginal = (
    editsToPolicies: UCompartmentPolicies,
    originalPolicies: UCompartmentPolicies | undefined
  ): UCompartmentPolicies => {
    const editsToReturn: UCompartmentPolicies = _.cloneDeep(editsToPolicies);
    Object.values(editsToPolicies.policies).forEach((organizationPolicy) => {
      if (
        originalPolicies &&
        organizationPolicy.name &&
        organizationPolicy.value === originalPolicies.policies[organizationPolicy.name].value &&
        organizationPolicy.lockedBy === originalPolicies.policies[organizationPolicy.name].lockedBy
      ) {
        delete editsToReturn.policies[organizationPolicy.name];
      } else if (
        // For new org, compare against default values
        !originalPolicies &&
        organizationPolicy.name &&
        organizationPolicy.value === organizationPolicy.defaultValue &&
        organizationPolicy.lockedBy === undefined
      ) {
        delete editsToReturn.policies[organizationPolicy.name];
      } else if (organizationPolicy.name) {
        // This is temporary solution to address BANY-1663. Should be removed as part of BANY-1664
        editsToReturn.policies[organizationPolicy.name].updatedBy = '';
        editsToReturn.policies[organizationPolicy.name].updatedAt = '';
      }
    });
    return editsToReturn;
  };
}
