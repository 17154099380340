import React from 'react';
import _ from 'lodash';
import * as log from 'loglevel';

import Button from '@react/react-spectrum/Button';
import { SelectOption } from '@react/react-spectrum/Select';
import SelectList from '@react/react-spectrum/SelectList';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import Provider from '@react/react-spectrum/Provider';
import ComboBox from '@react/react-spectrum/ComboBox';
import Search from '@react/react-spectrum/Search';
import { Grid, GridColumn, GridRow } from '@react/react-spectrum/Grid';
import Wait from '@react/react-spectrum/Wait';

import SearchOrgProvider, { SearchOrgResult } from '../../../providers/SearchOrgProvider';

import SearchType from './SearchType';
import './SearchOrg.css';
import Analytics from '../../../Analytics/Analytics';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import ScrollableContent from '../../../Compartments/EditCompartment/Widgets/ScrollableContent';

interface SearchOrgState {
  displayedOrgs: SelectOption[];
  selectedOrgsDisplayed: SelectOption[]; // is list of all Orgs selected by the user and currently displayed in selectlist
  errorMessage: string;
  searchTypeSearched: string;
  searchTypeDisplayed: string;
  orgsSearchedTerm: string; // search term for which the org list is fetched. NOTE: the user can update the search in Search box (without an enter) and scroll in the org list, in which case, the records should be fetched for the old search term which is tracked by searchTypeSearched
  displayedSearchTerm: string; // search term currently displayed in the Search box
  pageNumber: number;
  nextPageAvailable: boolean; // tells if there are more records that can be fetched
  loadStatus: string;
  isLoading: boolean;
}

export interface SearchOrgProps {
  selectedOrgs: SelectOption[];
  multipleSelection?: boolean;
  addSelectedOrgIds: (orgsToBeAdded: SelectOption[]) => void;
  removeSelectedOrgIds: (orgsToBeRemoved: string[]) => void;
  label: string;
  disallowOrgSelection: boolean;
  displaySelectedOrgsAsTagList: boolean; // display orgs as tag list (list displayed on the right side of the screen)
  scrollableHeight: string; // height for the org list. height is dependent on the space the particular page has
  searchedByType?: (searchType: string) => void; // search type filter used when searching for orgs
}

class SearchOrg extends React.Component<SearchOrgProps, SearchOrgState> {
  public static EMPTY_LIST_MSG = 'The list is currently empty.';
  static shouldReloadSelectedOrgsDisplayed = true; //  should selectedOrgsDisplayed be re-populated from the props
  searchDebounced = undefined;

  /**
   * For every selected Org, check if this Org is displayed currently on the screen
   * @returns list of all the selected Orgs by the user which are displayed on the screen
   */
  static findSelectedOrgDisplayed = (displayedOrgs: SelectOption[], selectedOrgs: SelectOption[]): SelectOption[] => {
    const selectedOrgsDisplayed: SelectOption[] = [];

    // Loop for every selected Org by the user
    _.forEach(selectedOrgs, (selectedOrg: SelectOption): void => {
      // check if the org is being displayed, if yes, add to the selectedOrgsDisplayed array
      if (
        _.find(displayedOrgs, (displayedOrg: SelectOption): boolean => {
          return displayedOrg.value === selectedOrg.value;
        })
      ) {
        selectedOrgsDisplayed.push(selectedOrg);
      }
    });
    return selectedOrgsDisplayed;
  };

  constructor(props: SearchOrgProps) {
    super(props);
    this.state = {
      searchTypeDisplayed: SearchType.PartialName,
      searchTypeSearched: SearchType.PartialName,
      selectedOrgsDisplayed: [],
      orgsSearchedTerm: '',
      displayedSearchTerm: '',
      displayedOrgs: [],
      errorMessage: '',
      pageNumber: 0,
      nextPageAvailable: false,
      loadStatus: SearchOrg.EMPTY_LIST_MSG,
      isLoading: false,
    };
    this.abortController = new AbortController();
    // User can click search multiple times. make API calls only once within a span of 0.5 second (determined empirically).
    const DEBOUNCE_TIME = 500;
    this.orgSearchDebounced = _.debounce(this.searchOrgs, DEBOUNCE_TIME);
  }

  private static getDerivedStateFromProps = (
    nextProps: SearchOrgProps,
    prevState: SearchOrgState
  ): Pick<SearchOrgState, never> => {
    // Only if the props have changed, update the selectedOrgsDisplayed
    if (SearchOrg.shouldReloadSelectedOrgsDisplayed) {
      SearchOrg.shouldReloadSelectedOrgsDisplayed = false;
      return {
        selectedOrgsDisplayed: SearchOrg.findSelectedOrgDisplayed(prevState.displayedOrgs, nextProps.selectedOrgs),
      };
    }
    return {};
  };

  componentWillUnmount = (): void => {
    // Abort all the fetch calls when the component is unmounted
    if (this.abortController) {
      this.abortController.abort();
    }
  };

  orgSearchDebounced = (): void => {
    this.searchOrgs();
  };

  /**
   * Renders the items which includes the item's label and detail button.
   * @param org is each item from the list.
   * @return a Detail button that shows the org details
   */
  private renderOrgFunc = (org: SelectOption): React.ReactNode => {
    return (
      <div className="SearchOrg__selectListElem">
        <span className="SearchOrg__selectListColumn1">{org.label}</span>
        <span className="SearchOrg__selectListColumn2">{org.value}</span>
      </div>
    );
  };

  /**
   * User reached the bottom of the list, load more records if available
   */
  handleScroll = (): void => {
    if (!this.state.nextPageAvailable) return;

    // load more data as user has reached the bottom of the list
    this.setState(
      (prevState: SearchOrgState): Pick<SearchOrgState, never> => {
        return {
          pageNumber: prevState.pageNumber + 1,
          loadStatus: 'Loading more...',
          isLoading: true,
        };
      },
      async (): Promise<void> => {
        await this.loadNextPage(this.state.orgsSearchedTerm);
      }
    );
  };

  /**
   * Searches for Orgs Page by Page.
   * @searchFor is the text to search for.
   */
  searchOrgs = async (): Promise<void> => {
    const searchFor = this.state.orgsSearchedTerm;

    // If search term is blank || search term length < 3, do not make the API call
    // GIL requires the search term to be of 3 or more characters
    if (_.isEqual(searchFor, '') || searchFor.length < 3) {
      this.setState({ errorMessage: 'Please enter 3 or more characters.' });
      return;
    }

    // Display the Fetching Org message until the request is still pending
    // Clear the displayed records for the new search term
    this.setState(
      {
        displayedOrgs: [],
        pageNumber: 0,
        nextPageAvailable: false,
        loadStatus: 'Loading orgs...',
        isLoading: true,
        errorMessage: '',
      },
      (): void => {
        this.loadNextPage(searchFor);
      }
    );
  };

  /**
   * *** Handling special characters remaining ***
   * converts the incoming string by appending %2b (concatenation) token in front of every word in the search string
   * @returns the properly formatted search string
   */
  processSearchTerm = (searchTerm: string): string => {
    const alphanumericString = searchTerm.split(/\s+/);
    const processedStringArray: string[] = [];
    alphanumericString.forEach((elem: string): void => {
      // %2b is database representation for '+' sign
      processedStringArray.push(`%2b${elem} `);
    });
    return processedStringArray.join('').trim();
  };

  /**
   * Gets the next page of records from the server and updates the displayed records asynchronously
   */
  loadNextPage = async (searchFor: string): Promise<void> => {
    let displayedOrgs: SelectOption[] = [];
    let errorMessage = '';
    let nextPageAvailable = true;

    // update parent if needed about the searchType ('Partial name', 'ECC end user ID', etc) used for searching
    if (this.props.searchedByType) {
      this.props.searchedByType(this.state.searchTypeSearched);
    }

    /*
     * *** Case not handled when multiple requests are made for the same search Term. ***
     */

    // Convert the Search Term in correct format only when searched by Partial Name
    const searchForProcessedString =
      this.state.searchTypeSearched === SearchType.PartialName ? this.processSearchTerm(searchFor) : searchFor;

    try {
      // Fetch next page of the records
      const searchResult = await SearchOrgProvider.searchOrg(
        this.state.searchTypeSearched,
        searchForProcessedString,
        this.state.pageNumber,
        this.abortController
      );

      // 500 records are requested from the server and if < 500 records are received,
      // no more records are left to be fetched.
      if (searchResult.length < SearchOrgProvider.pageSize) {
        nextPageAvailable = false;
      }

      searchResult.forEach((item: SearchOrgResult): void => {
        if (!_.isEmpty(item.id)) {
          const selectOption: SelectOption = {
            label: item.name,
            value: item.id,
          };
          displayedOrgs.push(selectOption);
        }
      });
    } catch (error) {
      // Do not show the abort message to the user.
      // AbortError occurs when component is unmounted and so there is no state to be updated
      if (error.name && _.isEqual(error.name, 'AbortError')) {
        return;
      }
      log.error(error.message);
      errorMessage = error.message;
      displayedOrgs = [];
    }

    /*
     * *** STOP PREVIOUS SEARCH QUERY TO OVERRIDE ***
     * When a older search org query takes more time than a newer query, the results from the
     * older query should not update the current results displayed for the newer query.
     * this.props.searchedOrgs is the lastest query searched by the user
     */
    if (_.isEqual(searchFor, this.state.orgsSearchedTerm)) {
      this.setState((prevState: SearchOrgState): Pick<SearchOrgState, never> => {
        // concatenate the existing records with the newly fetched records
        const newDisplayedOrgs = _.concat(prevState.displayedOrgs, displayedOrgs);
        let loadStatusNew = '';
        if (errorMessage.length > 0) loadStatusNew = '';
        else
          loadStatusNew = nextPageAvailable
            ? `Loaded ${this.formatNumber(newDisplayedOrgs.length)} orgs. Scroll to the bottom to load more.`
            : `Total records: ${this.formatNumber(newDisplayedOrgs.length)}`;

        const selectedOrgsDisplayed = SearchOrg.findSelectedOrgDisplayed(newDisplayedOrgs, this.props.selectedOrgs);

        return {
          selectedOrgsDisplayed,
          displayedOrgs: newDisplayedOrgs,
          errorMessage,
          nextPageAvailable,
          loadStatus: loadStatusNew,
          isLoading: false,
        };
      });
    }
  };

  /**
   * Format the Number of records in proper format.
   * e.g. 10000 records is displayed as 10,000
   */
  formatNumber = (num: number): string => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  /*
   * *** Converts the Orgs selected by the user to array of Org ID's ***
   * NEEDED Because: an Org can be deselected from the list of selected Orgs displayed on the right side of the screen
   * and to deselect Org on SelectList via Code, we update its value field
   * @returns the list of Org Id's that are selected by the user
   */
  selectedOrgsToOrgIDArray = (): string[] => {
    const { selectedOrgs } = this.props;
    return selectedOrgs.map((org: SelectOption): string => org.value);
  };

  /*
   * When only a single item can be selected from the Org List
   * To deselect all Orgs, return a empty list
   * @returns the Org Id of the selected Org
   */
  selectedSingleOrgToOrgIDString = (): string | string[] => {
    const { selectedOrgs } = this.props;
    return selectedOrgs.length === 1 ? selectedOrgs[0].value : [];
  };

  onChange = (orgIdsSelected: string[] | string): void => {
    /**
     * *** orgIdsSelected would be array of ID's in case when multiple Orgs can be selected in the SelectList ***
     * *** orgIdsSelected would be a single string ID in case when a single Org can be selected ***
     */

    const newOrgsSelected: SelectOption[] = [];

    /**
     * Initially, add all the Orgs selected and displayed on the screen to the orgsToBeRemoved list
     */
    let orgsToBeRemoved = this.state.selectedOrgsDisplayed;
    if (this.props.multipleSelection) {
      /**
       * CASE When User can select Multiple Org, the orgIdsSelected would be
       * an array of ids
       */

      (orgIdsSelected as string[]).forEach((orgSelectedAsString: string): void => {
        const org: SelectOption | undefined = this.state.displayedOrgs.find((orgOption: SelectOption): boolean =>
          _.isEqual(orgOption.value, orgSelectedAsString)
        );

        if (org) {
          newOrgsSelected.push(org);

          // As the Org is still selected by the user, remove it from the orgsToBeRemoved list
          // At the end, orgsToBeRemoved would have all the Orgs which were selected and displayed earlier but unselected now
          orgsToBeRemoved = _.remove(
            orgsToBeRemoved,
            (selectedOrgDisplayed: SelectOption): boolean => org.value !== selectedOrgDisplayed.value
          );
        }
      });
    } else {
      /**
       * *** Single Org Mode ***
       */

      const org: SelectOption | undefined = this.state.displayedOrgs.find((orgOption: SelectOption): boolean => {
        return orgOption.value === orgIdsSelected;
      });

      if (org) {
        newOrgsSelected.push(org);
      }
    }
    const orgsToBeRemovedLst = orgsToBeRemoved.map((orgToBeRemoved): string => orgToBeRemoved.value);
    SearchOrg.shouldReloadSelectedOrgsDisplayed = true;

    // if orgsToBeRemovedLst is not empty, an org was removed.
    // In Single Org select mode, ignore the org to be removed and replace with the new org to be added
    if (orgsToBeRemovedLst.length > 0 && this.props.multipleSelection)
      this.props.removeSelectedOrgIds(orgsToBeRemovedLst);
    else this.props.addSelectedOrgIds(newOrgsSelected);
  };

  /**
   * Select all the organizations displayed in the table if NOT already selected
   */
  selectAllOrgs = (): void => {
    const { selectedOrgs } = this.props;
    const { displayedOrgs } = this.state;

    // orgs displayed in the table which are currently NOT selected
    const displayedOrgsNotSelected: SelectOption[] = [];
    _.forEach(displayedOrgs, (dispOrg: SelectOption) => {
      if (!_.find(selectedOrgs, (selectedOrg: SelectOption) => selectedOrg.value === dispOrg.value)) {
        displayedOrgsNotSelected.push(dispOrg);
      }
    });

    if (displayedOrgsNotSelected.length > 0) {
      this.props.addSelectedOrgIds(displayedOrgsNotSelected);
    }
  };

  /**
   * Copies the search term displayed on the screen into orgsSearchedTerm state variable.
   * SCENARIO: user searches for 'Phil Corp' having more than 100 records. The user now updates
   * the search term to 'abc' without pressing an enter or clicking Search Org.
   * When the user now scrolls & reaches the bottom of the record list, the next page of the records
   * are fetched for 'Phil Corp' and not 'abc'
   * orgsDisplayedTerm keeps track of current displayed term on the screen ('abc' in this case)
   * orgsSearchedTerm keeps track of the search term for which records are to be fetched ('Phil Corp' in this case)
   */
  updateSearchTerm = (): void => {
    Analytics.fireCTAEvent(`searching orgs for ${this.props.label}`);
    this.setState(
      (prevState: SearchOrgState): Pick<SearchOrgState, never> => {
        return {
          orgsSearchedTerm: prevState.displayedSearchTerm.trim(),
          searchTypeSearched: prevState.searchTypeDisplayed.trim(),
        };
      },
      (): void => {
        this.orgSearchDebounced();
      }
    );
  };

  // Used to abort any pending fetch requests
  abortController: AbortController;

  render = (): React.ReactNode => {
    return (
      <div>
        <div>
          <div className="SearchOrg__container">
            <ComboBox
              className="SearchOrg__combobox"
              value={this.state.searchTypeDisplayed}
              options={Object.values(SearchType)}
              onChange={(newSearchType: string) => this.setState({ searchTypeDisplayed: newSearchType })}
              data-testid="SearchOrg__combobox"
            />
            <Search
              value={this.state.displayedSearchTerm}
              onChange={(value: string): void => {
                this.setState({ displayedSearchTerm: value });
              }}
              onSubmit={this.updateSearchTerm}
              placeholder="Search (enter 3 or more characters)"
              className="SearchOrg__searchBox"
            />
            <Button onClick={this.updateSearchTerm}>Search</Button>
            {this.state.isLoading && <Wait size="S" className="SearchOrg__wait" />}
          </div>
          {this.state.errorMessage.length > 0 && (
            <div className="SearchOrg__errorMessage">{this.state.errorMessage}</div>
          )}
        </div>

        <Grid variant="fluid">
          <GridRow>
            <GridColumn className="SearchOrg__selectListContainer" size={[9, 9, 9]}>
              <div className="SearchOrg__selectListHeaderContainer">
                <span className="SearchOrg__selectListHeaderColumn1">ORGANIZATION NAME</span>
                <span className="SearchOrg__selectListHeaderColumn2">ORGANIZATION ID</span>
              </div>
              <ScrollableContent
                uniqueId="OrgMapper__AdobeAgent__SearchOrg"
                onScroll={this.handleScroll}
                enableInfiniteScroll
                hasMorePages={this.state.nextPageAvailable}
                height={this.props.scrollableHeight}
                className="SearchOrg__scrollableContainer"
                dataTestId="SearchOrg_displayedOrgs"
              >
                <SelectList
                  options={this.state.displayedOrgs}
                  renderItem={this.renderOrgFunc}
                  className="SearchOrg__selectList"
                  value={
                    this.props.multipleSelection
                      ? this.selectedOrgsToOrgIDArray()
                      : this.selectedSingleOrgToOrgIDString()
                  }
                  onChange={this.onChange}
                  multiple={this.props.multipleSelection}
                  disabled={this.props.disallowOrgSelection}
                />
              </ScrollableContent>
              <div className="SearchOrg__footerContainer">
                <div className="SearchOrg__loadStatus">{this.state.loadStatus}</div>
                {/*  Select all button is ONLY displayed when multiple selection is allowed */}
                {this.props.multipleSelection && (
                  <div className="SearchOrg__selectAll">
                    <Button variant="primary" onClick={this.selectAllOrgs}>
                      Select all
                    </Button>
                  </div>
                )}
              </div>
            </GridColumn>
            {this.props.displaySelectedOrgsAsTagList && (
              <GridColumn size={[2, 2, 2]}>
                <div className="SearchOrg__selectedOrgList">
                  {_.map(this.props.selectedOrgs, (tagList: SelectOption): React.ReactNode => {
                    return (
                      <Provider
                        key={tagList.value}
                        locale={LocaleSettings.getSelectedLanguageTagForProvider()}
                        style={{ background: '#fcfcfc' }}
                      >
                        <TagList
                          onClose={(): void => {
                            SearchOrg.shouldReloadSelectedOrgsDisplayed = true;
                            this.props.removeSelectedOrgIds([tagList.value]);
                          }}
                          disabled={this.props.disallowOrgSelection}
                        >
                          <Tag>{tagList.label}</Tag>
                        </TagList>
                      </Provider>
                    );
                  })}
                </div>
              </GridColumn>
            )}
          </GridRow>
        </Grid>
      </div>
    );
  };
}

export default SearchOrg;
