import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import React, { ReactElement } from 'react';
import HelpOutline from '@react/react-spectrum/Icon/HelpOutline';
import GoUrl, { GoUrlKeys } from '../GoUrl/GoUrl';

interface GoHelpBubbleProps extends WrappedComponentProps {
  children: ReactElement;
  goUrlKey: GoUrlKeys;
  classNameToUse?: string;
}

const bubbleMessages = defineMessages({
  LearnMore: {
    id: 'HelpBubble.LearnMore',
    defaultMessage: 'Learn more',
  },
});

function GoHelpBubble(props: Omit<GoHelpBubbleProps, 'ref'>): React.ReactElement {
  return (
    <span>
      <OverlayTrigger placement="right">
        <div className={props.classNameToUse ? props.classNameToUse : 'HelpBubble__helpBubble'}>
          <GoUrl goUrlKey={props.goUrlKey} variant="quiet" target="gac_help">
            <HelpOutline />
          </GoUrl>
        </div>
        <Tooltip variant="info" className="HelpBubble__toolTip">
          {props.children}
          <GoUrl goUrlKey={props.goUrlKey} className="HelpBubble__link" target="gac_help" variant="overBackground">
            {props.intl.formatMessage(bubbleMessages.LearnMore)}
          </GoUrl>
        </Tooltip>
      </OverlayTrigger>
    </span>
  );
}

export default injectIntl(GoHelpBubble);

// If we ever need a non-go version of GoHelpBubble, a "HelpBubble" component using regular links can be implemented here
// Note: GoHelpBubble will no longer be the default export if "HelpBubble" is implemented
