/**************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 **************************************************************************/

import { defineMessages, MessageDescriptor } from 'react-intl';
import config from '../configurations/config';
import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';
import Utils from '../services/utils/Utils';
import { OrgReparentSharedGroupsReport, ReparentIds } from '../services/reparent';

export const localeMessages: Record<string, MessageDescriptor> = defineMessages({
  GetReparentReportApiError: {
    id: 'OrgReparentCheckProvider.GetReport.Error',
    defaultMessage: 'Unable to load validation data for reparent operation',
  },
});

class OrgReparentSharedGroupsCheckProvider {
  /**
   * Retrieves whether there are any issues or info for the user in re-parenting shared user groups.
   * This api handles multiple re-parent org operations at once but is limited on the server to 100
   * operations.
   */
  static async orgReparentSharedGroupsCheck(
    multipleReparentIds: ReparentIds[]
  ): Promise<OrgReparentSharedGroupsReport> {
    const response: Response = await fetch(`${config.banyansvc.url}/check-reparent-user-groups`, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(multipleReparentIds),
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, localeMessages.GetReparentReportApiError);
    return respBody;
  }
}
export default OrgReparentSharedGroupsCheckProvider;
