import {
  Button,
  ButtonGroup,
  Content,
  ContextualHelp,
  Dialog,
  Divider,
  Heading,
  InlineAlert,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { ManageUserGroupShareDialogProps, RevokeType } from './types';
import { ManageUserGroupShareContentModel, useManageUserGroupShareContent } from './ContentModels';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOrganizationsStep } from './SelectOrganizationsStep/SelectOrganizationsStep';
import { SelectRevokeTypeStep } from './SelectRevokeTypeStep/SelectRevokeTypeStep';
import { NavigationContextProvider } from './contexts/NavigationContext';
import { RevokeContextProvider } from './contexts/RevokeContext';
import { CommandService } from '../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../../services/orgMaster/OrgMaster';
import CmdDescriptionUtils from '../../services/Codes/CmdDescriptionUtils';
import { UUserGroupShare } from '../../services/orgMaster/UUserGroupShare';
import HierarchyManager from '../../services/organization/HierarchyManager';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import AdminPermission from '../../services/authentication/AdminPermission';
import FloodgateService from '../../services/floodgate/FloodgateService';
import { useLogger } from '@pandora/react-logger-provider';

enum Page {
  SELECT_ORGANIZATIONS,
  SELECT_REVOKE_TYPE,
}

export const ManageUserGroupShareDialog = ({
  sourceOrgUserGroup,
  content,
  initSelectedKeys = [],
  disableSecondaryButton = false,
  ...props
}: ManageUserGroupShareDialogProps) => {
  const logger = useLogger();
  const [page, setPage] = useState<Page>(Page.SELECT_ORGANIZATIONS);
  const [ctaDisabled, setCtaDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(initSelectedKeys.length === 0);
  const [org, setOrg] = useState<UOrgMaster | undefined>(undefined);
  const [secondaryDisabled, setSecondaryDisabled] = useState(disableSecondaryButton);
  const [selectedOrgUserGroups, setSelectedOrgUserGroups] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const [revokeType, setRevokeType] = useState(undefined);
  const [selectOrganizationsDisabled] = useState(AdminPermission.readOnlyMode);
  const [previousSelectedKeys, setPreviousSelectedKeys] = useState(initSelectedKeys);

  const contentEntry = content ?? useManageUserGroupShareContent();
  const dialogContainer = useDialogContainer();

  const headerContent = useMemo(() => {
    let header;
    if (page === Page.SELECT_ORGANIZATIONS) {
      header = contentEntry.get(ManageUserGroupShareContentModel.selectHeader);
    } else if (page === Page.SELECT_REVOKE_TYPE) {
      header = contentEntry.get(ManageUserGroupShareContentModel.revokeTypeHeader);
    }
    return header;
  }, [page]);

  useEffect(() => {
    const abortController = new AbortController();
    async function loadData() {
      try {
        setShowError(false);
        const orgData = HierarchyManager.getOrg(sourceOrgUserGroup.orgId);
        if (!abortController.signal.aborted) {
          if (!orgData) {
            logger.error('Error: org not present in org map');
            setShowError(true);
            return;
          } else {
            setOrg(orgData);
          }
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          logger.error('Error loading org data', e);
          setShowError(true);
        }
      }
    }
    void loadData();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleCancel = useCallback(() => {
    dialogContainer.dismiss();
  }, [dialogContainer]);

  const handleNext = useCallback(() => {
    if (page === Page.SELECT_ORGANIZATIONS) {
      setPage(Page.SELECT_REVOKE_TYPE);
    }
  }, [page]);

  const handlePrevious = useCallback(() => {
    if (page === Page.SELECT_REVOKE_TYPE) {
      setPreviousSelectedKeys(selectedOrgUserGroups);
      setPage(Page.SELECT_ORGANIZATIONS);
    }
  }, [page]);

  const resyncJob = () => {
    const sourceOrg = org as UOrgMaster;

    let userGroupSharesToResync: UUserGroupShare[] = [];

    Array.from(selectedOrgUserGroups).map((id: string) => {
      if (sourceOrgUserGroup.sharedUserGroupTargets) {
        userGroupSharesToResync.push(
          ...sourceOrgUserGroup.sharedUserGroupTargets.filter((target) => target.targetGroupId === id)
        );
      }
      userGroupSharesToResync.map((target) => {
        target.userGroupName = sourceOrgUserGroup.name;
      });
    });

    userGroupSharesToResync.map((userGroupShare) => {
      CommandService.addEdit(
        sourceOrg,
        new UUserGroupShare({ ...userGroupShare, id: userGroupShare.targetGroupId }),
        ObjectTypes.USER_GROUP_SHARE,
        OrgOperation.UPDATE,
        undefined,
        'RESYNC_USER_GROUP_SHARE',
        [
          CmdDescriptionUtils.getPathname(userGroupShare.sourceOrgId),
          sourceOrgUserGroup.name,
          CmdDescriptionUtils.getPathname(userGroupShare.targetOrgId),
        ]
      );
    });

    props.update();
  };

  const revokeJob = () => {
    const sourceOrg = org as UOrgMaster;

    let userGroupSharesToRevoke: UUserGroupShare[] = [];

    Array.from(selectedOrgUserGroups).map((id: string) => {
      if (sourceOrgUserGroup.sharedUserGroupTargets) {
        userGroupSharesToRevoke.push(
          ...sourceOrgUserGroup.sharedUserGroupTargets.filter((target) => target.targetGroupId === id)
        );
      }
      userGroupSharesToRevoke.map((target) => {
        target.strategy = revokeType;
        target.userGroupName = sourceOrgUserGroup.name;
      });
    });

    const revokeSummaryWord =
      revokeType === RevokeType.HARD_DELETE
        ? (contentEntry.get(ManageUserGroupShareContentModel.hardRevokeSummaryWord) as string)
        : (contentEntry.get(ManageUserGroupShareContentModel.softRevokeSummaryWord) as string);

    userGroupSharesToRevoke.map((userGroupShare) => {
      CommandService.addEdit(
        sourceOrg,
        new UUserGroupShare({ ...userGroupShare, id: userGroupShare.targetGroupId }),
        ObjectTypes.USER_GROUP_SHARE,
        OrgOperation.DELETE,
        undefined,
        'REVOKE_USER_GROUP_SHARE',
        [revokeSummaryWord, sourceOrgUserGroup.name, CmdDescriptionUtils.getPathname(userGroupShare.targetOrgId)]
      );

      // If the target org has already loaded its userGroups, we need to apply edits to the target org's userGroups
      // to alter whether the group is still present and/or looks shared following hard/soft delete.
      const targetOrg = HierarchyManager.getOrg(userGroupShare.targetOrgId);
      if (targetOrg) {
        UOrgMaster.editElementInList(
          targetOrg.userGroups,
          userGroupShare,
          OrgOperation.DELETE,
          ObjectTypes.USER_GROUP_SHARE
        );
      }
    });

    props.update();
  };

  const handleCta = useCallback(() => {
    revokeJob();
    dialogContainer.dismiss();
  }, [dialogContainer]);

  const handleResync = useCallback(() => {
    resyncJob();
    dialogContainer.dismiss();
  }, [dialogContainer]);

  return (
    <NavigationContextProvider
      ctaButtonDisabled={ctaDisabled}
      toggleDisableCtaButton={setCtaDisabled}
      nextButtonDisabled={nextDisabled}
      toggleDisableNextButton={setNextDisabled}
      secondaryButtonDisabled={secondaryDisabled}
      toggleDisableSecondaryButton={setSecondaryDisabled}
    >
      <RevokeContextProvider
        selectedOrgUserGroups={selectedOrgUserGroups}
        revokeType={revokeType}
        persistSelectedOrgUserGroups={setSelectedOrgUserGroups}
        persistRevokeType={setRevokeType}
      >
        <Dialog size={'L'} role="dialog" {...props} width={'860px'}>
          <Heading data-testid={'modal-header'}>{headerContent}</Heading>
          <Divider />
          <Content data-testid={'modal-content'} minHeight={'size-6000'}>
            {showError && (
              <InlineAlert variant="negative" data-testid="error-alert">
                {contentEntry.get(ManageUserGroupShareContentModel.error)}
              </InlineAlert>
            )}
            {page === Page.SELECT_ORGANIZATIONS && (
              <SelectOrganizationsStep
                sourceOrgUserGroup={sourceOrgUserGroup}
                initSelectedKeys={previousSelectedKeys}
                disabled={selectOrganizationsDisabled}
              />
            )}
            {page === Page.SELECT_REVOKE_TYPE && (
              <SelectRevokeTypeStep sourceOrgUserGroup={sourceOrgUserGroup}></SelectRevokeTypeStep>
            )}
          </Content>
          {page === Page.SELECT_ORGANIZATIONS && (
            <ButtonGroup>
              <Button
                variant="secondary"
                isDisabled={secondaryDisabled}
                data-testid="cancel-button"
                onPress={handleCancel}
              >
                {contentEntry.get(ManageUserGroupShareContentModel.cancelLabel)}
              </Button>
              {FloodgateService.isFeatureEnabled(FloodgateService.RESYNC_SHARED_USER_GROUPS) && (
                <Button
                  variant="primary"
                  isDisabled={selectOrganizationsDisabled || nextDisabled}
                  data-testid="resync-button"
                  onPress={handleResync}
                >
                  {contentEntry.get(ManageUserGroupShareContentModel.resyncLabel)}
                </Button>
              )}
              <Button
                variant="negative"
                isDisabled={selectOrganizationsDisabled || nextDisabled}
                style="outline"
                data-testid="next-button"
                onPress={handleNext}
              >
                {contentEntry.get(ManageUserGroupShareContentModel.nextLabel)}
              </Button>
              {selectOrganizationsDisabled && (
                <ContextualHelp
                  alignSelf="center"
                  crossOffset={-275}
                  marginStart={8}
                  offset={10}
                  placement="top left"
                  variant="info"
                >
                  <Heading>{contentEntry.get(ManageUserGroupShareContentModel.permissionsHeader)}</Heading>
                  <Content>
                    <Text>{contentEntry.get(ManageUserGroupShareContentModel.permissionsDescription)}</Text>
                  </Content>
                </ContextualHelp>
              )}
            </ButtonGroup>
          )}
          {page === Page.SELECT_REVOKE_TYPE && (
            <ButtonGroup>
              <Button
                variant="secondary"
                isDisabled={secondaryDisabled}
                data-testid="previous-button"
                onPress={handlePrevious}
              >
                {contentEntry.get(ManageUserGroupShareContentModel.previousLabel)}
              </Button>
              <Button
                variant="negative"
                isDisabled={ctaDisabled}
                style="fill"
                data-testid="cta-button"
                onPress={handleCta}
              >
                {contentEntry.get(ManageUserGroupShareContentModel.ctaLabel)}
              </Button>
            </ButtonGroup>
          )}
        </Dialog>
      </RevokeContextProvider>
    </NavigationContextProvider>
  );
};
