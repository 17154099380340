import * as _ from 'lodash';
import React, { Key, useState, useEffect, useCallback } from 'react';
import { ComboBox, SpectrumComboBoxProps } from '@adobe/react-spectrum';

interface ComboBoxSelectorProps<T>
  extends Omit<
    SpectrumComboBoxProps<T>,
    'selectedKey' | 'inputValue' | 'onSelectionChange' | 'onInputChange' | 'items'
  > {
  clearSelection?: boolean; // if true the selection will be cleared upon selected a menu item, if false, the behaviour will be the same as an RSV3 Combobox
  onSelect?: (key: Key) => void; // callback when a menu item is selected.  This is only called when an item is selected and not when the Combobox is opened (null value)
}

/**
 * Combobox component based off of the RSV3 Combobox except that it allows a developer
 * to specify whether than they want the Combobox to store and display selected items.
 *
 * The default Combobox displays a check mark next to selected menu items and displays the name of the menu item in the search field.
 * This behaviour does not make sense when using tags as the tags display what is selected and it looks strange when a Combobox is displaying a selected
 * item and when the Combobox still shows the seleted item when tags are deleted.
 *
 * This component allows a Combobox to clear its selection upon selecting a menu item if the clearSelection prop is true.  When selecting an item, the
 * Combobox will not show a check mark or display the selected item in the search field.  If the clearSelection prop is false, the behaviour is the same
 * as the RSV3 Combobox.
 */
function ComboBoxSelector<T>(props: ComboBoxSelectorProps<T>): React.ReactElement {
  const { onSelect, clearSelection, children, ...otherProps } = props;
  const [selectedKey, setSelectedKey] = useState<Key | null>('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (clearSelection) {
      setSelectedKey(null);
    }
  });

  const onKeySelect = useCallback((key: Key): void => {
    if (clearSelection) {
      setSelectedKey(key);
      setInputValue('');
    }
    if (onSelect && !_.isNil(key)) {
      onSelect(key);
    }
  }, []);

  const onInput = useCallback((value: string) => {
    if (clearSelection) {
      setInputValue(value);
      setSelectedKey((prevSelectedKey) => (_.isEmpty(value) ? null : prevSelectedKey));
    }
  }, []);

  return (
    <ComboBox
      selectedKey={clearSelection ? selectedKey : undefined}
      inputValue={clearSelection ? inputValue : undefined}
      onSelectionChange={onKeySelect}
      onInputChange={onInput}
      {...otherProps}
    >
      {children}
    </ComboBox>
  );
}
export default ComboBoxSelector;
