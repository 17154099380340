import ImportTypeInfo from '../../../../services/utils/ConvertToDataModel/ImportTypeInfo';
import {
  ImportOrganizationsDM,
  ImportProductsDM,
  ImportResourcesDM,
  ImportProductProfilesDM,
  ImportUserGroupsDM,
  ImportAdminsDM,
  ImportOrgPoliciesDM,
} from '../DataModelTypes/DataModelTypes';

// NOTE: Each **TypeInfo lists the field names expected during import for the individual detail type.
// For e.g. OrganizationTypeInfo mentions the field names expected during import of orgs.
// Meaning of individual property:
// propName: name of the field. For JSON this corresponds to the key name in the object and for CSV & XLSX
//           it corresponds to the header values
// typeString: the type of the field. This is needed because TypeScript only provides compile type checking
// optional: whether the field is optional or not. If the field is not optional and missing, an error is thrown

export const OrganizationTypeInfo: ImportTypeInfo<ImportOrganizationsDM>[] = [
  {
    propName: 'id',
    typeString: 'string',
    optional: true, // NOTE: when creating a new org, user can skip the orgId (id) if it is not referenced elsewhere in input file
  },
  {
    propName: 'name',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'countryCode',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'type',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'parentOrgId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
];

export const ProductsTypeInfo: ImportTypeInfo<ImportProductsDM>[] = [
  {
    propName: 'licenseId',
    typeString: 'string',
    optional: true, // NOTE: when allocating a new product, user can skip the licenseId if it is not referenced elsewhere in input file
  },
  {
    propName: 'allowOverallocation',
    typeString: 'boolean',
    optional: true,
  },
  {
    propName: 'sourceLicenseId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'orgId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
];

export const ResourceTypeInfo: ImportTypeInfo<ImportResourcesDM>[] = [
  {
    propName: 'licenseId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'resourceId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'grantedQuantity',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
];

export const ProdProfileTypeInfo: ImportTypeInfo<ImportProductProfilesDM>[] = [
  {
    propName: 'productProfileId',
    typeString: 'string',
    optional: true, // NOTE: when adding a new profile, user can skip the productProfileId if it is not referenced elsewhere in input file
  },
  {
    propName: 'productProfileName',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'licenseId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'orgId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'notifications',
    typeString: 'boolean',
    optional: true,
  },
  {
    propName: 'resourceId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'quota',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'selected',
    typeString: 'boolean',
    optional: true,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'resourceOperation',
    typeString: 'string',
    optional: true,
  },
];

export const UserGroupsTypeInfo: ImportTypeInfo<ImportUserGroupsDM>[] = [
  {
    propName: 'userGroupId',
    typeString: 'string',
    optional: true, // NOTE: when adding a new user group, user can skip the userGroupId if it is not referenced elsewhere in input file
  },
  {
    propName: 'userGroupName',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'userGroupDescription',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'orgId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'productProfileId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'licenseId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'profileOperation',
    typeString: 'string',
    optional: true,
  },
];

export const AdminTypeInfo: ImportTypeInfo<ImportAdminsDM>[] = [
  {
    propName: 'email',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'orgId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'adminType',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'userType',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'firstName',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'lastName',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'groupId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'licenseId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'contractId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
];

export const OrgPoliciesTypeInfo: ImportTypeInfo<ImportOrgPoliciesDM>[] = [
  {
    propName: 'orgId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'claimDomains',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'manageUserGroups',
    typeString: 'string',
    optional: true,
  },
  /* {
    propName: 'allowDirectorySync',
    typeString: 'string',
    optional: true,
  }, */
  {
    propName: 'changeIdentityConfig',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'allowAdobeID',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'inheritAdmins',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'inheritSharingPolicy',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'renameOrgs',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'manageProducts',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'inheritUsers',
    typeString: 'string',
    optional: true,
  },
  /* {
    propName: 'nonChildAllocation',
    typeString: 'string',
    optional: true,
  }, */
  {
    propName: 'setSharingPolicy',
    typeString: 'string',
    optional: true,
  },
  /* {
    propName: 'injectGroup',
    typeString: 'string',
    optional: true,
  }, */
  {
    propName: 'deleteOrgs',
    typeString: 'string',
    optional: true,
  },
  /* {
    propName: 'collab',
    typeString: 'string',
    optional: true,
  }, */
  /* {
    propName: 'shareOrganizationAddressBook',
    typeString: 'string',
    optional: true,
  }, */
  /* {
    propName: 'directoryMove',
    typeString: 'string',
    optional: true,
  }, */
  {
    propName: 'createChildren',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'manageAdmins',
    typeString: 'string',
    optional: true,
  },
];
