import React from 'react';
import * as _ from 'lodash';
import * as log from 'loglevel';
import { FormattedMessage, injectIntl, defineMessages, WrappedComponentProps } from 'react-intl';

import { Country } from '../../providers/ImsProvider';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import Utils from '../../services/utils/Utils';
import '../common.css';
import AdminPermission from '../../services/authentication/AdminPermission';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import { OrgType } from '../../services/organization/Org';
import NumberFormatter from '../../services/utils/NumberFormatter';
import { LoadOrgDataService } from '../../services/orgMaster/LoadOrgDataService';
import { CommandService } from '../../services/Commands/CommandService';
import CountryList from '../../services/countries/CountryList';

interface CompartmentInfoProps extends WrappedComponentProps {
  selectedOrg: UOrgMaster;
}

interface CompartmentInfoState {
  countryCode: string | undefined;
  locale: string;
  hasAdobeAgentPriviledge: boolean;
  userGroupCount: string | undefined;
  adminCount: string | undefined;
  userCount: string | undefined;
  domainCount: string | undefined;
}

const messages = defineMessages({
  OrgTypeClassroom: {
    id: 'CompartmentInfo.orgType.Classroom',
    defaultMessage: 'Classroom',
  },
  OrgTypeDeveloper: {
    id: 'CompartmentInfo.orgType.Developer',
    defaultMessage: 'Developer',
  },
  OrgTypeDirect: {
    id: 'CompartmentInfo.orgType.Direct',
    defaultMessage: 'Team Direct',
  },
  OrgTypeDistributor: {
    id: 'CompartmentInfo.orgType.Distributor',
    defaultMessage: 'Distributor',
  },
  OrgTypeEnterprise: {
    id: 'CompartmentInfo.orgType.Enterprise',
    defaultMessage: 'Enterprise',
  },
  OrgTypeIndirect: {
    id: 'CompartmentInfo.orgType.Indirect',
    defaultMessage: 'Indirect',
  },
  OrgTypeReseller: {
    id: 'CompartmentInfo.orgType.Reseller',
    defaultMessage: 'Reseller',
  },
});

class CompartmentInfo extends React.Component<CompartmentInfoProps, CompartmentInfoState> {
  private static countries: Country[] = [];

  private abortController = new AbortController(); // to avoid calling setState() when unmounted
  constructor(props: CompartmentInfoProps) {
    super(props);
    this.state = {
      countryCode: undefined,
      locale: LocaleSettings.getLocale(),
      hasAdobeAgentPriviledge: false,
      userGroupCount: undefined,
      adminCount: undefined,
      userCount: undefined,
      domainCount: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    await CompartmentInfo.loadCountries();
    await this.updateAdobeAgentPriviledge();
    await this.loadCounts();
  }

  static async loadCountries(): Promise<void> {
    try {
      CompartmentInfo.countries = await CountryList.getCountryList();
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      // log the error and show country code instead of country name
      log.error(`Could not get countries ${error}`);
    }
  }

  getCountryNameFromCode(): string {
    const country = _.find(CompartmentInfo.countries, ['countryCode', this.state.countryCode]);
    if (country === undefined) {
      return this.state.countryCode as string;
    }
    return country.countryName;
  }

  getUserGroupCount(): string | undefined {
    return this.props.selectedOrg.userGroupCountsLoaded
      ? _.toString(
          this.props.selectedOrg.totalUserGroupCount +
            this.props.selectedOrg.getNewUserGroupCount() -
            CommandService.getNumOfDeletedUserGroup(this.props.selectedOrg.id)
        )
      : undefined;
  }

  getAdminCount(): string | undefined {
    return this.props.selectedOrg.adminCountsLoaded
      ? _.toString(
          this.props.selectedOrg.totalAdminCount +
            this.props.selectedOrg.getNewAdminCount() -
            CommandService.getNumOfDeletedAdmins(this.props.selectedOrg.id)
        )
      : undefined;
  }

  getUserCount(): string | undefined {
    return this.props.selectedOrg.userCountsLoaded ? this.props.selectedOrg.totalUserCount.toString() : undefined;
  }

  getDomainCount(): string | undefined {
    return this.props.selectedOrg.domainCountsLoaded ? this.props.selectedOrg.totalDomainCount.toString() : undefined;
  }

  async loadUserCount(): Promise<void> {
    try {
      await LoadOrgDataService.loadUserCounts(this.props.selectedOrg?.id, this.abortController.signal);
      if (this.abortController.signal.aborted) return;
      this.setState({ userCount: this.getUserCount() });
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      // log the error and show the fieled as empty
      log.error(`Could not get organization user counts ${error}`);
    }
  }

  async loadAdminCount(): Promise<void> {
    try {
      await LoadOrgDataService.loadAdminCounts(this.props.selectedOrg?.id, this.abortController.signal);
      if (this.abortController.signal.aborted) return;
      this.setState({ adminCount: this.getAdminCount() });
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      // log the error and show the field as empty
      log.error(`Could not get organization admin counts ${error}`);
    }
  }

  async loadUserGroupCount(): Promise<void> {
    try {
      await LoadOrgDataService.loadUserGroupCounts(this.props.selectedOrg?.id, this.abortController.signal);
      if (this.abortController.signal.aborted) return;
      this.setState({ userGroupCount: this.getUserGroupCount() });
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      // log the error and show the field as empty
      log.error(`Could not get the organization user group counts ${error}`);
    }
  }

  async loadDomainCount(): Promise<void> {
    try {
      await LoadOrgDataService.loadDomainCounts(this.props.selectedOrg?.id, this.abortController.signal);
      if (this.abortController.signal.aborted) return;
      this.setState({ domainCount: this.getDomainCount() });
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      // log the error and show the field as empty
      log.error(`Could not get the organization domain counts ${error}`);
    }
  }

  async loadCounts(): Promise<void> {
    await Promise.all([this.loadUserCount(), this.loadAdminCount(), this.loadUserGroupCount(), this.loadDomainCount()]);
  }

  async updateAdobeAgentPriviledge(): Promise<void> {
    const hasAdobeAgentPriviledge = await AdminPermission.hasAdobeAgentPrivilege();
    if (this.abortController.signal.aborted) return;
    this.setState({ hasAdobeAgentPriviledge }); // update state with new data
  }

  async componentDidUpdate(prevProps: CompartmentInfoProps, prevState: CompartmentInfoState): Promise<void> {
    if (prevState.locale !== LocaleSettings.getLocale()) {
      await CompartmentInfo.loadCountries();
      if (this.abortController.signal.aborted) return; // nothing to do if component is unmounted
      this.setState({ locale: LocaleSettings.getLocale() });
    }
    if (prevState.countryCode !== this.props.selectedOrg.organization.countryCode) {
      if (this.abortController.signal.aborted) return; // nothing to do if component is unmounted
      this.setState({ countryCode: this.props.selectedOrg.organization.countryCode });
    }

    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id) {
      await this.loadCounts();
    }
    if (prevState.userCount !== this.getUserCount()) {
      if (this.abortController.signal.aborted) return; // nothing to do if component is unmounted
      this.setState({ userCount: this.getUserCount() });
    }
    if (prevState.adminCount !== this.getAdminCount()) {
      if (this.abortController.signal.aborted) return; // nothing to do if component is unmounted
      this.setState({ adminCount: this.getAdminCount() });
    }
    if (prevState.userGroupCount !== this.getUserGroupCount()) {
      if (this.abortController.signal.aborted) return; // nothing to do if component is unmounted
      this.setState({ userGroupCount: this.getUserGroupCount() });
    }
    if (prevState.domainCount !== this.getDomainCount()) {
      if (this.abortController.signal.aborted) return; // nothing to do if component is unmounted
      this.setState({ domainCount: this.getDomainCount() });
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  /**
   * Based off of https://git.corp.adobe.com/admin-tribe/twosie/blob/master/src/app/widgets/str/str-en.json#L503
   */
  private localizeOrgType(orgType: OrgType): string {
    const { formatMessage } = this.props.intl;
    switch (orgType) {
      case OrgType.CLASSROOM:
        return formatMessage(messages.OrgTypeClassroom);
      case OrgType.DEVELOPER:
        return formatMessage(messages.OrgTypeDeveloper);
      case OrgType.DIRECT:
        return formatMessage(messages.OrgTypeDirect);
      case OrgType.DISTRIBUTOR:
        return formatMessage(messages.OrgTypeDistributor);
      case OrgType.ENTERPRISE:
        return formatMessage(messages.OrgTypeEnterprise);
      case OrgType.INDIRECT:
        return formatMessage(messages.OrgTypeIndirect);
      case OrgType.RESELLER:
        return formatMessage(messages.OrgTypeReseller);
      default:
        return orgType;
    }
  }

  public render(): React.ReactNode {
    return (
      <div className="EditCompartment_orgInfoDiv" data-testid="CompartmentInfo">
        <div className="EditCompartment_orgInfo EditCompartment_orgInfoBorder">
          <div>
            <FormattedMessage id="CompartmentInfo.label.REGION" defaultMessage="COUNTRY/REGION" />
          </div>
          <div>{this.getCountryNameFromCode()}&nbsp;</div>
        </div>
        <div className="EditCompartment_orgInfo EditCompartment_orgInfoBorder">
          <div>
            <FormattedMessage id="CompartmentInfo.label.USERS" defaultMessage="USERS" />
          </div>
          <div data-testid="CompartmentInfo_user-count">
            {this.state.userCount && NumberFormatter.formatNumber(this.state.userCount)}&nbsp;
          </div>
        </div>
        <div className="EditCompartment_orgInfo EditCompartment_orgInfoBorder">
          <div>
            <FormattedMessage id="CompartmentInfo.label.USERGROUPS" defaultMessage="USER GROUPS" />
          </div>
          <div data-testid="CompartmentInfo_usergroup-count">
            {this.state.userGroupCount && NumberFormatter.formatNumber(this.state.userGroupCount)}&nbsp;
          </div>
        </div>
        <div className="EditCompartment_orgInfo EditCompartment_orgInfoBorder">
          <div>
            <FormattedMessage id="CompartmentInfo.label.ADMINS" defaultMessage="ADMINS" />
          </div>
          <div data-testid="CompartmentInfo_admin-count">
            {this.state.adminCount && NumberFormatter.formatNumber(this.state.adminCount)}&nbsp;
          </div>
        </div>
        <div
          className={
            this.state.hasAdobeAgentPriviledge
              ? 'EditCompartment_orgInfo EditCompartment_orgInfoBorder'
              : 'EditCompartment_orgInfo'
          }
        >
          <div>
            <FormattedMessage id="CompartmentInfo.label.DOMAINS" defaultMessage="DOMAINS" />
          </div>
          <div data-testid="CompartmentInfo_domain-count">
            {this.state.domainCount && NumberFormatter.formatNumber(this.state.domainCount)}&nbsp;
          </div>
        </div>
        {this.state.hasAdobeAgentPriviledge && (
          <div className="EditCompartment_orgInfo EditCompartment_orgInfoBorder">
            <div>
              <FormattedMessage id="CompartmentInfo.label.ORGID" defaultMessage="ORG ID" />
            </div>
            <div data-testid="CompartmentInfo_orgId">{this.props.selectedOrg.organization.id}&nbsp;</div>
          </div>
        )}
        {this.state.hasAdobeAgentPriviledge && (
          <div className="EditCompartment_orgInfo">
            <div>
              <FormattedMessage id="CompartmentInfo.label.ORGTYPE" defaultMessage="ORG TYPE" />
            </div>
            <div data-testid="CompartmentInfo_OrgType">
              {this.props.selectedOrg.organization.type
                ? this.localizeOrgType(this.props.selectedOrg.organization.type)
                : ''}
              &nbsp;
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(CompartmentInfo);
