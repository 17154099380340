import * as _ from 'lodash';
import { LoadOrgDataService } from '../orgMaster/LoadOrgDataService';
import { UOrgMaster } from '../orgMaster/UOrgMaster';
import { UProduct } from '../orgMaster/UProduct';
import HierarchyManager from '../organization/HierarchyManager';
import { OrgReparentSharedGroupsReportUtils } from './OrgReparentSharedGroupsReportUtils';
import OrgReparentSharedGroupsCheckProvider from '../../providers/OrgReparentSharedGroupsCheckProvider';

class OrgReparentService {
  /**
   * Determines whether an org has any directly allocated products (products allocated from its current parent org) using data from the OrgMasterTree
   * This method also loads products into the OrgMasterTree and caches the data
   * This method is intended for use by the OrgTree (not the OrgMapper for AdobeAgent)
   */
  static async orgHasAllocatedProducts(orgId: string): Promise<boolean> {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId); // get the original org master, only the existing org matters for re-parent with products
    // if there is no parent org, the products are either purchase or indirect allocations, so there is no directly allocated products
    if (org && !_.isEmpty(org.organization.parentOrgId)) {
      const parentOrg: UOrgMaster | undefined = org.getParentOrgMaster();
      if (parentOrg) {
        await LoadOrgDataService.loadProducts(org.organization.id); // load products for org
        await LoadOrgDataService.loadProducts(parentOrg.organization.id); // load products for parent org
        // filter for directly allocated products
        const productAllocations: UProduct[] = _.filter(
          org.products,
          (prod: UProduct): boolean =>
            !_.isEmpty(prod.sourceProductId) &&
            _.some(parentOrg.products, (parentProd: UProduct): boolean => parentProd.id === prod.sourceProductId)
        );
        if (!_.isEmpty(productAllocations)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Returns whether an intended re-parent operation would lead to a violation of the top-down sharing policy.
   *
   * @param orgToMove The organization being moved
   * @param newParentId The proposed new parent to move it to
   * @returns false if there is no violation, true if there are errors.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async moveViolatesTopDownSharingPolicy(orgToMove: UOrgMaster, newParentId: string) {
    const report = await OrgReparentSharedGroupsCheckProvider.orgReparentSharedGroupsCheck([
      { orgToReparentId: orgToMove.organization.id, destinationOrgId: newParentId },
    ]);
    return OrgReparentSharedGroupsReportUtils.hasErrors(report);
  }
}
export default OrgReparentService;
