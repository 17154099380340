import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Heading from '@react/react-spectrum/Heading';
import Well from '@react/react-spectrum/Well';
import Rule from '@react/react-spectrum/Rule';
import Switch from '@react/react-spectrum/Switch';
import Provider from '@react/react-spectrum/Provider';
import Wait from '@react/react-spectrum/Wait';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UAdmin, AdminProfileInfo, AdminUserGroupInfo } from '../../../services/orgMaster/UAdmin';
import { UserTypeMapInterface, UserTypeMap } from './services/UserTypeMap';
import { UProductProfile } from '../../../services/orgMaster/UProductProfile';
import { UUserGroup } from '../../../services/orgMaster/UUserGroup';
import ProductContractData from '../../../services/orgMaster/ProductContractData';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import './Common.css';
import '../../common.css';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';

interface ViewAdminContentProps extends WrappedComponentProps {
  admin: UAdmin;
  selectedOrg: UOrgMaster;
}

interface ViewAdminContentState {
  loaded: boolean; // determines whether component should indicate loading (products loading)
  productsContractsData: ProductContractData[]; // products with contract data associated with product admin role
}

const messages = defineMessages({
  ViewAdmin: {
    id: 'EditCompartment.Admins.ViewAdmin.Title',
    defaultMessage: 'View Admin',
  },
  Close: {
    id: 'EditCompartment.Admins.ViewAdmin.Close',
    defaultMessage: 'Close',
  },
});

/**
 * This component is rendered when user with only Global RO permission clicks on the Admin name from the AdminTable rows
 * to view the details of the selected admin. Note: We do not want this user to edit any admin rights, so we make everything
 * in this component read only
 */
class ViewAdminContent extends React.Component<ViewAdminContentProps, ViewAdminContentState> {
  constructor(props: ViewAdminContentProps) {
    super(props);
    this.state = { loaded: false, productsContractsData: this.createProductAndContractDataForAdmin() };
  }

  componentDidMount = async (): Promise<void> => {
    const { admin } = this.props;
    let productsContractsData: ProductContractData[] = [];
    if (admin.isProductAdmin()) {
      // load products so that we can get the product names for products associated with product admin (admins don't include product name data)
      await LoadOrgDataService.loadProducts(this.props.selectedOrg.organization.id);
      productsContractsData = this.createProductAndContractDataForAdmin();
    }
    this.setState({ loaded: true, productsContractsData });
  };

  /**
   * Generates array of products associated with contracts data
   * specifically for products targeted by the product admin role
   */
  private createProductAndContractDataForAdmin(): ProductContractData[] {
    const admin = this.props.admin as UAdmin; // this won't be necessary once branch is updated
    return admin.isProductAdmin()
      ? ProductContractData.createMultipleFromProductsAndContracts(
          admin.getAdminProducts(this.props.selectedOrg),
          this.props.selectedOrg.contracts
        )
      : [];
  }

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { admin } = this.props;
    const userTypeMap: UserTypeMapInterface = UserTypeMap.get();
    const profileList: AdminProfileInfo[] = admin.getAdminProfileInfo();
    const userGroupList: AdminUserGroupInfo[] = admin.getAdminUserGroupInfo();

    const { selectedOrg } = this.props;
    const showSystemAdminRole = true;
    const showGlobalAdminRole = !selectedOrg.isReadOnlyOrg();
    const showGlobalViewerAdminRole = !selectedOrg.isReadOnlyOrg();
    const showProductAdminRole = !selectedOrg.isReadOnlyOrg();
    const showProductProfileAdminRole = !selectedOrg.isReadOnlyOrg();
    const showUserGroupAdminRole = !selectedOrg.isReadOnlyOrg();
    const showDeploymentAdminRole = !selectedOrg.isReadOnlyOrg();
    const showSupportAdminRole = !selectedOrg.isReadOnlyOrg();
    const showStorageAdminRole = !selectedOrg.isReadOnlyOrg();

    return (
      <Dialog
        title={formatMessage(messages.ViewAdmin)}
        confirmLabel={formatMessage(messages.Close)}
        {...this.props}
        role="dialog"
        className="AdminDialog"
      >
        {this.state.loaded ? (
          <div>
            <div>
              <Well className="AdminDialogSection">
                <div className="AdminDialogSubHeading">
                  <Heading variant="subtitle2">
                    <FormattedMessage id="EditCompartment.ViewAdmin.UserDetails" defaultMessage="User Details" />
                  </Heading>
                </div>
                <Rule variant="medium" />
                <div>
                  <div className="AdminDialogUserDetailsContainer">
                    <span className="AdminDialogUserDetailLabels">
                      <FormattedMessage id="EditCompartment.ViewAdmin.Name" defaultMessage="Name" />
                    </span>
                    <span className="AdminDialogUserDetailsTextValue">{`${admin.firstName} ${admin.lastName}`}</span>
                  </div>
                  <div className="AdminDialogUserDetailsContainer">
                    <span className="AdminDialogUserDetailLabels">
                      <FormattedMessage id="EditCompartment.ViewAdmin.Email" defaultMessage="Email" />
                    </span>
                    <span className="AdminDialogUserDetailsTextValue">{admin.email}</span>
                  </div>
                  <div className="AdminDialogUserDetailsContainer">
                    <span className="AdminDialogUserDetailLabels">
                      <FormattedMessage id="EditCompartment.ViewAdmin.AccountType" defaultMessage="Account Type" />
                    </span>
                    <span className="AdminDialogUserDetailsTextValue">
                      {admin.userType && userTypeMap[admin.userType]}
                    </span>
                  </div>
                </div>
              </Well>
            </div>

            <div>
              <Well className="AdminDialogSection">
                <div className="AdminDialogSubHeading">
                  <Heading variant="subtitle2" className="EditAdminContent_heading">
                    <FormattedMessage id="EditCompartment.Admins.ViewAdmin.AdminRoles" defaultMessage="Admin Roles" />
                  </Heading>
                </div>
                <Rule variant="medium" />
                <div>
                  {showSystemAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.System" defaultMessage="System" />
                      </span>
                      <Switch className="OrgLevelAdminSwitch" defaultChecked={admin.isSysAdmin()} disabled />
                    </div>
                  )}
                  {showGlobalAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.Global" defaultMessage="Global" />
                      </span>
                      <Switch className="OrgLevelAdminSwitch" defaultChecked={admin.isGlobalAdmin()} disabled />
                    </div>
                  )}
                  {showGlobalViewerAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.GlobalRO" defaultMessage="Global Viewer" />
                      </span>
                      <Switch className="OrgLevelAdminSwitch" defaultChecked={admin.isGlobalAdminReadOnly()} disabled />
                    </div>
                  )}
                  {showDeploymentAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.Deployment" defaultMessage="Deployment" />
                      </span>
                      <Switch className="OrgLevelAdminSwitch" defaultChecked={admin.isDeploymentAdmin()} disabled />
                    </div>
                  )}
                  {showSupportAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.Support" defaultMessage="Support" />
                      </span>
                      <Switch className="OrgLevelAdminSwitch" defaultChecked={admin.isSupportAdmin()} disabled />
                    </div>
                  )}
                  {showStorageAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.Storage" defaultMessage="Storage" />
                      </span>
                      <Switch className="OrgLevelAdminSwitch" defaultChecked={admin.isStorageAdmin()} disabled />
                    </div>
                  )}
                  {showProductAdminRole && (
                    <div className="AdminRightSubsection">
                      <span>
                        <FormattedMessage id="EditCompartment.ViewAdmin.Product" defaultMessage="Product" />
                      </span>
                      {this.state.productsContractsData.map(
                        (productContractData: ProductContractData): React.ReactNode => (
                          <Provider
                            key={productContractData.product.id}
                            locale={LocaleSettings.getSelectedLanguageTagForProvider()}
                          >
                            <TagList readOnly>
                              <Tag>
                                {selectedOrg.shouldDisplayContractNames()
                                  ? productContractData.productContractsName()
                                  : productContractData.product.name}
                              </Tag>
                            </TagList>
                          </Provider>
                        )
                      )}
                      {this.state.productsContractsData.length === 0 && (
                        <div className="AdminDialogNoContent">
                          <FormattedMessage
                            id="EditCompartment.ViewAdmin.NoProductRoles"
                            defaultMessage="No product admin roles."
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {showProductProfileAdminRole && (
                    <div className="AdminRightSubsection">
                      <div>
                        <FormattedMessage
                          id="EditCompartment.ViewAdmin.ProductProfile"
                          defaultMessage="Product Profile"
                        />
                      </div>
                      {profileList.map((profile: UProductProfile | AdminProfileInfo): React.ReactNode => {
                        return (
                          <Provider key={profile.id} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
                            <TagList readOnly>
                              <Tag>{profile.name} </Tag>
                            </TagList>
                          </Provider>
                        );
                      })}
                      {profileList.length === 0 && (
                        <div className="AdminDialogNoContent">
                          <FormattedMessage
                            id="EditCompartment.ViewAdmin.NoProfileRoles"
                            defaultMessage="No profile admin roles."
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {showUserGroupAdminRole && (
                    <div className="AdminRightSubsection">
                      <div>
                        <FormattedMessage id="EditCompartment.ViewAdmin.UserGroup" defaultMessage="User Group" />
                      </div>
                      {userGroupList.map((userGroup: UUserGroup | AdminUserGroupInfo): React.ReactNode => {
                        return (
                          <Provider key={userGroup.id} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
                            <TagList readOnly>
                              <Tag>{userGroup.name} </Tag>
                            </TagList>
                          </Provider>
                        );
                      })}
                      {userGroupList.length === 0 && (
                        <div className="AdminDialogNoContent">
                          <FormattedMessage
                            id="EditCompartment.ViewAdmin.NoGroupRoles"
                            defaultMessage="No user group admin roles."
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Well>
            </div>
          </div>
        ) : (
          <Wait className="Load_wait" />
        )}
      </Dialog>
    );
  }
}

export default injectIntl(ViewAdminContent);
