import React from 'react';
import Link, { LinkProps } from '@react/react-spectrum/Link';
import { LocaleSettings } from '../../services/locale/LocaleSettings';

// See https://git.corp.adobe.com/admin-tribe/binky-ui/tree/master/src/widgets/common/go-url
export enum GoUrlKeys {
  adchoices = 'aac_cookie_pref',
  terms = 'terms',
  privacy = 'privacy',
  cookies = 'cookies',
  caRights = 'ca-rights',
  // The following keys were provided by Bernardo Espinosa at https://adobe-my.sharepoint.com/:f:/p/mespinos/Euw0FEyBxjdOk_npJRB1AOkB8RyreIwj45s7K7tj5akVDA?e=3mmnis
  organizationsProducts = 'gac_org_prod',
  organizationsUserGroups = 'gac_org_usg',
  organizationsAdmins = 'gac_org_aad',
  organizationsDomains = 'gac_org_dom',
  organizationsPolicies = 'gac_org_pol',
  organizationsTemplates = 'gac_org_ptmp',
  productAllocation = 'gac_prod_ttl',
  jobExecution = 'gac_jbst_ttl',
  orgMapper = 'gac_orgm_int',
  contractExpirationProducts = 'contract_expiration_prod',
  contractSwitch = 'export_alloc',
  exportReports = 'export_reports',
  userGroupsShare = 'user_group_share',
}

// Set of all goUrlKeys that are Global Admin Console specific
// These require lowercase language tags xx_xx whereas other goUrls require xx_XX
const gacGoUrls: Set<GoUrlKeys> = new Set<GoUrlKeys>([
  GoUrlKeys.organizationsProducts,
  GoUrlKeys.organizationsUserGroups,
  GoUrlKeys.organizationsAdmins,
  GoUrlKeys.organizationsDomains,
  GoUrlKeys.organizationsPolicies,
  GoUrlKeys.organizationsTemplates,
  GoUrlKeys.productAllocation,
  GoUrlKeys.jobExecution,
  GoUrlKeys.orgMapper,
  GoUrlKeys.exportReports,
]);

interface RequiredProps extends Omit<LinkProps, 'ref' | 'href'> {
  goUrlKey: GoUrlKeys;
}

interface OptionalProps {
  localize?: boolean;
}

interface GoUrlProps extends RequiredProps, OptionalProps {}

const defaultProps: OptionalProps = {
  localize: true,
};

function GoUrl(props: GoUrlProps): React.ReactElement {
  // Global Admin Console uses lower case go url language tags while Admin Console uses xx_XX case
  const languageTag: string = gacGoUrls.has(props.goUrlKey)
    ? LocaleSettings.getSelectedLanguageTagForAdobeGoUrls(true)
    : LocaleSettings.getSelectedLanguageTagForAdobeGoUrls(false);
  let url = `https://www.adobe.com/go/${props.goUrlKey}_${languageTag}`;
  if (!props.localize) {
    url = `https://www.adobe.com/go/${props.goUrlKey}`;
  }
  return (
    <Link href={url} {...props}>
      {props.children}
    </Link>
  );
}

GoUrl.defaultProps = defaultProps;
export default GoUrl;
