import React from 'react';
import { AlertDialog, ProgressCircle, View } from '@adobe/react-spectrum';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import Analytics from '../../../Analytics/Analytics';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import OrgDeletionService from '../OrgDeletionService';
import BanyanCompartmentAPI, { checkT2EESMResponse } from '../../../providers/BanyanCompartmentAPI';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';

import '../../common.css';
import './DeleteOrganization.css';
import Utils from '../../../services/utils/Utils';

const messages = defineMessages({
  OK: {
    id: 'Organizations.Delete.OK',
    defaultMessage: 'OK',
  },
  Cancel: {
    id: 'Organizations.Delete.Cancel',
    defaultMessage: 'Cancel',
  },
  DeleteOrg: {
    id: 'Organizations.Delete.DeleteOrg',
    defaultMessage: 'Delete organization',
  },
  DeleteConf: {
    id: 'Organizations.Delete.DeleteConf',
    defaultMessage: 'Do you want to delete "{orgName}"?',
  },
  Warning: {
    id: 'EditCompartment.DeleteConf.Warning',
    defaultMessage: 'Warning:',
  },
  CannotUndo: {
    id: 'EditCompartment.DeleteConf.CannotUndo',
    defaultMessage: 'This deletion cannot be undone after the job has been submitted.',
  },
  ResourcesReturned: {
    id: 'EditCompartment.DeleteConf.ResourcesReturned',
    defaultMessage:
      'Resources such as products, credits, transactions, that were allocated through Global Admin Console will be returned to its parent.',
  },
  ESMWarning: {
    id: 'EditCompartment.DeleteConf.ESMWarning',
    defaultMessage: 'Assets created by users in this org will no longer be accessible.',
  },
  NonLeafOrgDeleteNote: {
    id: 'EditCompartment.DeleteOrg.NonLeafOrgDeleteNote',
    defaultMessage:
      'This org cannot be deleted because this org has children. Please remove the children before deleting.',
  },
  CannotDeleteOrg: {
    id: 'EditCompartment.DeleteOrg.CannotDeleteOrg',
    defaultMessage: 'Cannot delete org',
  },
  UserGroupSharingOrgDeleteNote: {
    id: 'EditCompartment.DeleteOrg.UserGroupSharingOrgDeleteNote',
    defaultMessage:
      'This org cannot be deleted because it contains shared user groups. Please revoke access to the shared user groups before deleting.',
  },
  CheckingDeletion: {
    id: 'EditCompartment.DeleteOrg.CheckingDeletion',
    defaultMessage: 'Checking deletion',
  },
});

interface DeleteOrganizationProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
  close: () => void;
}

interface DeleteOrganizationState {
  orgIsESM: boolean; // reports whether the selected org is esm.  If it is a warning about losing assets needs to be displayed.
  allocationsWillReturn: boolean; // reports whether allocations could be returned.  If true a warning about returning allocations needs to be displayed.
  loaded: boolean; // reports whether the process of loading data is finished (regardless if data needed to load or not).
}

class DeleteOrganization extends React.Component<DeleteOrganizationProps, DeleteOrganizationState> {
  private abortController: AbortController;

  constructor(props: DeleteOrganizationProps) {
    super(props);
    this.state = {
      orgIsESM: false,
      allocationsWillReturn: false,
      loaded: false,
    };
    this.abortController = new AbortController();
  }

  async loadUserGroups(): Promise<void> {
    if (this.needsToLoadGroups()) {
      // We need the user groups to check if the org has any shared user groups before allowing deletion
      await LoadOrgDataService.loadUserGroups(this.props.selectedOrg.id, true, this.abortController.signal);
    }
  }

  async componentDidMount(): Promise<void> {
    try {
      const { selectedOrg } = this.props;

      await this.loadUserGroups();

      // only check for ESM, or show product return warning, or load data if it's an existing org without children
      if (!selectedOrg.isNewOrg() && selectedOrg.getChildren().length === 0) {
        const t2eEsmSettings: checkT2EESMResponse = await BanyanCompartmentAPI.checkT2EESM(
          selectedOrg.organization.id,
          undefined,
          this.abortController.signal
        );
        if (!this.abortController.signal.aborted) {
          this.setState({ loaded: true, orgIsESM: t2eEsmSettings.T2EESMEnabled, allocationsWillReturn: true });
        }
      } else {
        if (!this.abortController.signal.aborted) {
          // for new orgs or orgs with existing children, loading is not necessary, consider the dialog loaded
          this.setState({ loaded: true });
        }
      }
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return;
      }
      console.error('Error loading data', error);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  private needsToLoadGroups() {
    return !this.props.selectedOrg.isNewOrg() && !this.props.selectedOrg.userGroupsLoaded;
  }

  private orgInvolvedInUserGroupSharing(): boolean {
    const { selectedOrg } = this.props;
    return selectedOrg.userGroups.some((userGroup) => Utils.isSharedGroup(userGroup));
  }

  // determines whether the org is an existing org that has children
  private existingOrgWithChildren(): boolean {
    const { selectedOrg } = this.props;
    return !selectedOrg.isNewOrg() && selectedOrg.getChildren().length > 0;
  }

  // callback executed when user proceeds to delete org from dialog
  private onOrgDelete = (): void => {
    Analytics.fireCTAEvent('delete org dialog confirm');
    OrgDeletionService.deleteOrgHelper(this.props.selectedOrg);
    this.props.update();
  };

  private onCancelDeletionCheck = (): void => {
    Analytics.fireCTAEvent('delete org checking dialog canceled');
    this.abortController.abort();
  };

  render(): React.ReactNode {
    const { selectedOrg } = this.props;
    const { loaded } = this.state;
    const { formatMessage } = this.props.intl;

    if (this.needsToLoadGroups()) {
      // Display wait icon until data has loaded.  (If not data loading is not necessary, this will be removed quickly)
      return (
        <AlertDialog
          variant="confirmation"
          title={formatMessage(messages.CheckingDeletion)}
          primaryActionLabel={formatMessage(messages.Cancel)}
          onPrimaryAction={this.onCancelDeletionCheck}
          autoFocusButton="primary"
        >
          <View paddingY={'size-100'}>
            <ProgressCircle
              size="M"
              isIndeterminate
              top="50%"
              left="50%"
              aria-label={formatMessage(messages.CheckingDeletion)}
            />
          </View>
        </AlertDialog>
      );
    }

    if (this.orgInvolvedInUserGroupSharing()) {
      return (
        <AlertDialog
          variant="confirmation"
          title={formatMessage(messages.CannotDeleteOrg)}
          primaryActionLabel={formatMessage(messages.OK)}
          autoFocusButton="primary"
        >
          {formatMessage(messages.UserGroupSharingOrgDeleteNote)}
        </AlertDialog>
      );
    }

    if (this.existingOrgWithChildren()) {
      return (
        // Display dialog which prevents user from deleting org with children.  Data load not necessary for this dialog.
        <AlertDialog
          variant="confirmation"
          title={formatMessage(messages.CannotDeleteOrg)}
          primaryActionLabel={formatMessage(messages.OK)}
          autoFocusButton="primary"
        >
          {formatMessage(messages.NonLeafOrgDeleteNote)}
        </AlertDialog>
      );
    }

    // Display dialog which warns users but allows deletion of org.  Data loading might be necessary for this dialog
    return (
      <AlertDialog
        variant="destructive"
        title={formatMessage(messages.DeleteOrg)}
        primaryActionLabel={formatMessage(messages.OK)}
        cancelLabel={formatMessage(messages.Cancel)}
        onPrimaryAction={this.onOrgDelete}
        onSecondaryAction={(): void => {
          Analytics.fireCTAEvent('delete org dialog canceled');
        }}
        isPrimaryActionDisabled={!loaded}
        minWidth="36rem" // reduce chance that text needs to wrap. This isn't a guarantee.
        minHeight="25rem" // reduce the amount of dialog resizing that occurs when dynamic text is loaded.
      >
        {formatMessage(messages.DeleteConf, { orgName: selectedOrg.organization.name })}
        {!loaded ? (
          // Display wait icon until data has loaded.  (If not data loading is not necessary, this will removed quickly)
          <div data-testid="delete-confirm-dialog-wait">
            <ProgressCircle size="M" isIndeterminate top="50%" left="50%" aria-label="Loading" />
          </div>
        ) : (
          // If data has finished loading or did not need to load, show the warnings.
          <>
            <div className="EditCompartment__deleteConfirmDialog__warningHeader">{formatMessage(messages.Warning)}</div>
            <ul>
              <li className="EditCompartment__deleteConfirmDialog__warning">{formatMessage(messages.CannotUndo)}</li>
              {this.state.allocationsWillReturn && (
                <li className="EditCompartment__deleteConfirmDialog__warning">
                  {formatMessage(messages.ResourcesReturned)}
                </li>
              )}
              {this.state.orgIsESM && (
                // Only show this warning, if the org is t2e_esm
                <li className="EditCompartment__deleteConfirmDialog__warning">{formatMessage(messages.ESMWarning)}</li>
              )}
            </ul>
          </>
        )}
      </AlertDialog>
    );
  }
}
export default injectIntl(DeleteOrganization);
