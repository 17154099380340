import * as _ from 'lodash';
import * as log from 'loglevel';
import BanyanJobsApi from '../../providers/BanyanJobsApi';
import Utils from '../utils/Utils';
import { CancelPromise, CancelStatus } from '../utils/CancelPromise';
import { JobInfo, JobProgressResponse, JobStatusResponse, UpdateJobOptions } from './JobData';
import OrgPickerController from '../organization/OrgPickerController';
import { MESSAGES } from '../../JobExecution/Messages';
import Constants from '../../constants/Constants';

/**
 * Service for handling BanyanJob requests.
 * The BanyanJobsApi provider provides restful api calls to the backend.
 * This service handles managing those restful api calls to get the data in chunks overtime.
 */
class BanyanJobs {
  public static maxValuesPerRequest = 100;
  private static MAX_PAGES_TO_TRY = 100;
  private static MAX_RECENT_JOBS = 20;
  private static ONE_MIN_IN_MS = 60000;

  /**
   * Creates and executes a BanyanJob based upon the given 'options' and return the resulting Job ID.
   *
   * The promise returned in the CancelPromise of this method will resolve when the job is reported as 'done'.
   * The cancel method can be executed on the returned CancelPromise to cancel the job.
   *
   * getJobProgress() should be called after this to monitor for completion.
   *
   * @param options Configure the job to create
   * @returns job ID
   * @throws Error if job could not be created or could not parse ID from URL
   */
  static async createJob(options: UpdateJobOptions): Promise<string> {
    let jobId: string | null = '';
    // create a new job and get its job id
    const job: JobProgressResponse = await BanyanJobsApi.postJobs(options); // creating a job is always fast. There's no need for it to be cancelled.
    jobId = job.result.jobInfo.id;
    log.debug(`created job with id: ${jobId}`);
    if (!jobId) {
      Utils.throwLocalizedError(MESSAGES.CreateJobError);
    }
    return jobId;
  }

  /**
   * Given a jobId, this polls for the job's progress/results until the job is 'done' (cancelled/failed/etc)
   * or until the caller 'cancels' the return CancelPromise
   * or polling duration exceeds Timeout duration (in millisecond) as defined by Constants.JOB_PROGRESS_POLLING_TIMEOUT_IN_MS
   *
   * FYI, cancelling the CancelPromise results in the Promise resolving to a CancelStatus and ends the client-side polling;
   * the server will continue processing the job.
   *
   * When finished (i.e. when the job is 'done') the promise resolves to a JobProgressResponse, or to CancelStatus (if the caller cancelled or polling timed out).
   * When not finished/done the progressCallback is called with the JobProgressResponse data.
   *
   * Note that the returned 'JobProgressResponse' may not be a complete list of job details. It is the caller's responsibility
   * to accumulate job details via the progressCallback and the final JobProgressResponse.
   *
   * @param jobId
   * @param orgId
   * @param signal
   * @param progressCallback
   */
  static getJobProgress(
    jobId: string,
    orgId: string,
    signal: AbortSignal,
    progressCallback?: (data: JobProgressResponse) => void
  ): CancelPromise<JobProgressResponse | CancelStatus> {
    const cancelPromise: CancelPromise<JobProgressResponse | CancelStatus> = new CancelPromise<
      JobProgressResponse | CancelStatus
    >();

    cancelPromise.promise = new Promise<JobProgressResponse | CancelStatus>(async (resolve, reject): Promise<void> => {
      let progressRunning = true;
      const timeoutHandle: number = window.setTimeout((): void => {
        cancelPromise.timeout();
      }, Constants.JOB_PROGRESS_POLLING_TIMEOUT_IN_MS);

      while (progressRunning) {
        if (orgId !== OrgPickerController.getActiveOrgId()) {
          break;
        }
        if (signal.aborted) {
          window.clearTimeout(timeoutHandle);
          return; // component shutdown
        }
        let IJobProgressResponse: JobProgressResponse;
        try {
          IJobProgressResponse = await BanyanJobsApi.getJobProgress(jobId, {
            firstIndex: 0,
            maxValues: 50000,
          });
          // log.debug('getJobProgress: Got progress response: ', IJobProgressResponse);

          if (cancelPromise.cancelled) {
            log.info(`cancelled job ${jobId}`);
            window.clearTimeout(timeoutHandle);
            resolve({ cancelled: true, timedout: false });
            progressRunning = false;
            break;
          }
          if (cancelPromise.timedout) {
            log.info(`polling timedout for job ${jobId}`);
            resolve({ cancelled: false, timedout: true });
            progressRunning = false;
            break;
          }
          if (IJobProgressResponse.result.jobInfo.jobDone) {
            window.clearTimeout(timeoutHandle);
            resolve(IJobProgressResponse);
            progressRunning = false;
            break;
          }
          if (progressCallback) {
            progressCallback(IJobProgressResponse);
          }
        } catch (error) {
          // must catch here otherwise the error: "Uncaught (in promise) Error: ..." is propogated up
          log.error(error.message);
          reject(error);
          return;
        }
        await Utils.sleep(BanyanJobs.getSleepDuration(IJobProgressResponse.result.jobInfo.createdAt));
      }
    });
    return cancelPromise;
  }

  /**
   * Return custom sleep duration for job progress check requests, based on execution time taken by job until marked complete.
   * This helps avoid unnecessary polling for progress check of longer running jobs.
   *
   * Execution time of a running job (mins) : Sleep time (seconds) : Request per minute (RPM)
   * 0-1    : 2  : 30
   * 1-4    : 4  : 15
   * 4-12   : 10 : 6
   * 12-60  : 20 : 3
   * 60-240 : 60 : 1
   * @param createdAt Timestamp when job was created
   * @returns Sleep duration in milliseconds
   */
  static getSleepDuration(createdAt: string): number {
    const durationMS = new Date().valueOf() - new Date(createdAt).valueOf();
    let sleepDurationInMS: number;
    if (durationMS <= this.ONE_MIN_IN_MS) {
      sleepDurationInMS = 2000;
    } else if (durationMS <= 4 * this.ONE_MIN_IN_MS) {
      sleepDurationInMS = 4000;
    } else if (durationMS <= 12 * this.ONE_MIN_IN_MS) {
      sleepDurationInMS = 10000;
    } else if (durationMS <= 60 * this.ONE_MIN_IN_MS) {
      sleepDurationInMS = 20000;
    } else {
      sleepDurationInMS = 60000;
    }
    return sleepDurationInMS;
  }

  /**
   * Query the DB for the most recent UPDATE_ORG job (whose state may be running, done, or anything).
   * @returns Job ID or empty string if no such job is found.
   */
  static async getLatestUpdateJob(): Promise<string> {
    let currentPage = 0;
    const activeOrgId = OrgPickerController.getActiveOrgId();

    let jobs: JobInfo[] | undefined = [];
    let jobsFromDB: JobStatusResponse;
    do {
      jobsFromDB = await BanyanJobsApi.getJobs({
        page: currentPage,
        pageSize: 100,
        createdInOrg: !_.isNil(activeOrgId) ? activeOrgId : '',
      });
      ({ jobs } = jobsFromDB.result); // destructured assignment

      if (jobs && jobs.length > 0) {
        const latestUpdateResult = jobs[0];
        log.info(`Latest job ${JSON.stringify(latestUpdateResult)} found on page ${currentPage}`);
        return latestUpdateResult.id;
      }
      log.debug(`No jobs found on page ${currentPage}. Trying next page.`);
      currentPage += 1;
    } while (jobs && jobsFromDB.result.lastPage === false && currentPage < BanyanJobs.MAX_PAGES_TO_TRY); // continue looping if jobs is defined and the last response was a full page
    log.warn(`No UPDATE_ORG jobs found. Giving up on page ${currentPage}`);
    return '';
  }

  /**
   * Query the DB for the most recent UPDATE_ORG jobs (whose state may be running, done, or anything).
   * @returns job info or empty array if no update job is found.
   * Returns a maximum of BanyanJobs.MAX_RECENT_JOBS job entries.
   */
  static async getRecentUpdateJobs(): Promise<JobInfo[]> {
    let currentPage = 0;
    // eslint-disable-next-line prefer-const
    let result: JobInfo[] = [];
    const activeOrgId = OrgPickerController.getActiveOrgId();

    let jobs: JobInfo[] | undefined = [];
    let jobsFromDB: JobStatusResponse;
    do {
      jobsFromDB = await BanyanJobsApi.getJobs({
        page: currentPage,
        pageSize: 100,
        createdInOrg: !_.isNil(activeOrgId) ? activeOrgId : '',
      });
      ({ jobs } = jobsFromDB.result); // destructured assignment

      if (jobs) {
        jobs.forEach((job: JobInfo): void => {
          if (result.length < BanyanJobs.MAX_RECENT_JOBS) {
            result.push(job);
          }
        });
      }

      log.debug(`Not enough jobs found on page ${currentPage}. Trying next page.`);
      currentPage += 1;
    } while (
      jobs &&
      jobsFromDB.result.lastPage === false &&
      result.length < BanyanJobs.MAX_RECENT_JOBS &&
      currentPage < BanyanJobs.MAX_PAGES_TO_TRY
    ); // continue looping if jobs is defined and the last response was a full page
    return result;
  }
}
export default BanyanJobs;
