import * as _ from 'lodash';
import { EditState, ImportError, OrgOperation, ErrorData } from './OrgMaster';

/* eslint-disable import/no-cycle */
import { UOrgMaster } from './UOrgMaster';
/* eslint-enable import/no-cycle */

/**
 * UBasic is the base org data type.  All org data objects inherit from this.
 */

/**
 * Mutable object for UBasic.
 * Provides object containing only properties for UBasic.
 * Represents UBasic object that can be retrieved from back-end.
 */
export interface UBasicData {
  id?: string; // Id for the org data object (orgId, productId, etc)
  editState?: EditState | null | undefined; // EditState for the org data object (created, updated, deleted)
}

/**
 * UBasic object that also contains the methods and functionality.
 */
export abstract class UBasic implements UBasicData {
  private idField: string = '';
  private editStateField: EditState | null | undefined;

  /*
    Constructs a UBasic from either UBasicData or creates a copy of another UBasic object.
  */
  constructor(data?: UBasicData) {
    if (data) {
      this.idField = data.id || this.idField;
      this.editStateField = data.editState;
    }
  }

  /**
   * JSON representation of UBasic.
   */
  toJSON(): object {
    return {
      id: this.idField,
    };
  }

  /**
   * Id for the org data object (orgId, productId, etc).
   */
  get id(): string {
    return this.idField;
  }

  /**
   * sets a new Id for the object, does not check for edit state. This  case occurs in the AddProductDialog component
   * @param id
   */
  setId(id: string): void {
    this.idField = id;
  }

  /**
   * Sets a new id for the org data object.
   * Id can only be set for newly created object.
   * Boolean true value is returned if id is updated, false is returned if org data object
   * is not newly created and the id has not been updated.
   */
  setCreatedId(id: string): boolean {
    if (this.editStateField === EditState.CREATE) {
      this.idField = id;
      return true;
    }
    return false;
  }

  /**
   * EditState of the org data object (created, updated, deleted).
   * Note: EditState can be null and undefined.
   */
  get editState(): EditState | undefined | null {
    return this.editStateField;
  }

  /**
   * Controls setting of the EditState.
   * State cannot be updated if the object is in a created or deleted state.
   * State can be undefined or null.
   * TODO: Do we need edit state?
   */
  set editState(state: EditState | undefined | null) {
    switch (state) {
      case EditState.CREATE:
      case EditState.DELETE:
      case undefined:
      case null:
        this.editStateField = state;
        break;
      case EditState.UPDATE:
        if (this.editStateField !== EditState.CREATE && this.editStateField !== EditState.DELETE) {
          this.editStateField = state;
        }
        break;
      default:
        throw new Error(`Illegal state for setEditState: ${state}`);
    }
  }

  // Will need to revisit the purpose of this method for its intended usage
  /**
   * This method appears to allow resetting all the data of the org data object based on
   * another org data object.
   */
  updateElem(newElem: UBasicData): void {
    this.idField = newElem.id || this.idField;
    this.editState = EditState.UPDATE;
  }

  /**
   * Set this org data object as deleted and execute delete on all other
   * associated org data objects part of this object.
   */
  delete(): void {
    this.editState = EditState.DELETE;
    _.forEach(this, (a: any): void => {
      if (_.isObject(a)) {
        _.forEach(a, (e: any): void => {
          if (e instanceof UBasic) {
            e.delete();
          }
        });
      }
    });
  }

  /**
   * Either sets a delete state (including associated org data objects) or removes the state (including from associated org data objects)
   * if this org data object is already in a delete state.
   */
  toggleDeleteItem(): void {
    if (this.isDeleted()) {
      this.clearEditState();
    } else {
      this.delete();
    }
  }

  /**
   * Removes the EditState for the org data object.
   * Also removes the EditState for any associated org data objects as part of this object.
   */
  clearEditState(): void {
    this.editState = null;
    _.forEach(this, (a: any): void => {
      if (_.isObject(a)) {
        _.forEach(a, (e: any): void => {
          if (e instanceof UBasic) {
            e.clearEditState();
          }
        });
      }
    });
  }

  /**
   * Either sets a create state for the org data object or removes the state if it is already created.
   * TODO: Do we really need this? If not, remove it and the caller. I dont see why we should be using this method.
   */
  toggleCreateItem(): void {
    if (this.isCreated()) {
      this.editState = null;
    } else {
      this.editState = EditState.CREATE;
    }
  }

  /**
   * Reports whether the state of this org data object is created.
   * DEPRECATED: Use CommandService.isCreated. If your code still uses this, please update it.
   */
  isCreated(): boolean {
    return this.editState === EditState.CREATE;
  }

  /**
   * Reports whether the state of this org data object is updated.
   * DEPRECATED: Use CommandService.isUpdated. If your code still uses this, please update it.
   */
  isUpdated(): boolean {
    return this.editState === EditState.UPDATE;
  }

  /**
   * Reports whether the state of this org data object is deleted.
   * DEPRECATED: Use CommandService.isDeleted. If your code still uses this, please update it.
   */
  isDeleted(): boolean {
    return this.editState === EditState.DELETE;
  }

  /**
   * Method for adding a created org data object to the org or executing other operations.
   * Delete and Update operations will set a delete or update state for the given elem (if it exists on the given org).
   * Created operations will execute a newCallback (for adding the elem to an org) given that the given elem is newly created
   * and doesn't exist on the given root org.
   *   - org: An org that the elem should be imported to or the elem should associated with to perform an operation.
   *   - elem: The org data object to perform the operation on.
   *   - operation: The operation to perform.
   *   - findCallback: Method provided to help importElement find the given elem on the given org.
   *   - newCallback: Method which will be called if the given elem is newly created and doesn't exist on the given org.
   *                  Usually this method is used to add the given elem to the given org.
   * This method returns an error object or an array of errors, or a null value denoting success.
   */
  static importElement(
    org: UOrgMaster,
    elem: UBasicData,
    operation: OrgOperation,
    findCallback: (elem: UBasicData) => UBasic | undefined,
    newCallback: (elem: UBasicData) => ErrorData | ErrorData[] | null
  ): ErrorData | ErrorData[] | null {
    const existingElem = findCallback(elem);
    if (operation === OrgOperation.CREATE) {
      if (existingElem) {
        return {
          errorMessage: `Element ${elem.id} already in org ${org.organization.name}`,
          code: ImportError.ELEMENT_ALREADY_IN_ORG,
        };
      }
      return newCallback(elem);
    }
    if (!existingElem) {
      return {
        errorMessage: `Element ${elem.id} not found in org ${org.organization.name}`,
        code: ImportError.ELEMENT_NOT_FOUND,
      };
    }
    if (operation === OrgOperation.DELETE) {
      existingElem.delete();
      return null;
    }
    if (operation === OrgOperation.UPDATE) {
      existingElem.updateElem(elem);
      return null;
    }
    return null;
  }
}
