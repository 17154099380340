import React, { useState, useEffect } from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import { LocaleSettings } from '../../../../services/locale/LocaleSettings';
import Analytics from '../../../../Analytics/Analytics';
import config from '../../../../configurations/config';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import BanyanCompartmentAPI from '../../../../providers/BanyanCompartmentAPI';

const localeMessages = defineMessages({
  cancel: {
    id: 'Profiletable.AddProfileNotSupportedDialog.cancel',
    defaultMessage: 'Cancel',
  },
  ok: {
    id: 'Profiletable.AddProfileNotSupportedDialog.ok',
    defaultMessage: 'Ok',
  },
  goToAdminConsole: {
    id: 'Profiletable.AddProfileNotSupportedDialog.goToAdminConsole',
    defaultMessage: 'Go to Admin Console to add profile',
  },
});

interface AddProfileNotSupportedDialogProps {
  productId: string;
  selectedOrg: UOrgMaster;
}

function AddProfileNotSupportedDialogInternal(props: AddProfileNotSupportedDialogProps): JSX.Element {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [isUserSysAdmin, setIsUserSysAdmin] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    (async (): Promise<void> => {
      const isUserSysAdminCurrently = await BanyanCompartmentAPI.isCurrentUserSystemAdminOf(props.selectedOrg.id);
      setIsUserSysAdmin(isUserSysAdminCurrently);
    })();
  }, [props.selectedOrg.id]);

  return (
    <Dialog
      className="DelProductDialog__container"
      title={
        <FormattedMessage
          id="Profiletable.AddProfileNotSupportedDialog.title"
          defaultMessage="Add profile not supported in Global Admin Console"
        />
      }
      confirmLabel={isUserSysAdmin ? formatMessage(localeMessages.goToAdminConsole) : formatMessage(localeMessages.ok)}
      onConfirm={(): void => {
        Analytics.fireCTAEvent(`Open Admin Console link clicked`);
        if (isUserSysAdmin) {
          window.open(`${config.adminConsole.url}/${props.selectedOrg.id}/products/${props.productId}/profiles`);
        }
      }}
      cancelLabel={isUserSysAdmin ? formatMessage(localeMessages.cancel) : ''}
      onCancel={(): void => Analytics.fireCTAEvent('add product dialog not supported canceled')}
      {...props}
      role="dialog"
    >
      {isUserSysAdmin === undefined && <Wait className="Load_wait" />}
      {isUserSysAdmin !== undefined && isUserSysAdmin && (
        <FormattedMessage
          id="Profiletable.AddProfileNotSupportedDialog.messageWhenSystemAdmin"
          defaultMessage="Adding a profile for this product is not supported in Global Admin Console. Go to Admin Console to add a profile for this product."
        />
      )}
      {isUserSysAdmin !== undefined && !isUserSysAdmin && (
        <FormattedMessage
          id="Profiletable.AddProfileNotSupportedDialog.messageNotSystemAdmin"
          defaultMessage="Adding a profile for this product is not supported in Global Admin Console. You do not have system admin rights on this organization. Add yourself as a system admin and then go to Admin Console to add a profile for this product."
        />
      )}
    </Dialog>
  );
}

function AddProfileNotSupportedDialog(props: Omit<AddProfileNotSupportedDialogProps, 'ref'>): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <AddProfileNotSupportedDialogInternal {...props} />
    </IntlProvider>
  );
}

export default AddProfileNotSupportedDialog;
