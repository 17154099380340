import OrgPickerController from '../organization/OrgPickerController';
import UserProfileService from '../authentication/UserProfileService';
import { TEMP_ID_PREFIX } from '../orgMaster/OrgMaster';

class TempIdGenerator {
  private static readonly TEMP_ID_MAP: string = 'tempIdMap';

  /**
   * Map to store key()->currentTempId. See key().
   */
  private static tempIdMap: Map<string, number> = new Map<string, number>();
  private static currentTempId: number = 0;

  /**
   * @returns [rootOrgId, userId]
   */
  private static key(): string {
    return JSON.stringify([OrgPickerController.getActiveOrgId() as string, UserProfileService.getUserId() as string]);
  }

  public static initializeTempIdsFromSessionStorage(): void {
    const tempIdMapOrUndefined: string | null = sessionStorage.getItem(this.TEMP_ID_MAP);
    if (tempIdMapOrUndefined) {
      this.tempIdMap = new Map(JSON.parse(tempIdMapOrUndefined) as Map<string, number>);
      const currentTempId: number | undefined = this.tempIdMap.get(this.key());
      if (currentTempId !== undefined) {
        this.currentTempId = currentTempId;
      } else {
        this.currentTempId = 0;
      }
    }
  }

  public static saveTempIdsToSessionStorage(): void {
    sessionStorage.setItem(this.TEMP_ID_MAP, JSON.stringify(Array.from(this.tempIdMap)));
  }

  public static cleanTempIdsInSessionStorage(): void {
    this.tempIdMap.delete(this.key());
  }

  /**
   * Retrieves a tempId for product, org, etc which doesn't exist on the backend yet
   * Each call of this method generates a new tempId.
   * TODO: This method is currently being called in the render method of many components like UserGroupTable, which causes the temp id to increment even when its not actually being used. Fix that by not calling this method inside render method so that temp ids dont get incremented unnecessarily
   */
  public static getTempIdAndIncrement(): string {
    const newTempId = ++this.currentTempId;
    this.tempIdMap.set(this.key(), this.currentTempId);
    return TEMP_ID_PREFIX + newTempId.toString();
  }
}

export default TempIdGenerator;
