import React from 'react';
import { Footer as FooterArea, Divider, Text } from '@adobe/react-spectrum';

import { FormattedMessage } from 'react-intl';
import { LocaleSettings, enableLocaleSelector } from '../../services/locale/LocaleSettings';
import LocaleSelector from './LocaleSelector/LocaleSelector';
import GoUrl, { GoUrlKeys } from '../GoUrl/GoUrl';

import './Footer.css';

export const FOOTER_ID = 'FooterID';

function Footer(): React.ReactElement {
  const currentYear = new Date().getFullYear();
  return (
    <span id={FOOTER_ID} className="Footer_area">
      <FooterArea>
        <Divider size="S" />
        <GoUrl className="Footer__link" goUrlKey={GoUrlKeys.terms} target="gac_termsOfUse">
          <FormattedMessage id="footer.links.termsOfUse" defaultMessage="Terms of Use" />
        </GoUrl>
        <GoUrl className="Footer__link" goUrlKey={GoUrlKeys.privacy} target="gac_privacy">
          <FormattedMessage id="footer.links.privacy" defaultMessage="Privacy" />
        </GoUrl>
        <GoUrl className="Footer__link" goUrlKey={GoUrlKeys.adchoices} target="gac_adChoices">
          <FormattedMessage id="footer.links.adChoices" defaultMessage="ADChoices" />
        </GoUrl>
        <GoUrl className="Footer__link" goUrlKey={GoUrlKeys.cookies} target="gac_cookies">
          <FormattedMessage id="footer.links.cookies" defaultMessage="Cookie preferences" />
        </GoUrl>
        <GoUrl className="Footer__link" localize={false} goUrlKey={GoUrlKeys.caRights} target="gac_ca_rights">
          <FormattedMessage id="footer.links.usRights" defaultMessage="Do not sell or share my personal information" />
        </GoUrl>
        {enableLocaleSelector ? (
          <LocaleSelector
            className="Footer__label LocaleSelector"
            defaultSelectedLocale={LocaleSettings.getSelectedLanguageTag()}
          />
        ) : undefined}

        <Text position="absolute" bottom="0.6rem" right="1rem">
          {/* This legal message is not localized */}
          &copy; {currentYear} Adobe. All rights reserved.
        </Text>
      </FooterArea>
    </span>
  );
}
export default Footer;
