import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';

import { ImportOrganizationsDM } from '../DataModelTypes/DataModelTypes';

import OrgPickerController from '../../../../services/organization/OrgPickerController';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';

import { ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import { ExpandedNodesUtil } from '../../../../services/treeTableUtils/ExpandedNodesUtil';
import ImportOperationsUtils from '../../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import ImportUtils, { ChangeCount } from '../ImportUtils';
import { ORG_MAXIMUM_LENGTH, ORG_MINIMUM_LENGTH } from '../../../EditCompartment/Widgets/OrgNameEdit';
import OrgDeletionService from '../../../EditCompartment/OrgDeletionService';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import Utils from '../../../../services/utils/Utils';
import { Country } from '../../../../providers/ImsProvider';
import CountryList from '../../../../services/countries/CountryList';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import { AddOrgService } from '../../../../services/organization/AddOrgService';
import { CommandService } from '../../../../services/Commands/CommandService';
import OrgTreeCache from '../../../OrgTree/OrgTreeCache';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';
import { CmdDescriptionCodes } from '../../../../services/Codes/MessageImages';

const messages = defineMessages({
  OrganizationsLabel: {
    id: 'Organizations.Import.Organizations.OrganizationsLabel',
    defaultMessage: 'Organizations',
  },
  OrgNameInvalidError: {
    id: 'Organizations.Import.Organizations.OrgNameInvalid',
    defaultMessage: "name cannot be blank or null for record ''{index}''",
  },
  OrgIdInvalidError: {
    id: 'Organizations.Import.Organizations.OrgIdInvalidError',
    defaultMessage: "id cannot be blank or null for record ''{index}''",
  },
  ParentOrgIdInvalidError: {
    id: 'Organizations.Import.Organizations.ParentOrgIdInvalidError',
    defaultMessage: "parentOrgId cannot be blank or null for record ''{index}''",
  },
  OrgIdDuplicateError: {
    id: 'Organizations.Import.Organizations.OrgIdDuplicateError',
    defaultMessage: 'The following org ids are duplicate in the imported data: ',
  },
  OrgIdsWithChildrenError: {
    id: 'Organizations.Import.Organizations.OrgIdsWithChildrenError',
    defaultMessage:
      'Organizations to be deleted may not have child organizations. The following organization IDs do not satisfy this condition: ',
  },
  SameOrgAndParentOrgIdError: {
    id: 'Organizations.Import.Organizations.SameOrgAndParentOrgIdError',
    defaultMessage: 'The following orgs have the same org and parent org id (invalid operation): ',
  },
  OrgNotExistError: {
    id: 'Organizations.Import.Organizations.OrgNotExistError',
    defaultMessage: "The following orgIds marked for 'UPDATE' or 'DELETE' do not exist:",
  },
  ParentOrgIdMarkedForDeletion: {
    id: 'Organizations.Import.Organizations.ParentOrgIdMarkedForDeletion',
    defaultMessage:
      'The following org ids are marked for deletion and also selected as parentOrgId (invalid operation):',
  },
  ParentOrgIdNotValid: {
    id: 'Organizations.Import.Organizations.ParentOrgIdNotValid',
    defaultMessage: 'The following organizations do not have a valid parent org id:',
  },
  CycleError: {
    id: 'Organizations.Import.Organizations.CycleError',
    defaultMessage: 'Cycle exists in the org hierarchy including orgId {orgId}',
  },
  InvalidRootOrgDelete: {
    id: 'Organizations.Import.Organizations.InvalidRootOrgDelete',
    defaultMessage: 'Root org cannot be marked for deletion.',
  },
  InvalidCountryCode: {
    id: 'Organizations.Import.Organizations.InvalidCountryCode',
    defaultMessage: "The following org id's do not have a valid countryCode:",
  },
  InvalidOrgNameLength: {
    id: 'Organizations.Import.Organizations.InvalidOrgNameLength',
    defaultMessage:
      'Organization names must be between 4 and 100 characters. The following organizations do not satisfy this condition:',
  },
  TypeTOrgsCanNotBeAParentOrg: {
    id: 'Organizations.Import.Organizations.TypeTOrgsCanNotBeAParentOrg',
    defaultMessage: 'The following organizations cannot be used as parent orgs because of their org type:',
  },
  TypeTOrgsCanNotDeleted: {
    id: 'Organizations.Import.Organizations.TypeTOrgsCanNotDeleted',
    defaultMessage: 'The following organizations cannot be deleted because of their org type:',
  },
  OrgNameAlreadyExists: {
    id: 'Organizations.Import.Organizations.OrgNameAlreadyExists',
    defaultMessage: "Child org name ''{name}'' already exist under parentOrgId ''{parentOrgId}''",
  },
  MultipleChildOrgs: {
    id: 'Organizations.Import.Organizations.MultipleChildOrgs',
    defaultMessage: "Import causes multiple child orgs ''{orgName}'' to be created under org ''{parentOrgId}''",
  },
  OrgAndParentOrgInfo: {
    id: 'Organizations.Import.Organizations.OrgAndParentOrgInfo',
    defaultMessage: "Org name: ''{name}'', parentOrgId: ''{parentOrgId}''",
  },
  OrgInfoMessage: {
    id: 'Organizations.Import.Organizations.OrgInfoMessage',
    defaultMessage: "Org name: ''{name}'', orgId: ''{orgId}''",
  },
  OrgCannotBeAdded: {
    id: 'Organizations.Import.Organizations.OrgCannotBeAdded',
    defaultMessage: "Org ''{name}'' cannot be added to org ''{orgId}''",
  },
  UnableToExport: {
    id: 'Organizations.Import.Organizations.UnableToExport',
    defaultMessage: 'Unable to import at this time, please try again later.',
  },
});

class ImportOrganizations {
  // When the new org is added to the org hierarchy, it gets assigned a new temporary id
  // which is different from the one provided by the user in the input file
  // For e.g.
  // When Org A is added to the hierarchy, it is assigned an org Id="NEW_ID_1"
  // WHY is it NEEDED?
  // NOTE: Org id A & B are org ids assigned by the user in input file
  // Org id A (New org) is set as parent org of Org id B (New org).
  // When Org id A is added to org hierarchy, it is assigned a new org id="NEW_ID_1".
  // When creating Org id B, to assign Org id A as the parent of Org id B, we need to fetch
  // detials of org id "NEW_ID_1" from the org hierarchy instead of Org id A.
  // inputOrgIdToOrgIdInHierarchyMap helps in keeping this mapping
  // Mapping is as such: Org id A -> "NEW_ID_1"
  private static inputOrgIdToOrgIdInHierarchyMap = new Map<string, string>();

  /**
   * Check if the 'orgIdToCheck' is present in the new orgs to be created
   */
  public static isOrgANewOrg(allOrgs: ImportOrganizationsDM[], orgIdToCheck: string): boolean {
    const orgsToBeCreated = ImportUtils.filterToBeCreatedItems(allOrgs) as ImportOrganizationsDM[];
    return (
      _.find(orgsToBeCreated, (org: ImportOrganizationsDM): boolean => _.isEqual(org.id, orgIdToCheck)) !== undefined
    );
  }

  private static existingOrg(orgId: string): boolean {
    return this.getOrgFromHierarchy(orgId) !== undefined;
  }

  /**
   * Check if a org is valid or not
   * A org is valid if it is a new org or one existing in the hierarchy
   */
  public static isOrgValid(allOrgs: ImportOrganizationsDM[], orgIdToCheck: string): boolean {
    // check if org is an existing org or a new org
    return (
      this.getOrgFromHierarchy(orgIdToCheck) !== undefined || ImportOrganizations.isOrgANewOrg(allOrgs, orgIdToCheck)
    );
  }

  /**
   * @param allOrgs all orgs from input file
   * @param orgId to be checked
   * @returns true if the 'orgId' is marked for deletion else false
   */
  public static isOrgMarkedForDelete(allOrgs: ImportOrganizationsDM[], orgId: string): boolean {
    return (
      _.find(allOrgs, (org: ImportOrganizationsDM): boolean => {
        return ImportOperationsUtils.isOperationDelete(org.operation) && org.id === orgId;
      }) !== undefined
    );
  }

  public static getOrgFromHierarchy(orgId: string): UOrgMaster | undefined {
    return HierarchyManager.getOrg(orgId);
  }

  /**
   * Get orgId in the hierarchy for the 'orgId' passed in.
   * If 'orgId' is an existing org, return the 'orgId' back.
   * For new orgs, get orgId in hierarchy from inputOrgIdToOrgIdInHierarchyMap
   */
  public static getOrgIdInHierarchy(orgId: string): string {
    return this.inputOrgIdToOrgIdInHierarchyMap.has(orgId)
      ? (this.inputOrgIdToOrgIdInHierarchyMap.get(orgId) as string)
      : orgId;
  }

  /**
   * Find parentOrgId for the orgId passed in. The orgId can be a new org or an existing org.
   * For existing org, the parentOrgId is returned from OrgMasterTree
   * For new orgs, the parentOrgId is returned from the allOrgs passed in.
   * In other cases, return undefined
   * @param allOrgs all org data from the input file
   */
  public static getParentOrgId(orgId: string, allOrgs: ImportOrganizationsDM[]): string | undefined {
    // check is org is a new org
    const org = this.getOrgFromHierarchy(orgId);
    if (org !== undefined) {
      return org.organization.parentOrgId;
    }

    // for new orgs
    const newOrg = _.find(allOrgs, (eachNewOrg: ImportOrganizationsDM): boolean => _.isEqual(eachNewOrg.id, orgId));
    if (newOrg !== undefined && newOrg.parentOrgId) {
      return newOrg.parentOrgId;
    }
  }

  public static getParentOrgMaster(orgId: string): UOrgMaster | undefined {
    const parentOrgId = this.inputOrgIdToOrgIdInHierarchyMap.has(orgId)
      ? (this.inputOrgIdToOrgIdInHierarchyMap.get(orgId) as string)
      : orgId;

    return this.getOrgFromHierarchy(parentOrgId);
  }

  /**
   * check if the org id, name, & parent org id is set to valid values
   */
  private static verifyNullChecks(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allOrgs, (org: ImportOrganizationsDM, index: number): void => {
      if (ImportOperationsUtils.isOperationValid(org.operation)) {
        // check is name is blank
        if (ImportUtils.isNullOrEmpty(org.name)) {
          errorMessages.push(formatMessage(messages.OrgNameInvalidError, { index: index + 2 }));
        }

        // check is org id is blank
        if (_.isNil(org.id) && !ImportOperationsUtils.isOperationCreate(org.operation)) {
          errorMessages.push(formatMessage(messages.OrgIdInvalidError, { index: index + 2 }));
        }

        // parent org id can only be blank for root org.
        if (
          _.isNil(org.parentOrgId) &&
          ImportOperationsUtils.isOperationCreate(org.operation) &&
          !_.isEqual(org.id, OrgPickerController.getActiveOrgId())
        ) {
          errorMessages.push(formatMessage(messages.ParentOrgIdInvalidError, { index: index + 2 }));
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.OrganizationsLabel), errorMessages);
  }

  /**
   * check if all the org ids are unique in the imported data.
   */
  private static validateUniqueOrgIds(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const duplicates = new Set<string>();
    const orgIdFound = new Set<string>();

    allOrgs.forEach((org: ImportOrganizationsDM): void => {
      // new orgs to be created can have id field blank
      if (ImportUtils.isNullOrEmpty(org.id)) {
        return;
      }
      if (orgIdFound.has(org.id)) {
        duplicates.add(org.id);
      } else {
        orgIdFound.add(org.id);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.OrgIdDuplicateError),
      Array.from(duplicates)
    );
  }

  /**
   * Validate that non leaf org are not marked for deletion
   * @param allOrgs all orgs from import file
   */
  private static validateOrgIsLeafOrgForDelete(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const orgsToBeDeleted = _.filter(allOrgs, (org: ImportOrganizationsDM): boolean =>
      ImportOperationsUtils.isOperationDelete(org.operation)
    );
    const orgIdsWithChildren: string[] = [];

    _.forEach(orgsToBeDeleted, (org: ImportOrganizationsDM): void => {
      const uOrg = HierarchyManager.getOrg(org.id);
      if (uOrg && uOrg.childrenRefs.length > 0) {
        orgIdsWithChildren.push(org.id);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.OrgIdsWithChildrenError),
      Array.from(orgIdsWithChildren)
    );
  }

  /**
   * Validate ORG_MINIMUM_LENGTH < org name length < ORG_MAXIMUM_LENGTH
   * for all orgs to be created or updated
   * @param allOrgs all orgs from input file
   */
  private static validateOrgNameLength(allOrgs: ImportOrganizationsDM[]): void {
    const orgsToBeCreatedOrUpdated = _.filter(
      allOrgs,
      (org: ImportOrganizationsDM): boolean =>
        ImportOperationsUtils.isOperationCreate(org.operation) || ImportOperationsUtils.isOperationUpdate(org.operation)
    );
    const invalidOrgs: string[] = [];

    _.forEach(orgsToBeCreatedOrUpdated, (org: ImportOrganizationsDM): void => {
      if (org.name.length < ORG_MINIMUM_LENGTH || org.name.length > ORG_MAXIMUM_LENGTH) {
        invalidOrgs.push(this.getOrgNameAndParentOrgIdMessage(org));
      }
    });

    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.OrganizationsLabel),
      Utils.getLocalizedMessage(messages.InvalidOrgNameLength),
      invalidOrgs
    );
  }

  /**
   * check if org id and parent org id are not same
   */
  private static validateOrgIdsAndParentOrgIdsNotSame(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    // validate that org id and source org id are NOT same for orgs to be created.
    // NOTE update and delete operation do NOT depend on data from the input file
    const sameOrgAndParentOrgs = _.filter(
      allOrgs,
      (org: ImportOrganizationsDM): boolean =>
        _.isEqual(org.id, org.parentOrgId) && ImportOperationsUtils.isOperationCreate(org.operation)
    );
    const { formatMessage } = intl;
    const sameOrgIds = sameOrgAndParentOrgs.map((org: ImportOrganizationsDM): string => org.id);
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.SameOrgAndParentOrgIdError),
      sameOrgIds
    );
  }

  /**
   * For update and delete operation, org should already be present in the org hierarchy
   */
  private static validateOrgInHierarchyForUpdateDeleteOperation(
    allOrgs: ImportOrganizationsDM[],
    intl: IntlShape
  ): void {
    const invalidOrgsIds = new Set<string>();
    _.forEach(allOrgs, (org: ImportOrganizationsDM): void => {
      // check if the operation is 'delete' | 'update' & org in existing hierarchy
      if (
        (ImportOperationsUtils.isOperationDelete(org.operation) ||
          ImportOperationsUtils.isOperationUpdate(org.operation)) &&
        !this.existingOrg(org.id)
      ) {
        invalidOrgsIds.add(org.id);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.OrgNotExistError),
      Array.from(invalidOrgsIds)
    );
  }

  /**
   * @returns true if the org 'orgId' has a child org with name 'childOrgName'
   */
  private static isChildOrgNamePresent(orgId: string, childOrgName: string): boolean {
    const uOrg = HierarchyManager.getOrg(orgId);
    if (uOrg !== undefined) {
      return (
        _.find(uOrg.getChildren(), (childOrg: UOrgMaster) => childOrg.organization.name === childOrgName) !== undefined
      );
    }
    return false;
  }

  /**
   * check if the import operations causes parent org with same child org names
   * Following operation are validated
   * 1) two orgs with same name cannot be created under a common parent
   * 2) new org cannot be created with a existing name under parents hierarchy. NOTE: this also covers the case
   *    when an existing child org name is updated to say 'OrgA' in input file (UPDATE operation on existing org)
   *    and a new org is created under same parent with name "OrgA" (CREATE operation).
   * Both the operations lead to invalid hierarchy and are not allowed.
   */
  private static validateSameNamedOrgAtALevel(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const orgsToBeCreatedOrUpdated = _.filter(allOrgs, (org: ImportOrganizationsDM): boolean => {
      return (
        ImportOperationsUtils.isOperationCreate(org.operation) || ImportOperationsUtils.isOperationUpdate(org.operation)
      );
    });

    // Map parent org id to the child org names (child org names is a list)
    const parentOrgIdToChildOrgNamesMap = new Map<string, string[]>();

    // track org ids with same org names
    const orgsWithExistingOrgNames = new Set<string>();

    const { formatMessage } = intl;
    _.forEach(orgsToBeCreatedOrUpdated, (org: ImportOrganizationsDM): void => {
      if (_.isNil(org.parentOrgId)) {
        return;
      }

      if (this.isChildOrgNamePresent(org.parentOrgId, org.name)) {
        orgsWithExistingOrgNames.add(
          formatMessage(messages.OrgNameAlreadyExists, { name: org.name, parentOrgId: org.parentOrgId })
        );
        return;
      }

      // get the org names mapped to the parentOrgId
      let orgNames = parentOrgIdToChildOrgNamesMap.get(org.parentOrgId);

      if (orgNames === undefined) {
        orgNames = [];
      }

      // check if the parent has the org with the same name
      if (_.find(orgNames, (orgName: string) => _.isEqual(orgName, org.name)) !== undefined) {
        orgsWithExistingOrgNames.add(
          formatMessage(messages.MultipleChildOrgs, { orgName: org.name, parentOrgId: org.parentOrgId })
        );
      } else {
        // add the current org name to the list
        orgNames.push(org.name);

        // map the parent org id to the new org name list
        parentOrgIdToChildOrgNamesMap.set(org.parentOrgId, orgNames);
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.OrganizationsLabel), Array.from(orgsWithExistingOrgNames));
  }

  /**
   * check if the org to be deleted is not a parent org id of any org with update or create operation
   */
  private static validateParentOrgIdsNotMarkedAsDeleted(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    // create set for org ids which are marked for deletion
    const orgIdsToBeDeleted = new Set<string>();
    _.forEach(allOrgs, (org: ImportOrganizationsDM): void => {
      if (ImportOperationsUtils.isOperationDelete(org.operation)) {
        orgIdsToBeDeleted.add(org.id);
      }
    });

    const invalidParentOrgIds: string[] = [];

    // loop through and check if any org id with create | update operation references
    // org ids marked for deletion
    _.forEach(allOrgs, (org: ImportOrganizationsDM): void => {
      if (_.isNil(org.parentOrgId) || _.isNil(org.operation)) {
        return;
      }

      // check if the operation is 'create' | 'update' and parent org id is marked for deletion
      if (
        (ImportOperationsUtils.isOperationCreate(org.operation) ||
          ImportOperationsUtils.isOperationUpdate(org.operation)) &&
        orgIdsToBeDeleted.has(org.parentOrgId)
      ) {
        invalidParentOrgIds.push(org.parentOrgId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.ParentOrgIdMarkedForDeletion),
      Array.from(invalidParentOrgIds)
    );
  }

  private static getOrgNameAndParentOrgIdMessage(org: ImportOrganizationsDM): string {
    return Utils.getLocalizedMessage(messages.OrgAndParentOrgInfo, { name: org.name, parentOrgId: org.parentOrgId });
  }

  /**
   * Validate if a parent org id is valid.
   * Every org in the hierarchy should map back to the root org either directly or indirectly.
   *
   * Example:
   * Here a org1 -> org2 represents that org2 is the parent org of org1
   * A is a root org in the hierarchy
   *
   * Valid hierarchy:
   * B -> A,
   * C -> B
   * Here A, B, & C can reach A (C can reach A via B). Hence a valid hierarchy.
   *
   * Invalid hierarchy
   * B -> A
   * C -> D
   * Here C & D do not reach A and hence a disjoint set is formed in the hierarchy.
   *
   * NOTE (IMPORTANT): This also handles the case when the imported data is for a org in some different
   * hierarchy i.e. say the imported data is for org A, and the org selected in the org picker is org B,
   * then the mappings would never reach org B
   */
  private static validateParentOrgId(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const invalidOrgIds: string[] = [];
    const orgsToBeCreated = ImportUtils.filterToBeCreatedItems(allOrgs) as ImportOrganizationsDM[];
    const { formatMessage } = intl;

    _.forEach(orgsToBeCreated, (org: ImportOrganizationsDM): void => {
      // visitedOrgs keeps track of all orgs visited during this traversal. If an organization is
      // visited again, cycle exists in the hierarchy
      const visitedOrgs = new Set<string>();

      visitedOrgs.add(this.getOrgKey(org));
      if (!this.isParentOrgValid(org.parentOrgId, visitedOrgs, orgsToBeCreated)) {
        invalidOrgIds.push(this.getOrgNameAndParentOrgIdMessage(org));
      }
    });

    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.ParentOrgIdNotValid),
      Array.from(invalidOrgIds)
    );
  }

  /**
   * Checks if the parent org is valid. An org is considered as valid, if we can traverse from that org
   * to an org in the existing hierarchy directly and indirectly.
   * Traversal is from the org to its parent (recursive)
   */
  private static isParentOrgValid(
    parentOrgId: string | undefined,
    visitedOrgs: Set<string>,
    orgsToBeCreated: ImportOrganizationsDM[]
  ): boolean {
    if (parentOrgId === undefined) {
      return false;
    }

    // if parentOrgId is an existing org then it is a valid parent org
    if (this.existingOrg(parentOrgId)) {
      return true;
    }

    const parentOrgRecord = _.find(orgsToBeCreated, (org: ImportOrganizationsDM): boolean => org.id === parentOrgId);
    if (parentOrgRecord === undefined) {
      return false;
    }

    // check if this org is already visited, if yes, then a loop exists
    if (visitedOrgs.has(this.getOrgKey(parentOrgRecord))) {
      throw Error(
        `[${Utils.getLocalizedMessage(messages.OrganizationsLabel)}] ${Utils.getLocalizedMessage(messages.CycleError, {
          orgId: parentOrgId,
        })}`
      );
    }

    // mark the org id as visited
    visitedOrgs.add(this.getOrgKey(parentOrgRecord));

    return this.isParentOrgValid(parentOrgRecord.parentOrgId, visitedOrgs, orgsToBeCreated);
  }

  /**
   * Validate if a valid country code is selected.
   */
  private static async validateCountryCode(allOrgs: ImportOrganizationsDM[], intl: IntlShape): Promise<void> {
    // wait till the country code data is loaded
    try {
      const countryList: Country[] = await CountryList.getCountryList();
      const orgsWithInvalidCountryCodes = new Set<string>();
      _.forEach(allOrgs, (org: ImportOrganizationsDM) => {
        if (
          (ImportOperationsUtils.isOperationCreate(org.operation) ||
            ImportOperationsUtils.isOperationUpdate(org.operation)) &&
          !ImportUtils.isCountryCodeOrCountryNameValid(org.countryCode, countryList)
        ) {
          orgsWithInvalidCountryCodes.add(this.getOrgNameAndParentOrgIdMessage(org));
        }
      });

      const { formatMessage } = intl;
      ImportUtils.displayIfErrorWithHeader(
        formatMessage(messages.OrganizationsLabel),
        formatMessage(messages.InvalidCountryCode),
        Array.from(orgsWithInvalidCountryCodes)
      );
    } catch (error) {
      const { formatMessage } = intl;
      throw Error(formatMessage(messages.UnableToExport));
    }
  }

  private static validateRootOrgNotMarkedForDeletion(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    _.forEach(allOrgs, (org: ImportOrganizationsDM): void => {
      if (
        _.isEqual(org.id, OrgPickerController.getActiveOrgId()) &&
        ImportOperationsUtils.isOperationDelete(org.operation)
      ) {
        const { formatMessage } = intl;
        throw Error(`[${formatMessage(messages.OrganizationsLabel)}] ${formatMessage(messages.InvalidRootOrgDelete)}`);
      }
    });
  }

  /**
   * Validate that EXISTING READ ONLY orgs cannot be selected as parentOrgId for any new org to be created.
   
   * NOTE: new READ ONLY org cannot be created via import. Even if the type of org is provided by the user, the type 
   * for new orgs will be overridden by import as to be same as that of the parent org.
   * 
   * #TODO: need to add check for update when import starts supporting reparent
   * 
   * @param allOrgs all orgs from input file
   */
  private static validateReadOnlyOrgNotSelectedAsParentOrg(allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const toBeCreatedOrgs = ImportUtils.filterToBeCreatedItems(allOrgs) as ImportOrganizationsDM[];
    const invalidOrgData = new Set<string>();
    _.forEach(toBeCreatedOrgs, (org: ImportOrganizationsDM): void => {
      if (org.parentOrgId) {
        const uParentOrgMaster = ImportOrganizations.getOrgFromHierarchy(org.parentOrgId);
        // NOTE: here we do not need to worry if the uParentOrgMaster is not found because parentOrgId might be a new org.
        // and eventually we will get an error when checking the existing parent (parentOrgId's ancestor) if it is read-only, and the entire import
        // operation will fail
        if (uParentOrgMaster && uParentOrgMaster.isReadOnlyOrg()) {
          invalidOrgData.add(
            Utils.getLocalizedMessage(messages.OrgCannotBeAdded, {
              name: org.name,
              orgId: uParentOrgMaster.organization.id,
            })
          );
        }
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrganizationsLabel),
      formatMessage(messages.TypeTOrgsCanNotBeAParentOrg),
      Array.from(invalidOrgData)
    );
  }

  /**
   * Validate that existing type t orgs should not be deleted
   *
   * @param allOrgs all orgs from input file
   */
  public static validateNoTypeTOrgDeletion(allOrgs: ImportOrganizationsDM[]): void {
    const toBeDeletedOrgs = ImportUtils.filterToBeDeletedItems(allOrgs) as ImportOrganizationsDM[];
    const invalidOrgData = new Set<string>();
    _.forEach(toBeDeletedOrgs, (org: ImportOrganizationsDM): void => {
      const uOrg = ImportOrganizations.getOrgFromHierarchy(org.id);
      // validate that existing type T orgs are not marked for deletion
      // NOTE: when the code reaches here, it is guaranteed that the org exists else an error will be
      // caught in validateOrgInHierarchyForUpdateDeleteOperation
      if (uOrg && uOrg.isReadOnlyOrg()) {
        invalidOrgData.add(this.getOrgNameAndParentOrgIdMessage(org));
      }
    });

    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.OrganizationsLabel),
      Utils.getLocalizedMessage(messages.TypeTOrgsCanNotDeleted),
      Array.from(invalidOrgData)
    );
  }

  /**
   * Load org details for all orgs with valid operation and its parent
   * @param allOrgs all orgs from input file
   * @returns fn containing a promise to load org details in bulk
   */
  public static loadOrgDetails(allOrgs: ImportOrganizationsDM[]): { (): Promise<void> } {
    const allOrgIds = new Set<string>();
    _.forEach(allOrgs, (org: ImportOrganizationsDM): void => {
      if (ImportOperationsUtils.isOperationValid(org.operation)) {
        allOrgIds.add(org.id);
        if (org.parentOrgId) {
          allOrgIds.add(org.parentOrgId);
        }
      }
    });
    return () => LoadOrgDataService.loadOrgDetailsBulk(Array.from(allOrgIds));
  }

  public static async validate(allOrgs: ImportOrganizationsDM[], intl: IntlShape): Promise<void> {
    // reset the map on every import
    this.inputOrgIdToOrgIdInHierarchyMap = new Map<string, string>();

    // validate org data
    ImportOrganizations.verifyNullChecks(allOrgs, intl);
    ImportOrganizations.validateUniqueOrgIds(allOrgs, intl);

    // validate country code
    await ImportOrganizations.validateCountryCode(allOrgs, intl);

    // validate operations in the org hierarchy
    ImportOrganizations.validateOrgInHierarchyForUpdateDeleteOperation(allOrgs, intl);
    ImportOrganizations.validateOrgIsLeafOrgForDelete(allOrgs, intl);
    ImportOrganizations.validateParentOrgIdsNotMarkedAsDeleted(allOrgs, intl);
    ImportOrganizations.validateReadOnlyOrgNotSelectedAsParentOrg(allOrgs, intl);
    ImportOrganizations.validateSameNamedOrgAtALevel(allOrgs, intl);
    ImportOrganizations.validateOrgIdsAndParentOrgIdsNotSame(allOrgs, intl);

    // NOTE: must be called after validateOrgInHierarchyForUpdateDeleteOperation fn
    ImportOrganizations.validateNoTypeTOrgDeletion(allOrgs);
    ImportOrganizations.validateRootOrgNotMarkedForDeletion(allOrgs, intl);
    ImportOrganizations.validateOrgNameLength(allOrgs);

    // handle cycles and incorrect org hierarchy
    ImportOrganizations.validateParentOrgId(allOrgs, intl);
  }

  /**
   * handles org deletion.
   */
  private static handleOrgDeletion(orgId: string): void {
    const org = HierarchyManager.getOrg(orgId);
    if (org) {
      OrgDeletionService.deleteOrgHelper(org);
    }
  }

  private static handleOrgsToBeDeleted(allOrgs: ImportOrganizationsDM[]): number {
    // filter orgs to be deleted
    const orgsToBeDeleted = ImportUtils.filterToBeDeletedItems(allOrgs) as ImportOrganizationsDM[];

    orgsToBeDeleted.forEach((org: ImportOrganizationsDM): void => {
      // only add delete for the orgs that exist
      const uOrg = this.getOrgFromHierarchy(org.id);
      if (uOrg && !uOrg.isReadOnlyOrg()) {
        this.handleOrgDeletion(org.id);
        ExpandedNodesUtil.expandOrg(org.id);
      }
    });

    return orgsToBeDeleted.length;
  }

  private static orgUpdationHelper(
    originalOrg: UOrgMaster,
    editedCompartment: UOrgMaster,
    cmdDescriptionCode: CmdDescriptionCodes,
    cmdDescriptionParams: string[]
  ): void {
    CommandService.addEdit(
      editedCompartment,
      editedCompartment.organization,
      ObjectTypes.ORGANIZATION,
      OrgOperation.UPDATE,
      originalOrg.organization,
      cmdDescriptionCode,
      cmdDescriptionParams
    );
    ExpandedNodesUtil.expandOrg(editedCompartment.organization.id);
    OrgTreeCache.clear(); // update org tree if the org name is edited
  }

  /**
   * Checks if the org name was updated
   * @param uOrg UOrgMaster of the org on which updates are being performed
   * @param org input org data
   * @returns true if the org name was updated or false
   */
  private static isOrgNameUpdated(uOrg: UOrgMaster, org: ImportOrganizationsDM): boolean {
    return !_.isEqual(org.name, uOrg.organization.name);
  }

  private static handleOrgsToBeUpdated(allOrgs: ImportOrganizationsDM[]): number {
    // filter orgs to be updated
    const orgsToBeUpdated = ImportUtils.filterToBeUpdatedItems(allOrgs) as ImportOrganizationsDM[];
    let orgsUpdatedCount = 0;

    orgsToBeUpdated.forEach((org: ImportOrganizationsDM): void => {
      const currOrg = HierarchyManager.getOrg(org.id);

      // check if the org name was updated
      if (currOrg && this.isOrgNameUpdated(currOrg, org)) {
        const originalOrg = _.cloneDeep(currOrg);
        const oldOrgPathname = CmdDescriptionUtils.getPathname(currOrg.organization.id);
        const oldSimpleName = currOrg.organization.name;
        currOrg.organization.name = org.name;
        ImportOrganizations.orgUpdationHelper(originalOrg, currOrg, 'UPDATE_ORG_NAME', [
          oldOrgPathname,
          oldSimpleName,
          org.name,
        ]);
      }

      // NOTE: Reparenting is not handled yet. #TODO
      // currOrg.organization.parentOrgId = org.parentOrgId;

      // update the orgs updated count
      orgsUpdatedCount++;
    });

    return orgsUpdatedCount;
  }

  private static orgCreationHelper(org: ImportOrganizationsDM, parentOrganization: UOrgMaster): string {
    return AddOrgService.createNewOrg(parentOrganization, org.name, org.countryCode).id;
  }

  /**
   * adds the orgs marked as "create" in the org hierarchy
   */
  private static handleOrgsToBeCreated(allOrgs: ImportOrganizationsDM[]): number {
    // filter orgs to be created
    const orgsToBeCreated = ImportUtils.filterToBeCreatedItems(allOrgs) as ImportOrganizationsDM[];

    // keeps track of orgs which are already created
    // As org id can be blank for new orgs, parentOrgId + orgName is used uniquely identify an org
    const orgsCreated = new Set<string>();

    // keeps track of number of orgs created
    let orgsCreatedCount = 0;

    // perform until all the orgs are added
    while (orgsCreated.size < orgsToBeCreated.length) {
      // filter all orgs whose parentOrgIds are in the hierarchy
      const orgsWithParentOrgIdInHierarchy = _.filter(orgsToBeCreated, (org: ImportOrganizationsDM): boolean => {
        if (org.parentOrgId !== undefined && !orgsCreated.has(this.getOrgKey(org))) {
          // check if the parent org id is present in the org hierarchy or
          // if the parentOrg was a new org, check if the parentOrgId exists in inputOrgIdToOrgIdInHierarchyMap
          // i.e. the new org is added to the hierarchy with a different id that the one assigned in input file
          if (HierarchyManager.getOrg(org.parentOrgId) || this.inputOrgIdToOrgIdInHierarchyMap.has(org.parentOrgId)) {
            return true;
          }
        }
        return false;
      });

      // no more orgs to be processed
      if (orgsWithParentOrgIdInHierarchy.length < 1) {
        break;
      }

      orgsCreatedCount += orgsWithParentOrgIdInHierarchy.length;

      // eslint-disable-next-line no-loop-func
      orgsWithParentOrgIdInHierarchy.forEach((org: ImportOrganizationsDM): void => {
        // mark the org as created. Use parentOrgId and orgName combination to identify an org
        orgsCreated.add(this.getOrgKey(org));

        // There are two cases for parent org id
        // a) the parentOrgId can be an existing org in the hierarchy (not a new org)
        // b) the parentOrgId is a new org (to be fetched from inputOrgIdToOrgIdInHierarchyMap)

        // get the parent's org id in the org hierarchy
        const parentOrgIdInHierarchy = !HierarchyManager.getOrg(org.parentOrgId as string)
          ? this.inputOrgIdToOrgIdInHierarchyMap.get(org.parentOrgId as string)
          : org.parentOrgId;

        // get the parent org from the hierarchy
        const parentOrg = HierarchyManager.getOrg(parentOrgIdInHierarchy as string) as UOrgMaster;

        const tempOrgIdAssignedToOrg = ImportOrganizations.orgCreationHelper(org, parentOrg);

        // map the currOrgId to temporary org id
        this.inputOrgIdToOrgIdInHierarchyMap.set(org.id, tempOrgIdAssignedToOrg);

        // expand both the orgs in the org hierarchy
        ExpandedNodesUtil.expandOrg(tempOrgIdAssignedToOrg);
        ExpandedNodesUtil.expandOrg(parentOrgIdInHierarchy as string);
      });
    }
    return orgsCreatedCount;
  }

  private static getOrgKey(org: ImportOrganizationsDM): string {
    return `${org.parentOrgId}-${org.name}`;
  }

  /**
   * imports orgs into hierarchy
   */
  static import(allOrgs: ImportOrganizationsDM[]): ChangeCount {
    // NOTE (IMPORTANT): org hierarchy is already loaded when the user sees the import options as per
    // the current UI flow. In the future, if there is a design change that only orgs till certain levels are
    // loaded first, then explicit calls to fetch the concerned orgs during import will have to be made.
    return {
      createCount: ImportOrganizations.handleOrgsToBeCreated(allOrgs),
      updateCount: ImportOrganizations.handleOrgsToBeUpdated(allOrgs),
      deleteCount: ImportOrganizations.handleOrgsToBeDeleted(allOrgs),
    };
  }
}

export default ImportOrganizations;
export { messages };
