/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reprofileion of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import * as _ from 'lodash';

import { Item, Provider, defaultTheme } from '@adobe/react-spectrum';
import React, { Key, useCallback } from 'react';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import ComboBoxSelector from './ComboBoxSelector';
import ContractJIL from '../../services/orgMaster/ContractsJIL';

export interface ContractSelectorProps {
  contracts: ContractJIL[]; // available contracts to choose from
  clearSelection: boolean; // if true, ContractSelector does not show selectd items
  label: string; // label string to display for the Combobox
  width?: string; // how wide the Combobox should be
  disabled: boolean; // controls disabling the Combobox
  isLoading: boolean; // controls whether the wait component should display in the Combobox
  onSelect: (contractId: string) => void; // callback when a contract is selected (passes the contract id of the selected contract)
}

/**
 * Combobox component specific for selecting contracts.
 * Handles displaying contracts with their names.
 */
function ContractSelector(props: ContractSelectorProps): React.ReactElement {
  const { clearSelection, width, label, disabled, isLoading, contracts, onSelect, ...otherProps } = props;

  const onContractKeySelect = useCallback((key: Key) => onSelect(key as string), []);

  return (
    <Provider theme={defaultTheme} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
      <ComboBoxSelector
        clearSelection={clearSelection}
        width={width}
        label={label}
        isDisabled={_.isEmpty(contracts) || disabled || isLoading}
        loadingState={isLoading ? 'loading' : undefined}
        defaultItems={contracts}
        onSelect={onContractKeySelect}
        {...otherProps}
      >
        {(contract: ContractJIL) => <Item key={contract.id}>{contract.displayName}</Item>}
      </ComboBoxSelector>
    </Provider>
  );
}

export default ContractSelector;
