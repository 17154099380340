import React from 'react';
import _ from 'lodash';
import Alert from '@react/react-spectrum/Alert';
import Dialog from '@react/react-spectrum/Dialog';
import Textfield from '@react/react-spectrum/Textfield';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';
import Provider from '@react/react-spectrum/Provider';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import Wait from '@react/react-spectrum/Wait';
import '../UserGroupDialogContent.css';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UUserGroup, UUserGroupLicenseGroup } from '../../../../services/orgMaster/UUserGroup';
import { UAdmin } from '../../../../services/orgMaster/UAdmin';
import { LocaleSettings } from '../../../../services/locale/LocaleSettings';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';

export interface ViewUserGroupDialogContentProps extends WrappedComponentProps {
  userGroup: UUserGroup;
  selectedOrg: UOrgMaster;
}

interface ViewUserGroupDialogContentState {
  userGroup: UUserGroup;
  adminsForUserGroup: UAdmin[];
  isLoading: boolean;
  errMsg: string | undefined;
}

const messages = defineMessages({
  Close: {
    id: 'EditCompartment.UserGroups.ViewUG.Close',
    defaultMessage: 'Close',
  },
  Error: {
    id: 'EditCompartment.UserGroups.ViewUG.Error',
    defaultMessage: 'Error',
  },
});

/**
 * This component is rendered when user with only Global RO permission clicks on the User group name from the UserGroupTable rows
 * to view the details of the selected user group. Note: We do not want this user to edit the usergroup, so we make everything
 * in this component read only
 */
class ViewUserGroupDialogContent extends React.Component<
  ViewUserGroupDialogContentProps,
  ViewUserGroupDialogContentState
> {
  constructor(props: ViewUserGroupDialogContentProps) {
    super(props);
    const updatedUserGroup: UUserGroup | undefined = _.find(
      this.props.selectedOrg.userGroups,
      (userGroup: UUserGroup): boolean => userGroup.id === this.props.userGroup.id
    );
    this.state = {
      userGroup: _.cloneDeep(updatedUserGroup) || _.cloneDeep(this.props.userGroup),
      adminsForUserGroup: this.props.selectedOrg.getAdminsForUserGroup(this.props.userGroup.id),

      isLoading: false,
      errMsg: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    if (!this.props.userGroup.adminsLoaded || !this.props.userGroup.profilesLoaded) {
      this.setState({ isLoading: true });
      let adminsForUserGroup: UAdmin[] = [];
      try {
        await LoadOrgDataService.loadAdminForUserGroupV2(this.props.userGroup.orgId, this.props.userGroup.id);
        adminsForUserGroup = this.props.selectedOrg.getAdminsForUserGroup(this.props.userGroup.id);
        await LoadOrgDataService.loadProfilesForUserGroup(this.props.selectedOrg.id, this.props.userGroup.id);
      } catch (error) {
        this.setState({
          isLoading: false,
          errMsg: error.message,
        });
      }
      const updatedUserGroup: UUserGroup | undefined = _.find(
        this.props.selectedOrg.userGroups,
        (userGroup: UUserGroup): boolean => userGroup.id === this.props.userGroup.id
      );
      this.setState({
        isLoading: false,
        userGroup: _.cloneDeep(updatedUserGroup) || _.cloneDeep(this.props.userGroup),
        adminsForUserGroup,
      });
    }
  }

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { userGroup } = this.state;
    return (
      <Dialog
        className="UserGroupDialogContent__container"
        title={this.props.userGroup.name}
        confirmLabel={formatMessage(messages.Close)}
        {...this.props}
        role="dialog"
      >
        {this.state.errMsg && (
          <Alert header={formatMessage(messages.Error)} variant="error">
            {this.state.errMsg}
          </Alert>
        )}
        {this.state.isLoading ? (
          <Wait className="Load_wait" />
        ) : (
          <>
            <FieldLabel position="left" label="Name" id="view-ug-name">
              <Textfield value={this.props.userGroup.name} readOnly aria-labelledby="view-ug-name" />
            </FieldLabel>
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage
                  id="EditCompartment.UserGroups.ViewUserGroup.ProductProfiles"
                  defaultMessage="Product Profiles"
                />
              </Heading>
              <Rule variant="medium" />
              {userGroup.profiles.map(
                (profile: UUserGroupLicenseGroup): React.ReactNode => (
                  <Provider key={profile.id} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
                    <TagList readOnly>
                      <Tag>{profile.name}</Tag>
                    </TagList>
                  </Provider>
                )
              )}
              {userGroup.profiles.length === 0 && (
                <div className="UserGroupDialogNoContent">
                  <FormattedMessage
                    id="EditCompartment.UserGroups.ViewUserGroup.NoProfiles"
                    defaultMessage="No profiles"
                  />
                </div>
              )}
            </section>
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage id="EditCompartment.UserGroups.ViewUserGroup.Admins" defaultMessage="Admins" />
              </Heading>
              <Rule variant="medium" />
              {this.state.adminsForUserGroup.map(
                (admin: UAdmin): React.ReactNode => (
                  <Provider key={admin.email} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
                    <TagList readOnly>
                      <Tag>{admin.email}</Tag>
                    </TagList>
                  </Provider>
                )
              )}
              {this.state.adminsForUserGroup.length === 0 && (
                <div className="UserGroupDialogNoContent">
                  <FormattedMessage id="EditCompartment.UserGroups.ViewUserGroup.NoAdmins" defaultMessage="No admins" />
                </div>
              )}
            </section>
          </>
        )}
      </Dialog>
    );
  }
}
export default injectIntl(ViewUserGroupDialogContent);
