/**
 * ProductAttributes holds some attribute values for a given product.
 */
export interface ProductAttributesData {
  featureRestrictedLicense?: boolean; // determines if the product supports feature restricted licensing
  sharedDeviceLicense?: boolean;
  stockProduct?: boolean; // determines if the product is Adobe Stock
  singleApp?: boolean; // determines if the product is Any Single App
  overUsePolicyConfigurable?: boolean; // controls if the product policy allowExceedUsage is configurable by customer
  allowsAddingAdminsAndProfiles?: boolean; // determines whether product allows creating profiles and adding product or profile admins
  ccStorageCap?: string; // ChargingModel.cap of FI cc_storage if present. May be null.
}

export class ProductAttributes {
  featureRestrictedLicense: boolean = false;
  sharedDeviceLicense: boolean = false;
  stockProduct: boolean = false;
  singleApp: boolean = false;
  overUsePolicyConfigurable: boolean = false;
  allowsAddingAdminsAndProfiles: boolean = false;
  ccStorageCap: string = '';

  constructor(productAttributes?: ProductAttributesData) {
    if (productAttributes) {
      this.featureRestrictedLicense = productAttributes.featureRestrictedLicense || this.featureRestrictedLicense;
      this.sharedDeviceLicense = productAttributes.sharedDeviceLicense || this.sharedDeviceLicense;
      this.stockProduct = productAttributes.stockProduct || this.stockProduct;
      this.singleApp = productAttributes.singleApp || this.singleApp;
      this.overUsePolicyConfigurable = productAttributes.overUsePolicyConfigurable || this.overUsePolicyConfigurable;
      this.allowsAddingAdminsAndProfiles =
        productAttributes.allowsAddingAdminsAndProfiles || this.allowsAddingAdminsAndProfiles;
      this.ccStorageCap = productAttributes.ccStorageCap || this.ccStorageCap;
    }
  }
}
