import React from 'react';
import { IntlProvider, WrappedComponentProps, injectIntl, defineMessages } from 'react-intl';
import Dialog from '@react/react-spectrum/Dialog';
import Well from '@react/react-spectrum/Well';
import Wait from '@react/react-spectrum/Wait';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { OrganizationTemplateData } from '../../../../models/OrganizationTemplate';
import BanyanCompartmentAPI from '../../../../providers/BanyanCompartmentAPI';
import Analytics from '../../../../Analytics/Analytics';
import TemplatePolicyList from '../TemplatePolicyList/TemplatePolicyList';
import TemplatePolicyMap from '../TemplatePolicyMap';
import { LocaleSettings } from '../../../../services/locale/LocaleSettings';
import EditTemplate from '../EditTemplate/EditTemplate';
import { MESSAGES } from '../../../Messages';
import AdminPermission from '../../../../services/authentication/AdminPermission';
import './ViewTemplate.css';
import AlertBanner from '../../../../components/AlertBanner/AlertBanner';

interface ViewTemplateProps extends WrappedComponentProps {
  orgId: string;
  templateId: string;
  templateName: string;
  updateTemplate: (templateId: string, templateBody: OrganizationTemplateData) => Promise<string | undefined>;
}

interface ViewTemplateState {
  isLoading: boolean;
  template: OrganizationTemplateData | null;
  errorMsg?: string;
}

interface TemplatePolicyListIntlProps extends ViewTemplateProps {
  templatePolicyMap: TemplatePolicyMap;
  isEditable: boolean;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function TemplatePolicyListIntl(props: Omit<TemplatePolicyListIntlProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <TemplatePolicyList {...props} />
    </IntlProvider>
  );
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function EditTemplateIntl(props: Omit<ViewTemplateProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditTemplate {...props} />
    </IntlProvider>
  );
}

const messages = defineMessages({
  Title: {
    id: 'EditCompartment.Templates.View.Title',
    defaultMessage: 'View the policy template and you can edit the template when needed.',
  },
  Cancel: {
    id: 'EditCompartment.Templates.View.Cancel',
    defaultMessage: 'Cancel',
  },
  EditTemplate: {
    id: 'EditCompartment.Templates.View.EditTemplate',
    defaultMessage: 'Edit template',
  },
});

class ViewTemplate extends React.Component<ViewTemplateProps, ViewTemplateState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted

  constructor(props: ViewTemplateProps) {
    super(props);
    Analytics.fireCTAEvent(`View template dialog opened.`);
    this.state = {
      isLoading: false,
      template: null,
      errorMsg: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.loadTemplate();
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  private loadTemplate = async (): Promise<void> => {
    const { orgId, templateId } = this.props;
    const { formatMessage } = this.props.intl;
    if (!templateId) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const responseData: OrganizationTemplateData = await BanyanCompartmentAPI.getPolicyTemplate(orgId, templateId);
      if (responseData) {
        this.setState({ template: responseData });
      } else {
        this.setState({ template: null });
      }
    } catch (error) {
      this.setState({ template: null, errorMsg: `${formatMessage(MESSAGES.TemplateGETApiError)} : ${error.message}` });
    }
    this.setState({ isLoading: false });
  };

  private getTemplatePolicyMap = (): TemplatePolicyMap => {
    const templatePolicyMap: TemplatePolicyMap = {};
    if (this.state.template && this.state.template.policies) {
      this.state.template.policies.forEach((policy) => {
        templatePolicyMap[policy.name] = policy;
      });
    }
    return templatePolicyMap;
  };

  private navigateToEditTemplate = (): void => {
    ModalContainer.show(<EditTemplateIntl {...this.props} />, this);
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { isLoading, errorMsg } = this.state;
    return (
      <Dialog
        {...this.props} // required as onClose() is provided by ModalTrigger
        title={this.props.templateName}
        confirmLabel={formatMessage(messages.EditTemplate)}
        cancelLabel={formatMessage(messages.Cancel)}
        role="dialog"
        onConfirm={this.navigateToEditTemplate}
        confirmDisabled={AdminPermission.readOnlyMode() || isLoading || errorMsg !== undefined}
      >
        <div className="ViewTemplate__headerMessage">{formatMessage(messages.Title)}</div>
        {errorMsg && <AlertBanner variant="error">{errorMsg}</AlertBanner>}
        <Well className="ViewTemplate__policyWell">
          {!isLoading ? (
            <TemplatePolicyListIntl
              templatePolicyMap={this.getTemplatePolicyMap()}
              isEditable={false}
              {...this.props}
            />
          ) : (
            <Wait className="Load_wait" />
          )}
        </Well>
      </Dialog>
    );
  }
}

export default injectIntl(ViewTemplate);
