import config from '../configurations/config';
import { AuthInterface, AuthProvider } from '@pandora/react-auth-provider';
import { envNames, EnvProvider } from '@pandora/react-env-provider';
import AuthenticatedUser from '../services/authentication/AuthenticatedUser';
import { FetchProvider } from '@pandora/react-fetch-provider';
import UserProfileService from '../services/authentication/UserProfileService';

/*
  A wrapper of providers to set up Pandora api infrastructure with our auth and environment values
  refer:
   - https://git.corp.adobe.com/PandoraUI/core/tree/master/packages/react-auth-provider
   - https://git.corp.adobe.com/PandoraUI/core/tree/master/packages/react-env-provider
   - https://git.corp.adobe.com/PandoraUI/core/tree/master/packages/react-fetch-provider
   - https://git.corp.adobe.com/admin-tribe/binky-ui/blob/master/src2/core/services/ApiProvider.jsx
 */
export const PandoraApiProvider = ({ children }: { children: React.ReactNode }) => {
  const populateAuthDataforPandora = (): AuthInterface => {
    const token = AuthenticatedUser.getAccessToken();
    return {
      getAccessToken: () => (token ? token : ''),
      // since it is the same user profile found in IMS, I am converting it to Pandora's User Profile type definition from our type definition
      getUserProfile: () => UserProfileService.getUserProfilePandora(),
    };
  };

  const customFetch = (path: RequestInfo, options: RequestInit | undefined) => {
    const mergedOptions = {
      ...options,
      headers: {
        'X-Api-Key': config.reportBroker.apiKey,
        ...options?.headers,
      },
    };
    return fetch(path, mergedOptions);
  };

  return (
    <EnvProvider value={envNames[config.pandora.environment]}>
      <AuthProvider value={populateAuthDataforPandora()}>
        <FetchProvider value={customFetch}>{children}</FetchProvider>
      </AuthProvider>
    </EnvProvider>
  );
};
