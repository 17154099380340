import React from 'react';
import * as _ from 'lodash';
import { SelectOption } from '@react/react-spectrum/Select';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import Provider from '@react/react-spectrum/Provider';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import ProfileSelector from '../../../components/selectors/ProfileSelector';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UProductProfile } from '../../../services/orgMaster/UProductProfile';
import { UProduct } from '../../../services/orgMaster/UProduct';
import ProductContractData from '../../../services/orgMaster/ProductContractData';
import { UAdmin, AdminProfileInfo } from '../../../services/orgMaster/UAdmin';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import './Common.css';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import Utils from '../../../services/utils/Utils';

interface ProfileSectionProps extends WrappedComponentProps {
  compartment: UOrgMaster;
  disabled: boolean; // disables component including the button that enables and disables editing
  // Executed when profile is added or removed for profile admin.
  // (passes list of info for profiles currently associated with profile admin and indicates the profile and whether it was added or removed).
  updateProfileInfoCallback: (
    profileInfoList: AdminProfileInfo[],
    profileId: string,
    profileName: string | undefined,
    addRole: boolean
  ) => void;
  // List of profiles (indicated by info) associated with profile admin.
  profileInfoList: AdminProfileInfo[];
}

interface ProfileSectionState {
  productsContractsData: ProductContractData[]; // products with contract data for current org
  selectedProduct: string | undefined;
  // List of profiles (indicated by info) currently associated with profile admin.
  profileInfoList: AdminProfileInfo[];
  enabledForEdit: boolean; // determines whether this component is enabled for edit based on user enabling through button
  productsLoading: boolean; // determines whether component should indicate products are loading
  profilesLoading: boolean; // determines whether component should indicate profiles are loading for product
}

const messages = defineMessages({
  NoPdt: {
    id: 'EditCompartment.Admins.PdtSelForProfile.placeholder.NoProduct',
    defaultMessage: 'No product',
  },
  SelectPdt: {
    id: 'EditCompartment.Admins.PdtSelForProfile.placeholder.SelectProduct',
    defaultMessage: 'Select Product',
  },
  NoProf: {
    id: 'EditCompartment.Admins.PdtSelForProfile.placeholder.NoProfile',
    defaultMessage: 'No profiles',
  },
  SelectProf: {
    id: 'EditCompartment.Admins.PdtSelForProfile.placeholder.SelectProfile',
    defaultMessage: 'Select Profile',
  },
});

class ProfileSection extends React.Component<ProfileSectionProps, ProfileSectionState> {
  private abortController = new AbortController();

  constructor(props: ProfileSectionProps) {
    super(props);
    this.state = {
      productsContractsData: ProductContractData.createMultipleFromProductsAndContracts(
        this.props.compartment.products,
        this.props.compartment.contracts
      ),
      selectedProduct: undefined,
      profileInfoList: this.props.profileInfoList,
      enabledForEdit: this.props.compartment.productsLoaded,
      productsLoading: false,
      profilesLoading: false,
    };
  }

  async componentDidMount(): Promise<void> {
    if (!this.props.compartment.productsLoaded && !this.state.productsLoading && !this.abortController.signal.aborted) {
      try {
        this.setState({ productsLoading: true });
        await LoadOrgDataService.loadProducts(this.props.compartment.organization.id, this.abortController.signal);
        if (!this.abortController.signal.aborted) {
          this.setState({
            enabledForEdit: true,
            productsLoading: false,
            productsContractsData: ProductContractData.createMultipleFromProductsAndContracts(
              this.props.compartment.products,
              this.props.compartment.contracts
            ),
          });
        }
      } catch (error) {
        if (Utils.isAbortError(error)) {
          return;
        }
        throw error;
      }
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  // update admin profile list (TODO: rename this to 'update')
  private updateV2 = (
    profileInfoList: AdminProfileInfo[],
    profileId: string | undefined,
    profileName: string | undefined,
    addRole: boolean
  ): void => {
    if (profileId) {
      this.props.updateProfileInfoCallback(profileInfoList, profileId, profileName, addRole);
    }
    this.setState({ profileInfoList });
  };

  // get list of products available for the compartment
  private getProductSelectList = (): SelectOption[] => {
    const filteredProducts: UProduct[] = _.filter(this.props.compartment.products, (product: UProduct) =>
      product.allowedToAddAdminsAndProfiles()
    );
    return _.map(filteredProducts, (product: UProduct): SelectOption => ({ label: product.name, value: product.id }));
  };

  // get available list of profiles
  // available list of profiles is equal to the difference of list of profiles for the given product and the list of profiles already associated with the admin
  private getProfileSelectList = (productId: string, profileIdToNameMap: Map<string, string>): SelectOption[] => {
    const foundProduct = _.find(this.props.compartment.products, ['id', productId]);
    if (foundProduct) {
      const productProfileIds = _.map(foundProduct.productProfiles, (profile: UProductProfile): string => profile.id);
      const availableProfileIds: string[] = _.difference(
        productProfileIds,
        UAdmin.adminInfosToIds(this.state.profileInfoList)
      );
      return _.map(
        availableProfileIds,
        (profileId: string): SelectOption => ({ label: profileIdToNameMap.get(profileId), value: profileId })
      );
    }
    return [];
  };

  //  update state if the product selection changes
  private onProductForProfileSelection = async (productSelectionId: string): Promise<void> => {
    // loads profiles for product when product is selected
    this.setState({ profilesLoading: true });
    await LoadOrgDataService.loadProfiles(this.props.compartment.organization.id, productSelectionId);
    this.setState({ selectedProduct: productSelectionId, profilesLoading: false });
  };

  // update profile list on selection (TODO: rename to 'onProfileSelection')
  private onProfileSelectionV2 = (profileSelectionId: string): void => {
    const profileName: string | undefined = this.props.compartment.lookupProfileName(
      this.state.selectedProduct,
      profileSelectionId
    );
    const productId: string = this.state.selectedProduct as string; // is defined because product must be selected for profile to be selected
    const { profileInfoList } = this.state;
    if (
      !_.find(
        profileInfoList,
        (profileInfo: AdminProfileInfo): boolean =>
          profileInfo.id === profileSelectionId && profileInfo.productId === productId
      )
    ) {
      profileInfoList.push({ id: profileSelectionId, name: profileName, productId });
    }
    this.updateV2(profileInfoList, profileSelectionId, profileName, true);
  };

  // convert profile list to tags (TODO: rename to 'getProductProfilesTagList')
  private getProductProfilesTagListV2 = (): React.ReactNode => {
    const tags: React.ReactNode[] = _.reduce(
      this.state.profileInfoList,
      (tagList: React.ReactNode[], info: AdminProfileInfo): React.ReactNode[] => {
        if (!_.isEmpty(info.id) && !_.isEmpty(info.name)) {
          tagList.push(<Tag key={info.id}>{info.name}</Tag>);
        }
        return tagList;
      },
      []
    );
    return (
      <Provider locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
        <TagList
          data-testid="profile-admin-taglist"
          className="AdminRightTagList"
          disabled={this.props.disabled || !this.state.enabledForEdit}
          onClose={async (profileName: string | React.ReactNode): Promise<void> => {
            await this.removeProductProfileByNameV2(profileName as string);
          }}
        >
          {tags}
        </TagList>
      </Provider>
    );
  };

  // remove the product profile when user clicks 'X' on tag (TODO: rename to 'removeProductProfileByName')
  private removeProductProfileByNameV2 = async (profileName: string): Promise<void> => {
    const { profileInfoList } = this.state;
    const infoIndex: number = _.findIndex(profileInfoList, (i: AdminProfileInfo): boolean => i.name === profileName);
    let profileId: string | undefined;
    if (infoIndex >= 0) {
      const profileInfo: AdminProfileInfo = profileInfoList[infoIndex];
      profileId = profileInfo.id;

      // load profiles if not loaded yet so that profile can be properly removed from admin
      if (profileInfo.productId && profileId) {
        this.setState({ profilesLoading: true });
        await LoadOrgDataService.loadProfiles(this.props.compartment.organization.id, profileInfo.productId);
        this.setState({ profilesLoading: false });
      }

      profileInfoList.splice(infoIndex, 1); // remove profile info at its index location
    }
    this.updateV2(profileInfoList, profileId, undefined, false);
  };

  public render(): React.ReactNode {
    // all profiles in a compartment
    const { formatMessage } = this.props.intl;
    // retrieve ProductsContractsData associated with products that are administerable
    const availableProductsContractsData = _.filter(
      this.state.productsContractsData,
      (productContractData: ProductContractData): boolean => productContractData.product.allowedToAddAdminsAndProfiles()
    );
    // retrieve profiles that are not already assigned to the admin
    const availableProfiles = _.filter(
      this.props.compartment.getProfilesForProduct(this.state.selectedProduct),
      (profile: UProductProfile): boolean =>
        !_.find(this.state.profileInfoList, (profInfo: AdminProfileInfo): boolean => profInfo.id === profile.id)
    );
    return (
      <div>
        <div>
          <ProfileSelector
            spacing="size-500"
            productSelectorProps={{
              width: 'size-3000',
              label: formatMessage(messages.SelectPdt),
              disabled: this.props.disabled || !this.state.enabledForEdit,
              isLoading: this.state.productsLoading,
              displayContractNames: this.props.compartment.shouldDisplayContractNames(),
              productsContractsData: availableProductsContractsData,
              onSelect: this.onProductForProfileSelection,
              dataTestId: 'admin-product-for-profile-selection-combobox',
            }}
            profileSelectorProps={{
              width: 'size-3000',
              label: formatMessage(messages.SelectProf),
              disabled: !this.state.selectedProduct || this.props.disabled || !this.state.enabledForEdit,
              isLoading: this.state.profilesLoading,
              profiles: availableProfiles,
              onSelect: this.onProfileSelectionV2,
              dataTestId: 'admin-profile-selection-combobox',
            }}
          />
          <div>{this.getProductProfilesTagListV2()}</div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ProfileSection);
