import * as _ from 'lodash';
import { UOrgMaster } from '../orgMaster/UOrgMaster';
import { UOrg } from '../orgMaster/UOrg';
import OrgSelectionUtil from '../treeTableUtils/OrgSelectionUtil';

/**
 * This class stores a map & a set of organizations in memory
 * The org data is most up to date with edits
 * The map is structured as follows: org id -> UOrgMaster
 * whereas the set stores only the UOrg structure.
 *
 * This class stores the data model.
 * OrgMasterTree fetches the data from backend and stores in this class.
 * The UI components / services interact with this class to display the data.
 */
export default class HierarchyManager {
  private static orgMap = new Map<string, UOrgMaster>();
  private static orgSet = new Set<UOrg>(); // set of all the organizations in the hierarchy. Maintains insertion order. So root org is the first element of the set.

  /**
   * returns the org from the map if present else returns undefined
   * @param orgId
   */
  public static getOrg(orgId: string): UOrgMaster | undefined {
    return HierarchyManager.orgMap.has(orgId) ? HierarchyManager.orgMap.get(orgId) : undefined;
  }

  /**
   * Add parent child relation for the org.
   * @param childOrg
   * @param parentOrg
   */
  public static setParentChildRelation(childOrg: UOrgMaster, parentOrg: UOrgMaster | undefined): void {
    if (parentOrg !== undefined) {
      childOrg.setParentOrgRef(parentOrg);
      parentOrg.addChildOrgRef(childOrg);
    }
  }

  /**
   * update the parent and child pointers when the org is reparented
   * @param newParent
   * @param org
   */
  public static reparentOrg(newParent: UOrgMaster | undefined, org: UOrgMaster) {
    const currentParent = org.getParentOrgMaster();
    if (currentParent) {
      currentParent.removeChildOrgRef(org.id);
    }
    if (newParent) {
      newParent.addChildOrgRef(org);
      org.setParentOrgRef(newParent);
    } else {
      org.setParentOrgRef(undefined);
    }
  }

  /**
   * Add the org to map and set maintained by this class.
   * Also set its parent and child relation.
   * @param org
   */
  public static addOrg(org: UOrgMaster): UOrgMaster {
    HierarchyManager.orgMap.set(org.organization.id, org);
    HierarchyManager.orgSet.add(org.organization);

    // add parent-child reference
    const parent: UOrgMaster | undefined = HierarchyManager.getOrg(org.organization.parentOrgId);
    HierarchyManager.setParentChildRelation(org, parent);

    return org;
  }

  /**
   * remove an org from the hierarchy.
   * This is called when edits are discarded via Job Execution page and new orgs need to be removed from the hierarchy
   * @param org org to be removed from the hierarchy
   */
  public static removeOrgCompletely(org: UOrgMaster): void {
    const parentOrg = org.getParentOrgMaster();
    if (parentOrg) {
      parentOrg.removeChildOrgRef(org.id); // remove the reference of new org from the parent
    }

    // delete from the map and set
    HierarchyManager.orgSet.delete(org.organization);
    HierarchyManager.orgMap.delete(org.id);

    // remove all the children of the org recursively
    const childrenList = org.getChildren();
    // copy (not deep copy) the childrenList so that it does not shrink as orgs are removed
    _.forEach([...childrenList], (childOrg) => {
      HierarchyManager.removeOrgCompletely(childOrg);
    });
  }

  /**
   * get list of all orgs in the hierarchy (including the new orgs)
   * TODO: Investigate the uses of this method. Try not to use this method. Iterating over the entire org list results in poor performance.
   * TODO: Investigate if passing in function would help.
   * TODO: Investigate amount of memory allocated/ deallocated as part of Array.from call
   *
   */
  public static getOrgs(): UOrg[] {
    return Array.from(HierarchyManager.orgSet) as UOrg[];
  }

  public static getOrgSet(): Set<UOrg> {
    return HierarchyManager.orgSet;
  }

  /**
   * get list of all UOrgMaster in the hierarchy
   */
  public static getOrgMasters(): UOrgMaster[] {
    const uOrgs = Array.from(HierarchyManager.orgSet) as UOrg[];
    return _.map(uOrgs, (uOrg) => HierarchyManager.getOrg(uOrg.id) as UOrgMaster);
  }

  /**
   * throws error if org is not found
   * Ideally the error should never be thrown as the selected org would always be set
   * (selected org is initialized on app load and on every org switch - both from org tree change and org picker change)
   */
  public static getSelectedOrg(): UOrgMaster {
    const selectedOrgId = OrgSelectionUtil.getSelectedOrgId();
    return HierarchyManager.getOrg(selectedOrgId) as UOrgMaster;
  }

  /**
   * finds if the last selected org can be found and sets it as selected org.
   * If not found (could have been deleted or could be a newly added org) it sets the root org as selected org
   */
  public static setSelectedOrg(): void {
    const selectedOrgId: string = OrgSelectionUtil.getSelectedOrgId();
    const orgList = HierarchyManager.getOrgs();
    // If the selected org is not present in the list of edited orgs(example when the org is deleted), then set rootOrgId as last selected.
    if (this.getOrg(selectedOrgId) === undefined && orgList.length > 0) {
      OrgSelectionUtil.updateOrgSelection(orgList[0].id);
    }
  }

  /**
   * clear the org hierarchy.
   * reset the map and set.
   */
  public static clear(): void {
    HierarchyManager.orgMap.clear();
    HierarchyManager.orgSet = new Set();
  }
}
