import * as _ from 'lodash';
import { ImportError, OrgOperation, ErrorData, CAP_UNLIMITED } from './OrgMaster';

/* eslint-disable import/no-cycle */
import { UOrgMaster } from './UOrgMaster';
import { UBasic, UBasicData } from './UBasic';
import { UProduct } from './UProduct';
/* eslint-enable import/no-cycle */

import { UResourceData, UResource } from './UResource';
import TempIdGenerator from '../utils/TempIdGenerator';

/**
 * UProductProfile represents a product profile org data object.
 */

/**
 * Mutable object for UProductProfile.
 * Provides object containing only properties for UProductProfile.
 * Represents UProductPrfile object that can be retrieved from back-end.
 */
export interface UProductProfileData extends UBasicData {
  orgId?: string; // id of the org this product profile is associated with
  description?: string; // decription of the product profile
  name?: string; // name of the product profile
  notifications?: boolean;
  productId?: string; // id of the product this product profile is associated with
  resources?: UResourceData[]; // resources associated with the product profile.  License resource provides the cap and provisionedQuantity
  adminCount?: number; // count of admins for this profile
  adminsLoaded?: boolean; // true if admins loaded and false otherwise
  userGroupLoaded?: boolean; // true if user group loaded and false otherwise
}

/**
 * UProductProfile object that also contains the methods and functionality.
 */
export class UProductProfile extends UBasic implements UProductProfileData {
  orgId: string = '';
  description: string = '';
  name: string = '';
  notifications: boolean = false;
  productId: string = '';
  resources: UResource[] = [];
  adminCount: number = 0;
  adminsLoaded: boolean = false;
  userGroupLoaded: boolean = false;
  private static readonly SINGLE_DESKTOP_APPLICATION_CONFIGURATION: string = 'single_desktop_application_configuration';

  /**
   * Construct a UProductProfile from either UProductProfileData or creates a copy of another UProductProfile object.
   */
  constructor(profile?: UProductProfileData, newProductProfile = false) {
    super(profile);
    if (profile) {
      this.orgId = profile.orgId || this.orgId;
      this.description = profile.description || this.description;
      this.name = profile.name || this.name;
      this.notifications = profile.notifications || this.notifications;
      this.productId = profile.productId || this.productId;
      this.resources =
        _.map(profile.resources, (resource: UResourceData): UResource => new UResource(_.cloneDeep(resource))) ||
        this.resources;
      this.adminCount = profile.adminCount || this.adminCount;
      this.adminsLoaded = profile.adminsLoaded || this.adminsLoaded;
      this.userGroupLoaded = profile.userGroupLoaded || this.userGroupLoaded;
    }
    if (newProductProfile) {
      this.adminsLoaded = true;
      this.userGroupLoaded = true;
    }
  }

  /**
   * Retrieves the provisionedQuantity from the seat (license) resource (there's only 1 seat resource per product profile).
   * A null value is returned if there is no provisionedQuantity because the seat/license resource couldn't be found.
   */
  get provisionedQuantity(): number | null {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    return !resource ? null : resource.provisionedQuantity;
  }

  /**
   * JSON representation of UProductProfile.
   */
  toJSON(): object {
    return _.assign(super.toJSON(), {
      orgId: this.orgId,
      description: this.description,
      name: this.name,
      notifications: this.notifications,
      productId: this.productId,
      resources: this.resources,
    });
  }

  /**
   * Retrieves the product profile cap from the seat (license) resource (there's only 1 seat resource per product profile).
   */
  get cap(): string {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    return !resource || !resource.validCode() ? '0' : resource.cap;
  }

  /**
   * Sets the product profile cap on the seat (license) resource (there's only 1 seat resource per product profile).
   */
  set cap(newQuantity: string) {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    if (resource) {
      resource.cap = newQuantity;
    }
  }

  /**
   * Retrieves non seat (license) product profile resources.
   */
  getEditableResources(): UResource[] {
    return _.filter(
      this.resources,
      (res: UResource): boolean => !UResource.isSeatResource(res.code) && res.delegationConfigurable
    );
  }

  /**
   * Returns a list of the desktop type profile resources
   */
  getDesktopResources(): UResource[] {
    return _.filter(this.resources, (res: UResource): boolean => res.fulfillableItemType === 'DESKTOP');
  }

  /**
   * returns true if the this profile and other profile have same editable values
   * @param otherProfile
   */
  isEqual(otherProfile: UProductProfile): boolean {
    return (
      this.name === otherProfile.name &&
      _.isEqual(this.resources, otherProfile.resources) &&
      this.notifications === otherProfile.notifications
    );
  }

  /**
   * Method for adding a created product profile to an org or executing other operations on a product profile associated with an org.
   * Delete and Update operations will set a delete or update state for the given elem (if it exists on the given org).
   * Create operation will add the given product profile elem to the given org.
   *   - org: An org that the elem should be imported to or the elem should associated with to perform an operation.
   *   - elem: The product profile to perform the operation on.
   *   - operation: The operation to perform.
   * This method returns an error object or an array of errors, or a null value denoting success.
   */
  static importElement(org: UOrgMaster, elem: UBasicData, operation: OrgOperation): ErrorData | ErrorData[] | null {
    const profile: UProductProfile = elem as UProductProfile;
    const product: UProduct | undefined = _.find(org.products, {
      id: profile.productId,
    });
    if (!product) {
      return {
        errorMessage: `Product ${profile.productId} not found in org ${org.organization.name}`,
        code: ImportError.ELEMENT_NOT_FOUND,
      };
    }
    return UBasic.importElement(
      org,
      profile,
      operation,
      (e: UBasicData): UBasic | undefined => _.find(product.productProfiles, { id: e.id }),
      (e: UBasicData): ErrorData | ErrorData[] | null => {
        product.addProfile(new UProductProfile(e));
        return null;
      }
    );
  }

  /*
   * Get a default Profile Object
   */
  public static getNewProfileObject = (product: UProduct, orgId: string): UProductProfile => {
    const newProfileData: UProductProfileData = {};
    newProfileData.id = TempIdGenerator.getTempIdAndIncrement();
    newProfileData.orgId = orgId;
    newProfileData.name = '';
    newProfileData.notifications = true;
    newProfileData.productId = product.id;
    newProfileData.resources = [];
    newProfileData.userGroupLoaded = true;
    newProfileData.adminsLoaded = true;
    const newProfile = new UProductProfile(newProfileData, true);
    _.forEach(product.resources, (resource: UResource): void => {
      if (UResource.isSeatResource(resource.code) && resource.isUnlimited()) {
        newProfile.cap = CAP_UNLIMITED;
      }
    });
    if (newProfile.cap !== CAP_UNLIMITED) {
      // set to 1 if not UNLIMITED
      newProfile.cap = '0';
    }
    newProfile.toggleCreateItem();
    return newProfile;
  };
}
