import * as _ from 'lodash';
import React from 'react';
import Textfield from '@react/react-spectrum/Textfield';

import './EditNumberInput.css';

/**
 * NumberInput component using a text field.  Allows the user
 * to only enter numeric characters without mouse wheel scrolling
 * or arrow buttons
 */

interface EditNumberInputProps {
  className?: string;
  defaultValue?: number; // sets the value of the component
  min?: number; // minimum value possible for the component (value will not change any lower)
  max?: number; // maximum value possible for the component (value will not change any higher)
  invalid?: boolean; // if true, shows the field as invalid
  onChange?: (value: string) => void; // callback when the input is edited/changed
  onBlur?: React.FocusEventHandler; // callback when focus is lost on the component
  onFocus?: React.FocusEventHandler; // callback on focus on the component
  highlight?: boolean; // sets whether the input should show as highlighted
  placeholder?: string; // default string message to be shown
  labelledby?: string; // optional string to identify aria label value
  autoFocus?: boolean; // should the text field be auto focused when rendered
}

interface EditNumberInputState {
  value: string; // displayed value of the component
  currentDefaultValue: number | undefined; // default value set by parent.  Used to detect change from parent
  invalid?: boolean; // current state of whether the field is shown as invalid
}

// An instance of EditNumberInputProps for the purpose of filtering out extra props from EditNumberInputProps
// This map should have a key for each property in EditNumberInputProps
// As a convention the values should match the property names
const EditNumberInputPropsMap: { [P in keyof Required<EditNumberInputProps>]: keyof Required<EditNumberInputProps> } = {
  className: 'className',
  defaultValue: 'defaultValue',
  min: 'min',
  max: 'max',
  invalid: 'invalid',
  onChange: 'onChange',
  onBlur: 'onBlur',
  onFocus: 'onFocus',
  highlight: 'highlight',
  placeholder: 'placeholder',
  labelledby: 'labelledby',
  autoFocus: 'autoFocus',
};

class EditNumberInput extends React.Component<EditNumberInputProps, EditNumberInputState> {
  otherProps: any;
  constructor(props: EditNumberInputProps) {
    super(props);
    this.otherProps = _.omit(this.props, _.keys(EditNumberInputPropsMap));
    // displayed value is set to empty unless the given defaultValue is defined and not NaN
    this.state = this.createDefaultValueState();
  }

  /**
   * Executed when a render occurs.
   * This allows the EditNumberInput value to change if the parent re-renders
   */
  componentDidUpdate(): void {
    if (this.props.defaultValue !== this.state.currentDefaultValue || this.props.invalid !== this.state.invalid) {
      this.updateDefaultState();
    }
  }

  /**
   * Updates the displayed value as the user types it in
   */
  onChangeUpdate = (value: string): void => {
    if (!_.isNil(value)) {
      const intValue: number = _.parseInt(value, 10);
      // displayed value only changes if the value is numeric and is within the min/max range.  Empty string is always allowed.
      if (value === '' || (this.isNumeric(value) && this.minCheck(intValue) && this.maxCheck(intValue))) {
        this.setState({ value });
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      }
    }
  };

  /**
   * Generates the state object which sets the value and currentDefaultValue based off of the
   * defaultValue passed in from the parent.
   */
  private createDefaultValueState(): EditNumberInputState {
    return {
      value:
        typeof this.props.defaultValue === 'number' && !Number.isNaN(this.props.defaultValue)
          ? this.props.defaultValue.toString()
          : '',
      currentDefaultValue: this.props.defaultValue,
      invalid: this.props.invalid,
    };
  }

  /**
   * Renders this component with the state object based off the defaultValue passed from the parent.
   * This is in its own method so that setState is not called in the componentDidUpdate method.
   */
  private updateDefaultState(): void {
    this.setState(this.createDefaultValueState());
  }

  /**
   * Determines whether a string represents a number
   */
  private isNumeric(value: string): boolean {
    if (this.negativeAllowed()) {
      if (/^-?[0-9]*$/.test(value)) {
        return true;
      }
    } else if (/^[0-9]*$/.test(value)) {
      return true;
    }
    return false;
  }

  /**
   * Checks if a value is greater than or equal to the minimum.
   * If no minimum is given, then this always passes.
   */
  private minCheck(value: number): boolean {
    if (this.props.min === undefined || this.props.min <= value) {
      return true;
    }
    return false;
  }

  /**
   * Checks if a value is less than or equal to the maximum.
   * If no maximum is given, then this always passes.
   */
  private maxCheck(value: number): boolean {
    if (this.props.max === undefined || this.props.max >= value) {
      return true;
    }
    return false;
  }

  /**
   * Checks whether a negative value is allowed based on the minimum.
   * If no minimum is given, then negative is always allowed.
   */
  private negativeAllowed(): boolean {
    if (this.props.min !== undefined && this.props.min >= 0) {
      return false;
    }
    return true;
  }

  /**
   * Creates the className to apply to this component
   */
  private buildClassName(): string {
    let className = '';
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }
    if (this.props.highlight) {
      className = `${className} EditNumberInput__highlight`;
    }
    return className;
  }

  render(): React.ReactNode {
    return (
      <span {...this.otherProps}>
        <Textfield
          className={this.buildClassName()}
          value={this.state.value}
          validationState={this.state.invalid ? 'invalid' : undefined}
          onChange={this.onChangeUpdate}
          placeholder={this.props.placeholder}
          onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
            if (this.props.onBlur) {
              this.props.onBlur(event);
            }
          }}
          onFocus={(event: React.FocusEvent<HTMLInputElement>): void => {
            if (this.props.onFocus) {
              this.props.onFocus(event);
            }
          }}
          data-testid="editnumberinput-field"
          aria-labelledby={this.props.labelledby}
          autoFocus={this.props.autoFocus}
        />
      </span>
    );
  }
}
export default EditNumberInput;
