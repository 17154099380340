import * as _ from 'lodash';
import React from 'react';
import { AlertDialog, ActionButton, DialogContainer, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';

import Add from '@spectrum-icons/workflow/Add';
import Alert from '@spectrum-icons/workflow/Alert';
import Edit from '@spectrum-icons/workflow/Edit';
import DeleteOutline from '@spectrum-icons/workflow/DeleteOutline';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import Undo from '@spectrum-icons/workflow/Undo';
import Redo from '@spectrum-icons/workflow/Redo';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import AddChildOrgContent from './AddChildOrgContent/AddChildOrgContent';
import AdminPermission from '../../services/authentication/AdminPermission';
import EditOrganization from './EditOrganization/EditOrganization';
import DeleteOrganization from './DeleteOrganization/DeleteOrganization';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../services/orgMaster/UProduct';
import { ObjectTypes, OrgOperation } from '../../services/orgMaster/OrgMaster';
import OrgSelectionUtil from '../../services/treeTableUtils/OrgSelectionUtil';
import Analytics from '../../Analytics/Analytics';
import { CommandService } from '../../services/Commands/CommandService';
import GoToAdminConsoleDialog from '../../components/GoToAdminConsoleDialog/GoToAdminConsoleDialog';
import config from '../../configurations/config';
import HierarchyManager from '../../services/organization/HierarchyManager';
import OrgTreeCache from '../OrgTree/OrgTreeCache';
import CommandInterface from '../../services/Commands/CommandInterface';
import CmdDescriptionUtils from '../../services/Codes/CmdDescriptionUtils';

interface CompartmentActionButtonsProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
}

interface CompartmentActionButtonsState {
  showAddDialog: boolean;
  showDeleteDialog: boolean;
  showEditDialog: boolean;
  showUndoConfirmDialog: boolean;
}

const messages = defineMessages({
  Cancel: {
    id: 'Organizations.Undo.Cancel',
    defaultMessage: 'Cancel',
  },
  Proceed: {
    id: 'Organizations.Undo.Proceed',
    defaultMessage: 'Proceed',
  },
  RevertPending: {
    id: 'Organizations.Undo.RevertPending',
    defaultMessage: 'Revert pending organization changes',
  },
  Open: {
    id: 'EditCompartment.openInAdminConsoleTooltip',
    defaultMessage: 'Open in Admin Console',
  },
  Add: {
    id: 'EditCompartment.addChildOrgTooltip',
    defaultMessage: 'Add child organization',
  },
  Edit: {
    id: 'EditCompartment.editOrgTooltip',
    defaultMessage: 'Edit organization',
  },
  Delete: {
    id: 'EditCompartment.deleteTooltip',
    defaultMessage: 'Delete organization',
  },
  Undo: {
    id: 'EditCompartment.undo',
    defaultMessage: 'Discard changes in this org',
  },
  Redo: {
    id: 'EditCompartment.redo',
    defaultMessage: 'Reapply changes',
  },
});

class CompartmentActionButtons extends React.Component<CompartmentActionButtonsProps, CompartmentActionButtonsState> {
  constructor(props: CompartmentActionButtonsProps) {
    super(props);
    this.state = {
      showAddDialog: false,
      showDeleteDialog: false,
      showEditDialog: false,
      showUndoConfirmDialog: false,
    };
  }

  /**
   * Creates rows of text representing each child org to each product that will be removed from an
   * undo operation of the specified org.
   */
  private static createBrokenHierarchyText = (
    org: UOrgMaster,
    brokenProducts: UProduct[],
    depth = 0
  ): React.ReactNode[] => {
    const brokenProductsOnOrg: UProduct[] = _.filter(
      brokenProducts,
      (brokenProduct: UProduct): boolean =>
        !!_.find(
          org.products,
          (product: UProduct): boolean =>
            product.sourceProductId === brokenProduct.id || product.id === brokenProduct.id
        )
    );
    if (_.isEmpty(brokenProductsOnOrg)) {
      return [];
    }
    const brokenProductNamesOnOrg: string[] = _.map(brokenProductsOnOrg, 'name');
    let hierarchyText: React.ReactNode[] = [
      <p key={org.organization.id} style={{ marginLeft: `${depth}rem`, wordBreak: 'break-all' }}>{`${
        org.organization.name
      } - ${brokenProductNamesOnOrg.join(',')}`}</p>,
    ];
    _.forEach(org.getChildren(), (childOrg: UOrgMaster): void => {
      hierarchyText = hierarchyText.concat(
        CompartmentActionButtons.createBrokenHierarchyText(childOrg, brokenProductsOnOrg, depth + 1)
      );
    });
    return hierarchyText;
  };

  // /////////////////////////////// Add Product Undo
  /**
   * Remove multiple products from child orgs
   */
  removeProductsFromChild(childOrgs: UOrgMaster[], productIds: string[]): void {
    _.forEach(productIds, (productId: string): void => {
      this.removeProductFromChild(childOrgs, productId);
    });
  }

  /**
   * Remove product from child orgs
   */
  removeProductFromChild(childOrgs: UOrgMaster[], productId: string): void {
    _.forEach(childOrgs, (childOrg: UOrgMaster): void => {
      const product: UProduct | undefined = _.find(
        childOrg.products,
        (childProduct: UProduct): boolean => productId === childProduct.sourceProductId
      );
      if (product) {
        CommandService.addEdit(
          childOrg,
          product,
          ObjectTypes.PRODUCT,
          OrgOperation.DELETE,
          undefined,
          'REMOVE_PRODUCT',
          [product.name, CmdDescriptionUtils.getPathname(childOrg.id)]
        );
        this.removeProductFromChild(childOrg.getChildren(), product.id);
      }
    });
  }

  /**
   * Retrieves all products on an org that would cause a broken hierarchy if removed
   * (there exists children that have products allocated from product being removed)
   * An empty array means there is no broken hieararchy
   */
  private static checkBrokenProductHierarchy(org: UOrgMaster): UProduct[] {
    const brokenProducts: UProduct[] = [];
    const edits: CommandInterface[] | undefined = CommandService.getElementEdits(org.id, ObjectTypes.PRODUCT);
    if (!edits) {
      return [];
    }
    const createEdits: CommandInterface[] = CommandService.getEditsForOperation(edits, OrgOperation.CREATE);
    const removedProducts: UProduct[] = _.map(
      createEdits,
      (edit: CommandInterface): UProduct => new UProduct(edit.elem, true)
    );
    const orgChildren = org.getChildren();
    _.forEach(removedProducts, (removeProduct: UProduct): void => {
      _.forEach(orgChildren, (childOrg: UOrgMaster): void => {
        if (
          _.find(
            childOrg.products,
            (childProduct: UProduct): boolean => removeProduct.id === childProduct.sourceProductId
          )
        ) {
          brokenProducts.push(removeProduct);
        }
      });
    });
    return brokenProducts;
  }

  private undoChangesOrShowDialog = (): void => {
    Analytics.fireCTAEvent(`undo compartment clicked`);
    const brokenProducts: UProduct[] = CompartmentActionButtons.checkBrokenProductHierarchy(this.props.selectedOrg);
    if (!_.isEmpty(brokenProducts)) {
      this.setState({ showUndoConfirmDialog: true });
    } else {
      // no need to show dialog. Just undo changes.
      this.executeUndo();
    }
  };

  private undoChangesIncludingAllocations = (): void => {
    const brokenProducts: UProduct[] = CompartmentActionButtons.checkBrokenProductHierarchy(this.props.selectedOrg);
    if (!_.isEmpty(brokenProducts)) {
      this.removeProductsFromChild(this.props.selectedOrg.getChildren(), _.map(brokenProducts, 'id'));
    }
    this.executeUndo();
  };

  private getBrokenHierarchyText = (): React.ReactNode[] => {
    const brokenProducts: UProduct[] = CompartmentActionButtons.checkBrokenProductHierarchy(this.props.selectedOrg);
    return CompartmentActionButtons.createBrokenHierarchyText(this.props.selectedOrg, brokenProducts);
  };

  // TODO: localize
  private getAlertDialog = (): React.ReactElement => {
    const { formatMessage } = this.props.intl;
    return (
      <AlertDialog
        variant="destructive"
        title={formatMessage(messages.RevertPending)}
        primaryActionLabel={formatMessage(messages.Proceed)}
        cancelLabel={formatMessage(messages.Cancel)}
        onPrimaryAction={this.undoChangesIncludingAllocations}
      >
        <p>
          <Alert marginX=".3rem" size="S" />
          <b>Warning</b>: Organizations can only receive product allocations from their immediate parent. Therefore,
          this operation will:
        </p>
        <p style={{ wordBreak: 'break-all' }}>
          1. Revert all pending changes to {this.props.selectedOrg.organization.name}
        </p>
        <p>2. Revert the following pending product allocations:</p>
        <div style={{ paddingLeft: '2rem' }}>{this.getBrokenHierarchyText()}</div>
      </AlertDialog>
    );
  };

  private executeUndo = (): void => {
    const editsInOrg = CommandService.getAllEditsForTheOrg(OrgSelectionUtil.getSelectedOrgId());
    const org = HierarchyManager.getOrg(OrgSelectionUtil.getSelectedOrgId());
    if (org) {
      _.forEach(editsInOrg, (edit) => {
        CommandService.undoSingleEdit(org, edit);
      });
    }
    CommandService.setIsReverted(OrgSelectionUtil.getSelectedOrgId(), true);
    OrgTreeCache.clear(); // update org tree if there are reparent edits
    this.props.update();
  };

  private redoChanges = (): void => {
    Analytics.fireCTAEvent(`redo compartment clicked`);
    const editsInOrg = CommandService.getAllEditsForTheOrg(OrgSelectionUtil.getSelectedOrgId());
    const org = HierarchyManager.getOrg(OrgSelectionUtil.getSelectedOrgId());
    if (org) {
      _.forEach(editsInOrg, (edit) => {
        CommandService.handleReparentUndoRedo(org, edit, false);
        org.addEdit(edit.elem, edit.elemType, edit.operation, edit.originalElem);
      });
    }
    CommandService.setIsReverted(OrgSelectionUtil.getSelectedOrgId(), false);
    OrgTreeCache.clear(); // update org tree if there are reparent edits
    this.props.update();
  };

  public render(): React.ReactNode {
    const { selectedOrg } = this.props;
    const { formatMessage } = this.props.intl;
    const isCompartmentDeleted = CommandService.isDeleted(selectedOrg.id, selectedOrg.id);
    const isDeleteDisabledByOrgPolicy =
      !selectedOrg.policiesLoaded || !selectedOrg.compartmentPolicy.policies.deleteOrgs.value;
    const isEditDisabledByOrgPolicy =
      !selectedOrg.policiesLoaded || !selectedOrg.compartmentPolicy.policies.renameOrgs.value;
    const isCreateChildrenDisabledByOrgPolicy =
      !selectedOrg.policiesLoaded || !selectedOrg.compartmentPolicy.policies.createChildren.value;
    return (
      <div className="EditCompartment_actionButtons">
        {/* OPEN */}
        <TooltipTrigger delay={0}>
          <ActionButton
            onPress={(): void => {
              Analytics.fireCTAEvent(`Open in Admin Console button clicked`);
              const goToAdminConsoleDialogRef = ModalContainer.show(
                <GoToAdminConsoleDialog
                  onClose={(): void => {
                    ModalContainer.hide(goToAdminConsoleDialogRef as number);
                  }}
                  url={`${config.adminConsole.url}/${selectedOrg.id}/overview`}
                  selectedOrg={this.props.selectedOrg}
                />,
                this
              );
            }}
            data-testid="open-admin-console-link-button"
            aria-label={formatMessage(messages.Add)}
            marginX="4px"
          >
            <LinkOut size="S" />
          </ActionButton>
          <Tooltip>{formatMessage(messages.Open)}</Tooltip>
        </TooltipTrigger>

        {!AdminPermission.readOnlyMode() && (
          <React.Fragment>
            {/* ADD */}
            <TooltipTrigger delay={0}>
              <ActionButton
                isDisabled={
                  isCompartmentDeleted || isCreateChildrenDisabledByOrgPolicy || selectedOrg.isReadOnlyOrg() // cannot add org to a read only org
                }
                data-testid="add-organization-button"
                onPress={(): void => {
                  Analytics.fireCTAEvent(`add child org dialog opened`);
                  this.setState({ showAddDialog: true });
                }}
                aria-label={formatMessage(messages.Add)}
                marginX="4px"
              >
                <Add />
              </ActionButton>

              <Tooltip>{formatMessage(messages.Add)}</Tooltip>
            </TooltipTrigger>
            <DialogContainer
              onDismiss={() => {
                this.setState({ showAddDialog: false });
              }}
            >
              {this.state.showAddDialog && (
                <AddChildOrgContent
                  update={this.props.update}
                  selectedOrg={this.props.selectedOrg}
                  close={(): void => {
                    this.setState({ showAddDialog: false });
                  }}
                />
              )}
            </DialogContainer>

            {/* EDIT */}
            <TooltipTrigger delay={0}>
              <ActionButton
                isDisabled={isCompartmentDeleted || isEditDisabledByOrgPolicy}
                data-testid="edit-organization-button"
                onPress={(): void => {
                  Analytics.fireCTAEvent(`edit org dialog opened`);
                  this.setState({ showEditDialog: true });
                }}
                aria-label={formatMessage(messages.Edit)}
                marginX="4px"
              >
                <Edit />
              </ActionButton>
              <Tooltip>{formatMessage(messages.Edit)}</Tooltip>
            </TooltipTrigger>
            <DialogContainer
              onDismiss={() => {
                this.setState({ showEditDialog: false });
              }}
            >
              {this.state.showEditDialog && (
                <EditOrganization
                  selectedOrg={this.props.selectedOrg}
                  update={this.props.update}
                  close={(): void => {
                    this.setState({ showEditDialog: false });
                  }}
                />
              )}
            </DialogContainer>

            {/* DELETE */}
            <TooltipTrigger delay={0}>
              <ActionButton
                isDisabled={
                  isCompartmentDeleted ||
                  !HierarchyManager.getOrg(selectedOrg.organization.parentOrgId) ||
                  isDeleteDisabledByOrgPolicy ||
                  selectedOrg.isReadOnlyOrg() // cannot delete a read only org
                }
                data-testid="delete-organization-button"
                onPress={(): void => {
                  Analytics.fireCTAEvent(`delete org dialog opened`);
                  this.setState({ showDeleteDialog: true });
                }}
                aria-label={formatMessage(messages.Delete)}
                marginX="4px"
              >
                <DeleteOutline />
              </ActionButton>

              <Tooltip>{formatMessage(messages.Delete)}</Tooltip>
            </TooltipTrigger>
            <DialogContainer
              onDismiss={() => {
                this.setState({ showDeleteDialog: false });
              }}
            >
              {this.state.showDeleteDialog && (
                <DeleteOrganization
                  selectedOrg={this.props.selectedOrg}
                  update={this.props.update}
                  close={(): void => {
                    this.setState({ showDeleteDialog: false });
                  }}
                />
              )}
            </DialogContainer>

            {/* UNDO */}
            <TooltipTrigger delay={0}>
              <ActionButton
                onPress={this.undoChangesOrShowDialog}
                isDisabled={
                  isCompartmentDeleted ||
                  selectedOrg.isNewOrg() ||
                  _.isEmpty(CommandService.getAllEditsForTheOrgExcludingUndo(selectedOrg.id)) // disable when org is deleted or created or no edits
                }
                data-testid="undo-organization-button"
                aria-label={formatMessage(messages.Undo)}
                marginX="4px"
              >
                <Undo />
              </ActionButton>
              <Tooltip>{formatMessage(messages.Undo)}</Tooltip>
            </TooltipTrigger>
            <DialogContainer
              onDismiss={() => {
                this.setState({ showUndoConfirmDialog: false });
              }}
            >
              {
                // Popup confirmation dialog
                this.state.showUndoConfirmDialog && this.getAlertDialog()
              }
            </DialogContainer>

            {/* REDO */}
            <TooltipTrigger delay={0}>
              <ActionButton
                onPress={this.redoChanges}
                isDisabled={
                  isCompartmentDeleted || // disable when org is deleted
                  selectedOrg.isNewOrg() ||
                  !CommandService.isReverted(selectedOrg.id) // disable if no changes
                }
                data-testid="redo-organization-button"
                aria-label={formatMessage(messages.Redo)}
                marginX="4px"
              >
                <Redo />
              </ActionButton>
              <Tooltip>{formatMessage(messages.Redo)}</Tooltip>
            </TooltipTrigger>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default injectIntl(CompartmentActionButtons);
