/* eslint-disable @typescript-eslint/no-inferrable-types,prefer-template, no-restricted-syntax */
import * as _ from 'lodash';
import { CAP_RESOURCE_PREFIX, CAP_UNLIMITED, STOCK_FIS } from './OrgMaster';
import Utils from '../utils/Utils';

/**
 * Interface for Icons.
 * Contains image urls for different image types and resolutions.
 */
export interface Icons {
  svg?: string;
  '48x48'?: string;
  '16x16'?: string;
  '128x128'?: string;
}

export enum FulfillableItemType {
  DESKTOP = 'DESKTOP',
  SERVICE = 'SERVICE',
  FEATURE = 'FEATURE',
  MOBILE_APP = 'MOBILE_APP',
  QUOTA = 'QUOTA',
}

/**
 * Resource data from product and product profiles.
 */
export interface UResourceData {
  code?: string;
  shortDescription?: string;
  longDescription?: string;
  selected?: boolean;
  delegationConfigurable?: boolean;
  fulfillmentConfigurable?: boolean;
  fulfillableItemType?: FulfillableItemType;
  enterpriseName?: string;
  purchased?: boolean;
  icons?: Icons;
  cap?: string;
  unit?: string;
  isDivisible?: boolean;
  isInstantiable?: boolean;
  isDistributable?: boolean;
  provisionedQuantity?: number;
  grantedQuantity?: string;
}

/**
 * Resource data from product and product profiles and also includes methods and functionality
 */
export class UResource {
  code: string = '';
  shortDescription: string = '';
  longDescription: string = '';
  selected: boolean = false;
  delegationConfigurable: boolean = false;
  fulfillmentConfigurable: boolean = false;
  fulfillableItemType: FulfillableItemType | undefined;
  enterpriseName: string = ''; // Resource name.  For *license* resource, this is the non-localized resource name generated by banyansvc.
  purchased: boolean = false;
  icons: Icons = {};
  cap: string = '';
  unit: string = ''; // Resource unit.  For *license* resource, this is the non-localized resource unit generated by banyansvc.
  isDivisible: boolean = false;
  isInstantiable: boolean = false;
  isDistributable: boolean = false;
  provisionedQuantity: number = 0;
  grantedQuantity: string = '';

  constructor(resource?: UResourceData) {
    if (resource) {
      this.code = resource.code || this.code;
      this.shortDescription = resource.shortDescription || this.shortDescription;
      this.longDescription = resource.longDescription || this.longDescription;
      this.selected = resource.selected || this.selected;
      this.delegationConfigurable = resource.delegationConfigurable || this.delegationConfigurable;
      this.fulfillmentConfigurable = resource.fulfillmentConfigurable || this.fulfillmentConfigurable;
      this.fulfillableItemType = resource.fulfillableItemType;
      this.enterpriseName = resource.enterpriseName || this.enterpriseName;
      this.purchased = resource.purchased || this.purchased;
      this.icons = resource.icons || this.icons;
      this.cap = resource.cap || this.cap;
      this.unit = resource.unit || this.unit;
      this.isDivisible = resource.isDivisible || this.isDivisible;
      this.isInstantiable = resource.isInstantiable || this.isInstantiable;
      this.isDistributable = resource.isDistributable || this.isDistributable;
      this.provisionedQuantity = resource.provisionedQuantity || this.provisionedQuantity;
      this.grantedQuantity = resource.grantedQuantity || this.grantedQuantity;
    }
  }

  /**
   * Determines whether this resource is a seat resource (code begins with OrgMaster.CAP_RESOURCE_PREFIX)
   */
  public static isSeatResource(code: string | undefined): boolean {
    return _.startsWith(code, CAP_RESOURCE_PREFIX);
  }

  /**
   * checks if this resource is a stock resource by searching in a defined list of stock FIs
   */
  isAStockFI(): boolean {
    return _.includes(STOCK_FIS, this.code);
  }

  /**
   * Determines whether a resource is unlimited (grantedQuantity is "UNLIMITED")
   */
  isUnlimited(): boolean {
    return this.grantedQuantity === CAP_UNLIMITED;
  }

  /**
   * Returns the cap value as a number.  Returns the given
   * defaultValue if the cap value can't be converted to a number.
   */
  capAsInt(defaultValue: number): number {
    return Utils.parseIntOrDefault(this.cap, defaultValue);
  }

  /**
   * Returns the grant value as a number. Returns the given
   * defautValue if the cap value can't be converted to a number.
   */
  grantAsInt(defaultValue: number): number {
    return Utils.parseIntOrDefault(this.grantedQuantity, defaultValue);
  }

  /**
   * Converts the cap value to a number.  Returns the given defaultValue which can be undefined if the cap value can't be converted to a number.
   * Same as capAsInt except allows undefined defaultValue.
   */
  capAsNilInt(defaultValue: number | undefined): number | undefined {
    return Utils.parseIntOrNilDefault(this.cap, defaultValue);
  }

  /**
   * Converts the grant value to a number.  Returns the given defaultValue which can be undefined if the grant value can't be converted to a number.
   * Same as grantAsInt except allows undefined defaultValue.
   */
  grantAsNilInt(defaultValue: number | undefined): number | undefined {
    return Utils.parseIntOrNilDefault(this.grantedQuantity, defaultValue);
  }

  /**
   * Determines whether the resource code is valid (not an empty string).
   */
  validCode(): boolean {
    return !Utils.isEmptyString(this.code);
  }

  /**
   * Returns a name for the resource.  The name is the enterprise name if available otherwise
   * it is the code.
   */
  name(): string {
    if (!Utils.isEmptyString(this.enterpriseName)) {
      return this.enterpriseName;
    }
    return this.code;
  }
}

/**
 * Resource object that contains calculated data for the table
 */
export class ResourceCalculationData {
  // grant is grantedQuantity
  // localUse is provisionedQuantity
  // directChildSum means sum value of only the direct chidren of the current org
  // allChildSum means sum values of all children and all their children of the current org

  allocOut: number | undefined = 0; // (directChildSum(grant)) | (undefined if no children)
  overAlloc: number | undefined = 0; // abs(grant - allocOut - directChildSum(overalloc)) | (undefined if allocOut is undefined)
  totalAlloc: number | undefined = 0; // (allocOut + directChildSum(overAlloc)) | (undefined if any operand is undefined)
  grantOver: number | undefined = 0; // (grant - totalAlloc) | (undefined if grant is unlimited)
  // LocalLicensedQuantity value should match Admin Console's quantity
  localLicensedQuantity: number | undefined = 0; // (grant - allocOut) | (undefined if grant is unlimited) (0 if negative)
  totalUse: number = 0; // (localUse + allChildSum(localUse))
  useOver: number | undefined = 0; // (grant - totalUse) | (undefined if grant is unlimited)
}
