/**
 * Defines constants used for the OrgMasterTree and the Org data objects.
 */

/**
 * Enumerates states for org data objects.
 */
export enum EditState {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
}

/**
 * Enumerates operations to be performed on org data objects when importing.
 * Note: These are very similar to the EditState as many of these operations
 * make changes and then set the state on the org data object.
 */
export enum OrgOperation {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  REPARENT = 'REPARENT',
}

/**
 * Enumerates names of the different types of org data objects.
 * Although we are using typing, the data isn't typed from the back-end, so this
 * specifies what typing we should use and explicitly declares the type when
 * typing isn't available.
 */
export enum ObjectTypes {
  COMPARTMENT_POLICY = 'UCompartmentPolicies',
  ORGANIZATION = 'UOrganization',
  DOMAIN = 'UDomain',
  PRODUCT = 'UProduct',
  PRODUCT_PROFILE = 'UProductProfile',
  USER_GROUP = 'UUserGroup',
  USER_GROUP_SHARE = 'UUserGroupShare',
  ADMIN = 'UAdmin',
  CONTRACT_REPARENT = 'UContractReparent',
  CONTRACT_SWITCH = 'UContractSwitch',
  ALLOCATION_RETROFIT = 'UAllocationRetrofit',
  CONTRACT_ROLLBACK = 'UContractRollback',
  ALLOCATION_ROLLBACK = 'UAllocationRollback',
}

/**
 * Enumerates types of errors for import.
 */
export enum ImportError {
  ADMIN_FOR_WRONG_ORG = 'adminIdForWrongOrg',
  ELEMENT_ALREADY_IN_ORG = 'elementAlreadyInOrg',
  ELEMENT_MISSING_ORGID = 'elementMissingOrgID',
  ELEMENT_NOT_FOUND = 'elementNotFound',
  INVALID_ROLE_FOR_ADMIN = 'invalidRoleForAdmin',
  INVALID_TARGET_FOR_ADMIN = 'invalidTargetForAdmin',
  ORG_NOT_FOUND = 'orgNotFound',
  UNEXPECTED_ELEMENT_TYPE = 'unexpectedElementType',
}

/**
 * An error object containing only the import error type that can be returned
 * when org data operations have an error.  Can also contain custom error types as strings.
 */

/**
 * An error object containing the import error type and an error message that
 * can be returned when org data operations have an error.
 */
export interface ErrorData {
  errorMessage: string;
  code?: string;
}

export const TEMP_ID_PREFIX = '_NEW_ID'; // Temp id prepended to orgId when a newly created org doesn't exist yet and needs a temporary id (also used to detect newly created orgs)
export const CAP_RESOURCE_PREFIX = 'license_gac_'; // prefix at the beginning of product and product profile resources that contain cap and provisioned quantity values (used to detect which resource provides the cap and provisionedQuantity values)
export const CAP_UNLIMITED = 'UNLIMITED'; // Unlimited valu efor product caps.  Product caps can either be a number value or 'UNLIMITED'
export const STOCK_FIS = [
  // list of adobe stock FI codes
  'stock_image',
  'stock_credit',
  'stock_standard_credits',
  'stock_premium_credits',
  'stock_universal_credits',
];
