import React from 'react';
import _ from 'lodash';
import { Toast } from '@react/react-spectrum/Toast';
import { ToastInfo } from '../../services/toastMaster/ToastInfo';
import { BanyanToastType } from './BanyanToastType';
import './ToastList.css';

interface ToastDetails {
  toastInfo: ToastInfo;
  toastTimerId: number; // id of the timer that clears this toast. If the toast is to be displayed indefinitely, toastTimerId=-1
}

interface ToastListState {
  toastList: ToastDetails[];
}

interface ToastListProps {
  className?: string;
  style?: object;
  connectToMaster?: (toast: ToastList) => void; // Method to call to add this ToastList component to the ToastMaster
}

/**
 * Note: This component is intended to specify all toasts displayed within html.
 * This component handles following responsibilities:
 * 1. stores all toast related information
 * 2. manage timers to automatically hide a toast (if any)
 *
 *
 * It is NOT intended to be called directly to display the toasts.  Use ToastMaster service for that.
 * Note: This component is attached to the ToastMaster automatically.  This means that
 * you can only have once instance of this component
 */

class ToastList extends React.Component<ToastListProps, ToastListState> {
  // toasts with action buttons are always displayed indefinitely.
  // Toasts are displayed indefinitely by setting showTime: 0
  INDEFINITE_TOAST_SHOW_TIME = 0;

  DEFAULT_TOAST_TIME_IN_MILLIS = 10 * 1000; // 10 seconds

  constructor(props: ToastListProps) {
    super(props);
    if (this.props.connectToMaster) {
      this.props.connectToMaster(this);
    }
    this.state = {
      toastList: [],
    };
  }

  public addToastWithAction = (
    toastMessage: string,
    toastType: BanyanToastType,
    actionLabel: string,
    onAction: () => void
  ): void => {
    this.setState((prevState: ToastListState): Pick<ToastListState, never> => {
      const { toastList } = prevState;

      const toastDetails: ToastDetails = this.generateToastDetails(
        toastMessage,
        toastType,
        this.INDEFINITE_TOAST_SHOW_TIME,
        actionLabel,
        onAction
      );
      toastList.push(toastDetails);

      return { toastList };
    });
  };

  /**
   * Add a toast to the toast list and generate a timer to automatically remove the toast
   */
  public addToast = (toastMessage: string, toastType: BanyanToastType, showTime?: number): void => {
    this.setState((prevState: ToastListState): Pick<ToastListState, never> => {
      const { toastList } = prevState;

      const toastDetails: ToastDetails = this.generateToastDetails(
        toastMessage,
        toastType,
        showTime || this.DEFAULT_TOAST_TIME_IN_MILLIS
      );
      toastList.push(toastDetails);

      return { toastList };
    });
  };

  private generateToastDetails = (
    toastMessage: string,
    toastType: BanyanToastType,
    showTime: number,
    actionLabel?: string,
    onAction?: () => void
  ): ToastDetails => {
    const toastInfo: ToastInfo = {
      toastMessage,
      toastType,
      actionLabel,
      onAction,
      showTime,
      toastId: _.uniqueId(),
    };
    const toastTimerId = this.generateToastHidingTimer(toastInfo);
    return {
      toastInfo,
      toastTimerId,
    };
  };

  /**
   * hide a toast with id toastId. Also, clear any timers created to clear any toast messages
   */
  private removeToast = (toastId: string): void => {
    this.setState((prevState: ToastListState): Pick<ToastListState, never> => {
      const { toastList } = prevState;
      const toastIndex = _.findIndex(
        toastList,
        (toastDetails: ToastDetails): boolean => toastDetails.toastInfo.toastId === toastId
      );
      if (toastIndex !== -1) {
        // clear timer (if any) created to clear this toast
        const { toastTimerId } = toastList[toastIndex];
        if (toastTimerId !== -1) {
          clearTimeout(toastTimerId);
        }

        toastList.splice(toastIndex, 1);
        return { toastList };
      }
      return {};
    });
  };

  /**
   * Create a timer to automatically remove a toast. If the toast is NOT to be removed automatically, return -1
   *
   * Toast should be removed automatically in the following scenarios
   * 1. toastType=INFO || toastType=SUCCESS, and showTime > 0
   *
   * IMPORTANT: toastType=ERROR and toastType=WARNING are NEVER removed automatically from the UI
   * @returns timer id when a timer is created to remove a toast else -1
   */
  private generateToastHidingTimer = (toastInfo: ToastInfo): number => {
    if (
      toastInfo.showTime > 0 &&
      (toastInfo.toastType === BanyanToastType.INFO || toastInfo.toastType === BanyanToastType.SUCCESS)
    ) {
      return setTimeout(this.removeToast, toastInfo.showTime, toastInfo.toastId);
    }
    return -1;
  };

  render(): React.ReactNode {
    return (
      <div className={`BanyanToastContainer ${this.props.className}`} style={this.props.style}>
        {this.state.toastList.map((toastDetails: ToastDetails): React.ReactChild => {
          const { toastInfo } = toastDetails;
          return (
            <div key={toastInfo.toastId}>
              <Toast
                variant={toastInfo.toastType}
                closable
                onClose={() => this.removeToast(toastInfo.toastId)}
                actionLabel={toastInfo.actionLabel}
                onAction={toastInfo.onAction}
                closeOnAction
                className="BanyanToast"
              >
                {toastInfo.toastMessage}
              </Toast>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ToastList;
