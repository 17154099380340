import React from 'react';
import _ from 'lodash';
import Switch from '@react/react-spectrum/Switch';
import { TR, TD } from '@react/react-spectrum/Table';
import LockClosed from '@react/react-spectrum/Icon/LockClosed';
import LockOpen from '@react/react-spectrum/Icon/LockOpen';
import InfoOutline from '@react/react-spectrum/Icon/InfoOutline';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Button from '@react/react-spectrum/Button';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import { OrganizationPolicy, policiesNotSupportedYet } from '../../../services/orgMaster/UCompartmentPolicy';
import './CompartmentPolicy.css';
import LocaleTooltip from '../../../components/LocaleTooltip/LocaleTooltip';
import HierarchyManager from '../../../services/organization/HierarchyManager';
import FloodgateService from '../../../services/floodgate/FloodgateService';

interface OrganizationPolicyRowProps extends WrappedComponentProps {
  organizationPolicy: OrganizationPolicy;
  policyDescription: string;
  isLocked: boolean;
  isSwitchDisabled: boolean;
  updatePolicyValue: (policyName: string | undefined, selection: boolean) => void;
  updateLockedBy: (policyName: string | undefined) => void;
}

const messages = defineMessages({
  lockedByTT: {
    id: 'EditCompartment.Policies.lock.tooltip',
    defaultMessage: 'Locked by "{pathName}"',
  },
  notLockedTT: {
    id: 'EditCompartment.Policies.unlock.tooltip',
    defaultMessage: 'Click to lock this policy',
  },
  allowed: {
    id: 'EditCompartment.Policies.allowed',
    defaultMessage: 'Allowed',
  },
  notAllowed: {
    id: 'EditCompartment.Policies.notAllowed',
    defaultMessage: 'Not allowed',
  },
  notSupported: {
    id: 'EditCompartment.Policies.notSupported',
    defaultMessage: 'This policy is currently not editable. The feature will be available in a future release.',
  },
});

/**
 * Presentational component representing each row in policy table for an organization
 */
const ORG_POLICY_TESTID = 'orgpolicy-testid-';
const ORG_POLICY_LOCK_TESTID = 'orgpolicy-lock-testid-';
class OrganizationPolicyRow extends React.Component<OrganizationPolicyRowProps> {
  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { organizationPolicy, policyDescription, isLocked, isSwitchDisabled, updatePolicyValue, updateLockedBy } =
      this.props;
    let isPolicyNotSupportedYet =
      organizationPolicy && organizationPolicy.name ? policiesNotSupportedYet.has(organizationPolicy.name) : true;
    if (
      organizationPolicy?.name &&
      _.isEqual(organizationPolicy.name, 'manageUserGroups') &&
      !FloodgateService.isFeatureEnabled(FloodgateService.ENABLE_MANAGE_USER_GROUPS_ORG_POLICY)
    ) {
      isPolicyNotSupportedYet = true;
    }
    return (
      <TR id={`policies.${organizationPolicy.name}`} data-testid={`${ORG_POLICY_TESTID}${organizationPolicy.name}`}>
        <TD className={isPolicyNotSupportedYet ? 'CompartmentPolicy--not-supported' : ''}>{policyDescription}</TD>
        <TD>
          <span>
            <Switch
              onChange={(selection: boolean): void => {
                updatePolicyValue(organizationPolicy.name, selection);
              }}
              checked={organizationPolicy.value}
              disabled={isSwitchDisabled || isLocked || isPolicyNotSupportedYet}
              className={
                isPolicyNotSupportedYet
                  ? 'policies-ToggleSwitch CompartmentPolicy--not-supported'
                  : 'policies-ToggleSwitch'
              }
            >
              {organizationPolicy.value ? formatMessage(messages.allowed) : formatMessage(messages.notAllowed)}
            </Switch>
            {isPolicyNotSupportedYet && (
              <OverlayTrigger placement="right">
                <span>
                  <InfoOutline size="S" style={{ top: '.425em', position: 'relative' }} />
                </span>
                <LocaleTooltip>{formatMessage(messages.notSupported)}</LocaleTooltip>
              </OverlayTrigger>
            )}
          </span>
        </TD>
        <TD>
          <OverlayTrigger>
            <Button
              quiet
              variant="tool"
              onClick={(): void => {
                if (!isSwitchDisabled && !isPolicyNotSupportedYet) {
                  updateLockedBy(organizationPolicy.name);
                }
              }}
              data-testid={`${ORG_POLICY_LOCK_TESTID}${organizationPolicy.name}`}
              aria-label={isLocked || !_.isEmpty(organizationPolicy.lockedBy) ? 'lock' : 'unlock'}
              icon={
                isLocked || !_.isEmpty(organizationPolicy.lockedBy) ? (
                  <LockClosed size="S" />
                ) : (
                  <LockOpen size="S" className="CompartmentPolicy__lock--open" />
                )
              }
              disabled={isPolicyNotSupportedYet}
            />
            <LocaleTooltip>
              {isLocked || !_.isEmpty(organizationPolicy.lockedBy)
                ? formatMessage(messages.lockedByTT, {
                    pathName: HierarchyManager.getOrg(organizationPolicy.lockedBy as string)?.getPathname(),
                  })
                : formatMessage(messages.notLockedTT)}
            </LocaleTooltip>
          </OverlayTrigger>
        </TD>
      </TR>
    );
  }
}

const ALLOWED_LABEL = messages.allowed.defaultMessage;
const NOT_ALLOWED_LABEL = messages.notAllowed.defaultMessage;
export default injectIntl(OrganizationPolicyRow);
export { ALLOWED_LABEL, NOT_ALLOWED_LABEL, ORG_POLICY_TESTID, ORG_POLICY_LOCK_TESTID };
