import * as _ from 'lodash';
import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Heading from '@react/react-spectrum/Heading';
import Well from '@react/react-spectrum/Well';
import Rule from '@react/react-spectrum/Rule';
import Switch from '@react/react-spectrum/Switch';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { OrgOperation } from '../../../services/orgMaster/OrgMaster';
import {
  UAdmin,
  AdminProductInfo,
  AdminProfileInfo,
  AdminUserGroupInfo,
  AdminContractInfo,
} from '../../../services/orgMaster/UAdmin';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import { UserTypeMapInterface, UserTypeMap } from './services/UserTypeMap';
import { CommandService } from '../../../services/Commands/CommandService';
import { OrgAdminType } from '../../../services/authentication/IMS';
import ContractSection from './ContractSection';
import ProductSection from './ProductSection';
import ProfileSection from './ProfileSection';
import UserGroupSection from './UserGroupSection';
import './Common.css';
import '../../common.css';
import Analytics from '../../../Analytics/Analytics';
import FloodgateService from '../../../services/floodgate/FloodgateService';

interface EditAdminContentProps extends WrappedComponentProps {
  admin: UAdmin;
  update: () => void;
  selectedOrg: UOrgMaster;
}
interface EditAdminContentState {
  sysAdminEnabled: boolean;
  globalAdminEnabled: boolean;
  globalAdminReadOnlyEnabled: boolean;
  deploymentAdminEnabled: boolean;
  supportAdminEnabled: boolean;
  storageAdminEnabled: boolean;
  // List of profiles (indicated by info) currently associated with profile admin.
  productInfoList: AdminProductInfo[];
  // List of profiles (indicated by info) currently associated with profile admin.
  profileInfoList: AdminProfileInfo[];
  // List of user groups (indicated by info) currently associated with user group admin.
  userGroupInfoList: AdminUserGroupInfo[];
  // List of contracts (indicated by info) currently associated with contract admin role.
  contractInfoList: AdminContractInfo[];
  loaded: boolean; // determines whether component should indicate loading (products loading)
}

const messages = defineMessages({
  Save: {
    id: 'EditCompartment.Admins.dialog.Save',
    defaultMessage: 'Save',
  },
  editadmin: {
    id: 'EditCompartment.Admins.dialog.EditAdmin',
    defaultMessage: 'EDIT ADMIN',
  },
  Cancel: {
    id: 'EditCompartment.Admins.dialog.Cancel',
    defaultMessage: 'Cancel',
  },
});

class EditAdminContent extends React.Component<EditAdminContentProps, EditAdminContentState> {
  constructor(props: EditAdminContentProps) {
    super(props);
    this.admin = _.cloneDeep(this.props.admin);
    this.userTypeMap = UserTypeMap.get();
    this.state = {
      productInfoList: this.admin.getAdminProductInfo(),
      profileInfoList: this.admin.getAdminProfileInfo(),
      userGroupInfoList: this.admin.getAdminUserGroupInfo(),
      contractInfoList: this.admin.getAdminContractInfo(),
      sysAdminEnabled: this.admin.isSysAdmin(),
      globalAdminEnabled: this.admin.isGlobalAdmin(),
      globalAdminReadOnlyEnabled: this.admin.isGlobalAdminReadOnly(),
      deploymentAdminEnabled: this.admin.isDeploymentAdmin(),
      storageAdminEnabled: this.admin.isStorageAdmin(),
      supportAdminEnabled: this.admin.isSupportAdmin(),
      loaded: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    const { admin } = this.props;
    if (admin.isProductAdmin()) {
      // load products so that we can get the product names for products associated with product admin (admins don't include product name data)
      await LoadOrgDataService.loadProducts(this.props.selectedOrg.organization.id);
    }
    this.setState({ loaded: true });
  };

  // update the list of products selected (TODO: rename to 'updateProductAdminRights')
  private updateProductAdminRightsV2 = (
    productInfoList: AdminProductInfo[],
    productId: string,
    addRole: boolean
  ): void => {
    this.admin.updateProductAdminFor(productId, addRole);
    this.setState({ productInfoList });
  };

  // update the list of current profiles selected (UAdmin version of 'updateProfileAdminRights') (rename to 'updateProfileAdminRights' when UUsers are no longer used)
  private updateProfileAdminRightsV2 = (
    profileInfoList: AdminProfileInfo[],
    profileId: string,
    profileName: string | undefined,
    addRole: boolean
  ): void => {
    this.admin.updateProfileAdminFor(profileId, profileName, this.props.selectedOrg, addRole);
    this.setState({ profileInfoList });
  };

  // update the list of current user groups selected (UAdmin version of 'updateUserGroupAdminRights') (rename to 'updateUserGroupAdminRights' when UUsers are no longer used)
  private updateUserGroupAdminRightsV2 = (
    userGroupInfoList: AdminUserGroupInfo[],
    userGroupId: string,
    userGroupName: string | undefined,
    addRole: boolean
  ): void => {
    this.admin.updateUserGroupAdminFor(userGroupId, userGroupName, addRole);
    this.setState({ userGroupInfoList });
  };

  // update the list of current contracts selected
  private updateContractAdminRights = (
    contractInfoList: AdminContractInfo[],
    contractId: string,
    addRole: boolean
  ): void => {
    this.admin.updateContractAdminFor(contractId, addRole);
    this.setState({ contractInfoList });
  };

  // update the current selection of system admin right
  private updateSystemAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.ORG_ADMIN, addRole);
    this.setState({ sysAdminEnabled: addRole });
  };

  // update the current selection of global admin right
  private updateGlobalAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.COMPARTMENT_ADMIN, addRole);
    this.setState({ globalAdminEnabled: addRole });
  };

  // update the current selection of global admin read-only righyt
  private updateGlobalAdminReadOnlyRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.COMPARTMENT_VIEWER, addRole);
    this.setState({ globalAdminReadOnlyEnabled: addRole });
  };

  // update the current selection of deployment admin right
  private updateDeploymentAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.DEPLOYMENT_ADMIN, addRole);
    this.setState({ deploymentAdminEnabled: addRole });
  };

  // update the current selection of support admin right
  private updateSupportAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.SUPPORT_ADMIN, addRole);
    this.setState({ supportAdminEnabled: addRole });
  };

  // update the current selection of support admin right
  private updateStorageAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.STORAGE_ADMIN, addRole);
    this.setState({ storageAdminEnabled: addRole });
  };

  // disable save if no admin right is selected
  private isConfirmDisabled = (): boolean => {
    return !this.admin.hasAnyEdits();
  };

  admin: UAdmin;
  userTypeMap: UserTypeMapInterface;

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;

    const { selectedOrg } = this.props;
    const showSystemAdminRole = true;
    const showGlobalAdminRole = !selectedOrg.isReadOnlyOrg();
    const showGlobalViewerAdminRole = !selectedOrg.isReadOnlyOrg();
    const showProductAdminRole = !selectedOrg.isReadOnlyOrg();
    const showProductProfileAdminRole = !selectedOrg.isReadOnlyOrg();
    const showUserGroupAdminRole = !selectedOrg.isReadOnlyOrg();
    const showContractAdminRole =
      FloodgateService.isFeatureEnabled(FloodgateService.ENABLE_CONTRACT_ADMIN) && !selectedOrg.isReadOnlyOrg();
    const showDeploymentAdminRole = !selectedOrg.isReadOnlyOrg();
    const showSupportAdminRole = !selectedOrg.isReadOnlyOrg();
    const showStorageAdminRole = !selectedOrg.isReadOnlyOrg();

    const adminInfo: React.ReactNode = (
      <Well className="AdminDialogSection">
        <div className="AdminDialogSubHeading">
          <Heading variant="subtitle2">
            <FormattedMessage id="EditCompartment.Admins.Edit.area.UserDetails" defaultMessage="User Details" />
          </Heading>
        </div>
        <Rule variant="medium" />
        <div>
          <div className="AdminDialogUserDetailsContainer">
            <span className="AdminDialogUserDetailLabels">
              <FormattedMessage id="EditCompartment.Admins.Edit.field.Name" defaultMessage="Name" />
            </span>
            <span className="AdminDialogUserDetailsTextValue">{`${this.admin.firstName} ${this.admin.lastName}`}</span>
          </div>
          <div className="AdminDialogUserDetailsContainer">
            <span className="AdminDialogUserDetailLabels">
              <FormattedMessage id="EditCompartment.Admins.Edit.field.Email" defaultMessage="Email" />
            </span>
            <span className="AdminDialogUserDetailsTextValue">{this.admin.email}</span>
          </div>
          <div className="AdminDialogUserDetailsContainer">
            <span className="AdminDialogUserDetailLabels">
              <FormattedMessage id="EditCompartment.Admins.Edit.field.AccountType" defaultMessage="Account Type" />
            </span>
            <span className="AdminDialogUserDetailsTextValue">
              {this.admin.userType && this.userTypeMap[this.admin.userType]}
            </span>
          </div>
        </div>
      </Well>
    );

    const adminRightSection: React.ReactNode = (
      <Well className="AdminDialogSection">
        <div className="AdminDialogSubHeading">
          <Heading variant="subtitle2" className="EditAdminContent_heading">
            <FormattedMessage id="EditCompartment.Admins.Edit.area.AdminRoles" defaultMessage="Admin Roles" />
          </Heading>
          <div className="AdminInfoSubsection">
            {this.isConfirmDisabled() ? (
              <span className="AdminDialogInfoMessage">
                <FormattedMessage
                  id="EditCompartment.Admins.Edit.mgs.SelectAt"
                  defaultMessage="Change at least one admin role"
                />
              </span>
            ) : null}
          </div>
        </div>
        <Rule variant="medium" />
        <div>
          {showSystemAdminRole && (
            <div className="AdminRightSubsection">
              <span id="select-system-admin-rights">
                <FormattedMessage id="EditCompartment.Admins.Edit.role.System" defaultMessage="System" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateSystemAdminRights}
                defaultChecked={this.admin.isSysAdmin()}
                disabled={false}
                aria-labelledby="select-system-admin-rights"
              />
            </div>
          )}
          {showGlobalAdminRole && (
            <div className="AdminRightSubsection">
              <span id="select-global-admin-rights">
                <FormattedMessage id="EditCompartment.Admins.Edit.role.Global" defaultMessage="Global" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateGlobalAdminRights}
                defaultChecked={this.admin.isGlobalAdmin()}
                disabled={false}
                aria-labelledby="select-global-admin-rights"
              />
            </div>
          )}
          {showGlobalViewerAdminRole && (
            <div className="AdminRightSubsection">
              <span id="select-global-viewer-admin-rights">
                <FormattedMessage id="EditCompartment.Admins.Edit.role.GlobalRO" defaultMessage="Global Viewer" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateGlobalAdminReadOnlyRights}
                defaultChecked={this.admin.isGlobalAdminReadOnly()}
                disabled={false}
                aria-labelledby="select-global-viewer-admin-rights"
              />
            </div>
          )}
          {showContractAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admins.Edit.role.Contract" defaultMessage="Contract" />
              </div>
              <ContractSection
                compartment={this.props.selectedOrg}
                disabled={false}
                updateContractInfoCallback={this.updateContractAdminRights}
                initialContractInfoList={this.state.contractInfoList}
              />
            </div>
          )}
          {showProductAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admins.Edit.role.Product" defaultMessage="Product" />
              </div>
              <ProductSection
                compartment={this.props.selectedOrg}
                productInfoList={this.state.productInfoList}
                updateProductInfoCallback={this.updateProductAdminRightsV2}
                disabled={false}
              />
            </div>
          )}
          {showProductProfileAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage
                  id="EditCompartment.Admins.Edit.role.ProductProfile"
                  defaultMessage="Product Profile"
                />
              </div>
              <ProfileSection
                compartment={this.props.selectedOrg}
                updateProfileInfoCallback={this.updateProfileAdminRightsV2}
                profileInfoList={this.state.profileInfoList}
                disabled={false}
              />
            </div>
          )}
          {showUserGroupAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admins.Edit.role.UserGroup" defaultMessage="User Group" />
              </div>
              <UserGroupSection
                compartment={this.props.selectedOrg}
                updateUserGroupInfoCallback={this.updateUserGroupAdminRightsV2}
                userGroupInfoList={this.state.userGroupInfoList}
                disabled={false}
              />
            </div>
          )}
          {showDeploymentAdminRole && (
            <div className="AdminRightSubsection">
              <span id="select-deployment-admin-rights">
                <FormattedMessage id="EditCompartment.Admins.Edit.role.Deployment" defaultMessage="Deployment" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateDeploymentAdminRights}
                defaultChecked={this.admin.isDeploymentAdmin()}
                disabled={false}
                aria-labelledby="select-deployment-admin-rights"
              />
            </div>
          )}
          {showSupportAdminRole && (
            <div className="AdminRightSubsection">
              <span id="select-support-admin-rights">
                <FormattedMessage id="EditCompartment.Admins.Edit.role.Support" defaultMessage="Support" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateSupportAdminRights}
                defaultChecked={this.admin.isSupportAdmin()}
                disabled={false}
                aria-labelledby="select-support-admin-rights"
              />
            </div>
          )}
          {showStorageAdminRole && (
            <div className="AdminRightSubsection">
              <span id="select-storage-admin-rights">
                <FormattedMessage id="EditCompartment.Admins.Edit.role.Storage" defaultMessage="Storage" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateStorageAdminRights}
                defaultChecked={this.admin.isStorageAdmin()}
                disabled={false}
                aria-labelledby="select-storage-admin-rights"
              />
            </div>
          )}
        </div>
      </Well>
    );

    return (
      <Dialog
        title={formatMessage(messages.editadmin) /* "EDIT ADMIN" */}
        confirmLabel={formatMessage(messages.Save)}
        cancelLabel={formatMessage(messages.Cancel)}
        {...this.props}
        role="dialog"
        className="AdminDialog"
        onCancel={(): void => Analytics.fireCTAEvent(`edit admin dialog canceled`)}
        onConfirm={(): void => {
          Analytics.fireCTAEvent(`edit admin dialog save clicked`);
          CommandService.addAdminEdit(
            this.props.selectedOrg,
            _.cloneDeep(this.admin),
            OrgOperation.UPDATE,
            _.cloneDeep(this.props.admin)
          );
          this.props.update();
        }}
        confirmDisabled={this.isConfirmDisabled()}
      >
        {this.state.loaded ? (
          <div>
            <div>{adminInfo}</div>
            <div>{adminRightSection}</div>
          </div>
        ) : (
          <Wait className="Load_wait" />
        )}
      </Dialog>
    );
  }
}

export default injectIntl(EditAdminContent);
