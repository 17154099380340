import * as _ from 'lodash';

import { UProduct } from './UProduct';
import ContractJIL from './ContractsJIL';

/**
 * Data object which combines both product and associated contracts data.
 */
class ProductContractData {
  private productField: UProduct;
  private contractsField: ContractJIL[] = [];

  constructor(product: UProduct, contracts?: ContractJIL[]) {
    this.productField = product;
    this.contractsField = contracts || [];
  }

  /**
   * Product with associated contracts.
   */
  get product(): UProduct {
    return this.productField;
  }

  /**
   * Contracts associated with the product.
   */
  get contracts(): ContractJIL[] {
    return this.contractsField;
  }

  /**
   * Contract name(s) that should be displayed in the UI usually next to product names.
   */
  get contractNames(): string {
    return _.map(this.contractsField, (contract: ContractJIL): string => contract.displayName).join(', ');
  }

  /**
   * Contract tag(s) that should be displayed in the ProductTable.  (Usually indicates 'Allocation').
   * If there are multiple tags (multiple contracts for product), then only the unique tags will be returned.
   */
  get contractTags(): string[] {
    // only display unique tags
    return _.uniq(
      // map all contractTag strings from contracts, but only include the existing contractTags
      _.reduce(
        this.contractsField,
        (tags: string[], contract: ContractJIL): string[] => {
          if (!_.isEmpty(contract.contractTag())) {
            tags.push(contract.contractTag());
          }
          return tags;
        },
        []
      )
    );
  }

  /**
   * Displayable name with both the product name and contract name(s).
   * This is primarily for displaying the selected products from a product selector in tags.
   * name is of the form `${productName} (${contractNames})`
   */
  productContractsName(): string {
    return !_.isEmpty(this.contractNames) ? `${this.product.name} (${this.contractNames})` : this.product.name;
  }

  /**
   * Creates a ProductContractData object for a product given an array of contracts to search for which contract(s) are associated with the product.
   *
   * @param product product to create ProductContractData for
   * @param contracts Array of contracts which contains the contract(s) associated with the product.
   *                  Usually this is the contracts on the product's org and this method just finds associated contract(s).
   * @returns created ProductContractData
   */
  static createFromProductAndContracts(product: UProduct, contracts?: ContractJIL[]): ProductContractData {
    if (!_.isEmpty(product.contractIds) && !_.isEmpty(contracts)) {
      const prodContractIds = product.contractIds;
      // for each contract id associated with the product, retrieve the associated contracts
      const prodContracts = _.reduce(
        prodContractIds,
        (pContracts: ContractJIL[], prodContractId: string): ContractJIL[] => {
          const prodContract = _.find(contracts, (contract: ContractJIL): boolean => contract.id === prodContractId);
          if (prodContract) {
            pContracts.push(prodContract);
          }
          return pContracts;
        },
        []
      );
      return new ProductContractData(product, prodContracts);
    }
    return new ProductContractData(product);
  }

  /**
   * Creates multiple ProductContractData objects for multiple products and their associated contracts.
   *
   * @param products Array of products to create ProductContractData objects for.
   *                 Usually this is the product's on an org.
   * @param contracts Array of contracts which associate to the products in the array.
   *                  Usually this is the contracts on an org.
   * @returns created ProductContractData objects for all the products and contracts given
   */
  static createMultipleFromProductsAndContracts(
    products: UProduct[],
    contracts?: ContractJIL[]
  ): ProductContractData[] {
    const productsContractsData: ProductContractData[] = [];
    const sortedProducts = _.sortBy(products, (product: UProduct): string => product.name);
    _.forEach(sortedProducts, (product: UProduct): void => {
      productsContractsData.push(ProductContractData.createFromProductAndContracts(product, contracts));
    });
    return productsContractsData;
  }
}
export default ProductContractData;
