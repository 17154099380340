import React, { Component } from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { SideNav, SideNavHeading, SideNavItem } from '@react/react-spectrum/SideNav';
import { Grid, GridColumn, GridRow } from '@react/react-spectrum/Grid';
import './InsightsForGlobalAdmin.css';
import ExportOrgReport from './components/Reports/ExportOrgReport';
import FullErrorPage from '../components/FullErrorPage/FullErrorPage';
import AuditLogs from './components/Logs/AuditLogs';

interface InsightsForGlobalAdminProps extends WrappedComponentProps {}

// A type for the Insight item option. A string of this type can only be one of the Insight Option strings
type InsightOptionsType = typeof InsightsForGlobalAdmin.EXPORTS | typeof InsightsForGlobalAdmin.AUDIT;

interface InsightsForGlobalAdminState {
  // stores which item was selected in the side navigation. It can only be one of the Insights options
  // which makes sure that the selected item is always one of the given options
  selectedItem: InsightOptionsType;
}

class InsightsForGlobalAdmin extends Component<InsightsForGlobalAdminProps, InsightsForGlobalAdminState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted

  // Items shown on the page which can be selected by the user
  static readonly EXPORTS = 'exports';
  static readonly AUDIT = 'audit';
  messages = defineMessages({
    EXPORTS: {
      id: 'InsightsForGA.Report.Item.ExportReports',
      defaultMessage: 'Export Reports',
    },
    AUDIT: {
      id: 'InsightsForGA.Log.Item.AuditLogs',
      defaultMessage: 'Audit Logs',
    },
    Reports: {
      id: 'InsightsForGA.Item.Reports',
      defaultMessage: 'Reports',
    },
    Logs: {
      id: 'InsightsForGA.Item.Logs',
      defaultMessage: 'Logs',
    },
  });
  public constructor(props: InsightsForGlobalAdminProps) {
    super(props);
    this.state = {
      selectedItem: InsightsForGlobalAdmin.EXPORTS, // the first item should be selected initially
    };
  }

  /**
   * Populates the list of SideNavigation headings and items to be shown conditionally and returns the list
   * We cannot directly conditionally display sideNav items while rendering as that would throw errors
   * (<SideNav> does not like having a list of conditional items decided during rendering)
   * Instead we populate the list with only the items need to be shown, and use that for rendering later
   */
  private populateSideNavList = () => {
    const { formatMessage } = this.props.intl;
    const reportItems: React.ReactNode[] = [];
    const sideNavList: React.ReactNode[] = [];
    // check feature flags for "REPORTS" and report items and conditionally populate the sideNav list
    // same feature flag for both Insights tab and Reports, we don't have to check explicitly whether "Reports" need to be shown

    reportItems.push(
      <SideNavItem
        key={InsightsForGlobalAdmin.EXPORTS}
        value={InsightsForGlobalAdmin.EXPORTS}
        data-testid="export-reports-sidenav"
      >
        {formatMessage(this.messages.EXPORTS)}
      </SideNavItem>
    );

    sideNavList.push(
      <SideNavHeading key="reports" label={formatMessage(this.messages.Reports)}>
        {reportItems}
      </SideNavHeading>
    );

    sideNavList.push(
      <SideNavHeading key="logs" label={formatMessage(this.messages.Logs)}>
        <SideNavItem value={InsightsForGlobalAdmin.AUDIT} data-testid="audit-logs-sidenav">
          {formatMessage(this.messages.AUDIT)}
        </SideNavItem>
      </SideNavHeading>
    );

    return sideNavList;
  };

  /**
   * returns the content to be rendered and shown to the user depending on the side navigation item selected by user
   */
  private getItemContent = (): React.ReactNode => {
    switch (this.state.selectedItem) {
      case InsightsForGlobalAdmin.EXPORTS:
        return <ExportOrgReport />;
      case InsightsForGlobalAdmin.AUDIT:
        return <AuditLogs />;
      default:
        return <FullErrorPage />;
    }
  };

  render(): React.ReactNode {
    const sideNavList: React.ReactNode[] = this.populateSideNavList();
    return (
      <Grid>
        <GridRow>
          <GridColumn className="Insights_option_list">
            <SideNav
              defaultValue={this.state.selectedItem}
              onSelect={(value: string): void => {
                if (this.abortController.signal.aborted) return;
                this.setState({ selectedItem: value as InsightOptionsType });
              }}
            >
              {sideNavList}
            </SideNav>
          </GridColumn>
          <GridColumn className="grid_reports_list">{this.getItemContent()}</GridColumn>
        </GridRow>
      </Grid>
    );
  }
}

export default injectIntl(InsightsForGlobalAdmin);
