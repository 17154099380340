import * as _ from 'lodash';
import { UAdmin, UAdminData } from '../../../../../services/orgMaster/UAdmin';
import BanyanCompartmentAPI from '../../../../../providers/BanyanCompartmentAPI';
import { UOrgMaster } from '../../../../../services/orgMaster/UOrgMaster';
import { ObjectTypes } from '../../../../../services/orgMaster/OrgMaster';
import { UProductProfile } from '../../../../../services/orgMaster/UProductProfile';
import { UUserGroup, UUserGroupData } from '../../../../../services/orgMaster/UUserGroup';
import { CommandService } from '../../../../../services/Commands/CommandService';

class ProfileDialogService {
  // TODO: rename this method to "loadProfileAdmins"
  static async loadProfileAdminsV2(org: UOrgMaster, profile: UProductProfile): Promise<void> {
    if (profile.adminsLoaded) {
      return;
    }
    const currentProfile = _.find(
      org.getProfiles(),
      (eachProfile: UProductProfile): boolean => eachProfile.id === profile.id
    );
    // if current profile does not exist, then no admins to load, return
    if (!currentProfile) {
      return;
    }
    // load admins for profile
    const newAdmins: UAdminData[] = await BanyanCompartmentAPI.getAdminsForProfileV2(
      org.id,
      profile.productId,
      profile.id
    );
    // retrieve already loaded admins for profile
    const currentProfileAdmins: UAdmin[] = org.getAdminsForProfile(profile.productId, profile.id);
    // add the loaded admins that aren't already loaded for the profile to the profile
    _.forEach(newAdmins, (adminData) => {
      if (!_.find(currentProfileAdmins, (eachAdmin: UAdmin): boolean => eachAdmin.id === adminData.id)) {
        org.editAdminV2(new UAdmin(adminData));
        CommandService.applyEditForSingleElement(org, adminData.id as string, ObjectTypes.ADMIN);
      }
    });
    currentProfile.adminsLoaded = true;
  }

  static async loadProfileUserGroups(org: UOrgMaster, profile: UProductProfile): Promise<void> {
    if (profile.userGroupLoaded) {
      return;
    }
    const currentProfile = _.find(
      org.getProfiles(),
      (eachProfile: UProductProfile): boolean => eachProfile.id === profile.id
    );
    if (!currentProfile) {
      return;
    }
    const newUserGroups: UUserGroupData[] = await BanyanCompartmentAPI.getUserGroupsForProfile(
      org.id,
      profile.productId,
      profile.id
    );
    _.forEach(newUserGroups, (userGroupData) => {
      if (!_.find(org.userGroups, (eachUserGroup: UUserGroup): boolean => eachUserGroup.id === userGroupData.id)) {
        org.addUserGroup(new UUserGroup(userGroupData));
      }
    });
    currentProfile.userGroupLoaded = true;
  }
}
export default ProfileDialogService;
