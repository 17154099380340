import * as _ from 'lodash';
import React, { Key, useCallback } from 'react';
import { Flex, Item, Provider, defaultTheme } from '@adobe/react-spectrum';

import { UProductProfile } from '../../services/orgMaster/UProductProfile';
import ComboBoxSelector from './ComboBoxSelector';
import ProductSelector, { ProductSelectorProps } from './ProductSelector';
import { LocaleSettings } from '../../services/locale/LocaleSettings';

interface ProductSelectorForProfileProps extends Omit<ProductSelectorProps, 'clearSelection'> {
  dataTestId?: string; // data-testid that will be applied specifically to the ProductSelector
}

interface ProfileSelectorProps {
  productSelectorProps: ProductSelectorForProfileProps; // all props to apply to the ProductSelector (Product Combobox)
  profileSelectorProps: {
    width?: string; // how wide the Profile combobox should be
    profiles: UProductProfile[]; // profile data to display in the Profile combobox
    label: string; // label string to display for the Profile combobox
    disabled: boolean; // controls disabling the Profile combobox
    isLoading: boolean; // controls whether the wait component is displayed for the Profile combobox
    onSelect: (profileId: string) => void; // callback when a profile is selected (passes the profileId of the selected profile)
    dataTestId?: string; // data-testid that will be applied specifically to the ProfileSelector combobox
  };
  spacing?: string; // specifies the space between the Product combobox and the Profile combobox
}

/**
 * Combobox component specific for selecting profiles.
 * Handles displaying and selecting profiles.
 * This component includes both a ProductSelector combobox and a Profile combobox as a product must always be specified to determine
 * which profile to select.
 */
function ProfileSelector(props: ProfileSelectorProps): React.ReactElement {
  const root = document.getElementById('root') as HTMLElement;
  if (root) {
    root.style.isolation = 'isolate'; // This hack is necessary to get a v3 Combobox to work with a v2 Dialog
  }

  const { productSelectorProps: productProps, profileSelectorProps: profileProps, spacing, ...otherProps } = props;

  const onProfileKeySelect = useCallback((key: Key): void => profileProps.onSelect(key as string), []);

  // remove Provider once all dialogs using ProfileSelector are converted to RSV3
  return (
    <Provider theme={defaultTheme} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
      <Flex gap={spacing || 'size-500'} {...otherProps}>
        <ProductSelector clearSelection={false} {...productProps} data-testid={productProps.dataTestId} />
        <ComboBoxSelector
          clearSelection
          width={profileProps.width}
          label={profileProps.label}
          isDisabled={_.isEmpty(profileProps.profiles) || profileProps.disabled || profileProps.isLoading}
          loadingState={profileProps.isLoading ? 'loading' : undefined}
          defaultItems={profileProps.profiles}
          onSelect={onProfileKeySelect}
          data-testid={profileProps.dataTestId}
        >
          {(profile: UProductProfile) => <Item key={profile.id}>{profile.name}</Item>}
        </ComboBoxSelector>
      </Flex>
    </Provider>
  );
}
export default ProfileSelector;
