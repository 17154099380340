/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reprofileion of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect, useState } from 'react';
import Provider from '@react/react-spectrum/Provider';
import * as _ from 'lodash';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { AdminContractInfo } from '../../../services/orgMaster/UAdmin';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import './Common.css';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import Utils from '../../../services/utils/Utils';
import ContractJIL from '../../../services/orgMaster/ContractsJIL';
import ContractSelector from '../../../components/selectors/ContractSelector';

interface ContractSectionProps extends WrappedComponentProps {
  compartment: UOrgMaster;
  // Executed when contract is added or removed for contract admin.
  // (passes list of info for contracts currently associated with contract admin and indicates the contract and whether it is added or removed).
  updateContractInfoCallback: (contractInfoList: AdminContractInfo[], contractId: string, addRole: boolean) => void;
  disabled: boolean; // disables component including the button that enables and disables editing
  // List of contracts (indicated by info) associated with the contract admin.
  initialContractInfoList: AdminContractInfo[];
}

interface ContractSectionState {
  // List of contracts (indicated by info) currently associated with contract admin.
  contractInfoList: AdminContractInfo[];
  contractIdToNameMap: Map<string, string>;
  enabledForEdit: boolean; // determines whether this component is enabled for edit based on user enabling through button
  contractsLoading: boolean; // determines whether component should indicate contracts are loading
}

const messages = defineMessages({
  SelectCnt: {
    id: 'EditCompartment.Admins.CntSel.placeholder.SelectContract',
    defaultMessage: 'Select Contract',
  },
});

const ContractSection: React.FC<ContractSectionProps> = ({
  compartment,
  updateContractInfoCallback,
  disabled,
  initialContractInfoList,
  intl,
}) => {
  const createContractIdToNameMap = (contracts: ContractJIL[]) => {
    return contracts.reduce((contractMap: Map<string, string>, contract: ContractJIL) => {
      if (!contractMap.has(contract.id)) {
        contractMap.set(contract.id, contract.displayName);
      }
      return contractMap;
    }, new Map<string, string>());
  };

  const [contractSectionState, setContractSectionState] = useState<ContractSectionState>({
    contractInfoList: initialContractInfoList,
    contractIdToNameMap: createContractIdToNameMap(compartment.contracts),
    enabledForEdit: compartment.contractsLoaded,
    contractsLoading: false,
  });

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      if (!compartment.contractsLoaded && !contractSectionState.contractsLoading && !abortController.signal.aborted) {
        try {
          setContractSectionState((prevContractSectionState) => ({
            ...prevContractSectionState,
            contractsLoading: true,
          }));
          await LoadOrgDataService.loadContracts(compartment.organization.id, abortController.signal);
          if (!abortController.signal.aborted) {
            setContractSectionState((prevContractSectionState) => ({
              ...prevContractSectionState,
              enabledForEdit: true,
              contractsLoading: false,
              contractIdToNameMap: createContractIdToNameMap(compartment.contracts),
            }));
          }
        } catch (error) {
          if (!Utils.isAbortError(error)) {
            throw error;
          }
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [compartment, contractSectionState.contractsLoading]);

  const update = (contractInfoList: AdminContractInfo[], contractId: string | undefined, addRole: boolean): void => {
    if (contractId) {
      updateContractInfoCallback(contractInfoList, contractId, addRole);
    }
    setContractSectionState((prevContractSectionState) => ({ ...prevContractSectionState, contractInfoList }));
  };

  const onContractSelection = (selectedContractId: string): void => {
    const { contractInfoList } = contractSectionState;
    if (
      !_.find(contractInfoList, (contractInfo: AdminContractInfo): boolean => contractInfo.id === selectedContractId)
    ) {
      contractInfoList.push({ id: selectedContractId });
    }
    update(contractInfoList, selectedContractId, true);
  };

  const contractIdFromContractName = (contractName: string): string => {
    return (
      Array.from(contractSectionState.contractIdToNameMap.keys()).find(
        (contractId: string) => contractSectionState.contractIdToNameMap.get(contractId) === contractName
      ) ?? ''
    );
  };
  const removeContractByName = (contractName: string): void => {
    const contractId = contractIdFromContractName(contractName);
    const updatedContractInfoList = contractSectionState.contractInfoList.filter(
      (info: AdminContractInfo) => info.id !== contractId
    );
    update(updatedContractInfoList, contractId, false);
  };

  const getContractTagList = () => {
    const tags: React.ReactNode[] = _.reduce(
      contractSectionState.contractInfoList,
      (tagList: React.ReactNode[], contractInfo: AdminContractInfo): React.ReactNode[] => {
        if (contractInfo.id && contractSectionState.contractIdToNameMap.has(contractInfo.id)) {
          tagList.push(
            <Tag key={contractInfo.id}>{contractSectionState.contractIdToNameMap.get(contractInfo.id)}</Tag>
          );
        }
        return tagList;
      },
      []
    );

    return (
      <Provider locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
        <TagList
          data-testid="contract-admin-taglist"
          className="AdminRightTagList"
          disabled={disabled || !contractSectionState.enabledForEdit}
          onClose={(contractName: string | React.ReactNode) => removeContractByName(contractName as string)}
        >
          {tags}
        </TagList>
      </Provider>
    );
  };

  const availableContracts = compartment.contracts.filter(
    (contract: ContractJIL) =>
      !!contract.adminGroupId &&
      !contractSectionState.contractInfoList.find((contractInfo: AdminContractInfo) => contractInfo.id === contract.id)
  );

  return (
    <div>
      <ContractSelector
        width="size-3000"
        label={intl.formatMessage(messages.SelectCnt)}
        disabled={disabled || !contractSectionState.enabledForEdit || availableContracts.length === 0}
        isLoading={contractSectionState.contractsLoading}
        clearSelection
        contracts={availableContracts}
        onSelect={onContractSelection}
        data-testid="admin-contract-selection-combobox"
      />
      <div>{getContractTagList()}</div>
    </div>
  );
};

export default injectIntl(ContractSection);
