import React from 'react';
import CloudError from '@react/react-spectrum/Icon/CloudError';
import './FullErrorPage.css';

/**
 * Displays a page with an error message to notify the user.
 * This is the same page shown by ErrorBoundary.
 */

interface FullErrorPageProps {
  errorMessage?: string;
  children?: React.ReactNode;
}

function FullErrorPage(props: FullErrorPageProps): React.ReactElement {
  return (
    <div className="FullErrorPage">
      <div className="FullErrorPage_errorMessageContainer">
        <CloudError className="FullErrorPage_image" />
        <div className="FullErrorPage_errorHeading">{props.errorMessage}</div>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
export default FullErrorPage;
