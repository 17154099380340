import { CommandService } from './CommandService';
import OrgSelectionUtil from '../treeTableUtils/OrgSelectionUtil';
import OrgTreeCache from '../../Compartments/OrgTree/OrgTreeCache';
import { LoadOrgDataService } from '../orgMaster/LoadOrgDataService';
import OrgMapperCache from '../organization/OrgMapperCache';
import HierarchyManager from '../organization/HierarchyManager';

/**
 * Utility methods to clear edits, hierarchy or both.
 * Also prevents dependency cycle within different services.
 */
export class ClearOrgDataWrapper {
  /**
   * clear edits when:
   * 1. 'discard edits' button on Job Execution page is clicked
   * 2. when the job is submitted successfully
   */
  public static clearEdits() {
    CommandService.clearEdits();
    OrgTreeCache.clear(); // clearing edits should also trigger re render of org tree
    OrgSelectionUtil.setSelectedOrg(HierarchyManager.getOrgs());
  }

  /**
   * clear hierarchy data when:
   * 1. refresh data button is clicked on organization page or product allocation page
   * 2. root org is changed via org picker
   * 3. org data is refreshed after job completion
   */
  public static clearAllOrgs() {
    LoadOrgDataService.clear();
    OrgTreeCache.clear(); // clearing the orgs data should trigger rerender of org tree
    OrgMapperCache.clearOrgMapperData(); // clear data for org mapper when hierarchy is cleared
  }

  /**
   * When both edits and org data need to be cleared.
   * Specifically used in cleaning data after each test run.
   */
  public static clearAllOrgsAndEdits() {
    // order of operation here is important, else the tests will fail
    ClearOrgDataWrapper.clearEdits();
    ClearOrgDataWrapper.clearAllOrgs();
  }
}
