import * as _ from 'lodash';
import { UOrg } from '../orgMaster/UOrg';
import HierarchyManager from '../organization/HierarchyManager';

/**
 * Provides functionality for storing the state of expanded nodes for TreeTable components.  This allows
 * multiple TreeTable components to show the same expanded nodes.
 */

/**
 * Provides mapping between orgIds and whether they should be expanded
 */
export interface ExpandedNodes {
  [orgId: string]: boolean;
}

export class ExpandedNodesUtil {
  // local storage contains mappings of orgId to a boolean (org expanded in org tree or not)
  private static readonly EXPANDED_NODES_KEY = 'expandedNodes';
  private static readonly ROOT_ID_FOR_EXPANDED_NODES = 'rootIdForExpandedNodes'; // DO NOT use this to get root org id. This is meant specifically to invalidate ExpandedNodes in local storage. If you need to fetch root org id, call OrgMasterTree.get().id instead of loading it from local storage.

  /**
   * Updates and saves the rootId to sessionStorage
   */
  private static saveRootId(rootId: string): void {
    sessionStorage.setItem(ExpandedNodesUtil.ROOT_ID_FOR_EXPANDED_NODES, rootId);
  }

  /**
   * Loads the rootId from sessionStorage
   */
  private static loadRootId(): string {
    const data: string | null = sessionStorage.getItem(ExpandedNodesUtil.ROOT_ID_FOR_EXPANDED_NODES);
    if (data) {
      return data;
    }
    return '';
  }

  /**
   * @param expandedOrgs: nodes to set for storage in the ExpandedNodesUtil
   * Saves the current expandedNodes to sessionStorage
   */
  private static saveExpandedNodes(expandedOrgs: ExpandedNodes): void {
    sessionStorage.setItem(ExpandedNodesUtil.EXPANDED_NODES_KEY, JSON.stringify(expandedOrgs));
  }

  /**
   * Checks whether sessionStorage has any expandedNodes saved
   */
  private static hasSavedExpandedNodes(): boolean {
    return !_.isNil(sessionStorage.getItem(ExpandedNodesUtil.EXPANDED_NODES_KEY));
  }

  /**
   * Loads the expandedNodes from sessionStorage
   */
  private static loadExpandedNodes(): ExpandedNodes {
    const data: string | null = sessionStorage.getItem(ExpandedNodesUtil.EXPANDED_NODES_KEY);
    if (data) {
      return JSON.parse(data);
    }
    return {};
  }

  /**
   * @param orgList: orgTable
   * Loads the expandedNodes from sessionStorage and cleans out any deleted orgs
   */
  private static loadCleanExpandedNodes(orgList: UOrg[]): ExpandedNodes {
    const expandedNodes: ExpandedNodes = ExpandedNodesUtil.loadExpandedNodes();
    _.forEach(expandedNodes, (expandStatus: boolean, orgId: string): void => {
      if (!_.find(orgList, (entry: UOrg): boolean => entry.id === orgId)) {
        delete expandedNodes[orgId];
      }
    });
    return expandedNodes;
  }

  /**
   *
   * @param orgList: orgTable
   * set the default expanded nodes - root org and its children
   * returns the expandedNodes object, which is mapping of orgId to boolean (whether node is expanded or not)
   * This must be called after every refresh of OrgMasterTree, that is on every root org change in org picker
   */
  public static defaultExpandedNodes(): ExpandedNodes {
    const orgList = HierarchyManager.getOrgs();
    if (_.isEmpty(orgList)) throw new Error('org list should not be empty');
    const expandedNodes: ExpandedNodes = {};
    expandedNodes[orgList[0].id] = true;
    ExpandedNodesUtil.saveExpandedNodes(expandedNodes);

    return expandedNodes;
  }

  /**
   * @param expandedOrgs: expanded nodes to set for storage in the ExpandedNodesUtil
   * sets the expanded nodes
   */
  public static updateExpandedNodes(expandedOrgs: ExpandedNodes): void {
    ExpandedNodesUtil.saveExpandedNodes(expandedOrgs);
  }

  public static getExpandedNodes(): ExpandedNodes {
    const orgList = HierarchyManager.getOrgs();
    if (orgList.length === 0) {
      throw new Error('org list should not be empty');
    }
    // set to default nodes on initial render
    if (ExpandedNodesUtil.loadRootId() !== orgList[0].id || !ExpandedNodesUtil.hasSavedExpandedNodes()) {
      ExpandedNodesUtil.saveRootId(orgList[0].id);
      return ExpandedNodesUtil.defaultExpandedNodes();
    }
    return ExpandedNodesUtil.loadCleanExpandedNodes(orgList);
  }

  /**
   *
   * @param orgId: string
   * expand the org with given orgId
   */
  public static expandOrg(orgId: string): void {
    // expandOrg can only be called after getExpandedNodes is called to load the TreeTable which will clean out deleted orgs
    // this means we can call loadExpandedNodes here safetly
    const expandedNodes: ExpandedNodes = ExpandedNodesUtil.loadExpandedNodes();
    expandedNodes[orgId] = true;
    ExpandedNodesUtil.saveExpandedNodes(expandedNodes);
  }
}
