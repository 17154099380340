import React from 'react';
import { StepList, Step } from '@react/react-spectrum/StepList';
import Button from '@react/react-spectrum/Button';
import Wait from '@react/react-spectrum/Wait';
import Rule from '@react/react-spectrum/Rule';
import Heading from '@react/react-spectrum/Heading';

import './StepControl.css';
import '../../../App.css';
import Analytics from '../../../Analytics/Analytics';

interface StepControlState {
  step: number;
}

interface StepControlProps {
  children: JSX.Element[];
  pageTitle: string;
  enableNextOrReviewPendingBtn: (step: number) => boolean;
  hideNextBtn?: boolean;
  backout: () => void;
  onBackBtnClicked?: (step: number) => void; // callback to parent to notify that 'Back' btn was clicked. Parent can perform operations like clearing out selected orgs or manipulate other state variables
  onSubmit: () => void;
  validate?: () => Promise<void>; // to validate any checks. Executed on the second last step
  disallowOrgSelection: boolean; // boolean to know if user and org permission validation is in progress
}

class StepControl extends React.Component<StepControlProps, StepControlState> {
  constructor(props: StepControlProps) {
    super(props);
    this.state = {
      step: 0,
    };
  }

  navigateToNextPage = async (): Promise<void> => {
    Analytics.fireCTAEvent('next button clicked');
    if (this.state.step + 1 === this.props.children.length - 1 && this.props.validate) {
      await this.props.validate();
    }
    this.setState((prevState: StepControlState): Pick<StepControlState, never> => {
      return { step: prevState.step + 1 };
    });
  };

  navigateToPreviousPage = (): void => {
    Analytics.fireCTAEvent('back button clicked');
    this.setState(
      (prevState: StepControlState): Pick<StepControlState, never> => {
        return { step: prevState.step - 1 };
      },
      (): void => {
        // check if the parent component needs to perform some action on back btn.
        if (this.props.onBackBtnClicked) {
          this.props.onBackBtnClicked(this.state.step);
        }
        // When back button from the first step is pressed,
        // allow the user to select the actions again
        if (this.state.step < 0) this.props.backout();
      }
    );
  };

  /**
   * @returns For last step, return 'Review Pending Changes' btn,
   * for intermediate steps, return 'Next' if allowed to be displayed
   * else return empty element
   */
  displayNextOrReviewPendingBtn = (): React.ReactNode => {
    if (this.state.step === this.props.children.length - 1) {
      return (
        <Button
          variant="cta"
          disabled={!this.props.enableNextOrReviewPendingBtn(this.state.step)}
          onClick={(): void => {
            Analytics.fireCTAEvent(Analytics.REVIEW_PENDING_CHANGE);
            this.props.onSubmit();
          }}
          data-testid="review-pending-changes-org-mapper"
        >
          Review pending changes
        </Button>
      );
    }
    if (!this.props.hideNextBtn) {
      return (
        <Button
          onClick={this.navigateToNextPage}
          disabled={!this.props.enableNextOrReviewPendingBtn(this.state.step) || this.props.disallowOrgSelection}
        >
          Next
        </Button>
      );
    }
    return <></>;
  };

  render(): React.ReactNode {
    return (
      <div className="App__content">
        <div>
          <Heading className="App__header">{this.props.pageTitle}</Heading>
          <div className="StepControl__actionBtns">
            <Button disabled={this.props.disallowOrgSelection} onClick={this.navigateToPreviousPage}>
              Back
            </Button>
            {this.displayNextOrReviewPendingBtn()}
            {this.props.disallowOrgSelection && <Wait className="OrgMigration__loadIcon" size="S" />}
          </div>
          <Rule variant="small" />
        </div>
        <div className="OrgMigrationStep__list">
          <StepList selectedIndex={this.state.step} interaction="off" size="L" className="StepControl__StepList">
            {this.props.children.map((childComponent: JSX.Element): React.ReactChild => {
              return <Step key={childComponent.props.label}>{childComponent.props.label}</Step>;
            })}
          </StepList>
        </div>
        {this.props.children[this.state.step]}
      </div>
    );
  }
}

export default StepControl;
