import { defineMessages, IntlShape } from 'react-intl';
import Utils from '../Utils';

const messages = defineMessages({
  JSONFileEmpty: {
    id: 'Organizations.Import.JSONToDataModel.JSONFileEmpty',
    defaultMessage: 'JSON file is empty',
  },
  JSONParseError: {
    id: 'Organizations.Import.JSONToDataModel.JSONParseError',
    defaultMessage: 'Unable to parse the given JSON file: {error}',
  },
});

class JSONToDataModel {
  /**
   * Converts json contents from file into DataModelType[]
   * DataModelType is the type of the records in the csv file
   * for e.g. for Product Allocation, DataModelType will be ProdAllocImportData,
   *          for Admins, DataModelType will be AdminTypeImportData
   */
  public static convertToDataModelAr<DataModelType>(data: string, intl: IntlShape): DataModelType[] {
    const rows: string[] = Utils.splitByLineBreaks(data);
    const { formatMessage } = intl;
    if (rows.length === 0) {
      throw Error(formatMessage(messages.JSONFileEmpty));
    }
    try {
      return JSON.parse(data) as DataModelType[];
    } catch (error) {
      throw Error(formatMessage(messages.JSONParseError, { error: error.message }));
    }
  }

  public static convertToDataModel<DataModelType>(data: string, intl: IntlShape): DataModelType {
    const { formatMessage } = intl;
    if (!data) {
      throw Error(formatMessage(messages.JSONFileEmpty));
    }
    try {
      return JSON.parse(data) as DataModelType;
    } catch (error: any) {
      throw Error(formatMessage(messages.JSONParseError, { error: error.message }));
    }
  }
}

export default JSONToDataModel;
