/**
 * Downloading a file from the browser requires the following steps
 *  - Create a url which references a blob of data representing the contents of the file to download
 *  - Create a link element which refers to the url
 *  - Click on the link element which causes the file to download
 *  - The link element should be removed after a period of time and the url should also be destroyed
 */

class Download {
  /**
   * Creates a download link given a url to the data being downloaded and the fileName.
   * Automatically clicks the link which initiates a download.
   */
  public static download(url: string, fileName: string): void {
    // creates the download link
    const link: HTMLAnchorElement = document.createElement('a');
    link.setAttribute('download', fileName);
    link.setAttribute('href', url);
    link.setAttribute('target', '_self');
    document.body.append(link);

    // click the download link
    link.click();

    // remove the download link after a short period of time
    setTimeout((): void => {
      link.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }

  /**
   * Downloads a file given contents
   *  - data: string of the contents of the file
   *  - fileName: specifies the name for the file being downloaded
   *  - fileType: string representing the mime type of the file being downloaded
   */
  static downloadFile(data: string, fileName: string, fileType: string): void {
    const contentBlob: Blob = new Blob([data], { type: fileType });
    const url: string = URL.createObjectURL(contentBlob);
    Download.download(url, fileName);
  }
}
export default Download;
