/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Footer,
  Heading,
  InlineAlert,
  ProgressCircle,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { ReviewSelectedUserGroupsView } from './ReviewSelectedUserGroupsView';
import { useCallback, useEffect, useState } from 'react';
import { OrganizationSelector, OrganizationSelectorContentModel } from '@pandora/react-insights';
import { isEmpty } from 'lodash';
import { buildOrgHierarchy } from './buildOrgHierarchy';
import Analytics from '../../Analytics/Analytics';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { CreateUserGroupShareContentModel, useCreateUserGroupShareContent } from './ContentModels';
import { CreateUserGroupShareDialogProps } from './types';
import { useLogger } from '@pandora/react-logger-provider';
import HierarchyManager from '../../services/organization/HierarchyManager';
import FloodgateService from '../../services/floodgate/FloodgateService';
// eslint-disable-next-line import/no-named-as-default
import LoadOrgDataService from '../../services/orgMaster/LoadOrgDataService';

enum Page {
  REVIEW_USER_GROUPS,
  SELECT_ORGANIZATIONS,
}

export const CreateUserGroupShareDialog = ({
  rootOrgId,
  selectedUserGroups,
  defaultOrgSelections = [],
  onCreateUserGroupShares,
  content,
  ...others
}: CreateUserGroupShareDialogProps) => {
  const dialogContainer = useDialogContainer();
  const logger = useLogger();
  const [orgHierarchy, setOrgHierarchy] = useState<TreeNode | undefined>();
  const [showError, setShowError] = useState(false);
  const [page, setPage] = useState<Page>(Page.REVIEW_USER_GROUPS);
  const [targetOrgIds, setTargetOrgIds] = useState(defaultOrgSelections);
  const [hasOverwriteConflicts, setHasOverwriteConflicts] = useState(false);
  const contentEntry = content ?? useCreateUserGroupShareContent(targetOrgIds.length);
  const selectorContentEntry = contentEntry.search(OrganizationSelectorContentModel)[0];

  const userGroupHasOverwriteConflictsInOrgs = (userGroupName: string, orgIds: string[]): boolean => {
    for (const orgId of orgIds) {
      const uOrg = HierarchyManager.getOrg(orgId);
      const userGroupNames = uOrg?.userGroups.map((userGroup) => userGroup.name);
      const hasUserGroupName = userGroupNames?.includes(userGroupName);

      // There is an overwrite conflict if there is a user group with the same name in the target org
      if (uOrg?.userGroupsLoaded && hasUserGroupName) {
        // TODO: BANY-2409 we need to persist the list of user groups and target orgs id with overwrite conflicts so we can display them in the overwrite view
        // we should also keep track of the reason for the failure, see contextual help text in: https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3265329367
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const abortController = new AbortController();
    async function loadData() {
      try {
        setShowError(false);
        const orgData = HierarchyManager.getOrgs();
        const promises = [];

        for (const uOrg of orgData) {
          promises.push(LoadOrgDataService.loadUserGroups(uOrg.id, true, abortController.signal));
        }

        await Promise.all(promises);

        if (!abortController.signal.aborted) {
          setOrgHierarchy(buildOrgHierarchy(rootOrgId, orgData));
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          logger.error('Error loading org hierarchy', e);
          setShowError(true);
        }
      }
    }
    void loadData();
    return () => {
      abortController.abort();
    };
  }, [rootOrgId]);

  useEffect(() => {
    setHasOverwriteConflicts(false);
    if (selectedUserGroups.length > 0) {
      for (const userGroup of selectedUserGroups) {
        const userGroupName = userGroup.name;
        if (userGroupHasOverwriteConflictsInOrgs(userGroupName, targetOrgIds)) {
          setHasOverwriteConflicts(true);
        }
      }
    }
  }, [targetOrgIds]);

  const handleNext = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog switched to next page');
    setPage(Page.SELECT_ORGANIZATIONS);
  }, [dialogContainer]);

  const handlePrevious = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog switched to previous page');
    setPage(Page.REVIEW_USER_GROUPS);
  }, [dialogContainer]);

  const handleCancel = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog cancelled');
    dialogContainer.dismiss();
  }, [dialogContainer]);

  const handleCommit = useCallback(() => {
    Analytics.fireCTAEvent('create user group share dialog confirmed');
    logger.info(`Sharing ${selectedUserGroups.map((ug) => ug.id).join(', ')} with ${targetOrgIds.join(', ')}`);
    onCreateUserGroupShares(selectedUserGroups, targetOrgIds);
    dialogContainer.dismiss();
  }, [targetOrgIds, selectedUserGroups, dialogContainer]);

  const handleClose = useCallback(() => {
    // TODO: BANY-2409 For now, clicking 'Next' when there are overwrite conflicts will close the dialog
    dialogContainer.dismiss();
  }, [dialogContainer]);

  const heading =
    page === Page.REVIEW_USER_GROUPS
      ? contentEntry.get(CreateUserGroupShareContentModel.reviewHeader)
      : contentEntry.get(CreateUserGroupShareContentModel.selectHeader);

  return (
    <Dialog size={'L'} role="dialog" {...others} width={'860px'}>
      <Heading data-testid={'modal-header'}>{heading}</Heading>
      <Divider />
      <Content data-testid={'modal-content'} minHeight={'size-6000'}>
        {showError && (
          <InlineAlert variant="negative" data-testid="error-alert">
            {contentEntry.get(CreateUserGroupShareContentModel.error)}
          </InlineAlert>
        )}
        {page === Page.REVIEW_USER_GROUPS && (
          <ReviewSelectedUserGroupsView selectedUserGroups={selectedUserGroups} hideHeading={true} />
        )}
        {page === Page.SELECT_ORGANIZATIONS && (
          <>
            <p>{contentEntry.get(CreateUserGroupShareContentModel.selectDescription)}</p>
            {!orgHierarchy && (
              <ProgressCircle
                size="M"
                isIndeterminate
                top="50%"
                left="50%"
                data-testid={'modal-wait'}
                aria-label={`${contentEntry.get(CreateUserGroupShareContentModel.loadingAriaLabel)}`}
              />
            )}
            {orgHierarchy && (
              <OrganizationSelector
                defaultSelections={targetOrgIds}
                orgHierarchy={orgHierarchy!}
                onSelectionChanged={setTargetOrgIds}
                selectableDescendantsOrgId={rootOrgId}
                content={selectorContentEntry}
              />
            )}
          </>
        )}
      </Content>
      {page === Page.SELECT_ORGANIZATIONS && (
        <Footer>
          <Button variant="secondary" data-testid="previous-button" onPress={handlePrevious}>
            {contentEntry.get(CreateUserGroupShareContentModel.previousLabel)}
          </Button>
        </Footer>
      )}
      <ButtonGroup>
        <Button variant="secondary" data-testid="cancel-button" onPress={handleCancel}>
          {contentEntry.get(CreateUserGroupShareContentModel.cancelLabel)}
        </Button>
        {page === Page.REVIEW_USER_GROUPS && (
          <Button
            variant="accent"
            data-testid="next-button"
            onPress={handleNext}
            isDisabled={isEmpty(selectedUserGroups)}
          >
            {contentEntry.get(CreateUserGroupShareContentModel.nextLabel)}
          </Button>
        )}
        {page === Page.SELECT_ORGANIZATIONS &&
          !FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) && (
            <Button
              variant="accent"
              data-testid="create-share"
              onPress={handleCommit}
              isDisabled={isEmpty(targetOrgIds)}
            >
              {contentEntry.get(CreateUserGroupShareContentModel.ctaLabel)}
            </Button>
          )}
        {page === Page.SELECT_ORGANIZATIONS &&
          FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) &&
          hasOverwriteConflicts && (
            // If there are overwrite conflicts, display the 'Next button'
            // TODO: BANY-2409 For now, clicking 'Next' will close the dialog
            // TODO: BANY-2409 implement a new page with the overwrite view
            <Button
              variant="accent"
              data-testid="next-button"
              onPress={handleClose}
              isDisabled={isEmpty(selectedUserGroups)}
            >
              {contentEntry.get(CreateUserGroupShareContentModel.nextLabel)}
            </Button>
          )}
        {page === Page.SELECT_ORGANIZATIONS &&
          FloodgateService.isFeatureEnabled(FloodgateService.OVERWRITE_USER_GROUPS) &&
          !hasOverwriteConflicts && (
            // Proceed and show 'CTA label' if there are no overwrite conflicts
            <Button
              variant="accent"
              data-testid="create-share"
              onPress={handleCommit}
              isDisabled={isEmpty(targetOrgIds)}
            >
              {contentEntry.get(CreateUserGroupShareContentModel.ctaLabel)}
            </Button>
          )}
      </ButtonGroup>
    </Dialog>
  );
};
