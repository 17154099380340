import { defineMessages, MessageDescriptor } from 'react-intl';

/**
 * This would be central place for defining messages for users, generated from `Utils.ts`, which would need react-intl feature.
 * Defining messages here would help avoid react-intl related code duplication.
 * e.g. Error messages, Common headers/title, Dialogs etc.
 */
export const MESSAGES: Record<string, MessageDescriptor> = defineMessages({
  Unauthorized: {
    id: 'Utils.Error.Unauthorized',
    defaultMessage: '(Unauthorized)',
  },
  Forbidden: {
    id: 'Utils.Error.Forbidden',
    defaultMessage: '(Forbidden)',
  },
  NotFound: {
    id: 'Utils.Error.NotFound',
    defaultMessage: '(Not Found)',
  },
});
