import * as _ from 'lodash';
import React from 'react';

import './HLText.css';

/**
 * HLText provides text that can be highlighted and also indicates
 * whether it is selected/clicked or the mouse is hovering over it.
 *   - Component text shows highlighting if set by prop.
 *   - Component text shows select highlighting if selected.  This takes precedence over regular highlighting.
 *   - Component text shows surrounding box if mouse hovers over it.
 *   - Selection can be disabled by prop.  Select highlighting and hover box won't show up.  Regular highlighting can still be set.
 */

interface HLTextProps {
  className?: string;
  highlight?: boolean; // sets whether this component should be highlighted
  selectable?: boolean; // sets whether this component can be selected (this also controls whether the hover box will be shown)
  onSelect?: () => void; // callback, executed when this component is selected
  onBlur?: () => void; // callback, executed when this component is de-selected
}

interface HLTextState {
  selected: boolean; // determines whether this component shows the selected highlight
  hovered: boolean; // determines whether this component shows the hover box
}

// An instance of HLTextProps for the purpose of filtering out extra props from HLTextProps
// This map should have a key for each property in HLTextProps
// As a convention the values should match the property names
const HLTextPropsMap: { [P in keyof Required<HLTextProps>]: keyof Required<HLTextProps> } = {
  className: 'className',
  highlight: 'highlight',
  selectable: 'selectable',
  onSelect: 'onSelect',
  onBlur: 'onBlur',
};

class HLText extends React.Component<HLTextProps, HLTextState> {
  otherProps: any; // props not associated with HLText
  constructor(props: HLTextProps) {
    super(props);
    this.otherProps = _.omit(this.props, _.keys(HLTextPropsMap));
    this.state = {
      selected: false,
      hovered: false,
    };
  }

  /**
   * Callback, executed when this component is clicked.
   * Shows the selected highlight.
   */
  onSelect = (): void => {
    this.setState({ selected: true });
    if (this.props.onSelect) {
      this.props.onSelect();
    }
  };

  /**
   * Callback, executed when the mouse hovers over this component.
   * Shows the hover box.
   */
  onHover = (): void => {
    this.setState({ hovered: true });
  };

  /**
   * Callback, executed when the mouse is no longer hovering over this component.
   * Hides the hover box.
   */
  onHoverOut = (): void => {
    this.setState({ hovered: false });
  };

  /**
   * Callback, executed when the mouse clicks outside of this component.
   * Hides the hover box and hides the select highlight.
   */
  onBlur = (): void => {
    this.setState({ hovered: false, selected: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  /**
   * Builds the className for setting the styling of this component in order
   * to display selection highlighting and the hover box for this component.
   */
  private buildClassName(): string {
    let className = 'HLText';

    if (this.props.selectable) {
      className = `${className} HLText__hover`;
    }

    if (this.state.selected) {
      className = `${className} HLText__selected`;
    } else if (this.props.highlight) {
      className = `${className} HLText__highlight`;
    }

    if (this.state.hovered) {
      className = `${className} HLText__hovered`;
    }
    return className;
  }

  render(): React.ReactNode {
    return (
      <span {...this.otherProps}>
        <button
          type="button"
          className={`${this.buildClassName()} ${this.props.className}`}
          onFocus={this.props.selectable ? this.onSelect : undefined}
          onMouseOver={this.props.selectable ? this.onHover : undefined}
          onMouseOut={this.props.selectable ? this.onHoverOut : undefined}
          onBlur={this.props.selectable ? this.onBlur : undefined}
          data-testid="hltext-highlight-text"
        >
          {this.props.children}
        </button>
      </span>
    );
  }
}
export default HLText;
