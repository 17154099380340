export const EXPORT_FILE_NAME = 'product-allocation-data'; // name given to the file containing the export data from the Product Allocation page

/**
 * Mapping containing data of the supported file formats for export or import
 *  - label: Represents the name of file type for display purposes
 *  - extName: The file extension for the file type
 *  - mimeType: The mime type for the file type
 */
export const ProdAllocFiles = {
  CSV: {
    label: 'CSV',
    extName: 'csv',
    mimeType: '.csv',
  },
  JSON: {
    label: 'JSON',
    extName: 'json',
    mimeType: 'application/json',
  },
};
