import React from 'react';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Button from '@react/react-spectrum/Button';
import { FormattedMessage, IntlProvider } from 'react-intl';

import AddAdminContent from './AddAdminContent';
import { UAdmin } from '../../../services/orgMaster/UAdmin';
import '../../common.css';
import Analytics from '../../../Analytics/Analytics';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';

interface AddAdminProps {
  update: () => void;
  adminList: UAdmin[];
  selectedOrg: UOrgMaster;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function AddAdminContentIntl(props: Omit<AddAdminProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <AddAdminContent {...props} />
    </IntlProvider>
  );
}

const AddAdmin = (props: AddAdminProps): React.ReactElement => {
  const isDisabled =
    !props.selectedOrg.policiesLoaded || !props.selectedOrg.compartmentPolicy.policies.manageAdmins.value;
  return (
    <ModalTrigger>
      <span>
        <Button
          variant="primary"
          className="EditCompartment_pageButton"
          disabled={isDisabled}
          onClick={(): void => Analytics.fireCTAEvent('add admin dialog opened')}
          data-testid="admin-table-add-admin"
        >
          <FormattedMessage id="EditCompartment.Admins.button.AddAdmin" defaultMessage="Add admin" />
        </Button>
      </span>
      <AddAdminContentIntl
        {...props}
        update={props.update}
        adminList={props.adminList}
        selectedOrg={props.selectedOrg}
      />
    </ModalTrigger>
  );
};
export default AddAdmin;
