/**
 * This util suppresses verbose browser console warning messages like this:
 *   Warning: componentWillReceiveProps has been renamed, and is not recommended for use. See https://reactjs.org/link/unsafe-component-lifecycles for details.
 *
 *   * Move data fetching code or side effects to componentDidUpdate.
 *   * If you're updating state whenever props change, refactor your code to use memoization techniques or move it to static getDerivedStateFromProps. Learn more at: https://reactjs.org/link/derived-state
 *   * Rename componentWillReceiveProps to UNSAFE_componentWillReceiveProps to suppress this warning in non-strict mode. In React 18.x, only the UNSAFE_ name will work. To rename all deprecated lifecycles to their new names, you can run `npx react-codemod rename-unsafe-lifecycles` in your project source folder.
 *
 *   Please update the following components: %s
 *
 * In particular, react-spectrum rsv2 Modal and Portal trigger this error.
 * The warnings basically indicate that rvs2 may not work with React v18.
 * https://git.corp.adobe.com/React/react-spectrum-v2/issues/356
 * We can remove this code when we no longer use rsv2 or when rsv2 start calling the UNSAFE_*  methods.
 */
interface ConsoleType {
  warn: (msg: string, args?: any[]) => void;
}

const componentsWhitelist = ['Modal', 'Portal'];

export default (consoleType: ConsoleType, showIgnoreWarning: boolean = true): void => {
  const STRING_TO_FIND = 'Please update the following components:';

  const originalWarn = consoleType.warn;

  consoleType.warn = (msg, ...args): any => {
    let foundMatchingComponent = false;
    for (const argVal of args) {
      if (typeof argVal === 'string' && componentsWhitelist.includes(argVal)) {
        foundMatchingComponent = true;
      }
    }

    if (foundMatchingComponent && msg && msg.includes(STRING_TO_FIND)) return; // don't log the warning

    // otherwise go ahead and log the warning
    originalWarn.call(consoleType, msg, ...args);
  };

  // Also see setupTests.js where this output is suppressed when running unit tests.
  if (showIgnoreWarning) consoleType.warn(`Workaround: Ignoring react-unsafe for ${componentsWhitelist.join(', ')}`);
};
