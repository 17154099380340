import * as _ from 'lodash';
import React, { Component } from 'react';
import Wait from '@react/react-spectrum/Wait';
import Button from '@react/react-spectrum/Button';
import Dropdown from '@react/react-spectrum/Dropdown';
import More from '@react/react-spectrum/Icon/More';
import { Menu, MenuItem, MenuProps } from '@react/react-spectrum/Menu';
import Heading from '@react/react-spectrum/Heading';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Rule from '@react/react-spectrum/Rule';
import { FormattedMessage, injectIntl, IntlProvider, WrappedComponentProps, defineMessages } from 'react-intl';

import OrgTree from './OrgTree/OrgTree';
import './Compartments.css';
import './common.css';
import '../App.css';
import { LoadOrgDataService } from '../services/orgMaster/LoadOrgDataService';
import HeaderConsts from '../components/BanyanShell/HeaderConstants';
import AdminPermission from '../services/authentication/AdminPermission';
import Analytics from '../Analytics/Analytics';
import OrgPickerController from '../services/organization/OrgPickerController';
import ExportDialog from './ExportImport/Export/ExportDialog';
import ImportDialog from './ExportImport/Import/ImportDialog';
import { LocaleSettings } from '../services/locale/LocaleSettings';
import OrgSelectionUtil from '../services/treeTableUtils/OrgSelectionUtil';
import Utils from '../services/utils/Utils';
import AlertBanner from '../components/AlertBanner/AlertBanner';
import { MESSAGES } from './Messages';
import { UOrgMaster } from '../services/orgMaster/UOrgMaster';
import HierarchyManager from '../services/organization/HierarchyManager';
import { ClearOrgDataWrapper } from '../services/Commands/ClearOrgDataWrapper';
import OrgTreeCache from './OrgTree/OrgTreeCache';
import { CommandService } from '../services/Commands/CommandService';
import withRouter, { RouteComponentProps } from '../services/utils/withRouter';

interface CompartmentState {
  loading: boolean;
  pendingEdits: boolean | undefined; // show to enable/disable Review Pending Changes. If true, Review Pending Change is enabled
  errorMessage: string; // if not empty, ErrorBoundary is shown. The errorMessage is defined if loading of the orgs fails on componentDidMount or componentDidUpdate.
  selectedOrg: UOrgMaster | undefined;
}

interface CompartmentsProps extends WrappedComponentProps, RouteComponentProps {}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function MenuIntl(props: Omit<CompartmentsProps & MenuProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <Menu {...props}>{props.children}</Menu>
    </IntlProvider>
  );
}
const messages = defineMessages({
  UnableToLoadOrgs: {
    id: 'Organizations.Error.UnableToLoadOrgs',
    defaultMessage: 'Unable to load orgs',
  },
});

class Compartments extends Component<CompartmentsProps, CompartmentState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted
  constructor(props: CompartmentsProps) {
    super(props);
    this.state = {
      loading: false,
      pendingEdits: CommandService.anyEdits(),
      errorMessage: '',
      selectedOrg: !LoadOrgDataService.willRefresh() ? HierarchyManager.getSelectedOrg() : undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.load();
  }

  async componentDidUpdate(): Promise<void> {
    if (LoadOrgDataService.willRefresh()) {
      await this.load();
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  load = async (): Promise<void> => {
    try {
      if (this.abortController.signal.aborted) return;
      this.setState({ loading: true });
      OrgTreeCache.clear(); // clear the previous org hierarchy before loading the new one
      await LoadOrgDataService.initializeOrUpdateOrgMasterTree();
      await LoadOrgDataService.loadOrgDetails(OrgSelectionUtil.getSelectedOrgId(), this.abortController.signal);
      this.setState({
        errorMessage: '',
        loading: false,
        pendingEdits: CommandService.anyEdits(),
        selectedOrg: HierarchyManager.getSelectedOrg(),
      });
    } catch (error) {
      if (Utils.isAbortError(error)) {
        return; // noop. return now.
      }
      if (!this.state.errorMessage) {
        const { formatMessage } = this.props.intl;
        // do not set the error message state again if already set
        this.setState({ errorMessage: formatMessage(messages.UnableToLoadOrgs), loading: false });
      }
    }
  };

  /* render the compartment again when OrgMasterTree updates */
  update = async (): Promise<void> => {
    this.setState({
      pendingEdits: CommandService.anyEdits(),
      selectedOrg: HierarchyManager.getSelectedOrg(),
    });
  };

  /**
   * Redirect to the Job Execution page
   * Executes when the "Make It So" button is clicked.
   */
  onMakeItSoClicked = async (): Promise<void> => {
    Analytics.fireCTAEvent(`${Analytics.REVIEW_PENDING_CHANGE}`);
    this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
  };

  onRefreshData = async (): Promise<void> => {
    ClearOrgDataWrapper.clearAllOrgs();
    await this.load();
  };

  public render(): React.ReactNode {
    if (!_.isEmpty(this.state.errorMessage)) {
      throw new Error(this.state.errorMessage); // parent component will catch this in the ErrorBoundary
    }
    if (this.state.loading || LoadOrgDataService.willRefresh() || this.state.selectedOrg === undefined) {
      return <Wait centered size="L" />;
    }
    return (
      <div className="Compartments App__content" data-testid="compartments-page">
        <div className="Compartments__headerDiv">
          {CommandService.doesReparentEditsExist() && (
            <div>
              <AlertBanner variant="info">
                {this.props.intl.formatMessage(MESSAGES.ReparentUserNotification)}
              </AlertBanner>
            </div>
          )}
          <Heading className="App__header">
            <FormattedMessage id="Compartments.OrganizationsTitle" defaultMessage="Organizations" />
          </Heading>
          <div className="App__headerButtons">
            <Button variant="primary" onClick={this.onRefreshData} data-testid="org-refresh-buttom">
              <FormattedMessage id="Compartments.RefreshButton" defaultMessage="Refresh data" />
            </Button>
            {!AdminPermission.readOnlyMode() && (
              <Button
                variant="cta"
                onClick={this.onMakeItSoClicked}
                disabled={!this.state.pendingEdits}
                data-testid="org-review-pending-changes-button"
              >
                <FormattedMessage id="Compartments.ReviewButton" defaultMessage="Review pending changes" />
              </Button>
            )}
            {!AdminPermission.readOnlyMode() && (
              <input
                className="Compartments__fileUpload"
                type="file"
                id="compartmentFileUpload"
                accept="application/json"
                aria-label="Upload file for import"
              />
            )}
            {!AdminPermission.readOnlyMode() ? (
              <Dropdown alignRight closeOnSelect className="MoreDropdown">
                <Button
                  dropdownTrigger
                  className="Compartment__MoreButton"
                  data-testid="compartment-importexport-menu"
                  aria-label="More Options"
                >
                  <More size="S" />
                </Button>
                <MenuIntl dropdownMenu {...this.props}>
                  <ModalTrigger>
                    <MenuItem className="Compartment__MenuItem" value="Import" data-testid="compartment-import">
                      <FormattedMessage id="Compartments.menu.Import" defaultMessage="Import" />
                    </MenuItem>
                    <ImportDialog updateCompartmentCallback={this.update} {...this.props} />
                  </ModalTrigger>
                  <ModalTrigger>
                    <MenuItem className="Compartment__MenuItem" value="Export" data-testid="compartment-export">
                      <FormattedMessage id="Compartments.menu.Export" defaultMessage="Export" />
                    </MenuItem>
                    <ExportDialog orgID={OrgPickerController.getActiveOrgId()} {...this.props} />
                  </ModalTrigger>
                </MenuIntl>
              </Dropdown>
            ) : (
              <ModalTrigger>
                <Button variant="primary">
                  <FormattedMessage id="Compartments.menu.Export" defaultMessage="Export" />
                </Button>
                <ExportDialog orgID={OrgPickerController.getActiveOrgId()} {...this.props} />
              </ModalTrigger>
            )}
          </div>
          <Rule variant="small" />
        </div>
        <div className="Compartments_container">
          <div>
            <OrgTree updateCompartmentCallback={this.update} selectedOrg={this.state.selectedOrg as UOrgMaster} />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(Compartments));
