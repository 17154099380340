import * as _ from 'lodash';
import * as log from 'loglevel';
import config from '../configurations/config';
import {
  JobProgressResponse,
  JobStatusResponse,
  JobStateResponse,
  JobGetOptions,
  JobProgressOptions,
  UpdateJobOptions,
  ExportJob,
} from '../services/jobs/JobData';
import Authentication from '../services/authentication/Authentication';
import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';
import Utils from '../services/utils/Utils';
import { MESSAGES } from '../JobExecution/Messages';
import UExportReport from '../services/orgMaster/UExportReport';

const url = `${config.banyansvc.url}/jobs`;

/**
 * Provider for communicating with the BanyanJobs restful api.
 * ASR returns errors with a response body in the form:
 *   body:{message:"Application error", reason:"HTTP 404 Not Found Request id: xxx"}
 *   body:{message:"System error", reason:"HTTP 500 There was an error processing your request Request id: xxx"}
 */
class BanyanJobsApi {
  public static exportInProgress: boolean = false;
  /**
   * Retrieves status of jobs.
   * Given options are used to get a sampling of job statuses divided by page and page size for
   * the given user (email).
   * Get method call to BanyanJobs /jobs restful api.
   */
  static async getJobs(options: JobGetOptions): Promise<JobStatusResponse> {
    if (!Authentication.loggedIn()) {
      return Promise.reject(new Error('user not logged in'));
    }
    const params: URLSearchParams = new URLSearchParams();
    if (options.ownerEmail) {
      params.append('ownerEmail', options.ownerEmail);
    }
    if (Utils.isNumber(options.page)) {
      params.append('page', options.page.toString());
    }
    if (Utils.isNumber(options.pageSize)) {
      params.append('pageSize', options.pageSize.toString());
    }
    if (options.createdInOrg) {
      params.append('createdInOrg', options.createdInOrg);
    }
    if (options.includeChildren) {
      params.append('includeChildren', String(options.includeChildren));
    }
    const jobsUrl: string = `${url}?${params.toString()}`;
    const response = await fetch(jobsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetJobsApiError);
    return respBody;
  }

  /**
   * Creates a job.
   * Retrieves status of the created job.
   * Given options are used to specify the type of job to perform.
   * Post method call to BanyanJobs /jobs restful api.
   */
  static async postJobs(options: UpdateJobOptions): Promise<JobProgressResponse> {
    const response = await fetch(`${url}`, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const resp = await response.json();
    if (!response.ok) {
      log.error(`postJobs error response:`, resp);
      Utils.throwIfError(response, resp, MESSAGES.PostJobsApiError); // ${resp.message} currently lacks useful info
    }
    return resp;
  }

  /**
   * Retrieves the current status and data of a running job given a jobId.
   * Given options are used to specify which chunk of data to retrieve of the entire data associated with the job.
   * Get method call to BanyanJobs /jobs/{jobId}/progress restful api.
   */
  static async getJobProgress(jobId: string, options: JobProgressOptions): Promise<JobProgressResponse> {
    if (_.isEmpty(jobId)) {
      throw Error('jobId cannot be empty');
    }
    const params: URLSearchParams = new URLSearchParams();
    if (Utils.isNumber(options.firstIndex)) {
      params.append('first_index', options.firstIndex.toString());
    }
    if (Utils.isNumber(options.maxValues)) {
      params.append('max_values', options.maxValues.toString());
    }

    const jobsUrl: string = `${url}/${jobId}/progress?${params.toString()}`;
    const response = await fetch(jobsUrl, {
      method: 'GET',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    if (!response.ok) {
      // FYI, the error 'message' will be generic per ASR until banyansvc customizes its errors per the ASR methodology.
      // body:{message:"Application error", reason:"HTTP 404 Not Found Request id: xxx"}
      Utils.throwIfError(response, {}, MESSAGES.GetJobProgressApiError);
    }
    return await response.json();
  }

  /**
   * Cancels a job given a jobId.
   * Put method call to BanyanJobs /jobs/{jobId}/cancel.
   * @throws Error if request to cancel fails e.g. if jobId is not found
   */
  static async cancelJob(jobId: string): Promise<JobStateResponse> {
    const response = await fetch(`${url}/${jobId}/cancel`, {
      method: 'PUT',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.CancelJobApiError);
    return respBody;
  }

  /**
   * Creates a organization export job.
   * Retrieves the jobId of the created job.
   * Post method call to BanyanJobs /jobs/export-report restful api.
   */
  static async organizationExportJob(uExportReport: UExportReport): Promise<ExportJob> {
    const response = await fetch(`${url}/export-report`, {
      method: 'POST',
      body: JSON.stringify(uExportReport),
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
    });
    const resp = await response.json();
    if (!response.ok) {
      log.error(`/export-report error response:`, resp);
      Utils.throwIfError(response, resp, MESSAGES.OrganizationExportJobError);
    }
    return resp;
  }
}

export default BanyanJobsApi;
