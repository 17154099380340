import { defineMessages, useIntl } from 'react-intl';
import { OrganizationSelectorContentModel } from '@pandora/react-insights';
import { defineContents } from '@pandora/react-content-provider';

const messages = defineMessages({
  Cancel: {
    id: 'ManageUserGroupShare.Modal.Button.Cancel',
    defaultMessage: 'Cancel',
  },
  Cta: {
    id: 'ManageUserGroupShare.Modal.Button.Cta',
    defaultMessage: 'Revoke access',
  },
  Error: {
    id: 'ManageUserGroupShare.Modal.Error',
    defaultMessage: "Something went wrong. We're working on the problem. Please try again later.",
  },
  Next: {
    id: 'ManageUserGroupShare.Modal.Button.Next',
    defaultMessage: 'Revoke access...',
  },
  Previous: {
    id: 'CreateUserGroupShare.Modal.Button.Previous',
    defaultMessage: 'Previous',
  },
  PermissionsHeader: {
    id: 'ManageUserGroupShare.Modal.Permissions.Header',
    defaultMessage: 'Permission required',
  },
  PermissionsDescription: {
    id: 'ManageUserGroupShare.Modal.Permissions.Description',
    defaultMessage: 'You must be the global admin before you can revoke access.',
  },
  SelectHeader: {
    id: 'ManageUserGroupShare.Modal.SelectPane.Header',
    defaultMessage: 'Organizations with shared access to user group',
  },
  SelectDescription: {
    id: 'ManageUserGroupShare.Modal.SelectPane.Description',
    defaultMessage: 'The following organizations have shared access to the user group',
  },
  SelectUserGroupNameDescription: {
    id: 'ManageUserGroupShare.Modal.SelectPane.UserGroupName.Description',
    defaultMessage: 'User group name: ',
  },
  SelectTableLabel: {
    id: 'ManageUserGroupShare.Modal.SelectPane.TableLabel',
    defaultMessage: 'List of orgs that have shared access to this user group',
  },
  SelectTableColumnName: {
    id: 'ManageUserGroupShare.Modal.SelectPane.TableColumnName',
    defaultMessage: 'ORGANIZATION NAME',
  },
  SelectOwnedByDescription: {
    id: 'ManageUserGroupShare.Modal.SelectPane.OwnedBy.Description',
    defaultMessage: 'Owned by: ',
  },
  Resync: {
    id: 'ManageUserGroupShare.Modal.Button.Resync',
    defaultMessage: 'Re-sync groups',
  },
  RevokeTypeHeader: {
    id: 'ManageUserGroupShare.Modal.RevokeType.Header',
    defaultMessage: 'Revoke access to shared user group',
  },
  RevokeTypeRadioGroupLabel: {
    id: 'ManageUserGroupShare.Modal.RevokeType.RadioGroup.Label',
    defaultMessage: 'Selected a revoke type',
  },
  RevokeTypeDescription: {
    id: 'ManageUserGroupShare.Modal.RevokeType.Description',
    defaultMessage: 'You have two options when revoking a shared user group.',
  },
  RevokeTypeLearnMoreLink: {
    id: 'ManageUserGroupShare.Modal.RevokeType.LearnMore.Link.Text',
    defaultMessage: 'Learn more about revoking shared user groups.',
  },
  RevokeTypeHardRevokeDescription: {
    id: 'ManageUserGroupShare.Modal.RevokeType.HardRevoke.Description',
    defaultMessage: 'Revoke shared group and DELETE all {userCount} users from target org',
  },
  RevokeTypeHardRevokeDescriptionDetail1: {
    id: 'ManageUserGroupShare.Modal.RevokeType.HardRevoke.Description.Detail.1',
    defaultMessage: 'The user group will be deleted from the selected organizations',
  },
  RevokeTypeHardRevokeDescriptionDetail2: {
    id: 'ManageUserGroupShare.Modal.RevokeType.HardRevoke.Description.Detail.2',
    defaultMessage:
      'All users in the group will be removed from the organization, lose access to any assets, and all products will be unassigned',
  },
  RevokeTypeSoftRevokeDescription: {
    id: 'ManageUserGroupShare.Modal.RevokeType.SoftRevoke.Description',
    defaultMessage: 'Revoke shared group and LEAVE a copy of the group and all users',
  },
  RevokeTypeSoftRevokeDescriptionDetail1: {
    id: 'ManageUserGroupShare.Modal.RevokeType.SoftRevoke.Description.Detail.1',
    defaultMessage: 'The user group will no longer be synced to the selected organizations',
  },
  RevokeTypeSoftRevokeDescriptionDetail2: {
    id: 'ManageUserGroupShare.Modal.RevokeType.SoftRevoke.Description.Detail.2',
    defaultMessage:
      'A copy of the user group with the same name will remain in Admin Console with all users assigned to current products and can be managed by System Administrators',
  },
  Loading: {
    id: 'ManageUserGroupShare.Modal.Loading',
    defaultMessage: 'Loading content ...',
  },
  Search: {
    id: 'ManageUserGroupShare.Modal.Selector.Search',
    defaultMessage: 'Search',
  },
  SoftRevokeSummaryWord: {
    id: 'ManageUserGroupShare.Modal.RevokeType.SoftRevoke.SoftRevokeSummaryWord',
    defaultMessage: 'COPY',
  },
  HardRevokeSummaryWord: {
    id: 'ManageUserGroupShare.Modal.RevokeType.HardRevoke.HardRevokeSummaryWord',
    defaultMessage: 'DELETE',
  },
});

/**
 * Pandora style content model sourced from Banyan's existing react-intl messages.
 */
export const ManageUserGroupShareContentModel = defineContents('CreateUserGroupShare', {
  cancelLabel: {
    id: 'cancelLabel',
    placeholderContent: messages.Cancel.defaultMessage,
    type: 'string',
  },
  ctaLabel: {
    id: 'ctaLabel',
    placeholderContent: messages.Cta.defaultMessage,
    type: 'string',
  },
  error: {
    id: 'error',
    placeholderContent: messages.Error.defaultMessage,
    type: 'string',
  },
  loadingAriaLabel: {
    id: 'loadingAriaLabel',
    placeholderContent: messages.Loading.defaultMessage,
    type: 'string',
  },
  nextLabel: {
    id: 'nextLabel',
    placeholderContent: messages.Next.defaultMessage,
    type: 'string',
  },
  previousLabel: {
    id: 'previousLabel',
    placeholderContent: messages.Previous.defaultMessage,
    type: 'string',
  },
  permissionsHeader: {
    id: 'permissionsHeader',
    placeholderContent: messages.PermissionsHeader.defaultMessage,
    type: 'string',
  },
  permissionsDescription: {
    id: 'permissionsDescription',
    placeholderContent: messages.PermissionsDescription.defaultMessage,
    type: 'string',
  },
  resyncLabel: {
    id: 'resyncLabel',
    placeholderContent: messages.Resync.defaultMessage,
    type: 'string',
  },
  selectUserGroupNameDescription: {
    id: 'selectUserGroupNameDescription',
    placeholderContent: messages.SelectUserGroupNameDescription.defaultMessage,
    type: 'string',
  },
  selectOwnedByDescription: {
    id: 'selectOwnedByDescription',
    placeholderContent: messages.SelectOwnedByDescription.defaultMessage,
    type: 'string',
  },
  selectDescription: {
    id: 'selectDescription',
    placeholderContent: messages.SelectDescription.defaultMessage,
    type: 'string',
  },
  selectHeader: {
    id: 'selectHeader',
    placeholderContent: messages.SelectHeader.defaultMessage,
    type: 'string',
  },
  selectTableLabel: {
    id: 'selectTableLabel',
    placeholderContent: messages.SelectTableLabel.defaultMessage,
    type: 'string',
  },
  selectTableColumnName: {
    id: 'selectTableColumnName',
    placeholderContent: messages.SelectTableColumnName.defaultMessage,
    type: 'string',
  },
  revokeTypeRadioGroupLabel: {
    id: 'revokeTypeRadioGroupLabel',
    placeholderContent: messages.RevokeTypeRadioGroupLabel.defaultMessage,
    type: 'string',
  },
  revokeTypeHeader: {
    id: 'revokeTypeHeader',
    placeholderContent: messages.RevokeTypeHeader.defaultMessage,
    type: 'string',
  },
  revokeTypeDescription: {
    id: 'revokeTypeDescription',
    placeholderContent: messages.RevokeTypeDescription.defaultMessage,
    type: 'string',
  },
  revokeTypeLearnMoreLink: {
    id: 'revokeTypeLearnMoreLink',
    placeholderContent: messages.RevokeTypeLearnMoreLink.defaultMessage,
    type: 'string',
  },
  revokeTypeHardRevokeDescription: {
    id: 'revokeTypeHardRevokeDescription',
    placeholderContent: messages.RevokeTypeHardRevokeDescription.defaultMessage,
    type: 'string',
  },
  revokeTypeHardRevokeDescriptionDetail1: {
    id: 'revokeTypeHardRevokeDescriptionDetail1',
    placeholderContent: messages.RevokeTypeHardRevokeDescriptionDetail1.defaultMessage,
    type: 'string',
  },
  revokeTypeHardRevokeDescriptionDetail2: {
    id: 'revokeTypeHardRevokeDescriptionDetail2',
    placeholderContent: messages.RevokeTypeHardRevokeDescriptionDetail2.defaultMessage,
    type: 'string',
  },
  revokeTypeSoftRevokeDescription: {
    id: 'revokeTypeSoftRevokeDescription',
    placeholderContent: messages.RevokeTypeSoftRevokeDescription.defaultMessage,
    type: 'string',
  },
  revokeTypeSoftRevokeDescriptionDetail1: {
    id: 'revokeTypeSoftRevokeDescriptionDetail1',
    placeholderContent: messages.RevokeTypeSoftRevokeDescriptionDetail1.defaultMessage,
    type: 'string',
  },
  revokeTypeSoftRevokeDescriptionDetail2: {
    id: 'revokeTypeSoftRevokeDescriptionDetail2',
    placeholderContent: messages.RevokeTypeSoftRevokeDescriptionDetail2.defaultMessage,
    type: 'string',
  },
  search: {
    id: 'search',
    type: OrganizationSelectorContentModel,
    placeholderContent: messages.Search.defaultMessage,
  },
  softRevokeSummaryWord: {
    id: 'softRevokeSummaryWord',
    placeholderContent: messages.SoftRevokeSummaryWord.defaultMessage,
    type: 'string',
  },
  hardRevokeSummaryWord: {
    id: 'hardRevokeSummaryWord',
    placeholderContent: messages.HardRevokeSummaryWord.defaultMessage,
    type: 'string',
  },
});

/*
 * Construct the model for Insights Pandora component content strings with localization and return it
 * @see https://git.corp.adobe.com/PandoraUI/administration/blob/master/packages/react-insights/src/InsightsContentModel.ts
 * Any new strings added to the model above provided in the link, SHOULD be added here, otherwise an empty string will be rendered.
 */
export const useManageUserGroupShareContent = (userCount?: number) => {
  const { formatMessage } = useIntl();

  return ManageUserGroupShareContentModel.createEntry({
    cancelLabel: formatMessage(messages.Cancel),
    ctaLabel: formatMessage(messages.Cta),
    error: formatMessage(messages.Error),
    loadingAriaLabel: formatMessage(messages.Loading),
    nextLabel: formatMessage(messages.Next),
    previousLabel: formatMessage(messages.Previous),
    permissionsHeader: formatMessage(messages.PermissionsHeader),
    permissionsDescription: formatMessage(messages.PermissionsDescription),
    selectOwnedByDescription: formatMessage(messages.SelectOwnedByDescription),
    selectUserGroupNameDescription: formatMessage(messages.SelectUserGroupNameDescription),
    selectDescription: formatMessage(messages.SelectDescription),
    selectHeader: formatMessage(messages.SelectHeader),
    selectTableLabel: formatMessage(messages.SelectTableLabel),
    selectTableColumnName: formatMessage(messages.SelectTableColumnName),
    resyncLabel: formatMessage(messages.Resync),
    revokeTypeHeader: formatMessage(messages.RevokeTypeHeader),
    revokeTypeDescription: formatMessage(messages.RevokeTypeDescription),
    revokeTypeLearnMoreLink: formatMessage(messages.RevokeTypeLearnMoreLink),
    revokeTypeRadioGroupLabel: formatMessage(messages.RevokeTypeRadioGroupLabel),
    revokeTypeHardRevokeDescription: formatMessage(messages.RevokeTypeHardRevokeDescription, { userCount }),
    revokeTypeHardRevokeDescriptionDetail1: formatMessage(messages.RevokeTypeHardRevokeDescriptionDetail1),
    revokeTypeHardRevokeDescriptionDetail2: formatMessage(messages.RevokeTypeHardRevokeDescriptionDetail2),
    revokeTypeSoftRevokeDescription: formatMessage(messages.RevokeTypeSoftRevokeDescription),
    revokeTypeSoftRevokeDescriptionDetail1: formatMessage(messages.RevokeTypeSoftRevokeDescriptionDetail1),
    revokeTypeSoftRevokeDescriptionDetail2: formatMessage(messages.RevokeTypeSoftRevokeDescriptionDetail2),
    search: formatMessage(messages.Search),
    softRevokeSummaryWord: formatMessage(messages.SoftRevokeSummaryWord),
    hardRevokeSummaryWord: formatMessage(messages.HardRevokeSummaryWord),
  });
};
