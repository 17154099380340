import * as _ from 'lodash';
import React from 'react';
import { defineMessages } from 'react-intl';
import SelectorSection from '../../../components/selectors/SelectorSection';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource } from '../../../services/orgMaster/UResource';
import Utils from '../../../services/utils/Utils';

const MENU_SECTIONS_LOCALE = defineMessages({
  ALLOCATABLE_SECTION: {
    id: 'productAllocation.header.productSelector.allocatable',
    defaultMessage: 'Allocatable',
  },
  NON_ALLOCATABLE_SECTION: {
    id: 'productAllocation.header.productSelector.nonAllocatable',
    defaultMessage: 'Non-allocatable',
  },
});

/**
 * Interface for input data for the ProdAllocProductSelector
 */
export interface ProdAllocProductSelectorData {
  disabledProductKeys: string[]; // keys for menu items that should be disabled in the selector
  productMenuItemSections: SelectorSection<UProduct>[]; // data for the selector (grouped in sections)
}

class ProductResourceMenuUtils {
  static readonly MAX_PRODUCT_RESOURCE_MENU_LABEL_LENGTH = 45; // Max character length of a label for the product-resource select
  private static readonly VALUE_DIVIDER = ','; // Character which divides the productId and resourceCode in the value for the menu SelectOptions
  private static readonly SELECTED_PRODUCT_RESOURCE_KEY = 'selectedProductResource'; // key for sessionStorage of selected product/resource

  /**
   * Creates the resource part of the Tab label for the Product Resource Selector based on the resource.
   * For seats:       <resource name> (UNLIMITED)
   * other resources: <resource name> (<units> UNLIMITED)
   * e.g. Licenses (UNLIMITED). Adobe Stock Images (credits). Foo (UNLIMITED).
   */
  private static resourceLabel(resource: UResource): string {
    let resourceUnit = UResource.isSeatResource(resource.code) ? '' : Utils.localizedResourceUnit(resource.unit);

    if (resource.isUnlimited()) {
      resourceUnit = `${resourceUnit} ${Utils.localizedUnlimited()}`; // append UNLIMITED
      resourceUnit = resourceUnit.trimStart(); // remove leading space in case resourceUnit was empty
    }
    resourceUnit = Utils.isEmptyString(resourceUnit) ? '' : `(${resourceUnit})`; // surround with parentheses if non-empty

    return `${Utils.localizedResourceName(resource.name())} ${resourceUnit}`;
  }

  /**
   * Creates the icon for a product
   */
  private static createProductIcon(product: UProduct): React.ReactNode {
    return React.createElement('img', {
      alt: product.name,
      className: 'ProductAllocation__dropdown__icon',
      src: product.icons.svg,
    });
  }

  /**
   * Sorts an array of product by product name
   */
  private static sortProducts(products: UProduct[]): UProduct[] {
    return _.sortBy(products, (product: UProduct): string => product.name);
  }

  /**
   * Sorts an array of resources by enterpriseName or code with the seat resource always coming first
   */
  private static sortResources(resources: UResource[]): UResource[] {
    if (_.isEmpty(resources)) {
      return [];
    }
    const sortedResources: UResource[] = _.sortBy(resources, (resource: UResource): string => resource.name());
    const seatIndex: number = _.findIndex(sortedResources, (resource: UResource): boolean =>
      UResource.isSeatResource(resource.code)
    );
    const seatResource: UResource = sortedResources[seatIndex];
    sortedResources.splice(seatIndex, 1);
    sortedResources.splice(0, 0, seatResource);
    return sortedResources;
  }

  /**
   * Creates a string identifying both a product and resource pair (product id with resource code)
   */
  static createProductResourceId(product: UProduct | undefined, resource: UResource | undefined): string {
    if (product && resource) {
      return `${product.id}${ProductResourceMenuUtils.VALUE_DIVIDER}${resource.code}`;
    }
    return '';
  }

  /**
   * Retrieves the productId from the product resource id
   */
  static productIdFromProductResourceId(productResourceId: string): string {
    return productResourceId.split(ProductResourceMenuUtils.VALUE_DIVIDER)[0];
  }

  /**
   * Retrieves the resource code from the product resource id
   */
  static resourceCodeFromProductResourceId(productResourceId: string): string | undefined {
    const values: string[] = productResourceId.split(ProductResourceMenuUtils.VALUE_DIVIDER);
    if (values.length === 2) {
      return values[1];
    }
    return undefined;
  }

  /**
   * Retrieves a resource given an index.  This assumes the index is for selecting from the sorted list
   * of Resources (same order as the resource tabs)
   */
  static resourceFromTabIndex(tabIndex: number, resources: UResource[]): UResource | undefined {
    const sortResources: UResource[] = ProductResourceMenuUtils.sortResources(resources);
    return sortResources[tabIndex];
  }

  /**
   * Retrieves the index of a resource that exists within the sorted version of a list of resources.
   */
  static tabIndexFromResource(resource: UResource, resources: UResource[]): number {
    const sortResources: UResource[] = ProductResourceMenuUtils.sortResources(resources);
    const index: number = _.findIndex(
      sortResources,
      (sortedResource: UResource): boolean => sortedResource.code === resource.code
    );
    if (index < 0) {
      throw Error('Given resource is not in given list of resources');
    }
    return index;
  }

  /**
   * Given a list of products, generates the data for the ProdAllocProductSelector component
   */
  static productMenuItemSections(products: UProduct[]): ProdAllocProductSelectorData {
    const productMenuItemSections: SelectorSection<UProduct>[] = [];
    const sortedProducts = ProductResourceMenuUtils.sortProducts(products);
    // put redistributable products in the allocatable section
    const allocatableProducts = _.filter(sortedProducts, (product: UProduct): boolean => product.redistributable);
    // put non-redistributable products in the non-allocatable section
    const nonAllocatableProducts = _.filter(sortedProducts, (product: UProduct): boolean => !product.redistributable);
    let disabledProductKeys: string[] = [];
    if (!_.isEmpty(allocatableProducts)) {
      // if there are products in the allocatable section, add them to the input data for the ProdAllocProductSelector component with allocatable section name
      productMenuItemSections.push({
        sectionName: Utils.intl.formatMessage(MENU_SECTIONS_LOCALE.ALLOCATABLE_SECTION),
        sectionData: allocatableProducts,
      });
    }
    if (!_.isEmpty(nonAllocatableProducts)) {
      // if there are products in the non-allocatable section, add them to the input data for the ProductAllocProductSelector component with non-allocatable section name
      productMenuItemSections.push({
        sectionName: Utils.intl.formatMessage(MENU_SECTIONS_LOCALE.NON_ALLOCATABLE_SECTION),
        sectionData: nonAllocatableProducts,
      });
      // specify non-alloctable products as disabled menu items
      disabledProductKeys = _.map(nonAllocatableProducts, (nonAllocProduct: UProduct): string => nonAllocProduct.id);
    }
    return { disabledProductKeys, productMenuItemSections };
  }

  /**
   * Generates a list of resource names for tabs (Resource tabs) given a product
   *
   * IntlShape must be provided to allow localization of the license resource.
   */
  static resourceTabNames(product: UProduct | undefined): string[] {
    const tabNames: string[] = [];
    if (product) {
      const sortedResources: UResource[] = ProductResourceMenuUtils.sortResources(product.getQuotaResources(true));
      _.forEach(sortedResources, (resource: UResource): void => {
        tabNames.push(ProductResourceMenuUtils.resourceLabel(resource));
      });
    }
    return tabNames;
  }

  /**
   * Saves the selected product and resource to sessionStorage
   */
  static saveSelectedProductResource(product: UProduct | undefined, resource: UResource | undefined): void {
    sessionStorage.setItem(
      ProductResourceMenuUtils.SELECTED_PRODUCT_RESOURCE_KEY,
      ProductResourceMenuUtils.createProductResourceId(product, resource)
    );
  }

  /**
   * Retrieves the selected product from sessionStorage
   */
  static loadSelectedProduct(availableProducts: UProduct[]): UProduct | undefined {
    const data: string | null = sessionStorage.getItem(ProductResourceMenuUtils.SELECTED_PRODUCT_RESOURCE_KEY);
    const sortedProducts: UProduct[] = ProductResourceMenuUtils.sortProducts(availableProducts);
    if (data) {
      const productId: string = ProductResourceMenuUtils.productIdFromProductResourceId(data);
      const foundProduct: UProduct | undefined = _.find(
        sortedProducts,
        (product: UProduct): boolean => product.id === productId
      );
      if (foundProduct) {
        return ProductResourceMenuUtils.findAllocatableProduct(foundProduct, sortedProducts);
      }
    }
    return sortedProducts.length > 0
      ? ProductResourceMenuUtils.findAllocatableProduct(sortedProducts[0], sortedProducts)
      : undefined;
  }

  /**
   * Finds and returns the first allocatable product from the list of products (sorted) if the provided product
   * is not allocatable.  If the provided product is allocatable that product is returned instead
   */
  private static findAllocatableProduct(product: UProduct, allSortedProducts: UProduct[]): UProduct | undefined {
    if (!product.redistributable) {
      // Given product is not allocatable (redistributable) find the first product that is allocatable (redistributable).
      return _.find(allSortedProducts, (prod: UProduct): boolean => prod.redistributable);
    }
    return product;
  }

  /**
   * Retrieves the selected resource from sessionStorage
   */
  static loadSelectedResource(quotaResources: UResource[]): UResource | undefined {
    const data: string | null = sessionStorage.getItem(ProductResourceMenuUtils.SELECTED_PRODUCT_RESOURCE_KEY);
    const sortedResources: UResource[] = ProductResourceMenuUtils.sortResources(quotaResources);
    if (data) {
      const resourceCode: string | undefined = ProductResourceMenuUtils.resourceCodeFromProductResourceId(data);
      if (resourceCode) {
        const foundResource: UResource | undefined = _.find(
          sortedResources,
          (resource: UResource): boolean => resource.code === resourceCode
        );
        if (foundResource) {
          return foundResource;
        }
      }
    }
    return sortedResources.length > 0 ? sortedResources[0] : undefined;
  }
}
export default ProductResourceMenuUtils;
