import * as _ from 'lodash';
import { SelectOption } from '@react/react-spectrum/Select';
import OrganizationListProvider from '../../providers/OrganizationListProvider';
import { OrgAdminType } from '../authentication/IMS';
import { Org } from './Org';

class OrgMapperCache {
  private static childOrgListLoaded: boolean = false;
  private static orgMapperChildOrgList: Org[] = [];
  private static parentOrgListLoaded: boolean = false;
  private static orgMapperParentOrgList: Org[] = [];

  /**
   * Retrieves list of orgs (filtered by root and admin roles) and converts to SelectOptions
   * The root orgs are retrieved from the back-end each time this method is called to ensure an updated list (not intended for Org Picker)
   * Caller should handle errors for this method
   */
  public static async getOrgMapperChildOrgs(): Promise<SelectOption[]> {
    if (!this.childOrgListLoaded) {
      // fetch only root orgs
      const fetchRootOrgsOnly = true;
      // get all orgs where the user has Global or System admin permissions
      const orgMapperChildOrgPermissions = [OrgAdminType.COMPARTMENT_ADMIN, OrgAdminType.ORG_ADMIN];
      this.orgMapperChildOrgList = await OrganizationListProvider.getOrganizations(
        fetchRootOrgsOnly,
        orgMapperChildOrgPermissions,
        false
      );
      this.childOrgListLoaded = true;
    }
    return this.convertOrgToSelectOptions(this.orgMapperChildOrgList);
  }

  /**
   * Retrieves list of editable orgs on which the user has Global admin permissions
   * Caller should handle any errors for this method
   */
  public static async getEditableOrgs(): Promise<SelectOption[]> {
    if (!this.parentOrgListLoaded) {
      // fetch all orgs i.e. both child and root orgs
      const fetchOnlyRootOrgs = false;
      // fetch all orgs where the user has Global admin permissions
      const orgMapperParentOrgPermissions = [OrgAdminType.COMPARTMENT_ADMIN];
      this.orgMapperParentOrgList = await OrganizationListProvider.getOrganizations(
        fetchOnlyRootOrgs,
        orgMapperParentOrgPermissions,
        true
      );
      this.parentOrgListLoaded = true;
    }
    return this.convertOrgToSelectOptions(this.orgMapperParentOrgList);
  }

  private static convertOrgToSelectOptions(orgList: Org[]): SelectOption[] {
    const sortedOrgs: Org[] = _.sortBy(orgList, (org): string => org.name.toLowerCase());
    return _.map(
      sortedOrgs,
      (eachOrg: Org): SelectOption => ({
        label: eachOrg.name,
        value: eachOrg.id,
      })
    );
  }

  /**
   * ATTENTION! It is critical to clear this cache when the hierarchy is believed to be changed e.g. after a job executes or when the user refreshes data
   */
  public static clearOrgMapperData(): void {
    this.childOrgListLoaded = false;
    this.orgMapperChildOrgList = [];
    this.parentOrgListLoaded = false;
    this.orgMapperParentOrgList = [];
  }
}

export default OrgMapperCache;
