import React from 'react';
import * as _ from 'lodash';
import Button from '@react/react-spectrum/Button';

import Dialog from '@react/react-spectrum/Dialog';
// import Wait from '@react/react-spectrum/Wait';
import Progress from '@react/react-spectrum/Progress';

import { defineMessages, IntlProvider, WrappedComponentProps } from 'react-intl';

import Upload, { FileData } from '../../../services/utils/Upload';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import Import from './Import';

import { FileExtension } from './ImportEnums';
import './ImportDialog.css';
import { ChangeCount } from './ImportUtils';
import Analytics from '../../../Analytics/Analytics';

interface ImportDialogState {
  errorMessage: string;
  displaySuccessMessage: boolean;
  changeCount: ChangeCount;
  displayWait: boolean;
  closeDisabled: boolean;
  percentageCompleted: number;
}

interface ImportDialogProps extends WrappedComponentProps {
  updateCompartmentCallback: () => Promise<void>;
}

const messages = defineMessages({
  InfoMessage: {
    id: 'Organizations.Import.InfoMessage',
    defaultMessage: 'Please select a file to import. You will get a chance to review the changes.',
  },
  SelectFile: {
    id: 'Organizations.Import.SelectFile',
    defaultMessage: 'Select a file',
  },
  NextStepInfo: {
    id: 'Organizations.Import.NextStepInfo',
    defaultMessage: 'Please close this dialog to view the changes.',
  },
  SuccessMessage: {
    id: 'Organizations.Import.SuccessMessage',
    defaultMessage: 'Imported Successfully.',
  },
  TotalCreated: {
    id: 'Organizations.Import.TotalCreated',
    defaultMessage: 'Total created: ',
  },
  TotalUpdated: {
    id: 'Organizations.Import.TotalUpdated',
    defaultMessage: 'Total updated: ',
  },
  TotalDeleted: {
    id: 'Organizations.Import.TotalDeleted',
    defaultMessage: 'Total deleted: ',
  },
  CloseLabel: {
    id: 'Organizations.Import.CloseLabel',
    defaultMessage: 'Close',
  },
  ImportLabel: {
    id: 'Organizations.Import.ImportLabel',
    defaultMessage: 'Import',
  },
  ImportingLabel: {
    id: 'Organizations.Import.ImportingLabel',
    defaultMessage: 'Importing...',
  },
  ImportedLabel: {
    id: 'Organizations.Import.ImportedLabel',
    defaultMessage: 'Imported',
  },
});

function ImportDialog(props: Omit<ImportDialogProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <ImportDialogInternal {...props} />
    </IntlProvider>
  );
}

class ImportDialogInternal extends React.Component<ImportDialogProps, ImportDialogState> {
  constructor(props: ImportDialogProps) {
    super(props);
    Analytics.fireCTAEvent(`organizations import`);
    this.state = {
      errorMessage: '',
      displaySuccessMessage: false,
      changeCount: {
        createCount: 0,
        updateCount: 0,
        deleteCount: 0,
      },
      displayWait: false,
      closeDisabled: false,
      percentageCompleted: 0,
    };
  }

  getAllowedFilesMimeTypes = (): string[] => {
    return _.map(Object.keys(FileExtension), (key: string) => FileExtension[key].mimeType);
  };

  updateProgressCallback = (percentageCompleted: number): void => {
    this.setState({ percentageCompleted });
  };

  onImportClicked = async (): Promise<void> => {
    const fileData: FileData = await Upload.uploadFile(this.getAllowedFilesMimeTypes());
    // display wait till the import is in progress
    this.setState({
      displayWait: true,
      errorMessage: '',
      displaySuccessMessage: false,
      closeDisabled: true,
      percentageCompleted: 0,
    });
    try {
      const changeCount = await Import.import(fileData, this.props.intl, this.updateProgressCallback);
      this.setState({
        displaySuccessMessage: true,
        errorMessage: '',
        changeCount,
        displayWait: false,
        closeDisabled: false,
        percentageCompleted: 100,
      });
      this.props.updateCompartmentCallback();
    } catch (error) {
      this.setState({
        errorMessage: error.message,
        displaySuccessMessage: false,
        displayWait: false,
        closeDisabled: false,
        percentageCompleted: 100,
      });
    }
  };

  render(): React.ReactNode {
    const { formatMessage, formatNumber } = this.props.intl;
    return (
      <IntlProvider
        locale={LocaleSettings.getSelectedLanguageTagForProvider()}
        messages={LocaleSettings.getSelectedLocale()}
      >
        <Dialog
          confirmLabel={formatMessage(messages.CloseLabel)}
          keyboardConfirm
          {...this.props}
          role="dialog"
          title={formatMessage(messages.ImportLabel)}
          className="ImportDialog__container"
          confirmDisabled={this.state.closeDisabled}
        >
          <div>
            <div>{formatMessage(messages.InfoMessage)}</div>
            <Button
              className="ImportDialog__btn"
              variant="cta"
              onClick={this.onImportClicked}
              data-testid="import-selectfile"
              disabled={this.state.displayWait}
            >
              {formatMessage(messages.SelectFile)}
            </Button>
            {this.state.displayWait && (
              <div>
                <Progress
                  size="M"
                  className="ImportDialog__ProgressBar"
                  value={this.state.percentageCompleted}
                  label={`${formatMessage(messages.ImportedLabel)} ${formatNumber(this.state.percentageCompleted)}%`}
                  data-testid="import-waitProgress"
                />
              </div>
            )}
            {this.state.errorMessage.length > 0 && (
              <div className="ImportDialog__errorMessage" data-testid="import-error-message">
                {this.state.errorMessage}
              </div>
            )}
            {this.state.displaySuccessMessage && (
              <div>
                <div className="ImportDialog_successMessage" data-testid="import-success-message">
                  {this.state.displaySuccessMessage}
                </div>
                <div className="ImportDialog_successMessage">{formatMessage(messages.SuccessMessage)}</div>
                <div className="ImportDialog_successMessage">{`${formatMessage(messages.TotalCreated)}${
                  this.state.changeCount.createCount
                }`}</div>
                <div className="ImportDialog_successMessage">{`${formatMessage(messages.TotalDeleted)}${
                  this.state.changeCount.deleteCount
                }`}</div>
                <div className="ImportDialog_successMessage">{`${formatMessage(messages.TotalUpdated)}${
                  this.state.changeCount.updateCount
                }`}</div>
                <div className="ImportDialog_successMessage">{formatMessage(messages.NextStepInfo)}</div>
              </div>
            )}
          </div>
        </Dialog>
      </IntlProvider>
    );
  }
}

export default ImportDialog;
