/**************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 **************************************************************************/

import * as _ from 'lodash';
import { OrgReparentSharedGroupsReport } from './types';

export class OrgReparentSharedGroupsReportUtils {
  static emptyOrgReparentSharedGroupsReport(): OrgReparentSharedGroupsReport {
    return {
      invalidOrgReparentForSharedGroups: [],
      invalidOrgStandaloneReparentForSharedGroups: [],
    };
  }

  static mergeReports(
    mainReport: OrgReparentSharedGroupsReport,
    report: OrgReparentSharedGroupsReport
  ): OrgReparentSharedGroupsReport {
    const mergedReport: OrgReparentSharedGroupsReport = _.cloneDeep(mainReport);

    mergedReport.invalidOrgReparentForSharedGroups = mergedReport.invalidOrgReparentForSharedGroups.concat(
      report.invalidOrgReparentForSharedGroups
    );

    mergedReport.invalidOrgStandaloneReparentForSharedGroups =
      mergedReport.invalidOrgStandaloneReparentForSharedGroups.concat(
        report.invalidOrgStandaloneReparentForSharedGroups
      );

    return mergedReport;
  }

  static hasErrors(report: OrgReparentSharedGroupsReport): boolean {
    return (
      !_.isEmpty(report.invalidOrgReparentForSharedGroups) ||
      !_.isEmpty(report.invalidOrgStandaloneReparentForSharedGroups)
    );
  }
}
