import AuthenticatedUser from '../authentication/AuthenticatedUser';
import CommonHeaders from './CommonHeaders';
import { LocaleSettings } from '../locale/LocaleSettings';

/**
 * Generates the headers necessary for any restful api call.
 * The headers are generated in a method so that when any of the following
 * calls are exected, the headers are generated at that point instead of
 * before an AuthenticatedUser is available.
 *
 */
class GenerateBanyanSvcHeaders {
  static generateBanyanSvcHeaders(): Headers {
    const headers: Headers = CommonHeaders.generateCommonGACSvcHeaders();

    // x-user-auth is required when using @IMSUserAuthentication.
    // Now that Banyan uses @AdobeIoAuthentication, it's no longer necessary
    // https://wiki.corp.adobe.com/display/CTDxE/ASR+Input+validation
    // headers.append('x-user-auth', AuthenticatedUser.getAccessToken() as string);

    // Authorization is required by adobe.io for endpoints with "User token" validators
    headers.append('Authorization', `Bearer ${AuthenticatedUser.getAccessToken()}`);

    // Specifies locale for some banyansvc apis to return localized display data from JIL or AOS
    headers.append('Accept-Language', LocaleSettings.getSelectedLanguageTag());

    // x-user-id doesn't seem necessary.
    // headers.append('x-user-id', AuthenticatedUser.getUserEmail() as string);

    return headers;
  }

  static generateJILSvcHeaders(): Headers {
    const headers: Headers = CommonHeaders.generateCommonJILSvcHeaders();
    headers.append('Authorization', `Bearer ${AuthenticatedUser.getAccessToken()}`);
    headers.append('x-jil-feature', 'use_clam');
    return headers;
  }
}

export default GenerateBanyanSvcHeaders;
