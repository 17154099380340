/**
 * The configuration.common.js file is for configurations that are the same in
 * all environments.
 *  - If there are duplicate configuration properties between configuration.common.js and any of the env configuration files,
 *    the same configuration in the env configuration file will take precedence.
 *
 * This file does not have any configurations yet and is currently a placeholder.
 */
const config = {};
export default config;
