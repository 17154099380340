import * as log from 'loglevel';
import { SelectOption } from '@react/react-spectrum/Select';
import _ from 'lodash';
import OrganizationListProvider from '../../providers/OrganizationListProvider';
import { Org, UOrgAncestor } from './Org';
import { OrgAdminType } from '../authentication/IMS';
import UserProfileService from '../authentication/UserProfileService';
import Authentication from '../authentication/Authentication';

/**
 * Provides global access to organization data of orgs associated with the logged in user.
 * Stores the org list and provides methods to get information about the orgs.
 *
 * In order to use the OrgPickerController.
 *  - the "load" method must be executed (only once during the life of the app unless the data needs to be refreshed).
 *  - after the promise resolves for the "load" method, the other static methods for OrgPickerController can
 *    be used to get information about the list of orgs.
 */
class OrgPickerController {
  private static manageableOrgList: Org[] = []; // do not call this directly. Call getOrgDataList() to get list of orgs which would also include urlParamOrg if it exists
  private static loaded: boolean = false;
  private static activeOrgAncestorsMap: {};
  public static urlParamOrg: Org | undefined; // this is set by deep link url in DeepLinkParser. It is concatenated to manageable org list in getOrgDataList()
  public static ORGPICKER_VALUE_DELIMETER: string = '|';

  private static readonly ACTIVE_ORG_ID: string = 'activeOrg'; // local storage key for the active org

  /**
   * Reports whether the OrgPickerController has loaded with the list of orgs for the user.
   * This reports true after the "load" method has been executed and its promise has resolved.
   */
  static isLoaded(): boolean {
    return OrgPickerController.loaded;
  }

  /**
   * Returns the current active org. Three possible scenarios as follows:
   *
   * 1. If orgList is not empty and the orgId from local storage is found in orgList, return that org
   * 2. If the OrgPickerController has not loaded yet or IMS org list is empty, a value of undefined is returned
   * 3. If orgList is not empty and the orgId from local storage is not found in orgList, there could be two return values:
   * 3.1. If userId info IS available, return (if found) one of the org in orgList, such that org.userId === currentUserId
   * 3.2. If userId info is NOT avilable OR case #3.1 fails to find, return first org from orgList
   *
   * Note: For case #3, user profile switch may be required which is handled by OrgPickerController.setUserProfileForActiveOrg()
   */
  static getActiveOrg(): Org | undefined {
    const orgList = OrgPickerController.getOrgDataList();
    if (OrgPickerController.isLoaded()) {
      // find active org id stored in local storage
      const storedOrgId = localStorage.getItem(OrgPickerController.ACTIVE_ORG_ID); // OrgPickerController.load() will always set ACTIVE_ORG_ID to appropriate value based on user profile (T2E vs non-T2E) and IMS org list

      // if active org stored in local storage exists in org list, return that org
      const activeOrgInOrgList = _.find(orgList, ['id', storedOrgId]);
      if (activeOrgInOrgList) {
        return activeOrgInOrgList;
      }

      if (!_.isEmpty(orgList)) {
        // If userId info is available:
        // 1. Try to find one of org in orgList, associated with currentUserId
        // 2. If step 1 returns undefined or user id info is missing, return first element of orgList
        const currentUserId = UserProfileService.getUserId();
        const anyOrgForCurrentUserProfileInOrgList = _.find(orgList, ['userId', currentUserId]) || orgList[0];
        return anyOrgForCurrentUserProfileInOrgList;
      }
    }
    return undefined;
  }

  /**
   * Returns the id of the current active org.
   */
  static getActiveOrgId(): string | undefined {
    const activeOrg = OrgPickerController.getActiveOrg();
    return activeOrg ? activeOrg.id : undefined;
  }

  /**
   * Selects an org as an active org, given the id of the org.
   */
  static selectActiveOrg(orgId: string): void {
    localStorage.setItem(OrgPickerController.ACTIVE_ORG_ID, orgId);
  }

  static getManageableOrgs(): Org[] {
    return OrgPickerController.manageableOrgList;
  }

  /**
   * Retrieves the list of manageable orgs and the org specified in deep link (if any)
   * A value of undefined is returned if the OrgPickerController has not loaded.
   */
  static getOrgDataList(): Org[] {
    if (
      OrgPickerController.urlParamOrg === undefined ||
      _.find(OrgPickerController.manageableOrgList, ['id', OrgPickerController.urlParamOrg.id])
    ) {
      return OrgPickerController.manageableOrgList;
    }
    return _.concat(OrgPickerController.manageableOrgList, OrgPickerController.urlParamOrg);
  }

  /**
   * Loads the data for the OrgPickerController.  This method must be executed
   * in order to use the other static methods of this OrgPickerController.
   * A promise is returned which will resolve when the OrgPickerController has loaded.
   * @throws Error is call fails, caller must handle that
   */
  static async load(): Promise<void> {
    // fetch all orgs where the user has Global admin and Global viewer permissions
    const orgPickerPermissions = [OrgAdminType.COMPARTMENT_ADMIN, OrgAdminType.COMPARTMENT_VIEWER];
    // fetch all orgs i.e. both child and root orgs
    const fetchOnlyRootOrgs = false;
    const data: Org[] = await OrganizationListProvider.getOrganizations(fetchOnlyRootOrgs, orgPickerPermissions, false);
    OrgPickerController.manageableOrgList = _.sortBy(data, (org): string => org.name.toLowerCase());

    let activeOrgIdToUpdateTo;
    const storedOrgId = localStorage.getItem(OrgPickerController.ACTIVE_ORG_ID);
    // getActiveOrg() handles the cases (below) when "storedOrgId" IS defined. So do NOT update activeOrg:
    // Current user profile does NOT have Global Admin (GA) or Global Viewer (GV) access on "storedOrgId". Two cases:
    // 1. For Adobe agent user profile, OrgPickerController.load() is called twice - one before and one after DeepLinkParser's useEffect()
    //    1.1 During first OrgPickerController.load(), storedOrgId is yet to be concatenated to OrgPickerController.orgDataList
    // 2. For non-adobe-agents user profile, authenticating user (T1/T2) doesn't have GA or GV access to storedOrgId
    if (_.isEmpty(storedOrgId)) {
      // Set activeOrg if storedOrgId is undefined, such that current user profile has admin rights to load organizations page.
      activeOrgIdToUpdateTo = UserProfileService.getAnyOrgIdWithAdminRightsForCurrentProfile();
    }
    if (activeOrgIdToUpdateTo) {
      OrgPickerController.selectActiveOrg(activeOrgIdToUpdateTo);
    }
    OrgPickerController.loaded = true;
    const activeOrg = OrgPickerController.getActiveOrg();
    // Two cases when profile switch are required:
    // 1. "activeOrg" is a T2E org but current user profile is not T2E, since GAC login uses authenticating user profile (T1/T2/T3)
    // 2. "activeOrg" is inaccessible for current user profile, Refer: case#3 of OrgPickerController.getActiveOrg()
    await OrgPickerController.setUserProfileForActiveOrg(activeOrg);
    await OrgPickerController.setAncestorsForActiveOrg(activeOrg);
  }

  /**
   * Converts the list of orgs to an array of SelectOptions (for a Select component)
   * and returns the SelectOptions.
   * To facilitate T2E org switching in org picker, userId needs to be attached to orgId.
   *
   * Each of array object's "value" field will be formatted based on available information as below
   * 1. If org info has userId information available:
   *    "value" is "orgId|userId" (<orgId><pipe-symbol><userId>)
   * 2. If org info does NOT have userId information available:
   *    "value" is "orgId" (<orgId>)
   *
   */
  static getOrgLabels = (): SelectOption[] => {
    return _.map(
      OrgPickerController.getOrgDataList(),
      (eachOrg: Org): SelectOption => ({
        label: eachOrg.name,
        value: eachOrg.userId
          ? `${eachOrg.id}${OrgPickerController.ORGPICKER_VALUE_DELIMETER}${eachOrg.userId}`
          : eachOrg.id,
      })
    );
  };

  /**
   * Retrieve the ancestors of OrgPickerController.ACTIVE_ORG_ID and cache them in localStorage.
   *
   * List of ancestors retrieved from service (a.k.a Renga), contains orgs in hierarchical order with parent of OrgPickerController.ACTIVE_ORG_ID
   * being the last in the list.
   *
   * While caching data as static member of class, a map is constructed as follows:
   *  key: ordId of org (ancestor)
   *  value: organization details in form of UOrgData (fields populated: id, name and parentOrgId)
   * @param activeOrg Org assocaited with OrgPickerController.ACTIVE_ORG_ID
   * @throws Error is call fails, caller must handle that
   */
  static async setAncestorsForActiveOrg(activeOrg?: Org): Promise<void> {
    const activeOrgId = activeOrg?.id;
    if (activeOrgId) {
      try {
        const data: UOrgAncestor[] = await OrganizationListProvider.getAncestors(activeOrgId);
        OrgPickerController.activeOrgAncestorsMap = _.keyBy(data, 'id');
      } catch (err) {
        // dont blow up entire UI if ancestor call fails. Fail silently.
        // Full path names are useful when a child org is selected as root org
        // If the call to get ancestors fails, fullpathnames would not be shown
        // But that should not prevent a user from making changes or interacting with GAC
        log.error(err);
      }
    }
  }

  /**
   * Return a map of the ancestors for OrgPickerController.ACTIVE_ORG_ID from static storage if available, otherwise undefined.
   *
   * Map format:
   *  key: ordId of org (ancestor)
   *  value: organization details in form of UOrgData (fields populated: id, name and parentOrgId)
   */
  static getAncestorsForActiveOrg(): object | undefined {
    return OrgPickerController.activeOrgAncestorsMap;
  }

  // generate a deep link with active org. Endpoint must start with "/". Example: /product-allocation
  // returns the endpoint itself if no active org found
  public static getDeepLinkBasedOnActiveOrg(endpoint: string): string {
    const activeOrgId = OrgPickerController.getActiveOrgId();
    if (activeOrgId === undefined) {
      return endpoint;
    }
    return `/${activeOrgId}${endpoint}`;
  }

  /**
   * Returns the user id associated with a given "orgId", IF "orgId" is one of the manageable orgs.
   * If not, returns current user id (i.e UserProfileService.getUserId())
   *
   * Note: In prod, until ims admin cluster api is available, userId info associated with orgId would be missing.
   * In event when userId info is not available, return current userId
   *
   * @param orgId organization id
   */
  static getUserIdForOrgId(orgId: string): string | undefined {
    const manageableOrg = _.find(OrgPickerController.manageableOrgList, (org) => _.isEqual(org.id, orgId));
    if (_.isEmpty(manageableOrg) || _.isEmpty(manageableOrg?.userId)) {
      return UserProfileService.getUserId();
    }
    return manageableOrg?.userId;
  }

  /**
   * Switches user profile when:
   * 1. activeOrg is inaccessible for current user profile AND
   * 2. user id info is available (ORGS_OF_ALL_PROFILES_IN_ORG_PICKER feature flag is enabled)
   *
   * @param activeOrg Org associated with OrgPickerController.ACTIVE_ORG_ID
   */
  static async setUserProfileForActiveOrg(activeOrg?: Org): Promise<void> {
    // 1. "userId" is set only if activeOrg exist and user id info is available
    // 2. For adobe agents accessing org with no admin access (via DeepLink), user id info is missing since "/{orgId}/minimal" from banyansvc uses renga and not ims
    const userId = activeOrg?.userId;
    // "userId" could be of a T2E user profile, different from current user profile (T1/T2/T3/T2E). Hence, profile switch
    if (userId && !_.isEqual(userId, UserProfileService.getUserId())) {
      await Authentication.switchProfile(userId);
    }
  }
}
export default OrgPickerController;
