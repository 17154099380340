import _ from 'lodash';
import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Textfield from '@react/react-spectrum/Textfield';
import Textarea from '@react/react-spectrum/Textarea';
import Well from '@react/react-spectrum/Well';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import AlertBanner from '../../../../components/AlertBanner/AlertBanner';
import Analytics from '../../../../Analytics/Analytics';
import TemplatePolicy, { LockActionEnum } from '../../../../models/TemplatePolicy';
import { OrganizationTemplateData } from '../../../../models/OrganizationTemplate';
import { OrganizationPolicy, UCompartmentPolicies } from '../../../../services/orgMaster/UCompartmentPolicy';
import TemplatePolicyList from '../TemplatePolicyList/TemplatePolicyList';
import CheckedPoliciesMap from '../CheckedPoliciesMap';
import TemplatePolicyMap from '../TemplatePolicyMap';
import { MESSAGES } from '../../../Messages';
import '../../../common.css';
import './CreateTemplate.css';

interface CreateTemplateProps extends WrappedComponentProps {
  createTemplate: (templateBody: OrganizationTemplateData) => Promise<string | undefined>;
}

interface CreateTemplateState {
  templateName: string;
  templateDescription: string;
  templatePolicyMap: TemplatePolicyMap;
  checkedPoliciesMap: CheckedPoliciesMap;
  isLoading: boolean;
  errorMsg?: string;
}

const messages = defineMessages({
  CreateTemplateTitle: {
    id: 'EditCompartment.Templates.Create.Title',
    defaultMessage: 'Create a policy template',
  },
  Save: {
    id: 'EditCompartment.Templates.Create.Save',
    defaultMessage: 'Save',
  },
  Cancel: {
    id: 'EditCompartment.Templates.Create.Cancel',
    defaultMessage: 'Cancel',
  },
  NameLabel: {
    id: 'EditCompartment.Templates.Create.NameLabel',
    defaultMessage: 'Policy Template Name',
  },
  NamePlaceholder: {
    id: 'EditCompartment.Templates.Create.NamePlaceholder',
    defaultMessage: '1-100 characters',
  },
  DescLabel: {
    id: 'EditCompartment.Templates.Create.DescriptionLabel',
    defaultMessage: 'Description',
  },
  DescPlaceholder: {
    id: 'EditCompartment.Templates.Create.DescPlaceholder',
    defaultMessage: 'Descriptive text here',
  },
});

class CreateTemplate extends React.Component<CreateTemplateProps, CreateTemplateState> {
  constructor(props: any) {
    super(props);
    Analytics.fireCTAEvent(`Create template dialog opened`);
    const defaultTemplatePolicyMap = this.getDefaultTemplatePolicyMap();
    this.state = {
      templateName: '',
      templateDescription: '', // field is used.
      templatePolicyMap: defaultTemplatePolicyMap,
      checkedPoliciesMap: Object.keys(defaultTemplatePolicyMap).reduce(
        (policyNames, policyName) => ({ ...policyNames, [policyName]: false }),
        {}
      ),
      isLoading: false,
      errorMsg: undefined,
    };
  }

  private isTemplateInValid = (): boolean => {
    if (
      _.isEmpty(this.state.templateName.trim()) ||
      this.state.templateName.trim.length > 100 ||
      this.state.isLoading
    ) {
      return true;
    }
    if (
      _.isEmpty(
        Object.keys(this.state.checkedPoliciesMap).filter(
          (policyName: string): boolean => this.state.checkedPoliciesMap[policyName]
        )
      )
    ) {
      return true;
    }
    return false;
  };

  private changeTemplateName = (templateName: string): void => {
    this.setState({
      templateName,
    });
  };

  private changeTemplateDescription = (templateDescription: string): void => {
    this.setState({ templateDescription });
  };

  private getDefaultTemplatePolicyMap = (): TemplatePolicyMap => {
    const uCompartmentPolicies: UCompartmentPolicies = new UCompartmentPolicies();
    uCompartmentPolicies.setDefaultPolicies();
    const templatePolicyMap: TemplatePolicyMap = {};
    if (uCompartmentPolicies.policies) {
      Object.values(uCompartmentPolicies.policies).forEach((organizationPolicy: OrganizationPolicy) => {
        const templatePolicy: TemplatePolicy = new TemplatePolicy();
        templatePolicy.name = organizationPolicy.name || '';
        templatePolicy.type = organizationPolicy.type || '';
        templatePolicy.value = organizationPolicy.defaultValue || true;
        templatePolicy.lockAction = LockActionEnum.AS_IS;
        templatePolicyMap[templatePolicy.name] = templatePolicy;
      });
    }
    return templatePolicyMap;
  };

  private createTemplate = async (): Promise<boolean> => {
    Analytics.fireCTAEvent(`Create template dialog confirm clicked`);
    const { templateName, templateDescription, checkedPoliciesMap, templatePolicyMap } = this.state;
    const { formatMessage } = this.props.intl;
    const templateBody: OrganizationTemplateData = {
      name: templateName,
      description: templateDescription,
      policies: [],
    };
    Object.keys(checkedPoliciesMap)
      .filter((policyName: string): boolean => checkedPoliciesMap[policyName])
      .forEach((policyName: string): void => {
        templateBody.policies.push(templatePolicyMap[policyName]);
      });
    this.setState({ errorMsg: undefined, isLoading: true });
    let isSuccess = true;
    await this.props.createTemplate(templateBody).then((data) => {
      if (!_.isEmpty(data)) {
        this.setState({ errorMsg: `${formatMessage(MESSAGES.TemplatePOSTApiError)} : ${data}` });
        isSuccess = false;
      }
    });
    this.setState({ isLoading: false });
    return isSuccess;
  };

  updatePolicyValue = (policyName: string | undefined, selection: boolean): void => {
    if (policyName) {
      this.setState((state) => {
        const policyMap: TemplatePolicyMap = { ...state.templatePolicyMap }; // PL: Why is this done this way: flattening and then unflattening? Is this a copy and type conversion?
        const policyToUpdate: TemplatePolicy = { ...policyMap[`${policyName}`] };
        policyToUpdate.value = selection;
        policyMap[`${policyName}`] = policyToUpdate;
        return {
          templatePolicyMap: policyMap,
        };
      });
    }
  };

  updatePolicyLockAction = (policyName: string | undefined, policyLockAction: string): void => {
    if (policyName) {
      this.setState((state) => {
        const policyMap: TemplatePolicyMap = { ...state.templatePolicyMap };
        const policyToUpdate: TemplatePolicy = { ...policyMap[`${policyName}`] };
        if (policyLockAction === LockActionEnum.LOCK) {
          policyToUpdate.lockAction = LockActionEnum.LOCK;
        } else if (policyLockAction === LockActionEnum.UNLOCK) {
          policyToUpdate.lockAction = LockActionEnum.UNLOCK;
        } else {
          policyToUpdate.lockAction = LockActionEnum.AS_IS;
        }
        policyMap[`${policyName}`] = policyToUpdate;
        return {
          templatePolicyMap: policyMap,
        };
      });
    }
  };

  checkUncheckPolicy = (policyName: string): void => {
    if (policyName) {
      this.setState((state) => {
        const checkedPoliciesMap: CheckedPoliciesMap = { ...state.checkedPoliciesMap };
        checkedPoliciesMap[policyName] = !checkedPoliciesMap[policyName];
        return {
          checkedPoliciesMap,
        };
      });
    }
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { isLoading, errorMsg } = this.state;
    return (
      <Dialog
        {...this.props} // required as onClose() is provided by ModalTrigger
        title={formatMessage(messages.CreateTemplateTitle)}
        confirmLabel={formatMessage(messages.Save)}
        cancelLabel={formatMessage(messages.Cancel)}
        role="dialog"
        onCancel={(): void => Analytics.fireCTAEvent('Create template dialog canceled')}
        onConfirm={(): Promise<boolean> => this.createTemplate()}
        confirmDisabled={this.isTemplateInValid()}
      >
        <div className="CreateTemplate__headerMessage">
          <FormattedMessage
            id="EditCompartment.Templates.Create.Instructions"
            defaultMessage="Name the policy template and select the policies (at least one) to include in this template. Then set values
          for the policies you selected."
          />
        </div>
        <React.Fragment>
          {errorMsg && (
            <div>
              <AlertBanner variant="error" closeable onClose={() => this.setState({ errorMsg: undefined })}>
                {errorMsg}
              </AlertBanner>
            </div>
          )}
          <div className="CreateTemplate__fieldGroup">
            <div className="CreateTemplate__fieldContainer">
              <FieldLabel
                label={formatMessage(messages.NameLabel)}
                className="CreateTemplate__fieldLabel"
                necessity="required"
                labelFor="create-template-name-textfield"
              />
              <Textfield
                id="create-template-name-textfield"
                placeholder={formatMessage(messages.NamePlaceholder)}
                onChange={(templateName): void => this.changeTemplateName(templateName)}
                disabled={isLoading}
                autoFocus
              />
            </div>
            <div className="CreateTemplate__fieldContainer">
              <FieldLabel
                label={formatMessage(messages.DescLabel)}
                className="CreateTemplate__fieldLabel"
                labelFor="create-template-desc-textarea"
              />
              <Textarea
                id="create-template-desc-textarea"
                className="CreateTemplate__text-area"
                placeholder={formatMessage(messages.DescPlaceholder)}
                onChange={(templateDescription): void => this.changeTemplateDescription(templateDescription)}
                disabled={isLoading}
              />
            </div>
          </div>
          <Well className="CreateTemplate__policyWell">
            {!isLoading ? (
              <TemplatePolicyList
                templatePolicyMap={this.state.templatePolicyMap}
                isEditable
                updatePolicyValue={this.updatePolicyValue}
                updatePolicyLockAction={this.updatePolicyLockAction}
                checkUncheckPolicy={this.checkUncheckPolicy}
                checkedPoliciesMap={this.state.checkedPoliciesMap}
              />
            ) : (
              <Wait className="Load_wait" />
            )}
          </Well>
        </React.Fragment>
      </Dialog>
    );
  }
}

export default injectIntl(CreateTemplate);
