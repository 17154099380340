import * as _ from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import { parse, ParseResult, ParseError } from 'papaparse';
import Utils from '../../../services/utils/Utils';

import { CAP_UNLIMITED, ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from '../../../services/orgMaster/OrgMaster';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource } from '../../../services/orgMaster/UResource';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';

import ProductPermission from '../permissions/ProductPermission';
import ImportOperations from '../../../services/utils/ConvertToDataModel/ImportOperations';
import ImportOperationsUtils from '../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import HierarchyManager from '../../../services/organization/HierarchyManager';
import { CommandService } from '../../../services/Commands/CommandService';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';

const localeMessages = defineMessages({
  missingRequiredFields: {
    id: 'productAllocation.import.errors.missingRequiredFields',
    defaultMessage: 'For record #{recordNum}, Missing the following required fields: {fieldNames}',
  },
  typeMismatch: {
    id: 'productAllocation.import.errors.typeMismatch',
    defaultMessage: '{fieldName} must be type {typeName}',
  },
  multipleTypeMismatch: {
    id: 'productAllocation.import.errors.multipleTypeMismatch',
    defaultMessage:
      'For record #{recordNum}, there are the following errors regarding incorrect types for fields: {errors}',
  },
  invalidOperation: {
    id: 'productAllocation.import.errors.invalidOperation',
    defaultMessage: 'For record #{recordNum}, operation is invalid',
  },
  grantToUnlimited: {
    id: 'productAllocation.import.errors.grantToUnlimited',
    defaultMessage: 'Grant cannot be changed to UNLIMITED',
  },
  grantEditUnlimited: {
    id: 'productAllocation.import.errors.grantEditUnlimited',
    defaultMessage: 'Grant cannot be a string other than UNLIMITED',
  },
  grantNegative: {
    id: 'productAllocation.import.errors.grantNegative',
    defaultMessage: 'Grant cannot be negative',
  },
  grantInteger: {
    id: 'productAllocation.import.errors.grantInteger',
    defaultMessage: 'Grant must be an integer',
  },
  multipleGrantErrors: {
    id: 'productAllocation.import.errors.multipeGrantErrors',
    defaultMessage: 'For record #{recordNum}, there are the following errors with the grant value: {errors}',
  },
  multipleProductPolicyEdit: {
    id: 'productAllocation.import.errors.multipleProductPolicyEdit',
    defaultMessage: 'For record #{recordNum}, multiple edit attempts on product on product policy targeting resources',
  },
  grantUpdateUnlimited: {
    id: 'productAllocation.import.errors.grantUpdateUnlimited',
    defaultMessage: 'For record #{recordNum}, An unlimited Grant value cannot be updated',
  },
  editPermission: {
    id: 'productAllocation.import.errors.editPermission',
    defaultMessage:
      'For record #{recordNum}, You do not have permission to edit values for product {productId} on org {orgId}',
  },
  addPermission: {
    id: 'productAllocation.import.errors.addPermission',
    defaultMessage: 'For record #{recordNum}, You do not have permission create product {productId} on org {orgId}',
  },
  deletePermission: {
    id: 'productAllocation.import.errors.deletePermission',
    defaultMessage: 'For record #{recordNum}, You do not have permission to delete product {productId} on org {orgId}',
  },
  readOnlyOrg: {
    id: 'productAllocation.import.errors.readOnlyOrg',
    defaultMessage: 'For record #{recordNum}, products cannot be created, updated, or deleted for read-only org',
  },
  externalOrg: {
    id: 'productAllocation.import.errors.externalOrg',
    defaultMessage: 'For record #{recordNum}, Org {orgId} does not exist in your hierarchy',
  },
  externalProduct: {
    id: 'productAllocation.import.errors.externalProduct',
    defaultMessage: 'For record #{recordNum}, Product {licenseId} does not exist on Org {orgId}',
  },
  missingResourceCode: {
    id: 'productAllocation.import.errors.missingResourceCode',
    defaultMessage: 'For record #{recordNum}, resource code not provided when editing grant',
  },
  externalResource: {
    id: 'productAllocation.import.errors.externalResource',
    defaultMessage:
      'For record #{recordNum}, Resource {resourceId} does not exist on Product {licenseId} for Org {orgId}',
  },
  addProductMissingResourceCode: {
    id: 'productAllocation.import.errors.addProductMissingResourceCode',
    defaultMessage:
      'For record #{recordNum}, Resource for resource id does not exist for newly created product even though grant value is being specified',
  },
  invalidSourceProduct: {
    id: 'productAllocation.import.errors.invalidSourceProduct',
    defaultMessage: 'For record #{recordNum}, no source product exists for new product',
  },
  invalidAncestorSourceProduct: {
    id: 'productAllocation.import.errors.invalidAncestorSourceProduct',
    defaultMessage:
      'For record #{recordNum}, source license id does not refer to product for some parent of new product',
  },
  sourceProductIdRequired: {
    id: 'productAllocation.import.errors.sourceProductIdRequired',
    defaultMessage: 'For record #{recordNum}, Source License Id is required for ADD operation',
  },
  invalidOrgId: {
    id: 'productAllocation.import.errors.invalidOrgId',
    defaultMessage: 'For record #{recordNum}, orgId does not refer to any org in hierarchy',
  },
  addProductAlreadyExists: {
    id: 'productAllocation.import.errors.addProductAlreadyExists',
    defaultMessage: 'For record #{recordNum}, product already exists',
  },
  addProductIsNotDirectAllocation: {
    id: 'productAllocation.import.errors.addProductIsNotDirectAllocation',
    defaultMessage: 'For record #{recordNum}, product must be allocated from the direct parent org',
  },
  productIdMismatch: {
    id: 'productAllocation.import.errors.productIdMismatch',
    defaultMessage:
      'For record #{recordNum}, product is being created but licenseId  does not seem to match that product',
  },
  deleteProductDoesNotExist: {
    id: 'productAllocation.import.errors.deleteProductDoesNotExist',
    defaultMessage: 'For record #{recordNum}, product is being deleted but it does not exist',
  },
  deleteProductNoZeroGrant: {
    id: 'productAllocation.import.errors.deleteProductNoZeroGrant',
    defaultMessage: 'For record #{recordNum}, product cannot be deleted with 0 grant values on resources: {resCodes}',
  },
  deleteNoChildren: {
    id: 'productAllocation.import.errors.deleteNoChildren',
    defaultMessage: 'For record #{recordNum}, product cannot be deleted with allocated children products: {childIds}',
  },
  invalidJSON: {
    id: 'productAllocation.import.errors.invalidJSON',
    defaultMessage: 'Invalid JSON: {errorMessage}',
  },
  jsonArrayRequired: {
    id: 'productAllocation.import.errors.jsonArrayRequired',
    defaultMessage: 'JSON data must be contained within an array',
  },
  csvEmptyHeader: {
    id: 'productAllocation.import.errors.csvEmptyHeader',
    defaultMessage: 'The header row has two consecutive commas (,,) which is not allowed.',
  },
  csvMissingHeaders: {
    id: 'productAllocation.import.errors.csvMissingHeaders',
    defaultMessage: 'The following headers are missing: {headers}',
  },
  csvHeaderNotString: {
    id: 'productAllocation.import.errors.csvHeaderNotString',
    defaultMessage: 'Header {header} is not a valid string value',
  },
  csvHeaderRowMismatch: {
    id: 'productAllocation.import.errors.csvHeaderRowMismatch',
    defaultMessage:
      'For record #{recordNum}, Mismatch between the number of headers and the number of values for this record',
  },
  csvEmpty: {
    id: 'productAllocation.import.errors.csvEmpty',
    defaultMessage: 'CSV is empty',
  },
  csvMissing: {
    id: 'productAllocation.import.errors.csvMissing',
    defaultMessage: 'CSV is missing data or the header',
  },
  noUpdateOperations: {
    id: 'productAllocation.import.warnings.noUpdateOperations',
    defaultMessage: 'No changes made due to no operations given (UPDATE, CREATE, DELETE)',
  },
  noChanges: {
    id: 'productAllocation.import.info.noChanges',
    defaultMessage:
      'Import completed - no changes to existing values were detected for the following records: {recordNums}',
  },
  success: {
    id: 'productAllocation.import.info.success',
    defaultMessage: 'Import succeeded',
  },
  invalidCSV: {
    id: 'productAllocation.import.errors.invalidCSV',
    defaultMessage: 'Invalid CSV: {errorMessage}',
  },
});

/**
 * ImportStatus returned from import.
 * This object can be used to report extra info even with the import succeeding
 * This object does not return errors as that is handled by throwing errors
 */
export interface ProdAllocImportStatus {
  // status: 'info' | 'warning' | 'error'; // this field could be added in the future
  message: string;
}

/**
 * Global Admin Permissions are enforced with the following criteria
 *  - Org, products, and resources not in the hierarchy cannot be updated
 *  - Values cannot be updated on orgs where the user isn't a GlobalAdmin
 */

/**
 * Object representation of the data that can be imported per org, resource, and product.
 * This is also referred to as the data object.
 * This data object will be converted from JSON or CSV.
 * Note: optional fields means they can be omitted from the JSON or CSV.
 * Note: This interface provides compile time type checking, while IMPORT_TYPE_MAP and REQUIRED_IMPORT_PROPS are for runtime type checking
 * Note: If any changes are made to this interface, the equivalent changes should be made to the IMPORT_TYPE_MAP
 */
interface ProdAllocImportData {
  licenseId: string;
  sourceLicenseId?: string;
  resourceId?: string;
  orgId: string;
  grantedQuantity?: number | string;
  allowOverAllocation?: boolean;
  allowOverUse?: boolean;
  operation?: ImportOperations;
}

type ProdAllocImportDataProp = keyof ProdAllocImportData; // All strings representing the property names in ProdAllocImportData

// Typing for each element in the IMPORT_TYPE_MAP
interface ImportTypeInfo {
  propName: ProdAllocImportDataProp;
  typeString: string | string[];
  optional: boolean;
}

// Declares typing information for the properties of ProdAllocImportData for runtime checking.
// If ProdAllocImportData is changed, this mapping must be updated as well.
const IMPORT_TYPE_MAP: ImportTypeInfo[] = [
  {
    propName: 'licenseId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'sourceLicenseId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'resourceId',
    typeString: 'string',
    optional: true,
  },
  {
    propName: 'orgId',
    typeString: 'string',
    optional: false,
  },
  {
    propName: 'grantedQuantity',
    typeString: ['number', 'string'],
    optional: true,
  },
  {
    propName: 'allowOverAllocation',
    typeString: 'boolean',
    optional: true,
  },
  {
    propName: 'allowOverUse',
    typeString: 'boolean',
    optional: true,
  },
  {
    propName: 'operation',
    typeString: 'string',
    optional: true,
  },
];
// Array of strings representing the names of required properties on ProdAllocImportData
// Used for runtime checking
const REQUIRED_IMPORT_PROPS: ProdAllocImportDataProp[] = _.map(
  _.filter(IMPORT_TYPE_MAP, (typeInfo: ImportTypeInfo): boolean => !typeInfo.optional),
  (typeInfo: ImportTypeInfo): ProdAllocImportDataProp => typeInfo.propName
);

type ImportOperationsKey = keyof typeof ImportOperations;
const ImportOperationsValues: string[] = _.map(
  _.keys(ImportOperations) as ImportOperationsKey[],
  (key: ImportOperationsKey): string => ImportOperations[key]
);

/**
 * Provides functionality for error messaging
 */
class ProdAllocImportError {
  /**
   * Converts an index (starts at 0) to a record number (starts at 1)
   */
  static indexToRecordNum(index: number): number {
    return index + 1;
  }

  /**
   * Localizes an error message and handles record numbers converted from index
   */
  static localizeRecordError(message: MessageDescriptor, index?: number, args?: Record<string, PrimitiveType>): string {
    let allArgs: Record<string, PrimitiveType> = {};
    if (!_.isNil(index)) {
      const recordNum: number = ProdAllocImportError.indexToRecordNum(index);
      allArgs = _.assign(allArgs, { recordNum });
    }
    if (!_.isNil(args)) {
      allArgs = _.assign(allArgs, args);
    }
    return Utils.getLocalizedMessage(message, allArgs);
  }

  /**
   * Localizes an error message without a record number
   */
  static localizeError(message: MessageDescriptor, args?: Record<string, PrimitiveType>): string {
    return ProdAllocImportError.localizeRecordError(message, undefined, args);
  }
}

/**
 * Validation functionality used by all file types (validates data objects)
 * The following checks specify the criteria for a valid data object
 *  - The data object has all required fields (no undefined values for required fields)
 *  - The data object fields are all the correct type
 *  - The grant field on the data object is a valid grant value (positive integer)
 *    - UNLIMITED Grant value cannot changed (this is checked during assignment rather than at validation phase)
 */
class ProdAllocImportValidate {
  /**
   * Throws an error if the given data object does not contain values for the required props on ProdAllocImportData
   * The given index refers to the given data object's location from an array.  This is to provide more details for errors.
   */
  static validateRequiredFields(importData: ProdAllocImportData, index: number): void {
    const missingPropertyNames: string[] = [];

    _.forEach(REQUIRED_IMPORT_PROPS, (prop: ProdAllocImportDataProp): void => {
      if (importData[prop] === undefined) {
        missingPropertyNames.push(prop);
      }
    });

    if (missingPropertyNames.length > 0) {
      throw Error(
        ProdAllocImportError.localizeRecordError(localeMessages.missingRequiredFields, index, {
          fieldNames: missingPropertyNames.join(','),
        })
      );
    }
  }

  /**
   * Checks whether the type of a given value matches the given type name or any of the given type names
   */
  static validateType(value: any, type: string | string[]): boolean {
    const types: string[] = typeof type === 'string' ? [type] : type;
    let typeMatches = false;
    for (let i = 0; i < types.length; i++) {
      if (typeof value === types[i]) {
        typeMatches = true;
        break;
      }
    }
    return typeMatches;
  }

  /**
   * Throws an error if the given data object contains values that don't match the correct type specified by ProdAllocImportData.
   * The given index refers to the given data object's location from an array.  This is to provide more details for errors.
   * The given orgList is only
   */
  static validateFieldTypes(importData: ProdAllocImportData, index: number): void {
    const errorMessages: string[] = [];

    _.forEach(IMPORT_TYPE_MAP, (typeInfo: ImportTypeInfo): void => {
      const wrongType: boolean = _.isNil(importData[typeInfo.propName])
        ? !typeInfo.optional
        : !ProdAllocImportValidate.validateType(importData[typeInfo.propName], typeInfo.typeString);
      if (wrongType) {
        errorMessages.push(
          ProdAllocImportError.localizeError(localeMessages.typeMismatch, {
            fieldName: typeInfo.propName,
            typeName: typeof typeInfo.typeString === 'string' ? typeInfo.typeString : typeInfo.typeString.join(', '),
          })
        );
      }
    });

    if (errorMessages.length > 0) {
      throw Error(
        ProdAllocImportError.localizeRecordError(localeMessages.multipleTypeMismatch, index, {
          errors: errorMessages.join(', '),
        })
      );
    }
  }

  static validateOperationField(importData: ProdAllocImportData, index: number): void {
    if (
      !_.isEmpty(importData.operation) &&
      !_.find(
        ImportOperationsValues,
        (operation: ImportOperations): boolean => operation === _.toUpper(importData.operation)
      )
    ) {
      throw Error(ProdAllocImportError.localizeRecordError(localeMessages.invalidOperation, index));
    }
  }

  /**
   * Throws an error if the grant value on the given data object is invalid (negative or not an integer).
   * The given index refers to the given data object's location from an array.  This is to provide more details for errors.
   */
  static validateGrantField(importData: ProdAllocImportData, index: number, orgList: UOrgMaster[]): void {
    const errorMessages: string[] = [];
    if (importData.grantedQuantity !== undefined) {
      if (typeof importData.grantedQuantity === 'string') {
        if (_.toUpper(importData.grantedQuantity) === CAP_UNLIMITED) {
          const org: UOrgMaster | undefined = _.find(
            orgList,
            (currentOrg: UOrgMaster): boolean => importData.orgId === currentOrg.organization.id
          );
          // if we don't find a corresponding org, product, resource, do nothing no resource will be updated and editing a non-existent resource will be checked later with permissions
          if (org) {
            const product: UProduct | undefined = _.find(
              org.products,
              (currentProduct: UProduct): boolean => importData.licenseId === currentProduct.id
            );
            if (product) {
              const resource: UResource | undefined = _.find(
                product.resources,
                (currentResource: UResource): boolean => importData.resourceId === currentResource.code
              );
              if (resource && resource.grantedQuantity !== importData.grantedQuantity) {
                errorMessages.push(ProdAllocImportError.localizeError(localeMessages.grantToUnlimited));
              }
            }
          }
        } else {
          errorMessages.push(ProdAllocImportError.localizeError(localeMessages.grantEditUnlimited));
        }
      } else {
        if (importData.grantedQuantity < 0) {
          errorMessages.push(ProdAllocImportError.localizeError(localeMessages.grantNegative));
        }
        if (!Number.isInteger(importData.grantedQuantity)) {
          errorMessages.push(ProdAllocImportError.localizeError(localeMessages.grantInteger));
        }
      }
    }

    if (errorMessages.length > 0) {
      throw Error(
        ProdAllocImportError.localizeRecordError(localeMessages.multipleGrantErrors, index, {
          errors: errorMessages.join(', '),
        })
      );
    }
  }

  /**
   * Checks that edited policy fields are not being set on a resource basis
   */
  static validatePolicyFields(
    importData: ProdAllocImportData,
    allImportData: ProdAllocImportData[],
    index: number
  ): void {
    if (
      importData.resourceId !== undefined &&
      (importData.allowOverAllocation !== undefined || importData.allowOverUse !== undefined)
    ) {
      const editedFields: ProdAllocImportData[] = _.filter(
        allImportData,
        (data: ProdAllocImportData): boolean =>
          !!(
            data.operation &&
            (importData.licenseId === data.licenseId || importData.sourceLicenseId === data.sourceLicenseId) &&
            importData.resourceId !== data.resourceId &&
            (data.allowOverAllocation !== undefined || data.allowOverUse !== undefined) &&
            (importData.allowOverAllocation !== data.allowOverAllocation ||
              importData.allowOverUse !== data.allowOverUse)
          )
      );
      if (editedFields.length > 0) {
        throw Error(ProdAllocImportError.localizeRecordError(localeMessages.multipleProductPolicyEdit, index));
      }
    }
  }

  /**
   * Throws an error if any data object from the given array of data objects is invalid.
   */
  static validateData(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): void {
    _.forEach(allImportData, (importData: ProdAllocImportData, index: number): void => {
      ProdAllocImportValidate.validateOperationField(importData, index);
      if (importData.operation) {
        ProdAllocImportValidate.validateRequiredFields(importData, index);
        ProdAllocImportValidate.validateFieldTypes(importData, index);
        ProdAllocImportValidate.validateGrantField(importData, index, orgList);
        ProdAllocImportValidate.validatePolicyFields(importData, allImportData, index);
      }
    });
  }
}

/**
 * Provides functionality for checking whether user has certain permissions or the ability access certain orgs/products/resources
 * The following permissions are checked
 *  - Org exists within users hierarchy and product being edited exists on org and resource being edited exists on product
 *  - User has GlobalAdmin privilege to edit values for products and resources on an org
 *  - Although not quite related to permissions, checks whether user is trying to set an "UNLIMITED" grant to a number value
 *    (technically the user is not allowed to make this change)
 * This is separate from ProdAllocImportUtils so that permissions are checked and errors are thrown before processing any data.
 */
class ProdAllocImportPermission {
  /**
   * Determines whether import data is performing an update and the data fields for editing are actually given
   */
  static isOperationUpdateWithDataFields(importData: ProdAllocImportData): boolean {
    if (
      ImportOperationsUtils.isOperationUpdate(importData.operation) &&
      importData.grantedQuantity !== undefined &&
      importData.allowOverAllocation !== undefined &&
      importData.allowOverUse !== undefined
    ) {
      return true;
    }
    return false;
  }

  /**
   * Determines whether import data has a valid operation and if the operation is update the data fields for editing are actually given
   */
  static isOperationValidWithDataFields(importData: ProdAllocImportData): boolean {
    if (
      ProdAllocImportPermission.isOperationUpdateWithDataFields(importData) ||
      ImportOperationsUtils.isOperationCreate(importData.operation) ||
      ImportOperationsUtils.isOperationDelete(importData.operation)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Checks whether values will be set to allowed values given the importData which will set the data
   * and the resource which will be updated with those values.
   * Currently this only checks if the user is trying to edit an "UNLIMITED" grant value.
   */
  static checkAllowedValues(importData: ProdAllocImportData, resource: UResource, index: number): void {
    if (
      importData.grantedQuantity !== undefined &&
      resource.isUnlimited() &&
      importData.grantedQuantity !== CAP_UNLIMITED
    ) {
      throw Error(ProdAllocImportError.localizeRecordError(localeMessages.grantUpdateUnlimited, index));
    }
  }

  /**
   * Checks whether an org being modified is read-only
   * - org: Org the user is trying to edit
   * - importData: The data object with values used to edit the given org and resource
   * - index: Index representing the location of the data object in an array.  This provides information for error messages
   */
  static checkReadOnlyOrg(org: UOrgMaster, importData: ProdAllocImportData, index: number): void {
    if (ProdAllocImportPermission.isOperationValidWithDataFields(importData)) {
      if (org.isReadOnlyOrg()) {
        throw Error(ProdAllocImportError.localizeRecordError(localeMessages.readOnlyOrg, index));
      }
    }
  }

  /**
   * Checks whether a user has GlobalAdmin permission to edit values on resources and products for an org
   * (Also checks if the user is trying to modify a read-only org)
   * (Also checks if user is trying to edit an "UNLIMITED" grant value)
   *  - org: Org the user is trying to edit
   *  - product: Product on the org the user is trying to edit (existing product, undefined for add product)
   *  - resource: Resource on the org the user is trying to edit (existing resource, undefined for add product)
   *  - importData: The data object with values used to edit the given org and resource
   *  - dataIndex: Index representing the location of the data object in an array.  This provides information for error messages.
   *  - orgList: List of orgs in the users hierarchy
   */
  static checkEditable(
    org: UOrgMaster,
    product: UProduct | undefined,
    resource: UResource | undefined,
    importData: ProdAllocImportData,
    dataIndex: number
  ): void {
    this.checkReadOnlyOrg(org, importData, dataIndex);
    if (product) {
      if (
        ProdAllocImportPermission.isOperationUpdateWithDataFields(importData) &&
        !ProductPermission.canEditProduct(org, product)
      ) {
        throw Error(
          ProdAllocImportError.localizeRecordError(localeMessages.editPermission, dataIndex, {
            productId: product.id,
            orgId: org.organization.id,
          })
        );
      }
      // check add product permissions in ProdAllocImportAddProductUtils since that gets checked before actual importing anyways and has better functionality to check that scenario
      if (
        ImportOperationsUtils.isOperationDelete(importData.operation) &&
        !ProductPermission.canEditProductAndOrg(org, product)
      ) {
        throw Error(
          ProdAllocImportError.localizeRecordError(localeMessages.deletePermission, dataIndex, {
            productId: product.id,
            orgId: org.organization.id,
          })
        );
      }
    }
    if (resource) {
      ProdAllocImportPermission.checkAllowedValues(importData, resource, dataIndex);
      // for add product, this check will be done in AddProductUtils because a source product needs to be found to verify UNLIMITED
    }
    // }
  }

  /**
   * Checks whether the org, product, and resource the user is trying to edit exists or is in their hierarchy.
   * This check is done before checking whether the user can edit values.
   *  - importData: The data object which refers to an org, product, and resource to verify
   *  - dataIndex: Index representing the location of the data object in an array.  This provides information for error messages.
   *  - orgList: List of orgs in the users hierarchy
   */
  static checkAccessibleAndEditable(importData: ProdAllocImportData, dataIndex: number, orgList: UOrgMaster[]): void {
    const org: UOrgMaster | undefined = _.find(
      orgList,
      (orgEntry: UOrgMaster): boolean => orgEntry.organization.id === importData.orgId
    );
    if (!org) {
      throw Error(
        ProdAllocImportError.localizeRecordError(localeMessages.externalOrg, dataIndex, { orgId: importData.orgId })
      );
    }

    let product: UProduct | undefined;
    let resource: UResource | undefined;

    // product id does not have to be real for ADD operation
    if (!ImportOperationsUtils.isOperationCreate(importData.operation)) {
      product = _.find(org.products, (orgProduct: UProduct): boolean => orgProduct.id === importData.licenseId);
      if (!product) {
        throw Error(
          ProdAllocImportError.localizeRecordError(localeMessages.externalProduct, dataIndex, {
            licenseId: importData.licenseId,
            orgId: importData.orgId,
          })
        );
      }

      if (importData.grantedQuantity !== undefined) {
        if (importData.resourceId === undefined) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.missingResourceCode, dataIndex));
        }
        resource = _.find(
          product.getQuotaResources(true),
          (orgResource: UResource): boolean => orgResource.code === importData.resourceId
        );
        if (!resource) {
          throw Error(
            ProdAllocImportError.localizeRecordError(localeMessages.externalResource, dataIndex, {
              resourceId: importData.resourceId,
              licenseId: importData.licenseId,
              orgId: importData.orgId,
            })
          );
        }
        // resource code is checked in AddProductUtils anyways
      }
    }

    ProdAllocImportPermission.checkEditable(org, product, resource, importData, dataIndex);
  }

  /**
   * Checks permissions for all data objects.
   *  - allImportData: Array of data objects to check permissions against
   *  - orgList: List of orgs in the users hierarchy
   */
  static checkPermissions(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): void {
    _.forEach(allImportData, (importData: ProdAllocImportData, index: number): void => {
      if (importData.operation) {
        ProdAllocImportPermission.checkAccessibleAndEditable(importData, index, orgList);
      }
    });
  }
}

/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * Utilities for validating and generating the products to add
 */
class ProdAllocImportAddProductUtils {
  /**
   * Prepends the temp id to a license id
   * License ids for new products must have the temp id prepended otherwise the back-end doesn't know to create the product
   * Note: When comparing license ids from imports with license ids of products that will be created, use this
   * method to prepend a tempId otherwise the licenses won't match
   */
  static createTempLicenseId(licenseId: string): string {
    return `${TEMP_ID_PREFIX}_${licenseId}`;
  }

  /**
   * Updates the product or resource from the imported data of the created product
   * TODO: This seems to be a functionality that UPDATE should use as well
   */
  static updateResourceOrProduct(
    product: UProduct,
    importData: ProdAllocImportData,
    index: number,
    org: UOrgMaster
  ): void {
    let importResource: UResource | undefined;
    if (importData.grantedQuantity !== undefined) {
      importResource = _.find(
        product.getQuotaResources(true),
        (resource: UResource): boolean => resource.code === importData.resourceId
      );
      if (!importResource) {
        throw Error(ProdAllocImportError.localizeRecordError(localeMessages.addProductMissingResourceCode, index));
      }
      ProdAllocImportPermission.checkAllowedValues(importData, importResource, index);
    }
    ProdAllocImportUtils.updateWithImportData(product, importResource, importData, org);
  }

  /**
   * Checks whether a product created through import has an existing already created source product
   */
  static sourceProductExists(importData: ProdAllocImportData, orgList: UOrgMaster[]): boolean {
    if (!importData.sourceLicenseId) {
      return false;
    }
    return _.some(orgList, (orgEntry: UOrgMaster): boolean =>
      _.some(orgEntry.products, (product: UProduct): boolean => product.id === importData.sourceLicenseId)
    );
  }

  /**
   * Finds the existing source product actually in the hierarchy given import data.  Searches through both all created products or any
   * products that will be created from import data.
   */
  static findAncestorSourceProduct(
    importData: ProdAllocImportData,
    allImportData: ProdAllocImportData[],
    orgList: UOrgMaster[],
    index: number
  ): UProduct {
    let sourceProduct: UProduct | undefined;
    let currentProductData: ProdAllocImportData | undefined = importData;
    while (currentProductData && !sourceProduct) {
      // loop searching is used here instead of _.find because eslint dislikes function callbacks in loops that reference variables

      // first check if the source product exists (is a created product)
      for (let orgIndex: number = 0; orgIndex < orgList.length && !sourceProduct; orgIndex++) {
        const orgEntry: UOrgMaster = orgList[orgIndex];
        for (let productIndex: number = 0; productIndex < orgEntry.products.length && !sourceProduct; productIndex++) {
          const product: UProduct = orgEntry.products[productIndex];
          if (product.id === currentProductData.sourceLicenseId) {
            sourceProduct = product;
          }
        }
      }

      // the source product might be one of the products being created, walk up the import hierarchy until the existing source product is found
      if (!sourceProduct) {
        let sourceProductData: ProdAllocImportData | undefined;
        for (let dataIndex: number = 0; dataIndex < allImportData.length; dataIndex++) {
          const iData: ProdAllocImportData = allImportData[dataIndex];
          if (
            ImportOperationsUtils.isOperationCreate(iData.operation) &&
            iData.licenseId === currentProductData.sourceLicenseId
          ) {
            sourceProductData = iData;
            break;
          }
        }
        if (!sourceProductData) {
          if (currentProductData === importData) {
            // the source product id on the current index must be invalid
            throw Error(ProdAllocImportError.localizeRecordError(localeMessages.invalidSourceProduct, index));
          } else {
            // the source product id somewhere in the hierarchy must be invalid
            throw Error(ProdAllocImportError.localizeRecordError(localeMessages.invalidAncestorSourceProduct, index));
          }
        }
        currentProductData = sourceProductData;
      }
    }
    return sourceProduct as UProduct; // this won't be undefined as it would have thrown an error above
  }

  /**
   * Generates a new UProduct based off of the sourceProduct and the imported data
   */
  static createProductFromImport(
    sourceProduct: UProduct,
    sourceProductExists: boolean,
    importData: ProdAllocImportData
  ): UProduct {
    let sourceLicenseId = '';
    if (importData.sourceLicenseId) {
      // This should have been validated earlier for being undefined (but if it is treat it as blank)
      sourceLicenseId = importData.sourceLicenseId;
      if (!sourceProductExists) {
        // If direct source product doesn't already exist, it needs a temp id
        sourceLicenseId = ProdAllocImportAddProductUtils.createTempLicenseId(sourceLicenseId);
      }
    }

    const newProduct: UProduct = _.cloneDeep(sourceProduct);
    newProduct.productProfiles = [];
    newProduct.orgId = importData.orgId;
    newProduct.sourceProductId = sourceLicenseId;
    newProduct.setId(ProdAllocImportAddProductUtils.createTempLicenseId(importData.licenseId));
    newProduct.profilesLoaded = true;
    return newProduct;
  }

  /**
   * Initializes the resources of a product to default values (in this case 0)
   * The product being passed in is modified and its resources are updated
   */
  static initResources(product: UProduct): void {
    const resources: UResource[] = product.getQuotaResources(true);
    _.forEach(resources, (resource: UResource): void => {
      /* eslint-disable no-param-reassign */
      if (!resource.isUnlimited()) {
        resource.grantedQuantity = '0';
      }
      resource.provisionedQuantity = 0;
      /* eslint-enable no-param-reassign */
    });
  }

  /**
   * Checks whether adding the product is allowed
   * - org: existing org for which the product is being added to
   * - importData: data with information on the product to add
   * - allImportData: the entire imported data
   * - dataIndex: index representing the location of the data object in an array.  This provides information for error messages.
   */
  static checkAddProductPermission(
    org: UOrgMaster,
    importData: ProdAllocImportData,
    allImportData: ProdAllocImportData[],
    orgList: UOrgMaster[],
    dataIndex: number
  ): boolean {
    if (ImportOperationsUtils.isOperationCreate(importData.operation)) {
      const ancestorProduct: UProduct | undefined = ProdAllocImportAddProductUtils.findAncestorSourceProduct(
        importData,
        allImportData,
        orgList,
        dataIndex
      );
      const destOrg: UOrgMaster | undefined = _.find(
        orgList,
        (o: UOrgMaster): boolean => o.organization.id === org.organization.id
      );
      // if no ancestorProduct exists, assume redistributable is false, so we can't add product
      if (!ancestorProduct || !destOrg || !ProductPermission.canAddProduct(destOrg, ancestorProduct)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Checks whether product being created is actually allocated from direct parent org
   * - org: existing org for which the product is being added to
   * - importData: data with the information on the product to add
   * - allImportData: the entire imported data
   * - orgList: list of all existing orgs
   */
  static checkDirectSourceAllocation(
    org: UOrgMaster,
    importData: ProdAllocImportData,
    allImportData: ProdAllocImportData[],
    orgList: UOrgMaster[]
  ): boolean {
    const parentOrg: UOrgMaster | undefined = _.find(
      orgList,
      (currentOrg: UOrgMaster): boolean => org.organization.parentOrgId === currentOrg.organization.id
    );
    if (parentOrg) {
      // if parent org doesn't exist, this would have errored earlier as we cannot edit top level org
      // check if source product exists on existing parent products
      let allocatedFromDirectParent: boolean = !!_.find(
        parentOrg.products,
        (product: UProduct): boolean => importData.sourceLicenseId === product.id
      );
      // check if source product exists on parent products that will be created
      if (!allocatedFromDirectParent) {
        const parentImportData: ProdAllocImportData[] = _.filter(
          allImportData,
          (iData: ProdAllocImportData): boolean =>
            ImportOperationsUtils.isOperationCreate(iData.operation) && iData.orgId === org.organization.parentOrgId
        );
        allocatedFromDirectParent = !!_.find(
          parentImportData,
          (iData: ProdAllocImportData): boolean => importData.sourceLicenseId === iData.licenseId
        );
      }
      return allocatedFromDirectParent;
    }
    return false;
  }

  /**
   * Validates and generates the products to add
   *
   * Validation checks for the following
   *  - sourceProductId is required
   *  - org associated with new product must exist
   *  - new product cannot already exist
   *  - other resources being created for product must have matching sourceProductId and productId
   *  - product must be allowed to be added
   */
  static validateAndProcessProducts(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): UProduct[] {
    const productsToAdd: UProduct[] = [];

    // loop for all data, so that we get accurate index for error messaging
    _.forEach(allImportData, (importData: ProdAllocImportData, index: number): void => {
      if (ImportOperationsUtils.isOperationCreate(importData.operation)) {
        // check that sourceProductId exists
        if (importData.sourceLicenseId === undefined) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.sourceProductIdRequired, index));
        }

        // check that orgId associated with new product exists
        const addProductOrg: UOrgMaster | undefined = _.find(
          orgList,
          (orgEntry: UOrgMaster): boolean => orgEntry.organization.id === importData.orgId
        );
        if (!addProductOrg) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.invalidOrgId, index));
        }

        // check that product doesn't already exists
        const addProductAlreadyExists: boolean = !!_.find(
          addProductOrg.products,
          (product: UProduct): boolean => product.id === importData.licenseId
        );
        if (addProductAlreadyExists) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.addProductAlreadyExists, index));
        }

        // check that the product is being allocated from the direct parent org (can't create indirect allocations)
        if (
          !ProdAllocImportAddProductUtils.checkDirectSourceAllocation(addProductOrg, importData, allImportData, orgList)
        ) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.addProductIsNotDirectAllocation, index));
        }

        // check that we are allowed to add the product
        if (
          !ProdAllocImportAddProductUtils.checkAddProductPermission(
            addProductOrg,
            importData,
            allImportData,
            orgList,
            index
          )
        ) {
          throw Error(
            ProdAllocImportError.localizeRecordError(localeMessages.addPermission, index, {
              productId: importData.licenseId,
              orgId: addProductOrg.organization.id,
            })
          );
        }

        // determines whether the direct source product actually exists (findAncestorSourceProduct finds the existing ancestor which might not be the direct source)
        const sourceProductExists: boolean = ProdAllocImportAddProductUtils.sourceProductExists(importData, orgList);

        // search on sourceProductId because that identifies what product to create
        // if source product id does not exist in productsToAdd, then a different product must be created
        // if source product id does not exist in the hierarchy, then the import sourceLicenseId must be compared with the tempId (since the source will need to be created) to find it in productsToAdd
        // if product id does not exist in productsToAdd, it is possible that the id was entered incorrectly
        const pendingNewProduct: UProduct | undefined = _.find(
          productsToAdd,
          (product: UProduct): boolean =>
            !_.isNil(importData.sourceLicenseId) &&
            (product.sourceProductId === importData.sourceLicenseId ||
              (!sourceProductExists &&
                product.sourceProductId ===
                  ProdAllocImportAddProductUtils.createTempLicenseId(importData.sourceLicenseId))) &&
            product.orgId === importData.orgId
        );
        // handle case if product is already being created but resouces are being specified
        if (pendingNewProduct) {
          if (
            !_.find(
              productsToAdd,
              (product: UProduct): boolean =>
                product.id === ProdAllocImportAddProductUtils.createTempLicenseId(importData.licenseId)
            )
          ) {
            throw Error(ProdAllocImportError.localizeRecordError(localeMessages.productIdMismatch, index));
          }
          ProdAllocImportAddProductUtils.updateResourceOrProduct(pendingNewProduct, importData, index, addProductOrg);
        } else {
          const sourceProduct: UProduct = ProdAllocImportAddProductUtils.findAncestorSourceProduct(
            importData,
            allImportData,
            orgList,
            index
          );

          // at this point a sourceProduct exists or an error would have been thrown
          // create the new product objects
          const newProduct: UProduct = ProdAllocImportAddProductUtils.createProductFromImport(
            sourceProduct,
            sourceProductExists,
            importData
          );
          ProdAllocImportAddProductUtils.initResources(newProduct);
          ProdAllocImportAddProductUtils.updateResourceOrProduct(newProduct, importData, index, addProductOrg);
          productsToAdd.push(newProduct);
        }
      }
    });
    return productsToAdd;
  }

  /**
   * Validates and creates new products based on import data
   */
  static validateAndAddProducts(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): void {
    const newProducts: UProduct[] = ProdAllocImportAddProductUtils.validateAndProcessProducts(allImportData, orgList);
    _.forEach(newProducts, (product: UProduct): void => {
      CommandService.addEdit(
        HierarchyManager.getOrg(product.orgId) as UOrgMaster,
        product,
        ObjectTypes.PRODUCT,
        OrgOperation.CREATE,
        undefined,
        'ADD_PRODUCT',
        [product.name, CmdDescriptionUtils.getPathname(product.orgId)]
      );
    });
  }
}

/* eslint-enable @typescript-eslint/no-use-before-define */

/**
 * Utilities for validating and deleting products
 */
class ProdAllocImportDeleteProductUtils {
  /**
   * Finds the productIds of products allocated from the product to delete
   * (whether already existing or being created from import)
   */
  static findChildProducts(
    importData: ProdAllocImportData,
    allImportData: ProdAllocImportData[],
    orgList: UOrgMaster[]
  ): string[] {
    let childProductIds: string[] = [];
    _.forEach(orgList, (org: UOrgMaster): void => {
      const childProducts: UProduct[] = _.filter(
        org.products,
        (product: UProduct): boolean => product.sourceProductId === importData.licenseId
      );
      childProductIds = childProductIds.concat(_.map(childProducts, (product: UProduct): string => product.id));
    });
    _.forEach(allImportData, (iData: ProdAllocImportData): void => {
      if (
        importData.licenseId === iData.sourceLicenseId &&
        ImportOperationsUtils.isOperationValid(iData.operation) &&
        !_.find(childProductIds, (productId: string): boolean => productId === iData.licenseId)
      ) {
        childProductIds.push(iData.licenseId);
      }
    });
    return childProductIds;
  }

  /**
   * Validates and returns all the products to delete
   * Performs the following validations
   *  - org associated with product being deleted must exist
   *  - product being deleted must exist
   *  - product being deleted must not have resources with grant values of 0
   *  - product being deleted must not be allocating to other products
   */
  static validateAndProcessProducts(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): UProduct[] {
    const productsToDelete: UProduct[] = [];
    _.forEach(allImportData, (importData: ProdAllocImportData, index: number): void => {
      if (ImportOperationsUtils.isOperationDelete(importData.operation)) {
        // check that orgId associated with product to delete exists
        const productOrg: UOrgMaster | undefined = _.find(
          orgList,
          (org: UOrgMaster): boolean => org.organization.id === importData.orgId
        );
        if (!productOrg) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.invalidOrgId, index));
        }

        // check that the product exists
        const productToDelete: UProduct | undefined = _.find(
          productOrg.products,
          (product: UProduct): boolean => product.id === importData.licenseId
        );
        if (!productToDelete) {
          throw Error(ProdAllocImportError.localizeRecordError(localeMessages.deleteProductDoesNotExist, index));
        }

        // Cannot delete products with 0 grant value due to https://jira.corp.adobe.com/browse/CLAS-207
        const zeroResources: UResource[] = _.filter(
          productToDelete.resources,
          (resource: UResource): boolean => resource.grantedQuantity === '0'
        );
        const zeroResourceCodes: string[] = _.map(zeroResources, (res: UResource): string => res.code);
        // also check for updates that will make the resource grant 0 for the product to delete
        _.forEach(allImportData, (iData: ProdAllocImportData): void => {
          if (ImportOperationsUtils.isOperationUpdate(iData.operation) && iData.licenseId === productToDelete.id) {
            if (
              !_.isNil(iData.resourceId) &&
              iData.grantedQuantity === 0 &&
              !_.find(zeroResourceCodes, (code: string): boolean => code === iData.resourceId)
            ) {
              zeroResourceCodes.push(iData.resourceId);
            }
          }
        });
        if (!_.isEmpty(zeroResources)) {
          throw Error(
            ProdAllocImportError.localizeRecordError(localeMessages.deleteProductNoZeroGrant, index, {
              resCodes: zeroResourceCodes.join(','),
            })
          );
        }

        // check that product doesn't have child products
        const childProductIds: string[] = ProdAllocImportDeleteProductUtils.findChildProducts(
          importData,
          allImportData,
          orgList
        );
        // remove any child products that will be deleted as result of import
        _.forEach(allImportData, (iData: ProdAllocImportData): void => {
          if (ImportOperationsUtils.isOperationDelete(iData.operation) && iData.licenseId !== productToDelete.id) {
            const foundIndex: number = _.findIndex(
              childProductIds,
              (productId: string): boolean => productId === iData.licenseId
            );
            if (foundIndex >= 0) {
              childProductIds.splice(foundIndex, 1);
            }
          }
        });
        if (!_.isEmpty(childProductIds)) {
          throw Error(
            ProdAllocImportError.localizeRecordError(localeMessages.deleteNoChildren, index, {
              childIds: childProductIds.join(', '),
            })
          );
        }
        productsToDelete.push(productToDelete);
      }
    });
    return productsToDelete;
  }

  /**
   * Validates and deletes products based on import data
   */
  static validateAndDeleteProducts(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): void {
    const productsToDelete: UProduct[] = ProdAllocImportDeleteProductUtils.validateAndProcessProducts(
      allImportData,
      orgList
    );
    _.forEach(productsToDelete, (product: UProduct): void => {
      CommandService.addEdit(
        HierarchyManager.getOrg(product.orgId) as UOrgMaster,
        product,
        ObjectTypes.PRODUCT,
        OrgOperation.DELETE,
        undefined,
        'REMOVE_PRODUCT',
        [product.name, CmdDescriptionUtils.getPathname(product.orgId)]
      );
    });
  }
}

/**
 * Import functionality for all file types (data objects)
 */
class ProdAllocImportUtils {
  /**
   * Checks if the import data contains any operations to make any changes
   */
  static hasAnyOperations(allImportData: ProdAllocImportData[]): boolean {
    return _.some(
      allImportData,
      (importData: ProdAllocImportData): boolean =>
        importData.operation === ImportOperations.UPDATE ||
        importData.operation === ImportOperations.CREATE ||
        importData.operation === ImportOperations.DELETE
    );
  }

  /**
   * Updates the data model from the imported data.
   *  - product: Product of the org related to the imported data (data object) that will be updated.
   *  - resource: Resource of the product/org related to the imorted data (data object) that will be updated.
   *  - importData: Data object that provides data that will update the data model.
   *  - org: Org which the product belongs to
   *  - originalProduct: The original product before applying updates. This will be optional/undefined for the CREATE context
   * Returns whether values have actually been updated
   * This is where the data is specified for import that will actually change values in the data model.
   * If you need to add or remove values for import, this is where you need to edit.
   */
  static updateWithImportData(
    product: UProduct,
    resource: UResource | undefined,
    importData: ProdAllocImportData,
    org: UOrgMaster,
    originalProduct?: UProduct
  ): boolean {
    /* eslint-disable no-param-reassign */
    let valuesUpdated = false;
    const { operation } = importData;
    if (
      resource !== undefined &&
      importData.grantedQuantity !== undefined &&
      resource.grantedQuantity !== importData.grantedQuantity.toString()
    ) {
      valuesUpdated = true;
      resource.grantedQuantity = importData.grantedQuantity.toString();
      if (operation === ImportOperations.UPDATE) {
        CommandService.addEdit(
          org,
          product,
          ObjectTypes.PRODUCT,
          OrgOperation.UPDATE,
          originalProduct,
          'UPDATE_PRODUCT_QUANTITY',
          [product.name, CmdDescriptionUtils.getPathname(org.id)]
        );
      }
    }
    if (importData.allowOverAllocation !== undefined && product.allowExceedQuotas !== importData.allowOverAllocation) {
      valuesUpdated = true;
      product.allowExceedQuotas = importData.allowOverAllocation;
      if (operation === ImportOperations.UPDATE) {
        CommandService.addEdit(
          org,
          product,
          ObjectTypes.PRODUCT,
          OrgOperation.UPDATE,
          originalProduct,
          'UPDATE_PRODUCT_OVERALLOC',
          [product.name, CmdDescriptionUtils.getPathname(org.id)]
        );
      }
    }
    if (importData.allowOverUse !== undefined && product.allowExceedUsage !== importData.allowOverUse) {
      valuesUpdated = true;
      product.allowExceedUsage = importData.allowOverUse;
      if (operation === ImportOperations.UPDATE) {
        CommandService.addEdit(
          org,
          product,
          ObjectTypes.PRODUCT,
          OrgOperation.UPDATE,
          originalProduct,
          'UPDATE_PRODUCT_OVERUSE',
          [product.name, CmdDescriptionUtils.getPathname(org.id)]
        );
      }
    }
    return valuesUpdated;
    /* eslint-enable no-param-reassign */
  }

  /**
   * Determines the org, product, and resource associated with the given import data (data object)
   * and updates data model.
   *  - importData: Data object that provides data that will update the data model.
   *  - orgList: List of all orgs loaded in the ProductAllocation page.
   * Returns whether values have actually been updated
   */
  static importData(importData: ProdAllocImportData, orgList: UOrgMaster[]): boolean {
    let valuesUpdated = false;
    const org: UOrgMaster | undefined = _.find(
      orgList,
      (orgEntry: UOrgMaster): boolean => orgEntry.organization.id === importData.orgId
    );
    if (org) {
      const product: UProduct | undefined = _.find(
        org.products,
        (orgProduct: UProduct): boolean => orgProduct.id === importData.licenseId
      );
      const originalProduct = _.cloneDeep(product);
      if (product) {
        const resource: UResource | undefined = _.find(
          product.getQuotaResources(true),
          (orgResource: UResource): boolean => orgResource.code === importData.resourceId
        );
        valuesUpdated = this.updateWithImportData(product, resource, importData, org, originalProduct);
      }
    }
    return valuesUpdated;
  }

  /**
   * Process the import data (data object) for multiple data objects and updates the data model.
   *  - allImportData: Data objects that provide data that will update the data model.
   *  - orgList: List of all orgs loaded in the ProductAllocation page.
   */
  static importAllData(allImportData: ProdAllocImportData[], orgList: UOrgMaster[]): ProdAllocImportStatus {
    const status: ProdAllocImportStatus = {
      message: Utils.getLocalizedMessage(localeMessages.success),
    };
    ProdAllocImportValidate.validateData(allImportData, orgList);
    ProdAllocImportPermission.checkPermissions(allImportData, orgList);

    if (!ProdAllocImportUtils.hasAnyOperations(allImportData)) {
      status.message = Utils.getLocalizedMessage(localeMessages.noUpdateOperations);
    }

    ProdAllocImportAddProductUtils.validateAndAddProducts(allImportData, orgList);
    const recordNumsWithNoChanges: number[] = [];
    _.forEach(allImportData, (importData: ProdAllocImportData, index: number): void => {
      if (ImportOperationsUtils.isOperationUpdate(importData.operation)) {
        const valuesUpdated: boolean = ProdAllocImportUtils.importData(importData, orgList);
        // only update is being checked for whether updates actually occur (update is the only operation where nothing changes if no values are changed)
        if (!valuesUpdated) {
          recordNumsWithNoChanges.push(ProdAllocImportError.indexToRecordNum(index));
        }
      }
    });
    if (!_.isEmpty(recordNumsWithNoChanges)) {
      status.message = Utils.getLocalizedMessage(localeMessages.noChanges, {
        recordNums: recordNumsWithNoChanges.join(','),
      });
    }
    ProdAllocImportDeleteProductUtils.validateAndDeleteProducts(allImportData, orgList);
    return status;
  }
}

/**
 * Import functionality specifically for JSON file type.
 */
export class ProdAllocImportJSON {
  /**
   * Takes a JSON array and trims every string value for every JSON object in the array
   * The jsonObjectArray parameter is actually modified
   */
  static trimJsonValues(jsonObjectArray: any[]): void {
    /* eslint-disable no-param-reassign */
    _.forEach(jsonObjectArray, (jsonObject: any): void => {
      _.forEach(_.keys(jsonObject), (propName: string): void => {
        if (typeof jsonObject[propName] === 'string') {
          jsonObject[propName] = _.trim(jsonObject[propName]);
        }
      });
    });
    /* eslint-enable no-param-reassign */
  }

  /**
   * Updates the data model given JSON data.
   */
  static import(data: string, orgList: UOrgMaster[]): ProdAllocImportStatus {
    let allImportData: ProdAllocImportData[];
    try {
      const jsonObjectArray: any[] = JSON.parse(data);
      ProdAllocImportJSON.trimJsonValues(jsonObjectArray);
      allImportData = jsonObjectArray as ProdAllocImportData[];
    } catch (error) {
      throw Error(ProdAllocImportError.localizeError(localeMessages.invalidJSON, { errorMessage: error.message }));
    }
    if (!Array.isArray(allImportData)) {
      throw Error(ProdAllocImportError.localizeError(localeMessages.jsonArrayRequired));
    }
    return ProdAllocImportUtils.importAllData(allImportData, orgList);
  }
}

/**
 * - Header exists
 * - Number of headers equals number of items
 */

/**
 * Import functionality specifically for CSV file type.
 */
export class ProdAllocImportCSV {
  /**
   * The headers for a CSV are considered valid by the following criterias
   *  - There are no empty header values (ex: header1,,header3)
   *  - There are no required headers missing
   *  - There are no header values that don't appear to be strings (ex: header1,500,true)
   * A header row is required for the imported CSV.  Since there does not appear to be a way
   * to fully ensure the header row is provided, the above checks should fail if the header row is not provided.
   *
   * The following additional criteria must be met for CSV to be considered valid
   *  - The CSV file can't be empty
   *  - THe CSV file must have at least 2 rows (1 header and 1 value row)
   */

  /**
   * Throws error if any header column string is empty (no commas within nothing in between)
   *  - headers: Each header column string is a single element in the array
   */
  private static validateNoEmptyHeader(headers: string[]): void {
    const emptyHeader: string | undefined = _.find(headers, (header: string): boolean => _.isEmpty(header));
    if (emptyHeader !== undefined) {
      throw Error(ProdAllocImportError.localizeError(localeMessages.csvEmptyHeader));
    }
  }

  /**
   * Throws error if any required header column string is missing (related to the required properties of ProdAllocImportData)
   *  - headers: Each header column string is a single element in the array
   */
  private static validateRequiredHeaders(headers: string[]): void {
    const missingRequiredHeaders: string[] = [];
    _.forEach(REQUIRED_IMPORT_PROPS, (prop: ProdAllocImportDataProp): void => {
      if (_.find(headers, (header: string): boolean => header === prop) === undefined) {
        missingRequiredHeaders.push(prop);
      }
    });

    if (missingRequiredHeaders.length > 0) {
      throw Error(
        ProdAllocImportError.localizeError(localeMessages.csvMissingHeaders, {
          headers: missingRequiredHeaders.join(', '),
        })
      );
    }
  }

  /**
   * Throws error if any header column string appears to be a number or boolean value.
   *  - headers: Each header column string is a single element in the array
   */
  private static validateHeadersAllStrings(headers: string[]): void {
    const nonStringValue: string | undefined = _.find(
      headers,
      (header: string): boolean => Utils.canParseBool(header) || Utils.canParseInt(header)
    );
    if (nonStringValue !== undefined) {
      throw Error(ProdAllocImportError.localizeError(localeMessages.csvHeaderNotString, { header: nonStringValue }));
    }
  }

  /**
   * Throws error, if the header row is invalid.
   *  - headers: Each header column string is a single element in the array
   */
  private static validateHeaders(headers: string[]): void {
    ProdAllocImportCSV.validateNoEmptyHeader(headers);
    ProdAllocImportCSV.validateRequiredHeaders(headers);
    ProdAllocImportCSV.validateHeadersAllStrings(headers);
  }

  /**
   * Parse csv string to an array of string arrays.
   * Each row is an array of strings.
   * The entire parsed data will be an array of all rows (array of strings)
   */
  private static parseCSV(csvString: string): string[][] {
    const csvRows: ParseResult<any> = parse(csvString);
    if (!_.isEmpty(csvRows.errors)) {
      const errorMessages: string[] = _.map(
        csvRows.errors,
        (error: ParseError, index: number): string => `${index + 1}: ${error.message}`
      );
      throw Error(
        ProdAllocImportError.localizeError(localeMessages.invalidCSV, { errorMessage: errorMessages.join(';') })
      );
    }
    if (!_.isEmpty(csvRows.data)) {
      return _.map(csvRows.data, (csvRow: any): string[] => _.map(csvRow, (value: string): string => value.trim()));
    }
    return [];
  }

  /**
   * Retrieves the header row from the CSV.
   *  - rows: Each element is an array of string values of each line in the CSV file
   * The elements of the returned array are each header column string.
   * Note: If the header is not provided, this will grab the first row.  (The returned value needs to be validated)
   */
  private static getHeader(rows: string[][]): string[] {
    const headers: string[] = rows[0]; // there is only 1 header row and it is the first row
    return _.map(headers, (header: string): string => header.trim());
  }

  /**
   * Retrieves all the rows except the header row.
   *  - rows: Each element is an array of string values of each line in the CSV file
   * The returned array is 2 dimensional
   *  - The first dimension represents a row (an array) where every element is a column value of that row
   *  - The second dimension selects a single column value for that row
   * Note: If the header row is not provided, this will omit the first row (The header row needs to be validated).
   */
  private static getValues(rows: string[][]): string[][] {
    const values: string[][] = [];
    _.forEach(rows.slice(1), (row: string[]): void => {
      const rowValuesTrimmed: string[] = _.map(row, (value: string): string => value.trim());
      values.push(rowValuesTrimmed);
    });
    return values;
  }

  /**
   * Converts CSV data of a single row to a single data object
   *  - headers: Each header column string is a single element in the array
   *  - rowValue: Represents a single row where each element is a column value
   *  - index: Refers to the row number (0 indexed).  This is to provide more details for errors.
   * Note: Values being assigned to the data object must be escaped as CSV is not automatically escaped.
   */
  private static createProdAllocImportData(headers: string[], rowValue: string[], index: number): ProdAllocImportData {
    if (headers.length !== rowValue.length) {
      throw Error(ProdAllocImportError.localizeRecordError(localeMessages.csvHeaderRowMismatch, index));
    }
    const data: { [key: string]: any } = {};
    _.forEach(headers, (header: string, headerIndex: number): void => {
      const parsedValue: number | string | boolean = Utils.parseStringValue(Utils.htmlEntities(rowValue[headerIndex]));
      // _.isEmpty returns true for non-strings
      if (parsedValue !== '') {
        data[header] = parsedValue;
      }
    });
    return data as ProdAllocImportData;
  }

  /**
   * Converts CSV data to data objects
   *  - headers: Each header column string is a single element in the array
   *  - values: Array of all rows containing values.  Each row (array) contains elements representing the column values for that row.
   */
  private static createAllProdAllocImportData(headers: string[], values: string[][]): ProdAllocImportData[] {
    const data: ProdAllocImportData[] = [];
    _.forEach(values, (value: string[], index: number): void => {
      data.push(ProdAllocImportCSV.createProdAllocImportData(headers, value, index));
    });
    return data;
  }

  /**
   * Updates the data model given CSV data.
   */
  static import(data: string, orgList: UOrgMaster[]): ProdAllocImportStatus {
    const rows: string[] = _.filter(Utils.splitByLineBreaks(_.trim(data)), (row: string): boolean => !_.isEmpty(row));
    if (rows.length === 0) {
      throw Error(ProdAllocImportError.localizeError(localeMessages.csvEmpty));
    }
    if (rows.length < 2) {
      throw Error(ProdAllocImportError.localizeError(localeMessages.csvMissing));
    }
    const parsedCSV: string[][] = ProdAllocImportCSV.parseCSV(data);
    const headers: string[] = ProdAllocImportCSV.getHeader(parsedCSV);
    ProdAllocImportCSV.validateHeaders(headers);
    const values: string[][] = ProdAllocImportCSV.getValues(parsedCSV);
    const allImportData: ProdAllocImportData[] = ProdAllocImportCSV.createAllProdAllocImportData(headers, values);
    return ProdAllocImportUtils.importAllData(allImportData, orgList);
  }
}
