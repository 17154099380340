import { UOrgMaster } from '../orgMaster/UOrgMaster';
import TempIdGenerator from '../utils/TempIdGenerator';
import { UOrgData } from '../orgMaster/UOrg';
import { CommandService } from '../Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../orgMaster/OrgMaster';
import { ExpandedNodesUtil } from '../treeTableUtils/ExpandedNodesUtil';
import OrgSelectionUtil from '../treeTableUtils/OrgSelectionUtil';
import OrgTreeCache from '../../Compartments/OrgTree/OrgTreeCache';
import HierarchyManager from './HierarchyManager';
import CmdDescriptionUtils from '../Codes/CmdDescriptionUtils';

export class AddOrgService {
  /**
   * This method:
   * 1. Creates a new org
   * 2. Add to the OrgManager's map and set
   * 3. link it to its parent org
   * 4. save the edit for creating the new org
   * 5. update the org selection and expand the parent org
   * @param parentOrg
   * @param countryCode
   * @param orgName
   */
  public static createNewOrg(parentOrg: UOrgMaster, orgName: string, countryCode: string): UOrgMaster {
    const newId = TempIdGenerator.getTempIdAndIncrement();
    const orgData: UOrgData = {
      countryCode,
      id: newId,
      name: orgName,
      parentOrgId: parentOrg.organization.id,
      type: parentOrg.organization.type,
    };
    const orgMaster = new UOrgMaster(orgData, true);
    const newOrgMaster = HierarchyManager.addOrg(orgMaster);
    CommandService.addEdit(
      newOrgMaster,
      orgMaster.organization,
      ObjectTypes.ORGANIZATION,
      OrgOperation.CREATE,
      undefined,
      'CREATE_ORG',
      [CmdDescriptionUtils.getPathname(orgMaster.organization.id)]
    );
    ExpandedNodesUtil.expandOrg(parentOrg.id); // expand the parent org in org tree
    OrgSelectionUtil.updateOrgSelection(newId); // select the org in org tree and show its edit compartment view
    // update the org tree when a new org is added because it's not possible to change
    // just part of the Prime React tree by, for example, adding a new node
    OrgTreeCache.clear();
    return newOrgMaster;
  }
}
