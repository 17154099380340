import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';
import * as log from 'loglevel';

import { ImportOrganizationsDM, ImportProductsDM, ImportResourcesDM } from '../DataModelTypes/DataModelTypes';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import { UProduct } from '../../../../services/orgMaster/UProduct';
import ImportOrganizations from './ImportOrganizations';
import { UResource } from '../../../../services/orgMaster/UResource';
import Utils from '../../../../services/utils/Utils';
import ImportOperationsUtils from '../../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import ImportUtils, { ChangeCount } from '../ImportUtils';
import TempIdGenerator from '../../../../services/utils/TempIdGenerator';
import { EditState, ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import { CommandService } from '../../../../services/Commands/CommandService';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';

const messages = defineMessages({
  ProductsLabel: {
    id: 'Organizations.Import.Products.ProductsLabel',
    defaultMessage: 'Products',
  },
  ResourcesLabel: {
    id: 'Organizations.Import.Products.ResourcesLabel',
    defaultMessage: 'Resources',
  },
  LicenseIdFieldInvalid: {
    id: 'Organizations.Import.Products.LicenseIdFieldInvalid',
    defaultMessage: "licenseId cannot be blank for record ''{index}''",
  },
  OrgIdFieldInvalid: {
    id: 'Organizations.Import.Products.OrgIdFieldInvalid: {',
    defaultMessage: "orgId ''{orgId}'' cannot be blank for record ''{index}''",
  },
  SourceLicenseIdFieldInvalid: {
    id: 'Organizations.Import.Products.SourceLicenseIdFieldInvalid',
    defaultMessage: "sourceLicenseId cannot be blank for record ''{index}''",
  },
  LicenseIdDuplicateInputFile: {
    id: 'Organizations.Import.Products.LicenseIdDuplicateInputFile',
    defaultMessage: 'The following license ids are duplicate in the imported data:',
  },
  SameLicenseAndSourceLicenseId: {
    id: 'Organizations.Import.Products.SameLicenseAndSourceLicenseId',
    defaultMessage: 'The following licenseIds have the same licenseId and sourceLicenseId (invalid operation):',
  },
  LicenseIdNotExist: {
    id: 'Organizations.Import.Products.LicenseIdNotExist',
    defaultMessage: "The following licenseIds marked as 'UPDATE' or 'DELETE' do not exist in the org:",
  },
  LicenseIdSelectedAsSourceLicenseInvalid: {
    id: 'Organizations.Import.Products.LicenseIdSelectedAsSourceLicenseInvalid',
    defaultMessage:
      "The following licenseId's are marked for 'DELETE' and also selected as sourceLicenseId (invalid operation):",
  },
  SourceProductAbsentFromParentOrg: {
    id: 'Organizations.Import.Products.SourceProductAbsentFromParentOrg',
    defaultMessage: 'sourceLicenseId must be from immediate parent org. The following do not satisfy this condition:',
  },
  CannotAllocateProductToOrg: {
    id: 'Organizations.Import.Products.CannotAllocateProductToOrg',
    defaultMessage: "Cannot allocate product to org ''{orgId}'' from sourceLicenseId ''{sourceLicenseId}''",
  },
  InvalidOrgId: {
    id: 'Organizations.Import.Products.InvalidOrgId',
    defaultMessage: "orgId ''{orgId}'' is neither a new org nor an existing org",
  },
  InvalidResourceDeletionOperation: {
    id: 'Organizations.Import.Products.InvalidResourceDeletionOperation',
    defaultMessage: 'The following licenseIds have resources marked for deletion (Invalid operation):',
  },
  InvalidResourceIdForLicense: {
    id: 'Organizations.Import.Products.InvalidResourceIdForLicense',
    defaultMessage: "resourceId ''{resourceId}'' is invalid for licenseId ''{licenseId}''",
  },
  DuplicateResourceToALicense: {
    id: 'Organizations.Import.Products.DuplicateResourceToALicense',
    defaultMessage: "The following licenseId's have duplicate resources:",
  },
  InvalidGrantedQuantity: {
    id: 'Organizations.Import.Products.InvalidGrantedQuantity',
    defaultMessage: 'The following licenseIds have invalid grantedQuantity:',
  },
  DuplicateAllocation: {
    id: 'Organizations.Import.Products.DuplicateAllocation',
    defaultMessage:
      "Allocation from sourceLicenseId ''{sourceLicenseId}'' to orgId ''{orgId}'' already exists in the org",
  },
  DuplicateAllocationFromImportFile: {
    id: 'Organizations.Import.Products.DuplicateAllocationFromImportFile',
    defaultMessage:
      "Multiple allocations from sourceLicenseId ''{sourceLicenseId}'' to orgId ''{orgId}'' exists in the input file",
  },
  LicenseIdInvalid: {
    id: 'Organizations.Import.Products.LicenseIdInvalid',
    defaultMessage: "Resources for licenseId ''{licenseId}'' cannot be updated as licenseId is invalid.",
  },
  ProductOperationNotValidTypeTOrgs: {
    id: 'Organizations.Import.Products.ProductOperationNotValidTypeTOrgs',
    defaultMessage: "Products cannot be added or updated in the following orgs because of the org's type:",
  },
  ProductFormatMessage: {
    id: 'Organizations.Import.Products.ProductFormatMessage',
    defaultMessage: "sourceLicenseId: ''{sourceLicenseId}'' and orgId: ''{orgId}''",
  },
  InvalidOrgIdMarkedForDelete: {
    id: 'Organizations.Import.Products.InvalidOrgIdMarkedForDelete',
    defaultMessage: 'The following products cannot be added to the orgs because those orgs are marked for deletion:',
  },
});

class ImportProductsAndResources {
  static UNLIMITED_LABEL = 'UNLIMITED';

  // When a new product is added to the org hierarchy, it gets assigned a new temporary id
  // which is different from the one provided by the user in the input file
  // For e.g.
  // NOTE: product id A & B are product ids assigned by the user in input file
  // product id A (New product) is set as sourceProductID of product id B (New product).
  // When product id A is added to org hierarchy, it is assigned a new product id="NEW_ID_1".
  // When creating product id B, to assign product id A as the sourceProductID of Product id B, we need to fetch
  // detials of product id "NEW_ID_1" from the org hierarchy instead of Product id A.
  // inputOrgIdToOrgIdInHierarchyMap helps in keeping this mapping
  // Mapping is as such: Product id A -> "NEW_ID_1"
  private static inputProductIdToProductIdInHierarchyMap = new Map<string, string>();

  /**
   * Validate if the new product belongs to the org
   * NOTE: the new product's orgId must match the orgId passed in.
   */
  private static isNewProductValid(
    allProducts: ImportProductsDM[],
    productId: string | undefined,
    orgId: string
  ): boolean {
    const productsToBeCreated = ImportUtils.filterToBeCreatedItems(allProducts) as ImportProductsDM[];

    return (
      _.find(
        productsToBeCreated,
        (prod: ImportProductsDM): boolean => _.isEqual(prod.licenseId, productId) && _.isEqual(prod.orgId, orgId)
      ) !== undefined
    );
  }

  /**
   * @param allProducts all products from input file
   * @param productId to be checked
   * @returns true if the 'productId' is marked for deletion else false
   */
  public static isProductMarkedForDelete(allProducts: ImportProductsDM[], productId: string): boolean {
    return (
      _.find(allProducts, (product: ImportProductsDM): boolean => {
        return ImportOperationsUtils.isOperationDelete(product.operation) && product.licenseId === productId;
      }) !== undefined
    );
  }

  /**
   * Check if a product is present in the hierarchy
   */
  private static isProductInHierarchy(orgId: string, productId: string | undefined): boolean {
    const org = ImportOrganizations.getOrgFromHierarchy(orgId);
    return (
      org !== undefined &&
      _.find(org.products, (product: UProduct): boolean => _.isEqual(product.id, productId)) !== undefined
    );
  }

  /**
   * Check if a product is valid or not
   * A product is valid if it a new product or one existing in hierarchy
   * NOTE: It is also verified that the product belongs to the 'orgId' passed in for the new products
   */
  public static isProductValid(allProducts: ImportProductsDM[], productId: string | undefined, orgId: string): boolean {
    if (ImportProductsAndResources.isProductInHierarchy(orgId, productId)) {
      return true;
    }
    return ImportProductsAndResources.isNewProductValid(allProducts, productId, orgId);
  }

  public static getUProductFromHierarchy(orgId: string, productId: string | undefined): UProduct | undefined {
    const org = ImportOrganizations.getOrgFromHierarchy(orgId);
    if (org !== undefined) {
      return _.find(org.products, (prod: UProduct): boolean => _.isEqual(prod.id, productId));
    }
  }

  /**
   * Get productId in the hierarchy for the 'productId' passed in. If 'productId' is an existing product,
   * return the 'productId' back. For new products, get productId in hierarchy from
   * inputProductIdToProductIdInHierarchyMap
   */
  public static getProductIdInHierarchy(productId: string): string {
    return this.inputProductIdToProductIdInHierarchyMap.has(productId)
      ? (this.inputProductIdToProductIdInHierarchyMap.get(productId) as string)
      : productId;
  }

  /**
   * check if the licenseId, orgId & operation is set to valid values
   */
  private static verifyNullChecks(allProducts: ImportProductsDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allProducts, (product: ImportProductsDM, index: number): void => {
      if (ImportOperationsUtils.isOperationValid(product.operation)) {
        // check is product id is blank
        if (_.isEmpty(product.licenseId) && !ImportOperationsUtils.isOperationCreate(product.operation)) {
          errorMessages.push(formatMessage(messages.LicenseIdFieldInvalid, { index: index + 2 }));
        }

        // check is org id is blank
        if (_.isEmpty(product.orgId)) {
          errorMessages.push(formatMessage(messages.OrgIdFieldInvalid, { orgId: product.orgId, index: index + 2 }));
        }

        if (ImportOperationsUtils.isOperationCreate(product.operation) && _.isEmpty(product.sourceLicenseId)) {
          errorMessages.push(
            formatMessage(messages.SourceLicenseIdFieldInvalid, {
              index: index + 2,
            })
          );
        }
      }
    });
    ImportUtils.displayIfError(formatMessage(messages.ProductsLabel), errorMessages);
  }

  /**
   * check if all the product ids are unique in the imported data.
   */
  private static validateUniqueProductIds(allProducts: ImportProductsDM[], intl: IntlShape): void {
    const duplicates = new Set<string>();
    const productIdsFound = new Set<string>();

    allProducts.forEach((product: ImportProductsDM): void => {
      if (ImportUtils.isNullOrEmpty(product.licenseId)) {
        return;
      }
      if (productIdsFound.has(product.licenseId)) {
        duplicates.add(product.licenseId);
      } else {
        productIdsFound.add(product.licenseId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductsLabel),
      formatMessage(messages.LicenseIdDuplicateInputFile),
      Array.from(duplicates)
    );
  }

  /**
   * check if product id and source product id are not same
   */
  private static validateProductAndSourceProductIdNotSame(allProducts: ImportProductsDM[], intl: IntlShape): void {
    // sourceLicenseId & licenseId should not be same for orgs with 'CREATE' operation.
    // NOTE: sourceLicenseId will be ignored for orgs with 'UPDATE' & 'DELETE' operation
    const sameProductAndSourceProductId = _.filter(
      allProducts,
      (product: ImportProductsDM): boolean =>
        ImportOperationsUtils.isOperationCreate(product.operation) &&
        _.isEqual(product.licenseId, product.sourceLicenseId)
    );

    const sameProductIds = sameProductAndSourceProductId.map((product: ImportProductsDM): string => product.licenseId);
    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductsLabel),
      formatMessage(messages.SameLicenseAndSourceLicenseId),
      sameProductIds
    );
  }

  /**
   * check if products with 'update' & 'delete' operation belong to the org
   */
  private static validateProductsInOrg(allProducts: ImportProductsDM[], intl: IntlShape): void {
    const invalidProductIds: string[] = [];

    allProducts.forEach((product: ImportProductsDM): void => {
      // check operation is 'update' | 'delete' and product not in hierarchy
      if (
        (ImportOperationsUtils.isOperationDelete(product.operation) ||
          ImportOperationsUtils.isOperationUpdate(product.operation)) &&
        !this.isProductInHierarchy(product.orgId, product.licenseId)
      ) {
        invalidProductIds.push(product.licenseId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductsLabel),
      formatMessage(messages.LicenseIdNotExist),
      Array.from(invalidProductIds)
    );
  }

  /**
   * Get list of all orgIds on which product operation is to be performed.
   * NOTE: This includes parentOrgIds as well. Products are not loaded for orgIds without a valid operation field
   */
  static getUniqueOrgIds(allProducts: ImportProductsDM[], allOrgs: ImportOrganizationsDM[]): string[] {
    const uniqueOrgIds = new Set<string>();
    _.forEach(allProducts, (product: ImportProductsDM): void => {
      if (ImportOperationsUtils.isOperationValid(product.operation)) {
        uniqueOrgIds.add(product.orgId);
        const parentOrgId = ImportOrganizations.getParentOrgId(product.orgId, allOrgs);
        if (parentOrgId !== undefined) {
          uniqueOrgIds.add(parentOrgId);
        }
      }
    });
    return Array.from(uniqueOrgIds);
  }

  /**
   * loads products for all the orgs present in product import data (including parentOrgId).
   * products for certain orgs down the hierarchy might not have been loaded when the import is
   * performed
   */
  static loadProducts(allProducts: ImportProductsDM[], allOrgs: ImportOrganizationsDM[]): { (): Promise<void> }[] {
    const uniqueOrgIds = ImportProductsAndResources.getUniqueOrgIds(allProducts, allOrgs);
    const loadProducts: { (): Promise<void> }[] = [];
    uniqueOrgIds.forEach((orgId: string) => {
      const currentOrgMaster: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
      // check if the org is present in the org hierarchy
      if (currentOrgMaster !== undefined) {
        // if YES, load the products for that org
        loadProducts.push(() => LoadOrgDataService.loadProducts(currentOrgMaster.id));
      }
    });
    return loadProducts;
  }

  /**
   * product should not be marked for deletion if it selected as a sourceProduct for products with
   * 'create' and 'update' operation
   */
  private static validateSourceProductIdNotMarkedAsDeleted(allProducts: ImportProductsDM[], intl: IntlShape): void {
    // select productIds marked for deletion
    const productIdsMarkedAsDeleted = new Set<string>();
    _.forEach(allProducts, (product: ImportProductsDM): void => {
      if (ImportOperationsUtils.isOperationDelete(product.operation)) {
        productIdsMarkedAsDeleted.add(product.licenseId);
      }
    });

    const invalidSourceProductIDs: string[] = [];

    _.forEach(allProducts, (product: ImportProductsDM): void => {
      // check if any product id with create | update operation references
      // product ids marked for deletion
      if (
        !_.isNil(product.sourceLicenseId) &&
        (ImportOperationsUtils.isOperationCreate(product.operation) ||
          ImportOperationsUtils.isOperationUpdate(product.operation)) &&
        productIdsMarkedAsDeleted.has(product.sourceLicenseId)
      ) {
        invalidSourceProductIDs.push(product.sourceLicenseId);
      }
    });
    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductsLabel),
      formatMessage(messages.LicenseIdSelectedAsSourceLicenseInvalid),
      invalidSourceProductIDs
    );
  }

  /**
   * For products to be created, validate that the sourceProductId is in the DIRECT parentOrg only
   * (No INDIRECT Allocation allowed via Import)
   */
  static validateSourceProductIdInParentOrg(
    allProducts: ImportProductsDM[],
    allOrgs: ImportOrganizationsDM[],
    intl: IntlShape
  ): void {
    // get products to be created
    const productsToBeCreated = ImportUtils.filterToBeCreatedItems(allProducts) as ImportProductsDM[];

    const invalidLicenses = new Set<string>();

    productsToBeCreated.forEach((product: ImportProductsDM): void => {
      // get the parentOrgId for this products's org
      const parentOrgId = ImportOrganizations.getParentOrgId(product.orgId, allOrgs);

      // check if the sourceProduct exists and belongs to the parent org else throw error
      if (parentOrgId === undefined || !this.isProductValid(allProducts, product.sourceLicenseId, parentOrgId)) {
        invalidLicenses.add(
          Utils.getLocalizedMessage(messages.CannotAllocateProductToOrg, {
            orgId: product.orgId,
            sourceLicenseId: product.sourceLicenseId,
          })
        );
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductsLabel),
      formatMessage(messages.SourceProductAbsentFromParentOrg),
      Array.from(invalidLicenses)
    );
  }

  /**
   * map productId to sourceProductId
   */
  static mapProductIdToSourceProductId(allProducts: ImportProductsDM[]): Map<string, string> {
    const productIdToSourceProductIdMap = new Map<string, string>();
    allProducts.forEach((product: ImportProductsDM): void => {
      if (!(_.isNil(product.sourceLicenseId) || _.isEmpty(product.sourceLicenseId))) {
        productIdToSourceProductIdMap.set(product.licenseId, product.sourceLicenseId);
      }
    });
    return productIdToSourceProductIdMap;
  }

  /**
   * validate orgs in products. Org is valid if it in the org hierarchy or if it is to be created
   */
  static validateOrgs(allProducts: ImportProductsDM[], allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allProducts, (product: ImportProductsDM): void => {
      // check if the org is in the hierarchy || if the org is a new org to be created.
      if (!ImportOrganizations.isOrgValid(allOrgs, product.orgId)) {
        errorMessages.push(formatMessage(messages.InvalidOrgId, { orgId: product.orgId }));
      }
    });
    ImportUtils.displayIfError(formatMessage(messages.ProductsLabel), errorMessages);
  }

  /**
   * Validate if any resources are marked for deletion. Resource deletion is an invalid operation.
   */
  static validateResourcesToBeDeleted(allProducts: ImportProductsDM[], intl: IntlShape): void {
    const invalidLicenseIds = new Set<string>();

    allProducts.forEach((eachProduct: ImportProductsDM): void => {
      if (eachProduct.resources !== undefined && ImportUtils.filterToBeDeletedItems(eachProduct.resources).length > 0) {
        invalidLicenseIds.add(eachProduct.licenseId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ResourcesLabel),
      formatMessage(messages.InvalidResourceDeletionOperation),
      Array.from(invalidLicenseIds)
    );
  }

  /**
   * a) check if the licenseId is not allocated multiple resources of same type
   * b) validate that the resource is present in the product resources
   *
   * @param importProductResources resources of the import product
   * @param productResources resources of the product in the org
   */
  static validateImportResources(
    importProductResources: ImportResourcesDM[],
    productResources: UResource[],
    intl: IntlShape
  ): void {
    const licenseIdWithDuplicateResources = new Set<string>();
    const errorMessages: string[] = [];
    const resourcesFound = new Set<string>();
    const { formatMessage } = intl;

    _.forEach(importProductResources, (resource: ImportResourcesDM): void => {
      if (resourcesFound.has(resource.resourceId)) {
        licenseIdWithDuplicateResources.add(resource.licenseId);
        return;
      }

      if (
        _.find(productResources, (prodRes: UResource): boolean => _.isEqual(prodRes.code, resource.resourceId)) ===
        undefined
      ) {
        errorMessages.push(
          formatMessage(messages.InvalidResourceIdForLicense, {
            resourceId: resource.resourceId,
            licenseId: resource.licenseId,
          })
        );
      }
      resourcesFound.add(resource.resourceId);
    });

    ImportUtils.displayIfError(formatMessage(messages.ResourcesLabel), errorMessages);

    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ResourcesLabel),
      formatMessage(messages.DuplicateResourceToALicense),
      Array.from(licenseIdWithDuplicateResources)
    );
  }

  /**
   * validate that the resources to be updated are present on the product in the org
   */
  static validateResourcesToBeUpdated(allProducts: ImportProductsDM[], intl: IntlShape): void {
    const productResourcesToBeUpdated = _.filter(allProducts, (product: ImportProductsDM): boolean => {
      return _.some(product.resources, (resource: ImportResourcesDM): boolean => {
        return (
          ImportOperationsUtils.isOperationUpdate(resource.operation) && !ImportUtils.isNullOrEmpty(resource.resourceId)
        );
      });
    });

    const errorMessages: string[] = [];
    const { formatMessage } = intl;

    _.forEach(productResourcesToBeUpdated, (product: ImportProductsDM): void => {
      if (product.resources !== undefined) {
        // find the product in hierarchy
        const productInHierarchy = this.getUProductFromHierarchy(product.orgId, product.licenseId);
        if (productInHierarchy !== undefined) {
          const resourcesToBeUpdated = ImportUtils.filterToBeUpdatedItems(product.resources) as ImportResourcesDM[];
          ImportProductsAndResources.validateImportResources(resourcesToBeUpdated, productInHierarchy.resources, intl);
        } else {
          // the licenseId is invalid
          errorMessages.push(formatMessage(messages.LicenseIdInvalid, { licenseId: product.licenseId }));
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.ResourcesLabel), errorMessages);
  }

  /**
   * Get all resources from all the products as a list
   */
  static getAllResources(allProducts: ImportProductsDM[]): ImportResourcesDM[] {
    const allResources: ImportResourcesDM[] = [];
    allProducts.forEach((product: ImportProductsDM): void => {
      if (product.resources !== undefined) {
        allResources.push(...product.resources);
      }
    });
    return allResources;
  }

  /**
   * Validate whether the grantedQuantity on resources is either a number | 'UNLIMITED' for products with
   * 'create' and 'update' operation
   */
  static validateGrantedQuantityForResources(allProducts: ImportProductsDM[], intl: IntlShape): void {
    const invalidProductIds = new Set<string>();
    _.forEach(allProducts, (product: ImportProductsDM): void => {
      if (
        ImportOperationsUtils.isOperationUpdate(product.operation) ||
        ImportOperationsUtils.isOperationCreate(product.operation)
      ) {
        _.forEach(product.resources, (resource: ImportResourcesDM): void => {
          // check whether grantedQuantity is set to 'UNLIMITED' | number
          if (
            (ImportOperationsUtils.isOperationCreate(resource.operation) ||
              ImportOperationsUtils.isOperationUpdate(resource.operation)) &&
            !(_.isEqual(resource.grantedQuantity, this.UNLIMITED_LABEL) || Utils.canParseInt(resource.grantedQuantity))
          ) {
            invalidProductIds.add(product.licenseId);
          }
        });
      }
    });
    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ResourcesLabel),
      formatMessage(messages.InvalidGrantedQuantity),
      Array.from(invalidProductIds)
    );
  }

  /**
   * Validate that no operations are performed on type T orgs
   * @param allProducts all products from the input file
   */
  static async validateTypeTOrgs(allProducts: ImportProductsDM[], intl: IntlShape): Promise<void> {
    const orgIds = new Set<string>();
    _.forEach(allProducts, (product: ImportProductsDM): void => {
      if (ImportOperationsUtils.isOperationValid(product.operation)) {
        orgIds.add(product.orgId);
      }
    });

    const { formatMessage } = intl;
    await ImportUtils.throwIfExistingOrgIsReadOnly(
      formatMessage(messages.ProductsLabel),
      formatMessage(messages.ProductOperationNotValidTypeTOrgs),
      Array.from(orgIds)
    );
  }

  /**
   * check if the org has a product allocated from the 'sourceProductId'
   * @returns true if the org has a product allocated from the 'sourceProductId' else false
   */
  static isProductAllocatedFromSourceProductOnOrg(orgId: string, sourceProductID: string): boolean {
    const org = ImportOrganizations.getOrgFromHierarchy(orgId);
    if (org !== undefined && org.products !== null) {
      // check if product is allocated from source product
      return org.products.find((prod: UProduct): boolean => prod.sourceProductId === sourceProductID) !== undefined;
    }
    return false;
  }

  /**
   * An org should only have a single allocation for a product
   */
  static validateDuplicateProductAllocation(allProducts: ImportProductsDM[], intl: IntlShape): void {
    // map orgId to all the sourceProductIds from which products are already allocated to this org
    const orgIdToSourceLicenseIdMap = new Map<string, string[]>();
    const productsToBeCreated = ImportUtils.filterToBeCreatedItems(allProducts) as ImportProductsDM[];
    const errorMessages: string[] = [];
    const { formatMessage } = intl;

    _.forEach(productsToBeCreated, (product: ImportProductsDM): void => {
      if (product.sourceLicenseId !== undefined) {
        // check if product is allocated from the sourceProduct to this org
        if (this.isProductAllocatedFromSourceProductOnOrg(product.orgId, product.sourceLicenseId)) {
          errorMessages.push(
            formatMessage(messages.DuplicateAllocation, {
              orgId: product.orgId,
              sourceLicenseId: product.sourceLicenseId,
            })
          );
          return;
        }

        // check if user is trying to allocated multiple allocations from same sourceLicenseId in input file
        let sourceProductIds: string[] | undefined = orgIdToSourceLicenseIdMap.get(product.orgId);
        if (sourceProductIds === undefined) {
          sourceProductIds = [];
        }

        if (_.findIndex(sourceProductIds, (prod: string): boolean => _.isEqual(prod, product.sourceLicenseId)) > -1) {
          errorMessages.push(
            formatMessage(messages.DuplicateAllocationFromImportFile, {
              orgId: product.orgId,
              sourceLicenseId: product.sourceLicenseId,
            })
          );
        } else {
          // add the current sourceProductId and map back to the orgId
          sourceProductIds.push(product.sourceLicenseId);
          orgIdToSourceLicenseIdMap.set(product.orgId, sourceProductIds);
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.ProductsLabel), errorMessages);
  }

  /**
   * Validate that the org to which the product is to be added is not marked for delete
   * @param allProducts all products from input file
   * @param allOrgs all orgs from input file
   */
  private static validateOrgNotMarkedForDeletion(
    allProducts: ImportProductsDM[],
    allOrgs: ImportOrganizationsDM[]
  ): void {
    const productsToBeCreated = ImportUtils.filterToBeCreatedItems(allProducts) as ImportProductsDM[];
    const orgsToDelete = new Set<string>();
    _.forEach(productsToBeCreated, (product: ImportProductsDM): void => {
      if (ImportOrganizations.isOrgMarkedForDelete(allOrgs, product.orgId)) {
        orgsToDelete.add(this.getProductFormatMessage(product));
      }
    });
    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.ProductsLabel),
      Utils.getLocalizedMessage(messages.InvalidOrgIdMarkedForDelete),
      Array.from(orgsToDelete)
    );
  }

  private static getProductFormatMessage(product: ImportProductsDM): string {
    return Utils.getLocalizedMessage(messages.ProductFormatMessage, {
      sourceLicenseId: product.sourceLicenseId,
      orgId: product.orgId,
    });
  }

  static async validate(
    allProducts: ImportProductsDM[],
    allOrgs: ImportOrganizationsDM[],
    intl: IntlShape
  ): Promise<void> {
    // reset the map on every import
    this.inputProductIdToProductIdInHierarchyMap = new Map<string, string>();

    ImportProductsAndResources.verifyNullChecks(allProducts, intl);
    ImportProductsAndResources.validateUniqueProductIds(allProducts, intl);

    ImportProductsAndResources.validateProductAndSourceProductIdNotSame(allProducts, intl);
    ImportProductsAndResources.validateOrgNotMarkedForDeletion(allProducts, allOrgs);
    ImportProductsAndResources.validateOrgs(allProducts, allOrgs, intl);
    ImportProductsAndResources.validateProductsInOrg(allProducts, intl);
    ImportProductsAndResources.validateDuplicateProductAllocation(allProducts, intl);
    ImportProductsAndResources.validateSourceProductIdNotMarkedAsDeleted(allProducts, intl);
    await ImportProductsAndResources.validateTypeTOrgs(allProducts, intl);
    ImportProductsAndResources.validateSourceProductIdInParentOrg(allProducts, allOrgs, intl);

    // Resource Validation
    ImportProductsAndResources.validateGrantedQuantityForResources(allProducts, intl);
    // NOTE:'delete' operation on a resource will throw an error
    // The operation field on Resources is ignored when the operation field on the product is set
    // as 'create' | 'delete'
    // When the operation on the product is 'update' only then the operation field on Resources is taken into
    // consideration ('update' and 'create' operation only.)
    ImportProductsAndResources.validateResourcesToBeDeleted(allProducts, intl);
    ImportProductsAndResources.validateResourcesToBeUpdated(allProducts, intl);
  }

  /**
   * Get the source UProduct from the parentOrg
   */
  private static getSourceUProductFromParent(
    orgId: string,
    sourceProductId: string,
    allOrgs: ImportOrganizationsDM[]
  ): UProduct | undefined {
    // get the parentOrgId. parentOrgId can be new org or an existing org in hierarchy
    const parentOrgId = ImportOrganizations.getParentOrgId(orgId, allOrgs);

    // if parentOrg itself is a new org, parentOrgId will be an id from the input file & hence its id in the
    // hierarchy will be retrieved from inputOrgIdToOrgIdInHierarchyMap
    const parentOrgIdInHierarchy = ImportOrganizations.getOrgIdInHierarchy(parentOrgId as string);
    const parentUOrgMaster = HierarchyManager.getOrg(parentOrgIdInHierarchy as string);

    if (parentUOrgMaster) {
      // get sourceProductIdInHierarchy
      const sourceProductIdInHierarchy = this.getProductIdInHierarchy(sourceProductId);

      // check if the sourceProductId is present in the parent's products
      return _.find(parentUOrgMaster.products, (eachProduct: UProduct): boolean => {
        return _.isEqual(eachProduct.id, sourceProductIdInHierarchy as string);
      });
    }
  }

  private static cloneSourceProduct(sourceProduct: UProduct): UProduct {
    // clone the source Product
    const clonedProduct = _.cloneDeep(sourceProduct);

    clonedProduct.productProfiles = [];
    clonedProduct.totalProfileCount = 0;
    clonedProduct.profilesLoaded = true; // mark profiles as loaded for a new product fix: https://jira.corp.adobe.com/browse/BANY-333

    // FYI: toggleCreateItem is needed so that the id field is overwritten. If not set, set it
    if (!CommandService.isCreated(clonedProduct.orgId, clonedProduct.id)) {
      clonedProduct.toggleCreateItem();
    }

    // assign temp id
    clonedProduct.setCreatedId(TempIdGenerator.getTempIdAndIncrement());

    // clear granted quantity for each resource
    _.forEach(clonedProduct.resources, (uRes: UResource): void => {
      // eslint-disable-next-line no-param-reassign
      uRes.grantedQuantity = _.isEqual(this.UNLIMITED_LABEL, uRes.grantedQuantity) ? this.UNLIMITED_LABEL : '1';
    });
    return clonedProduct;
  }

  private static findMatchingResource(uResources: UResource[], code: string): UResource | undefined {
    return _.find(uResources, (res: UResource): boolean => _.isEqual(res.code, code));
  }

  /**
   * Update uResource of the product for the corresponding import resource
   * @returns number of updates performed on the product resources
   */
  private static updateResources(uResources: UResource[], allResources: ImportResourcesDM[]): number {
    let resourceUpdateCount = 0;
    _.forEach(allResources, (resource: ImportResourcesDM): void => {
      if (
        ImportOperationsUtils.isOperationUpdate(resource.operation) ||
        ImportOperationsUtils.isOperationCreate(resource.operation)
      ) {
        const matchingResource = this.findMatchingResource(uResources, resource.resourceId);
        const grantedQuantity = this.getGrantedQuantity(resource.grantedQuantity);
        // check if there is a matching resource and the grantedQuantity is different from the one already on the resource
        if (matchingResource !== undefined && !_.isEqual(grantedQuantity, matchingResource.grantedQuantity)) {
          matchingResource.grantedQuantity = grantedQuantity;
          resourceUpdateCount++;
        }
      }
    });
    return resourceUpdateCount;
  }

  private static getProductKey(product: ImportProductsDM): string {
    return `${product.sourceLicenseId}-${product.orgId}`;
  }

  /**
   * adds products and its resources to the org hierarchy
   */
  private static handleProductsToBeCreated(allProducts: ImportProductsDM[], allOrgs: ImportOrganizationsDM[]): number {
    const productsToBeCreated = ImportUtils.filterToBeCreatedItems(allProducts) as ImportProductsDM[];

    const productsAdded = new Set<string>();

    let productsCreatedCount = 0;

    // perform until all the products are added
    while (productsAdded.size < productsToBeCreated.length) {
      // NOTE: Repeatedly check if the sourceProductId for a productId is added into the hierarchy
      // If, YES, clone the sourceProductId and add the product in the respective org
      // sourceProductId/productId can be a new product or an existing product
      // orgId/parentOrgId can be a new org or an existing org

      const productsWithSourceProductIdInHierarchy = _.filter(productsToBeCreated, (eachProduct: ImportProductsDM) => {
        const productKey = this.getProductKey(eachProduct);
        // check if product is not added and sourceProductId is added in the hierarchy
        return (
          !productsAdded.has(productKey) &&
          ImportProductsAndResources.getSourceUProductFromParent(
            eachProduct.orgId,
            eachProduct.sourceLicenseId as string,
            allOrgs
          ) !== undefined
        );
      });

      // no more products left to be added
      if (productsWithSourceProductIdInHierarchy.length < 1) {
        break;
      }

      for (let i = 0; i < productsWithSourceProductIdInHierarchy.length; i++) {
        const inputProduct = productsWithSourceProductIdInHierarchy[i];
        // get UProduct for the sourceProduct
        const sourceProduct = ImportProductsAndResources.getSourceUProductFromParent(
          inputProduct.orgId,
          inputProduct.sourceLicenseId as string,
          allOrgs
        );
        // validate that source product exists
        if (sourceProduct !== undefined) {
          // validate that license is allowed to be edited
          // (cannot allocate product from a non-editable license)
          if (sourceProduct.allLicenseTuplesAllowEditing()) {
            // clone sourceProduct. NOTE: sourceProduct's resources are also cloned.
            const productToAdd = ImportProductsAndResources.cloneSourceProduct(sourceProduct);

            // check if there are resources to be updated
            if (inputProduct.resources !== undefined) {
              this.updateResources(productToAdd.resources, inputProduct.resources);
            }

            // map the input product id to the assigned temp id in hierarchy
            this.inputProductIdToProductIdInHierarchyMap.set(inputProduct.licenseId, productToAdd.id);

            // ADD PRODUCT to the ORG

            // org can be a new org or an existing org
            const orgIdInHierarchy = ImportOrganizations.getOrgIdInHierarchy(inputProduct.orgId);

            const orgMaster = HierarchyManager.getOrg(orgIdInHierarchy as string);
            if (!_.isNil(orgMaster)) {
              productToAdd.orgId = orgMaster.organization.id;
              productToAdd.sourceProductId = sourceProduct.id;
              productToAdd.allowExceedQuotas = ImportUtils.sanitizeBoolean(inputProduct.allowOverallocation);
              CommandService.addEdit(
                orgMaster,
                productToAdd,
                ObjectTypes.PRODUCT,
                OrgOperation.CREATE,
                undefined,
                'ADD_PRODUCT',
                [productToAdd.name, CmdDescriptionUtils.getPathname(productToAdd.orgId)]
              );
              // mark the org has updated
              ImportUtils.markOrgAsEdited(orgMaster.organization.id, EditState.UPDATE);
              productsCreatedCount++;
            }
          } else {
            log.warn('{} product does not allow changes.', sourceProduct.name);
          }
        }

        // mark the product as added
        productsAdded.add(this.getProductKey(inputProduct));
      }
    }
    return productsCreatedCount;
  }

  /**
   * get grantedQuantity based on the grantedQuantity passed in.
   * If the passed in grantedQuantity = 'UNLIMITED' | is a number, return it back else return 0
   */
  private static getGrantedQuantity(grantedQuantity: string | undefined): string {
    // check if the grantedQuantity = 'UNLIMITED'
    if (_.isEqual(this.UNLIMITED_LABEL, grantedQuantity)) {
      return grantedQuantity as string;
    }

    // check if the grantedQuantity is a number
    if (Utils.canParseInt(grantedQuantity)) {
      return grantedQuantity;
    }

    // default grantedQuantity
    return '1';
  }

  /**
   * deletes the products from the org hierarchy
   */
  private static handleProductsToBeDeleted(allProducts: ImportProductsDM[]): number {
    // filter products to be deleted
    const productsToBeDeleted = ImportUtils.filterToBeDeletedItems(allProducts) as ImportProductsDM[];
    let productDeletedCount = 0;

    for (let i = 0; i < productsToBeDeleted.length; i++) {
      const productToDelete = productsToBeDeleted[i];
      const org = ImportOrganizations.getOrgFromHierarchy(productToDelete.orgId);
      if (org !== undefined) {
        const affectedProduct = this.getUProductFromHierarchy(productToDelete.orgId, productToDelete.licenseId);
        // remove the product if it exists and it NOT a purchase product
        if (affectedProduct && !affectedProduct.isPurchase()) {
          // validate that the license is editable
          if (affectedProduct.allLicenseTuplesAllowEditing()) {
            CommandService.addEdit(
              org,
              affectedProduct,
              ObjectTypes.PRODUCT,
              OrgOperation.DELETE,
              undefined,
              'REMOVE_PRODUCT',
              [affectedProduct.name, CmdDescriptionUtils.getPathname(affectedProduct.orgId)]
            );
            // increment the count only when the product was successfully deleted
            productDeletedCount++;
          } else {
            log.warn('{} product does not allow changes.', affectedProduct.name);
          }
        }
      }
    }
    return productDeletedCount;
  }

  private static handleProductDetailsToBeUpdated(allProducts: ImportProductsDM[]): number {
    // filter products to be updated
    const productsToBeUpdated = ImportUtils.filterToBeUpdatedItems(allProducts) as ImportProductsDM[];

    let productUpdatedCount = 0;

    for (let i = 0; i < productsToBeUpdated.length; i++) {
      const inputProductToBeUpdated = productsToBeUpdated[i];

      // get the product to be updated
      const productInHierarchyToBeUpdated = this.getUProductFromHierarchy(
        inputProductToBeUpdated.orgId,
        inputProductToBeUpdated.licenseId
      );
      const originalProduct = _.cloneDeep(productInHierarchyToBeUpdated);

      const allowExceedQuotas = ImportUtils.sanitizeBoolean(inputProductToBeUpdated.allowOverallocation);

      // check if any fields for license in hierarchy are different from the ones in input file
      // Also validate license is NOT a purchase product
      if (
        productInHierarchyToBeUpdated !== undefined &&
        !productInHierarchyToBeUpdated.isPurchase() &&
        !_.isEqual(productInHierarchyToBeUpdated.allowExceedQuotas, allowExceedQuotas)
      ) {
        // validate that the license is editable
        if (productInHierarchyToBeUpdated.allLicenseTuplesAllowEditing()) {
          // NOTE: only allowExceedQuotas and allowExceedUsage are updatable. All other updates are ignored.
          productInHierarchyToBeUpdated.allowExceedQuotas = allowExceedQuotas;
          CommandService.addEdit(
            HierarchyManager.getOrg(productInHierarchyToBeUpdated.orgId) as UOrgMaster,
            productInHierarchyToBeUpdated,
            ObjectTypes.PRODUCT,
            OrgOperation.UPDATE,
            originalProduct,
            'UPDATE_PRODUCT_OVERALLOC',
            [productInHierarchyToBeUpdated.name, CmdDescriptionUtils.getPathname(productInHierarchyToBeUpdated.orgId)]
          );

          // mark the org as updated
          ImportUtils.markOrgAsEdited(productInHierarchyToBeUpdated.orgId, EditState.UPDATE);
          productUpdatedCount++;
        } else {
          log.warn('{} product does not allow changes.', productInHierarchyToBeUpdated.name);
        }
      }
    }
    return productUpdatedCount;
  }

  private static handleProductResourcesToBeUpdated(allProducts: ImportProductsDM[]): number {
    const productResourcesToBeUpdated = _.filter(allProducts, (product: ImportProductsDM): boolean => {
      return _.some(product.resources, (resource: ImportResourcesDM): boolean => {
        return (
          ImportOperationsUtils.isOperationUpdate(resource.operation) && !ImportUtils.isNullOrEmpty(resource.resourceId)
        );
      });
    });

    let updateResourceCount = 0;

    _.forEach(productResourcesToBeUpdated, (inputProduct: ImportProductsDM): void => {
      // get the product to be updated
      const productInHierarchyToBeUpdated = this.getUProductFromHierarchy(inputProduct.orgId, inputProduct.licenseId);
      // validate that license exists and if not a purchase product
      if (
        productInHierarchyToBeUpdated !== undefined &&
        !productInHierarchyToBeUpdated.isPurchase() &&
        inputProduct.resources !== undefined
      ) {
        // validate that the license is editable
        if (productInHierarchyToBeUpdated.allLicenseTuplesAllowEditing()) {
          const originalProduct = _.cloneDeep(productInHierarchyToBeUpdated);
          updateResourceCount += this.updateResources(productInHierarchyToBeUpdated.resources, inputProduct.resources);
          CommandService.addEdit(
            HierarchyManager.getOrg(productInHierarchyToBeUpdated.orgId) as UOrgMaster,
            productInHierarchyToBeUpdated,
            ObjectTypes.PRODUCT,
            OrgOperation.UPDATE,
            originalProduct,
            'UPDATE_PRODUCT_QUANTITY',
            [productInHierarchyToBeUpdated.name, CmdDescriptionUtils.getPathname(productInHierarchyToBeUpdated.orgId)]
          );
        } else {
          log.warn('{} product does not allow changes.', productInHierarchyToBeUpdated.name);
        }
      }
    });

    return updateResourceCount;
  }

  /**
   * updates the products in the org hierarchy
   */
  private static handleProductsToBeUpdated(allProducts: ImportProductsDM[]): number {
    return this.handleProductDetailsToBeUpdated(allProducts) + this.handleProductResourcesToBeUpdated(allProducts);
  }

  static import(allProducts: ImportProductsDM[], allOrgs: ImportOrganizationsDM[]): ChangeCount {
    return {
      deleteCount: ImportProductsAndResources.handleProductsToBeDeleted(allProducts),
      createCount: ImportProductsAndResources.handleProductsToBeCreated(allProducts, allOrgs),
      updateCount: ImportProductsAndResources.handleProductsToBeUpdated(allProducts),
    };
  }
}

export default ImportProductsAndResources;
