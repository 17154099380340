import TreeNode from 'primereact/components/treenode/TreeNode';
import * as _ from 'lodash';
import HierarchyManager from '../../services/organization/HierarchyManager';
import OrgPickerController from '../../services/organization/OrgPickerController';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import { ExpandedNodesUtil } from '../../services/treeTableUtils/ExpandedNodesUtil';

export default class OrgTreeCache {
  orgList: UOrgMaster[] | undefined;
  filtering: boolean;
  filteredOrgIds: string[];
  orgHierarchy: TreeNode | undefined;

  private static model: OrgTreeCache | undefined;

  constructor() {
    this.filtering = false;
    this.filteredOrgIds = [];
    this.orgList = HierarchyManager.getOrgMasters();
    this.orgHierarchy = this.createOrgTreeHierarchy(
      HierarchyManager.getOrg(OrgPickerController.getActiveOrgId() as string) as UOrgMaster
    );
  }

  public getOrgHierarchy(): TreeNode {
    return this.orgHierarchy as TreeNode;
  }

  public updateOrgHierarchy(): void {
    this.orgHierarchy = this.createOrgTreeHierarchy(
      HierarchyManager.getOrg(OrgPickerController.getActiveOrgId() as string) as UOrgMaster
    );
  }

  /**
   * filter org hierarchy given search query
   * This updates the org tree if filtering is on
   * @param searchQuery
   */
  public filterOrgs(searchQuery: string): void {
    this.filtering = !_.isEmpty(searchQuery);
    this.filteredOrgIds = [];
    if (this.filtering) {
      _.forEach(this.orgList, (org: UOrgMaster): void => {
        if (_.includes(_.toLower(org.name), _.toLower(searchQuery))) {
          // include the org to be filtered
          if (!_.includes(this.filteredOrgIds, org.id)) {
            this.filteredOrgIds.push(org.id);
          }
          // include all the parents in the filter list
          let parentOrg: UOrgMaster | undefined = org.getParentOrgMaster();
          while (parentOrg !== undefined) {
            // walk up the tree and include all the ancestors for orgs whose name match the search query
            ExpandedNodesUtil.expandOrg(parentOrg.id);
            if (!_.includes(this.filteredOrgIds, parentOrg.id)) {
              this.filteredOrgIds.push(parentOrg.id);
            }
            parentOrg = parentOrg.getParentOrgMaster();
          }
          // include all children in the filter list
          this.addChildrenToFilteredOrgList(org, this.filteredOrgIds);
        }
      });
    } else if (this.orgList) {
      ExpandedNodesUtil.defaultExpandedNodes();
    }
  }

  /* This will change filteredOrg. DFS traversal and mark all the child orgs to be filtered */
  private addChildrenToFilteredOrgList = (org: UOrgMaster, filteredOrgs: string[]): void => {
    _.forEach(org.getChildren(), (entry: UOrgMaster): void => {
      if (!_.includes(filteredOrgs, entry.id)) {
        filteredOrgs.push(entry.id); // if this org is a child org, push it to filtered list
      }
      this.addChildrenToFilteredOrgList(entry, filteredOrgs); // call the function recursively for the child org
    });
  };

  /**
   *  Recursively creates hierarchy of the input org in a format required by TreeTable
   */
  private createOrgTreeHierarchy = (org: UOrgMaster): TreeNode => {
    const childrenList: UOrgMaster[] = _.filter(org.getChildren(), (childOrg: UOrgMaster): boolean => {
      return !this.filtering || _.includes(this.filteredOrgIds, childOrg.id);
    });
    return {
      key: org.id,
      data: {
        name: org.name,
      },
      children: _.map(childrenList, (childOrg: UOrgMaster): TreeNode => this.createOrgTreeHierarchy(childOrg)),
    };
  };

  public static get(): OrgTreeCache {
    if (OrgTreeCache.model === undefined) {
      OrgTreeCache.model = new OrgTreeCache();
    }
    return OrgTreeCache.model;
  }

  public static clear(): void {
    OrgTreeCache.model = undefined;
  }

  public static isCleared(): boolean {
    return OrgTreeCache.model === undefined;
  }
}
