import { UserType, UserTypeName } from '../../../../services/authentication/IMS';

export type UserTypeMapInterface = { [type in UserType]: UserTypeName };

export class UserTypeMap {
  public static get(): UserTypeMapInterface {
    return {
      TYPE1: UserTypeName.ADOBE_ID,
      TYPE2: UserTypeName.ENTERPRISE_ID,
      TYPE2E: UserTypeName.BUSINESS_ID,
      TYPE3: UserTypeName.FEDERATED_ID,
    };
  }
}
