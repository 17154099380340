import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import React from 'react';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import ComboBox from '@react/react-spectrum/ComboBox';
import { SelectOption } from '@react/react-spectrum/Select';
import Wait from '@react/react-spectrum/Wait';
import * as _ from 'lodash';
import Provider from '@react/react-spectrum/Provider';
import { UAdmin } from '../../../../services/orgMaster/UAdmin';
import '../../../common.css';
import './AdminSection.css';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import JILSearch from '../../services/JILSearch';
import { LocaleSettings } from '../../../../services/locale/LocaleSettings';

/*
 * AdminSection receives these props from the EditProdProfileDialogContent component.
 * - 'admins' list of current admins to be displayed as Tag items
 * - 'adminCompletions' list of admins that can be selected from the dropdown
 * - 'onDeleteAdmin' callback is implemented at the EditProdProfileDialogContent level to update the compartment with the deleted admin data
 * - 'onAddAdmin' callback is implemented at the EditProdProfileDialogContent level to update the compartment with added admin data
 */
interface AdminSectionProps extends WrappedComponentProps {
  selectedOrg: UOrgMaster;
  admins: UAdmin[];
  onDeleteAdmin: (admin: UAdmin) => void;
  onAddAdmin: (email: string) => void;
  labelledby: string;
  disabled?: boolean;
}

/*
 * AdminSection has one state
 * - 'inputEmail' is a variable that updates email input field of the combox element as the state changes
 */
interface AdminSectionState {
  inputEmail: string;
  isLoading: boolean;
  adminSearchResults: string[] | null;
  showMenu: boolean;
}

const messages = defineMessages({
  SelectAdmin: {
    id: 'EditCompartment.EditProfile.AdminSelection',
    defaultMessage: 'Select or enter admin email',
  },
});

class AdminSection extends React.Component<AdminSectionProps, AdminSectionState> {
  searchTimer: NodeJS.Timeout | undefined; // Store the search timer. Previous timer is replaced with a new one while the user is typing. Once the user stops typing, the search is fired after 1second.

  constructor(props: AdminSectionProps) {
    super(props);
    this.state = {
      inputEmail: '',
      isLoading: false,
      adminSearchResults: null,
      showMenu: false,
    };
  }

  componentWillUnmount(): void {
    // clear timeout if component is unmounted
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }

  availableAdmins(): string[] {
    return _.chain<UAdmin>(this.props.selectedOrg.getAdmins())
      .differenceBy(this.props.admins, 'email')
      .map('email')
      .value();
  }

  /*
   *  Reset the input state for the Combobox element and pass the selected item to the add callback function to update the compartment
   */
  private onSelect = (email: string | SelectOption): void => {
    if (typeof email === 'string') {
      this.setState({ inputEmail: '', adminSearchResults: null });
      this.props.onAddAdmin(email);
    }
  };

  /*
   * Update the input state for the Combobox element based on user's input
   */
  private onChange = (input: string): void => {
    this.setState({
      inputEmail: input,
      adminSearchResults: null,
    });
    const validInput = !_.isEmpty(input) && input.length >= 3 && !_.includes(this.availableAdmins(), input); // the input is valid if its empty, has length greater than 3 and user group is not included in the current selection list
    if (validInput) {
      if (this.searchTimer) {
        // clear out the previous timer as the user is still typing
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(async (): Promise<void> => {
        if (!this.props.selectedOrg.adminsLoaded || this.props.selectedOrg.isMoreAdmins()) {
          this.setState({ isLoading: true });
          await JILSearch.adminSearch(this.props.selectedOrg, input);
        }
        const availableAdmins = this.availableAdmins();
        const matches = _.filter(availableAdmins, (adminEmail: string): boolean => _.includes(adminEmail, input));
        this.setState({
          adminSearchResults: matches,
          showMenu: false,
          isLoading: false,
        });
      }, 1000); // timer is required to fire the search call only when the user stops typing
    }
  };

  public render(): React.ReactNode {
    // Get the 'Tag' elements to be displayed for admins by iterating through the admins lists passed as props
    const { formatMessage } = this.props.intl;
    const adminListDisplay = this.props.admins.map((admin: UAdmin): React.ReactNode => {
      return (
        <Provider key={admin.email + admin.userType} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
          <TagList
            disabled={this.props.disabled}
            onClose={(): void => this.props.onDeleteAdmin(admin)}
            data-testid="profile-admin-taglist"
          >
            <Tag closeable>{admin.email} </Tag>
          </TagList>
        </Provider>
      );
    });

    return (
      <React.Fragment>
        <span data-testid="admin-selection-combobox">
          <ComboBox
            className="AdminSection__admins EditCompartment__EditProfileDialog__UserGroupSectionAndAdminSection"
            disabled={this.props.disabled}
            data-testid="edit-profile-and-usergroup-dialog-admin-section-combobox"
            placeholder={formatMessage(messages.SelectAdmin)}
            options={this.availableAdmins()}
            aria-label={formatMessage(messages.SelectAdmin)}
            onSelect={this.onSelect}
            onChange={this.onChange}
            onBlur={(): void => this.setState({ showMenu: true })}
            onFocus={(): void => this.setState({ showMenu: false })}
            onMenuToggle={(): void => {
              this.setState((state) => ({ showMenu: !state.showMenu }));
            }}
            value={this.state.inputEmail}
            showMenu={this.state.showMenu}
            aria-labelledby={this.props.labelledby}
            aria-describedby="admin-section-description"
          />
        </span>
        <span className=" EditCompartment__font--light" id="admin-section-description">
          {this.state.isLoading && (
            <span>
              <Wait className="EditCompartment__margin--top" size="S" />
              <span className="EditCompartment__margin--left">
                <FormattedMessage
                  id="EditCompartment.EditProfileOrUserGroupDialog.AdminSection.SearchInProgress"
                  defaultMessage="Search in progress"
                />
              </span>
            </span>
          )}
          <div>
            <FormattedMessage
              id="EditCompartment.EditProfileOrUserGroupDialog.AdminSection.EnterAdmin"
              defaultMessage="Enter the admin email to search admins not in the list"
            />
          </div>
          {!_.isEmpty(this.state.adminSearchResults) && (
            <div>
              <FormattedMessage
                id="EditCompartment.EditProfileOrUserGroupDialog.AdminSection.AdminSearchComplete"
                defaultMessage="Admin search complete, click dropdown to see more admins"
              />
            </div>
          )}
          {this.state.adminSearchResults && this.state.adminSearchResults.length === 0 && (
            <div>
              <FormattedMessage
                id="EditCompartment.EditProfileOrUserGroupDialog.AdminSection.AdminNotFound"
                defaultMessage="This admin does not exist. You can add new admins to the profile from the Admins tab after it is created."
              />
            </div>
          )}
        </span>
        <div data-testid="adminsection-selected-admins">{adminListDisplay}</div>
      </React.Fragment>
    );
  }
}
export default injectIntl(AdminSection);
