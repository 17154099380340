import { OwnershipStatus } from '@pandora/administration-core-types';
import { DirectoryStatus, TrustStatus } from '@pandora/data-model-directory';
import { DirectoryDomainEnforcementState } from '@pandora/react-directory-domain-enforcement-status';
import { UBasic, UBasicData } from './UBasic';

/**
 * These likely correspond to
 * https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-entities/src/main/java/com/adobe/jil/entities/domains/IMSFederationType.java
 */
export enum DirectoryType {
  TYPE1 = 'TYPE1', // Adobe ID
  TYPE2 = 'TYPE2', // Enterprise ID
  TYPE2E = 'TYPE2E', // Business ID  TODO: is this indeed valid for directories?
  TYPE3 = 'TYPE3', // Federated ID
}

/**
 * Based on:
 * https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-organizations-entities/src/main/java/com/adobe/jil/organizations/entities/directories/Directory.java
 */
export interface UDirectoryTentantInfo {
  imsTenantInfo: {
    id: string;
    authSrcCode: string;
    defaultIdp: string;
    idps: [
      {
        id: string;
        type: string;
        testUrl: string;
      }
    ];
  };
  adminuiTenantStatus: string;
  imsTenantAdminStatus: null | string;
}

export interface DomainEnforcementInfo {
  authSrc: string;
  state: string;
  notifyAt?: number;
  stateEndsAt?: number;
}

export interface UDirectoryData extends UBasicData {
  id: string;
  name: string;
  externalId?: string;
  type?: DirectoryType;
  ownershipStatus?: OwnershipStatus;
  tenantInfo?: UDirectoryTentantInfo;
  domainCount?: number;
  externallyManaged?: boolean;
  notifyAdminsDomainMigrationComplete?: boolean;
  status?: DirectoryStatus | TrustStatus;
  domainEnforcement?: DomainEnforcementInfo;
}

export class UDirectory extends UBasic implements UDirectoryData {
  name: string = '';
  externalId?: string;
  type?: DirectoryType;
  ownershipStatus?: OwnershipStatus;
  tenantInfo?: UDirectoryTentantInfo;
  domainCount?: number;
  externallyManaged?: boolean;
  notifyAdminsDomainMigrationComplete?: boolean;
  status?: DirectoryStatus | TrustStatus; // This is combination of DirectoryStatus and TrustStatus depending on the OwnershipStatus
  domainEnforcement?: DomainEnforcementInfo; // Received only if Renga has Domain enforcement data available.

  constructor(directory?: UDirectoryData) {
    super(directory);
    if (directory) {
      this.name = directory.name || this.name;
      this.externalId = directory.externalId || this.externalId;
      this.type = directory.type || this.type;
      this.ownershipStatus = directory.ownershipStatus || this.ownershipStatus;
      this.tenantInfo = directory.tenantInfo || this.tenantInfo;
      this.domainCount = directory.domainCount || this.domainCount;
      this.externallyManaged = directory.externallyManaged || this.externallyManaged;
      this.status = directory.status || this.status;
      this.notifyAdminsDomainMigrationComplete =
        directory.notifyAdminsDomainMigrationComplete || this.notifyAdminsDomainMigrationComplete;
      this.domainEnforcement = directory.domainEnforcement || this.domainEnforcement;
    }
  }

  /*
   * Convert Directory type from Type 1, Type 2, Type2e, Type3 to Adobe ID, Enterprise ID, Federated ID
   * respectively for display
   */
  public static getDirectoryTypeDisplayName = (directoryType: DirectoryType | undefined): string => {
    switch (directoryType) {
      case DirectoryType.TYPE1:
        return 'Adobe ID';
      case DirectoryType.TYPE2:
        return 'Enterprise ID';
      case DirectoryType.TYPE2E:
        return 'Business ID';
      case DirectoryType.TYPE3:
        return 'Federated ID';
      default:
        return '';
    }
  };

  /*
   * Check if a UDirectory's 'domainEnforcement.state' is one of the values defined in enum 'DirectoryDomainEnforcementState'.
   * Returns true if state is one of the DirectoryDomainEnforcementState value; false otherwise.
   *
   * Refer: https://jira.corp.adobe.com/browse/ABPQE-775
   */
  public static isDomainEnforcementStateToBeDisplayed = (state: string | undefined): boolean => {
    if (state && DirectoryDomainEnforcementState[state as DirectoryDomainEnforcementState]) {
      return true;
    }
    return false;
  };
}
