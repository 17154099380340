import * as _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSupportCasesContent } from './SupportCasesContent';
import { JilModelList } from '@pandora/data-model-list';
import {
  SUPPORT_TICKET_LIST_ENTITY_NAME,
  SUPPORT_TICKET_QUERY_PARAM,
  SUPPORT_TICKET_RECORD_TYPE,
  SupportTicket,
  useSupportTicketListHook,
} from '@pandora/data-model-support-ticket';
import { SupportTicketListView, filterSupportTicketList } from '@pandora/react-support-ticket';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { Item, ProgressCircle, TabList, TabPanels, Tabs } from '@adobe/react-spectrum';
import { defineMessages, useIntl } from 'react-intl';
import FullErrorPage from '../../../components/FullErrorPage/FullErrorPage';
import { useParams } from 'react-router-dom';
import log from 'loglevel';
import { useAuth } from '@pandora/react-auth-provider';
import PandoraPaginationContentProvider from '../../../providers/PandoraPaginationContentProvider';
import JilAPIProvider from '../../../providers/JilAPIProvider';
import { Product } from '@pandora/data-model-product';

interface TabContent {
  key: string;
  name: string;
  content: JSX.Element;
}
function SupportCases() {
  const content = useSupportCasesContent();
  const { getSupportTicketList } = useSupportTicketListHook();
  const abortController = useRef(new AbortController());
  const rootOrgId = OrgPickerController.getActiveOrgId() ?? '';
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [closedSupportTicketList, setClosedSupportTicketList] = useState([] as SupportTicket[]);
  const [openSupportTicketList, setOpenSupportTicketList] = useState([] as SupportTicket[]);
  const [products, setProducts] = useState([] as Product[]);
  const intl = useIntl();
  const pathParams = useParams();
  const userId = useAuth()?.getUserProfile?.()?.userId ?? '';
  const supportTicketDetails = ({ labelText }: { labelText: string }) => <span>{labelText}</span>;

  const getFilteredSupportTicketList = async (status: SUPPORT_TICKET_QUERY_PARAM, prods: Product[]) => {
    const supportTicketList = await getSupportTicketList({
      locale: LocaleSettings.getLocale(),
      orgId: rootOrgId,
      recordType: SUPPORT_TICKET_LIST_ENTITY_NAME.CASES,
      status,
    });
    const productList = new JilModelList<Product>();
    productList.items = prods;

    return filterSupportTicketList(
      false, //isSupportCase is always false
      true, // we are sending true for a global admin because anyone is a global admin in the UI
      productList,
      [],
      supportTicketList.items,
      userId
    );
  };

  const messages = defineMessages({
    SupportCasesErrorMessage: {
      id: 'SupportForGA.SupportCases.ErrorMessage',
      defaultMessage: 'Unable to fetch data',
    },
    OpenCases: {
      id: 'SupportForGA.SupportCases.OpenCases',
      defaultMessage: 'Open Cases',
    },
    ClosedCases: {
      id: 'SupportForGA.SupportCases.ClosedCases',
      defaultMessage: 'Closed Cases',
    },
    TabLabel: {
      id: 'SupportForGA.SupportCases.TabLabel',
      defaultMessage: 'Support ticket tabs',
    },
  });

  useEffect(() => {
    (async (): Promise<void> => {
      if (!abortController.current.signal.aborted) {
        setIsLoading(true);
        try {
          const jilProds = await JilAPIProvider.getProducts(rootOrgId, abortController.current.signal);
          const prods = _.map(jilProds, (p) => new Product(p));
          const openTickets = await getFilteredSupportTicketList(SUPPORT_TICKET_QUERY_PARAM.ACTIVE, prods);
          const closedTickets = await getFilteredSupportTicketList(SUPPORT_TICKET_QUERY_PARAM.INACTIVE, prods);
          if (!abortController.current.signal.aborted) {
            setOpenSupportTicketList(openTickets);
            setClosedSupportTicketList(closedTickets);
            setProducts(prods);
          }
        } catch (error) {
          if (!abortController.current.signal.aborted) {
            log.error('Unable to fetch support ticket list', error);
            setIsServerError(true);
          }
        } finally {
          if (!abortController.current.signal.aborted) {
            setIsLoading(false);
          }
        }
      }
    })();
  }, [rootOrgId]);

  useEffect(() => {
    return function cleanup(): void {
      abortController.current.abort();
    };
  }, []);

  const tabs = useMemo((): TabContent[] => {
    const generateSupportTicketListView = (isActiveList: boolean) => {
      if (isLoading) {
        //return the loading symbol
        return (
          <>
            <div className="Support_body">
              <ProgressCircle size="M" isIndeterminate top="50%" left="50%" aria-label="Loading" />
            </div>
          </>
        );
      }
      if (isServerError) {
        return <FullErrorPage errorMessage={intl.formatMessage(messages.SupportCasesErrorMessage)} />;
      }
      const supportTicketList = new JilModelList<SupportTicket>();
      supportTicketList.items = isActiveList ? openSupportTicketList : closedSupportTicketList;
      const productList = new JilModelList<Product>();
      productList.items = products;
      //mandatory props needed to render the table using pandora (returns pandora component)
      return (
        <PandoraPaginationContentProvider>
          {!isLoading && (
            <SupportTicketListView
              content={content}
              data-testid="support-ticket-list-view"
              getSupportTicketDetailsLink={supportTicketDetails}
              productList={productList}
              queryParam={isActiveList ? SUPPORT_TICKET_QUERY_PARAM.ACTIVE : SUPPORT_TICKET_QUERY_PARAM.INACTIVE}
              recordType={SUPPORT_TICKET_RECORD_TYPE.CASE}
              supportTicketList={supportTicketList}
            />
          )}
        </PandoraPaginationContentProvider>
      );
    };
    //will render the list (either open or closed depending on the condition)
    return [
      {
        content: generateSupportTicketListView(true),
        name: intl.formatMessage(messages.OpenCases),
        key: 'open',
      },
      {
        content: generateSupportTicketListView(false),
        name: intl.formatMessage(messages.ClosedCases),
        key: 'closed',
      },
    ];
  }, [closedSupportTicketList, content, intl, isLoading, openSupportTicketList, products, pathParams]);

  return (
    <Tabs aria-label={intl.formatMessage(messages.TabLabel)} items={tabs} defaultSelectedKey="open">
      <TabList>{(item: TabContent) => <Item key={item.key}>{item.name}</Item>}</TabList>
      <TabPanels>{(item: TabContent) => <Item key={item.key}>{item.content}</Item>}</TabPanels>
    </Tabs>
  );
}

export default SupportCases;
