import { defineMessages, MessageDescriptor } from 'react-intl';

/**
 * This would be central place for defining messages for users, generated from `Job Execution` tab, which would need react-intl feature.
 * Defining messages here would help avoid react-intl related code duplication.
 * e.g. Error messages, Common headers/title, Dialogs etc.
 */
export const MESSAGES: Record<string, MessageDescriptor> = defineMessages({
  GetJobsApiError: {
    id: 'BanyanJobsApi.GetJobs.Error',
    defaultMessage: 'Unable to load recent jobs',
  },
  PostJobsApiError: {
    id: 'BanyanJobsApi.PostJobs.Error',
    defaultMessage: 'Failed to create job.',
  },
  GetJobProgressApiError: {
    id: 'BanyanJobsApi.GetJobProgress.Error',
    defaultMessage: 'Unable to get job details',
  },
  CancelJobApiError: {
    id: 'BanyanJobsApi.CancelJob.Error',
    defaultMessage: 'Failed to cancel job.',
  },
  CreateJobError: {
    id: 'BanyanJobs.CreateJob.Error',
    defaultMessage: 'JobId not found',
  },
  OrganizationExportJobError: {
    id: 'BanyanJobsApi.OrganizationExportJobError.Error',
    defaultMessage: 'Failed to create organization export job.',
  },
});
