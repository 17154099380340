import React from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

export interface RouteComponentProps {
  navigate: NavigateFunction;
}

type NonRouteProps<P> = Omit<P, keyof RouteComponentProps>;

/**
 * Use this function to wrap class components if the component needs access to react-router navigate.
 * This function will not be needed one we are no longer using class components.
 *
 * https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
 * https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
 */
const withRouter = <P extends RouteComponentProps>(Component: React.ComponentType<P>): React.FC<NonRouteProps<P>> => {
  return (props: NonRouteProps<P>): React.ReactElement => {
    const navigate = useNavigate();
    const allProps: P = { navigate, ...props } as P;
    return <Component {...allProps} />;
  };
};
export default withRouter;
