import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import _ from 'lodash';
import Search from '@react/react-spectrum/Search';
import Checkbox from '@react/react-spectrum/Checkbox';
import Dialog from '@react/react-spectrum/Dialog';
import Wait from '@react/react-spectrum/Wait';
import { TreeTable } from 'primereact/treetable';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { Column } from 'primereact/column';
import AlertBanner from '../../../../components/AlertBanner/AlertBanner';
import Analytics from '../../../../Analytics/Analytics';
import { ExpandedNodes } from '../../../../services/treeTableUtils/ExpandedNodesUtil';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import { OrganizationTemplateData } from '../../../../models/OrganizationTemplate';
import BanyanCompartmentAPI from '../../../../providers/BanyanCompartmentAPI';
import TemplatePolicy, { LockActionEnum } from '../../../../models/TemplatePolicy';
import { MESSAGES } from '../../../Messages';
import './ApplyTemplate.css';
import { CommandService } from '../../../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import { UCompartmentPolicies } from '../../../../services/orgMaster/UCompartmentPolicy';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';

interface ApplyTemplateProps extends WrappedComponentProps {
  rootOrgId: string;
  templateId: string;
  templateName: string;
  update: () => void;
}

interface ApplyTemplateState {
  isLoading: boolean;
  selectedOrgs: ExpandedNodes;
  expandedOrgs: ExpandedNodes;
  template: OrganizationTemplateData | null;
  rootNode: TreeNode;
  searchInput: string;
  searchResultRootNode: TreeNode;
  areAllOrgSelected: boolean;
  errorMsg?: string;
}

const messages = defineMessages({
  ApplyTemplateTitle: {
    id: 'EditCompartment.Templates.Apply.Title',
    defaultMessage: 'Apply {templateName} to organizations',
  },
  Include: {
    id: 'EditCompartment.Templates.Apply.Include',
    defaultMessage: 'Include',
  },
  Name: {
    id: 'EditCompartment.Templates.Apply.Name',
    defaultMessage: 'Name',
  },
  OrgId: {
    id: 'EditCompartment.Templates.Apply.Id',
    defaultMessage: 'Organization ID',
  },
  Apply: {
    id: 'EditCompartment.Templates.Apply.Apply',
    defaultMessage: 'Apply template',
  },
  Cancel: {
    id: 'EditCompartment.Templates.Apply.Cancel',
    defaultMessage: 'Cancel',
  },
  SearchPlaceholder: {
    id: 'EditCompartment.Templates.Apply.SearchPlaceholder',
    defaultMessage: 'Search',
  },
  ReviewPendingChanges: {
    id: 'EditCompartment.Templates.Apply.ReviewPendingChanges',
    defaultMessage: 'Review pending changes',
  },
  NoChangesAfterApplyingTemplate: {
    id: 'EditCompartment.Templates.Apply.NoChangesAfterApplyingTemplate',
    defaultMessage: 'All organization policies already match the template;  no changes made.',
  },
});

class ApplyTemplate extends React.Component<ApplyTemplateProps, ApplyTemplateState> {
  constructor(props: ApplyTemplateProps) {
    super(props);
    Analytics.fireCTAEvent(`Apply template dialog opened`);
    const rootNode = this.rootOrgHierarchy();
    this.state = {
      isLoading: false,
      selectedOrgs: {
        [props.rootOrgId]: true,
      },
      expandedOrgs: {
        [props.rootOrgId]: true,
      },
      template: null,
      rootNode,
      searchInput: '',
      searchResultRootNode: rootNode,
      areAllOrgSelected: false,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.loadTemplate();
  }

  private loadTemplate = async (): Promise<void> => {
    const { rootOrgId, templateId } = this.props;
    const { formatMessage } = this.props.intl;
    if (!templateId) {
      return;
    }
    this.setState({ isLoading: true, errorMsg: undefined });
    try {
      const responseData: OrganizationTemplateData = await BanyanCompartmentAPI.getPolicyTemplate(
        rootOrgId,
        templateId
      );
      if (responseData) {
        this.setState({ template: responseData });
      } else {
        this.setState({ template: null });
      }
    } catch (error) {
      this.setState({ template: null, errorMsg: `${formatMessage(MESSAGES.TemplateGETApiError)} : ${error.message}` });
    }
    this.setState({ isLoading: false });
  };

  private rootOrgHierarchy = (): TreeNode => {
    return this.getOrgHierarchy(HierarchyManager.getOrg(this.props.rootOrgId) as UOrgMaster);
  };

  /**
   *  Recursively creates hierarchy of the input org in a format required by TreeTable
   */
  private getOrgHierarchy = (org: UOrgMaster): TreeNode => {
    return {
      key: org.organization.id,
      data: {
        name: org.organization.name,
        id: org.organization.id,
      },
      children: _.map(org.getChildren(), (childOrg: UOrgMaster): TreeNode => this.getOrgHierarchy(childOrg)),
    };
  };

  /**
   *  Recursively traverse hierarchy of the input org to filter organizations matching search query
   *
   *  @return TreeNode as root of hierarchy if node(s) exist that match searchQuery, else null.
   */
  private getFilteredOrgHierarchy = (
    currentRoot: TreeNode,
    searchQuery: string,
    orgsToExpand: ExpandedNodes
  ): TreeNode | null => {
    const childrenList: TreeNode[] = [];
    // find all decendants of currentRoot that match searchQuery
    _.forEach(currentRoot.children, (child: TreeNode) => {
      const validChildNode = this.getFilteredOrgHierarchy(child, searchQuery, orgsToExpand);
      if (validChildNode) {
        childrenList.push(validChildNode);
      }
    });

    // if currentRoot as well as none of it's decendants match searchQuery, return null
    if (!_.includes(_.toLower(currentRoot.data.name), _.toLower(searchQuery)) && _.isEmpty(childrenList)) {
      return null;
    }

    // Either currentRoot or it's decendants match searchQuery, hence expand currentRoot
    // eslint-disable-next-line no-param-reassign
    orgsToExpand[currentRoot.data.id] = true;

    return {
      key: currentRoot.key,
      data: {
        name: currentRoot.data.name,
        id: currentRoot.data.id,
      },
      children: childrenList,
    };
  };

  private checkUncheckAllOrg = (): void => {
    this.setState((state) => {
      const rootNode: TreeNode = { ...state.rootNode };
      const selectedOrgs: ExpandedNodes = { ...state.selectedOrgs };
      const expandedOrgs: ExpandedNodes = { [state.rootNode.key]: true, ...state.expandedOrgs };

      const orgsToConsider: TreeNode[] = [rootNode];
      let currentNode: TreeNode | undefined = rootNode;
      while (currentNode) {
        if (state.areAllOrgSelected) {
          // de-select already selected orgs
          delete selectedOrgs[currentNode.key];
          if (currentNode.key !== rootNode.key) {
            // un-expand every node except root node
            delete expandedOrgs[currentNode.key];
          }
        } else {
          selectedOrgs[currentNode.key] = true;
          expandedOrgs[currentNode.key] = true;
        }
        currentNode.children.forEach((childNode) => orgsToConsider.push(childNode));
        currentNode = orgsToConsider.pop();
      }
      return {
        selectedOrgs,
        expandedOrgs,
        areAllOrgSelected: !state.areAllOrgSelected,
        errorMsg: undefined,
      };
    });
  };

  private checkUncheckOrg = (orgId: string): void => {
    this.setState((state) => {
      const selectedOrgs: ExpandedNodes = { ...state.selectedOrgs };
      let allOrgSelectedFlag = state.areAllOrgSelected;
      const expandedOrgs: ExpandedNodes = { ...state.expandedOrgs, [state.rootNode.key]: true };
      if (selectedOrgs[orgId]) {
        delete selectedOrgs[orgId];
        if (allOrgSelectedFlag) {
          // not all orgs are selected now
          allOrgSelectedFlag = false;
        }
      } else {
        selectedOrgs[orgId] = true;
        expandedOrgs[orgId] = true;
      }
      return {
        selectedOrgs,
        expandedOrgs,
        areAllOrgSelected: allOrgSelectedFlag,
        errorMsg: undefined,
      };
    });
  };

  private checkboxTemplate = (node: TreeNode): React.ReactNode => {
    const { selectedOrgs } = this.state;
    return (
      <Checkbox
        checked={selectedOrgs[node.key] === true}
        onClick={(): void => {
          this.checkUncheckOrg(node.key);
        }}
      />
    );
  };

  private isApplyButtonDisabled = (): boolean => {
    return (
      _.isEmpty(this.state.template) ||
      !_.isEmpty(this.state.errorMsg) ||
      Object.keys(this.state.selectedOrgs).length === 0
    );
  };

  /**
   * Retrieve current edits to policies of a given org associated with `orgId`
   */
  getEditsToPoliciesForOrg = (orgId: string): UCompartmentPolicies => {
    const currentEditsForPolicies =
      (_.find(
        CommandService.getElementEdits(orgId, ObjectTypes.COMPARTMENT_POLICY),
        (edit) => edit.operation === OrgOperation.UPDATE
      )?.elem as UCompartmentPolicies) || new UCompartmentPolicies();

    if (!currentEditsForPolicies.orgId) {
      // When no commands exist for `orgId`, set following for `new UCompartmentPolicies()`
      currentEditsForPolicies.orgId = orgId;
      currentEditsForPolicies.setId(`${UCompartmentPolicies.POLICY_PREFIX}${orgId}`); // Note: The id field for policies is ignored on backend. Its only required for uniquely identifying commands on frontend.
    }
    return currentEditsForPolicies;
  };

  /**
   * Remove policy edit if same as that of original UOrgMaster
   * @param editsToPolicies current policy edits for an org
   * @param currentUOrgMaster current UOrgMaster which might be edited and not same as original org.
   */
  removeEditsIfSameAsOriginal = (
    editsToPolicies: UCompartmentPolicies,
    currentUOrgMaster: UOrgMaster
  ): UCompartmentPolicies => {
    const editsToReturn: UCompartmentPolicies = _.cloneDeep(editsToPolicies);
    const originalPolicies = !currentUOrgMaster.isNewOrg()
      ? HierarchyManager.getOrg(currentUOrgMaster.id)?.compartmentPolicy
      : undefined;
    Object.values(editsToPolicies.policies).forEach((organizationPolicy) => {
      if (
        originalPolicies &&
        organizationPolicy.name &&
        organizationPolicy.value === originalPolicies.policies[organizationPolicy.name].value &&
        organizationPolicy.lockedBy === originalPolicies.policies[organizationPolicy.name].lockedBy
      ) {
        delete editsToReturn.policies[organizationPolicy.name];
      } else if (
        // For new org, compare against default values
        !originalPolicies &&
        organizationPolicy.name &&
        organizationPolicy.value === organizationPolicy.defaultValue &&
        organizationPolicy.lockedBy === undefined
      ) {
        delete editsToReturn.policies[organizationPolicy.name];
      }
    });
    return editsToReturn;
  };

  private applyTemplate = async (): Promise<boolean> => {
    let wasAnyOrgPolicyModified: boolean = false;
    this.setState({ isLoading: true, errorMsg: undefined });
    Analytics.fireCTAEvent(`Apply template dialog confirm clicked`);
    // Iterate over each selected org to get corresponding UOrgMaster
    await Promise.all(
      Object.keys(this.state.selectedOrgs).map(async (orgId: string) => {
        // Retrieve org corresponding to orgId
        let currentUOrgMaster: UOrgMaster = HierarchyManager.getOrg(orgId) as UOrgMaster;
        if (!currentUOrgMaster.policiesLoaded) {
          await LoadOrgDataService.loadPolicies(currentUOrgMaster.id);
        }
        currentUOrgMaster = HierarchyManager.getOrg(orgId) as UOrgMaster; // re-initialize after loading policies
        const originalPolicies = _.cloneDeep(currentUOrgMaster.compartmentPolicy);
        let editsToPolicies = this.getEditsToPoliciesForOrg(orgId);
        const editsToPoliciesBeforeApplyingTemplate = _.cloneDeep(editsToPolicies);

        // Iterate over all policies belonging to template
        if (this.state.template) {
          this.state.template.policies.forEach((templatePolicy: TemplatePolicy) => {
            // todo: check for restrictions on updating policy values in banyansvc
            // When no edit exists for policy named `templatePolicy.name` for a selected org
            if (
              !editsToPolicies.policies[templatePolicy.name] && // policy is not in edited set and ...
              currentUOrgMaster.compartmentPolicy.policies[templatePolicy.name] // policy is defined
            ) {
              editsToPolicies.policies[templatePolicy.name] = _.cloneDeep(
                currentUOrgMaster.compartmentPolicy.policies[templatePolicy.name]
              );
            }

            if (editsToPolicies.policies[templatePolicy.name] !== undefined) {
              // undefined can happen if the template contains policies which have been removed as policies.

              // Update policy value for a org in UOrgMaster using value defined in template
              editsToPolicies.policies[templatePolicy.name].value = templatePolicy.value;

              // Update policy lock value for a org in UOrgMaster using value defined in template
              // If lockAction in template is AS_IS, no changes to be made to lockedBy field
              // If lockAction is LOCK, updated lockedBy to current root org
              // If lockAction is UNLOCK, updated lockedBy to undefined
              if (templatePolicy.lockAction === LockActionEnum.UNLOCK) {
                editsToPolicies.policies[templatePolicy.name].lockedBy = undefined;
              } else if (templatePolicy.lockAction === LockActionEnum.LOCK) {
                editsToPolicies.policies[templatePolicy.name].lockedBy = this.props.rootOrgId;
              }
              editsToPolicies = this.removeEditsIfSameAsOriginal(editsToPolicies, currentUOrgMaster);
            }
          });
          if (!_.isEmpty(editsToPolicies.policies)) {
            // when template values are same as current policy values
            CommandService.addEdit(
              currentUOrgMaster,
              editsToPolicies,
              ObjectTypes.COMPARTMENT_POLICY,
              OrgOperation.UPDATE,
              originalPolicies,
              'UPDATE_POLICY',
              [CmdDescriptionUtils.getPathname(currentUOrgMaster.organization.id)]
            );
          }
          const editsToPoliciesByTemplate = _.find(
            CommandService.getElementEdits(orgId, ObjectTypes.COMPARTMENT_POLICY),
            (edit) => edit.operation === OrgOperation.UPDATE
          )?.elem as UCompartmentPolicies;
          if (
            editsToPoliciesByTemplate &&
            !_.isEqual(editsToPoliciesByTemplate, editsToPoliciesBeforeApplyingTemplate)
          ) {
            wasAnyOrgPolicyModified = true;
          }
          this.props.update();
        }
      })
    );
    const { formatMessage } = this.props.intl;
    this.setState({
      isLoading: false,
      errorMsg: wasAnyOrgPolicyModified ? undefined : `${formatMessage(messages.NoChangesAfterApplyingTemplate)}`,
    });
    return wasAnyOrgPolicyModified;
  };

  private onSearchQueryChange = (searchInput: string): void => {
    const filterQuery = _.trim(searchInput);
    if (_.isEmpty(filterQuery)) {
      // reset hierarchy if the searchInput is empty
      this.setState((state) => {
        return {
          searchResultRootNode: state.rootNode,
          searchInput,
          errorMsg: undefined,
        };
      });
    } else {
      this.setState((state) => {
        let expandedOrgsForSearch = { [state.rootNode.key]: true, ...state.expandedOrgs };
        let searchResultRootNode = this.getFilteredOrgHierarchy(state.rootNode, filterQuery, expandedOrgsForSearch);

        if (searchResultRootNode == null) {
          searchResultRootNode = {
            key: state.rootNode.key,
            data: {
              name: state.rootNode.data.name,
              id: state.rootNode.data.id,
            },
            children: [],
          };
          expandedOrgsForSearch = state.expandedOrgs;
        }
        return {
          searchResultRootNode,
          expandedOrgs: expandedOrgsForSearch,
          searchInput,
          errorMsg: undefined,
        };
      });
    }
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { templateName } = this.props;
    return (
      <Dialog
        {...this.props} // required as onClose() is provided by ModalTrigger
        title={formatMessage(messages.ApplyTemplateTitle, { templateName })}
        confirmLabel={formatMessage(messages.Apply)}
        cancelLabel={formatMessage(messages.Cancel)}
        role="dialog"
        onCancel={(): void => Analytics.fireCTAEvent(`Apply template dialog canceled`)}
        onConfirm={async (): Promise<boolean> => await this.applyTemplate()}
        confirmDisabled={this.isApplyButtonDisabled()}
      >
        <React.Fragment>
          <div className="CreateTemplate__headerMessage">
            <FormattedMessage
              id="EditCompartment.Templates.Apply.Instructions"
              defaultMessage="You can apply the policy settings in a template to any of your organizations. This can streamline setup and facilitate consistent policy management across your organizations.
            {pHtmlTag}Select organizations that you would like to update, then {applyTemplateStrong} Next,  you’ll need to click  {reviewPendingChangesStrong}  to view, approve and submit changes.
            {pHtmlTag}Only policies that require update will be changed.  If no policies are updated as a result of applying the template, you won't see {reviewPendingChangesStrong} enabled."
              values={{
                pHtmlTag: <p />,
                applyTemplateStrong: <strong>{formatMessage(messages.Apply)}</strong>,
                reviewPendingChangesStrong: <strong>{formatMessage(messages.ReviewPendingChanges)}</strong>,
              }}
            />
          </div>
          <div className="EditCompartment_tabSectionHeader">
            <Search
              placeholder={formatMessage(messages.SearchPlaceholder)}
              onChange={this.onSearchQueryChange}
              value={this.state.searchInput}
              data-testid="apply-templates--search-organizations"
            />
          </div>
          {this.state.errorMsg && (
            /* AlertBanner is NOT closable if component fails to load template data to be applied */
            <AlertBanner
              variant="error"
              closeable={!_.isEmpty(this.state.template)}
              onClose={() => this.setState({ errorMsg: undefined })}
            >
              {this.state.errorMsg}
            </AlertBanner>
          )}
          {!this.state.isLoading ? (
            <div className="ApplyTemplate_treeTable">
              <TreeTable
                value={[this.state.searchResultRootNode]}
                selectionMode="single"
                selectionKeys={this.state.selectedOrgs}
                expandedKeys={this.state.expandedOrgs}
                propagateSelectionUp={false}
                propagateSelectionDown={false}
                scrollable={false}
                onToggle={(e: { originalEvent: Event; value: ExpandedNodes }): void => {
                  this.setState({ expandedOrgs: e.value });
                }}
              >
                <Column
                  body={this.checkboxTemplate}
                  className="ApplyTemplate_treeTable_selectColumn"
                  header={
                    <Checkbox checked={this.state.areAllOrgSelected} onClick={(): void => this.checkUncheckAllOrg()} />
                  }
                />
                <Column field="name" header={formatMessage(messages.Name)} expander />
                <Column field="id" header={formatMessage(messages.OrgId)} />
              </TreeTable>
            </div>
          ) : (
            <Wait className="Load_wait" />
          )}
        </React.Fragment>
      </Dialog>
    );
  }
}

export default injectIntl(ApplyTemplate);
