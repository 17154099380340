import * as _ from 'lodash';
import { OrgReparentProductsReport } from './types';

export class OrgReparentProductsReportUtils {
  static emptyOrgReparentProductsReport(): OrgReparentProductsReport {
    return {
      expiredProductInfoForReparent: [],
      overAllocatedInfoForReparent: [],
      overAllocatedInfoForAdd: [],
      orgsMissingGAPermissionForAdd: [],
      conflictingProductsForAdd: [],
      orgsWithManageProductPolicyOffForAdd: [],
      orgsWithManageProductPolicyOffForDelete: [],
      productsWithZeroGrantsForDelete: [],
      productsToDelete: [],
    };
  }

  static mergeReports(
    mainReport: OrgReparentProductsReport,
    report: OrgReparentProductsReport
  ): OrgReparentProductsReport {
    const mergedReport: OrgReparentProductsReport = _.cloneDeep(mainReport);

    mergedReport.expiredProductInfoForReparent = mergedReport.expiredProductInfoForReparent.concat(
      report.expiredProductInfoForReparent
    );

    mergedReport.overAllocatedInfoForReparent = mergedReport.overAllocatedInfoForReparent.concat(
      report.overAllocatedInfoForReparent
    );
    mergedReport.overAllocatedInfoForAdd = mergedReport.overAllocatedInfoForAdd.concat(report.overAllocatedInfoForAdd);

    mergedReport.orgsMissingGAPermissionForAdd = mergedReport.orgsMissingGAPermissionForAdd.concat(
      report.orgsMissingGAPermissionForAdd
    );
    mergedReport.orgsMissingGAPermissionForAdd = _.uniqBy(mergedReport.orgsMissingGAPermissionForAdd, 'id');

    mergedReport.orgsWithManageProductPolicyOffForAdd = mergedReport.orgsWithManageProductPolicyOffForAdd.concat(
      report.orgsWithManageProductPolicyOffForAdd
    );
    mergedReport.orgsWithManageProductPolicyOffForAdd = _.uniqBy(
      mergedReport.orgsWithManageProductPolicyOffForAdd,
      'id'
    );

    mergedReport.conflictingProductsForAdd = mergedReport.conflictingProductsForAdd.concat(
      report.conflictingProductsForAdd
    );

    mergedReport.orgsWithManageProductPolicyOffForDelete = mergedReport.orgsWithManageProductPolicyOffForDelete.concat(
      report.orgsWithManageProductPolicyOffForDelete
    );
    mergedReport.orgsWithManageProductPolicyOffForDelete = _.uniqBy(
      mergedReport.orgsWithManageProductPolicyOffForDelete,
      'id'
    );

    mergedReport.productsWithZeroGrantsForDelete = mergedReport.productsWithZeroGrantsForDelete.concat(
      report.productsWithZeroGrantsForDelete
    );
    mergedReport.productsToDelete = mergedReport.productsToDelete.concat(report.productsToDelete);

    return mergedReport;
  }

  static hasErrors(report: OrgReparentProductsReport): boolean {
    return (
      !_.isEmpty(report.expiredProductInfoForReparent) ||
      !_.isEmpty(report.overAllocatedInfoForReparent) ||
      !_.isEmpty(report.overAllocatedInfoForAdd) ||
      !_.isEmpty(report.orgsMissingGAPermissionForAdd) ||
      !_.isEmpty(report.conflictingProductsForAdd) ||
      !_.isEmpty(report.orgsWithManageProductPolicyOffForAdd) ||
      !_.isEmpty(report.orgsWithManageProductPolicyOffForDelete) ||
      !_.isEmpty(report.productsWithZeroGrantsForDelete) ||
      !_.isEmpty(report.productsToDelete)
    );
  }
}
