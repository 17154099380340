import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';
import { parse, ParseError } from 'papaparse';

import ImportTypeInfo from './ImportTypeInfo';
import Utils from '../Utils';

const messages = defineMessages({
  InvalidHeaderValues: {
    id: 'Organizations.Import.CSVToDataModel.InvalidHeaderValues',
    defaultMessage: 'Failed to parse headers. Invalid header values.',
  },
  ErrorParsingCSV: {
    id: 'Organizations.Import.CSVToDataModel.ErrorParsingCSV',
    defaultMessage: 'Invalid data. The following errors occurred when parsing the file: {error}',
  },
  EmptyHeader: {
    id: 'Organizations.Import.CSVToDataModel.EmptyHeader',
    defaultMessage: 'Cannot have empty header.  A header can be missing but there cannot be an empty placeholder',
  },
  EmptyCSV: {
    id: 'Organizations.Import.CSVToDataModel.EmptyCSV',
    defaultMessage: 'CSV is empty',
  },
  CSVMissingDataOrHeader: {
    id: 'Organizations.Import.CSVToDataModel.CSVMissingDataOrHeader',
    defaultMessage: 'CSV is missing data or the header',
  },
  MissingRequiredHeaders: {
    id: 'Organizations.Import.CSVToDataModel.MissingRequiredHeaders',
    defaultMessage: 'The following required headers are missing: {headers}',
  },
  HeaderInvalidType: {
    id: 'Organizations.Import.CSVToDataModel.HeaderInvalidType',
    defaultMessage: 'Header {header} is not a valid header value',
  },
  ColumnMismatch: {
    id: 'Organizations.Import.CSVToDataModel.ColumnMismatch',
    defaultMessage: 'For record {index}, mismatch between the number of headers and number of values for this row',
  },
  MismatchTypeForFields: {
    id: 'Organizations.Import.CSVToDataModel.MismatchTypeForFields',
    defaultMessage:
      'For record {index}, required fields are of an invalid type. The following are the required fields and their types: {fieldAndTypes}',
  },
});

class CSVToDataModel {
  /**
   * Parse CSV string as string[]. NOTE: csvString must contain only one record at a time
   * If the CSV string is invalid, throw an error
   */
  private static CSVStringtoArray(csvString: string, intl: IntlShape): string[][] {
    // parse data with papa parse
    // any errors when parsing csv will be received in parsedCSVRow.errors
    if (csvString) {
      const parsedCSVRow = parse(csvString);
      if (parsedCSVRow.errors.length > 0) {
        const errorMessages = parsedCSVRow.errors.map(
          (error: ParseError, index: number): string => `${index + 1}) ${error.message}`
        );
        const { formatMessage } = intl;
        throw Error(formatMessage(messages.ErrorParsingCSV, { error: errorMessages.join(', \n') }));
      }
      if (parsedCSVRow.data.length > 0) {
        // for each csv row, trim each value
        return parsedCSVRow.data.map((eachRow: any): string[] => {
          return _.map(eachRow, (value: string): string => value.trim());
        });
      }
    }
    return [];
  }

  /**
   * Retrieves the header row from the CSV.
   *  - rows: Each element is a string of each line in the CSV file
   * The elements of the returned array are each header column string.
   * Note: If the header is not provided, this will grab the first row.  (The returned value needs to be validated)
   */
  private static getHeader(headerRow: string, intl: IntlShape): string[] {
    const headers: string[][] = CSVToDataModel.CSVStringtoArray(headerRow, intl);
    if (headers.length < 1) {
      const { formatMessage } = intl;
      throw Error(formatMessage(messages.InvalidHeaderValues));
    }
    // get the header data at index 0. headerRow only contains header data.
    return _.map(headers[0], (header: string): string => header.trim());
  }

  /**
   * Throws error if any header column string is empty (no commas within nothing in between)
   *  - headers: Each header column string is a single element in the array
   */
  private static validateNoEmptyHeader(headers: string[], intl: IntlShape): void {
    const emptyHeader: string | undefined = _.find(headers, (header: string): boolean => _.isEmpty(header));
    if (emptyHeader !== undefined) {
      const { formatMessage } = intl;
      throw Error(formatMessage(messages.EmptyHeader));
    }
  }

  /**
   * @returns required prop names as a list
   */
  private static getPropNamesOfRequiredFields<DataModelType>(importTypeMap: ImportTypeInfo<DataModelType>[]): string[] {
    // get all the required props
    const requiredProps = _.filter(
      importTypeMap,
      (typeInfo: ImportTypeInfo<DataModelType>): boolean => !typeInfo.optional
    );

    // get the names of required props as an array
    return _.map(requiredProps, (typeInfo: ImportTypeInfo<DataModelType>): string => typeInfo.propName as string);
  }

  /**
   * Throws error if any required header column string is missing (related to the required properties passed as argument)
   *  - headers: Each header column string is a single element in the array
   */
  private static validateRequiredHeaders<DataModelType>(
    headers: string[],
    allFields: ImportTypeInfo<DataModelType>[],
    intl: IntlShape
  ): void {
    const missingRequiredHeaders: string[] = [];
    const requiredFieldNames = CSVToDataModel.getPropNamesOfRequiredFields<DataModelType>(allFields);
    _.forEach(requiredFieldNames, (requiredFieldName: string): void => {
      // check if the requiredField is present in the headers. If not, add to the missingRequiredHeaders list
      if (_.find(headers, (header: string): boolean => header === requiredFieldName) === undefined) {
        missingRequiredHeaders.push(requiredFieldName);
      }
    });

    // if there are any missing headers, throw an Exception
    if (missingRequiredHeaders.length > 0) {
      const { formatMessage } = intl;
      throw Error(formatMessage(messages.MissingRequiredHeaders, { headers: missingRequiredHeaders.join(', ') }));
    }
  }

  /**
   * Throws error if any header column string appears to be a number or boolean value.
   *  - headers: Each header column string is a single element in the array
   */
  private static validateHeadersAllStrings(headers: string[], intl: IntlShape): void {
    const nonStringValue: string | undefined = _.find(
      headers,
      (header: string): boolean => Utils.canParseBool(header) || Utils.canParseInt(header)
    );
    if (nonStringValue !== undefined) {
      const { formatMessage } = intl;
      throw Error(formatMessage(messages.HeaderInvalidType, { header: nonStringValue }));
    }
  }

  /**
   * Throws error, if the header row is invalid.
   *  - headers: Each header column string is a single element in the array
   */
  private static validateHeaders<DataModelType>(
    headers: string[],
    allFieldsImport: ImportTypeInfo<DataModelType>[],
    intl: IntlShape
  ): void {
    CSVToDataModel.validateNoEmptyHeader(headers, intl);
    CSVToDataModel.validateRequiredHeaders<DataModelType>(headers, allFieldsImport, intl);
    CSVToDataModel.validateHeadersAllStrings(headers, intl);
  }

  /**
   * Retrieves all the rows except the header row.
   *  - rows: Each element is a string of each line in the CSV file
   * The returned array is 2 dimensional
   *  - The first dimension represents a row (an array) where every element is a column value of that row
   *  - The second dimension selects a single column value for that row
   * Note: If the header row is not provided, this will omit the first row (The header row needs to be validated).
   */
  private static getValues(rows: string[], intl: IntlShape): string[][] {
    return CSVToDataModel.CSVStringtoArray(rows.join('\n'), intl);
  }

  /**
   * Converts CSV data of a single row to a single data object (DataModelType)
   *  - headers: Each header column string is a single element in the array
   *  - rowValue: Represents a single row where each element is a column value
   *  - index: Refers to the row number (0 indexed).  This is to provide more details for errors.
   * Note: Values being assigned to the data object must be escaped as CSV is not automatically escaped.
   */
  private static createImportData<DataModelType>(
    headers: string[],
    rowValue: string[],
    index: number,
    intl: IntlShape
  ): DataModelType {
    if (headers.length !== rowValue.length) {
      const { formatMessage } = intl;
      throw Error(formatMessage(messages.ColumnMismatch, { index: index + 2 }));
    }

    const data: { [key: string]: any } = {};
    _.forEach(headers, (header: string, headerIndex: number): void => {
      const parsedValue: number | string | boolean = Utils.htmlEntities(rowValue[headerIndex]);
      // if parsedValue is string and isEmpty ignore
      if (!(typeof parsedValue === 'string' && _.isEmpty(parsedValue))) {
        data[header] = parsedValue;
      }
    });

    return data as DataModelType;
  }

  /**
   * Converts CSV data to data (DataModelType) objects
   *  - headers: Each header column string is a single element in the array
   *  - values: Array of all rows containing values.  Each row (array) contains elements representing the column values for that row.
   */
  private static createAllImportData<DataModelType>(
    headers: string[],
    values: string[][],
    requiredFields: ImportTypeInfo<DataModelType>[],
    intl: IntlShape
  ): DataModelType[] {
    const recordsAsDataModelArr: DataModelType[] = [];
    _.forEach(values, (value: string[], index: number): void => {
      try {
        recordsAsDataModelArr.push(CSVToDataModel.createImportData<DataModelType>(headers, value, index, intl));
      } catch (err) {
        // when unable to convert obj to DataModel type.
        const { formatMessage } = intl;
        const requiredFieldsAndTypes = this.getRequiredFieldsAndTypesAsCSV<DataModelType>(requiredFields);
        throw Error(
          formatMessage(messages.MismatchTypeForFields, { index: index + 2, fieldAndTypes: requiredFieldsAndTypes })
        );
      }
    });
    return recordsAsDataModelArr;
  }

  /**
   * get the prop names & types as a CSV for required fields
   * for e.g.
   * {
   *   propName: 'prop1',
   *   typeString: 'string',
   *   optional: false,
   * },
   * {
   *   propName: 'prop2',
   *   typeString: 'number',
   *   optional: false,
   * },
   * {
   *   propName: 'prop3',
   *   typeString: 'string',
   *   optional: true,
   * },
   * returns "prop1 - string, prop2 - number"
   * NOTE: prop3 is optional and hence not returned
   * @param requiredFields of the DataModel
   */
  private static getRequiredFieldsAndTypesAsCSV<DataModelType>(
    requiredFields: ImportTypeInfo<DataModelType>[]
  ): string {
    const values: string[] = [];
    requiredFields.forEach((requiredField: ImportTypeInfo<DataModelType>): void => {
      if (!requiredField.optional) {
        values.push(`${requiredField.propName} - ${requiredField.typeString}`);
      }
    });
    return values.join(', ');
  }

  /**
   * Converts csv contents from file into DataModelType[]
   * DataModelType is the type of the records in the csv file
   * for e.g. for Product Allocation, DataModelType will be ProdAllocImportData,
   *          for Admins, DataModelType will be AdminTypeImportData
   * validateCSVFileContent() MUST be called before this method, for example so this method doesn't try to process an empty file
   */
  public static convertToDataModelAr<DataModelType>(
    data: string,
    allFields: ImportTypeInfo<DataModelType>[],
    intl: IntlShape
  ): DataModelType[] {
    // filter out empty rows from input file
    const rows: string[] = _.filter(Utils.splitByLineBreaks(data), (row: string): boolean => !_.isEmpty(row.trim()));
    // header should be the first row in the file
    const headers: string[] = CSVToDataModel.getHeader(rows[0], intl);
    CSVToDataModel.validateHeaders<DataModelType>(headers, allFields, intl);
    const values: string[][] = CSVToDataModel.getValues(rows.slice(1), intl);
    return CSVToDataModel.createAllImportData<DataModelType>(headers, values, allFields, intl);
  }

  /**
   * For a CSV file, perform following validations:
   * 1. CSV is not empty
   * 2. CSV file has at least two rows - 1 header and 1 data row
   * This fn throws an exception if the above validations fails else returns normally
   * @param data file contents as string
   */
  public static validateCSVFileContent(data: string, intl: IntlShape) {
    // filter out empty rows from input file
    const rows: string[] = _.filter(Utils.splitByLineBreaks(data), (row: string): boolean => !_.isEmpty(row.trim()));
    const { formatMessage } = intl;
    if (rows.length === 0) {
      throw Error(formatMessage(messages.EmptyCSV));
    }
    if (rows.length < 2) {
      throw Error(formatMessage(messages.CSVMissingDataOrHeader));
    }
  }
}

export default CSVToDataModel;
