import React, { Component } from 'react';
import AdobeLogo from '@react/react-spectrum/Icon/AdobeLogo';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { SelectOption } from '@react/react-spectrum/Select';
import * as _ from 'lodash';
import * as log from 'loglevel';
import { I18nShell } from '@gac/banyan-shell';
import AdminPermission from '../../services/authentication/AdminPermission';
import AuthenticateUser from '../../services/authentication/AuthenticatedUser';
import Authentication from '../../services/authentication/Authentication';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OrgPickerController from '../../services/organization/OrgPickerController';
import HeaderConsts, { Workspace, WorkspaceLocale, WorkspaceUIDs } from './HeaderConstants';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import './BanyanShell.css';
import { UserProfile } from '../../services/authentication/IMS';
import UserProfileService from '../../services/authentication/UserProfileService';
import Analytics from '../../Analytics/Analytics';
import withRouter, { RouteComponentProps } from '../../services/utils/withRouter';

interface BanyanShellProps extends RouteComponentProps, WrappedComponentProps {
  renderCallback: () => void;
  workspacesLocale: WorkspaceLocale[];
  children?: React.ReactNode;
  setDeepLinkingError: (error: string) => void;
  userProfile: UserProfile | undefined;
}

interface BanyanShellState {
  selectedWorkspaceId: string | undefined; // used to control the 'selected tab' underline of <Shell>
}

const shellMessages = defineMessages({
  globalAdminConsole: {
    id: 'shell.tabs.globalAdminConsole',
    defaultMessage: 'Global Admin Console',
  },
});

const helpxSearch = 'https://helpx.adobe.com/search-results.html'; // Helpx search page entree

class BanyanShell extends Component<BanyanShellProps, BanyanShellState> {
  public constructor(props: BanyanShellProps) {
    super(props);
    this.state = {
      selectedWorkspaceId: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    // At this point, OrgPickerController.load() has already been invoked by App.ts, which is a prerequisite of calling canAccess().
    const canAccessGAC = await AdminPermission.canAccess();
    if (!canAccessGAC) {
      throw new Error('You do not have permission to view this application');
    }

    this.setActiveTab();
  }

  componentDidUpdate(): void {
    this.setActiveTab();
  }

  /**
   * Parse the browser URL and compare to all 'workspace' URLs. If a match if found, set the active "workspace" (aka Tab) of Shell.
   */
  private setActiveTab = (): void => {
    const selectedWorkspace = this.props.workspacesLocale.find(({ url }): boolean => {
      const { pathname } = window.location;
      const endpoint = pathname.substring(pathname.lastIndexOf('/'));
      return new RegExp(`^${url}$`).test(endpoint); // check to see if endpoint matches the url
    });
    if (selectedWorkspace && selectedWorkspace.uid !== this.state.selectedWorkspaceId) {
      this.setState({
        selectedWorkspaceId: selectedWorkspace.uid,
      });
    }
  };

  /**
   * Go to a new page/route depending upon the tab selected by the user.
   * App.tsx defines the routes and where they are rendered.
   * @param workspace - tab selected by the user.
   */
  private workspaceChangeHandler = async (workspace: Workspace): Promise<void> => {
    log.debug('navigating to ', workspace);
    let { url } = workspace;
    const isAdobeAgentWithNoOrgs = await AdminPermission.isAdobeAgentWithNoOrgs();
    if (
      !isAdobeAgentWithNoOrgs &&
      (workspace.url === HeaderConsts.ORGANIZATIONS_URL ||
        workspace.url === HeaderConsts.PRODUCT_ALLOCATION_URL ||
        workspace.url === HeaderConsts.JOB_EXECUTION_URL ||
        workspace.url === HeaderConsts.INSIGHTS ||
        workspace.url === HeaderConsts.SUPPORT)
    ) {
      url = OrgPickerController.getDeepLinkBasedOnActiveOrg(url);
    }
    this.props.navigate(url);
  };

  /**
   * Called when navigation's upper-left icon or title are clicked.
   * Navigate to the first workspace e.g. /compartments
   */
  private solutionClickHandler = async (): Promise<void> => {
    let { url } = this.props.workspacesLocale[0];
    const isAdobeAgentWithNoOrgs = await AdminPermission.isAdobeAgentWithNoOrgs();
    if (
      !isAdobeAgentWithNoOrgs &&
      (url === HeaderConsts.ORGANIZATIONS_URL ||
        url === HeaderConsts.PRODUCT_ALLOCATION_URL ||
        url === HeaderConsts.JOB_EXECUTION_URL)
    ) {
      url = OrgPickerController.getDeepLinkBasedOnActiveOrg(url);
    }
    this.props.navigate(url);
  };

  // Prepares the workspace
  private prepareWorkspaces(workspacesLocale: WorkspaceLocale[]): Workspace[] {
    return _.map(
      workspacesLocale,
      (workspaceLocale: WorkspaceLocale): Workspace => ({
        uid: workspaceLocale.uid,
        name: this.props.intl.formatMessage(workspaceLocale.messageDescriptor),
        url: workspaceLocale.url,
      })
    );
  }

  public render(): React.ReactNode {
    const { children, workspacesLocale } = this.props;

    // TODO: investigate into the bug in shell due to which the org list is not updated on re-render.
    const imsOrgsList: SelectOption[] = _.isEmpty(OrgPickerController.getOrgLabels())
      ? [{ label: 'no orgs', value: '' }]
      : OrgPickerController.getOrgLabels();
    const activeOrg = OrgPickerController.getActiveOrg();
    // To support switching between T2E and non-T2E orgs in org picker, "userId" associated with each org is required.
    // As of this comment, <Shell> doesn't support an object as value for "selectedImsOrg". It only accepts string.
    let selectedImsOrg: string | undefined;
    if (activeOrg) {
      selectedImsOrg = activeOrg.userId
        ? `${activeOrg?.id}${OrgPickerController.ORGPICKER_VALUE_DELIMETER}${activeOrg?.userId}`
        : activeOrg.id;
    } else {
      selectedImsOrg = undefined;
    }
    return (
      <div id="shellWrapper" className="BanyanShell__contentArea">
        <I18nShell
          solutionIcon={<AdobeLogo />}
          solutionTitle={this.props.intl.formatMessage(shellMessages.globalAdminConsole)}
          solutionShortTitle={this.props.intl.formatMessage(shellMessages.globalAdminConsole)}
          imsOrgs={imsOrgsList}
          onSolutionClick={this.solutionClickHandler}
          selectedImsOrg={selectedImsOrg || ''}
          userAvatar={AuthenticateUser.getUserPictureURL(UserProfileService.getUserId())}
          onUserProfileClick={(): void => {
            window.open('https://www.adobe.com/go/aac_acctmgmt', '_blank');
          }}
          onSignOutClick={(): void => {
            this.props.navigate('/'); // Clear the url state on sign out, otherwise on next sign-in, the app is redirected to deep link with previous orgId
            Authentication.signOut();
          }}
          onImsOrgChange={async (input: string): Promise<void> => {
            if (!_.isEmpty(input)) {
              const [orgId, userId] = input.split(OrgPickerController.ORGPICKER_VALUE_DELIMETER);
              OrgPickerController.selectActiveOrg(orgId);
              await Authentication.switchProfile(userId); // Calls IMS to get new user profile and token. Sets window.adobeIMS. no-op if userId is undefined
              Analytics.setData();
              // update the url deep link based on selected org if the user is on organization page, product allocation page, job execution page
              if (this.state.selectedWorkspaceId === WorkspaceUIDs.COMPARTMENTS) {
                this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.ORGANIZATIONS_URL));
              } else if (this.state.selectedWorkspaceId === WorkspaceUIDs.PRODUCT_ALLOCATION) {
                this.props.navigate(
                  OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.PRODUCT_ALLOCATION_URL)
                );
              } else if (this.state.selectedWorkspaceId === WorkspaceUIDs.JOB_EXECUTION) {
                this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
              } else if (this.state.selectedWorkspaceId === WorkspaceUIDs.INSIGHTS) {
                this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.INSIGHTS));
              } else if (this.state.selectedWorkspaceId === WorkspaceUIDs.SUPPORT) {
                this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.SUPPORT));
              }
              this.props.setDeepLinkingError(''); // remove the org not found error dialog when org is switched through org picker
              this.props.renderCallback(); // re render app component to show the updated org name
            }
            document.body.click(); // close the org selector popover
          }}
          onHelpSearch={(input: string): void => {
            if (!_.isEmpty(input)) {
              Analytics.fireCTAEvent(`Open Helpx link clicked`);
              const helpSearchQuery = `${helpxSearch}?q=${input}`;
              // Other fields that might be interesting (per admin console):
              // &facets_fields=%5B%22applicable_products%22%5D
              // &post_facet_filters=%7B%22applicable_products%22%3A%5B%22Adobe%20Enterprise%20%26%20Teams%22%5D%7D
              // &scope=%5B%22helpx%22%5D
              window.open(helpSearchQuery);
            }
          }}
          selectedWorkspaceId={this.state.selectedWorkspaceId}
          onWorkspaceChange={this.workspaceChangeHandler}
          workspaces={this.prepareWorkspaces(workspacesLocale)}
          locale={LocaleSettings.getSelectedLanguageTag()}
          profile={this.props.userProfile}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </I18nShell>
      </div>
    );
  }
}

export default withRouter(injectIntl(BanyanShell));
