import * as _ from 'lodash';
import { defineMessages } from 'react-intl';
import Utils from '../utils/Utils';

// Contract tags to localized (MessageDescriptor) contract tag for display.
// Supported tags (the values are the tags): https://git.corp.adobe.com/sls/clam-transformers/blob/master/src/main/resources/contractTags.json
const contractTagLocales = defineMessages({
  RESELLER: {
    id: 'Contracts.ContractTags.Reseller',
    defaultMessage: 'Reseller',
  },
  CLASSROOM: {
    id: 'Contracts.ContractTags.Classroom',
    defaultMessage: 'Classroom',
  },
  ALLOCATION: {
    id: 'Contracts.ContractTags.Allocation',
    defaultMessage: 'Allocation',
  },
  TRIAL: {
    id: 'Contracts.ContractTags.Trial',
    defaultMessage: 'Trial',
  },
});

// Reference: https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-organizations-entities/src/main/java/com/adobe/jil/organizations/entities/Contract.java
// Reference: https://wiki.corp.adobe.com/display/clam/CLAM+-+Contract+Name

export interface ContractJILData {
  id: string;
  orgId: string;
  adminGroupId?: string; // renga group ID for supporting contract admins
  buyingProgram: string;
  name?: string; // custom contract name specified by user or the contract id
  displayNamePrefix?: string; // string representing the contract type.  ex: ETLA, VIP, etc
  displayName?: string; // full contract name in the form `${prefix} - ${configured}`.  This is the string we display in the UI
  tags?: string[]; // Tags to display for a product in the ProductTable.  Currently is this will only have 1 element.
}

class ContractJIL implements ContractJILData {
  id: string;
  orgId: string;
  adminGroupId: string;
  buyingProgram: string;
  name: string;
  displayNamePrefix: string;
  displayName: string;
  tags: string[];

  constructor(contract: ContractJILData) {
    this.id = contract.id;
    this.orgId = contract.orgId;
    this.adminGroupId = contract.adminGroupId || '';
    this.buyingProgram = contract.buyingProgram;
    this.name = contract.name || '';
    this.displayNamePrefix = contract.displayNamePrefix || '';
    this.displayName = contract.displayName || '';
    this.tags = contract.tags || [];
  }

  /**
   * Retrieves the single tag for display in the UI for products.
   */
  contractTag(): string {
    // Currently there is only supposed to be 1 tag
    return !_.isEmpty(this.tags) ? this.tags[0] : '';
  }

  /**
   * Get localized contract tag for display from a given contract tag.
   * Empty string is returned for invalid or missing contract tags since this is for display purposes only
   * and follows similar logic for CLAM's behavior if a contract tag can't be determined (empty string).
   * The contract tag should be previously checked if the component rendering the contract tag shouldn't display.
   */
  static getLocalizedContractTag = (contractTag: string | undefined): string => {
    if (_.isEmpty(contractTag)) {
      return ''; // for display purposes empty string is returned if contract tag is not present
    }
    const contractTagDescriptor = (contractTagLocales as any)[_.toUpper(contractTag)];
    if (_.isEmpty(contractTagDescriptor)) {
      return ''; // for display purposes empty string is returned if contract tag is invalid
    }
    return Utils.intl.formatMessage(contractTagDescriptor);
  };
}

export default ContractJIL;
