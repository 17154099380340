import React from 'react';
import Tooltip, { TooltipProps } from '@react/react-spectrum/Tooltip';
import { IntlProvider } from 'react-intl';
import { LocaleSettings } from '../../services/locale/LocaleSettings';

/**
 * LocaleTooltip is a Tooltip that handles wrapping the provider for internationalization.
 * LocaleTooltip needs to wrap Tooltips that use localized messages and are inside Overlays to prevent the following error:
 *    "Could not find required 'intl' object.  <IntlProvider> needs to exist in the component ancestry.  Use default message as fallback.""
 * TBD: Is this component the best solution or can React-Spectrum implement a fix within their own components?
 */
function LocaleTooltip(props: Omit<TooltipProps, 'ref'>): React.ReactElement {
  return (
    <Tooltip {...props}>
      <IntlProvider
        locale={LocaleSettings.getSelectedLanguageTagForProvider()}
        messages={LocaleSettings.getSelectedLocale()}
      >
        {props.children}
      </IntlProvider>
    </Tooltip>
  );
}
export default LocaleTooltip;
