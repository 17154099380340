/**
 * CancelStatus is an object that has two flags controlling whether some
 * related job should be cancelled or not.
 *
 * `timeout` flag should control cancellation on client-side only, allowing server to continue processing.
 */
export interface CancelStatus {
  cancelled: boolean;
  timedout: boolean;
}

/**
 * CancelPromise stores a promise and provides functionality to flags
 * whether some running job related to the promise should be canceled.
 * Note: CancelPromise cannot inherit from Promise as this breaks functionality in Chrome.
 */
export class CancelPromise<T> implements CancelStatus {
  promise: Promise<T> | null = null; // promise stored by the CancelPromies (CancelPromise is not a promise itself)
  private cancelledField: boolean = false;
  private timedoutField: boolean = false;

  /**
   * Cancel flag, if set to true, anyone using this CancelPromise should check this
   * to be notified as to when to cancel.
   */
  get cancelled(): boolean {
    return this.cancelledField;
  }

  /**
   * Sets the cancel flag to true, anyone using this CancelPromise should cancel.
   */
  cancel(): void {
    this.cancelledField = true;
  }

  /**
   * Timeout flag, if set to true, anyone using this CancelPromise should check this
   * to be notified as to when to cancel.
   */
  get timedout(): boolean {
    return this.timedoutField;
  }

  /**
   * Sets the timeout flag to true, anyone using this CancelPromise should cancel.
   */
  timeout(): void {
    this.timedoutField = true;
  }
}
