import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';

import {
  ImportOrganizationsDM,
  ImportProductProfilesDM,
  ImportProductsDM,
  ImportUserGroupsDM,
} from '../DataModelTypes/DataModelTypes';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import { UUserGroup, UUserGroupLicenseGroup } from '../../../../services/orgMaster/UUserGroup';
import ImportOrganizations from './ImportOrganizations';
import ImportProductsAndResources from './ImportProductsAndResources';
import ImportProductProfiles from './ImportProductProfiles';
import ImportOperationsUtils from '../../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import ImportUtils, { ChangeCount } from '../ImportUtils';
import { ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import Utils from '../../../../services/utils/Utils';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import { CommandService } from '../../../../services/Commands/CommandService';

const messages = defineMessages({
  UserGroupsLabel: {
    id: 'Organizations.Import.UserGroups.UserGroupsLabel',
    defaultMessage: 'User Groups',
  },
  UserGroupNameFieldInvalid: {
    id: 'Organizations.Import.UserGroups.UserGroupNameFieldInvalid',
    defaultMessage:
      'userGroupName should not be blank or empty. User groups in following orgs do not satisfy this condition:',
  },
  UserGroupIdFieldInvalid: {
    id: 'Organizations.Import.UserGroups.UserGroupIdFieldInvalid',
    defaultMessage: "userGroupId field cannot be blank for record ''{index}''",
  },
  OrgIdFieldInvalid: {
    id: 'Organizations.Import.UserGroups.OrgIdFieldInvalid',
    defaultMessage: "orgId field cannot be blank for record ''{index}''",
  },
  SameUserGroupDetails: {
    id: 'Organizations.Import.UserGroups.SameUserGroupDetails',
    defaultMessage:
      "userGroupName and orgId must be same for a 'userGroupId'. Following userGroupIds do not satisfy this condition:",
  },
  UserGroupIdNotExist: {
    id: 'Organizations.Import.UserGroups.UserGroupIdNotExist',
    defaultMessage: "The following userGroupIds marked as 'UPDATE' or 'DELETE' do not exist:",
  },
  OrgIdNotExist: {
    id: 'Organizations.Import.UserGroups.OrgIdNotExist',
    defaultMessage: "orgId ''{orgId}'' is neither a new org nor an existing org for user group ''{userGroupName}''",
  },
  LicenseIdNotExist: {
    id: 'Organizations.Import.UserGroups.LicenseIdNotExist',
    defaultMessage: "licenseId ''{licenseId}'' is either invalid or does not belong to the orgId ''{orgId}''",
  },
  ProductProfileIDNotExist: {
    id: 'Organizations.Import.UserGroups.ProductProfileIDNotExist',
    defaultMessage:
      "productProfileId ''{productProfileId}'' is either invalid or does not belong to the licenseId or orgId",
  },
  UserGroupFormatMessage: {
    id: 'Organizations.Import.UserGroups.UserGroupFormatMessage',
    defaultMessage: "userGroupName ''{userGroupName}'' for orgId ''{orgId}''",
  },
  DuplicateUserGroupName: {
    id: 'Organizations.Import.UserGroups.DuplicateUserGroupName',
    defaultMessage: "User group ''{userGroupName}'' already exists in the org ''{orgId}''",
  },
  DuplicateUserGroupNameImportFile: {
    id: 'Organizations.Import.UserGroups.DuplicateUserGroupNameImportFile',
    defaultMessage: "Import file contains duplicate user group name ''{userGroupName}'' in org ''{orgId}''",
  },
  ProductProfileAbsentFromUserGroup: {
    id: 'Organizations.Import.UserGroups.ProductProfileAbsentFromUserGroup',
    defaultMessage: "productProfileId ''{productProfileId}'' is not present in user group ''{userGroupName}''",
  },
  UserGroupOperationNotValidOnTypeTOrg: {
    id: 'Organizations.Import.UserGroups.UserGroupOperationNotValidOnTypeTOrg',
    defaultMessage: "User Groups cannot be added or updated in the following orgs because of the org's type:",
  },
  InvalidOrgIdMarkedForDelete: {
    id: 'Organizations.Import.UserGroup.InvalidOrgIdMarkedForDelete',
    defaultMessage: 'The following user groups cannot be added to the orgs because those orgs are marked for deletion:',
  },
});

class ImportUserGroups {
  private static inputUserGroupIdToUserGroupIdInHierarchyMap = new Map<string, string>();

  /**
   * For user groups 'create' operation, userGroupName must be valid
   */
  private static validateUserGroupsNameForCREATE(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    const userGroupsToBeCreated = ImportUtils.filterToBeCreatedItems(allUserGroups) as ImportUserGroupsDM[];
    const invalidUserGroupOrgIds = new Set<string>();

    _.forEach(userGroupsToBeCreated, (userGroup: ImportUserGroupsDM): void => {
      if (ImportUtils.isNullOrEmpty(userGroup.userGroupName)) {
        invalidUserGroupOrgIds.add(userGroup.orgId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.UserGroupsLabel),
      formatMessage(messages.UserGroupNameFieldInvalid),
      Array.from(invalidUserGroupOrgIds)
    );
  }

  /**
   * userGroupId and orgId must be present for all the records
   */
  private static validateMandatoryFields(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allUserGroups, (userGroup: ImportUserGroupsDM, index: number): void => {
      if (ImportOperationsUtils.isOperationValid(userGroup.operation)) {
        // check if userGroupId is blank
        if (_.isNil(userGroup.userGroupId) && !ImportOperationsUtils.isOperationCreate(userGroup.operation)) {
          errorMessages.push(formatMessage(messages.UserGroupIdFieldInvalid, { index: index + 2 }));
        }

        // check is orgId is blank
        if (ImportUtils.isNullOrEmpty(userGroup.orgId)) {
          errorMessages.push(formatMessage(messages.UserGroupIdFieldInvalid, { index: index + 2 }));
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.UserGroupsLabel), errorMessages);
  }

  /**
   * for user groups,
   */
  private static verifyNullChecks(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    ImportUserGroups.validateMandatoryFields(allUserGroups, intl);
    ImportUserGroups.validateUserGroupsNameForCREATE(allUserGroups, intl);
  }

  /**
   * userGroupName and orgId must be same for a userGroupId
   */
  private static validateSameDetailsForAUserGroups(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    const validateUserGroups = new Set<string>();
    const invalidUserGroups = new Set<string>();

    _.forEach(allUserGroups, (userGroup: ImportUserGroupsDM): void => {
      if (!ImportUtils.isNullOrEmpty(userGroup.userGroupId) && !validateUserGroups.has(userGroup.userGroupId)) {
        // mark the usergroup as validated
        validateUserGroups.add(userGroup.userGroupId);

        const allUserGroupWithSameIds = _.filter(allUserGroups, (ug: ImportUserGroupsDM): boolean =>
          _.isEqual(ug.userGroupId, userGroup.userGroupId)
        );

        // validate that all have the same userGroupName and orgId
        if (
          !allUserGroupWithSameIds.every((ug: ImportUserGroupsDM) =>
            _.isEqual(userGroup.userGroupName, ug.userGroupName)
          ) ||
          !allUserGroupWithSameIds.every((ug: ImportUserGroupsDM) => _.isEqual(userGroup.orgId, ug.orgId))
        ) {
          invalidUserGroups.add(userGroup.userGroupId);
        }
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.UserGroupsLabel),
      formatMessage(messages.SameUserGroupDetails),
      Array.from(invalidUserGroups)
    );
  }

  /**
   * Validate if a user group is valid or not. A user group is valid if it exists in the hierarchy
   * or if the user group is to be created
   */
  public static isUserGroupValid(allUserGroups: ImportUserGroupsDM[], userGroupId: string, orgId: string): boolean {
    // check if user group is present in the org hierarchy or is a new user group
    return (
      this.getUserGroupInHierarchy(orgId, userGroupId) !== undefined ||
      this.isUserGroupToBeCreated(allUserGroups, userGroupId, orgId)
    );
  }

  /**
   * Validate it the user group is to be created. A user group is considered to be created, if it has an
   * entry in the allUserGroups with operation='CREATE'
   * @param allUserGroups all user groups from input file
   * @param userGroupId to be checked if valid
   * @returns true if the user group is a new user group else false
   */
  private static isUserGroupToBeCreated(
    allUserGroups: ImportUserGroupsDM[],
    userGroupId: string,
    orgId: string
  ): boolean {
    return (
      _.find(
        allUserGroups,
        (usrGrp: ImportUserGroupsDM): boolean =>
          _.isEqual(usrGrp.userGroupId, userGroupId) &&
          _.isEqual(usrGrp.orgId, orgId) &&
          ImportOperationsUtils.isOperationCreate(usrGrp.operation)
      ) !== undefined
    );
  }

  /**
   * @returns user group if present in the hierarchy else undefined
   */
  public static getUserGroupInHierarchy(orgId: string, userGroupId: string | undefined): UUserGroup | undefined {
    const org = HierarchyManager.getOrg(orgId);
    if (org) {
      return _.find(org.userGroups, (uGroup: UUserGroup): boolean => _.isEqual(uGroup.id, userGroupId));
    }
  }

  /**
   * User groups with 'UPDATE' and 'DELETE' operation should be present in the org
   */
  private static validateUserGroupsToBeUpdatedOrDeleted(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    const userGroupsToBeUpdatedOrDeleted = _.filter(
      allUserGroups,
      (userGroup: ImportUserGroupsDM): boolean =>
        ImportOperationsUtils.isOperationUpdate(userGroup.operation) ||
        ImportOperationsUtils.isOperationDelete(userGroup.operation)
    );

    const validatedUserGroupIds = new Set<string>();
    const invalidUserGroupIds = new Set<string>();

    userGroupsToBeUpdatedOrDeleted.forEach((userGroup: ImportUserGroupsDM): void => {
      // check that user group is not already validated and present in the org
      if (
        !validatedUserGroupIds.has(userGroup.userGroupId) &&
        this.getUserGroupInHierarchy(userGroup.orgId, userGroup.userGroupId) === undefined
      ) {
        invalidUserGroupIds.add(userGroup.userGroupId);
      }
      validatedUserGroupIds.add(userGroup.userGroupId);
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.UserGroupsLabel),
      formatMessage(messages.UserGroupIdNotExist),
      Array.from(invalidUserGroupIds)
    );
  }

  /**
   * Validate that the org to which the user group is to be added is not marked for delete
   * @param allUserGroups all user groups from input file
   * @param allOrgs all orgs from input file
   */
  private static validateOrgNotMarkedForDeletion(
    allUserGroups: ImportUserGroupsDM[],
    allOrgs: ImportOrganizationsDM[]
  ): void {
    const userGroupsToBeCreated = ImportUtils.filterToBeCreatedItems(allUserGroups) as ImportUserGroupsDM[];
    const orgsToDelete = new Set<string>();
    _.forEach(userGroupsToBeCreated, (userGroup: ImportUserGroupsDM): void => {
      if (ImportOrganizations.isOrgMarkedForDelete(allOrgs, userGroup.orgId)) {
        orgsToDelete.add(this.formatUserGroupDM(userGroup));
      }
    });
    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.UserGroupsLabel),
      Utils.getLocalizedMessage(messages.InvalidOrgIdMarkedForDelete),
      Array.from(orgsToDelete)
    );
  }

  /**
   * Validate that the org to which the user groups are to be added is valid
   * @param allUserGroups all user groups from the input file
   * @param allOrgs all orgs from the input file
   */
  private static validateUserGroupOrgsForCreate(
    allUserGroups: ImportUserGroupsDM[],
    allOrgs: ImportOrganizationsDM[],
    intl: IntlShape
  ): void {
    // filter user groups to be created
    const userGroupsToBeCreated = ImportUtils.filterToBeCreatedItems(allUserGroups) as ImportUserGroupsDM[];
    const orgIdsValidated = new Set<string>();
    const errorMessages: string[] = [];

    const { formatMessage } = intl;
    _.forEach(userGroupsToBeCreated, (userGroup: ImportUserGroupsDM): void => {
      // check if org is valid
      if (!orgIdsValidated.has(userGroup.orgId) && !ImportOrganizations.isOrgValid(allOrgs, userGroup.orgId)) {
        errorMessages.push(
          formatMessage(messages.OrgIdNotExist, {
            orgId: userGroup.orgId,
            userGroupName: userGroup.userGroupName,
          })
        );
      }
      orgIdsValidated.add(userGroup.orgId);
    });

    ImportUtils.displayIfError(formatMessage(messages.UserGroupsLabel), errorMessages);
  }

  /**
   * Validate that the user product is valid to which the profile belongs
   * @param allUserGroups all user groups from the input file
   * @param allOrgs all orgs from the input file
   * @param allProducts all products from the input file
   */
  private static validateUserGroupProducts(
    allUserGroups: ImportUserGroupsDM[],
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[],
    intl: IntlShape
  ): void {
    const userGroupsToBeCreatedUpdated = _.filter(allUserGroups, (userGroup: ImportUserGroupsDM): boolean => {
      return (
        !ImportUtils.isNullOrEmpty(userGroup.productProfileId) &&
        (ImportOperationsUtils.isOperationCreate(userGroup.operation) ||
          ImportOperationsUtils.isOperationUpdate(userGroup.operation))
      );
    });
    const productIdsValidated = new Set<string>();
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(userGroupsToBeCreatedUpdated, (userGroup: ImportUserGroupsDM): void => {
      if (userGroup.licenseId !== undefined && !productIdsValidated.has(userGroup.licenseId)) {
        productIdsValidated.add(userGroup.licenseId);
        if (
          !ImportUtils.isNullOrEmpty(userGroup.licenseId) &&
          !ImportProductsAndResources.isProductValid(allProducts, userGroup.licenseId, userGroup.orgId)
        ) {
          errorMessages.push(
            formatMessage(messages.LicenseIdNotExist, {
              licenseId: userGroup.licenseId,
              orgId: userGroup.orgId,
            })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.UserGroupsLabel), errorMessages);
  }

  /**
   * Validate that the profile to be added to this user group is valid
   * A profile can be new profile or an already existing one
   * @param allUserGroups is the list of all user groups from the input file
   * @param allProdProfiles is the list of all product profile from the input file
   */
  private static validateUserGroupProductProfiles(
    allUserGroups: ImportUserGroupsDM[],
    allProdProfiles: ImportProductProfilesDM[],
    intl: IntlShape
  ): void {
    const userGroupsToBeCreatedUpdated = _.filter(allUserGroups, (userGroup: ImportUserGroupsDM): boolean => {
      return (
        !ImportUtils.isNullOrEmpty(userGroup.productProfileId) &&
        (ImportOperationsUtils.isOperationCreate(userGroup.profileOperation) ||
          ImportOperationsUtils.isOperationUpdate(userGroup.profileOperation))
      );
    });
    const profileValidated = new Set<string>();
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(userGroupsToBeCreatedUpdated, (userGroup: ImportUserGroupsDM): void => {
      if (userGroup.productProfileId !== undefined && !profileValidated.has(userGroup.productProfileId)) {
        profileValidated.add(userGroup.productProfileId);
        if (
          !ImportUtils.isNullOrEmpty(userGroup.productProfileId) &&
          !ImportProductProfiles.isProductProfileValid(
            allProdProfiles,
            userGroup.productProfileId,
            userGroup.licenseId,
            userGroup.orgId
          )
        ) {
          errorMessages.push(
            formatMessage(messages.ProductProfileIDNotExist, { productProfileId: userGroup.productProfileId })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.UserGroupsLabel), errorMessages);
  }

  /**
   * Check if userGroupName is present in the hierarchy or not
   */
  static isUserGroupNameInHierarchy(orgId: string, userGroupName: string): boolean {
    const org = HierarchyManager.getOrg(orgId);
    if (org) {
      return _.find(org.userGroups, ['name', userGroupName]) !== undefined;
    }
    return false;
  }

  static getUserGroupIdInHierarchy(userGroupId: string): string {
    return this.inputUserGroupIdToUserGroupIdInHierarchyMap.has(userGroupId)
      ? (this.inputUserGroupIdToUserGroupIdInHierarchyMap.get(userGroupId) as string)
      : userGroupId;
  }

  /**
   * Return user group names from the input file for given 'orgId'
   * and exclude user group name of 'excludeUserGroupId'
   * @param allUserGroups all user groups from input file
   * @param excludeUserGroupId user group id to be excluded
   */
  static getToBeUpdatedOrCreatedUserGroupNames(
    allUserGroups: ImportUserGroupsDM[],
    orgId: string,
    excludeUserGroupId: string
  ): string[] {
    // filter user groups to be updated or created
    const userGroupsToBeUpdatedOrCreated = _.filter(allUserGroups, (userGroup: ImportUserGroupsDM): boolean => {
      return (
        (ImportOperationsUtils.isOperationUpdate(userGroup.operation) ||
          ImportOperationsUtils.isOperationCreate(userGroup.operation)) &&
        _.isEqual(orgId, userGroup.orgId) &&
        !_.isEqual(excludeUserGroupId, userGroup.userGroupId) // 'excludeUserGroupId'
      );
    });
    // get only user group names
    return userGroupsToBeUpdatedOrCreated.map((userGroup: ImportUserGroupsDM): string => userGroup.userGroupName);
  }

  /**
   * Validate if a userGroup name already exists in the hierarchy or the input file
   */
  static validateSameNamedUserGroups(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    const userGroupsToBeCreated = ImportUtils.filterToBeCreatedItems(allUserGroups) as ImportUserGroupsDM[];

    const errorMessages: string[] = [];
    const userGroupsValidated = new Set<string>();
    const { formatMessage } = intl;

    _.forEach(userGroupsToBeCreated, (userGroup: ImportUserGroupsDM): void => {
      const userGroupKey = this.getUserGroupKey(userGroup);
      if (!userGroupsValidated.has(userGroupKey)) {
        userGroupsValidated.add(userGroupKey);

        // check if user group name is present in the org
        if (this.isUserGroupNameInHierarchy(userGroup.orgId, userGroup.userGroupName)) {
          errorMessages.push(
            Utils.getLocalizedMessage(messages.DuplicateUserGroupName, {
              userGroupName: userGroup.userGroupName,
              orgId: userGroup.orgId,
            })
          );
          return;
        }

        // get user group names from input file (exclude current user group name) filtered by the orgId
        const userGroupNames = new Set<string>(
          this.getToBeUpdatedOrCreatedUserGroupNames(allUserGroups, userGroup.orgId, userGroup.userGroupId)
        );

        // check if user group name is present in the input file for the org
        // NOTE: user can try to create user group with same name but with different dummy userGroupId in input file, which will be flagged an error here.
        if (userGroupNames.has(userGroup.userGroupName)) {
          errorMessages.push(
            Utils.getLocalizedMessage(messages.DuplicateUserGroupNameImportFile, {
              userGroupName: userGroup.userGroupName,
              orgId: userGroup.orgId,
            })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.UserGroupsLabel), errorMessages);
  }

  static formatUserGroupDM(userGroup: ImportUserGroupsDM): string {
    return Utils.getLocalizedMessage(messages.UserGroupFormatMessage, {
      userGroupName: userGroup.userGroupName,
      orgId: userGroup.orgId,
    });
  }

  /**
   * @returns UUserGroupLicenseGroup from a user group | undefined
   */
  static getUUserGroupLicenseGroupFromHierarchy(userGroup: ImportUserGroupsDM): UUserGroupLicenseGroup | undefined {
    const uUserGroup = this.getUserGroupInHierarchy(userGroup.orgId, userGroup.userGroupId);
    if (uUserGroup !== undefined) {
      return _.find(uUserGroup.profiles, (prof: UUserGroupLicenseGroup): boolean =>
        _.isEqual(prof.id, userGroup.productProfileId)
      );
    }
  }

  /**
   * Validate profile is present in the user group for it to be deleted
   */
  static validateProfileInUserGroupForDelete(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): void {
    // filter user group profiles to be deleted from the user group
    const userGroupProfileToBeDeleted = _.filter(allUserGroups, (userGroup: ImportUserGroupsDM): boolean => {
      return (
        !ImportUtils.isNullOrEmpty(userGroup.productProfileId) &&
        ImportOperationsUtils.isOperationDelete(userGroup.operation)
      );
    });

    const profileIdsValidated = new Set<string>();
    const errorMessages: string[] = [];
    const { formatMessage } = intl;

    userGroupProfileToBeDeleted.forEach((userGroup: ImportUserGroupsDM): void => {
      if (this.getUUserGroupLicenseGroupFromHierarchy(userGroup) === undefined) {
        errorMessages.push(
          formatMessage(messages.ProductProfileAbsentFromUserGroup, {
            productProfileId: userGroup.productProfileId,
            userGroupName: userGroup.userGroupName,
          })
        );
      }
      profileIdsValidated.add(userGroup.productProfileId as string);
    });

    ImportUtils.displayIfError(formatMessage(messages.UserGroupsLabel), errorMessages);
  }

  /**
   * Validate that no operations are performed on a read only org
   * @param allUserGroups all user groups from the input file
   */
  static async validateReadOnlyOrgs(allUserGroups: ImportUserGroupsDM[], intl: IntlShape): Promise<void> {
    const orgIds = new Set<string>();
    _.forEach(allUserGroups, (userGroup: ImportUserGroupsDM): void => {
      if (ImportOperationsUtils.isOperationValid(userGroup.operation)) {
        orgIds.add(userGroup.orgId);
      }
    });

    const { formatMessage } = intl;
    await ImportUtils.throwIfExistingOrgIsReadOnly(
      formatMessage(messages.UserGroupsLabel),
      formatMessage(messages.UserGroupOperationNotValidOnTypeTOrg),
      Array.from(orgIds)
    );
  }

  /**
   * @param allUserGroups all user groups from import file
   * @returns fn[] where each fn returns a promise to load user groups or prod profiles for 1 org
   */
  public static loadUserGroupsAndProdProfile(allUserGroups: ImportUserGroupsDM[]): { (): Promise<void> }[] {
    const loadUserGroupsAndProdProfiles: { (): Promise<void> }[] = [];

    const orgsLoaded = new Set<string>();
    const productsLoaded = new Set<string>();

    _.forEach(allUserGroups, (userGroup: ImportUserGroupsDM): void => {
      // check if the org is already loaded in the hierarchy
      if (
        ImportOperationsUtils.isOperationValid(userGroup.operation) ||
        ImportOperationsUtils.isOperationValid(userGroup.profileOperation)
      ) {
        // check if the org is present in the hierarchy
        const currentOrgMaster = HierarchyManager.getOrg(userGroup.orgId);
        if (currentOrgMaster) {
          if (!orgsLoaded.has(userGroup.orgId)) {
            // Note: loading profiles for user groups might not be necessary if this is not required for import
            loadUserGroupsAndProdProfiles.push(async (): Promise<void> => {
              await LoadOrgDataService.loadUserGroups(userGroup.orgId, true);
              await LoadOrgDataService.loadProfilesForLoadedUserGroups(userGroup.orgId);
            });
            orgsLoaded.add(userGroup.orgId);
          }

          if (!_.isNil(userGroup.licenseId) && !productsLoaded.has(userGroup.licenseId)) {
            loadUserGroupsAndProdProfiles.push(() =>
              LoadOrgDataService.loadProfiles(userGroup.orgId, userGroup.licenseId as string)
            );
            productsLoaded.add(userGroup.licenseId);
          }
        }
      }
    });

    return loadUserGroupsAndProdProfiles;
  }

  static async validate(
    allUserGroups: ImportUserGroupsDM[],
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[],
    allProdProfiles: ImportProductProfilesDM[],
    intl: IntlShape
  ): Promise<void> {
    // reset the map on every import
    this.inputUserGroupIdToUserGroupIdInHierarchyMap = new Map<string, string>();

    ImportUserGroups.verifyNullChecks(allUserGroups, intl);
    ImportUserGroups.validateSameDetailsForAUserGroups(allUserGroups, intl);
    ImportUserGroups.validateSameNamedUserGroups(allUserGroups, intl);
    ImportUserGroups.validateUserGroupsToBeUpdatedOrDeleted(allUserGroups, intl);
    ImportUserGroups.validateProfileInUserGroupForDelete(allUserGroups, intl);
    await ImportUserGroups.validateReadOnlyOrgs(allUserGroups, intl);

    ImportUserGroups.validateOrgNotMarkedForDeletion(allUserGroups, allOrgs);
    ImportUserGroups.validateUserGroupOrgsForCreate(allUserGroups, allOrgs, intl);
    ImportUserGroups.validateUserGroupProducts(allUserGroups, allOrgs, allProducts, intl);
    ImportUserGroups.validateUserGroupProductProfiles(allUserGroups, allProdProfiles, intl);
  }

  /**
   * Handle entire user group deletion
   * A user group is to be deleted if operation = 'DELETE' & productProfileId = null | ''
   */
  static handleUserGroupDeletion(allUserGroups: ImportUserGroupsDM[]): number {
    // check if: operation = 'DELETE' && productProfileId = null | ''
    const userGroupsToBeDeleted = _.filter(
      allUserGroups,
      (userGroup: ImportUserGroupsDM): boolean =>
        ImportOperationsUtils.isOperationDelete(userGroup.operation) &&
        ImportUtils.isNullOrEmpty(userGroup.productProfileId)
    );

    let userGroupsDeletedCount = 0;

    _.forEach(userGroupsToBeDeleted, (userGroup: ImportUserGroupsDM): void => {
      const org = HierarchyManager.getOrg(userGroup.orgId);
      // check if deleting usergroup was successful
      if (org) {
        const uUserGroup: UUserGroup | undefined = this.getUserGroupInHierarchy(org.id, userGroup.userGroupId);
        if (uUserGroup) {
          CommandService.addEdit(
            org,
            uUserGroup,
            ObjectTypes.USER_GROUP,
            OrgOperation.DELETE,
            undefined,
            'DELETE_USER_GROUP',
            [uUserGroup.name, CmdDescriptionUtils.getPathname(uUserGroup.orgId)]
          );
          userGroupsDeletedCount++;
        }
      }
    });
    return userGroupsDeletedCount;
  }

  /**
   * Disassociated a product profile from the user group
   */
  static handleUserGroupProfileDeletion(allUserGroups: ImportUserGroupsDM[]): number {
    // check if: profileOperation = 'DELETE' && productProfileId != null | ''
    const userGroupProfilesToBeDeleted = _.filter(
      allUserGroups,
      (userGroup: ImportUserGroupsDM): boolean =>
        ImportOperationsUtils.isOperationDelete(userGroup.profileOperation) &&
        !ImportUtils.isNullOrEmpty(userGroup.productProfileId)
    );

    let userGroupProfileDeletionCount = 0;
    _.forEach(userGroupProfilesToBeDeleted, (userGroup: ImportUserGroupsDM): void => {
      const uUserGroup = this.getUserGroupInHierarchy(userGroup.orgId, userGroup.userGroupId);
      const originalUG = _.cloneDeep(uUserGroup);
      const uProductProfile = ImportProductProfiles.getProfileFromOrg(userGroup.orgId, userGroup.productProfileId);
      if (uUserGroup !== undefined && uProductProfile !== undefined) {
        uUserGroup.removeProfile(uProductProfile);
        CommandService.addEdit(
          HierarchyManager.getOrg(userGroup.orgId) as UOrgMaster,
          uUserGroup,
          ObjectTypes.USER_GROUP,
          OrgOperation.UPDATE,
          originalUG,
          'UPDATE_USER_GROUP',
          [uUserGroup.name, CmdDescriptionUtils.getPathname(uUserGroup.orgId)]
        );
        userGroupProfileDeletionCount++;
      }
    });
    return userGroupProfileDeletionCount;
  }

  /**
   * For user groups, entire user group can be deleted or a profile of the user group is deleted
   * @param allUserGroups all user groups from input file
   */
  static handleUserGroupsToBeDeleted(allUserGroups: ImportUserGroupsDM[]): number {
    return this.handleUserGroupDeletion(allUserGroups) + this.handleUserGroupProfileDeletion(allUserGroups);
  }

  static handleUserGroupDetailsUpdate(allUserGroups: ImportUserGroupsDM[]): number {
    const userGroupsToBeUpdated = ImportUtils.filterToBeUpdatedItems(allUserGroups) as ImportUserGroupsDM[];
    let userGroupsUpdated = 0;
    for (let i = 0; i < userGroupsToBeUpdated.length; i++) {
      const userGroup = userGroupsToBeUpdated[i];
      const uUserGroup = this.getUserGroupInHierarchy(userGroup.orgId, userGroup.userGroupId);
      const originalUG = _.cloneDeep(uUserGroup);
      // check if user group exist in hierarchy and also fields on user groups are to be updated
      if (
        uUserGroup !== undefined &&
        (!_.isEqual(uUserGroup.name, userGroup.userGroupName) ||
          !_.isEqual(uUserGroup.description, userGroup.userGroupDescription))
      ) {
        uUserGroup.name = userGroup.userGroupName;
        uUserGroup.description = userGroup.userGroupDescription;
        CommandService.addEdit(
          HierarchyManager.getOrg(uUserGroup.orgId) as UOrgMaster,
          uUserGroup,
          ObjectTypes.USER_GROUP,
          OrgOperation.UPDATE,
          originalUG,
          'UPDATE_USER_GROUP',
          [uUserGroup.name, CmdDescriptionUtils.getPathname(uUserGroup.orgId)]
        );
        userGroupsUpdated++;
      }
    }
    return userGroupsUpdated;
  }

  static handleUserGroupProfilesUpdate(allUserGroups: ImportUserGroupsDM[]): number {
    const userGroupsToBeUpdated = _.filter(allUserGroups, (userGroup: ImportUserGroupsDM): boolean =>
      ImportOperationsUtils.isOperationUpdate(userGroup.profileOperation)
    );
    let userGroupsUpdatedCount = 0;
    _.forEach(userGroupsToBeUpdated, (userGroup: ImportUserGroupsDM): void => {
      if (userGroup.productProfileId !== undefined) {
        const uUserGroup = this.getUserGroupInHierarchy(userGroup.orgId, userGroup.userGroupId);
        const originalUG = _.cloneDeep(uUserGroup);
        // get the productProfileId in the hierarchy in case the profile is a new profile
        const profileIdInHierarchy = ImportProductProfiles.getProductProfileIdInHierarchy(userGroup.productProfileId);
        const uProductProfile = ImportProductProfiles.getProfileFromOrg(userGroup.orgId, profileIdInHierarchy);
        if (
          uUserGroup !== undefined &&
          uProductProfile !== undefined &&
          _.find(uUserGroup.profiles, (prof: UUserGroupLicenseGroup): boolean =>
            _.isEqual(prof.id, userGroup.productProfileId)
          ) === undefined // validate profile is not already added to the user group
        ) {
          uUserGroup.profiles.push({
            id: uProductProfile.id,
            name: uProductProfile.name,
            productId: uProductProfile.productId,
          });
          CommandService.addEdit(
            HierarchyManager.getOrg(uUserGroup.orgId) as UOrgMaster,
            uUserGroup,
            ObjectTypes.USER_GROUP,
            OrgOperation.UPDATE,
            originalUG,
            'UPDATE_USER_GROUP',
            [uUserGroup.name, CmdDescriptionUtils.getPathname(uUserGroup.orgId)]
          );
          userGroupsUpdatedCount++;
        }
      }
    });
    return userGroupsUpdatedCount;
  }

  /**
   * Only userGroupName and description can be updated for a user group update
   */
  static handleUserGroupsToBeUpdated(allUserGroups: ImportUserGroupsDM[]): number {
    return this.handleUserGroupDetailsUpdate(allUserGroups) + this.handleUserGroupProfilesUpdate(allUserGroups);
  }

  static handleUserGroupToBeCreated(allUserGroups: ImportUserGroupsDM[]): number {
    const userGroupsToBeCreated = _.filter(allUserGroups, (userGroup: ImportUserGroupsDM): boolean => {
      return (
        ImportOperationsUtils.isOperationCreate(userGroup.operation) ||
        ImportOperationsUtils.isOperationCreate(userGroup.profileOperation)
      );
    });

    const userGroupsCreated = new Set<string>();
    let userGroupsCreatedCount = 0;

    _.forEach(userGroupsToBeCreated, (userGroup: ImportUserGroupsDM): void => {
      const userGroupKey = this.getUserGroupKey(userGroup);
      if (!userGroupsCreated.has(userGroupKey)) {
        // mark the user Group as created
        userGroupsCreated.add(userGroupKey);

        // get all records with same userGroupId
        const allUserGroupWithSameIds = _.filter(
          userGroupsToBeCreated,
          (ug: ImportUserGroupsDM): boolean =>
            _.isEqual(ug.userGroupName, userGroup.userGroupName) && _.isEqual(ug.orgId, userGroup.orgId)
        );

        // org can be new org or an existing org
        // for new org, its entry will be present in inputOrgIdToOrgIdInHierarchyMap
        const orgIdInHierarchy = ImportOrganizations.getOrgIdInHierarchy(userGroup.orgId);

        // get a new UUserGroup object
        const uUserGroup = UUserGroup.getNewUserGroup(orgIdInHierarchy);
        // user can only set the name and description
        uUserGroup.name = userGroup.userGroupName;
        uUserGroup.description = userGroup.userGroupDescription;
        uUserGroup.orgId = orgIdInHierarchy;

        // check if there are any product profiles to be added to this user group
        _.forEach(allUserGroupWithSameIds, (userGrpWithSameId: ImportUserGroupsDM): void => {
          if (
            userGrpWithSameId.productProfileId !== undefined &&
            ImportOperationsUtils.isOperationCreate(userGroup.profileOperation)
          ) {
            // get the productProfileId in the hierarchy in case the profile is a new profile
            const profileIdInHierarchy = ImportProductProfiles.getProductProfileIdInHierarchy(
              userGrpWithSameId.productProfileId
            );

            const uProductProfile = ImportProductProfiles.getProfileFromOrg(orgIdInHierarchy, profileIdInHierarchy);
            if (uProductProfile !== undefined) {
              uUserGroup.profiles.push({
                id: uProductProfile.id,
                name: uProductProfile.name,
                productId: uProductProfile.productId,
              });
            }
          }
        });
        const org = HierarchyManager.getOrg(orgIdInHierarchy);
        CommandService.addEdit(
          org as UOrgMaster,
          uUserGroup,
          ObjectTypes.USER_GROUP,
          OrgOperation.CREATE,
          undefined,
          'CREATE_USER_GROUP',
          [uUserGroup.name, CmdDescriptionUtils.getPathname(uUserGroup.orgId)]
        );

        if (org) {
          // map the input user group id to user group id in hierarchy
          this.inputUserGroupIdToUserGroupIdInHierarchyMap.set(userGroup.userGroupId, uUserGroup.id);
          // NOTE: Do not increment totalUserGroupCount for new user groups
          // org.totalUserGroupCount += 1;
          userGroupsCreatedCount++;
        }
      }
    });
    return userGroupsCreatedCount;
  }

  static getUserGroupKey(userGroup: ImportUserGroupsDM): string {
    return `${userGroup.userGroupName}-${userGroup.orgId}`;
  }

  static import(allUserGroups: ImportUserGroupsDM[]): ChangeCount {
    return {
      deleteCount: this.handleUserGroupsToBeDeleted(allUserGroups),
      updateCount: this.handleUserGroupsToBeUpdated(allUserGroups),
      createCount: this.handleUserGroupToBeCreated(allUserGroups),
    };
  }
}

export default ImportUserGroups;
