import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';
import * as log from 'loglevel';

import { ImportOrganizationsDM, ImportProductProfilesDM, ImportProductsDM } from '../DataModelTypes/DataModelTypes';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import { UProduct } from '../../../../services/orgMaster/UProduct';
import { UProductProfile } from '../../../../services/orgMaster/UProductProfile';
import { UResource, UResourceData } from '../../../../services/orgMaster/UResource';
import BanyanCompartmentAPI from '../../../../providers/BanyanCompartmentAPI';

import ImportOrganizations from './ImportOrganizations';
import ImportProductsAndResources from './ImportProductsAndResources';
import ImportOperationsUtils from '../../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import ImportUtils, { ChangeCount } from '../ImportUtils';
import Utils from '../../../../services/utils/Utils';
import { CAP_UNLIMITED, EditState, ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import { CommandService } from '../../../../services/Commands/CommandService';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';

const messages = defineMessages({
  ProductProfilesLabel: {
    id: 'Organizations.Import.ProductProfiles.ProductProfilesLabel',
    defaultMessage: 'Product Profiles',
  },
  NotificationsInvalidField: {
    id: 'Organizations.Import.ProductProfiles.NotificationsInvalidField',
    defaultMessage:
      "notifications can only be true or false for record ''{index}'', orgId ''{orgId}'', and licenseId ''{licenseId}''",
  },
  ProfileNameInvalidField: {
    id: 'Organizations.Import.ProductProfiles.ProfileNameInvalidField',
    defaultMessage:
      "productProfileName cannot be blank for record ''{index}'', orgId ''{orgId}'', and licenseId ''{licenseId}''",
  },
  QuotaInvalidField: {
    id: 'Organizations.Import.ProductProfiles.QuotaInvalidField',
    defaultMessage: "quota should be a number for resourceId ''{resourceId}'' of profile ''{productProfileName}''",
  },
  SelectedInvalidField: {
    id: 'Organizations.Import.ProductProfiles.SelectedInvalidField',
    defaultMessage:
      "selected field should be true or false for resourceId ''{resourceId}'' of profile ''{profileName}'' of org ''{orgId}''",
  },
  ProfileIdInvalidField: {
    id: 'Organizations.Import.ProductProfiles.ProfileIdInvalidField',
    defaultMessage: "productProfileId cannot be blank for record ''{index}''",
  },
  OrgIdInvalidField: {
    id: 'Organizations.Import.ProductProfiles.OrgIdInvalidField',
    defaultMessage: "orgId cannot be blank for record ''{index}''",
  },
  LicenseIdInvalidField: {
    id: 'Organizations.Import.ProductProfiles.LicenseIdInvalidField',
    defaultMessage: "licenseId cannot be blank for record ''{index}''",
  },
  SameProfileDetails: {
    id: 'Organizations.Import.ProductProfiles.SameProfileDetails',
    defaultMessage:
      'productProfileName, orgId & licenseId should be same for a productProfileId. The following productProfileIds do not satisfy this condition:',
  },
  ProfileIdsNotExistForUpdateOrDelete: {
    id: 'Organizations.Import.ProductProfiles.ProfileIdsNotExistForUpdateOrDelete',
    defaultMessage: "The following productProfileIds marked as 'DELETE' or 'UPDATE' do not exist:",
  },
  ResourceIdNotExist: {
    id: 'Organizations.Import.ProductProfiles.ResourceIdNotExist',
    defaultMessage: "resourceId ''{resourceId}'' does not exists in profile ''{profileName}''",
  },
  OrgIdNotExist: {
    id: 'Organizations.Import.ProductProfiles.OrgIdNotExist',
    defaultMessage: 'The following profiles do not have a valid orgId:',
  },
  InvalidOrgIdMarkedForDelete: {
    id: 'Organizations.Import.ProductProfiles.InvalidOrgIdMarkedForDelete',
    defaultMessage: 'The following profiles cannot be added to orgs because those orgs are marked for deletion:',
  },
  InvalidProductIdMarkedForDelete: {
    id: 'Organizations.Import.ProductProfiles.InvalidProductIdMarkedForDelete',
    defaultMessage:
      'The following profiles cannot be added to licenses because those licenses are marked for deletion:',
  },
  LicenseIdNotExist: {
    id: 'Organizations.Import.ProductProfiles.LicenseIdNotExist',
    defaultMessage: 'The following profiles do not have a valid licenseId:',
  },
  ResourceIdInvalid: {
    id: 'Organizations.Import.ProductProfiles.ResourceIdInvalid',
    defaultMessage: "resourceId ''{resourceId}'' is not valid for profile ''{productProfileName}'' in org ''{orgId}''",
  },
  ProfileNameDuplicateInInputFile: {
    id: 'Organizations.Import.ProductProfiles.ProfileNameDuplicate',
    defaultMessage: "Import file contains duplicate profile name ''{profileName}'' in org ''{orgId}''",
  },
  ProductProfileOperationNotValidOnReadOnlyOrg: {
    id: 'Organizations.Import.ProductProfiles.ProductProfileOperationNotValidOnReadOnlyOrg',
    defaultMessage: "Product Profiles cannot be added or updated in the following orgs because of the org's type:",
  },
  ProfilesMessageFormat: {
    id: 'Organizations.Import.ProductProfiles.ProfilesMessageFormat',
    defaultMessage: "Profile name: ''{name}'', orgId: ''{orgId}''",
  },
  ProfileProductMessageFormat: {
    id: 'Organizations.Import.ProductProfiles.ProfileProductMessageFormat',
    defaultMessage: "Profile name: ''{name}'', licenseId: ''{licenseId}''",
  },
  ProfileNameAlreadyExists: {
    id: 'Organizations.Import.ProductProfiles.ProfileNameAlreadyExists',
    defaultMessage: "Profile name ''{name}'' already exists in org ''{orgId}''",
  },
});

class ImportProductProfiles {
  private static licenseIdToProfileResourcesMap = new Map<string, UResource[]>();

  private static inputProfileIdToProfileIdInHierarchyMap = new Map<string, string>();

  /**
   * check if product profile is valid or not
   * A profile can be an existing profile or new profile to be created
   * NOTE: this fn does not validate orgId and the productId passed in. That check needs to be handled
   * separately
   * @param allProdProfiles new product profiles to be added
   * @param productProfileId to checked as valid or not
   */
  public static isProductProfileValid(
    allProdProfiles: ImportProductProfilesDM[],
    productProfileId: string | undefined,
    productId: string | undefined,
    orgId: string
  ): boolean {
    // check if profile is already existing or to be created
    return (
      this.isProfileInHierarchy(orgId, productId, productProfileId) ||
      this.isProfileToBeCreated(allProdProfiles, productProfileId, orgId, productId)
    );
  }

  /**
   * Get productProfileId in the hierarchy for the 'productProfileId' passed in. If 'productProfileId'
   * is an existing profile, return the 'productProfileId' back. For new profiles, get productProfileId
   * in hierarchy from inputProfileIdToProfileIdInHierarchyMap
   */
  public static getProductProfileIdInHierarchy(productProfileId: string): string {
    return this.inputProfileIdToProfileIdInHierarchyMap.has(productProfileId)
      ? (this.inputProfileIdToProfileIdInHierarchyMap.get(productProfileId) as string)
      : productProfileId;
  }

  /**
   * Validate if the profile is to be created. This fn also validates that the profile belongs to the
   * orgId and the productId passed in.
   */
  private static isProfileToBeCreated(
    allProdProfiles: ImportProductProfilesDM[],
    productProfileId: string | undefined,
    orgId: string,
    productId: string | undefined
  ): boolean {
    // check if the orgId and productId matches the orgId and productId set for newly created profiles
    return (
      _.find(
        allProdProfiles,
        (prof: ImportProductProfilesDM): boolean =>
          _.isEqual(prof.productProfileId, productProfileId) &&
          _.isEqual(prof.licenseId, productId) &&
          _.isEqual(prof.orgId, orgId)
      ) !== undefined
    );
  }

  /**
   * Check if the profile is present in the org hierarchy
   */
  private static isProfileInHierarchy(
    orgId: string,
    productId: string | undefined,
    productProfileId: string | undefined
  ): boolean {
    const product = ImportProductsAndResources.getUProductFromHierarchy(orgId, productId);
    if (product !== undefined) {
      return (
        _.find(product.productProfiles, (profile: UProductProfile): boolean =>
          _.isEqual(profile.id, productProfileId)
        ) !== undefined
      );
    }
    return false;
  }

  private static verifyNullChecks(allProdProfiles: ImportProductProfilesDM[], intl: IntlShape): void {
    ImportProductProfiles.verifyNullChecksOfMandatoryFields(allProdProfiles, intl);
    ImportProductProfiles.verifyNullChecksOfProfileDetails(allProdProfiles, intl);
    ImportProductProfiles.verifyNullChecksofResources(allProdProfiles, intl);
  }

  /**
   * For CREATE operation, productProfileName and notifications fields should be set to valid value
   */
  private static verifyNullChecksOfProfileDetails(allProdProfiles: ImportProductProfilesDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allProdProfiles, (profile: ImportProductProfilesDM, index: number): void => {
      if (ImportOperationsUtils.isOperationCreate(profile.operation)) {
        if (_.isNil(profile.notifications)) {
          errorMessages.push(
            formatMessage(messages.NotificationsInvalidField, {
              index: index + 2,
              orgId: profile.orgId,
              licenseId: profile.licenseId,
            })
          );
        }

        if (ImportUtils.isNullOrEmpty(profile.productProfileName)) {
          errorMessages.push(
            formatMessage(messages.ProfileNameInvalidField, {
              index: index + 2,
              orgId: profile.orgId,
              licenseId: profile.licenseId,
            })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.ProductProfilesLabel), errorMessages);
  }

  /**
   * If resourceId is provided for (CREATE | UPDATE) operation, validate grantedQuantity (quota) field for seat resource
   * and selected field for others
   */
  private static verifyNullChecksofResources(allProdProfiles: ImportProductProfilesDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allProdProfiles, (profile: ImportProductProfilesDM): void => {
      if (
        !ImportUtils.isNullOrEmpty(profile.resourceId) &&
        (ImportOperationsUtils.isOperationCreate(profile.resourceOperation) ||
          ImportOperationsUtils.isOperationUpdate(profile.resourceOperation))
      ) {
        if (UResource.isSeatResource(profile.resourceId)) {
          if (ImportUtils.isNullOrEmpty(profile.quota)) {
            errorMessages.push(
              formatMessage(messages.QuotaInvalidField, {
                resourceId: profile.resourceId,
                productProfileName: profile.productProfileName,
              })
            );
          }
        } else if (_.isNil(profile.selected)) {
          errorMessages.push(
            formatMessage(messages.SelectedInvalidField, {
              resourceId: profile.resourceId,
              profileName: profile.productProfileName,
              orgId: profile.orgId,
            })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.ProductProfilesLabel), errorMessages);
  }

  /**
   * check if the productProfileId, productProfileName, licenseId, and orgId are set to valid values.
   * These are the mandatory fields for product profiles.
   */
  private static verifyNullChecksOfMandatoryFields(allProdProfiles: ImportProductProfilesDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allProdProfiles, (profile: ImportProductProfilesDM, index: number): void => {
      if (ImportOperationsUtils.isOperationValid(profile.operation)) {
        // check if productProfileId is blank
        if (
          ImportUtils.isNullOrEmpty(profile.productProfileId) &&
          !ImportOperationsUtils.isOperationCreate(profile.operation)
        ) {
          errorMessages.push(formatMessage(messages.ProfileIdInvalidField, { index: index + 2 }));
        }

        // check if licenseId is blank
        if (ImportUtils.isNullOrEmpty(profile.licenseId)) {
          errorMessages.push(formatMessage(messages.LicenseIdInvalidField, { index: index + 2 }));
        }

        // check id orgId is blank
        if (ImportUtils.isNullOrEmpty(profile.orgId)) {
          errorMessages.push(formatMessage(messages.OrgIdInvalidField, { index: index + 2 }));
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.ProductProfilesLabel), errorMessages);
  }

  /**
   * Profile Name, orgId and licenseId should be same for a ProfileId across all its entries
   */
  private static verifyMandatoryFieldsSameForProfileId(
    allProdProfiles: ImportProductProfilesDM[],
    intl: IntlShape
  ): void {
    const validatedProfileIds = new Set<string>();
    const invalidProfileIds: string[] = [];
    _.forEach(allProdProfiles, (profile: ImportProductProfilesDM): void => {
      // check if the profile names are already validated for this profile
      if (!ImportUtils.isNullOrEmpty(profile.productProfileId) && !validatedProfileIds.has(profile.productProfileId)) {
        validatedProfileIds.add(profile.productProfileId);

        // get all entried for this profile.productProfileId
        const allProfWithSameId = allProdProfiles.filter((eachProfile: ImportProductProfilesDM): boolean =>
          _.isEqual(eachProfile.productProfileId, profile.productProfileId)
        );

        // validate that all have the same productProfileName, orgId and licenseId
        if (
          !allProfWithSameId.every((prof: ImportProductProfilesDM) =>
            _.isEqual(prof.productProfileName, profile.productProfileName)
          ) ||
          !allProfWithSameId.every((prof: ImportProductProfilesDM) => _.isEqual(prof.orgId, profile.orgId)) ||
          !allProfWithSameId.every((prof: ImportProductProfilesDM) => _.isEqual(prof.licenseId, profile.licenseId))
        ) {
          invalidProfileIds.push(profile.productProfileId);
        }
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductProfilesLabel),
      formatMessage(messages.SameProfileDetails),
      Array.from(invalidProfileIds)
    );
  }

  /**
   * find the profile in the org
   */
  public static getProfileFromOrg(orgId: string, profileId: string | undefined): UProductProfile | undefined {
    const org = HierarchyManager.getOrg(orgId);
    if (org !== undefined) {
      return _.find(org.getProfiles(), (eachProfile: UProductProfile): boolean => _.isEqual(profileId, eachProfile.id));
    }
  }

  /**
   * @returns UResource if present on the profile | undefined
   */
  private static getResourceInProfile(profile: ImportProductProfilesDM): UResource | undefined {
    const uProfile = ImportProductProfiles.getProfileFromOrg(profile.orgId, profile.productProfileId);
    if (!_.isNil(uProfile)) {
      return _.find(uProfile.resources, (res: UResource): boolean => _.isEqual(res.code, profile.resourceId));
    }
  }

  /**
   * validate that the profile to be deleted | updated is in the org hierarchy.
   * NOTE: profile is identified as to be deleted if the resourceId field is null | empty and
   * operation = "DELETE"
   */
  private static validateProfilesToBeDeletedAndUpdated(
    allProdProfiles: ImportProductProfilesDM[],
    intl: IntlShape
  ): void {
    // filter profile with delete operation and resourceId: null | blank
    const profilesToBeUpdatedOrDeleted = _.filter(
      allProdProfiles,
      (profile: ImportProductProfilesDM): boolean =>
        (ImportOperationsUtils.isOperationDelete(profile.operation) ||
          ImportOperationsUtils.isOperationUpdate(profile.operation)) &&
        !ImportUtils.isNullOrEmpty(profile.resourceId)
    );

    const invalidProfileIds = new Set<string>();
    const profileIdsValidated = new Set<string>();

    profilesToBeUpdatedOrDeleted.forEach((profile: ImportProductProfilesDM): void => {
      // check if profile is present in the hierarchy
      if (
        !profileIdsValidated.has(profile.productProfileId) &&
        ImportProductProfiles.getProfileFromOrg(profile.orgId, profile.productProfileId) === undefined
      ) {
        invalidProfileIds.add(profile.productProfileId);
      }
      profileIdsValidated.add(profile.productProfileId);
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.ProductProfilesLabel),
      formatMessage(messages.ProfileIdsNotExistForUpdateOrDelete),
      Array.from(invalidProfileIds)
    );
  }

  /**
   * Validate for each resource to be updated | deleted that the resource is present in the profile in the hierarchy
   */
  private static validateProfileResourcesToBeDeletedUpdated(
    allProdProfiles: ImportProductProfilesDM[],
    intl: IntlShape
  ): void {
    const profileResources = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      return (
        (ImportOperationsUtils.isOperationUpdate(profile.resourceOperation) ||
          ImportOperationsUtils.isOperationDelete(profile.resourceOperation)) &&
        !ImportUtils.isNullOrEmpty(profile.resourceId)
      );
    });

    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    profileResources.forEach((profile: ImportProductProfilesDM): void => {
      if (!ImportProductProfiles.getResourceInProfile(profile)) {
        errorMessages.push(
          formatMessage(messages.ResourceIdNotExist, {
            resourceId: profile.resourceId,
            profileName: profile.productProfileName,
          })
        );
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.ProductProfilesLabel), errorMessages);
  }

  /**
   * Validate the orgId is valid for product profile.
   */
  private static validateOrgsForProfilesToBeCreated(
    allProdProfiles: ImportProductProfilesDM[],
    allOrgs: ImportOrganizationsDM[]
  ): void {
    const profilesToBeCreated = ImportUtils.filterToBeCreatedItems(allProdProfiles) as ImportProductProfilesDM[];

    const invalidProfiles = new Set<string>();
    const orgIdsValidated = new Set<string>();

    _.forEach(profilesToBeCreated, (profile: ImportProductProfilesDM): void => {
      if (!orgIdsValidated.has(profile.orgId) && !ImportOrganizations.isOrgValid(allOrgs, profile.orgId)) {
        invalidProfiles.add(this.formatImportProfilesDMRecord(profile));
      }
      orgIdsValidated.add(profile.orgId);
    });

    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.ProductProfilesLabel),
      Utils.getLocalizedMessage(messages.OrgIdNotExist),
      Array.from(invalidProfiles)
    );
  }

  /**
   * Find the first productId and orgId which is in the hierarchy of this product and org | null
   */
  private static getProductIdAndOrgIdInHierarchy(
    orgId: string,
    productId: string,
    allOrgs: ImportOrganizationsDM[],
    productIdToSourceProductIdMap: Map<string, string>
  ): string[] | null {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      // *** org is a new org ***

      // profile resources cannot be fetched using this org hence get details of the parentOrg
      const parentOrgId = ImportOrganizations.getParentOrgId(orgId, allOrgs);

      // As org is a new org, product will also be a new product hence get sourceProductId
      const sourceProductId = productIdToSourceProductIdMap.get(productId);

      if (!ImportUtils.isNullOrEmpty(parentOrgId) && !ImportUtils.isNullOrEmpty(sourceProductId)) {
        return this.getProductIdAndOrgIdInHierarchy(
          parentOrgId as string,
          sourceProductId as string,
          allOrgs,
          productIdToSourceProductIdMap
        );
      }
    } else {
      // *** org is an existing org ***

      // NOTE: a product can be a new product or an existing product
      // check if product is present in hierarchy
      if (_.find(org.products, (product: UProduct): boolean => _.isEqual(product.id, productId)) !== undefined) {
        // product exists in the hierarchy
        return [org.organization.id, productId];
      }

      // product is a new product
      if (productIdToSourceProductIdMap.has(productId)) {
        return this.getProductIdAndOrgIdInHierarchy(
          org.organization.parentOrgId,
          productIdToSourceProductIdMap.get(productId) as string,
          allOrgs,
          productIdToSourceProductIdMap
        );
      }
    }
    return null;
  }

  /**
   * get resources for the profile.
   */
  private static async getResourcesForProfiles(
    profile: ImportProductProfilesDM,
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[]
  ): Promise<UResource[] | undefined> {
    const productIdToSourceProductIdMap = ImportProductsAndResources.mapProductIdToSourceProductId(allProducts);

    // Resources for the profiles are fetched by using the product id to which the profile belongs.
    // NOTE: productid can be new product or an already existing one.
    // banyan svc will only return resources for a valid product id. Therefore, for new product,
    // the resources are fetched using the sourceProductId which is in hierarchy (recursive).
    // getProductIdAndOrgIdInHierarchy traverses up the hierarchy and returns the first existing product and org
    // for valid values, orgIdAndProductInHierarchy will have 2 elements: orgId and productId
    const orgIdAndProductInHierarchy = this.getProductIdAndOrgIdInHierarchy(
      profile.orgId,
      profile.licenseId,
      allOrgs,
      productIdToSourceProductIdMap
    );
    if (orgIdAndProductInHierarchy !== null) {
      try {
        const profileResourceOptions = await BanyanCompartmentAPI.getProfileResourceOptions(
          orgIdAndProductInHierarchy[0],
          orgIdAndProductInHierarchy[1]
        );
        return profileResourceOptions.map((resData: UResourceData) => new UResource(resData));
      } catch (err) {
        return undefined;
      }
    }
  }

  /**
   * Validate that no operations are performed on a read only org
   * @param allProdProfiles all product profiles from the input file
   */
  static async validateReadOnlyOrgs(allProdProfiles: ImportProductProfilesDM[], intl: IntlShape): Promise<void> {
    const orgIds = new Set<string>();
    _.forEach(allProdProfiles, (profile: ImportProductProfilesDM): void => {
      if (ImportOperationsUtils.isOperationValid(profile.operation)) {
        orgIds.add(profile.orgId);
      }
    });

    const { formatMessage } = intl;
    await ImportUtils.throwIfExistingOrgIsReadOnly(
      formatMessage(messages.ProductProfilesLabel),
      formatMessage(messages.ProductProfileOperationNotValidOnReadOnlyOrg),
      Array.from(orgIds)
    );
  }

  /**
   * Validate if a valid product is selected for the profile
   */
  private static validateProductsForProfilesToBeCreated(
    allProdProfiles: ImportProductProfilesDM[],
    allProducts: ImportProductsDM[]
  ): void {
    const profilesToBeCreated = ImportUtils.filterToBeCreatedItems(allProdProfiles) as ImportProductProfilesDM[];

    const profilesValidated = new Set<string>();
    const invalidProfiles = new Set<string>();

    _.forEach(profilesToBeCreated, (profile: ImportProductProfilesDM): void => {
      if (
        !profilesValidated.has(profile.licenseId) &&
        !ImportProductsAndResources.isProductValid(allProducts, profile.licenseId, profile.orgId)
      ) {
        invalidProfiles.add(this.formatImportProfilesDMRecord(profile));
      }
      profilesValidated.add(profile.productProfileId);
    });

    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.ProductProfilesLabel),
      Utils.getLocalizedMessage(messages.LicenseIdNotExist),
      Array.from(invalidProfiles)
    );
  }

  /**
   * Fetch resources for profiles.
   * NOTE: profile resources are fetched for all orgs with create operation even if no resource operation
   * is performed on that org. This is because Quota field is saved on the profile resources.
   */
  private static async fetchResourcesForProfile(
    allProdProfiles: ImportProductProfilesDM[],
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[]
  ): Promise<void> {
    const profilesToBeCreated = ImportUtils.filterToBeCreatedItems(allProdProfiles) as ImportProductProfilesDM[];

    for (let i = 0; i < profilesToBeCreated.length; i++) {
      const profile = profilesToBeCreated[i];
      if (!this.licenseIdToProfileResourcesMap.has(profile.licenseId)) {
        const resources = await this.getResourcesForProfiles(profile, allOrgs, allProducts);
        this.licenseIdToProfileResourcesMap.set(profile.licenseId, resources as UResource[]);
      }
    }
  }

  /**
   * Validate profile resources for profiles to be created
   */
  private static validateProfileResourcesForToBeCreatedProfiles(
    allProdProfiles: ImportProductProfilesDM[],
    intl: IntlShape
  ): void {
    const profilesToBeCreated = _.filter(
      allProdProfiles,
      (profile: ImportProductProfilesDM): boolean =>
        ImportOperationsUtils.isOperationCreate(profile.resourceOperation) &&
        !ImportUtils.isNullOrEmpty(profile.resourceId)
    );

    const errorMessages = new Set<string>();
    const { formatMessage } = intl;
    for (let i = 0; i < profilesToBeCreated.length; i++) {
      const profile = profilesToBeCreated[i];

      // check if resources are already fetched for this product
      const resources = this.licenseIdToProfileResourcesMap.get(profile.licenseId);

      // validate if the resource is present
      if (
        _.find(resources, (resource: UResource): boolean => _.isEqual(resource.code, profile.resourceId)) === undefined
      ) {
        errorMessages.add(
          formatMessage(messages.ResourceIdInvalid, {
            resourceId: profile.resourceId,
            productProfileName: profile.productProfileName,
            orgId: profile.orgId,
          })
        );
      }
    }

    ImportUtils.displayIfError(formatMessage(messages.ProductProfilesLabel), Array.from(errorMessages));
  }

  /**
   * Validate that the org to which the profile is to be added is not marked for delete
   * @param allProdProfiles all profiles from input file
   * @param allOrgs all orgs from input file
   */
  private static validateOrgNotMarkedForDeletion(
    allProdProfiles: ImportProductProfilesDM[],
    allOrgs: ImportOrganizationsDM[]
  ): void {
    const profilesToBeCreated = ImportUtils.filterToBeCreatedItems(allProdProfiles) as ImportProductProfilesDM[];
    const orgsToDelete = new Set<string>();
    _.forEach(profilesToBeCreated, (profile: ImportProductProfilesDM): void => {
      if (ImportOrganizations.isOrgMarkedForDelete(allOrgs, profile.orgId)) {
        orgsToDelete.add(this.formatImportProfilesDMRecord(profile));
      }
    });
    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.ProductProfilesLabel),
      Utils.getLocalizedMessage(messages.InvalidOrgIdMarkedForDelete),
      Array.from(orgsToDelete)
    );
  }

  /**
   * Validate that the product to which the profile is to be added is not marked for delete
   * @param allProdProfiles all profiles from input file
   * @param allProducts all products from input file
   */
  private static validateProductNotMarkedForDeletion(
    allProdProfiles: ImportProductProfilesDM[],
    allProducts: ImportProductsDM[]
  ): void {
    const profilesToBeCreated = ImportUtils.filterToBeCreatedItems(allProdProfiles) as ImportProductProfilesDM[];
    const productsToDelete = new Set<string>();
    _.forEach(profilesToBeCreated, (profile: ImportProductProfilesDM): void => {
      if (ImportProductsAndResources.isProductMarkedForDelete(allProducts, profile.licenseId)) {
        productsToDelete.add(
          Utils.getLocalizedMessage(messages.ProfileProductMessageFormat, {
            name: profile.productProfileName,
            licenseId: profile.licenseId,
          })
        );
      }
    });
    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.ProductProfilesLabel),
      Utils.getLocalizedMessage(messages.InvalidProductIdMarkedForDelete),
      Array.from(productsToDelete)
    );
  }

  private static async validateProfileDetailsToBeCreated(
    allProdProfiles: ImportProductProfilesDM[],
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[],
    intl: IntlShape
  ): Promise<void> {
    // validate if the org is valid for the profile.
    ImportProductProfiles.validateOrgsForProfilesToBeCreated(allProdProfiles, allOrgs);
    ImportProductProfiles.validateOrgNotMarkedForDeletion(allProdProfiles, allOrgs);

    // validate if the product is valid for the profile
    ImportProductProfiles.validateProductsForProfilesToBeCreated(allProdProfiles, allProducts);
    ImportProductProfiles.validateProductNotMarkedForDeletion(allProdProfiles, allProducts);

    // Fetch all the profile resources
    // NOTE: This call must be made before validateProfileResourcesForToBeCreatedProfiles
    await ImportProductProfiles.fetchResourcesForProfile(allProdProfiles, allOrgs, allProducts);

    // validate profile resources
    ImportProductProfiles.validateProfileResourcesForToBeCreatedProfiles(allProdProfiles, intl);
  }

  /**
   * load all products and profiles for all the orgs on which import operations are performed
   * products and prod profiles for certain orgs down the hierarchy might not have been loaded when the import is
   * performed
   * @returns fn[] where each fn returns a promise to load products and profiles for one org
   */
  public static loadProductsAndProdProfiles(allProdProfiles: ImportProductProfilesDM[]): { (): Promise<void> }[] {
    const orgsLoaded = new Set<string>();
    // const productsLoaded = new Set<string>();

    const loadProdAndProfiles: { (): Promise<void> }[] = [];
    _.forEach(allProdProfiles, (profile: ImportProductProfilesDM): void => {
      // validate that the products are not already loaded on the org
      if (
        (ImportOperationsUtils.isOperationValid(profile.operation) ||
          ImportOperationsUtils.isOperationValid(profile.resourceOperation)) &&
        !orgsLoaded.has(profile.orgId)
      ) {
        loadProdAndProfiles.push(async () => {
          // NOTE: products must be loaded before the profiles for concerned products can be loaded
          // FYI: if the products or the profiles are already loaded, NO extra calls are made
          await LoadOrgDataService.loadProducts(profile.orgId);
          return LoadOrgDataService.loadProfiles(profile.orgId, profile.licenseId, true);
        });
        orgsLoaded.add(profile.orgId);
      }
    });
    return loadProdAndProfiles;
  }

  static async validate(
    allProdProfiles: ImportProductProfilesDM[],
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[],
    intl: IntlShape
  ): Promise<void> {
    // reset the map on every import
    this.inputProfileIdToProfileIdInHierarchyMap = new Map<string, string>();

    ImportProductProfiles.verifyNullChecks(allProdProfiles, intl);
    ImportProductProfiles.verifyMandatoryFieldsSameForProfileId(allProdProfiles, intl);
    ImportProductProfiles.validateDuplicateProfileName(allProdProfiles, intl);
    ImportProductProfiles.validateProfilesToBeDeletedAndUpdated(allProdProfiles, intl);
    ImportProductProfiles.validateProfileResourcesToBeDeletedUpdated(allProdProfiles, intl);
    await ImportProductProfiles.validateReadOnlyOrgs(allProdProfiles, intl);

    await ImportProductProfiles.validateProfileDetailsToBeCreated(allProdProfiles, allOrgs, allProducts, intl);
  }

  private static handleResourceDeactivation(allProdProfiles: ImportProductProfilesDM[]): number {
    // filter resources to be deactivated. NOTE: resourceOperation is considered when filtering
    const profileResourcesToBeDeactivated = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      return (
        ImportOperationsUtils.isOperationDelete(profile.resourceOperation) &&
        !ImportUtils.isNullOrEmpty(profile.resourceId)
      );
    });

    let resourceDeactivatedCount = 0;

    _.forEach(profileResourcesToBeDeactivated, (profResourceToDelete: ImportProductProfilesDM): void => {
      // validate that the product to which the profile belongs is editable
      const uProduct = this.getProductFromOrg(profResourceToDelete);
      if (!uProduct?.allLicenseTuplesAllowEditing()) {
        log.warn('{} product does not allow changes.', uProduct?.name);
        return;
      }

      // find the matching profile
      const uProfile = ImportProductProfiles.getProfileFromOrg(
        profResourceToDelete.orgId,
        profResourceToDelete.productProfileId
      );
      const originalProfile = _.cloneDeep(uProfile);
      const matchingResource = this.getResourceInProfile(profResourceToDelete);
      if (matchingResource !== undefined) {
        matchingResource.selected = false;
        if (uProfile) {
          CommandService.addEdit(
            HierarchyManager.getOrg(uProfile.orgId) as UOrgMaster,
            uProfile,
            ObjectTypes.PRODUCT_PROFILE,
            OrgOperation.UPDATE,
            originalProfile,
            'UPDATE_PRDT_PROFILE',
            [
              uProfile.name,
              ImportProductProfiles.getProductName(uProfile.orgId, uProfile.productId),
              CmdDescriptionUtils.getPathname(uProfile.orgId),
            ]
          );
        }
        resourceDeactivatedCount++;
        ImportUtils.markOrgAsEdited(profResourceToDelete.orgId, EditState.UPDATE);
      }
    });

    return resourceDeactivatedCount;
  }

  /**
   * Delete the profile from the hierarchy
   */
  private static handleEntireProfileDeletion(allProdProfiles: ImportProductProfilesDM[]): number {
    // filter entire profiles to be deleted.
    // NOTE: A profile is to be deleted if operation = 'delete' and resourceId = null | blank
    const profilesToBeDeleted = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      return (
        ImportOperationsUtils.isOperationDelete(profile.operation) && ImportUtils.isNullOrEmpty(profile.resourceId)
      );
    });
    let profDeletedCount = 0;

    for (let i = 0; i < profilesToBeDeleted.length; i++) {
      const profToDelete = profilesToBeDeleted[i];

      const affectedProduct = ImportProductsAndResources.getUProductFromHierarchy(
        profToDelete.orgId,
        profToDelete.licenseId
      );
      // check if the product exists and is editable
      if (affectedProduct && affectedProduct.allLicenseTuplesAllowEditing()) {
        const uProductProfile: UProductProfile | undefined = _.find(affectedProduct.productProfiles, [
          'id',
          profToDelete.productProfileId,
        ]);
        if (uProductProfile) {
          CommandService.addEdit(
            HierarchyManager.getOrg(uProductProfile.orgId) as UOrgMaster,
            uProductProfile,
            ObjectTypes.PRODUCT_PROFILE,
            OrgOperation.DELETE,
            undefined,
            'DELETE_PRDT_PROFILE',
            [
              uProductProfile.name,
              ImportProductProfiles.getProductName(uProductProfile.orgId, uProductProfile.productId),
              CmdDescriptionUtils.getPathname(uProductProfile.orgId),
            ]
          );
          affectedProduct.totalProfileCount -= 1;
          profDeletedCount++;
        }
      }
    }

    return profDeletedCount;
  }

  /**
   * Handle profiles to deleted.
   * NOTE: There are two types of deletion that can be performed
   * a) entire profile deletion
   * b) resource deactivation
   */
  private static handleProfilesToBeDeleted(allProdProfiles: ImportProductProfilesDM[]): number {
    return this.handleEntireProfileDeletion(allProdProfiles) + this.handleResourceDeactivation(allProdProfiles);
  }

  private static handleProfileResourceUpdate(allProdProfiles: ImportProductProfilesDM[]): number {
    // filter profile resources to be updated
    const profResourceToBeUpdated = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      return (
        ImportOperationsUtils.isOperationUpdate(profile.resourceOperation) &&
        !ImportUtils.isNullOrEmpty(profile.resourceId)
      );
    });

    let resourcesUpdatedCount = 0;
    // group profiles by profileId
    const groupedProfileResources = _.groupBy(profResourceToBeUpdated, 'productProfileId');
    _.forEach(groupedProfileResources, (resources: ImportProductProfilesDM[], profileId: string) => {
      // NOTE: resources[] is the list of all profile entries from the input file that belong to the same 'profileId'
      // check if the product to which the profile belongs is editable
      const uProduct = this.getProductFromOrg(resources[0]);
      if (!uProduct?.allLicenseTuplesAllowEditing()) {
        log.warn('{} product does not allow changes.', uProduct?.name);
        return;
      }

      const uProfile = ImportProductProfiles.getProfileFromOrg(resources[0].orgId, profileId);
      const originalProfile = _.cloneDeep(uProfile);
      if (uProfile !== undefined) {
        resourcesUpdatedCount += this.findAndUpdateResources(uProfile.resources, resources);
        CommandService.addEdit(
          HierarchyManager.getOrg(uProfile.orgId) as UOrgMaster,
          uProfile,
          ObjectTypes.PRODUCT_PROFILE,
          OrgOperation.UPDATE,
          originalProfile,
          'UPDATE_PRDT_PROFILE',
          [
            uProfile.name,
            ImportProductProfiles.getProductName(uProfile.orgId, uProfile.productId),
            CmdDescriptionUtils.getPathname(uProfile.orgId),
          ]
        );
      }
    });

    return resourcesUpdatedCount;
  }

  private static handleProfileDetailsUpdate(allProdProfiles: ImportProductProfilesDM[]): number {
    // filter records whose profile details are to be updated
    const profilesToBeUpdated = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      return ImportOperationsUtils.isOperationUpdate(profile.operation);
    });

    let profUpdatedCount = 0;

    for (let i = 0; i < profilesToBeUpdated.length; i++) {
      const profile = profilesToBeUpdated[i];

      // check if the product to which the profile belongs is editable
      const uProduct = this.getProductFromOrg(profile);
      if (!uProduct?.allLicenseTuplesAllowEditing()) {
        log.warn('{} product does not allow changes.', uProduct?.name);
        continue;
      }

      const uProfile = ImportProductProfiles.getProfileFromOrg(profile.orgId, profile.productProfileId);
      const originalProfile = _.cloneDeep(uProfile);
      // check profile is valid and profile details are different
      const sanitizedNotificationField = ImportUtils.sanitizeBoolean(profile.notifications);
      // check if profile is valid and
      // existing & imported profile details are different
      if (
        uProfile !== undefined &&
        (!_.isEqual(uProfile.name, profile.productProfileName) ||
          !_.isEqual(uProfile.notifications, sanitizedNotificationField))
      ) {
        uProfile.name = profile.productProfileName;

        uProfile.notifications = sanitizedNotificationField;
        this.findAndUpdateResources(uProfile.resources, [profile]);
        CommandService.addEdit(
          HierarchyManager.getOrg(uProfile.orgId) as UOrgMaster,
          uProfile,
          ObjectTypes.PRODUCT_PROFILE,
          OrgOperation.UPDATE,
          originalProfile,
          'UPDATE_PRDT_PROFILE',
          [
            uProfile.name,
            ImportProductProfiles.getProductName(uProfile.orgId, uProfile.productId),
            CmdDescriptionUtils.getPathname(uProfile.orgId),
          ]
        );

        // mark the org as updated
        ImportUtils.markOrgAsEdited(uProfile.orgId, EditState.UPDATE);

        // update count when a profile is updated
        profUpdatedCount++;
      }
    }
    return profUpdatedCount;
  }

  /**
   * Handle profiles to be updated
   * NOTE: Two of details can be updated on a profile
   * 1) profile details like profileName, notifications
   * 2) profile resources: services and seats
   */
  private static handleProfilesToBeUpdated(allProdProfiles: ImportProductProfilesDM[]): number {
    return this.handleProfileDetailsUpdate(allProdProfiles) + this.handleProfileResourceUpdate(allProdProfiles);
  }

  /**
   * check if profileName already exist in the hierarchy for the product
   * @returns true is a profile with same name is found else false
   */
  private static isProfileNameInHierarchy(profile: ImportProductProfilesDM): boolean {
    const product = ImportProductsAndResources.getUProductFromHierarchy(profile.orgId, profile.licenseId);
    return product !== undefined && _.find(product.productProfiles, ['name', profile.productProfileName]) !== undefined;
  }

  /**
   * @returns profile names for all profiles to be updated and created for the orgId passed in
   */
  static getNamesForProfilesToBeUpdatedAndCreated(
    allProdProfiles: ImportProductProfilesDM[],
    orgId: string,
    profileId: string
  ): string[] {
    const profilesToBeUpdateCreated = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      const profileCondition = profileId === undefined ? true : profileId === profile.productProfileId;
      return (
        (ImportOperationsUtils.isOperationUpdate(profile.operation) ||
          ImportOperationsUtils.isOperationCreate(profile.operation)) &&
        _.isEqual(orgId, profile.orgId) &&
        !_.isEqual(profileId, profile.productProfileId) &&
        profileCondition
      );
    });
    return profilesToBeUpdateCreated.map((profile: ImportProductProfilesDM): string => profile.productProfileName);
  }

  /**
   * Validate that two profile with the same name are not added into the hierarchy
   * To check this the productId is mapped to all the profile Names which are to be added to the product (handles
   * same named profiles in input files)
   * NOTE: this fn also checks is the profile with same name already exist in the product
   */
  private static validateDuplicateProfileName(allProdProfiles: ImportProductProfilesDM[], intl: IntlShape): void {
    const profilesToBeCreated = ImportUtils.filterToBeCreatedItems(allProdProfiles) as ImportProductProfilesDM[];

    const profileIdsValidated = new Set<string>();
    const invalidProfiles = new Set<string>();

    _.forEach(profilesToBeCreated, (profile: ImportProductProfilesDM): void => {
      const profileKey = this.getProfileKey(profile);
      // if profileId is not already validated
      if (!profileIdsValidated.has(profileKey)) {
        // mark the profileId as validate
        profileIdsValidated.add(profileKey);

        if (this.isProfileNameInHierarchy(profile)) {
          invalidProfiles.add(
            Utils.getLocalizedMessage(messages.ProfileNameAlreadyExists, {
              name: profile.productProfileName,
              orgId: profile.orgId,
            })
          );
          return;
        }

        // get names for all profiles which to be updated and created
        // This handles the case when a profile A is renamed as B in the input file and
        // there is a new profile with the name B to be created for the same org
        // This is an invalid operation as a org cannot have profiles with same name
        const profileNames = new Set<string>(
          this.getNamesForProfilesToBeUpdatedAndCreated(allProdProfiles, profile.orgId, profile.productProfileId)
        );

        // check if same profile name exists
        // NOTE: user can try to create profile with same name but with different dummy productProfileId in input file, which will be flagged as an error here.
        if (profileNames.has(profile.productProfileName)) {
          invalidProfiles.add(
            Utils.getLocalizedMessage(messages.ProfileNameDuplicateInInputFile, {
              profileName: profile.productProfileName,
              orgId: profile.orgId,
            })
          );
        } else {
          // map the licenseid to the profileName
          profileNames.add(profile.productProfileName);
        }
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfError(formatMessage(messages.ProductProfilesLabel), Array.from(invalidProfiles));
  }

  private static formatImportProfilesDMRecord(profile: ImportProductProfilesDM): string {
    return Utils.getLocalizedMessage(messages.ProfilesMessageFormat, {
      name: profile.productProfileName,
      orgId: profile.orgId,
    });
  }

  /**
   * Get UProduct from the hierarchy
   */
  private static getProductFromOrg(profile: ImportProductProfilesDM): UProduct | undefined {
    // get the orgId for the org in the hierarchy. An org can be new or an existing org
    const orgId = ImportOrganizations.getOrgIdInHierarchy(profile.orgId);

    // find the productId in hierarchy if the product is a new product
    const productId = ImportProductsAndResources.getProductIdInHierarchy(profile.licenseId);

    return ImportProductsAndResources.getUProductFromHierarchy(orgId, productId);
  }

  /**
   * returns the current product name. If product not found , then return empty string.
   * NOTE: The chances of not finding the product is very rare as a profile operation will always be associated
   * with a product in context.
   * @private
   */
  private static getProductName(orgId: string, productId: string): string {
    const currentProduct = ImportProductsAndResources.getUProductFromHierarchy(orgId, productId);
    return currentProduct ? currentProduct.name : '';
  }
  /**
   * Update the 'uResources' for each profile in 'profiles'
   * @param profiles import data for the concerned profile
   * @param uResources UResources for the concerned profile saved in state
   */
  private static findAndUpdateResources(uResources: UResource[], profiles: ImportProductProfilesDM[]): number {
    let resourcesUpdatedCount = 0;
    // for each profile resource to be updated, find the matching UResource
    _.forEach(profiles, (profile: ImportProductProfilesDM): void => {
      // validate is the resource is to be updated or created
      if (
        ImportOperationsUtils.isOperationCreate(profile.resourceOperation) ||
        ImportOperationsUtils.isOperationUpdate(profile.resourceOperation)
      ) {
        const matchingResource = _.find(uResources, (resource: UResource): boolean =>
          _.isEqual(resource.code, profile.resourceId)
        );
        if (matchingResource !== undefined) {
          // update cap field for license resources or Stock FI else update selected other resources
          if (UResource.isSeatResource(matchingResource.code) || matchingResource.isAStockFI()) {
            const quota = this.getQuotaValue(profiles, matchingResource.code);
            // update only when quota value is different than existing value and existing quota != UNLIMITED
            if (!_.isEqual(matchingResource.cap, quota) && !_.isEqual(matchingResource.cap, CAP_UNLIMITED)) {
              matchingResource.cap = quota;
              resourcesUpdatedCount++;
            }
          } else {
            const sanitizedSelectedField = ImportUtils.sanitizeBoolean(profile.selected);
            if (!_.isEqual(sanitizedSelectedField, matchingResource.selected)) {
              matchingResource.selected = sanitizedSelectedField;
              resourcesUpdatedCount++;
            }
          }
        }
      }
    });
    return resourcesUpdatedCount;
  }

  /**
   * Find the resource identified by 'resourceCode' and return quota field if valid, else return "0"
   * @param profiles import data for the concerned profiles
   * @param resourceCode resource code for which quota field is to be returned
   */
  private static getQuotaValue(profiles: ImportProductProfilesDM[], resourceCode: string): string {
    const matchingResource = _.find(profiles, (profile: ImportProductProfilesDM): boolean =>
      _.isEqual(profile.resourceId?.trim(), resourceCode.trim())
    );
    if (matchingResource !== undefined) {
      // return integer value if quota is a number
      if (Utils.canParseInt(matchingResource.quota)) {
        return matchingResource.quota as string;
      }
      // return 'UNLIMITED' if quota value is 'UNLIMITED', fix https://jira.corp.adobe.com/browse/BANY-842
      if (CAP_UNLIMITED === matchingResource.quota) {
        return CAP_UNLIMITED;
      }
    }
    return '0';
  }

  private static getProfileKey(profile: ImportProductProfilesDM): string {
    return `${profile.productProfileName}-${profile.licenseId}-${profile.orgId}`;
  }

  private static async handleProfileToBeCreated(allProdProfiles: ImportProductProfilesDM[]): Promise<number> {
    const profilesToBeCreated = _.filter(allProdProfiles, (profile: ImportProductProfilesDM): boolean => {
      return (
        ImportOperationsUtils.isOperationCreate(profile.operation) ||
        ImportOperationsUtils.isOperationCreate(profile.resourceOperation)
      );
    });

    const profilesCreated = new Set<string>();
    let profilesCreatedCount = 0;

    for (let i = 0; i < profilesToBeCreated.length; i++) {
      const profile = profilesToBeCreated[i];
      const profileKey = this.getProfileKey(profile);

      // check if the profile is already created
      if (!profilesCreated.has(profileKey)) {
        // mark the profile as created
        profilesCreated.add(profileKey);

        // filter all profile ids with the same id
        const allProfilesWithSameId = _.filter(
          profilesToBeCreated,
          (prof: ImportProductProfilesDM): boolean =>
            _.isEqual(prof.productProfileName, profile.productProfileName) &&
            _.isEqual(prof.licenseId, profile.licenseId) &&
            _.isEqual(prof.orgId, profile.orgId)
        );
        if (allProfilesWithSameId.length > 0) {
          const uProduct = this.getProductFromOrg(profile);
          // validate that product exists and is editable
          if (uProduct !== undefined && uProduct.allLicenseTuplesAllowEditing()) {
            // create a default copy of the profile
            const orgId = ImportOrganizations.getOrgIdInHierarchy(profile.orgId);
            const uProfile = UProductProfile.getNewProfileObject(uProduct, orgId);
            uProfile.name = profile.productProfileName;
            uProfile.notifications = ImportUtils.sanitizeBoolean(profile.notifications);
            uProfile.orgId = orgId;

            // get default resources for this product
            // NOTE: it is guaranteed that the product is in the hierarchy when the code reaches here
            if (this.licenseIdToProfileResourcesMap.has(profile.licenseId)) {
              uProfile.resources = _.cloneDeep(
                this.licenseIdToProfileResourcesMap.get(profile.licenseId) as UResource[]
              );
            } else {
              uProfile.resources = [];
            }

            // update the resources on the profile
            this.findAndUpdateResources(uProfile.resources, allProfilesWithSameId);

            // add the profile to the product
            CommandService.addEdit(
              HierarchyManager.getOrg(uProfile.orgId) as UOrgMaster,
              uProfile,
              ObjectTypes.PRODUCT_PROFILE,
              OrgOperation.CREATE,
              undefined,
              'CREATE_PRDCT_PROFILE',
              [
                uProfile.name,
                ImportProductProfiles.getProductName(uProfile.orgId, uProfile.productId),
                CmdDescriptionUtils.getPathname(uProfile.orgId),
              ]
            );

            // NOTE: totalProfileCount should not be incremented for new profiles
            // uProduct.totalProfileCount += 1;
            profilesCreatedCount++;
            this.inputProfileIdToProfileIdInHierarchyMap.set(profile.productProfileId, uProfile.id);
          }
        }
      }
    }
    return profilesCreatedCount;
  }

  static async import(allProdProfiles: ImportProductProfilesDM[]): Promise<ChangeCount> {
    return {
      deleteCount: ImportProductProfiles.handleProfilesToBeDeleted(allProdProfiles),
      updateCount: ImportProductProfiles.handleProfilesToBeUpdated(allProdProfiles),
      createCount: await ImportProductProfiles.handleProfileToBeCreated(allProdProfiles),
    };
  }
}

export default ImportProductProfiles;
