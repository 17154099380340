import * as _ from 'lodash';
import BanyanCompartmentAPI from '../../../providers/BanyanCompartmentAPI';
import { UAdmin, UAdminData } from '../../../services/orgMaster/UAdmin';
import { UUserGroup, UUserGroupData } from '../../../services/orgMaster/UUserGroup';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { ResponseWithCounts } from '../../../services/providerUtils/ProviderUtil';

class JILSearch {
  /**
   * Admin JIL Search
   * isInputOrgACopy is true for the edit dialog, which maintain a copy of the current org to store edits
   */
  public static async adminSearch(org: UOrgMaster, filterQuery: string): Promise<void> {
    // load admins for org matching search criteria
    const response: ResponseWithCounts = await BanyanCompartmentAPI.getAdminsV2(org.id, {
      searchQuery: filterQuery,
    });
    // retrieve admins already loaded for org
    const adminsLoadedForOriginal = org.getAdmins();
    // filter out admins already loaded on org (the resulting list should only contain admins matching the search criteria that haven't already been loaded)
    const newAdmins = _.filter(
      response.data,
      (adminData: UAdminData): boolean =>
        !_.find(adminsLoadedForOriginal, (currentAdmin: UAdmin): boolean => currentAdmin.id === adminData.id)
    );
    // add the newly loaded admins matching the search criteria to the org
    _.forEach(newAdmins, (adminData: UAdminData): void => {
      org.editAdminV2(new UAdmin(adminData));
    });
  }

  /**
   * User group JIL Search
   */
  public static async userGroupSearch(org: UOrgMaster, filterQuery: string): Promise<void> {
    const response: ResponseWithCounts = await BanyanCompartmentAPI.getUserGroups(org.id, {
      searchQuery: filterQuery,
    });
    const userGroupsNotLoaded: UUserGroupData[] = _.filter(
      response.data,
      (userGroupData: UUserGroupData): boolean =>
        !_.find(org.userGroups, (userGroups) => userGroups.id === userGroupData.id)
    );
    _.forEach(userGroupsNotLoaded, (userGroupData: UUserGroupData) => {
      org.addUserGroup(new UUserGroup(userGroupData));
    });
  }
}

export default JILSearch;
