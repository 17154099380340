import React from 'react';
import * as _ from 'lodash';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import Provider from '@react/react-spectrum/Provider';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import ProfileSelector from '../../../../../components/selectors/ProfileSelector';
import { UOrgMaster } from '../../../../../services/orgMaster/UOrgMaster';
import { UProductProfile } from '../../../../../services/orgMaster/UProductProfile';
import ProductContractData from '../../../../../services/orgMaster/ProductContractData';
import './ProfileSection.css';
import { UUserGroupLicenseGroup } from '../../../../../services/orgMaster/UUserGroup';
import { LoadOrgDataService } from '../../../../../services/orgMaster/LoadOrgDataService';
import { LocaleSettings } from '../../../../../services/locale/LocaleSettings';

interface ProfileSectionProps extends WrappedComponentProps {
  disabled?: boolean;
  profiles: UUserGroupLicenseGroup[];
  onDeleteProfile: (profile: UUserGroupLicenseGroup) => void;
  onSelectProfile: (profileSelection: UProductProfile) => void;
  selectedOrg: UOrgMaster;
}

interface ProfileSectionState {
  productsContractsData: ProductContractData[]; // products with contract data for current org
  selectedProduct: string | undefined;
  isProfilesLoading: boolean;
}

const messages = defineMessages({
  SelectProduct: {
    id: 'EditCompartment.UserGroups.EditUserGroup.ProductSelection',
    defaultMessage: 'Select product',
  },
  NoProducts: {
    id: 'EditCompartment.UserGroups.EditUserGroup.NoProducts',
    defaultMessage: 'No products',
  },
  SelectProfile: {
    id: 'EditCompartment.UserGroups.EditUserGroup.ProfileSelection',
    defaultMessage: 'Select profile',
  },
  NoProfiles: {
    id: 'EditCompartment.UserGroups.EditUserGroup.NoProfiles',
    defaultMessage: 'No profiles',
  },
});

class ProfileSection extends React.Component<ProfileSectionProps, ProfileSectionState> {
  abortController: AbortController;

  constructor(props: ProfileSectionProps) {
    super(props);
    this.state = {
      selectedProduct: undefined,
      isProfilesLoading: false,
      productsContractsData: ProductContractData.createMultipleFromProductsAndContracts(
        this.props.selectedOrg.products,
        this.props.selectedOrg.contracts
      ),
    };
    this.abortController = new AbortController();
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  //  Update state if the product selection changes
  private onProductSelection = async (productSelectionId: string): Promise<void> => {
    const foundProduct = _.find(this.props.selectedOrg.products, ['id', productSelectionId]);
    if (foundProduct) {
      if (!foundProduct.profilesLoaded) {
        this.setState({ isProfilesLoading: true, selectedProduct: productSelectionId });
        await LoadOrgDataService.loadProfiles(foundProduct.orgId, foundProduct.id, true, this.abortController.signal);
        this.setState({ isProfilesLoading: false });
      } else {
        this.setState({ selectedProduct: productSelectionId });
      }
    }
  };

  // Update the state of the combobox value and execute the call back function when profile is selected
  private onProfileSelection = (profileSelectionId: string): void => {
    const profile = this.props.selectedOrg.getProfileForProduct(this.state.selectedProduct ?? '', profileSelectionId);
    if (
      !_.isNil(profile) &&
      !_.find(this.props.profiles, (p: UUserGroupLicenseGroup): boolean => p.id === profile.id)
    ) {
      this.props.onSelectProfile(profile);
    }
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    // productsContractsData in ascending order by product names
    const availableProductsContractsData = _.orderBy(
      this.state.productsContractsData,
      (productContractData: ProductContractData): string => productContractData.product.name,
      ['asc']
    );
    // retrieve profiles that are not already associated with user group
    const availableProfiles = _.filter(
      this.props.selectedOrg.getProfilesForProduct(this.state.selectedProduct),
      (profile: UProductProfile): boolean =>
        !_.find(this.props.profiles, (currentProfile: UProductProfile): boolean => currentProfile.id === profile.id)
    );
    // Get the 'Tag' elements to be displayed for user group by iterating through the profile list passed as props
    const profileListDisplay = this.props.profiles.map((profile: UUserGroupLicenseGroup): React.ReactNode => {
      return (
        <Provider key={profile.id} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
          <TagList
            disabled={this.props.disabled}
            onClose={(): void => this.props.onDeleteProfile(profile)}
            data-testid="profile-section-taglist"
          >
            <Tag closeable data-testid={`usergroup-selected-profile-list-${profile.name}`}>
              {profile.name}{' '}
            </Tag>
          </TagList>
        </Provider>
      );
    });
    return (
      <div>
        <ProfileSelector
          spacing="size-50"
          productSelectorProps={{
            width: 'size-3000',
            label: formatMessage(messages.SelectProduct),
            disabled: this.props.disabled ?? false,
            isLoading: false,
            displayContractNames: this.props.selectedOrg.shouldDisplayContractNames(),
            productsContractsData: availableProductsContractsData,
            onSelect: this.onProductSelection,
            dataTestId: 'usergroup-product-selection-combobox',
          }}
          profileSelectorProps={{
            width: 'size-3000',
            label: formatMessage(messages.SelectProfile),
            disabled: !this.state.selectedProduct,
            isLoading: this.state.isProfilesLoading,
            profiles: availableProfiles,
            onSelect: this.onProfileSelection,
            dataTestId: 'usergroup-profile-selection-combobox',
          }}
        />
        <div>{profileListDisplay}</div>
      </div>
    );
  }
}

export default injectIntl(ProfileSection);
