import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import { ComboBox, defaultTheme, InlineAlert, Item, Key, Provider as ProviderV3 } from '@adobe/react-spectrum';
import Wait from '@react/react-spectrum/Wait';
import Provider from '@react/react-spectrum/Provider';
import { defineMessages, useIntl } from 'react-intl';

import { UUserGroup } from '../../../services/orgMaster/UUserGroup';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { AdminUserGroupInfo } from '../../../services/orgMaster/UAdmin';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import './Common.css';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import Utils from '../../../services/utils/Utils';
import log from 'loglevel';

const messages = defineMessages({
  NoUG: {
    id: 'EditCompartment.Admins.UGSel.placeholder.NoUserGroups',
    defaultMessage: 'No user groups',
  },
  SelectUG: {
    id: 'EditCompartment.Admins.UGSel.placeholder.SelectUserGroup',
    defaultMessage: 'Select User Group',
  },
  LoadUserGroupsError: {
    id: 'BanyanCompartmentsApi.GetUserGroups.Error',
    defaultMessage: 'Error loading user groups',
  },
});

/**
 * Displays a list of tags representing user groups associated with a user group admin.
 */
const UserGroupTagList = ({
  disabled,
  onUserGroupRemove,
  userGroupInfoList,
}: {
  disabled: boolean;
  onUserGroupRemove: (userGroupName: string) => void;
  userGroupInfoList: AdminUserGroupInfo[];
}) => {
  const validGroups = useMemo(() => {
    return userGroupInfoList.filter((info) => !isEmpty(info.id) && !isEmpty(info.name));
  }, [userGroupInfoList]);

  return (
    <Provider locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
      <TagList
        data-testid="usergroup-admin-taglist"
        className="AdminRightTagList"
        disabled={disabled}
        onClose={(tag) => onUserGroupRemove(tag as string)}
      >
        {validGroups.map((info: AdminUserGroupInfo) => (
          <Tag key={info.id}>{info.name}</Tag>
        ))}
      </TagList>
    </Provider>
  );
};

interface UserGroupSectionProps {
  compartment: UOrgMaster;
  // Executed when user group is added or removed for user group admin.
  // (passes list of info for user groups currently associated with user group admin and indicates the user group and whether it was added or removed).
  updateUserGroupInfoCallback: (
    userGroupInfoList: AdminUserGroupInfo[],
    userGroupId: string,
    userGroupName: string | undefined,
    addRole: boolean
  ) => void;
  disabled: boolean; // disables component including the button that enables and disables editing
  // List of user groups (indicated by info) associated with user group admin.
  userGroupInfoList: AdminUserGroupInfo[];
}

/**
 * A picker that allows the user to select user groups into a list that is assigned to the user group admin.
 */
const UserGroupSection = (props: UserGroupSectionProps) => {
  const { formatMessage } = useIntl();
  const [comboBoxValue, setComboboxValue] = useState<string | null>(null);
  const [comboBoxInput, setComboboxInput] = useState<string | undefined>();
  const [userGroupInfoList, setUserGroupInfoList] = useState<AdminUserGroupInfo[]>(props.userGroupInfoList ?? []);
  const [enabledForEdit, setEnabledForEdit] = useState<boolean>(props.compartment.userGroupsLoaded);
  const [userGroupsLoading, setUserGroupLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    async function loadUserGroups() {
      try {
        setUserGroupLoading(true);
        await LoadOrgDataService.loadUserGroups(props.compartment.organization.id, undefined, abortController.signal);
        if (!abortController.signal.aborted) {
          setUserGroupLoading(false);
          setEnabledForEdit(true);
        }
      } catch (error) {
        if (Utils.isAbortError(error)) {
          return;
        }
        if (!abortController.signal.aborted) {
          log.error('Error loading user groups', error);
          setLoadingError(formatMessage(messages.LoadUserGroupsError));
          setUserGroupLoading(false);
        }
      }
    }
    if (!props.compartment.userGroupsLoaded) {
      loadUserGroups();
    }
    return () => {
      abortController.abort();
    };
  }, []);

  const update = (
    updatedUserGroupInfoList: AdminUserGroupInfo[],
    userGroupId: string | undefined,
    userGroupName: string | undefined,
    addRole: boolean
  ): void => {
    if (userGroupId) {
      props.updateUserGroupInfoCallback(updatedUserGroupInfoList, userGroupId, userGroupName, addRole);
    }
    setComboboxValue(null);
    setComboboxInput('');
    setUserGroupInfoList(updatedUserGroupInfoList);
  };

  const onUserGroupSelection = (selectedGroupId: Key | null) => {
    if (selectedGroupId) {
      const selectedGroup = props.compartment.userGroups.find((group) => group.id === selectedGroupId);
      const existing = userGroupInfoList.find((group): boolean => group.id === selectedGroupId);

      if (selectedGroup && !existing) {
        const newSelections = [...userGroupInfoList, { id: selectedGroup.id, name: selectedGroup.name }];
        update(newSelections, selectedGroup.id, selectedGroup.name, true);
      }
    }
  };

  // remove the user group from user group list when user clicks 'X' on the tag
  const removeUserGroupByName = (userGroupName: string): void => {
    const selections = userGroupInfoList ?? [];
    const index = selections.findIndex((info) => info.name === userGroupName);
    if (index >= 0) {
      const group = selections[index];
      const updatedSelections = [...selections];
      updatedSelections.splice(index, 1);
      update(updatedSelections, group.id, group.name, false);
    }
  };

  const selectedIds = userGroupInfoList.map((info) => info.id);
  const available = props.compartment.userGroups.filter((userGroup: UUserGroup) => !selectedIds.includes(userGroup.id));
  const regex = new RegExp(comboBoxInput ?? '.', 'i');
  const filtered = available.filter((userGroup) => userGroup.name.match(regex));
  const items = filtered.map((userGroup: UUserGroup) => ({
    id: userGroup.id,
    name: userGroup.name,
  }));

  const disabledKeys = available.filter((userGroup: UUserGroup) => userGroup.isTarget).map((userGroup) => userGroup.id);

  return (
    <ProviderV3 theme={defaultTheme} scale={'medium'} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
      <div>
        <span data-testid="admin-usergroup-selection-combobox">
          <ComboBox
            onSelectionChange={onUserGroupSelection}
            onInputChange={(value) => setComboboxInput(value)}
            items={items}
            selectedKey={comboBoxValue}
            inputValue={comboBoxInput}
            isDisabled={
              props.disabled || !enabledForEdit || isEmpty(available) || available.length === disabledKeys.length
            }
            disabledKeys={disabledKeys}
            placeholder={isEmpty(available) ? formatMessage(messages.NoUG) : formatMessage(messages.SelectUG)}
            aria-label="select user group"
          >
            {(item) => <Item>{item.name}</Item>}
          </ComboBox>
        </span>
        {userGroupsLoading && (
          <span data-testid="admin-usergroup-selection-wait">
            <Wait size="S" />
          </span>
        )}
        {loadingError && (
          <InlineAlert variant="negative" data-testid="error-alert">
            {loadingError}
          </InlineAlert>
        )}
        <div>
          <UserGroupTagList
            disabled={props.disabled || !enabledForEdit}
            onUserGroupRemove={removeUserGroupByName}
            userGroupInfoList={userGroupInfoList}
          />
        </div>
      </div>
    </ProviderV3>
  );
};

export default UserGroupSection;
