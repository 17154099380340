enum SearchType {
  ExactName = 'Exact name',
  PartialName = 'Partial name',
  OrgId = 'Org ID',
  ClaimedDomain = 'Claimed domain',
  AdminEmail = 'Admin email',
  ECCEndUserID = 'ECC end user ID',
  AdminID = 'Admin ID',
}

export default SearchType;
