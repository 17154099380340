import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';

import {
  ImportAdminsDM,
  ImportOrganizationsDM,
  ImportProductProfilesDM,
  ImportProductsDM,
  ImportUserGroupsDM,
} from '../DataModelTypes/DataModelTypes';
import ImportOperationsUtils from '../../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import ImportUtils, { ChangeCount } from '../ImportUtils';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import ImportOrganizations from './ImportOrganizations';
import { UProduct } from '../../../../services/orgMaster/UProduct';
import { UProductProfile } from '../../../../services/orgMaster/UProductProfile';
import { UUserGroup } from '../../../../services/orgMaster/UUserGroup';
import { UAdmin } from '../../../../services/orgMaster/UAdmin';
import { OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import {
  OrgAdminType,
  OrgLevelAdminType,
  AdminTypeName,
  UserType,
  UserTypeName,
} from '../../../../services/authentication/IMS';
import ImportUserGroups from './ImportUserGroups';
import ImportProductProfiles from './ImportProductProfiles';
import ImportProductsAndResources from './ImportProductsAndResources';
import TempIdGenerator from '../../../../services/utils/TempIdGenerator';
import EmailValidation from '../../../EditCompartment/AdminTable/services/EmailValidation';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import Utils from '../../../../services/utils/Utils';
import { Country } from '../../../../providers/ImsProvider';
import CountryList from '../../../../services/countries/CountryList';
import { CommandService } from '../../../../services/Commands/CommandService';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import ContractJIL from '../../../../services/orgMaster/ContractsJIL';

const messages = defineMessages({
  AdminLabel: {
    id: 'Organizations.Import.Admins.AdminLabel',
    defaultMessage: 'Admins',
  },
  EmailInvalidError: {
    id: 'Organizations.Import.Admins.EmailInvalidError',
    defaultMessage: "email cannot be blank or null for record ''{index}''",
  },
  OrgIdInvalidError: {
    id: 'Organizations.Import.Admins.OrgIdInvalidError',
    defaultMessage: "orgId cannot be blank or null for record ''{index}''",
  },
  AdminTypeDataInvalidError: {
    id: 'Organizations.Import.Admins.AdminTypeDataInvalidError',
    defaultMessage: "adminType cannot be blank or null for record ''{index}''",
  },
  UserTypeDataInvalidError: {
    id: 'Organizations.Import.Admins.UserTypeDataInvalidError',
    defaultMessage: "userType cannot be blank or null for record ''{index}''",
  },
  EmailInvalidFormatError: {
    id: 'Organizations.Import.Admins.EmailInvalidFormatError',
    defaultMessage: "''{emailId}'' is not in 'a@b.c' form.",
  },
  EmailMaxLengthError: {
    id: 'Organizations.Import.Admins.EmailMaxLengthError',
    defaultMessage: "''{emailId}'' exceeds maximum length.",
  },
  EmailSameUserTypeError: {
    id: 'Organizations.Import.Admins.EmailSameUserTypeError',
    defaultMessage: "''{emailId}'' email must have the same userType across all its entries",
  },
  EmailExistInOrgError: {
    id: 'Organizations.Import.Admins.EmailExistInOrgError',
    defaultMessage: "''{orgId}'' org already has an admin with email ''{emailId}''",
  },
  AdminTypeInvalidError: {
    id: 'Organizations.Import.Admins.AdminTypeInvalidError',
    defaultMessage: "''{adminType}'' adminType is not valid for email ''{emailId}''",
  },
  UserTypeInvalidError: {
    id: 'Organizations.Import.Admins.UserTypeInvalidError',
    defaultMessage: "''{userType}'' userType is not valid for email ''{emailId}''",
  },
  OrgIdInvalidForAdmin: {
    id: 'Organizations.Import.Admins.OrgIdInvalidForAdmin',
    defaultMessage: "''{orgId}'' orgId is neither a new org nor an existing org for admin with email ''{emailId}''",
  },
  GroupIdInvalidForAdmin: {
    id: 'Organizations.Import.Admins.GroupIdInvalidForAdmin',
    defaultMessage: "''{groupId}'' groupId is not valid for admin with email ''{emailId}''",
  },
  LicenseIdInvalidForAdmin: {
    id: 'Organizations.Import.Admins.LicenseIdInvalidForAdmin',
    defaultMessage: "''{licenseId}'' licenseId is not valid for admin with email ''{emailId}''",
  },
  GroupIdOrLicenseIdInvalidForAdmin: {
    id: 'Organizations.Import.Admins.GroupIdOrLicenseIdInvalidForAdmin',
    defaultMessage: "''{groupId}'' groupId or ''{licenseId}'' licenseId is invalid for admin with email ''{emailId}''",
  },
  ContractIdInvalidForAdmin: {
    id: 'Organizations.Import.Admins.ContractIdInvalidForAdmin',
    defaultMessage: "''{contractId}'' contractId is not valid for admin with email ''{emailId}''",
  },
  InvalidFirstAndLastName: {
    id: 'Organizations.Import.Admins.InvalidFirstAndLastName',
    defaultMessage:
      'FirstName and lastName must be same for an admin with email {emailId}, org {orgId}, and userType {userType}',
  },
  InvalidCountryCode: {
    id: 'Organizations.Import.Admins.InvalidCountryCode',
    defaultMessage: '{countryCode} countryCode is not valid for admin with email {emailId} and userType {userType}',
  },
  EmailAdminDoesNotExist: {
    id: 'Organizations.Import.Admins.EmailAdminDoesNotExist',
    defaultMessage: "Admin ''{email}'' to be deleted does not exist",
  },
  AdminRoleDoesNotExist: {
    id: 'Organizations.Import.Admins.AdminRoleDoesNotExist',
    defaultMessage: "Admin ''{email}'' does not have ''{adminType}'' role marked for deletion",
  },
  AdminOperationNotValidOnReadOnlyOrg: {
    id: 'Organizations.Import.Admins.AdminOperationNotValidOnReadOnlyOrg',
    defaultMessage: "Admins cannot be added or updated in the following orgs because of the org's type:",
  },
  InvalidOrgIdForAdmin: {
    id: 'Organizations.Import.Admins.InvalidOrgIdForAdmin',
    defaultMessage: "Org ''{orgId}'' is invalid for admin ''{email}''",
  },
  InvalidOrgIdMarkedForDelete: {
    id: 'Organizations.Import.Admins.InvalidOrgIdMarkedForDelete',
    defaultMessage: 'The following admins cannot be added to orgs because those orgs are marked for deletion:',
  },
  UnableToLoadCountryCodes: {
    id: 'Organizations.Import.Admins.UnableToLoadCountryCodes',
    defaultMessage: 'Unable to load country codes. Please try again later.',
  },
});

class ImportAdmins {
  /**
   * email, orgId, adminType and userType are required fields for all admins
   */
  static verifyNullChecks(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allAdmins, (admin: ImportAdminsDM, index: number): void => {
      if (ImportOperationsUtils.isOperationValid(admin.operation)) {
        if (ImportUtils.isNullOrEmpty(admin.email)) {
          errorMessages.push(formatMessage(messages.EmailInvalidError, { index: index + 2 }));
        }
        if (ImportUtils.isNullOrEmpty(admin.orgId)) {
          errorMessages.push(formatMessage(messages.OrgIdInvalidError, { index: index + 2 }));
        }
        // adminType can be null when the entire admin role is to be deleted
        if (ImportUtils.isNullOrEmpty(admin.adminType) && !ImportOperationsUtils.isOperationDelete(admin.operation)) {
          errorMessages.push(formatMessage(messages.AdminTypeDataInvalidError, { index: index + 2 }));
        }
        if (ImportUtils.isNullOrEmpty(admin.userType)) {
          errorMessages.push(formatMessage(messages.UserTypeDataInvalidError, { index: index + 2 }));
        }
      }
    });
    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Validate that the email format and the email length is valid
   */
  static validateEmailFormat(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const emailsValidated = new Set<string>();
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allAdmins, (admin: ImportAdminsDM): void => {
      if (!emailsValidated.has(admin.email)) {
        if (!EmailValidation.isEmailFormatValid(admin.email)) {
          errorMessages.push(formatMessage(messages.EmailInvalidFormatError, { emailId: admin.email }));
        } else if (!EmailValidation.isEmailLengthValid(admin.email)) {
          errorMessages.push(formatMessage(messages.EmailMaxLengthError, { emailId: admin.email }));
        }
      }
      emailsValidated.add(admin.email);
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * adminType must be same for all entries for an email
   * NOTE: banyan svc throws an error if an admin with different userType is inserted
   * for the same email. There must is a change here if the above logic changes.
   */
  static validateEmailIdHasSameUserType(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const adminsToBeCreated = ImportUtils.filterToBeCreatedItems(allAdmins) as ImportAdminsDM[];
    const emailsValidated = new Set<string>();
    const errorMessages: string[] = [];
    const { formatMessage } = intl;

    _.forEach(adminsToBeCreated, (admin: ImportAdminsDM): void => {
      if (!emailsValidated.has(admin.email)) {
        const allRecordsWithSameEmailId = _.filter(adminsToBeCreated, (adm: ImportAdminsDM): boolean =>
          _.isEqual(adm.email, admin.email)
        );
        if (
          !allRecordsWithSameEmailId.every((adm: ImportAdminsDM): boolean => _.isEqual(adm.userType, admin.userType))
        ) {
          errorMessages.push(formatMessage(messages.EmailSameUserTypeError, { emailId: admin.email }));
        }
        emailsValidated.add(admin.email);
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Check if a new user with existing email but different userType is being created
   *
   * For e.g. a user with email: a@b.c and userType: 'Federated ID' already exists in the org.
   * Creating a user with email: a@b.c and userType: 'Adobe Agent'  is invalid.
   *
   * NOTE: When a client creates an admin, a user is created who is given the admin permissions.
   *
   * @param orgId of the org
   */
  static isDifferentUserWithSameEmail(orgId: string, email: string, userType: string): boolean {
    const org = ImportOrganizations.getOrgFromHierarchy(orgId);
    if (org !== undefined) {
      const adminWithSameEmail = _.find(org.getAdmins(), (adm: UAdmin): boolean => _.isEqual(adm.email, email));

      if (adminWithSameEmail !== undefined && !_.isEqual(adminWithSameEmail.userType, this.getUserType(userType))) {
        return true;
      }
    }
    return false;
  }

  /**
   * Validate that an admin with same email does not exist on the same org
   */
  static validateDuplicateEmailIdsOnAnOrg(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const adminsToBeCreated = ImportUtils.filterToBeCreatedItems(allAdmins) as ImportAdminsDM[];
    const emailsValidated = new Set<string>();
    const errorMessages: string[] = [];
    const { formatMessage } = intl;

    _.forEach(adminsToBeCreated, (admin: ImportAdminsDM): void => {
      if (
        !emailsValidated.has(admin.email) &&
        this.isDifferentUserWithSameEmail(admin.orgId, admin.email, admin.userType)
      ) {
        errorMessages.push(formatMessage(messages.EmailExistInOrgError, { orgId: admin.orgId, emailId: admin.email }));
      }
      emailsValidated.add(admin.email);
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Validate that the org to which the admin is to be added is not marked for delete
   * @param allAdmins all admins from input file
   * @param allOrgs all orgs from input file
   */
  private static validateOrgNotMarkedForDeletion(allAdmins: ImportAdminsDM[], allOrgs: ImportOrganizationsDM[]): void {
    const adminsToBeCreated = ImportUtils.filterToBeCreatedItems(allAdmins) as ImportAdminsDM[];
    const invalidOrgDelete = new Set<string>();
    _.forEach(adminsToBeCreated, (admin: ImportAdminsDM): void => {
      if (ImportOrganizations.isOrgMarkedForDelete(allOrgs, admin.orgId)) {
        invalidOrgDelete.add(
          Utils.getLocalizedMessage(messages.InvalidOrgIdForAdmin, { orgId: admin.orgId, email: admin.email })
        );
      }
    });
    ImportUtils.displayIfErrorWithHeader(
      Utils.getLocalizedMessage(messages.AdminLabel),
      Utils.getLocalizedMessage(messages.InvalidOrgIdMarkedForDelete),
      Array.from(invalidOrgDelete)
    );
  }

  /**
   * Validate that the adminType and userType have valid values
   * adminType values should be one of values in AdminTypeName and
   * userType values shoule be one of values in UserTypeName
   *
   * NOTE: adminType and userType is validated for admins for following cases:
   * 1) admin with create operation
   * 2) admin with delete operation and adminType is not null | blank
   *    NOTE IMPORTANT: In case when the user wants to delete entire admin role, the adminType will not be set and the
   *    operation will be 'DELETE'
   *
   * @param allAdmins all admins from the input file
   */
  static validateUserTypeAndAdminType(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const adminToBeCreatedOrDeleted = _.filter(allAdmins, (admin: ImportAdminsDM): boolean => {
      return (
        ImportOperationsUtils.isOperationCreate(admin.operation) ||
        (ImportOperationsUtils.isOperationDelete(admin.operation) && !ImportUtils.isNullOrEmpty(admin.adminType))
      );
    });
    const errorMessages: string[] = [];
    const { formatMessage } = intl;

    const adminTypeNames = new Set<string>(Object.values(AdminTypeName).map((val) => val.toUpperCase()));
    const userTypeNames = new Set<string>(Object.values(UserTypeName).map((val) => val.toUpperCase()));

    _.forEach(adminToBeCreatedOrDeleted, (admin: ImportAdminsDM): void => {
      if (!adminTypeNames.has(admin.adminType.toUpperCase())) {
        errorMessages.push(
          formatMessage(messages.AdminTypeInvalidError, { adminType: admin.adminType, emailId: admin.email })
        );
      }
      if (!userTypeNames.has(admin.userType.toUpperCase())) {
        errorMessages.push(
          formatMessage(messages.UserTypeInvalidError, { userType: admin.userType, emailId: admin.email })
        );
      }
    });
    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Validate groupId & licenseId depending on the type of admin to be created
   * For USER GROUP ADMIN, validate that the userGroupId (groupId) is valid
   * For PRODUCT PROFILE ADMIN, validate that the productProfileId (groupId) and licenseId (productId) is valid
   * For PRODUCT ADMIN, validate the licenseId (productId)
   *
   * @param allAdmins all admins from the input file
   * @param allOrgs  all orgs from the input file
   * @param allProdProfiles all products from the input file
   * @param allUserGroups all user groups from the input file
   */
  static validateLicenseIdAndGroupID(
    allAdmins: ImportAdminsDM[],
    allProducts: ImportProductsDM[],
    allProdProfiles: ImportProductProfilesDM[],
    allUserGroups: ImportUserGroupsDM[],
    intl: IntlShape
  ): void {
    const errorMessages: string[] = [];
    // filter admins which have valid operation field set
    const filteredAdmins = _.filter(
      allAdmins,
      (admin: ImportAdminsDM): boolean =>
        ImportOperationsUtils.isOperationValid(admin.operation) && !ImportUtils.isNullOrEmpty(admin.adminType)
    );

    const { formatMessage } = intl;

    _.forEach(filteredAdmins, (admin: ImportAdminsDM): void => {
      // if the admin is a user group admin, check if userGroupId (groupId) is valid
      if (admin.adminType.toUpperCase().includes(AdminTypeName.USER_GROUP_ADMIN.toUpperCase())) {
        if (
          !(admin.groupId !== undefined && ImportUserGroups.isUserGroupValid(allUserGroups, admin.groupId, admin.orgId))
        ) {
          errorMessages.push(
            formatMessage(messages.GroupIdInvalidForAdmin, { groupId: admin.groupId, emailId: admin.email })
          );
        }
      } else if (admin.adminType.toUpperCase().includes(AdminTypeName.PRODUCT_PROFILE_ADMIN.toUpperCase())) {
        if (
          !(
            admin.groupId !== undefined &&
            admin.licenseId !== undefined &&
            ImportProductProfiles.isProductProfileValid(allProdProfiles, admin.groupId, admin.licenseId, admin.orgId)
          )
        ) {
          errorMessages.push(
            formatMessage(messages.GroupIdOrLicenseIdInvalidForAdmin, {
              groupId: admin.groupId,
              licenseId: admin.licenseId,
              emailId: admin.email,
            })
          );
        }
      } else if (admin.adminType.toUpperCase().includes(AdminTypeName.PRODUCT_ADMIN.toUpperCase())) {
        if (
          !(
            admin.licenseId !== undefined &&
            ImportProductsAndResources.isProductValid(allProducts, admin.licenseId, admin.orgId)
          )
        ) {
          errorMessages.push(
            formatMessage(messages.LicenseIdInvalidForAdmin, { licenseId: admin.licenseId, emailId: admin.email })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Validate contractId for CONTRACT admin to be created, updated or deleted
   *
   * @param allAdmins all admins from the input file
   */
  static validateContractID(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    // filter admins which have valid operation field set
    const filteredAdmins = _.filter(
      allAdmins,
      (admin: ImportAdminsDM): boolean =>
        ImportOperationsUtils.isOperationValid(admin.operation) && !ImportUtils.isNullOrEmpty(admin.adminType)
    );

    const { formatMessage } = intl;

    _.forEach(filteredAdmins, (admin: ImportAdminsDM): void => {
      // if the admin type of contract admin, check if contractId is valid
      if (admin.adminType.toUpperCase().includes(AdminTypeName.CONTRACT_ADMIN.toUpperCase())) {
        const org = HierarchyManager.getOrg(admin.orgId);
        if (org) {
          // if none of the contracts matches admin.contractId, show invalid contract ID error
          if (!_.some(org.contracts, (contract: ContractJIL): boolean => _.isEqual(contract.id, admin.contractId))) {
            errorMessages.push(
              formatMessage(messages.ContractIdInvalidForAdmin, { contractId: admin.contractId, emailId: admin.email })
            );
          }
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * First Name and last name must be same for an admin across all its entries.
   * All entries are for same admin when email, userType and orgId are same
   * @param allAdmins all admins from input file
   */
  static validateFirstNameLastName(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const compositeKeysValidated = new Set<string>();
    const errorMessages: string[] = [];

    const { formatMessage } = intl;
    const adminsToBeCreated = ImportUtils.filterToBeCreatedItems(allAdmins) as ImportAdminsDM[];

    _.forEach(adminsToBeCreated, (admin: ImportAdminsDM): void => {
      const compositeKey = `${admin.orgId}/${admin.userType}/${admin.email}`;
      if (!compositeKeysValidated.has(compositeKey)) {
        compositeKeysValidated.add(compositeKey);

        // filter all admins having orgId, email and userType same
        const adminEntriesForAnOrg = _.filter(adminsToBeCreated, (adm: ImportAdminsDM): boolean => {
          return (
            _.isEqual(adm.email, admin.email) &&
            _.isEqual(adm.orgId, admin.orgId) &&
            _.isEqual(adm.userType, admin.userType)
          );
        });

        // validate that the firstName and lastName is same for all the entries
        if (
          !adminEntriesForAnOrg.every((adm: ImportAdminsDM): boolean => _.isEqual(adm.firstName, admin.firstName)) &&
          !adminEntriesForAnOrg.every((adm: ImportAdminsDM): boolean => _.isEqual(adm.lastName, admin.lastName))
        ) {
          errorMessages.push(
            formatMessage(messages.InvalidFirstAndLastName, {
              emailId: admin.email,
              orgId: admin.orgId,
              userType: admin.userType,
            })
          );
        }
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Validate the country code selected for all the admins to be created
   * @param allAdmins all admins from input file
   */
  static async validateCountryCode(allAdmins: ImportAdminsDM[], intl: IntlShape): Promise<void> {
    const { formatMessage } = intl;
    const errorMessages: string[] = [];
    try {
      const countryList: Country[] = await CountryList.getCountryList();
      const adminsToBeCreated = ImportUtils.filterToBeCreatedItems(allAdmins) as ImportAdminsDM[];
      _.forEach(adminsToBeCreated, (admin: ImportAdminsDM): void => {
        if (!ImportUtils.isCountryCodeOrCountryNameValid(admin.countryCode, countryList)) {
          errorMessages.push(
            formatMessage(messages.InvalidCountryCode, {
              countryCode: admin.countryCode ? admin.countryCode : '',
              emailId: admin.email,
              userType: admin.userType,
            })
          );
        }
      });
    } catch (error) {
      // case when we failed to load country code details from IMS
      throw Error(formatMessage(messages.UnableToLoadCountryCodes));
    }
    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), errorMessages);
  }

  /**
   * Validate that admin to be deleted (admin right or entire admin deletion) exists in the org
   * @param allAdmins all admins from input file
   */
  static validateAdminForDeleteOperation(allAdmins: ImportAdminsDM[], intl: IntlShape): void {
    const adminsToBeDeleted = _.filter(allAdmins, (admin: ImportAdminsDM): boolean => {
      return ImportOperationsUtils.isOperationDelete(admin.operation) && !ImportUtils.isNullOrEmpty(admin.adminType);
    });
    const errorMessages = new Set<string>();
    const { formatMessage } = intl;

    _.forEach(adminsToBeDeleted, (admin: ImportAdminsDM): void => {
      const orgInHierarchy: UOrgMaster | undefined = ImportOrganizations.getOrgFromHierarchy(admin.orgId);
      if (!orgInHierarchy) {
        // this check will be performed before reaching here
        return;
      }
      const uAdminObj = this.createUAdminDataObject(admin);
      if (!orgInHierarchy.doesAdminExistV2(uAdminObj)) {
        errorMessages.add(formatMessage(messages.EmailAdminDoesNotExist, { email: admin.email }));
      }
      const orgAdminType: OrgAdminType | undefined = ImportAdmins.getOrgAdminType(admin.adminType);
      if (!orgAdminType || !orgInHierarchy.doesAdminRoleExistV2(uAdminObj, orgAdminType)) {
        errorMessages.add(
          formatMessage(messages.AdminRoleDoesNotExist, { email: admin.email, adminType: admin.adminType })
        );
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), Array.from(errorMessages));
  }

  /**
   * Validate if the org to which the admin is to be added or to be removed from  is valid or not
   * @param allAdmins all admins from input file
   * @param allOrgs all orgs from input file
   */
  static validateAdminsOrg(allAdmins: ImportAdminsDM[], allOrgs: ImportOrganizationsDM[], intl: IntlShape): void {
    const adminsToBeCreatedOrDeleted = _.filter(allAdmins, (admin: ImportAdminsDM): boolean => {
      return (
        ImportOperationsUtils.isOperationCreate(admin.operation) ||
        ImportOperationsUtils.isOperationDelete(admin.operation)
      );
    });

    const errorMessages = new Set<string>();
    const { formatMessage } = intl;
    _.forEach(adminsToBeCreatedOrDeleted, (admin: ImportAdminsDM): void => {
      if (!ImportOrganizations.isOrgValid(allOrgs, admin.orgId)) {
        errorMessages.add(formatMessage(messages.OrgIdInvalidForAdmin, { orgId: admin.orgId, emailId: admin.email }));
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.AdminLabel), Array.from(errorMessages));
  }

  /**
   * Validate that no operations are performed on a read only org
   * @param allAdmins all admins from input file
   */
  static async validateReadOnlyOrgs(allAdmins: ImportAdminsDM[], intl: IntlShape): Promise<void> {
    const orgIds = new Set<string>();
    _.forEach(allAdmins, (admin: ImportAdminsDM): void => {
      if (ImportOperationsUtils.isOperationValid(admin.operation)) {
        orgIds.add(admin.orgId);
      }
    });

    const { formatMessage } = intl;
    await ImportUtils.throwIfExistingOrgIsReadOnly(
      formatMessage(messages.AdminLabel),
      formatMessage(messages.AdminOperationNotValidOnReadOnlyOrg),
      Array.from(orgIds)
    );
  }

  /**
   * For some orgs down the hierarchy, admins might not have been loaded.
   * @returns fn[] where each fn returns a promise to load admin for that org
   */
  public static loadAdmins(allAdmins: ImportAdminsDM[]): { (): Promise<void> }[] {
    // find all unique orgIds
    const allUniqueOrgIds = allAdmins
      .filter((admin: ImportAdminsDM): boolean => ImportOperationsUtils.isOperationValid(admin.operation))
      .map((admin: ImportAdminsDM): string => admin.orgId)
      .filter((orgId: string, index: number, self: string[]): boolean => {
        return self.indexOf(orgId) === index;
      });

    const loadOrgAdmins: { (): Promise<void> }[] = [];
    _.forEach(allUniqueOrgIds, (orgId: string): void => {
      const org = ImportOrganizations.getOrgFromHierarchy(orgId);
      if (org !== undefined) {
        loadOrgAdmins.push(async () => {
          // contracts, products, profiles, and user groups are not automatically loaded for loadAdminsV2 so they need to be loaded for import
          await LoadOrgDataService.loadContracts(orgId);
          await LoadOrgDataService.loadProducts(orgId);
          await LoadOrgDataService.loadProfilesForAllProducts(orgId);
          await LoadOrgDataService.loadUserGroups(orgId, true);
          await LoadOrgDataService.loadAdminsV2(orgId, true);
        });
      }
    });

    return loadOrgAdmins;
  }

  static async validate(
    allAdmins: ImportAdminsDM[],
    allOrgs: ImportOrganizationsDM[],
    allProducts: ImportProductsDM[],
    allProdProfiles: ImportProductProfilesDM[],
    allUserGroups: ImportUserGroupsDM[],
    intl: IntlShape
  ): Promise<void> {
    ImportAdmins.verifyNullChecks(allAdmins, intl);
    ImportAdmins.validateUserTypeAndAdminType(allAdmins, intl);
    ImportAdmins.validateFirstNameLastName(allAdmins, intl);
    await ImportAdmins.validateCountryCode(allAdmins, intl);

    ImportAdmins.validateEmailFormat(allAdmins, intl);
    ImportAdmins.validateEmailIdHasSameUserType(allAdmins, intl);
    ImportAdmins.validateDuplicateEmailIdsOnAnOrg(allAdmins, intl);

    ImportAdmins.validateOrgNotMarkedForDeletion(allAdmins, allOrgs);
    ImportAdmins.validateAdminsOrg(allAdmins, allOrgs, intl);
    ImportAdmins.validateLicenseIdAndGroupID(allAdmins, allProducts, allProdProfiles, allUserGroups, intl);
    ImportAdmins.validateContractID(allAdmins, intl);
    ImportAdmins.validateAdminForDeleteOperation(allAdmins, intl);
    await ImportAdmins.validateReadOnlyOrgs(allAdmins, intl);
  }

  /**
   * Get UserType based on the 'userType' passed in.
   * @returns respective if it matches type 2 or type 3 or type_twoe else type 1
   */
  static getUserType(userType: string): UserType {
    if (UserTypeName.BUSINESS_ID.toUpperCase() === userType.toUpperCase()) {
      return UserType.TYPE_TWOE;
    }
    if (UserTypeName.FEDERATED_ID.toUpperCase() === userType.toUpperCase()) {
      return UserType.TYPE_THREE;
    }
    if (UserTypeName.ENTERPRISE_ID.toUpperCase() === userType.toUpperCase()) {
      return UserType.TYPE_TWO;
    }
    return UserType.TYPE_ONE;
  }

  /**
   * Get OrgAdminType based of adminType
   * @param adminType
   */
  static getOrgAdminType(adminType: string): OrgAdminType | undefined {
    if (ImportUtils.isNullOrEmpty(adminType)) {
      return;
    }
    if (AdminTypeName.SYSTEM_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.ORG_ADMIN;
    }
    if (AdminTypeName.GLOBAL_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.COMPARTMENT_ADMIN;
    }
    if (AdminTypeName.GLOBAL_VIEWER.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.COMPARTMENT_VIEWER;
    }
    if (AdminTypeName.CONTRACT_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.CONTRACT_ADMIN;
    }
    if (AdminTypeName.PRODUCT_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.PRODUCT_ADMIN;
    }
    if (AdminTypeName.PRODUCT_PROFILE_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.LICENSE_ADMIN;
    }
    if (AdminTypeName.USER_GROUP_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.USER_GROUP_ADMIN;
    }
    if (AdminTypeName.STORAGE_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.STORAGE_ADMIN;
    }
    if (AdminTypeName.SUPPORT_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.SUPPORT_ADMIN;
    }
    if (AdminTypeName.DEPLOYMENT_ADMIN.toUpperCase() === adminType.toUpperCase()) {
      return OrgAdminType.DEPLOYMENT_ADMIN;
    }
  }

  /**
   * Create UAdmin from ImportAdminsDM.
   * This only creates a UAdmin with user data without any admin roles.
   *
   * @param adminDM ImportAdminsDM to convert to UAdmin
   * @returns converted UAdmin
   */
  private static createUAdminDataObject(adminDM: ImportAdminsDM): UAdmin {
    return new UAdmin({
      id: TempIdGenerator.getTempIdAndIncrement(),
      email: adminDM.email,
      userType: ImportAdmins.getUserType(adminDM.userType),
      orgId: ImportOrganizations.getOrgIdInHierarchy(adminDM.orgId),
      firstName: adminDM.firstName,
      lastName: adminDM.lastName,
      countryCode: adminDM.countryCode,
      username: '',
      domain: '',
    });
  }

  /**
   * Update UAdmin based on ImportAdminsDM
   *
   * @param admin UAdmin that will be updated (this is not modified, a modified version of this object is returned)
   * @param adminDM ImportAdminsDM with information to use to update the UAdmin
   * @param org Org associated with the admin.  This is only needed to provide profile and user group names for the admin.
   * @param addRole whether or not the admin role from ImportAdminsDM is added or removed
   * @returns updated UAdmin (based off of the admin argument)
   */
  private static handleAdminUpdate(admin: UAdmin, adminDM: ImportAdminsDM, org: UOrgMaster, addRole: boolean): UAdmin {
    const editedAdmin: UAdmin = _.cloneDeep(admin);
    if (adminDM.adminType.toUpperCase().includes(AdminTypeName.CONTRACT_ADMIN.toUpperCase())) {
      if (!_.isNil(adminDM.contractId)) {
        editedAdmin.updateContractAdminFor(adminDM.contractId, addRole);
      }
    } else if (adminDM.adminType.toUpperCase().includes(AdminTypeName.PRODUCT_ADMIN.toUpperCase())) {
      if (!_.isNil(adminDM.licenseId)) {
        editedAdmin.updateProductAdminFor(
          ImportProductsAndResources.getProductIdInHierarchy(adminDM.licenseId),
          addRole
        );
      }
    } else if (adminDM.adminType.toUpperCase().includes(AdminTypeName.PRODUCT_PROFILE_ADMIN.toUpperCase())) {
      if (!_.isNil(adminDM.licenseId) && !_.isNil(adminDM.groupId)) {
        // Retrieve profile name to assign to profile admin (profile admins have profile names to display to UI without needing to load profiles)
        const productIdInHierarchy = ImportProductsAndResources.getProductIdInHierarchy(adminDM.licenseId);
        const product: UProduct | undefined = _.find(
          org.products,
          (p: UProduct): boolean => p.id === productIdInHierarchy
        );
        const profileIdInHierarchy = ImportProductProfiles.getProductProfileIdInHierarchy(adminDM.groupId);
        const profile: UProductProfile | undefined =
          product && _.find(product.productProfiles, (p: UProductProfile): boolean => p.id === profileIdInHierarchy);
        editedAdmin.updateProfileAdminFor(profileIdInHierarchy, profile?.name, org, addRole);
      }
    } else if (adminDM.adminType.toUpperCase().includes(AdminTypeName.USER_GROUP_ADMIN.toUpperCase())) {
      if (!_.isNil(adminDM.groupId)) {
        // Retrieve user group name to assign to user group admin (user group admins have user group names to display to UI without needing to load user groups)
        const userGroupIdInHierarchy = ImportUserGroups.getUserGroupIdInHierarchy(adminDM.groupId);
        const userGroup: UUserGroup | undefined = _.find(
          org.userGroups,
          (u: UUserGroup): boolean => u.id === userGroupIdInHierarchy
        );
        editedAdmin.updateUserGroupAdminFor(userGroupIdInHierarchy, userGroup?.name, addRole);
      }
    } else {
      const orgAdminType: OrgAdminType | undefined = ImportAdmins.getOrgAdminType(adminDM.adminType);
      if (orgAdminType) {
        editedAdmin.updateOrgLevelAdminFor(orgAdminType as OrgLevelAdminType, addRole);
      }
    }
    return editedAdmin;
  }

  /**
   * Determines whether the update for a UAdmin is the first edit.
   * (Ex: Counting only 1 update for all admin role changes for a single admin, instead of counting each one)
   *
   * @param editedAdmin updated admin
   * @param originalAdmin admin before update
   * @returns true if this is the first edit for an admin
   */
  private static checkFirstEdit(editedAdmin: UAdmin, originalAdmin: UAdmin): boolean {
    // first edit if updated admin has edits but the previous version doesn't have any edits
    return editedAdmin.hasAnyEdits() && !originalAdmin.hasAnyEdits();
  }

  /**
   * Handle all edits for import admins.
   *
   * @param allAdmins import admin data
   * @returns object with the number of changes for different operations
   */
  static handleAdminEdits(allAdmins: ImportAdminsDM[]): ChangeCount {
    const changeCount: ChangeCount = { updateCount: 0, createCount: 0, deleteCount: 0 };
    _.forEach(allAdmins, (adminDM: ImportAdminsDM): void => {
      // retrieve org for admin
      const org: UOrgMaster | undefined = ImportOrganizations.getOrgFromHierarchy(
        ImportOrganizations.getOrgIdInHierarchy(adminDM.orgId)
      );
      if (org) {
        // retrieve existing admin referenced by import data
        const admin: UAdmin | undefined = _.find(
          org.getAdmins(),
          (a: UAdmin): boolean =>
            a.email === adminDM.email && _.toUpper(a.userType) === _.toUpper(ImportAdmins.getUserType(adminDM.userType))
        );
        if (!admin && ImportOperationsUtils.isOperationCreate(adminDM.operation)) {
          // Admin doesn't exist yet, create admin and add roles
          const newAdmin: UAdmin = ImportAdmins.createUAdminDataObject(adminDM);
          const editedNewAdmin: UAdmin = ImportAdmins.handleAdminUpdate(newAdmin, adminDM, org, true);
          CommandService.addAdminEdit(org, editedNewAdmin, OrgOperation.CREATE);
          changeCount.createCount++;
        } else if (
          admin &&
          _.isEmpty(adminDM.adminType) &&
          ImportOperationsUtils.isOperationDelete(adminDM.operation)
        ) {
          // Admin is set to delete without admin type provided.  This means to remove all admin roles from admin.
          const editedAdmin: UAdmin = _.cloneDeep(admin);
          editedAdmin.removeAllAdminRoles();
          CommandService.addAdminEdit(org, editedAdmin, OrgOperation.DELETE, admin);
          if (ImportAdmins.checkFirstEdit(editedAdmin, admin)) {
            changeCount.deleteCount++;
          }
        } else if (
          admin &&
          !_.isEmpty(adminDM.adminType) &&
          (ImportOperationsUtils.isOperationCreate(adminDM.operation) ||
            ImportOperationsUtils.isOperationDelete(adminDM.operation))
        ) {
          // Admin exists and is being modified, update the admin
          const addRole = ImportOperationsUtils.isOperationCreate(adminDM.operation);
          const editedAdmin = ImportAdmins.handleAdminUpdate(admin, adminDM, org, addRole);
          CommandService.addAdminEdit(org, editedAdmin, OrgOperation.UPDATE, admin);
          if (ImportAdmins.checkFirstEdit(editedAdmin, admin)) {
            changeCount.updateCount++;
          }
        }
      }
    });
    return changeCount;
  }

  static import(allAdmins: ImportAdminsDM[]): ChangeCount {
    return ImportAdmins.handleAdminEdits(allAdmins);
  }
}

export default ImportAdmins;
export { messages };
