import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  IntlProvider,
  WrappedComponentProps,
} from 'react-intl';
import { TD } from '@react/react-spectrum/Table';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Dropdown from '@react/react-spectrum/Dropdown';
import Button from '@react/react-spectrum/Button';
import More from '@react/react-spectrum/Icon/More';
import { Menu, MenuItem, MenuProps } from '@react/react-spectrum/Menu';
import Dialog from '@react/react-spectrum/Dialog';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import * as _ from 'lodash';
import { UProductProfile } from '../../../../../services/orgMaster/UProductProfile';
import { UAdmin } from '../../../../../services/orgMaster/UAdmin';
import { UOrgMaster } from '../../../../../services/orgMaster/UOrgMaster';
import EditProdProfileDialogContent, { ProfileDialogContext } from './EditProdProfileDialogContent';
import AdminPermission from '../../../../../services/authentication/AdminPermission';
import ViewProdProfileDialogContent from './ViewProdProfileDialogContent';
import Analytics from '../../../../../Analytics/Analytics';
import { LocaleSettings } from '../../../../../services/locale/LocaleSettings';
import { CommandService } from '../../../../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../../../../../services/orgMaster/OrgMaster';
import { MAX_DISPLAY_CHARACTER_LENGTH } from '../../../Constants';
import { ProductAttributes } from '../../../../../services/orgMaster/ProductAttributes';
import NumberFormatter from '../../../../../services/utils/NumberFormatter';
import CmdDescriptionUtils from '../../../../../services/Codes/CmdDescriptionUtils';
import { UProduct } from '../../../../../services/orgMaster/UProduct';

/*
 * Profile Row receives these props from the ProductRow.
 * - 'profile' is received from the ProductRow component as we iterate through the compartment.products.productProfiles field
 * - 'update' callback is implemented at the EditCompartment level and passed through child components
 * - 'onClose' optional callback to programmatically hide the dialog
 */
interface ProfileRowProps extends WrappedComponentProps {
  profile: UProductProfile;
  attributes: ProductAttributes;
  update: () => void;
  selectedOrg: UOrgMaster;
}

interface ProfileRowState {
  adminsForProfile: UAdmin[];
}

interface ProfileRowPropsIntl extends ProfileRowProps {
  context: ProfileDialogContext;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function EditProdProfileDialogContentIntl(props: Omit<ProfileRowPropsIntl, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditProdProfileDialogContent {...props} />
    </IntlProvider>
  );
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function MenuIntl(props: Omit<ProfileRowProps & MenuProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <Menu {...props}>{props.children}</Menu>
    </IntlProvider>
  );
}

function ViewProdProfileDialogContentIntl(props: Omit<ProfileRowProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ViewProdProfileDialogContent {...props} />
    </IntlProvider>
  );
}

const messages = defineMessages({
  OK: {
    id: 'EditCompartment.Products.Profile.OK',
    defaultMessage: 'Ok',
  },
  Warning: {
    id: 'EditCompartment.Products.Profile.Warning',
    defaultMessage: 'Warning',
  },
  Cancel: {
    id: 'EditCompartment.Products.Profile.Cancel',
    defaultMessage: 'Cancel',
  },
  AreYouSure: {
    id: 'EditCompartment.Products.Profile.AreYouSure',
    defaultMessage: 'Are you sure you want to delete {ProfileName}?',
  },
});

class ProfileRow extends React.Component<ProfileRowProps, ProfileRowState> {
  constructor(props: ProfileRowProps) {
    super(props);
    this.state = {
      adminsForProfile: this.props.selectedOrg.getAdminsForProfile(this.props.profile.productId, this.props.profile.id),
    };
  }
  /**
   * returns the current product name. If product not found, then returns empty string.
   * NOTE: The chances of not finding the product is very rare as we can never have a profile row
   * without a product in context which the profile is associated with.
   * @private
   */
  private getProductName(): string {
    const currentProduct = _.find(this.props.selectedOrg.products, (product: UProduct) =>
      _.isEqual(product.id, this.props.profile.productId)
    );
    return currentProduct ? currentProduct.name : '';
  }

  /*
   * Iterates through the 'compartment' field to locate and remove the deleted profile when delete action is performed
   * and calls save operation on EditCompartment level
   */
  onDeleteProfile = (): void => {
    CommandService.addEdit(
      this.props.selectedOrg,
      this.props.profile,
      ObjectTypes.PRODUCT_PROFILE,
      OrgOperation.DELETE,
      undefined,
      'DELETE_PRDT_PROFILE',
      [this.props.profile.name, this.getProductName(), CmdDescriptionUtils.getPathname(this.props.profile.orgId)]
    );
    this.props.update();
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const readOnly = AdminPermission.readOnlyMode() || this.props.selectedOrg.isReadOnlyOrg();
    const profileNameButton = (
      <Button
        quiet
        variant="action"
        onClick={(): void => {
          Analytics.fireCTAEvent(`${readOnly ? 'view' : 'edit'} product profile dialog opened from name`);
          ModalContainer.show(
            readOnly ? (
              <ViewProdProfileDialogContentIntl
                {...this.props}
                profile={this.props.profile}
                attributes={this.props.attributes}
                selectedOrg={this.props.selectedOrg}
              />
            ) : (
              <EditProdProfileDialogContentIntl
                {...this.props}
                context={ProfileDialogContext.EDIT}
                update={this.props.update}
                profile={this.props.profile}
                attributes={this.props.attributes}
                selectedOrg={this.props.selectedOrg}
              />
            ),
            this
          );
        }}
        data-testid="profile-table-profile-name"
        className="EditCompartmentTabs__Name"
      >
        {this.props.profile.name}
      </Button>
    );

    return (
      <React.Fragment>
        <TD>
          {this.props.profile.name.length > MAX_DISPLAY_CHARACTER_LENGTH ? (
            /* The tooltip is shown when the name is long and truncated */
            <OverlayTrigger placement="top">
              {profileNameButton}
              <Tooltip className="EditCompartmentTabs__NameTooltip"> {this.props.profile.name}</Tooltip>
            </OverlayTrigger>
          ) : (
            profileNameButton
          )}
        </TD>
        <TD>
          {this.props.profile.provisionedQuantity &&
            NumberFormatter.formatNumber(this.props.profile.provisionedQuantity.toString())}
        </TD>
        <TD>
          <span>{NumberFormatter.formatNumber(this.props.profile.cap)}</span>
        </TD>
        <TD>
          <FormattedNumber value={this.state.adminsForProfile.length} />
        </TD>
        <TD>
          {this.props.profile.notifications ? (
            <FormattedMessage id="ProfileTable.notificationOn" defaultMessage="On" />
          ) : (
            <FormattedMessage id="ProfileTable.notificationOff" defaultMessage="Off" />
          )}
        </TD>
        <TD className="MoreOptionsTableCell">
          <Dropdown alignRight closeOnSelect className="MoreDropdown">
            <Button
              dropdownTrigger
              className="MoreButton"
              data-testid="profiles-menu-options"
              aria-label="profile menu options"
            >
              <More size="S" />
            </Button>
            <MenuIntl dropdownMenu {...this.props}>
              {readOnly ? (
                <ModalTrigger>
                  <MenuItem
                    onClick={(): void => Analytics.fireCTAEvent(`view product profile dialog opened from dropdown`)}
                    data-testid="profile-table-view-profile-dialog"
                  >
                    <FormattedMessage id="EditCompartment.Products.Profiles.menu.View" defaultMessage="View profile" />
                  </MenuItem>
                  <ViewProdProfileDialogContentIntl
                    {...this.props}
                    profile={this.props.profile}
                    attributes={this.props.attributes}
                    selectedOrg={this.props.selectedOrg}
                  />
                </ModalTrigger>
              ) : (
                <>
                  <ModalTrigger>
                    <MenuItem
                      onClick={(): void => Analytics.fireCTAEvent(`Edit product profile dialog opened from dropdown`)}
                      data-testid="profile-table-edit-profile-dialog"
                    >
                      <FormattedMessage
                        id="EditCompartment.Products.Profiles.menu.EditProfile"
                        defaultMessage="Edit profile"
                      />
                    </MenuItem>
                    <EditProdProfileDialogContentIntl
                      {...this.props}
                      context={ProfileDialogContext.EDIT}
                      update={this.props.update}
                      profile={this.props.profile}
                      attributes={this.props.attributes}
                      selectedOrg={this.props.selectedOrg}
                    />
                  </ModalTrigger>
                  <ModalTrigger>
                    <MenuItem
                      onClick={(): void => Analytics.fireCTAEvent('delete profile dialog opened')}
                      data-testid="profile-table-delete-profile-dialog"
                    >
                      <FormattedMessage
                        id="EditCompartment.Products.Profiles.menu.DeleteProfile"
                        defaultMessage="Delete profile"
                      />
                    </MenuItem>
                    <Dialog
                      variant="destructive"
                      title={formatMessage(messages.Warning)}
                      confirmLabel={formatMessage(messages.OK)}
                      onCancel={(): void => {
                        Analytics.fireCTAEvent('delete profile dialog canceled');
                      }}
                      onConfirm={(): void => {
                        this.onDeleteProfile();
                        Analytics.fireCTAEvent('delete profile dialog confirmed');
                      }}
                      cancelLabel={formatMessage(messages.Cancel)}
                    >
                      {formatMessage(messages.AreYouSure, { ProfileName: this.props.profile.name })}
                    </Dialog>
                  </ModalTrigger>
                </>
              )}
            </MenuIntl>
          </Dropdown>
        </TD>
      </React.Fragment>
    );
  }
}
export default injectIntl(ProfileRow);
