import * as _ from 'lodash';
import React from 'react';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Dropdown from '@react/react-spectrum/Dropdown';
import More from '@react/react-spectrum/Icon/More';
import { Menu, MenuItem, MenuProps } from '@react/react-spectrum/Menu';
import Dialog from '@react/react-spectrum/Dialog';
import Search from '@react/react-spectrum/Search';
import Button from '@react/react-spectrum/Button';
import { Table, TBody, TD, TH, THead, TR } from '@react/react-spectrum/Table';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import { defineMessages, FormattedMessage, injectIntl, IntlProvider, WrappedComponentProps } from 'react-intl';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import AddAdmin from './AddAdmin';
import { UAdmin } from '../../../services/orgMaster/UAdmin';
import { OrgOperation } from '../../../services/orgMaster/OrgMaster';
import { UserTypeMap, UserTypeMapInterface } from './services/UserTypeMap';
import EditAdminContent from './EditAdminContent';
import SearchError from '../SearchError/SearchError';
import '../../common.css';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import AdminPermission from '../../../services/authentication/AdminPermission';
import ViewAdminContent from './ViewAdminContent';
import Analytics from '../../../Analytics/Analytics';
import JILSearch from '../services/JILSearch';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoHelpBubble from '../../../components/HelpBubble/HelpBubble';
import { OrgAdminType } from '../../../services/authentication/IMS';
import { MAX_DISPLAY_CHARACTER_LENGTH } from '../Constants';
import ScrollableContent from '../Widgets/ScrollableContent';
import { CommandService } from '../../../services/Commands/CommandService';

interface AdminTableProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
}

interface AdminTableState {
  filteredAdmins: UAdmin[];
  searchInput: string;
  errorMessage: string; // if not empty, show the alert box. The errorMessage is defined if the loading of admins fails on componentDidMount/componentDidUpdate
  showSearchError: boolean;
  loadingSearchResults: boolean;
  displayLoadingForAdminCounts: boolean;
}

interface AdminTablePropsIntl extends AdminTableProps {
  admin: UAdmin;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function EditAdminContentIntl(props: Omit<AdminTablePropsIntl, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditAdminContent {...props} />
    </IntlProvider>
  );
}

function ViewAdminContentIntl(props: Omit<AdminTablePropsIntl, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ViewAdminContent {...props} />
    </IntlProvider>
  );
}

export const messages = defineMessages({
  OK: {
    id: 'EditCompartment.Admins.dialog.OK',
    defaultMessage: 'OK',
  },
  Warning: {
    id: 'EditCompartment.Admins.dialog.Warning',
    defaultMessage: 'Warning',
  },
  Cancel: {
    id: 'EditCompartment.AdminTable.dialog.Cancel',
    defaultMessage: 'Cancel',
  },
  AreYouSureDelete: {
    id: 'EditCompartment.Admins.dialog.AreYouSureRemoveRights',
    defaultMessage: 'Are you sure you want to remove admin rights for {email}?',
  },
  SearchPlaceholder: {
    id: 'EditCompartment.Admins.SearchPlaceholder',
    defaultMessage: 'Search',
  },
  PaneHelp: {
    id: 'Organizations.Administrators.Helptext',
    defaultMessage:
      'View and manage admins of the organization selected on the left.  Scroll to the bottom to see more admins if they are not all shown.',
  },
  SystemAdmin: {
    id: 'EditCompartment.Admins.SystemAdmin',
    defaultMessage: 'System Admin',
  },
  GlobalAdmin: {
    id: 'EditCompartment.Admins.GlobalAdmin',
    defaultMessage: 'Global Admin',
  },
  GlobalAdminReadOnly: {
    id: 'EditCompartment.Admins.GlobalAdminRO',
    defaultMessage: 'Global Viewer',
  },
  DeploymentAdmin: {
    id: 'EditCompartment.Admins.DeployAdmin',
    defaultMessage: 'Deployment Admin',
  },
  ProductProfileAdmin: {
    id: 'EditCompartment.Admins.ProdProf',
    defaultMessage: 'Product Profile Admin',
  },
  ProductAdmin: {
    id: 'EditCompartment.Admins.ProdAdmin',
    defaultMessage: 'Product Admin',
  },
  UserGroupAdmin: {
    id: 'EditCompartment.Admins.UserGroup',
    defaultMessage: 'User Group Admin',
  },
  ContractAdmin: {
    id: 'EditCompartment.Admins.Contract',
    defaultMessage: 'Contract Admin',
  },
  StorageAdmin: {
    id: 'EditCompartment.Admins.StorageAdmin',
    defaultMessage: 'Storage Admin',
  },
  SupportAdmin: {
    id: 'EditCompartment.Admins.SupportAdmin',
    defaultMessage: 'Support Admin',
  },
  FailedToLoadAdmin: {
    id: 'EditCompartment.Admins.FailedToLoadAdmin',
    defaultMessage: 'Unable to load admins: {error}',
  },
  AdminLabelSingular: {
    id: 'EditCompartment.Admins.AdminLabelSingular',
    defaultMessage: 'admin',
  },
  AdminLabelPlural: {
    id: 'EditCompartment.Admins.AdminLabelPlural',
    defaultMessage: 'admins',
  },
});

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function MenuIntl(props: Omit<AdminTableProps & MenuProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <Menu {...props}>{props.children}</Menu>
    </IntlProvider>
  );
}

const ADMIN_TEST_ID = 'admin-test-id-';

class AdminTable extends React.Component<AdminTableProps, AdminTableState> {
  searchTimer: NodeJS.Timeout | undefined; // Store the search timer. Previous timer is replaced with a new one while the user is typing. Once the user stops typing, the search is fired after 1second.
  abortController: AbortController;
  constructor(props: AdminTableProps) {
    super(props);
    this.userTypeMap = UserTypeMap.get();
    this.state = {
      filteredAdmins: this.props.selectedOrg.getAdmins(),
      searchInput: '',
      errorMessage: '',
      showSearchError: false,
      loadingSearchResults: false,
      displayLoadingForAdminCounts: true,
    };
    this.abortController = new AbortController();
  }

  localizedAdminType(adminType: OrgAdminType): string {
    const { formatMessage } = this.props.intl;
    switch (adminType) {
      case OrgAdminType.ORG_ADMIN:
        return formatMessage(messages.SystemAdmin);
      case OrgAdminType.COMPARTMENT_ADMIN:
        return formatMessage(messages.GlobalAdmin);
      case OrgAdminType.COMPARTMENT_VIEWER:
        return formatMessage(messages.GlobalAdminReadOnly);
      case OrgAdminType.DEPLOYMENT_ADMIN:
        return formatMessage(messages.DeploymentAdmin);
      case OrgAdminType.LICENSE_ADMIN:
        return formatMessage(messages.ProductProfileAdmin);
      case OrgAdminType.PRODUCT_ADMIN:
        return formatMessage(messages.ProductAdmin);
      case OrgAdminType.USER_GROUP_ADMIN:
        return formatMessage(messages.UserGroupAdmin);
      case OrgAdminType.CONTRACT_ADMIN:
        return formatMessage(messages.ContractAdmin);
      case OrgAdminType.STORAGE_ADMIN:
        return formatMessage(messages.StorageAdmin);
      case OrgAdminType.SUPPORT_ADMIN:
        return formatMessage(messages.SupportAdmin);
      default:
        return `Unknown Admin Type: ${adminType}`;
    }
  }

  private async loadAdmins(): Promise<void> {
    try {
      if (!this.props.selectedOrg.adminsLoaded) {
        await LoadOrgDataService.loadAdminsV2(this.props.selectedOrg.id, false, undefined, this.abortController.signal);
      }
      if (this.abortController.signal.aborted) return;
      this.setState((state) => {
        return {
          errorMessage: '', // reset error message
          filteredAdmins: AdminTable.getFilteredAdmins(state.searchInput, this.props.selectedOrg),
        };
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      // in the case of failure, errorMessage is set as below. This would cause an Alert box to be shown in the AdminTable if the load was not successful.
      const { formatMessage } = this.props.intl;
      this.setState({ errorMessage: formatMessage(messages.FailedToLoadAdmin, { error: error.message }) });
    }
  }

  private async loadAdminCounts(): Promise<void> {
    await LoadOrgDataService.loadAdminCounts(this.props.selectedOrg.id, this.abortController.signal);
    if (this.abortController.signal.aborted) return;
    this.setState({ displayLoadingForAdminCounts: false });
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async componentDidMount(): Promise<void> {
    await this.loadAdmins();
    await this.loadAdminCounts();
  }

  async componentDidUpdate(prevProps: AdminTableProps): Promise<void> {
    const currentAdmins = AdminTable.getFilteredAdmins(this.state.searchInput, this.props.selectedOrg);
    const isAdminListChange = !_.isEqual(this.state.filteredAdmins, currentAdmins);
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id || isAdminListChange) {
      await this.loadAdmins(); // load admins if the changed compartment has not loaded the admins
    }
  }

  // Delete the admin from all the rights, remove from product admins, profile admins, user group admins,
  // deployment admins, support admins and system admin rights
  private onDeleteAdmin = (admin: UAdmin): void => {
    const editedAdmin: UAdmin = _.cloneDeep(admin);
    editedAdmin.removeAllAdminRoles();
    CommandService.addAdminEdit(this.props.selectedOrg, editedAdmin, OrgOperation.DELETE, admin);
    this.props.update();
  };

  // create a separate admin row for each admin
  private getAdminRows = (): React.ReactNode => {
    const { formatMessage } = this.props.intl;
    const readOnly =
      AdminPermission.readOnlyMode() ||
      !this.props.selectedOrg.policiesLoaded ||
      !this.props.selectedOrg.compartmentPolicy.policies.manageAdmins.value;
    const sortedAdmins = _.sortBy<UAdmin>(this.state.filteredAdmins, (admin) => {
      return `${_.toLower(admin.firstName)} ${_.toLower(admin.lastName)} ${_.toLower(admin.email)}`;
    });
    const rows: React.ReactNode[] = [];
    _.forEach(sortedAdmins, (admin: UAdmin): void => {
      const adminName = (
        <Button
          quiet
          variant="action"
          onClick={(): void => {
            Analytics.fireCTAEvent(` ${readOnly ? 'view' : 'edit'} admin dialog opened from name`);
            ModalContainer.show(
              /* depending on user permission, display view or edit modal on click */
              readOnly ? (
                <ViewAdminContentIntl {...this.props} admin={admin} selectedOrg={this.props.selectedOrg} />
              ) : (
                <EditAdminContentIntl
                  {...this.props}
                  admin={admin}
                  update={this.props.update}
                  selectedOrg={this.props.selectedOrg}
                />
              ),
              this
            );
          }}
          data-testid="admin-table-admin-name"
          className="EditCompartmentTabs__Name"
        >
          {`${admin.firstName} ${admin.lastName}`}
        </Button>
      );
      const roles = _.map(admin.getAdminRoles(), (roleString: OrgAdminType) =>
        this.localizedAdminType(roleString)
      ).join(', ');
      const row: React.ReactNode = (
        <TR key={admin.email + admin.userType} data-testid={`${ADMIN_TEST_ID}${admin.email + admin.userType}`}>
          <TD>
            {`${admin.firstName} ${admin.lastName}`.length > MAX_DISPLAY_CHARACTER_LENGTH ? (
              /* The tooltip is shown when name is long and truncated */
              <OverlayTrigger placement="top">
                {adminName}
                <Tooltip className="EditCompartmentTabs__NameTooltip">{`${admin.firstName} ${admin.lastName}`}</Tooltip>
              </OverlayTrigger>
            ) : (
              adminName
            )}
          </TD>
          <TD>{admin.email}</TD>
          <TD>{admin.userType && this.userTypeMap[admin.userType]}</TD>
          <TD className="AdminTableRoles">
            {roles.length > MAX_DISPLAY_CHARACTER_LENGTH ? (
              <OverlayTrigger placement="top">
                <span>{roles}</span>
                <Tooltip className="EditCompartmentTabs__NameTooltip">{roles}</Tooltip>
              </OverlayTrigger>
            ) : (
              roles
            )}
          </TD>
          <TD className="MoreOptionsTableCell">
            <Dropdown alignRight closeOnSelect className="MoreDropdown">
              <Button
                dropdownTrigger
                className="MoreButton"
                data-testid="admin-menu-options"
                aria-label="admin menu options"
              >
                <More size="S" />
              </Button>
              <MenuIntl dropdownMenu {...this.props}>
                {readOnly ? (
                  <ModalTrigger>
                    <MenuItem
                      onClick={(): void => Analytics.fireCTAEvent(` view admin dialog opened from dropdown`)}
                      data-testid="admin-table-view-admin"
                    >
                      <FormattedMessage id="EditCompartment.Admins.menu.ViewAdmin" defaultMessage="View Admin" />
                    </MenuItem>
                    <ViewAdminContentIntl {...this.props} admin={admin} />
                  </ModalTrigger>
                ) : (
                  <>
                    <ModalTrigger>
                      <MenuItem
                        onClick={(): void => Analytics.fireCTAEvent(`edit admin dialog opened from dropdown`)}
                        data-testid="admin-table-edit-admin"
                      >
                        <FormattedMessage id="EditCompartment.Admins.menu.EditAdmin" defaultMessage="Edit Admin" />
                      </MenuItem>
                      <EditAdminContentIntl {...this.props} admin={admin} update={this.props.update} />
                    </ModalTrigger>
                    <ModalTrigger>
                      <MenuItem
                        onClick={(): void => Analytics.fireCTAEvent(`delete admin dialog opened`)}
                        data-testid="admin-table-delete-admin"
                      >
                        <FormattedMessage
                          id="EditCompartment.Admins.menu.RemoveAdminRights"
                          defaultMessage="Remove Admin Rights"
                        />
                      </MenuItem>
                      <Dialog
                        variant="destructive"
                        title={formatMessage(messages.Warning)}
                        confirmLabel={formatMessage(messages.OK)}
                        onCancel={(): void => Analytics.fireCTAEvent(`delete admin dialog canceled`)}
                        onConfirm={(): void => {
                          this.onDeleteAdmin(admin);
                          Analytics.fireCTAEvent(`delete admin dialog confirmed`);
                        }}
                        cancelLabel={formatMessage(messages.Cancel)}
                      >
                        {formatMessage(messages.AreYouSureDelete, { email: admin.email })}
                      </Dialog>
                    </ModalTrigger>
                  </>
                )}
              </MenuIntl>
            </Dropdown>
          </TD>
        </TR>
      );
      rows.push(row);
    });
    return rows;
  };

  private onSearch = (searchInput: string): void => {
    const filterQuery = _.trim(searchInput);
    if (_.isEmpty(filterQuery)) {
      this.setState({
        filteredAdmins: this.props.selectedOrg.getAdmins(),
      });
      return;
    }
    if (filterQuery.length < 3) {
      this.setState({ showSearchError: true });
      return;
    }
    Analytics.fireCTAEvent(`admin search`);
    this.setState({
      filteredAdmins: AdminTable.getFilteredAdmins(filterQuery, this.props.selectedOrg),
    });
    if (this.searchTimer) {
      // clear timeout as the user is still typing
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(async (): Promise<void> => {
      // if there are more admins which have not yet been loaded, call admin jil search and add the matching admins to the compartment to show them
      if (this.props.selectedOrg.isMoreAdmins()) {
        // show load symbol while waiting for response of search query
        this.setState({ loadingSearchResults: true });
        await JILSearch.adminSearch(this.props.selectedOrg, filterQuery);
        this.setState({
          filteredAdmins: AdminTable.getFilteredAdmins(filterQuery, this.props.selectedOrg),
          loadingSearchResults: false,
        });
      }
    }, 1000); // The search API call is made 1 second after the time user stops typing
  };

  /**
   * filter admins as per search query
   */
  private static getFilteredAdmins(filterQuery: string, compartment: UOrgMaster): UAdmin[] {
    const admins = compartment.getAdmins();
    if (filterQuery.length < 3) {
      // no filtering with search string less than 3 characters because server side search expects 3 or more characters
      return admins;
    }
    return _.filter(
      admins,
      (admin: UAdmin): boolean =>
        _.includes(_.toLower(admin.firstName), _.toLower(filterQuery)) ||
        _.includes(_.toLower(admin.lastName), _.toLower(filterQuery)) ||
        _.includes(_.toLower(admin.email), _.toLower(filterQuery))
    );
  }

  private morePagesToLoad(): boolean {
    return this.props.selectedOrg.currentAdminPageIndex + 1 < this.props.selectedOrg.totalAdminPageCount;
  }

  /**
   * Load more next page of admins
   */
  loadNextPage = async (): Promise<void> => {
    try {
      // get next list of admins
      await LoadOrgDataService.loadNextPageAdminsV2(this.props.selectedOrg.id);
      this.setState((state) => ({
        filteredAdmins: AdminTable.getFilteredAdmins(state.searchInput, this.props.selectedOrg),
      })); // rerender with updated values
    } catch (error) {
      this.setState({ errorMessage: error });
    }
  };

  userTypeMap: UserTypeMapInterface;

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in admin table if we are unable to load the admin data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }
    const finalTotalAdmins =
      this.props.selectedOrg.totalAdminCount +
      this.props.selectedOrg.getNewAdminCount() -
      CommandService.getNumOfDeletedAdmins(this.props.selectedOrg.id); // adjust for added and deleted admins
    // show wait if admins not loaded
    return this.props.selectedOrg.adminsLoaded ? (
      <div className="AdminTable">
        <div className="EditCompartment_tabSectionHeader">
          <Search
            placeholder={formatMessage(messages.SearchPlaceholder)}
            onChange={(searchInput: string): void => {
              if (!searchInput) {
                // reset admin list if the searchInput is empty
                this.setState({
                  filteredAdmins: this.props.selectedOrg.getAdmins(),
                  searchInput,
                  showSearchError: false,
                });
              } else {
                this.setState({ searchInput, showSearchError: false });
              }
              this.onSearch(searchInput);
            }}
            value={this.state.searchInput}
            className="EditCompartment__TableSearch"
            data-testid="admin-table-admin-search"
          />
          <span className="EditCompartment__tabSection--rightAligned">
            <span className="EditCompartment__listCountDisplay">
              {this.state.displayLoadingForAdminCounts ? (
                <Wait size="S" />
              ) : (
                <FormattedMessage
                  id="EditCompartment.AdminTableCountDisplay"
                  description="Display current admin count and total admin count"
                  defaultMessage="Displaying {currentAdminCount} of {totalAdminCount} {adminLabel}"
                  values={{
                    currentAdminCount: this.state.filteredAdmins.length,
                    totalAdminCount: finalTotalAdmins,
                    adminLabel:
                      finalTotalAdmins === 1
                        ? formatMessage(messages.AdminLabelSingular)
                        : formatMessage(messages.AdminLabelPlural),
                  }}
                />
              )}
            </span>
            {!AdminPermission.readOnlyMode() && (
              <AddAdmin
                update={this.props.update}
                adminList={this.props.selectedOrg.getAdmins()}
                selectedOrg={this.props.selectedOrg}
              />
            )}
            <GoHelpBubble goUrlKey={GoUrlKeys.organizationsAdmins}>
              <p>{formatMessage(messages.PaneHelp)}</p>
            </GoHelpBubble>
          </span>
        </div>
        <SearchError showError={this.state.showSearchError} />
        <ScrollableContent
          onScroll={this.loadNextPage}
          enableInfiniteScroll={this.props.selectedOrg.totalAdminPageCount > 1 && this.state.searchInput.length === 0}
          hasMorePages={this.morePagesToLoad()}
          uniqueId="EditCompartment_AdminTableID"
          className="EditCompartment_tabContent"
        >
          <React.Fragment>
            <Table>
              <THead className="EditCompartment__stickyHeader">
                <TH>
                  <FormattedMessage id="EditCompartment.Admins.header.Name" defaultMessage="NAME" />
                </TH>
                <TH>
                  <FormattedMessage id="EditCompartment.Admins.header.Email" defaultMessage="EMAIL" />
                </TH>
                <TH>
                  <FormattedMessage id="EditCompartment.Admins.header.IdType" defaultMessage="ID TYPE" />
                </TH>
                <TH>
                  <FormattedMessage id="EditCompartment.Admins.header.Role" defaultMessage="ROLE(S)" />
                </TH>
                <TH />
              </THead>
              <TBody>{this.getAdminRows()}</TBody>
            </Table>
            {this.state.loadingSearchResults && <Wait className="Load_wait" />}
          </React.Fragment>
        </ScrollableContent>
      </div>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}
export default injectIntl(AdminTable);
export { ADMIN_TEST_ID };
