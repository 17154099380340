import { defineMessages, MessageDescriptor } from 'react-intl';

/**
 * This would be central place for defining messages for users, generated from `Adobe Agents` & 'org migration' tab,
 * which would need react-intl feature.
 * Defining messages here would help avoid react-intl related code duplication.
 * e.g. Error messages, Common headers/title, Dialogs etc.
 */
export const MESSAGES: Record<string, MessageDescriptor> = defineMessages({
  ReviewLabel: {
    id: 'OrgMigrationForAA.ReviewLabel',
    defaultMessage: 'Review',
  },
  SearchOrganization: {
    id: 'OrgMigrationForAA.SearchOrganizationTitle',
    defaultMessage: 'Search organization',
  },
  SelectOrganization: {
    id: 'OrgMigrationForAA.SelectOrganization',
    defaultMessage: 'Select organization',
  },
  FailedToCheckEligibility: {
    id: 'OrgMigrationForAA.FailedToCheckEligibility',
    defaultMessage: 'Failed to check eligibility for org {orgId}',
  },
  HelpLearnMore: {
    id: 'OrgMigration.help.LearnMore',
    defaultMessage: 'Learn more',
  },
});
