import { defineMessages, MessageDescriptor } from 'react-intl';
import { CmdDescriptionCodesData } from './MessageImages';

export interface ErrorCodesData {
  errorCode: string;
  errorParams?: string[];
}

export interface MessageData {
  errorData?: ErrorCodesData;
  warningData?: ErrorCodesData[];
  cmdDescription: CmdDescriptionCodesData[];
}

/**
 * This would be central place for defining messages for Error and Warning Codes from banyan svc
 */
export const ERROR_CODES_MESSAGES: Record<string, MessageDescriptor> = defineMessages({
  NOT_A_SYSTEM_ADMIN_OR_GLOBAL_ADMIN: {
    id: 'Banyan.ErrorCodes.NOT_A_SYSTEM_ADMIN_OR_GLOBAL_ADMIN',
    defaultMessage: 'Not a System Admin or Global Admin',
  },
  NOT_A_GLOBAL_ADMIN: {
    id: 'Banyan.ErrorCodes.NOT_A_GLOBAL_ADMIN',
    defaultMessage: 'Not a Global Admin',
  },
  INVALID_PROFILE_REPARENT_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_PROFILE_REPARENT_OPERATION',
    defaultMessage: 'Reparent is not a valid operation for a profile.',
  },
  INVALID_ADMIN_REPARENT_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_ADMIN_REPARENT_OPERATION',
    defaultMessage: 'Reparent is not a valid operation for an admin.',
  },
  INVALID_ADMIN_UPDATE_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_ADMIN_UPDATE_OPERATION',
    defaultMessage: 'Updating an admin is not a valid operation.',
  },
  INVALID_POLICY_DELETE_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_POLICY_DELETE_OPERATION',
    defaultMessage: 'Organization policies cannot be deleted.',
  },
  INVALID_POLICY_REPARENT_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_POLICY_REPARENT_OPERATION',
    defaultMessage: 'Organization policies cannot be reparented.',
  },
  NOT_AN_ADOBE_AGENT: {
    id: 'Banyan.ErrorCodes.NOT_AN_ADOBE_AGENT',
    defaultMessage: 'Not an Adobe Agent.',
  },
  INVALID_ORG_ID: {
    id: 'Banyan.ErrorCodes.INVALID_ORG_ID',
    defaultMessage: 'Invalid org id {0}.',
  },
  INVALID_PARENT_ORG_ID: {
    id: 'Banyan.ErrorCodes.INVALID_PARENT_ORG_ID',
    defaultMessage: 'Invalid parent orgId {0}.',
  },
  REPARENT_OPERATION_CREATES_LOOP: {
    id: 'Banyan.ErrorCodes.REPARENT_OPERATION_CREATES_LOOP',
    defaultMessage: 'Reparenting org {0} would create a loop in the hierarchy',
  },
  INVALID_PRODUCT_REPARENT_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_PRODUCT_REPARENT_OPERATION',
    defaultMessage: 'Product reparent is not a valid operation.',
  },
  INVALID_USER_GROUP_REPARENT_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_USER_GROUP_REPARENT_OPERATION',
    defaultMessage: 'User group reparent is not a valid operation.',
  },
  REPARENT_DISALLOWED_BECAUSE_DESTINATION_IS_READ_ONLY: {
    id: 'Banyan.ErrorCodes.REPARENT_DISALLOWED_BECAUSE_DESTINATION_IS_READ_ONLY',
    defaultMessage: 'Re-parent is disallowed because the destination {0} is a read-only org',
  },
  REPARENT_DISALLOWED_BECAUSE_ESM_ORG_MISMATCH: {
    id: 'Banyan.ErrorCodes.REPARENT_DISALLOWED_BECAUSE_ESM_ORG_MISMATCH',
    defaultMessage:
      'Re-parent is disallowed between org-to-reparent {0} and destination-org {1} because only one is enabled with ESM',
  },
  REPARENT_DISALLOWED_BECAUSE_COHORT_MISMATCH: {
    id: 'Banyan.ErrorCodes.REPARENT_DISALLOWED_BECAUSE_COHORT_MISMATCH',
    defaultMessage:
      'Re-parent is disallowed between org-to-reparent {0} and destination-org {1} because cohort status does not match',
  },
  MISSING_COMMON_GLOBAL_ADMIN_FOR_REPARENT: {
    id: 'Banyan.ErrorCodes.MISSING_COMMON_GLOBAL_ADMIN_FOR_REPARENT',
    defaultMessage: 'Reparent has no global admin shared between source and destination',
  },
  REPARENT_DISALLOWED_BECAUSE_ORG_HAS_ALLOCATED_PRODUCTS: {
    id: 'Banyan.ErrorCodes.REPARENT_DISALLOWED_BECAUSE_ORG_HAS_ALLOCATED_PRODUCTS',
    defaultMessage: 'Reparent disallowed because org {0} has allocated products',
  },
  ERROR_IN_AUTHENTICATION: {
    id: 'Banyan.ErrorCodes.ERROR_IN_AUTHENTICATION',
    defaultMessage: 'Error in authentication',
  },
  MISSING_TEMPLATE_ID_FIELD: {
    id: 'Banyan.ErrorCodes.MISSING_TEMPLATE_ID_FIELD',
    defaultMessage: 'Missing required templatedId field.',
  },
  UNAUTHORIZED_TEMPLATE_ACCESS: {
    id: 'Banyan.ErrorCodes.UNAUTHORIZED_TEMPLATE_ACCESS',
    defaultMessage: 'Unauthorized to manage policies/templates on this organization.',
  },
  NESTING_LIMIT_EXCEEDED: {
    id: 'Banyan.ErrorCodes.NESTING_LIMIT_EXCEEDED',
    defaultMessage: 'Nesting Limit exceeded - bad data',
  },
  TEMPLATE_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.TEMPLATE_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to complete requested operation on template.',
  },
  INVALID_TEMPLATE_ID: {
    id: 'Banyan.ErrorCodes.INVALID_TEMPLATE_ID',
    defaultMessage: 'Missing or invalid template Id.',
  },
  INVALID_TEMPLATE_NAME: {
    id: 'Banyan.ErrorCodes.INVALID_TEMPLATE_NAME',
    defaultMessage: 'Template name should be non-empty and be less than or equal to 100 characters.',
  },
  DUPLICATE_TEMPLATE_NAME: {
    id: 'Banyan.ErrorCodes.DUPLICATE_TEMPLATE_NAME',
    defaultMessage: 'Template with same name already exists.',
  },
  MISSING_TEMPLATE_POLICIES: {
    id: 'Banyan.ErrorCodes.MISSING_TEMPLATE_POLICIES',
    defaultMessage: 'Template does not include any policies.',
  },
  INVALID_TEMPLATE_POLICIES: {
    id: 'Banyan.ErrorCodes.INVALID_TEMPLATE_POLICIES',
    defaultMessage: 'Template contains invalid policies.',
  },
  TEMPLATES_NOT_FOUND_FOR_ORG: {
    id: 'Banyan.ErrorCodes.TEMPLATES_NOT_FOUND_FOR_ORG',
    defaultMessage: 'Templates not found for organization {0}.',
  },
  MISSING_OR_EMPTY_TEMPLATE: {
    id: 'Banyan.ErrorCodes.MISSING_OR_EMPTY_TEMPLATE',
    defaultMessage: 'Template is missing or empty.',
  },
  POLICY_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.POLICY_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to complete requested operation on policies.',
  },
  POLICY_INTERNAL_SERVER_ERROR_FOR_ORG: {
    id: 'Banyan.ErrorCodes.POLICY_INTERNAL_SERVER_ERROR_FOR_ORG',
    defaultMessage: 'Failed to complete requested operation on policies for org {0}.',
  },
  MISSING_POLICY_LIST: {
    id: 'Banyan.ErrorCodes.MISSING_POLICY_LIST',
    defaultMessage: 'Policy list missing or empty.',
  },
  INVALID_POLICY_LIST: {
    id: 'Banyan.ErrorCodes.INVALID_POLICY_LIST',
    defaultMessage: 'Policy list is malformed or invalid.',
  },
  INVALID_OR_FORBIDDEN_POLICY_CHANGES: {
    id: 'Banyan.ErrorCodes.INVALID_OR_FORBIDDEN_POLICY_CHANGES',
    defaultMessage: 'Policy changes requested are invalid or unauthorized.',
  },
  PRODUCT_POLICY_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.PRODUCT_POLICY_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to complete requested operation on product policies.',
  },
  ORG_IDS_ARE_OUTSIDE_HIERARCHY: {
    id: 'Banyan.ErrorCodes.ORG_IDS_ARE_OUTSIDE_HIERARCHY',
    defaultMessage: 'Org IDs are outside of the hierarchy.',
  },
  MISSING_PRODUCT_ID_FOR_PRODUCT_POLICY: {
    id: 'Banyan.ErrorCodes.MISSING_PRODUCT_ID_FOR_PRODUCT_POLICY',
    defaultMessage: 'ProductId is missing for product policy.',
  },
  MISSING_PRODUCT_POLICIES: {
    id: 'Banyan.ErrorCodes.MISSING_PRODUCT_POLICIES',
    defaultMessage: 'Product policies are missing.',
  },
  PRODUCT_POLICY_NOT_FOUND_FOR_ORG: {
    id: 'Banyan.ErrorCodes.PRODUCT_POLICY_NOT_FOUND_FOR_ORG',
    defaultMessage: 'Product policies associated with a product {0} of an org {1} not found.',
  },
  POLICY_FORBIDS_CHILD_CREATION: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_CHILD_CREATION',
    defaultMessage: 'Could not add organization {0} because policy disallows creation of child orgs.',
  },
  ERROR_ADDING_ORG_DUE_TO_PARENT_ORG_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.ERROR_ADDING_ORG_DUE_TO_PARENT_ORG_NOT_FOUND',
    defaultMessage: 'Could not add organization {0} because parentOrgId {1} was not found.',
  },
  POLICY_FORBIDS_ORG_DELETION: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_ORG_DELETION',
    defaultMessage: 'Policy forbids deleting org {0}.',
  },
  POLICY_FORBIDS_ALTERING_ORG_DETAILS: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_ALTERING_ORG_DETAILS',
    defaultMessage: 'Policy forbids altering details of org {0}.',
  },
  ORG_DELETION_FAILED_AS_ORG_HAS_CLAIMED_DOMAINS: {
    id: 'Banyan.ErrorCodes.ORG_DELETION_FAILED_AS_ORG_HAS_CLAIMED_DOMAINS',
    defaultMessage: 'Org deletion failed as org {0} has claimed domains.',
  },
  INVALID_ROOT_ORG_DELETION: {
    id: 'Banyan.ErrorCodes.INVALID_ROOT_ORG_DELETION',
    defaultMessage: 'Cannot delete root org.',
  },
  ORG_DELETION_FAILED_AS_ORG_HAS_CHILDREN: {
    id: 'Banyan.ErrorCodes.ORG_DELETION_FAILED_AS_ORG_HAS_CHILDREN',
    defaultMessage: 'Org {0} cannot be deleted as the org has children: {1}',
  },
  ORG_UNLINKED_BUT_DELETION_FAILED_AS_CHILDREN_REFERENCE_ORG: {
    id: 'Banyan.ErrorCodes.ORG_UNLINKED_BUT_DELETION_FAILED_AS_CHILDREN_REFERENCE_ORG',
    defaultMessage: 'Org {0} was removed from hierarchy but failed to be deleted as some children still reference it',
  },
  ORG_DELETION_FAILED_AS_ORG_HAS_PRODUCTS: {
    id: 'Banyan.ErrorCodes.ORG_DELETION_FAILED_AS_ORG_HAS_PRODUCTS',
    defaultMessage: 'Org {0} cannot be deleted as the org has products.',
  },
  ORG_DELETION_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.ORG_DELETION_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to complete org deletion org operation.',
  },
  ORG_PATHNAME_TOO_LONG: {
    id: 'Banyan.ErrorCodes.ORG_PATHNAME_TOO_LONG',
    defaultMessage: 'Max org pathname exceeded. Org pathname must be less than 255 characters.',
  },
  ORG_NAME_TOO_SHORT: {
    id: 'Banyan.ErrorCodes.ORG_NAME_TOO_SHORT',
    defaultMessage: 'Org name must be at least 4 characters.',
  },
  POLICY_FORBIDS_ADDING_ADMINS: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_ADDING_ADMINS',
    defaultMessage: 'Policy forbids adding administrators.',
  },
  PRODUCT_DISALLOWS_ADDING_ADMIN: {
    id: 'Banyan.ErrorCodes.PRODUCT_DISALLOWS_ADDING_ADMIN',
    defaultMessage:
      'Failed to add or update admin {0} of org {1} because a product forbids adding a product or profile admin role.',
  },
  POLICY_FORBIDS_MANAGING_ADMINS: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_MANAGING_ADMINS',
    defaultMessage: 'Policy forbids managing administrators.',
  },
  POLICY_FORBIDS_ADDING_ADOBE_ID_ADMIN: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_ADDING_ADOBE_ID_ADMIN',
    defaultMessage: 'Policy forbids adding admins with account type as Adobe Id.',
  },
  POLICY_FORBIDS_ADDING_ADMIN_WITH_ACCOUNT_TYPE: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_ADDING_ADMIN_WITH_ACCOUNT_TYPE',
    defaultMessage: 'Permission to add admin with account type has been disabled by a policy',
  },
  USER_CANNOT_BE_ADDED_DIRECTLY_TO_ORG: {
    id: 'Banyan.ErrorCodes.USER_CANNOT_BE_ADDED_DIRECTLY_TO_ORG',
    defaultMessage: "The user cannot be added to the org. The user first needs to be added to the domain's directory.",
  },
  USER_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.USER_NOT_FOUND',
    defaultMessage: 'User with email {0} was not found',
  },
  PRODUCT_ALREADY_EXISTS: {
    id: 'Banyan.ErrorCodes.PRODUCT_ALREADY_EXISTS',
    defaultMessage: 'Product already exists in the org and cannot be reallocated.',
  },
  PRODUCT_CONFLICTS: {
    id: 'Banyan.ErrorCodes.PRODUCT_CONFLICTS',
    defaultMessage: "Product ''{0}'' conflicts with ''{1}'' in the destination org and cannot be reallocated.",
  },
  POLICY_FORBIDS_PRODUCT_MANAGEMENT: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_PRODUCT_MANAGEMENT',
    defaultMessage: 'Product management is forbidden by the policy.',
  },
  PRODUCT_DELETION_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.PRODUCT_DELETION_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to complete product deletion operation.',
  },
  PRODUCT_ALLOCATION_FAILED_INVALID_GRANTED_QUANTITY: {
    id: 'Banyan.ErrorCodes.PRODUCT_ALLOCATION_FAILED_INVALID_GRANTED_QUANTITY',
    defaultMessage: 'Product {0} cannot be allocated because granted quantity is not valid.',
  },
  POLICY_FORBIDS_USER_GROUP_MANAGEMENT: {
    id: 'Banyan.ErrorCodes.POLICY_FORBIDS_USER_GROUP_MANAGEMENT',
    defaultMessage: 'User group management is forbidden by the policy.',
  },
  FAILED_TO_EXPORT_ORGANIZATION_DATA: {
    id: 'Banyan.ErrorCodes.FAILED_TO_EXPORT_ORGANIZATION_DATA',
    defaultMessage: 'Failed to export data for org {0}.',
  },
  INVALID_EXPORT_FORMAT: {
    id: 'Banyan.ErrorCodes.INVALID_EXPORT_FORMAT',
    defaultMessage: 'Format parameter must be csv, json, or xlsx.',
  },
  COULD_NOT_EXPORT_DATA: {
    id: 'Banyan.ErrorCodes.COULD_NOT_EXPORT_DATA',
    defaultMessage: 'Could not export data.',
  },
  EXPORT_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.EXPORT_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to export org data for org {0}',
  },
  FAILED_TO_EXPORT_IN_REQUESTED_FORMAT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_EXPORT_IN_REQUESTED_FORMAT',
    defaultMessage: 'Failed to export data in format {0}.',
  },
  DETAIL_TO_EXPORT_NOT_SELECTED: {
    id: 'Banyan.ErrorCodes.DETAIL_TO_EXPORT_NOT_SELECTED',
    defaultMessage:
      'At least one detail from organizations, admins, domains, products, productprofiles, usergroups, orgpolicies must be selected.',
  },
  EXPORT_CSV_MULTIPLE_DETAIL_SELECTED: {
    id: 'Banyan.ErrorCodes.EXPORT_CSV_MULTIPLE_DETAIL_SELECTED',
    defaultMessage:
      'Only one detail from organizations, admins, domains, products, productprofiles, usergroups, orgpolicies should be selected for csv format.',
  },
  EXPORT_FAILED_TO_FETCH_PROFILES: {
    id: 'Banyan.ErrorCodes.EXPORT_FAILED_TO_FETCH_PROFILES',
    defaultMessage: 'Error getting {0} for product {1} for org {2}',
  },
  EXPORT_FAILED_TO_FETCH_DETAILS: {
    id: 'Banyan.ErrorCodes.EXPORT_FAILED_TO_FETCH_DETAILS',
    defaultMessage: 'Error getting {0} for org {1}',
  },
  OFFER_ID_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.OFFER_ID_NOT_FOUND',
    defaultMessage: 'No offer found for ID {0}',
  },
  ALLOCATION_FAILED_DUE_TO_MISSING_OFFER: {
    id: 'Banyan.ErrorCodes.ALLOCATION_FAILED_DUE_TO_MISSING_OFFER',
    defaultMessage: 'Could not allocate {0} because offer ID {1} not found.',
  },
  ORG_COUNT_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.ORG_COUNT_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to get counts for org {0}',
  },
  FAILED_TO_FETCH_USER_GROUPS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_USER_GROUPS',
    defaultMessage: 'Could not get user groups for org {0}.',
  },
  FAILED_TO_ADD_OR_UPDATE_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_OR_UPDATE_ADMIN',
    defaultMessage: 'Error adding or updating admin with email {0}.',
  },
  FAILED_TO_ADD_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_ADMIN',
    defaultMessage: 'Error adding admin with email {0}.',
  },
  FAILED_TO_UPDATE_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_ADMIN',
    defaultMessage: 'Error updating admin with email {0}',
  },
  FAILED_TO_REMOVE_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REMOVE_ADMIN',
    defaultMessage: 'Error removing admin with email {0}.',
  },
  INVALID_ADMIN_ROLE_TYPE: {
    id: 'Banyan.ErrorCodes.INVALID_ADMIN_ROLE_TYPE',
    defaultMessage: 'Invalid admin role type: {0}',
  },
  FAILED_TO_UPDATE_ORG_POLICY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_ORG_POLICY',
    defaultMessage: 'Failed to update org policies for org {0}.',
  },
  FAILED_TO_UPDATE_ORG_POLICY_DUE_TO_INVALID_ORG_ID: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_ORG_POLICY_DUE_TO_INVALID_ORG_ID',
    defaultMessage: 'Failed to update org policies due to invalid org Id {0}.',
  },
  FAILED_TO_ADD_ORG_POLICY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_ORG_POLICY',
    defaultMessage: 'Failed to add org policies for org {0}.',
  },
  FAILED_TO_UPDATE_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_ORG',
    defaultMessage: 'Failed to update org {0}.',
  },
  FAILED_TO_ADD_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_ORG',
    defaultMessage: 'Failed to add org {0}.',
  },
  UNAUTHORIZED_TO_CREATE_ROOT_ORG: {
    id: 'Banyan.ErrorCodes.UNAUTHORIZED_TO_CREATE_ROOT_ORG',
    defaultMessage:
      'User is not authorized to create a root org. Only ADOBE_AGENT_ADMIN or ADOBE_AGENT_PROVISIONER can create a top level org.',
  },
  FAILED_TO_REPARENT_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REPARENT_ORG',
    defaultMessage: 'Failed to reparent org {0}',
  },
  INVALID_FETCHING_MODEL_ID_FOR_DIFF_COMMAND_EXECUTOR: {
    id: 'Banyan.ErrorCodes.INVALID_FETCHING_MODEL_ID_FOR_DIFF_COMMAND_EXECUTOR',
    defaultMessage: 'Getting model id is not a valid operation for diff command executor.',
  },
  HOOLIHAN_MSG_PUBLISH_FAILURE: {
    id: 'Banyan.ErrorCodes.HOOLIHAN_MSG_PUBLISH_FAILURE',
    defaultMessage: 'Could not publish message to hoolihan for req Id: {0}.',
  },
  FAILED_TO_ALLOCATE_PRODUCT_DUE_TO_INVALID_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ALLOCATE_PRODUCT_DUE_TO_INVALID_PRODUCT',
    defaultMessage: 'Cannot allocate product as the requested product is null.',
  },
  PARENT_PRODUCT_DISALLOWED_FROM_ALLOCATING_PRODUCT: {
    id: 'Banyan.ErrorCodes.PARENT_PRODUCT_DISALLOWED_FROM_ALLOCATING_PRODUCT',
    defaultMessage: 'Parent product {0} is not allowed to allocate.',
  },
  PRODUCT_QUANTITY_TYPE_MISMATCH_BETWEEN_PARENT_AND_CHILD: {
    id: 'Banyan.ErrorCodes.PRODUCT_QUANTITY_TYPE_MISMATCH_BETWEEN_PARENT_AND_CHILD',
    defaultMessage: 'Parent product "{0}", with quantity {1} is a different type than the child\'s quantity {2}.',
  },
  ADDING_PRODUCT_CAUSES_OVER_ALLOCATION: {
    id: 'Banyan.ErrorCodes.ADDING_PRODUCT_CAUSES_OVER_ALLOCATION',
    defaultMessage: 'Adding product {0} causes over allocation.',
  },
  ADDING_PRODUCT_CAUSES_OVER_USE: {
    id: 'Banyan.ErrorCodes.ADDING_PRODUCT_CAUSES_OVER_USE',
    defaultMessage: 'Adding product {0} causes over use.',
  },
  EDITING_PRODUCT_CAUSES_OVER_ALLOCATION: {
    id: 'Banyan.ErrorCodes.EDITING_PRODUCT_CAUSES_OVER_ALLOCATION',
    defaultMessage: 'Editing product {0} causes over allocation.',
  },
  EDITING_PRODUCT_CAUSES_OVER_USE: {
    id: 'Banyan.ErrorCodes.EDITING_PRODUCT_CAUSES_OVER_USE',
    defaultMessage: 'Editing product {0} causes over use.',
  },
  FAILED_TO_ADD_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_PRODUCT',
    defaultMessage: 'Failed to add product {0}.',
  },
  GETTING_OR_CREATING_ORG_CONTRACT_FAILED: {
    id: 'Banyan.ErrorCodes.GETTING_OR_CREATING_ORG_CONTRACT_FAILED',
    defaultMessage: "Could not get or create organization's allocation contract.",
  },
  CONTRACT_NOT_FOUND_WITH_BUYING_PROGRAM: {
    id: 'Banyan.ErrorCodes.CONTRACT_NOT_FOUND_WITH_BUYING_PROGRAM',
    defaultMessage: 'Could not find contract with buying program in org {0}.',
  },
  FETCHING_ORG_CONTRACT_FAILED: {
    id: 'Banyan.ErrorCodes.FETCHING_ORG_CONTRACT_FAILED',
    defaultMessage: "Could not get organization's contracts",
  },
  INVALID_REQUEST_ID: {
    id: 'Banyan.ErrorCodes.INVALID_REQUEST_ID',
    defaultMessage: 'request Id is null.',
  },
  ERROR_POLLING_FULFILLMENT_EVENT_STATUS: {
    id: 'Banyan.ErrorCodes.ERROR_POLLING_FULFILLMENT_EVENT_STATUS',
    defaultMessage: 'Error while polling fulfillmentEvent status with Id {0}.',
  },
  ERROR_FINDING_FULFILLMENT_EVENT: {
    id: 'Banyan.ErrorCodes.ERROR_FINDING_FULFILLMENT_EVENT',
    defaultMessage: 'Error finding the fulfillment event.',
  },
  FULFILLMENT_EVENT_STATUS_ERROR: {
    id: 'Banyan.ErrorCodes.FULFILLMENT_EVENT_STATUS_ERROR',
    defaultMessage: 'Fulfillment event status error.',
  },
  FAILED_TO_UPDATE_PRODUCT_DUE_TO_INVALID_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_PRODUCT_DUE_TO_INVALID_PRODUCT',
    defaultMessage: 'Product requested is null, cannot execute UPDATE operation.',
  },
  INVALID_UPDATE_ON_UNLIMITED_RESOURCE: {
    id: 'Banyan.ErrorCodes.INVALID_UPDATE_ON_UNLIMITED_RESOURCE',
    defaultMessage: 'UNLIMITED resources cannot be updated with a number or vice-versa.',
  },
  ALLOCATION_TYPE_NOT_HANDLED: {
    id: 'Banyan.ErrorCodes.ALLOCATION_TYPE_NOT_HANDLED',
    defaultMessage: 'Allocation type is not supported for now.',
  },
  FAILED_TO_UPDATE_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_PRODUCT',
    defaultMessage: 'Failed to update product {0}.',
  },
  FAILED_TO_UPDATE_PRODUCT_POLICY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_PRODUCT_POLICY',
    defaultMessage: 'Failed to update product policy {0}.',
  },
  TIMED_OUT_WAITING_FOR_PRODUCT_POLICY_UPDATE: {
    id: 'Banyan.ErrorCodes.TIMED_OUT_WAITING_FOR_PRODUCT_POLICY_UPDATE',
    defaultMessage: 'Timed out while updating product policy {0} for product {1} for org {2}.',
  },
  FAILED_TO_DELETE_PRODUCT_AS_NOT_AN_ALLOCATED_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_PRODUCT_AS_NOT_AN_ALLOCATED_PRODUCT',
    defaultMessage: 'Cannot delete product {0} because it is not an allocated product.',
  },
  FAILED_TO_FETCH_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PRODUCT',
    defaultMessage: 'Failed to fetch product {0} with ID {1}',
  },
  PRODUCT_IS_MISSING_CONTRACT_ID: {
    id: 'Banyan.ErrorCodes.PRODUCT_IS_MISSING_CONTRACT_ID',
    defaultMessage: 'Product {0} with ID {1} on org {2} is missing its contract ID',
  },
  ALTERING_PRODUCT_FAILED_NOT_AN_ALLOCABLE_PRODUCT: {
    id: 'Banyan.ErrorCodes.ALTERING_PRODUCT_FAILED_NOT_AN_ALLOCABLE_PRODUCT',
    defaultMessage: 'Cannot alter product {0} because it is not an allocable product.',
  },
  FAILED_TO_ADD_PROFILE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_PROFILE',
    defaultMessage: 'Failed to add product profile {0}.',
  },
  FAILED_TO_UPDATE_PROFILE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_PROFILE',
    defaultMessage: 'Failed to update product profile {0}.',
  },
  FAILED_TO_DELETE_PROFILE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_PROFILE',
    defaultMessage: 'Failed to delete product profile {0}.',
  },
  FAILED_TO_ADD_USERGROUP: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_USERGROUP',
    defaultMessage: 'Failed to add user group {0}.',
  },
  FAILED_TO_UPDATE_USERGROUP: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_USERGROUP',
    defaultMessage: 'Failed to update user group {0}.',
  },
  FAILED_TO_DELETE_USERGROUP: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_USERGROUP',
    defaultMessage: 'Failed to delete user group {0}.',
  },
  INVALID_ADD_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_ADD_OPERATION',
    defaultMessage: 'Add is not a valid operation.',
  },
  INVALID_UPDATE_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_UPDATE_OPERATION',
    defaultMessage: 'Update is not a valid operation.',
  },
  INVALID_DELETE_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_DELETE_OPERATION',
    defaultMessage: 'Delete is not a valid operation.',
  },
  INVALID_REPARENT_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_REPARENT_OPERATION',
    defaultMessage: 'Reparent is not a valid operation.',
  },
  FAILED_TO_REPARENT_DUE_TO_INVALID_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REPARENT_DUE_TO_INVALID_PRODUCT',
    defaultMessage: 'Product requested is null, cannot execute REPARENT operation.',
  },
  NOT_A_GLOBAL_ADMIN_OF_DESTINATION_ORG: {
    id: 'Banyan.ErrorCodes.NOT_A_GLOBAL_ADMIN_OF_DESTINATION_ORG',
    defaultMessage: 'Not a global admin of destination org {0}.',
  },
  NEW_ROOT_CANNOT_HAVE_ALLOCATION: {
    id: 'Banyan.ErrorCodes.NEW_ROOT_CANNOT_HAVE_ALLOCATION',
    defaultMessage: 'Reparenting org {0} to new root cannot have allocated product {1} with ID {2}',
  },
  PURCHASED_PRODUCT_CANNOT_BE_REALLOCATED: {
    id: 'Banyan.ErrorCodes.PURCHASED_PRODUCT_CANNOT_BE_REALLOCATED',
    defaultMessage: 'Purchased product {0} with ID {1} cannot be reallocated.',
  },
  REPARENTING_ORG_WITH_PRODUCT_DISALLOWED_BECAUSE_ESM_ORG_MISMATCH: {
    id: 'Banyan.ErrorCodes.REPARENTING_ORG_WITH_PRODUCT_DISALLOWED_BECAUSE_ESM_ORG_MISMATCH',
    defaultMessage:
      'Org "{0}" cannot be re-parented to org "{1}" because both are not ESM-enabled. This relates to product {2} with ID {3}.',
  },
  REALLOCATING_PRODUCT_CAUSES_OVER_ALLOCATION: {
    id: 'Banyan.ErrorCodes.REALLOCATING_PRODUCT_CAUSES_OVER_ALLOCATION',
    defaultMessage:
      'Reallocating product {0} with ID {1} would trigger an over-allocation of destination source product {2}.',
  },
  REALLOCATING_PRODUCT_CAUSES_OVER_USE: {
    id: 'Banyan.ErrorCodes.REALLOCATING_PRODUCT_CAUSES_OVER_USE',
    defaultMessage: 'Reallocating product {0} with ID {1} would trigger an over use of destination source product {2}.',
  },
  PRODUCT_REALLOCATION_INTERNAL_SERVER_ERROR: {
    id: 'Banyan.ErrorCodes.PRODUCT_REALLOCATION_INTERNAL_SERVER_ERROR',
    defaultMessage: 'Failed to complete product reallocation.',
  },
  INVALID_DIFF_GENERATION_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_DIFF_GENERATION_OPERATION',
    defaultMessage: 'Generating diff is not a valid operation.',
  },
  UNRECOGNISED_COMMAND_TYPE: {
    id: 'Banyan.ErrorCodes.UNRECOGNISED_COMMAND_TYPE',
    defaultMessage: 'Unrecognized CommandType: {0}.',
  },
  FAILED_TO_REPARENT_PRODUCTS_DUE_TO_INVALID_REQUEST: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REPARENT_PRODUCTS_DUE_TO_INVALID_REQUEST',
    defaultMessage: 'Products reparent requested is null, cannot execute REPARENT operation.',
  },
  NO_PRODUCTS_TO_REALLOCATE: {
    id: 'Banyan.ErrorCodes.NO_PRODUCTS_TO_REALLOCATE',
    defaultMessage: 'No products to reallocate. Org {0}.',
  },
  NEW_ROOT_CANNOT_HAVE_ALLOCATION_PRODUCTS: {
    id: 'Banyan.ErrorCodes.NEW_ROOT_CANNOT_HAVE_ALLOCATION_PRODUCTS',
    defaultMessage: 'Org {0} with allocated products cannot be reparented to become new root.',
  },
  ROOT_CANNOT_HAVE_ALLOCATION_PRODUCTS: {
    id: 'Banyan.ErrorCodes.ROOT_CANNOT_HAVE_ALLOCATION_PRODUCTS',
    defaultMessage: 'Org {0} is a root org with allocated products and cannot be reallocated.',
  },
  FAILED_TO_DETERMINE_PARENT_PRODUCTS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DETERMINE_PARENT_PRODUCTS',
    defautMessage: 'Failed to determine the source products on the parent org {0} for products on org {1}.',
  },
  FAILED_TO_FETCH_REQUIRED_PRODUCTS_INFO: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_REQUIRED_PRODUCTS_INFO',
    defaultMessage: 'Failed to fetch required info for products on org {0}.',
  },
  REALLOCATING_NOT_ALLOWED_AS_PRODUCTS_HAVE_EXPIRED: {
    id: 'Banyan.ErrorCodes.REALLOCATING_NOT_ALLOWED_AS_PRODUCTS_HAVE_EXPIRED',
    defaultMessage: 'Re-allocating not allowed as products of org {0} have expired.',
  },
  PURCHASED_PRODUCTS_CANNOT_BE_REALLOCATED: {
    id: 'Banyan.ErrorCodes.PURCHASED_PRODUCTS_CANNOT_BE_REALLOCATED',
    defaultMessage: 'Purchased products on org {0} cannot be reallocated.',
  },
  FAILED_TO_FETCH_MATCHING_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_MATCHING_PRODUCT',
    defaultMessage: 'Failed to fetch product matching {0}.',
  },
  REQUESTED_REPARENT_PRODUCT_COMMAND_IS_NOT_ALLOWED: {
    id: 'Banyan.ErrorCodes.REQUESTED_REPARENT_PRODUCT_COMMAND_IS_NOT_ALLOWED',
    defaultMessage: 'Requesting a re-parent product command is not allowed',
  },
  REQUESTED_REPARENT_PRODUCTS_COMMAND_IS_NOT_ALLOWED: {
    id: 'Banyan.ErrorCodes.REQUESTED_REPARENT_PRODUCTS_COMMAND_IS_NOT_ALLOWED',
    defaultMessage: 'Requesting a reparent products command is not allowed',
  },
  FAILED_TO_CREATE_THE_COMMAND: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_THE_COMMAND',
    defaultMessage: 'Failed to create command for {0}.',
  },
  JOB_CONTEXT_NOT_DEFINED: {
    id: 'Banyan.ErrorCodes.JOB_CONTEXT_NOT_DEFINED',
    defaultMessage: 'Job context not defined.',
  },
  FAILED_TO_SERIALIZE_DATA: {
    id: 'Banyan.ErrorCodes.FAILED_TO_SERIALIZE_DATA',
    defaultMessage: 'Failed to serialize data.',
  },
  FAILED_TO_DESERIALIZE_DATA: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DESERIALIZE_DATA',
    FAdefaultMessage: 'Failed to deserialize data.',
  },
  ERROR_WRITING_ACTUAL_ID_AND_MODEL_ID_TO_DB: {
    id: 'Banyan.ErrorCodes.ERROR_WRITING_ACTUAL_ID_AND_MODEL_ID_TO_DB',
    defaultMessage: 'Could not write the model id and actual id to DB.',
  },
  FAILED_TO_FIND_COMMAND_FOR_JOB: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FIND_COMMAND_FOR_JOB',
    defaultMessage: 'Failed to find commands associated with job {0}.',
  },
  FAILED_TO_FETCH_PROFILE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILE',
    defaultMessage: 'Failed to fetch user profile {0}.',
  },
  ORG_HIERARCHY_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.ORG_HIERARCHY_NOT_FOUND',
    defaultMessage: 'Could not get the org hierarchy for org {0}.',
  },
  FAILED_TO_FETCH_PRODUCTS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PRODUCTS',
    defaultMessage: 'Failed to fetch products for {0}.',
  },
  FAILED_TO_FETCH_PRODUCTS_FOR_ORGS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PRODUCTS_FOR_ORGS',
    defaultMessage: 'Failed to fetch products for orgs.',
  },
  FAILED_TO_FETCH_PROFILE_RESOURCE_OPTIONS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILE_RESOURCE_OPTIONS',
    defaultMessage: 'Could not get profile resource options for product {0} of org {1}.',
  },
  FAILED_TO_FETCH_PROFILE_FOR_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILE_FOR_PRODUCT',
    defaultMessage: 'Failed to fetch profiles for product {0} of org {1}.',
  },
  FAILED_TO_FETCH_PROFILES_FOR_PRODUCTS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILES_FOR_PRODUCTS',
    defaultMessage: 'Failed to fetch profiles for multiple products of org {0}',
  },
  FAILED_TO_FETCH_PROFILE_ADMINS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILE_ADMINS',
    defaultMessage: 'Failed to fetch admins for product {0}, license group {1}, org {2}.',
  },
  FAILED_TO_FETCH_USER_GROUPS_FOR_PROFILE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_USER_GROUPS_FOR_PROFILE',
    defaultMessage: 'Failed to fetch user group for product {0}, license group {1}, org {2}.',
  },
  FAILED_TO_FETCH_USER_GROUPS_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_USER_GROUPS_FOR_ORG',
    defaultMessage: 'Failed to fetch user groups for org {0}.',
  },
  FAILED_TO_FETCH_PROFILES_FOR_USER_GROUP: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILES_FOR_USER_GROUP',
    defaultMessage: 'Failed to fetch profiles for user group {0} for org {1}',
  },
  FAILED_TO_FETCH_PROFILES_FOR_USER_GROUPS_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PROFILES_FOR_USER_GROUPS_FOR_ORG',
    defaultMessage: 'Failed to fetch profiles for user groups for org {0}',
  },
  FAILED_TO_FETCH_ADMINS_FOR_USER_GROUPS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_ADMINS_FOR_USER_GROUPS',
    defaultMessage: 'Failed to fetch admins for user group {0}, org {1}.',
  },
  FAILED_TO_FETCH_ADMINS_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_ADMINS_FOR_ORG',
    defaultMessage: 'Failed to fetch admins for org {0}.',
  },
  FAILED_TO_FETCH_DOMAINS_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_DOMAINS_FOR_ORG',
    defaultMessage: 'Failed to fetch domains for org {0}.',
  },
  FAILED_TO_FETCH_COUNTS_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_COUNTS_FOR_ORG',
    defaultMessage: 'Failed to fetch counts for org {0}.',
  },
  EMAIL_NOT_SAME_AS_AUTH_TOKEN: {
    id: 'Banyan.ErrorCodes.EMAIL_NOT_SAME_AS_AUTH_TOKEN',
    defaultMessage: 'Owner email not same as auth token.',
  },
  UNSUPPORTED_PARAMETERS_FOR_JOBS_API: {
    id: 'Banyan.ErrorCodes.UNSUPPORTED_PARAMETERS_FOR_JOBS_API',
    defaultMessage: 'Unsupported combination of parameters in /Jobs api.',
  },
  FAILED_TO_RETRIEVE_JOBS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_RETRIEVE_JOBS',
    defaultMessage: 'Failed to retrieve jobs for {0}.',
  },
  BAD_JOB_STATE: {
    id: 'Banyan.ErrorCodes.BAD_JOB_STATE',
    defaultMessage: 'Bad job state!',
  },
  FAILED_TO_FETCH_USER_PROFILES: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_USER_PROFILES',
    defaultMessage: 'Failed to fetch the user profile',
  },
  DRYRUN_UNEXPECTED_VALUE: {
    id: 'Banyan.ErrorCodes.DRYRUN_UNEXPECTED_VALUE',
    defaultMessage: 'Expected value of dryRun to be boolean.',
  },
  FAILED_TO_START_JOB: {
    id: 'Banyan.ErrorCodes.FAILED_TO_START_JOB',
    defaultMessage: 'Unable to start job.',
  },
  NO_COMMANDS_TO_EXECUTE: {
    id: 'Banyan.ErrorCodes.NO_COMMANDS_TO_EXECUTE',
    defaultMessage: 'No commands to execute.',
  },
  CREATING_JOB_FAILED: {
    id: 'Banyan.ErrorCodes.CREATING_JOB_FAILED',
    defaultMessage: 'Creating job failed.',
  },
  CREATING_JOB_FAILED_DUE_TO_DB: {
    id: 'Banyan.ErrorCodes.CREATING_JOB_FAILED_DUE_TO_DB',
    defaultMessage: 'Creating job failed due to a database issue.',
  },
  UNEXPECTED_NULL_VALUE: {
    id: 'Banyan.ErrorCodes.UNEXPECTED_NULL_VALUE',
    defaultMessage: 'Missing value for {0} for {1} in {2}',
  },
  UNEXPECTED_STRING_VALUE: {
    id: 'Banyan.ErrorCodes.UNEXPECTED_STRING_VALUE',
    defaultMessage: 'Expected String value for {0}.',
  },
  ARG_NOT_IN_EXPECTED_FORMAT: {
    id: 'Banyan.ErrorCodes.ARG_NOT_IN_EXPECTED_FORMAT',
    defaultMessage: '{0} not in the expected format.',
  },
  EXPECTED_VALUE_TO_BE_AN_ARRAY: {
    id: 'Banyan.ErrorCodes.EXPECTED_VALUE_TO_BE_AN_ARRAY',
    defaultMessage: 'Expected {0} to be an array.',
  },
  MISSING_VALUE_FOR_KEY: {
    id: 'Banyan.ErrorCodes.MISSING_VALUE_FOR_KEY',
    defaultMessage: 'Missing value for {0}.',
  },
  FAILED_TO_RETRIEVE_JOB_INFO: {
    id: 'Banyan.ErrorCodes.FAILED_TO_RETRIEVE_JOB_INFO',
    defaultMessage: 'Unable to retrieve job information for {0}',
  },
  INVALID_FIRST_INDEX: {
    id: 'Banyan.ErrorCodes.INVALID_FIRST_INDEX',
    defaultMessage: 'first_index must be > 0, but is {0}.',
  },
  INVALID_MAX_VALUE: {
    id: 'Banyan.ErrorCodes.INVALID_MAX_VALUE',
    defaultMessage: 'max_values must be > 0, but is {0}.',
  },
  JOB_ID_NOT_DEFINED: {
    id: 'Banyan.ErrorCodes.JOB_ID_NOT_DEFINED',
    defaultMessage: 'JobId must be defined.',
  },
  JOB_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.JOB_NOT_FOUND',
    defaultMessage: 'Job {0} not found.',
  },
  COMMANDS_NOT_FOUND_FOR_DIFF_JOB: {
    id: 'Banyan.ErrorCodes.COMMANDS_NOT_FOUND_FOR_DIFF_JOB',
    defaultMessage: 'Did not find commands for diff job in DB.',
  },
  FAILED_TO_RETRIEVE_JOB_IN_PROGRESS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_RETRIEVE_JOB_IN_PROGRESS',
    defaultMessage: 'Failed to retrieve job in progress for job id {0}.',
  },
  FAILED_TO_VALIDATE_USER_AUTHORIZATION: {
    id: 'Banyan.ErrorCodes.FAILED_TO_VALIDATE_USER_AUTHORIZATION',
    defaultMessage: 'Failed to validate user authorization.',
  },
  FAILED_TO_RETRIEVE_ORGS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_RETRIEVE_ORGS',
    defaultMessage: 'Could not retrieve organizations.',
  },
  FAILED_TO_FETCH_ORG_DETAILS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_ORG_DETAILS',
    defaultMessage: 'Failed to get org details for org {0}.',
  },
  FAILED_TO_FETCH_ORG_READ_ONLY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_ORG_READ_ONLY',
    defaultMessage: 'Failed to get org read only data for org {0} due to {1}',
  },
  FAILED_TO_VALIDATE_ALLOCATED_PRODUCTS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_VALIDATE_ALLOCATED_PRODUCTS',
    defaultMessage: 'Failed to determine if org has allocated products or not.',
  },
  INVALID_ORG_ID_FORMAT: {
    id: 'Banyan.ErrorCodes.INVALID_ORG_ID_FORMAT',
    defaultMessage: 'orgId must be of the form id@AdobeOrg.',
  },
  ARG_NAME_NULL: {
    id: 'Banyan.ErrorCodes.ARG_NAME_NULL',
    defaultMessage: '{0} is required, but is null.',
  },
  MISSING_FIELD: {
    id: 'Banyan.ErrorCodes.MISSING_FIELD',
    defaultMessage: '{0} is required, but is missing.',
  },
  FIELD_NAME_SET_TO_MODEL_ID: {
    id: 'Banyan.ErrorCodes.FIELD_NAME_SET_TO_MODEL_ID',
    defaultMessage: '{0} is required, but is set to a model id.',
  },
  PRODUCT_CREATION_FAILED_DUE_TO_MISSING_LICENSE_RESOURCE_QUANTITY: {
    id: 'Banyan.ErrorCodes.PRODUCT_CREATION_FAILED_DUE_TO_MISSING_LICENSE_RESOURCE_QUANTITY',
    defaultMessage: 'Could not find license resource quantity while creating new product.',
  },
  FAILED_TO_CREATE_BUSINESS_ID_ACCOUNT: {
    id: 'Banyan.ErrorCodes.CREATING_TYPE2E_ACCOUNT_FAILED',
    defaultMessage: 'Error creating Business ID account.',
  },
  ORG_ALREADY_EXISTS: {
    id: 'Banyan.ErrorCodes.ORG_ALREADY_EXISTS',
    defaultMessage: 'Org {0} already exists.',
  },
  INVALID_REQUEST_METHOD: {
    id: 'Banyan.ErrorCodes.INVALID_REQUEST_METHOD',
    defaultMessage: 'Method {0} is not supported.',
  },
  ARG_NAME_REQUIRED: {
    id: 'Banyan.ErrorCodes.ARG_NAME_REQUIRED',
    defaultMessage: '{0} is required.',
  },
  // This can be removed in 30 days after the old jobs get purged
  // after job warning messages PR gets merged
  ADDING_ADMIN_FAILED_DUE_TO_CONFLICTING_USER_TYPE: {
    id: 'Banyan.ErrorCodes.ADDING_ADMIN_FAILED_DUE_TO_CONFLICTING_USER_TYPE',
    defaultMessage: 'Existing admin user of type {0} cannot be changed to type {1}.',
  },
  ADDED_ADMIN_WITH_CONFLICTING_USER_TYPE: {
    id: 'Banyan.ErrorCodes.ADDED_ADMIN_WITH_CONFLICTING_USER_TYPE',
    defaultMessage: 'Admin was added with user type {0} instead of {1}',
  },
  ADMIN_HAS_INVALID_OPERATIONS: {
    id: 'Banyan.ErrorCodes.ADMIN_HAS_INVALID_OPERATIONS',
    defaultMessage: 'Operations for admin {0} with user type {1} must only be ADD, REMOVE, or blank.',
  },
  CREATE_ROLE_ASSIGNMENT_FAILURE: {
    id: 'Banyan.ErrorCodes.CREATE_ROLE_ASSIGNMENT_FAILURE',
    defaultMessage: 'New Org Setup Failed: createRoleAssignment: {0}.',
  },
  FAILED_TO_ADD_GLOBAL_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_GLOBAL_ADMIN',
    defaultMessage: 'Adding new Global Admin failed: {0}.',
  },
  FAILED_TO_REMOVE_PARENT_CHILD_RELATIONSHIP: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REMOVE_PARENT_CHILD_RELATIONSHIP',
    defaultMessage: 'Failed to remove parent-child relationship.',
  },
  FAILED_TO_ADD_SYSTEM_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ADD_SYSTEM_ADMIN',
    defaultMessage: 'Adding new System Admin failed.',
  },
  INVALID_ORG_NAME: {
    id: 'Banyan.ErrorCodes.INVALID_ORG_NAME',
    defaultMessage: 'Invalid org name {0}.',
  },
  FAILED_TO_CREATE_PARENT_CHILD_RELATIONSHIP: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_PARENT_CHILD_RELATIONSHIP',
    defaultMessage: 'Failed to create parent-child relationship.',
  },
  CHILD_ORG_ALREADY_CHILD_OF_DIFFERENT_ORG: {
    id: 'Banyan.ErrorCodes.CHILD_ORG_ALREADY_CHILD_OF_DIFFERENT_ORG',
    defaultMessage: 'Child org {0} is already a child of another org.',
  },
  CHILD_ALREADY_ANCESTOR_OF_DESIRED_PARENT: {
    id: 'Banyan.ErrorCodes.CHILD_ALREADY_ANCESTOR_OF_DESIRED_PARENT',
    defaultMessage: 'Child is already an ancestor of the desired parent.',
  },
  ENROLLING_ENTITY_OF_CHILD_AND_PARENT_NOT_SAME: {
    id: 'Banyan.ErrorCodes.ENROLLING_ENTITY_OF_CHILD_AND_PARENT_NOT_SAME',
    defaultMessage: 'Enrolling entity of child and parent is not the same.',
  },
  FAILED_TO_GET_CHILDREN_OF_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_GET_CHILDREN_OF_ORG',
    defaultMessage: 'Failed to get children of org {0}.',
  },
  FAILED_TO_DELETE_ORG_BECAUSE_ORG_HAS_CHILDREN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_ORG_BECAUSE_ORG_HAS_CHILDREN',
    defaultMessage: 'Failed to delete org {0} because it still has children referencing it as parent',
  },
  FAILED_TO_CREATE_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ORG',
    defaultMessage: 'Failed to create org {0}.',
  },
  FAILED_TO_GET_ANCESTORS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_GET_ANCESTORS',
    defaultMessage: 'Failed to get ancestors of org {0}.',
  },
  FAILED_TO_GET_ROOT_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_GET_ROOT_ORG',
    defaultMessage: 'Failed to get root of {0}.',
  },
  FAILED_TO_GET_PARENT_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_GET_PARENT_ORG',
    defaultMessage: 'Failed to get parent of {0}.',
  },
  ORG_WITH_PRODUCTS_CANNOT_BE_REPARENTED_TO_NEW_ORG: {
    id: 'Banyan.ErrorCodes.ORG_WITH_PRODUCTS_CANNOT_BE_REPARENTED_TO_NEW_ORG',
    defaultMessage:
      'Org {0} with allocated products cannot be reparented to a newly created org in the same job.  Please run a job to create the org first and then reparent organizations to it.',
  },
  UNABLE_TO_DELETE_PRODUCTS_ON_ORGS_WITH_MANAGE_PRODUCT_POLICY_NOT_ALLOWED_FOR_REPARENT: {
    id: 'Banyan.ErrorCodes.UNABLE_TO_DELETE_PRODUCTS_ON_ORGS_WITH_MANAGE_PRODUCT_POLICY_NOT_ALLOWED_FOR_REPARENT',
    defaultMessage:
      'Unable to delete products on orgs with manage product policy set to Not Allowed for reparent of org {0} with product {1}',
  },
  UNABLE_TO_DELETE_PRODUCTS_WITH_ZERO_GRANT_VALUES_FOR_REPARENT: {
    id: 'Banyan.ErrorCodes.UNABLE_TO_DELETE_PRODUCTS_WITH_ZERO_GRANT_VALUES_FOR_REPARENT',
    defaultMessage:
      'Unable to delete products with 0 grant values for reparent of org {0} with product {1} (Make quantity nonzero, then delete)',
  },
  PRODUCTS_MUST_BE_DELETED_PRIOR_TO_REPARENTING: {
    id: 'Banyan.ErrorCodes.PRODUCTS_MUST_BE_DELETED_PRIOR_TO_REPARENTING',
    defaultMessage: 'Prior to reparenting the following products must be deleted from the orgs to be reparented: {0}',
  },
  UNABLE_TO_ALLOCATE_PRODUCTS_FROM_PARENT_ORG_WITHOUT_GLOBAL_ADMIN_PERMISSION: {
    id: 'Banyan.ErrorCodes.UNABLE_TO_ALLOCATE_PRODUCTS_FROM_PARENT_ORG_WITHOUT_GLOBAL_ADMIN_PERMISSION',
    defaultMessage: 'Cannot allocate products from parent org {0} without global admin permission',
  },
  UNABLE_TO_ALLOCATE_PRODUCTS_TO_ORGS_WITH_MANAGE_PRODUCT_POLICY_NOT_ALLOWED_FOR_REPARENT: {
    id: 'Banyan.ErrorCodes.UNABLE_TO_ALLOCATE_PRODUCTS_TO_ORGS_WITH_MANAGE_PRODUCT_POLICY_NOT_ALLOWED_FOR_REPARENT',
    defaultMessage:
      'Unable to allocate products to orgs with manage product policy set to Not Allowed for reparent of org {0} and product {1} to destination-org {2}',
  },
  ALLOCATING_PRODUCT_CAUSES_OVERALLOCATION_FOR_REPARENT: {
    id: 'Banyan.ErrorCodes.ALLOCATING_PRODUCT_CAUSES_OVERALLOCATION_FOR_REPARENT',
    defaultMessage:
      'Allocating product causes over-allocation between org {0} and source org {1} with source product {2} for reparent of org {3} and product {4} to destination-org {5}',
  },
  UNABLE_TO_ALLOCATE_PRODUCT_TO_ORG_WITH_CONFLICTING_PRODUCT: {
    id: 'Banyan.ErrorCodes.UNABLE_TO_ALLOCATE_PRODUCT_TO_ORG_WITH_CONFLICTING_PRODUCT',
    defaultMessage: "Unable to allocate ''{0}'' to org ''{1}'' because it contains a conflicting product ''{2}''",
  },
  REPARENTING_PRODUCT_CAUSES_OVER_ALLOCATION_WITH_DESTINATION: {
    id: 'Banyan.ErrorCodes.REPARENTING_PRODUCT_CAUSES_OVER_ALLOCATION_WITH_DESTINATION',
    defaultMessage:
      'Re-parenting org {0} with product {1} causes over-allocation with destination-org {2} with product {3}',
  },
  FAILED_TO_READ_ORG_POLICIES: {
    id: 'Banyan.ErrorCodes.FAILED_TO_READ_ORG_POLICIES',
    defaultMessage: 'Failed to read org policies: {0}',
  },
  ARGUMENT_INVALID: {
    id: 'Banyan.ErrorCodes.ARGUMENT_INVALID',
    defaultMessage: '{0} is invalid.',
  },
  INVALID_ENTITY_IN_UDS_DOCUMENT: {
    id: 'Banyan.ErrorCodes.INVALID_ENTITY_IN_UDS_DOCUMENT',
    defaultMessage: '{0} should be same as ownerEntity in UDSDocument.',
  },
  INVALID_DOC_TYPE: {
    id: 'Banyan.ErrorCodes.INVALID_DOC_TYPE',
    defaultMessage: '{0} should be one of supported types - {1}.',
  },
  INVALID_JSON_STRUCTURE_FOR_ORIGINAL_ORG: {
    id: 'Banyan.ErrorCodes.INVALID_JSON_STRUCTURE_FOR_ORIGINAL_ORG',
    defaultMessage: 'Value of originalOrg is not in the expected json structure.',
  },
  INVALID_JSON_STRUCTURE_FOR_UPDATED_ORG: {
    id: 'Banyan.ErrorCodes.INVALID_JSON_STRUCTURE_FOR_UPDATED_ORG',
    defaultMessage: 'Value of updatedOrg is not in the expected json structure.',
  },
  INCORRECT_VALUE_FORMAT: {
    id: 'Banyan.ErrorCodes.INCORRECT_VALUE_FORMAT',
    defaultMessage: 'Value of updates is not in the right format.',
  },
  FAILED_TO_EXECUTE_COMMAND: {
    id: 'Banyan.ErrorCodes.FAILED_TO_EXECUTE_COMMAND',
    defaultMessage: 'Error executing the command.',
  },
  FAILED_TO_ALLOCATE_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ALLOCATE_PRODUCT',
    defaultMessage: 'Error allocating the product.',
  },
  FAILED_TO_DELETE_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_PRODUCT',
    defaultMessage: 'Error deleting the product.',
  },
  ALLOCATION_TIME_LIMIT_EXCEEDED: {
    id: 'Banyan.ErrorCodes.ALLOCATION_TIME_LIMIT_EXCEEDED',
    defaultMessage: 'Allocation time limit exceeded.',
  },
  JOB_ABANDONED_MULTIPLE_TIMES: {
    id: 'Banyan.ErrorCodes.JOB_ABANDONED_MULTIPLE_TIMES',
    defaultMessage: 'The job was abandoned more than {0} times.',
  },
  FAILED_TO_EXECUTE_JOB: {
    id: 'Banyan.ErrorCodes.FAILED_TO_EXECUTE_JOB',
    defaultMessage: 'Error running the job.',
  },
  ERROR_RETRIEVING_SERVICE_TOKEN_TO_PUBLISH_MESSAGE_TO_HOOLIHAN: {
    id: 'Banyan.ErrorCodes.ERROR_RETRIEVING_SERVICE_TOKEN_TO_PUBLISH_MESSAGE_TO_HOOLIHAN',
    defaultMessage: 'Error while retrieving service token to publish message to hoolihan: {0}',
  },
  ERROR_PUBLISHING_MESSAGE_TO_HOOLIHAN: {
    id: 'Banyan.ErrorCodes.ERROR_PUBLISHING_MESSAGE_TO_HOOLIHAN',
    defaultMessage: 'Error while publishing message to hoolihan: {0}.',
  },
  ERROR_CONVERTING_TO_JSON_TO_PUBLISH_MESSAGE_TO_HOOLIHAN: {
    id: 'Banyan.ErrorCodes.ERROR_CONVERTING_TO_JSON_TO_PUBLISH_MESSAGE_TO_HOOLIHAN',
    defaultMessage: 'Error while converting message to JSON string to publish message to hoolihan: {0}',
  },
  FAILED_TO_FETCH_ROOT_ORG_DETAILS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_ROOT_ORG_DETAILS',
    defaultMessage: 'Failed to fetch root org details for org {0}.',
  },
  UNAUTHORIZED_TO_GET_ROOT_ORG_DETAILS: {
    id: 'Banyan.ErrorCodes.UNAUTHORIZED_TO_GET_ROOT_ORG_DETAILS',
    defaultMessage: 'User unauthorized to access root org details.',
  },
  FAILED_TO_FETCH_PARENT_ORG_IDS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_PARENT_ORG_IDS',
    defaultMessage: 'Failed to fetch parent org IDs',
  },
  FAILED_TO_VALIDATE_READ_ONLY_ORGS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_VALIDATE_READ_ONLY_ORGS',
    defaultMessage: 'Failed to validate read-only orgs',
  },
  FAILED_TO_VALIDATE_REPARENT_INVOLVING_PRODUCTS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_VALIDATE_REPARENT_INVOLVING_PRODUCTS',
    defaultMessage: 'Failed to validate reparent involving products',
  },
  FAILED_TO_FETCH_ORG_INFO: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FETCH_ORG_INFO',
    defaultMessage: 'Failed to fetch org info',
  },
  FAILED_TO_CREATE_ADMIN_DUE_TO_INVALID_EMAIL_ADDRESS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ADMIN_DUE_TO_INVALID_EMAIL_ADDRESS',
    defaultMessage: 'Failed to create admin "{0}" because email address is invalid',
  },
  FAILED_TO_REMOVE_OWN_ORGADMIN_ROLE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REMOVE_OWN_ORGADMIN_ROLE',
    defaultMessage: 'Failed to remove admin {0} because an admin cannot delete own admin role.',
  },
  FAILED_TO_CREATE_USER_GROUP_DUE_TO_DUPLICATE_USER_GROUP_NAME: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_USER_GROUP_DUE_TO_DUPLICATE_USER_GROUP_NAME',
    defaultMessage: 'Failed to create user group {0} due to duplicate user group name',
  },
  FAILED_TO_CREATE_ADMIN_DUE_TO_INVALID_COUNTRY_CODE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ADMIN_DUE_TO_INVALID_COUNTRY_CODE',
    defaultMessage: 'Failed to create admin {0} due to invalid country code',
  },
  FAILED_TO_CREATE_ADMIN_DUE_TO_DOMAIN_ENFORCEMENT_VIOLATION: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ADMIN_DUE_TO_DOMAIN_ENFORCEMENT_VIOLATION',
    defaultMessage:
      'The organization who owns the domain "{0}", does not allow creation of Adobe ID accounts with this domain.',
  },
  FAILED_TO_CREATE_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ADMIN',
    defaultMessage: 'Failed to create admin {0}',
  },
  UNAUTHORIZED_TO_ACCESS_DATA: {
    id: 'Banyan.ErrorCodes.UNAUTHORIZED_TO_ACCESS_DATA',
    defaultMessage: 'Unauthorized to access data.',
  },
  FAILED_TO_CREATE_ORG_DUE_TO_INVALID_ORG_NAME: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ORG_DUE_TO_INVALID_ORG_NAME',
    defaultMessage: 'Failed to create org {0} due to invalid org name.',
  },
  ORG_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.ORG_NOT_FOUND',
    defaultMessage: 'Org with id {0} not found',
  },
  ORG_DOES_NOT_SUPPORT_ALLOCATIONS: {
    id: 'Banyan.ErrorCodes.ORG_DOES_NOT_SUPPORT_ALLOCATIONS',
    defaultMessage: 'Org {0} cannot participate in product allocation.',
  },
  ORG_DOES_NOT_SUPPORT_TEMPLATES: {
    id: 'Banyan.ErrorCodes.ORG_DOES_NOT_SUPPORT_TEMPLATES',
    defaultMessage: 'Org {0} cannot participate in template management.',
  },
  REPARENT_DESTINATION_HAS_ORG_WITH_SAME_NAME: {
    id: 'Banyan.ErrorCodes.REPARENT_DESTINATION_HAS_ORG_WITH_SAME_NAME',
    defaultMessage: 'Reparent destination has org with same name {0}.',
  },
  ORG_NAME_IS_NOT_GLOBALLY_UNIQUE: {
    id: 'Banyan.ErrorCodes.ORG_NAME_IS_NOT_GLOBALLY_UNIQUE',
    defaultMessage: 'Org name {0} is not globally unique.',
  },
  COULD_NOT_UPDATE_ORG_PROPERTIES: {
    id: 'Banyan.ErrorCodes.COULD_NOT_UPDATE_ORG_PROPERTIES',
    defaultMessage: 'Could not update properties on org {0}.',
  },
  COULD_NOT_BREAK_CHILD_PARENT_RELATIONSHIP: {
    id: 'Banyan.ErrorCodes.COULD_NOT_BREAK_CHILD_PARENT_RELATIONSHIP',
    defaultMessage: 'Could not break relationship between org ({0}) and its current parent ({1}).',
  },
  COULD_NOT_MAKE_CHILD_PARENT_RELATIONSHIP: {
    id: 'Banyan.ErrorCodes.COULD_NOT_MAKE_CHILD_PARENT_RELATIONSHIP',
    defaultMessage: 'Could not make org {0} a child of {1}.',
  },
  FAILED_TO_CREATE_COMMAND_FOR_DELETE_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_COMMAND_FOR_DELETE_ORG',
    defaultMessage: 'Failed to create command for deleting organization with id {0}',
  },
  FAILED_TO_CREATE_COMMAND_FOR_UPDATE_ORG: {
    // TODO: remove this error code after Dec 3rd. (After jobs with such failure have been purged).
    // Backend was removed under https://git.corp.adobe.com/Project-Banyan/banyansvc/pull/948
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_COMMAND_FOR_UPDATE_ORG  ',
    defaultMessage: ' Failed to create command for updating organization with id {0}',
  },
  MAX_ORGS_PER_HIERARCHY_REACHED: {
    id: 'Banyan.ErrorCodes.MAX_ORGS_PER_HIERARCHY_REACHED',
    defaultMessage: 'Org hierarchy cannot have more than {0} orgs. Current size is {1} orgs.',
  },
  MAX_DIRECT_CHILD_ORGS_PER_PARENT_REACHED: {
    id: 'Banyan.ErrorCodes.MAX_DIRECT_CHILD_ORGS_PER_PARENT_REACHED',
    defaultMessage:
      'Parent org {0} cannot have more than {1} direct child orgs.  Current number of children is {2} orgs.',
  },
  MAX_DEPTH_PER_HIERARCHY_REACHED: {
    id: 'Banyan.ErrorCodes.MAX_DEPTH_PER_HIERARCHY_REACHED',
    defaultMessage: 'Org hierarchy cannot have a depth exceeding {0} orgs. New depth would have been {1} orgs.',
  },
  FAILED_TO_REPARENT_AS_PARENT_ORG_IS_READ_ONLY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_REPARENT_AS_PARENT_ORG_IS_READ_ONLY  ',
    defaultMessage: 'Changing parent failed because the new parent org {0} cannot have children.',
  },
  FAILED_TO_READ_ORG_DATA: {
    id: 'Banyan.ErrorCodes.FAILED_TO_READ_ORG_DATA',
    defaultMessage: 'Failed to read organization data',
  },
  REPARENT_COMMANDS_ARE_MUTUALLY_EXCLUSIVE: {
    id: 'Banyan.ErrorCodes.REPARENT_COMMANDS_ARE_MUTUALLY_EXCLUSIVE',
    defaultMessage: 'Jobs may not contain reparent changes with other types of changes.',
  },
  ELEMENT_ID_NOT_FOUND_IN_COMMAND: {
    id: 'Banyan.ErrorCodes.ELEMENT_ID_NOT_FOUND_IN_COMMAND',
    defaultMessage: 'No element id found in the command',
  },
  CYCLIC_DEPENDENCY_FOUND_IN_COMMAND_LIST: {
    id: 'Banyan.ErrorCodes.CYCLIC_DEPENDENCY_FOUND_IN_COMMAND_LIST',
    defaultMessage: 'Cyclic dependency found in command list',
  },
  INVALID_COMMAND: {
    id: 'Banyan.ErrorCodes.INVALID_COMMAND',
    defaultMessage: 'Operation {0} is not allowed on {1}',
  },
  INVALID_COMMAND_DATA: {
    id: 'Banyan.ErrorCodes.INVALID_COMMAND_DATA',
    defaultMessage: 'Field "{0}" of {1} is invalid',
  },
  NOT_RUN_DUE_TO_ERROR_IN_DEPENDENT_COMMAND: {
    id: 'Banyan.ErrorCodes.NOT_RUN_DUE_TO_ERROR_IN_DEPENDENT_COMMAND',
    defaultMessage: 'Not run due to error in command {0}',
  },
  FAILED_TO_SET_SECURITY_CONTACTS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_SET_SECURITY_CONTACTS',
    defaultMessage: 'Failed to set security contacts for org {0}',
  },
  FAILED_TO_SET_AUTH_SETTINGS: {
    id: 'Banyan.ErrorCodes.FAILED_TO_SET_AUTH_SETTINGS',
    defaultMessage:
      'Failed to set password requirements (part of Authentication Settings in Admin Console) for org {0}',
  },
  // NOTE: This code can be removed 30 days after https://git.corp.adobe.com/Project-Banyan/banyansvc/pull/1569 is merged
  FAILED_TO_SET_ASSET_SHARING_POLICY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_SET_ASSET_SHARING_POLICY',
    defaultMessage: 'Failed to set asset sharing restrictions policy in Admin Console for org {0}',
  },
  FAILED_TO_SET_ASSET_SHARING_OR_WORKSPACE_POLICY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_SET_ASSET_SHARING_OR_WORKSPACE_POLICY',
    defaultMessage: 'Failed to set asset sharing restrictions or workspace policy in Admin Console for org {0}',
  },
  FAILED_TO_INHERIT_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_INHERIT_ADMIN',
    defaultMessage: 'Failed to inherit admin with email {0} as {1}',
  },
  FAILED_TO_INHERIT_ALL_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_INHERIT_ALL_ADMIN',
    defaultMessage: 'Failed to inherit all admins',
  },
  FAILED_CONTRACT_SWITCH_ELIGIBILITY_CHECK: {
    id: 'Banyan.ErrorCodes.FAILED_CONTRACT_SWITCH_ELIGIBILITY_CHECK',
    defaultMessage: 'Failed to check ETLA to Allocation contract switch eligibility for org {0}',
  },
  ORG_HAS_MULTIPLE_ETLA_CONTRACTS: {
    id: 'Banyan.ErrorCodes.ORG_HAS_MULTIPLE_ETLA_CONTRACTS',
    defaultMessage: 'Child org {0} has multiple ETLA contracts and hence is not eligible for contract switch.',
  },
  CONTRACT_MISSING_FOR_ID: {
    id: 'Banyan.ErrorCodes.CONTRACT_MISSING_FOR_ID',
    defaultMessage: 'Contract missing for ID {0}',
  },
  FAILED_TO_FIND_PURCHASED_PRODUCT: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FIND_PURCHASED_PRODUCT',
    defaultMessage: 'Failed to find purchase information',
  },
  ETLA_CONTRACT_MISSING_ON_CHILD_ORG: {
    id: 'Banyan.ErrorCodes.ETLA_CONTRACT_MISSING_ON_CHILD_ORG',
    defaultMessage: 'Org {0} does not have an ETLA contract',
  },
  ETLA_CONTRACT_MISSING_ON_PURCHASE_ORG: {
    id: 'Banyan.ErrorCodes.ETLA_CONTRACT_MISSING_ON_PURCHASE_ORG',
    defaultMessage: 'Purchase org {0} does not have an ETLA contract',
  },
  ORG_HAS_NON_REDISTRIBUTABLE_PRODUCTS: {
    id: 'Banyan.ErrorCodes.ORG_HAS_NON_REDISTRIBUTABLE_PRODUCTS',
    defaultMessage: 'Child org {0} has some non-redistributable products and hence is not eligible for Contract Switch',
  },
  CONTRACT_TERM_START_DATES_DO_NOT_MATCH_FOR_CHECK_ELIGIBILITY: {
    id: 'Banyan.ErrorCodes.CONTRACT_TERM_START_DATES_DO_NOT_MATCH_FOR_CHECK_ELIGIBILITY',
    defaultMessage: 'Start dates {0} and {1} do not match and hence org {2} is not eligible for contract switch',
  },
  CONTRACT_TERM_END_DATES_DO_NOT_MATCH_FOR_CHECK_ELIGIBILITY: {
    id: 'Banyan.ErrorCodes.CONTRACT_TERM_END_DATES_DO_NOT_MATCH_FOR_CHECK_ELIGIBILITY',
    defaultMessage: 'End dates {0} and {1} do not match and hence org {2} is not eligible for contract switch',
  },
  FAILED_TO_PARSE_TERM_DATES_FOR_CHECK_ELIGIBILITY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_PARSE_TERM_DATES_FOR_CHECK_ELIGIBILITY',
    defaultMessage: 'Failed to parse term dates {0} and {1} for child org {2}',
  },
  PARENT_CONTRACT_MISSING_ECC_IDS_FROM_CHILD_CONTRACT: {
    id: 'Banyan.ErrorCodes.PARENT_CONTRACT_MISSING_ECC_IDS_FROM_CHILD_CONTRACT',
    defaultMessage: 'Parent contract does not contain any of these child ECC IDs: {0}',
  },
  PURCHASE_CONTRACT_MISSING_ECC_IDS_FROM_CHILD_CONTRACT: {
    id: 'Banyan.ErrorCodes.PURCHASE_CONTRACT_MISSING_ECC_IDS_FROM_CHILD_CONTRACT',
    defaultMessage: 'Purchase contract does not contain any of these child orgs {0} ECC IDs: {1}',
  },
  LICENSE_HAS_MULTIPLE_PURCHASED_PRODUCTS: {
    id: 'Banyan.ErrorCodes.LICENSE_HAS_MULTIPLE_PURCHASED_PRODUCTS',
    defaultMessage: 'License {0} in org {1} has multiple purchaser orgs and hence is not eligible for contract switch',
  },
  LICENSE_IN_CHILD_ORG_IS_NOT_PRESENT_IN_PARENT_ORG: {
    id: 'Banyan.ErrorCodes.LICENSE_IN_CHILD_ORG_IS_NOT_PRESENT_IN_PARENT_ORG',
    defaultMessage: 'License {0} in child org {1} is not present in parent org {2}',
  },
  LICENSE_IN_CHILD_ORG_IS_NOT_SAME_AS_LICENSE_IN_PARENT_ORG: {
    id: 'Banyan.ErrorCodes.LICENSE_IN_CHILD_ORG_IS_NOT_SAME_AS_LICENSE_IN_PARENT_ORG',
    defaultMessage:
      'Product {0} in child org {1} has a different product configuration than the product {2} in parent org {3}',
  },
  ECC_IDS_MISSING_ON_CHILDS_ETLA_CONTRACT: {
    id: 'Banyan.ErrorCodes.ECC_IDS_MISSING_ON_CHILDS_ETLA_CONTRACT',
    defaultMessage: 'ECC IDs missing on child {0} ETLA contract',
  },
  FAILED_TO_FIND_A_VALID_CONTRACT_IN_PARENT_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_FIND_A_VALID_CONTRACT_IN_PARENT_ORG',
    defaultMessage: 'Failed to find a valid contract in parent org {0}',
  },
  PARENT_ORG_HAS_MULTIPLE_ELIGIBLE_CONTRACT_TO_ALLOCATE_TO_CHILD: {
    id: 'Banyan.ErrorCodes.PARENT_ORG_HAS_MULTIPLE_ELIGIBLE_CONTRACT_TO_ALLOCATE_TO_CHILD',
    defaultMessage: 'Parent org {0} has multiple eligible contract to allocate to child org {1}',
  },
  FAILED_TO_CHECK_ROLLBACK_ELIGIBILITY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CHECK_ROLLBACK_ELIGIBILITY',
    defaultMessage: 'Failed to check rollback eligibility for org {1}',
  },
  ORG_HAS_MULTIPLE_ALLOCATION_CONTRACTS_ELIGIBLE_FOR_ROLLBACK: {
    id: 'Banyan.ErrorCodes.ORG_HAS_MULTIPLE_ALLOCATION_CONTRACTS_ELIGIBLE_FOR_ROLLBACK',
    defaultMessage: 'Org {0} has multiple allocation contracts eligible for rollback',
  },
  ORG_DOES_NOT_HAVE_VALID_CONTRACT_FOR_ROLLBACK: {
    id: 'Banyan.ErrorCodes.ORG_DOES_NOT_HAVE_VALID_CONTRACT_FOR_ROLLBACK',
    defaultMessage: 'Org {0} does not have a valid contract for rollback',
  },
  FAILED_TO_ROLLBACK_ALLOCATION_TO_ETLA_CONTRACT_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_ROLLBACK_ALLOCATION_TO_ETLA_CONTRACT_FOR_ORG',
    defaultMessage: 'Failed to rollback allocation contract to ETLA contract for org {0}',
  },
  FAILED_TO_RETRIEVE_TOKEN_FOR_CONTRACT_CONVERSION_FOR_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_RETRIEVE_TOKEN_FOR_CONTRACT_CONVERSION_FOR_ORG',
    defaultMessage: 'Failed to retrieve required user profile while converting contract for org {0}',
  },
  CHILD_ORG_INFORMATION_MISSING_FOR_ROLLBACK_OF_ALLOCATION_TO_ETLA_CONTRACT: {
    id: 'Banyan.ErrorCodes.CHILD_ORG_INFORMATION_MISSING_FOR_ROLLBACK_OF_ALLOCATION_TO_ETLA_CONTRACT',
    defaultMessage: 'Failed to rollback allocation as org information is missing',
  },
  STANDALONE_ORG_NOT_ELIGIBLE_FOR_ROLLBACK_OF_ALLOCATION_TO_ETLA_CONTRACT: {
    id: 'Banyan.ErrorCodes.STANDALONE_ORG_NOT_ELIGIBLE_FOR_ROLLBACK_OF_ALLOCATION_TO_ETLA_CONTRACT',
    defaultMessage: 'Org {0} does not belong to any hierarchy and is not eligible for Contract Rollback',
  },
  PARENT_CONTRACT_INFORMATION_MISSING_FOR_ROLLBACK_OF_ALLOCATION_TO_ETLA_CONTRACT: {
    id: 'Banyan.ErrorCodes.PARENT_CONTRACT_INFORMATION_MISSING_FOR_ROLLBACK_OF_ALLOCATION_TO_ETLA_CONTRACT',
    defaultMessage: 'Failed to rollback allocation as parent contract information is missing for org {0}',
  },
  CANNOT_ADD_USER_TO_THIS_DOMAIN: {
    id: 'Banyan.ErrorCodes.CANNOT_ADD_USER_TO_THIS_DOMAIN',
    defaultMessage: 'Domain of {0} is not owned or trusted by this org',
  },
  FAILED_TO_CREATE_OR_UPDATE_ADMIN: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_OR_UPDATE_ADMIN',
    defaultMessage: 'Error adding or updating admin with email {0} ({1})',
  },
  FAILED_TO_CREATE_ADMIN_USER_AND_ROLES: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_ADMIN_USER_AND_ROLES',
    defaultMessage: 'Error creating admin user and roles with email {0} ({1})',
  },
  FAILED_TO_UPDATE_ADMIN_ROLES: {
    id: 'Banyan.ErrorCodes.FAILED_TO_UPDATE_ADMIN_ROLES',
    defaultMessage: 'Error updating roles for admin with email {0} ({1})',
  },
  FAILED_TO_DELETE_ALL_ADMIN_ROLE: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_ALL_ADMIN_ROLE',
    defaultMessage: 'Error deleting all roles for admin with email {0} ({1})',
  },
  FAILED_TO_INTEGRATE_PRODUCTS_FOR_ORG_INTO_HIERARCHY: {
    id: 'Banyan.ErrorCodes.FAILED_TO_INTEGRATE_PRODUCTS_FOR_ORG_INTO_HIERARCHY',
    defaultMessage: 'Failed to integrate products of org {0} into hierarchy',
  },
  INVALID_OPERATION: {
    id: 'Banyan.ErrorCodes.INVALID_OPERATION',
    defaultMessage: '{0} is not a valid operation in command {1}',
  },
  ARG_NAME_NULL_IN_INDEX: {
    id: 'Banyan.ErrorCodes.ARG_NAME_NULL_IN_INDEX',
    defaultMessage: '{0} is null in command {1}',
  },
  TOO_MANY_COMMANDS: {
    id: 'Banyan.ErrorCodes.TOO_MANY_COMMANDS',
    defaultMessage: 'Too many commands in a job',
  },
  REQUEST_DATA_TOO_LARGE: {
    id: 'Banyan.ErrorCodes.REQUEST_DATA_TOO_LARGE',
    defaultMessage: 'Request data is too large in command {0}',
  },
  MESSAGE_DATA_TOO_LARGE: {
    id: 'Banyan.ErrorCodes.MESSAGE_DATA_TOO_LARGE',
    defaultMessage: 'Message data is too large in command {0}',
  },
  MISSING_REQUIRED_FIELD: {
    id: 'Banyan.ErrorCodes.MISSING_REQUIRED_FIELD',
    defaultMessage: 'Required field {0} is missing from {1} in command {2}',
  },
  MAPPING_FAILED: {
    id: 'Banyan.ErrorCodes.MAPPING_FAILED',
    defaultMessage: 'Failed to parse data as {0} in command {1}',
  },
  COMMAND_VALIDATION_FAILED: {
    id: 'Banyan.ErrorCodes.COMMAND_VALIDATION_FAILED',
    defaultMessage: 'Command validation failed',
  },
  EXPORT_BUSY_WITH_ANOTHER_EXPORT: {
    id: 'Banyan.ErrorCodes.EXPORT_BUSY_WITH_ANOTHER_EXPORT',
    defaultMessage: 'An export operation could not be started at this time. Please try again in a couple of minutes.',
  },
  ORG_NOT_ELIGIBLE_FOR_PRODUCT_ALLOCATIONS: {
    id: 'Banyan.ErrorCodes.ORG_NOT_ELIGIBLE_FOR_PRODUCT_ALLOCATIONS',
    defaultMessage: 'Org {0} is not eligible for product allocations.',
  },
  FAILED_TO_SHARE_USER_GROUP_TO_TARGET_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_SHARE_USER_GROUP_TO_TARGET_ORG',
    defaultMessage: 'User group with name {0} in source org {1} could not be shared with target org {2}',
  },
  SOURCE_USER_GROUP_NOT_FOUND: {
    id: 'Banyan.ErrorCodes.SOURCE_USER_GROUP_NOT_FOUND',
    defaultMessage: 'User group with name {0} in source org {1} could not be found',
  },
  TARGET_ORG_NOT_DESCENDED_FROM_SOURCE_ORG: {
    id: 'Banyan.ErrorCodes.TARGET_ORG_NOT_DESCENDED_FROM_SOURCE_ORG',
    defaultMessage:
      'User group with name {0} in source org {1} cannot be shared to target org {2} as the target org is not a descendant of the source org',
  },
  FAILED_TO_CREATE_USER_GROUP_SHARE_TO_TARGET_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_CREATE_USER_GROUP_SHARE_TO_TARGET_ORG',
    defaultMessage: 'User group with name {0} in source org {1} could not be shared with target org {2}',
  },
  FAILED_TO_DELETE_USER_GROUP_SHARE_TO_TARGET_ORG: {
    id: 'Banyan.ErrorCodes.FAILED_TO_DELETE_USER_GROUP_SHARE_TO_TARGET_ORG',
    defaultMessage: 'User group with name {0} in source org {1} shared with target org {2} could not be deleted',
  },
  USER_GROUP_SHARE_ALREADY_EXISTS: {
    id: 'Banyan.ErrorCodes.USER_GROUP_SHARE_ALREADY_EXISTS',
    defaultMessage: 'User group with name {0} in source org {1} has already been shared to target org {2}',
  },
  USER_GROUP_SHARE_CONFLICTS: {
    id: 'Banyan.ErrorCodes.USER_GROUP_SHARE_CONFLICTS',
    defaultMessage:
      'User group with name {0} in source org {1} cannot be shared to target org {2} as it conflicts with an existing group',
  },
  USER_GROUP_SHARE_REMOVAL_IN_PROGRESS: {
    id: 'Banyan.ErrorCodes.USER_GROUP_SHARE_REMOVAL_IN_PROGRESS',
    defaultMessage:
      'User group with name {0} in source org {1} shared to target org {2} is in the process of being removed',
  },
  USER_GROUP_SHARING_FEATURE_NOT_AVAILABLE_FOR_ORG: {
    id: 'Banyan.ErrorCodes.USER_GROUP_SHARING_FEATURE_NOT_AVAILABLE_FOR_ORG',
    defaultMessage: 'User group sharing feature is not available for org {0}',
  },
});
