import * as _ from 'lodash';
import { SelectOption } from '@react/react-spectrum/Select';
import React from 'react';
import { defineMessages, IntlProvider, WrappedComponentProps } from 'react-intl';

import { LocaleSettings } from '../../services/locale/LocaleSettings';
import StepControl from './StepControl/StepControl';
import OrgPickerController from '../../services/organization/OrgPickerController';
import HeaderConsts from '../../components/BanyanShell/HeaderConstants';
import SearchOrganizationContent from './SearchOrganizationContent/SearchOrganizationContent';
import { GoUrlKeys } from '../../components/GoUrl/GoUrl';
import SelectOrganization from './SelectOrganization/SelectOrganization';
import { MESSAGES } from '../Messages';
import { CommandService } from '../../services/Commands/CommandService';
import TempIdGenerator from '../../services/utils/TempIdGenerator';
import { Org } from '../../services/organization/Org';
import CommandInterface from '../../services/Commands/CommandInterface';
import OrgHierarchyHelper from '../helpers/OrgHierarchyHelper';
import withRouter, { RouteComponentProps } from '../../services/utils/withRouter';

interface ConvertETLAToAllocationContractInternalProps extends RouteComponentProps, WrappedComponentProps {
  setPath: () => void;
}

interface ConvertETLAToAllocationContractInternalState {
  rootOrg: SelectOption | undefined;
  selectedChildOrgs: Org[]; // orgs which are selected for ETLA to Allocation contract conversion
}

const messages = defineMessages({
  ConvertETLAToAllocationContract: {
    id: 'ConvertETLAToAllocationContract.ConvertETLAToAllocationContractLabel',
    defaultMessage: 'Convert ETLA to Allocation contract',
  },
  ContractSwitchDescription: {
    id: 'ConvertETLAToAllocationContract.ContractSwitchDescription',
    defaultMessage:
      'After an organization is added to the org hierarchy and it has existing products from an ETLA contract, Adobe agents needs to convert the contract type from the ETLA contract to Allocation contract in order to allocate the same products from a parent organization inside Global Admin Console. Make sure the parent and the child orgs have the same ECC ID, EDM ID, and End dates. The child orgs should have the same or subset products as the parent org and those products are redistributable.',
  },
  SelectOrgInfoMessageContractConversion: {
    id: 'ConvertETLAToAllocationContract.SelectOrgInfoMessageContractConversion',
    defaultMessage:
      'Make sure the parent and the child orgs have the same ECC ID, EDM ID, and End dates. The child orgs should have the same or subset products as the parent org and those products are redistributable. You can check eligibility for the org before you convert.',
  },
  SelectOrganizationTitleContractConversion: {
    id: 'ConvertETLAToAllocationContract.SelectOrganizationTitleContractConversion',
    defaultMessage: 'Select organization to convert to allocation',
  },
});

class ConvertETLAToAllocationContractInternal extends React.Component<
  ConvertETLAToAllocationContractInternalProps,
  ConvertETLAToAllocationContractInternalState
> {
  constructor(props: ConvertETLAToAllocationContractInternalProps) {
    super(props);
    this.state = {
      rootOrg: undefined,
      selectedChildOrgs: [],
    };
    this.stepControllerRef = React.createRef();
  }

  // ref for StepController component to directly navigate when an org is selected
  stepControllerRef: any;

  enableNextOrReviewPendingBtn = (step: number): boolean => {
    // on step 0, if an org is not selected, disable the next button
    if (step === 0 && this.state.rootOrg === undefined) {
      return false;
    }
    // on last step, if no eligible org is selected for ETLA to allocation contract conversion, disable 'Review Pending Changes' btn
    if (step === 1 && this.state.selectedChildOrgs.length < 1) {
      return false;
    }
    return true; // otherwise enable the 'review' button
  };

  /**
   * set the root org selected for contracts. This is the org whose hierarchy would be loaded on the next step.
   * NOTE: the function accepts an array of selected orgs as input, but ideally there should only be a single org selected for contracts
   * In case if more than one orgs are selected, only the first org is selected and the other orgs are ignored.
   */
  setRootOrg = (newRootOrg: SelectOption[]): void => {
    if (newRootOrg.length > 0) {
      this.setState({ rootOrg: newRootOrg[0] }, () => {
        // navigate to next step directly once the org is selected
        if (this.stepControllerRef) {
          this.stepControllerRef.current.navigateToNextPage();
        }
      });
    }
  };

  /**
   * clear rootOrg if org is present in 'toBeRemovedOrgs'
   */
  removeRootOrg = (toBeRemovedOrgs: string[]): void => {
    this.setState(
      (
        prevState: ConvertETLAToAllocationContractInternalState
      ): Pick<ConvertETLAToAllocationContractInternalState, never> => {
        let { rootOrg } = prevState;

        // Remove the Orgs that are deselected by the user
        _.forEach(toBeRemovedOrgs, (toBeRemovedOrg: string): void => {
          if (toBeRemovedOrg === rootOrg?.value) {
            rootOrg = undefined;
          }
        });
        return { rootOrg };
      }
    );
  };

  /**
   * add newSelectedOrgs to selectedChildOrgs state. selectedChildOrgs tracks the orgs selected for ETLA to allocation contract conversion
   */
  addSelectedOrgs = (newSelectedOrgs: Org[]): void => {
    this.setState(
      (
        prevState: ConvertETLAToAllocationContractInternalState
      ): Pick<ConvertETLAToAllocationContractInternalState, never> => {
        let { selectedChildOrgs } = prevState;
        selectedChildOrgs.push(...newSelectedOrgs);
        selectedChildOrgs = _.uniqBy(selectedChildOrgs, 'id');
        return {
          selectedChildOrgs,
        };
      }
    );
  };

  /**
   * deselect for orgs for ETLA to allocation contract conversion
   */
  removeSelectedOrgs = (orgsToBeRemoved: Org[]): void => {
    this.setState(
      (
        prevState: ConvertETLAToAllocationContractInternalState
      ): Pick<ConvertETLAToAllocationContractInternalState, never> => {
        const { selectedChildOrgs } = prevState;

        // Remove the Orgs that are deselected by the user
        _.forEach(orgsToBeRemoved, (org: Org): void => {
          _.remove(selectedChildOrgs, { id: org.id });
        });
        return { selectedChildOrgs };
      }
    );
  };

  /**
   * Clear state when back btn is clicked
   */
  onBackBtnClicked = (step: number): void => {
    if (step === 0) {
      this.setState({ rootOrg: undefined, selectedChildOrgs: [] });
    }
  };

  /**
   * NOTE IMPORTANT:
   * Contract Switch works in two phases:
   * 1) Switch ETLA contract to allocation contract
   * 2) Retrofit allocations
   *
   * The UI ONLY generates the Contract Switch command. Retrofit command is dynamically generated by banyan svc.
   */
  submit = (): void => {
    if (this.state.selectedChildOrgs) {
      _.forEach(this.state.selectedChildOrgs, (org: Org): void => {
        const NEW_CONTRACT_ID = TempIdGenerator.getTempIdAndIncrement();

        const contractSwitchCommand: CommandInterface = OrgHierarchyHelper.generateContractSwitchCommand(
          NEW_CONTRACT_ID,
          org.id,
          org.name,
          true
        );
        CommandService.addEditForOrgMapper(contractSwitchCommand);
      });

      this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <StepControl
          pageTitle={formatMessage(messages.ConvertETLAToAllocationContract)}
          hideNextBtn
          enableNextOrReviewPendingBtn={this.enableNextOrReviewPendingBtn}
          backout={this.props.setPath}
          onSubmit={this.submit}
          disallowOrgSelection={false}
          ref={this.stepControllerRef}
          onBackBtnClicked={this.onBackBtnClicked}
        >
          <SearchOrganizationContent
            goUrlKey={GoUrlKeys.contractSwitch}
            goUrlTarget="gac_help"
            infoMessage={formatMessage(messages.ContractSwitchDescription)}
            label={formatMessage(MESSAGES.SearchOrganization)}
            selectedOrgs={this.state.rootOrg ? [this.state.rootOrg] : []}
            addSelectedOrgIds={this.setRootOrg}
            removeSelectedOrgIds={this.removeRootOrg}
            multipleSelection={false}
            disallowOrgSelection={false}
            displaySelectedOrgsAsTagList={false}
            scrollableHeight="20rem"
          />
          <SelectOrganization
            goUrlKey={GoUrlKeys.contractSwitch}
            goUrlTarget="gac_help"
            infoMessage={formatMessage(messages.SelectOrgInfoMessageContractConversion)}
            infoTitle={formatMessage(messages.SelectOrganizationTitleContractConversion)}
            label={formatMessage(MESSAGES.SelectOrganization)}
            rootOrg={this.state.rootOrg}
            selectedOrgs={this.state.selectedChildOrgs}
            allowMultipleSelection
            {...this.props}
            addSelectedOrgs={this.addSelectedOrgs}
            removeSelectedOrgs={this.removeSelectedOrgs}
            isContractSwitchMode
          />
        </StepControl>
      </div>
    );
  }
}

function ConvertETLAToAllocationContract(
  props: Omit<ConvertETLAToAllocationContractInternalProps, 'ref'>
): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ConvertETLAToAllocationContractInternal {...props} />
    </IntlProvider>
  );
}

export default withRouter(ConvertETLAToAllocationContract);
