/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import { ActionButton, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import UsersShareIcon from '@spectrum-icons/workflow/Delegate';
import React from 'react';
import { SharedUserGroupButtonProps } from './types';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  shareUserGroup: {
    id: 'sharedUserGroupButton.button.label',
    defaultMessage: 'Share User Group',
  },
});

function SharedUserGroupButton({ ...props }: SharedUserGroupButtonProps): JSX.Element {
  return (
    <TooltipTrigger delay={0} placement="end">
      <ActionButton
        aria-label={props.intl.formatMessage(messages.shareUserGroup)}
        isQuiet={true}
        data-testid="shared-user-group-button"
      >
        <UsersShareIcon size="XS" />
      </ActionButton>
      <Tooltip variant="info">{props.tooltipText}</Tooltip>
    </TooltipTrigger>
  );
}

export default injectIntl(SharedUserGroupButton);
