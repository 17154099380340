import React, { Component } from 'react';
import * as _ from 'lodash';
import * as log from 'loglevel';
// eslint-disable-next-line import/no-unresolved
import TreeNode from 'primereact/components/treenode/TreeNode';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeicons/primeicons.css';
import Edit from '@react/react-spectrum/Icon/Edit';
import Folder from '@react/react-spectrum/Icon/Folder';
import Search from '@react/react-spectrum/Search';
import DragHandle from '@react/react-spectrum/Icon/DragHandle';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Dialog from '@react/react-spectrum/Dialog';
import Wait from '@react/react-spectrum/Wait';
import Button from '@react/react-spectrum/Button';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { defineMessages, FormattedMessage, injectIntl, IntlProvider, WrappedComponentProps } from 'react-intl';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import LocaleTooltip from '../../components/LocaleTooltip/LocaleTooltip';

import { LoadOrgDataService } from '../../services/orgMaster/LoadOrgDataService';
import './OrgTree.css';
import { ExpandedNodes, ExpandedNodesUtil } from '../../services/treeTableUtils/ExpandedNodesUtil';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import OrgSelectionUtil from '../../services/treeTableUtils/OrgSelectionUtil';
import AdminPermission from '../../services/authentication/AdminPermission';
import Analytics from '../../Analytics/Analytics';
import { CommandService } from '../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from '../../services/orgMaster/OrgMaster';
import ScrollableContent from '../EditCompartment/Widgets/ScrollableContent';
import OrgReparentProductsErrorMessages from '../../components/OrgReparentProductsMessages/OrgReparentProductsErrorMessages';
import OrgReparentService from '../../services/reparent/OrgReparentService';
import OrgReparentProductsCheckService from '../../services/reparent/OrgReparentProductsCheckService';
import { OrgReparentProductsReport, OrgReparentProductsReportUtils } from '../../services/reparent';
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import HierarchyManager from '../../services/organization/HierarchyManager';
import OrgTreeCache from './OrgTreeCache';
import EditCompartment from '../EditCompartment/EditCompartment';
import '../common.css';
import { UOrg } from '../../services/orgMaster/UOrg';
import CmdDescriptionUtils from '../../services/Codes/CmdDescriptionUtils';
import UserGroupSharingService from '../../providers/UserGroupSharingService';

interface OrgTreeProps extends WrappedComponentProps {
  updateCompartmentCallback: () => void;
  selectedOrg: UOrgMaster;
}

interface OrgTreeState {
  orgIdToMove: undefined | string;
  draggedOver: Map<string, boolean>;
  searchOrg: string;
  allowReparenting: boolean;
  checkingParentOrgId: string; // specifies parent org during reparent in order to show the wait circle (empty means no wait circle)
  errorMessage: string;
  orgTreeCache: OrgTreeCache | undefined;
}

const messages = defineMessages({
  search: {
    id: 'OrgTree.SearchOrgs',
    defaultMessage: 'Search',
  },
  reparentOn: { id: 'OrgTree.reparentingOn', defaultMessage: 'Control for reparenting orgs will be switched on' },
  reparentOff: { id: 'OrgTree.reparentingOff', defaultMessage: 'Control for reparenting orgs will be switched off' },
  cannotReparent: { id: 'OrgTree.cannotReparent', defaultMessage: 'Control for reparenting orgs is disabled' },
  cannotReparentMessage: {
    id: 'OrgTree.cannotReparentMessage',
    defaultMessage:
      'Reparenting is disabled as there are other pending changes. First submit the job for pending changes and then reparent orgs.',
  },
  buttonOK: { id: 'OrgTree.OK', defaultMessage: 'OK' },
  buttonCancel: { id: 'OrgTree.Cancel', defaultMessage: 'Cancel' },
  dragOn: {
    id: 'OrgTree.dragOn',
    defaultMessage:
      "To reparent an organization, click on the organization name and drag it to the new parent's organization name.",
  },
  reparentOnNote: {
    id: 'OrgTree.reparentOnNote',
    defaultMessage: 'Note that a job with reparent changes may not contain other types of changes.',
  },
  changeHierarchy: { id: 'OrgTree.ChangeHierarchy', defaultMessage: 'Change hierarchy' },
  saveReparentLabel: { id: 'OrgTree.SaveReparentLabel', defaultMessage: 'Save' },
  restrictReparentProductsDialogMessage: {
    id: 'OrgTree.restrictReparentProductsDialog.message',
    defaultMessage: 'Unable to reparent org if it has allocated products',
  },
  restrictReparentReadOnlyDialogMessage: {
    id: 'OrgTree.restrictReparentReadOnlyDialogMessage.message',
    defaultMessage: 'Unable to reparent org if new parent is read only',
  },
  restrictReparentTopDownSharingDialogMessage: {
    id: 'OrgTree.restrictReparentTopDownSharingDialogMessage.message',
    defaultMessage: 'Unable to reparent org as it violates the top-down user group sharing policy',
  },
  restrictReparentDeleteDialogMessage: {
    id: 'OrgTree.restrictReparentDeleteDialogMessage.message',
    defaultMessage: 'Unable to reparent org if new parent is deleted',
  },
  reparentErrorDialogTitle: {
    id: 'OrgTree.reparentErrorDialogTitle.title',
    defaultMessage: 'Reparent errors',
  },
  reparentProductsWarningDialogTitle: {
    id: 'OrgTree.reparentProductsWarningDialog.title',
    defaultMessage: 'Reparent will delete products',
  },
  reparentErrorDialogConfirm: {
    id: 'OrgTree.reparentErrorDialog.confirm',
    defaultMessage: 'OK',
  },
  reparentProductsWarningDialogConfirm: {
    id: 'OrgTree.reparentProductsWarningDialog.confirm',
    defaultMessage: 'Proceed',
  },
  reparentProductsWarningDialogCancel: {
    id: 'OrgTree.reparentProductsWarningDialog.cancel',
    defaultMessage: 'Cancel',
  },
  loadOrgDataError: {
    id: 'OrgTree.errors.loadOrgData',
    defaultMessage: 'Error loading orgs for display',
  },
  loadOrgVerificationError: {
    id: 'OrgTree.errors.loadOrgVerification',
    defaultMessage: 'An error occurred while validating reparenting request',
  },
});

// This function was static inside the OrgTree class, but react intl can't handle static functions so it was moved out.
function isOrgMoved(orgId: string): boolean {
  const orgEdits = CommandService.getElementEdits(orgId, ObjectTypes.ORGANIZATION);
  if (_.isEmpty(orgEdits) || orgEdits[0].originalElem === undefined) {
    // if there are no update edits on the org return false
    return false;
  }
  const orgToMove: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
  // when org is newly created, then moving it again would not cause a reparent operation
  // therefore, we dont want to show the folder icon when org is created
  if (orgToMove) {
    return !_.isEqual(orgToMove.organization.parentOrgId, (orgEdits[0].originalElem as UOrg).parentOrgId);
  }
  return false;
}

const TREE_TESTID_PREFIX: string = 'treetable_';
const WAIT_PREFIX: string = 'validcheckwait_';

class OrgTree extends Component<OrgTreeProps, OrgTreeState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted
  private errorDialogRef: number | undefined = undefined;
  private searchTimer: NodeJS.Timeout | undefined;
  private static SEARCH_TIMEOUT = 500; // search timeout.

  constructor(props: OrgTreeProps) {
    super(props);
    // expand root org and its children on initial render
    this.state = {
      orgIdToMove: undefined,
      draggedOver: new Map<string, boolean>(),
      searchOrg: '',
      allowReparenting: false,
      checkingParentOrgId: '',
      errorMessage: '',
      orgTreeCache: undefined,
    };
  }

  componentWillUnmount(): void {
    this.abortController.abort();
    if (this.errorDialogRef !== undefined) {
      ModalContainer.hide(this.errorDialogRef);
      this.errorDialogRef = undefined;
    }
  }

  async componentDidMount(): Promise<void> {
    await LoadOrgDataService.initializeOrUpdateOrgMasterTree(); // wait for orgs to be loaded before initializing the orgTreeCache
    this.load();
  }

  componentDidUpdate(): void {
    if (OrgTreeCache.isCleared()) {
      this.load();
    }
  }

  load(): void {
    try {
      if (this.abortController.signal.aborted) return;
      this.setState({ orgTreeCache: OrgTreeCache.get() });
    } catch (error) {
      log.error('failed to load orgs for org tree', error);
      if (this.state.errorMessage !== this.props.intl.formatMessage(messages.loadOrgDataError)) {
        this.setState({ errorMessage: this.props.intl.formatMessage(messages.loadOrgDataError) });
      }
    }
  }

  private onSearch = (searchInput: string): void => {
    Analytics.fireCTAEvent(`org search`);
    this.setState({ searchOrg: searchInput });
    if (this.searchTimer) {
      // clear timeout as the user is still typing
      clearTimeout(this.searchTimer);
    }
    // This time makes sure that filtering is done only after 500ms after the user stops typing
    // This avoid unnecessary intermediate computation
    this.searchTimer = setTimeout(async (): Promise<void> => {
      this.state.orgTreeCache?.filterOrgs(_.trim(searchInput));
      this.state.orgTreeCache?.updateOrgHierarchy();
      this.setState({ orgTreeCache: OrgTreeCache.get(), searchOrg: searchInput });
    }, OrgTree.SEARCH_TIMEOUT); // time is required to fire the call only when the user stops typing
  };

  /**
   * Returns list of ids of all the descendants and the org itself
   *
   * @param organization UOrgMaster
   */
  private getAllDescendantOrgsIds = (organization: UOrgMaster): string[] => {
    const invalidOrgIds: string[] = _.flatMap(organization.getChildren(), (child: UOrgMaster): string[] =>
      this.getAllDescendantOrgsIds(child)
    );
    invalidOrgIds.push(organization.organization.id);
    return invalidOrgIds;
  };

  /**
   * Check if the org move for reparenting is valid.
   * Invalid parent org for the move would be: org itself, currrent parent of the org, all the descendants and
   * any level which has an org with same name
   *
   * NOTE: It is callers responsibility to make sure org details for 'orgToMoveId' and 'newParentId' are loaded
   *
   * @param orgToMoveId
   * @param newParentId
   */
  private isValidMove(orgToMoveId: string | undefined, newParentId: string): boolean {
    if (!orgToMoveId) return false;
    // can not move to the same org
    if (orgToMoveId === newParentId) {
      return false;
    }
    const orgToMove = HierarchyManager.getOrg(orgToMoveId);
    // cannot move non-existent org
    if (!orgToMove) {
      return false;
    }
    const oldParentOrg = orgToMove.getParentOrgMaster();
    // can not move to the same parent
    if (oldParentOrg && newParentId === oldParentOrg.organization.id) {
      return false;
    }
    const newParentOrg = HierarchyManager.getOrg(newParentId);
    // cannot move to non-existent parent and target org cannot a read only org
    if (!newParentOrg) {
      return false;
    }
    const newSiblingNames = _.map(
      newParentOrg.getChildren(),
      (childOrg: UOrgMaster): string => childOrg.organization.name
    );
    // can not move to a level which has an org with similar name
    if (_.includes(newSiblingNames, orgToMove.organization.name)) {
      return false;
    }
    const invalidOrgIds: string[] = this.getAllDescendantOrgsIds(orgToMove);
    return !_.includes(invalidOrgIds, newParentId);
  }

  private deSelectDragOver(newParentId: string): void {
    this.setState(
      // de-select drag-over
      (prevState: OrgTreeState): Pick<OrgTreeState, never> => {
        prevState.draggedOver.set(newParentId, false);
        return { draggedOver: prevState.draggedOver, checkingParentOrgId: '' }; // loading circle should disappear when no longer "dragged over"
      }
    );
  }

  private showErrorDialog(
    variant: 'confirmation' | 'information' | 'destructive' | 'error',
    title: string,
    confirmLabel: string,
    cancelLabel: string | undefined,
    onConfirm: any,
    displayContent: React.ReactNode,
    newParentId: string
  ): void {
    this.deSelectDragOver(newParentId);
    this.errorDialogRef = ModalContainer.show(
      <IntlProvider
        locale={LocaleSettings.getSelectedLanguageTagForProvider()}
        messages={LocaleSettings.getSelectedLocale()}
      >
        <Dialog
          variant={variant}
          title={title}
          confirmLabel={confirmLabel}
          cancelLabel={cancelLabel}
          onConfirm={onConfirm}
          onClose={(): void => {
            ModalContainer.hide(this.errorDialogRef as number);
            this.errorDialogRef = undefined;
          }}
        >
          {displayContent}
        </Dialog>
      </IntlProvider>,
      this
    );
  }

  // Check re-parent products operation.  If true is returned, then proceed with normal re-parent.  If false is returned, prevent normal re-parent
  private async checkReparentProducts(orgToMove: UOrgMaster, newParentId: string): Promise<boolean> {
    const orgToMoveId = orgToMove.organization.id;
    // check that an original version of the org-to-reparent exists, if not, the org is being created and therefore there are no existing products to check for re-parent
    // allow the re-parent to succeed
    if (!HierarchyManager.getOrg(orgToMoveId)) {
      return true;
    }
    let hasProductsToReparent: boolean = false;
    try {
      hasProductsToReparent = await OrgReparentService.orgHasAllocatedProducts(orgToMoveId);
    } catch (error) {
      log.error('failed to load data to determine if org-to-reparent has products');
      this.setState({ errorMessage: this.props.intl.formatMessage(messages.loadOrgVerificationError) });
      return false;
    }
    if (hasProductsToReparent) {
      // Check for errors or delete warnings when re-parenting with products
      if (_.startsWith(newParentId, TEMP_ID_PREFIX)) {
        // Re-parent with products to destination org that hasn't been created yet is not allowed since verification can't be performed
        this.showErrorDialog(
          'error',
          this.props.intl.formatMessage(messages.reparentErrorDialogTitle),
          this.props.intl.formatMessage(messages.reparentErrorDialogConfirm),
          undefined,
          undefined,
          <FormattedMessage
            id="orgReparentProductsErrors.reparentToNewOrg"
            defaultMessage="Org {orgName} ({orgId}) with allocated products cannot be reparented to a newly created org in the same job.  Please run a job to create the org first and then reparent organizations to it."
            values={{
              orgName: orgToMove.organization.name,
              orgId: orgToMove.organization.id,
            }}
          />,
          newParentId
        );
        return false;
      }

      let report: OrgReparentProductsReport | undefined;
      try {
        report = await OrgReparentProductsCheckService.orgReparentProductsCheck(orgToMoveId, newParentId);
      } catch (error) {
        log.error(
          'failed to load data to determine if there are any errors or warnings in re-parenting an org with products'
        );
        this.setState({ errorMessage: this.props.intl.formatMessage(messages.loadOrgVerificationError) });
        return false;
      }
      if (OrgReparentProductsReportUtils.hasErrors(report)) {
        // If re-parent products errors are detected, prevent re-parent and display error dialog
        this.showErrorDialog(
          'error',
          this.props.intl.formatMessage(messages.reparentErrorDialogTitle),
          this.props.intl.formatMessage(messages.reparentErrorDialogConfirm),
          undefined,
          undefined,
          <OrgReparentProductsErrorMessages report={report} />,
          newParentId
        );
        return false;
      }
      // could show 'warnings' here if desired.
    }
    return true;
  }

  private reparentTheOrg(orgToMove: UOrgMaster, newParentId: string): void {
    const oldOrgPathName = CmdDescriptionUtils.getPathname(orgToMove.id);
    HierarchyManager.reparentOrg(HierarchyManager.getOrg(newParentId), orgToMove);
    const originalOrg = _.cloneDeep(orgToMove);
    const newUOrg = _.cloneDeep(orgToMove.organization);
    newUOrg.parentOrgId = newParentId;
    CommandService.addEdit(
      orgToMove,
      newUOrg,
      ObjectTypes.ORGANIZATION,
      OrgOperation.UPDATE,
      originalOrg.organization,
      'REPARENT_ORG',
      [oldOrgPathName, CmdDescriptionUtils.getPathname(newParentId)]
    );
    // expand the new parent (make sure the state is being set after expanding the node, in this case this.setState({draggedOver}) takes care of that)
    ExpandedNodesUtil.expandOrg(newParentId);
    OrgSelectionUtil.updateOrgSelection(orgToMove.id); // select reparented org
    this.deSelectDragOver(newParentId);
    // update OrgMasterTree
    OrgTreeCache.clear(); // update org tree on reparent
    this.props.updateCompartmentCallback();
    /* eslint-enable no-param-reassign */
  }

  private async onOrgDrop(newParentId: string): Promise<void> {
    if (!this.state.orgIdToMove) {
      return;
    }

    // No action, if the org is dropped onto an invalid parent org
    // Invalid parent org includes: the org itself, or any descendant org,
    // or to a level which already has an org with the same name
    if (!this.isValidMove(this.state.orgIdToMove, newParentId)) {
      return;
    }
    const orgToMove = HierarchyManager.getOrg(this.state.orgIdToMove);
    if (!orgToMove) {
      return;
    }

    // load org details for the new parent org to check if its readonly org or not
    this.setState({ checkingParentOrgId: newParentId }); // show loading circle
    try {
      await LoadOrgDataService.loadOrgDetails(newParentId);
    } catch (error) {
      log.error('failed to load details for re-parenting org');
      this.setState({ errorMessage: this.props.intl.formatMessage(messages.loadOrgVerificationError) });
      this.deSelectDragOver(newParentId);
      return;
    }
    const newParentOrg = HierarchyManager.getOrg(newParentId);
    // cannot move to non-existent parent and target org cannot a read only org
    if (!newParentOrg || newParentOrg.isReadOnlyOrg()) {
      this.showErrorDialog(
        'error',
        this.props.intl.formatMessage(messages.reparentErrorDialogTitle),
        this.props.intl.formatMessage(messages.reparentErrorDialogConfirm),
        undefined,
        undefined,
        this.props.intl.formatMessage(messages.restrictReparentReadOnlyDialogMessage),
        newParentId
      );
      return;
    }
    // cannot move to a deleted org
    if (CommandService.isDeleted(newParentId, newParentId)) {
      this.showErrorDialog(
        'error',
        this.props.intl.formatMessage(messages.reparentErrorDialogTitle),
        this.props.intl.formatMessage(messages.reparentErrorDialogConfirm),
        undefined,
        undefined,
        this.props.intl.formatMessage(messages.restrictReparentDeleteDialogMessage),
        newParentId
      );
      return;
    }

    const checkReparentProductsResult: boolean = await this.checkReparentProducts(orgToMove, newParentId);
    if (!checkReparentProductsResult) {
      // if re-parent is cancelled, manually de-select the drag-over org and hide the loading circle (normal re-parent would have handled that for us)
      this.deSelectDragOver(newParentId);
      return;
    }

    const hasSharedUserGroups = await UserGroupSharingService.hasSharedUserGroupsFeature(this.props.selectedOrg.id);
    if (hasSharedUserGroups) {
      const violated = await OrgReparentService.moveViolatesTopDownSharingPolicy(orgToMove, newParentId);
      if (violated) {
        this.deSelectDragOver(newParentId);
        this.showErrorDialog(
          'error',
          this.props.intl.formatMessage(messages.reparentErrorDialogTitle),
          this.props.intl.formatMessage(messages.reparentErrorDialogConfirm),
          undefined,
          undefined,
          this.props.intl.formatMessage(messages.restrictReparentTopDownSharingDialogMessage),
          newParentId
        );
        return;
      }
    }

    this.reparentTheOrg(orgToMove, newParentId);
  }

  private orgNameDisplayTemplate = (node: TreeNode): React.ReactNode => {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(node.key);
    if (!org) {
      return;
    }
    const orgInfo: React.ReactNode = (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <span
        onDrop={async (): Promise<void> => {
          if (!this.state.allowReparenting) {
            return; // nothing to do if reparenting control is not switched on
          }
          if (!_.isEmpty(this.state.checkingParentOrgId)) {
            return; // do not start another reparent if another one is in progress
          }
          await this.onOrgDrop(node.key);
        }}
        onDragEnter={async (event: any): Promise<void> => {
          if (!this.state.allowReparenting) {
            return; // nothing to do if reparenting control is not switched on
          }
          if (!_.isEmpty(this.state.checkingParentOrgId)) {
            return; // do not start another reparent if another one is in progress
          }
          this.setState((prevState: OrgTreeState): Pick<OrgTreeState, never> => {
            // set dragOver state to true if its a valid parent org
            if (this.isValidMove(prevState.orgIdToMove, node.key)) {
              prevState.draggedOver.set(node.key, true);
            }
            return { draggedOver: prevState.draggedOver };
          });
          // need the below so that onDrop is able to catch the event
          event.preventDefault();
        }}
        onDragOver={(event: any): void => {
          event.preventDefault();
        }}
        onDragLeave={(): void => {
          if (!this.state.allowReparenting) {
            return; // nothing to do if reparenting control is not switched on
          }
          if (!_.isEmpty(this.state.checkingParentOrgId)) {
            return; // do not start another reparent if another one is in progress
          }
          // set dragOver state to false
          const { draggedOver } = this.state;
          draggedOver.set(node.key, false);
          this.setState({ draggedOver });
          this.setState((prevState: OrgTreeState): Pick<OrgTreeState, never> => {
            // set dragOver state to false
            prevState.draggedOver.set(node.key, false);
            return { draggedOver: prevState.draggedOver };
          });
        }}
        className={`
          ${!_.isEmpty(CommandService.getAllEditsForTheOrgExcludingUndo(org.id)) ? 'OrgTree_editedName' : ''} 
          ${!_.isEmpty(this.state.checkingParentOrgId) || this.state.draggedOver.get(node.key) ? 'OrgTree_dragged' : ''}
          ${CommandService.isDeleted(org.id, org.id) ? 'OrgTree_deletedOrg' : ''}
          ${
            !_.isEmpty(this.state.searchOrg) &&
            _.includes(_.toLower(node.data.name), _.toLower(_.trim(this.state.searchOrg)))
              ? 'OrgTree_filteredOrg'
              : ''
          }
          OrgTree_orgName
         `}
        draggable={this.state.allowReparenting}
        onDragStart={(event: any): void => {
          if (!this.state.allowReparenting) {
            return; // nothing to do if reparenting control is not switched on
          }
          Analytics.fireCTAEvent(`org drag`);
          const { dataTransfer } = event;
          if (dataTransfer) {
            dataTransfer.dropEffect = 'copy';
          }
          this.setState({ orgIdToMove: node.key });
        }}
        // this data-testid can be duplicated if org names are same
        data-testid={`${TREE_TESTID_PREFIX}${node.data.name}`}
      >
        {this.state.allowReparenting && <DragHandle size="XS" />}
        {node.data.name}
        {!_.isEmpty(CommandService.getAllEditsForTheOrgExcludingUndo(org.id)) &&
          !CommandService.isDeleted(org.id, org.id) &&
          !CommandService.isCreated(org.id, org.id) && (
            <Edit size="XS" className="OrgTree_EditIcon" data-testid="orgtree-edit-icon" />
          )}
        {isOrgMoved(node.key) && !CommandService.isDeleted(org.id, org.id) && (
          <Folder size="XS" className="OrgTree_reparentIcon" data-testid="org-reparent-icon" />
        )}
        {CommandService.isCreated(org.id, org.id) && (
          <span className="OrgTree_newOrg">
            <FormattedMessage id="OrgTree.newOrg" defaultMessage="New Org" />
          </span>
        )}
        {this.state.checkingParentOrgId === node.key && (
          <Wait data-testid={`${WAIT_PREFIX}${node.key}`} className="OrgTree__loadingIcon" size="S" />
        )}
      </span>
    );
    return this.state.allowReparenting ? (
      <OverlayTrigger>
        {orgInfo}
        <LocaleTooltip>
          <FormattedMessage id="OrgTree.tooltip.DnD" defaultMessage="Drag and drop to reparent" />
        </LocaleTooltip>
      </OverlayTrigger>
    ) : (
      orgInfo
    );
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    if (this.state.orgTreeCache === undefined) {
      return <Wait className="Load_wait" />;
    }
    return (
      <div className="OrgTree__container">
        <div className="OrgTree" data-testid="orgtree-container">
          {!_.isEmpty(this.state.errorMessage) && (
            <AlertBanner variant="error" closeable>
              {this.state.errorMessage}
            </AlertBanner>
          )}
          <div className="OrgTree_header">
            <Search
              className="OrgTree_search"
              placeholder={formatMessage(messages.search)}
              quiet
              onChange={(searchInput: string): void => {
                this.onSearch(searchInput);
              }}
              onSubmit={this.onSearch}
              value={this.state.searchOrg}
              data-testid="OrgTree-search"
              aria-label={formatMessage(messages.search)}
            />
            {!AdminPermission.readOnlyMode() && this.state.allowReparenting && (
              <Button // when reparent control is on, show blue "Save" CTA button
                variant="cta"
                label={formatMessage(messages.saveReparentLabel)}
                onClick={(): void => {
                  this.setState({ allowReparenting: false }); // switch off reparent control
                }}
                className="OrgTree__EditStructure"
              />
            )}
            {!AdminPermission.readOnlyMode() && !this.state.allowReparenting && (
              <ModalTrigger>
                <Button // when reparent control is off, show Edit button (secondary)
                  variant="secondary"
                  label={formatMessage(messages.changeHierarchy)}
                  className="OrgTree__EditStructure"
                  data-testid="OrgTree-reparent-switch"
                />
                {CommandService.isOnlyReparentEdits() ? (
                  <Dialog
                    className="OrgTree_reparentDialog"
                    title={formatMessage(messages.reparentOn)}
                    confirmLabel={formatMessage(messages.buttonOK)}
                    cancelLabel={formatMessage(messages.buttonCancel)}
                    onConfirm={(): void => {
                      Analytics.fireCTAEvent(` Switch on org reparent control`);
                      this.setState({ allowReparenting: true });
                    }}
                  >
                    {formatMessage(messages.dragOn)} &nbsp;
                    {formatMessage(messages.reparentOnNote)}
                  </Dialog>
                ) : (
                  <Dialog
                    title={formatMessage(messages.cannotReparent)}
                    confirmLabel={formatMessage(messages.buttonOK)}
                  >
                    {formatMessage(messages.cannotReparentMessage)}
                  </Dialog>
                )}
              </ModalTrigger>
            )}
          </div>
          <ScrollableContent uniqueId="OrgTreeID" className="OrgTree__TreeTable">
            <TreeTable
              value={[this.state.orgTreeCache.getOrgHierarchy()]}
              onToggle={(e: { originalEvent: Event; value: ExpandedNodes }): void => {
                // update expanded nodes
                ExpandedNodesUtil.updateExpandedNodes(e.value);
                // re-render
                this.setState({});
              }}
              expandedKeys={ExpandedNodesUtil.getExpandedNodes()}
              selectionMode="single"
              onSelectionChange={(e: { originalEvent: Event; value: string }): void => {
                // update org selection
                if (!_.isEmpty(e.value) && e.value !== OrgSelectionUtil.getSelectedOrgId()) {
                  OrgSelectionUtil.updateOrgSelection(e.value);
                  this.props.updateCompartmentCallback();
                }
              }}
              selectionKeys={this.props.selectedOrg.id}
            >
              <Column field="name" body={this.orgNameDisplayTemplate} expander className="OrgTree_column" />
            </TreeTable>
          </ScrollableContent>
        </div>
        <EditCompartment
          updateCompartmentCallback={this.props.updateCompartmentCallback}
          selectedOrg={this.props.selectedOrg}
        />
      </div>
    );
  }
}

export default injectIntl(OrgTree);
export { isOrgMoved };
export { TREE_TESTID_PREFIX, WAIT_PREFIX };
