/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import {
  Cell,
  Column,
  Flex,
  Link,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Selection,
  DialogContainer,
} from '@adobe/react-spectrum';
import Utils from '../../../services/utils/Utils';
import {
  defineMessages,
  FormattedMessage,
  IntlProvider,
  MessageDescriptor,
  WrappedComponentProps,
  useIntl,
} from 'react-intl';

import SharedUserGroupButton from './SharedUserGroupButton';
import Dropdown from '@react/react-spectrum/Dropdown';
import Button from '@react/react-spectrum/Button';
import More from '@react/react-spectrum/Icon/More';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import { Menu, MenuItem, MenuProps } from '@react/react-spectrum/Menu';
import Analytics from '../../../Analytics/Analytics';

import Dialog from '@react/react-spectrum/Dialog';
import React, { useEffect, useState } from 'react';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import AdminPermission from '../../../services/authentication/AdminPermission';
import { UUserGroup } from '../../../services/orgMaster/UUserGroup';
import { UOrgAncestor } from '../../../services/organization/Org';
import { CommandService } from '../../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../../../services/orgMaster/OrgMaster';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';
import * as _ from 'lodash';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import EditUserGroupDialogContent, {
  EditUserGroupDialogContentProps,
  EditUserGroupDialogContext,
} from './EditUserGroupDialogContent/EditUserGroupDialogContent';
import ViewUserGroupDialogContent, {
  ViewUserGroupDialogContentProps,
} from './ViewUserGroupDialogContent/ViewUserGroupDialogContent';
import { MAX_DISPLAY_CHARACTER_LENGTH } from '../Constants';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import { ManageUserGroupShareDialog } from '../../../components/ManageUserGroupShareDialog';
import OrganizationListProvider from '../../../providers/OrganizationListProvider';

interface UserGroupsTableProps {
  selectedOrg: UOrgMaster;
  filteredUserGroups: Array<UUserGroup>;
  formatMessage: (message: MessageDescriptor, record?: Record<any, any>) => string;
  update: () => void;
  intl: any;
  onIsCheckboxesSelected: (bool: boolean) => void;
  onSelectedUserGroups: (userGroups: Array<UUserGroup>) => void;
  isUserGroupSharingEnabled: boolean;
}

interface UserGroupsProps extends WrappedComponentProps {
  userGroup: UUserGroup;
}

function MenuIntl(props: Omit<UserGroupsProps & MenuProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <Menu {...props}>{props.children}</Menu>
    </IntlProvider>
  );
}

function EditUserGroupDialogContentIntl(props: Omit<EditUserGroupDialogContentProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditUserGroupDialogContent {...props} />
    </IntlProvider>
  );
}

function ViewUserGroupDialogContentIntl(props: Omit<ViewUserGroupDialogContentProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ViewUserGroupDialogContent {...props} />
    </IntlProvider>
  );
}

const USER_GROUP_TESTID = 'usergroup-testid-';

const messages = defineMessages({
  Warning: {
    id: 'EditCompartment.UserGroups.Delete.Warning',
    defaultMessage: 'Warning',
  },
  OK: {
    id: 'EditCompartment.UserGroups.Delete.OK',
    defaultMessage: 'Ok',
  },
  Cancel: {
    id: 'EditCompartment.UserGroups.Delete.Cancel',
    defaultMessage: 'Cancel',
  },
  AreYouSure: {
    id: 'EditCompartment.UserGroups.Delete.confirmDelete',
    defaultMessage: 'Are you sure you want to delete {group}?',
  },
  SharedGroupOwnedByParentOrg: {
    id: 'EditCompartment.UserGroups.SharedGroups.ParentOwnerSharedGroup',
    defaultMessage: `This shared user group is owned by {parent}. Only the owner can share or modify the group.`,
  },
  SharedGroup: {
    id: 'EditCompartment.UserGroups.SharedGroups.SharedGroup',
    defaultMessage: 'This user group is shared with other organizations.',
  },
  SharedGroupTableLabel: {
    id: 'EditCompartment.UserGroups.SharedGroups.SharedGroupTableLabel',
    defaultMessage: 'User groups list',
  },
  ManageSharedAccessLink: {
    id: 'EditCompartment.UserGroups.ManageSharedAccessLink.Text',
    defaultMessage: 'Manage shared access',
  },
  DropdownButtonLabel: {
    id: 'EditCompartment.UserGroups.SharedGroups.DropdownButtonLabel',
    defaultMessage: 'user group menu options',
  },
});

export const UserGroupsTable = ({ ...props }: UserGroupsTableProps): JSX.Element => {
  const intl = useIntl();
  const [disabledStates, setDisabledStates] = useState<number[]>([]);
  const [manageUserGroupShareDialogSubject, setManageUserGroupShareDialogSubject] = useState<UUserGroup | undefined>();
  const [openManageUserGroupShareDialog, setOpenManageUserGroupShareDialog] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [sourceOrgNames, setSourceOrgNames] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeOrgAncestorsMap, setActiveOrgAncestorsMap] = useState<Record<string, UOrgAncestor>>({});

  const onDeleteUserGroup = (userGroup: UUserGroup): void => {
    CommandService.addEdit(
      props.selectedOrg,
      userGroup,
      ObjectTypes.USER_GROUP,
      OrgOperation.DELETE,
      undefined,
      'DELETE_USER_GROUP',
      [userGroup.name, CmdDescriptionUtils.getPathname(userGroup.orgId)]
    );
    props.update();
  };

  const readOnly =
    AdminPermission.readOnlyMode() ||
    !props.selectedOrg.policiesLoaded ||
    !props.selectedOrg.compartmentPolicy.policies.manageUserGroups.value;

  const sortedAndFilteredUserGroup = _.sortBy(props.filteredUserGroups, ['name']);

  const indexedUserGroups = sortedAndFilteredUserGroup.map((e, index) => ({
    id: index.toString(),
    userGroup: e,
  }));

  const getUserGroupsIdMapping = (ids: Array<string>): Array<UUserGroup> => {
    return indexedUserGroups.filter((element) => ids.includes(element.id)).map((el) => el.userGroup);
  };

  const loadDisabledStates = (): void => {
    let updatedDisableStates = new Array<number>();
    for (let i = 0; i < sortedAndFilteredUserGroup.length; i++) {
      if (Utils.isTargetGroup(sortedAndFilteredUserGroup[i]) || Utils.isLeafOrg(props.selectedOrg)) {
        updatedDisableStates.push(i);
      }
    }
    setDisabledStates(updatedDisableStates);
  };

  const findSourceOrgName = (userGroup: UUserGroup): string => {
    const sourceOrgId = userGroup.sharedUserGroupSource?.sourceOrgId;
    if (activeOrgAncestorsMap && sourceOrgId) {
      const sourceOrg = activeOrgAncestorsMap[sourceOrgId];
      return sourceOrg ? sourceOrg.name : '';
    }
    return '';
  };

  const getSourceOrgName = (userGroup: UUserGroup): string => {
    return isLoading ? '' : sourceOrgNames[userGroup.id];
  };

  const groupIds = props.filteredUserGroups.map((group) => group.id).join(',');

  const matchSourceOrgNames = () => {
    for (const userGroup of props.filteredUserGroups) {
      if (Utils.isTargetGroup(userGroup)) {
        const sourceOrgName = findSourceOrgName(userGroup);
        sourceOrgNames[userGroup.id] = sourceOrgName;
        setSourceOrgNames(sourceOrgNames);
      }
    }
  };

  // on mount
  useEffect(() => {
    setSelectedKeys(new Set([]));
    props.onIsCheckboxesSelected(false);
    props.onSelectedUserGroups([]);
  }, [props.selectedOrg]);

  useEffect(() => {
    loadDisabledStates();
    matchSourceOrgNames();
  }, [props.selectedOrg, groupIds, activeOrgAncestorsMap]);

  useEffect(() => {
    async function getAncestors(orgId: string) {
      const abortController = new AbortController();
      setIsLoading(true);
      try {
        const data: UOrgAncestor[] = await OrganizationListProvider.getAncestors(orgId, abortController.signal);
        if (!abortController.signal.aborted) {
          setActiveOrgAncestorsMap(_.keyBy(data, 'id'));
        }
      } catch (error) {
        if (Utils.isAbortError(error)) {
          return;
        }
        if (!abortController.signal.aborted) {
          console.error('Error getting ancestors for user group: ', error);
        }
      }
      setIsLoading(false);
    }
    getAncestors(props.selectedOrg.id);
  }, [props.selectedOrg.id]);

  let userGroupNameButton = (userGroup: UUserGroup) => {
    return (
      <Button
        quiet
        variant="action"
        onClick={(): void => {
          Analytics.fireCTAEvent(`${readOnly ? 'view' : 'edit'} user group dialog opened from name`);
          ModalContainer.show(
            readOnly ? (
              <ViewUserGroupDialogContentIntl {...props} userGroup={userGroup} />
            ) : (
              <EditUserGroupDialogContentIntl
                {...props}
                userGroup={userGroup}
                update={props.update}
                context={EditUserGroupDialogContext.EDIT}
              />
            ),
            this
          );
        }}
        className="EditCompartmentTabs__Name"
      >
        {userGroup.name}
      </Button>
    );
  };

  const handleKeys = (keys: Selection) => {
    setSelectedKeys(keys);
    let selectedKeyArray = [];
    if (keys === 'all') {
      // pressing the 'select all' set the keys to 'all' instead of populating the individual keys, so we have to do it manually
      selectedKeyArray = indexedUserGroups
        .filter((element) => !disabledStates.map(String).includes(element.id))
        .map((el) => el.id);
    } else {
      selectedKeyArray = Array.from(keys);
    }
    props.onSelectedUserGroups(getUserGroupsIdMapping(selectedKeyArray.map(String)));
    props.onIsCheckboxesSelected(selectedKeyArray.length > 0);
  };

  return (
    <TableView
      selectionMode={!AdminPermission.readOnlyMode() && props.isUserGroupSharingEnabled ? 'multiple' : 'none'}
      disabledKeys={disabledStates.map(String)}
      selectedKeys={selectedKeys}
      onSelectionChange={handleKeys}
      aria-label={props.formatMessage(messages.SharedGroupTableLabel)}
      data-testid={`${USER_GROUP_TESTID}table`}
    >
      <TableHeader>
        <Column key="UserGroupsTab-column-usergroupname">
          <Flex alignItems="center">
            <FormattedMessage id="EditCompartment.UserGroups.heading.UserGroup" defaultMessage="USER GROUP" />
          </Flex>
        </Column>
        <Column key="UserGroupsTab-column-usercount">
          <FormattedMessage id="EditCompartment.UserGroups.heading.UserCount" defaultMessage="USER COUNT" />
        </Column>
        <Column key="UserGroupsTab-column-blank">
          <div></div>
        </Column>
      </TableHeader>
      <TableBody items={indexedUserGroups}>
        {(item) => (
          <Row key={item.id}>
            <Cell>
              <Flex alignItems="center">
                {item.userGroup.name.length > MAX_DISPLAY_CHARACTER_LENGTH ? (
                  /* The tooltip is shown when name is long and truncated */
                  <OverlayTrigger placement="top">
                    {userGroupNameButton(item.userGroup)}
                    <Tooltip className="EditCompartmentTabs__NameTooltip"> {item.userGroup.name}</Tooltip>
                  </OverlayTrigger>
                ) : (
                  userGroupNameButton(item.userGroup)
                )}
                {props.isUserGroupSharingEnabled && Utils.isSharedGroup(item.userGroup) && (
                  <SharedUserGroupButton
                    tooltipText={
                      Utils.isTargetGroup(item.userGroup)
                        ? props.formatMessage(messages.SharedGroupOwnedByParentOrg, {
                            parent: getSourceOrgName(item.userGroup),
                          })
                        : props.formatMessage(messages.SharedGroup)
                    }
                  />
                )}
              </Flex>
            </Cell>
            <Cell>{item.userGroup.userCount}</Cell>
            <Cell>
              <Flex alignItems="center" justifyContent="end" direction="row" gap="size-100">
                {props.isUserGroupSharingEnabled && Utils.isSourceGroup(item.userGroup) && (
                  <>
                    <Link
                      data-testid="manage-shared-org-link"
                      marginBottom="size-65"
                      onPress={(): void => {
                        setOpenManageUserGroupShareDialog(true);
                        setManageUserGroupShareDialogSubject(item.userGroup);
                      }}
                    >
                      {intl.formatMessage(messages.ManageSharedAccessLink)}
                    </Link>
                    {openManageUserGroupShareDialog && manageUserGroupShareDialogSubject === item.userGroup && (
                      <DialogContainer
                        onDismiss={(): void => {
                          setOpenManageUserGroupShareDialog(false);
                          setManageUserGroupShareDialogSubject(undefined);
                        }}
                      >
                        <ManageUserGroupShareDialog
                          sourceOrgUserGroup={item.userGroup}
                          data-testid="manage-user-group-share-dialog"
                          update={props.update}
                        />
                      </DialogContainer>
                    )}
                  </>
                )}
                <Dropdown alignRight closeOnSelect className="MoreDropdown">
                  <Button
                    dropdownTrigger
                    className="MoreButton"
                    data-testid="usergroup-menu-options"
                    aria-label={props.formatMessage(messages.DropdownButtonLabel)}
                  >
                    <More size="S" />
                  </Button>
                  <MenuIntl dropdownMenu {...props} userGroup={item.userGroup}>
                    {readOnly ? (
                      <ModalTrigger>
                        <MenuItem
                          onClick={(): void => Analytics.fireCTAEvent(`view user group dialog opened from dropdown`)}
                        >
                          <FormattedMessage
                            id="EditCompartment.UserGroups.menu.ViewUserGroup"
                            defaultMessage="View User Group"
                          />
                        </MenuItem>
                        <ViewUserGroupDialogContentIntl {...props} userGroup={item.userGroup} />
                      </ModalTrigger>
                    ) : (
                      <>
                        <ModalTrigger>
                          <MenuItem
                            onClick={(): void => Analytics.fireCTAEvent(`edit user group dialog opened from dropdown`)}
                            data-testid="user-group-row-edit-user-group"
                          >
                            <FormattedMessage
                              id="EditCompartment.UserGroups.menu.EditUserGroup"
                              defaultMessage="Edit User Group"
                            />
                          </MenuItem>
                          <EditUserGroupDialogContentIntl
                            userGroup={item.userGroup}
                            {...props}
                            update={props.update}
                            context={EditUserGroupDialogContext.EDIT}
                          />
                        </ModalTrigger>
                        <ModalTrigger>
                          <MenuItem
                            onClick={(): void => Analytics.fireCTAEvent('delete user group dialog opened')}
                            data-testid="user-group-row-delete-user-group"
                            disabled={Utils.isTargetGroup(item.userGroup)}
                          >
                            <FormattedMessage
                              id="EditCompartment.UserGroups.menu.DeleteUserGroup"
                              defaultMessage="Delete User Group"
                            />
                          </MenuItem>
                          <Dialog
                            variant="destructive"
                            title={props.formatMessage(messages.Warning)}
                            confirmLabel={props.formatMessage(messages.OK)}
                            onCancel={(): void => Analytics.fireCTAEvent('delete user group dialog canceled')}
                            onConfirm={(): void => {
                              onDeleteUserGroup(item.userGroup);
                              Analytics.fireCTAEvent('delete user group dialog confirmed');
                            }}
                            cancelLabel={props.formatMessage(messages.Cancel)}
                          >
                            {props.formatMessage(messages.AreYouSure, { group: item.userGroup.name })}
                          </Dialog>
                        </ModalTrigger>
                      </>
                    )}
                  </MenuIntl>
                </Dropdown>
              </Flex>
            </Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

export { USER_GROUP_TESTID };
