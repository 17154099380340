import React, { Component } from 'react';
import { Button, ButtonGroup, Content, Dialog, Divider, Form, Heading } from '@adobe/react-spectrum';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import OrgNameEdit from '../Widgets/OrgNameEdit';
import CountryCode from '../Widgets/CountryCode';
import Analytics from '../../../Analytics/Analytics';

import '../../common.css';
import { AddOrgService } from '../../../services/organization/AddOrgService';

interface AddChildOrgContentProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
  close: () => void;
}

interface AddChildOrgContentState {
  orgName: string;
  orgNameValidationState: 'invalid' | 'valid' | undefined;
  countryCodeValidationState: 'invalid' | 'valid' | undefined;
  countryCode: string;
}

const messages = defineMessages({
  title: {
    id: 'Organizations.Add.title',
    defaultMessage: 'Add child organization for {orgName}',
  },
});

class AddChildOrgContent extends Component<AddChildOrgContentProps, AddChildOrgContentState> {
  parent: UOrgMaster;
  constructor(props: any) {
    super(props);
    this.parent = this.props.selectedOrg;
    this.state = {
      orgName: '',
      orgNameValidationState: 'invalid',
      countryCode: this.parent.organization.countryCode, // use country code from the parent
      countryCodeValidationState: 'invalid', // initialize as invalid until countries are loaded.
    };
  }

  /**
   * creates a new org on save
   */
  private onConfirm = (): void => {
    Analytics.fireCTAEvent(`add child org dialog save clicked`);
    AddOrgService.createNewOrg(this.parent, this.state.orgName, this.state.countryCode);
    this.props.update();
  };

  /**
   * update callback - can be called by OrgNameEdit to update orgNameValidationState
   * orgNameValidationState controls enable/disable state for the Save button
   */
  private updateOrgNameValidationState = (orgNameValidationState: 'invalid' | 'valid' | undefined): void => {
    this.setState({ orgNameValidationState });
  };

  /**
   * update callback - can be called by CountryCode to update countryCodeValidationState
   * countryCodeValidationState controls enable/disable state for the Save button
   */
  private updateCountryCodeValidationState = (countryCodeValidationState: 'invalid' | 'valid' | undefined): void => {
    this.setState({ countryCodeValidationState });
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    return (
      <Dialog size="S">
        <Heading>{formatMessage(messages.title, { orgName: this.parent.organization.name })}</Heading>
        <Divider />
        <Content>
          <Form>
            <OrgNameEdit
              parentId={this.parent.id}
              currentName=""
              updateOrgName={(orgName: string): void => this.setState({ orgName })}
              updateValidationState={this.updateOrgNameValidationState}
            />
            <CountryCode
              countryCode={this.parent.organization.countryCode}
              updateCountryCode={(countryCode: string): void => this.setState({ countryCode })}
              updateValidationState={this.updateCountryCodeValidationState}
            />
          </Form>
        </Content>
        <ButtonGroup>
          <Button
            variant="secondary"
            onPress={(): void => {
              Analytics.fireCTAEvent(`add child org dialog cancel`);
              this.props.close();
            }}
          >
            <FormattedMessage id="Organizations.Add.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            variant="cta"
            onPress={(): void => {
              this.onConfirm();
              this.props.close();
            }}
            isDisabled={
              this.state.orgNameValidationState === 'invalid' || this.state.countryCodeValidationState === 'invalid'
            }
            autoFocus
          >
            <FormattedMessage id="Organizations.Add.Save" defaultMessage="Save" />
          </Button>
        </ButtonGroup>
      </Dialog>
    );
  }
}

export default injectIntl(AddChildOrgContent);
