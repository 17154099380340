import * as _ from 'lodash';
import React, { Key, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import GlobeGrid from '@spectrum-icons/workflow/GlobeGrid';
import { Picker, Item } from '@adobe/react-spectrum';

import { LocaleSettings } from '../../../services/locale/LocaleSettings';

export const LOCALE_SELECTOR_BUTTON_TEST_ID = 'locale-selector-button';

const localeMessages = defineMessages({
  language: {
    id: 'footer.labels.language',
    defaultMessage: 'Language',
  },
});

/**
 * Provides a selector component for selecting different locales
 */

/**
 * Mapping of LanguageTags to a Languages Name to display in this selector.  The Language Names are the names
 * displayed in the selector of this component
 * See https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/i18n_web/05_ui_language_selector.md
 */
const LocaleNames: { [key: string]: string } = {
  'cs-CZ': 'Čeština',
  'da-DK': 'Dansk',
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'nl-NL': 'Nederlands',
  'nb-NO': 'Norsk',
  'pl-PL': 'Polski',
  'pt-BR': 'Português',
  'ru-RU': 'Pусский',
  'fi-FI': 'Suomi',
  'sv-SE': 'Svenska',
  'tr-TR': 'Türkçe',
  // 'uk-UA': 'українська',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
  // 'kk-KK': 'Test',
};
const LanguageTags: ReadonlyArray<string> = Object.keys(LocaleNames); // Array of LanguageTags of supported locales

interface LocaleSelectorProps {
  className?: string;
  defaultSelectedLocale: string; // Initial selected locale
}

/**
 * Format languageTag for case insenstivity
 * Edits languageTag so it matches the languageTags in LocaleNames regardless of capitalization
 *
 * Returns languageTag as xx-XX (lowercase-UPPERCASE) so it matches key values in LocalNames above.
 * For example, given kk-kk returns kk-KK.  Given KK-KK returns kk-KK.
 */
function normalizeLanguageTag(languageTag: string): string {
  const languageTagParts: string[] = languageTag.split('-');
  if (languageTagParts.length > 0 && !_.isEmpty(languageTagParts[0])) {
    languageTagParts[0] = _.toLower(languageTagParts[0]);
  }
  if (languageTagParts.length > 1 && !_.isEmpty(languageTagParts[1])) {
    languageTagParts[1] = _.toUpper(languageTagParts[1]);
  }
  return languageTagParts.join('-');
}

/**
 * Retrieves the local name given a LanguageTag
 */
function localeName(languageTag: string): string {
  const name: string = LocaleNames[normalizeLanguageTag(languageTag)];
  if (!name) {
    // return 'English';  // perhaps needed if state captures a language whose designation has changed
    throw new Error('localeName unknown languageTag given');
  }
  return name;
}

function LocaleSelector(props: LocaleSelectorProps): React.ReactElement {
  const [selectedLocale, setSelectedLocale] = useState<string>(props.defaultSelectedLocale);

  const { formatMessage } = useIntl();

  return (
    <span className={props.className ? props.className : ''}>
      <GlobeGrid
        id="footer.globalIcon"
        position="relative"
        top="0.45rem"
        size="M"
        aria-label={formatMessage(localeMessages.language)}
      />
      <Picker
        position="relative"
        bottom="0.35rem"
        marginStart="0.5rem"
        isQuiet
        // Need to convert languageTag strings to objects otherwise there will be a weak map error
        items={_.map(LanguageTags, (languageTag: string) => ({ tag: languageTag }))}
        defaultSelectedKey={selectedLocale}
        onSelectionChange={(key: Key): void => {
          LocaleSettings.changeLocale(key.toString());
          setSelectedLocale(key.toString());
        }}
        aria-labelledby="footer.globalIcon"
        data-testid={LOCALE_SELECTOR_BUTTON_TEST_ID}
      >
        {(languageObj): React.ReactElement => <Item key={languageObj.tag}>{localeName(languageObj.tag)}</Item>}
      </Picker>
    </span>
  );
}
export default LocaleSelector;
