/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import { UBasic, UBasicData } from './UBasic';
import { assign } from 'lodash';

export interface UUserGroupShareData extends UBasicData {
  sourceOrgId: string;
  targetOrgId: string;
  sourceGroupId: string;
  targetGroupId?: string;
  userGroupName?: string;
  strategy?: string | undefined;
}

export class UUserGroupShare extends UBasic implements UUserGroupShareData {
  sourceOrgId: string = '';
  sourceGroupId: string = '';
  targetOrgId: string = '';
  targetGroupId?: string | undefined;
  userGroupName?: string | undefined;
  strategy?: string | undefined;

  /**
   * Constructs a UUserGroupShare from either UUserGroupShareData or creates a copy of another UUserGroupShare object.
   */
  constructor(userGroupShare?: UUserGroupShareData) {
    super(userGroupShare);
    if (userGroupShare) {
      this.sourceOrgId = userGroupShare.sourceOrgId || this.sourceOrgId;
      this.sourceGroupId = userGroupShare.sourceGroupId || this.sourceGroupId;
      this.targetOrgId = userGroupShare.targetOrgId || this.targetOrgId;
      this.targetGroupId = userGroupShare.targetGroupId || this.targetGroupId;
      this.userGroupName = userGroupShare.userGroupName || this.userGroupName;
      this.strategy = userGroupShare.strategy || this.strategy;
    }
  }

  isEqual(otherUGS: UUserGroupShare): boolean {
    return (
      this.sourceOrgId === otherUGS.sourceOrgId &&
      this.sourceGroupId === otherUGS.sourceGroupId &&
      this.targetOrgId === otherUGS.targetOrgId &&
      this.targetGroupId === otherUGS.targetGroupId &&
      this.userGroupName === otherUGS.userGroupName &&
      this.strategy === otherUGS.strategy
    );
  }

  /**
   * JSON representation of UUserGroupShare.
   */
  toJSON(): object {
    return assign(super.toJSON(), this);
  }
}
