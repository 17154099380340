import configurations from '../../configurations/config';

/**
 * Generates the common headers necessary for any restful api call to GAC service (i.e. backend).
 * These headers are generated in a method so that when any of the AuthenticatedUser related
 * calls are executed, the headers are generated at that point instead of
 * before an AuthenticatedUser is available.
 *
 */
class CommonHeaders {
  static generateCommonGACSvcHeaders(): Headers {
    const headers: Headers = new Headers();

    headers.append('x-api-key', configurations.banyansvc.apiKey);

    // used with old versions of @IMSServiceAuthentication for service-to-service, and thus not relevant in the UI?
    // https://wiki.corp.adobe.com/display/CTDxE/ASR+Enabling+IMS+Authentication
    // headers.append('x-ims-clientid', configurations.banyansvc.clientId);

    headers.append('Content-Type', 'application/json');
    return headers;
  }

  static generateCommonJILSvcHeaders(): Headers {
    const headers: Headers = new Headers();
    headers.append('x-api-key', configurations.jilApi.apiKey);
    headers.append('Content-Type', 'application/json');
    return headers;
  }
}

export default CommonHeaders;
