import React from 'react';
import Alert from '@react/react-spectrum/Alert';
import Button from '@react/react-spectrum/Button';
import Close from '@react/react-spectrum/Icon/Close';

import './AlertBanner.css';

/**
 * AlertBanner handles showing a banner that is more space efficient.
 * It also handles closing the banner with close button or after timeout.
 * The AlertBanner is shown upon mounting.
 */

interface AlertBannerProps {
  className?: string; // css class for styling this component
  style?: object; // css styling for this component

  header?: string; // header label in the banner
  children?: React.ReactNode; // content that will be in the body area of the banner (usually just a string)
  variant?: 'error' | 'warning' | 'info' | 'help' | 'success'; // specifies the type of banner that will be dislayed
  closeable?: boolean; // determines whether the close button will be shown.  onClose will be executed and the banner will close when the button is clicked
  closeTime?: number; // timeout in milliseconds before onClose will be executed and the banner closes (undefined, 0, or negative numbers means there will be no timer)
  onClose?: () => void; // callback when either the timeout elapses or if the close button is clicked
}

interface AlertBannerState {
  show?: boolean; // determines whether the AlertBanner should be currently displayed
}

class AlertBanner extends React.Component<AlertBannerProps, AlertBannerState> {
  private timeout: number | undefined; // id for the close timer
  constructor(props: AlertBannerProps) {
    super(props);
    this.state = {
      show: true,
    };
  }

  private clearTimeout(): void {
    if (this.timeout !== undefined) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  componentDidMount(): void {
    // Starts the close timer from the point the component is mounted
    if (typeof this.props.closeTime === 'number' && this.props.closeTime > 0) {
      this.timeout = window.setTimeout((): void => {
        this.timeout = undefined;
        this.onClose();
      }, this.props.closeTime);
    }
  }

  componentWillUnmount(): void {
    // Clears out the close timer if the component is unmounted
    this.clearTimeout();
  }

  /**
   * Provides the primary className for the AlertBanner.
   * A different className is given depending on whether a header exists, so that
   * the space can be used efficiently with a header and without a header.
   */
  generateClassName(): string {
    if (this.props.header === undefined) {
      return 'AlertBanner__noHeader';
    }
    return 'AlertBanner';
  }

  /**
   * Executed when the close button is clicked.
   */
  onClose = (): void => {
    this.clearTimeout();
    this.setState({ show: false }, (): void => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  };

  render(): React.ReactNode {
    return (
      this.state.show && (
        <Alert
          className={`${this.generateClassName()}${this.props.className ? ` ${this.props.className}` : ''}`}
          style={this.props.style}
          header={this.props.header}
          variant={this.props.variant}
          data-testid="alertBanner-alert"
        >
          <div className="AlertBanner__content">{this.props.children}</div>
          {this.props.closeable && (
            // The close button.  To show a button without borders we show an action type button with --quiet styling.
            // There also exists "icon" type, but that is not preferred by Spectrum
            <div className="AlertBanner__buttons">
              <Button
                className="spectrum-ActionButton--quiet AlertBanner__close"
                variant="action"
                onClick={this.onClose}
                data-testid="alertBanner-closeButton"
              >
                <Close size="XS" />
              </Button>
            </div>
          )}
        </Alert>
      )
    );
  }
}
export default AlertBanner;
