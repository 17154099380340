import * as _ from 'lodash';

class EmailValidation {
  /**
   * Email id should in in the form a@b.c
   */
  static isEmailFormatValid(email: string): boolean {
    const EMAIL_REGEX = /.+@.+\..+/;
    return EMAIL_REGEX.test(email);
  }

  /**
   * Email id should not exceed 254 characters
   */
  static isEmailLengthValid(email: string): boolean {
    const EMAIL_MAX_LEN = 254;
    return _.inRange(email.length, 1, EMAIL_MAX_LEN + 1);
  }
}

export default EmailValidation;
