/* eslint-disable no-case-declarations */

import * as _ from 'lodash';
import { WorkBook } from 'xlsx/types';
import { IntlShape, defineMessages } from 'react-intl';

import { FileData } from '../../../services/utils/Upload';

import {
  OrganizationTypeInfo,
  ProdProfileTypeInfo,
  UserGroupsTypeInfo,
  ProductsTypeInfo,
  ResourceTypeInfo,
  AdminTypeInfo,
  OrgPoliciesTypeInfo,
} from './DetailsToRequiredFieldsMap/DetailsToRequiredFieldsMap';
import CSVToDataModel from '../../../services/utils/ConvertToDataModel/CSVToDataModel';
import JSONToDataModel from '../../../services/utils/ConvertToDataModel/JSONToDataModel';
import XLSXToDataModel from '../../../services/utils/ConvertToDataModel/XLSXToDataModel';

import ImportOrganizations from './ImportHandler/ImportOrganizations';
import {
  ImportOrganizationsDM,
  OrgDataJSON,
  ImportProductsDM,
  OrganizationsJSON,
  ImportResourcesDM,
  ImportProductProfilesDM,
  ProductProfilesJSON,
  ImportUserGroupsDM,
  UserGroupsJSON,
  ProfilesForUserGroupsJSON,
  ImportResourcesProfilesDM,
  ImportAdminsDM,
  ImportOrgPoliciesDM,
} from './DataModelTypes/DataModelTypes';

import { Details, FileExtension, XLSXSheetNames } from './ImportEnums';
import ImportProductsAndResources from './ImportHandler/ImportProductsAndResources';
import ImportUserGroups from './ImportHandler/ImportUserGroups';
import OrgSelectionUtil from '../../../services/treeTableUtils/OrgSelectionUtil';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import ImportProductProfiles from './ImportHandler/ImportProductProfiles';
import { ChangeCount } from './ImportUtils';
import ImportAdmins from './ImportHandler/ImportAdmins';
import ImportOrgPolicies from './ImportHandler/ImportOrgPolicies';
import ValidateTypeInfo from '../../../services/utils/ConvertToDataModel/ValidateTypeInfo';

// #TODO: Reparenting is not supported. Will be handled in a separate PR

// NOTE: data structure for JSON input file is different from CSV input file

const messages = defineMessages({
  InvalidFileFormat: {
    id: 'Organizations.Import.Import.InvalidFileFormat',
    defaultMessage: 'Invalid file format. Valid formats are json, xlsx, and csv.',
  },
  InvalidFileFormatForCSV: {
    id: 'Organizations.Import.Import.InvalidFileFormatForCSV',
    defaultMessage:
      'Filename must end with one of "admins.csv", "organizations.csv", "productprofiles.csv", "usergroups.csv" or "orgpolicies.csv"',
  },
  ProductNotExportedForCSV: {
    id: 'Organizations.Import.Import.ProductNotExportedForCSV',
    defaultMessage: 'Products cannot be imported in csv format.',
  },
  OrganizationsLabel: {
    id: 'Organizations.Import.Import.OrganizationsLabel',
    defaultMessage: 'Organizations',
  },
  ProductsLabel: {
    id: 'Organizations.Import.Import.ProductsLabel',
    defaultMessage: 'Products',
  },
  ResourcesLabel: {
    id: 'Organizations.Import.Import.ResourcesLabel',
    defaultMessage: 'Resources',
  },
  ProductProfilesLabel: {
    id: 'Organizations.Import.Import.ProductProfilesLabel',
    defaultMessage: 'Product Profiles',
  },
  UserGroupsLabel: {
    id: 'Organizations.Import.Import.UserGroupsLabel',
    defaultMessage: 'User Groups',
  },
  AdminLabel: {
    id: 'Organizations.Import.Import.AdminLabel',
    defaultMessage: 'Admins',
  },
  OrgPoliciesLabel: {
    id: 'Organizations.Import.OrgPoliciesLabel.OrgPoliciesLabel',
    defaultMessage: 'Org Policies',
  },
});

interface ImportData {
  allOrgs: ImportOrganizationsDM[];
  allProducts: ImportProductsDM[];
  allProdProfiles: ImportProductProfilesDM[];
  allUserGroups: ImportUserGroupsDM[];
  allAdmins: ImportAdminsDM[];
  allOrgPolicies: ImportOrgPoliciesDM[];
}

class ImportHelper {
  /**
   * Performs following operations
   * 1. validate data types for all detail types
   * 2. load data for each input detail with valid operation and update status via Progress Bar
   * 3. validate input data is correct
   * 4. apply all changes on the hierarchy
   */
  static async validateAndImportDataForJSONAndXLSX(
    importData: ImportData,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    // step 1. validate data types for all detail types
    ImportHelper.validateInputDataTypes(importData, intl);

    // step 2. load all data for import operation
    const allDataPromises = ImportHelper.generateAllAPICalls(importData);
    await ImportHelper.loadAllData(allDataPromises, updateProgressCallback);

    // step 3. NOTE: All validations will be performed before the import in hierarchy (ALL or NOTHING operation)
    // *** VALIDATION ***
    await ImportHelper.validate(importData, intl);

    // step 4. *** IMPORT INTO HIERARCHY ***
    return await ImportHelper.importData(importData);
  }

  /**
   * Perform all the validations on the input data
   */
  static async validate(importData: ImportData, intl: IntlShape): Promise<void> {
    // validate org data
    await ImportOrganizations.validate(importData.allOrgs, intl);

    // validate products and its resources
    await ImportProductsAndResources.validate(importData.allProducts, importData.allOrgs, intl);

    await ImportProductProfiles.validate(importData.allProdProfiles, importData.allOrgs, importData.allProducts, intl);

    await ImportUserGroups.validate(
      importData.allUserGroups,
      importData.allOrgs,
      importData.allProducts,
      importData.allProdProfiles,
      intl
    );

    await ImportAdmins.validate(
      importData.allAdmins,
      importData.allOrgs,
      importData.allProducts,
      importData.allProdProfiles,
      importData.allUserGroups,
      intl
    );

    await ImportOrgPolicies.validate(importData.allOrgPolicies, importData.allOrgs, intl);
  }

  /**
   * Import the data into the org hierarchy
   */
  static async importData(importData: ImportData): Promise<ChangeCount> {
    const allChangeCounts: ChangeCount[] = [];

    // import the org into hierarchy
    allChangeCounts.push(ImportOrganizations.import(importData.allOrgs));

    allChangeCounts.push(ImportProductsAndResources.import(importData.allProducts, importData.allOrgs));

    // ImportProductProfiles.import needs to wait for profile resources (call to /default-profile-resources) might not have been completed for creating profiles
    allChangeCounts.push(await ImportProductProfiles.import(importData.allProdProfiles));

    allChangeCounts.push(ImportUserGroups.import(importData.allUserGroups));

    allChangeCounts.push(ImportAdmins.import(importData.allAdmins));

    allChangeCounts.push(ImportOrgPolicies.import(importData.allOrgPolicies));

    return {
      createCount: _.sumBy(allChangeCounts, (changeCount: ChangeCount): number => changeCount.createCount),
      updateCount: _.sumBy(allChangeCounts, (changeCount: ChangeCount): number => changeCount.updateCount),
      deleteCount: _.sumBy(allChangeCounts, (changeCount: ChangeCount): number => changeCount.deleteCount),
    };
  }

  /**
   * Set resourceOperation = operation field. The two fields are same for xlsx and csv format.
   * The two fields can be different for json import and hence should not be called for json import.
   */
  static setResourceOperationFromOperationForProfile(allProdProfile: ImportProductProfilesDM[]): void {
    _.forEach(allProdProfile, (profile: ImportProductProfilesDM): void => {
      // eslint-disable-next-line no-param-reassign
      profile.resourceOperation = profile.operation;
    });
  }

  /**
   * Set profileOperation = operation field. The two fields are same for xlsx and csv format.
   * The two fields can be different for json import and hence should not be called for json import.
   */
  static setprofileResourceOperationFromOperationForUserGroups(allUserGroups: ImportUserGroupsDM[]): void {
    _.forEach(allUserGroups, (userGroup: ImportUserGroupsDM): void => {
      // eslint-disable-next-line no-param-reassign
      userGroup.profileOperation = userGroup.operation;
    });
  }

  /**
   * Get all resources from all the products as a list
   */
  static getAllResources(allProducts: ImportProductsDM[]): ImportResourcesDM[] {
    const allResources: ImportResourcesDM[] = [];
    allProducts.forEach((product: ImportProductsDM): void => {
      if (product.resources !== undefined) {
        allResources.push(...product.resources);
      }
    });
    return allResources;
  }

  static validateInputDataTypes(importData: ImportData, intl: IntlShape): void {
    const { formatMessage } = intl;
    ValidateTypeInfo.validate<ImportOrganizationsDM>(
      importData.allOrgs,
      OrganizationTypeInfo,
      formatMessage(messages.OrganizationsLabel),
      intl
    );

    ValidateTypeInfo.validate<ImportProductsDM>(
      importData.allProducts,
      ProductsTypeInfo,
      formatMessage(messages.ProductsLabel),
      intl
    );

    ValidateTypeInfo.validate<ImportResourcesDM>(
      ImportHelper.getAllResources(importData.allProducts),
      ResourceTypeInfo,
      formatMessage(messages.ResourcesLabel),
      intl
    );

    ValidateTypeInfo.validate<ImportProductProfilesDM>(
      importData.allProdProfiles,
      ProdProfileTypeInfo,
      formatMessage(messages.ProductProfilesLabel),
      intl
    );

    ValidateTypeInfo.validate<ImportUserGroupsDM>(
      importData.allUserGroups,
      UserGroupsTypeInfo,
      formatMessage(messages.UserGroupsLabel),
      intl
    );

    ValidateTypeInfo.validate<ImportAdminsDM>(
      importData.allAdmins,
      AdminTypeInfo,
      formatMessage(messages.AdminLabel),
      intl
    );

    ValidateTypeInfo.validate<ImportOrgPoliciesDM>(
      importData.allOrgPolicies,
      OrgPoliciesTypeInfo,
      formatMessage(messages.OrgPoliciesLabel),
      intl
    );
  }

  /**
   * Generate all API calls to be made to load data for import operation.
   * NOTE: None of the API call is actually started during this fn's execution. The API calls have to explicitely called by the caller of this function.
   * @param importData all data from import file
   * @returns list of fn where each fn returns a promise for an API call to load data
   */
  static generateAllAPICalls(importData: ImportData): { (): Promise<void> }[] {
    const allDataPromises: { (): Promise<void> }[] = [];

    allDataPromises.push(ImportOrganizations.loadOrgDetails(importData.allOrgs));

    allDataPromises.push(...ImportProductsAndResources.loadProducts(importData.allProducts, importData.allOrgs));

    allDataPromises.push(...ImportProductProfiles.loadProductsAndProdProfiles(importData.allProdProfiles));

    allDataPromises.push(...ImportUserGroups.loadUserGroupsAndProdProfile(importData.allUserGroups));

    allDataPromises.push(...ImportAdmins.loadAdmins(importData.allAdmins));

    allDataPromises.push(...ImportOrgPolicies.loadOrgPolicies(importData.allOrgPolicies));

    return allDataPromises;
  }

  static async loadAllData(
    allDataPromises: { (): Promise<void> }[],
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<void> {
    const CHUNK_SIZE = 10;
    const chunks = _.chunk(allDataPromises, CHUNK_SIZE);

    let promisesCompleted = 0;
    for (let i = 0; i < chunks.length; i++) {
      await Promise.all(chunks[i].map((fn) => fn()));
      promisesCompleted += chunks[i].length;
      const percentageOfDataLoaded = Math.ceil((promisesCompleted * 100) / allDataPromises.length);
      // data loading is expected to consume 80% of the total time required for import.
      // we find the percentage of import operation completed
      // for e.g. if percentageOfDataLoaded=3%, then percentageOfImportOperation=2.4%
      // if percentageOfDataLoaded=20%, then percentageOfImportOperation=16%
      // if percentageOfDataLoaded=100%, then percentageOfImportOperation=80%
      const DATA_LOAD_PERCENTAGE = 80;
      const percentageOfImportOperation = (percentageOfDataLoaded * DATA_LOAD_PERCENTAGE) / 100;
      // round to nearted integer
      updateProgressCallback(Math.round(percentageOfImportOperation));
    }
  }
}

class ImportJSON {
  static getImportData(orgDataJSON: OrgDataJSON): ImportData {
    const allOrgs = orgDataJSON.Organizations;
    const allProducts = ImportJSON.getAllProducts(orgDataJSON);
    const allProductProfiles = ImportJSON.getAllProductProfiles(orgDataJSON);
    const allUserGroups = ImportJSON.getAllUserGroups(orgDataJSON);
    const allAdmins = ImportJSON.getAllAdmins(orgDataJSON);
    const allOrgPolicies = ImportJSON.getAllOrgPolicies(orgDataJSON);
    return {
      allOrgs: allOrgs !== undefined ? allOrgs : [],
      allProducts: allProducts !== undefined ? allProducts : [],
      allProdProfiles: allProductProfiles !== undefined ? allProductProfiles : [],
      allUserGroups: allUserGroups !== undefined ? allUserGroups : [],
      allAdmins: allAdmins !== undefined ? allAdmins : [],
      allOrgPolicies: allOrgPolicies !== undefined ? allOrgPolicies : [],
    };
  }

  /**
   * Overrides the licenseId on the resources with the id of the Product in which the resources are enclosed
   */
  static overrideLicenseIdOnResources(allProducts: ImportProductsDM[]): void {
    _.forEach(allProducts, (product: ImportProductsDM): void => {
      if (product.resources !== undefined) {
        product.resources.forEach((resource: ImportResourcesDM): void => {
          // set licenseId of the enclosing product
          // eslint-disable-next-line no-param-reassign
          resource.licenseId = product.licenseId;
        });
      }
    });
  }

  /**
   * Get all the org policies from all orgs as a list.
   * NOTE: This fn also overrides the orgId on the org policy with the id of the org
   * in which the respective org policy is enclosed
   */
  static getAllOrgPolicies(orgDataJSON: OrgDataJSON): ImportOrgPoliciesDM[] {
    const allOrgPolicies: ImportOrgPoliciesDM[] = [];
    _.forEach(orgDataJSON.Organizations, (orgJSON: OrganizationsJSON): void => {
      if (orgJSON.orgPolicies !== undefined) {
        // set orgId of the enclosing org
        // eslint-disable-next-line no-param-reassign
        orgJSON.orgPolicies.orgId = orgJSON.id;
        allOrgPolicies.push(orgJSON.orgPolicies);
      }
    });
    return allOrgPolicies;
  }

  /**
   * Get all the admins from all orgs as a list.
   * NOTE: This fn also overrides the orgId on the admins with the id of the org
   * in which the respective admins are enclosed.
   */
  static getAllAdmins(orgDataJSON: OrgDataJSON): ImportAdminsDM[] {
    const allAdmins: ImportAdminsDM[] = [];
    _.forEach(orgDataJSON.Organizations, (orgJSON: OrganizationsJSON): void => {
      if (orgJSON.admins !== undefined) {
        orgJSON.admins.forEach((admin: ImportAdminsDM): void => {
          // set orgId of the enclosing org
          // eslint-disable-next-line no-param-reassign
          admin.orgId = orgJSON.id;
        });
        allAdmins.push(...orgJSON.admins);
      }
    });
    return allAdmins;
  }

  /**
   * Get all the products from all orgs as a list.
   * NOTE: This fn also overrides the orgId on the Products with the id of the org
   * in which the respective products are enclosed.
   */
  static getAllProducts(orgDataJSON: OrgDataJSON): ImportProductsDM[] {
    const allProducts: ImportProductsDM[] = [];
    _.forEach(orgDataJSON.Organizations, (orgJSON: OrganizationsJSON): void => {
      if (orgJSON.products !== undefined) {
        orgJSON.products.forEach((product: ImportProductsDM): void => {
          // set orgId of the enclosing org
          // eslint-disable-next-line no-param-reassign
          product.orgId = orgJSON.id;
          _.forEach(product.resources, (res: ImportResourcesDM): void => {
            // set the productId of the enclosing product
            res.licenseId = product.licenseId;
          });
        });
        allProducts.push(...orgJSON.products);
      }
    });
    // override the productId on Resources with the enclosed productId
    ImportJSON.overrideLicenseIdOnResources(allProducts);
    return allProducts;
  }

  static convertProductProfilesJSONToImportProductProfilesDM(
    prodProfileJSON: ProductProfilesJSON
  ): ImportProductProfilesDM[] {
    if (!_.isNil(prodProfileJSON.resources) && prodProfileJSON.resources.length > 0) {
      return _.map(prodProfileJSON.resources, (res: ImportResourcesProfilesDM): ImportProductProfilesDM => {
        return {
          productProfileId: prodProfileJSON.productProfileId,
          productProfileName: prodProfileJSON.productProfileName,
          licenseId: prodProfileJSON.licenseId,
          orgId: prodProfileJSON.orgId,
          notifications: prodProfileJSON.notifications,
          operation: prodProfileJSON.operation,
          resourceId: res.resourceId,
          quota: res.quota,
          selected: res.selected,
          resourceOperation: res.operation,
        };
      });
    }
    return [
      {
        productProfileId: prodProfileJSON.productProfileId,
        productProfileName: prodProfileJSON.productProfileName,
        licenseId: prodProfileJSON.licenseId,
        orgId: prodProfileJSON.orgId,
        notifications: prodProfileJSON.notifications,
        operation: prodProfileJSON.operation,
        quota: '0', // set default quota to 1
      },
    ];
  }

  /**
   * Convert product profiles format of JSON to common ImportProductProfilesDM
   */
  static getAllProductProfiles(orgDataJSON: OrgDataJSON): ImportProductProfilesDM[] {
    const allProdProfileJSON: ProductProfilesJSON[] = [];
    _.forEach(orgDataJSON.Organizations, (orgJSON: OrganizationsJSON): void => {
      if (orgJSON.productProfiles !== undefined) {
        orgJSON.productProfiles.forEach((profile: ProductProfilesJSON): void => {
          // set the orgId of the enclosing org
          // eslint-disable-next-line no-param-reassign
          profile.orgId = orgJSON.id;
        });
        allProdProfileJSON.push(...orgJSON.productProfiles);
      }
    });

    const allProdProfiles: ImportProductProfilesDM[] = [];
    allProdProfileJSON.forEach((prof: ProductProfilesJSON): void => {
      allProdProfiles.push(...this.convertProductProfilesJSONToImportProductProfilesDM(prof));
    });
    return allProdProfiles;
  }

  static convertUserGroupJSONToImportUserGroupsDM(userGroupsJSON: UserGroupsJSON): ImportUserGroupsDM[] {
    // check if there is any profile to be added
    if (!_.isNil(userGroupsJSON.profiles) && userGroupsJSON.profiles.length > 0) {
      return _.map(userGroupsJSON.profiles, (prof: ProfilesForUserGroupsJSON): ImportUserGroupsDM => {
        return {
          userGroupId: userGroupsJSON.userGroupId,
          userGroupName: userGroupsJSON.userGroupName,
          userGroupDescription: userGroupsJSON.userGroupDescription,
          orgId: userGroupsJSON.orgId,
          operation: userGroupsJSON.operation,
          productProfileId: prof.productProfileId,
          licenseId: prof.licenseId,
          profileOperation: prof.operation,
        };
      });
    }
    return [
      {
        userGroupId: userGroupsJSON.userGroupId,
        userGroupName: userGroupsJSON.userGroupName,
        userGroupDescription: userGroupsJSON.userGroupDescription,
        orgId: userGroupsJSON.orgId,
        operation: userGroupsJSON.operation,
      },
    ];
  }

  /**
   * Convert user groups profiles format of JSON to common ImportUserGroupsDM
   */
  static getAllUserGroups(orgDataJSON: OrgDataJSON): ImportUserGroupsDM[] {
    const allUserGroups: UserGroupsJSON[] = [];
    _.forEach(orgDataJSON.Organizations, (orgJSON: OrganizationsJSON): void => {
      if (orgJSON.userGroups !== undefined) {
        orgJSON.userGroups.forEach((userGroup: UserGroupsJSON): void => {
          // set the orgId of the enclosing org
          // eslint-disable-next-line no-param-reassign
          userGroup.orgId = orgJSON.id;
        });
        allUserGroups.push(...orgJSON.userGroups);
      }
    });

    const allUserGroupsDM: ImportUserGroupsDM[] = [];
    allUserGroups.forEach((userGroup: UserGroupsJSON): void => {
      allUserGroupsDM.push(...this.convertUserGroupJSONToImportUserGroupsDM(userGroup));
    });
    return allUserGroupsDM;
  }

  static async import(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    // get the Org Data for Json format
    const orgDataJSON: OrgDataJSON = JSONToDataModel.convertToDataModel<OrgDataJSON>(data, intl);
    const importData: ImportData = ImportJSON.getImportData(orgDataJSON);

    return await ImportHelper.validateAndImportDataForJSONAndXLSX(importData, intl, updateProgressCallback);
  }
}

/**
 * Convert csv input file data into Data Models.
 */
class ImportCSV {
  static CSVLabel = 'CSV';

  static async handleOrgImport(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    // get all input data
    const allOrgs: ImportOrganizationsDM[] = CSVToDataModel.convertToDataModelAr<ImportOrganizationsDM>(
      data,
      OrganizationTypeInfo,
      intl
    );

    // validate types for input data
    ValidateTypeInfo.validate<ImportOrganizationsDM>(
      allOrgs,
      OrganizationTypeInfo,
      intl.formatMessage(messages.OrganizationsLabel),
      intl
    );

    // load all data for concerned orgs
    const allDataPromises = [ImportOrganizations.loadOrgDetails(allOrgs)];
    await ImportHelper.loadAllData(allDataPromises, updateProgressCallback);

    // validate the orgs
    await ImportOrganizations.validate(allOrgs, intl);

    // import orgs in hierarchy
    return ImportOrganizations.import(allOrgs);
  }

  static async handleProductProfilesImport(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    // get all product profiles
    const allProdProfiles: ImportProductProfilesDM[] = CSVToDataModel.convertToDataModelAr<ImportProductProfilesDM>(
      data,
      ProdProfileTypeInfo,
      intl
    );
    // make resourceOperation = operation field
    ImportHelper.setResourceOperationFromOperationForProfile(allProdProfiles);

    // validate types for required fields
    ValidateTypeInfo.validate<ImportProductProfilesDM>(
      allProdProfiles,
      ProdProfileTypeInfo,
      intl.formatMessage(messages.ProductProfilesLabel),
      intl
    );

    const allProfilePromises = ImportProductProfiles.loadProductsAndProdProfiles(allProdProfiles);
    await ImportHelper.loadAllData(allProfilePromises, updateProgressCallback);

    // validate product profiles
    await ImportProductProfiles.validate(allProdProfiles, [], [], intl);

    // import product profiles into hierarchy
    // ImportProductProfiles.import needs to wait for profile resources (call to /default-profile-resources) might not have been completed for creating profiles
    return await ImportProductProfiles.import(allProdProfiles);
  }

  static async handleUserGroupsImport(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    const allUserGroups: ImportUserGroupsDM[] = CSVToDataModel.convertToDataModelAr<ImportUserGroupsDM>(
      data,
      UserGroupsTypeInfo,
      intl
    );
    ImportHelper.setprofileResourceOperationFromOperationForUserGroups(allUserGroups);

    // validate types for required fields
    ValidateTypeInfo.validate<ImportUserGroupsDM>(
      allUserGroups,
      UserGroupsTypeInfo,
      intl.formatMessage(messages.UserGroupsLabel),
      intl
    );

    const allUserGroupPromises = ImportUserGroups.loadUserGroupsAndProdProfile(allUserGroups);
    await ImportHelper.loadAllData(allUserGroupPromises, updateProgressCallback);

    // validate user groups
    // As only one detail can be uploaded at a time, all the 3 parameters are empty
    await ImportUserGroups.validate(allUserGroups, [], [], [], intl);

    // import user groups
    return ImportUserGroups.import(allUserGroups);
  }

  static async handleAdminsImport(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    const allAdmins: ImportAdminsDM[] = CSVToDataModel.convertToDataModelAr<ImportAdminsDM>(data, AdminTypeInfo, intl);

    // validate types for required fields
    ValidateTypeInfo.validate<ImportAdminsDM>(allAdmins, AdminTypeInfo, intl.formatMessage(messages.AdminLabel), intl);

    const allAdminPromises = ImportAdmins.loadAdmins(allAdmins);
    await ImportHelper.loadAllData(allAdminPromises, updateProgressCallback);

    await ImportAdmins.validate(allAdmins, [], [], [], [], intl);

    return ImportAdmins.import(allAdmins);
  }

  static async handleOrgPoliciesImport(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    const allOrgPolicies: ImportOrgPoliciesDM[] = CSVToDataModel.convertToDataModelAr<ImportOrgPoliciesDM>(
      data,
      OrgPoliciesTypeInfo,
      intl
    );

    // validate types for required fields
    ValidateTypeInfo.validate<ImportOrgPoliciesDM>(
      allOrgPolicies,
      OrgPoliciesTypeInfo,
      intl.formatMessage(messages.OrgPoliciesLabel),
      intl
    );

    const allOrgPolicyPromises = ImportOrgPolicies.loadOrgPolicies(allOrgPolicies);
    await ImportHelper.loadAllData(allOrgPolicyPromises, updateProgressCallback);

    await ImportOrgPolicies.validate(allOrgPolicies, [], intl);

    return ImportOrgPolicies.import(allOrgPolicies);
  }

  /**
   * For a provided file name, split the fileName on "." and return the last second part.
   * NOTE: the last second part corresponds to the detail type of the file. If any change is made to this logic,
   * equivalent change must be made in getFileName fn of
   * com.adobe.banyansvc.service_wrappers.ExportOrgDataService class in banyan svc
   *
   * For e.g. for fileName = export_6C2256BE5D51EE750A494006.organizations.csv return "organizations"
   * for fileName = export_6C2256BE5D51EE750A494006.organizations (2).csv return "organizations (2)""
   * for fileName = export.now.now.admins.csv return "admins"
   * for fileName = export_abc.csv return ''
   */
  static getDetailTypeFromFileName(fileName: string): string {
    const parts = fileName.split('.');
    if (parts.length >= 2) {
      return parts[parts.length - 2].toUpperCase();
    }
    return '';
  }

  static async import(
    data: string,
    fileName: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    // validate contents of CSV file
    CSVToDataModel.validateCSVFileContent(data, intl);

    const detailType = ImportCSV.getDetailTypeFromFileName(fileName);

    if (detailType.includes(Details.Organizations.toUpperCase())) {
      return await ImportCSV.handleOrgImport(data, intl, updateProgressCallback);
    }

    if (detailType.includes(Details.ProductProfiles.toUpperCase())) {
      return await ImportCSV.handleProductProfilesImport(data, intl, updateProgressCallback);
    }

    if (detailType.includes(Details.UserGroups.toUpperCase())) {
      return await ImportCSV.handleUserGroupsImport(data, intl, updateProgressCallback);
    }

    if (detailType.includes(Details.Admins.toUpperCase())) {
      return await ImportCSV.handleAdminsImport(data, intl, updateProgressCallback);
    }

    if (detailType.includes(Details.OrgPolicies.toUpperCase())) {
      return await ImportCSV.handleOrgPoliciesImport(data, intl, updateProgressCallback);
    }

    const { formatMessage } = intl;
    // NOTE: should be checked after product profiles
    if (detailType.includes(Details.Products.toUpperCase())) {
      throw Error(formatMessage(messages.ProductNotExportedForCSV));
    }

    throw Error(formatMessage(messages.InvalidFileFormatForCSV));
  }
}

class ImportXLSX {
  /**
   * For XLSX file, products and resources are in different XLSX sheets.
   * This fn encloses the product resources within the corresponding product
   */
  static getAllProducts(workBook: WorkBook, intl: IntlShape): ImportProductsDM[] {
    // get all products
    const productsAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.Product);
    const allProducts =
      productsAsCSVString !== undefined && productsAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportProductsDM>(productsAsCSVString, ProductsTypeInfo, intl)
        : [];

    // get all resources
    const resourcesAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.Resources);
    const allResources =
      resourcesAsCSVString !== undefined && resourcesAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportResourcesDM>(resourcesAsCSVString, ResourceTypeInfo, intl)
        : [];

    // enclose resources within corresponding product
    _.forEach(allResources, (resource: ImportResourcesDM): void => {
      const product = _.find(allProducts, (prod: ImportProductsDM): boolean =>
        _.isEqual(prod.licenseId, resource.licenseId)
      );
      if (product !== undefined) {
        if (product.resources === undefined) {
          product.resources = [];
        }
        product.resources.push(resource);
      }
    });

    return allProducts;
  }

  static getImportData(workBook: WorkBook, intl: IntlShape): ImportData {
    const orgsAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.Organizations);
    const allOrgs =
      orgsAsCSVString !== undefined && orgsAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportOrganizationsDM>(orgsAsCSVString, OrganizationTypeInfo, intl)
        : [];

    const prodProfilesAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.ProductProfiles);
    const allProdProfiles =
      prodProfilesAsCSVString !== undefined && prodProfilesAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportProductProfilesDM>(
            prodProfilesAsCSVString,
            ProdProfileTypeInfo,
            intl
          )
        : [];
    ImportHelper.setResourceOperationFromOperationForProfile(allProdProfiles);

    const userGroupsAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.UserGroups);
    const allUserGroups =
      userGroupsAsCSVString !== undefined && userGroupsAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportUserGroupsDM>(userGroupsAsCSVString, UserGroupsTypeInfo, intl)
        : [];
    ImportHelper.setprofileResourceOperationFromOperationForUserGroups(allUserGroups);

    const adminsAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.Admins);
    const allAdmins =
      adminsAsCSVString !== undefined && adminsAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportAdminsDM>(adminsAsCSVString, AdminTypeInfo, intl)
        : [];

    const orgPoliciesAsCSVString = XLSXToDataModel.readSheetAsCSV(workBook, XLSXSheetNames.OrgPolicies);
    const allOrgPolicies =
      orgPoliciesAsCSVString !== undefined && orgPoliciesAsCSVString !== ''
        ? CSVToDataModel.convertToDataModelAr<ImportOrgPoliciesDM>(orgPoliciesAsCSVString, OrgPoliciesTypeInfo, intl)
        : [];

    return {
      allProducts: this.getAllProducts(workBook, intl),
      allOrgs,
      allProdProfiles,
      allUserGroups,
      allAdmins,
      allOrgPolicies,
    };
  }

  static async import(
    data: string,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    const workBook = XLSXToDataModel.getXLSXWorkBook(data);
    const importData = ImportXLSX.getImportData(workBook, intl);

    return await ImportHelper.validateAndImportDataForJSONAndXLSX(importData, intl, updateProgressCallback);
  }
}

class Import {
  static async import(
    fileData: FileData,
    intl: IntlShape,
    updateProgressCallback: (completedPercentage: number) => void
  ): Promise<ChangeCount> {
    let changeCount: ChangeCount;
    switch (fileData.fileExtension) {
      case FileExtension.CSV.extName:
        changeCount = await ImportCSV.import(fileData.data, fileData.fileName, intl, updateProgressCallback);
        break;

      case FileExtension.JSON.extName:
        changeCount = await ImportJSON.import(fileData.data, intl, updateProgressCallback);
        break;

      case FileExtension.XLSX.extName:
        changeCount = await ImportXLSX.import(fileData.data, intl, updateProgressCallback);
        break;

      default:
        const { formatMessage } = intl;
        throw Error(formatMessage(messages.InvalidFileFormat));
    }

    // select the root org at the end of import
    OrgSelectionUtil.updateOrgSelection(OrgPickerController.getActiveOrgId() as string);
    return changeCount;
  }
}

export default Import;
