import React from 'react';
import { useIntl, IntlProvider } from 'react-intl';
import Dialog from '@react/react-spectrum/Dialog';
import _ from 'lodash';
import { MESSAGES } from '../../Messages';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';

/**
 * This dialog could be used to avoid duplication of lot of <Dialog/> properties.
 * e.g. To show errors to users when API calls (such as GET API(s)) fail OR
 *      for confirmation as well as destructive dialogs where title and other props would remain same except inner content.
 *
 * TODO: Use this dialog to replace existing Dialogs as applicable.
 */
export interface UserMessageDialogProps {
  msgId: {};
  variant?: 'confirmation' | 'information' | 'destructive' | 'error';
  className?: string;
  role?: 'dialog' | 'alertdialog';
  messageData?: {};
  onProceed?: () => Promise<void>;
}

const UserMessageDialog: React.FC<UserMessageDialogProps> = (props: UserMessageDialogProps) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  // explicit role check is required as ModalContainer.show() passes role as 'document' resulting into console warning.
  const role: 'dialog' | 'alertdialog' =
    props.role?.includes('dialog') || props.role?.includes('alertdialog') ? props.role : 'dialog';

  let title = '';
  let confirmLabel = '';
  switch (props.variant) {
    case 'confirmation':
      title = formatMessage(MESSAGES.ConfirmationDialogTitle);
      confirmLabel = formatMessage(MESSAGES.MessageDialogProceed);
      break;
    case 'destructive':
      title = formatMessage(MESSAGES.DestructiveDialogTitle);
      confirmLabel = formatMessage(MESSAGES.MessageDialogProceed);
      break;
    case 'error':
      title = formatMessage(MESSAGES.ErrorDialogTitle);
      confirmLabel = formatMessage(MESSAGES.MessageDialogConfirm);
      break;
    case 'information':
    default:
      title = formatMessage(MESSAGES.InformationDialogTitle);
      confirmLabel = formatMessage(MESSAGES.MessageDialogConfirm);
  }

  return (
    <Dialog
      {...props} // required as onClose() is provided by ModalTrigger
      title={title}
      role={role}
      className={props.className}
      variant={props.variant}
      confirmLabel={confirmLabel}
      cancelLabel={formatMessage(MESSAGES.MessageDialogCancel)}
      onConfirm={(): void => {
        if (!_.isUndefined(props.onProceed)) props.onProceed();
      }}
    >
      {formatMessage(props.msgId, props.messageData)}
    </Dialog>
  );
};

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function UserMessageDialogIntl(props: Omit<UserMessageDialogProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <UserMessageDialog {...props} />
    </IntlProvider>
  );
}

export default UserMessageDialogIntl;
