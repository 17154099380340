import * as _ from 'lodash';
import OrgInfo from './OrgInfo';
import OrganizationListProvider from '../../providers/OrganizationListProvider';

class OrgInfoService {
  /**
   * Retrieve information about orgs
   * (Handles the request limit)
   * @param orgIds ids of the orgs to retrieve information for
   * @param fullPathName if true, the fullPathName will be included in the info (name includes org name and names of all parents to root)
   * @param parent if true, the parent org id will be included in the info
   * @param root if true, the root org id will be included in the info
   * @param allocations if true, a boolean reporting whether the orgs have directly allocated products will be included in the info
   * @param adminPermissions if true, a boolean reporting whether the user has RW Global Admin permissions (explicit or implicit) on the orgs will be included in the info
   * @param editableOrg if true, a boolean reporting whether the orgs are readOnly will be included in the info
   * @param nameIsGloballyUnique if true, a boolean reporting whether the org names are globally unique amongst enterprise orgs will be included in the info
   */
  static async getOrgInfo(
    orgIds: string[],
    fullPathName: boolean,
    parent: boolean,
    root: boolean,
    allocations: boolean,
    adminPermissions: boolean,
    editableOrg: boolean,
    nameIsGloballyUnique: boolean
  ): Promise<OrgInfo[]> {
    const MAX_ORG_IDS = 100; // banyansvc getOrgInfo has a limit of 100 org ids

    // break apart orgIds into groups
    const groupedOrgIds: string[][] = _.chunk(orgIds, MAX_ORG_IDS);
    let orgInfos: OrgInfo[] = [];
    const promises: Promise<OrgInfo[]>[] = [];

    // for each group call provider (so that we don't go over the orgIds limit)
    _.forEach(groupedOrgIds, (orgIdsGroup: string[]): void => {
      promises.push(
        OrganizationListProvider.getOrgInfo(
          orgIdsGroup,
          fullPathName,
          parent,
          root,
          allocations,
          adminPermissions,
          editableOrg,
          nameIsGloballyUnique
        )
      );
    });

    // merge all results
    const groupedInfo: OrgInfo[][] = await Promise.all(promises);
    _.forEach(groupedInfo, (infoGroup: OrgInfo[]): void => {
      orgInfos = orgInfos.concat(infoGroup);
    });
    return orgInfos;
  }

  /**
   * Retrieves information relevant to re-parent operations without access to an OrgMasterTree
   * The following information is included
   * - parentOrgId
   * - hasDirectAllocations
   * - hasRWGAPermission
   * - isReadOnlyOrg
   *
   * @param orgIds ids of orgs to retrieve re-parent information for
   */
  static async getReparentInfo(orgIds: string[], reparentToStandalone: boolean): Promise<OrgInfo[]> {
    return await OrgInfoService.getOrgInfo(orgIds, false, true, false, true, true, true, reparentToStandalone);
  }

  /**
   * Retrieves the root org id for a given org
   *
   * @param orgId id of org to find root org id for
   */
  static async getRootOrgId(orgId: string): Promise<string> {
    const results: OrgInfo[] = await OrgInfoService.getOrgInfo([orgId], false, false, true, false, false, false, false);
    const orgInfo: OrgInfo = results[0]; // there should only be 1 result since only 1 orgId was given
    return orgInfo.rootOrgId ? orgInfo.rootOrgId : '';
  }
}
export default OrgInfoService;
