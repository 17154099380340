import * as _ from 'lodash';
import log from 'loglevel';
import { SelectOption } from '@react/react-spectrum/Select';
import React from 'react';
import { defineMessages, IntlProvider, WrappedComponentProps } from 'react-intl';

import { LocaleSettings } from '../../services/locale/LocaleSettings';
import StepControl from './StepControl/StepControl';
import OrgPickerController from '../../services/organization/OrgPickerController';
import HeaderConsts from '../../components/BanyanShell/HeaderConstants';
import SearchOrganizationContent from './SearchOrganizationContent/SearchOrganizationContent';
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import { GoUrlKeys } from '../../components/GoUrl/GoUrl';
import SelectOrganization from './SelectOrganization/SelectOrganization';
import { MESSAGES } from '../Messages';
import { Org } from '../../services/organization/Org';
import { ObjectTypes, OrgOperation } from '../../services/orgMaster/OrgMaster';
import { UContractRollbackData } from '../../services/orgMaster/UContractRollback';
import CommandInterface from '../../services/Commands/CommandInterface';
import TempIdGenerator from '../../services/utils/TempIdGenerator';
import { CommandService } from '../../services/Commands/CommandService';
import withRouter, { RouteComponentProps } from '../../services/utils/withRouter';

interface RevertAllocationContractToETLAInternalProps extends RouteComponentProps, WrappedComponentProps {
  setPath: () => void;
}

interface RevertAllocationContractToETLAInternalState {
  rootOrg: SelectOption | undefined;
  selectedChildOrgs: Org[];
}

const messages = defineMessages({
  RevertAllocationContractsToETLA: {
    id: 'RevertAllocationContractsToETLA.Title',
    defaultMessage: 'Revert allocation contracts to ETLA',
  },
  RevertAllocationDescription: {
    id: 'RevertAllocationContractsToETLA.Description',
    defaultMessage:
      'Adobe agent can revert the allocation contracts back to ETLA contracts. After reverting the contract, the ETLA contract will reflect what existed prior to the conversion. Any products allocated to the org before this reversal remain in the org',
  },
  SelectOrganizationTitleRevertToETLA: {
    id: 'RevertAllocationContractsToETLA.SelectOrganizationTitleRevertToETLA',
    defaultMessage: 'Select organization to revert to ETLA',
  },
  RollbackInfoMessage: {
    id: 'RevertAllocationContractsToETLA.RollbackInfoMessage',
    defaultMessage:
      "Reverting a contract is completed one org at a time. An Adobe Agent can revert an org's allocation contract back to ETLA if the conversion from ETLA to allocation contract has occurred in the past for that org. Any products allocated to the org before this reversal remain in the org",
  },
});

class RevertAllocationContractToETLAInternal extends React.Component<
  RevertAllocationContractToETLAInternalProps,
  RevertAllocationContractToETLAInternalState
> {
  constructor(props: RevertAllocationContractToETLAInternalProps) {
    super(props);
    this.state = {
      rootOrg: undefined,
      selectedChildOrgs: [],
    };
    this.stepControllerRef = React.createRef();
  }

  // ref for StepController component to directly navigate when an org is selected
  stepControllerRef: any;

  enableNextOrReviewPendingBtn = (step: number): boolean => {
    // on step 0, if an org is not selected, disable the next button
    if (step === 0 && this.state.rootOrg === undefined) {
      return false;
    }
    // on step 1, for rollback, there should be a single org selected
    if (step === 1 && this.state.selectedChildOrgs.length !== 1) {
      return false;
    }
    return true; // otherwise enable the 'review' button
  };

  /**
   * set the org selected for contracts.
   * NOTE: the function accepts an array of selected orgs as input, but ideally there should only be a single org selected for contracts
   * In case if more than one orgs are selected, only the first org is selected and the other orgs are ignored.
   */
  setRootOrg = (newRootOrg: SelectOption[]): void => {
    if (newRootOrg.length > 0) {
      this.setState({ rootOrg: newRootOrg[0] }, () => {
        // navigate to next step directly once the org is selected
        if (this.stepControllerRef) {
          this.stepControllerRef.current.navigateToNextPage();
        }
      });
    }
  };

  /**
   * removes the orgs from the list of selected child Orgs
   */
  removeRootOrgs = (toBeRemovedOrgs: string[]): void => {
    this.setState(
      (
        prevState: RevertAllocationContractToETLAInternalState
      ): Pick<RevertAllocationContractToETLAInternalState, never> => {
        let { rootOrg } = prevState;

        // Remove the Orgs that are deselected by the user
        _.forEach(toBeRemovedOrgs, (toBeRemovedOrg: string): void => {
          if (toBeRemovedOrg === rootOrg?.value) {
            rootOrg = undefined;
          }
        });
        return { rootOrg };
      }
    );
  };

  addSelectedOrgs = (newSelectedOrgs: Org[]): void => {
    this.setState(
      (
        prevState: RevertAllocationContractToETLAInternalState
      ): Pick<RevertAllocationContractToETLAInternalState, never> => {
        let { selectedChildOrgs } = prevState;
        selectedChildOrgs.push(...newSelectedOrgs);
        selectedChildOrgs = _.uniqBy(selectedChildOrgs, 'id');
        return {
          selectedChildOrgs,
        };
      }
    );
  };

  removeSelectedOrgs = (orgsToBeRemoved: Org[]): void => {
    this.setState(
      (
        prevState: RevertAllocationContractToETLAInternalState
      ): Pick<RevertAllocationContractToETLAInternalState, never> => {
        const { selectedChildOrgs } = prevState;

        // Remove the Orgs that are deselected by the user
        _.forEach(orgsToBeRemoved, (org: Org): void => {
          _.remove(selectedChildOrgs, { id: org.id });
        });
        return { selectedChildOrgs };
      }
    );
  };

  /**
   * Clear state when back btn is clicked
   */
  onBackBtnClicked = (step: number): void => {
    if (step === 0) {
      this.setState({ rootOrg: undefined, selectedChildOrgs: [] });
    }
  };

  submit = (): void => {
    // NOTE: there can ONLY be ONE Rollback command in a job
    // Validate that no prior OrgMapper commands are pending
    if (this.state.selectedChildOrgs.length === 1 && CommandService.getEditsForOrgMapper().length < 1) {
      const NEW_CONTRACT_ID = TempIdGenerator.getTempIdAndIncrement();

      const org = this.state.selectedChildOrgs[0];
      const contractSwitchCommand: CommandInterface = this.generateContractRollbackCommand(
        NEW_CONTRACT_ID,
        org.id,
        org.name
      );
      CommandService.addEditForOrgMapper(contractSwitchCommand);

      this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
    } else {
      log.error('Contract rollback must be executed as a single command in a job.');
    }
  };

  generateContractRollbackCommand = (contractId: string, orgId: string, orgName: string): CommandInterface => {
    const elem: UContractRollbackData = {
      orgName,
      orgId,
      id: contractId,
    };
    return {
      elem,
      elemType: ObjectTypes.CONTRACT_ROLLBACK,
      operation: OrgOperation.CREATE,
      lastUpdatedAt: new Date().getTime(),
      messageData: {
        cmdDescription: [
          {
            cmdDescriptionCode: 'ROLLBACK_ALLOCATION_CONTRACT',
            cmdDescriptionParams: [elem.orgName],
          },
        ],
      },
    };
  };

  render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <AlertBanner
          style={{ marginLeft: '1.5rem' }}
          variant="error"
          header="Stop. This action should be taken as an exception. This action will revert allocations and contracts to previous states."
        />
        <StepControl
          pageTitle={formatMessage(messages.RevertAllocationContractsToETLA)}
          hideNextBtn
          enableNextOrReviewPendingBtn={this.enableNextOrReviewPendingBtn}
          backout={this.props.setPath}
          onSubmit={this.submit}
          disallowOrgSelection={false}
          ref={this.stepControllerRef}
          onBackBtnClicked={this.onBackBtnClicked}
        >
          <SearchOrganizationContent
            goUrlKey={GoUrlKeys.jobExecution} // to update here
            goUrlTarget="gac+abc" // to update here
            infoMessage={formatMessage(messages.RevertAllocationDescription)}
            label={formatMessage(MESSAGES.SearchOrganization)}
            selectedOrgs={this.state.rootOrg ? [this.state.rootOrg] : []}
            addSelectedOrgIds={this.setRootOrg}
            removeSelectedOrgIds={this.removeRootOrgs}
            multipleSelection={false}
            disallowOrgSelection={false}
            displaySelectedOrgsAsTagList={false}
            scrollableHeight="20rem"
          />
          <SelectOrganization
            goUrlKey={GoUrlKeys.jobExecution} // to update here
            goUrlTarget="gac+abc" // to update here
            infoMessage={formatMessage(messages.RollbackInfoMessage)}
            infoTitle={formatMessage(messages.SelectOrganizationTitleRevertToETLA)}
            label={formatMessage(MESSAGES.SelectOrganization)}
            rootOrg={this.state.rootOrg}
            allowMultipleSelection={false}
            {...this.props}
            selectedOrgs={this.state.selectedChildOrgs}
            addSelectedOrgs={this.addSelectedOrgs}
            removeSelectedOrgs={this.removeSelectedOrgs}
            isContractSwitchMode={false}
          />
        </StepControl>
      </div>
    );
  }
}

function RevertAllocationContractToETLA(props: Omit<RevertAllocationContractToETLAInternalProps, 'ref'>): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <RevertAllocationContractToETLAInternal {...props} />
    </IntlProvider>
  );
}

export default withRouter(RevertAllocationContractToETLA);
