import * as _ from 'lodash';
import ImportOperations from './ImportOperations';

class ImportOperationsUtils {
  static isOperationUpdate(operation: string | undefined): boolean {
    return !_.isNil(operation) && _.isEqual(operation.toUpperCase(), ImportOperations.UPDATE);
  }

  static isOperationCreate(operation: string | undefined): boolean {
    return !_.isNil(operation) && _.isEqual(operation.toUpperCase(), ImportOperations.CREATE);
  }

  static isOperationDelete(operation: string | undefined): boolean {
    return !_.isNil(operation) && _.isEqual(operation.toUpperCase(), ImportOperations.DELETE);
  }

  static isOperationValid(value: string | undefined): boolean {
    return this.isOperationCreate(value) || this.isOperationUpdate(value) || this.isOperationDelete(value);
  }
}
export default ImportOperationsUtils;
