export interface UContractRollbackData {
  id: string;
  orgName: string;
  orgId: string;
}

class UContractRollback implements UContractRollbackData {
  id: string;
  orgName: string;
  orgId: string;

  constructor(contractRollbackData: UContractRollbackData) {
    this.orgId = contractRollbackData.orgId;
    this.id = contractRollbackData.id;
    this.orgName = contractRollbackData.orgName;
  }

  /**
   * compare if this contract rollback is same as the other contract rollback
   */
  isEqual(otherContSwitch: UContractRollback) {
    return this.orgId === otherContSwitch.orgId && this.orgName === otherContSwitch.orgName;
  }
}

export default UContractRollback;
