import { defaultTheme, ProgressCircle, Provider as ProviderV3 } from '@adobe/react-spectrum';
import React, { Component } from 'react';
import Provider from '@react/react-spectrum/Provider';
import { Navigate, Route, Routes } from 'react-router-dom';
import _ from 'lodash';

import Button from '@react/react-spectrum/Button';

import { defineMessages, FormattedMessage, IntlProvider } from 'react-intl';

import consoleWarnDisable from './services/utils/consoleWarnDisable';

import Authentication from './services/authentication/Authentication';

import HeaderConstants, { WorkspaceLocale, WorkspaceUIDs } from './components/BanyanShell/HeaderConstants';
import BanyanShell from './components/BanyanShell/BanyanShell';
import Footer from './components/Footer/Footer';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import FullErrorPage from './components/FullErrorPage/FullErrorPage';
import NotFound from './components/NotFound/NotFound';
import AlertBanner from './components/AlertBanner/AlertBanner';
import './App.css';
import OrgPickerController from './services/organization/OrgPickerController';
import { Org } from './services/organization/Org';
import AdminPermission from './services/authentication/AdminPermission';
import OrgMigrationForAdobeAgent from './OrgMigration/OrgMigrationForAdobeAgent';
import OrgMigrationForGlobalAdmin from './OrgMigration/OrgMigrationForGlobalAdmin';

import { LocaleSettings } from './services/locale/LocaleSettings';

import ToastMaster from './services/toastMaster/ToastMaster';
import { VerificationProvider } from './providers/VerificationProvider';
import { AuthWindow, UserProfile } from './services/authentication/IMS';
import config from './configurations/config';
import Utils from './services/utils/Utils';
import DeepLinkParser from './components/DeepLinking/DeepLinkParser';
import RedirectToDeepLink from './components/DeepLinking/RedirectToDeepLink';
import FloodgateService from './services/floodgate/FloodgateService';
import UserProfileService from './services/authentication/UserProfileService';
import { ImsLibData } from './services/authentication/ImsData';
import Analytics from './Analytics/Analytics';
import ToastList from './components/BanyanToast/ToastList';
import { PandoraApiProvider } from './providers/PandoraApiProvider';

declare const window: AuthWindow;

interface State {
  userLoggedIn: boolean;
  workspacesLocale: WorkspaceLocale[]; // Workspaces available for navigation from the shell
  eventErrorMessage: string;
  showDefaultLocaleWarningDialog: boolean;
  deepLinkingError: string;
  canAccessGAC: boolean;
  isAdobeAgentWithNoOrgs: boolean;
  userProfile: UserProfile | undefined;
}

const messages = defineMessages({
  ServerNotResponding: {
    id: 'GAC.App.ServerNotResponding',
    defaultMessage: 'Server is not responding',
  },
  UnAuthorizedToView: {
    id: 'GAC.App.UnAuthorizedToView',
    defaultMessage: 'You do not have permission to view this application',
  },
  GACUnknownError: {
    id: 'GAC.App.GACUnknownError',
    defaultMessage: '{error} We’re working on the problem. Please try again later.',
  },
  ErrorLoggingIn: {
    id: 'GAC.App.ErrorLoggingIn',
    defaultMessage: 'Error logging in, try again',
  },
});

class App extends Component<any, State> {
  abortController: AbortController;

  public constructor(props: any) {
    super(props);
    // log.setDefaultLevel('debug'); // set the app's default log level to print Adobe Analytics events
    this.state = {
      userLoggedIn: false,
      workspacesLocale: [],
      eventErrorMessage: '',
      showDefaultLocaleWarningDialog: false,
      deepLinkingError: '',
      canAccessGAC: false,
      isAdobeAgentWithNoOrgs: false,
      userProfile: undefined,
    };
    this.abortController = new AbortController();
  }

  async componentDidMount(): Promise<void> {
    // Add analytics tag
    if (config.analytics && config.analytics.url) {
      const analyticsTag = document.createElement('script');
      analyticsTag.src = config.analytics.url;
      analyticsTag.async = true;
      document.body.appendChild(analyticsTag);
    }

    // sign out user if was previously signed in; to refetch the accesstoken
    if (window.adobeIMS) {
      window.adobeIMS.signOut();
    }

    if (!this.state.userLoggedIn) {
      // ensure the user's preferred locale is set before redirecting to IMS for authentication.
      LocaleSettings.init();
    }

    Authentication.setupIMS(this.updateState);

    await FloodgateService.initializeFeatureFlags();

    consoleWarnDisable(window.console, true);
  }

  public componentWillUnmount(): void {
    ToastMaster.detachToastList();
    this.abortController.abort();
  }

  private static async isError(): Promise<string> {
    const serverIsUp: boolean = await VerificationProvider.isBanyanSvcUp();
    if (!serverIsUp) {
      return Utils.getLocalizedMessage(messages.ServerNotResponding);
    }

    try {
      // first! initialize OrgPickerController.getOrgDataList() with manageble orgs before calling canAccess()
      await OrgPickerController.load();

      // Check if the user is an Agent or is a Global Admin/Viewer of at least one org.
      const canAccessGAC = await AdminPermission.canAccess();
      if (!canAccessGAC) {
        return Utils.getLocalizedMessage(messages.UnAuthorizedToView);
      }
    } catch (error) {
      return Utils.getLocalizedMessage(messages.GACUnknownError, { error: error.message });
    }
    return '';
  }

  private gainsightInit = (): void => {
    if (!window.adobeIMS) {
      throw new Error(Utils.getLocalizedMessage(messages.ErrorLoggingIn));
    }
    const activeOrg: Org | undefined = OrgPickerController.getActiveOrg();
    if (activeOrg) {
      if (config.gainsight && config.gainsight.tag) {
        // passing user and account objects to GainSight
        window.aptrinsic(
          'identify',
          {
            // User Fields
            // it is assumed that updateState() already called UserProfileService.initializeUserProfile(). Hence, UserProfileService.getUserProfile() should never be undefined
            id: UserProfileService.getUserProfile()?.userId, // Required for logged in app users. Pass user id to GainSight
            locale: LocaleSettings.getSelectedLanguageTag(), // Pass language tag to select localization for GainSight
          },
          {
            // Account Fields
            id: activeOrg.id, // Required
            name: activeOrg.name,
          }
        );
      }
    }
  };

  /**
   * imslib2.js generates various events after it is initialized (both before redirecting the user to IMS login and after the user is logged in).
   *
   * Refer: ImsData.ts to understand which imslib2 events invoke this method.
   *
   * @param imslibData information provided by imslib2 during authentication events
   */
  private updateState = async (imslibData?: ImsLibData): Promise<void> => {
    if (!window.adobeIMS) {
      throw new Error(Utils.getLocalizedMessage(messages.ErrorLoggingIn));
    }

    // If the user isn't logged in yet, just redirect the user to sign-in page. imslib2.js no longer does this implicitly.
    if (!Authentication.loggedIn()) {
      window.adobeIMS.signIn();
      return;
    }

    // call signOut() when token has expired. Similar to what ImsData.setHeartBeatInterval() does.
    if (imslibData && imslibData.tokenHasExpired && window.adobeIMS) {
      window.adobeIMS.signOut(); // proactively redirect the user to the SUSI login page once their session as expired, instead of leaving them on banyanui.
      return;
    }

    // Call initializeUserProfile() upfront since it is an async call. If not, rest of places that call UserProfileService.getUserProfile() will not wait for profile to be fetched.
    await UserProfileService.initializeUserProfile();

    const showDefaultLocaleWarningDialog: boolean = LocaleSettings.init((): void => {
      this.gainsightInit();
      this.setStateWrapper({});
    });

    const errorToDisplay = await App.isError(); //Important: isError() initializes OrgPickerController, which is a prerequisite to subsequent calls below.
    if (!Utils.isEmptyString(errorToDisplay)) {
      this.setStateWrapper({
        userLoggedIn: Authentication.loggedIn(),
        eventErrorMessage: errorToDisplay,
      });
      return;
    }

    const workspacesLocale: WorkspaceLocale[] = await HeaderConstants.getWorkspacesLocale(); // Once user is logged in, show navigation
    this.gainsightInit();
    Analytics.setData();

    const isAdobeAgentWithNoOrgs = await AdminPermission.isAdobeAgentWithNoOrgs();
    this.setStateWrapper({
      userLoggedIn: Authentication.loggedIn(),
      workspacesLocale,
      showDefaultLocaleWarningDialog,
      canAccessGAC: true, // If this had been false, App.isError() would populate errorToDisplay accordingly
      isAdobeAgentWithNoOrgs,
      userProfile: await window.adobeIMS.getProfile(),
    });
  };

  /**
   * Callback to set deepLinkingError
   * deepLinkingError is used to persist the error message across redirects
   */
  private setDeepLinkingError = (deepLinkingError: string): void => {
    this.setStateWrapper({ deepLinkingError });
  };

  /**
   * Wrapper for setState with abortController to avoid updating the state of the component after it has been unmounted.
   * Note, this wrapper does not support updating states through (state) => {newState} function. Call abortController explicitly in those cases.
   * @param state
   * @param callback
   */
  private setStateWrapper(state: Pick<State, never>, callback?: () => void): void {
    if (this.abortController.signal.aborted) {
      return;
    }
    this.setState(state, callback);
  }

  public render(): React.ReactNode {
    if (!this.state.userLoggedIn) {
      return (
        <ProviderV3
          theme={defaultTheme}
          colorScheme="light"
          scale="medium"
          locale={LocaleSettings.getSelectedLanguageTagForProvider()}
          position="absolute"
          width="100%"
          height="100%"
        >
          <ProgressCircle
            size="L"
            isIndeterminate
            data-testid="full-app-wait"
            top="50%"
            left="50%"
            aria-label="Loading"
          />
        </ProviderV3>
      );
    }

    return (
      <div>
        <IntlProvider
          locale={LocaleSettings.getSelectedLanguageTagForProvider()}
          messages={LocaleSettings.getSelectedLocale()}
        >
          {/* TypekitId is the same as Admin Console (Provides the same fonts as Admin Consoles) */}
          <Provider theme="light" scale="medium" typekitId="ubl8raj">
            <ProviderV3
              theme={defaultTheme}
              colorScheme="light"
              locale={LocaleSettings.getSelectedLanguageTagForProvider()}
            >
              <PandoraApiProvider>
                {_.isEmpty(this.state.eventErrorMessage) ? (
                  <>
                    <ErrorBoundary>
                      <BanyanShell
                        userProfile={this.state.userProfile}
                        renderCallback={this.updateState}
                        workspacesLocale={this.state.workspacesLocale}
                        setDeepLinkingError={this.setDeepLinkingError}
                      >
                        <IntlProvider
                          locale={LocaleSettings.getSelectedLanguageTagForProvider()}
                          messages={LocaleSettings.getSelectedLocale()}
                        >
                          {/* This alert text will only be in English and does not need localization because it is only displayed when defaulting to English upon not finding the users locale */}
                          {this.state.showDefaultLocaleWarningDialog && (
                            <AlertBanner
                              className="App__alert"
                              header="Default Locale"
                              variant="warning"
                              closeable
                              closeTime={30000}
                            >
                              Unable to determine locale. Setting locale to English US.
                            </AlertBanner>
                          )}
                          <ErrorBoundary>
                            <Routes>
                              {/* URL is already a deeplink for a supported URL. Just render it. */}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.COMPARTMENTS]) && (
                                <Route
                                  path={`/:orgId${HeaderConstants.ORGANIZATIONS_URL}`}
                                  element={
                                    <DeepLinkParser
                                      setDeepLinkingError={this.setDeepLinkingError}
                                      deepLinkingError={this.state.deepLinkingError}
                                      renderCallback={this.updateState}
                                      endpoint={HeaderConstants.ORGANIZATIONS_URL}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.PRODUCT_ALLOCATION]) && (
                                <Route
                                  path={`/:orgId${HeaderConstants.PRODUCT_ALLOCATION_URL}`}
                                  element={
                                    <DeepLinkParser
                                      setDeepLinkingError={this.setDeepLinkingError}
                                      deepLinkingError={this.state.deepLinkingError}
                                      renderCallback={this.updateState}
                                      endpoint={HeaderConstants.PRODUCT_ALLOCATION_URL}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.INSIGHTS]) && (
                                <Route
                                  path={`/:orgId${HeaderConstants.INSIGHTS}`}
                                  element={
                                    <DeepLinkParser
                                      setDeepLinkingError={this.setDeepLinkingError}
                                      deepLinkingError={this.state.deepLinkingError}
                                      renderCallback={this.updateState}
                                      endpoint={HeaderConstants.INSIGHTS}
                                    />
                                  }
                                />
                              )}

                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.SUPPORT]) && (
                                <Route
                                  path={`/:orgId${HeaderConstants.SUPPORT}`}
                                  element={
                                    <DeepLinkParser
                                      setDeepLinkingError={this.setDeepLinkingError}
                                      deepLinkingError={this.state.deepLinkingError}
                                      renderCallback={this.updateState}
                                      endpoint={HeaderConstants.SUPPORT}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.JOB_EXECUTION]) && (
                                <Route
                                  path={`/:orgId${HeaderConstants.JOB_EXECUTION_URL}`}
                                  element={
                                    <DeepLinkParser
                                      setDeepLinkingError={this.setDeepLinkingError}
                                      deepLinkingError={this.state.deepLinkingError}
                                      renderCallback={this.updateState}
                                      endpoint={HeaderConstants.JOB_EXECUTION_URL}
                                    />
                                  }
                                />
                              )}
                              {/* Handle non-deeplink URLs */}
                              {_.find(this.state.workspacesLocale, [
                                'uid',
                                WorkspaceUIDs.ORG_MIGRATION_GLOBAL_ADMIN,
                              ]) && (
                                <Route
                                  path={HeaderConstants.ORG_MAPPER_FOR_GLOBAL_ADMIN}
                                  element={<OrgMigrationForGlobalAdmin />}
                                />
                              )}
                              {_.find(this.state.workspacesLocale, [
                                'uid',
                                WorkspaceUIDs.ORG_MIGRATION_ADOBE_AGENT,
                              ]) && (
                                <Route
                                  path={HeaderConstants.ORG_MAPPER_FOR_ADOBE_AGENT}
                                  element={<OrgMigrationForAdobeAgent />}
                                />
                              )}

                              {/* Redirect from simple URLs to deeplink URLs */}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.COMPARTMENTS]) && (
                                <Route
                                  path={HeaderConstants.ORGANIZATIONS_URL}
                                  element={
                                    <RedirectToDeepLink
                                      endpoint={HeaderConstants.ORGANIZATIONS_URL}
                                      isAdobeAgentWithNoOrgs={this.state.isAdobeAgentWithNoOrgs}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.PRODUCT_ALLOCATION]) && (
                                <Route
                                  path={HeaderConstants.PRODUCT_ALLOCATION_URL}
                                  element={
                                    <RedirectToDeepLink
                                      endpoint={HeaderConstants.PRODUCT_ALLOCATION_URL}
                                      isAdobeAgentWithNoOrgs={this.state.isAdobeAgentWithNoOrgs}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.INSIGHTS]) && (
                                <Route
                                  path={HeaderConstants.INSIGHTS}
                                  element={
                                    <RedirectToDeepLink
                                      endpoint={HeaderConstants.INSIGHTS}
                                      isAdobeAgentWithNoOrgs={this.state.isAdobeAgentWithNoOrgs}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.SUPPORT]) && (
                                <Route
                                  path={HeaderConstants.SUPPORT}
                                  element={
                                    <RedirectToDeepLink
                                      endpoint={HeaderConstants.SUPPORT}
                                      isAdobeAgentWithNoOrgs={this.state.isAdobeAgentWithNoOrgs}
                                    />
                                  }
                                />
                              )}
                              {_.find(this.state.workspacesLocale, ['uid', WorkspaceUIDs.JOB_EXECUTION]) && (
                                <Route
                                  path={HeaderConstants.JOB_EXECUTION_URL}
                                  element={
                                    <RedirectToDeepLink
                                      endpoint={HeaderConstants.JOB_EXECUTION_URL}
                                      isAdobeAgentWithNoOrgs={this.state.isAdobeAgentWithNoOrgs}
                                    />
                                  }
                                />
                              )}
                              {/* Redirect for default path */}
                              <Route
                                path="/"
                                element={
                                  this.state.isAdobeAgentWithNoOrgs ? (
                                    <Navigate to={HeaderConstants.ORG_MAPPER_FOR_ADOBE_AGENT} replace />
                                  ) : (
                                    <Navigate to={HeaderConstants.ORGANIZATIONS_URL} replace />
                                  )
                                }
                              />
                              <Route path="*" element={<NotFound />} />
                            </Routes>
                          </ErrorBoundary>
                        </IntlProvider>
                      </BanyanShell>
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <ToastList
                        style={{ left: '2rem', bottom: '2rem' }}
                        connectToMaster={ToastMaster.attachToastList}
                      />
                      <Footer />
                    </ErrorBoundary>
                  </>
                ) : (
                  <FullErrorPage errorMessage={this.state.eventErrorMessage}>
                    {!this.state.canAccessGAC && (
                      <Button onClick={(): void => Authentication.signOut()}>
                        <FormattedMessage id="errors.signOut" defaultMessage="Sign out" />
                      </Button>
                    )}
                  </FullErrorPage>
                )}
              </PandoraApiProvider>
            </ProviderV3>
          </Provider>
        </IntlProvider>
      </div>
    );
  }
}

export default App;
