import { defineMessages, useIntl } from 'react-intl';
import { SupportTicketListViewContentModel } from '@pandora/react-support-ticket';

// strings shown in the pandora component (note, we might delete some of them if it's not used in the future)
export const useSupportCasesContent = () => {
  const messages = defineMessages({
    AdminHeading: {
      id: 'SupportForGA.SupportCases.AdminHeading',
      defaultMessage: 'ADMIN',
    },
    AutoClosed: {
      id: 'SupportForGA.SupportCases.AutoClosed',
      defaultMessage: 'Auto closed',
    },
    AwaitingAssignment: {
      id: 'SupportForGA.SupportCases.AwaitingAssignment',
      defaultMessage: 'Awaiting assignment',
    },
    CancelMisroute: {
      id: 'SupportForGA.SupportCases.CancelMisroute',
      defaultMessage: 'Cancel/Misroute',
    },
    CaseIdHeading: {
      id: 'SupportForGA.SupportCases.CaseIdHeading',
      defaultMessage: 'CASE ID',
    },
    CaseTitleHeading: {
      id: 'SupportForGA.SupportCases.CaseTitleHeading',
      defaultMessage: 'CASE TITLE',
    },
    DateCreatedHeading: {
      id: 'SupportForGA.SupportCases.DateCreatedHeading',
      defaultMessage: 'DATE CREATED',
    },
    DateRequestedHeading: {
      id: 'SupportForGA.SupportCases.DateRequestedHeading',
      defaultMessage: 'DATE REQUESTED',
    },
    DateScheduledHeading: {
      id: 'SupportForGA.SupportCases.DateScheduledHeading',
      defaultMessage: 'DATE SCHEDULED',
    },
    Escalated: {
      id: 'SupportForGA.SupportCases.Escalated',
      defaultMessage: 'Escalated',
    },
    InProgress: {
      id: 'SupportForGA.SupportCases.InProgress',
      defaultMessage: 'In progress',
    },
    IssueObsolete: {
      id: 'SupportForGA.SupportCases.IssueObsolete',
      defaultMessage: 'Issue obsolete',
    },
    Merged: {
      id: 'SupportForGA.SupportCases.Merged',
      defaultMessage: 'Merged',
    },
    NoClosedCasesContentMessage: {
      id: 'SupportForGA.SupportCases.NoClosedCasesContentMessage',
      defaultMessage: 'Once a case is resolved, you will be able to view its history here.',
    },
    NoClosedCasesHeadingMessage: {
      id: 'SupportForGA.SupportCases.NoClosedCasesHeadingMessage',
      defaultMessage: 'No closed cases at this time',
    },
    NoClosedSessionsContentMessage: {
      id: 'SupportForGA.SupportCases.NoClosedSessionsContentMessage',
      defaultMessage: "Once sessions are complete, you'll be able to view all history here.",
    },
    NoClosedSessionsHeadingMessage: {
      id: 'SupportForGA.SupportCases.NoClosedSessionsHeadingMessage',
      defaultMessage: 'No closed sessions at this time',
    },
    NoOpenCasesHeadingMessage: {
      id: 'SupportForGA.SupportCases.NoOpenCasesHeadingMessage',
      defaultMessage: 'No open cases at this time',
    }, //fix form here
    NoOpenSessionsContentMessage: {
      id: 'SupportForGA.SupportCases.NoOpenSessionsContentMessage',
      defaultMessage:
        'To get started with administration or product features, click Request expert session on the top right',
    },
    NoOpenSessionsHeadingMessage: {
      id: 'SupportForGA.SupportCases.NoOpenSessionsHeadingMessage',
      defaultMessage: 'No open sessions at this time',
    },
    NoSearchResultsCasesContentMessage: {
      id: 'SupportForGA.SupportCases.NoSearchResultsCasesContentMessage',
      defaultMessage: 'No cases',
    },
    NoSearchResultsSessionsContentMessage: {
      id: 'SupportForGA.SupportCases.NoSearchResultsSessionsContentMessage',
      defaultMessage: 'No sessions',
    },
    NotScheduledHeading: {
      id: 'SupportForGA.SupportCases.NotScheduledHeading',
      defaultMessage: 'Not yet scheduled',
    },
    P1Heading: {
      id: 'SupportForGA.SupportCases.P1Heading',
      defaultMessage: 'P1 - Critical',
    },
    P2Heading: {
      id: 'SupportForGA.SupportCases.P2Heading',
      defaultMessage: 'P2 - Urgent',
    },
    P3Heading: {
      id: 'SupportForGA.SupportCases.P3Heading',
      defaultMessage: 'P3 - Important',
    },
    P4Heading: {
      id: 'SupportForGA.SupportCases.P4Heading',
      defaultMessage: 'P4 - Minor',
    },
    PendingEngineering: {
      id: 'SupportForGA.SupportCases.PendingEngineering',
      defaultMessage: 'Pending engineering',
    },
    PendingManagedServices: {
      id: 'SupportForGA.SupportCases.PendingManagedServices',
      defaultMessage: 'Pending managed services',
    },
    PendingResponse: {
      id: 'SupportForGA.SupportCases.PendingResponse',
      defaultMessage: 'Pending response',
    },
    PriorityHeading: {
      id: 'SupportForGA.SupportCases.PriorityHeading',
      defaultMessage: 'PRIORITY',
    },
    //missing product item content model
    ProductNameHeading: {
      id: 'SupportForGA.SupportCases.ProductNameHeading',
      defaultMessage: 'PRODUCT',
    },
    ResolutionConfirmed: {
      id: 'SupportForGA.SupportCases.ResolutionConfirmed',
      defaultMessage: 'Resolution confirmed',
    },
    ResolutionIrrelevant: {
      id: 'SupportForGA.SupportCases.ResolutionIrrelevant',
      defaultMessage: 'Resolution irrelevant',
    },
    ResolutionProvided: {
      id: 'SupportForGA.SupportCases.ResolutionProvided',
      defaultMessage: 'Resolution provided',
    },
    SearchLabel: {
      id: 'SupportForGA.SupportCases.SearchLabel',
      defaultMessage: 'Search',
    },
    ServerErrorContentMessage: {
      id: 'SupportForGA.SupportCases.ServerErrorContentMessage',
      defaultMessage: "We're unable to process this request. Please try again later.",
    },
    SessionCompleted: {
      id: 'SupportForGA.SupportCases.SessionCompleted',
      defaultMessage: 'Session completed',
    },
    SessionIdHeading: {
      id: 'SupportForGA.SupportCases.SessionIdHeading',
      defaultMessage: 'SESSION ID',
    },
    SessionScheduled: {
      id: 'SupportForGA.SupportCases.SessionScheduled',
      defaultMessage: 'Session scheduled',
    },
    SessionTopicHeading: {
      id: 'SupportForGA.SupportCases.SessionTopicHeading',
      defaultMessage: 'SESSION TOPIC',
    },
    StatusHeading: {
      id: 'SupportForGA.SupportCases.StatusHeading',
      defaultMessage: 'STATUS',
    },
    SupportCaseTable: {
      id: 'SupportForGA.SupportCases.SupportCaseTable',
      defaultMessage: 'Support Case Table',
    },
    Unknown: {
      id: 'SupportForGA.SupportCases.Unknown',
      defaultMessage: 'Unknown',
    },
    YouSuffix: {
      id: 'SupportForGA.SupportCases.youSuffix',
      defaultMessage: '{name} (you)',
    },
  });

  //passed in all the attributes for SupportTicketListViewContentModel and will be displayed on the table
  const { formatMessage } = useIntl();
  return SupportTicketListViewContentModel.createEntry({
    adminHeading: formatMessage(messages.AdminHeading),
    autoClosed: formatMessage(messages.AutoClosed),
    awaitingAssignment: formatMessage(messages.AwaitingAssignment),
    cancelMisroute: formatMessage(messages.CancelMisroute),
    caseIdHeading: formatMessage(messages.CaseIdHeading),
    caseTitleHeading: formatMessage(messages.CaseTitleHeading),
    dateCreatedHeading: formatMessage(messages.DateCreatedHeading),
    dateRequestHeading: formatMessage(messages.DateRequestedHeading),
    dateScheduledHeading: formatMessage(messages.DateScheduledHeading),
    escalated: formatMessage(messages.Escalated),
    inProgress: formatMessage(messages.InProgress),
    issueObsolete: formatMessage(messages.IssueObsolete),
    merged: formatMessage(messages.Merged),
    noClosedCasesContentMessage: formatMessage(messages.NoClosedCasesHeadingMessage),
    noClosedCasesHeadingMessage: formatMessage(messages.NoClosedCasesHeadingMessage),
    noClosedSessionsContentMessage: formatMessage(messages.NoClosedSessionsContentMessage),
    noClosedSessionsHeadingMessage: formatMessage(messages.NoClosedSessionsHeadingMessage),
    noOpenCasesHeadingMessage: formatMessage(messages.NoOpenCasesHeadingMessage),
    noOpenSessionsContentMessage: formatMessage(messages.NoOpenSessionsContentMessage),
    noSearchResultsCasesContentMessage: formatMessage(messages.NoSearchResultsCasesContentMessage),
    noSearchResultsSessionsContentMessage: formatMessage(messages.NoSearchResultsSessionsContentMessage),
    notScheduledHeading: formatMessage(messages.NotScheduledHeading),
    p1Heading: formatMessage(messages.P1Heading),
    p2Heading: formatMessage(messages.P2Heading),
    p3Heading: formatMessage(messages.P3Heading),
    p4Heading: formatMessage(messages.P4Heading),
    pendingEngineering: formatMessage(messages.PendingEngineering),
    pendingManagedServices: formatMessage(messages.PendingManagedServices),
    pendingResponse: formatMessage(messages.PendingResponse),
    priorityHeading: formatMessage(messages.PriorityHeading),
    productNameHeading: formatMessage(messages.ProductNameHeading),
    resolutionConfirmed: formatMessage(messages.ResolutionConfirmed),
    resolutionIrrelevant: formatMessage(messages.ResolutionIrrelevant),
    resolutionProvided: formatMessage(messages.ResolutionProvided),
    searchLabel: formatMessage(messages.SearchLabel),
    serverErrorContentMessage: formatMessage(messages.ServerErrorContentMessage),
    sessionCompleted: formatMessage(messages.SessionCompleted),
    sessionIdHeading: formatMessage(messages.SessionIdHeading),
    sessionScheduled: formatMessage(messages.SessionScheduled),
    sessionTopicHeading: formatMessage(messages.SessionTopicHeading),
    statusHeading: formatMessage(messages.StatusHeading),
    supportCaseTable: formatMessage(messages.SupportCaseTable),
    unknown: formatMessage(messages.Unknown),
    youSuffix: formatMessage(messages.YouSuffix, { name: '{name}' }), // setting {name} to {name} to fulfill formatMessages requirement of providing input for "input" and to send off the localized message with variable for pandora to provide the input.
  });
};
