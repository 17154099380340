import * as _ from 'lodash';
import * as log from 'loglevel';
import { createIntl, createIntlCache, IntlShape, MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import { MESSAGES } from './Messages';
import { ErrorCodesData, ERROR_CODES_MESSAGES } from '../Codes/ErrorCodes';
import { RESOURCE_NAMES, UNITS, RESOURCE_QUANTITIES } from '../Codes/MessageImages';
import { CAP_UNLIMITED } from '../../services/orgMaster/OrgMaster';
import { AuthWindow } from '../authentication/IMS';
import { UOrgMaster } from '../orgMaster/UOrgMaster';
import { UUserGroup } from '../orgMaster/UUserGroup';
import CmdDescriptionUtils from '../Codes/CmdDescriptionUtils';
import { UUserGroupShare } from '../orgMaster/UUserGroupShare';

declare const window: AuthWindow;

const TRUE_STRING = 'true';
const FALSE_STRING = 'false';

/**
 * Additional utility helpers.
 */
class Utils {
  static intlCache = createIntlCache();
  static intl: IntlShape;

  static setIntl(locale: string, messages: Record<string, string>): void {
    Utils.intl = createIntl(
      {
        locale,
        messages,
      },
      Utils.intlCache
    );
  }

  /**
   * Returns the given defaultValue if the given field is null or undefined, otherwise the
   * given field is returned.  Used in situations to quickly provide a default boolean
   * if a value is not available.
   */
  static booleanValueIfDefined(field: boolean | null | undefined, defaultValue: boolean): boolean {
    if (_.isNil(field)) {
      return defaultValue;
    }
    return field;
  }

  /*
   * Determines whether a string is empty
   */
  static isEmptyString(value: string): boolean {
    return value.length === 0;
  }

  /**
   * Determines whether a number is a defined number.
   * This method can be used for type guarding.
   */
  static isNumber(value: number | undefined): value is number {
    return !_.isNil(value);
  }

  /**
   * Determines whether a string value can be parsed into an int number.
   * This method can be used for type guarding to check that the value is not undefined and is a string.
   * Note: A string is parsed up to the first letter (behaviour from _.parseInt)
   */
  static canParseInt(value: string | undefined | null): value is string {
    if (!_.isNil(value)) {
      const result: number = _.parseInt(value, 10);
      if (!Number.isNaN(result)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Determines whether a string value represents entirely an int number.
   * Only numeric characters and a preceding '-' count as being an int.
   * This method can be used for type guarding to check that the vlaue is not undefined and is a string.
   */
  static canParsePureInt(value: string | undefined): value is string {
    if (!_.isNil(value)) {
      return value.match(/^-?[0-9]+$/) !== null;
    }
    return false;
  }

  /**
   * Parses a string value into a number.  If the given value can't be parsed into a number the defaultValue is returned instead.
   * Note: A string is parsed up to the first letter (behaviour from _.parseInt)
   *  ex: "1a1" becomes 1
   */
  static parseIntOrDefault(value: string | undefined | null, defaultValue: number): number {
    return Utils.parseIntOrNilDefault(value, defaultValue) as number;
  }

  /**
   * Parses a string value into a number.  If the given value can't be parased into a number the defaultValue is returned instead.
   * Unlike parseIntOrDefault this method allows null or undefined for the defaultValue
   * Note: A string is parsed up to the first letter (behaviour from _.parseInt)
   *  ex: "1a1" becomes 1
   */
  static parseIntOrNilDefault(value: string | undefined | null, defaultValue: number | undefined): number | undefined {
    if (!_.isNil(value)) {
      const result: number = _.parseInt(value);
      if (!Number.isNaN(result)) {
        return result;
      }
    }
    return defaultValue;
  }

  /**
   * Given a value, returns a defaultValue if the value isn't defined (or null) otherwise the value is returned.
   */
  static numberDefinedOrDefault(value: number | undefined, defaultValue: number): number {
    if (_.isNil(value)) {
      return defaultValue;
    }
    return value;
  }

  /**
   * Determines whether a string can be parsed into a boolean
   * Also provides type guarding to check that the value is not undefined
   */
  static canParseBool(value: string | undefined): value is string {
    if (!_.isNil(value)) {
      return _.toLower(value) === TRUE_STRING || _.toLower(value) === FALSE_STRING;
    }
    return false;
  }

  /**
   * Parses a string into a boolean.  If the given value cannot be parsed, then the defaultValue is returned.
   * The defaultValue cannot be undefined.
   */
  static parseBoolOrDefault(value: string | undefined, defaultValue: boolean): boolean {
    return Utils.parseBoolOrNilDefault(value, defaultValue) as boolean;
  }

  /**
   * Parses a string into a boolean.  If the given value cannot be parsed, then the defaultValue is returned.
   * The defaultValue can be undefined.
   */
  static parseBoolOrNilDefault(value: string | undefined, defaultValue: boolean | undefined): boolean | undefined {
    if (_.toLower(value) === TRUE_STRING) {
      return true;
    }
    if (_.toLower(value) === FALSE_STRING) {
      return false;
    }
    return defaultValue;
  }

  /**
   * Parses a string into a value matching what is represented in the string
   *  - String with numeric characters (including '-' for negative) is parsed to a number
   *  - String with 'true' or 'false' is parsed to a boolean
   *  - Anything else is considered a string
   * This method does not handle other types like objects, null, or undefined
   */
  static parseStringValue(value: string): string | number | boolean {
    if (Utils.canParsePureInt(value)) {
      return Utils.parseIntOrDefault(value, 0);
    }
    if (Utils.canParseBool(value)) {
      return Utils.parseBoolOrDefault(value, false);
    }
    return value;
  }

  /**
   * Returns elements that are present in 1 array but not in the other.
   * For lodash the order matters (if a has less elements than b, the additional elements of b are ignored).
   * For this method, the order does not matter.
   * The array inputs and return must all contain the same type of elements.
   */
  static difference<T>(a: T[], b: T[], comparator?: (a: T, b: T) => boolean): T[] {
    if (b.length > a.length) {
      return comparator ? _.differenceWith(b, a, comparator) : _.difference(b, a);
    }
    return comparator ? _.differenceWith(a, b, comparator) : _.difference(a, b);
  }

  /**
   * Splits a string by line breaks (Windows, Mac, Unix).
   */
  static splitByLineBreaks(str: string): string[] {
    return str.split(/\r\n|\r|\n/);
  }

  // https://css-tricks.com/snippets/javascript/htmlentities-for-javascript/
  /**
   * Escapes dangerous html characters in a string.
   */
  static htmlEntities(str: string): string {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;');
  }

  /**
   * Retrieve a single cookie value selected by key
   */
  static getCookieValue(key: string): string | undefined {
    const cookies: string = document.cookie;
    const cookiesArray: string[] = cookies.split(';');
    const cookie: string | undefined = _.find(cookiesArray, (keyValuePair: string): boolean =>
      _.startsWith(keyValuePair, `${key}=`)
    );
    if (cookie) {
      return cookie.split('=')[1];
    }
    return undefined;
  }

  /**
   * Halts execution of code for the given number of milliseconds.
   * Must be used with await (ex): await sleep(1000);.
   * Must be used in async method (because await is used).
   */
  static async sleep(waitMilliseconds: number): Promise<void> {
    return new Promise((resolve: () => void): void => {
      setTimeout(resolve, waitMilliseconds);
    });
  }

  /**
   * Converts json data to csv
   * - This method can only convert json contained within an array with flat objects with the same properties (no array or object properties)
   * - The properties within each object don't have to be in the same order
   * - The csv headers will be based off the first objects properties and in that order
   */
  static jsonArrayToCsv(jsonData: any): string {
    if (!_.isArray(jsonData) || _.isEmpty(jsonData)) {
      return '';
    }
    const propKeys = _.keys(jsonData[0]); // fields of the first json object determine the headers
    const csvData: string[] = [propKeys.join(',')]; // populate headers in csv
    _.forEach(jsonData, (jsonObject: any): void => {
      const rowValues: string[] = [];
      _.forEach(propKeys, (key: string): void => {
        rowValues.push(_.toString(jsonObject[key]));
      });
      const rowStr: string = rowValues.join(',');
      csvData.push(rowStr);
    });
    return csvData.join('\n');
  }

  /**
   * Throws Error if response is NOT OK i.e. the status code is NOT 200-299.
   * OR redirect to login page if status code is 401 UNAUTHORIZED.
   * If BanyanError's user-friendly 'detail' message is present in respBody, the resulting 'error.message' will be populated with it.
   * Otherwise defaultErrMsg is used for the localized Error and localized values for (Unauthorized, Forbidden, Not Found) may be appended.
   * https://wiki.corp.adobe.com/display/BANY/Banyan+REST+and+Java+error+handling
   *
   * @param response - response from fetch()
   * @param respBody - results of response.json(). Note that plain-text is valid JSON.
   * @param defaultErrMsg - Json of the type `MessageDescriptor` as defined in one of the `Messages.ts` file, if response does not contain a BanyanError.
   * @param errMsgParams - values for variables (if any) as defined in `defaultErrMsg`.
   * @throws Error if !response.ok
   */
  static throwIfError(
    response: Response,
    respBody: any,
    msgDescriptor: MessageDescriptor,
    errMsgParams?: Record<string, PrimitiveType> | undefined
  ): void {
    if (!response.ok) {
      log.error('error response body:', respBody);

      // If user is Unauthorized, redirect to login page
      if (response.status === 401 && window.adobeIMS) {
        log.info(`${response.status} ${response.statusText} response from ${response.url}. Redirecting to login.`);
        window.adobeIMS.signOut();
      }

      const errorCode: string = _.get(respBody, 'errorCode');
      const errorParams: string[] = _.get(respBody, 'errorParams');
      let errMsg: string = '';

      // throw if translation for ERROR CODE is FOUND
      this.throwIfErrorCodeExist(errorCode, errorParams);

      // else use the default message to throw an error
      errMsg = this.getLocalizedMessage(msgDescriptor, errMsgParams);
      let errMsgToAppend: MessageDescriptor | undefined;
      switch (response.status) {
        case 401:
          errMsgToAppend = MESSAGES.Unauthorized;
          break;
        case 403:
          errMsgToAppend = MESSAGES.Forbidden;
          break;
        case 404:
          errMsgToAppend = MESSAGES.NotFound;
          break;
        default: // noop
      }
      if (errMsgToAppend) {
        errMsg = `${errMsg} ${this.getLocalizedMessage(errMsgToAppend)}`;
      }
      throw Error(errMsg);
    }
  }

  static throwIfErrorCodeExist(errorCode: string, errorParams?: string[]): void {
    const msgDescriptorForErrorCode = ERROR_CODES_MESSAGES[errorCode];
    const mappedErrorParams = errorParams ? Utils.formatErrorParams(errorParams) : {};
    if (msgDescriptorForErrorCode) {
      throw Error(this.getLocalizedMessage(msgDescriptorForErrorCode, mappedErrorParams));
    }
  }

  /**
   * Throws Error with a localized message.
   *
   * @param defaultErrMsg - Json of the type `MessageDescriptor` as defined in one of the `Messages.ts` file, if response does not contain a BanyanError.
   * @param errMsgParams - values for variables (if any) as defined in `defaultErrMsg`.
   * @throws Error with a localized message
   */
  static throwLocalizedError(
    defaultErrMsg: MessageDescriptor,
    errMsgParams?: Record<string, PrimitiveType> | undefined
  ): void {
    throw Error(this.getLocalizedMessage(defaultErrMsg, errMsgParams));
  }

  /**
   * return true if the Error was thrown by fetch() due to an aborted AbortController.signal.
   * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
   * https://developers.google.com/web/updates/2017/09/abortable-fetch#reacting_to_an_aborted_fetch
   */
  static isAbortError(err: Error): boolean {
    return err.name.startsWith('AbortError');
  }

  static getLocalizedMessage(
    defaultErrMsg: MessageDescriptor,
    errMsgParams?: Record<string, PrimitiveType> | undefined
  ): string {
    return Utils.intl.formatMessage(defaultErrMsg, errMsgParams);
  }

  /**
   * Create an object by assigning each error params to its index
   * for e.g. errorParams = ['banyan','ui'] => {'0': 'banyan', '1': 'ui'}
   * @param errorParams array of error parameters
   */
  static formatErrorParams(errorParams: string[] | undefined): any {
    const obj: any = {};
    if (errorParams) {
      for (let i = 0; i < errorParams.length; i++) {
        obj[`${i}`] = errorParams[i];
      }
    }
    return obj;
  }

  /**
   *  Takes the error message response (errorData) from the service, extract the error code, and produce
   *  a localized message with parameters.
   *  If the error code is not found in existing messages, returns the error code itself.
   */
  static localizeErrorData = (errorData: ErrorCodesData): string => {
    const msgDescriptorForErrorCode = ERROR_CODES_MESSAGES[errorData.errorCode];
    if (msgDescriptorForErrorCode === undefined) {
      return errorData.errorCode;
    }
    const mappedErrorParams = errorData.errorParams ? Utils.formatErrorParams(errorData.errorParams) : {};
    return Utils.getLocalizedMessage(msgDescriptorForErrorCode, mappedErrorParams);
  };

  /**
   * converts a string to a key format for looking up localization message descriptions
   * This method is used to convert resource EnterpriseNames and units to keys to look up mesage descriptions in MessageImages.ts
   * ex: 'deviceLicense' - DEVICELICENSE
   *      'MILLION GB-SECONDS' - MILLION_GB_SECONDS
   */
  static convertKeyForLocalization(key: string): string {
    // convert the unit to uppercase and then replace any special character with underscore
    return key.toUpperCase().replace(/[^a-zA-Z0-9]/g, '_');
  }

  /**
   * Provides the localized resource Enterprise Name for a given Enterprise Name in English.
   */
  static localizedResourceName(name: string): string {
    const key: string = Utils.convertKeyForLocalization(name);
    const descriptor: MessageDescriptor | undefined = RESOURCE_NAMES[key];
    return descriptor ? Utils.intl.formatMessage(descriptor) : name;
  }

  /**
   * Provides the localized resource unit for a given unit in English.
   */
  static localizedResourceUnit(unit: string): string {
    const key: string = Utils.convertKeyForLocalization(unit);
    const descriptor: MessageDescriptor | undefined = UNITS[key];
    return descriptor ? Utils.intl.formatMessage(descriptor) : unit;
  }

  /**
   * Provides the localized version of 'UNLIMITED'.
   * This is to be used when displaying 'UNLIMITED' as a grant quantity.
   * Do not use localization when comparing, checking, or sending to the back-end the 'UNLIMITED' value.
   */
  static localizedUnlimited(): string {
    return Utils.intl.formatMessage(RESOURCE_QUANTITIES.UNLIMITED);
  }

  /**
   * Localizes a grant quantity if it is 'UNLIMITED' otherwise simply returns the grant quantity
   */
  static localizeUnlimitedValue(value: string | number): string | number {
    if (value === CAP_UNLIMITED) {
      return Utils.localizedUnlimited();
    }
    return value;
  }

  /**
   * converts the policy name to its key for 'POLICIES' in MessageImages.ts
   * This method is used for computing localized command descriptions from stored images.
   * ex: 'createchildren' - CREATE_CHILDREN
   *      'allowAdobeID' - ALLOW_ADOBE_ID
   * @param policyName
   */
  static convertPolicyNameToKeyForLocalization(policyName: string): string {
    // split the string with camel case, include _ between the split strings
    // ex: createChildren - create_children
    const key = policyName.replace(/([a-z])(?=[A-Z])/g, '$1_');
    // capitalize
    return key.toUpperCase();
  }

  static localizeDateAsString(date: Date): string {
    return this.intl.formatDate(date, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  static localizeTimeAsString(date: Date): string {
    return this.intl.formatTime(date);
  }

  static isTargetGroup(userGroup: UUserGroup): boolean {
    return userGroup.isTarget !== undefined && userGroup.isTarget;
  }

  static isSourceGroup(userGroup: UUserGroup): boolean {
    return userGroup.isSource !== undefined && userGroup.isSource;
  }

  static isLeafOrg(org: UOrgMaster): boolean {
    return org.childrenRefs.length === 0;
  }

  static isSharedGroup(userGroup: UUserGroup): boolean {
    if (userGroup.isTarget !== undefined && userGroup.isSource !== undefined) {
      return userGroup.isTarget || userGroup.isSource;
    }
    return false;
  }

  static truncatePath(path: string = ''): string {
    if (path.length === 0) {
      return '';
    }
    const pathSegments: string[] = path.split('/');
    const pathEnd: number = pathSegments.length - 1;
    const ellipsisPath: string[] = '.../'.repeat(pathEnd).split('/', pathEnd);
    return pathEnd > 1 && pathEnd !== pathSegments.length ? [...ellipsisPath, pathSegments[pathEnd]].join('/') : path;
  }

  static createTargetGroupIdToOrgPathMap(userGroup: UUserGroup): Map<string, string> {
    const { sharedUserGroupTargets } = userGroup;

    if (!sharedUserGroupTargets || sharedUserGroupTargets.length === 0) {
      return new Map();
    }

    const targetGroupToOrgIdsMap: Map<string, string> = new Map();

    sharedUserGroupTargets.map((sharedTarget: UUserGroupShare): void => {
      const { targetGroupId = '', targetOrgId = '' } = sharedTarget;
      const orgPath: string =
        targetOrgId.length > 0 ? Utils.truncatePath(CmdDescriptionUtils.getPathname(targetOrgId)) : '';

      if (targetGroupId.length > 0 && orgPath.length > 0) {
        targetGroupToOrgIdsMap.set(targetGroupId, orgPath);
      }
    });

    return targetGroupToOrgIdsMap;
  }
}
export default Utils;
