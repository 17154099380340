import React from 'react';
import * as _ from 'lodash';
import { Table, TBody, TD, TH, THead, TR } from '@react/react-spectrum/Table';
import Search from '@react/react-spectrum/Search';
import Alert from '@react/react-spectrum/Alert';
import Wait from '@react/react-spectrum/Wait';
import Button from '@react/react-spectrum/Button';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { OwnershipStatus } from '@pandora/administration-core-types';
import { ContentEntryProvider } from '@pandora/react-content-provider';
import { DirectoryStatusWithLabel } from '@pandora/react-directory-status';
import {
  DirectoryDomainEnforcementState,
  DirectoryDomainEnforcementStatus,
  DirectoryDomainEnforcementStatusContentModel,
} from '@pandora/react-directory-domain-enforcement-status';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import '../../common.css';
import { UDirectory } from '../../../services/orgMaster/UDirectory';
import './DirectoryTable.css';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import config from '../../../configurations/config';
import Analytics from '../../../Analytics/Analytics';
import ScrollableContent from '../Widgets/ScrollableContent';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import GoToAdminConsoleDialog from '../../../components/GoToAdminConsoleDialog/GoToAdminConsoleDialog';
import FloodgateService from '../../../services/floodgate/FloodgateService';

interface DirectoryTableProps extends WrappedComponentProps {
  selectedOrg: UOrgMaster;
}

interface DirectoriesTableStates {
  filteredDirectories: UDirectory[];
  searchTerm: string;
  errorMessage: string;
}

const messages = defineMessages({
  ViewInAdminConsole: {
    id: 'EditCompartment.Directories.ViewInAdminConsole',
    defaultMessage: 'View in Admin Console',
  },
  SearchPlaceholder: {
    id: 'EditCompartment.Directories.SearchPlaceholder',
    defaultMessage: 'Search',
  },
  PaneHelp: {
    id: 'Organizations.Directories.Helptext',
    defaultMessage:
      'View the directories to which the organization selected on the left has access.  More information about them is available in the Admin Console',
  },
  FailedToLoadDirectories: {
    id: 'EditCompartment.Directories.FailedToLoadDirectories',
    defaultMessage: 'Unable to load directories: {error}',
  },
  DirectoryStatusActive: {
    id: 'EditCompartment.Directories.DirectoryStatusActive',
    defaultMessage: 'Active',
  },
  DirectoryStatusNeedsDomain: {
    id: 'EditCompartment.Directories.DirectoryStatusNeedsDomain',
    defaultMessage: 'Needs Domain',
  },
  DirectoryStatusPending: {
    id: 'EditCompartment.Directories.DirectoryStatusPending',
    defaultMessage: 'Pending',
  },
  DirectoryStatusRejected: {
    id: 'EditCompartment.Directories.DirectoryStatusRejected',
    defaultMessage: 'Rejected',
  },
  DirectoryStatusRequiresActivation: {
    id: 'EditCompartment.Directories.DirectoryStatusRequiresActivation',
    defaultMessage: 'Requires Activation',
  },
  DirectoryStatusRequiresApproval: {
    id: 'EditCompartment.Directories.DirectoryStatusRequiresApproval',
    defaultMessage: 'Requires Approval',
  },
  DirectoryStatusRequiresConfiguration: {
    id: 'EditCompartment.Directories.DirectoryStatusRequiresConfiguration',
    defaultMessage: 'Requires Configuration',
  },
  DirectoryStatusRevoked: {
    id: 'EditCompartment.Directories.DirectoryStatusRevoked',
    defaultMessage: 'Revoked',
  },
  DirectoryStatusTrusted: {
    id: 'EditCompartment.Directories.DirectoryStatusTrusted',
    defaultMessage: 'Trusted',
  },
  DirectoryDomainEnforcementScheduled: {
    id: 'EditCompartment.Directories.DomainEnforcementStatus.Scheduled',
    defaultMessage: 'Scheduled for {date}',
  },
  DirectoryDomainEnforcementOff: {
    id: 'EditCompartment.Directories.DomainEnforcementStatus.Off',
    defaultMessage: 'Off',
  },
  DirectoryDomainEnforcementOn: {
    id: 'EditCompartment.Directories.DomainEnforcementStatus.On',
    defaultMessage: 'On',
  },
  DirectoryDomainEnforcementUnknown: {
    id: 'EditCompartment.Directories.DomainEnforcementStatus.Unknown',
    defaultMessage: 'Unknown',
  },
});

class DirectoryTable extends React.Component<DirectoryTableProps, DirectoriesTableStates> {
  abortController: AbortController;

  constructor(props: DirectoryTableProps) {
    super(props);
    this.state = {
      filteredDirectories: this.props.selectedOrg.directories,
      searchTerm: '',
      errorMessage: '', // if not empty, show the alert box. The errorMessage is defined if the loading of directories fails on componentDidMount/componentDidUpdate
    };
    this.abortController = new AbortController();
  }

  private async loadDirectories(): Promise<void> {
    try {
      if (!this.props.selectedOrg.directoriesLoaded) {
        await LoadOrgDataService.loadDirectories(this.props.selectedOrg.id, this.abortController.signal);
      }
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      this.setState((state) => {
        return {
          errorMessage: '', // reset error message
          filteredDirectories: DirectoryTable.getFilteredDirectories(state.searchTerm, this.props.selectedOrg),
        };
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      const { formatMessage } = this.props.intl;
      this.setState({ errorMessage: formatMessage(messages.FailedToLoadDirectories, { error: error.message }) });
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async componentDidMount(): Promise<void> {
    await this.loadDirectories();
  }

  async componentDidUpdate(prevProps: DirectoryTableProps): Promise<void> {
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id) {
      await this.loadDirectories();
    }
  }

  private static getFilteredDirectories(searchTerm: string, compartment: UOrgMaster): UDirectory[] {
    return _.filter(compartment.directories, (directory: UDirectory): boolean =>
      _.includes(_.toLower(directory.name), _.toLower(searchTerm))
    );
  }

  private onSearch = (searchTerm: string): void => {
    const searchDirectories = _.trim(searchTerm);
    if (_.isEmpty(searchDirectories)) {
      this.setState({ filteredDirectories: this.props.selectedOrg.directories, searchTerm });
      return;
    }
    Analytics.fireCTAEvent(`directory search`);
    const filteredDirectories = DirectoryTable.getFilteredDirectories(searchTerm, this.props.selectedOrg);
    this.setState({ filteredDirectories, searchTerm });
  };

  /**
   * Load more next page of directories
   */
  loadNextPage = async (): Promise<void> => {
    try {
      // get next list of admins
      await LoadOrgDataService.loadNextPageDirectories(this.props.selectedOrg.id);
      this.setState((state) => ({
        filteredDirectories: DirectoryTable.getFilteredDirectories(state.searchTerm, this.props.selectedOrg),
      })); // rerender with updated values
    } catch (error) {
      this.setState({ errorMessage: error });
    }
  };

  private morePagesToLoad(): boolean {
    return this.props.selectedOrg.currentDirectoriesPageIndex + 1 < this.props.selectedOrg.totalDirectoriesPageCount;
  }

  public render(): React.ReactNode {
    const showDEStatus = FloodgateService.isFeatureEnabled(FloodgateService.RENDER_DOMAIN_ENFORCEMENT_STATUS);

    // show wait if directory not loaded
    const { formatMessage } = this.props.intl;
    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in directory table if we are unable to load the directory data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }
    const rows: React.ReactNode[] = this.state.filteredDirectories.map((directory: UDirectory): React.ReactNode => {
      const domainEnforcementEnablementDate = directory.domainEnforcement?.stateEndsAt
        ? new Date(directory.domainEnforcement.stateEndsAt)
        : new Date();
      const showDEStatusViaPandora = UDirectory.isDomainEnforcementStateToBeDisplayed(
        directory.domainEnforcement?.state
      );
      return (
        <TR key={directory.id} data-testid={directory.id}>
          <TD>{directory.name}</TD>
          <TD>{UDirectory.getDirectoryTypeDisplayName(directory.type)}</TD>
          {showDEStatus && (
            <TD>
              {showDEStatusViaPandora ? (
                <ContentEntryProvider
                  model={DirectoryDomainEnforcementStatusContentModel}
                  locale={LocaleSettings.getSelectedLanguageTagForProvider()}
                  value={{
                    on: formatMessage(messages.DirectoryDomainEnforcementOn),
                    off: formatMessage(messages.DirectoryDomainEnforcementOff),
                    scheduled: formatMessage(messages.DirectoryDomainEnforcementScheduled, {
                      date: new Intl.DateTimeFormat(LocaleSettings.getSelectedLanguageTagForProvider()).format(
                        domainEnforcementEnablementDate
                      ),
                    }),
                    unknown: formatMessage(messages.DirectoryDomainEnforcementUnknown),
                  }}
                >
                  <DirectoryDomainEnforcementStatus
                    state={
                      DirectoryDomainEnforcementState[
                        directory.domainEnforcement!.state as DirectoryDomainEnforcementState
                      ]
                    }
                    enablementDate={domainEnforcementEnablementDate}
                  />
                </ContentEntryProvider>
              ) : (
                <span className="DirectoryTable__enDashAsDEState__margin">–</span>
              )}
            </TD>
          )}
          <TD>
            <ContentEntryProvider
              value={{
                active: formatMessage(messages.DirectoryStatusActive),
                needsDomain: formatMessage(messages.DirectoryStatusNeedsDomain),
                pending: formatMessage(messages.DirectoryStatusPending),
                rejected: formatMessage(messages.DirectoryStatusRejected),
                requiresActivation: formatMessage(messages.DirectoryStatusRequiresActivation),
                requiresApproval: formatMessage(messages.DirectoryStatusRequiresApproval),
                requiresConfiguration: formatMessage(messages.DirectoryStatusRequiresConfiguration),
                revoked: formatMessage(messages.DirectoryStatusRevoked),
                trusted: formatMessage(messages.DirectoryStatusTrusted),
              }}
              locale={LocaleSettings.getSelectedLanguageTagForProvider()}
            >
              <DirectoryStatusWithLabel
                status={directory.status}
                ownershipStatus={directory.ownershipStatus as OwnershipStatus}
              />
            </ContentEntryProvider>
          </TD>
        </TR>
      );
    });
    return this.props.selectedOrg.directoriesLoaded ? (
      <div>
        <div className="EditCompartment_tabSectionHeader EditCompartment__margin">
          {this.props.selectedOrg.directories.length > 0 && (
            <Search
              placeholder={formatMessage(messages.SearchPlaceholder)}
              onChange={this.onSearch}
              value={this.state.searchTerm}
              data-testid="directory-table-search"
              className="EditCompartment__TableSearch"
            />
          )}
          <span className="DirectoryTable__rightContainer">
            {this.props.selectedOrg.directories.length > 0 && (
              <Button
                onClick={(): void => {
                  const goToAdminConsoleDialogRef = ModalContainer.show(
                    <GoToAdminConsoleDialog
                      onClose={(): void => {
                        ModalContainer.hide(goToAdminConsoleDialogRef as number);
                      }}
                      url={`${config.adminConsole.url}/${this.props.selectedOrg.id}/settings/identity/directories`}
                      selectedOrg={this.props.selectedOrg}
                    />,
                    this
                  );
                }}
                label={formatMessage(messages.ViewInAdminConsole)}
                variant="secondary"
              />
            )}
          </span>
        </div>
        <ScrollableContent
          onScroll={this.loadNextPage}
          enableInfiniteScroll={
            this.props.selectedOrg.totalDirectoriesPageCount > 1 && this.state.searchTerm.length === 0
          }
          hasMorePages={this.morePagesToLoad()}
          uniqueId="EditCompartment_DirectoriesTableID"
          className="EditCompartment_tabContent"
        >
          <Table>
            <THead className="EditCompartment__stickyHeader">
              <TH>
                <FormattedMessage id="EditCompartment.Directories.Name" defaultMessage="NAME" />
              </TH>
              <TH>
                <FormattedMessage id="EditCompartment.Directories.Directory" defaultMessage="TYPE" />
              </TH>
              {showDEStatus && (
                <TH>
                  <FormattedMessage
                    id="EditCompartment.Directories.DomainEnforcement"
                    defaultMessage="DOMAIN ENFORCEMENT"
                  />
                </TH>
              )}
              <TH>
                <FormattedMessage id="EditCompartment.Directories.Status" defaultMessage="STATUS" />
              </TH>
            </THead>
            <TBody>{rows}</TBody>
          </Table>
        </ScrollableContent>
      </div>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}
export default injectIntl(DirectoryTable);
