/**
 * Represents LockActionEnum instance retrieved from backend.
 *
 * A template consists of one or more instances of TemplatePolicy. When a template is applied to an organization, each of the TemplatePolicy
 * instance would be used to determine value of that particular policy for an organization. At that time, along with policy value change,
 * `lockedBy` field would be updated as follows:
 *
 * `LOCK`: Would lock a policy at an organization to which a template belongs
 * `UNLOCK`: Would unlock a policy even if it is locked
 * `AS_IS`: Would not change the value of `lockedBy` field for that policy. If a policy was locked before applying template, it would remain locked
 * or it would remain unlocked.
 *
 *
 */
export enum LockActionEnum {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  AS_IS = 'AS_IS',
}

/**
 * Provides object containing only properties for TemplatePolicy.
 * Represents TemplatePolicy instance retrieved from backend.
 *
 * @param name Unique name of policy as defined in UCompartmentPolicy.setDefaultPolicies
 * @param type Datatype of the value property of a policy. Currently supported: "boolean"
 * @param value Value for a policy in a specific template
 * @param lockAction Supported values: lock, unlock, as-is.
 */
interface TemplatePolicyData {
  name: string;
  type: string;
  value: any;
  lockAction: LockActionEnum;
}

/**
 * TemplatePolicy that also contains the methods and functionality.
 */
class TemplatePolicy implements TemplatePolicyData {
  name: string = '';
  type: string = '';
  value: any = {};
  lockAction: LockActionEnum = LockActionEnum.AS_IS;
}

export default TemplatePolicy;
