import configurations from '../configurations/config';
import { MESSAGES } from '../OrgMigration/Messages';
import AuthenticatedUser from '../services/authentication/AuthenticatedUser';
import EligibilityStatus from '../services/orgMaster/EligibilityStatus';
import GenerateBanyanSvcHeaders from '../services/providerUtils/generateBanyanSvcHeaders';
import Utils from '../services/utils/Utils';

const url = `${configurations.banyansvc.url}`;
class ContractSwitchProvider {
  public static async checkContractSwitchEligibilityForOrg(
    orgId: string,
    abortController: AbortController
  ): Promise<EligibilityStatus> {
    const options: any = {
      method: 'GET',
      headers: GenerateBanyanSvcHeaders.generateBanyanSvcHeaders(),
      signal: abortController.signal,
    };

    const accessTokenForT3 = await AuthenticatedUser.getAuthAccountAccessToken(); // Following API requires the Adobe Agent role. That role can only exist on Auth Accounts, not T2E accounts.
    options.headers.set('Authorization', `Bearer ${accessTokenForT3}`);

    const response = await fetch(`${url}/${orgId}/etla-contract-switch-eligibility-status`, options);
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.FailedToCheckEligibility, { orgId });
    return respBody;
  }

  public static async checkContractRollbackEligibilityForOrg(
    orgId: string,
    abortController: AbortController
  ): Promise<EligibilityStatus> {
    const options: any = {
      method: 'GET',
      headers: GenerateBanyanSvcHeaders.generateBanyanSvcHeaders(),
      signal: abortController.signal,
    };

    const accessTokenForT3 = await AuthenticatedUser.getAuthAccountAccessToken(); // Following API requires the Adobe Agent role. That role can only exist on Auth Accounts, not T2E accounts.
    options.headers.set('Authorization', `Bearer ${accessTokenForT3}`);
    const response = await fetch(`${url}/${orgId}/allocation-contract-rollback-eligibility-status`, options);

    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.FailedToCheckEligibility, { orgId });
    return respBody;
  }
}

export default ContractSwitchProvider;
