/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IdAndName } from '../../services/reparent';
import { OrgReparentSharedGroupsErrorMessagesProps } from './types';

export const OrgReparentSharedGroupsErrorMessages = ({ ...props }: OrgReparentSharedGroupsErrorMessagesProps) => {
  return (
    <>
      {!_.isEmpty(props.report.invalidOrgStandaloneReparentForSharedGroups) && (
        <>
          <FormattedMessage
            id="orgReparentSharedGroupErrors.invalidStandaloneSharedGroups"
            defaultMessage="Cannot convert organizations to standalone because they contain shared user groups."
          />
          {_.map(
            props.report.invalidOrgStandaloneReparentForSharedGroups,
            (info: IdAndName): React.ReactNode => (
              <div key={`invalid_org_standalone_reparent_shared_group_${info.id}_${info.name}`}>
                <FormattedMessage
                  id="orgReparentSharedGroupErrors.invalidStandalone"
                  defaultMessage="org: <b>{orgName}</b> ({orgId})"
                  values={{
                    orgName: info.name,
                    orgId: info.id,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </>
      )}
      {!_.isEmpty(props.report.invalidOrgReparentForSharedGroups) && (
        <>
          <FormattedMessage
            id="orgReparentSharedGroupErrors.invalidTopDownSharedGroups"
            defaultMessage="Unable to reparent organizations as it violates the top-down user group sharing policy"
          />
          {_.map(
            props.report.invalidOrgReparentForSharedGroups,
            (info: IdAndName): React.ReactNode => (
              <div key={`invalid_org_top_down_reparent_shared_group_${info.id}_${info.name}`}>
                <FormattedMessage
                  id="orgReparentSharedGroupErrors.invalidTopDownSharing"
                  defaultMessage="org: <b>{orgName}</b> ({orgId})"
                  values={{
                    orgName: info.name,
                    orgId: info.id,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </>
      )}
    </>
  );
};
