import * as _ from 'lodash';

import ImportOperationsUtils from '../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import { DataModel } from './DataModelTypes/DataModelTypes';
import { EditState } from '../../../services/orgMaster/OrgMaster';
import Utils from '../../../services/utils/Utils';
import { Country } from '../../../providers/ImsProvider';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import HierarchyManager from '../../../services/organization/HierarchyManager';

export interface ChangeCount {
  createCount: number;
  updateCount: number;
  deleteCount: number;
}

class ImportUtils {
  static filterToBeCreatedItems(allrecords: DataModel[]): DataModel[] {
    return _.filter(allrecords, (record: DataModel) => ImportOperationsUtils.isOperationCreate(record.operation));
  }

  static filterToBeUpdatedItems(allrecords: DataModel[]): DataModel[] {
    return _.filter(allrecords, (record: DataModel) => ImportOperationsUtils.isOperationUpdate(record.operation));
  }

  static filterToBeDeletedItems(allrecords: DataModel[]): DataModel[] {
    return _.filter(allrecords, (record: DataModel) => ImportOperationsUtils.isOperationDelete(record.operation));
  }

  static isNullOrEmpty(value: string | undefined | null): boolean {
    return _.isNil(value) || _.isEmpty(value);
  }

  /**
   * Mark the org as edited with the editState passed in. editState can be 'UPDATE' | 'DELETE' | 'CREATE'
   */
  static markOrgAsEdited(orgId: string, editState: EditState): void {
    const org = HierarchyManager.getOrg(orgId);
    if (org !== undefined) {
      org.editState = editState;
    }
  }

  /**
   * @returns true if the selected field is ('true' (case insensitive) | true) else returns false
   */
  static sanitizeBoolean(value: boolean | undefined): boolean {
    if (value !== undefined && Utils.canParseBool(String(value))) {
      return String(value).toLowerCase() === 'true';
    }
    return false;
  }

  /**
   * Generates error message to be displayed in import dialog. The error message is of the form
   * [ErrorLabel] [Header] \n
   * [ErrorMessageAsNumericList]
   *
   * for e.g. for errorLabel = "Label", header = "Sample text" and errorMessages = ["message1", "message2"]
   * returns => "[Label] Sample text \n 1) message1 \n 2) message2"
   *
   * If header is missing, just returns
   * [ErrorLabel] \n
   * [ErrorMessageAsNumericList]
   */
  static formatErrorMessages(errorLabel: string, errorMessages: string[], header?: string | undefined | null): string {
    const formattedMessages = errorMessages
      .map((value: string, index: number): string => {
        return `${index + 1}) ${value}`;
      })
      .join('\n ');

    if (!this.isNullOrEmpty(header)) {
      return `[${errorLabel}] ${header} \n ${formattedMessages}`;
    }
    return `[${errorLabel}] \n ${formattedMessages}`;
  }

  static displayIfError(errorLabel: string, errorMessages: string[]): void {
    if (errorMessages.length > 0) {
      throw Error(this.formatErrorMessages(errorLabel, errorMessages));
    }
  }

  static displayIfErrorWithHeader(errorLabel: string, header: string, errorMessages: string[]): void {
    if (errorMessages.length > 0) {
      throw Error(this.formatErrorMessages(errorLabel, errorMessages, header));
    }
  }

  /**
   * Helper fn to check if the country code is valid or not
   */
  static isCountryCodeOrCountryNameValid(countryDetail: string | undefined, countryList: Country[]): boolean {
    return (
      _.find(
        countryList,
        (each: Country): boolean =>
          _.includes(countryDetail, each.countryCode) || _.includes(countryDetail, each.countryName)
      ) !== undefined
    );
  }

  /**
   * For a list of orgIds, checks if the org is an EXISTING READ ONLY org and throws an error
   *
   * @param orgIds list of orgIds to be validated
   */
  static async throwIfExistingOrgIsReadOnly(errorLabel: string, header: string, orgIds: string[]): Promise<void> {
    // stores messages to be displayed in numeric order when displaying the error
    // each message is made of org name and org id
    const invalidOrgData = new Set<string>();

    // org details for orgs down the hierarchy might not have been loaded, hence load org details for these orgs
    const allOrgDetailsPromises = _.map(orgIds, (orgId: string) => LoadOrgDataService.loadOrgDetails(orgId));
    await Promise.all(allOrgDetailsPromises);

    _.forEach(orgIds, (orgId: string): void => {
      const uOrg = HierarchyManager.getOrg(orgId);
      if (uOrg && uOrg.isReadOnlyOrg()) {
        invalidOrgData.add(`'${uOrg.id}' - '${uOrg.name}'`);
      }
    });

    ImportUtils.displayIfErrorWithHeader(errorLabel, header, Array.from(invalidOrgData));
  }
}

export default ImportUtils;
