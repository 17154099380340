import React from 'react';
import Provider from '@react/react-spectrum/Provider';
import * as _ from 'lodash';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import ProductSelector from '../../../components/selectors/ProductSelector';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import ProductContractData from '../../../services/orgMaster/ProductContractData';
import { AdminProductInfo } from '../../../services/orgMaster/UAdmin';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import './Common.css';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import Utils from '../../../services/utils/Utils';

interface ProductSectionProps extends WrappedComponentProps {
  compartment: UOrgMaster;
  // Executed when product is added or removed for product admin.
  // (passes list of info for products currently associated with product admin and indicates the product and whether it is added or removed).
  updateProductInfoCallback: (productInfoList: AdminProductInfo[], productId: string, addRole: boolean) => void;
  disabled: boolean; // disables component including the button that enables and disables editing
  // List of products (indicated by info) associated with the product admin.
  productInfoList: AdminProductInfo[];
}

interface ProductSectionState {
  productsContractsData: ProductContractData[]; // products with contract data for current org
  // List of products (indicated by info) currently associated with product admin.
  productInfoList: AdminProductInfo[];
  productContractNameMap: Map<string, string>; // map of product id to productContractName (display name for product tags)
  enabledForEdit: boolean; // determines whether this component is enabled for edit based on user enabling through button
  productsLoading: boolean; // determines whether component should indicate products are loading
}

const messages = defineMessages({
  NoPdt: {
    id: 'EditCompartment.Admins.PdtSel.placeholder.NoProduct',
    defaultMessage: 'No product',
  },
  SelectPdt: {
    id: 'EditCompartment.Admins.PdtSel.placeholder.SelectProduct',
    defaultMessage: 'Select Product',
  },
});

class ProductSection extends React.Component<ProductSectionProps, ProductSectionState> {
  private abortController = new AbortController();

  constructor(props: ProductSectionProps) {
    super(props);
    const productsContractsData = ProductContractData.createMultipleFromProductsAndContracts(
      this.props.compartment.products,
      this.props.compartment.contracts
    );
    this.state = {
      productsContractsData: productsContractsData,
      productInfoList: this.props.productInfoList,
      productContractNameMap: ProductSection.createProductContractNameMap(
        this.props.compartment,
        productsContractsData
      ),
      enabledForEdit: this.props.compartment.productsLoaded,
      productsLoading: false,
    };
  }

  async componentDidMount(): Promise<void> {
    if (!this.props.compartment.productsLoaded && !this.state.productsLoading && !this.abortController.signal.aborted) {
      try {
        this.setState({ productsLoading: true });
        await LoadOrgDataService.loadProducts(this.props.compartment.organization.id, this.abortController.signal);
        if (!this.abortController.signal.aborted) {
          const productsContractsData = ProductContractData.createMultipleFromProductsAndContracts(
            this.props.compartment.products,
            this.props.compartment.contracts
          );
          this.setState({
            enabledForEdit: true,
            productsLoading: false,
            productsContractsData,
            productContractNameMap: ProductSection.createProductContractNameMap(
              this.props.compartment,
              productsContractsData
            ),
          });
        }
      } catch (error) {
        if (Utils.isAbortError(error)) {
          return;
        }
        throw error;
      }
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  /**
   * Given products with associated contracts data, generate a map of
   * product ids to the productContractsName.  This will be the display name for product tags
   */
  private static createProductContractNameMap(
    org: UOrgMaster,
    productsContractsData: ProductContractData[]
  ): Map<string, string> {
    return _.reduce(
      productsContractsData,
      (map: Map<string, string>, productContractData: ProductContractData): Map<string, string> => {
        if (!map.has(productContractData.product.id)) {
          if (org.shouldDisplayContractNames()) {
            map.set(productContractData.product.id, productContractData.productContractsName());
          } else {
            // just show product name if contract names aren't being rendered
            map.set(productContractData.product.id, productContractData.product.name);
          }
        }
        return map;
      },
      new Map<string, string>()
    );
  }

  /**
   * Look up product id given a productContractName
   */
  private productIdFromProductContractName(productContractName: string): string {
    return (
      _.find(
        Array.from(this.state.productContractNameMap.keys()),
        (productId: string): boolean => this.state.productContractNameMap.get(productId) === productContractName
      ) ?? ''
    );
  }

  // update product list (TODO: rename this to 'update')
  private updateV2 = (productInfoList: AdminProductInfo[], productId: string | undefined, addRole: boolean): void => {
    if (productId) {
      this.props.updateProductInfoCallback(productInfoList, productId, addRole);
    }
    this.setState({ productInfoList });
  };

  // update product list on selection (TODO: rename this to 'onProductSelection')
  private onProductSelectionV2 = (selectedProductId: string): void => {
    const { productInfoList } = this.state;
    if (!_.find(productInfoList, (productInfo: AdminProductInfo): boolean => productInfo.id === selectedProductId)) {
      productInfoList.push({ id: selectedProductId });
    }
    this.updateV2(productInfoList, selectedProductId, true);
  };

  // convert product list into tags (TODO: rename this to 'getProductTagList')
  private getProductTagListV2 = (): React.ReactNode => {
    // admins data does not include names for products and contracts,
    // so we still need look up product and contract names from org products and contracts
    const tags: React.ReactNode[] = _.reduce(
      this.state.productInfoList,
      (tagList: React.ReactNode[], productInfo: AdminProductInfo): React.ReactNode[] => {
        const { productContractNameMap } = this.state;
        if (productInfo.id && productContractNameMap.has(productInfo.id)) {
          tagList.push(<Tag key={productInfo.id}>{productContractNameMap.get(productInfo.id)}</Tag>);
        }
        return tagList;
      },
      []
    );
    return (
      <Provider locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
        <TagList
          data-testid="product-admin-taglist"
          className="AdminRightTagList"
          disabled={this.props.disabled || !this.state.enabledForEdit}
          onClose={(productName: string | React.ReactNode): void => this.removeProductByNameV2(productName as string)}
        >
          {tags}
        </TagList>
      </Provider>
    );
  };

  // remove the product from product list when user clicks 'X' on the tag
  // (TODO: rename this to 'removeProductByName')
  private removeProductByNameV2 = (tagName: string): void => {
    const productId = this.productIdFromProductContractName(tagName);
    const { productInfoList } = this.state;
    if (productId) {
      _.remove(productInfoList, (info: AdminProductInfo): boolean => info.id === productId);
    }
    this.updateV2(productInfoList, productId, false);
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    // retrieve ProductsContractsData associated with products that are administerable and are not already assigned to the admin
    const availableProductsContractsData = _.filter(
      this.state.productsContractsData,
      (productContractData: ProductContractData): boolean =>
        productContractData.product.allowedToAddAdminsAndProfiles() &&
        !_.find(
          this.state.productInfoList,
          (prodInfo: AdminProductInfo): boolean => prodInfo.id === productContractData.product.id
        )
    );
    return (
      <div>
        <ProductSelector
          width="size-3000"
          label={formatMessage(messages.SelectPdt)}
          disabled={this.props.disabled || !this.state.enabledForEdit}
          isLoading={this.state.productsLoading}
          clearSelection
          displayContractNames={this.props.compartment.shouldDisplayContractNames()}
          productsContractsData={availableProductsContractsData}
          onSelect={this.onProductSelectionV2}
          data-testid="admin-product-selection-combobox"
        />
        <div>{this.getProductTagListV2()}</div>
      </div>
    );
  }
}

export default injectIntl(ProductSection);
