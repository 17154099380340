import React from 'react';
import { Navigate } from 'react-router-dom';
import OrgPickerController from '../../services/organization/OrgPickerController';
import HeaderConsts from '../BanyanShell/HeaderConstants';
import Compartments from '../../Compartments/Compartments';
import JobExecution from '../../JobExecution/JobExecution';
import ProductAllocation from '../../ProductAllocation/ProductAllocation';
import NotFound from '../NotFound/NotFound';
import AdobeAgentWithNoOrgMessage from './AdobeAgentWithNoOrgMessage';
import OrgMigrationForAdobeAgent from '../../OrgMigration/OrgMigrationForAdobeAgent';
import InsightsForGlobalAdmin from '../../Insights/InsightsForGlobalAdmin';
import SupportForGlobalAdmin from '../../Support/SupportForGlobalAdmin';

interface RedirectToDeepLinkInterface {
  endpoint: string;
  isAdobeAgentWithNoOrgs: boolean;
}

function RedirectToDeepLink(props: RedirectToDeepLinkInterface): JSX.Element {
  const { endpoint, isAdobeAgentWithNoOrgs } = props;
  // special case - Adobe Agent with no orgs
  if (isAdobeAgentWithNoOrgs) {
    if (
      endpoint === HeaderConsts.PRODUCT_ALLOCATION_URL ||
      endpoint === HeaderConsts.ORGANIZATIONS_URL ||
      endpoint === HeaderConsts.SUPPORT
    ) {
      return <AdobeAgentWithNoOrgMessage />;
    }
    if (endpoint === HeaderConsts.JOB_EXECUTION_URL) {
      // An agent without orgs might have used Org Migration for Adobe Agents page and have 'commands' from there
      // But an Agent should never such commands because they'll have to be Global Admins to due Org Migration.
      return <JobExecution />;
    }
    // endpoint doesn't match a supported deeplink URL. So just send them to Org Migration.
    // But this should never happen because callers should only call this method with a deeplink-able endpoint.
    return <OrgMigrationForAdobeAgent />;
  }
  const redirectedLink = OrgPickerController.getDeepLinkBasedOnActiveOrg(endpoint);
  if (redirectedLink === endpoint) {
    // that is, if no active org found (OrgPickerController.getDeepLinkBasedOnActiveOrg returns endpoint if no active org)
    // for a Global Admin (Agent w/o org is handled above).
    // so route directly to the component, instead of looping back again and again to redirect component
    // That said, this block should never happen because App.tsx will show full page error for Global Admin w/o orgs.
    switch (endpoint) {
      case HeaderConsts.ORGANIZATIONS_URL:
        return <Compartments />;
      case HeaderConsts.PRODUCT_ALLOCATION_URL:
        return <ProductAllocation />;
      case HeaderConsts.JOB_EXECUTION_URL:
        return <JobExecution />;
      case HeaderConsts.INSIGHTS:
        return <InsightsForGlobalAdmin />;
      case HeaderConsts.SUPPORT:
        return <SupportForGlobalAdmin />;
      default:
        return <NotFound />;
    }
  }
  return <Navigate to={OrgPickerController.getDeepLinkBasedOnActiveOrg(endpoint) as string} />;
}

export default RedirectToDeepLink;
