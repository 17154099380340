import * as _ from 'lodash';
import { unparse } from 'papaparse';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource, ResourceCalculationData } from '../../../services/orgMaster/UResource';

import {
  ResourceCalculationMap,
  ResourceCalculationUtils,
  OrgProductResourceData,
} from '../calculation/ResourceCalculation';
import Utils from '../../../services/utils/Utils';

/**
 * Object representation of the data that will be exported per org, resource, and product.
 * This is also referred to as the data object.
 * This data object will be converted to JSON or CSV.
 * Note: null values represent the lack of data for the property
 */
interface ProdAllocExportData {
  productName: string;
  licenseId: string;
  sourceLicenseId: string;
  productId: string;
  resourceName: string;
  resourceId: string;
  orgPathName: string;
  orgName: string;
  orgId: string;
  grantedQuantity: number | string | null;
  unit: string;
  totalAllocations: number | null;
  grantOverage: number | null;
  localLicensedQuantity: number | null;
  localUsage: number;
  totalUsage: number | null;
  useOverage: number | null;
  allowOverAllocation: boolean;
  allowOverUse: boolean;
  isPurchasedProduct: boolean;
  redistributable: boolean;
  operation: null; // type null since this will be exported as empty in order to make it more convenient for the user to fill in during import
}

/**
 * Export functionality used by all file formats.
 */
class ProdAllocExportUtils {
  /**
   * Converts NaN or undefined number values to null (for JSON).  Otherwise passes the number value through.
   */
  private static handleUndefNumValues(value: number | undefined): number | null {
    if (value === undefined || Number.isNaN(value)) {
      return null;
    }
    return value;
  }

  /**
   * Converts negative number values to a positive value and postive values to null.
   * Also converts NaN or undefined values to null
   */
  private static handleOverageNumValues(value: number | undefined): number | null {
    let numValue: number | null = ProdAllocExportUtils.handleUndefNumValues(value);
    if (numValue !== null) {
      if (numValue < 0) {
        numValue = Math.abs(numValue);
      } else {
        numValue = null;
      }
    }
    return numValue;
  }

  /**
   * Converts negative number values to 0.
   * Also converts NaN or undefined values to null
   */
  private static handlePositiveNumValues(value: number | undefined): number | null {
    let numValue: number | null = ProdAllocExportUtils.handleUndefNumValues(value);
    if (numValue !== null && numValue < 0) {
      numValue = 0;
    }
    return numValue;
  }

  /**
   * Creates and populates a single data object for a product, resource, and org.
   *  - product: The product on the given org that provides data to populate on the data object
   *  - resource: The resource on the product that provides data to populate on the data object
   *  - org: The org that provides data to populate on the data object
   *  - resourceCalculationMap: The map of ProductAllocation calculated data related to the given product and
   *    resource that provides data associated with the given org to populate on the data object
   * This is where the data is specified for export.
   * If you want to add or remove data fields, this is where you need to edit.
   */
  static createDataObject(
    product: UProduct,
    resource: UResource,
    org: UOrgMaster,
    resourceCalculationMap: ResourceCalculationMap
  ): ProdAllocExportData {
    const calcData: ResourceCalculationData | undefined = resourceCalculationMap.get(org.organization.id);
    return {
      productName: product.name,
      licenseId: product.id,
      sourceLicenseId: product.sourceProductId,
      productId: product.productTypeId,
      resourceName: Utils.localizedResourceName(resource.name()),
      resourceId: resource.code,
      orgPathName: org.getPathname(),
      orgName: org.organization.name,
      orgId: org.organization.id,
      grantedQuantity: resource.isUnlimited()
        ? resource.grantedQuantity
        : ProdAllocExportUtils.handleUndefNumValues(resource.grantAsNilInt(undefined)),
      unit: Utils.localizedResourceUnit(resource.unit),
      totalAllocations: calcData ? ProdAllocExportUtils.handleUndefNumValues(calcData.totalAlloc) : null,
      grantOverage: calcData ? ProdAllocExportUtils.handleOverageNumValues(calcData.grantOver) : null,
      localLicensedQuantity: calcData
        ? ProdAllocExportUtils.handlePositiveNumValues(calcData.localLicensedQuantity)
        : null,
      localUsage: resource.provisionedQuantity,
      totalUsage: calcData ? calcData.totalUse : null,
      useOverage: calcData ? ProdAllocExportUtils.handleOverageNumValues(calcData.useOver) : null,
      allowOverAllocation: product.allowExceedQuotas,
      allowOverUse: product.allowExceedUsage,
      isPurchasedProduct: product.isPurchase(),
      redistributable: product.redistributable,
      operation: null,
    };
  }

  /**
   * Creates and populates data objects for an org and its children for a given product and resource (single hierarchy)
   * The returned data is contained within the given allData array
   *  - rootOrParentProduct: Product on the root org or parent org to select the products on the given org and children which will provide data for their data objects
   *  - selectedResource: Resource used to select the resource from the given org and children which will provide data for their data objects
   *  - org: Org that will provide data for its data object.  The orgs children will also provide data for their respective data objects.
   *  - allData: Array that will populate with data objects from the given org and its children
   */
  static processDataForOrgProductResource(
    rootOrParentProduct: UProduct,
    selectedResource: UResource,
    resourceCalculationMap: ResourceCalculationMap,
    org: UOrgMaster,
    allData: ProdAllocExportData[]
  ): void {
    const { product, resource } = ResourceCalculationUtils.orgProductAndResource(
      org,
      rootOrParentProduct,
      selectedResource,
      false
    );
    if (product && resource) {
      const exportData: ProdAllocExportData = ProdAllocExportUtils.createDataObject(
        product,
        resource,
        org,
        resourceCalculationMap
      );
      allData.push(exportData);

      const children: UOrgMaster[] = org.getChildren();
      if (children.length > 0) {
        _.forEach(children, (childOrg: UOrgMaster): void => {
          ProdAllocExportUtils.processDataForOrgProductResource(
            product,
            selectedResource,
            resourceCalculationMap,
            childOrg,
            allData
          );
        });
      }
    }
  }

  /**
   * Populates data objects for all orgs and all selectable products and resources from the ProductAllocation page. (Multiple hierarchies)
   *  - availableProducts: List of products selectable for viewing from the ProductAllocation page.  Used for selecting products on child orgs.
   *  - orgList: List of all loaded orgs in the ProductAllocation page
   */
  static processData(availableProducts: UProduct[], orgList: UOrgMaster[]): ProdAllocExportData[] {
    const allData: ProdAllocExportData[] = [];
    _.forEach(availableProducts, (selectedProduct: UProduct): void => {
      // Find all root/purchase orgs with products and populate data objects for each hierarchy
      const rootOrgsAndProducts = ResourceCalculationUtils.rootAndPurchaseOrgsWithProduct(orgList, selectedProduct);
      const selectedResources: UResource[] = ResourceCalculationUtils.getSortedQuotaResources(selectedProduct);
      _.forEach(selectedResources, (selectedResource: UResource): void => {
        const resourceCalculationMap: ResourceCalculationMap = ResourceCalculationUtils.populateResourceUsages(
          orgList,
          selectedProduct,
          selectedResource
        );

        _.forEach(rootOrgsAndProducts, (rootOrgAndProduct: OrgProductResourceData): void => {
          const { org: rootOrg, product: rootProduct } = rootOrgAndProduct;
          if (rootProduct && rootOrg) {
            ProdAllocExportUtils.processDataForOrgProductResource(
              rootProduct,
              selectedResource,
              resourceCalculationMap,
              rootOrg,
              allData
            );
          }
        });
      });
    });
    return _.orderBy(allData, ['productName', 'orgPathName']); // make sure orgs are grouped together for the same product
  }
}

/**
 * Export functionality specific to the JSON file format
 */
export class ProdAllocExportJSON {
  /**
   * Returns JSON data representing the data from all orgs and selectable products and resources from the ProductAllocation page.
   *  - availableProducts: List of products selectable for viewing from the ProductAllocation page.  Used for selecting products on child orgs.
   *  - orgList: List of all loaded orgs in the ProductAllocation page
   */
  static export(availableProducts: UProduct[], orgList: UOrgMaster[]): string {
    return JSON.stringify(ProdAllocExportUtils.processData(availableProducts, orgList), null, 2);
  }
}

/**
 * Export functionality specific to the CSV file format
 */
export class ProdAllocExportCSV {
  /**
   * Returns CSV data representing the data from all orgs and selectable products and resources from the ProductAllocation page.
   *  - availableProducts: List of products selectable for viewing from the ProductAllocation page.  Used for selecting products on child orgs.
   *  - orgList: List of all loaded orgs in the ProductAllocation page
   */
  static export(availableProducts: UProduct[], orgList: UOrgMaster[]): string {
    const allExportData: ProdAllocExportData[] = ProdAllocExportUtils.processData(availableProducts, orgList);
    if (allExportData.length > 0) {
      return unparse(allExportData);
    }
    return '';
  }
}
