import * as _ from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { EditState, OrgOperation, ErrorData, TEMP_ID_PREFIX } from './OrgMaster';

/* eslint-disable import/no-cycle */
import { UBasic, UBasicData } from './UBasic';
import { UProductProfileData, UProductProfile } from './UProductProfile';
import { UOrgMaster } from './UOrgMaster';
/* eslint-enable import/no-cycle */

import { UResourceData, UResource, Icons, FulfillableItemType } from './UResource';
import { ProductAttributes, ProductAttributesData } from './ProductAttributes';
import { LicenseTuple, LicenseTupleData } from './LicenseTuple';

import Utils from '../../services/utils/Utils';

/**
 * UProduct represents a product org data object.
 */

/**
 * Determines what kind of contract (ETLA, VIP, TEAM DIRECT, eVIP, VIPMP, etc) does a product belong to.
 * For ETLA contract, customer segment is "ENTERPRISE"
 * For VIP contract, customer segment is either "TEAM" or "ENTERPRISE" (depending on what product was bought)
 * For TEAM DIRECT contract, customer segment is "TEAM"
 * For contracts belonging to individual personal purchases, customer segment is "INDIVIDUAL". Such products are not associated with any org and hence won't show up here.
 */
export enum CustomerSegment {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTERPRISE = 'ENTERPRISE',
  TEAM = 'TEAM',
}

/**
 * Cloud or family of a product
 */
export enum Cloud {
  CREATIVE = 'CREATIVE',
  DOCUMENT = 'DOCUMENT',
  EXPERIENCE = 'EXPERIENCE',
  SIGN = 'SIGN',
  PUBLISHING_ADCLOUD = 'PUBLISHING_ADCLOUD',
  OTHERS = 'OTHERS',
  UNKNOWN = 'UNKNOWN',
}

/**
 * Cloud or family of a product mapped to a localized displayable name
 */
const cloudDisplayNames: { [P in keyof typeof Cloud]: MessageDescriptor } = defineMessages({
  CREATIVE: {
    id: 'product.cloud.creative',
    defaultMessage: 'Creative Cloud',
  },
  DOCUMENT: {
    id: 'product.cloud.document',
    defaultMessage: 'Document Cloud',
  },
  EXPERIENCE: {
    id: 'product.cloud.experience',
    defaultMessage: 'Experience Cloud',
  },
  SIGN: {
    id: 'product.cloud.sign',
    defaultMessage: 'Sign',
  },
  PUBLISHING_ADCLOUD: {
    id: 'product.cloud.publishingAdCloud',
    defaultMessage: 'Publishing Ad Cloud',
  },
  OTHERS: {
    id: 'product.cloud.others',
    defaultMessage: 'Others',
  },
  UNKNOWN: {
    id: 'product.cloud.unknown',
    defaultMessage: 'Unknown',
  },
});

/**
 * Mutable object for UProduct.
 * Provides object containing only properties for UProduct.
 * Represents UProduct object that can be retrieved from back-end.
 */
export interface UProductData extends UBasicData {
  name?: string; // product name
  longDescription?: string; // long description of the product
  orgId?: string; // orgId of the org this product belongs to
  resources?: UResourceData[]; // resources associated with the product.  License resource provides the cap and provisionedQuantity
  productProfiles?: UProductProfileData[]; // product profiles associated with this product
  icons?: Icons; // product icons
  allowExceedQuotas?: boolean; // flag that determines whether a product's grant is allowed to exceed how much was allocated to it by parent orgs
  allowExceedUsage?: boolean; // flag that determines whether a product's usage is allowed to exceed how much it is allowed
  sourceProductId?: string; // product id of the parent product
  sourceProductOrgId?: string; // orgId of the parent product
  offerId?: string;
  productArrangementCode?: string; // identifies most types of product and can determine product licenses that must be allocated together
  productGroupId?: string; // id for identifying types of product licenses (productGroupId is of the form: <productArrangementCode>_<figId> and is created during mapping UProduct on the back-end)
  customerSegment?: CustomerSegment | undefined; // restricts some of the GAC behaviors when customerSegment=TEAM (i.e. orgs with VIP or TEAM DIRECT contracts)
  cloud?: Cloud; // cloud or family of product
  totalProfileCount?: number; // total number of profiles. Initialized to x-total-count and incremented when user adds a new profile
  totalProfilePageCount?: number; // total number of pages. Required for paginated calls to load profiles
  currentProfilePageIndex?: number; // current profile page index
  redistributable?: boolean; // determines whether another product can be allocated from this product
  productAttributes?: ProductAttributesData; // holds some attribute values for the product
  tuples?: LicenseTupleData[];
}

/**
 * UProduct object that also contains the methods and functionality.
 */
export class UProduct extends UBasic implements UProductData {
  name: string = '';
  longDescription: string = '';
  orgId: string = '';
  resources: UResource[] = [];
  productProfiles: UProductProfile[] = [];
  icons: Icons = {};
  allowExceedQuotas: boolean = false;
  allowExceedUsage: boolean = false;
  sourceProductId: string = '';
  sourceProductOrgId: string = '';
  offerId: string = '';
  productArrangementCode: string = '';
  productGroupId: string = '';
  customerSegment: CustomerSegment | undefined = undefined;
  cloud: Cloud | undefined = undefined;
  redistributable: boolean = false;
  promiseProfilesLoaded: Promise<void> | null = null; // promise to know the profiles load state. Possible states: 1. null: profiles have not been loaded 2. pending: currently loading 3. resolved: profiles already loaded
  profilesLoaded: boolean = false; // boolean to know if the profiles have been loaded
  totalProfileCount: number = 0;
  totalProfilePageCount: number = 0;
  currentProfilePageIndex: number = 0;
  productAttributes: ProductAttributes = new ProductAttributes(undefined);
  tuples: LicenseTuple[] = [];
  public static PROFILE_PAGE_SIZE: number = 5; // page size for paginated loading of profiles

  /**
   * Construct a UProduct from either UProductData or creates a copy of another UProduct object.
   */
  constructor(product?: UProductData, newProduct = false) {
    super(product);
    if (product) {
      this.name = product.name || this.name;
      this.longDescription = product.longDescription || this.longDescription;
      this.orgId = product.orgId || this.orgId;
      this.resources =
        _.map(product.resources, (resource: UResourceData): UResource => new UResource(_.cloneDeep(resource))) ||
        this.resources;
      this.productProfiles = product.productProfiles
        ? _.map(
            product.productProfiles,
            (profile: UProductProfileData): UProductProfile => new UProductProfile(profile)
          )
        : this.productProfiles;
      this.icons = _.cloneDeep(product.icons) || this.icons;
      this.allowExceedQuotas = product.allowExceedQuotas || this.allowExceedQuotas;
      this.allowExceedUsage = product.allowExceedUsage || this.allowExceedUsage;
      this.sourceProductId = product.sourceProductId || this.sourceProductId;
      this.sourceProductOrgId = product.sourceProductOrgId || this.sourceProductOrgId;
      this.offerId = product.offerId || this.offerId;
      this.productArrangementCode = product.productArrangementCode || this.productArrangementCode;
      this.productGroupId = product.productGroupId || this.productGroupId;
      this.customerSegment = product.customerSegment || this.customerSegment;
      this.cloud = product.cloud || this.cloud;
      this.redistributable = product.redistributable || this.redistributable;
      this.productAttributes = new ProductAttributes(product.productAttributes);
      this.tuples =
        _.map(
          product.tuples,
          (licenseTuple: LicenseTupleData): LicenseTuple => new LicenseTuple(_.cloneDeep(licenseTuple))
        ) || this.tuples;
      if (newProduct) {
        this.profilesLoaded = true;
      }
    }
  }

  /**
   * JSON representation of UProduct.
   */
  toJSON(): object {
    return _.assign(super.toJSON(), {
      name: this.name,
      orgId: this.orgId,
      resources: this.resources,
      productProfiles: this.productProfiles,
      icons: this.icons,
      allowExceedQuotas: this.allowExceedQuotas,
      allowExceedUsage: this.allowExceedUsage,
      sourceProductId: this.sourceProductId,
      sourceProductOrgId: this.sourceProductOrgId,
      offerId: this.offerId,
      productArrangementCode: this.productArrangementCode,
      productGroupId: this.productGroupId,
      customerSegment: this.customerSegment,
      redistributable: this.redistributable,
      productAttributes: this.productAttributes,
      tuples: this.tuples,
    });
  }

  /**
   * Retrieve the contract id for this product from tuples.
   * Remove this when RENDER_CONTRACT_NAMES FF is removed.
   */
  get contractId(): string {
    if (_.isEmpty(this.tuples) || _.isNil(this.tuples[0])) {
      return '';
    }
    // contract id should be in the first tuple
    return this.tuples[0].contractId;
  }

  /**
   * Retrieve all the contract ids for contracts associated with this product from tuples.
   */
  get contractIds(): string[] {
    return _.map(this.tuples, (tuple: LicenseTuple): string => tuple.contractId);
  }

  /**
   * Retrieves the provisionedQuantity from the seat (license) resource (there's only 1 seat resource per product).
   * A null value is returned if there is no provisionedQuantity because the seat/license resource couldn't be found.
   */
  get provisionedQuantity(): number | null {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    return !resource ? null : resource.provisionedQuantity;
  }

  /**
   * Retrieves the product cap from the seat (license) resource (there's only 1 seat resource per product).
   */
  get cap(): string {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    return !resource || !resource.validCode() ? '0' : resource.cap;
  }

  /**
   * Sets the product cap for the seat (license) resource (there's only 1 seat resource per product).
   */
  set cap(newQuantity: string) {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    if (resource) {
      resource.cap = newQuantity;
    }
  }

  /**
   * Returns an identifier for the same type of product (productTypeId).
   * The productTypeId is the productGroupId because this value is believed to be the most unique identifier for product types.
   * This method can be refactorered out in favor of using productGroupId or productGroupId can simply be renamed as productTypeId.
   * A product refers to a product license which is unique to an org (product card for single org)
   * A product type refers to the same type of product license (grants same entitlements) across multiple orgs (same product card for multiple orgs)
   */
  get productTypeId(): string {
    return this.productGroupId;
  }

  /**
   * Reports whether this product is a purchase product.
   * A purchase product is a product on a child org that was purchased and not allocated by a parent org.
   * A purchase product either not have a sourceProductId or it will equal its own id
   */
  isPurchase(): boolean {
    return _.isEmpty(this.sourceProductId) || this.sourceProductId === this.id;
  }

  /**
   * Reports whether this product was allocated from a product on the parent org.
   * An allocation product will have a sourceProductId (but it won't equal its own id like the root product)
   */
  isAllocation(): boolean {
    return !_.isEmpty(this.sourceProductId) && this.sourceProductId !== this.id;
  }

  /**
   * Retrieves the product grant from the seat (license) resource (there's only 1 seat resource per product).
   */
  get grantedSeats(): string {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    return !resource || !resource.validCode() ? '0' : resource.grantedQuantity;
  }

  /**
   * Sets the product cap for the seat (license) resource (there's only 1 seat resource per product).
   */
  set grantedSeats(newQuantity: string) {
    const resource: UResource | undefined = _.find(this.resources, (res: UResource): boolean =>
      UResource.isSeatResource(res.code)
    );
    if (resource) {
      resource.grantedQuantity = newQuantity;
    }
  }

  /**
   * Retrieves only the quota resources and also double checks that they have cap (number value).
   * includeUnlimited flag determines whether resources with unlimited cap are included in results.
   */
  getQuotaResources(includeUnlimited: boolean = true): UResource[] {
    return _.filter(
      this.resources,
      (res: UResource): boolean =>
        res.fulfillableItemType === FulfillableItemType.QUOTA &&
        res.validCode() &&
        (includeUnlimited || !res.isUnlimited())
    );
  }

  /**
   * Adds a product profile to this product.
   */
  /* eslint-disable no-param-reassign */
  addProfile(profile: UProductProfile): void {
    let productId: string = this.id;
    if (!productId) {
      productId = this.name;
    }
    profile.productId = productId;
    this.productProfiles.push(profile);
    this.editState = EditState.UPDATE;
  }
  /* eslint-enable no-param-reassign */

  /**
   * Retrieves non seat (license) product resources.
   */
  getEditableResources(): UResource[] {
    return _.filter(this.resources, (res: UResource): boolean => !UResource.isSeatResource(res.code));
  }

  /**
   * get count of new profiles
   */
  getNewUserProfiles(): number {
    return _.filter(this.productProfiles, (productProfile: UProductProfile): boolean =>
      _.includes(productProfile.id, TEMP_ID_PREFIX)
    ).length;
  }

  /**
   * Retrieves a displayable version of the given cloud
   * (This should be used for displaying the cloud/family rather than displaying the cloud value directly)
   */
  static cloudDisplayName(cloud: Cloud | undefined): string {
    return cloud ? Utils.intl.formatMessage(cloudDisplayNames[cloud]) : '';
  }

  // Expiration ////////////////////////////////////////////

  /**
   * Retrieves the LicenseTuple for UProduct which is has the earliest date.
   * The earliest date is more important because it either means that is the closest LicenseTuple
   * to expiration or it is the most expired LicenseTuple.
   * @returns The earliest LicenseTuple or undefined if the earliest LicenseTuple could not be determined.
   */
  getEarliestExpiringTuple(): LicenseTuple | undefined {
    // find the earliest LicenseTuple by finding the lowest value expiration date from its ComplianceSymptoms
    // TODO: When CLAM supports allocations, we should just return the result from _.minBy
    const earliestTuple: LicenseTuple | undefined = _.minBy(this.tuples, (licenseTuple: LicenseTuple) =>
      licenseTuple.expirationDate()
    );
    // TODO: The rest of the code in this method temporary and will be removed when CLAM supports allocations.
    if (earliestTuple) {
      // earliest tuple exists, so return it
      return earliestTuple;
    }
    if (!earliestTuple && !_.isEmpty(this.tuples)) {
      // for the time being, if we could not find an earliest tuple and tuples do exist, find the first tuple with a valid phase
      return _.find(this.tuples, (licenseTuple: LicenseTuple): boolean => licenseTuple.hasValidPhase());
    }
    return undefined;
  }

  /**
   * Determines whether this product license allows editing based off of its LicenseTuples (edit grant values and policies, add child licenses, delete licenses).
   * This method is used by the UI to determine whether it should enable or disable editing for the product license.
   */
  allLicenseTuplesAllowEditing(): boolean {
    // if you can find 1 licenseTuple that disallows editing, the product cannot allow editing
    return !_.find(this.tuples, (licenseTuple: LicenseTuple): boolean => !licenseTuple.allowsEditing());
  }

  // ////////////////////////////////////////////////

  /**
   * return true if the other product has same values (that can be edited) as the this product.
   * @param otherProduct
   */
  isEqual(otherProduct: UProduct): boolean {
    return (
      this.allowExceedQuotas === otherProduct.allowExceedQuotas &&
      this.allowExceedUsage === otherProduct.allowExceedUsage &&
      this.grantedSeats === otherProduct.grantedSeats &&
      _.isEqual(this.resources, otherProduct.resources)
    );
  }

  /**
   * @return true if products have matching redistributable fields AND productTypeId AND cc_storage 'cap'. False otherwise.
   */
  isMatchingProduct(otherProduct?: UProduct): boolean {
    return (
      this.redistributable === otherProduct?.redistributable &&
      this.productTypeId === otherProduct.productTypeId &&
      this.productAttributes.ccStorageCap === otherProduct.productAttributes.ccStorageCap // this differentiates 0GB from 1024GB product variations
    );
  }

  /**
   * Determines whether this product allows creating profiles and adding product or profile admins.
   * This method acts as a wrapper around 'allowsAddingAdminsAndProfiles' should additional logic be needed
   * on the front-end.
   */
  allowedToAddAdminsAndProfiles(): boolean {
    return this.productAttributes.allowsAddingAdminsAndProfiles;
  }

  /**
   * Method for adding a created product to an org or executing other operations on a product associated with an org.
   * Delete and Update operations will set a delete or update state for the given elem (if it exists on the given org).
   * Create operation will add the given product elem to the given org.
   *   - org: An org that the elem should be imported to or the elem should be associated with to perform an operation.
   *   - elem: The product to perform the operation on.
   *   - operation: The operation to perform.
   * This method returns an error object or an array of errors, or a null value denoting success.
   */
  static importElement(org: UOrgMaster, elem: UBasicData, operation: OrgOperation): ErrorData | ErrorData[] | null {
    return UBasic.importElement(
      org,
      elem,
      operation,
      (e: UBasicData): UBasic | undefined => _.find(org.products, { id: e.id }),
      (e: UBasicData): ErrorData | ErrorData[] | null => {
        org.addProduct(new UProduct(e, true));
        return null;
      }
    );
  }
}
