/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */

import { Content, ContextualHelp as SpectrumContextualHelp, Heading, Text } from '@adobe/react-spectrum';
import React from 'react';
import { ContextualHelpProps } from './types';

export const ContextualHelp = ({ content }: ContextualHelpProps): JSX.Element => {
  return (
    <SpectrumContextualHelp variant="info" placement="start top" crossOffset={100}>
      {content.heading ? <Heading>{content.heading}</Heading> : <></>}
      <Content>
        <Text>{content.body}</Text>
      </Content>
    </SpectrumContextualHelp>
  );
};
