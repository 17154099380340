import { Flex, Radio, RadioGroup, Text } from '@adobe/react-spectrum';
import { RevokeType, SelectRevokeTypeStepProps } from '../types';
import { ManageUserGroupShareContentModel, useManageUserGroupShareContent } from '../ContentModels';
import { ContentEntryInterface } from '@pandora/react-content-provider';
import { useEffect, useState } from 'react';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useRevokeContext } from '../contexts/RevokeContext';
import GoUrl, { GoUrlKeys } from '../../GoUrl/GoUrl';

export const SelectRevokeTypeStep = ({ sourceOrgUserGroup, content }: SelectRevokeTypeStepProps) => {
  const [selectedRadio, setSelectedRadio] = useState('');
  const { toggleDisableCtaButton } = useNavigationContext();
  const { persistRevokeType } = useRevokeContext();

  const userCount: number = sourceOrgUserGroup.userCount;
  const contentEntry: ContentEntryInterface = content ?? useManageUserGroupShareContent(userCount);

  useEffect(() => {
    if (selectedRadio.length === 0) {
      toggleDisableCtaButton(true);
    }
  }, []);

  const handleSelected = (selected: string) => {
    setSelectedRadio(selected);
    if (selected.length > 0) {
      persistRevokeType(selected);
      toggleDisableCtaButton(false);
    }
  };

  return (
    <>
      <Flex direction="row" height="size-400" gap="size-200">
        <Flex direction="row" gap="size-40">
          <Text data-testid="revoke-type-description">
            {contentEntry.get(ManageUserGroupShareContentModel.revokeTypeDescription)}
          </Text>
          <GoUrl
            data-testid="revoke-type-learn-more"
            goUrlKey={GoUrlKeys.userGroupsShare}
            target="gac_organizationUserGroups"
          >
            {contentEntry.get(ManageUserGroupShareContentModel.revokeTypeLearnMoreLink)}
          </GoUrl>
        </Flex>
      </Flex>
      <Flex direction="column" gap="size-200">
        <RadioGroup
          data-testid="revoke-type-selector-radio-group"
          aria-label={`${contentEntry.get(ManageUserGroupShareContentModel.revokeTypeRadioGroupLabel)}`}
          value={selectedRadio}
          onChange={handleSelected}
        >
          <Flex direction="column" gap="size-10">
            <Radio data-testid="revoke-type-hard-radio" value={RevokeType.HARD_DELETE}>
              <Text data-testid="revoke-type-hard-radio-text">
                <strong>
                  {contentEntry.format(ManageUserGroupShareContentModel.revokeTypeHardRevokeDescription, { userCount })}
                </strong>
              </Text>
            </Radio>
            <ul>
              <li data-testid="hard-revoke-description-detail1">
                {contentEntry.get(ManageUserGroupShareContentModel.revokeTypeHardRevokeDescriptionDetail1)}
              </li>
              <li data-testid="hard-revoke-description-detail2">
                {contentEntry.get(ManageUserGroupShareContentModel.revokeTypeHardRevokeDescriptionDetail2)}
              </li>
            </ul>
          </Flex>
          <Flex direction="column" gap="size-0">
            <Radio data-testid="revoke-type-soft-radio" value={RevokeType.SOFT_DELETE}>
              <Text data-testid="revoke-type-soft-radio-text">
                <strong>{contentEntry.get(ManageUserGroupShareContentModel.revokeTypeSoftRevokeDescription)}</strong>
              </Text>
            </Radio>
            <ul>
              <li data-testid="soft-revoke-description-detail1">
                {contentEntry.get(ManageUserGroupShareContentModel.revokeTypeSoftRevokeDescriptionDetail1)}
              </li>
              <li data-testid="soft-revoke-description-detail2">
                {contentEntry.get(ManageUserGroupShareContentModel.revokeTypeSoftRevokeDescriptionDetail2)}
              </li>
            </ul>
          </Flex>
        </RadioGroup>
      </Flex>
    </>
  );
};
