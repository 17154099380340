import _ from 'lodash';
import config from '../../configurations/config';
import CommonHeaders from '../providerUtils/CommonHeaders';
import { AuthWindow, UserType } from './IMS';
import UserProfileService from './UserProfileService';

declare const window: AuthWindow;
const url = `${config.banyansvc.url}`;

// fetches properties related to adobeIMS object
// Note: adobeIMS object is added by imslib2.js once injected in setupIMS() in Authentication.ts
// window.adobeIMS.getAccessToken() exists only when the user login is successful, otherwise it remains undefined
// See UserProfileService.ts to get user profile info.
class AuthenticatedUser {
  public static aaAccessTokenCache: {
    // Cache for AA (Authenticating account) access token
    token: string | undefined;
    expiresAt: number | undefined; // unit is milliseconds (ms)
  };

  static getAccessToken(): string | undefined {
    if (window.adobeIMS) {
      return window.adobeIMS.getAccessToken()?.token;
    }
    return undefined;
  }

  /**
   * API Ref: https://git.corp.adobe.com/pages/IMS/imslib2.js/classes/_adobe_ims_adobeims_.adobeims.html#avatarurl
   * @param userId user id for which avatar Url is to be retrieved
   * @returns url of the user avatar
   */
  static getUserPictureURL(userId?: string): string | undefined {
    if (window.adobeIMS && userId) {
      return window.adobeIMS.avatarUrl(userId);
    }
    return undefined;
  }

  /**
   * Retrieve authenticating account (AA) access token provided current access token is associated with a T2E user profile.
   * If not T2E user profile, return user's current token.
   *
   * This method is necessary to make calls directly from this UI to a non-banyan backend service like JIL.
   * In particular it's necessary when the call requires Adobe Agent privilege, which only exists on an Auth Account (T1/T2/T3), and the user's current profile is T2E.
   *
   * Note:
   * 1. This method caches the token retrieved from banyansvc and returns cached token as long as it is not expired
   * 2. As of this comment, expiry of a token retrieved from banyansvc is 3600 seconds (i.e. 1 hr)
   *
   * Note: If banyansvc API call fails with error, return undefined.
   *
   * @returns access token as described below for 3 cases; undefined otherwise
   * Case 1: Returns access token same as current access token, IF current user profile is non-T2E
   * Case 2: Fetch new AA token from banyansvc, cache it and return; IF current user profile is T2E and cache is empty
   * Case 3: Returns cached AA access token, IF current user profile is T2E and AA token is available in cache AND is not expired
   *
   */
  static async getAuthAccountAccessToken(): Promise<string | undefined> {
    const currentUserProfile = UserProfileService.getUserProfile();
    const currentToken = AuthenticatedUser.getAccessToken();

    if (
      currentUserProfile?.account_type &&
      !_.isEqual(currentUserProfile.account_type.toLowerCase(), UserType.TYPE_TWOE.toLowerCase())
    ) {
      // If current profile is not T2E, it is a AA profile. Hence, return current access token
      return currentToken;
    }

    const aaUserId = currentUserProfile?.authId;
    if (_.isEmpty(aaUserId)) {
      return undefined;
    }

    if (
      AuthenticatedUser.aaAccessTokenCache?.token &&
      AuthenticatedUser.aaAccessTokenCache?.expiresAt &&
      Date.now() < AuthenticatedUser.aaAccessTokenCache.expiresAt
    ) {
      // If token is cached and if it is not expired yet, use it
      return AuthenticatedUser.aaAccessTokenCache.token;
    }

    // When AA token is either empty or is expired, fetch a new token
    const headers: Headers = CommonHeaders.generateCommonGACSvcHeaders();
    headers.append('Authorization', `Bearer ${currentToken}`);

    const response: Response = await fetch(`${url}/exchangeToken/${aaUserId}`, {
      method: 'GET',
      headers,
    });

    if (response.status !== 200) {
      return undefined;
    }

    const responseText = await response.text();
    const responseJson = responseText.length ? JSON.parse(responseText) : {};
    const { accessToken, expiresIn } = responseJson;
    if (accessToken === undefined || expiresIn === undefined) {
      return undefined;
    }

    // Cache newly fetched token
    AuthenticatedUser.aaAccessTokenCache = {
      token: accessToken,
      // 1. unit of 'responseJson.expires_in' is "seconds"; unit of 'expiresAt' is "ms".
      // 2. 60000 ms (1 min) is subtracted from actual responseJson.expires_in value to ensure token doesn't expire while a jil call is in progress
      // Note: As of this comment, JIL API "https://bps-il-stage.adobe.io/jil-api/v2/organizations:search.tokenized_name?" takes ~3.5 seconds on local
      expiresAt: Date.now() + parseInt(expiresIn, 10) * 1000 - 60000,
    };
    return AuthenticatedUser.aaAccessTokenCache.token;
  }
}

export default AuthenticatedUser;
