import * as _ from 'lodash';
import React, { Key, useCallback } from 'react';
import { Section, Item, Image, Text, Provider, defaultTheme } from '@adobe/react-spectrum';

import { LocaleSettings } from '../../services/locale/LocaleSettings';
import { UProduct, Cloud } from '../../services/orgMaster/UProduct';
import ProductContractData from '../../services/orgMaster/ProductContractData';
import SelectorSection from './SelectorSection';
import ComboBoxSelector from './ComboBoxSelector';

export interface ProductSelectorProps {
  productsContractsData: ProductContractData[]; // product and contract data to display in the Combobox
  displayContractNames: boolean; // determines whether contract names should be displayed alongside the product names
  clearSelection: boolean; // if true, ProductSelector does not show selectd items (used in ProductSections), if false, displays selected items (used with ProfileSelector)
  label: string; // label string to display for the Combobox
  width?: string; // how wide the Combobox should be
  disabled: boolean; // controls disabling the Combobox
  isLoading: boolean; // controls whether the wait component should display in the Combobox
  onSelect: (productId: string) => void; // callback when a product is selected (passes the product id of the selected product)
}

/**
 * Group products into sections with contract data by cloud/family for grouped display in the Combobox
 */
function groupMenuItems(productsContractsData: ProductContractData[]): SelectorSection<ProductContractData>[] {
  const groupedData = _.groupBy(
    productsContractsData,
    (productContractData: ProductContractData): string => productContractData.product.cloud ?? ''
  );
  return _.map(
    groupedData,
    (group: ProductContractData[], cloud: string): SelectorSection<ProductContractData> => ({
      sectionName: UProduct.cloudDisplayName(cloud as Cloud),
      sectionData: group,
    })
  );
}

/**
 * Combobox component specific for selecting products.
 * Handles displaying products with contract names and icons.  Also handles grouping products into sections by cloud/family.
 */
function ProductSelector(props: ProductSelectorProps): React.ReactElement {
  const root = document.getElementById('root') as HTMLElement;
  // This hack is necessary to get a v3 Combobox to work with a v2 Dialog (remove once all dialogs using ProductSelector are converted to RSV3)
  if (root) {
    root.style.isolation = 'isolate'; // This hack is necessary to get a v3 Combobox to work with a v2 Dialog
  }

  const {
    clearSelection,
    width,
    label,
    disabled,
    isLoading,
    displayContractNames,
    productsContractsData,
    onSelect,
    ...otherProps
  } = props;
  const productContractGroups = groupMenuItems(productsContractsData);

  const onProductKeySelect = useCallback((key: Key) => onSelect(key as string), []);

  // remove Provider once all dialogs using ProductSelector are converted to RSV3
  return (
    <Provider theme={defaultTheme} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
      <ComboBoxSelector
        clearSelection={clearSelection}
        width={width}
        label={label}
        isDisabled={_.isEmpty(productContractGroups) || disabled || isLoading}
        loadingState={isLoading ? 'loading' : undefined}
        defaultItems={productContractGroups}
        onSelect={onProductKeySelect}
        {...otherProps}
      >
        {(productContractGroup: SelectorSection<ProductContractData>) => (
          <Section
            title={productContractGroup.sectionName}
            key={productContractGroup.sectionName}
            items={productContractGroup.sectionData}
          >
            {(productContractData: ProductContractData) => (
              <Item key={productContractData.product.id} textValue={productContractData.product.name}>
                <Image
                  alt=""
                  width="size-300"
                  height="size-300"
                  slot="icon"
                  src={productContractData.product.icons.svg ?? ''}
                />
                <Text>{productContractData.product.name}</Text>
                {displayContractNames && <Text slot="description">{productContractData.contractNames}</Text>}
              </Item>
            )}
          </Section>
        )}
      </ComboBoxSelector>
    </Provider>
  );
}
export default ProductSelector;
