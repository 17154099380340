import { ObjectTypes, OrgOperation } from '../orgMaster/OrgMaster';

import { UCompartmentPoliciesData } from '../orgMaster/UCompartmentPolicy';
import { UOrgMasterData, Update } from '../orgMaster/UOrgMaster';
import { UOrgData } from '../orgMaster/UOrg';
import { UProductData } from '../orgMaster/UProduct';
import { UProductProfileData } from '../orgMaster/UProductProfile';

import { UAdminData } from '../orgMaster/UAdmin';
import { UUserGroupData } from '../orgMaster/UUserGroup';
import { UDomainData } from '../orgMaster/UDomain';
import { ErrorCodesData, MessageData } from '../Codes/ErrorCodes';

/**
 * Defines enumerations and interfaces for input and outputs of the BanyanJobs api.
 */

/**
 * Enumerations for job state.
 */
export enum JobResultState {
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  WAITING = 'WAITING',
  WAITINGFORDURATION = 'WAITINGFORDURATION',
  CANCELLING = 'CANCELLING',
  WAITINGANDCANCELLING = 'WAITINGANDCANCELLING',
}

/**
 * Enumerations for job status.
 * Specifies whether a job has succeeded or failed.
 */
export enum JobResultStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
}

export enum CommandStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  NOTHING = '', // initially command status is null
}

/**
 * Status of a job.
 */
export interface JobInfo {
  id: string;
  ownerEmail: string;
  jobStatus: JobResultStatus;
  jobState: JobResultState;
  jobDone: boolean;
  createdAt: string;
  updatedAt: string;
  errorData?: ErrorCodesData;
  org: string;
}

/**
 * Single org data retrieved from a job.
 *   - status: Some string denoting a status for the retrieved data.
 *   - elem: An org data object (containing back-end data about org, products, users, etc).
 *   - elemType: Denotes the type of org data object (org, product, user, etc).
 */

// ElemInfo is a set of values used to better display update and diff messages.  They come back from diff and update service
// calls in the json elemInfo field and within this field can be the following elements.
export interface ElemInfo {
  orgPathname?: string;
  oldOrgPathname?: string;
  oldOrgSimpleName?: string;
  oldParentId?: string;
  pdtName?: string; // may be used in future
}
export interface ElemInfoWrap {
  id: string;
  elemInfo?: ElemInfo;
}
export interface CommandValue {
  status: CommandStatus; // SUCCESS or ? (not the same as JobResultStatus)  Error Code
  elem:
    | UOrgMasterData
    | UOrgData
    | UCompartmentPoliciesData
    | UDomainData
    | UProductData
    | UProductProfileData
    | UAdminData
    | UUserGroupData
    | ElemInfoWrap; // this variant lets you read the additional information used for better command display
  elemType: ObjectTypes;
  operation: OrgOperation;
  messageData: MessageData;
  executionOrder?: number;
}

/**
 * Combination of the job status and org data
 * retrieved from job.
 */
export interface JobData {
  jobInfo: JobInfo;
  values: CommandValue[];
}

/**
 * Object returned from job progress restful api call.
 * This includes the job status and org data.
 */
export interface JobProgressResponse {
  result: JobData;
}

/**
 * Status of multiple jobs.
 *   - lastPage: Indicates whether this is the last data chunk retrieved from a call to get all jobs.
 */
export interface JobStatus {
  jobs?: JobInfo[];
  lastPage?: boolean;
}

/**
 * Object returned from a get job restful api call.
 */
export interface JobStatusResponse {
  result: JobStatus;
}

/**
 * Object returned from a cancel job restful api call.
 */
export interface JobStateResponse {
  result: JobInfo;
}

export interface UpdateJobOptions {
  dryRun: boolean;
  updates: Update[];
  createdInOrg: string; // org job is run from
}

/**
 * Options to provide as input to the get jobs restful api call
 * when retrieving job statuses.
 *   - ownerEmail: The logged in users email to specify retrieval of jobs pertaining to that user.
 *   - page: Data is retrieved in chunks, specifies which chunk to retrieve.
 *   - pageSize: Specifies the size of the chunk to retrieve.
 */
export interface JobGetOptions {
  ownerEmail?: string;
  page?: number;
  pageSize?: number;
  createdInOrg?: string;
  includeChildren?: boolean;
}

/**
 * Options to provide as input to the progress jobs restful api call.
 *   - first_index: Data is retrieved in chunks, specifies which chunk to retrieve.
 *   - max_values: Specifies the maximum size of a chunk to retrieve.
 */
export interface JobProgressOptions {
  firstIndex?: number;
  maxValues?: number;
}

export interface ExportJob {
  jobId: string;
}
