import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';

import ImportTypeInfo from './ImportTypeInfo';
import ImportOperations from './ImportOperations';
import Utils from '../Utils';
import ImportUtils from '../../../Compartments/ExportImport/Import/ImportUtils';
import ImportOperationsUtils from './ImportOperationsUtils';
import { DataModel } from '../../../Compartments/ExportImport/Import/DataModelTypes/DataModelTypes';

type ImportOperationsKey = keyof typeof ImportOperations;
const ImportOperationsValues: string[] = _.map(
  _.keys(ImportOperations) as ImportOperationsKey[],
  (key: ImportOperationsKey): string => ImportOperations[key]
);

const messages = defineMessages({
  MissingRequiredFields: {
    id: 'Organizations.Import.Validations.MissingRequiredFields',
    defaultMessage: 'For record {index}, the following required fields are missing: ',
  },
  PropNameType: {
    id: 'Organizations.Import.Validations.PropNameType',
    defaultMessage: '{propName} must be a {type}',
  },
  InvalidOperation: {
    id: 'Organizations.Import.Validations.InvalidOperation',
    defaultMessage: 'For record {index}, operation is invalid',
  },
  RecordLabel: {
    id: 'Organizations.Import.Validations.RecordLabel',
    defaultMessage: 'For record {index}',
  },
});

class ValidateTypeInfo {
  /**
   * Throws an error if the given data object does not contain values for the required props on ProdAllocImportData
   * The given index refers to the given data object's location from an array.  This is to provide more details for errors.
   */
  static validateRequiredFields<DataModelType>(
    importData: DataModelType,
    index: number,
    IMPORT_TYPE_MAP: ImportTypeInfo<DataModelType>[],
    sectionLabel: string,
    intl: IntlShape
  ): void {
    const missingPropertyNames: string[] = [];

    const REQUIRED_IMPORT_PROPS = _.filter(
      IMPORT_TYPE_MAP,
      (importType: ImportTypeInfo<DataModelType>): boolean => !importType.optional
    );

    _.forEach(REQUIRED_IMPORT_PROPS, (prop: ImportTypeInfo<DataModelType>): void => {
      if (importData[prop.propName] === undefined) {
        missingPropertyNames.push(prop.propName as string);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      sectionLabel,
      formatMessage(messages.MissingRequiredFields, { index: index + 2 }),
      missingPropertyNames
    );
  }

  /**
   * Checks whether the type of a given value matches the given type name or any of the given type names
   */
  static validateType(value: any, type: string | string[]): boolean {
    const types: string[] = typeof type === 'string' ? [type] : type;
    let typeMatches = false;
    for (let i = 0; i < types.length; i++) {
      switch (types[i].toLowerCase()) {
        case 'boolean':
          if (Utils.canParseBool(value)) {
            typeMatches = true;
          }
          break;

        case 'number':
          if (Utils.canParseInt(value)) {
            typeMatches = true;
          }
          break;

        default:
          if (typeof value === types[i]) {
            typeMatches = true;
            break;
          }
      }
    }
    return typeMatches;
  }

  /**
   * Throws an error if the given data object contains values that don't match the correct type specified by ProdAllocImportData.
   * The given index refers to the given data object's location from an array.  This is to provide more details for errors.
   * The given orgList is only
   */
  static validateFieldTypes<DataModelType>(
    importData: DataModelType,
    index: number,
    IMPORT_TYPE_MAP: ImportTypeInfo<DataModelType>[],
    sectionLabel: string,
    intl: IntlShape
  ): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(IMPORT_TYPE_MAP, (typeInfo: ImportTypeInfo<DataModelType>): void => {
      const wrongType: boolean = _.isNil(importData[typeInfo.propName])
        ? !typeInfo.optional
        : !ValidateTypeInfo.validateType(importData[typeInfo.propName], typeInfo.typeString);
      if (wrongType) {
        errorMessages.push(
          formatMessage(messages.PropNameType, {
            propName: typeInfo.propName.toString(),
            type: typeInfo.typeString.toString(),
          })
        );
      }
    });
    ImportUtils.displayIfErrorWithHeader(
      sectionLabel,
      formatMessage(messages.RecordLabel, { index: index + 2 }),
      errorMessages
    );
  }

  static validateOperationField<DataModelType>(
    importData: DataModelType,
    index: number,
    IMPORT_TYPE_MAP: ImportTypeInfo<DataModelType>[],
    sectionLabel: string,
    intl: IntlShape
  ): void {
    // get operation field type info
    const operationTypeInfo = _.find(IMPORT_TYPE_MAP, (typeMap: ImportTypeInfo<DataModelType>): boolean =>
      _.isEqual(typeMap.propName, 'operation')
    );

    // check if the operation field to set to valid value
    if (
      !_.isNil(operationTypeInfo) &&
      !_.isNil(importData[operationTypeInfo.propName]) &&
      !_.isEmpty(importData[operationTypeInfo.propName]) &&
      !_.find(
        ImportOperationsValues,
        (operation: ImportOperations): boolean =>
          operation === _.toUpper(importData[operationTypeInfo.propName] as unknown as string)
      )
    ) {
      const { formatMessage } = intl;
      ImportUtils.displayIfError(sectionLabel, [formatMessage(messages.InvalidOperation, { index: index + 2 })]);
    }
  }

  static validate<DataModelType extends DataModel>(
    allImportData: DataModelType[],
    IMPORT_TYPE_MAP: ImportTypeInfo<DataModelType>[],
    sectionLabel: string,
    intl: IntlShape
  ): void {
    _.forEach(allImportData, (importData: DataModelType, index: number): void => {
      ValidateTypeInfo.validateOperationField<DataModelType>(importData, index, IMPORT_TYPE_MAP, sectionLabel, intl);
      if (ImportOperationsUtils.isOperationValid(importData.operation)) {
        // validate required fields and its types only when the operation='CREATE'|'UPDATE'|'DELETE'
        ValidateTypeInfo.validateRequiredFields<DataModelType>(importData, index, IMPORT_TYPE_MAP, sectionLabel, intl);
        ValidateTypeInfo.validateFieldTypes<DataModelType>(importData, index, IMPORT_TYPE_MAP, sectionLabel, intl);
      }
    });
  }
}

export default ValidateTypeInfo;
