import React, { useRef, useState } from 'react';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Alert from '@react/react-spectrum/Icon/Alert';
import Button from '@react/react-spectrum/Button';
import ModalContainer from '@react/react-spectrum/ModalContainer';

import LocalePopover from '../../../components/LocalePopover/LocalePopover';
import GoUrl, { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoToAdminConsoleDialog from '../../../components/GoToAdminConsoleDialog/GoToAdminConsoleDialog';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { LicenseTuple } from '../../../services/orgMaster/LicenseTuple';

import config from '../../../configurations/config';

import './ExpirationIcon.css';

const localeMessages = defineMessages({
  expiringTooltipTitle: {
    id: 'productAllocation.expirationIcon.tooltip.expiringTitle',
    defaultMessage: 'This product is expiring soon',
  },
  expiredTooltipTitle: {
    id: 'productAllocation.expirationIcon.tooltip.expiredTitle',
    defaultMessage: 'This product has expired',
  },
  notificationLink: {
    id: 'productAllocation.expirationIcon.tooltip.notificationLinkText',
    defaultMessage: 'See what you can do now',
  },
  graceLink: {
    id: 'productAllocation.expirationIcon.tooltip.gracePeriodLinkText',
    defaultMessage: 'See what you need to do now',
  },
  postGraceLink: {
    id: 'productAllocation.expirationIcon.tooltip.postGracePeriodLinkText',
    defaultMessage: 'See what you need to do now',
  },
  ariaLabelWarning: {
    id: 'productAllocation.expirationIcon.tooltip.ariaLabelWarning',
    defaultMessage: 'Warning',
  },
  ariaLabelError: {
    id: 'productAllocation.expirationIcon.tooltip.ariaLabelError',
    defaultMessage: 'Error',
  },
  goToAdminConsole: {
    id: 'productallocation.expirationIcon.tooltip.goToAdminConsole',
    defaultMessage: 'Go to Admin Console',
  },
});

interface ExpirationIconProps {
  org: UOrgMaster; // id of the org that the product for the given licenseTuple belongs to
  // LicenseTuple used to determine the messaging and styling.  undefined LicenseTuple will result in no tooltip or alert icon.
  licenseTuple: LicenseTuple | undefined;
  // true the tooltip is intended for an editable grant input field or false if it is intended for a read-only grant text field.
  // This is necessary to determine the appropriate alignment and styling against different the different types of components.
  isEditableField: boolean;
  context: React.ReactNode; // a reference to a parent component of this component (this is specific for the 'Go to Admin Console' button)
}

/**
 * Handles formatting of the date to display in the UI.
 * If no date is available a <date unavailable> message is displayed.
 * TODO: This method will either be removed when CLAM implements compliance for allocations or the case
 * for when the date is undefined will be removed.
 *
 * @param date date to format for display in the UI
 * @returns formatted date string/element
 */
function createFormattedDate(date: Date | undefined): React.ReactNode {
  if (date) {
    return <FormattedDate value={date} />;
  }
  // empty string is not ideal for the user, but it is ok because it is temporary until CLAM implements compliance for allocation.
  return '';
}

interface ExpirationTooltipMessageProps {
  licenseTuple: LicenseTuple | undefined;
  linkOnClickCallback?: React.MouseEventHandler;
}

/**
 * Generates message content for the compliance tooltip of a product license.
 * The messages are based on the LicenseTuple.
 *
 * @param licenseTuple LicenseTuple used to determine the message.  undefined LicenseTuple will result in no content.
 * @param linkOnClickCallback Callback that will trigger if the link in the expiration tooltip message is clicked.
 * @returns The message content for compliance tooltip.
 */
function ExpirationTooltipMessage({ licenseTuple, linkOnClickCallback }: ExpirationTooltipMessageProps) {
  const { formatMessage } = useIntl();
  if (licenseTuple?.isNotificationPhase()) {
    return (
      <span data-testid="compliance-tooltipMessage-notificationPhase">
        <FormattedMessage
          id="productAllocation.expirationIcon.tooltip.notificationMessage"
          defaultMessage="The contract for this product will expire on {date}. {linkText} or contact your organization's administrator for assistance to prevent users from losing access to their apps."
          values={{
            date: createFormattedDate(licenseTuple.expirationDate()),
            linkText: (
              <GoUrl goUrlKey={GoUrlKeys.contractExpirationProducts} target="gac_help" onClick={linkOnClickCallback}>
                {formatMessage(localeMessages.notificationLink)}
              </GoUrl>
            ),
          }}
        />
      </span>
    );
  }
  if (licenseTuple?.isGracePhase()) {
    return (
      <span data-testid="compliance-tooltipMessage-gracePhase">
        <FormattedMessage
          id="productAllocation.expirationIcon.tooltip.gracePeriodMessage"
          defaultMessage="The contract for this product has expired. {linkText} or contact your organization's administrator for assistance to prevent users from losing access to their apps on {date}."
          values={{
            linkText: (
              <GoUrl goUrlKey={GoUrlKeys.contractExpirationProducts} target="gac_help" onClick={linkOnClickCallback}>
                {formatMessage(localeMessages.graceLink)}
              </GoUrl>
            ),
            date: createFormattedDate(licenseTuple.accessLostDate()),
          }}
        />
      </span>
    );
  }
  if (licenseTuple?.isPostGracePhase()) {
    return (
      <span data-testid="compliance-tooltipMessage-postGracePhase">
        <FormattedMessage
          id="productAllocation.expirationIcon.tooltip.postGracePeriodMessage"
          defaultMessage="The contract for this product has expired, and users have lost access to their apps. {linkText} or contact your organization's administrator for assistance."
          values={{
            linkText: (
              <GoUrl goUrlKey={GoUrlKeys.contractExpirationProducts} target="gac_help" onClick={linkOnClickCallback}>
                {formatMessage(localeMessages.postGraceLink)}
              </GoUrl>
            ),
          }}
        />
      </span>
    );
  }
  return <></>;
}

/**
 * Component that displays an alert icon depending on LicenseTuple compliance and also handles
 * displaying a tooltip on hover as well as its contents.
 * Content such as type of alert icon, tooltip title, and tooltip messages are determined by the LicenseTuple.
 */
function ExpirationIcon(props: ExpirationIconProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { licenseTuple, isEditableField, ...componentProps } = props;
  const [hovering, saveHovering] = useState(false);
  const overlayComponent = useRef<OverlayTrigger & HTMLElement>(null);
  return (
    <span {...componentProps}>
      {licenseTuple?.shouldShowExpireMessages() && (
        <OverlayTrigger placement="right" trigger="click" ref={overlayComponent}>
          <Button
            className={`ExpirationIcon__alertIcon ${!isEditableField ? 'ExpirationIcon__alertIconTextField' : ''}`}
            quiet
            variant="action"
            icon={
              <Alert
                className={`${
                  licenseTuple.isNotificationPhase()
                    ? 'ExpirationIcon__alertIconWarning'
                    : 'ExpirationIcon__alertIconError'
                }${hovering ? 'Hover' : ''}`}
                size="S"
              />
            }
            aria-label={
              licenseTuple.isNotificationPhase()
                ? formatMessage(localeMessages.ariaLabelWarning)
                : formatMessage(localeMessages.ariaLabelError)
            }
            onMouseEnter={(): void => saveHovering(true)}
            onMouseLeave={(): void => saveHovering(false)}
            data-testid="compliance-tooltipAlert"
          />
          <LocalePopover
            className="ExpirationIcon__alertTooltip"
            title={
              licenseTuple.isNotificationPhase()
                ? formatMessage(localeMessages.expiringTooltipTitle)
                : formatMessage(localeMessages.expiredTooltipTitle)
            }
            variant={licenseTuple.isNotificationPhase() ? 'default' : 'error'}
            role="tooltip"
            data-testid="compliance-tooltip"
          >
            <>
              <ExpirationTooltipMessage
                licenseTuple={licenseTuple}
                linkOnClickCallback={(): void => overlayComponent.current?.hide()}
              />
              <div>
                <Button
                  className="ExpirationIcon__tooltipButton"
                  tabIndex={0}
                  aria-label={formatMessage(localeMessages.goToAdminConsole)}
                  onClick={(): void => {
                    const goToAdminConsoleDialogRef = ModalContainer.show(
                      <GoToAdminConsoleDialog
                        onClose={(): void => {
                          ModalContainer.hide(goToAdminConsoleDialogRef);
                        }}
                        url={`${config.adminConsole.url}/${props.org.id}/overview`}
                        selectedOrg={props.org}
                      />,
                      props.context
                    );
                    overlayComponent.current?.hide();
                  }}
                >
                  {formatMessage(localeMessages.goToAdminConsole)}
                </Button>
              </div>
            </>
          </LocalePopover>
        </OverlayTrigger>
      )}
    </span>
  );
}
export default ExpirationIcon;
