import * as _ from 'lodash';

import { OrgOperation, ErrorData } from './OrgMaster';

/* eslint-disable import/no-cycle */
import { UBasic, UBasicData } from './UBasic';
import { UOrgMaster } from './UOrgMaster';
import { UProductProfile } from './UProductProfile';
/* eslint-enable import/no-cycle */

import TempIdGenerator from '../utils/TempIdGenerator';
import { UUserGroupShare } from './UUserGroupShare';

/**
 * UUserGroup represents a user group org data object.
 */

/**
 * Mutable object for UUserGroup.
 * Provides object containing only properties for UUserGroup.
 * Represents a UUserGroup object that can be retrieved from the back-end.
 */
export interface UUserGroupData extends UBasicData {
  name?: string; // user group name
  description?: string; // description of the user group
  userCount?: number; // number of users in the user group
  profiles?: UUserGroupLicenseGroup[]; // array of product profile ids or names the user group belongs to
  orgId?: string; // id of the org the user group belongs to
  // adminCount?: number; DO NOT add admin count unless JIL call to fetch user groups for a given profile includes admin count, see this: https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1829781156
  isTarget?: boolean; // is the user group a target group
  isSource?: boolean; // is the user group a source group
  sharedUserGroupTargets?: UUserGroupShare[];
  sharedUserGroupSource?: UUserGroupShare;
}

export interface UUserGroupLicenseGroup {
  id: string;
  name?: string;
  productId?: string;
}

/**
 * UUserGroup object that also contains the methods and functionality.
 */
export class UUserGroup extends UBasic implements UUserGroupData {
  name: string = '';
  description: string = '';
  userCount: number = 0;
  profiles: UUserGroupLicenseGroup[] = [];
  profilesLoaded: boolean = false;
  promiseProfilesLoaded: Promise<void> | null = null;
  orgId: string = '';
  adminsLoaded: boolean = false;
  isTarget?: boolean = false;
  isSource?: boolean = false;
  sharedUserGroupTargets?: UUserGroupShare[] = [];
  sharedUserGroupSource?: UUserGroupShare = undefined;

  /**
   * Constructs a UUserGroup from either UUserGroupData or creates a copy of another UUserGroup object.
   */
  constructor(userGroup?: UUserGroupData, newUserGroup = false) {
    super(userGroup);
    if (userGroup) {
      this.name = userGroup.name || this.name;
      this.description = userGroup.description || this.description;
      this.userCount = userGroup.userCount || this.userCount;
      this.profiles = _.cloneDeep(userGroup.profiles) || this.profiles;
      this.orgId = userGroup.orgId || this.orgId;
      if (newUserGroup) {
        this.profilesLoaded = true;
        this.adminsLoaded = true;
      }
      this.sharedUserGroupTargets = _.cloneDeep(userGroup.sharedUserGroupTargets) || this.sharedUserGroupTargets;
      this.sharedUserGroupSource = userGroup.sharedUserGroupSource || this.sharedUserGroupSource;
      this.isSource = userGroup.isSource || this.isSource;
      this.isTarget = userGroup.isTarget || this.isTarget;
    }
  }

  /**
   * JSON representation of UUserGroup.
   */
  toJSON(): object {
    return _.assign(super.toJSON(), {
      name: this.name,
      description: this.description,
      userCount: this.userCount,
      profiles: this.profiles,
      orgId: this.orgId,
    });
  }

  /**
   * Associate user group with given product profile.
   */
  addProfile(profile: UProductProfile): void {
    if (profile.id) {
      this.profiles.push({ id: profile.id });
    } else {
      this.profiles.push({ id: profile.name });
    }
  }

  /**
   * Disassociate user group with given product profile.
   */
  removeProfile(profile: UProductProfile): void {
    let profileIndex: number;
    if (profile.id) {
      profileIndex = this.profiles.map((eachProfile) => eachProfile.id).indexOf(profile.id);
    } else {
      profileIndex = this.profiles.map((eachProfile) => eachProfile.id).indexOf(profile.name);
    }
    if (profileIndex !== -1) {
      this.profiles.splice(profileIndex, 1);
    }
  }

  /**
   * Compare the editable value of this user group with the one passed in the param
   * @param otherUG UUserGroup
   */
  isEqual(otherUG: UUserGroup): boolean {
    return this.name === otherUG.name && _.isEqual(this.profiles, otherUG.profiles);
  }

  static getNewUserGroup = (orgId: string): UUserGroup => {
    const newUserGroup = new UUserGroup({
      id: TempIdGenerator.getTempIdAndIncrement(),
      name: '',
      orgId,
      profiles: [],
    });
    newUserGroup.toggleCreateItem();
    return newUserGroup;
  };

  /**
   * Method for adding a user group to an org.
   * Delete and Update operations will set a delete or update state for the given elem (if it exists on the given org).
   * Create operation will add the given user group to the given org.
   *   - org: An org that the elem should be imported to or the elem should be associated with to perform an operation.
   *   - elem: The user group to perform the operation on.
   *   - operation: The operation to perform.
   * This method returns an error object or an array of errors, or a null value denoting success.
   */
  static importElement(org: UOrgMaster, elem: UBasicData, operation: OrgOperation): ErrorData | ErrorData[] | null {
    return UBasic.importElement(
      org,
      elem,
      operation,
      (e: UBasicData): UBasic | undefined => _.find(org.userGroups, { id: e.id }),
      (e: UBasicData): ErrorData | ErrorData[] | null => {
        org.addNewUserGroup(new UUserGroup(e));
        return null;
      }
    );
  }
}
