import config from '../configurations/config';
import AuthenticatedUser from '../services/authentication/AuthenticatedUser';
import { LocaleSettings } from '../services/locale/LocaleSettings';
import Utils from '../services/utils/Utils';
import { MESSAGES } from '../Compartments/Messages';

const url = `${config.imsApi.url}`;
const apiKey = `${config.imsApi.apiKey}`;

export interface Country {
  countryName: string;
  countryCode: string;
}

export interface CountryData {
  countries: Country[];
}

// Fetches the list of countries from ims with the given locale value
class ImsProvider {
  static async getCountries(signal?: AbortSignal): Promise<CountryData> {
    // NOTE: the locale returned by LocaleSettings is of the form en-US, and the expected form for ims/countrie os en_US
    const locale = LocaleSettings.getSelectedLanguageTag().replace('-', '_');
    const response = await fetch(`${url}/countries/v1?locale=${locale}&client_id=${apiKey}`, {
      method: 'GET',
      headers: {
        'X-Api-Key': config.imsLib.clientId,
        Authorization: `Bearer ${AuthenticatedUser.getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      signal,
    });
    const countryData: CountryData = await response.json();
    Utils.throwIfError(response, countryData, MESSAGES.GetCountryApiError);
    return countryData;
  }
}
export default ImsProvider;
