import * as _ from 'lodash';
import config from '../configurations/config';
import ContractJIL from '../services/orgMaster/ContractsJIL';
import AuthenticatedUser from '../services/authentication/AuthenticatedUser';
import Utils from '../services/utils/Utils';
import { MESSAGES } from '../Compartments/Messages';
import ProviderUtil, { PageOptions, ResponseWithCounts } from '../services/providerUtils/ProviderUtil';
import GenerateBanyanSvcHeaders from '../services/providerUtils/generateBanyanSvcHeaders';

const url = `${config.jilApi.url}`;

class JilAPIProvider {
  static async getContracts(orgId: string, signal?: AbortSignal): Promise<ContractJIL[]> {
    let getContractsJILUrl: string = `${url}/v2/organizations/${orgId}/contracts`;
    // UI never uses or displays inactive contracts, so they should be excluded from the response
    getContractsJILUrl = ProviderUtil.getURLWithParam(getContractsJILUrl, 'includeInactiveContracts', 'false');
    const response = await fetch(getContractsJILUrl, {
      method: 'GET',
      headers: {
        'X-Api-Key': config.jilApi.apiKey,
        'x-jil-feature': 'use_clam',
        Authorization: `Bearer ${AuthenticatedUser.getAccessToken()}`,
      },
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetContractsError);
    return respBody;
  }

  static async getDirectories(orgId: string, options?: PageOptions, signal?: AbortSignal): Promise<ResponseWithCounts> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    let adminsUrl: string = `${url}/v2/organizations/${orgId}/directories`;
    if (options) {
      adminsUrl = ProviderUtil.addPageOptions(adminsUrl, options);
    }
    const response = await fetch(adminsUrl, {
      method: 'GET',
      headers: GenerateBanyanSvcHeaders.generateJILSvcHeaders(),
      signal,
    });
    return await ProviderUtil.getResponseWithCounts(response, MESSAGES.GetAdminsApiError);
  }
  static async getProducts(orgId: string, signal?: AbortSignal): Promise<any> {
    if (_.isEmpty(orgId)) {
      Utils.throwLocalizedError(MESSAGES.OrgIdEmptyError);
    }
    const getProductsJILUrl: string = `${url}/v2/organizations/${orgId}/products`;
    const response = await fetch(getProductsJILUrl, {
      method: 'GET',
      headers: GenerateBanyanSvcHeaders.generateJILSvcHeaders(),
      signal,
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, MESSAGES.GetProductsError);
    return respBody;
  }
}

export default JilAPIProvider;
