/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { defineMessages, useIntl } from 'react-intl';
import { OrganizationSelectorContentModel } from '@pandora/react-insights';
import { defineContents } from '@pandora/react-content-provider';

const messages = defineMessages({
  Cancel: {
    id: 'CreateUserGroupShare.Modal.Button.Cancel',
    defaultMessage: 'Cancel',
  },
  Cta: {
    id: 'CreateUserGroupShare.Modal.Button.Cta',
    defaultMessage: 'Share User Groups',
  },
  Error: {
    id: 'CreateUserGroupShare.Modal.Error',
    defaultMessage: "Something went wrong. We're working on the problem. Please try again later.",
  },
  Next: {
    id: 'CreateUserGroupShare.Modal.Button.Next',
    defaultMessage: 'Next',
  },
  Previous: {
    id: 'CreateUserGroupShare.Modal.Button.Previous',
    defaultMessage: 'Previous',
  },
  ReviewHeader: {
    id: 'CreateUserGroupShare.Modal.ReviewPane.Header',
    defaultMessage: 'Review user groups to share with another organization',
  },
  SelectDescription: {
    id: 'CreateUserGroupShare.Modal.SelectPane.Description',
    defaultMessage:
      'Select the organizations that will receive the selected user groups. The selected organizations will be able to access the users in these shared groups.',
  },
  SelectHeader: {
    id: 'CreateUserGroupShare.Modal.SelectPane.Header',
    defaultMessage: 'Select organizations to share user groups',
  },
  Loading: {
    id: 'CreateUserGroupShare.Modal.Loading',
    defaultMessage: 'Loading content ...',
  },
  Search: {
    id: 'CreateUserGroupShare.Modal.Selector.Search',
    defaultMessage: 'Search',
  },
  Count: {
    id: 'CreateUserGroupShare.Modal.Selector.Count',
    defaultMessage: '{count} selected',
  },
  TableHeader: {
    id: 'CreateUserGroupShare.Modal.Selector.TableHeader',
    defaultMessage: 'NAME',
  },
});

/**
 * Pandora style content model sourced from Banyan's existing react-intl messages.
 */
export const CreateUserGroupShareContentModel = defineContents('CreateUserGroupShare', {
  cancelLabel: {
    id: 'cancelLabel',
    placeholderContent: messages.Cancel.defaultMessage,
    type: 'string',
  },
  ctaLabel: {
    id: 'ctaLabel',
    placeholderContent: messages.Cta.defaultMessage,
    type: 'string',
  },
  error: {
    id: 'error',
    placeholderContent: messages.Error.defaultMessage,
    type: 'string',
  },
  loadingAriaLabel: {
    id: 'loadingAriaLabel',
    placeholderContent: messages.Loading.defaultMessage,
    type: 'string',
  },
  nextLabel: {
    id: 'nextLabel',
    placeholderContent: messages.Next.defaultMessage,
    type: 'string',
  },
  previousLabel: {
    id: 'previousLabel',
    placeholderContent: messages.Previous.defaultMessage,
    type: 'string',
  },
  reviewHeader: {
    id: 'reviewHeader',
    placeholderContent: messages.ReviewHeader.defaultMessage,
    type: 'string',
  },
  selectDescription: {
    id: 'selectDescription',
    placeholderContent: messages.SelectDescription.defaultMessage,
    type: 'string',
  },
  selectHeader: {
    id: 'selectHeader',
    placeholderContent: messages.SelectHeader.defaultMessage,
    type: 'string',
  },
  selector: {
    id: 'selector',
    type: OrganizationSelectorContentModel,
    placeholderContent: {
      search: messages.Search.defaultMessage,
      count: messages.Count.defaultMessage,
      header: messages.TableHeader.defaultMessage,
    },
  },
});

/*
 * Construct the model for Insights Pandora component content strings with localization and return it
 * @see https://git.corp.adobe.com/PandoraUI/administration/blob/master/packages/react-insights/src/InsightsContentModel.ts
 * Any new strings added to the model above provided in the link, SHOULD be added here, otherwise an empty string will be rendered.
 */
export const useCreateUserGroupShareContent = (selectedCount: number) => {
  const { formatMessage } = useIntl();

  return CreateUserGroupShareContentModel.createEntry({
    cancelLabel: formatMessage(messages.Cancel),
    ctaLabel: formatMessage(messages.Cta),
    error: formatMessage(messages.Error),
    loadingAriaLabel: formatMessage(messages.Loading),
    nextLabel: formatMessage(messages.Next),
    previousLabel: formatMessage(messages.Previous),
    reviewHeader: formatMessage(messages.ReviewHeader),
    selectDescription: formatMessage(messages.SelectDescription),
    selectHeader: formatMessage(messages.SelectHeader),
    selector: {
      search: formatMessage(messages.Search),
      selectedCount: formatMessage(messages.Count, { count: selectedCount }),
      header: formatMessage(messages.TableHeader),
    },
  });
};
