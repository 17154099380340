import * as _ from 'lodash';
import * as log from 'loglevel';
import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Switch from '@react/react-spectrum/Switch';
import Heading from '@react/react-spectrum/Heading';
import Textfield from '@react/react-spectrum/Textfield';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import Select, { SelectOption } from '@react/react-spectrum/Select';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import { UResource, UResourceData } from '../../../../../services/orgMaster/UResource';
import { UProduct } from '../../../../../services/orgMaster/UProduct';
import EnabledServices from '../../common/EnabledServices/EnabledServices';
import { UProductProfile } from '../../../../../services/orgMaster/UProductProfile';
import { UOrgMaster } from '../../../../../services/orgMaster/UOrgMaster';
import { UAdmin } from '../../../../../services/orgMaster/UAdmin';
import AdminSection from '../../../Widgets/AdminSection/AdminSection';
import UserGroupSection from './UserGroupSection';
import './ProdProfileDialogContent.css';
import '../../../../common.css';
import { UUserGroup, UUserGroupLicenseGroup } from '../../../../../services/orgMaster/UUserGroup';
import Analytics from '../../../../../Analytics/Analytics';
import ProfileDialogService from './ProfileDialogService';
import BanyanCompartmentAPI from '../../../../../providers/BanyanCompartmentAPI';
import { CAP_UNLIMITED, ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from '../../../../../services/orgMaster/OrgMaster';
import { CommandService } from '../../../../../services/Commands/CommandService';
import CommandInterface from '../../../../../services/Commands/CommandInterface';
import { MAX_NAME_ALLOWED_LENGTH } from '../../../Constants';
import { ProductAttributes } from '../../../../../services/orgMaster/ProductAttributes';
import EditNumberInput from '../../../../../ProductAllocation/components/EditNumberInput/EditNumberInput';
import CmdDescriptionUtils from '../../../../../services/Codes/CmdDescriptionUtils';
import HierarchyManager from '../../../../../services/organization/HierarchyManager';
import Utils from '../../../../../services/utils/Utils';

export enum ProfileDialogContext {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

/*
 * EditProdProfileDialogContent receives these props from the EditProdProfileDialog component.
 * - 'context' is initialized at the ProductRow level and passed through the child components
 * - 'profile' is initialized at the ProductRow component and passed through the child components
 * - 'save' callback is implemented at the EditCompartment level and passed through the child components
 */
interface EditProdProfileDialogContentProps extends WrappedComponentProps {
  context: ProfileDialogContext;
  profile: UProductProfile;
  attributes: ProductAttributes;
  update: () => void;
  selectedOrg: UOrgMaster;
}

/*
 * EditProdProfileDialogContent has these two states
 * - 'profile' is a copy of the 'profile' props that this component receives from parent
 * - 'compartment' is a copy of the 'compartment' props that gets edited in this component and gets bubbled up
 *    to the EditCompartment component through the 'save' callback
 */
interface EditProdProfileDialogContentState {
  profile: UProductProfile;
  editedCompartment: UOrgMaster;
  profileAdmins: UAdmin[];
  isSubmitting: boolean;
  adminErrorMessage: string;
  userGroupErrorMessage: string;
  errorMessage: string;
  quantityErrorMessage: string;
  productItemErrorMessage: string;
  loadingDefaultProfileResources: boolean; // show loading symbol till response from '/default-profile-resources' is received
  selectedSingleAppProduct: string; // prefix is added in the case of Any Single App product
}

const messages = defineMessages({
  Save: {
    id: 'EditCompartment.Products.Profile.dialog.Save',
    defaultMessage: 'Save',
  },
  AddProfile: {
    id: 'EditCompartment.Products.Profile.dialog.AddProfile',
    defaultMessage: 'Add Profile',
  },
  EditProfile: {
    id: 'EditCompartment.Products.Profile.dialog.EditProfile',
    defaultMessage: 'Edit Profile',
  },
  Cancel: {
    id: 'EditCompartment.Products.Profile.dialog.Cancel',
    defaultMessage: 'Cancel',
  },
  ProfileName: {
    id: 'EditCompartment.Products.Profile.dialog.ProfileName',
    defaultMessage: 'Profile name',
  },
  Product: {
    id: 'EditCompartment.Products.Profile.dialog.SingleApp.Product',
    defaultMessage: 'Products',
  },
  Quota: {
    id: 'EditCompartment.Products.Profile.dialog.Quota',
    defaultMessage: 'Quota',
  },
  Notifications: {
    id: 'EditCompartment.Products.Profile.dialog.Notifications',
    defaultMessage: 'Notifications',
  },
  UnableToAddProfile: {
    id: 'EditCompartment.Products.Profile.dialog.UnableToAddProfile',
    defaultMessage: 'Unable to add profiles at this time.',
  },
  EnterNumber: {
    id: 'EditCompartment.Products.Profile.dialog.EnterNumber',
    defaultMessage: 'Enter a number',
  },
  SelectProduct: {
    id: 'EditCompartment.Products.Profile.dialog.SelectProduct',
    defaultMessage: 'Select a product for this profile',
  },
});

class EditProdProfileDialogContent extends React.Component<
  EditProdProfileDialogContentProps,
  EditProdProfileDialogContentState
> {
  private static QUOTA_MAX = 500000; // value copied from https://git.corp.adobe.com/admin-tribe/twosie/blob/master/src/app/core/products/products.constants.js
  private abortController = new AbortController(); // to avoid calling setState() when unmounted
  private adminEdits: CommandInterface[] = []; // array to maintain the admin edits, these edits are added only when save is clicked, else discarded // TODO: remove once UUsers are no longer supported.
  private editedAdmins: UAdmin[] = []; // array that contains edited admins, these admins are saved only when save is clicked, else discarded
  private userGroupEdits: CommandInterface[] = []; // array to maintain the user group edits, these edits are added only when save is clicked, else discarded

  constructor(props: EditProdProfileDialogContentProps) {
    super(props);
    const updatedProfile = _.find(
      this.props.selectedOrg.getProfiles(),
      (orgProfile: UProductProfile): boolean => orgProfile.id === this.props.profile.id
    );
    const profile: UProductProfile = _.cloneDeep(updatedProfile) || _.cloneDeep(this.props.profile);
    this.state = {
      profile,
      editedCompartment: _.cloneDeep(this.props.selectedOrg), // local copy of compartment to add/delete admins/user groups
      profileAdmins: _.cloneDeep(this.props.selectedOrg.getAdminsForProfile(profile.productId, profile.id)),
      isSubmitting: true,
      adminErrorMessage: '',
      userGroupErrorMessage: '',
      errorMessage: '',
      quantityErrorMessage: '',
      productItemErrorMessage: 'Product not selected for profile',
      selectedSingleAppProduct: '',
      loadingDefaultProfileResources: this.props.context === ProfileDialogContext.ADD, // show loading symbol till response from '/default-profile-resources' is received
    };
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async loadDataForEditProfileDialog(): Promise<void> {
    try {
      await ProfileDialogService.loadProfileAdminsV2(this.props.selectedOrg, this.state.profile);
    } catch (error) {
      this.setState({ adminErrorMessage: `could not load admins for the profile: ${error}` });
    }
    try {
      await ProfileDialogService.loadProfileUserGroups(this.props.selectedOrg, this.state.profile);
    } catch (error) {
      this.setState({ userGroupErrorMessage: `could not load user group for the profile: ${error}` });
    }
  }

  /**
   * If the product to which this profile is added is a new product, BanyanCompartmentAPI.getProfileResourceOptions
   * will return an error. So find first sourceProduct which is already in the hierarchy.
   * @returns first parentOrgId and the sourceProductId in the hierarchy
   */
  getSourceProductIdAndParentOrgIdInHierarchy = (productId: string, orgId: string): string[] | null => {
    if (productId.includes(TEMP_ID_PREFIX)) {
      const orgMaster = HierarchyManager.getOrg(orgId);
      if (orgMaster !== undefined) {
        // find the product on the orgMaster
        const uProduct = _.find(orgMaster.products, (product: UProduct) => _.isEqual(product.id, productId));
        if (uProduct !== undefined) {
          // find the parentOrgMaster to find the sourceProduct
          const parentOrgMaster = orgMaster.getParentOrgMaster();
          if (parentOrgMaster !== undefined) {
            const sourceProduct = _.find(parentOrgMaster.products, (product: UProduct): boolean =>
              _.isEqual(uProduct.sourceProductId, product.id)
            );
            if (sourceProduct !== undefined) {
              return this.getSourceProductIdAndParentOrgIdInHierarchy(sourceProduct.id, sourceProduct.orgId);
            }
          }
        }
      }
      return null;
    }
    return [orgId, productId];
  };

  componentDidMount = async (): Promise<void> => {
    await this.loadDataForEditProfileDialog();
    const updatedProfile = _.find(
      this.props.selectedOrg.getProfiles(),
      (orgProfile: UProductProfile): boolean => orgProfile.id === this.props.profile.id
    );
    const profileState: UProductProfile = _.cloneDeep(updatedProfile) || _.cloneDeep(this.props.profile);
    this.setState({
      editedCompartment: this.props.selectedOrg,
      profile: profileState,
      profileAdmins: _.cloneDeep(this.props.selectedOrg.getAdminsForProfile(profileState.productId, profileState.id)),
    });
    if (this.props.context === ProfileDialogContext.ADD) {
      const { formatMessage } = this.props.intl;
      // for add mode, fetch the profileResourceOptions
      const orgIdAndProductInHierarchy = this.getSourceProductIdAndParentOrgIdInHierarchy(
        this.state.profile.productId,
        this.state.editedCompartment.organization.id
      );
      if (orgIdAndProductInHierarchy === null) {
        this.setState({ errorMessage: formatMessage(messages.UnableToAddProfile) });
      } else {
        try {
          const profileResourceOptions = await BanyanCompartmentAPI.getProfileResourceOptions(
            orgIdAndProductInHierarchy[0],
            orgIdAndProductInHierarchy[1],
            this.abortController.signal
          );
          const profileResources = profileResourceOptions.map((resData: UResourceData) => new UResource(resData));
          if (!this.props.attributes.singleApp) {
            // if it is not Single App product, remove desktop resources, as they are not needed for any purpose.
            _.remove(profileResources, (res: UResource) => res.fulfillableItemType === 'DESKTOP');
          }
          this.setState(
            (prevState: EditProdProfileDialogContentState): Pick<EditProdProfileDialogContentState, never> => {
              const { profile } = prevState;
              profile.resources = profileResources;
              return { profile, loadingDefaultProfileResources: false };
            }
          );
        } catch (err) {
          this.setState({ errorMessage: formatMessage(messages.UnableToAddProfile) });
        }
      }
    }
  };

  /*
   * Updates the compartment state with the modified compartment that gets passed
   */
  updateCompartment = (compartment: UOrgMaster): void => {
    this.setState({
      editedCompartment: compartment,
    });
  };

  /*
   * Updates the profile state with the modified profile that gets passed
   */
  updateProfile = (profile: UProductProfile): void => {
    this.setState({
      profile,
    });
  };

  /**
   * Add admin to be edited if it isn't already set as edited (admin needs to add profile as target or remove profile as target)
   * Remove admin to be edited if it already is set as edited (admin was already set to add or remove profile as target, but now that operation is undone)
   */
  updateEditedAdmins = (admin: UAdmin): void => {
    const adminIndex: number = _.findIndex(this.editedAdmins, (a: UAdmin): boolean => a.id === admin.id);
    if (adminIndex >= 0) {
      this.editedAdmins.splice(adminIndex, 1);
    } else {
      this.editedAdmins.push(admin);
    }
  };

  /*
   * Removes the selected admin from the profile admin list and updates the profile state
   */
  onDeleteAdmin = (admin: UAdmin): void => {
    const profileAdmin: UAdmin | undefined = _.find(
      this.state.profileAdmins,
      (a: UAdmin): boolean => a.id === admin.id
    );
    if (profileAdmin) {
      this.setState((prevState: EditProdProfileDialogContentState): Pick<EditProdProfileDialogContentState, never> => {
        profileAdmin.removeProfileAdminFor(prevState.profile.id, prevState.profile.productId);
        this.updateEditedAdmins(profileAdmin);
        return {
          profileAdmins: _.filter(prevState.profileAdmins, (a: UAdmin): boolean => a.id !== admin.id),
        };
      });
    }
  };

  /*
   * Removes the current profile id from the selected usergroup and updates the compartment state
   */
  onDeleteUserGroup = (userGroup: UUserGroup): void => {
    const { editedCompartment } = this.state;
    const updateUserGroup: UUserGroup | undefined = _.find(
      editedCompartment.userGroups,
      (eachUserGroup: UUserGroup): boolean => eachUserGroup.id === userGroup.id
    );
    const originalUSerGroup = _.cloneDeep(updateUserGroup); // save the original copy before making changes to it
    if (updateUserGroup) {
      updateUserGroup.profiles = _.filter(
        updateUserGroup.profiles,
        (eachProfile: UUserGroupLicenseGroup): boolean => eachProfile.id !== this.state.profile.id
      );
      this.userGroupEdits.push({
        elem: updateUserGroup,
        elemType: ObjectTypes.USER_GROUP,
        operation: OrgOperation.UPDATE, // Deleting a profile from user group is UPDATE operation
        lastUpdatedAt: new Date().getTime(),
        originalElem: originalUSerGroup,
        messageData: {
          // TODO - set the right data here
          cmdDescription: [],
        },
      });
    }
    this.updateCompartment(editedCompartment);
  };

  /*
   * Adds the current profile id to the selected userGroup and updates the compartment state
   */
  onAddUserGroup = (userGroupName: string): void => {
    const { editedCompartment } = this.state;
    _.forEach(editedCompartment.userGroups, (userGroup: UUserGroup): void => {
      if (userGroup.name === userGroupName) {
        const originalUserGroup = _.cloneDeep(userGroup); // save the original copy of user group before making changes to it
        if (
          !_.find(
            userGroup.profiles,
            (eachProfile: UProductProfile): boolean => eachProfile.id === this.state.profile.id
          )
        ) {
          userGroup.profiles.push({
            id: this.state.profile.id,
            name: this.state.profile.name,
            productId: this.state.profile.productId,
          });
        }
        this.userGroupEdits.push({
          elem: userGroup,
          elemType: ObjectTypes.USER_GROUP,
          operation: OrgOperation.UPDATE, // Adding a profile to user group is UPDATE operation
          lastUpdatedAt: new Date().getTime(),
          originalElem: originalUserGroup,
          messageData: {
            // TODO - set the right data here
            cmdDescription: [],
          },
        });
      }
    });
    this.updateCompartment(editedCompartment);
  };

  /*
   * Adds the selected admin and updates the profile state
   */
  onAddAdmin = (email: string): void => {
    if (_.find(this.state.profileAdmins, (eachAdmin: UAdmin): boolean => eachAdmin.email === email)) {
      return;
    }
    const selectedAdmin: UAdmin | undefined = _.find(this.state.editedCompartment.getAdmins(), ['email', email]);
    if (selectedAdmin) {
      this.setState((prevState: EditProdProfileDialogContentState): Pick<EditProdProfileDialogContentState, never> => {
        const { profileAdmins } = prevState;
        const admin: UAdmin = _.cloneDeep(selectedAdmin);
        admin.addProfileAdminFor(
          prevState.profile.id,
          prevState.profile.name,
          prevState.editedCompartment,
          this.state.profile.productId // provide productId because profile may not yet be created yet when looking up associated product
        );
        this.updateEditedAdmins(admin);
        profileAdmins.push(admin);
        return { profileAdmins };
      });
    }
  };

  /*
   * Updates the profile state with profile's modified 'quota' value
   */
  onQuantityChange = (resource: UResource, newQuantity: string | null): void => {
    const { profile } = this.state;
    const profileResource = _.find(profile.resources, ['code', resource.code]);
    if (profileResource) {
      // handle the null case when input is empty
      if (newQuantity === null || _.isEmpty(newQuantity)) {
        this.setState({ quantityErrorMessage: 'Input quantity cannot be empty' });
      } else {
        profileResource.cap = newQuantity; // cap is a string value
        this.setState({ quantityErrorMessage: '' });
      }
      this.updateProfile(profile);
    }
  };

  /*
   * Updates the profile state with profile's modified 'notifications' value
   */
  onNotificationChange = (value: boolean): void => {
    const { profile } = this.state;
    profile.notifications = value;
    this.updateProfile(profile);
  };

  private isProfileUpdate(): boolean {
    const originalProfile: Pick<UProductProfile, never> = _.pick(this.props.profile, [
      'name',
      'notifications',
      'resources',
      'cap',
    ]);
    const editedProfile: Pick<UProductProfile, never> = _.pick(this.state.profile, [
      'name',
      'notifications',
      'resources',
      'cap',
    ]);
    return !_.isEqual(originalProfile, editedProfile);
  }

  /**
   * returns the current product name. If product not found, then returns empty string.
   * NOTE: The chances of not finding the product is very rare as we can never navigate to this dialog
   * without a product in context which the profile is associated with.
   * @private
   */
  private getProductName(): string {
    const currentProduct = _.find(this.props.selectedOrg.products, (product: UProduct) =>
      _.isEqual(product.id, this.state.profile.productId)
    );
    return currentProduct ? currentProduct.name : '';
  }
  /*
   * Locates the current product object and updates the products field with edited profile on the compartment object (copy)
   *  and passes it to the save callback
   */
  onSave = (): void => {
    const { profile } = this.state;
    switch (this.props.context) {
      case ProfileDialogContext.ADD:
        if (this.props.attributes.singleApp) {
          // In the case of single app, prepend the fulfilled desktop item name to the profile name for user readability
          profile.name = `${this.state.selectedSingleAppProduct}: ${profile.name}`;
        }
        CommandService.addEdit(
          this.props.selectedOrg,
          profile,
          ObjectTypes.PRODUCT_PROFILE,
          OrgOperation.CREATE,
          undefined,
          'CREATE_PRDCT_PROFILE',
          [profile.name, this.getProductName(), CmdDescriptionUtils.getPathname(profile.orgId)]
        );
        Analytics.fireCTAEvent('add product profile dialog save clicked');
        break;
      case ProfileDialogContext.EDIT:
        // If the change is just for adding/deleting user group or admins and no real change in profile data
        // then do not add the "UPDATE" edit for profile.
        if (this.isProfileUpdate()) {
          CommandService.addEdit(
            this.props.selectedOrg,
            profile,
            ObjectTypes.PRODUCT_PROFILE,
            OrgOperation.UPDATE,
            _.cloneDeep(this.props.profile),
            'UPDATE_PRDT_PROFILE',
            [profile.name, this.getProductName(), CmdDescriptionUtils.getPathname(profile.orgId)]
          );
        }
        Analytics.fireCTAEvent('edit product profile dialog save clicked');
        break;
      default:
        log.error('wrong context for profile dialog!');
    }
    // add all the admin changes
    _.forEach(this.editedAdmins, (admin: UAdmin): void => {
      const originalAdmin: UAdmin | undefined = _.find(
        this.state.editedCompartment.getAdmins(),
        (a: UAdmin): boolean => a.id === admin.id
      );
      if (originalAdmin) {
        CommandService.addAdminEdit(
          this.props.selectedOrg,
          _.cloneDeep(admin),
          OrgOperation.UPDATE,
          _.cloneDeep(originalAdmin)
        );
      }
    });
    // add all the user group changes
    _.forEach(this.userGroupEdits, (userGroupEdit: CommandInterface): void => {
      const elem = userGroupEdit.elem as UUserGroup;
      CommandService.addEdit(
        this.props.selectedOrg,
        elem,
        userGroupEdit.elemType,
        userGroupEdit.operation,
        userGroupEdit.originalElem,
        'UPDATE_USER_GROUP',
        [elem.name, CmdDescriptionUtils.getPathname(elem.orgId)]
      );
    });
    this.props.update();
  };

  private onCancel = (): void => {
    switch (this.props.context) {
      case ProfileDialogContext.ADD:
        Analytics.fireCTAEvent('edit product profile dialog canceled');
        break;
      case ProfileDialogContext.EDIT:
        Analytics.fireCTAEvent('add product profile dialog canceled');
        break;
      default:
        log.error('wrong context for profile dialog!');
    }
  };

  /**
   * Returns the desktop fulfilled profile resources as a SelectOption[] list
   */
  fulfilledDesktopItemsAsSelectList(): SelectOption[] {
    const desktopItems = _.orderBy(this.state.profile.getDesktopResources(), ['enterpriseName'], ['asc']);

    return _.map(
      desktopItems,
      (res: UResource): SelectOption => ({ label: Utils.localizedResourceName(res.name()), value: res.code })
    );
  }

  /*
   * Updates the profile state with profile's modified 'notifications' value
   */
  onProfileNameChange = (newName: string): void => {
    const { profile } = this.state;
    profile.name = newName;
    this.updateProfile(profile);
  };

  /*
   * Updates the profile state with profile's modified 'enabled services' value
   */
  onServiceChange = (newVal: boolean, resource: UResource): void => {
    const { profile } = this.state;
    const profileResource = _.find(profile.resources, ['code', resource.code]);
    if (profileResource) {
      profileResource.selected = newVal;
      this.updateProfile(profile);
    }
  };

  /**
   * Updates the selected desktop resource to be true and sets the profile name prefix as the resource enterprise name
   * @param code - resource code which was selected
   */
  onDesktopItemSelected = (code: string | string[]): void => {
    const { profile } = this.state;
    _.forEach(profile.resources, (res: UResource): void => {
      if (res.fulfillableItemType === 'DESKTOP') {
        if (res.code === code) {
          res.selected = true;
          this.setState({
            selectedSingleAppProduct: Utils.localizedResourceName(res.name()),
            productItemErrorMessage: '',
          });
        } else {
          res.selected = false;
        }
      }
    });
    this.updateProfile(profile);
  };

  /*
   * Returns the profile names for all of the products in the compartment for input validation when editing
   * profile name (profile name should be unique accross the compartment and it is case insensitive)
   */
  getProfileNamesFromAllProducts = (): string[] => {
    let profileNames = _.flatMap(this.state.editedCompartment.products, (product: UProduct): string[] =>
      _.flatMap(product.productProfiles, 'name')
    );
    profileNames = _.map(profileNames, (profileName: string): string => _.toLower(profileName));
    // remove original profile name - original name is allowed unless empty
    _.pull(profileNames, _.toLower(this.props.profile.name));
    return profileNames;
  };

  /*
   * Returns the list of user groups in the edited product profile
   */
  getUserGroupsForProfile = (): UUserGroup[] => {
    return _.filter(
      this.state.editedCompartment.userGroups,
      (each: UUserGroup): boolean => !!_.find(each.profiles, ['id', this.state.profile.id])
    );
  };

  isQuotaInvalid = (): boolean => {
    // Check if quantity input error message is set when the resource list is populated
    return this.state.profile.resources.length > 0 ? this.state.quantityErrorMessage.length > 0 : false;
  };

  /*
  This method returns if the product is single app and the context is ADD.
   */
  isSingleAppAndAddContext = (): boolean => {
    return this.props.attributes.singleApp && _.isEqual(this.props.context, ProfileDialogContext.ADD);
  };

  profileExists = (): boolean => {
    const allProfileNames = this.getProfileNamesFromAllProducts();
    return _.includes(allProfileNames, _.toLower(this.state.profile.name));
  };

  getValidationState = (): 'invalid' | 'valid' | undefined => {
    if (this.state.isSubmitting) return undefined;
    return this.profileExists() || _.isEmpty(this.state.profile.name) ? 'invalid' : 'valid';
  };

  isSaveDisabled = (): boolean => {
    return (
      _.isEmpty(this.state.profile.name) ||
      (this.props.attributes.singleApp &&
        _.isEqual(this.props.context, ProfileDialogContext.ADD) &&
        this.state.productItemErrorMessage.length > 0) ||
      this.state.profile.name.length > MAX_NAME_ALLOWED_LENGTH ||
      this.profileExists() ||
      this.isQuotaInvalid() ||
      (_.isEqual(this.props.context, ProfileDialogContext.ADD) && this.state.errorMessage.length > 0)
    );
    // if errorMessage.length > 0 for 'add' context, then disable save
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const resources = this.state.profile.getEditableResources();
    return (
      <Dialog
        className="ProdProfileDialogContent__container"
        title={
          this.props.context === ProfileDialogContext.EDIT
            ? formatMessage(messages.EditProfile)
            : formatMessage(messages.AddProfile)
        }
        onConfirm={this.onSave}
        onCancel={this.onCancel}
        confirmLabel={formatMessage(messages.Save)}
        cancelLabel={formatMessage(messages.Cancel)}
        confirmDisabled={this.isSaveDisabled()}
        {...this.props}
        role="dialog"
        data-testid="edit-profile-dialog"
      >
        {this.state.profile.adminsLoaded && this.state.profile.userGroupLoaded ? (
          <div>
            {_.isEqual(this.props.context, ProfileDialogContext.ADD) &&
              !this.state.loadingDefaultProfileResources &&
              this.props.attributes.singleApp && (
                <FieldLabel position="left" label={formatMessage(messages.Product)} id="edit-profile-select-product">
                  <br />
                  <div>
                    <Select
                      aria-labelledby="edit-profile-select-product"
                      aria-describedby="editProdProfProduct_errorMessage"
                      className="ProdProfileDialogContent__SelectProduct"
                      options={this.fulfilledDesktopItemsAsSelectList()}
                      placeholder={formatMessage(messages.SelectProduct)}
                      onChange={(item: string | string[]): void => {
                        this.onDesktopItemSelected(item);
                      }}
                      autoFocus
                    />
                  </div>
                </FieldLabel>
              )}
            {!this.state.isSubmitting &&
              this.isSingleAppAndAddContext() &&
              this.state.productItemErrorMessage.length > 0 && (
                <span id="editProdProfProduct_errorMessage" className="ProdProfileDialogContent__ErrorMessage">
                  {this.state.productItemErrorMessage}
                </span>
              )}

            {_.isEqual(this.props.context, ProfileDialogContext.ADD) && this.state.errorMessage.length > 0 && (
              <div className="ProdProfileDialogContent__ErrorMessage ProdProfileDialogContent__ErrorMessageMargins">
                {this.state.errorMessage}
              </div>
            )}
            <FieldLabel
              position="left"
              label={formatMessage(messages.ProfileName)}
              id="edit-profile-name"
              labelFor="edit-profile-name-input"
            >
              <br />
              {this.isSingleAppAndAddContext() && (
                <OverlayTrigger placement="top">
                  <Textfield
                    placeholder={this.state.selectedSingleAppProduct}
                    disabled
                    className="ProdProfileDialogContent__NamePrefix"
                  />
                  <Tooltip className="ProdProfileDialogContent__NameTooltip">
                    {this.state.selectedSingleAppProduct}
                  </Tooltip>
                </OverlayTrigger>
              )}
              <Textfield
                id="edit-profile-name-input"
                className={
                  this.isSingleAppAndAddContext()
                    ? 'ProdProfileDialogContent__NameInputForSingleApp'
                    : 'ProdProfileDialogContent__NameInput'
                }
                aria-labelledby="edit-profile-name"
                validationState={this.getValidationState()}
                value={this.state.profile.name}
                onChange={(newName: string): void => this.onProfileNameChange(newName)}
                onBlur={(): void => this.setState({ isSubmitting: false })}
                onFocus={(): void => this.setState({ isSubmitting: true })}
                autoFocus={!this.props.attributes.singleApp}
                aria-describedby="editProdProfName_errorMessage"
              />
              <br />
              {this.isSingleAppAndAddContext() && (
                <span className="EditCompartment__font--light">
                  <FormattedMessage
                    id="EditCompartment.EditProfile.ProductSelection.ProfileName"
                    defaultMessage="The product profile name will include selected product name, e.g. Photoshop: profile"
                  />
                </span>
              )}
            </FieldLabel>
            {!this.state.isSubmitting && _.isEmpty(this.state.profile.name) && (
              <span id="editProdProfName_errorMessage" className="ProdProfileDialogContent__ErrorMessage">
                <FormattedMessage
                  id="EditCompartment.Products.Profiles.Edit.ProfileNameEmpty"
                  defaultMessage="Profile name can not be empty"
                />
              </span>
            )}
            {!this.state.isSubmitting && this.state.profile.name.length > MAX_NAME_ALLOWED_LENGTH && (
              <span id="editProdProfName_errorMessage" className="ProdProfileDialogContent__ErrorMessage">
                <FormattedMessage
                  id="EditCompartment.Products.Profiles.Edit.ProfileNameTooLong"
                  defaultMessage="Profile name should be less than 255 characters"
                />
              </span>
            )}
            {!this.state.isSubmitting && this.profileExists() && (
              <span id="editProdProfName_errorMessage" className="ProdProfileDialogContent__ErrorMessage">
                <FormattedMessage
                  id="EditCompartment.Products.Profiles.Edit.ProfileNameExists"
                  defaultMessage="Profile name should be unique across the organization"
                />
              </span>
            )}

            {this.state.loadingDefaultProfileResources && <Wait size="S" />}
            {!this.state.loadingDefaultProfileResources && (
              <div data-testid="resource-quantity-entries">
                {_.map(this.state.profile.resources, (resource: UResource): React.ReactNode => {
                  let elem;
                  let resLabel = '';
                  // Dont show license resource for Adobe Stock
                  if (!this.props.attributes.stockProduct && UResource.isSeatResource(resource.code)) {
                    if (this.state.profile.cap === CAP_UNLIMITED) {
                      elem = <Textfield disabled placeholder={Utils.localizedUnlimited()} />;
                    } else {
                      elem = (
                        <EditNumberInput
                          min={0}
                          max={EditProdProfileDialogContent.QUOTA_MAX}
                          defaultValue={Number(resource.cap)}
                          onChange={(newVal: string | null): void => this.onQuantityChange(resource, newVal)}
                          data-testid={`${resource.code}-edit-profile-quota-numberInput`}
                          placeholder={formatMessage(messages.EnterNumber)}
                        />
                      );
                    }
                    resLabel = formatMessage(messages.Quota);
                  } else if (this.props.attributes.stockProduct && resource.isAStockFI()) {
                    // non-stock products can have stock FIs, we have to check if it is a stock product
                    elem = (
                      <EditNumberInput
                        min={0}
                        max={EditProdProfileDialogContent.QUOTA_MAX}
                        defaultValue={Number(resource.cap)}
                        onChange={(newVal: string | null): void => this.onQuantityChange(resource, newVal)}
                        data-testid={`${resource.code}-edit-profile-credit-numberInput`}
                        placeholder={formatMessage(messages.EnterNumber)}
                      />
                    );
                    resLabel = Utils.localizedResourceName(resource.name());
                  }
                  if (elem) {
                    return (
                      <FieldLabel
                        key={resource.code}
                        className="ProdProfileDialogContent__QuantityField"
                        position="left"
                        label={resLabel}
                        id="edit-profile-quota"
                        labelFor="edit-profile-quota-input"
                      >
                        <br />
                        <span
                          id="edit-profile-quota-input"
                          aria-labelledby="edit-profile-quota"
                          aria-describedby="editProdProfQuota_errorMessage"
                        >
                          {elem}
                        </span>
                      </FieldLabel>
                    );
                  }
                })}
              </div>
            )}

            <div id="editProdProfQuota_errorMessage" className="ProdProfileDialogContent__ErrorMessage">
              {/* Display resource error only when resources have been loaded */}
              {!this.state.isSubmitting && this.state.profile.resources.length > 0 && (
                <span>{this.state.quantityErrorMessage}</span>
              )}
            </div>
            <div>
              <FieldLabel
                position="left"
                label={formatMessage(messages.Notifications)}
                id="edit-profile-dialog-notification-switch"
              >
                <Switch
                  defaultChecked={this.state.profile.notifications}
                  onChange={(notification: boolean): void => this.onNotificationChange(notification)}
                  data-testid="edit-profile-notifications"
                  aria-labelledby="edit-profile-dialog-notification-switch"
                />
              </FieldLabel>
            </div>
            <section data-testid="edit-profile-userGroup-section">
              <Heading variant="subtitle1" id="profile-dialog-user-groups">
                <FormattedMessage id="EditCompartment.Products.Profiles.Edit.UserGroups" defaultMessage="User Groups" />
              </Heading>
              {_.isEmpty(this.state.userGroupErrorMessage) ? (
                <UserGroupSection
                  compartment={this.state.editedCompartment}
                  userGroups={this.getUserGroupsForProfile()}
                  onDeleteUserGroup={this.onDeleteUserGroup}
                  onAddUserGroup={this.onAddUserGroup}
                  labelledby="profile-dialog-user-groups"
                />
              ) : (
                <Alert variant="error">{this.state.userGroupErrorMessage}</Alert>
              )}
            </section>
            <section data-testid="edit-profile-admin-section">
              <Heading variant="subtitle1">
                <FormattedMessage id="EditCompartment.Products.Profiles.Edit.Admins" defaultMessage="Admins" />
              </Heading>
              {_.isEmpty(this.state.adminErrorMessage) ? (
                <AdminSection
                  selectedOrg={this.state.editedCompartment}
                  admins={this.state.profileAdmins}
                  onDeleteAdmin={this.onDeleteAdmin}
                  onAddAdmin={this.onAddAdmin}
                  labelledby="profile-dialog-admins"
                />
              ) : (
                <Alert variant="error">{this.state.adminErrorMessage}</Alert>
              )}
            </section>
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage
                  id="EditCompartment.Products.Profiles.Edit.EnabledServices"
                  defaultMessage="Enabled Services"
                />
              </Heading>
            </section>
            <EnabledServices
              onServiceChange={this.onServiceChange}
              resources={resources}
              filterResourcesByProperty="delegationConfigurable" // TODO: define "delegationConfigurable" as a reusable constant
              readOnly={false}
            />
          </div>
        ) : (
          <Wait className="Load_wait" />
        )}
      </Dialog>
    );
  }
}
export default injectIntl(EditProdProfileDialogContent);
