import React from 'react';

import { Grid, GridRow, GridColumn } from '@react/react-spectrum/Grid';
import { Table, THead, TH, TBody, TR, TD } from '@react/react-spectrum/Table';
import { SelectOption } from '@react/react-spectrum/Select';
import AlertBanner from '../../../components/AlertBanner/AlertBanner';

import './OrgActionSummary.css';

interface OrgActionSummaryProps {
  selectedOrgIds: SelectOption[];
  selectedParentOrgId?: SelectOption[];
  label: string;
  errorMessage: React.ReactNode;
  warningMessage: React.ReactNode;
}

class OrgActionSummary extends React.Component<OrgActionSummaryProps> {
  render(): React.ReactNode {
    return (
      <div>
        <Grid>
          {this.props.errorMessage !== undefined && (
            <GridRow>
              <AlertBanner variant="error" header="Org Mapping has errors">
                {this.props.errorMessage}
              </AlertBanner>
            </GridRow>
          )}
          {this.props.warningMessage !== undefined && (
            <GridRow>
              <AlertBanner variant="warning" header="Org Mapping has warnings">
                {this.props.warningMessage}
              </AlertBanner>
            </GridRow>
          )}
          <GridRow>
            <GridColumn className="GridLayout" size={12}>
              <GridRow
                className="GridLayout"
                align={{
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                }}
              >
                <Table>
                  <THead>
                    <TH>Selected orgs</TH>
                    <TH>Selected org ID&apos;s</TH>
                    <TH>Action</TH>
                  </THead>
                  <TBody>
                    {this.props.selectedOrgIds.map((org: SelectOption): React.ReactNode => {
                      return (
                        <TR key={org.value}>
                          <TD>{org.label}</TD>
                          <TD>{org.value}</TD>
                          {this.props.selectedParentOrgId === undefined ? (
                            <TD>Make standalone</TD>
                          ) : (
                            <TD>{`New parent corp: ${this.props.selectedParentOrgId[0].label}`}</TD>
                          )}
                        </TR>
                      );
                    })}
                  </TBody>
                </Table>
              </GridRow>
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
    );
  }
}

export default OrgActionSummary;
