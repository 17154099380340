import React, { useEffect, useState } from 'react';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import { INSIGHTS_ITEM_DETAIL_TAB, useInsightsItem } from '@pandora/data-model-insights';
import {
  InsightsItemDetailListTableProvider,
  InsightsItemDetailListTableSection,
  InsightsProvider,
} from '@pandora/react-insights';
import { FeatureFlagProvider } from '@pandora/react-feature-provider';
import { ApiContextInterface, ApiContextProvider } from '@pandora/react-api-context-provider';
import config from '../../../configurations/config';
import FullErrorPage from '../../../components/FullErrorPage/FullErrorPage';
import { defineMessages, useIntl } from 'react-intl';
import TreeNode from 'primereact/components/treenode/TreeNode';
import * as _ from 'lodash';
import { UOrgData } from '../../../services/orgMaster/UOrg';
import BanyanCompartmentAPI from '../../../providers/BanyanCompartmentAPI';
import { Divider, ProgressCircle } from '@adobe/react-spectrum';
import Heading from '@react/react-spectrum/Heading';
import log from 'loglevel';
import { useAuditLogsContent } from './AuditLogsContent';
import PandoraPaginationContentProvider from '../../../providers/PandoraPaginationContentProvider';
import FloodgateService from '../../../services/floodgate/FloodgateService';

function AuditLogs(): JSX.Element {
  const messages = defineMessages({
    AuditLogsErrorMessage: {
      id: 'InsightsForGA.AuditLogs.ErrorMessage',
      defaultMessage: 'Unable to fetch data',
    },
    AuditLogsTitle: {
      id: 'InsightsForGA.AuditLogs.AuditLogsTitle',
      defaultMessage: 'Audit Logs',
    },
  });

  const { formatMessage } = useIntl();
  const rootOrgId = OrgPickerController.getActiveOrgId() ?? '';
  const [orgHierarchyData, setOrgHierarchyData] = useState<UOrgData[]>([]);
  const [isLoadingHierarchy, setIsLoadingHierarchy] = useState<boolean>(true);

  /* Api context data for the api context provider pandora package
   refer - https://git.corp.adobe.com/PandoraUI/administration/tree/master/packages/react-api-context-provider
   */
  const apiContextData: ApiContextInterface = {
    clientId: config.reportBroker.apiKey,
    orgId: rootOrgId,
  };

  /* Hook to fetch the insights item from report broker. The response will be used in the props sent to the Insights component
  refer - https://git.corp.adobe.com/PandoraUI/administration-core/tree/master/packages/data-model-insights#useinsightsitem-hook
  we retrieve this metadata file - https://git.corp.adobe.com/admin-tribe/report-broker/blob/master/src/main/resources/reports/metadata/auditLogsMultiOrgCol.json
  */
  const {
    data: itemList,
    loading,
    error,
  } = useInsightsItem({
    orgId: rootOrgId,
    types: ['auditLogsMultiOrgCol'],
    apiKey: config.reportBroker.apiKey,
  });

  // fetch the content model to be passed to Insights component
  const auditLogsContent = useAuditLogsContent();
  /**
   * convert the org hierarchy data to the TreeNode structure of Primereact. This will be passed as a prop into the Insights component
   * for the org filter content.
   */
  const convertOrgHierarchyToTreeNode = (orgId: string): TreeNode | undefined => {
    const orgData = _.find(orgHierarchyData, (org: UOrgData): boolean => org.id === orgId);
    if (orgData) {
      const childrenList = _.filter(orgHierarchyData, (org: UOrgData): boolean => org.parentOrgId === orgId);
      // sort child org list in ascending order by name
      const sortedChildList = _.orderBy(childrenList, [(child: UOrgData) => _.toLower(child.name)]);
      const childTreeNodes: TreeNode[] = [];
      _.forEach(sortedChildList, (org: UOrgData): void => {
        const childTreeNode = convertOrgHierarchyToTreeNode(org.id as string);
        if (childTreeNode) {
          childTreeNodes.push(childTreeNode);
        }
      });
      return {
        key: orgId,
        data: {
          name: orgData.name ?? '',
          id: orgId,
        },
        children: childTreeNodes,
      };
    }
    return undefined;
  };

  /**
   *  equivalent of componentDidMount() and componentDidUpdate() -
   * We fetch the org hierarchy data here. The user can navigate to the Insights tab in two ways:
   *  - directly loads it - org hierarchy data should be fetched by making the api call to banyansvc
   *  - comes here after loading the organizations or product allocations tab - the data is already present
   *  We are calling the api for both cases for now.
   *  TODO - retrieve the data from the org map maintained instead of calling api for the second case as an improvement
   *
   */
  useEffect(() => {
    (async (): Promise<void> => {
      setIsLoadingHierarchy(true);
      setOrgHierarchyData(await BanyanCompartmentAPI.getHierarchy(rootOrgId));
      setIsLoadingHierarchy(false);
    })();
  }, [rootOrgId]);

  const nodeToRender = (): React.ReactElement => {
    if (loading || isLoadingHierarchy) {
      return (
        <>
          <div className="Insights__headerDiv">
            <Heading className="App__header" data-testid="audit-logs-heading">
              {formatMessage(messages.AuditLogsTitle)}
            </Heading>
            <Divider size="S" />
          </div>
          <div className="Insights_body">
            <ProgressCircle size="M" isIndeterminate top="50%" left="50%" aria-label="Loading" />
          </div>
        </>
      );
    }
    if (itemList && !isLoadingHierarchy) {
      return (
        <>
          <div className="Insights__headerDiv">
            <Heading className="App__header" data-testid="audit-logs-heading">
              {formatMessage(messages.AuditLogsTitle)}
            </Heading>
            <Divider size="S" />
          </div>
          <div className="Insights_body">
            <PandoraPaginationContentProvider>
              <FeatureFlagProvider value={FloodgateService.features}>
                <ApiContextProvider value={apiContextData}>
                  <InsightsProvider content={auditLogsContent}>
                    <InsightsItemDetailListTableProvider
                      tabName={INSIGHTS_ITEM_DETAIL_TAB.VIEW}
                      insightsItem={itemList?.items[0]}
                      orgHierarchy={convertOrgHierarchyToTreeNode(rootOrgId)}
                    >
                      <InsightsItemDetailListTableSection />
                    </InsightsItemDetailListTableProvider>
                  </InsightsProvider>
                </ApiContextProvider>
              </FeatureFlagProvider>
            </PandoraPaginationContentProvider>
          </div>
        </>
      );
    }
    log.error(`Insights item hook failed to fetch report broker data with this error - ${error}`);
    return <FullErrorPage errorMessage={formatMessage(messages.AuditLogsErrorMessage)} />;
  };

  return nodeToRender();
}

export default AuditLogs;
