import { createContext, useContext, useMemo } from 'react';
import { RevokeContextProviderProps } from '../types';

const RevokeContext = createContext<RevokeContextProviderProps>({
  selectedOrgUserGroups: [],
  revokeType: undefined,
  persistSelectedOrgUserGroups: () => {},
  persistRevokeType: () => {},
});
const useRevokeContext = () => useContext<RevokeContextProviderProps>(RevokeContext);

const RevokeContextProvider = ({
  children,
  selectedOrgUserGroups,
  revokeType,
  persistSelectedOrgUserGroups,
  persistRevokeType,
}: RevokeContextProviderProps) => {
  const value = useMemo(
    () => ({
      selectedOrgUserGroups,
      revokeType,
      persistSelectedOrgUserGroups,
      persistRevokeType,
    }),
    [selectedOrgUserGroups, revokeType]
  );
  return <RevokeContext.Provider value={value}>{children}</RevokeContext.Provider>;
};

export { RevokeContextProvider, useRevokeContext };
