import * as _ from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import LocaleSelectOption from '../../../services/locale/LocaleSelectOption';

/**
 * Defines the data columns for the ProductAllocation table
 */

// Localization for Product Allocation data columns
const localeMessages = defineMessages({
  grantColumn: {
    id: 'productAllocation.table.headers.grant',
    defaultMessage: 'GRANT',
  },
  totalAllocColumn: {
    id: 'productAllocation.table.headers.totalAlloc',
    defaultMessage: 'TOTAL ALLOCATIONS',
  },
  localLicensedQuantityColumn: {
    id: 'productAllocation.table.headers.localLicensedQuantity',
    defaultMessage: 'LOCAL LICENSED QUANTITY',
  },
  localUseColumn: {
    id: 'productAllocation.table.headers.localUse',
    defaultMessage: 'LOCAL USAGE',
  },
  totalUseColumn: {
    id: 'productAllocation.table.headers.totalUse',
    defaultMessage: 'TOTAL USAGE',
  },
  allowOverAllocColumn: {
    id: 'productAllocation.table.headers.allowOverAlloc',
    defaultMessage: 'OVER ALLOCATION',
  },
  allowOverUseColumn: {
    id: 'productAllocation.table.headers.allowOverUse',
    defaultMessage: 'OVER USE',
  },
});

/**
 * Enumerates value representation and data fields for the Tree Table columns
 */
export enum ColumnField {
  GRANT = 'grant',
  TOTAL_ALLOC = 'totalAlloc',
  LOCAL_LICENSED_QUANTITY = 'localLicensedQuantity',
  LOCAL_USE = 'localUse',
  TOTAL_USE = 'totalUse',
  ALLOW_OVER_ALLOC = 'allowOverAlloc',
  ALLOW_OVER_USE = 'allowOverUse',
}

/**
 * Mapping of value/id for a Column and the Column's display name
 * SelectOption type is used here because it is compatible with a selector
 * should one be needed and it fulfills the purpose of mapping
 */
const columnsData: ReadonlyArray<Readonly<LocaleSelectOption>> = [
  {
    messageDescriptor: localeMessages.grantColumn,
    value: ColumnField.GRANT,
  },
  {
    messageDescriptor: localeMessages.totalAllocColumn,
    value: ColumnField.TOTAL_ALLOC,
  },
  {
    messageDescriptor: localeMessages.localLicensedQuantityColumn,
    value: ColumnField.LOCAL_LICENSED_QUANTITY,
  },
  {
    messageDescriptor: localeMessages.localUseColumn,
    value: ColumnField.LOCAL_USE,
  },
  {
    messageDescriptor: localeMessages.totalUseColumn,
    value: ColumnField.TOTAL_USE,
  },
  {
    messageDescriptor: localeMessages.allowOverAllocColumn,
    value: ColumnField.ALLOW_OVER_ALLOC,
  },
  {
    messageDescriptor: localeMessages.allowOverUseColumn,
    value: ColumnField.ALLOW_OVER_USE,
  },
];

export class ColumnUtils {
  /**
   * Retrieves the column localization message descriptor given the columns value.
   * Allows the column names to be shown for a given list of visible columns.
   * If there is no localization matching the given value, an error is thrown.
   */
  static columnLocaleFromValue(value: string): MessageDescriptor {
    const option: LocaleSelectOption | undefined = _.find(
      columnsData,
      (columnData: LocaleSelectOption): boolean => columnData.value === value
    );
    if (option) {
      return option.messageDescriptor;
    }
    throw new Error('Unknown column for ProductAllocation');
  }

  /**
   * Retrieves list of all column values (values from the column selector).
   */
  static allColumns(): string[] {
    const columnValues: string[] = [];
    _.forEach(columnsData, (columnData: LocaleSelectOption): void => {
      columnValues.push(columnData.value);
    });
    return columnValues;
  }
}
