import * as _ from 'lodash';
import { UserRole, AgentAdminType, OrgAdminType, UserType } from './IMS';
import OrgPickerController from '../organization/OrgPickerController';

import UserProfileService from './UserProfileService';
import BanyanCompartmentAPI, { OrgToAdminAccessMap } from '../../providers/BanyanCompartmentAPI';

/**
 * Service which can be used to determine whether the user has certain Admin privileges.
 *  - Global Admin privilege allows the user to view the app at all
 *  - Adobe Agent admin privilege allows the user to view the top level org page and do certain edits
 */
class AdminPermission {
  /**
   * For adobe agents, authenticating account (T3) may have multiple T2E accounts, linked to it.
   * Only a T3 profile (authenticating account) can have agent permissions. T2E profile would never have such permissions.
   * Org-picker could have multiple T2E accounts linked to an authenticating account (T1/T2/T3).
   * `switchProfile` would be called each time a new org is selected in org-picker.
   * To avoid checking agent permissions in linked authenticating account (T1/T2/T3) for every T2E profile (via banyansvc's /check-admin-access),
   * following maps would save agent permisssions against corresponding authenticaing account (T1/T2/T3).
   * Two maps with key and value are as follows:
   * authIdToIsAgentMap:
   *    key: authenticating user id (i.e. user id associated with T1/T2/T3 user profile)
   *    value: true if authenticating user id has agent permissions as required by isAdobeAgent()
   * authIdToIsRWAgentMap:
   *    key: authenticating user id (i.e. user id associated with T1/T2/T3 user profile)
   *    value: true if authenticating user id has agent permissions as required by isRWAdobeAgent()
   */
  static authIdToIsAgentMap: Map<string, boolean> | undefined = undefined;
  static authIdToIsRWAgentMap: Map<string, boolean> | undefined = undefined;

  /**
   * Checks whether the user is an Adobe Agent with write permissions.
   * If user profile is non-T2E, checks for agent roles only in current user profile
   * If user profile is T2E, checks for agent roles in linked T3 account (authenticating account)
   *
   * Note: Assumed that Agent roles can only exist on a T3 account.
   */
  static async isRWAdobeAgent(): Promise<boolean> {
    const currentProfile = UserProfileService.getUserProfile();
    const agentRoles = [
      AgentAdminType.ADOBE_AGENT_ADMIN,
      AgentAdminType.ADOBE_AGENT_PROVISIONER,
      AgentAdminType.ADOBE_AGENT_PROFESSIONAL_SERVICES,
    ];
    if (!_.isEqual(currentProfile?.account_type.toUpperCase(), UserType.TYPE_TWOE)) {
      return !!_.find(UserProfileService.getUserRolesAcrossHierarchy(), (role: UserRole): boolean =>
        _.includes(agentRoles, role.named_role.toUpperCase())
      ); // These checks are solely based on current user profile
    }

    // If account_type of currentProfile is T2E, and if agent roles were already checked for linked T3 acocunt, use that result
    let isRWAgentOnAuthAccount = AdminPermission.authIdToIsRWAgentMap?.get(currentProfile?.authId || '');
    if (isRWAgentOnAuthAccount !== undefined) {
      return isRWAgentOnAuthAccount;
    }

    // Check for agent roles on T3 account (authenticating account) linked to current T2E user profile
    isRWAgentOnAuthAccount = await BanyanCompartmentAPI.checkAgentAccess(agentRoles);
    if (currentProfile?.authId) {
      // Save agent permission for authenticating account to avoid making same call for linked T2E accounts
      AdminPermission.authIdToIsRWAgentMap = new Map();
      AdminPermission.authIdToIsRWAgentMap.set(currentProfile?.authId, isRWAgentOnAuthAccount);
    }
    return isRWAgentOnAuthAccount;
  }

  static async isAdobeAgentWithNoOrgs(): Promise<boolean> {
    const isAdobeAgent = await AdminPermission.isAdobeAgent();
    return isAdobeAgent && OrgPickerController.getOrgDataList().length === 0;
  }

  /**
   * Checks whether the user is an Adobe Agent (with either read or write permissions)
   * If user profile is non-T2E, checks for agent roles only in current user profile
   * If user profile is T2E, checks for agent roles in linked T3 account (authenticating account)
   *
   * Note: Assumed that Agent roles can only exist on a T3 account.
   */
  static async isAdobeAgent(): Promise<boolean> {
    const currentProfile = UserProfileService.getUserProfile();
    const agentRoles = [
      AgentAdminType.ADOBE_AGENT_ADMIN,
      AgentAdminType.ADOBE_AGENT_PROVISIONER,
      AgentAdminType.ADOBE_AGENT_PROFESSIONAL_SERVICES,
      AgentAdminType.ADOBE_AGENT_CUSTOMER_CARE,
      AgentAdminType.ADOBE_AGENT_READ,
      AgentAdminType.ADOBE_AGENT_RESELLER_LICENSING,
    ];
    if (!_.isEqual(currentProfile?.account_type.toUpperCase(), UserType.TYPE_TWOE)) {
      return !!_.find(UserProfileService.getUserRolesAcrossHierarchy(), (role: UserRole): boolean =>
        _.includes(agentRoles, role.named_role.toUpperCase())
      ); // These checks are solely based on current user profile
    }

    // If account_type of currentProfile is T2E, and if agent roles were already checked for linked T3 acocunt, use that result
    let isAgentOnAuthAccount = AdminPermission.authIdToIsAgentMap?.get(currentProfile?.authId || '');
    if (isAgentOnAuthAccount !== undefined) {
      return isAgentOnAuthAccount;
    }

    // Check for agent roles on T3 account (authenticating account) linked to current T2E user profile
    isAgentOnAuthAccount = await BanyanCompartmentAPI.checkAgentAccess(agentRoles);
    if (currentProfile?.authId) {
      // Save agent permission for authenticating account to avoid making same call for linked T2E accounts
      AdminPermission.authIdToIsAgentMap = new Map();
      AdminPermission.authIdToIsAgentMap.set(currentProfile?.authId, isAgentOnAuthAccount);
    }
    return isAgentOnAuthAccount;
  }

  /**
   * Checks whether the user is a explicitly a Global Admin for the org selected by orgId.  (Does not consider inherited Global Admin)
   * TODO: in late April global admin will be inferred for child orgs, not explicitly in the IMS profile. We'll need a new API.
   */
  static isRWGlobalAdminForOrgExplicitly(orgId: string): boolean {
    return !!_.find(
      UserProfileService.getUserRoles(orgId),
      (role: UserRole): boolean => role.named_role.toUpperCase() === OrgAdminType.COMPARTMENT_ADMIN
    );
  }

  /**
   * Checks whether the user is an explicit Global Admin on the current root org
   * Relies upon the org picker only being populated with orgs that the user is explicitly granted Global Admin or Global Viewer roles on
   */
  static isRWGlobalAdminOfActiveOrg(): boolean {
    const orgId: string | undefined = OrgPickerController.getActiveOrgId();
    if (_.isNil(orgId)) {
      return false;
    }
    return AdminPermission.isRWGlobalAdminForOrgExplicitly(orgId);
  }

  /**
   * Checks whether the user is an explicit Global Admin in any org.  (Does not consider inherited Global Admin)
   */
  static isRWGlobalAdminAcrossHierarchyExplicitly(): boolean {
    return !!_.find(
      UserProfileService.getUserRolesAcrossHierarchy(),
      (role: UserRole): boolean => role.named_role.toUpperCase() === OrgAdminType.COMPARTMENT_ADMIN
    );
  }

  /**
   * Checks whether the user is an explicit Global Admin Read-Only for the org selected by orgId.  (Does not consider inherited Global Admin Read-Only)
   */
  static isROGlobalAdminForOrgExplicitly(orgId: string): boolean {
    return !!_.find(
      UserProfileService.getUserRoles(orgId),
      (role: UserRole): boolean => role.named_role.toUpperCase() === OrgAdminType.COMPARTMENT_VIEWER
    );
  }

  /**
   * Checks whether the user is an explicit Global Admin Read Only on the current root org
   * Relies upon the org picker only being populated with orgs that the user is explicitly granted Global Admin or Global Viewer roles on
   */
  static isROGlobalAdminOfActiveOrg(): boolean {
    const orgId: string | undefined = OrgPickerController.getActiveOrgId();
    if (_.isNil(orgId)) {
      return false;
    }
    return AdminPermission.isROGlobalAdminForOrgExplicitly(orgId);
  }

  /**
   * Checks whether the user is an explicit Global Admin Read-Only in any org.  (Does not consider inherited Global Admin Read-Only)
   */
  static isROGlobalAdminAcrossHierarchyExplicitly(): boolean {
    return !!_.find(
      UserProfileService.getUserRolesAcrossHierarchy(),
      (role: UserRole): boolean => role.named_role.toUpperCase() === OrgAdminType.COMPARTMENT_VIEWER
    );
  }

  /**
   * Checks whether the user is an explicit Global Admin or Global Admin Read-Only in any org.  (Does not consider inherited Global Admin or Global Admin Read-Only)
   */
  static isROorRWGlobalAdminForOrgExplicitly(orgId: string): boolean {
    return (
      AdminPermission.isRWGlobalAdminForOrgExplicitly(orgId) || AdminPermission.isROGlobalAdminForOrgExplicitly(orgId)
    );
  }

  /**
   * Checks whether the user is an explicit Global Admin or Global Admin Read-Only on the current root org
   * Relies upon the org picker only being populated with orgs that the user is explicitly granted Global Admin or Global Viewer roles on
   */
  static isROorRWGlobalAdminOfActiveOrg(): boolean {
    const orgId: string | undefined = OrgPickerController.getActiveOrgId();
    if (_.isNil(orgId)) {
      return false;
    }
    return AdminPermission.isROorRWGlobalAdminForOrgExplicitly(orgId);
  }

  /**
   * Checks whether the user is an explicit or implicit (inherited) Global Admin of multiple orgs
   * (This method is asynchronous and must cal the back-end)
   */
  static async isRWGlobalAdminForOrgs(orgIds: string[]): Promise<OrgToAdminAccessMap> {
    return BanyanCompartmentAPI.getOrgToAdminAccessMap(
      [OrgAdminType.COMPARTMENT_VIEWER, OrgAdminType.COMPARTMENT_ADMIN],
      orgIds,
      false
    );
  }

  /**
   * Checks if the user has only read permission for the current root org (i.e, edit operations cannot be performed).
   * (This method only checks explicit global admin permissions on the current root org)
   * Relies upon the org picker only being populated with orgs that the user is explicitly granted Global Admin or Global Viewer roles on
   */
  static readOnlyMode(): boolean {
    const orgId: string | null | undefined = OrgPickerController.getActiveOrgId();
    if (_.isNil(orgId)) {
      throw Error('No root org selected');
    }
    // No need to check for GlobalAdminReadOnly as any other admin besides Global Admin should be in read only mode anyways
    // AdobeAgents are read only unless they have Global Admin Role
    return !AdminPermission.isRWGlobalAdminForOrgExplicitly(orgId);
  }

  /**
   * Checks if the user can access Global Admin Console
   * (They must be an AdobeAgent or a Global Admin/Global Admin Read-Only for at least 1 org)
   *
   * IMPORTANT: Callers of this method must ensure that OrgPickerController.load() has already been invoked before calling this method.
   * Why? Because this method relies on OrgPickerController.getOrgDataList()
   */
  static async canAccess(): Promise<boolean> {
    const isAdobeAgent = await AdminPermission.isAdobeAgent();
    if (
      isAdobeAgent ||
      AdminPermission.isRWGlobalAdminAcrossHierarchyExplicitly() ||
      AdminPermission.isROGlobalAdminAcrossHierarchyExplicitly()
    ) {
      // Above checks are solely based on current user profile
      return true;
    }
    // If current user profile doesn't have required admin permissions,
    // use OrgPickerController.getOrgDataList() to check if any org exists with those permissions across linked user accounts
    // NOTE: Callers of this method must ensure that OrgPickerController.load() has already been invoked before calling this method.
    const orgsToAccess = OrgPickerController.getOrgDataList(); // List of orgs where current user has either GA or GV role. This eliminates the need to call "GET /check-admin-access" API, which does the same processing as "GET /manageable" API (called by OrgPickerController.ts).
    return !_.isEmpty(orgsToAccess);
  }

  /**
   * Reports whether the uesr has at least direct (explicit) read permission for the root org (AdobeAgent or has Global Admin or Global Admin read-only roles for the root org)
   * Relies upon the org picker only being populated with orgs that the user is explicitly granted Global Admin or Global Viewer roles on
   */
  static async hasReadPrivilege(): Promise<boolean> {
    const orgId: string | null | undefined = OrgPickerController.getActiveOrgId();
    if (_.isNil(orgId)) {
      throw Error('No root org selected');
    }
    return await AdminPermission.hasReadPrivilegeForOrg(orgId);
  }

  /**
   * Reports whether the user has at least read permission for an org (AdobeAgent or has Global Admin or Global Admin read-only roles for that org).
   * Does not check whether the user may have inherited read permissions from having read permissions on a parent org.
   */
  static async hasReadPrivilegeForOrg(orgId: string): Promise<boolean> {
    return (
      AdminPermission.isROGlobalAdminForOrgExplicitly(orgId) ||
      AdminPermission.isRWGlobalAdminForOrgExplicitly(orgId) ||
      (await AdminPermission.isAdobeAgent())
    );
  }

  /**
   * Reports whether the user has Adobe Agent permissions.
   */
  static async hasAdobeAgentPrivilege(): Promise<boolean> {
    return await AdminPermission.isAdobeAgent();
  }

  /**
   * Checks whether the user is System Admin of a given org
   */
  static async isSystemAdminOfOrg(orgId: string): Promise<boolean> {
    let isCurrentProfileASystemAdmin: boolean = !!_.find(
      UserProfileService.getUserRoles(orgId),
      (role: UserRole): boolean => role.named_role.toUpperCase() === OrgAdminType.ORG_ADMIN
    );
    if (!isCurrentProfileASystemAdmin) {
      // Check if any of the linked user profile (due to user having a combination of T2E/T1/T2/T3 accounts) has system admin role on orgId.
      // e.g. When an agent adds themselves as System admin to a T2E enabled org via AAUI, they get added as BusinessID (i.e. T2E profile)
      // When the same agent, tries to use GAC (e.g. Bring standalone org to hierarchy), they might be logged in with T3 profile, causing above check to return false.
      // Hence, following additional check tries to check for system admin roles across linked user profiles.
      // Note: Following call is slow.
      return BanyanCompartmentAPI.isCurrentUserSystemAdminOf(orgId);
    }
    return isCurrentProfileASystemAdmin;
  }
}
export default AdminPermission;
