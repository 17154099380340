import * as _ from 'lodash';
import { SelectOption } from '@react/react-spectrum/Select';
import React from 'react';
import { defineMessages, IntlProvider, WrappedComponentProps } from 'react-intl';
import ModalContainer from '@react/react-spectrum/ModalContainer';

import { LocaleSettings } from '../../services/locale/LocaleSettings';
import OrgActionSummary from './OrgActionSummary/OrgActionSummary';
import SearchOrg from './SearchOrg/SearchOrg';
import StepControl from './StepControl/StepControl';
import AdobeAgentPaths from './AdobeAgentPaths';
import OrgPickerController from '../../services/organization/OrgPickerController';
import HeaderConsts from '../../components/BanyanShell/HeaderConstants';
import OrgHierarchyHelper from '../helpers/OrgHierarchyHelper';
import DeleteProductWarningDialog from './DeleteProductWarningDialog/DeleteProductWarningDialog';
import { MESSAGES } from '../Messages';
import withRouter, { RouteComponentProps } from '../../services/utils/withRouter';
import SearchType from './SearchOrg/SearchType';

interface MoveStandAloneOrgIntoAHierarchyInternalProps extends RouteComponentProps, WrappedComponentProps {
  setPath: () => void;
}

interface MoveStandAloneOrgIntoAHierarchyInternalState {
  selectedOrgs: SelectOption[];
  selectedParentOrgs: SelectOption[];
  disallowOrgSelection: boolean;
  errorMessage: React.ReactNode;
  warningMessage: React.ReactNode;
  hasDeleteWarning: boolean;
  searchedChildOrgsByECCIDFilter: boolean; // user used 'ECC ID' filter to search for child orgs
  searchedChildOrgsByNonECCIDFilter: boolean; // user used NON 'ECC ID' filter to search for child orgs
}

const messages = defineMessages({
  ParentOrgCanNotBeSelectedError: {
    id: 'MoveStandAloneOrgIntoAHierarchy.ParentOrgCanNotBeSelectedError',
    defaultMessage: 'Parent org cannot be the same as the child org.',
  },
  StandAloneToParent: {
    id: 'MoveStandAloneOrgIntoAHierarchy.StandAloneToParent',
    defaultMessage: 'Select standalone orgs to move under a parent',
  },
  SelectParentOrg: {
    id: 'MoveStandAloneOrgIntoAHierarchy.SelectParentOrg',
    defaultMessage: 'Select parent org',
  },
  StandAloneOrgToParentOrg: {
    id: 'MoveStandAloneOrgIntoAHierarchy.Title',
    defaultMessage: 'Move standalone orgs into a hierarchy',
  },
});

class MoveStandAloneOrgIntoAHierarchyInternal extends React.Component<
  MoveStandAloneOrgIntoAHierarchyInternalProps,
  MoveStandAloneOrgIntoAHierarchyInternalState
> {
  constructor(props: MoveStandAloneOrgIntoAHierarchyInternalProps) {
    super(props);
    this.state = {
      selectedOrgs: [],
      selectedParentOrgs: [],
      disallowOrgSelection: false,
      errorMessage: undefined,
      warningMessage: undefined,
      hasDeleteWarning: false,
      searchedChildOrgsByECCIDFilter: false,
      searchedChildOrgsByNonECCIDFilter: false,
    };
  }

  /**
   * @returns if the parent Org is one of the selected Child Orgs
   */
  isParentOrgSelectedInChildOrgs = (): boolean => {
    if (this.state.selectedParentOrgs.length <= 0) {
      return false;
    }
    return (
      _.find(
        this.state.selectedOrgs,
        (childOrg): boolean => childOrg.value === this.state.selectedParentOrgs[0].value
      ) !== undefined
    );
  };

  enableNextOrReviewPendingBtn = (step: number): boolean => {
    // on step 0, if child org is not selected, disable the next button
    if (step === 0 && this.state.selectedOrgs.length <= 0) {
      return false;
    }

    // on step 1, if parent child org is not selected or if the parent is selected but is also selected as a child Org,
    // disable the next button
    if ((step === 1 && this.state.selectedParentOrgs.length <= 0) || this.isParentOrgSelectedInChildOrgs()) {
      return false;
    }

    // on last step, if there is an errorMessage, disable the 'Review Pending Changes' btn
    if (step === 2 && this.state.errorMessage !== undefined) {
      return false;
    }
    return true;
  };

  /**
   * Add the new Orgs to the list if not already added
   */
  addSelectedOrgIds = (newSelectedOrgs: SelectOption[]): void => {
    this.setState(
      (
        prevState: MoveStandAloneOrgIntoAHierarchyInternalState
      ): Pick<MoveStandAloneOrgIntoAHierarchyInternalState, never> => {
        return { selectedOrgs: OrgHierarchyHelper.addSelectedValues(prevState.selectedOrgs, newSelectedOrgs) };
      }
    );
  };

  /**
   * removes the orgs from the list of selected child Orgs
   */
  removeSelectedOrgIds = (toBeRemovedOrgs: string[]): void => {
    this.setState(
      (
        prevState: MoveStandAloneOrgIntoAHierarchyInternalState
      ): Pick<MoveStandAloneOrgIntoAHierarchyInternalState, never> => {
        return { selectedOrgs: OrgHierarchyHelper.removeSelectedValues(prevState.selectedOrgs, toBeRemovedOrgs) };
      }
    );
  };

  /**
   * removes the orgs from the list of selected child Orgs
   */
  removeSelectedParentOrgIds = (): void => {
    this.setState({ selectedParentOrgs: [] });
  };

  /**
   * Add the new Orgs to the list if not already added
   */
  addSelectedParentOrgIds = (newSelectedParentOrgs: SelectOption[]): void => {
    this.setState({ selectedParentOrgs: newSelectedParentOrgs });
  };

  /**
   * Validates if user permissions and org properties are valid (Please see: validateOrgAndUserPermissions for more info)
   * if a problem is detected, errorMessage will be set. That affects enableNextOrReviewPendingBtn (see above).
   */
  validate = async (): Promise<void> => {
    // set the disallowOrgSelection = true during which the user
    // cannot alter the org selected (child or parent org whichever applicable)
    this.setState({ disallowOrgSelection: true });

    const searchedByECCIDFilterOnly =
      this.state.searchedChildOrgsByECCIDFilter && !this.state.searchedChildOrgsByNonECCIDFilter;

    const errorElem = await OrgHierarchyHelper.validateOrgAndUserPermissions(
      this.state.selectedOrgs,
      this.state.selectedParentOrgs,
      searchedByECCIDFilterOnly
    );

    this.setState({ errorMessage: errorElem, disallowOrgSelection: false });
  };

  searchedByType = (searchType: string): void => {
    if (searchType === SearchType.ECCEndUserID) {
      this.setState({ searchedChildOrgsByECCIDFilter: true });
    } else {
      this.setState({ searchedChildOrgsByNonECCIDFilter: true });
    }
  };

  submit = (): void => {
    OrgHierarchyHelper.generateAndAddOrgUpdateCommand(
      AdobeAgentPaths.MoveStandaloneOrgsIntoAHeirarchy,
      this.state.selectedOrgs,
      this.state.selectedParentOrgs
    );
    this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
  };

  onDialogClose = (): void => {
    if (this.deleteWarningDialogRef) {
      ModalContainer.hide(this.deleteWarningDialogRef);
    }
  };

  deleteWarningDialogRef: number | undefined;
  verifyBeforeSubmit = (): void => {
    if (this.state.hasDeleteWarning) {
      this.deleteWarningDialogRef = ModalContainer.show(
        <DeleteProductWarningDialog onClose={this.onDialogClose} submit={this.submit} />,
        this
      );
    } else {
      this.submit();
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {this.isParentOrgSelectedInChildOrgs() && (
          <div className="OrgMigration__error">{formatMessage(messages.ParentOrgCanNotBeSelectedError)}</div>
        )}
        <StepControl
          pageTitle={formatMessage(messages.StandAloneOrgToParentOrg)}
          enableNextOrReviewPendingBtn={this.enableNextOrReviewPendingBtn}
          backout={this.props.setPath}
          onSubmit={this.verifyBeforeSubmit}
          validate={this.validate}
          disallowOrgSelection={this.state.disallowOrgSelection}
        >
          <SearchOrg
            selectedOrgs={this.state.selectedOrgs}
            addSelectedOrgIds={this.addSelectedOrgIds}
            removeSelectedOrgIds={this.removeSelectedOrgIds}
            multipleSelection
            label={formatMessage(messages.StandAloneToParent)}
            key="Child Component"
            disallowOrgSelection={this.state.disallowOrgSelection}
            displaySelectedOrgsAsTagList
            scrollableHeight="30rem"
            /* fn to get notified if the user searches for child orgs ONLY based on ECC ID */
            searchedByType={this.searchedByType}
          />
          <SearchOrg
            selectedOrgs={this.state.selectedParentOrgs}
            addSelectedOrgIds={this.addSelectedParentOrgIds}
            removeSelectedOrgIds={this.removeSelectedParentOrgIds}
            key="Parent Component"
            label={formatMessage(messages.SelectParentOrg)}
            disallowOrgSelection={this.state.disallowOrgSelection}
            displaySelectedOrgsAsTagList
            scrollableHeight="30rem"
          />
          <OrgActionSummary
            selectedOrgIds={this.state.selectedOrgs}
            selectedParentOrgId={this.state.selectedParentOrgs}
            label={formatMessage(MESSAGES.ReviewLabel)}
            errorMessage={this.state.errorMessage}
            warningMessage={this.state.warningMessage}
          />
        </StepControl>
      </div>
    );
  }
}

function MoveStandAloneOrgIntoAHierarchy(
  props: Omit<MoveStandAloneOrgIntoAHierarchyInternalProps, 'ref'>
): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <MoveStandAloneOrgIntoAHierarchyInternal {...props} />
    </IntlProvider>
  );
}

export default withRouter(MoveStandAloneOrgIntoAHierarchy);
