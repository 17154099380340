import React from 'react';
import * as _ from 'lodash';
import { Table, TBody, TD, TH, THead, TR } from '@react/react-spectrum/Table';
import Search from '@react/react-spectrum/Search';
import StatusLight from '@react/react-spectrum/StatusLight';
import Alert from '@react/react-spectrum/Alert';
import Wait from '@react/react-spectrum/Wait';
import Button from '@react/react-spectrum/Button';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import '../../common.css';
import { UDomain, DomainStatus } from '../../../services/orgMaster/UDomain';
import './DomainsTable.css';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoHelpBubble from '../../../components/HelpBubble/HelpBubble';
import GoToAdminConsoleDialog from '../../../components/GoToAdminConsoleDialog/GoToAdminConsoleDialog';
import config from '../../../configurations/config';
import Analytics from '../../../Analytics/Analytics';
import ScrollableContent from '../Widgets/ScrollableContent';
import { UDirectory } from '../../../services/orgMaster/UDirectory';

interface DomainTableProps extends WrappedComponentProps {
  selectedOrg: UOrgMaster;
}

interface DomainsTableStates {
  filteredDomains: UDomain[];
  searchTerm: string;
  errorMessage: string;
}

const messages = defineMessages({
  ViewInAdminConsole: {
    id: 'EditCompartment.Domains.ViewInAdminConsole',
    defaultMessage: 'View in Admin Console',
  },
  SearchPlaceholder: {
    id: 'EditCompartment.Domains.SearchPlaceholder',
    defaultMessage: 'Search',
  },
  PaneHelp: {
    id: 'Organizations.Domains.Helptext',
    defaultMessage:
      'View the domains to which the organization selected on the left has access.  More information about them is available in the Admin Console',
  },
  FailedToLoadDomains: {
    id: 'EditCompartment.Domains.FailedToLoadDomains',
    defaultMessage: 'Unable to load domains: {error}',
  },
});

class DomainsTable extends React.Component<DomainTableProps, DomainsTableStates> {
  abortController: AbortController;

  constructor(props: DomainTableProps) {
    super(props);
    this.state = {
      filteredDomains: this.props.selectedOrg.domains,
      searchTerm: '',
      errorMessage: '', // if not empty, show the alert box. The errorMessage is defined if the loading of domains fails on componentDidMount/componentDidUpdate
    };
    this.abortController = new AbortController();
  }

  private async loadDomains(): Promise<void> {
    try {
      if (!this.props.selectedOrg.domainsLoaded) {
        await LoadOrgDataService.loadDomains(this.props.selectedOrg.id, this.abortController.signal);
      }
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      this.setState((state) => {
        return {
          errorMessage: '', // reset error message
          filteredDomains: DomainsTable.getFilteredDomains(state.searchTerm, this.props.selectedOrg),
        };
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      const { formatMessage } = this.props.intl;
      this.setState({ errorMessage: formatMessage(messages.FailedToLoadDomains, { error: error.message }) });
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async componentDidMount(): Promise<void> {
    await this.loadDomains();
  }

  async componentDidUpdate(prevProps: DomainTableProps): Promise<void> {
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id) {
      await this.loadDomains();
    }
  }

  private getLocalizedDomainStatusLabel = (domainStatus: DomainStatus): React.ReactNode => {
    switch (domainStatus) {
      case DomainStatus.RESERVED:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Reserved" defaultMessage="Reserved" />;
      case DomainStatus.UNCLAIMED:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Unclaimed" defaultMessage="Unclaimed" />;
      case DomainStatus.CLAIMED:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Claimed" defaultMessage="Claimed" />;
      case DomainStatus.VALIDATED:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Validated" defaultMessage="Validated" />;
      case DomainStatus.WITHDRAWN:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Withdrawn" defaultMessage="Withdrawn" />;
      case DomainStatus.ACTIVE:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Active" defaultMessage="Active" />;
      case DomainStatus.EXPIRED:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Expired" defaultMessage="Expired" />;
      default:
        return <FormattedMessage id="EditCompartment.Domains.DomainStatus.Undefined" defaultMessage="Undefined" />;
    }
  };

  private static getFilteredDomains(searchTerm: string, compartment: UOrgMaster): UDomain[] {
    return _.filter(compartment.domains, (domain: UDomain): boolean =>
      _.includes(_.toLower(domain.domainName), _.toLower(searchTerm))
    );
  }

  private onSearch = (searchTerm: string): void => {
    const searchDomains = _.trim(searchTerm);
    if (_.isEmpty(searchDomains)) {
      this.setState({ filteredDomains: this.props.selectedOrg.domains, searchTerm });
      return;
    }
    Analytics.fireCTAEvent(`domain search`);
    const filteredDomains = DomainsTable.getFilteredDomains(searchTerm, this.props.selectedOrg);
    this.setState({ filteredDomains, searchTerm });
  };

  public render(): React.ReactNode {
    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in domain table if we are unable to load the domain data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }
    const rows: React.ReactNode[] = this.state.filteredDomains.map((domain: UDomain): React.ReactNode => {
      const statusVariant = domain.domainStatus === DomainStatus.ACTIVE ? 'positive' : 'negative';
      return (
        <TR key={domain.domainName}>
          <TD data-testid="domain-name">{domain.domainName}</TD>
          <TD>{domain.directoryName}</TD>
          <TD>{UDirectory.getDirectoryTypeDisplayName(domain.directoryType)}</TD>
          <TD>
            <div className="DomainsTable__statusColumn">
              <StatusLight variant={statusVariant} />
            </div>
            <div className="DomainsTable__statusColumn">{this.getLocalizedDomainStatusLabel(domain.domainStatus)}</div>
          </TD>
        </TR>
      );
    });
    // show wait if domain not loaded
    const { formatMessage } = this.props.intl;
    return this.props.selectedOrg.domainsLoaded ? (
      <div>
        <div className="EditCompartment_tabSectionHeader EditCompartment__margin--bottom">
          {this.props.selectedOrg.domains.length > 0 && (
            <Search
              placeholder={formatMessage(messages.SearchPlaceholder)}
              onChange={this.onSearch}
              value={this.state.searchTerm}
              data-testid="domain-table-search"
              className="EditCompartment__TableSearch"
            />
          )}
          <span className="DomainsTable__rightContainer">
            {this.props.selectedOrg.domains.length > 0 && (
              <Button
                onClick={(): void => {
                  const goToAdminConsoleDialogRef = ModalContainer.show(
                    <GoToAdminConsoleDialog
                      onClose={(): void => {
                        ModalContainer.hide(goToAdminConsoleDialogRef as number);
                      }}
                      url={`${config.adminConsole.url}/${this.props.selectedOrg.id}/settings/identity/domains`}
                      selectedOrg={this.props.selectedOrg}
                    />,
                    this
                  );
                }}
                label={formatMessage(messages.ViewInAdminConsole)}
                variant="secondary"
              />
            )}
            <GoHelpBubble goUrlKey={GoUrlKeys.organizationsDomains}>
              <p>{formatMessage(messages.PaneHelp)}</p>
            </GoHelpBubble>
          </span>
        </div>
        <ScrollableContent uniqueId="EditCompartment_DomainsTableID" className="EditCompartment_tabContent">
          <Table>
            <THead className="EditCompartment__stickyHeader">
              <TH>
                <div>
                  <FormattedMessage id="EditCompartment.Domains.DomainName" defaultMessage="DOMAIN NAME" />
                </div>
              </TH>
              <TH>
                <FormattedMessage id="EditCompartment.Domains.Directory" defaultMessage="DIRECTORY" />
              </TH>
              <TH>
                <FormattedMessage id="EditCompartment.Domains.DirectoryType" defaultMessage="DIRECTORY TYPE" />
              </TH>
              <TH>
                <FormattedMessage id="EditCompartment.Domains.Status" defaultMessage="STATUS" />
              </TH>
            </THead>
            <TBody>{rows}</TBody>
          </Table>
        </ScrollableContent>
      </div>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}
export default injectIntl(DomainsTable);
