import React, { ReactNode } from 'react';
import * as _ from 'lodash';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import Search from '@react/react-spectrum/Search';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import { Tag } from '@react/react-spectrum/TagList';
import { Flex } from '@adobe/react-spectrum';
import './ProductTable.css';
import '../../common.css';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource } from '../../../services/orgMaster/UResource';
import ProductContractData from '../../../services/orgMaster/ProductContractData';
import ProfileTable from './ProfileTable/ProfileTable';
import Analytics from '../../../Analytics/Analytics';
import { TEMP_ID_PREFIX } from '../../../services/orgMaster/OrgMaster';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoHelpBubble from '../../../components/HelpBubble/HelpBubble';
import NumberFormatter from '../../../services/utils/NumberFormatter';
import ScrollableContent from '../Widgets/ScrollableContent';
import ContractJIL from '../../../services/orgMaster/ContractsJIL';
import { ContractType } from '../../../services/organization/ContractType';
import Utils from '../../../services/utils/Utils';

/*
 * Product Table receives these props from the EditCompartment component
 * - 'save' callback is implemented at the EditCompartment level and passed through child components
 */
interface ProductTableProps extends WrappedComponentProps {
  update: () => void;
  productsLoaded: () => void;
  selectedOrg: UOrgMaster;
}

interface ProductTableState {
  productContractMap: Map<string, ProductContractData>; // map of product id to product with contract data
  filteredProducts: UProduct[] | undefined;
  searchInput: string;
  errorMessage: string; // if the errorMessage is not empty, the alert box is shown. The errorMessage is set when unable to load the products
  triggerScrollRerender: boolean;
}

const messages = defineMessages({
  unable_to_load: {
    id: 'ProductTable.UnableToLoad',
    defaultMessage: 'Unable to load products data: {error}',
  },
  Search_products: {
    id: 'ProductTable.SearchProducts',
    defaultMessage: 'Search',
  },
  PaneHelp: {
    id: 'Organizations.Products.Helptext',
    defaultMessage:
      'Products and services available in the organization selected on the left are shown below. You can view and manage product profiles here or in the Admin Console.',
  },
  ProductLableSingular: {
    id: 'ProductTable.ProductLableSingular',
    defaultMessage: 'Product',
  },
  ProductLablePlural: {
    id: 'ProductTable.ProductLablePlural',
    defaultMessage: 'Products',
  },
});

class ProductTable extends React.Component<ProductTableProps, ProductTableState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted

  constructor(props: ProductTableProps) {
    super(props);
    this.state = {
      productContractMap: this.createProductToContractMap(),
      filteredProducts: this.props.selectedOrg.products,
      searchInput: '',
      errorMessage: '', // initially set errorMessage to empty string
      triggerScrollRerender: false,
    };
  }

  private async loadProducts(): Promise<void> {
    const { formatMessage } = this.props.intl;
    try {
      // load products is not loaded already
      if (!this.props.selectedOrg.productsLoaded) {
        await LoadOrgDataService.loadProducts(this.props.selectedOrg.id, this.abortController.signal);
        this.props.productsLoaded();
      }
      if (this.abortController.signal.aborted) return;
      this.setState({
        errorMessage: '', // reset error message
        productContractMap: this.createProductToContractMap(),
        filteredProducts: this.getFilteredProducts(this.props.selectedOrg),
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return;
      this.setState({ errorMessage: formatMessage(messages.unable_to_load, { error: error.message }) });
    }
  }

  async componentDidMount(): Promise<void> {
    await this.loadProducts();
  }

  async componentDidUpdate(prevProps: ProductTableProps): Promise<void> {
    const isProductListChange = !_.isEqual(
      this.state.filteredProducts,
      this.getFilteredProducts(this.props.selectedOrg)
    );
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id || isProductListChange) {
      await this.loadProducts(); // load products if the changed compartment has not loaded the products
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  /**
   * Generates a map of product ids to their associated products with contract data
   */
  private createProductToContractMap(): Map<string, ProductContractData> {
    const map: Map<string, ProductContractData> = new Map<string, ProductContractData>();
    const productsContractsData = ProductContractData.createMultipleFromProductsAndContracts(
      this.props.selectedOrg.products,
      this.props.selectedOrg.contracts
    );
    _.forEach(productsContractsData, (productContractData: ProductContractData): void => {
      map.set(productContractData.product.id, productContractData);
    });
    return map;
  }

  private onSearch = (searchInput: string): void => {
    Analytics.fireCTAEvent('product search');
    if (this.abortController.signal.aborted) return;
    this.setState({ searchInput });
    if (this.abortController.signal.aborted) return;
    this.setState({ filteredProducts: this.getFilteredProducts(this.props.selectedOrg) });
  };

  private getFilteredProducts(compartment: UOrgMaster): UProduct[] {
    const currentProducts = compartment.products;
    const trimmedSearchInput = _.trim(this.state.searchInput);
    if (_.isEmpty(trimmedSearchInput)) {
      return currentProducts;
    }
    return _.filter(currentProducts, (product: UProduct): boolean =>
      _.includes(_.toLower(product.name), _.toLower(trimmedSearchInput))
    );
  }

  // Only ETLA and VIP contracts are tagged with respective license
  // please see: https://xd.adobe.com/view/8bbccd91-f167-42d8-8bfb-1ec60ddc8e4a-deef/screen/02a3074d-0906-44ed-a82b-0beba4345ead (slide 111)
  ALLOWED_CONTRACT_TYPES_TO_DISPLAY = new Set([ContractType.ETLA.toString(), ContractType.VIP.toString()]);

  /**
   * contract label should be generated when the org has both ETLA and VIP contracts in it.
   * @returns true if the contract label should be generated for the license else false
   */
  private shouldGenerateContractLabel = (): boolean => {
    const { contracts } = this.props.selectedOrg;
    const allowedContractTypes = Array.from(this.ALLOWED_CONTRACT_TYPES_TO_DISPLAY);
    // validate for EACH allowed contract type, the org has a contract present in it or NOT
    return _.every(allowedContractTypes, (contractType: string): boolean => {
      // check if the org has atleast one contract of 'contractType'
      return _.some(contracts, (cont: ContractJIL): boolean => {
        return cont.buyingProgram !== undefined && cont.buyingProgram.toUpperCase() === contractType.toUpperCase();
      });
    });
  };

  /**
   * For a license, get the contract type if allowed to display else null
   */
  private getContractLabelTag = (product: UProduct): ReactNode | null => {
    const { contracts } = this.props.selectedOrg;
    if (product.tuples && product.tuples.length > 0) {
      const productContractId = product.contractId;
      const licenseContract = _.find(contracts, (contract: ContractJIL) => _.isEqual(contract.id, productContractId));
      if (licenseContract && this.ALLOWED_CONTRACT_TYPES_TO_DISPLAY.has(licenseContract.buyingProgram)) {
        return <Tag className="ProductTable__contractType">{licenseContract.buyingProgram}</Tag>;
      }
    }
    return null;
  };

  /**
   * For a license, get the license expiry status
   */
  private getContractExpiryStatusTags = (product: UProduct): ReactNode | null => {
    const licenseTuple = product.getEarliestExpiringTuple();
    if (licenseTuple) {
      if (licenseTuple.isNotificationPhase()) {
        return (
          <Tag className="ProductTable__expiringLabel">
            <FormattedMessage id="ProductTable.ExpiringLabel" defaultMessage="Expiring" />
          </Tag>
        );
      }
      if (licenseTuple.isGracePhase() || licenseTuple.isPostGracePhase()) {
        return (
          <Tag className="ProductTable__expiredLabel">
            <FormattedMessage id="ProductTable.ExpiredLabel" defaultMessage="Expired" />
          </Tag>
        );
      }
    }
    return null;
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in products table if we are unable to load the product data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }

    const products: React.ReactNode[] = _.sortBy(this.state.filteredProducts, 'name').map(
      (product: UProduct): React.ReactNode => {
        const productContractData = this.state.productContractMap.get(product.id);
        const quotaResources: UResource[] = product.getQuotaResources();
        const productInfo = (
          <span className="ProductTable__quotaContainer">
            <Flex direction="row" alignItems="center">
              <Flex direction="row">
                <img alt="" className="ProductTable__productIcon" src={product.icons.svg} />
                <Flex direction="column" justifyContent="center">
                  <Flex direction="row" alignItems="center">
                    <span className="ProductTable__productName">{product.name}</span>
                    {this.props.selectedOrg.shouldDisplayContractNames()
                      ? !_.isEmpty(productContractData?.contractTags) &&
                        _.map(
                          productContractData?.contractTags,
                          (contractTag: string): React.ReactNode => (
                            <Tag className="ProductTable__contractTag" key={contractTag}>
                              {ContractJIL.getLocalizedContractTag(contractTag)}
                            </Tag>
                          )
                        )
                      : // remove this else-block when RENDER_CONTRACT_NAMES FF is permanent
                        this.shouldGenerateContractLabel() && this.getContractLabelTag(product)}
                  </Flex>
                  {this.props.selectedOrg.shouldDisplayContractNames() && (
                    <div className="ProductTable__contractName">{productContractData?.contractNames}</div>
                  )}
                </Flex>
              </Flex>
              <div className="ProductTable__infoContainer">
                {this.getContractExpiryStatusTags(product)}
                <div className="ProductTable__quotaInfo">
                  {/* do not display resource quantities for new products */}
                  {!product.id.includes(TEMP_ID_PREFIX) &&
                    _.map(quotaResources, (resource: UResource): React.ReactNode => {
                      return (
                        <span key={resource.code}>
                          {NumberFormatter.formatNumber(resource.cap)} {`${Utils.localizedResourceUnit(resource.unit)}`}
                          <br />
                        </span>
                      );
                    })}
                </div>
              </div>
            </Flex>
          </span>
        );
        return (
          <AccordionItem
            header={productInfo}
            key={product.id}
            disabled={!product.allLicenseTuplesAllowEditing() || !product.allowedToAddAdminsAndProfiles()}
            className="ProductTable__accordian"
            data-testid={`product_${product.id}`}
          >
            <ProfileTable productId={product.id} update={this.props.update} selectedOrg={this.props.selectedOrg} />
          </AccordionItem>
        );
      }
    );
    // show wait if products not loaded
    return this.props.selectedOrg.productsLoaded ? (
      <div className="ProductTable">
        <div className="EditCompartment_tabSectionHeader EditCompartment__margin--bottom">
          {this.props.selectedOrg.products.length > 0 ? (
            <Search
              placeholder={formatMessage(messages.Search_products)}
              className="EditCompartment__TableSearch"
              onChange={async (searchInput: string): Promise<void> => {
                if (this.abortController.signal.aborted) return;
                if (!searchInput) {
                  // reset product list if the searchInput is empty
                  this.setState({
                    filteredProducts: this.props.selectedOrg.products,
                    searchInput,
                  });
                } else {
                  this.setState({ searchInput });
                }
                this.onSearch(searchInput);
              }}
              value={this.state.searchInput}
              data-testid="product-table-search"
              aria-label="Search Products"
            />
          ) : (
            <div>
              <FormattedMessage id="ProductTable.NoProducts" defaultMessage="No Products" />
            </div>
          )}
          <span className="EditCompartment__tabSection--rightAligned">
            <span className="EditCompartment__listCountDisplay">
              <FormattedMessage
                id="EditCompartment.ProductTableCountDisplay"
                description="Display product count"
                defaultMessage="{productCount} {productLabel}"
                values={{
                  productCount: this.props.selectedOrg.products.length,
                  productLabel:
                    this.props.selectedOrg.products.length === 1
                      ? formatMessage(messages.ProductLableSingular)
                      : formatMessage(messages.ProductLablePlural),
                }}
              />
            </span>
            <GoHelpBubble goUrlKey={GoUrlKeys.organizationsProducts}>
              <p>{formatMessage(messages.PaneHelp)}</p>
            </GoHelpBubble>
          </span>
        </div>
        <ScrollableContent uniqueId="EditCompartment_ProductTableID" className="EditCompartment_tabContent">
          <Accordion
            multiselectable
            onChange={(): void => {
              // toggle triggerScrollRerender to trigger re-render of ScrollableContent component on click
              this.setState((state): Pick<ProductTable, never> => {
                return { triggerScrollRerender: !state.triggerScrollRerender };
              });
              Analytics.fireCTAEvent('product accordion clicked');
            }}
            data-testid="product-table-main-accordion"
          >
            {products}
          </Accordion>
        </ScrollableContent>
      </div>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}

export default injectIntl(ProductTable);
