import * as _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './OrgReparentProductsMessages.css';
import {
  IdAndName,
  OrgAndProductRefs,
  OrgAndProductWithSource,
  OrgReparentProductsReport,
} from '../../services/reparent';

interface OrgReparentProductsErrorMessagesProps {
  report: OrgReparentProductsReport;
}

function OrgReparentProductsErrorMessages(props: OrgReparentProductsErrorMessagesProps): React.ReactElement {
  return (
    <div>
      {/* orgs involved has expired products */}
      {!_.isEmpty(props.report.expiredProductInfoForReparent) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.expiredProducts"
              defaultMessage="Reallocating products prohibited since following products are expired."
            />
          </div>
          {_.map(
            props.report.expiredProductInfoForReparent,
            (info: OrgAndProductRefs): React.ReactNode => (
              <div
                className="orgReparentProductsMessages__listItem"
                key={`expiredProducts_${info.productId}_${info.orgId}`}
              >
                <FormattedMessage
                  id="orgReparentProductsErrors.deleteProductsFirstDetails"
                  defaultMessage="product: <b>{productName}</b>, org: <b>{orgName}</b>"
                  values={{
                    productName: info.productName,
                    orgName: info.orgName,
                    orgId: info.orgId,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </div>
      )}
      {/* zero grant values for delete errors */}
      {!_.isEmpty(props.report.productsWithZeroGrantsForDelete) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.zeroGrantsDelete"
              defaultMessage="The following products cannot be deleted because they have 0 grant values on resources (Make quantity nonzero, then delete)"
            />
          </div>
          {_.map(
            props.report.productsWithZeroGrantsForDelete,
            (info: OrgAndProductRefs): React.ReactNode => (
              <div
                className="orgReparentProductsMessages__listItem"
                key={`zeroGrantsDelete_${info.productId}_${info.orgId}`}
              >
                <FormattedMessage
                  id="orgReparentProductsErrors.licenseInfo"
                  defaultMessage="<b>product:</b> {productName}, <b>org:</b> {orgName} ({orgId})"
                  values={{
                    productName: info.productName,
                    orgName: info.orgName,
                    orgId: info.orgId,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </div>
      )}
      {/* unmovable products must be deleted before reparenting */}
      {!_.isEmpty(props.report.productsToDelete) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.deleteProductsFirst"
              defaultMessage="The following products need to be deleted before reparenting."
              // TODO: provide a link to the product allocation page, which closes the dialog too. And can we preselect one of the products?
            />
          </div>
          {_.map(
            props.report.productsToDelete,
            (info: OrgAndProductRefs): React.ReactNode => (
              <div
                className="orgReparentProductsMessages__listItem"
                key={`deleteProducts_${info.productId}_${info.orgId}`}
              >
                <FormattedMessage
                  id="orgReparentProductsErrors.deleteProductsFirstDetails"
                  defaultMessage="product: <b>{productName}</b>, org: <b>{orgName}</b>"
                  values={{
                    productName: info.productName,
                    orgName: info.orgName,
                    orgId: info.orgId,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </div>
      )}
      {/* manage product policy off for delete errors */}
      {!_.isEmpty(props.report.orgsWithManageProductPolicyOffForDelete) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.manageProductPolicyDelete"
              defaultMessage="Cannot delete products from the following org(s) because their 'Manage Product' policy is set to Not Allowed"
            />
          </div>
          {_.map(
            props.report.orgsWithManageProductPolicyOffForDelete,
            (org: IdAndName): React.ReactNode => (
              <div className="orgReparentProductsMessages__listItem" key={`manageProductPolicyDelete_${org.id}`}>
                {org.name} ({org.id})
              </div>
            )
          )}
        </div>
      )}
      {/* missing global admin permission for add errors */}
      {!_.isEmpty(props.report.orgsMissingGAPermissionForAdd) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.globalAdminAdd"
              defaultMessage="Cannot allocate products from the following parent orgs to their children because you do not have Global Admin permissions on those parent orgs"
            />
          </div>
          {_.map(
            props.report.orgsMissingGAPermissionForAdd,
            (org: IdAndName): React.ReactNode => (
              <div className="orgReparentProductsMessages__listItem" key={`globalAdminAdd_${org.id}`}>
                {org.name} ({org.id})
              </div>
            )
          )}
        </div>
      )}
      {/* manage product policies off for add errors */}
      {!_.isEmpty(props.report.orgsWithManageProductPolicyOffForAdd) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.manageProductPolicyAdd"
              defaultMessage="Cannot add products to the following org(s) because their 'Manage Product' policy is set to Not Allowed"
            />
          </div>
          {_.map(
            props.report.orgsWithManageProductPolicyOffForAdd,
            (org: IdAndName): React.ReactNode => (
              <div className="orgReparentProductsMessages__listItem" key={`manageProductPolicyAdd_${org.id}`}>
                {org.name} ({org.id})
              </div>
            )
          )}
        </div>
      )}
      {/* over-allocation from add errors */}
      {!_.isEmpty(props.report.overAllocatedInfoForAdd) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.overAllocAdd"
              defaultMessage="Allocating the following products would trigger an over-allocation of their source product"
            />
          </div>
          {_.map(
            props.report.overAllocatedInfoForAdd,
            (info: OrgAndProductWithSource): React.ReactNode => (
              <div
                className="orgReparentProductsMessages__listItem orgReparentProductsMessages__long"
                key={`overAllocAdd_${info.productId}_${info.orgId}`}
              >
                <FormattedMessage
                  id="orgReparentProductsErrors.overAllocAddInfo.licenseSourceInfo"
                  defaultMessage="<b>product:</b> {productName}, <b>org:</b> {orgName} ({orgId}), <b>parent org for source:</b> {parentOrgName} ({parentOrgId})"
                  values={{
                    productName: info.productName,
                    orgName: info.orgName,
                    orgId: info.orgId,
                    parentOrgName: info.sourceOrgName,
                    parentOrgId: info.sourceOrgId,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </div>
      )}
      {/* conflicting product from add errors */}
      {!_.isEmpty(props.report.conflictingProductsForAdd) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsErrors.conflictingProductAdd"
              defaultMessage="Allocating the following products cannot proceed due to conflicting products in the child orgs."
            />
          </div>
          {_.map(
            props.report.conflictingProductsForAdd,
            (info: OrgAndProductWithSource): React.ReactNode => (
              <div
                className="orgReparentProductsMessages__listItem orgReparentProductsMessages__long"
                key={`conflictingProdAdd_${info.productId}_${info.orgId}`}
              >
                <FormattedMessage
                  id="orgReparentProductsErrors.conflictingProductAdd.licenseSourceInfo"
                  defaultMessage="Product ''{productName}'' in child org ''{orgName}'' conflicts with product to allocate (''{sourceProductName}'') from org ''{sourceOrgName}''"
                  values={{
                    productName: info.productName,
                    orgName: info.orgName,
                    sourceProductName: info.sourceProductName,
                    sourceOrgName: info.sourceOrgName,
                  }}
                />
              </div>
            )
          )}
        </div>
      )}
      {/* over-allocation from re-parent errors */}
      {!_.isEmpty(props.report.overAllocatedInfoForReparent) && (
        <div>
          <div>
            <FormattedMessage
              id="orgReparentProductsReport.overAllocReparent"
              defaultMessage="Reallocating the following products would trigger an over-allocation of their new source product"
            />
          </div>
          {_.map(
            props.report.overAllocatedInfoForReparent,
            (info: OrgAndProductWithSource): React.ReactNode => (
              <div
                className="orgReparentProductsMessages__listItem orgReparentProductsMessages__long"
                key={`overAllocReparent_${info.productId}_${info.orgId}`}
              >
                <FormattedMessage
                  id="orgReparentProductsErrors.overAllocReparentInfo.licenseSourceInfo"
                  defaultMessage="<b>product:</b> {productName}, <b>org:</b> {orgName} ({orgId}), <b>parent org for source:</b> {parentOrgName} ({parentOrgId})"
                  values={{
                    productName: info.productName,
                    orgName: info.orgName,
                    orgId: info.orgId,
                    parentOrgName: info.sourceOrgName,
                    parentOrgId: info.sourceOrgId,
                    b: (msg: string): React.ReactNode => <b className="orgReparentProductsMessages__label">{msg}</b>,
                  }}
                />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}
export default OrgReparentProductsErrorMessages;
