import * as _ from 'lodash';
import * as log from 'loglevel';
import { EditState, ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from './OrgMaster';

import { OrgAdminType } from '../authentication/IMS';

/* eslint-disable import/no-cycle */
/* eslint-disable prefer-template */
import { UBasic, UBasicData } from './UBasic';
import { UOrg, UOrgData } from './UOrg';
import { UCompartmentPolicies, UCompartmentPoliciesData } from './UCompartmentPolicy';

import { UProduct, UProductData } from './UProduct';
import { UProductProfile, UProductProfileData } from './UProductProfile';
import { LicenseTuple } from './LicenseTuple';
import { UAdmin, UAdminData } from './UAdmin';
import { UUserGroup, UUserGroupData } from './UUserGroup';
import { UDomain } from './UDomain';
import { UDirectory } from './UDirectory';
import OrgPickerController from '../organization/OrgPickerController';
import { MessageData } from '../Codes/ErrorCodes';
import ContractJIL from './ContractsJIL';

/* eslint-enable import/no-cycle */
import FloodgateService from '../floodgate/FloodgateService';
import { UUserGroupShare } from './UUserGroupShare';

/**
 * Combines the arguments common to import methods.
 * This allows them to be grouped so that multiple groups of arguments
 * can be passed to multiple import methods.
 */
export interface Update {
  elemType: ObjectTypes;
  elem: UBasicData;
  operation: OrgOperation;
  messageData: MessageData;
}

/**
 * UOrgMaster represents an org master which contains the data associated with
 * the org and contains data on child orgs.  UOrg contains the data of the org itself.
 */

/**
 * Mutable object for UOrgMaster.
 * Provides object containing only properties of UOrgMaster.
 * Represents a UOrgMaster object that can be retrieved from the back-end.
 */
export interface UOrgMasterData extends UBasicData {
  compartmentPolicy?: UCompartmentPolicies; // Policies for this org master
  organization?: UOrg; // Org data for this org itself
  // TODO: once UUsers are removed, rename this to "admins" and remove "admins" from UAdministerableData (or remove UAdministerable and UAdministerableData)
  adminsV2?: UAdminData[]; // Array of admins (in a format where there is 1 admin object per user with all roles and targets)
  products?: UProduct[]; // Array of products associated with this org
  userGroups?: UUserGroup[]; // Array of user groups associated with this org
  domains?: UDomain[]; // Array of domains associated with this org
  childrenRefs?: UOrgMaster[]; // an array to hold references to children orgs
  parentOrgRef: UOrgMaster | undefined; // reference to the parent org
  orgId?: string; // id of this org
  totalProducts?: number; // total number of products for this org
  totalAdminCount?: number; // total number of admins for this org
  totalUserGroupCount?: number; // total number of user groups for this org
  currentAdminPageIndex?: number; //  current admin  page index
  currentUserGroupPageIndex?: number; // current user group page index
  totalAdminPageCount?: number; //  total number of admin pages
  totalUserGroupPageCount?: number; // total number of user group pages
}

/**
 * UOrgMaster object that also contains the methods and functionality.
 */
export class UOrgMaster extends UBasic implements UOrgMasterData {
  compartmentPolicy: UCompartmentPolicies;
  organization: UOrg;
  adminsV2: UAdmin[] = [];
  products: UProduct[] = [];
  userGroups: UUserGroup[] = [];
  domains: UDomain[] = [];
  directories: UDirectory[] = [];
  contracts: ContractJIL[] = [];
  childrenRefs: UOrgMaster[] = [];
  parentOrgRef: UOrgMaster | undefined;
  children: UOrgMaster[] = [];
  orgId: string = '';
  currentAdminOrder: number = 0;
  totalAdminCount: number = 0; // initialized from /count api. Only read this value if adminCountsLoaded is true.
  totalAdminPageCount: number = 0; // total number of admin pages
  totalUserGroupCount: number = 0; // initialized from /count api. Only read this value if userGroupCountsLoaded is true.
  totalDirectoriesCount: number = 0; // initialized from jil directories api
  totalDirectoriesPageCount: number = 0;
  totalUserGroupPageCount: number = 0; // total number of user group pages
  totalDomainCount: number = 0; // initialized from /count api. Only read this value if domainCountsLoaded is true.
  totalUserCount: number = 0; // initialized from /count api. Only read this value if userCountsLoaded is true.
  currentAdminPageIndex: number = 0; // current admin page index
  currentUserGroupPageIndex: number = 0; // current user group page index
  currentDirectoriesPageIndex: number = 0; // current directories index
  productsLoaded: boolean = false; // boolean to know if the products have been loaded
  adminsLoaded: boolean = false; // boolean to know if the admins have been loaded
  userGroupsLoaded: boolean = false; // boolean to know if the user groups have been loaded
  policiesLoaded: boolean = false; // boolean to know if the policies have been loaded
  domainsLoaded: boolean = false; // boolean to know if the domains have been loaded
  directoriesLoaded: boolean = false;
  orgDetailsLoaded: boolean = false; // boolean to know if the org details have been loaded
  // Loading booleans for user, admin, user group, and domains count are necessary because the counts are numbers and have no nil value to check if they are loaded or not.
  // Even if the counts also accepted a nil value it will be difficult to distinguish if the count needs loading, or if the count has no value from server.
  userCountsLoaded: boolean = false; // boolean to know if user counts have been loaded
  adminCountsLoaded: boolean = false; // boolean to know if admin counts have been loaded (necessary because totalAdminCount is a number and does not have a nil value )
  userGroupCountsLoaded: boolean = false; // boolean to know if user group counts have been loaded
  domainCountsLoaded: boolean = false; // boolean to know if domain counts have been loaded
  contractsLoaded: boolean = false; // boolean to know if the contracts have been loaded
  readOnlyLoaded: boolean = false; // boolean to know if the read only information is loaded
  promiseUserCountsLoaded: Promise<void> | null = null; // promise to know the user count load state. Possible states: 1. null: user counts have not been loaded 2. pending: currently loading 3. resolved: user counts already loaded
  promiseAdminCountsLoaded: Promise<void> | null = null; // promise to know the admin count load state.  Possible states: 1. null: admin counts have not been loaded 2. pending: currently loading 3. resolved: admin counts already loaded
  promiseUserGroupCountsLoaded: Promise<void> | null = null; // promise to know the user group count load state. Possible states: 1. null: user group counts have not been loaded 2. pending: currently loading 3. resolved: user group counts already loaded
  promiseDomainCountsLoaded: Promise<void> | null = null; // promise to know the domain count load state. Possible states: 1. null: domain counts have not been loaded 2. pending: currenty loading 3. resolve: domain counts already loaded
  promiseProductLoaded: Promise<void> | null = null; // promise to know the product load state. Possible states: 1. null: products have not been loaded 2. pending: currently loading 3. resolved: products already loaded
  promiseUserGroupLoaded: Promise<void> | null = null; // promise to know the user group load state. Possible states: 1. null: user groups have not been loaded 2. pending: currently loading 3. resolved: user groups already loaded
  promiseAdminsLoaded: Promise<void> | null = null; // promise to know the admins load state. Possible states: 1. null: admins have not been loaded 2. pending: currently loading 3. resolved: admins already loaded
  promisePoliciesLoaded: Promise<void> | null = null; // promise to know the policies load state. Possible states: 1. null: policies have not been loaded 2. pending: currently loading 3. resolved: policies already loaded
  promiseDomainsLoaded: Promise<void> | null = null; // promise to know the domains load state. Possible states: 1. null: domains have not been loaded 2. pending: currently loading 3. resolved: domains already loaded
  promiseDirectoriesLoaded: Promise<void> | null = null; // promise to know the directories load state. Possible states: 1. null: directories have not been loaded 2. pending: currently loading 3. resolved: directories already loaded
  promiseOrgDetailsLoaded: Promise<void> | null = null; // promise to know the org details load state. Possible states: 1. null: org details have not been loaded 2. pending: currently loading 3. resolved: org details already loaded
  promiseContractsLoaded: Promise<void> | null = null; // promise to know the org contracts load state. Possible states: 1. null: org details have not been loaded 2. pending: currently loading 3. resolved: org details already loaded

  public static ADMIN_PAGE_SIZE: number = 10; // page size for paginated loading of admins
  public static USERGROUP_PAGE_SIZE: number = 10; // page size for paginated loading of user group
  public static DIRECTORIES_PAGE_SIZE: number = 10; // page size for paginated loading of directories

  /**
   * Constructs a UOrgMaster from org data (UOrg or UOrgData).
   */
  constructor(organization: UOrgData, newOrg = false) {
    super();
    this.compartmentPolicy = new UCompartmentPolicies();
    this.organization = new UOrg(organization);
    // if its a new org then no need to load the products/profiles/user groups/admins/domains/policies, set all promises to resolved and booleans to true
    if (newOrg) {
      this.compartmentPolicy.setDefaultPolicies(this.id); // set compartment policies to default for a new org. Setting orgId field of compartment policies is required for updating policies on new org.
      this.productsLoaded = true;
      this.adminsLoaded = true;
      this.userGroupsLoaded = true;
      this.policiesLoaded = true;
      this.domainsLoaded = true;
      this.orgDetailsLoaded = true;
      this.readOnlyLoaded = true;
      this.contractsLoaded = true;
      this.promiseProductLoaded = Promise.resolve();
      this.promiseUserGroupLoaded = Promise.resolve();
      this.promiseAdminsLoaded = Promise.resolve();
      this.promisePoliciesLoaded = Promise.resolve();
      this.promiseDomainsLoaded = Promise.resolve();
      this.promiseOrgDetailsLoaded = Promise.resolve();
      this.promiseContractsLoaded = Promise.resolve();
    }
  }

  /**
   * Retrieves the id of this org.
   */
  get id(): string {
    return this.organization.id;
  }

  get name(): string {
    return this.organization.name;
  }

  /**
   * Retrieves the child orgs of this org.
   */
  getChildren(): UOrgMaster[] {
    return this.childrenRefs;
  }

  /**
   * Add child org to this org by passing the org itself
   * (Child orgs state not changed).
   *
   * Note: Dont call this directly from UI components. See setParentChildRelation method in HierarchyManager
   */
  addChildOrgRef(childOrg: UOrgMaster): void {
    const existsAlready = _.find(this.childrenRefs, (childRef) => childRef === childOrg);
    if (!existsAlready) {
      this.childrenRefs.push(childOrg);
      this.childrenRefs = _.sortBy(this.childrenRefs, (child: UOrgMaster) => _.toLower(child.name));
    }
  }

  /**
   * remove the reference of the child org when the child org is deleted
   * @param childOrgToRemoveId
   *
   *  Note: Dont call this directly from UI components. See removeOrgCompletely method in HierarchyManager
   */
  removeChildOrgRef(childOrgToRemoveId: string): void {
    _.remove(this.childrenRefs, (child) => child.id === childOrgToRemoveId);
  }

  /**
   * Changes the compartment policy for this org
   * (This changes the orgs state to updated).
   */
  addCompartmentPolicy(compartmentPolicy: UCompartmentPolicies): void {
    this.compartmentPolicy = compartmentPolicy;
    this.editState = EditState.UPDATE;
  }

  addUserGroup(newUserGroup: UUserGroup): void {
    if (!_.find(this.userGroups, (eachUserGroup) => eachUserGroup.name === newUserGroup.name)) {
      this.userGroups.push(newUserGroup);
    }
  }

  addDirectories(newDirectory: UDirectory): void {
    if (!_.find(this.directories, (eachDirectory) => eachDirectory.name === newDirectory.name)) {
      this.directories.push(newDirectory);
    }
  }

  /**
   * Associates user group with this org
   * (This changes the orgs state to updated).
   */
  addNewUserGroup(userGroup: UUserGroup): void {
    this.addUserGroup(userGroup);
    this.editState = EditState.UPDATE;
  }

  /**
   * Associates a product with this org
   * (This changes the orgs state to updated).
   */
  addProduct(product: UProduct): void {
    this.products.push(product);
    this.editState = EditState.UPDATE;
  }

  /**
   * Indicates whether this org is new.
   * (It doesn't have a real org id yet and instead has temp one because
   * the org doesn't exist on the back-end yet).
   */
  isNewOrg(): boolean {
    return _.startsWith(this.organization.id, TEMP_ID_PREFIX);
  }

  /**
   * get count of new admins
   */
  getNewAdminCount(): number {
    return _.filter(this.getAdmins(), (admin: UAdmin): boolean => _.includes(admin.id, TEMP_ID_PREFIX)).length;
  }

  /**
   * get count of new user groups
   */
  getNewUserGroupCount(): number {
    return _.filter(this.userGroups, (userGroup: UUserGroup): boolean => _.includes(userGroup.id, TEMP_ID_PREFIX))
      .length;
  }

  /**
   * Determines whether the UI should be displaying contract names and tags.
   * The criteria is that there is more than 1 contract so that the user can
   * distinguish products that are associated with the multiple contracts.
   */
  shouldDisplayContractNames(): boolean {
    return FloodgateService.isFeatureEnabled(FloodgateService.RENDER_CONTRACT_NAMES) && this.contracts.length > 1;
  }

  getProduct(productId: string): UProduct | undefined {
    return _.find(this.products, ['id', productId]);
  }

  getUserGroup(userGroupId: string): UUserGroup | undefined {
    return _.find(this.userGroups, ['id', userGroupId]);
  }

  // Determines whether all loaded user groups for the org have loaded profiles.
  // This only checks the loaded user groups not necessarily all user groups that exist on the org.
  // NOTE: This method is not necessary, if user groups table no longer loads profiles on list items.
  allLoadedUserGroupProfilesLoaded(): boolean {
    for (let userGroupIndex = 0; userGroupIndex < this.userGroups.length; userGroupIndex++) {
      const userGroup: UUserGroup = this.userGroups[userGroupIndex];
      if (!userGroup.profilesLoaded) {
        return false;
      }
    }
    return true;
  }

  /**
   * Retrieves all admins for the org.  This includes product admins, profile admins, and user group admins.
   * (This is the entire list of admins each representing all roles and targets for a single user.  These admins are all
   * on the org and no longer stored in elements such as products, profiles, or user groups)
   */
  getAdmins(): UAdmin[] {
    return _.orderBy(this.adminsV2, (admin: UAdmin): number | undefined => admin.adminOrder);
  }

  /* eslint-enable no-continue */

  /**
   * Retrieves all admins with a product admin role targeting the given product id
   */
  getAdminsForProduct(productId: string): UAdmin[] {
    return _.filter(this.getAdmins(), (admin: UAdmin): boolean => admin.isProductAdminOf(productId));
  }

  /**
   * Retrieves all admins with a profile admin role targeting the given profile id (of product id)
   */
  getAdminsForProfile(productId: string, profileId: string): UAdmin[] {
    return _.filter(this.getAdmins(), (admin: UAdmin): boolean => admin.isProfileAdminOf(profileId, productId));
  }

  /**
   * Retrieves all admins with a user group admin role targeting the given user group id
   */
  getAdminsForUserGroup(userGroupId: string): UAdmin[] {
    return _.filter(this.getAdmins(), (admin: UAdmin): boolean => admin.isUserGroupAdminOf(userGroupId));
  }

  /**
   * Retrieves the id of the product associated with a profile
   * @param profileId id of profile to lookup product by
   * @returns product id associated with the profile or null if no product could be found
   */
  lookupProductIdByProfileId(profileId: string): string | null {
    const product: UProduct | undefined = _.find(
      this.products,
      (prod: UProduct): boolean =>
        !!_.find(prod.productProfiles, (profile: UProductProfile): boolean => profile.id === profileId)
    );
    if (product) {
      return product.id;
    }
    return null;
  }

  /**
   * Retrieves the profile name given a productId and profileId
   *
   * @param productId id of the product associated with the profile.
   *                  This can be undefined since a product may not have been selected given the context this method is generally used in.
   * @param profileId id of the profile to get the name from
   * @returns name of the profile or undefined if the profile name could not be found
   */
  lookupProfileName(productId: string | undefined, profileId: string): string | undefined {
    if (!_.isNil(productId)) {
      const product = _.find(this.products, (prod: UProduct): boolean => prod.id === productId);
      if (product) {
        const profile = _.find(product.productProfiles, (prof: UProductProfile): boolean => prof.id === profileId);
        if (profile) {
          return profile.name;
        }
      }
    }
    return undefined;
  }

  /**
   * Add product profile (elem) to its related product belonging to this org.
   * Returns the product that the given product profile was added to.
   * Returns null there was no product in this org that the product profile belonged to.
   */
  addOrgProductProfile(elem: UProductProfile): UProduct | null {
    const profile: UProductProfile = new UProductProfile(elem);
    const product: UProduct = _.find(this.products, {
      id: elem.productId,
      orgId: elem.orgId,
    }) as UProduct;
    if (product) {
      product.productProfiles.push(profile);
      return product;
    }
    return null;
  }

  /**
   * edit admin in the org (in a format where there is 1 admin object per user with all roles and targets).
   * adds the admin to the admin list if the operation is CREATE. If the operation is not specified, its a CREATE operation.
   * remove the admin from the admin list if operation is DELETE.
   * update admin in the admin list if the operation is UPDATE.
   * All admins are added, removed, or updated from the admins list in this org.
   * TODO: rename this method to "editAdmin".
   */
  editAdminV2(elem: UAdmin, operation: OrgOperation = OrgOperation.CREATE): void {
    UOrgMaster.editElementInList(this.adminsV2, elem, operation, ObjectTypes.ADMIN);
  }

  /**
   * Returns an Array of org data objects related to an admin type (roletype) that can administrate that org data object.
   * Admin type of Product Admin will return the array of products associated with this org.
   * Admin type of User Group Admin will return the array of user groups assocaited with this org.
   */
  getTargetList(roleType: OrgAdminType | undefined | null): UProduct[] | UUserGroup[] | null {
    switch (roleType) {
      case OrgAdminType.PRODUCT_ADMIN:
        return this.products;
      case OrgAdminType.USER_GROUP_ADMIN:
        return this.userGroups;
      default:
        log.trace(`unhandled roleType for admin: ${roleType}`);
        return null;
    }
  }

  isMoreUserGroups(): boolean {
    return this.currentUserGroupPageIndex + 1 < this.totalUserGroupPageCount;
  }

  isMoreAdmins(): boolean {
    return this.currentAdminPageIndex + 1 < this.totalAdminPageCount;
  }

  getProfiles(): UProductProfile[] {
    return _.flatMap(this.products, (product: UProduct): UProductProfile[] => product.productProfiles);
  }

  /**
   * Retrieves all profiles from a specific product on the org.
   *
   * @param productId id of the product to retrieve profiles from
   * @returns all profiles for a product
   */
  getProfilesForProduct(productId: string | undefined): UProductProfile[] {
    const product = _.find(this.products, (prod: UProduct): boolean => prod.id === productId);
    if (product) {
      return product.productProfiles;
    }
    return [];
  }

  /**
   * Retrieves a specific profile from a specific product on the org.
   *
   * @param productId id of the product to retriee the profile from
   * @param profileId id of the profile to retrieve
   * @returns the selected profile
   */
  getProfileForProduct(productId: string, profileId: string): UProductProfile | undefined {
    return _.find(
      this.getProfilesForProduct(productId),
      (profile: UProductProfile): boolean => profile.id === profileId
    );
  }

  // Checks whether the admin exists in the org.
  // TODO: when UUsers are no longer used, rename this method to 'doesAdminExist'
  doesAdminExistV2(inputAdmin: UAdmin): boolean {
    return !!_.find(this.getAdmins(), (eachAdmin: UAdmin): boolean => UAdmin.checkIfSame(eachAdmin, inputAdmin));
  }

  // Checks whether input admin's existing counterpart has admin role
  // TODO: when UUsers are no longer used, rename this method to 'doesAdminRoleExist'
  doesAdminRoleExistV2(inputAdmin: UAdmin, adminRole: OrgAdminType): boolean {
    const existingAdmin = _.find(this.getAdmins(), (eachAdmin: UAdmin): boolean =>
      UAdmin.checkIfSame(eachAdmin, inputAdmin)
    );
    if (existingAdmin) {
      return existingAdmin.roleExists(adminRole);
    }
    return false;
  }

  /**
   * edit the element in the list.
   * If its a CREATE operation, this method adds the element to the list,
   * If its an UPDATE operation, this method updates the element in the list,
   * If its a DELETE operation, this method would remove the element from the list
   *
   *
   * @param list list of the elements to be updated
   * @param elem edited element to be added, updated, deleted from the list
   * @param operation CREATE, UPDATE or DELETE
   * @param elemType UPRODUCT, UPRODUCTPROFILE, UUSERGROUP, UUSER, UORGANIZATION, ..
   * @private
   */
  public static editElementInList(
    list: UBasicData[],
    elem: UBasicData,
    operation: OrgOperation,
    elemType: ObjectTypes
  ): void {
    if (elemType === ObjectTypes.USER_GROUP_SHARE) {
      if (operation === OrgOperation.DELETE) {
        const share = elem as UUserGroupShare;
        UOrgMaster.applyUserGroupShareEdits(list, share.sourceGroupId, share);
        UOrgMaster.applyUserGroupShareEdits(list, share.targetGroupId, share);
      }
      return;
    }

    // convert elem to object
    let newUObj: UUserGroup | UAdmin | UProductProfile | UProduct | undefined;
    switch (elemType) {
      case ObjectTypes.USER_GROUP:
        newUObj = new UUserGroup(elem as UUserGroupData, operation === OrgOperation.CREATE);
        if ((elem as any).profilesLoaded !== undefined && operation !== OrgOperation.CREATE) {
          // It is possible that the 'elem' is an already existing updated UUserGroup element.
          // If that is the case, then we should also assign whether or not it has loaded profiles.
          newUObj.profilesLoaded = (elem as UUserGroup).profilesLoaded;
        }
        break;
      case ObjectTypes.ADMIN:
        newUObj = new UAdmin(elem as UAdmin);
        break;
      case ObjectTypes.PRODUCT_PROFILE:
        newUObj = new UProductProfile(elem as UProductProfile, operation === OrgOperation.CREATE);
        break;
      case ObjectTypes.PRODUCT:
        newUObj = new UProduct(elem as UProductData, operation === OrgOperation.CREATE);
        break;
      default:
        log.error(`${elemType} is not a valid data type for this edit operation`);
        return;
    }

    // based on the operation, update the list
    if (operation === OrgOperation.CREATE) {
      if (elemType === ObjectTypes.ADMIN) {
        // Specifically for UAdmins it is possible to partially load UAdmins for a specific target (ex: when loading admins for profiles or user groups).
        // Because of this the loaded data should be merged as it could contain additional data rather than rejecting it as a duplicate.
        const existingAdmin: UAdmin | undefined = _.find(list, (item: UBasicData): boolean => item.id === elem.id) as
          | UAdmin
          | undefined;
        if (existingAdmin) {
          // Merge loaded admin role and target data with existing admin data
          existingAdmin.mergeAdminRoles(newUObj as UAdmin);
        } else {
          // No existing admin data, just add the new admin data
          list.push(newUObj);
        }
      } else if (!_.find(list, (each) => each.id === elem.id)) {
        // avoid duplicates, add only the elements which have not been added before (an element might have been added by the search call)
        list.push(newUObj);
      }
    }
    if (operation === OrgOperation.DELETE) {
      _.remove(list, (each) => each.id === elem.id);
    }
    if (operation === OrgOperation.UPDATE) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === elem.id) {
          // eslint-disable-next-line no-param-reassign
          list[i] = newUObj;
        }
      }
    }
  }

  private static applyUserGroupShareEdits(list: UBasicData[], groupId: string | undefined, share: UUserGroupShare) {
    if (!groupId) {
      return;
    }
    const groupIndex = _.findIndex(list, (each) => each.id === groupId);
    if (groupIndex !== -1) {
      const group = list[groupIndex] as UUserGroup;

      if (share.strategy === 'HARD_DELETE') {
        if (group.isTarget) {
          _.pullAt(list, [groupIndex]);
        } else {
          list[groupIndex] = UOrgMaster.removeShareFromUserGroup(group, share);
        }
      } else {
        if (group.isTarget) {
          const copy = new UUserGroup(group, false);
          copy.isTarget = false;
          copy.sharedUserGroupSource = undefined;
          list[groupIndex] = copy;
        } else {
          list[groupIndex] = UOrgMaster.removeShareFromUserGroup(group, share);
        }
      }
    }
  }

  /**
   * If a source group, there can be multiple target groups. These are stored in the sharedUserGroupTargets array.
   * This method removes a share from the in-memory sharedUserGroupsTarget array, and removes the isSource flag on
   * the group if this is the last reference to it from targets.
   *
   * @param group The group to remove the share from
   * @param share The share to remove
   * @private
   */
  private static removeShareFromUserGroup(group: UUserGroup, share: UUserGroupShare) {
    const copy = new UUserGroup(group, false);
    copy.sharedUserGroupTargets = copy.sharedUserGroupTargets?.filter(
      (targetShare) => targetShare.targetGroupId !== share.id
    );
    if (_.isEmpty(copy.sharedUserGroupTargets)) {
      copy.isSource = false;
    }
    return copy;
  }

  /**
   * Retrieves the parent org of an org matching a given org id.
   * This can return undefined if the org has no parent.
   */
  getParentOrgMaster(): UOrgMaster | undefined {
    return this.parentOrgRef;
  }

  public isReadOnlyOrg(): boolean {
    return this.organization.readOnly;
  }

  /**
   * set parent org reference when orgs are first loaded and when new orgs are created by the user
   * @param parentOrg
   *
   * Note: Dont call this directly from UI components. See setParentChildRelation method in HierarchyManager
   */
  public setParentOrgRef(parentOrg: UOrgMaster | undefined): void {
    this.parentOrgRef = parentOrg;
  }

  public updateCompartmentPolicies(editedPolicies: UCompartmentPolicies) {
    _.forEach(editedPolicies.policies, (editedPolicy) => {
      if (editedPolicy.name) {
        this.compartmentPolicy.policies[editedPolicy.name] = _.cloneDeep(editedPolicy);
      }
    });
  }

  /**
   * Add the following edit to the org based on the element type and operation type.
   * For example if the element is product and the operation type is UPDATE, this method updates the product in the product list.
   * Another example if the element is user group and the operation is CREATE, this method adds the user group to the user group list.
   * @param editedElem
   * @param elemType
   * @param operation
   * @param originalElem
   */
  public addEdit(
    editedElem: UBasicData,
    elemType: ObjectTypes,
    operation: OrgOperation,
    originalElem: UBasicData | undefined
  ): void {
    // update the element in UOrgMaster object based on the element type.
    if (elemType === ObjectTypes.ORGANIZATION) {
      const orgData = editedElem as UOrgData;
      if (operation === OrgOperation.UPDATE) {
        this.organization = new UOrg(orgData);
        this.editState = EditState.UPDATE;
      } else if (operation === OrgOperation.CREATE) {
        this.editState = EditState.CREATE;
      } else if (operation === OrgOperation.DELETE) {
        this.editState = EditState.DELETE;
      }
    } else if (elemType === ObjectTypes.COMPARTMENT_POLICY) {
      if (originalElem !== undefined) {
        // first set it back to the original value and then update only the changed policies
        this.compartmentPolicy = new UCompartmentPolicies(originalElem as UCompartmentPoliciesData);
      }
      const editedPolicies = new UCompartmentPolicies(editedElem as UCompartmentPoliciesData);
      this.updateCompartmentPolicies(editedPolicies);
      this.editState = EditState.UPDATE;
    } else if (elemType === ObjectTypes.PRODUCT) {
      UOrgMaster.editElementInList(this.products, editedElem, operation, elemType);
      this.editState = EditState.UPDATE;
    } else if (elemType === ObjectTypes.PRODUCT_PROFILE) {
      const profileData = editedElem as UProductProfileData;
      const matchingProducts: UProduct[] = this.products.filter((p) => p.id === profileData.productId);
      if (matchingProducts === undefined || matchingProducts.length === 0) {
        return;
      }
      if (matchingProducts.length !== 1) {
        log.error(`found more than one product ${profileData.productId}`);
        return;
      }
      const product = matchingProducts[0];
      UOrgMaster.editElementInList(product.productProfiles, editedElem, operation, elemType);
      this.editState = EditState.UPDATE;
    } else if (elemType === ObjectTypes.ADMIN) {
      UOrgMaster.editElementInList(this.adminsV2, editedElem, operation, elemType);
      this.editState = EditState.UPDATE;
    } else if (elemType === ObjectTypes.USER_GROUP) {
      UOrgMaster.editElementInList(this.userGroups, editedElem, operation, elemType);
      this.editState = EditState.UPDATE;
    } else if (elemType === ObjectTypes.USER_GROUP_SHARE) {
      UOrgMaster.editElementInList(this.userGroups, editedElem, operation, elemType);
      this.editState = EditState.UPDATE;
    }
  }

  /**
   * removes product from product list
   * @param product
   */
  public removeProduct(product: UProduct): void {
    _.remove(this.products, (p: UProduct): boolean => p.id === product.id);
  }

  /**
   * remove the profile from the product profile list
   * @param profile
   */
  public removeProductProfile(profile: UProductProfile): void {
    _.forEach(this.products, (p) => {
      _.remove(p.productProfiles, (pp: UProductProfile): boolean => pp.id === profile.id);
    });
  }

  /**
   * remove the user group from the user group list
   * @param userGroup
   */
  public removeUserGroup(userGroup: UUserGroup): void {
    _.remove(this.userGroups, (u: UUserGroup): boolean => u.id === userGroup.id);
  }

  /**
   * remove admin from the admin list (for UAdmins)
   * TODO: rename this method to removeAdmin
   * @param admin
   */
  public removeAdminV2(admin: UAdmin): void {
    _.remove(this.adminsV2, (a: UAdmin): boolean => a.id === admin.id);
  }

  /**
   * Undo the create operation for a particular element.
   * That is, remove the newly created element from the org.
   * @param elem element - UUSER, UADMIN, UPRODUCT, UPRODUCTPROFILE, UORGANIZATION
   * @param elemType type of element
   */
  public undoCreate(elem: UBasicData, elemType: ObjectTypes): void {
    switch (elemType) {
      case ObjectTypes.PRODUCT:
        this.removeProduct(elem as UProduct);
        break;
      case ObjectTypes.PRODUCT_PROFILE:
        this.removeProductProfile(elem as UProductProfile);
        break;
      case ObjectTypes.ADMIN:
        this.removeAdminV2(elem as UAdmin);
        break;
      case ObjectTypes.USER_GROUP:
        this.removeUserGroup(elem as UUserGroup);
        break;
      case ObjectTypes.ORGANIZATION:
        log.error("Incorrect operation. Can not undo 'create org' operation");
        break;
      case ObjectTypes.COMPARTMENT_POLICY:
        log.error('Incorrect operation. Can not undo create policies. Policies can only be updated');
        break;
      default:
        return undefined;
    }
  }

  /**
   * Undo update operation on the product.
   * That is, replace the current edited product with original product in product list.
   * This method first removes the current edited product from the product list and
   * then adds the original product back to the list
   * @param editedProduct edited product that is currently present in the list
   * @param originalProduct original product that need to be set in the list
   */
  public undoUpdateProduct(editedProduct: UProduct, originalProduct: UProduct) {
    this.removeProduct(editedProduct);
    this.addProduct(originalProduct);
  }

  /**
   * Undo update operation on the product profile.
   * That is, replace the current edited profile with original profile in profile list.
   * This method first removes the current edited profile from the profile list and
   * then adds the original profile back to the list
   * @param pp
   * @param originalPP
   */
  public undoUpdateProductProfile(pp: UProductProfile, originalPP: UProductProfile) {
    this.removeProductProfile(pp);
    this.addOrgProductProfile(originalPP);
  }

  /**
   * undo update operation on the admin  list
   * replace the current edited admin with the original admin in the admin list
   * TODO: rename this method to undoUpdateAdmin
   * @param admin
   * @param originalAdmin
   */
  public undoUpdateAdminV2(admin: UAdmin, originalAdmin: UAdmin) {
    this.removeAdminV2(admin);
    this.editAdminV2(originalAdmin);
  }

  /**
   * undo update operation on the user group list
   * replace the current edited user group with the original user group in the user group list
   * @param ug
   * @param originalUG
   */
  public undoUpdateUserGroup(ug: UUserGroup, originalUG: UUserGroup) {
    this.removeUserGroup(ug);
    this.addUserGroup(originalUG);
  }

  /**
   * set the organization field to original org
   * @param originalOrg
   */
  public undoUpdateOrg(originalOrg: UOrg) {
    this.organization = _.cloneDeep(originalOrg);
  }

  /**
   * set the policies to original compartment policies
   * @param originalPolicies
   */
  public undoUpdatePolicies(originalPolicies: UCompartmentPolicies) {
    this.compartmentPolicy = new UCompartmentPolicies(originalPolicies);
  }

  /**
   * this undoes effect of an edit
   * That is, replace the edited element in the list with the original element.
   * @param elem
   * @param elemType
   * @param originalElem
   */
  public undoUpdate(elem: UBasicData, elemType: ObjectTypes, originalElem: UBasicData): void {
    switch (elemType) {
      case ObjectTypes.PRODUCT:
        this.undoUpdateProduct(new UProduct(elem as UProduct), new UProduct(originalElem as UProduct));
        break;
      case ObjectTypes.PRODUCT_PROFILE:
        this.undoUpdateProductProfile(elem as UProductProfile, originalElem as UProductProfile);
        break;
      case ObjectTypes.ADMIN:
        this.undoUpdateAdminV2(elem as UAdmin, originalElem as UAdmin);
        break;
      case ObjectTypes.USER_GROUP:
        this.undoUpdateUserGroup(elem as UUserGroup, originalElem as UUserGroup);
        break;
      case ObjectTypes.ORGANIZATION:
        this.undoUpdateOrg(originalElem as UOrg);
        break;
      case ObjectTypes.COMPARTMENT_POLICY:
        this.undoUpdatePolicies(originalElem as UCompartmentPolicies);
        break;
      default:
        return undefined;
    }
  }

  /**
   * undo delete: add the deleted element into the org
   * @param elem
   * @param elemType
   */
  public undoDelete(elem: UBasicData, elemType: ObjectTypes): void {
    switch (elemType) {
      case ObjectTypes.PRODUCT:
        this.addProduct(new UProduct(elem as UProduct));
        break;
      case ObjectTypes.PRODUCT_PROFILE:
        this.addOrgProductProfile(new UProductProfile(elem as UProductProfile));
        break;
      case ObjectTypes.ADMIN:
        {
          const admin = new UAdmin(elem as UAdmin);
          admin.clearAllEdits(); // clear all the 'REMOVE' operations for all the targets when reverting an admin delete
          this.editAdminV2(admin);
        }
        break;
      case ObjectTypes.USER_GROUP:
        this.addUserGroup(new UUserGroup(elem as UUserGroup));
        break;
      case ObjectTypes.ORGANIZATION:
        // nothing to do here. We do not actually delete the org (as in remove its references from parent org) till the job is submitted
        break;
      case ObjectTypes.COMPARTMENT_POLICY:
        log.error('Incorrect operation. Can not delete policies. Policies can only be updated');
        break;
      default:
        return undefined;
    }
  }

  /**
   * undo an edit.
   * For example, undo create product -> remove the newly created product from org
   * undo profile update -> replace the updated profile with original profile in the list
   * undo delete usergroup -> add back the deleted user group to the org
   * @param operation CREATE, UPDATE, DELETE
   * @param elem element
   * @param elemType type of element UPRODUCT, UPRODUCTPROFILE, UUSERGROUP
   * @param originalElem original element
   */
  public undo(
    operation: OrgOperation,
    elem: UBasicData,
    elemType: ObjectTypes,
    originalElem: UBasicData | undefined
  ): void {
    switch (operation) {
      case OrgOperation.CREATE:
        this.undoCreate(elem, elemType);
        break;
      case OrgOperation.UPDATE:
        if (originalElem !== undefined) {
          this.undoUpdate(elem, elemType, originalElem);
        }
        break;
      case OrgOperation.DELETE:
        this.undoDelete(elem, elemType);
        break;
      default:
    }
  }

  /**
   * Follow parent links to generate a pathname for the specified orgId.
   * If pathname can't be generated, return the org id itself.
   */
  public getPathname(): string {
    const ancestorsOfActiveOrg: any = OrgPickerController.getAncestorsForActiveOrg();
    const thisOrg =
      ancestorsOfActiveOrg && ancestorsOfActiveOrg[this.id] ? ancestorsOfActiveOrg[this.id] : this.organization;
    if (thisOrg) {
      const parent = this.getParentOrgMaster();
      if (parent) {
        return `${parent.getPathname()}/${thisOrg.name}`;
      }
      return thisOrg.name;
    }
    return this.id; // We couldn't find an org with this id.
  }

  /**
   * Reports whether this product is an allocated product who's parent did not allocate it (re-parented)
   * The execution of this method does require searching.
   */
  public isIndirectAllocation(product: UProduct): boolean {
    const directParentOrg: UOrgMaster | undefined = this.getParentOrgMaster();
    if (directParentOrg) {
      const directParentProductExists: boolean = _.some(
        directParentOrg.products,
        (eachProduct: UProduct): boolean => product.sourceProductId === eachProduct.id
      );
      return !directParentProductExists;
    }
    return false;
  }

  /**
   * Retrieves the highest priority LicenseTuple of all product licenses in the org hierarchy matching the given product.
   * LicenseTuple priority is in this order: NORMAL < NOTIFICATION < GRACE_PERIOD < POST_GRACE.
   *
   * @param rootProduct Product license to match against.
   *                This should either be the root product or one of the root most products (ex: selected product on Product Allocation page).
   * @returns Highest priority LicenseTuple or undefined if no LicenseTuple could be determined.
   */
  public getCompliancePhaseForProductHierarchy(rootProduct: UProduct): LicenseTuple | undefined {
    type OrgAndSourceProduct = { org: UOrgMaster; sourceProductId: string }; // pairs org with a sourceProductId
    let maxPriorityTuple: LicenseTuple | undefined;
    const { productGroupId } = rootProduct;
    // initialize with root org and source product id of selected product (usually the selected product is the root product).
    // note: if selected product does not belong to the root org, this means the root org does not have a product,
    // so the given root product, correctly, won't match against any product on the root org below (and its directly children products will be treated as purchases).
    let orgsAndSourceProduct: OrgAndSourceProduct[] = [{ org: this, sourceProductId: rootProduct.sourceProductId }];
    // Loop through all orgs in the hierarchy (we add child orgs to the array while we are iterating which causes breadth-first traversal)
    // Need to use for loop over _.forEach because we are adding elements to orgsAndSourceProduct array while iterating
    for (let index = 0; index < orgsAndSourceProduct.length; index++) {
      const orgAndSourceProduct = orgsAndSourceProduct[index];
      const { org, sourceProductId } = orgAndSourceProduct;
      // Find the product on the current org that matches the given parent product or matches selected product (productGroupId) (if purchase).
      // Note: If the current org has no products, then the children orgs will have no sourceProductId to match against since there cannot be any child allocations,
      // but purchases of child orgs will still be able to match using productGroupId.
      const prod: UProduct | undefined = _.find(
        org.products,
        (p: UProduct): boolean =>
          // Only check against sourceProductId if the product is not a purchase.
          // Given sourceProductId might be empty which will match againts any purchases in the org since purchase have empty sourceProductIds (use productGroupId for purchases)
          (!p.isPurchase() && p.sourceProductId === sourceProductId) || p.productGroupId === productGroupId
      );
      if (prod) {
        // matching product found for org, check LicenseTuple
        maxPriorityTuple = this.getHigherPriorityTuple(prod, maxPriorityTuple);
      }
      // retrieve the children of the current org to provide more orgs to iterate through
      orgsAndSourceProduct = orgsAndSourceProduct.concat(
        _.map(
          org.getChildren(),
          (childOrg: UOrgMaster): OrgAndSourceProduct => ({
            org: childOrg,
            sourceProductId: prod ? prod.id : '', // If a matching product was found this will be the sourceProductId for its children
          })
        )
      );
    }
    return maxPriorityTuple;
  }

  /**
   * For a earliest expiring license tuple in a product (prodTuple), check if prodTuple.priority > maxPriorityTuple.priority
   * @returns license tuple with higher priority
   */
  private getHigherPriorityTuple = (prod: UProduct, maxPriorityTuple?: LicenseTuple): LicenseTuple | undefined => {
    const prodTuple = prod.getEarliestExpiringTuple();
    if (prodTuple && (_.isNil(maxPriorityTuple) || prodTuple.priority() > maxPriorityTuple.priority())) {
      // prodTuple is of higher priority if
      // 1. prodTuple exist (not undefined) and
      // 2. maxPriorityTuple is not populated or prodTuple.priority > maxPriorityTuple.priority
      return prodTuple;
    }
    return maxPriorityTuple;
  };

  /**
   * For the all licenses in the org, get the compliance tuple with higest priority
   */
  public getHigestPriorityLicenseTupleInTheOrg = (): LicenseTuple | undefined => {
    let maxPriorityTuple: LicenseTuple | undefined;
    _.forEach(this.products, (prod: UProduct): void => {
      maxPriorityTuple = this.getHigherPriorityTuple(prod, maxPriorityTuple);
    });
    return maxPriorityTuple;
  };
}
