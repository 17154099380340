import * as _ from 'lodash';
import * as log from 'loglevel';
import OrgPickerController from '../organization/OrgPickerController';

import { ObjectTypes, OrgOperation, TEMP_ID_PREFIX } from './OrgMaster';

import { UOrgMaster } from './UOrgMaster';
import { UOrgData } from './UOrg';

import BanyanCompartmentAPI, {
  OrgReadOnlyMap,
  OrgIdUProductsMap,
  ProductMapResponse,
  PoliciesBulkResponse,
  ProfilesMap,
  UserGroupProfilesMap,
} from '../../providers/BanyanCompartmentAPI';
import { UProduct, UProductData } from './UProduct';
import { UResourceData } from './UResource';
import { UUserGroup, UUserGroupData, UUserGroupLicenseGroup } from './UUserGroup';
import { UAdmin, UAdminData } from './UAdmin';
import { UCompartmentPolicies, UCompartmentPoliciesData } from './UCompartmentPolicy';
import { UProductProfile, UProductProfileData } from './UProductProfile';
import { UDomain, UDomainData } from './UDomain';
import CountData from '../utils/CountData';
import Utils from '../utils/Utils';
import HierarchyManager from '../organization/HierarchyManager';
import OrgTreeCache from '../../Compartments/OrgTree/OrgTreeCache';
import { CommandService } from '../Commands/CommandService';
import { UDirectory, UDirectoryData } from './UDirectory';
import ContractJIL, { ContractJILData } from './ContractsJIL';
import JilAPIProvider from '../../providers/JilAPIProvider';
import { ResponseWithCounts } from '../providerUtils/ProviderUtil';

/* eslint-disable no-param-reassign */

type ResolveCallback = () => void;
type RejectCallback = (error: string) => void;

/**
 * OrgMasterTree is used to gather all the org data from the back-end and then organize into the data model
 * in a way that can be used to generate the ui.
 * LoadOrgDataService is a singleton.
 */
export class LoadOrgDataService {
  private idField: string = ''; // org id of the root org of this org master tree
  private static model: LoadOrgDataService | null; // Singleton instance of the OrgMasterTree.  Null means an instance hasn't been initialized
  private static promise: Promise<void> | undefined;
  private static MAX_ORG_IDS = 1000;
  private static MAX_PRODUCT_IDS = 1000; // This is only used by the "loadProfilesForAllProducts" method
  private static MAX_USER_GROUP_IDS = 1000; // This is only used by the "loadProfilesForLoadedUserGroups" method
  /**
   * Constructs an OrgMasterTree from the orgId of the root org.
   * This method is private because OrgMasterTree is a singleton and can only be constructed internally.
   */
  private constructor(orgId: string) {
    LoadOrgDataService.clear(); // remove existing model, if any
    this.initModel(orgId); // initialize parameters
  }

  // clear the existing data model
  public static clear(): void {
    HierarchyManager.clear();
    LoadOrgDataService.model = null;
    LoadOrgDataService.promise = undefined;
  }

  /**
   * Get root org id
   */
  get id(): string {
    return this.idField;
  }

  // ///////////////////////////////////////////////////////

  /**
   * Retrieves the singleton instance of this org master tree.
   * If the data has not yet been loaded, this method will load the data.
   * If the org master tree root org id no longer matches the active org id (org has changed),
   * this method will re-load the data.
   * In all other cases, this method will simply return the org master tree instance without loading data.
   *
   * OrgMasterTree data wont be updated unless the update param is set to true.
   * This is done in Organization and Product Allocation pages (on componentDidMount after initial load or org change via the org picker)
   * Without the update param, any call to get() method can update model.id without waiting for orgs to load which can lead to incorrect check for willRefresh()
   * Hence, the model.id should only be updated when the call to get() waits for the orgs to load (else it can lead to inconsistent bugs)
   *
   * returns undefined when user has no orgs (for AdobeAgent case)
   */
  static get(): LoadOrgDataService | undefined {
    if (!OrgPickerController.getActiveOrg()) {
      return undefined;
    }
    return LoadOrgDataService.model as LoadOrgDataService;
  }

  /**
   * This method initializes or updates the the entire tree hierarchy.
   * It is usually done on the first load or when the job completes.
   */
  static async initializeOrUpdateOrgMasterTree(): Promise<void> {
    if (LoadOrgDataService.promise === undefined || LoadOrgDataService.willRefresh()) {
      LoadOrgDataService.promise = new Promise<void>(
        async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
          try {
            OrgTreeCache.clear(); // first clear out the previous org tree and then initialize a new one
            CommandService.initializeFromSessionStorage(); // Initialize all the edits from session storage
            // OrgPickerController.getOrgDataList() will be empty if user is Adobe Agent with no global admin permission
            LoadOrgDataService.model = new LoadOrgDataService(OrgPickerController.getActiveOrgId() as string);
            await LoadOrgDataService.addOrgs();
            CommandService.addParentChildRelationshipForNewOrgs();
            const allOrgs = HierarchyManager.getOrgMasters();
            for (let i = 0; i < allOrgs.length; i++) {
              const org = allOrgs[i];
              CommandService.redoAllEditsInOrg(org);
            }
            HierarchyManager.setSelectedOrg();
            resolve();
          } catch (error) {
            reject(error);
          }
        }
      );
    }
    return LoadOrgDataService.promise;
  }

  /**
   * Reports whether the OrgMasterTree needs a refresh or will call refresh
   * when using get to retrieve the OrgMasterTree singleton.
   * This occurs when either there is no loaded data or if the root org changes.
   */
  static willRefresh(): boolean {
    if (_.isEmpty(OrgPickerController.getOrgDataList())) {
      return false; // no orgs for Adobe Agent case
    }
    return !LoadOrgDataService.model || LoadOrgDataService.model.idField !== OrgPickerController.getActiveOrgId();
  }

  // /////////////////////////////////////////////////// private

  /**
   * Initialize the tree structure. Add parent - child relationships.
   */
  private static async addOrgs(): Promise<void> {
    const orgs: UOrgData[] = await BanyanCompartmentAPI.getHierarchy(OrgPickerController.getActiveOrgId() as string);
    for (let i = 0; i < orgs.length; i++) {
      const newOrgMaster = new UOrgMaster(orgs[i]);
      HierarchyManager.addOrg(newOrgMaster);
    }
  }

  /**
   * Fetch the org details and update the org details property on the UOrgMaster object
   * @param orgId string
   * @param signal AbortSignal
   */
  public static async loadOrgDetails(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.orgDetailsLoaded) {
      return; // nothing to do
    }

    if (org.promiseOrgDetailsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return. NOTE this is to avoid making duplicate calls to the org details API on each call to loadOrgDetails()
      try {
        await org.promiseOrgDetailsLoaded;
        return;
      } catch (e) {
        // Try again if previous call resulted in an error.
        org.promiseOrgDetailsLoaded = null;
      }
    }

    if (signal && signal.aborted) {
      return;
    }

    org.promiseOrgDetailsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const orgDetails: UOrgData = await BanyanCompartmentAPI.getOrgDetails(orgId, signal);
          this.setOrgDetails(orgDetails);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseOrgDetailsLoaded;
  }

  public static async loadContracts(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
    if (org === undefined || org.contractsLoaded) {
      return; // nothing to do
    }

    if (org.promiseContractsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return. NOTE this is to avoid making duplicate calls to the contracts API on each call to loadContracts()
      try {
        await org.promiseContractsLoaded;
        return;
      } catch (e) {
        // Try again if previous call resulted in an error.
        org.promiseContractsLoaded = null;
      }
    }

    if (signal && signal.aborted) {
      return;
    }

    org.promiseContractsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const contractList: ContractJILData[] = await JilAPIProvider.getContracts(orgId, signal);
          _.forEach(contractList, (contract: ContractJILData): void => {
            org.contracts.push(new ContractJIL(contract));
          });
          org.contractsLoaded = true;
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseContractsLoaded;
  }

  /**
   * Get org details in bulk
   * @param allOrgIds orgs to fetch
   * @param signal Abort signal
   */
  public static async loadOrgDetailsBulk(allOrgIds: string[], signal?: AbortSignal): Promise<void> {
    // filter out all orgs for which org details is already fetched or the org is not valid
    const orgsNotFetched = _.filter(allOrgIds, (orgId: string): boolean => {
      if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
        return false;
      }
      const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
      if (org === undefined) {
        return false; // nothing to do
      }
      return !org.orgDetailsLoaded;
    });

    // create chunks of MAX_ORG_IDS
    const chunkedOrgIds: string[][] = _.chunk(orgsNotFetched, LoadOrgDataService.MAX_ORG_IDS);

    for (let i = 0; i < chunkedOrgIds.length; i++) {
      const allOrgDetails: UOrgData[] = await BanyanCompartmentAPI.getOrgDetailsBulk(chunkedOrgIds[i], signal);
      _.forEach(allOrgDetails, (orgDetails: UOrgData): void => this.setOrgDetails(orgDetails));
    }
  }

  public static async loadOrgReadOnlyBulk(
    parentOrgId: string,
    allOrgIds: string[],
    signal?: AbortSignal
  ): Promise<void> {
    // filter out all orgs for which read only is already fetched or the org is not valid
    const orgsNotFetched = _.filter(allOrgIds, (orgId: string): boolean => {
      if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
        return false;
      }
      const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
      if (org === undefined) {
        return false; // nothing to do
      }
      return !org.orgDetailsLoaded && !org.readOnlyLoaded;
    });

    // create chunks of MAX_ORG_IDS
    const chunkedOrgIds: string[][] = _.chunk(orgsNotFetched, LoadOrgDataService.MAX_ORG_IDS);

    for (let i = 0; i < chunkedOrgIds.length; i++) {
      const orgReadOnlyMap: OrgReadOnlyMap = await BanyanCompartmentAPI.getOrgReadOnlyBulk(
        parentOrgId,
        chunkedOrgIds[i],
        signal
      );
      _.forEach(chunkedOrgIds[i], (orgIdFromChunk) => {
        const org = HierarchyManager.getOrg(orgIdFromChunk);
        if (org !== undefined) {
          org.organization.readOnly = orgReadOnlyMap[orgIdFromChunk];
          org.readOnlyLoaded = true;
        }
      });
    }
  }

  public static setOrgDetails(uOrgData: UOrgData): void {
    if (uOrgData.id) {
      const updateOrg = (orgToUpdate: UOrgMaster | undefined): void => {
        if (orgToUpdate === undefined) {
          log.error(`Invalid org with id ${uOrgData.id}`);
          return; // nothing to do
        }
        // NOTE: The org-details api is only required to populate the fields that are not returned from /hierarchy call.
        // Therefore, fields like id, name, parentOrgId are not assigned here. If in future org-details API
        // returns more fields, feel free to assign each field separately rather than replacing the entire UOrg object.
        // If the entire UOrg object is replaced, it can possibly overwrite user's edits. So please refrain from doing so.
        orgToUpdate.organization.countryCode = uOrgData.countryCode as string;
        orgToUpdate.organization.readOnly = uOrgData.readOnly as boolean;
        orgToUpdate.organization.type = uOrgData.type;
        orgToUpdate.orgDetailsLoaded = true;
      };
      const org: UOrgMaster | undefined = HierarchyManager.getOrg(uOrgData.id);
      updateOrg(org);
    } else {
      log.error(`Invalid org data: ${uOrgData}`);
    }
  }

  /**
   * Fetch the product list and update the products in the UOrgMaster object
   * @param orgId string
   * @param signal AbortSignal
   */
  public static async loadProducts(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.productsLoaded) {
      return; // if original org does not exists, then we donot have to load the products from the backend
    }

    if (org.promiseProductLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return. NOTE this is to avoid making duplicate calls to the products API on each call to loadProducts()
      try {
        await org.promiseProductLoaded;
        return;
      } catch (e) {
        // Try again if previous call resulted in an error.
        org.promiseProductLoaded = null;
      }
    }

    if (signal && signal.aborted) {
      return;
    }

    org.promiseProductLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const productList: UProductData[] = await BanyanCompartmentAPI.getProducts(orgId, signal);
          const addProductsToOrg = (orgToUpdate: UOrgMaster): void => {
            _.forEach(productList, (productData: UProductData): void => {
              orgToUpdate.products.push(new UProduct(productData));
              if (productData.id !== undefined) {
                CommandService.applyEditForSingleElement(org, productData.id, ObjectTypes.PRODUCT);
              }
            });
            orgToUpdate.productsLoaded = true;
          };
          addProductsToOrg(org);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseProductLoaded;
  }

  /**
   * Assemble offer and merchandising data to products and resources from shared data.
   * Note: The provided productMapResponse's productMap is modified and returned by this method (it is a reference not a copy)
   * @param productMapResponse a ProductMapResponse from the hierarchy bulk products api.  The productMap in the ProductMapResponse is modified with the shared data.
   * @returns the product map with the assembled offer and merchandise data from the productMap contained in the ProductMapResponse
   */
  private static assembleProductsAndResources(productMapResponse: ProductMapResponse): OrgIdUProductsMap {
    _.forEach(productMapResponse.productMap, (productsInOrg: UProductData[]): void => {
      _.forEach(productsInOrg, (uProductData: UProductData): void => {
        if (uProductData.offerId) {
          const sharedProductData: UProductData | undefined =
            productMapResponse.sharedProductData[uProductData.offerId];
          if (sharedProductData) {
            uProductData.longDescription = sharedProductData.longDescription;
            uProductData.icons = sharedProductData.icons;
          }
          if (uProductData.resources) {
            _.forEach(uProductData.resources, (uResourceData: UResourceData): void => {
              if (uResourceData.code && sharedProductData.resources) {
                const sharedResource: UResourceData | undefined = _.find(
                  sharedProductData.resources,
                  (res: UResourceData): boolean => res.code === uResourceData.code
                );
                if (sharedResource) {
                  uResourceData.shortDescription = sharedResource.shortDescription;
                  uResourceData.longDescription = sharedResource.longDescription;
                  uResourceData.enterpriseName = sharedResource.enterpriseName;
                  uResourceData.icons = sharedResource.icons;
                }
              }
            });
          }
        } else {
          log.warn(
            `no offerId available for product with id ${uProductData.id} with name ${uProductData.name} on org ${uProductData.orgId}`
          );
        }
      });
    });
    return productMapResponse.productMap;
  }

  /**
   * Fetch the product list and update the products property for multiple requested
   * @param parentOrgId parent org id of the parent for the entire hierarchy for all the orgs in orgIds
   * @param orgIds list of requested org ids
   * @param signal AbortSignal
   */
  public static async loadHierarchyProductsBulk(
    parentOrgId: string,
    orgIds: string[],
    signal?: AbortSignal
  ): Promise<void> {
    const orgProductsToFetch: string[] = _.filter(orgIds, (orgId: string): boolean => {
      if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
        return false;
      }
      const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
      if (org === undefined) {
        return false;
      }
      return !org.productsLoaded;
    });

    const chunkedOrgIds: string[][] = _.chunk(orgProductsToFetch, LoadOrgDataService.MAX_ORG_IDS);

    for (let i = 0; i < chunkedOrgIds.length; i++) {
      const orgIdsGroup: string[] = chunkedOrgIds[i];
      const productMapResponse: ProductMapResponse = await BanyanCompartmentAPI.getHierarchyProductsBulk(
        parentOrgId,
        orgIdsGroup,
        signal
      );
      const productsGroupedByOrg: OrgIdUProductsMap =
        LoadOrgDataService.assembleProductsAndResources(productMapResponse);
      _.forEach(orgIdsGroup, (orgId: string): void => {
        const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
        if (org === undefined) {
          return;
        }
        const productsForOrg: UProductData[] = productsGroupedByOrg[orgId];
        if (!_.isEmpty(productsForOrg)) {
          org.products = org.products.concat(_.map(productsForOrg, (p: UProductData): UProduct => new UProduct(p)));
          _.forEach(org.products, (product) => {
            if (product.id !== undefined) {
              CommandService.applyEditForSingleElement(org, product.id, ObjectTypes.PRODUCT);
            }
          });
        }
        org.productsLoaded = true;
      });
    }
  }

  /**
   * Fetch the profile list for the given product and update the productProfiles property on the given UProduct object
   * @param orgId
   * @param productId
   * @param loadAllPages load all the pages of profiles
   * @param signal AbortSignal
   */
  public static async loadProfiles(
    orgId: string,
    productId: string,
    loadAllPages?: boolean,
    signal?: AbortSignal
  ): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX) || _.startsWith(productId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org or new product
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      return; // nothing to do
    }

    if (!org.productsLoaded) {
      // load products if not loaded already
      await this.loadProducts(org.id);
    }

    const product = org.getProduct(productId);
    if (!product) {
      return; // nothing to do if product is undefined
    }
    if (product.profilesLoaded) {
      return; // nothing to do
    }
    if (product.promiseProfilesLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await product.promiseProfilesLoaded;
        return;
      } catch (e) {
        // Try again if previous call resulted in an error.
        product.promiseProfilesLoaded = null;
      }
    }

    // dont initialize if aborted
    if (signal && signal.aborted) {
      return;
    }

    product.promiseProfilesLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const response: ResponseWithCounts = await BanyanCompartmentAPI.getProfiles(
            orgId,
            productId,
            {
              pageSize: !loadAllPages ? UProduct.PROFILE_PAGE_SIZE : undefined,
              pageNumber: !loadAllPages ? 0 : undefined,
            },
            signal
          );
          const addProdProfilesToOrgAndProduct = (orgToUpdate: UOrgMaster, prodToUpdate: UProduct): void => {
            prodToUpdate.totalProfileCount = response.totalCount;
            prodToUpdate.totalProfilePageCount = response.pageCount;
            const profileList: UProductProfileData[] = response.data;
            _.forEach(profileList, (profile: UProductProfileData): void => {
              if (profile.id) {
                orgToUpdate.addOrgProductProfile(new UProductProfile(profile));
                CommandService.applyEditForSingleElement(org, profile.id, ObjectTypes.PRODUCT_PROFILE);
              }
            });
            prodToUpdate.profilesLoaded = true;
          };
          addProdProfilesToOrgAndProduct(org, product);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return product.promiseProfilesLoaded;
  }

  /**
   * Load the profiles for all products on an org.
   * Note: This method can be removed if the admins table moves loading of profiles into the dialogs.
   * @param orgId org with products to load profiles for.
   * @param signal  abort signal
   */
  static async loadProfilesForAllProducts(orgId: string, signal?: AbortSignal): Promise<void> {
    // nothing to load if org is new
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return;
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
    // nothing to load if org is non existent
    if (org === undefined) {
      return;
    }

    // retrieve all products that have active promises to load profiles
    const productsToWaitFor: UProduct[] = _.filter(
      org.products,
      (product: UProduct): boolean => !product.profilesLoaded && product.promiseProfilesLoaded !== null
    );

    // wait for promises to resolve for all products that have active promises to load profiles.
    // even if there are promise to wait for, the code continues on  as there may be other products that have not loaded profiles.
    for (let promiseIndex = 0; promiseIndex < productsToWaitFor.length; promiseIndex++) {
      const productToWaitFor: UProduct = productsToWaitFor[promiseIndex];
      try {
        await productToWaitFor.promiseProfilesLoaded;
      } catch (e) {
        // try again if previous call resulted in error
        productToWaitFor.promiseProfilesLoaded = null;
      }
    }

    // At this point one of the following may have happened
    // * Some products have previously loaded their profiles, but we continue on to load the profiles for the rest of the unloaded products.
    // * The previous requests were aborted, so we will try again to load the profiles for the unloaded products

    const productsToLoad: UProduct[] = _.filter(org.products, (product: UProduct): boolean => !product.profilesLoaded);
    const productIdsToLoad: string[] = _.map(productsToLoad, (product: UProduct): string => product.id);
    // both chunkedProductsToLoad and chunkedProductIds should be the same length
    const chunkedProductsToLoad: UProduct[][] = _.chunk(productsToLoad, LoadOrgDataService.MAX_PRODUCT_IDS);
    const chunkedProductIds: string[][] = _.chunk(productIdsToLoad, LoadOrgDataService.MAX_PRODUCT_IDS);

    if (signal && signal.aborted) {
      return;
    }

    // don't bother loading anything if the products are already loaded
    if (_.isEmpty(chunkedProductIds)) {
      return;
    }
    const promise: Promise<void> = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          // load profiles in chunks so we don't go over the limits for banyansvc
          for (let chunkIndex = 0; chunkIndex < chunkedProductIds.length; chunkIndex++) {
            const profilesMap: ProfilesMap = await BanyanCompartmentAPI.getProfilesBulk(
              orgId,
              chunkedProductIds[chunkIndex],
              signal
            );
            // apply profiles data to the corresponding product
            _.forEach(chunkedProductsToLoad[chunkIndex], (product: UProduct): void => {
              const profiles: UProductProfileData[] | undefined = profilesMap[product.id];
              if (profiles) {
                // add the profiles to products
                product.totalProfileCount = profiles.length; // #loaded profiles is the totalProfileCount because we load all profiles
                product.totalProfilePageCount = 1; // we aren't paginated so we technically are only loading 1 page of data
                _.forEach(profiles, (profile: UProductProfileData): void => {
                  if (profile.id) {
                    org.addOrgProductProfile(new UProductProfile(profile));
                    CommandService.applyEditForSingleElement(org, profile.id, ObjectTypes.PRODUCT_PROFILE);
                  }
                });
                product.profilesLoaded = true;
              }
            });
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    _.forEach(productsToLoad, (product: UProduct): void => {
      product.promiseProfilesLoaded = promise;
    });
    return promise;
  }

  /**
   * load the next page of profiles
   * @param productId
   * @param orgId
   */
  public static async loadNextPageProfile(productId: string, orgId: string) {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.isNewOrg()) {
      return;
    }
    const product = org.getProduct(productId);
    if (product === undefined) {
      return;
    }
    if (product.currentProfilePageIndex + 1 >= product.totalProfilePageCount) {
      return;
    }
    const response: ResponseWithCounts = await BanyanCompartmentAPI.getProfiles(orgId, productId, {
      pageSize: UProduct.PROFILE_PAGE_SIZE,
      pageNumber: product.currentProfilePageIndex + 1,
    });
    const nextPageProfiles: UProductProfileData[] = response.data;
    product.currentProfilePageIndex += 1;

    _.forEach(nextPageProfiles, (profileData: UProductProfileData): void => {
      if (profileData.id) {
        org.addOrgProductProfile(new UProductProfile(profileData));
        CommandService.applyEditForSingleElement(org, profileData.id, ObjectTypes.PRODUCT_PROFILE);
      }
    });
  }

  /**
   * load the next page of user groups
   * @param orgId
   */
  public static async loadNextPageUserGroup(orgId: string) {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.isNewOrg()) {
      return;
    }
    if (org.currentUserGroupPageIndex + 1 >= org.totalUserGroupCount) {
      return;
    }
    // get next list of user groups
    const response: ResponseWithCounts = await BanyanCompartmentAPI.getUserGroups(orgId, {
      pageSize: UOrgMaster.USERGROUP_PAGE_SIZE,
      pageNumber: org.currentUserGroupPageIndex + 1,
    });
    const nextPageUserGroups: UUserGroupData[] = response.data;
    // increment the last page loaded
    org.currentUserGroupPageIndex += 1;
    // add the next page of user groups to the org
    _.forEach(nextPageUserGroups, (userGroupData: UUserGroupData): void => {
      if (userGroupData.id) {
        org.addUserGroup(new UUserGroup(userGroupData));
        CommandService.applyEditForSingleElement(org, userGroupData.id, ObjectTypes.USER_GROUP);
      }
    });
  }

  /**
   * load next page of admins (in a format where there is 1 admin object per user with all roles and targets).
   * TODO: rename this method to "loadNextPageAdmins".
   */
  public static async loadNextPageAdminsV2(orgId: string) {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
    if (org === undefined || org.isNewOrg()) {
      return;
    }
    if (org.currentAdminPageIndex + 1 >= org.totalAdminCount) {
      return;
    }
    // get next list of admins
    const response: ResponseWithCounts = await BanyanCompartmentAPI.getAdminsV2(orgId, {
      pageSize: UOrgMaster.ADMIN_PAGE_SIZE,
      pageNumber: org.currentAdminPageIndex + 1,
    });
    // increment the last page loaded
    org.currentAdminPageIndex += 1;
    // add the next page of admins to the org
    this.addAdminsToOrgV2(org, response.data, response.pageCount);
  }

  /**
   * load admins for the given user group (in a format where there is 1 admin object per user with all roles and targets).
   * TODO: rename this method to "loadAdminForUserGroup".
   * @param orgId id of org
   * @param userGroupId id of user group
   */
  public static async loadAdminForUserGroupV2(orgId: string, userGroupId: string): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX) || _.startsWith(userGroupId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org or user group
    }
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
    // if original org does not exist, then the org in context is a new org, hence there wont be any admins to load for a new org
    if (!org) {
      return;
    }
    const userGroup = _.find(org.userGroups, (ug) => ug.id === userGroupId);
    // if user group does not exist in the original org, then this user group is a new user group, hence we dont need to load admins for this user group
    if (!userGroup || userGroup.adminsLoaded) {
      return;
    }
    const ugAdmins: UAdminData[] = await BanyanCompartmentAPI.getAdminsForUserGroupV2(orgId, userGroupId); // load admins for user group
    const currentUgAdmins: UAdmin[] = org.getAdminsForUserGroup(userGroupId); // retrieve admins already loaded for user group
    // add the loaded admins that aren't already loaded for user group to the user group
    _.forEach(ugAdmins, (adminData) => {
      if (!_.find(currentUgAdmins, (eachAdmin: UAdmin): boolean => eachAdmin.id === adminData.id)) {
        org.editAdminV2(new UAdmin(adminData), OrgOperation.CREATE);
        CommandService.applyEditForSingleElement(org, adminData.id as string, ObjectTypes.ADMIN);
      }
    });
    userGroup.adminsLoaded = true;
  }

  /**
   * Fetch the user group list and update the userGroups property on the UOrgMaster object
   * @param orgId string
   * @param loadAllPages boolean load all the user groups
   * @param signal AbortSignal
   */
  public static async loadUserGroups(
    orgId: string,
    loadAllPages: boolean = false,
    signal?: AbortSignal
  ): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.userGroupsLoaded) {
      return; // nothing to do
    }

    if (org.promiseUserGroupLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseUserGroupLoaded;
        return;
      } catch (e) {
        // Try again if previous call resulted in an error.
        org.promiseUserGroupLoaded = null;
      }
    }

    if (signal && signal.aborted) {
      return;
    }

    org.promiseUserGroupLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const response: ResponseWithCounts = await BanyanCompartmentAPI.getUserGroups(
            orgId,
            {
              pageSize: !loadAllPages ? UOrgMaster.USERGROUP_PAGE_SIZE : undefined,
              pageNumber: !loadAllPages ? 0 : undefined,
            },
            signal
          );
          const addUserGroupsToOrg = (orgToUpdate: UOrgMaster): void => {
            orgToUpdate.totalUserGroupPageCount = response.pageCount;
            const userGroupList: UUserGroupData[] = response.data;
            _.forEach(userGroupList, (userGroupData: UUserGroupData): void => {
              orgToUpdate.addUserGroup(new UUserGroup(userGroupData));
              CommandService.applyEditForSingleElement(orgToUpdate, userGroupData.id as string, ObjectTypes.USER_GROUP);
            });
            orgToUpdate.userGroupsLoaded = true;
          };
          addUserGroupsToOrg(org);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseUserGroupLoaded;
  }

  /**
   * Loads profiles for a user group
   * Note: This method assumes the specified user group is already loaded.
   *
   * @param orgId org the requested user group belongs to
   * @param userGroupId user group to load profiles for
   * @param signal  abort signal
   */
  public static async loadProfilesForUserGroup(
    orgId: string,
    userGroupId: string,
    signal?: AbortSignal
  ): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX) || _.startsWith(userGroupId, TEMP_ID_PREFIX)) {
      return; // nothing to load for new org or new user group
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      return; // nothing to do
    }

    const userGroup: UUserGroup | undefined = org.getUserGroup(userGroupId);
    if (!userGroup) {
      return; // nothing to do if user group is undefined
    }
    if (userGroup.profilesLoaded) {
      return; // nothing to do (profiles already loaded)
    }

    if (userGroup.promiseProfilesLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await userGroup.promiseProfilesLoaded;
        return;
      } catch (e) {
        // Try again if previous  call resulted in an error.
        userGroup.promiseProfilesLoaded = null;
      }
    }

    // don't initialize if aborted
    if (signal && signal.aborted) {
      return;
    }

    userGroup.promiseProfilesLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const userGroupProfiles: UUserGroupLicenseGroup[] = await BanyanCompartmentAPI.getProfilesForUserGroup(
            orgId,
            userGroupId,
            signal
          );
          userGroup.profiles = userGroupProfiles;
          userGroup.profilesLoaded = true;
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return userGroup.promiseProfilesLoaded;
  }

  /**
   * Loads the profiles for all the loaded user groups on an org.
   * Note: This method can be removed if loading profiles for multiple user groups at once is no longer needed.
   *       (Probably if user groups table no longer needs to display profiles on list items)
   * @param orgId org with user groups to load profiles for.
   * @param signal abort signal
   */
  public static async loadProfilesForLoadedUserGroups(orgId: string, signal?: AbortSignal): Promise<void> {
    // nothing to load if org is new
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return;
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
    // nothing to load if org is non existent
    if (org === undefined) {
      return;
    }

    // retrieve all user groups that have active promises to load profiles
    const userGroupsToWaitFor: UUserGroup[] = _.filter(
      org.userGroups,
      (userGroup: UUserGroup): boolean => !userGroup.profilesLoaded && userGroup.promiseProfilesLoaded !== null
    );

    // wait for promises to resolve for all user groups that have active promises to load profiles.
    // even if there are promises to wait for, the code continues on as there may be other user groups that have not loaded profiles.
    for (let promiseIndex = 0; promiseIndex < userGroupsToWaitFor.length; promiseIndex++) {
      const userGroupToWaitFor: UUserGroup = userGroupsToWaitFor[promiseIndex];
      try {
        await userGroupToWaitFor.promiseProfilesLoaded;
      } catch (e) {
        // try again if previous call resulted in error
        userGroupToWaitFor.promiseProfilesLoaded = null;
      }
    }

    // At this point one of the following may have happened
    // * Some user groups have previously loaded their profiles, but we continue on to load the profiles for the rest of the unloaded user groups.
    // * The previous requests were aborted, so we will try again to load the profiles for the unloaded user groups.

    // Retrieve all user groups that have not loaded profiles.
    // We are filtering this list again from all user groups on the org because some of the promises processed above may have been aborted and still need to be loaded.
    const userGroupsToLoad: UUserGroup[] = _.filter(
      org.userGroups,
      (userGroup: UUserGroup): boolean => !userGroup.profilesLoaded
    );
    const userGroupIdsToLoad: string[] = _.map(userGroupsToLoad, (userGroup: UUserGroup): string => userGroup.id);
    // chunkedUserGroupsToLoad and chunkedUesrGroupIds should have the same length
    const chunkedUserGroupsToLoad: UUserGroup[][] = _.chunk(userGroupsToLoad, LoadOrgDataService.MAX_USER_GROUP_IDS);
    const chunkedUserGroupIds: string[][] = _.chunk(userGroupIdsToLoad, LoadOrgDataService.MAX_USER_GROUP_IDS);

    if (signal && signal.aborted) {
      return;
    }

    // don't bother loading anything if the user groups are already loaded
    if (_.isEmpty(chunkedUserGroupIds)) {
      return;
    }
    const promise: Promise<void> = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          // load profiles for user groups in chunks so we don't go over the limits for banyansvc
          for (let chunkIndex = 0; chunkIndex < chunkedUserGroupIds.length; chunkIndex++) {
            const userGroupProfilesMap: UserGroupProfilesMap = await BanyanCompartmentAPI.getProfilesForUserGroups(
              orgId,
              chunkedUserGroupIds[chunkIndex],
              signal
            );
            // apply the profiles data to the corresponding user group
            _.forEach(chunkedUserGroupsToLoad[chunkIndex], (userGroup: UUserGroup): void => {
              const profiles: UUserGroupLicenseGroup[] | undefined = userGroupProfilesMap[userGroup.id];
              if (profiles) {
                userGroup.profiles = profiles;
                userGroup.profilesLoaded = true;
              }
            });
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    _.forEach(userGroupsToLoad, (userGroup: UUserGroup): void => {
      userGroup.promiseProfilesLoaded = promise;
    });
    return promise;
  }

  /**
   * Adds list of admins to org (in a format where there is 1 admin object per user with all roles and targets).
   * TODO: rename this method to "addAdminsToOrg".
   * @param orgToUpdate org to add admins to
   * @param adminList list of admins to add to the org (should represent 1 page)
   * @param pageCount number of admins in a page retrieved
   */
  private static addAdminsToOrgV2 = (orgToUpdate: UOrgMaster, adminList: UAdminData[], pageCount: number): void => {
    orgToUpdate.totalAdminPageCount = pageCount;
    _.forEach(adminList, (admin: UAdminData): void => {
      if (admin.id) {
        orgToUpdate.editAdminV2(new UAdmin(admin), OrgOperation.CREATE);
        CommandService.applyEditForSingleElement(orgToUpdate, admin.id as string, ObjectTypes.ADMIN);
      }
    });
    orgToUpdate.adminsLoaded = true;
  };

  /**
   * Fetch the admin list for the given org and update the admins property on the given UOrgMaster object (in a format where there is 1 admin object per user with all roles and targets).
   * Note: products, profiles and user groups should be loaded before admins are assigned to their targets.
   * Because as per the current admin model, each admin object is assigned to its own target.
   * For example, the product profile admin is nested within its specific product profile
   *
   * NOTE IMPORTANT: when 'loadAllAdminPages' = true, currently only first 1000 admins are loaded.
   * TODO: rename this method to "loadAdmins".
   *
   * @param orgId string
   * @param searchQuery string
   * @param loadAllAdminPages when set to true load first 1000 admins in the org
   * @param signal AbortSignal
   */
  public static async loadAdminsV2(
    orgId: string,
    loadAllAdminPages?: boolean,
    searchQuery?: string,
    signal?: AbortSignal
  ): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.adminsLoaded) {
      return; // nothing to do
    }

    if (org.promiseAdminsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseAdminsLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseAdminsLoaded = null;
      }
    }

    if (signal && signal.aborted) {
      return;
    }

    org.promiseAdminsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          // load all admins or the first page of admins
          const response: ResponseWithCounts = await BanyanCompartmentAPI.getAdminsV2(
            org.organization.id,
            {
              pageSize: !loadAllAdminPages ? UOrgMaster.ADMIN_PAGE_SIZE : undefined,
              pageNumber: !loadAllAdminPages ? 0 : undefined,
              searchQuery,
            },
            true,
            signal
          );
          // add admins to the org
          this.addAdminsToOrgV2(org, response.data, response.pageCount);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseAdminsLoaded;
  }

  /**
   * Fetch the compartment policy for the given org and update the compartmentPolicy property on the given UOrgMaster object.
   * @param orgId string
   * @param signal AbortSignal
   */
  public static async loadPolicies(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.policiesLoaded) {
      return; // nothing to do
    }

    if (org.promisePoliciesLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promisePoliciesLoaded;
        return;
      } catch (e) {
        // Try again if previous call resulted in an error.
        org.promisePoliciesLoaded = null;
      }
    }

    if (signal && signal.aborted) {
      return;
    }

    org.promisePoliciesLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const policyData: UCompartmentPoliciesData = await BanyanCompartmentAPI.getPolicies(orgId, signal);
          const addPoliciesToOrg = (orgToUpdate: UOrgMaster) => {
            orgToUpdate.compartmentPolicy = new UCompartmentPolicies(policyData);
            orgToUpdate.policiesLoaded = true;
            CommandService.applyPolicyEdit(orgToUpdate);
          };
          addPoliciesToOrg(org);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promisePoliciesLoaded;
  }

  /**
   * Fetch the compartment policies for multiple orgs and update the compartmentPolicy property on all the requested UOrgMaster objects.
   * @param parentOrgId parent of orgIds where user is explicitly a GlobalAdmin.
   * @param orgIds list of requested org ids
   * @param signal AbortSignal
   */
  public static async loadHierarchyPoliciesBulk(
    parentOrgId: string,
    orgIds: string[],
    signal?: AbortSignal
  ): Promise<void> {
    const orgPoliciesToFetch: string[] = _.filter(orgIds, (orgId: string): boolean => {
      if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
        return false;
      }
      const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
      if (org === undefined) {
        return false;
      }
      return !org.policiesLoaded;
    });

    const chunkedOrgIds: string[][] = _.chunk(orgPoliciesToFetch, LoadOrgDataService.MAX_ORG_IDS);

    for (let i = 0; i < chunkedOrgIds.length; i++) {
      const policiesResponse: PoliciesBulkResponse = await BanyanCompartmentAPI.getHierarchyPoliciesBulk(
        parentOrgId,
        chunkedOrgIds[i],
        signal
      );

      _.forEach(policiesResponse.orgPoliciesMap, (policiesPerOrg: UCompartmentPoliciesData, orgId: string): void => {
        if (orgId) {
          const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);
          if (org === undefined || _.isEmpty(policiesPerOrg)) {
            return;
          }
          // there's only 1 UCompartmentPolicies object per org
          org.compartmentPolicy = new UCompartmentPolicies(policiesPerOrg);
          org.policiesLoaded = true;
          CommandService.applyPolicyEdit(org);
        }
      });
    }
  }

  /**
   * Fetch the domains for the given org and update the domains property on the given UOrgMaster object.
   * @param orgId string
   * @param signal AbortSignal
   */
  public static async loadDomains(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.domainsLoaded) {
      return; // nothing to do
    }

    if (org.promiseDomainsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseDomainsLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseDomainsLoaded = null;
      }
    }

    org.promiseDomainsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const domainList: UDomainData[] = await BanyanCompartmentAPI.getDomains(orgId, signal);
          const addDomainsToOrg = (orgToUpdate: UOrgMaster) => {
            _.forEach(domainList, (domainData: UDomainData): void => {
              orgToUpdate.domains.push(new UDomain(domainData));
            });
            orgToUpdate.domainsLoaded = true;
          };
          addDomainsToOrg(org);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );

    return org.promiseDomainsLoaded;
  }

  /**
   * Fetch the directories for the given org and update the directories property on the given UOrgMaster object.
   * @param orgId string
   * @param signal AbortSignal
   */
  public static async loadDirectories(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.directoriesLoaded) {
      return; // nothing to do
    }

    if (org.promiseDirectoriesLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseDirectoriesLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseDirectoriesLoaded = null;
      }
    }

    org.promiseDirectoriesLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const response: ResponseWithCounts = await JilAPIProvider.getDirectories(
            orgId,
            {
              pageSize: UOrgMaster.DIRECTORIES_PAGE_SIZE,
              pageNumber: 0,
            },
            signal
          );
          org.totalDirectoriesCount = response.totalCount;
          org.totalDirectoriesPageCount = response.pageCount;
          const directoryList: UDirectory[] = response.data;
          const addDirectoriesToOrg = (orgToUpdate: UOrgMaster) => {
            _.forEach(directoryList, (directory: UDirectory): void => {
              orgToUpdate.directories.push(new UDirectory(directory));
            });
            orgToUpdate.directoriesLoaded = true;
          };
          addDirectoriesToOrg(org);
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );

    return org.promiseDirectoriesLoaded;
  }

  /**
   * load the next page of directories
   * @param orgId
   */
  public static async loadNextPageDirectories(orgId: string) {
    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined || org.isNewOrg()) {
      return;
    }
    if (org.currentDirectoriesPageIndex + 1 >= org.totalDirectoriesCount) {
      return;
    }
    // get next list of directories
    const response: ResponseWithCounts = await JilAPIProvider.getDirectories(orgId, {
      pageSize: UOrgMaster.DIRECTORIES_PAGE_SIZE,
      pageNumber: org.currentDirectoriesPageIndex + 1,
    });
    const nextPageDirectories: UDirectory[] = response.data;
    // increment the last page loaded
    org.currentDirectoriesPageIndex += 1;
    // add the next page of directories to the org
    _.forEach(nextPageDirectories, (directoryData: UDirectoryData): void => {
      if (directoryData) {
        org.addDirectories(new UDirectory(directoryData));
      }
    });
  }

  public static async loadUserCounts(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      return; // nothing to do
    }

    // check if requested data is already loaded
    if (org.userCountsLoaded) {
      return; // nothing to do
    }

    if (org.promiseUserCountsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseUserCountsLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseUserCountsLoaded = null;
      }
    }

    org.promiseUserCountsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const userCountData: CountData = await BanyanCompartmentAPI.getCounts(
            orgId,
            true,
            false,
            false,
            false,
            signal
          );
          org.totalUserCount = Utils.parseIntOrDefault(userCountData.userCount, 0);
          org.userCountsLoaded = true;
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseUserCountsLoaded;
  }

  public static async loadAdminCounts(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      return; // nothing to do
    }

    // check if requestd data is already loaded
    if (org.adminCountsLoaded) {
      return; // nothing to do
    }

    if (org.promiseAdminCountsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseAdminCountsLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseAdminCountsLoaded = null;
      }
    }

    org.promiseAdminCountsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const adminCountData: CountData = await BanyanCompartmentAPI.getCounts(
            orgId,
            false,
            true,
            false,
            false,
            signal
          );
          if (!org.adminCountsLoaded && !_.isNil(adminCountData.adminCount)) {
            org.totalAdminCount = Utils.parseIntOrDefault(adminCountData.adminCount, 0);
            org.adminCountsLoaded = true;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseAdminCountsLoaded;
  }

  public static async loadUserGroupCounts(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      return; // nothing to do
    }

    // check if requested data is already loaded
    if (org.userGroupCountsLoaded) {
      return; // nothing to do
    }

    if (org.promiseUserGroupCountsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseUserGroupCountsLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseUserGroupCountsLoaded = null;
      }
    }

    org.promiseUserGroupCountsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const userGroupCountData: CountData = await BanyanCompartmentAPI.getCounts(
            orgId,
            false,
            false,
            true,
            false,
            signal
          );
          org.totalUserGroupCount = Utils.parseIntOrDefault(userGroupCountData.userGroupCount, 0);
          org.userGroupCountsLoaded = true;
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseUserGroupCountsLoaded;
  }

  public static async loadDomainCounts(orgId: string, signal?: AbortSignal): Promise<void> {
    if (_.startsWith(orgId, TEMP_ID_PREFIX)) {
      return; // nothing to load for a new org
    }

    const org: UOrgMaster | undefined = HierarchyManager.getOrg(orgId);

    if (org === undefined) {
      return; // nothing to do
    }

    // check if requested data is already loaded
    if (org.domainCountsLoaded) {
      return; // nothing to do
    }

    if (org.promiseDomainCountsLoaded !== null) {
      // the promise has already been defined, just wait for it to complete and return
      try {
        await org.promiseDomainCountsLoaded;
        return;
      } catch (e) {
        // Try Again if previous call resulted in an error.
        org.promiseDomainCountsLoaded = null;
      }
    }

    org.promiseDomainCountsLoaded = new Promise<void>(
      async (resolve: ResolveCallback, reject: RejectCallback): Promise<void> => {
        try {
          const domainCountData: CountData = await BanyanCompartmentAPI.getCounts(
            orgId,
            false,
            false,
            false,
            true,
            signal
          );
          org.totalDomainCount = Utils.parseIntOrDefault(domainCountData.domainCount, 0);
          org.domainCountsLoaded = true;
          resolve();
        } catch (error) {
          reject(error);
        }
      }
    );
    return org.promiseDomainCountsLoaded;
  }

  /**
   * Initializes the org master tree to an initial state before loading data.
   */
  private initModel(orgId: string): void {
    this.idField = orgId;
  }
}

export default LoadOrgDataService;
/* eslint-enable no-param-reassign */
