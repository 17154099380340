import * as log from 'loglevel';
import * as _ from 'lodash';
import ImsProvider, { Country, CountryData } from '../../providers/ImsProvider';
import { LocaleSettings } from '../locale/LocaleSettings';

class CountryList {
  private static countryList: Country[] = [];
  private static isLoaded = false;
  private static locale: string = ''; // keep track of the locale in which the country list is fetched

  public static getCountryList = async (): Promise<Country[]> => {
    const selectedLocale = LocaleSettings.getSelectedLanguageTag();
    // validate that country list is loaded in the selected locale
    if (CountryList.isLoaded && selectedLocale === CountryList.locale) {
      return CountryList.countryList;
    }

    try {
      // update locale with selectedLocale
      CountryList.locale = selectedLocale;
      const data: CountryData = await ImsProvider.getCountries();
      CountryList.countryList = _.sortBy(data.countries, (each: Country): string => each.countryName);
      CountryList.isLoaded = true;
      return CountryList.countryList;
    } catch (error) {
      log.error('Error getting country list ', error);
      throw error;
    }
  };
}

export default CountryList;
