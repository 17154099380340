import * as _ from 'lodash';
import * as log from 'loglevel';
import React from 'react';

import Button from '@react/react-spectrum/Button';
import Dropdown from '@react/react-spectrum/Dropdown';
import Switch from '@react/react-spectrum/Switch';
import Wait from '@react/react-spectrum/Wait';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import Dialog from '@react/react-spectrum/Dialog';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Rule from '@react/react-spectrum/Rule';

import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import { Tab, TabList } from '@react/react-spectrum/TabList';
import { Menu, MenuItem } from '@react/react-spectrum/Menu';
import Heading from '@react/react-spectrum/Heading';

import More from '@react/react-spectrum/Icon/More';

import Edit from '@react/react-spectrum/Icon/Edit';
import InfoOutline from '@react/react-spectrum/Icon/InfoOutline';

import TreeNode from 'primereact/components/treenode/TreeNode';
import { TreeTable } from 'primereact/treetable';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';

import { defineMessages, FormattedMessage, injectIntl, MessageDescriptor, WrappedComponentProps } from 'react-intl';

import DeleteOutline from '@react/react-spectrum/Icon/DeleteOutline';
import Alert from '@spectrum-icons/workflow/Alert';
import { ActionButton, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import { ExpandedNodes, ExpandedNodesUtil } from '../services/treeTableUtils/ExpandedNodesUtil';

import EditNumberInput from './components/EditNumberInput/EditNumberInput';
import HLText from './components/HLText/HLText';
import ExpirationIcon from './components/ExpirationIcon/ExpirationIcon';
import ExpirationBanner from '../components/ExpirationBanner/ExpirationBanner';

import LocaleTooltip from '../components/LocaleTooltip/LocaleTooltip';
import AlertBanner from '../components/AlertBanner/AlertBanner';

import HeaderConsts from '../components/BanyanShell/HeaderConstants';
import { ObjectTypes, OrgOperation } from '../services/orgMaster/OrgMaster';
import { LoadOrgDataService } from '../services/orgMaster/LoadOrgDataService';
import { UOrgMaster } from '../services/orgMaster/UOrgMaster';
import { UProduct } from '../services/orgMaster/UProduct';
import { LicenseTuple } from '../services/orgMaster/LicenseTuple';
import { UResource } from '../services/orgMaster/UResource';

import Upload, { FileData } from '../services/utils/Upload';
import Download from '../services/utils/Download';

import { EXPORT_FILE_NAME, ProdAllocFiles } from './services/importExport/ProdAllocFiles';
import { ProdAllocExportCSV, ProdAllocExportJSON } from './services/importExport/ProdAllocExport';

import { ColumnField, ColumnUtils } from './services/productAllocationData/Columns';
import ProductResourceMenuUtils from './services/productAllocationData/ProductResourceMenu';
import { ResourceCalculationMap, ResourceCalculationUtils } from './services/calculation/ResourceCalculation';
import { HighlightMap, TreeTableData, TreeTableNodeData } from './services/treeTable/TreeTableData';

import AdminPermission from '../services/authentication/AdminPermission';
import AccessDeniedPage from '../components/AccessDeniedPage/AccessDeniedPage';
import FullErrorPage from '../components/FullErrorPage/FullErrorPage';
import Utils from '../services/utils/Utils';

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeicons/primeicons.css';
import './ProductAllocation.css';
import '../App.css';
import ProdAllocProductSelector from './components/ProdAllocProductSelector/ProdAllocProductSelector';
import AddProductDialog from './components/AddProdDialog/AddProductDialog';
import Analytics from '../Analytics/Analytics';
import {
  ProdAllocImportCSV,
  ProdAllocImportJSON,
  ProdAllocImportStatus,
} from './services/importExport/ProdAllocImport';
import ProductPermission from './services/permissions/ProductPermission';

import ShoppingCart from '../images/shopping-cart-purchased.svg';
import DelProductDialog from './components/DelProdDialog/DelProductDialog';
import { CommandService } from '../services/Commands/CommandService';
import { UOrg } from '../services/orgMaster/UOrg';
import GoUrl, { GoUrlKeys } from '../components/GoUrl/GoUrl';
import GoHelpBubble from '../components/HelpBubble/HelpBubble';
import NumberFormatter from '../services/utils/NumberFormatter';
import OrgPickerController from '../services/organization/OrgPickerController';
import { MESSAGES } from '../Compartments/Messages';
import HierarchyManager from '../services/organization/HierarchyManager';
import { ClearOrgDataWrapper } from '../services/Commands/ClearOrgDataWrapper';
import CmdDescriptionUtils from '../services/Codes/CmdDescriptionUtils';
import withRouter, { RouteComponentProps } from '../services/utils/withRouter';

// Localization for the Product Allocation page
const localeMessages = defineMessages({
  compartmentColumn: {
    id: 'productAllocation.table.headers.compartments',
    defaultMessage: 'Organization',
  },
  export: {
    id: 'productAllocation.header.export',
    defaultMessage: 'Export',
  },
  import: {
    id: 'productAllocation.header.import',
    defaultMessage: 'Import',
  },
  overAllocationAlert: {
    id: 'productAllocation.alertBanner.overAllocation',
    defaultMessage: 'There are over allocated grant fields in the table.',
  },
  overProvisionAlert: {
    id: 'productAllocation.alertBanner.overProvision',
    defaultMessage: 'Over use policy cannot be off when products are overused.',
  },
  updateToZeroAlert: {
    id: 'productAllocation.alertBanner.updateToZero',
    defaultMessage: 'There are grant fields being updated to 0 from a non-zero value in the table.',
  },
  noProductError: {
    id: 'productAllocation.errors.noProducts',
    defaultMessage: 'There are no products to display for this page',
  },
  productSelectorLabel: {
    id: 'productAllocation.productSelector.label',
    defaultMessage: 'Select a product',
  },
  addProduct: {
    id: 'productAllocation.plusicon.addProduct',
    defaultMessage: 'Add product',
  },
  removeProduct: {
    id: 'productAllocation.trashicon.removeProduct',
    defaultMessage: 'Remove product',
  },
  PaneHelp: {
    id: 'Organizations.ProductAllocation.Helptext',
    defaultMessage:
      'Product Allocation shows, for each product, how much product resource has been granted to each' +
      ' organization and how much has been assigned to users or otherwise consumed.  Totals show rolled up values that' +
      ' include all child organizations.  You can change the allocations to organizations you manage by editing the Grant' +
      ' values. The Overallocation and Overuse switches control whether limits can be exceeded by Global and System' +
      ' administrators. You can click on some numbers to highlight values that are used in computing that number.',
  },
  importErrorDialogTitle: {
    id: 'productAllocation.import.dialog.title',
    defaultMessage: 'Error',
  },
  importErrorDialogConfirm: {
    id: 'productAllocation.import.dialog.confirm',
    defaultMessage: 'OK',
  },
  importInfoDialogTitle: {
    id: 'productAllocation.import.infoDialog.title',
    defaultMessage: 'Import',
  },
  importInfoDialogConfirm: {
    id: 'productAllocation.import.infoDialog.confirm',
    defaultMessage: 'OK',
  },
  overAllocationColumnTooltip: {
    id: 'productAllocation.tooltip.overAllocationColumn',
    defaultMessage:
      'When on, over allocation policy allows global admins to grant additional product licenses to a child organization, even if all available licenses from the parent organization have been allocated.',
  },
  overUseColumnTooltip: {
    id: 'productAllocation.tooltip.overUseColumn',
    defaultMessage:
      'When on, over use policy allows admins that manage an organization’s Admin Console to assign product licenses to users, even if all available licenses have been used. When off, a limit is set to the number of product licenses that can be assigned to users.',
  },
});

// Product Allocation Component ///////////////////////////////////////////

interface ProductAllocationProps extends RouteComponentProps, WrappedComponentProps {}

interface ProductAllocationState {
  orgMasterTree: LoadOrgDataService | undefined; // Tree structure of all data for orgs
  orgList: UOrgMaster[]; // List of orgs for the table
  availableProducts: UProduct[]; // List of all products of the root org
  selectedProduct: UProduct | undefined; // Selected product to display data for in the table (based on Product Resource selector)
  selectedResource: UResource | undefined; // Selected resource to display data for in the table (based on Product Resource selector)
  resourceCalculationMap: ResourceCalculationMap; // Mapping for looking up calculated table data
  highlightMap: HighlightMap; // Mapping for highlighting
  overAllocationErrorDisplayed: boolean; // Reports whether the over allocation error is displayed as part of the Alert Banner error message
  overProvisionErrorDisplayed: boolean; // Reports whether the over provisioned error is displayed as part of the Alert Banner error message
  updateToZeroErrorDisplayed: boolean; // Reports whether the update to zero error is displayed as part of the Alert Banner erorr message
  errorMessage: React.ReactNode; // Error message to display in Alert banner on page
  loadedForRead: boolean; // Reports whether the minimum data to view the table has been loaded
  loadedForWrite: boolean; // Reports whether the data necessary for editing the table and page has been loaded
  enableMakeItSo: boolean; // Determines whether the MakeItSo button should be enabled for clicking (and cta variant)
  allowedAccess: boolean; // Determines whether the user is allowed to view this page
  hideTrashIcons: boolean; // Determines whether the trashcan icons are visible or not
  showOverUsePolicyColumn: boolean; // Determines if the over use policy toggle column is to be shown or not
  globalLicenseTuple: LicenseTuple | undefined; // LicenseTuple which determines display of compliance banner
  loading: boolean;
}

// stores both the product id and resource code to determine which resources on products have errors
class ProductResourceErrorSet {
  private productResourceSet: Set<string> = new Set<string>();

  private static createProductResourceId(productId: string, resourceCode: string): string {
    return `${productId}_${resourceCode}`;
  }

  hasElements(): boolean {
    return this.productResourceSet.size > 0;
  }

  contains(productId: string, resourceCode: string): boolean {
    const key = ProductResourceErrorSet.createProductResourceId(productId, resourceCode);
    return this.productResourceSet.has(key);
  }

  addProductAndResource(productId: string, resourceCode: string): void {
    this.productResourceSet.add(ProductResourceErrorSet.createProductResourceId(productId, resourceCode));
  }

  removeProductAndResource(productId: string, resouceCode: string): void {
    this.productResourceSet.delete(ProductResourceErrorSet.createProductResourceId(productId, resouceCode));
  }

  removeProduct(productId: string): void {
    const deleteIds: string[] = [];
    this.productResourceSet.forEach((value: string): void => {
      if (value.startsWith(productId)) {
        deleteIds.push(value);
      }
    });
    _.forEach(deleteIds, (id: string): void => {
      this.productResourceSet.delete(id);
    });
  }
}

class ProductAllocation extends React.Component<ProductAllocationProps, ProductAllocationState> {
  private loadErrorMessage: string = ''; // Error message thrown if there are any errors loading the data
  private overAllocatedProductResources: ProductResourceErrorSet = new ProductResourceErrorSet();
  private overProvisionedProductResources: ProductResourceErrorSet = new ProductResourceErrorSet();
  private updateToZeroProductResources: ProductResourceErrorSet = new ProductResourceErrorSet();
  private abortController: AbortController = new AbortController();

  static MAX_REQUESTS = 100; // Chrome can only queue a limited number of calls until it starts failing with net::ERR_INSUFFICIENT_RESOURCES (https://jira.corp.adobe.com/browse/BANY-961)

  // Ref of Dialog displayed on Import/Export errors
  private modalContainerRef: any | undefined = undefined;

  constructor(props: ProductAllocationProps) {
    super(props);

    this.state = {
      orgMasterTree: undefined,
      orgList: [],
      availableProducts: [],
      selectedProduct: undefined,
      selectedResource: undefined,
      resourceCalculationMap: new ResourceCalculationMap(),
      highlightMap: new HighlightMap(),
      overAllocationErrorDisplayed: false,
      overProvisionErrorDisplayed: false,
      updateToZeroErrorDisplayed: false,
      errorMessage: undefined,
      loadedForRead: false,
      loadedForWrite: false,
      enableMakeItSo: CommandService.anyEdits(),
      allowedAccess: false,
      hideTrashIcons: true,
      showOverUsePolicyColumn: false,
      globalLicenseTuple: undefined,
      loading: false,
    };
  }

  // Utilities /////////////////////////////////////////////
  /**
   * Given a targetProduct, returns the org the parent product belongs to.
   */
  private findIndirectParentOrgForProduct(targetProduct: UProduct): UOrgMaster | undefined {
    return _.find(this.state.orgList, (org: UOrgMaster): boolean =>
      _.some(org.products, (product: UProduct): boolean => targetProduct.sourceProductId === product.id)
    );
  }

  /**
   * Determines whether a resource grant value is being updated to 0 from a non-zero value.  This is not allowed.
   * - This method takes in the orgId, productId, resourceCode with the updated grantValue for the resource to check
   * - Creating a product with a 0 grant value is fine
   * - A grant value of 0 is fine so long as a non-zero value is not being updated to a 0 value
   * - The seats resource being set to 0 is fine.  Only non-seat resources are disallowed from updating to 0
   */
  private static updatingToZero(orgId: string, productId: string, resourceCode: string, grantValue: string): boolean {
    if (grantValue !== '0') {
      // this method is only concerned with updates to 0, if the grantValue is non zero, then there is no update to 0
      return false;
    }
    const productEdits = CommandService.getElementEdits(orgId, ObjectTypes.PRODUCT);
    if (_.isEmpty(productEdits)) {
      return false;
    }
    const findProductEdit = _.find(productEdits, (edit) => edit.elem.id === productId);
    if (findProductEdit === undefined) {
      return false;
    }
    const originalProduct: UProduct | undefined = findProductEdit.originalElem as UProduct | undefined;
    if (!originalProduct) {
      // if original product doesn't exist, then there's no update occurring (either the org/product/resource are being created or don't exist to be updated)
      return false;
    }
    const originalResource: UResource | undefined = _.find(
      originalProduct.resources,
      (res: UResource): boolean => res.code === resourceCode
    );
    if (!originalResource) {
      // if original resources doesn't exist, then there's no update occurring (either the org/product/resource are being created or don't exist to be updated)
      return false;
    }
    if (UResource.isSeatResource(originalResource.code)) {
      // seats resources can be set to 0
      return false;
    }
    if (originalResource.grantedQuantity !== '0') {
      // original granted value not zero and it is being updated to 0
      return true;
    }
    return false;
  }

  // Lifecycle //////////////////////////////////////

  /**
   * Load the data after initial render.
   * (Show wait component, load data, show data)
   */
  componentDidMount(): void {
    this.load();
  }

  /**
   * Load data only if the data needs to be updated after render
   * (other than initial render).
   * (If data needs loading, show wait component, load data, show data)
   */
  componentDidUpdate(): void {
    if (LoadOrgDataService.willRefresh()) {
      this.setLoadingState();
      this.load();
    }
    this.checkAndClearErrors();
  }

  /**
   * Hide the modal error dialog (if it's being shown) when unmounting the component
   */
  componentWillUnmount(): void {
    this.abortController.abort();
    if (this.modalContainerRef) ModalContainer.hide(this.modalContainerRef);
  }

  // Expiration ///////////////////////////////////////////

  /**
   * Retrieves the highest priority LicenseTuple of all product licenses in the org hierarchy matching the given product.
   * LicenseTuple priority is in this order: NORMAL < NOTIFICATION < GRACE_PERIOD < POST_GRACE.
   *
   * @param selectedProduct Product license to match against.
   *                This should either be the root product or one of the root most products (ex: selected product on Product Allocation page)
   * @returns Highest priority LicenseTuple or undefined if no LicenseTuple could be determined.
   */
  private static getGlobalLicenseTuple(selectedProduct: UProduct | undefined): LicenseTuple | undefined {
    if (selectedProduct) {
      // Get the root org in order to find out the highest prority LicenseTuple within its hierarchy
      const rootOrg: UOrgMaster | undefined = HierarchyManager.getOrg(OrgPickerController.getActiveOrgId() ?? '');
      return rootOrg?.getCompliancePhaseForProductHierarchy(selectedProduct);
    }
    return undefined;
  }

  // Callbacks //////////////////////////////////////////////

  /**
   * Executed when a product is selected from the product selector.
   * Handles displaying the data associated with the selected product and selected resource.
   */
  private onProductSelected = (value: string): void => {
    Analytics.fireCTAEvent('product changed');
    this.setState((prevState: Readonly<ProductAllocationState>): Pick<ProductAllocationState, never> => {
      const selectedProduct: UProduct | undefined = _.find(
        prevState.availableProducts,
        (product: UProduct): boolean => product.id === value
      );
      const { selectedResource, resourceCalculationMap } = ProductAllocation.selectAndCalculateResource(
        0,
        selectedProduct,
        prevState.orgList
      );
      ProductResourceMenuUtils.saveSelectedProductResource(selectedProduct, selectedResource);
      return {
        selectedProduct,
        selectedResource,
        resourceCalculationMap,
        // retrieving LicenseTuple for hierarchy is costly for performance, only execute during product selection or page load (not during render)
        globalLicenseTuple: ProductAllocation.getGlobalLicenseTuple(selectedProduct),
      };
    });
  };

  /**
   * Executed when a resource is selected from the resource tabs
   * Handles displaying the data associated with the selected resource.
   */
  private onResourceSelected = (index: number): void => {
    Analytics.fireCTAEvent('resource changed');
    this.setState((prevState: Readonly<ProductAllocationState>): Pick<ProductAllocationState, never> => {
      const { selectedResource, resourceCalculationMap } = ProductAllocation.selectAndCalculateResource(
        index,
        prevState.selectedProduct,
        prevState.orgList
      );
      ProductResourceMenuUtils.saveSelectedProductResource(prevState.selectedProduct, selectedResource);
      return { selectedResource, resourceCalculationMap };
    });
  };

  /**
   * Returns the selectedResource and the calculated ResourceCalculationMap given an index of the resource from resource tabs
   */
  private static selectAndCalculateResource(
    resourceIndex: number,
    selectedProduct: UProduct | undefined,
    orgList: UOrgMaster[]
  ): { selectedResource: UResource | undefined; resourceCalculationMap: ResourceCalculationMap } {
    const quotaResources: UResource[] = selectedProduct
      ? ResourceCalculationUtils.getSortedQuotaResources(selectedProduct)
      : [];
    const selectedResource: UResource | undefined = ProductResourceMenuUtils.resourceFromTabIndex(
      resourceIndex,
      quotaResources
    );
    const resourceCalculationMap: ResourceCalculationMap =
      selectedProduct && selectedResource
        ? ResourceCalculationUtils.populateResourceUsages(orgList, selectedProduct, selectedResource)
        : new ResourceCalculationMap();
    return { selectedResource, resourceCalculationMap };
  }

  /**
   * Callback from table when Grant NumberInput is changed.
   * This should cause a recalculation of some of the resource data.
   *  - product: The product associated with the data that was changed in the table
   *  - resource: The resource associated with the data that was changed in the table
   *  - org: The org the resource belongs to
   *  - quantity: The updated changed Grant value in the table
   */
  private onResourceWithQuantityChanged = (
    product: UProduct,
    resource: UResource,
    org: UOrgMaster,
    quantity: number | null
  ): void => {
    Analytics.fireCTAEvent('Grant updated');
    Analytics.fireUpdateProductEvent(product.name);
    if (this.state.orgMasterTree && this.state.selectedProduct && this.state.selectedResource) {
      const originalProduct = _.cloneDeep(product);
      /* eslint-disable no-param-reassign */
      if (quantity !== null && resource.grantedQuantity !== quantity.toString()) {
        if (Number.isNaN(quantity)) {
          resource.grantedQuantity = '0';
        } else {
          resource.grantedQuantity = quantity.toString();
        }
        const resourceToUpdateInProduct = _.find(
          product.resources,
          (eachResource: UResource): boolean => eachResource.code === resource.code
        );
        if (resourceToUpdateInProduct) {
          resourceToUpdateInProduct.grantedQuantity = resource.grantedQuantity;

          CommandService.addEdit(
            org,
            product,
            ObjectTypes.PRODUCT,
            OrgOperation.UPDATE,
            originalProduct,
            'UPDATE_PRODUCT_QUANTITY',
            [product.name, CmdDescriptionUtils.getPathname(product.orgId)]
          );
        }
      }
      /* eslint-enable no-param-reassign */
      const orgList: UOrgMaster[] = HierarchyManager.getOrgMasters();
      this.setState((prevState: Readonly<ProductAllocationState>): Pick<ProductAllocationState, never> => {
        const resourceCalculationMap: ResourceCalculationMap = ResourceCalculationUtils.populateResourceUsages(
          orgList,
          prevState.selectedProduct as UProduct,
          prevState.selectedResource as UResource
        );
        return { orgList, resourceCalculationMap, enableMakeItSo: CommandService.anyEdits() };
      });
    }
  };

  /**
   * Callback from table when Allow Over Allocated Checkbox is changed.
   *  - product: The actual product belonging to the org with data that was changed in the table
   *  - value: The Checkbox value from the table
   */
  private onProductAllowOverAllocChanged = (product: UProduct, org: UOrgMaster, value: boolean): void => {
    Analytics.fireCTAEvent('product allow over allocation updated');
    /* eslint-disable no-param-reassign */
    if (this.state.orgMasterTree) {
      const originalProduct = _.cloneDeep(product);
      if (value) {
        this.overAllocatedProductResources.removeProduct(product.id);
      }
      product.allowExceedQuotas = value;
      CommandService.addEdit(
        org,
        product,
        ObjectTypes.PRODUCT,
        OrgOperation.UPDATE,
        originalProduct,
        'UPDATE_PRODUCT_OVERALLOC',
        [product.name, CmdDescriptionUtils.getPathname(product.orgId)]
      );
      this.setState({ enableMakeItSo: CommandService.anyEdits() });
    }
    /* eslint-enable no-param-reassign */
  };

  /**
   * Invoked when grant input field is modified or over use product policy is modified.
   * Check if any edit has resulted in violation of over use policy.
   *
   * Violation of over use policy:  if product is over provisioned and over use policy is toggled-off
   */
  private addErrorIfOverUsePolicyIsBeingViolated = (treeData: TreeTableNodeData, overUseAllowed: boolean): void => {
    const product: UProduct = treeData.product as UProduct;
    const overUsePolicyDisabled = !overUseAllowed;
    const isProductOverProvisioned =
      treeData.localUse !== undefined &&
      treeData.localUse > 0 &&
      treeData.localLicensedQuantity !== undefined &&
      Math.max(0, treeData.localLicensedQuantity) < treeData.localUse; // Why not use cap instead of localLicensedQuantity? Because it would get outdated upon quantity changes
    if (overUsePolicyDisabled && isProductOverProvisioned && this.state.selectedResource) {
      this.overProvisionedProductResources.addProductAndResource(product.id, this.state.selectedResource.code);
    } else {
      this.overProvisionedProductResources.removeProduct(product.id);
      // alert will be cleared after render
    }
  };

  /**
   * Callback from table when Over Use switch is toggled.
   *  - product: The actual product belonging to the org with data that was changed in the table
   *  - org: The org that the given product belongs to
   *  - value: true if toggle is on, false otherwise
   */
  private onProductAllowOverUseChanged = (treeData: TreeTableNodeData, value: boolean): void => {
    const product: UProduct = treeData.product as UProduct;
    const org: UOrgMaster = treeData.org;
    Analytics.fireCTAEvent('product allow over use changed');
    if (this.state.orgMasterTree) {
      const originalProduct = _.cloneDeep(product);
      this.addErrorIfOverUsePolicyIsBeingViolated(treeData, value);
      product.allowExceedUsage = value;
      CommandService.addEdit(
        org,
        product,
        ObjectTypes.PRODUCT,
        OrgOperation.UPDATE,
        originalProduct,
        'UPDATE_PRODUCT_OVERUSE',
        [product.name, CmdDescriptionUtils.getPathname(product.orgId)]
      );
      this.setState({ enableMakeItSo: CommandService.anyEdits() });
    }
  };

  /**
   * Opens an upload dialog for csv and json files and parses the data to update the Product Allocation page.
   * Executes when the "Import" button is clicked.
   */
  onImportClicked = async (): Promise<void> => {
    Analytics.fireCTAEvent('import product allocation');
    const fileData: FileData = await Upload.uploadFile([ProdAllocFiles.CSV.mimeType, ProdAllocFiles.JSON.mimeType]);
    let imported = false;
    try {
      if (fileData.fileExtension === ProdAllocFiles.CSV.extName) {
        const status: ProdAllocImportStatus = ProdAllocImportCSV.import(fileData.data, this.state.orgList);
        imported = true;
        if (!_.isEmpty(status.message)) {
          this.displayImportExportInfoDialog(status);
        }
      } else if (fileData.fileExtension === ProdAllocFiles.JSON.extName) {
        const status: ProdAllocImportStatus = ProdAllocImportJSON.import(fileData.data, this.state.orgList);
        imported = true;
        if (!_.isEmpty(status.message)) {
          this.displayImportExportInfoDialog(status);
        }
      } else {
        throw Error('Unsupported file type');
      }
    } catch (error) {
      this.displayImportExportErrorDialog(error.message);
      if (CommandService.anyEdits()) {
        // if there are any successful edits after import, even if there's an error, enable "review pending changes"
        this.setState({ enableMakeItSo: true });
      }
    }
    if (imported) {
      this.load();
      this.setState({ enableMakeItSo: CommandService.anyEdits() });
    }
  };

  /**
   * Downloads the json or csv representation of the data in the Product Allocation page.
   * Executes when an option in the "Export" menu is clicked.
   */
  onExportClicked = (value: string): void => {
    Analytics.fireCTAEvent('export product allocation');
    if (this.state.loadedForRead) {
      if (value === ProdAllocFiles.CSV.extName) {
        Download.downloadFile(
          ProdAllocExportCSV.export(this.state.availableProducts, this.state.orgList),
          `${EXPORT_FILE_NAME}.${ProdAllocFiles.CSV.extName}`,
          ProdAllocFiles.CSV.mimeType
        );
      } else {
        Download.downloadFile(
          ProdAllocExportJSON.export(this.state.availableProducts, this.state.orgList),
          `${EXPORT_FILE_NAME}.${ProdAllocFiles.JSON.extName}`,
          ProdAllocFiles.JSON.mimeType
        );
      }
    } else {
      const errorMessage = 'ProductAllocation Export: Data not loaded yet';
      log.error(errorMessage);
      this.displayImportExportErrorDialog(errorMessage);
    }
  };

  /**
   * Redirect to the Job Execution page where ORG_DIFF is run.
   * Executes when the "Make It So" button is clicked.
   */
  onMakeItSoClicked = async (): Promise<void> => {
    Analytics.fireCTAEvent(Analytics.REVIEW_PENDING_CHANGE);
    if (
      this.overAllocatedProductResources.hasElements() ||
      this.overProvisionedProductResources.hasElements() ||
      this.updateToZeroProductResources.hasElements()
    ) {
      this.displayErrors();
    } else {
      this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
    }
  };

  // Reloading /////////////////////////////////////////////////

  /**
   * Sets the ProductAllocation page into a reload state.
   * (Wait component shows up)
   */
  setLoadingState(): void {
    if (!this.state.loadedForRead && !this.state.loadedForWrite) {
      // no need to update either state variable. This avoids re-entering componentDidUpdate -> setLoadingState while load() is updating the page.
      return;
    }
    this.setState({ loadedForRead: false, loadedForWrite: false });
  }

  // Tree Table Highlighted ///////////////////////////////

  /* eslint-disable no-param-reassign */

  /**
   * Highlights the related column values in the TreeTable when an TotalAlloc column value is selected.
   * TotalAlloc highlights: directChildren(Grant), directChildren(GrantOver).
   */
  private totalAllocHighlight = (node: TreeNode, highlight: boolean): void => {
    const nodeCopy: TreeNode = _.cloneDeep(node);
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(nodeCopy);
    if (treeData) {
      const children: TreeTableNodeData[] = TreeTableNodeData.createChildrenFromNode(nodeCopy);
      _.forEach(children, (child: TreeTableNodeData): void => {
        if (child.product && !child.product.isPurchase() && !child.org.isIndirectAllocation(child.product)) {
          child.highlight.grant = highlight;
          child.highlight.grantOver = highlight;
        }
      });
      if (children.length > 0) {
        if (highlight) {
          ExpandedNodesUtil.expandOrg(treeData.org.organization.id);
        }
        this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
          const highlightMap: HighlightMap = _.cloneDeep(prevState.highlightMap);
          highlightMap.update([...children]);
          return {
            highlightMap,
          };
        });
      }
    }
  };

  /**
   * Highlights the related column values in the TreeTable when a GrantOver column value is selected.
   * GrantOver highlights: Grant, TotalAlloc.
   */
  private grantOverHighlight = (node: TreeNode, highlight: boolean): void => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(_.cloneDeep(node));
    if (treeData) {
      treeData.highlight.grant = highlight;
      treeData.highlight.totalAlloc = highlight;
      this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
        const highlightMap: HighlightMap = _.cloneDeep(prevState.highlightMap);
        highlightMap.update([treeData]);
        return {
          highlightMap,
        };
      });
    }
  };

  /**
   * Highlights the related column values in the TreeTable when a LocalLicensedQuantity column value is selected.
   * LocalLicensedQuantity highlights: Grant, directChildren(Grant)
   */
  private localLicensedQuantityHighlight = (node: TreeNode, highlight: boolean): void => {
    const nodeCopy: TreeNode = _.cloneDeep(node);
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(nodeCopy);
    if (treeData) {
      treeData.highlight.grant = highlight;
      const children: TreeTableNodeData[] = TreeTableNodeData.createChildrenFromNode(nodeCopy);
      _.forEach(children, (child: TreeTableNodeData): void => {
        if (child.product && !child.product.isPurchase() && !child.org.isIndirectAllocation(child.product)) {
          child.highlight.grant = highlight;
        }
      });
      if (children.length > 0 && highlight) {
        ExpandedNodesUtil.expandOrg(treeData.org.organization.id);
      }
      this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
        const highlightMap: HighlightMap = _.cloneDeep(prevState.highlightMap);
        highlightMap.update([treeData, ...children]);
        return {
          highlightMap,
        };
      });
    }
  };

  /**
   * Highlights the related column values in the TreeTable when the Over column value is selected.
   * over highlights: UseOver.
   */
  private overHighlight = (node: TreeNode, highlight: boolean): void => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(_.cloneDeep(node));
    if (treeData) {
      treeData.highlight.useOver = highlight;
      this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
        const highlightMap: HighlightMap = _.cloneDeep(prevState.highlightMap);
        highlightMap.update([treeData]);
        return {
          highlightMap,
        };
      });
    }
  };

  /**
   * Highlights the related column values in the TreeTable when a TotalUse column value is selected.
   * TotalUse highlights: LocalUse, directChildren(TotalUse) or directChildren(LocalUse) if leaf child
   */
  private totalUseHighlight = (node: TreeNode, highlight: boolean): void => {
    const nodeCopy: TreeNode = _.cloneDeep(node);
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(nodeCopy);
    if (treeData) {
      const children: TreeTableNodeData[] = TreeTableNodeData.createChildrenFromNode(nodeCopy);
      treeData.highlight.localUse = highlight;
      _.forEach(children, (child: TreeTableNodeData): void => {
        if (child.product && !child.product.isPurchase() && !child.org.isIndirectAllocation(child.product)) {
          if (child.org.getChildren().length === 0) {
            child.highlight.localUse = highlight;
          } else {
            child.highlight.totalUse = highlight;
          }
        }
      });
      if (highlight && children.length > 0) {
        ExpandedNodesUtil.expandOrg(treeData.org.organization.id);
      }
      this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
        const highlightMap: HighlightMap = _.cloneDeep(prevState.highlightMap);
        highlightMap.update([treeData, ...children]);
        return {
          highlightMap,
        };
      });
    }
  };

  /**
   * Highlights the related column values in the TreeTable when a UseOver column value is selected.
   * UseOver highlights: Grant, TotalUse.
   */
  private useOverHighlight = (node: TreeNode, highlight: boolean): void => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(_.cloneDeep(node));
    if (treeData) {
      treeData.highlight.grant = highlight;
      treeData.highlight.totalUse = highlight;
      this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
        const highlightMap: HighlightMap = _.cloneDeep(prevState.highlightMap);
        highlightMap.update([treeData]);
        return {
          highlightMap,
        };
      });
    }
  };

  /* eslint-enable no-param-reassign */

  // Tree Table ////////////////////////////////////////

  // Tree table related we want these grouped here

  /**
   * Creates the displayed value for the Total Allocations (over GrantOver) field of the TreeTable component.
   */
  private totalAllocGrantOverField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData && treeData.resource) {
      if (!Utils.isNumber(treeData.totalAlloc) || treeData.org.getChildren().length === 0) {
        return <span data-testid={`${treeData.orgName}-totalAlloc-dash`}>-</span>;
      }
      return (
        <span>
          <HLText
            selectable
            highlight={treeData.highlight.totalAlloc}
            onSelect={(): void => {
              this.totalAllocHighlight(node, true);
            }}
            onBlur={(): void => {
              this.totalAllocHighlight(node, false);
            }}
            data-testid={`${treeData.orgName}-totalAlloc`}
          >
            {NumberFormatter.formatNumber(treeData.totalAlloc.toString())}
          </HLText>
          {Utils.isNumber(treeData.grantOver) && treeData.grantOver < 0 ? (
            <span className="ProductAllocation__table__column__space">
              <HLText
                className="ProductAllocation__table__column__over"
                selectable
                highlight={treeData.highlight.grantOver}
                onSelect={(): void => {
                  this.grantOverHighlight(node, true);
                }}
                onBlur={(): void => {
                  this.grantOverHighlight(node, false);
                }}
                data-testid={`${treeData.orgName}-grantOver`}
              >
                (
                <FormattedMessage
                  id="productAllocation.table.over.grantOver"
                  defaultMessage="{grantOver} over"
                  values={{ grantOver: NumberFormatter.formatNumber(Math.abs(treeData.grantOver).toString()) }}
                />
                )
              </HLText>
            </span>
          ) : undefined}
        </span>
      );
    }
    return undefined;
  };

  /**
   * Creates the displayed value for the Local Licensed Quantity field of the TreeTable component.
   */
  private localLicensedQuantityField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData && treeData.resource) {
      if (Utils.isNumber(treeData.localLicensedQuantity)) {
        return (
          <HLText
            selectable
            highlight={treeData.highlight.localLicensedQuantity}
            onSelect={(): void => {
              this.localLicensedQuantityHighlight(node, true);
            }}
            onBlur={(): void => {
              this.localLicensedQuantityHighlight(node, false);
            }}
            data-testid={`${treeData.orgName}-localLicensedQuantity`}
          >
            {treeData.localLicensedQuantity < 0
              ? NumberFormatter.formatNumber('0')
              : NumberFormatter.formatNumber(treeData.localLicensedQuantity.toString())}
          </HLText>
        );
      }
      return <span>-</span>;
    }
    return undefined;
  };

  /**
   * Creates the displayed value for the Local Usage field of the TreeTable component.
   */
  private localUseField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData && Utils.isNumber(treeData.localUse)) {
      if (treeData.localUse === 0) {
        return <span data-testid={`${treeData.orgName}-localUsage-dash`}>-</span>;
      }
      return (
        <span>
          <HLText highlight={treeData.highlight.localUse} data-testid={`${treeData.orgName}-localUsage`}>
            {NumberFormatter.formatNumber(treeData.localUse.toString())}
          </HLText>
          {Utils.isNumber(treeData.useOver) && treeData.useOver < 0 && (
            <span className="ProductAllocation__table__column__space">
              <HLText
                className="ProductAllocation__table__column__over"
                selectable
                highlight={treeData.highlight.grantOver}
                onSelect={(): void => {
                  this.overHighlight(node, true);
                }}
                onBlur={(): void => {
                  this.overHighlight(node, false);
                }}
                data-testid={`${treeData.orgName}-localUseOver`}
              >
                (<FormattedMessage id="productAllocation.table.over.localUseOver" defaultMessage="over" />)
              </HLText>
            </span>
          )}
        </span>
      );
    }
    return undefined;
  };

  /**
   * Creates the displayed value for the Total Usage field of the TreeTable component.
   */
  private totalUseField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData && Utils.isNumber(treeData.totalUse)) {
      if (treeData.totalUse === 0 || treeData.org.getChildren().length === 0) {
        return <span data-testid={`${treeData.orgName}-totalUsage-dash`}>-</span>;
      }
      return (
        <span>
          <HLText
            selectable
            highlight={treeData.highlight.totalUse}
            onSelect={(): void => {
              this.totalUseHighlight(node, true);
            }}
            onBlur={(): void => {
              this.totalUseHighlight(node, false);
            }}
            data-testid={`${treeData.orgName}-totalUsage`}
          >
            {NumberFormatter.formatNumber(treeData.totalUse.toString())}
          </HLText>
          {Utils.isNumber(treeData.useOver) && treeData.useOver < 0 && (
            <span className="ProductAllocation__table__column__space">
              <HLText
                className="ProductAllocation__table__column__over"
                selectable
                highlight={treeData.highlight.useOver}
                onSelect={(): void => {
                  this.useOverHighlight(node, true);
                }}
                onBlur={(): void => {
                  this.useOverHighlight(node, false);
                }}
                data-testid={`${treeData.orgName}-useOver`}
              >
                (
                <FormattedMessage
                  id="productAllocation.table.over.useOver"
                  defaultMessage="{useOver} over"
                  values={{ useOver: NumberFormatter.formatNumber(Math.abs(treeData.useOver).toString()) }}
                />
                )
              </HLText>
            </span>
          )}
        </span>
      );
    }
    return undefined;
  };

  private onProductAddClick = (): void => Analytics.fireCTAEvent('add product dialog opened');

  private onProductDelClick = (): void => Analytics.fireCTAEvent('delete product dialog opened');

  /**
   * Interprets the treeData into a form suitable for the read-only grant field
   * - UNLIMITED value is displayed if resource is unlimited
   * - Grant value is displayed formatted with localized separators
   * - Empty string is displayed in all other circumstances (undefined/null product, resource, grant)
   */
  private formatReadOnlyGrantValue = (treeData: TreeTableNodeData): string | JSX.Element => {
    if (treeData.resource) {
      if (treeData.resource.isUnlimited()) {
        return Utils.localizedUnlimited();
      }
      if (Utils.isNumber(treeData.grant)) {
        return NumberFormatter.formatNumber(treeData.grant.toString());
      }
    }
    return '';
  };

  /**
   * Returns one of the following
   * 1. undefined if no tree node (table cell will be empty)
   * 2. number input or readonly value for the Grant column of the TreeTable component
   * 3. "+" icon to add a product
   * 4. "x" to indicate the product is non-redistributable
   */
  private grantInputField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    const { loadedForWrite } = this.state;
    /* 1. empty table cell */
    if (!treeData) {
      return undefined;
    }
    /* 2. If product exists, return number input or readonly value for the Grant column of the TreeTable component */
    if (treeData.product && treeData.resource) {
      const resourceIsUnlimited = treeData.resource.isUnlimited();
      // retrieve LicenseTuple
      const licenseTuple: LicenseTuple | undefined = treeData.product.getEarliestExpiringTuple();
      const licenseTupleAllowsEditing = treeData.product.allLicenseTuplesAllowEditing();
      // determine grant over
      let grantOverInvalid = false;
      if (treeData.product && !treeData.product.allowExceedQuotas) {
        grantOverInvalid = treeData.grantOver !== undefined && treeData.grantOver < 0;
        if (grantOverInvalid) {
          this.overAllocatedProductResources.addProductAndResource(treeData.product.id, treeData.resource.code);
        } else {
          this.overAllocatedProductResources.removeProductAndResource(treeData.product.id, treeData.resource.code);
          // alert will be cleared after render
        }
      }
      this.addErrorIfOverUsePolicyIsBeingViolated(treeData, treeData.product.allowExceedUsage);
      // determine updating to zero
      const updateZeroInvalid: boolean = ProductAllocation.updatingToZero(
        treeData.org.organization.id,
        treeData.product.id,
        treeData.resource.code,
        treeData.resource.grantedQuantity
      );
      if (updateZeroInvalid) {
        this.updateToZeroProductResources.addProductAndResource(treeData.product.id, treeData.resource.code);
      } else {
        this.updateToZeroProductResources.removeProductAndResource(treeData.product.id, treeData.resource.code);
        // alert will be cleared after render
      }
      const invalid: boolean = grantOverInvalid || updateZeroInvalid;

      // determine if readonly grant value should be shown
      const permissionToEditProduct = ProductPermission.canEditProduct(treeData.org, treeData.product);
      const grantIsReadOnly =
        !loadedForWrite || !permissionToEditProduct || resourceIsUnlimited || !licenseTupleAllowsEditing;
      // determine if delete product trash can is allowed to display
      const permissionToEditProductAndOrg = ProductPermission.canEditProductAndOrg(treeData.org, treeData.product);
      const canDeleteProduct = loadedForWrite && permissionToEditProductAndOrg && licenseTupleAllowsEditing;
      return (
        <span>
          {grantIsReadOnly ? (
            // User cannot edit OR unlimited, just show readonly field
            // User also cannot edit if compliance is post grace or no compliance was available
            <>
              <HLText
                className="ProductAllocation__table__numField"
                highlight={treeData.highlight.grant}
                data-testid={`${treeData.orgName}-grant-text`}
              >
                {this.formatReadOnlyGrantValue(treeData)}
              </HLText>
              <ExpirationIcon
                org={treeData.org}
                licenseTuple={licenseTuple}
                isEditableField={false}
                data-testid={`${treeData.orgName}-compliance-tooltip-container-readonly`}
                context={this}
              />
            </>
          ) : (
            // Show input field w/ overallocation tooltip
            <>
              <OverlayTrigger placement="right" trigger={['hover', 'focus']} disabled={!invalid}>
                <span>
                  <EditNumberInput
                    highlight={treeData.highlight.grant}
                    className="ProductAllocation__table__inputField"
                    defaultValue={treeData.grant}
                    min={0}
                    invalid={invalid}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>): void =>
                      this.onResourceWithQuantityChanged(
                        treeData.product as UProduct,
                        treeData.resource as UResource,
                        treeData.org,
                        _.parseInt(event.target.value, 10)
                      )
                    }
                    data-testid={`${treeData.orgName}-grant-input`}
                    labelledby={`Number of allocated resources for org ${treeData.orgName}`}
                  />
                </span>
                <LocaleTooltip placement="right" variant="error">
                  <span data-testid={`${treeData.orgName}-grant-error-icon`}>
                    {grantOverInvalid && (
                      <div data-testid={`${treeData.orgName}-grant-over-tooltip`}>
                        <FormattedMessage
                          id="productAllocation.table.tooltips.grantOverAllocated"
                          defaultMessage="Grant value over allocated."
                        />
                      </div>
                    )}
                    {updateZeroInvalid && (
                      <div data-testid={`${treeData.orgName}-update-zero-tooltip`}>
                        <FormattedMessage
                          id="productAllocation.table.tooltips.updateZero"
                          defaultMessage="Grant value may not be lowered to 0."
                        />
                      </div>
                    )}
                  </span>
                </LocaleTooltip>
              </OverlayTrigger>
              {this.state.hideTrashIcons && (
                <ExpirationIcon
                  org={treeData.org}
                  licenseTuple={licenseTuple}
                  isEditableField
                  data-testid={`${treeData.orgName}-compliance-tooltip-container-edit`}
                  context={this}
                />
              )}
            </>
          )}
          {/* Cannot delete a product of a read only org  */}
          {canDeleteProduct && (
            <ModalTrigger>
              <Button
                aria-label={this.props.intl.formatMessage(localeMessages.removeProduct)}
                className={`${this.state.hideTrashIcons ? 'ProductAllocation__trashcan--hidden' : ''}`}
                onClick={this.onProductDelClick}
                quiet
                variant="action"
                data-testid={`${treeData.orgName}-delete-product-button`}
              >
                <span>
                  <DeleteOutline size="S" />
                </span>
              </Button>
              <DelProductDialog prodToDelete={treeData.product} compartmentId={treeData.org.id} update={this.load} />
            </ModalTrigger>
          )}
        </span>
      );
    }

    /* 3 & 4. Display either + or x icons */
    if (this.state.loadedForWrite && !AdminPermission.readOnlyMode()) {
      // check for product in hierarchy before displaying the "+" for add product
      // set currentOrg to the parent and walk up the tree
      let parentProduct: UProduct | undefined;
      let currentOrg: UOrgMaster | undefined = treeData.org.getParentOrgMaster();
      let isDirectChild = true;
      while (currentOrg && !parentProduct) {
        parentProduct = _.find(currentOrg.products, (product: UProduct): boolean => {
          return product.isMatchingProduct(this.state.selectedProduct);
        });
        if (!parentProduct) {
          isDirectChild = false;
        }
        currentOrg = currentOrg.getParentOrgMaster();
      }

      if (!treeData.product && parentProduct) {
        /* 3. No product exists, return "+" icon to add product
           Disable add product icon for read only org
         */
        const permissionToAddProduct = ProductPermission.canAddProduct(treeData.org, parentProduct);
        const licenseTupleAllowsEditing = parentProduct.allLicenseTuplesAllowEditing();
        const canAddProduct = permissionToAddProduct && licenseTupleAllowsEditing;
        if (canAddProduct) {
          return (
            <ModalTrigger>
              <Button
                aria-label={this.props.intl.formatMessage(localeMessages.addProduct)}
                quiet
                variant="action"
                onClick={this.onProductAddClick}
                className="ProductAllocation__table__addOption"
                data-testid={`${treeData.orgName}-add-product-button`}
              >
                <span
                  className="spectrum-Link ProductAllocation__table__addOption__text"
                  data-testid={`${treeData.orgName}-add-product`}
                >
                  +
                </span>
              </Button>
              {/* selectedProduct is what's selected in the dropdown on top of the page */}
              <AddProductDialog
                orgList={this.state.orgList}
                rootProduct={this.state.selectedProduct as UProduct}
                compartmentId={treeData.org.id}
                update={this.load}
              />
            </ModalTrigger>
          );
        }

        /* 4. org is read-only, return "x" icon indicating org is read-only */
        // X is the same icon for non-redistributable as well, but we are replicating the X rendering code here in case we wish to use a different icon and to have separate functionality
        if (treeData.org.isReadOnlyOrg()) {
          return (
            <OverlayTrigger placement="right" trigger={['hover', 'focus']}>
              <span
                className="ProductAllocation__table__readonlyTooltip"
                data-testid={`${treeData.orgName}-grant-readonly`}
              >
                x
              </span>
              <LocaleTooltip placement="right" variant="info">
                <span data-testid={`${treeData.orgName}-grant-readonly-tooltip`}>
                  <FormattedMessage
                    id="productAllocation.table.tooltips.readonly"
                    defaultMessage="This org doesn't allow allocations"
                  />
                </span>
              </LocaleTooltip>
            </OverlayTrigger>
          );
        }

        /* 5. not redistributable but direct child of parent with product, return "x" icon indicating not redistributable */
        if (!parentProduct.redistributable && isDirectChild) {
          return (
            <OverlayTrigger placement="right" trigger={['hover', 'focus']}>
              <span
                className="ProductAllocation__table__redisTooltip"
                data-testid={`${treeData.orgName}-grant-no-redist`}
              >
                x
              </span>
              <LocaleTooltip placement="right" variant="info">
                <span data-testid={`${treeData.orgName}-grant-no-redist-tooltip`}>
                  <FormattedMessage
                    id="productAllocation.table.tooltips.redistributable"
                    defaultMessage="The selected product is not redistributable"
                  />
                </span>
              </LocaleTooltip>
            </OverlayTrigger>
          );
        }
        /* otherwise nothing is displayed because the admin lacks permission to allocate */
      }
    }
  };

  /**
   * Creates the displayed orgName and handles
   * any additional indicators (purchase, etc)
   */
  private orgNameField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData) {
      let indicator: React.ReactNode | undefined;
      if (treeData.product) {
        if (treeData.product.isPurchase()) {
          indicator = (
            <OverlayTrigger placement="bottom" trigger={['hover', 'focus']}>
              <span
                className="ProductAllocation__table__column__indicator"
                data-testid={`${treeData.orgName}-purchase-icon`}
              >
                <img
                  className="ProductAllocation__table__column__purchaseIcon"
                  src={ShoppingCart}
                  alt="shopping-cart-purchased"
                />
              </span>
              <LocaleTooltip placement="bottom" variant="info">
                {OrgPickerController.getActiveOrgId() === treeData.org.organization.id ? (
                  <span data-testid={`${treeData.orgName}-toplevel-purchase-tooltip`}>
                    <FormattedMessage
                      id="productAllocation.table.tooltips.topLevelPurchase"
                      defaultMessage="Product from a purchase agreement."
                    />
                  </span>
                ) : (
                  <span data-testid={`${treeData.orgName}-purchase-tooltip`}>
                    <FormattedMessage
                      id="productAllocation.table.tooltips.purchase"
                      defaultMessage="This org has previously purchased product licenses. This limits the allocation of products to this org."
                    />
                    <br />
                    <br />
                    <FormattedMessage
                      id="productAllocation.table.tooltips.purchaseHelp"
                      defaultMessage="To enable product allocation, contact Adobe Support or your onboarding assistant."
                    />{' '}
                    <GoUrl
                      style={{ color: 'white', textDecoration: 'underline' }}
                      goUrlKey={GoUrlKeys.contractSwitch}
                      target="gac_help"
                    >
                      <FormattedMessage id="productallocation.purchaseproduct.LearnMore" defaultMessage="Learn more" />
                    </GoUrl>
                  </span>
                )}
              </LocaleTooltip>
            </OverlayTrigger>
          );
        } else if (treeData.org.isIndirectAllocation(treeData.product)) {
          const indirectParentOrg: UOrgMaster | undefined = this.findIndirectParentOrgForProduct(treeData.product);
          let parentOrgId: string = '(not found)';
          let parentOrgName: string = '(not found)';
          if (indirectParentOrg) {
            parentOrgId = indirectParentOrg.organization.id;
            parentOrgName = indirectParentOrg.organization.name;
          }
          indicator = (
            <OverlayTrigger placement="right" trigger={['hover', 'focus']}>
              <span
                className="ProductAllocation__table__column__indicator"
                data-testid={`${treeData.orgName}-indirect-alloc-icon`}
              >
                (*)
              </span>
              <LocaleTooltip
                placement="right"
                variant="info"
                className="ProductAllocation__table__column__tooltipWidth"
              >
                <div
                  className="ProductAllocation__table__column__tooltipWidth"
                  data-testid={`${treeData.orgName}-indirect-alloc-tooltip`}
                >
                  <div>
                    <FormattedMessage
                      id="productAllocation.table.tooltips.indirect.title"
                      defaultMessage="Indirect Allocation"
                    />
                  </div>
                  <div data-testid={`${treeData.orgName}-indirect-alloc-tooltip-sourceOrgName`}>
                    <FormattedMessage
                      id="productAllocation.table.tooltips.indirect.sourceOrgName"
                      defaultMessage="Source Org Name: {sourceOrgName}"
                      values={{ sourceOrgName: parentOrgName }}
                    />
                  </div>
                  <div data-testid={`${treeData.orgName}-indirect-alloc-tooltip-sourceOrgId`}>
                    <FormattedMessage
                      id="productAllocation.table.tooltips.indirect.sourceOrgId"
                      defaultMessage="Source Org ID: {sourceOrgId}"
                      values={{ sourceOrgId: parentOrgId }}
                    />
                  </div>
                  <div data-testid={`${treeData.orgName}-indirect-alloc-tooltip-sourceProductId`}>
                    <FormattedMessage
                      id="productAllocation.table.tooltips.indirect.sourceProductId"
                      defaultMessage="Source Product ID: {sourceProductId}"
                      values={{ sourceProductId: treeData.product.sourceProductId }}
                    />
                  </div>
                </div>
              </LocaleTooltip>
            </OverlayTrigger>
          );
        }
      }
      return (
        <span className="ProductAllocation__table__column__wrap" data-testid={`${treeData.orgName}-orgName`}>
          {treeData.orgName}
          {indicator}
          {!_.isEmpty(CommandService.getAllEditsForTheOrgExcludingUndo(treeData.org.id)) && (
            <Edit
              size="XS"
              className="ProductAllocation__table__column__editIcon"
              data-testid={`${treeData.orgName}-editIcon`}
            />
          )}
        </span>
      );
    }
  };

  /**
   * Creates the Switch for the Allow Over Allocated column of the TreeTable component.
   */
  private allowOverAllocField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData && treeData.product && treeData.resource && treeData.allowOverAlloc !== undefined) {
      const canEdit =
        this.state.loadedForWrite &&
        !AdminPermission.readOnlyMode() &&
        treeData.product.redistributable && // For products that are not redistributable, allocations are not allowed and hence this policy should not be editable.
        treeData.product.allLicenseTuplesAllowEditing(); // can edit allow over allocation as long as user is global admin on selected active org (can edit this policy even on the active org) and we aren't loading write data
      // can also edit allow over allocation if compliance allows editing
      return (
        <Switch
          defaultChecked={treeData.allowOverAlloc}
          checked={treeData.allowOverAlloc}
          onChange={(checked: boolean): void =>
            this.onProductAllowOverAllocChanged(treeData.product as UProduct, treeData.org, checked)
          }
          disabled={!canEdit}
          data-testid={`${treeData.orgName}-overAlloc`}
        />
      );
    }
  };

  /**
   * Creates the Switch for the Over Use column of the TreeTable component.
   */
  private allowOverUseField = (node: TreeNode): React.ReactNode => {
    const treeData: TreeTableNodeData | null = TreeTableNodeData.createFromNode(node);
    if (treeData && treeData.product && treeData.resource && treeData.allowOverUse !== undefined) {
      // Editing overuse policy is allowed if all of following are satisfied:
      // 1. User is an explicit GA of selected active org
      // 2. Product attribute "overUsePolicyConfigurable" is set to true
      // 3. Compliance symptoms allow editing
      const canEdit =
        this.state.loadedForWrite &&
        !AdminPermission.readOnlyMode() &&
        treeData.product.productAttributes.overUsePolicyConfigurable &&
        treeData.product.allLicenseTuplesAllowEditing();

      return (
        <>
          <Switch
            defaultChecked={treeData.allowOverUse}
            checked={treeData.allowOverUse}
            onChange={(checked: boolean): void => this.onProductAllowOverUseChanged(treeData, checked)}
            disabled={!canEdit}
            data-testid={`${treeData.orgName}-overUse`}
          />
          {this.overProvisionedProductResources.contains(treeData.product.id, treeData.resource.code) && (
            <span style={{ float: 'right', marginLeft: '-30px' }}>
              <TooltipTrigger delay={0}>
                <ActionButton isQuiet>
                  <Alert
                    marginY=".3rem"
                    size="S"
                    color="negative"
                    aria-label={this.props.intl.formatMessage(localeMessages.overProvisionAlert)}
                  />
                </ActionButton>
                <Tooltip variant="negative" showIcon>
                  {this.props.intl.formatMessage(localeMessages.overProvisionAlert)}
                </Tooltip>
              </TooltipTrigger>
            </span>
          )}
        </>
      );
    }
  };

  /**
   * Creates a column header based on supported column fields.
   * If no resource is given, then no unit is displayed for the column header
   * If tooltipMessage is defined, then "i" icon will be displayed after column name
   */
  private createColumnHeader(
    field: ColumnField,
    resourceForUnit?: UResource,
    tooltipMessage?: MessageDescriptor
  ): React.ReactNode {
    const { formatMessage } = this.props.intl;
    return (
      <div data-testid={`column-header-${field}`}>
        <div>
          {formatMessage(ColumnUtils.columnLocaleFromValue(field))}
          {tooltipMessage && (
            <OverlayTrigger>
              <span style={{ paddingLeft: '3px' }}>
                <InfoOutline size="S" style={{ top: '.25em', position: 'relative' }} />
              </span>
              <LocaleTooltip>{formatMessage(tooltipMessage)}</LocaleTooltip>
            </OverlayTrigger>
          )}
        </div>
        {resourceForUnit && (
          <div data-testid={`column-header-unit-${field}`} className="ProductAllocation__table__header__unitColor">
            {/* Units for column header e.g. Licenses, credits, etc */}
            {`(${Utils.localizedResourceUnit(resourceForUnit.unit)})`}
          </div>
        )}
      </div>
    );
  }

  /**
   * Creates the headers for the TreeTable component
   */
  private getTableHeader(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    return this.state.selectedResource ? (
      <ColumnGroup>
        <Row>
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column ProductAllocation__table__column__compartments"
            header={formatMessage(localeMessages.compartmentColumn)}
          />
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column ProductAllocation__table__column__grant"
            header={this.createColumnHeader(ColumnField.GRANT, this.state.selectedResource)}
          />
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column"
            header={this.createColumnHeader(ColumnField.TOTAL_ALLOC, this.state.selectedResource)}
          />
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column"
            header={this.createColumnHeader(ColumnField.LOCAL_LICENSED_QUANTITY, this.state.selectedResource)}
          />
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column"
            header={this.createColumnHeader(ColumnField.LOCAL_USE, this.state.selectedResource)}
          />
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column"
            header={this.createColumnHeader(ColumnField.TOTAL_USE, this.state.selectedResource)}
          />
          <Column
            className="ProductAllocation__table__column ProductAllocation__table__header__column"
            header={this.createColumnHeader(
              ColumnField.ALLOW_OVER_ALLOC,
              undefined,
              localeMessages.overAllocationColumnTooltip
            )}
          />
          {this.state.showOverUsePolicyColumn && (
            <Column
              className="ProductAllocation__table__column ProductAllocation__table__header__column"
              header={this.createColumnHeader(
                ColumnField.ALLOW_OVER_USE,
                undefined,
                localeMessages.overUseColumnTooltip
              )}
            />
          )}
        </Row>
      </ColumnGroup>
    ) : undefined;
  }

  // Error Handling /////////////////////////////////

  /**
   * Determines the error messages to display in the Alert Banner
   */
  private displayErrors(): void {
    if (
      !_.isEmpty(this.loadErrorMessage) ||
      this.overAllocatedProductResources.hasElements() ||
      this.overProvisionedProductResources.hasElements() ||
      this.updateToZeroProductResources.hasElements()
    ) {
      this.setState({
        errorMessage: (
          <span>
            {!_.isEmpty(this.loadErrorMessage) && <div>{this.loadErrorMessage}</div>}
            {this.overAllocatedProductResources.hasElements() && (
              <div>{this.props.intl.formatMessage(localeMessages.overAllocationAlert)}</div>
            )}
            {this.overProvisionedProductResources.hasElements() && (
              <div>{this.props.intl.formatMessage(localeMessages.overProvisionAlert)}</div>
            )}
            {this.updateToZeroProductResources.hasElements() && (
              <div>{this.props.intl.formatMessage(localeMessages.updateToZeroAlert)}</div>
            )}
          </span>
        ),
        overAllocationErrorDisplayed: this.overAllocatedProductResources.hasElements(),
        overProvisionErrorDisplayed: this.overProvisionedProductResources.hasElements(),
        updateToZeroErrorDisplayed: this.updateToZeroProductResources.hasElements(),
      });
    }
  }

  /**
   * Clears the error messages in the alert on the page.
   * Also hides the alert (since there are no messages).
   */
  private clearErrors(): void {
    this.setState((prevState: ProductAllocationState): Pick<ProductAllocationState, never> => {
      if (prevState.errorMessage) {
        return {
          errorMessage: undefined,
          overAllocationErrorDisplayed: false,
          overProvisionErrorDisplayed: false,
          updateToZeroErrorDisplayed: false,
        };
      }
      return {};
    });
  }

  /**
   * Clears alert messages if there are no errors for products and resources in the Product Allocation page
   */
  private checkAndClearErrors(): void {
    // don't need to clear anything if the alert banner isn't even displayed
    if (this.state.errorMessage) {
      if (
        _.isEmpty(this.loadErrorMessage) &&
        !this.overAllocatedProductResources.hasElements() &&
        !this.overProvisionedProductResources.hasElements() &&
        !this.updateToZeroProductResources.hasElements()
      ) {
        // just clear all errors if there aren't any issues (note: the load error message should not get cleared)
        this.clearErrors();
      } else if (
        (!this.overAllocatedProductResources.hasElements() && this.state.overAllocationErrorDisplayed) ||
        (!this.overProvisionedProductResources.hasElements() && this.state.overProvisionErrorDisplayed) ||
        (!this.updateToZeroProductResources.hasElements() && this.state.updateToZeroErrorDisplayed)
      ) {
        // At least 1 issue type has been resolved but there are still some issue types, so the alert banner needs to be updated.
        this.displayErrors();
      }
    }
  }

  /**
   * Displays an error message in the Dialog Box on the page
   */
  private displayImportExportErrorDialog(errorMessage: string): void {
    if (errorMessage.length > 0) {
      log.error(errorMessage);
      this.modalContainerRef = ModalContainer.show(
        <Dialog
          variant="error"
          title={this.props.intl.formatMessage(localeMessages.importErrorDialogTitle)}
          confirmLabel={this.props.intl.formatMessage(localeMessages.importErrorDialogConfirm)}
          onClose={(): void => {
            ModalContainer.hide(this.modalContainerRef);
            this.modalContainerRef = undefined;
          }}
          data-testid="prodalloc-import-error-dialog"
        >
          <span data-testid="prodalloc-import-error-dialog-content">{errorMessage}</span>
        </Dialog>,
        this
      );
    }
  }

  /**
   * Displays an info/warning message in the Dialog Box on the page
   */
  private displayImportExportInfoDialog(status: ProdAllocImportStatus): void {
    if (!_.isEmpty(status.message)) {
      this.modalContainerRef = ModalContainer.show(
        <Dialog
          variant="information"
          title={this.props.intl.formatMessage(localeMessages.importInfoDialogTitle)}
          confirmLabel={this.props.intl.formatMessage(localeMessages.importInfoDialogConfirm)}
          onClose={(): void => {
            ModalContainer.hide(this.modalContainerRef);
            this.modalContainerRef = undefined;
          }}
          data-testid="prodalloc-import-info-dialog"
        >
          <span data-testid="prodalloc-import-info-dialog-content">{status.message}</span>
        </Dialog>,
        this
      );
    }
  }

  // Data Load //////////////////////////////////////////////////////////

  /**
   * Loads data specific for enabling write mode
   */
  loadForWrite = async (orgIds: string[]): Promise<void> => {
    if (!this.abortController.signal.aborted) {
      await LoadOrgDataService.loadOrgReadOnlyBulk(OrgPickerController.getActiveOrgId() as string, orgIds);

      await LoadOrgDataService.loadHierarchyPoliciesBulk(OrgPickerController.getActiveOrgId() as string, orgIds);
      this.setState({ loadedForWrite: true });
    }
  };

  /**
   * Loads all data for the table.
   */
  load = async (): Promise<void> => {
    let hasReadPriviledge = false;
    if (this.state.loading) {
      return;
    }
    try {
      this.setState({ loading: true });
      hasReadPriviledge = await AdminPermission.hasReadPrivilege();
      if (!hasReadPriviledge) {
        this.setState({
          loadedForRead: true,
          allowedAccess: false,
        });
        return;
      }
      await LoadOrgDataService.initializeOrUpdateOrgMasterTree();
      const uOrgList: UOrg[] = HierarchyManager.getOrgs();
      const orgIds: string[] = _.map(uOrgList, (uOrg: UOrg): string => uOrg.id);
      // load products for each org
      await LoadOrgDataService.loadHierarchyProductsBulk(OrgPickerController.getActiveOrgId() as string, orgIds);
      const orgList = HierarchyManager.getOrgMasters(); // update loading products
      let availableProducts: UProduct[] = [...orgList[0].products]; // copy the array of root products as the availableProducts array(products are still referenced)
      // iterate all products of all orgs (not including the root org) and add all purchase products to the availableProducts array
      const orgListWithoutRoot = orgList.slice(1, orgList.length); // TODO: find a more robust way of removing the root org from the org list
      _.forEach(orgListWithoutRoot, (org: UOrgMaster): void => {
        _.forEach(org.products, (orgProduct: UProduct): void => {
          if (orgProduct.isPurchase() || org.isIndirectAllocation(orgProduct)) {
            availableProducts.push(orgProduct);
          }
        });
      });
      // Prune availableProducts of duplicate product types. Products of higher-level children are kept and lower duplicates are pruned.
      availableProducts = _.uniqWith(availableProducts, (arrVal, othVal) => {
        return arrVal.isMatchingProduct(othVal);
      });
      const doesAnyProductHaveOverUsePolicyConfigurable = _.some(
        availableProducts,
        (product: UProduct): boolean => product.productAttributes.overUsePolicyConfigurable
      );

      const selectedProduct: UProduct | undefined = ProductResourceMenuUtils.loadSelectedProduct(availableProducts);
      const quotaResources: UResource[] = selectedProduct
        ? ResourceCalculationUtils.getSortedQuotaResources(selectedProduct)
        : [];
      const selectedResource: UResource | undefined = ProductResourceMenuUtils.loadSelectedResource(quotaResources);

      const resourceCalculationMap: ResourceCalculationMap =
        selectedProduct && selectedResource
          ? ResourceCalculationUtils.populateResourceUsages(orgList, selectedProduct, selectedResource)
          : new ResourceCalculationMap();
      if (!this.abortController.signal.aborted) {
        this.setState({
          orgMasterTree: LoadOrgDataService.get() as LoadOrgDataService,
          orgList,
          resourceCalculationMap,
          availableProducts,
          selectedProduct,
          selectedResource,
          errorMessage: '',
          loadedForRead: true,
          loadedForWrite: false,
          enableMakeItSo: CommandService.anyEdits(),
          allowedAccess: true,
          showOverUsePolicyColumn: doesAnyProductHaveOverUsePolicyConfigurable,
          // retrieving LicenseTuple for hierarchy is costly for performance, only execute during product selection or page load (not during render)
          globalLicenseTuple: ProductAllocation.getGlobalLicenseTuple(selectedProduct),
        });
      }
      await this.loadForWrite(orgIds);
    } catch (error) {
      if (!this.abortController.signal.aborted) {
        this.abortController.abort();
        this.loadErrorMessage = error.message ?? error;
        this.displayErrors();
        this.setState({ loadedForRead: true, allowedAccess: hasReadPriviledge });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  onRefreshData = async (): Promise<void> => {
    this.setLoadingState();
    ClearOrgDataWrapper.clearAllOrgs();
    await this.load();
  };

  /** Show / hide the trashcan icons */
  toggleTrashIcons = (): void => {
    this.setState((prevState) => {
      return { hideTrashIcons: !prevState.hideTrashIcons };
    });
  };

  // Render ////////////////////////////////////////////////

  render(): React.ReactNode {
    let content: React.ReactNode;
    if (this.state.loadedForRead === false) {
      content = <Wait centered size="L" />;
    } else if (!this.state.allowedAccess) {
      content = <AccessDeniedPage />;
    } else if (_.isEmpty(this.state.availableProducts)) {
      content = <FullErrorPage errorMessage={this.props.intl.formatMessage(localeMessages.noProductError)} />;
    } else {
      const resourceTabNames: string[] = ProductResourceMenuUtils.resourceTabNames(this.state.selectedProduct);
      content = (
        <div data-testid="product-allocation-page" className="App__content">
          <div>
            <Heading className="App__header">
              <FormattedMessage id="productAllocation.header.title" defaultMessage="Product allocation" />
              <GoHelpBubble goUrlKey={GoUrlKeys.productAllocation} classNameToUse="HelpBubble__helpBubble_left">
                <p>{this.props.intl.formatMessage(localeMessages.PaneHelp)}</p>
              </GoHelpBubble>
            </Heading>
            {!this.state.loadedForWrite && !this.state.errorMessage && (
              <span className="ProductAllocation__header__writeLoad" data-testid="prodalloc-write-load">
                <Wait size="S" />
                <span className="ProductAllocation__header__writeLoad__message">
                  <FormattedMessage
                    id="productAllocation.header.writeLoad"
                    defaultMessage="Editing will be enabled in a few moments."
                  />
                </span>
              </span>
            )}

            {/* Import, Export, Make It So buttons */}
            <span className="App__headerButtons">
              <Button variant="primary" onClick={this.onRefreshData} data-testid="org-refresh-buttom">
                <FormattedMessage id="productAllocation.refreshButton" defaultMessage="Refresh data" />
              </Button>
              {!AdminPermission.readOnlyMode() && (
                <Button
                  disabled={!this.state.enableMakeItSo}
                  variant={this.state.enableMakeItSo ? 'cta' : 'primary'}
                  onClick={this.onMakeItSoClicked}
                  data-testid="prodalloc-review-pending-changes-button"
                >
                  <FormattedMessage id="productAllocation.header.makeItSo" defaultMessage="Review pending changes" />
                </Button>
              )}
              <Dropdown alignRight closeOnSelect className="ProductAllocation__header__buttons__importExport__dropdown">
                <Button
                  dropdownTrigger
                  className="ProductAllocation__header__buttons__importExport__button"
                  aria-label="import and export options"
                  data-testid="prodalloc-import-export-menu-button"
                >
                  <More size="S" />
                </Button>
                <Menu dropdownMenu>
                  {/* Use localization in formatMessage function for menus otherwise labels won't change when localization changes */}
                  <MenuItem
                    label={this.props.intl.formatMessage(localeMessages.import)}
                    onClick={this.onImportClicked}
                    disabled={!this.state.loadedForWrite || AdminPermission.readOnlyMode()}
                    data-testid="prodalloc-import"
                  />
                  <MenuItem
                    label={`${this.props.intl.formatMessage(localeMessages.export)} ${ProdAllocFiles.CSV.label}`}
                    onClick={(): void => this.onExportClicked(ProdAllocFiles.CSV.extName)}
                    data-testid="prodalloc-export-csv"
                  />
                  <MenuItem
                    label={`${this.props.intl.formatMessage(localeMessages.export)} ${ProdAllocFiles.JSON.label}`}
                    onClick={(): void => this.onExportClicked(ProdAllocFiles.JSON.extName)}
                    data-testid="prodalloc-export-json"
                  />
                </Menu>
              </Dropdown>
            </span>
            <Rule variant="small" />
          </div>
          {/* Product Allocation Table */}
          <div className="ProductAllocation__body">
            {!_.isNil(this.state.globalLicenseTuple) && this.state.globalLicenseTuple.shouldShowExpireMessages() && (
              <ExpirationBanner className="ProductAllocation__alert" licenseTuple={this.state.globalLicenseTuple} />
            )}
            {this.state.errorMessage && (
              <span data-testid="prodalloc-alertBanner">
                <AlertBanner className="ProductAllocation__alert" variant="error">
                  {this.state.errorMessage}
                </AlertBanner>
              </span>
            )}
            {CommandService.doesReparentEditsExist() && (
              <span data-testid="prodalloc-alertBanner">
                <AlertBanner className="ProductAllocation__alert" variant="info">
                  {this.props.intl.formatMessage(MESSAGES.ReparentUserNotification)}
                </AlertBanner>
              </span>
            )}
            <div className="ProductAllocation__table__container" data-testid="prodalloc-table-container">
              {/* Product Selector */}
              <ProdAllocProductSelector
                width="size-5000"
                label={this.props.intl.formatMessage(localeMessages.productSelectorLabel)}
                selectedProduct={this.state.selectedProduct}
                options={ProductResourceMenuUtils.productMenuItemSections(this.state.availableProducts)}
                onProductSelect={this.onProductSelected}
                data-testid="prodalloc-product-selector"
              />
              {this.state.selectedProduct && this.state.loadedForWrite && !AdminPermission.readOnlyMode() && (
                // button to show / hide trashcan icons
                <div className="ProductAllocation__header__deleteToggleButton">
                  <Button onClick={this.toggleTrashIcons} quiet variant="action" data-testid="delete-product-toggle">
                    <span className="spectrum-Link">
                      {this.state.hideTrashIcons ? (
                        <span data-testid="enable-delete-product-text">
                          <FormattedMessage
                            id="productAllocation.header.enableRemoval"
                            defaultMessage="Enable product removal"
                          />
                        </span>
                      ) : (
                        <span data-testid="disable-delete-product-text">
                          <FormattedMessage
                            id="productAllocation.header.disableRemoval"
                            defaultMessage="Disable product removal"
                          />
                        </span>
                      )}
                    </span>
                  </Button>
                </div>
              )}
              {this.state.selectedProduct && (
                // Resource Tabs
                <span data-testid="resource-tabs">
                  <TabList
                    selectedIndex={
                      this.state.selectedResource
                        ? ProductResourceMenuUtils.tabIndexFromResource(
                            this.state.selectedResource,
                            this.state.selectedProduct.getQuotaResources(true)
                          )
                        : 0
                    }
                    onChange={this.onResourceSelected}
                  >
                    {_.map(
                      resourceTabNames,
                      (tabName: string, index: number): React.ReactNode => (
                        <Tab key={index} data-testid="prodalloc-resource-tab-select">
                          {tabName}
                        </Tab>
                      )
                    )}
                  </TabList>
                </span>
              )}

              {this.state.selectedProduct && this.state.selectedResource ? (
                <div data-testid="product-allocation-table" className="ProductAllocation__table">
                  <TreeTable
                    value={[
                      TreeTableData.createTreeTableData(
                        this.state.orgList,
                        this.state.selectedProduct as UProduct,
                        this.state.selectedResource as UResource,
                        this.state.resourceCalculationMap,
                        this.state.highlightMap
                      ),
                    ]}
                    headerColumnGroup={this.getTableHeader()}
                    onToggle={(event: { originalEvent: Event; value: ExpandedNodes }): void => {
                      // update the expanded nodes
                      ExpandedNodesUtil.updateExpandedNodes(event.value);
                      // re-render
                      this.setState({});
                    }}
                    expandedKeys={ExpandedNodesUtil.getExpandedNodes()}
                  >
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__compartments"
                      field="orgName"
                      body={this.orgNameField}
                      data-testid="org-name-field"
                      expander
                    />
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__grant"
                      field={ColumnField.GRANT}
                      body={this.grantInputField}
                    />
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__right"
                      field={ColumnField.TOTAL_ALLOC}
                      body={this.totalAllocGrantOverField}
                    />
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__right"
                      field={ColumnField.LOCAL_LICENSED_QUANTITY}
                      body={this.localLicensedQuantityField}
                    />
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__right"
                      field={ColumnField.LOCAL_USE}
                      body={this.localUseField}
                    />
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__right"
                      field={ColumnField.TOTAL_USE}
                      body={this.totalUseField}
                    />
                    <Column
                      className="ProductAllocation__table__column ProductAllocation__table__column__centered"
                      field={ColumnField.ALLOW_OVER_ALLOC}
                      body={this.allowOverAllocField}
                    />
                    {this.state.showOverUsePolicyColumn && (
                      <Column
                        className="ProductAllocation__table__column ProductAllocation__table__column__centered"
                        field={ColumnField.ALLOW_OVER_USE}
                        body={this.allowOverUseField}
                      />
                    )}
                  </TreeTable>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      );
    }
    return content;
  }
}
export default injectIntl(withRouter(ProductAllocation));
