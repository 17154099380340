import React from 'react';
import ErrorMessageIntl from '../UserMessage/ErrorMessageIntl';
import { MESSAGES } from '../../Messages';

interface SearchErrorProps {
  showError: boolean;
}

class SearchError extends React.Component<SearchErrorProps> {
  public render(): React.ReactNode {
    return (
      <ErrorMessageIntl
        showErrorMsg={this.props.showError}
        msgId={MESSAGES.SearchError}
        className="EditCompartment__margin--bottom EditCompartment_errorMessage"
      />
    );
  }
}

export default SearchError;
