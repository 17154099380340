import { defineMessages, MessageDescriptor } from 'react-intl';
import config from '../configurations/config';
import BanyanSvcHeader from '../services/providerUtils/generateBanyanSvcHeaders';
import Utils from '../services/utils/Utils';
import { OrgReparentProductsReport, ReparentIds } from '../services/reparent';

const url = `${config.banyansvc.url}`;

export const localeMessages: Record<string, MessageDescriptor> = defineMessages({
  GetReparentProductsReportApiError: {
    id: 'OrgReparentProductsCheckProvider.GetReport.Error',
    defaultMessage: 'Unable to load validation data for reparent operation',
  },
});

class OrgReparentProductsCheckProvider {
  /**
   * Retrieves whether there are any issues or info for the user in re-parenting products.
   * This api handles multiple re-parent products for multiple re-parent org operations
   * (This call has a limit of 100 pairs of org-to-reparent ids and destination-org ids)
   */
  static async orgReparentProductsCheck(multipleReparentIds: ReparentIds[]): Promise<OrgReparentProductsReport> {
    const response: Response = await fetch(`${url}/check-reparent-products`, {
      method: 'POST',
      headers: BanyanSvcHeader.generateBanyanSvcHeaders(),
      body: JSON.stringify(multipleReparentIds),
    });
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, localeMessages.GetReparentProductsReportApiError);
    return respBody;
  }
}
export default OrgReparentProductsCheckProvider;
