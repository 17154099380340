import React from 'react';
import * as _ from 'lodash';
import { FormattedNumber } from 'react-intl';
import { CAP_UNLIMITED } from '../orgMaster/OrgMaster';
import Utils from '../../services/utils/Utils';

class NumberFormatter {
  static formatNumber(value: string): string | JSX.Element {
    return _.isEqual(value, CAP_UNLIMITED) ? (
      // using String.toLowerCase instead of _.lowerCase because String.toLowerCase properly retains localization
      // and does not remove special characters or substitute with English characters.
      Utils.localizedUnlimited().toLowerCase()
    ) : (
      <FormattedNumber value={parseInt(value, 10)} />
    );
  }
}

export default NumberFormatter;
