import * as log from 'loglevel';
import _ from 'lodash';
import { AuthWindow } from './IMS';
import { ImsData, ImsLibData } from './ImsData';
import config from '../../configurations/config';
import UserProfileService from './UserProfileService';

declare const window: AuthWindow;

// Authentication through IMS: https://git.corp.adobe.com/pages/IMS/imslib2.js/ Entry point: SetupIMS()
// 1) Set window.adobeId with correct properties
// 2) Inject imslib.min.js to main page, which initializes window.adobeIMS (That shows the adobe sign in dialog for user to log in)
// 3) Once user is signed in, window.adobeIMS invokes relevant methods as defined on window.adobeId in ImsData.ts
class Authentication {
  static loggedIn(): boolean {
    if (window.adobeIMS) {
      return window.adobeIMS.isSignedInUser();
    }
    return false;
  }

  static signOut(): void {
    if (window.adobeIMS) {
      window.adobeIMS.signOut();
    } else {
      log.error('signOut failed, no user logged in');
    }
  }

  /**
   * Inject imslib.min.js to main app, which initializes window.adobeIMS using window.adobeId
   * When imsLib triggers events ( e.g. onAccessToken, onReady, onAccessTokenHasExpired, etc) as defined on window.adobeId in ImsData.ts,
   * given callback "onStateChanged" is invoked which allows banyanui app to update state based on authenticated user profile.
   *
   * Api Ref: https://git.corp.adobe.com/pages/IMS/imslib2.js/classes/_adobe_ims_adobeims_.adobeims.html
   * Code ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/demo-apps/react/src/ims/Ims-Cdn.js
   *
   * @param onStateChanged callback invoked from ImsData.ts when imsLib triggers various events
   */
  static setupIMS(onStateChanged: (data: ImsLibData) => void): void {
    // Initializes window.adobeId object, configuration setting for IMS. IMS lib requires this object.
    // Code ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/demo-apps/react/src/ims/Ims-Cdn.js
    // API ref: https://git.corp.adobe.com/pages/IMS/imslib2.js/interfaces/_adobe_id_iadobeiddata_.iadobeiddata.html
    const imsData = new ImsData(onStateChanged); // onStateChanged() is updateState() of App.tsx

    window.adobeid = imsData.adobeIdData;

    const imsLibTag = document.createElement('script');
    imsLibTag.src = config.imsLib.imsLibUrl;
    document.body.appendChild(imsLibTag);
  }

  /**
   * Generate new profile and a user token for a given userId, by switching current user profile of an authenticated user.
   * This is used when an authenticating user has multiple profiles - T2E and non-T2E, and wants to switch between them without having to signout and signin again.
   *
   * Note: Authenticating user can have multiple userId(s) - one for each type of user profile.
   * Supplied "userId" must belong to already authenticated user.
   *
   * API Ref: https://git.corp.adobe.com/pages/IMS/imslib2.js/classes/_adobe_ims_adobeims_.adobeims.html#switchprofile
   * window.adobeIMS.switchProfile() calls ims api and updates "window.adobeIMS" object with the new token for "userId"
   *
   * @param userId user id used for getting the new token and profile
   */
  static async switchProfile(userId?: string): Promise<void> {
    if (userId && !_.isEqual(userId, UserProfileService.getUserId()) && window.adobeIMS) {
      try {
        await window.adobeIMS?.switchProfile(userId);
        await UserProfileService.initializeUserProfile(); // initialize profile for "userId"
      } catch (error) {
        log.error(`Failed to switchProfile. ${error}`);
      }
    }
  }
}

export default Authentication;
