import * as _ from 'lodash';
import { OrgAdminType, OrgLevelAdminType, UserType } from '../authentication/IMS';

/* eslint-disable import/no-cycle */
import { UBasic, UBasicData } from './UBasic';
import { UOrgMaster } from './UOrgMaster';
import { UProduct } from './UProduct';
/* eslint-enable import/no-cycle */

/**
 * Operations for UAdminTarget which denote whether the target will be added or removed for the admin role.
 * This denotes the action the user intended to make for admin roles.
 */
export enum UAdminOperation {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

/**
 * Target information for an admin (UAdmin) (the org, product, profile, contract, user group a single role is targeting).
 * UAdmin contains multiple UAdminTargets denoting the multiple possible targeting info for any role.
 * This interface is compatible with the JSON format for the targets from the back-end.
 */
export interface UAdminTarget {
  name?: string; // name of the admin target (profile name or userGroup name) (does not include product name)
  orgId?: string; // if admin is org level admin, this field contains the org id, otherwise it is undefined
  productId?: string; // if admin is a product admin or profile admin, this field contains the product id, otherwise it is undefined
  profileId?: string; // if admin is a profile admin, this field contains the profile id, otherwise it is undefined
  userGroupId?: string; // if admin is a user group admin, this field contains the user group id, otherwise it is undefined
  contractId?: string; // if admin is a contract admin, this field contains the contract id, otherwise it is undefined
  operation?: UAdminOperation; // denotes whether this target is being added or removed for the admin role.  undefined means no change is intended for this target
}

/**
 * Target information specifically for the targets of a contract admin.
 */
export interface AdminContractInfo {
  id?: string; // id of the contract administered by contract admin
}

/**
 * Target information specifically for the targets of a product admin.
 */
export interface AdminProductInfo {
  id?: string; // product id of product administered by product admin
}

/**
 * Target information specifically for the targets of a profile admin.
 */
export interface AdminProfileInfo {
  id?: string; // profile id of profile administered by profile admin
  productId?: string; // product id of product associated with profile
  name?: string; // name of profile
}

/**
 * Target information specifically for the targets of a user group admin.
 */
export interface AdminUserGroupInfo {
  id?: string; // user group id of user group administered by user group admin
  name?: string; // name of user group
}

type AdminRoles = keyof typeof OrgAdminType; // key strings representing the names of each admin role (used as keys for the RoleMap)
type RoleMap = { [key in AdminRoles]?: UAdminTarget[] }; // mapping of admin roles to target data

/**
 * UAdmin representing a single user and all of its admin roles and targets.
 * (A single user only has 1 UAdmin object)
 */

/**
 * Mutable object for UAdmin.
 * Provides only properties of UAdmin.
 * Represents UAdmin object that can be retrieved from the back-end.
 */
export interface UAdminData extends UBasicData {
  // id is actually the user id and not a combination of the email, user type, and admin role
  orgId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  userType?: UserType;
  countryCode?: string;
  domain?: string;
  username?: string;
  roles?: RoleMap; // map of admin roles to targets (if a user lacks an admin role, the admin role key will be undefined or have an empty UAdminTarget array)
  adminOrder?: number; // sort order by jil
}

/**
 * UAdmin object that also contains utility methods and functionality.
 */
export class UAdmin extends UBasic implements UAdminData {
  orgId: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  userType?: UserType;
  countryCode: string = '';
  domain: string = '';
  username: string = '';
  roles: RoleMap = {};
  adminOrder?: number = 0;

  static ROLE_SEPARATOR = ', ';

  constructor(admin?: UAdminData) {
    super(admin);
    if (admin) {
      this.orgId = admin.orgId || this.orgId;
      this.firstName = admin.firstName || this.firstName;
      this.lastName = admin.lastName || this.lastName;
      this.email = admin.email || this.email;
      this.userType = admin.userType || this.userType;
      this.countryCode = admin.countryCode || this.countryCode;
      this.domain = admin.domain || this.domain;
      this.username = admin.username || this.username;
      this.roles = admin.roles || this.roles;
      this.adminOrder = admin.adminOrder || this.adminOrder;
    }
  }

  /**
   * Converts a boolean indicating whether the admin has an admin role to an operation
   *
   * @param isAdmin boolean indicating whether the admin has an admin role
   * @returns operatoin converted from boolean
   */
  private static boolToOperation(isAdmin: boolean): UAdminOperation {
    return isAdmin ? UAdminOperation.ADD : UAdminOperation.REMOVE;
  }

  /**
   * Retrieves array of targets for an admin role.
   * Empty array is provided if targets array is undefined for a given role.
   * This method should be used for getting targets instead of accessing this.roles directly
   * as it is safer because it always provides an array.
   * (Note: We cannot initialize arrays for all roles as that would create unnecessary entries for roles
   * that the admin does not have.)
   *
   * @param adminRole admin role to retrieve targets for
   * @returns list of targets for the admin role
   */
  private getTargets(adminRole: OrgAdminType): UAdminTarget[] {
    const targets: UAdminTarget[] | undefined = this.roles[adminRole];
    if (_.isNil(targets)) {
      return [];
    }
    return targets;
  }

  /**
   * Retrieves array of targets for admin role for which the admin is actively an admin of.
   *
   * @param adminRole admin role to retrieve targets for
   * @returns list of targets for the admin role
   */
  private getActiveTargets(adminRole: OrgAdminType): UAdminTarget[] {
    const targets: UAdminTarget[] = this.getTargets(adminRole);
    return _.filter(targets, (target: UAdminTarget): boolean => target.operation !== UAdminOperation.REMOVE);
  }

  /**
   * Retrieves the names of all admin roles associated with this admin.
   * (Warning: This does not check if there are any targets associated with those roles)
   *
   * @returns names ofall admin roles assocaited with this admin
   */
  private getRoleNames(): OrgAdminType[] {
    return _.keys(this.roles) as OrgAdminType[];
  }

  /**
   * Retrieves all targets for all admin roles associated with this admin (regardless of the admin is admin for those targets).
   * (Warning: You will not be able to differentiate the admin roles for the targets once retrieved from this method)
   *
   * @returns array of all targets for all admin roles
   */
  private getAllTargets(): UAdminTarget[] {
    let targets: UAdminTarget[] = [];
    const roles: OrgAdminType[] = this.getRoleNames();
    _.forEach(roles, (role: OrgAdminType): void => {
      targets = targets.concat(this.getTargets(role));
    });
    return targets;
  }

  /**
   * Determines whether 2 targets are considered the same based on admin role.
   * This is primarily used for querying purposes.
   *
   * @param adminRole admin role which denotes the type of comparison for the targets
   * @param target target to compare with otherTarget
   * @param otherTarget target to compare with target
   * @returns true if the targets are the same by admin role
   */
  private static compareTargets(adminRole: OrgAdminType, target: UAdminTarget, otherTarget: UAdminTarget): boolean {
    if (adminRole === OrgAdminType.PRODUCT_ADMIN) {
      return target.productId === otherTarget.productId;
    }
    if (adminRole === OrgAdminType.LICENSE_ADMIN) {
      return target.profileId === otherTarget.profileId && target.productId === otherTarget.productId;
    }
    if (adminRole === OrgAdminType.USER_GROUP_ADMIN) {
      return target.userGroupId === otherTarget.userGroupId;
    }
    if (adminRole === OrgAdminType.CONTRACT_ADMIN) {
      return target.contractId === otherTarget.contractId;
    }
    return target.orgId === otherTarget.orgId;
  }

  /**
   * Finds a target from the admin using a request target to specify the id(s) to look up with.
   *
   * @param adminRole admin role of the target to look up
   * @param requestedTarget target used to identify the target to find
   * @returns the requested target or undefined if the target could not be found
   */
  private findTarget(adminRole: OrgAdminType, requestedTarget: UAdminTarget): UAdminTarget | undefined {
    return _.find(this.getTargets(adminRole), (target: UAdminTarget): boolean =>
      UAdmin.compareTargets(adminRole, requestedTarget, target)
    );
  }

  /**
   * Finds a target from the admin using a request target to specify the id(s) for which the admin is actively an admin of.
   * (Targets with REMOVE operation are not included in result)
   *
   * @param adminRole admin role of the targe to look up
   * @param requestedTarget target used to identify the target to find
   * @returns the requested target or undefined if the target could not be found or is being removed
   */
  private findActiveTarget(adminRole: OrgAdminType, requestedTarget: UAdminTarget): UAdminTarget | undefined {
    const target: UAdminTarget | undefined = this.findTarget(adminRole, requestedTarget);
    if (target && target.operation === UAdminOperation.REMOVE) {
      return undefined;
    }
    return target;
  }

  /**
   * Adds target for this admin.
   * This method should be used for adding targets instead of accessing this.roles directly,
   * since this method handles initializing the role.
   *
   * @param adminRole admin role associated with the target
   * @param target target to add to this admin
   */
  private addTarget(adminRole: OrgAdminType, target: UAdminTarget): void {
    if (_.isNil(this.roles[adminRole])) {
      this.roles[adminRole] = [];
    }
    this.roles[adminRole]?.push(target);
  }

  /**
   * Removes target for this admin using a request target to specify id(s) to look up with.
   * This method should be used for removing targets instead of accessing this.roles directly,
   * since this method handles removing the target if it exists or if the this.roles is uninitialized.
   *
   * @param adminRole admin role associated with the target
   * @param requestedTarget target used to identify which target to remove
   */
  private removeTarget(adminRole: OrgAdminType, requestedTarget: UAdminTarget): void {
    if (!_.isNil(this.roles[adminRole])) {
      _.remove(this.roles[adminRole] as UAdminTarget[], (target: UAdminTarget): boolean =>
        UAdmin.compareTargets(adminRole, requestedTarget, target)
      );
    }
  }

  /**
   * Handles updating admin roles and targets and operations for those targets.
   * This method should be used by all public methods that modify admin roles and targets.
   *
   * @param adminRole admin role associated with the target being updated
   * @param requestedTarget target to identify the target the update or the target to add
   * @param operation whether to add or remove the target
   */
  private updateAdmin(
    adminRole: OrgAdminType,
    requestedTarget: UAdminTarget,
    operation: UAdminOperation | undefined
  ): void {
    const existingTarget: UAdminTarget | undefined = this.findTarget(adminRole, requestedTarget);
    if (!_.isNil(existingTarget)) {
      if (existingTarget.operation === UAdminOperation.ADD && operation !== UAdminOperation.ADD) {
        // Target was requested to add, but is now requested to remove or remove operation (undefined) which means:
        // Target has been requested to revert add operation: remove target since it won't be added (and will match back-end data)
        this.removeTarget(adminRole, existingTarget);
      } else if (_.isNil(existingTarget.operation) && operation === UAdminOperation.REMOVE) {
        // Target is requested to remove: set remove operation so that it will be removed by the service
        existingTarget.operation = UAdminOperation.REMOVE;
      } else if (existingTarget.operation === UAdminOperation.REMOVE && operation !== UAdminOperation.REMOVE) {
        // Target was requested to remove, but is now requested to add or remove operation (undefined) which means:
        // Target has been requested to revert remove operation: set operation undefined, since it won't be deleted (and will match back-end data)
        existingTarget.operation = undefined;
      }
    } else if (_.isNil(existingTarget) && operation === UAdminOperation.ADD) {
      // Target is requested to add: add target with add operation so that it will be added by the service
      const newTarget: UAdminTarget = _.cloneDeep(requestedTarget);
      newTarget.operation = UAdminOperation.ADD;
      this.addTarget(adminRole, newTarget);
    }
    // Cases for which no action will be done
    // - Target was requested to add and is being requested to add again
    // - Target was requested to be removed and is being requested to remove again
    // - Target was requested to remove operation (undefined) but it doesn't have an operation
    // - Target exists (was loaded from back-end data) and is being requested to add
    // - Target does not exist (when loaded from back-end data) and is being requested to remove
    // - Target does not exist in (when loaded from back-end data) and is being requested to remove operation (undefined)
  }

  /**
   * Determines whether any target for a role has been modified.
   * (Any target has an operation value)
   *
   * @param adminRole admin role to check if it has been modified
   * @returns true if any target for a role has been modified.
   */
  hasAnyEditsForRole(adminRole: OrgAdminType): boolean {
    return !!_.find(this.getTargets(adminRole), (target: UAdminTarget): boolean => !_.isNil(target.operation));
  }

  hasAnyEdits(): boolean {
    return !!_.find(this.getAllTargets(), (target: UAdminTarget): boolean => !_.isNil(target.operation));
  }

  /**
   * Determines whether the user has an existing admin role for its member org
   * regardless of how the role has been edited.
   */
  roleExists(adminRole: OrgAdminType): boolean {
    return !_.isEmpty(this.getTargets(adminRole));
  }

  /**
   * Determines whether the user is an admin of the given role for its member org
   */
  isAdminOfRole(adminRole: OrgAdminType): boolean {
    return !_.isEmpty(this.getActiveTargets(adminRole));
  }

  /**
   * Determines whether the user is a global admin for its member org
   */
  isGlobalAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.COMPARTMENT_ADMIN);
  }

  /**
   * Determines whether the user is a global viewer for its member org
   */
  isGlobalAdminReadOnly(): boolean {
    return this.isAdminOfRole(OrgAdminType.COMPARTMENT_VIEWER);
  }

  /**
   * Determines whether the user is a system admin for its member org
   */
  isSysAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.ORG_ADMIN);
  }

  /**
   * Determines whether the user is a deployment admin for the its member org
   */
  isDeploymentAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.DEPLOYMENT_ADMIN);
  }

  /**
   * Determines whether the user is a support admin for its member org
   */
  isSupportAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.SUPPORT_ADMIN);
  }

  /**
   * Determines whether the user is a storage admin for its member org
   */
  isStorageAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.STORAGE_ADMIN);
  }

  /**
   * Determines whether the user is a product admin for any product on its member org
   */
  isProductAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.PRODUCT_ADMIN);
  }

  /**
   * Determines whether the user is a product admin of a specific product on its member org
   */
  isProductAdminOf(productId: string): boolean {
    return !!this.findActiveTarget(OrgAdminType.PRODUCT_ADMIN, { productId });
  }

  /**
   * Determines whether the user is a profile admin for any profile on its member org
   */
  isProfileAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.LICENSE_ADMIN);
  }

  /**
   * Determines whether the user is a profile admin of a specific profile on its member org
   */
  isProfileAdminOf(profileId: string, productId: string): boolean {
    return !!this.findActiveTarget(OrgAdminType.LICENSE_ADMIN, { profileId, productId });
  }

  /**
   * Determines whether the user is a user group adin for any user group on its member org
   */
  isUserGroupAdmin(): boolean {
    return this.isAdminOfRole(OrgAdminType.USER_GROUP_ADMIN);
  }

  /**
   * Determines whether the user is a user group admin of a specific user group on its member org
   */
  isUserGroupAdminOf(userGroupId: string): boolean {
    return !!this.findActiveTarget(OrgAdminType.USER_GROUP_ADMIN, { userGroupId });
  }

  /**
   * Retrieve all target data specific for products associated with the product admin role of the user on its member org.
   * Only returns the data for targets that the admin is a product admin of.
   */
  getAdminProductInfo(): AdminProductInfo[] {
    return _.map(
      this.getActiveTargets(OrgAdminType.PRODUCT_ADMIN),
      (target: UAdminTarget): AdminProductInfo => ({ id: target.productId })
    );
  }

  /**
   * Retrieve all target data specific for profiles associated with the profile admin role of the user on its member org.
   * Only returns the data for targets that the admin is a profile admin of.
   */
  getAdminProfileInfo(): AdminProfileInfo[] {
    return _.map(
      this.getActiveTargets(OrgAdminType.LICENSE_ADMIN),
      (target: UAdminTarget): AdminProfileInfo => ({
        id: target.profileId,
        productId: target.productId,
        name: target.name,
      })
    );
  }

  /**
   * Retrieve all target data specific for user groups associated with the profile admin role of the user on its member org.
   * Only returns the data for targets that the admin is a user group admin of.
   */
  getAdminUserGroupInfo(): AdminUserGroupInfo[] {
    return _.map(
      this.getActiveTargets(OrgAdminType.USER_GROUP_ADMIN),
      (target: UAdminTarget): AdminUserGroupInfo => ({ id: target.userGroupId, name: target.name })
    );
  }

  /**
   * Retrieve all target data specific for contracts associated with the contract admin role of the user on its member org.
   * Only returns the data for targets that the admin is a contract admin of.
   */
  getAdminContractInfo(): AdminContractInfo[] {
    return _.map(
      this.getActiveTargets(OrgAdminType.CONTRACT_ADMIN),
      (target: UAdminTarget): AdminContractInfo => ({ id: target.contractId })
    );
  }

  /**
   * Convert lists of either AdminProductInfo, AdminProfileInfo, AdminUserGroupInfo, AdminContractInfo to a list of ids
   * (product ids or profile ids or user group ids)
   */
  static adminInfosToIds(
    infos: AdminProductInfo[] | AdminProfileInfo[] | AdminUserGroupInfo[] | AdminContractInfo[]
  ): string[] {
    // map info objects to ids omitting the undefined or empty ids
    return _.reduce(
      infos,
      (idList: string[], info: AdminProductInfo | AdminProfileInfo | AdminUserGroupInfo): string[] => {
        if (info.id) {
          idList.push(info.id);
        }
        return idList;
      },
      []
    );
  }

  /**
   * Retrieve a list of all admin roles for which the admin is an admin for on its member org.
   * This method returns only admin roles that the admin is an admin for (excluding roles with only REMOVED targets)
   */
  getAdminRoles(): OrgAdminType[] {
    return _.filter(this.getRoleNames(), (roleName: OrgAdminType): boolean => this.isAdminOfRole(roleName));
  }

  /**
   * Retrieve a list of products associated with the product admin role of the user on its member org.
   * Org must be provided to retrieve the products from.
   * Only retrieves products for which the admin is a product admin of.
   * If the user is not a product admin, an empty array is returned.
   */
  getAdminProducts(org: UOrgMaster): UProduct[] {
    const products: UProduct[] = [];
    if (this.isProductAdmin()) {
      // using foreach instead of map to prevent entries for undefined results
      _.forEach(this.getActiveTargets(OrgAdminType.PRODUCT_ADMIN), (target: UAdminTarget): void => {
        if (target.productId) {
          const product: UProduct | undefined = org.getProduct(target.productId);
          if (product) {
            products.push(product);
          }
        }
      });
    }
    return products;
  }

  /**
   * Updates org level admin target for org level admin.
   * This method adds or removes the org level admin role on the admin
   * (since each org level admin generally only has 1 target)
   *
   * @param adminRole role associated with target to upate (must be org level admin role)
   * @param addRole whether the admin will be made admin of role or removed as admin of role
   */
  updateOrgLevelAdminFor(adminRole: OrgLevelAdminType, addRole: boolean): void {
    if (_.isEmpty(this.orgId)) {
      throw new Error('orgId required for setting org level admin type');
    }
    this.updateAdmin(adminRole, { orgId: this.orgId }, UAdmin.boolToOperation(addRole));
  }

  /**
   * Updates product admin target for product admin.
   * This can add or remove the product admin role for the admin
   * (if it is adding the first target or removing the last target).
   *
   * @param productId id of the product associated with the product admin
   * @param addRole whether the admin will be made product admin of role or removed as product admin of role
   */
  updateProductAdminFor(productId: string, addRole: boolean): void {
    this.updateAdmin(OrgAdminType.PRODUCT_ADMIN, { productId }, UAdmin.boolToOperation(addRole));
  }

  /**
   * Updates profile admin target for profile admin.
   * This can add or remove the profile admin role for the admin
   * (if it is adding the first target or removing the last target).
   *
   * @param profileId id of profile associated with the profile admin
   * @param profileName name of the profile associated with the profile admin (only used for UI display purposes)
   * @param org organization the admin is a memer of (necessary for access to products associated with profiles)
   * @param addRole whether the admin will be made profile admin of role or removed as profile admin of role
   */
  updateProfileAdminFor(profileId: string, profileName: string | undefined, org: UOrgMaster, addRole: boolean): void {
    const productId: string | null = org.lookupProductIdByProfileId(profileId);
    if (productId) {
      this.updateAdmin(
        OrgAdminType.LICENSE_ADMIN,
        { profileId, productId, name: profileName },
        UAdmin.boolToOperation(addRole)
      );
    }
  }

  /**
   * Updates user group admin target for user group admin.
   * This can add or remove the user group admin role for the admin
   * (if it is adding the first target or removing the last target).
   *
   * @param userGroupId id of the user group associated with the user group admin
   * @param userGroupName name of the user group associated with the user group admin (only used for UI display purposes)
   * @param addRole whether the admin will be made user group admin of role or removed as user group admin of role
   */
  updateUserGroupAdminFor(userGroupId: string, userGroupName: string | undefined, addRole: boolean): void {
    this.updateAdmin(
      OrgAdminType.USER_GROUP_ADMIN,
      { userGroupId, name: userGroupName },
      UAdmin.boolToOperation(addRole)
    );
  }

  /**
   * Updates contract admin target for contract admin.
   * This can add or remove the contract admin role for the admin
   * (if it is adding the first target or removing the last target).
   *
   * @param contractId id of the contract associated with the contract admin
   * @param addRole whether the admin will be made contract admin of role or removed as contract admin of role
   */
  updateContractAdminFor(contractId: string, addRole: boolean): void {
    this.updateAdmin(OrgAdminType.CONTRACT_ADMIN, { contractId }, UAdmin.boolToOperation(addRole));
  }

  /**
   * Add profile admin target for profile admin.
   * This can add the profile admin role for the admin (if it is adding the first target).
   *
   * @param profileId id of the profile associated with the profile admin
   * @param profileName name of the profile associated with the profile admin (only used for UI display purposes)
   * @param org organization the admin is a member of (necessary for access to products associated with profiles)
   * @param productId (optional) id of product associated with profile.  If not given, the product is looked up from the profile.
   *                  This is mostly used when adding a profile admin to a profile being created (since there's no profile yet to look up a product for)
   */
  addProfileAdminFor(profileId: string, profileName: string, org: UOrgMaster, productId?: string): void {
    const prodId: string | null = productId ? productId : org.lookupProductIdByProfileId(profileId);
    if (prodId) {
      this.updateAdmin(
        OrgAdminType.LICENSE_ADMIN,
        { profileId, productId: prodId, name: profileName },
        UAdminOperation.ADD
      );
    }
  }

  /**
   * Add user group admin target for user group admin.
   * This can add the user group admin role for the admin (if it is adding the first target).
   *
   * @param userGroupId id of the user group associated with user group admin
   * @param userGroupName name of the user group associated with the user group admin (only used for UI display purposes)
   */
  addUserGroupAdminFor(userGroupId: string, userGroupName: string): void {
    this.updateAdmin(OrgAdminType.USER_GROUP_ADMIN, { userGroupId, name: userGroupName }, UAdminOperation.ADD);
  }

  /**
   * Remove profile target for profile admin.
   * This can remove the profile admin role for the admin (if it removes the last target).
   *
   * @param profileId id of profile for the target being removed
   * @param productId id of product associated with profile for the target being removed
   */
  removeProfileAdminFor(profileId: string, productId: string): void {
    this.updateAdmin(OrgAdminType.LICENSE_ADMIN, { profileId, productId }, UAdminOperation.REMOVE);
  }

  /**
   * Remove user group target for user group admin.
   * This can remove the user group admin role for the admin (if it removes the last target).
   *
   * @param userGroupId id of user group for the target being removed
   */
  removeUserGroupAdminFor(userGroupId: string): void {
    this.updateAdmin(OrgAdminType.USER_GROUP_ADMIN, { userGroupId }, UAdminOperation.REMOVE);
  }

  /**
   * Removes all targets for all roles for the admin.
   * The admin will no longer be admin of any role.
   */
  removeAllAdminRoles(): void {
    const roles: OrgAdminType[] = this.getRoleNames();
    _.forEach(roles, (role: OrgAdminType): void => {
      const targets: UAdminTarget[] = this.getTargets(role);
      _.forEach(targets, (target: UAdminTarget): void => {
        this.updateAdmin(role, target, UAdminOperation.REMOVE);
      });
    });
  }

  /**
   * Clears edit for product target for product admin.
   * This is different from remove because it is not requesting the target be removed but
   * is instead clearing out any request to modify the target.
   *
   * @param productId id of product for the target being cleared
   */
  clearProductAdminEditFor(productId: string): void {
    this.updateAdmin(OrgAdminType.PRODUCT_ADMIN, { productId }, undefined);
  }

  /**
   * Clears edit for profile targe for profile admin.
   * This is different from remove because it is not requesting the target be removed but
   * is instead clearing out any request to modify the target.
   *
   * @param profileId id of profile for the target being cleared
   * @param productId id of product associated with profile
   */
  clearProfileAdminEditFor(profileId: string, productId: string): void {
    this.updateAdmin(OrgAdminType.LICENSE_ADMIN, { profileId, productId }, undefined);
  }

  /**
   * Clears edit for user group target for user group admin.
   * This is different from remove because it is not requesting the target be removed but
   * is instead clearing out any request to modify the target.
   *
   * @param userGroupId id of user group for the target being cleared
   */
  clearUserGroupAdminEditFor(userGroupId: string): void {
    this.updateAdmin(OrgAdminType.USER_GROUP_ADMIN, { userGroupId }, undefined);
  }

  /**
   * Removes all operations for all targets for all roles for the admin.
   */
  clearAllEdits(): void {
    const roles: OrgAdminType[] = this.getRoleNames();
    _.forEach(roles, (role: OrgAdminType): void => {
      const targets: UAdminTarget[] = this.getTargets(role);
      _.forEach(targets, (target: UAdminTarget): void => {
        this.updateAdmin(role, target, undefined);
      });
    });
  }

  /**
   * Add admin role targets from a source UAdmin that don't alreay exist in this UAdmin to this UAdmin.
   *
   * @param admin admin to merge targets into this admin
   */
  mergeAdminRoles(admin: UAdmin): void {
    _.forEach(admin.getRoleNames(), (roleName: OrgAdminType): void => {
      _.forEach(admin.getTargets(roleName), (target: UAdminTarget): void => {
        if (!this.findTarget(roleName, target)) {
          this.addTarget(roleName, target);
        }
      });
    });
  }

  /**
   * Generates a string listing all roles on an admin that have been requested for modification.
   */
  updatedRoleNames(): string {
    const modifiedRoles: string[] = [];
    _.forEach(this.getRoleNames(), (roleName: OrgAdminType): void => {
      if (this.hasAnyEditsForRole(roleName)) {
        modifiedRoles.push(roleName);
      }
    });
    return modifiedRoles.join(UAdmin.ROLE_SEPARATOR);
  }

  /**
   * Determines whether this admin and another admin both have modifications
   * or neither have modifications (having any operation)
   *
   * @param otherAdmin admin to compare against
   * @returns true if this admin and the other admin are both modified or not modified.
   */
  isEqualInEdits(otherAdmin: UAdmin): boolean {
    return this.hasAnyEdits() === otherAdmin.hasAnyEdits();
  }

  // Check if 2 admins are considered the same without comparing ids
  public static checkIfSame(admin1: UAdmin, admin2: UAdmin): boolean {
    return admin1.email === admin2.email && admin1.userType === admin2.userType;
  }

  /**
   * JSON representation of UAdmin.
   */
  toJSON(): object {
    return _.assign(super.toJSON(), {
      orgId: this.orgId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      userType: this.userType,
      countryCode: this.countryCode,
      domain: this.domain,
      username: this.username,
      roles: this.roles,
    });
  }
}
