import * as _ from 'lodash';
import FloodgateProvider from '../../providers/FloodgateProvider';

/**
 * reference:
 * API documentation https://p13n.corp.adobe.com/platform/help/get-fg-api-v3-feature/
 * Floodgate help https://p13n.corp.adobe.com/fg/help/
 *
 * Stage feature flags: https://stage.sophia.corp.adobe.com/sophia/#features/feature-flags/634
 * Prod feature flags: https://sophia.corp.adobe.com/sophia/#features/feature-flags/205
 *
 */
class FloodgateService {
  public static ENABLE_CONTRACT_ADMIN = 'enableContractAdmin';
  public static ENABLE_MANAGE_USER_GROUPS_ORG_POLICY = 'enableManageUserGroupsOrgPolicy';
  public static INCLUDE_WORKSPACE_POLICY = 'includeWorkspacePolicy';
  public static OVERWRITE_USER_GROUPS = 'overwriteUserGroups';
  public static RENDER_CONTRACT_NAMES = 'renderContractNames';
  public static RENDER_DOMAIN_ENFORCEMENT_STATUS = 'renderDomainEnforcementStatus';
  public static RESYNC_SHARED_USER_GROUPS = 'resyncSharedUserGroups';
  public static SUPPORT_TAB = 'enableSupportTab';
  static features: string[];

  static async initializeFeatureFlags(): Promise<void> {
    FloodgateService.features = await FloodgateProvider.getFeatureFlags();
  }

  //checks if feature is enabled
  static isFeatureEnabled(feature: string): boolean {
    return _.includes(FloodgateService.features, feature);
  }
}

export default FloodgateService;
