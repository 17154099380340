import * as _ from 'lodash';
import { UOrg } from '../orgMaster/UOrg';

/**
 * Store the current selection of org tree
 * (This class is required to remove the multiple callbacks for update and passing the tree view selection across multiple UI components)
 *
 */
class OrgSelectionUtil {
  private static readonly SELECTED_ORG_ID =
    'SelectedOrgId'; /* local storage key that stores the id of the org selected on the org tree */

  /**
   *
   * @param orgId: string
   * update the local storage on org selection
   * Note: this should be called on every root org change through org picker
   */
  public static updateOrgSelection(orgId: string): void {
    sessionStorage.setItem(OrgSelectionUtil.SELECTED_ORG_ID, orgId);
  }

  public static getSelectedOrgId(): string {
    return sessionStorage.getItem(OrgSelectionUtil.SELECTED_ORG_ID) as string;
  }

  public static unsetSelectedOrg(): void {
    sessionStorage.removeItem(OrgSelectionUtil.SELECTED_ORG_ID);
  }

  /**
   * finds if the last selected org can be found and sets it as selected org.
   * If not found(could have been deleted or could be a newly added org) it sets the root org as selected org
   */
  public static setSelectedOrg(orgList: UOrg[]): void {
    const selectedOrgId: string = OrgSelectionUtil.getSelectedOrgId();
    // If the selected org is not present in the list of edited orgs(example when the org is deleted), then set rootOrgId as last selected.
    if (!_.find(orgList, (org: UOrg) => org.id === selectedOrgId) && orgList.length > 0) {
      const rootOrgid = orgList[0].id;
      OrgSelectionUtil.updateOrgSelection(rootOrgid);
    }
  }
}

export default OrgSelectionUtil;
