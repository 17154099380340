import * as _ from 'lodash';
import { MessageDescriptor } from 'react-intl';
import Utils from '../utils/Utils';

export interface PageOptions {
  pageNumber?: number;
  pageSize?: number;
  sortOrder?: 'ASC' | 'DESC';
  searchQuery?: string;
}

export interface ResponseWithCounts {
  data: any; // the JSON response data from the API call
  totalCount: number; // Total number of elements in the list, example total number of admins for admins call
  pageCount: number; // Total number of pages as per the given page size
}

class ProviderUtil {
  private static TOTAL_COUNT_HEADER = 'x-total-count';
  private static PAGE_COUNT_HEADER = 'x-page-count';

  /**
   * Given a url and a query param, adds the query param to url and returns the updated url
   * If the query param is first one then return url is concatenation of url and '?' and the first param, then the updated url would look like: url?paramName=paramValue
   * If the query param is the second one then the return value is concatenation of url and '&' and second param, the updated url would look like url?firstParamName=firstParamValue&secondParamName=secondParamValue
   * @param updatedUrl
   * @param paramName
   * @param paramValue
   */
  public static getURLWithParam(updatedUrl: string, paramName: string, paramValue: string | number | boolean): string {
    const isFirstParam = !_.includes(updatedUrl, '?');
    return `${updatedUrl}${isFirstParam ? '?' : '&'}${paramName}=${paramValue}`;
  }

  /**
   * Add page options to the url
   * @param fetchUrl string
   * @param options PageOptions
   */
  public static addPageOptions(fetchUrl: string, options: PageOptions): string {
    let updatedUrl = fetchUrl;
    if (options.pageNumber !== undefined) {
      updatedUrl = this.getURLWithParam(updatedUrl, 'page', options.pageNumber);
    }
    if (options.pageSize !== undefined) {
      updatedUrl = this.getURLWithParam(updatedUrl, 'page_size', options.pageSize);
    }
    if (options.searchQuery !== undefined) {
      updatedUrl = this.getURLWithParam(updatedUrl, 'search_query', options.searchQuery);
    }
    if (options.sortOrder !== undefined) {
      updatedUrl = this.getURLWithParam(updatedUrl, 'sort_order', options.sortOrder);
    }
    return updatedUrl;
  }

  public static async getResponseWithCounts(
    response: Response,
    errMesage: MessageDescriptor
  ): Promise<ResponseWithCounts> {
    const pageCountString: string | null = response.headers.get(this.PAGE_COUNT_HEADER);
    const totalCountString: string | null = response.headers.get(this.TOTAL_COUNT_HEADER);
    const respBody = await response.json();
    Utils.throwIfError(response, respBody, errMesage);
    return {
      data: respBody,
      pageCount: Utils.parseIntOrDefault(pageCountString, 0),
      totalCount: Utils.parseIntOrDefault(totalCountString, 0),
    };
  }
}

export default ProviderUtil;
