import * as log from 'loglevel';
import config from '../../configurations/config';
import { LocaleSettings } from '../locale/LocaleSettings';
import { AdobeId, AuthWindow, Environment, TokenInformation } from './IMS';

/**
 * imslibData is NOT a data structure returned by imslib. It is owned and defined by banyanui.
 * Used to pass data from imslib event handlers such as onReady(), onAccessToken(), etc. to App.tsx
 *
 * Code Ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/demo-apps/react/src/ims/data/ims-data.js
 */
export interface ImsLibData {
  token?: TokenInformation; // Used only within this class to be able to call setHeartBeatInterval()
  tokenHasExpired: boolean;
}

declare const window: AuthWindow;

/**
 * This class encapsulates the data required and events triggered by imsLib2.
 *
 * adobeIdData: describes data required durig initialization of window.adobeIMS
 * imsLibData: Used to pass data from imslib event handlers such as onReady(), onAccessToken(), etc. to App.tsx
 *
 * API Ref: https://git.corp.adobe.com/pages/IMS/imslib2.js/
 * Code Ref: https://git.corp.adobe.com/IMS/imslib2.js/blob/master/demo-apps/react/src/ims/data/ims-data.js
 */
export class ImsData {
  static heartbeatTimerHandler: any;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onStateChanged: (data: ImsLibData) => void;

  imslibData: ImsLibData = {
    token: undefined,
    tokenHasExpired: false,
  };

  adobeIdData: AdobeId = {
    scope: config.imsLib.scopes,
    client_id: config.imsLib.clientId,
    locale: LocaleSettings.getLocale(),
    environment: Environment[config.imsLib.environment as keyof typeof Environment],
    useLocalStorage: false,
    // imslib flow: Upon successful authentication, imslib calls onAccessToken() followed by onReady().
    // Do not trigger callback (this.onStateChanged) from here, as it would be triggered from onReady().
    onAccessToken: (token: TokenInformation) => {
      // explicitly update local state as we do not want to call this.triggerOnStateChanged()
      this.imslibData = {
        ...this.imslibData,
        token,
      };
    },
    // This would never be triggered as such since Authentication.setHeartBeatInterval() invalidates session before token expires.
    // This simply acts as a fail-safe in addition to Authentication.setHeartBeatInterval()
    onAccessTokenHasExpired: () => {
      const imslibData = {
        ...this.imslibData,
        tokenHasExpired: true, // App.tsx handles token expiry behavior
      };
      this.triggerOnStateChanged(imslibData);
    },
    onReauthAccessToken: (/* reauthToken: TokenInformation */) => {
      log.debug('Ims lib onReauthAccessToken() called'); // banyanui doesn't use this as of this comment
    },
    onError: (errortype, error) => {
      log.error(`Ims lib onError() called with error type: ${errortype}. Reason: ${JSON.stringify(error)}`);
    },
    onReady: (context: any): void => {
      log.debug(`Ims lib onReady() context: ${JSON.stringify(context)}`);
      const imslibData = {
        ...this.imslibData,
      };
      this.setHeartBeatInterval();
      this.triggerOnStateChanged(imslibData);
    },
  };

  // heartbeat interval set to 15s, ensuring that we that we send user to SUSI login page
  // at least 15s before expiry, when earlyReauthMargin is 30s.
  setHeartBeatInterval = (): void => {
    if (this.imslibData.token) {
      const heartbeatIntervalMilliseconds = 15000;
      // Clear previous timer before creating a new one, since setHeartBeatInterval is called for every imslib event
      if (ImsData.heartbeatTimerHandler) {
        clearInterval(ImsData.heartbeatTimerHandler);
      }
      ImsData.heartbeatTimerHandler = setInterval(() => {
        if (!window.adobeIMS) {
          throw new Error('adobeIMS not defined on ready');
        }
        if (window.adobeIMS.isSignedInUser() && this.imslibData.token) {
          const now = new Date().getTime();
          const msRemainingTTL = this.imslibData.token.expire.getTime() - now;
          // Configure time parameters for early re-authentication
          const earlyReauthMargin = 30000;
          // If the token will be expiring in less than 30s,
          // then signout now instead of letting it expire.
          if (msRemainingTTL < earlyReauthMargin) {
            window.adobeIMS.signOut();
          }
        } else {
          window.adobeIMS.signOut();
        }
      }, heartbeatIntervalMilliseconds);
    }
  };

  triggerOnStateChanged = (newState: ImsLibData): void => {
    this.imslibData = { ...newState };
    if (this.onStateChanged) {
      this.onStateChanged(this.imslibData); // this calls App.tsx updateState()
    }
  };

  constructor(onStateChanged: (data: ImsLibData) => void, adobeid: any = null) {
    this.onStateChanged = onStateChanged;
    if (adobeid) {
      this.adobeIdData = {
        ...this.adobeIdData,
        ...adobeid,
      };
    }
  }
}
