import * as _ from 'lodash';
import * as log from 'loglevel';

import Utils from '../../../services/utils/Utils';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource, ResourceCalculationData } from '../../../services/orgMaster/UResource';
import { CommandService } from '../../../services/Commands/CommandService';

/**
 * Utilities for calculating resource data for the Product Allocation table
 */

/**
 * Typing for the property name of a resource where that property is of type number or undefined.
 * This allows you to assign a string name to look up a number or undefined property on a resource
 */
export type ResourceNumPropName = {
  [P in keyof UResource]: UResource[P] extends number | undefined ? P : never;
}[keyof UResource];

/**
 * Typing for property names on ResourceTableData
 */
export type ResourceCalculationDataPropName = keyof ResourceCalculationData;

/**
 * Mapping between orgIds and the ResourceCalculationData for the currently selected product and resource
 * TODO productName will be sourceProductId when implemented
 */
export class ResourceCalculationMap {
  private mapField: {
    [orgId: string]: ResourceCalculationData;
  } = {};

  /**
   * Retrieve ResourceCalculationData by org id,
   * Undefined value means no ResourceCalculationData was available at the specified location
   */
  get(orgId: string): ResourceCalculationData | undefined {
    if (this.mapField[orgId]) {
      return this.mapField[orgId];
    }
    return undefined;
  }

  /**
   * Adds ResourceCalculationData to a location determined by org id
   */
  add(orgId: string, data: ResourceCalculationData): void {
    this.mapField[orgId] = data;
  }
}

/**
 * Single object containing a UOrgMaster, UProduct and UResource
 */
export interface OrgProductResourceData {
  org?: UOrgMaster;
  product?: UProduct;
  resource?: UResource;
}

export class ResourceCalculationUtils {
  /**
   * Determines whether a product matches another product on either the root org or the parent org.
   * Matching is determined by whether the ids are the same or if the product's sourceProductId matches the id of the parent product
   */
  static matchChildToParentProduct(childProduct: UProduct, parentProduct: UProduct): boolean {
    return childProduct.sourceProductId === parentProduct.id;
  }

  /**
   * Retrieves a resource from the given product, selected by the given selectedResource
   */
  static orgResourceFromProduct(product: UProduct, selectedResource: UResource): UResource | undefined {
    return _.find(
      product.getQuotaResources(true),
      (resource: UResource): boolean => resource.code === selectedResource.code
    );
  }

  /**
   * Retrieves a product from a given parent org, selected by product on a child org.
   * (The same product is retrieved if the given product exists on the given parent org (selectedProduct on root org))
   */
  static parentOrgProduct(parentOrg: UOrgMaster, childProduct: UProduct): UProduct | undefined {
    if (parentOrg && childProduct) {
      return _.find(
        parentOrg.products,
        (parentProduct: UProduct): boolean =>
          this.matchChildToParentProduct(childProduct, parentProduct) || childProduct.id === parentProduct.id
      );
    }
    return undefined;
  }

  /**
   * Retrieves a product belonging to a child org, selected by product on parent org.
   * (The same product is retrieved if the given product exists on the given child org (selectedProduct on root org))
   */
  static orgProduct(childOrg: UOrgMaster, parentProduct: UProduct): UProduct | undefined {
    if (childOrg && parentProduct) {
      return _.find(
        childOrg.products,
        (childProduct: UProduct): boolean =>
          this.matchChildToParentProduct(childProduct, parentProduct) || childProduct.id === parentProduct.id
      );
    }
    return undefined;
  }

  /**
   * Retrieves a product and its corresponding resource from the given org
   *  - org: The org to retrieve the resource from
   *  - rootOrParentProduct: A product to select the product on the org that the resource belongs to (must be product on parent org or product on org if given org is the root org)
   *  - selectedResource: A resource to select the actual resource on the org and product
   *  - productCanBeDeleted: If product on org selected by selectedProduct is deleted, then no resource will be retrieved (undefined)
   */
  static orgProductAndResource(
    org: UOrgMaster,
    rootOrParentProduct: UProduct,
    selectedResource: UResource,
    productCanBeDeleted: boolean = true
  ): OrgProductResourceData {
    if (
      org &&
      rootOrParentProduct &&
      (productCanBeDeleted === true || !rootOrParentProduct.isDeleted()) &&
      selectedResource &&
      selectedResource.validCode()
    ) {
      const orgProduct: UProduct | undefined = ResourceCalculationUtils.orgProduct(org, rootOrParentProduct);
      if (orgProduct) {
        const orgResource = ResourceCalculationUtils.orgResourceFromProduct(orgProduct, selectedResource);
        return { product: orgProduct, resource: orgResource };
      }
    }
    return {};
  }

  /**
   * Retrieves a list of orgs and their associated products that are root products or purchase products that match the same type as the given selectedProduct
   */
  static rootAndPurchaseOrgsWithProduct(orgList: UOrgMaster[], selectedProduct: UProduct): OrgProductResourceData[] {
    const orgsAndProducts: OrgProductResourceData[] = [];
    _.forEach(orgList, (org: UOrgMaster): void => {
      const orgProduct: UProduct | undefined = _.find(
        org.products,
        (product: UProduct): boolean =>
          (product.isPurchase() || org === orgList[0] || org.isIndirectAllocation(product)) &&
          product.isMatchingProduct(selectedProduct)
      );
      if (orgProduct) {
        orgsAndProducts.push({ org, product: orgProduct });
      }
    });
    return orgsAndProducts;
  }

  /**
   * Iterates through resources of orgs and calculates and the creates the resourceCalculationMap which contains
   * the data for the table.
   *  - orgList: All orgs to calculate table data for (resources calculated are from these orgs).
   *  - selectedProduct: Product used to select the products in the orgs to calculate data for.
   *  - selectedResource: Resource used to select the resources in the orgs to calculate data for.
   * If any resource does not have a code, then data will not be calculated for that resource and it will not have a corresponding
   * ResourceCalculationData object in the returned ResourceCalculationMap
   */
  static populateResourceUsages(
    orgList: UOrgMaster[],
    selectedProduct: UProduct,
    selectedResource: UResource
  ): ResourceCalculationMap {
    const resourceCalculationMap: ResourceCalculationMap = new ResourceCalculationMap();
    if (orgList.length > 0) {
      const rootAndPurchaseOrgsWithProduct: OrgProductResourceData[] =
        ResourceCalculationUtils.rootAndPurchaseOrgsWithProduct(orgList, selectedProduct);

      _.forEach(rootAndPurchaseOrgsWithProduct, (orgAndProduct: OrgProductResourceData): void => {
        // these values are defined as they were set right above.
        const org: UOrgMaster = orgAndProduct.org as UOrgMaster;
        const product: UProduct = orgAndProduct.product as UProduct;
        // Generate ResourceCalculationData for map
        // Calculates Allocated Out, Use Rollup
        ResourceCalculationUtils.calculateResourceValues(org, resourceCalculationMap, product, selectedResource);
        // Calculates Over Allocated
        ResourceCalculationUtils.calculateOverAlloc(org, resourceCalculationMap, product, selectedResource);
        // Calculates Org Limit and Allocated Rollup
        ResourceCalculationUtils.calculateResourceMapValues(org, resourceCalculationMap, product, selectedResource);
        // Calculates Available Use Allocated and Grant Over
        ResourceCalculationUtils.calculateResourceMapDepValues(org, resourceCalculationMap, product, selectedResource);
      });
    }
    return resourceCalculationMap;
  }

  /**
   * Sums up some resource value for all children of an org matching a product and resource.
   *  - org: Specified root org (It's children's resources will be summed).
   *  - resourceCalculationMap: Calculated resource data
   *  - rootOrParentProduct: Product (on the parent org or on given org if root) which specifies the resources on the children to sum.
   *  - selectedResource: Resource which specifies the resource on children to sum.
   *  - valueSelector: Specifies the name of a property on the resource object (resource or resourceCalculationData) to sum or
   *                   specifies a callback to process the data on the resource object and then return
   *                   the value to sum
   *  - nestedChildren: If true, will sum all children and all children of children.
   *                    If false, will only sum the direct children of the org.
   */
  static sumChildrenValues(
    org: UOrgMaster,
    resourceCalculationMap: ResourceCalculationMap,
    rootOrParentProduct: UProduct,
    selectedResource: UResource,
    valueSelector:
      | ResourceNumPropName
      | ResourceCalculationDataPropName
      | ((resource: UResource) => number | undefined),
    nestedChildren: boolean
  ): number {
    if (!valueSelector) {
      throw new Error('valueSelector must be defined');
    }
    if (CommandService.isDeleted(org.id, org.id)) {
      return 0;
    }
    let valueCount = 0;
    const product: UProduct | undefined = ResourceCalculationUtils.orgProduct(org, rootOrParentProduct);
    if (product) {
      _.forEach(org.getChildren(), (child: UOrgMaster): void => {
        const { product: childProduct, resource: childResource } = ResourceCalculationUtils.orgProductAndResource(
          child,
          product,
          selectedResource,
          false
        );
        if (childProduct && childResource) {
          if (nestedChildren) {
            valueCount += ResourceCalculationUtils.sumChildrenValues(
              child,
              resourceCalculationMap,
              product,
              selectedResource,
              valueSelector,
              nestedChildren
            ); // remove this line and this method will only calculate direct children
          }
          let value: number | undefined;
          if (valueSelector instanceof Function) {
            value = valueSelector(childResource);
          } else if (valueSelector in childResource) {
            const resourcePropName: ResourceNumPropName = valueSelector as ResourceNumPropName;
            if (resourcePropName) {
              value = childResource[resourcePropName];
            }
          } else {
            try {
              const resourceCalculationData: ResourceCalculationData | undefined = resourceCalculationMap.get(
                child.organization.id
              );
              if (resourceCalculationData && valueSelector in resourceCalculationData) {
                const calculationDataPropName: ResourceCalculationDataPropName =
                  valueSelector as ResourceCalculationDataPropName;
                if (calculationDataPropName) {
                  value = resourceCalculationData[calculationDataPropName];
                } else {
                  log.error(
                    'sumChildrenValues valueSelector string is a property on resourceCalculationData but is not appropriate for summing'
                  );
                }
              } else {
                log.error(
                  'sumChildrenValues valueSelector string does not refer to any property on either the resource or resourceCalculationData or associated resourceCalculationData was not found'
                );
              }
            } catch (error) {
              log.error(
                'sumChildrenValues valueSelector appears to refer to property on resourceCalculationData but resourceCalculationMap appears to be uninitialized'
              );
            }
          }
          if (value) {
            valueCount += value;
          }
        }
      });
    }
    return valueCount;
  }

  /**
   * Generates the ResourceCalculationData for each org.
   * Calculates the AllocatedOut and TotalUse values.
   * (Calculates using values from the resource and not the ResourceCalculationMap)
   *  - org: Specified root org
   *  - resourceCalculationMap: Calculated resource data
   *  - rootOrParentProduct: Product (on the parent org or on given org if root) which specifies the resources on the children and the given org for calculation.
   *  - selectedResource: Resource which specifies the resource on the children and the given org for calculation.
   */
  private static calculateResourceValues(
    org: UOrgMaster,
    resourceCalculationMap: ResourceCalculationMap,
    rootOrParentProduct: UProduct,
    selectedResource: UResource
  ): void {
    const { product, resource } = ResourceCalculationUtils.orgProductAndResource(
      org,
      rootOrParentProduct,
      selectedResource
    );
    if (!_.isNil(product) && !_.isNil(resource) && resource.validCode()) {
      const resourceCalculationData: ResourceCalculationData = new ResourceCalculationData();
      resourceCalculationMap.add(org.id, resourceCalculationData);
      // Allocated Out
      resourceCalculationData.allocOut =
        org.getChildren().length > 0
          ? ResourceCalculationUtils.sumChildrenValues(
              org,
              resourceCalculationMap,
              rootOrParentProduct,
              selectedResource,
              (res: UResource): number | undefined => res.grantAsNilInt(undefined),
              false
            )
          : undefined;
      // Total Use
      resourceCalculationData.totalUse =
        resource.provisionedQuantity +
        ResourceCalculationUtils.sumChildrenValues(
          org,
          resourceCalculationMap,
          rootOrParentProduct,
          selectedResource,
          'provisionedQuantity',
          true
        );
      _.forEach(org.getChildren(), (child: UOrgMaster): void => {
        ResourceCalculationUtils.calculateResourceValues(child, resourceCalculationMap, product, selectedResource);
      });
    }
  }

  /**
   * Calculates the OverAlloc value.  This is it's own method because the calculation is recursive.
   *  - org: Specified root org (It' children will have the overAlloc value calculated so that this org can calculates it's overAlloc value).
   *  - resourceCalculationMap: Calculated resource data
   *  - rootOrParentProduct: Product (on the parent org or on given org if root) which specifies the resources on the children and the given org for overAlloc calculation.
   *  - selectedResource: Resource which specifies the resource on the children and the given org for overAlloc calculation.
   */
  private static calculateOverAlloc(
    org: UOrgMaster,
    resourceCalculationMap: ResourceCalculationMap,
    rootOrParentProduct: UProduct,
    selectedResource: UResource
  ): void {
    const { product, resource } = ResourceCalculationUtils.orgProductAndResource(
      org,
      rootOrParentProduct,
      selectedResource,
      false
    );
    const resourceCalculationData: ResourceCalculationData | undefined = resourceCalculationMap.get(
      org.organization.id
    );
    if (product && resource && resource.validCode() && resourceCalculationData && resourceCalculationData.allocOut) {
      _.forEach(org.getChildren(), (child: UOrgMaster): void => {
        ResourceCalculationUtils.calculateOverAlloc(child, resourceCalculationMap, product, selectedResource);
      });
      const overAlloc: number =
        resource.grantAsInt(0) -
        resourceCalculationData.allocOut -
        ResourceCalculationUtils.sumChildrenValues(
          org,
          resourceCalculationMap,
          rootOrParentProduct,
          selectedResource,
          'overAlloc',
          false
        );
      resourceCalculationData.overAlloc = overAlloc <= 0 ? Math.abs(overAlloc) : undefined;
    }
  }

  /**
   * Calculates the UseOver and TotalAlloc values.
   * (Calculates based on values in the ResourceCalculationMap)
   *  - org: Specified root org
   *  - resourceCalculationMap: Calculated resource data
   *  - rootOrParentProduct: Product (on the parent org or on given org if root) which specifies the resources on the children and the given org for calculation.
   *  - selectedResource: Resource which specifies the resource on the children and the given org for calculation.
   */
  private static calculateResourceMapValues(
    org: UOrgMaster,
    resourceCalculationMap: ResourceCalculationMap,
    rootOrParentProduct: UProduct,
    selectedResource: UResource
  ): void {
    const resourceCalculationData: ResourceCalculationData | undefined = resourceCalculationMap.get(org.id);
    if (resourceCalculationData) {
      const { product, resource } = ResourceCalculationUtils.orgProductAndResource(
        org,
        rootOrParentProduct,
        selectedResource
      );
      if (product && resource) {
        // Use Over
        resourceCalculationData.useOver = !resource.isUnlimited()
          ? resource.grantAsInt(0) - resourceCalculationData.totalUse
          : undefined;
        // Total Alloc
        resourceCalculationData.totalAlloc =
          resourceCalculationData.allocOut && !resource.isUnlimited()
            ? resourceCalculationData.allocOut +
              ResourceCalculationUtils.sumChildrenValues(
                org,
                resourceCalculationMap,
                rootOrParentProduct,
                selectedResource,
                'overAlloc',
                false
              )
            : undefined;

        _.forEach(org.getChildren(), (child: UOrgMaster): void => {
          ResourceCalculationUtils.calculateResourceMapValues(child, resourceCalculationMap, product, selectedResource);
        });
      }
    }
  }

  /**
   * Calculates the LocalLicensedQuantity and Grant Over values.
   * (Calculates the values that requires values on the ResourceCalculationMap based on other values on the ResourceCalculationMap)
   *  - org: Specified root org
   *  - resourceCalculationMap: Calculated resource data
   *  - rootOrParentProduct: Product (on the parent org or on given org if root) which specifies the resources on the children and the given org for calculation.
   *  - selectedResource: Resource which specifies the resource on the children and the given org for calculation.
   */
  private static calculateResourceMapDepValues(
    org: UOrgMaster,
    resourceCalculationMap: ResourceCalculationMap,
    rootOrParentProduct: UProduct,
    selectedResource: UResource
  ): void {
    const resourceCalculationData: ResourceCalculationData | undefined = resourceCalculationMap.get(org.id);
    if (resourceCalculationData) {
      const { product, resource } = ResourceCalculationUtils.orgProductAndResource(
        org,
        rootOrParentProduct,
        selectedResource
      );
      if (product && resource) {
        // Local Licensed Quantity
        resourceCalculationData.localLicensedQuantity = !resource.isUnlimited()
          ? resource.grantAsInt(0) - Utils.numberDefinedOrDefault(resourceCalculationData.allocOut, 0)
          : undefined;
        // Grant Over
        resourceCalculationData.grantOver = !resource.isUnlimited()
          ? resource.grantAsInt(0) - Utils.numberDefinedOrDefault(resourceCalculationData.totalAlloc, 0)
          : undefined;
        _.forEach(org.getChildren(), (child: UOrgMaster): void => {
          ResourceCalculationUtils.calculateResourceMapDepValues(
            child,
            resourceCalculationMap,
            product,
            selectedResource
          );
        });
      }
    }
  }

  /**
   * Given a product, returns the quota resources with the seat resource as the first resource
   */
  static getSortedQuotaResources(product: UProduct): UResource[] {
    const quotaResources: UResource[] = product.getQuotaResources(true);
    if (quotaResources.length > 0) {
      const index: number = _.findIndex(quotaResources, (resource: UResource): boolean =>
        UResource.isSeatResource(resource.code)
      );
      if (index > 0) {
        const seatsResource: UResource = quotaResources[index];
        quotaResources.splice(index, 1);
        quotaResources.splice(0, 0, seatsResource);
      }
    }
    return quotaResources;
  }
}
