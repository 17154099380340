import React from 'react';
import { Table, TBody, TR, TD } from '@react/react-spectrum/Table';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import CheckedPoliciesMap from '../CheckedPoliciesMap';
import TemplatePolicyMap from '../TemplatePolicyMap';
import TemplatePolicyListRow from './TemplatePolicyListRow';
import { MESSAGES } from '../../../Messages';
import '../../CompartmentPolicy/CompartmentPolicy.css';
import '../../../common.css';
import FloodgateService from '../../../../services/floodgate/FloodgateService';

interface TemplatePolicyListProps extends WrappedComponentProps {
  templatePolicyMap: TemplatePolicyMap;
  isEditable: boolean;
  checkedPoliciesMap?: CheckedPoliciesMap;
  updatePolicyValue?: (policyName: string | undefined, selection: boolean) => void;
  updatePolicyLockAction?: (policyName: string | undefined, policyLockAction: string) => void;
  checkUncheckPolicy?: (policyName: string) => void;
}

class TemplatePolicyList extends React.Component<TemplatePolicyListProps> {
  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { templatePolicyMap, updatePolicyValue, updatePolicyLockAction, checkUncheckPolicy } = this.props;
    const noOfColumns = this.props.isEditable ? 4 : 3;
    return (
      <div className="CompartmentPolicy">
        <Table quiet id="policies.OrgAndAdminTable">
          <TBody className="PolicyList__table-body">
            {(templatePolicyMap.createChildren ||
              templatePolicyMap.manageAdmins ||
              templatePolicyMap.renameOrgs ||
              templatePolicyMap.deleteOrgs ||
              templatePolicyMap.inheritAdmins) && (
              <TR>
                <TD colSpan={noOfColumns}>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.OrgAndAdminPolicyGroupHeading)}</span>
                  </div>
                </TD>
              </TR>
            )}
            {templatePolicyMap.createChildren && (
              <TemplatePolicyListRow
                policyName="createChildren"
                policyDescription={formatMessage(MESSAGES.CreateChildPolicy)}
                policyValue={templatePolicyMap.createChildren.value}
                policyLockAction={templatePolicyMap.createChildren.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.createChildren
                    ? this.props.checkedPoliciesMap.createChildren
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {templatePolicyMap.manageAdmins && (
              <TemplatePolicyListRow
                policyName="manageAdmins"
                policyDescription={formatMessage(MESSAGES.ManageAdminsPolicy)}
                policyValue={templatePolicyMap.manageAdmins.value}
                policyLockAction={templatePolicyMap.manageAdmins.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.manageAdmins
                    ? this.props.checkedPoliciesMap.manageAdmins
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {templatePolicyMap.renameOrgs && (
              <TemplatePolicyListRow
                policyName="renameOrgs"
                policyDescription={formatMessage(MESSAGES.RenameOrgsPolicy)}
                policyValue={templatePolicyMap.renameOrgs.value}
                policyLockAction={templatePolicyMap.renameOrgs.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.renameOrgs
                    ? this.props.checkedPoliciesMap.renameOrgs
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {templatePolicyMap.deleteOrgs && (
              <TemplatePolicyListRow
                policyName="deleteOrgs"
                policyDescription={formatMessage(MESSAGES.DeleteOrgsPolicy)}
                policyValue={templatePolicyMap.deleteOrgs.value}
                policyLockAction={templatePolicyMap.deleteOrgs.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.deleteOrgs
                    ? this.props.checkedPoliciesMap.deleteOrgs
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {templatePolicyMap.inheritAdmins && (
              <TemplatePolicyListRow
                policyName="inheritAdmins"
                policyDescription={formatMessage(MESSAGES.InheritAdminsPolicy)}
                policyValue={templatePolicyMap.inheritAdmins.value}
                policyLockAction={templatePolicyMap.inheritAdmins.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.inheritAdmins
                    ? this.props.checkedPoliciesMap.inheritAdmins
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {(templatePolicyMap.inheritUsers ||
              templatePolicyMap.injectGroup ||
              templatePolicyMap.claimDomains ||
              templatePolicyMap.allowAdobeID ||
              templatePolicyMap.manageUserGroups ||
              templatePolicyMap.changeIdentityConfig ||
              templatePolicyMap.directoryMove) && (
              <TR>
                <TD colSpan={noOfColumns}>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.IdentityAndUsersPolicyGroupHeading)}</span>
                  </div>
                </TD>
              </TR>
            )}
            {templatePolicyMap.inheritUsers && (
              <TemplatePolicyListRow
                policyName="inheritUsers"
                policyDescription={formatMessage(MESSAGES.InheritUsersPolicy)}
                policyValue={templatePolicyMap.inheritUsers.value}
                policyLockAction={templatePolicyMap.inheritUsers.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.inheritUsers
                    ? this.props.checkedPoliciesMap.inheritUsers
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {/* {templatePolicyMap.injectGroup && (
              <TemplatePolicyListRow
                policyName="injectGroup"
                policyDescription={formatMessage(MESSAGES.DupGroupsPolicy)}
                policyValue={templatePolicyMap.injectGroup.value}
                policyLockAction={templatePolicyMap.injectGroup.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.injectGroup
                    ? this.props.checkedPoliciesMap.injectGroup
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )} */}
            {/* {templatePolicyMap.claimDomains && (
              <TemplatePolicyListRow
                policyName="claimDomains"
                policyDescription={formatMessage(MESSAGES.ClaimDomainsPolicy)}
                policyValue={templatePolicyMap.claimDomains.value}
                policyLockAction={templatePolicyMap.claimDomains.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.claimDomains
                    ? this.props.checkedPoliciesMap.claimDomains
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )} */}
            {templatePolicyMap.allowAdobeID && (
              <TemplatePolicyListRow
                policyName="allowAdobeID"
                policyDescription={formatMessage(MESSAGES.AdobeIdPolicy)}
                policyValue={templatePolicyMap.allowAdobeID.value}
                policyLockAction={templatePolicyMap.allowAdobeID.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.allowAdobeID
                    ? this.props.checkedPoliciesMap.allowAdobeID
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {FloodgateService.isFeatureEnabled(FloodgateService.ENABLE_MANAGE_USER_GROUPS_ORG_POLICY) &&
              templatePolicyMap.manageUserGroups && (
                <TemplatePolicyListRow
                  policyName="manageUserGroups"
                  policyDescription={formatMessage(MESSAGES.ManageUserGroupsPolicy)}
                  policyValue={templatePolicyMap.manageUserGroups.value}
                  policyLockAction={templatePolicyMap.manageUserGroups.lockAction}
                  isEditable={this.props.isEditable}
                  isChecked={
                    this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.manageUserGroups
                      ? this.props.checkedPoliciesMap.manageUserGroups
                      : false
                  }
                  updatePolicyValue={updatePolicyValue}
                  updatePolicyLockAction={updatePolicyLockAction}
                  checkUncheckPolicy={checkUncheckPolicy}
                />
              )}
            {templatePolicyMap.changeIdentityConfig && (
              <TemplatePolicyListRow
                policyName="changeIdentityConfig"
                policyDescription={formatMessage(MESSAGES.IdConfigPolicy)}
                policyValue={templatePolicyMap.changeIdentityConfig.value}
                policyLockAction={templatePolicyMap.changeIdentityConfig.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.changeIdentityConfig
                    ? this.props.checkedPoliciesMap.changeIdentityConfig
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {/* {templatePolicyMap.directoryMove && (
              <TemplatePolicyListRow
                policyName="directoryMove"
                policyDescription={formatMessage(MESSAGES.MoveDirPolicy)}
                policyValue={templatePolicyMap.directoryMove.value}
                policyLockAction={templatePolicyMap.directoryMove.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.directoryMove
                    ? this.props.checkedPoliciesMap.directoryMove
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )} */}
            {(templatePolicyMap.nonChildAllocation || templatePolicyMap.manageProducts) && (
              <TR>
                <TD colSpan={noOfColumns}>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.AllocationPolicyGroupHeading)}</span>
                  </div>
                </TD>
              </TR>
            )}
            {templatePolicyMap.manageProducts && (
              <TemplatePolicyListRow
                policyName="manageProducts"
                policyDescription={formatMessage(MESSAGES.ManageProductsPolicy)}
                policyValue={templatePolicyMap.manageProducts.value}
                policyLockAction={templatePolicyMap.manageProducts.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.manageProducts
                    ? this.props.checkedPoliciesMap.manageProducts
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {/* {templatePolicyMap.nonChildAllocation && (
              <TemplatePolicyListRow
                policyName="nonChildAllocation"
                policyDescription={formatMessage(MESSAGES.NonChildAllocPolicy)}
                policyValue={templatePolicyMap.nonChildAllocation.value}
                policyLockAction={templatePolicyMap.nonChildAllocation.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.nonChildAllocation
                    ? this.props.checkedPoliciesMap.nonChildAllocation
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )} */}
            {(templatePolicyMap.setSharingPolicy || templatePolicyMap.inheritSharingPolicy) && (
              <TR>
                <TD colSpan={noOfColumns}>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.AssetSharingPolicyGroupHeading)}</span>
                  </div>
                </TD>
              </TR>
            )}
            {templatePolicyMap.setSharingPolicy && (
              <TemplatePolicyListRow
                policyName="setSharingPolicy"
                policyDescription={formatMessage(MESSAGES.SetSharingPolicy)}
                policyValue={templatePolicyMap.setSharingPolicy.value}
                policyLockAction={templatePolicyMap.setSharingPolicy.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.setSharingPolicy
                    ? this.props.checkedPoliciesMap.setSharingPolicy
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
            {templatePolicyMap.inheritSharingPolicy && (
              <TemplatePolicyListRow
                policyName="inheritSharingPolicy"
                policyDescription={formatMessage(MESSAGES.InheritSharingPolicy)}
                policyValue={templatePolicyMap.inheritSharingPolicy.value}
                policyLockAction={templatePolicyMap.inheritSharingPolicy.lockAction}
                isEditable={this.props.isEditable}
                isChecked={
                  this.props.checkedPoliciesMap && this.props.checkedPoliciesMap.inheritSharingPolicy
                    ? this.props.checkedPoliciesMap.inheritSharingPolicy
                    : false
                }
                updatePolicyValue={updatePolicyValue}
                updatePolicyLockAction={updatePolicyLockAction}
                checkUncheckPolicy={checkUncheckPolicy}
              />
            )}
          </TBody>
        </Table>
      </div>
    );
  }
}

export default injectIntl(TemplatePolicyList);
