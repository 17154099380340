import React from 'react';
import { TextField } from '@adobe/react-spectrum';
import * as _ from 'lodash';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import '../../common.css';
import HierarchyManager from '../../../services/organization/HierarchyManager';

interface OrgNameEditProps extends WrappedComponentProps {
  parentId: string | undefined; // parentId string
  currentName: string; // current org name
  updateOrgName: (orgName: string) => void; // callback to update org name
  updateValidationState: (validationState: 'valid' | 'invalid' | undefined) => void; // callback to enable/disable Save button
}

const messages = defineMessages({
  nameEmpty: {
    id: 'Organizations.Add.NameEmpty',
    defaultMessage: 'The name  of organization can not be empty',
  },
  nameHasSlash: {
    id: 'Organizations.Add.NameHasSlash',
    defaultMessage: 'The name of organization can not have /',
  },
  nameDuplicate: {
    id: 'Organizations.Add.nameDuplicate',
    defaultMessage: 'The name of organization already exists at this level',
  },
  nameTooShort: {
    id: 'Organizations.Add.nameTooShort',
    defaultMessage: 'The name of the organization must be greater than 3 characters.',
  },
  nameTooLong: {
    id: 'Organizations.Add.nameTooLong',
    defaultMessage: 'The name of the organization must be less than 100 characters.',
  },
  Organization: {
    id: 'Organizations.Add.label.Organization',
    defaultMessage: 'Organization',
  },
});

interface OrgNameEditState {
  inputName: string; // store the user input
  validationState?: 'valid' | 'invalid'; // is org name valid
  errorMessage?: string; // error message shown to the user
}

const ORG_MINIMUM_LENGTH = 4;

const ORG_MAXIMUM_LENGTH = 100;

/**
 * React Component to update an org name
 * Used in AddChildOrgContent and EditOrganization dialogs
 */
class OrgNameEdit extends React.Component<OrgNameEditProps, OrgNameEditState> {
  constructor(props: OrgNameEditProps) {
    super(props);
    this.state = {
      inputName: this.props.currentName,
    };
  }

  /**
   * gets sibling names, given the parent id (read from props) and current name (read from props)
   */
  private getSiblingOrgNames = (): string[] => {
    const parentOrg = this.props.parentId ? HierarchyManager.getOrg(this.props.parentId) : undefined;
    const siblingOrgNames: string[] = [];
    if (parentOrg) {
      _.forEach(parentOrg.getChildren(), (child: UOrgMaster): void => {
        // include all the children of the parent org except than the current org
        if (_.toLower(child.organization.name) !== _.toLower(this.props.currentName)) {
          siblingOrgNames.push(_.toLower(child.organization.name));
        }
      });
    }
    return siblingOrgNames;
  };

  /**
   * Validate input and set validation.
   * If valid, update parent component with new, valid org name.
   */
  private handleOnBlur = (): void => {
    const { formatMessage } = this.props.intl;
    const orgName = _.trim(this.state.inputName);
    let validationState: 'valid' | 'invalid' | undefined = 'invalid';
    let errorMessage;
    if (_.isEmpty(orgName)) {
      errorMessage = formatMessage(messages.nameEmpty); // 'The name  of organization can not be empty'
    } else if (orgName === this.props.currentName) {
      validationState = 'valid';
    } else if (_.includes(orgName, '/')) {
      errorMessage = formatMessage(messages.nameHasSlash); // 'The name of organization can not have /'
    } else if (_.includes(this.getSiblingOrgNames(), _.toLower(orgName))) {
      errorMessage = formatMessage(messages.nameDuplicate); // 'The name of organization already exists at this level'
    } else if (orgName.length < ORG_MINIMUM_LENGTH) {
      errorMessage = formatMessage(messages.nameTooShort); // 'Org name must be greater than 3'
    } else if (orgName.length > ORG_MAXIMUM_LENGTH) {
      errorMessage = formatMessage(messages.nameTooLong); // 'Org name must be less than 100 characters'
    } else {
      validationState = 'valid';
    }
    if (validationState === 'valid') {
      this.props.updateOrgName(orgName); // update parent component with new orgname
    }
    this.props.updateValidationState(validationState); // enable/disable Save button
    this.setState({ validationState, errorMessage });
    this.setState((prevState) => ({ inputName: _.trim(prevState.inputName) })); // update with trimmed name
  };

  /**
   * Unset validation and error message on focus
   */
  unsetOrgNameValidation = (): void => {
    this.setState({
      validationState: undefined,
    });
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { errorMessage, validationState } = this.state;
    return (
      <TextField
        label={formatMessage(messages.Organization)}
        data-testid="edit-org-name"
        id="add-edit-org-dialog-name-field"
        onChange={(orgName: string): void => {
          this.setState({ inputName: orgName });
          // Enable save button as soon as 4th char is entered as clue the user can proceed.
          if (orgName.length >= ORG_MINIMUM_LENGTH && orgName.length <= ORG_MAXIMUM_LENGTH) {
            this.props.updateValidationState('valid');
          } else {
            this.props.updateValidationState('invalid');
          }
        }}
        onBlur={this.handleOnBlur}
        onFocus={this.unsetOrgNameValidation}
        value={this.state.inputName}
        validationState={validationState}
        errorMessage={errorMessage}
        isRequired
        necessityIndicator="label"
        autoFocus
        minHeight="6em" // reserve space for error message. minHeight works with top labels. side labels need height.
      />
    );
  }
}

export default injectIntl(OrgNameEdit);
export { ORG_MINIMUM_LENGTH, ORG_MAXIMUM_LENGTH };
