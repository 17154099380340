import React, { Component } from 'react';
import _ from 'lodash';
import * as log from 'loglevel';

import './OrgMigrationForGlobalAdmin.css';
import '../App.css';

import Button from '@react/react-spectrum/Button';
import ComboBox from '@react/react-spectrum/ComboBox';
import Search from '@react/react-spectrum/Search';
import Wait from '@react/react-spectrum/Wait';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';
import Provider from '@react/react-spectrum/Provider';

import { SelectOption } from '@react/react-spectrum/Select';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { defineMessages, injectIntl, WrappedComponentProps, FormattedMessage as FM } from 'react-intl';

import TreeNode from 'primereact/components/treenode/TreeNode';
import { TreeTable } from 'primereact/treetable';

import HeaderConsts from '../components/BanyanShell/HeaderConstants';
import { ObjectTypes, OrgOperation } from '../services/orgMaster/OrgMaster';
import OrgPickerController from '../services/organization/OrgPickerController';
import AlertBanner from '../components/AlertBanner/AlertBanner';
import Analytics from '../Analytics/Analytics';
import { UOrgData } from '../services/orgMaster/UOrg';
import { CommandService } from '../services/Commands/CommandService';
import { ExpandedNodes } from '../services/treeTableUtils/ExpandedNodesUtil';
import OrgInfoService from '../services/organization/OrgInfoService';
import { LocaleSettings } from '../services/locale/LocaleSettings';
import GoUrl, { GoUrlKeys } from '../components/GoUrl/GoUrl';
import OrgMapperCache from '../services/organization/OrgMapperCache';
import CommandInterface from '../services/Commands/CommandInterface';
import { MESSAGES } from './Messages';
import TempIdGenerator from '../services/utils/TempIdGenerator';
import OrgHierarchyHelper from './helpers/OrgHierarchyHelper';
import withRouter, { RouteComponentProps } from '../services/utils/withRouter';

interface OrgMigrationForGlobalAdminState {
  selectedParentOrgId: string;
  selectedParentName: string;
  selectedOrgs: SelectOption[];
  enabledMakeItSoButton: boolean;
  enabledTable: boolean;
  childListOptions: SelectOption[];
  parentListOptions: SelectOption[];
  displayedListOptions: SelectOption[];
  searchText: string;
  errorMessage: string;
  noParentWarning: boolean;
  childListsLoaded: boolean;
  parentListsLoaded: boolean;
}

interface OrgMigrationForGlobalAdminProps extends RouteComponentProps, WrappedComponentProps {}

class OrgMigrationForGlobalAdmin extends Component<OrgMigrationForGlobalAdminProps, OrgMigrationForGlobalAdminState> {
  private static readonly NO_PARENT_TREE_KEY = 'empty';
  static orgIdToRootOrgIdMap = new Map<string, string>();
  abortController: AbortController;

  public constructor(props: OrgMigrationForGlobalAdminProps) {
    super(props);
    this.state = {
      selectedParentOrgId: '',
      selectedParentName: '',
      selectedOrgs: [],
      enabledMakeItSoButton: false,
      enabledTable: false,
      childListOptions: [],
      parentListOptions: [],
      displayedListOptions: [],
      searchText: '',
      errorMessage: '',
      noParentWarning: false,
      childListsLoaded: false,
      parentListsLoaded: false,
    };
    this.abortController = new AbortController();
  }

  componentDidMount(): void {
    // make to calls to fetch child and parent org list simultaneously
    this.load();
    this.loadParentOrgs();
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async load(): Promise<void> {
    let childListOptions: SelectOption[] = [];
    let errorMessage = '';
    try {
      childListOptions = await OrgMapperCache.getOrgMapperChildOrgs();
    } catch (error) {
      log.error('Unable to load child org list');
      errorMessage = 'Unable to load child org list';
    }

    if (this.abortController.signal.aborted) return;
    this.setState({
      childListsLoaded: true,
      childListOptions,
      displayedListOptions: childListOptions,
      errorMessage,
    });
  }

  async loadParentOrgs(): Promise<void> {
    let parentListOptions: SelectOption[] = [];
    let errorMessage = '';
    try {
      parentListOptions = await OrgMapperCache.getEditableOrgs();
    } catch (error) {
      log.error('Unable to load parent org list');
      errorMessage = 'Unable to load parent org list';
    }
    if (this.abortController.signal.aborted) return;
    this.setState({ errorMessage, parentListOptions, parentListsLoaded: true });
  }

  // Strings
  messages = defineMessages({
    parent_Org_Same_As_Selected_Org: {
      id: 'OrgMigrationForGA.parent_Org_Same_As_Selected_Org',
      defaultMessage: 'Parent Org and one of the Selected Orgs are the same.',
    },
    select_Parent_Org: { id: 'OrgMigrationForGA.select_Parent_Org', defaultMessage: 'Select Parent Org' },
    no_Parent_Selected: { id: 'OrgMigrationForGA.tree_table_no_parent_selected', defaultMessage: 'No Parent Selected' },
    error: { id: 'OrgMigrationForGA.ERROR', defaultMessage: 'ERROR' },
    no_matching_organization_found: {
      id: 'OrgMigrationForGA.no_matching_organization_found',
      defaultMessage: 'No matching organizations found',
    },
    organization: { id: 'OrgMigrationForGA.organization', defaultMessage: 'Organization' },
    organization_id: { id: 'OrgMigrationForGA.organization_id', defaultMessage: 'Organization Id' },
    filter_organization_list: {
      id: 'OrgMigrationForGA.Filter_Organization_List',
      defaultMessage: 'Filter Organization List',
    },
  });
  /**
   * Grabs the information the user selected and prepares it for Job Execution by created a Job and pushing each Job into
   * an array of Jobs.
   */
  onMakeItSo = (): void => {
    Analytics.fireCTAEvent('Org mapper for global admin'); // to log 'Org mapper for global admin' under REVIEW_PENDING_CHANGES_EVENT graph, prop7
    Analytics.fireCTAEvent('review pending event'); // to log 'review pending event' under ORG_MAPPER_ACTIONS_EVENT graph, prop6
    const { formatMessage } = this.props.intl;
    // First, transform the selected list into a list of org ids
    const newChildren: string[] = [];
    this.state.selectedOrgs.forEach((entry) => newChildren.push(entry.value));

    // Next, check if the parent is among the children and issue error if so
    const isParentTheSameAsSelectedOrg = newChildren.find(
      (selectedItem: string): boolean => selectedItem === this.state.selectedParentOrgId
    );
    if (isParentTheSameAsSelectedOrg) {
      this.setState({
        errorMessage: formatMessage(this.messages.parent_Org_Same_As_Selected_Org),
      });
      return;
    }

    // Next, find the name of the new parent
    const parentOrgEntry = OrgPickerController.getOrgLabels().find(
      (entry: SelectOption) =>
        entry.value.split(OrgPickerController.ORGPICKER_VALUE_DELIMETER)[0] === this.state.selectedParentOrgId
    );
    let parOrgName = '';
    if (parentOrgEntry) parOrgName = parentOrgEntry.label as string;

    // Add to the Commands
    this.state.selectedOrgs.forEach((org): void => {
      const elem: UOrgData = {
        // elem is a UOrgData type
        id: org.value,
        parentOrgId: this.state.selectedParentOrgId,
        name: org.label as string,
        parentOrgName: parOrgName,
      };
      const command: CommandInterface = {
        elem,
        elemType: ObjectTypes.ORGANIZATION,
        operation: OrgOperation.UPDATE,
        lastUpdatedAt: new Date().getTime(),
        messageData: {
          cmdDescription: [
            {
              cmdDescriptionCode: 'REPARENT_ORG',
              cmdDescriptionParams: [org.label as string, parOrgName || ''],
            },
          ],
        },
      };
      CommandService.addEditForOrgMapper(command);

      const NEW_CONTRACT_ID = TempIdGenerator.getTempIdAndIncrement();
      const contractSwitchCommand = OrgHierarchyHelper.generateContractSwitchCommand(
        NEW_CONTRACT_ID,
        org.value,
        org.label as string,
        false
      );
      // NOTE: this command will convert an eligible ETLA contract on the child org to an allocation contract
      // and allocate products from Parent org. Please see: https://wiki.corp.adobe.com/display/BANY/Converting+ETLA+contracts+to+enable+allocations#ConvertingETLAcontractstoenableallocations-SupportforGlobalAdmins
      CommandService.addEditForOrgMapper(contractSwitchCommand);
    });

    this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
  };

  treeTableData(): TreeNode | undefined {
    if (this.state.selectedParentOrgId || !_.isEmpty(this.state.selectedOrgs)) {
      return {
        key: this.state.selectedParentOrgId || OrgMigrationForGlobalAdmin.NO_PARENT_TREE_KEY,
        data: {
          orgName: this.state.selectedParentName || this.props.intl.formatMessage(this.messages.no_Parent_Selected),
        },
        selectable: false,
        children: _.map(
          this.state.selectedOrgs,
          (org: SelectOption): TreeNode => ({ key: org.value, data: { orgName: org.label as string }, children: [] })
        ),
      };
    }
    return undefined;
  }

  treeTableExpandedNodes(): ExpandedNodes {
    const expandedNodes: ExpandedNodes = {};
    if (this.state.selectedParentOrgId) {
      expandedNodes[this.state.selectedParentOrgId] = true;
    } else if (!_.isEmpty(this.state.selectedOrgs)) {
      expandedNodes[OrgMigrationForGlobalAdmin.NO_PARENT_TREE_KEY] = true;
    }
    _.forEach(this.state.selectedOrgs, (org: SelectOption): void => {
      expandedNodes[org.value] = true;
    });
    return expandedNodes;
  }

  render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const treeData: TreeNode | undefined = this.treeTableData();
    return (
      <div className="App__content">
        <div>
          <Heading className="App__header">
            <FM id="OrgMapper.title.OrgMapper" defaultMessage="Organization mapper" />
          </Heading>
          <Rule variant="small" />
        </div>
        <div>
          <p className="OrgMigration__Label">
            <FM
              defaultMessage="The organization mapper helps you assemble existing organizations into a hierarchy.  Add organizations that you manage and arrange them according to their relationship to their parent org and other child orgs."
              id="OrgMigrationForGA.To_setup_or_manage_your_orgnization"
            />
            <br />
            <FM
              defaultMessage="To get started, select an organization to be the parent organization, then select other organizations to be under it as child organizations."
              id="OrgMigrationForGA.Getting_started"
            />
            <GoUrl goUrlKey={GoUrlKeys.orgMapper} target="gac_help">
              <span>{formatMessage(MESSAGES.HelpLearnMore)}</span>
            </GoUrl>
          </p>
        </div>
        <div>
          <Heading className="OrgMigration__Label" variant="subtitle1">
            <FM
              defaultMessage="Step 1: Select the parent organization"
              id="OrgMigrationForGA.Select_parent_organization"
            />
          </Heading>
          <p className="OrgMigration__Paragraph">
            <FM
              defaultMessage="First, select the organization that would be the parent of one or more of your existing organizations that are not yet in a hierarchy."
              id="OrgMigrationForGA.Select_parent_organization_directions"
            />
          </p>
          {this.state.parentListsLoaded ? (
            <Provider locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
              <ComboBox
                className="OrgMigration__comboBox"
                placeholder={formatMessage(this.messages.select_Parent_Org)}
                options={this.state.parentListOptions}
                onChange={(parent: string): void => {
                  if (_.isEmpty(parent)) {
                    this.setState({ enabledTable: false, noParentWarning: false, errorMessage: '' });
                  }
                }}
                onSelect={async (parent: string | SelectOption): Promise<void> => {
                  Analytics.fireCTAEvent('parent org selected');
                  const parentOrgID: string = typeof parent === 'string' ? parent : parent.value;
                  let parentName: string = typeof parent === 'string' ? '' : (parent.label as string); // parent label is a string for OrgMapper
                  if (!parentName) {
                    const parentSelectOption: SelectOption | undefined = _.find(
                      this.state.parentListOptions,
                      (org: SelectOption): boolean => org.value === parentOrgID
                    );
                    if (parentSelectOption) {
                      parentName = parentSelectOption.label as string; // parent label is a string for org mapper
                    } else {
                      parentName = '';
                    }
                  }

                  // If the selected parent orgId is a child of some org, then the root org of this hierarchy should not
                  // allowed to be selected as the child org in Step 2. rootOrgId of this org hierarchy is fetched via call to
                  // OrganizationListProvider.getRootOrgId. The results are cached in OrgMigrationForGlobalAdmin.orgIdToRootOrgIdMap
                  let parentRootOrgId = '';
                  // check of the root org is already fetched before
                  if (OrgMigrationForGlobalAdmin.orgIdToRootOrgIdMap.has(parentOrgID)) {
                    parentRootOrgId = OrgMigrationForGlobalAdmin.orgIdToRootOrgIdMap.get(parentOrgID) as string;
                  } else {
                    try {
                      // make a call to fetch the root orgId
                      parentRootOrgId = await OrgInfoService.getRootOrgId(parentOrgID);
                    } catch (err) {
                      parentRootOrgId = '';
                    }
                    // cache the rootOrgId fetched in the map
                    OrgMigrationForGlobalAdmin.orgIdToRootOrgIdMap.set(parentOrgID, parentRootOrgId);
                  }

                  this.setState(
                    (prevState: OrgMigrationForGlobalAdminState): Pick<OrgMigrationForGlobalAdminState, never> => {
                      // remove the selected parent and its root orgId from the list of possible children
                      const displayedListOptions: SelectOption[] = _.filter(
                        prevState.childListOptions,
                        (org: SelectOption): boolean => org.value !== parentOrgID && org.value !== parentRootOrgId
                      );

                      // remove the selected parent and its root orgId from the list of selected orgs if it is selected
                      const selectedOrgs: SelectOption[] = _.filter(
                        prevState.selectedOrgs,
                        (org: SelectOption): boolean => org.value !== parentOrgID && org.value !== parentRootOrgId
                      );

                      return {
                        selectedParentOrgId: parentOrgID,
                        selectedParentName: parentName,
                        displayedListOptions,
                        selectedOrgs,
                        enabledTable: true,
                        noParentWarning: false,
                        errorMessage: '',
                      };
                    }
                  );
                }}
                data-testid="org-migration-global-admin-combobox"
              />
            </Provider>
          ) : (
            <Wait style={{ marginLeft: '5rem' }} />
          )}
          {this.state.errorMessage && (
            <div className="OrgMigration__alert">
              <AlertBanner
                header={formatMessage(this.messages.error)}
                variant="error"
                closeable
                closeTime={10000}
                onClose={(): void => this.setState({ errorMessage: '' })}
              >
                {this.state.errorMessage}
              </AlertBanner>
            </div>
          )}
          <Heading className="OrgMigration__Label" variant="subtitle1">
            <FM defaultMessage="Step 2: Select child organizations" id="OrgMigrationForGA.step2" />
          </Heading>
          <p className="OrgMigration__Paragraph">
            <FM
              defaultMessage="Next, select the organizations that will be under the parent org you selected in Step 1."
              id="OrgMigrationForGA.step2_directions"
            />
            <br />
            <FM
              defaultMessage="If some of the organizations will be under different parent orgs, map the orgs that will be under one parent org.  After you submit your changes for the first parent org, return to the org mapper to arrange the orgs under the next parent org."
              id="OrgMigrationForGA.step2_directions_info"
            />
          </p>
          {this.state.noParentWarning && (
            <div className="OrgMigration__alert">
              <AlertBanner
                header=""
                variant="warning"
                closeTime={5000}
                closeable
                onClose={(): void => this.setState({ noParentWarning: false })}
              >
                <FM
                  defaultMessage="Select a Parent Organization before selecting children."
                  id="OrgMigrationForGA.Select_a_Parent_Organization"
                />
              </AlertBanner>
            </div>
          )}

          {this.state.childListsLoaded ? (
            <div>
              <Search
                className="OrgMigration__filter"
                placeholder={this.props.intl.formatMessage(this.messages.filter_organization_list)}
                onChange={(value: string): void => this.setState({ searchText: value })}
                value={this.state.searchText}
                quiet
                data-testid="org-migration-global-admin-search"
              />
              <div className="OrgMigration__datatable">
                <DataTable
                  value={this.state.displayedListOptions}
                  selection={this.state.selectedOrgs}
                  onSelectionChange={(e): void => {
                    Analytics.fireCTAEvent('organizations to be direct children selected');
                    if (!this.state.enabledTable) {
                      // no parent selected so data table is disabled.
                      this.setState({ noParentWarning: true });
                      return;
                    }
                    this.setState({ selectedOrgs: e.value, errorMessage: '' });
                    if (!_.isEmpty(e.value) && !_.isEmpty(this.state.selectedParentOrgId))
                      this.setState({ enabledMakeItSoButton: true });
                    else this.setState({ enabledMakeItSoButton: false });
                  }}
                  globalFilter={this.state.searchText}
                  emptyMessage={formatMessage(this.messages.no_matching_organization_found)}
                  resizableColumns
                  autoLayout
                  scrollable
                  data-testid="org-migration-global-admin-datatable"
                >
                  <Column selectionMode="multiple" style={{ width: '4%', textAlign: 'center' }} />
                  <Column field="label" header={formatMessage(this.messages.organization)} />
                  <Column field="value" header={formatMessage(this.messages.organization_id)} />
                </DataTable>
              </div>
            </div>
          ) : (
            <Wait style={{ marginLeft: '5rem' }} />
          )}
          <Heading className="OrgMigration__Label" variant="subtitle1">
            <FM defaultMessage="Step 3: Review and save changes" id="OrgMigrationForGA.step3" />
          </Heading>
          <p className="OrgMigration__Paragraph">
            <FM
              defaultMessage="An org tree diagram will appear to show your choices.  If this looks correct, review changes and submit your selections.  After you've set up your initial parent-child organization structure, you can add other organizations."
              id="OrgMigrationForGA.step3_directions"
            />
          </p>
          {/* eslint-disable @typescript-eslint/no-empty-function */}
          {/* The empty method for onToggle is necessary otherwise expandedKeys does nothing */}
          {treeData && (
            <div className="OrgMigration__TreeTable">
              <TreeTable value={[treeData]} onToggle={(): void => {}} expandedKeys={this.treeTableExpandedNodes()}>
                <Column className="OrgMigration__TreeTableColumn" field="orgName" expander />
              </TreeTable>
            </div>
          )}
          {/* eslint-enable @typescript-eslint/no-empty-function */}
          <Button
            className="OrgMigration__button"
            disabled={!this.state.enabledMakeItSoButton}
            onClick={this.onMakeItSo}
            data-testid="org-migration-global-admin-review-pending-change"
          >
            <FM defaultMessage="Review changes" id="OrgMigrationForGA.Review_changes" />
          </Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(OrgMigrationForGlobalAdmin));
