import * as _ from 'lodash';

/**
 * The following demonstrates the rules in determining phases, states, and behaviours.
 *
 * CompliancePhase.NORMAL If 'can_administer' symptom is present and 'can_message_upcoming_expiration' and 'can_message_expiration' are not present.
 * CompliancePhase.NOTIFICATION If 'can_message_upcoming_expiration' symptom is present.
 * CompliancePhase.GRACE_PERIOD If 'can_message_expiration' and 'can_administer' symptom are present.
 * CompliancePhase.POST_GRACE If 'can_message_expiration' symtom is present and 'can_administer' symptom is not present.
 *
 * CompliancePhases NOTIFICATION, GRACE_PERIOD, POST_GRACE determine different messassing for the user.  NORMAL does not have any messassing.
 *
 * ExpireState.EXPIRING Same as CompliancePhase.NOTIFICATION.  Same as 'can_message_upcoming_expiration' symptom being present.  User should see notifications/warnings.
 * ExpireState.EXPIRED If CompliancePhase is GRACE_PERIOD or POST_GRACE.  Same as 'can_message_expiration' symptom being present.  User should see errors.
 *
 * License is not editable if CompliancePhase is POST_GRACE or undefined.  Same as 'can_administer' symptom being present.
 *
 */

/**
 * Name, value pair for compliance symptoms.
 * Compliance symptoms determine expiration related information for product licenses.
 * Name indicates the compliance symptom.
 * Value is some string value associated with compliance symptom (ex: boolean string or date).
 */
export interface ComplianceSymptom {
  name?: string;
  value?: string;
}

/**
 * Enumerates the different names for compliance symptoms that are currently used by this UI.
 */
enum SymptomNames {
  CAN_ADMINISTER = 'can_administer',
  CAN_MESSAGE_UPCOMING_EXPIRATION = 'can_message_upcoming_expiration',
  CAN_MESSAGE_EXPIRATION = 'can_message_expiration',
  CAN_MESSAGE_EXPIRATION_AT = 'can_message_expiration_at',
  CAN_ACCESS_UNTIL = 'can_access_until',
}

/**
 * Mutable object for LicenseTuple.
 * Provides object containing properties for LicenseTuple.
 * Represents LicenseTuple object that can be retrieved from the back-end.
 * LicenseTuple contains multiple compliance symptoms.
 * LicenseTuples are attached to licenses (UProduct).
 */
export interface LicenseTupleData {
  complianceTupleId?: string;
  quantity?: string;
  contractId?: string;
  complianceSymptoms?: ComplianceSymptom[];
}

/**
 * LicenseTuple object that also contains methods, functionality, and utilities.
 */
export class LicenseTuple implements LicenseTupleData {
  complianceTupleId: string = '';
  quantity: string = '';
  contractId: string = '';
  complianceSymptoms: ComplianceSymptom[] = [];

  private canAdminister: boolean = false;
  private canMessageUpcomingExpiration: boolean = false;
  private canMessageExpiration: boolean = false;
  private canMessageExpirationAt: Date | undefined;
  private canAccessUntil: Date | undefined;

  constructor(licenseTuple?: LicenseTupleData) {
    if (licenseTuple) {
      this.complianceTupleId = licenseTuple.complianceTupleId || this.complianceTupleId;
      this.quantity = licenseTuple.quantity || this.quantity;
      this.contractId = licenseTuple.contractId || this.contractId;
      this.complianceSymptoms = licenseTuple.complianceSymptoms || this.complianceSymptoms;

      // Save symptoms as fields instead of having to look them up from the list each time
      this.canAdminister = this.symptomEnabled(SymptomNames.CAN_ADMINISTER);
      this.canMessageUpcomingExpiration = this.symptomEnabled(SymptomNames.CAN_MESSAGE_UPCOMING_EXPIRATION);
      this.canMessageExpiration = this.symptomEnabled(SymptomNames.CAN_MESSAGE_EXPIRATION);
      this.canMessageExpirationAt = this.dateFromSymptom(SymptomNames.CAN_MESSAGE_EXPIRATION_AT);
      this.canAccessUntil = this.dateFromSymptom(SymptomNames.CAN_ACCESS_UNTIL);
    }
  }

  /**
   * Determines whether the license is in any phase that needs to show compliance messages to the user.
   * Note: The opposite (!) of this would be the normal phase.
   */
  shouldShowExpireMessages(): boolean {
    return this.canMessageUpcomingExpiration || this.canMessageExpiration;
  }

  /**
   * Determines whether the license is expiring soon.
   */
  isNotificationPhase(): boolean {
    return this.canMessageUpcomingExpiration && !this.canMessageExpiration;
  }

  /**
   * Determines whether the license has expired but can still be used for a short time.
   */
  isGracePhase(): boolean {
    return this.canAdminister && !this.canMessageUpcomingExpiration && this.canMessageExpiration;
  }

  /**
   * Determines whether the license is expired passed the grace period and cannot be used or edited.
   */
  isPostGracePhase(): boolean {
    return !this.canAdminister && !this.canMessageUpcomingExpiration && this.canMessageExpiration;
  }

  /**
   * Checks whether a phase for the license could be determined.
   * If no phase can be determined, this usually means that data for a Tuple is incomplete.
   * This method is only necessary to assist in allowing GAC features to work while allocation compliance is being implemented.
   * TODO: This method is temporary and will be removed when CLAM supports allocations.
   */
  hasValidPhase(): boolean {
    return (
      !this.shouldShowExpireMessages() || this.isNotificationPhase() || this.isGracePhase() || this.isPostGracePhase()
    );
  }

  /**
   * Determines whether this single LicenseTuple allows editing.
   * This method should not be used to determine if a license can be edited (the license needs to determine this by looking at all the other LicenseTuples).
   * LicenseTuple also does not allow editing if it has no expiration date available.
   */
  allowsEditing(): boolean {
    // return this.canAdminister && !_.isNil(this.canMessageExpirationAt) && !_.isNil(this.canAccessUntil); // TODO: This should be the logic when allocations are supported by CLAM.
    // For now allow editing as long as the LicenseTuple is administrable or there is no valid phase
    // (so that allocations can still be edited while CLAM work is still being done)
    return this.canAdminister || !this.hasValidPhase();
  }

  /**
   * Retrieve the expiration date.
   * Undefined expiration date should indicate that data was omitted from compliance symptoms
   * and the license should not be editable.
   */
  expirationDate(): Date | undefined {
    return this.canMessageExpirationAt;
  }

  /**
   * Retrieve the date users will loose access to the license (start of post grace phase).
   * Undefined access lost date should indicate that data was omitted from compliance symptoms
   * and the license should not be editable.
   */
  accessLostDate(): Date | undefined {
    return this.canAccessUntil;
  }

  /**
   * Retrieves a number indicating the priority for this ComplianceInfo.
   * The more urgent the error the higher the number.
   * This is generally used to determine which error should be shown over other errors for compliance.
   * Order of priority is NORMAL < NOTIFICATION < GRACE < POST GRACE
   */
  priority(): number {
    if (this.isNotificationPhase()) {
      return 1;
    }
    if (this.isGracePhase()) {
      return 2;
    }
    if (this.isPostGracePhase()) {
      return 3;
    }
    // anything other than the above phases don't require messaging and have the lowest priority
    return 0;
  }

  /**
   * Find ComplianceSymptom by name.
   *
   * @param symptomName name to use in looking up the ComplianceSymptom.
   * @returns ComplianceSymptom with matching name or undefined if the ComplianceSymptom could not be found.
   */
  private findSymptom(symptomName: SymptomNames): ComplianceSymptom | undefined {
    return _.find(this.complianceSymptoms, (cs: ComplianceSymptom): boolean => cs.name === symptomName);
  }

  /**
   * Determines whether a ComplianceSymptom indicates a phase that is actually active.
   * This means that the ComplianceSymptom must have a value of 'true' (string).
   * Note: This method will return false if looking up a ComplianceSymptom that indicates something other than phase (ex: date).
   *
   * @param symptomName name to use in looking up the ComplianceSymptom.
   * @returns true if there is a matching ComplianceSymptom that is active or false if there is no matching ComplianceSymptom or
   *          the ComplianceSymptom did not have a 'true' value (not active or not a ComplianceSymptom indicating phase).
   */
  private symptomEnabled(symptomName: SymptomNames): boolean {
    const complianceSymptom: ComplianceSymptom | undefined = this.findSymptom(symptomName);
    if (complianceSymptom && complianceSymptom.value === 'true') {
      return true;
    }
    return false;
  }

  /**
   * Creates a Date object from a ComplianceSymptom that stores  date.
   * This method should only be used with ComplianceSymptoms that contain a date for their value.
   *
   * @param dateSymptomName name of the ComplianceSymptom that stores a date
   * @returns Date converted from ComplianceSymptom or undefined if no date ComplianceSymptom could be found or if it
   */
  private dateFromSymptom(dateSymptomName: SymptomNames): Date | undefined {
    const complianceDate: ComplianceSymptom | undefined = this.findSymptom(dateSymptomName);
    if (complianceDate && complianceDate.value) {
      return new Date(complianceDate.value);
    }
    return undefined;
  }
}
