import * as _ from 'lodash';

/* eslint-disable import/no-cycle */
import { OrgType } from '../organization/Org';
import { UBasic, UBasicData } from './UBasic';
/* eslint-enable import/no-cycle */

/**
 * UOrg represents the data for an org.
 */

/**
 * Mutable object for UOrg.
 * Provides object containing only properties for UOrg.
 * Represents a UOrg object that can be retrieved from the back-end.
 */
export interface UOrgData extends UBasicData {
  name?: string; // name of the org
  countryCode?: string; // country code for the org
  type?: OrgType; // org type
  parentOrgId?: string; // id of the parent org for this org
  parentOrgName?: string; // used for reparent in some cases (comes from client, not service).  Client side field
  readOnly?: boolean; // if the org read only
}

/**
 * UOrg object that also contains the methods and functionality.
 */
export class UOrg extends UBasic implements UOrgData {
  name: string = '';
  countryCode: string = '';
  type?: OrgType;
  parentOrgId: string = '';
  readOnly: boolean = false;

  /**
   * Constructs a UOrg from either UOrgData or creates a copy of another UOrg object.
   */
  constructor(org?: UOrgData) {
    super(org);
    if (org) {
      this.name = org.name || this.name;
      this.countryCode = org.countryCode || this.countryCode;
      this.type = org.type || this.type;
      this.parentOrgId = org.parentOrgId || this.parentOrgId;
      this.readOnly = org.readOnly || this.readOnly;
    }
  }

  /**
   * compare if the org data of other org is same as this org
   * @param otherOrg
   */
  isEqual(otherOrg: UOrg) {
    return (
      this.parentOrgId === otherOrg.parentOrgId &&
      this.name === otherOrg.name &&
      this.countryCode === otherOrg.countryCode
    );
  }

  /**
   * JSON representation of UOrg.
   */
  toJSON(): object {
    return _.assign(super.toJSON(), {
      name: this.name,
      countryCode: this.countryCode,
      type: this.type,
      parentOrgId: this.parentOrgId,
    });
  }
}
