import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import { SelectOption } from '@react/react-spectrum/Select';
import Provider from '@react/react-spectrum/Provider';
import ComboBox from '@react/react-spectrum/ComboBox';
import Wait from '@react/react-spectrum/Wait';
import * as _ from 'lodash';
import { UUserGroup } from '../../../../../services/orgMaster/UUserGroup';
import '../../../../common.css';
import { UOrgMaster } from '../../../../../services/orgMaster/UOrgMaster';
import JILSearch from '../../../services/JILSearch';
import { LocaleSettings } from '../../../../../services/locale/LocaleSettings';

/*
 * UserGroupSection receives these props from the EditProdProfileDialogContent component.
 * - 'userGroups' list of current user groups to be displayed as Tag items
 * - 'compartment' compartment from dialog component
 * - 'onDeleteUserGroup' callback is implemented at the EditProdProfileDialogContent level to update the compartment
 *    with the deleted user group data
 * - 'onAddUserGroup' callback is implemented at the EditProdProfileDialogContent level to update the compartment with
 *    the added user group data
 */
interface UserGroupSectionProps extends WrappedComponentProps {
  compartment: UOrgMaster;
  userGroups: UUserGroup[];
  onDeleteUserGroup: (userGroup: UUserGroup) => void;
  onAddUserGroup: (userGroupName: string) => void;
  labelledby: string;
}

/*
 * UserGroupSection has one state
 * - 'inputUserGroup' is a variable that updates input field of the combox element as the state changes
 */
interface UserGroupSectionState {
  inputUserGroup: string;
  showLoading: boolean;
  userGroupSearchComplete: boolean;
  showMenu: boolean;
}

const messages = defineMessages({
  SelectUG: {
    id: 'EditCompartment.EditProfile.UserGroupSelection',
    defaultMessage: 'Select user group',
  },
});

class UserGroupSection extends React.Component<UserGroupSectionProps, UserGroupSectionState> {
  searchTimer: NodeJS.Timeout | undefined; // Store the search timer. Previous timer is replaced with a new one while the user is typing. Once the user stops typing, the search is fired after 1second.

  constructor(props: UserGroupSectionProps) {
    super(props);
    this.state = {
      inputUserGroup: '',
      showLoading: false,
      userGroupSearchComplete: false,
      showMenu: false,
    };
  }

  componentWillUnmount(): void {
    // clear timeout when component unmounts
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }

  private getUserGroupNotInProfile(): string[] {
    return _.chain(this.props.compartment.userGroups).differenceBy(this.props.userGroups, 'id').map('name').value();
  }

  /*
   *  Reset the input state for the Combobox element and pass the selected item to the add callback function to update the compartment
   */
  private onSelect = (name: string | SelectOption): void => {
    this.setState({ inputUserGroup: '', userGroupSearchComplete: false });
    if (typeof name === 'string') {
      this.props.onAddUserGroup(name);
    }
  };

  /*
   * Update the input state for the Combobox element based on user's input
   */
  private onChange = async (input: string): Promise<void> => {
    this.setState({
      inputUserGroup: input,
      userGroupSearchComplete: false,
    });
    const validInput = !_.isEmpty(input) && input.length >= 3 && !_.includes(this.getUserGroupNotInProfile(), input); // the input is valid if its empty, has length greater than 3 and user group is not included in the current selection list
    if (validInput) {
      if (this.searchTimer) {
        // clear timeout as the user is still typing
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(async (): Promise<void> => {
        if (this.props.compartment.isMoreUserGroups() || !this.props.compartment.userGroupsLoaded) {
          this.setState({ showLoading: true });
          await JILSearch.userGroupSearch(this.props.compartment, input);
          const matches = _.filter(this.getUserGroupNotInProfile(), (userGroupName) =>
            _.includes(userGroupName, input)
          );
          this.setState({ showLoading: false, userGroupSearchComplete: !!matches, showMenu: false });
        }
      }, 1000); // time is required to fire the search call only when the user stops typing
    }
  };

  public render(): React.ReactNode {
    // Get the 'Tag' elements to be displayed for usergroups by iterating through the userGroup lists passed as props
    const { formatMessage } = this.props.intl;
    const userGroupDisplay = this.props.userGroups.map((userGroup: UUserGroup): React.ReactNode => {
      return (
        <Provider key={userGroup.id} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
          <TagList
            onClose={(): void => this.props.onDeleteUserGroup(userGroup)}
            data-testid="profile-userGroup-taglist"
          >
            <Tag aria-labelledby={this.props.labelledby} closeable>
              {userGroup.name}{' '}
            </Tag>
          </TagList>
        </Provider>
      );
    });

    return (
      <React.Fragment>
        <ComboBox
          data-testid="edit-profile-userGroup-section-combobox"
          className="EditCompartment__EditProfileDialog__UserGroupSectionAndAdminSection"
          placeholder={formatMessage(messages.SelectUG)}
          options={this.getUserGroupNotInProfile()}
          aria-label="Default"
          onSelect={this.onSelect}
          onChange={this.onChange}
          onBlur={(): void => this.setState({ showMenu: true })}
          onFocus={(): void => this.setState({ showMenu: false })}
          value={this.state.inputUserGroup}
          onMenuToggle={(): void => {
            this.setState((state) => ({ showMenu: !state.showMenu }));
          }}
          showMenu={this.state.showMenu}
          aria-labelledby={this.props.labelledby}
          aria-describedby="user-group-section-description"
        />
        <span className=" EditCompartment__font--light" id="user-group-section-description">
          {this.state.showLoading && (
            <span>
              <Wait className="EditCompartment__margin--top" size="S" />
              <span className="EditCompartment__margin--left">
                <FormattedMessage
                  id="EditCompartment.EditProfileOrUserGroupDialog.UserGroupSection.SearchInProgress"
                  defaultMessage="Search in progress"
                />
              </span>
            </span>
          )}
          <div>
            <FormattedMessage
              id="EditCompartment.EditProfileOrUserGroupDialog.UserGroupSection.EnterUserGroup"
              defaultMessage="Enter the user group name to search user groups not in the list"
            />
          </div>
          {this.state.userGroupSearchComplete && (
            <div>
              <FormattedMessage
                id="EditCompartment.EditProfileOrUserGroupDialog.UserGroupSection.UserGroupSearchComplete"
                defaultMessage="User group search complete, click dropdown to see more user groups"
              />
            </div>
          )}
        </span>
        <div>{userGroupDisplay}</div>
      </React.Fragment>
    );
  }
}
export default injectIntl(UserGroupSection);
