import { defineMessages, MessageDescriptor } from 'react-intl';
import * as _ from 'lodash';

export interface CmdDescriptionCodesData {
  cmdDescriptionCode: CmdDescriptionCodes;
  cmdDescriptionParams: string[];
}

// this interface will enforce that every value of any code in CMD_DESCRIPTIONS  must have a MessageDescriptor but any
// code string can be added
interface CmdDescription {
  [key: string]: MessageDescriptor;
}

// this method takes and returns the same object, but will use type inference to type the returning object explictly
const createCmdDescriptions = <T extends CmdDescription>(t: T) => t;

/**
 * This would be central place for defining messages for command description codes. These codes will be a part
 * of the command objects created in banyanui, or can also be a part of the commands created by
 * the service.
 * NOTE: When an update is made on the code, equivalent update must be made in banyansvc CmdDescriptionCodes.java
 * NOTE VERY IMPORTANT: Codes should not be removed or changed. Instead a new code should be created.
 * This is because old command data might contain these codes. The removal of codes can be done in stages
 * keeping backward compatibility of data in mind.
 */
export const CMD_DESCRIPTIONS = createCmdDescriptions(
  defineMessages({
    /*
     * message-param[0] is the product name
     * message-param[1] is the org pathname
     * message-param[2] is the resource list, example: 10 users, 5 transactions
     */
    ADD_PRODUCT: {
      id: 'Banyan.CmdDescription.AddProduct',
      defaultMessage: 'Add product "{0}" in "{1}" with {2}',
    },

    /**
     * param[0] is the product name
     * param[1] is the org pathname
     * param[2] is the resource list
     */
    UPDATE_PRODUCT_QUANTITY: {
      id: 'Banyan.CmdDescription.UpdateProduct.Quantity',
      defaultMessage: 'Update product "{0}" in "{1}" to have {2}',
    },

    /**
     * param[0] is the product name
     * param[1] is the org pathname
     */
    UPDATE_PRODUCT_OVERALLOC: {
      id: 'Banyan.CmdDescription.UpdateProduct.OverAllocation',
      defaultMessage: 'Update "Over Allocation" product policy for "{0}" in "{1}"',
    },

    /**
     * param[0] is the product name
     * param[1] is the org pathname
     */
    UPDATE_PRODUCT_OVERUSE: {
      id: 'Banyan.CmdDescription.UpdateProduct.OverUsage',
      defaultMessage: 'Update "Over Use" product policy for "{0}" in "{1}"',
    },

    /**
     * param[0] is the product name
     * param[1] is the org pathname
     */
    REMOVE_PRODUCT: {
      id: 'Banyan.CmdDescription.RemoveProduct',
      defaultMessage: 'Remove product "{0}" in "{1}"',
    },

    /**
     * param[0] is the product name
     * param[1] is the org pathname
     * param[2] is the new parent org pathname
     */
    REPARENT_PRODUCT: {
      id: 'Banyan.CmdDescription.ReparentProduct',
      defaultMessage: 'Change product allocation for "{0}" in "{1}" to come from "{2}"',
    },

    /**
     * param[0] is the org pathname
     * param[1] is the new parent org pathname
     * param[2] is the names of the first 2 products in the contract being reparented
     */
    REPARENT_PRODUCTS: {
      id: 'Banyan.CmdDescription.ReparentContract',
      defaultMessage: 'Change product allocations in "{0}" to come from "{1}" ({2})',
    },

    /**
     * param[0] is the profile name
     * param[1] is the product name
     * param[2] is the org pathname
     */
    CREATE_PRDCT_PROFILE: {
      id: 'Banyan.CmdDescription.CreateProductProfile',
      defaultMessage: 'Create Product Profile "{0}" of product "{1}" in "{2}"',
    },

    /**
     * param[0] is the profile name
     * param[1] is the product name
     * param[2] is the org pathname
     */
    UPDATE_PRDT_PROFILE: {
      id: 'Banyan.CmdDescription.UpdateProductProfile',
      defaultMessage: 'Update Product Profile "{0}" of product "{1}" in "{2}"',
    },

    /**
     * param[0] is the profile name
     * param[1] is the product name
     * param[2] is the org pathname
     */
    DELETE_PRDT_PROFILE: {
      id: 'Banyan.CmdDescription.DeleteProductProfile',
      defaultMessage: 'Delete Product Profile "{0}" of product "{1}" in "{2}"',
    },

    /**
     * param[0] is the user group name
     * param[1] is the org pathname
     */
    CREATE_USER_GROUP: {
      id: 'Banyan.CmdDescription.CreateUserGroup',
      defaultMessage: 'Create User Group "{0}" in "{1}"',
    },

    /**
     * param[0] is the user group name
     * param[1] is the org pathname
     */
    UPDATE_USER_GROUP: {
      id: 'Banyan.CmdDescription.UpdateUserGroup',
      defaultMessage: 'Update User Group "{0}" in "{1}"',
    },

    /**
     * param[0] is the user group name
     * param[1] is the org pathname
     */
    DELETE_USER_GROUP: {
      id: 'Banyan.CmdDescription.DeleteUserGroup',
      defaultMessage: 'Delete User Group "{0}" in "{1}"',
    },

    /**
     * param[0] is the selected source org id
     * param[1] is the user group name
     * param[2] is the target org id that the group will be shared with
     */
    CREATE_USER_GROUP_SHARE: {
      id: 'Banyan.CmdDescription.CreateUserGroupShare',
      defaultMessage: '"{0}" shared user group "{1}" with "{2}"',
    },

    /**
     * param[0] is the selected source org id
     * param[1] is the user group name
     * param[2] is the target org id that the group will be re-synced with
     */
    RESYNC_USER_GROUP_SHARE: {
      id: 'Banyan.CmdDescription.ResyncUserGroupShare',
      defaultMessage: 'Re-syncing "{0}" shared user group "{1}" with "{2}"',
    },

    /**
     * param[0] is the type of revoke command running
     * param[1] is the user group name
     * param[2] is the parent org name
     */
    REVOKE_USER_GROUP_SHARE: {
      id: 'Banyan.CmdDescription.RevokeUserGroupShare',
      defaultMessage: 'Revoke and {0} shared user group "{1}" from organization "{2}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     */
    ADD_ADMIN: {
      id: 'Banyan.CmdDescription.AddAdmin',
      defaultMessage: 'Add admin user {0} in "{1}" with role {2}',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     * param[3] is the product name
     */
    ADD_ADMIN_TO_PRODUCT: {
      id: 'Banyan.CmdDescription.AddAdmin.product',
      defaultMessage: 'Add admin user {0} in "{1}" with role {2} to product "{3}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     * param[3] is the profile name
     */
    ADD_ADMIN_TO_PRDT_PROFILE: {
      id: 'Banyan.CmdDescription.AddAdmin.pp',
      defaultMessage: 'Add admin user {0} in "{1}" with role {2} to profile "{3}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     * param[3] is the user group name
     */
    ADD_ADMIN_TO_USER_GROUP: {
      id: 'Banyan.CmdDescription.AddAdmin.ug',
      defaultMessage: 'Add admin user {0} in "{1}" with role {2} to user group "{3}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     */
    REMOVE_ADMIN: {
      id: 'Banyan.CmdDescription.RemoveAdmin',
      defaultMessage: 'Remove admin user {0} in "{1}" with role {2}',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     * param[3] is the product name
     */
    REMOVE_ADMIN_FROM_PRODUCT: {
      id: 'Banyan.CmdDescription.RemoveAdmin.product',
      defaultMessage: 'Remove admin user {0} in "{1}" with role {2} from product "{3}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     * param[3] is the profile name
     */
    REMOVE_ADMIN_FROM_PRDT_PROFILE: {
      id: 'Banyan.CmdDescription.RemoveAdmin.pp',
      defaultMessage: 'Remove admin user {0} in "{1}" with role {2} from profile "{3}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] is the role name
     * param[3] is the user group name
     */
    REMOVE_ADMIN_FROM_USER_GROUP: {
      id: 'Banyan.CmdDescription.RemoveAdmin.ug',
      defaultMessage: 'Remove admin user {0} in "{1}" with role {2} from user group "{3}"',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] list of role names
     */
    ADD_ADMIN_ROLES: {
      id: 'Banyan.CmdDescription.AddAdminUserAndRoles',
      defaultMessage: 'Add admin {0} in "{1}" with {rolesCount, plural, one {role} other {roles}} {2}',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     * param[2] list of role names
     */
    UPDATE_ADMIN_ROLES: {
      id: 'Banyan.CmdDescription.UpdateAdminRoles',
      defaultMessage: 'Update admin {0} in "{1}" with {rolesCount, plural, one {role} other {roles}} {2}',
    },

    /**
     * param[0] is the user email
     * param[1] is the org pathname
     */
    DELETE_ALL_ROLES_FOR_ADMIN: {
      id: 'Banyan.CmdDescription.DeleteAllRolesForAdmin',
      defaultMessage: 'Remove all admin roles for {0} in "{1}"',
    },

    /**
     * param[0] is the org pathname
     */
    CREATE_ORG: {
      id: 'Banyan.CmdDescription.CreateOrganization',
      defaultMessage: 'Create organization "{0}"',
    },

    /**
     * param[0] is the old org pathname
     * param[1] is the old org simple name
     * param[2] is the new org simple name
     */
    UPDATE_ORG_NAME: {
      id: 'Banyan.CmdDescription.UpdateOrgName',
      defaultMessage: 'Update organization name "{0}" from "{1}" to "{2}"',
    },

    /**
     * param[0] is the org pathname
     */
    DELETE_ORG: {
      id: 'Banyan.CmdDescription.DeleteOrg',
      defaultMessage: 'Delete organization "{0}"',
    },

    /**
     * param[0] is the old org pathname
     * param[1] is the org pathname of new parent
     */
    REPARENT_ORG: {
      id: 'Banyan.CmdDescription.Reparent',
      defaultMessage: 'Change parent of organization "{0}" to "{1}"',
    },

    /**
     * param[0] is the org pathname
     * param[1] is the policy image
     */
    UPDATE_POLICY: {
      id: 'Banyan.CmdDescription.UpdatePolicy',
      defaultMessage: 'Update Organization Policy in "{0}": {1}',
    },

    /**
     * param[0] is the org name
     */
    CONVERT_ETLA_CONTRACT: {
      id: 'Banyan.CmdDescription.ConvertETLAContractForOrg',
      defaultMessage: 'Integrate products in Org "{0}" into organization hierarchy',
    },

    /**
     * param[0] is the org name
     */
    RETROFIT_ALLOCATIONS: {
      id: 'Banyan.CmdDescription.RetrofitAllocationsForOrg',
      defaultMessage: 'Retrofit allocations for org "{0}"',
    },
    // TODO: Remove RETROFIT_ALLOCATIONS as retrofit allocations will be executed as part of Contract Switch command.

    /**
     * param[0] is the org name
     */
    ROLLBACK_ALLOCATION_CONTRACT: {
      id: 'Banyan.CmdDescription.RollbackAllocationContractForOrg',
      defaultMessage: 'Rollback allocation contract to ETLA contract for org "{0}"',
    },

    /**
     * param[0] is the org name
     */
    ROLLBACK_ALLOCATIONS: {
      id: 'Organizations.JobExecution.RollbackAllocationsForOrg',
      defaultMessage: 'Rollback allocations for org "{0}"',
    },

    ILLEGAL_OPRN: {
      id: 'Banyan.CmdDescription.IllegalOperation',
      defaultMessage: 'Error: illegal operation',
    },
    /**
     * param[0] is the element type
     * param[1] is the name
     */
    DEFAULT: {
      id: 'Banyan.CmdDescription.Default',
      defaultMessage: 'Operation on {0} {1}',
    },
  })
);

/* List of policy strings to be used in command description display.
 * NOTE:For any new items added here , the naming convention of the key should follow the method 'convertPolicyNameToKeyForLocalization' in Utils.ts
 */
export const POLICIES: Record<string, MessageDescriptor> = defineMessages({
  CLAIM_DOMAINS: {
    id: 'Banyan.CmdDescription.Pol.ClaimDomains',
    defaultMessage: 'Claim domains',
  },

  CREATE_CHILDREN: {
    id: 'Banyan.CmdDescription.Pol.CreateChildren',
    defaultMessage: 'Create child organizations',
  },

  RENAME_ORGS: {
    id: 'Banyan.CmdDescription.Pol.RenameOrg',
    defaultMessage: 'Rename organization',
  },

  DELETE_ORGS: {
    id: 'Banyan.CmdDescription.Pol.DeleteOrg',
    defaultMessage: 'Delete organization',
  },

  ALLOW_SYNC: {
    id: 'Banyan.CmdDescription.Pol.AllowSync',
    defaultMessage: 'Use directory sync',
  },

  MANAGE_ADMINS: {
    id: 'Banyan.CmdDescription.Pol.ManageAdmins',
    defaultMessage: 'Add or delete administrators',
  },

  INHERIT_ADMINS: {
    id: 'Banyan.CmdDescription.Pol.InheritAdmins',
    defaultMessage: 'Inherit system admins from parent',
  },

  INHERIT_USERS: {
    id: 'Banyan.CmdDescription.Pol.InheritUsers',
    defaultMessage: 'Inherit users from directories managed by the parent organization',
  },

  NON_CHILD_ALLOCATION: {
    id: 'Banyan.CmdDescription.Pol.NonChildAlloc',
    defaultMessage: 'Allocate product resources to organizations other than child organizations',
  },

  ALLOW_ADOBE_ID: {
    id: 'Banyan.CmdDescription.Pol.AllowAdobe',
    defaultMessage: 'Add Adobe ID users',
  },

  MANAGE_USER_GROUPS: {
    id: 'Banyan.CmdDescription.Pol.ManageUserGroups',
    defaultMessage: 'Manage User Groups',
  },

  INJECT_GROUP: {
    id: 'Banyan.CmdDescription.Pol.InjectGroup',
    defaultMessage: 'Duplicate user groups to another organization',
  },

  CHANGE_IDENTITY_CONFIG: {
    id: 'Banyan.CmdDescription.Pol.ChangeId',
    defaultMessage: 'Change identity configuration',
  },

  DIRECTORY_MOVE: {
    id: 'Banyan.CmdDescription.Pol.DirectoryMove',
    defaultMessage: 'Move a directory',
  },

  MANAGE_PRODUCTS: {
    id: 'Banyan.CmdDescription.Pol.ManageProducts',
    defaultMessage: 'Manage products',
  },

  INHERIT_SHARING_POLICY: {
    id: 'Banyan.CmdDescription.Pol.InheritSharingPolicy',
    defaultMessage: 'Inherit sharing policy from parent when organization is created',
  },

  SET_SHARING_POLICY: {
    id: 'Banyan.CmdDescription.Pol.SetSharingPolicy',
    defaultMessage: 'System or Storage admin can change asset sharing settings',
  },

  INHERIT_WORKSPACES_POLICY: {
    id: 'Banyan.CmdDescription.Pol.InheritWorkspacesPolicy',
    defaultMessage: 'Inherit workspace policy from parent when organization is created',
  },

  SET_WORKSPACES_POLICY: {
    id: 'Banyan.CmdDescription.Pol.SetWorkspacesPolicy',
    defaultMessage: 'System or Storage admin can change workspace policies',
  },

  // keys below (ALLOWED through UNLOCKED) CANNOT be changed without consulting JobExecution.computeAndLocalizePolicyImage
  ALLOWED: {
    id: 'Banyan.CmdDescription.Pol.Value.Allowed',
    defaultMessage: 'allowed',
  },

  NOT_ALLOWED: {
    id: 'Banyan.CmdDescription.Pol.Value.NotAllowed',
    defaultMessage: 'not allowed',
  },

  LOCKED: {
    id: 'Banyan.CmdDescription.Pol.GettingLocked',
    defaultMessage: 'lock',
  },

  UNLOCKED: {
    id: 'Banyan.CmdDescription.Pol.GettingUnlocked',
    defaultMessage: 'unlock',
  },
});

/**
 * This list refers to UResource/FI Enterprise Names.
 * TODO: A definitive list of Enterprise Names needs to be found and this list should be updated with the remaining names.
 * Resources like 'Licenses' are more banyan specific.
 * This list will have to be updated when new FI items are onboarded.
 * These will be used for display of Resource names throughout the UI.
 */
export const RESOURCE_NAMES: Record<string, MessageDescriptor> = defineMessages({
  LICENSES: {
    id: 'Banyan.Resources.Names.Licenses',
    defaultMessage: 'Licenses',
  },
  SIGN_TRANSACTIONS: {
    id: 'Banyan.Resources.Names.SignTransactions',
    defaultMessage: 'Sign Transactions',
  },
  ADOBE_STOCK_IMAGES: {
    id: 'Banyan.Resources.Names.AdobeStockImages',
    defaultMessage: 'Adobe Stock Images',
  },
  ADOBE_STOCK_PREMIUM_CREDITS: {
    id: 'Banyan.Resources.Names.AdobeStockPremiumCredits',
    defaultMessage: 'Adobe Stock Premium Credits',
  },
});

/**
 * This list is referred from https://git.corp.adobe.com/admin-tribe/onesie/blob/master/src/app/widgets/common/scorecard/scorecard-label/scorecard-label.constants.js
 * Units like 'Licenses' are more banyan specific. This list will have to be updated as and when new FI items are onboarded or more units are added
 * These will be used for display of Resource units throughout the UI.
 * NOTE: for any new items added here, the naming convention of the key should follow the method 'convertUnitValueToKeyForLocalization' in Utils.ts
 */
export const UNITS: Record<string, MessageDescriptor> = defineMessages({
  CREDIT: {
    id: 'Banyan.Resources.Units.Credit',
    defaultMessage: 'credit',
  },
  DEVICELICENSE: {
    // deprecated soon
    id: 'Banyan.Resources.Units.DeviceLicense',
    defaultMessage: 'deviceLicense',
  },
  IMAGE: {
    id: 'Banyan.Resources.Units.Image',
    defaultMessage: 'image',
  },
  INSTANCE: {
    id: 'Banyan.Resources.Units.Instance',
    defaultMessage: 'instance',
  },
  INTEGRATION: {
    id: 'Banyan.Resources.Units.Integration',
    defaultMessage: 'integration',
  },
  LICENSES: {
    id: 'Banyan.Resources.Units.Licenses',
    defaultMessage: 'Licenses',
  },
  USERS: {
    id: 'Banyan.Resources.Units.Users',
    defaultMessage: 'Users',
  },
  MACHINES: {
    id: 'Banyan.Resources.Units.Machines',
    defaultMessage: 'machines',
  },
  MILLION_GB_SECONDS: {
    id: 'Banyan.Resources.Units.MillionGbSeconds',
    defaultMessage: 'MILLION GB-SECONDS',
  },
  STOCK_IMAGE: {
    id: 'Banyan.Resources.Units.StockImage',
    defaultMessage: 'stock_image',
  },
  TRANSACTIONS: {
    id: 'Banyan.Resources.Units.Transactions',
    defaultMessage: 'transactions',
  },
  VIDEO: {
    id: 'Banyan.Resources.Units.Video',
    defaultMessage: 'video',
  },
});

/**
 * List of localized quantity values for resources.
 * Currently, the only quantity value that is localized is UNLIMITED (upper and lower case).
 */
export const RESOURCE_QUANTITIES: Record<string, MessageDescriptor> = defineMessages({
  UNLIMITED: {
    id: 'Banyan.Resources.Quantities.Unlimited',
    defaultMessage: 'UNLIMITED',
  },
});

// The keys in this list are referred from the enum OrgAdminType in IMS.ts
export const ADMIN_TYPES: Record<string, MessageDescriptor> = defineMessages({
  ORG_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.SystemAdmin',
    defaultMessage: 'System Admin',
  },
  COMPARTMENT_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.GlobalAdmin',
    defaultMessage: 'Global Admin',
  },
  COMPARTMENT_VIEWER: {
    id: 'Banyan.CmdDescription.Admins.GlobalAdminRO',
    defaultMessage: 'Global Viewer',
  },
  DEPLOYMENT_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.DeployAdmin',
    defaultMessage: 'Deployment Admin',
  },
  LICENSE_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.ProdProf',
    defaultMessage: 'Product Profile Admin',
  },
  PRODUCT_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.ProdAdmin',
    defaultMessage: 'Product Admin',
  },
  USER_GROUP_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.UserGroup',
    defaultMessage: 'User Group Admin',
  },
  STORAGE_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.StorageAdmin',
    defaultMessage: 'Storage Admin',
  },
  SUPPORT_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.SupportAdmin',
    defaultMessage: 'Support Admin',
  },
  CONTRACT_ADMIN: {
    id: 'Banyan.CmdDescription.Admins.ContractAdmin',
    defaultMessage: 'Contract Admin',
  },
});

// This type will check for the string to belong to any one of the command description keys used in CMD_DESCRIPTIONS at
// compile time
export type CmdDescriptionCodes = keyof typeof CMD_DESCRIPTIONS;
// This can be used to check if the string belongs to the keys at run time
export const cmdDescrpnCodeKeys = _.keys(CMD_DESCRIPTIONS);
export const cmdDescrpnCodeKeysForAdmins = _.filter(cmdDescrpnCodeKeys, (key: string) => key.includes('ADMIN'));
