import React, { Component } from 'react';
import { defineMessages, FormattedMessage as FM, injectIntl, WrappedComponentProps } from 'react-intl';
import SupportCases from './components/Cases/SupportCases';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';

interface SupportForGlobalAdminProps extends WrappedComponentProps {}
//this interface might be removed if we decide we don't need a side nav
interface SupportForGlobalAdminState {
  // stores which item was selected in the side navigation. It can only be one of the Support options
  // which makes sure that the selected item is always one of the given options
  selectedItem: 'Support Cases';
}

class SupportForGlobalAdmin extends Component<SupportForGlobalAdminProps, SupportForGlobalAdminState> {
  //messages might be removed if we decide we don't need a side nav
  // Items shown on the page which can be selected by the user
  messages = defineMessages({
    Cases: {
      id: 'SupportForGA.Item.SupportCases',
      defaultMessage: 'Support Cases',
    },
  });
  public constructor(props: SupportForGlobalAdminProps) {
    super(props);
  }

  /**
   * returns the content to be rendered (specifically displaying the SupportCases table)
   */

  render(): React.ReactNode {
    return (
      <div>
        <div className="App__content">
          <div style={{ padding: '1rem' }}>
            <Heading className="App__header">
              <FM id="Support.title.SupportCases" defaultMessage="Support Cases" />
            </Heading>
            <Rule variant="small" />
            <div style={{ padding: '1rem' }}>
              <SupportCases />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SupportForGlobalAdmin);
