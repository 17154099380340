import React from 'react';
import _ from 'lodash';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import { Table, TBody, TD, TR } from '@react/react-spectrum/Table';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import OrganizationPolicyRow from './OrganizationPolicyRow';
import './CompartmentPolicy.css';
import { LoadOrgDataService } from '../../../services/orgMaster/LoadOrgDataService';
import AdminPermission from '../../../services/authentication/AdminPermission';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import { UCompartmentPolicies } from '../../../services/orgMaster/UCompartmentPolicy';
import { CommandService } from '../../../services/Commands/CommandService';
import { ObjectTypes, OrgOperation } from '../../../services/orgMaster/OrgMaster';
import { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoHelpBubble from '../../../components/HelpBubble/HelpBubble';
import Analytics from '../../../Analytics/Analytics';
import ScrollableContent from '../Widgets/ScrollableContent';
import { MESSAGES } from '../../Messages';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';
import FloodgateService from '../../../services/floodgate/FloodgateService';
import { OrgToAdminAccessMap } from '../../../providers/BanyanCompartmentAPI';

interface CompartmentPolicyProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
}

interface CompartmentPolicyState {
  compartmentPolicy: UCompartmentPolicies;
  errorMessage: string;
}

interface OrgIdGAMap {
  [orgId: string]: boolean;
}

// NOTE: In case of any change to any of the policies, equivalent change has to be made in
// src/Compartments/ExportImport/Import/DataModelTypes.ts ImportOrgPoliciesDM structure
// and src/Compartments/ExportImport/Import/ImportHandler/ImportOrgPolicies.ts
export const messages = defineMessages({
  PaneHelp: {
    id: 'Organizations.Policies.Helptext',
    defaultMessage:
      'Policies shown here control some behaviors of the organization selected on the left.  They can' +
      ' restrict what Global and System administrators can do or specify default behaviors.  Policies can be locked which means they cannot be changed' +
      ' unless the Global Administrator has the required permission.',
  },
  FailedToLoadPolicies: {
    id: 'Organizations.Policies.FailedToLoadPolicies',
    defaultMessage: 'Unable to load policies: {error}',
  },
});

class CompartmentPolicy extends React.Component<CompartmentPolicyProps, CompartmentPolicyState> {
  abortController: AbortController;
  disabled: boolean;
  orgIdToIsGAMap: OrgIdGAMap; // map of orgId to boolean indicating if user if global admin of orgId. Stores results for `lockedBy` field of each policy

  constructor(props: CompartmentPolicyProps) {
    super(props);
    this.state = {
      compartmentPolicy: this.props.selectedOrg.compartmentPolicy,
      errorMessage: '', // if not empty, show the alert box. The errorMessage is defined if the loading of compartment policy fails on componentDidMount/componentDidUpdate
    };
    this.disabled = AdminPermission.readOnlyMode();
    this.abortController = new AbortController();
    this.orgIdToIsGAMap = {};
  }

  private static convertOrgsInfoToPermissionMap(adminAccessMap: OrgToAdminAccessMap): OrgIdGAMap {
    const orgIdToIsGAMap: OrgIdGAMap = {};
    _.forEach(Object.keys(adminAccessMap), (key: string): void => {
      orgIdToIsGAMap[key] = adminAccessMap[key];
    });
    return orgIdToIsGAMap;
  }

  private async loadPolicies(): Promise<void> {
    try {
      if (!this.props.selectedOrg.policiesLoaded) {
        // As policies are the first thing to load on EditCompartment component, it should never happen that policies tab is mounted and policies are not loaded
        await LoadOrgDataService.loadPolicies(this.props.selectedOrg.id, this.abortController.signal);
        this.orgIdToIsGAMap = {};
      }
      if (this.abortController.signal.aborted) return;
      if (_.isEmpty(this.orgIdToIsGAMap)) {
        // Stores unique orgId for `lockedBy` field and call OrgInfo for RW permissions
        const uniqueLockedByOrgs: Set<string> = new Set();
        _.forEach(this.props.selectedOrg.compartmentPolicy.policies, (policy) => {
          if (policy.lockedBy) {
            uniqueLockedByOrgs.add(policy.lockedBy);
          }
        });
        // call `/organizations/check-rw-orgs-permissions`
        const activeOrgId = OrgPickerController.getActiveOrgId();
        const listOfOrgs = activeOrgId
          ? Array.from(uniqueLockedByOrgs).concat([activeOrgId])
          : Array.from(uniqueLockedByOrgs);
        this.orgIdToIsGAMap = CompartmentPolicy.convertOrgsInfoToPermissionMap(
          await AdminPermission.isRWGlobalAdminForOrgs(listOfOrgs)
        );
      }
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      this.setState({
        errorMessage: '', // reset error message
        compartmentPolicy: this.props.selectedOrg.compartmentPolicy,
      });
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      const { formatMessage } = this.props.intl;
      this.setState({ errorMessage: formatMessage(messages.FailedToLoadPolicies, { error: error.message }) });
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async componentDidMount(): Promise<void> {
    await this.loadPolicies();
  }

  async componentDidUpdate(prevProps: CompartmentPolicyProps): Promise<void> {
    if (
      prevProps.selectedOrg.id !== this.props.selectedOrg.id ||
      this.isAnyPolicyDifferent(this.props.selectedOrg.compartmentPolicy, this.state.compartmentPolicy)
    ) {
      await this.loadPolicies();
    }
  }

  isAnyPolicyDifferent = (
    compartmentPolicy1: UCompartmentPolicies,
    compartmentPolicy2: UCompartmentPolicies
  ): boolean => {
    const policyNames = Object.keys(compartmentPolicy1.policies);
    return !!_.find(
      policyNames,
      (policyName) =>
        compartmentPolicy1.policies[policyName].value !== compartmentPolicy2.policies[policyName].value ||
        compartmentPolicy1.policies[policyName].lockedBy !== compartmentPolicy2.policies[policyName].lockedBy
    );
  };

  updatePolicyValue = (policyName: string | undefined, selection: Object): void => {
    if (policyName) {
      Analytics.fireCTAEvent(`policy value updated`);
      Analytics.firePolicyEvent(policyName);
      const originalPolicies = _.cloneDeep(this.state.compartmentPolicy);
      const editsToPolicies = _.cloneDeep(this.state.compartmentPolicy);
      editsToPolicies.policies[policyName].value = selection;
      CommandService.addEdit(
        this.props.selectedOrg,
        editsToPolicies,
        ObjectTypes.COMPARTMENT_POLICY,
        OrgOperation.UPDATE,
        originalPolicies,
        'UPDATE_POLICY',
        [CmdDescriptionUtils.getPathname(this.props.selectedOrg.id)]
      );
      this.props.update();
    }
  };

  updateLockedBy = (policyName: string | undefined): void => {
    const activeOrgId = OrgPickerController.getActiveOrgId();
    if (policyName) {
      Analytics.fireCTAEvent(`policy lockedBy updated`);
      Analytics.firePolicyEvent(policyName);
      const originalPolicies = _.cloneDeep(this.state.compartmentPolicy);
      const editsToPolicies = _.cloneDeep(this.state.compartmentPolicy);
      const currentLockedBy = editsToPolicies.policies[policyName].lockedBy;
      // TODO: allow only to global admin of parent org.  Any org at or above current lockedBy can clear it.
      if (_.isEmpty(currentLockedBy) && activeOrgId && this.orgIdToIsGAMap[activeOrgId]) {
        // lock a policy
        editsToPolicies.policies[policyName].lockedBy = activeOrgId;
      } else if (currentLockedBy === activeOrgId || (currentLockedBy && this.orgIdToIsGAMap[currentLockedBy])) {
        // unlock a policy
        editsToPolicies.policies[policyName].lockedBy = undefined;
      } else {
        // lock/unlock action is restricted
        return;
      }
      CommandService.addEdit(
        this.props.selectedOrg,
        editsToPolicies,
        ObjectTypes.COMPARTMENT_POLICY,
        OrgOperation.UPDATE,
        originalPolicies,
        'UPDATE_POLICY',
        [CmdDescriptionUtils.getPathname(this.props.selectedOrg.id)]
      );
      this.props.update();
    }
  };

  isLocked = (policyName: string): boolean => {
    const { compartmentPolicy } = this.state;
    const policy = compartmentPolicy.policies[policyName];
    if (!policy) {
      return false;
    }
    const lockedByOrg: string = policy.lockedBy || '';
    return !_.isEmpty(lockedByOrg) && lockedByOrg !== this.props.selectedOrg.id && !this.orgIdToIsGAMap[lockedByOrg];
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    if (!_.isEmpty(this.state.errorMessage)) {
      // show the alert box in policy table if we are unable to load the policy data
      return <Alert variant="error">{this.state.errorMessage}</Alert>;
    }
    const { compartmentPolicy } = this.state;
    // show wait if policies not loaded or state is yet to be updated
    return this.props.selectedOrg.policiesLoaded && !_.isEmpty(compartmentPolicy.policies) ? (
      <ScrollableContent uniqueId="EditCompartment_PolicyTableID" className="EditCompartment_tabContent">
        <React.Fragment>
          <GoHelpBubble goUrlKey={GoUrlKeys.organizationsPolicies}>
            <p>{formatMessage(messages.PaneHelp)}</p>
          </GoHelpBubble>

          <Table quiet id="policies.OrgAndAdminTable" data-testid="policy-table">
            <TBody>
              <TR>
                <TD>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.OrgAndAdminPolicyGroupHeading)}</span>
                  </div>
                </TD>
                <TD />
                <TD />
              </TR>
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.createChildren}
                isLocked={this.isLocked('createChildren')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.CreateChildPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.manageAdmins}
                isLocked={this.isLocked('manageAdmins')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.ManageAdminsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.renameOrgs}
                isLocked={this.isLocked('renameOrgs')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.RenameOrgsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.deleteOrgs}
                isLocked={this.isLocked('deleteOrgs')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.DeleteOrgsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.inheritAdmins}
                isLocked={this.isLocked('inheritAdmins')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.InheritAdminsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <TR>
                <TD>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.IdentityAndUsersPolicyGroupHeading)}</span>
                  </div>
                </TD>
                <TD />
                <TD />
              </TR>
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.inheritUsers}
                isLocked={this.isLocked('inheritUsers')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.InheritUsersPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              {/* <OrganizationPolicyRow
              organizationPolicy={editedCompartmentPolicy.policies.injectGroup}
              isLocked={this.isLocked('injectGroup')}
              isSwitchDisabled={this.disabled}
              policyDescription={formatMessage(MESSAGES.DupGroupsPolicy)}
              updatePolicyValue={this.updatePolicyValue}
              updateLockedBy={this.updateLockedBy}
            /> */}
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.allowAdobeID}
                isLocked={this.isLocked('allowAdobeID')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.AdobeIdPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.manageUserGroups}
                isLocked={this.isLocked('manageUserGroups')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.ManageUserGroupsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.claimDomains}
                isLocked={this.isLocked('claimDomains')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.ClaimDomainsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.changeIdentityConfig}
                isLocked={this.isLocked('changeIdentityConfig')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.IdConfigPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              {/* <OrganizationPolicyRow
              organizationPolicy={editedCompartmentPolicy.policies.directoryMove}
              isLocked={this.isLocked('directoryMove')}
              isSwitchDisabled={this.disabled}
              policyDescription={formatMessage(MESSAGES.MoveDirPolicy)}
              updatePolicyValue={this.updatePolicyValue}
              updateLockedBy={this.updateLockedBy}
            /> */}
              <TR>
                <TD>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.AllocationPolicyGroupHeading)}</span>
                  </div>
                </TD>
                <TD />
                <TD />
              </TR>
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.manageProducts}
                isLocked={this.isLocked('manageProducts')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.ManageProductsPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              {/* <OrganizationPolicyRow
              organizationPolicy={editedCompartmentPolicy.policies.nonChildAllocation}
              isLocked={this.isLocked('nonChildAllocation')}
              isSwitchDisabled={this.disabled}
              policyDescription={formatMessage(MESSAGES.NonChildAllocPolicy)}
              updatePolicyValue={this.updatePolicyValue}
              updateLockedBy={this.updateLockedBy}
            /> */}

              <TR>
                <TD>
                  <div className="CompartmentPolicy_subheading">
                    <span>{formatMessage(MESSAGES.AssetSharingPolicyGroupHeading)}</span>
                  </div>
                </TD>
                <TD />
                <TD />
              </TR>
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.setSharingPolicy}
                isLocked={this.isLocked('setSharingPolicy')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.SetSharingPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              <OrganizationPolicyRow
                organizationPolicy={compartmentPolicy.policies.inheritSharingPolicy}
                isLocked={this.isLocked('inheritSharingPolicy')}
                isSwitchDisabled={this.disabled}
                policyDescription={formatMessage(MESSAGES.InheritSharingPolicy)}
                updatePolicyValue={this.updatePolicyValue}
                updateLockedBy={this.updateLockedBy}
              />
              {FloodgateService.isFeatureEnabled(FloodgateService.INCLUDE_WORKSPACE_POLICY) && (
                <>
                  <OrganizationPolicyRow
                    organizationPolicy={compartmentPolicy.policies.setWorkspacesPolicy}
                    isLocked={this.isLocked('setWorkspacesPolicy')}
                    isSwitchDisabled={this.disabled}
                    policyDescription={formatMessage(MESSAGES.SetWorkspacesPolicy)}
                    updatePolicyValue={this.updatePolicyValue}
                    updateLockedBy={this.updateLockedBy}
                  />
                  <OrganizationPolicyRow
                    organizationPolicy={compartmentPolicy.policies.inheritWorkspacesPolicy}
                    isLocked={this.isLocked('inheritWorkspacesPolicy')}
                    isSwitchDisabled={this.disabled}
                    policyDescription={formatMessage(MESSAGES.InheritWorkspacesPolicy)}
                    updatePolicyValue={this.updatePolicyValue}
                    updateLockedBy={this.updateLockedBy}
                  />
                </>
              )}
            </TBody>
          </Table>
        </React.Fragment>
      </ScrollableContent>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}
export default injectIntl(CompartmentPolicy);
