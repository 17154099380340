import _ from 'lodash';

import configurations from '../configurations/config';
import AuthenticatedUser from '../services/authentication/AuthenticatedUser';

import SearchType from '../OrgMigration/components/SearchOrg/SearchType';
import GenerateBanyanSvcHeaders from '../services/providerUtils/generateBanyanSvcHeaders';

const url = `${configurations.jilApi.url}/v2`;
const tokenizedName = `organizations:search.tokenized_name?tokenized_name=`;
const exactName = `organizations:search.name?name=`;
const adminEmail = `organizations:search.admin_email`;
const claimedDomain = `organizations:search.claimed_domain?claimed_domain=`;
const eccEndUserIDName = `organizations:search.ecc_end_user_id?ecc_end_user_id=`;
const adminID = `organizations:search.admin_id?admin_id=`;

/**
 * The properties that are provided in the org.
 */

export interface SearchOrgResult {
  id: string;
  name: string;
}

/**
 * Verifies if an org id exist.
 */
export default class SearchOrgProvider {
  public static pageSize = 100; // FYI, a large customer could have 1300 orgs/contracts with the same ECC ID.
  /**
   * Verifies the orgs id.
   * @param searchType is type on which the search is made e.g. OrgId, OrgName, etc
   * @param searchValue is value to be searched
   * @returns a json the list of Orgs and the page number that was fetched
   * @throws an Error if the response was not successful/ok
   */
  static async searchOrg(
    searchType: string,
    searchValue: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    pageNumber: number = 0,
    abortController: AbortController
  ): Promise<SearchOrgResult[]> {
    let urlOrgSearch = '';
    let method = 'GET';
    const urlSearchParams = new URLSearchParams();

    const headers: Headers = GenerateBanyanSvcHeaders.generateJILSvcHeaders();
    const accessTokenForT3 = await AuthenticatedUser.getAuthAccountAccessToken(); // This JIL endpoint requires the Adobe Agent role. That role can only exist on Auth Accounts, not T2E accounts.
    headers.set('Authorization', `Bearer ${accessTokenForT3}`);

    switch (searchType) {
      case SearchType.OrgId:
        urlOrgSearch = `${url}/organizations/${searchValue}`;
        break;

      case SearchType.ExactName:
        urlOrgSearch = `${url}/${exactName}${searchValue}&page_size=${SearchOrgProvider.pageSize}&page=${pageNumber}`;
        break;

      case SearchType.ClaimedDomain:
        urlOrgSearch = `${url}/${claimedDomain}${searchValue}&org_type=ENTERPRISE&page_size=${SearchOrgProvider.pageSize}&page=${pageNumber}`;
        break;

      case SearchType.ECCEndUserID:
        urlOrgSearch = `${url}/${eccEndUserIDName}${searchValue}&antiCache=${Date.now()}&page_size=${
          SearchOrgProvider.pageSize
        }&page=${pageNumber}`;
        headers.append('Accept', 'application/vnd.com.adobe.jil.minimum+json');
        break;

      case SearchType.AdminID:
        urlOrgSearch = `${url}/${adminID}${searchValue}&antiCache=${Date.now()}&page_size=${
          SearchOrgProvider.pageSize
        }&page=${pageNumber}`;
        break;

      case SearchType.AdminEmail:
        urlOrgSearch = `${url}/${adminEmail}`;
        method = 'POST';
        urlSearchParams.append('admin_email', searchValue);
        urlSearchParams.append('page', pageNumber.toString());
        urlSearchParams.append('page_size', (100).toString());
        break;

      case SearchType.PartialName:
      default:
        urlOrgSearch = `${url}/${tokenizedName}${searchValue}&page_size=${SearchOrgProvider.pageSize}&page=${pageNumber}`;
    }

    const options: any = {
      method,
      headers: headers,
      signal: abortController.signal,
    };

    if (_.isEqual(method, 'POST')) {
      options.body = urlSearchParams;
    }

    const response = await fetch(urlOrgSearch, options);

    if (response.status !== 200) {
      throw new Error('Error while executing search. Please change the search term.');
    }

    return SearchType.OrgId === searchType ? [await response.json()] : await response.json();
  }
}
