/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { UOrgData } from '../../services/orgMaster/UOrg';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { filter, find, forEach, orderBy, toLower } from 'lodash';

/**
 * Build a tree of organizations from the org data
 * @param orgId - the id of the organization to build the tree from
 * @param orgHierarchyData - the list of organizations
 * @returns a TreeNode representing the organization hierarchy
 */
export const buildOrgHierarchy = (orgId: string, orgHierarchyData: UOrgData[]): TreeNode => {
  const orgData = find(orgHierarchyData, (org: UOrgData): boolean => org.id === orgId);
  if (orgData) {
    const childrenList = filter(orgHierarchyData, (org: UOrgData): boolean => org.parentOrgId === orgId);
    // sort child org list in ascending order by name
    const sortedChildList = orderBy(childrenList, [(child: UOrgData) => toLower(child.name)]);
    const childTreeNodes: TreeNode[] = [];
    forEach(sortedChildList, (org: UOrgData): void => {
      const childTreeNode = buildOrgHierarchy(org.id as string, orgHierarchyData);
      childTreeNodes.push(childTreeNode);
    });
    return {
      key: orgId,
      data: {
        name: orgData.name ?? '',
        id: orgId,
      },
      children: childTreeNodes,
    };
  }
  return { key: orgId, data: { name: '', id: orgId }, children: [] };
};
