import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';

interface DeleteProductWarningDialogProps {
  onClose: () => void;
  submit: () => void;
}

// NOTE: as of April 2021 this appears to be dead code
function DeleteProductWarningDialog(props: DeleteProductWarningDialogProps) {
  return (
    <Dialog
      variant="destructive"
      title="Re-parent operations will delete products"
      confirmLabel="Proceed"
      cancelLabel="Cancel"
      onConfirm={(): void => props.submit()}
      onClose={(): void => {
        props.onClose();
      }}
    >
      Reparent operations will delete products. Please verify the products that will be deleted (listed in the warning
      on this page). Selecting &quot;Proceed&quot;, will execute reparent operations and delete the products. Selecting
      &quot;Cancel&quot;, will prevent reparent operation and no products will be deleted.
    </Dialog>
  );
}

export default DeleteProductWarningDialog;
