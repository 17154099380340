import React from 'react';
import * as _ from 'lodash';
import { TR, TD } from '@react/react-spectrum/Table';
import Switch from '@react/react-spectrum/Switch';
import LockClosed from '@react/react-spectrum/Icon/LockClosed';
import LockOpen from '@react/react-spectrum/Icon/LockOpen';
import Checkbox from '@react/react-spectrum/Checkbox';
import ComboBox from '@react/react-spectrum/ComboBox';
import { SelectOption } from '@react/react-spectrum/Select';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { LockActionEnum } from '../../../../models/TemplatePolicy';
import { ReactComponent as LockClosedOutline } from '../../../../images/S_LockClosedOutline_18_N.svg';
import './TemplatePolicyListRow.css';

interface TemplatePolicyListRowProps extends WrappedComponentProps {
  policyName: string;
  policyDescription: string;
  policyValue: boolean;
  policyLockAction: LockActionEnum;
  isEditable: boolean;
  isChecked?: boolean;
  updatePolicyValue?: (policyName: string | undefined, selection: boolean) => void;
  updatePolicyLockAction?: (policyName: string | undefined, policyLockAction: string) => void;
  checkUncheckPolicy?: (policyName: string) => void;
}

interface TemplatePolicyListRowState {
  showComboboxMenu: boolean;
}

const messages = defineMessages({
  allowed: {
    id: 'EditCompartment.Polcies.allowed',
    defaultMessage: 'Allowed',
  },
  notAllowed: {
    id: 'EditCompartment.Polcies.notAllowed',
    defaultMessage: 'Not allowed',
  },
  lock: {
    id: 'EditCompartment.Polcies.LockAction.lock',
    defaultMessage: 'Lock',
  },
  unlock: {
    id: 'EditCompartment.Polcies.LockAction.unlock',
    defaultMessage: 'Unlock',
  },
  keepAsIs: {
    id: 'EditCompartment.Polcies.LockAction.keepAsIs',
    defaultMessage: 'Keep as is',
  },
});

class TemplatePolicyListRow extends React.Component<TemplatePolicyListRowProps, TemplatePolicyListRowState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showComboboxMenu: false,
    };
  }

  onPolicyValueChange = (policyName: string | undefined, selection: boolean): void => {
    const { updatePolicyValue } = this.props;
    if (updatePolicyValue) {
      updatePolicyValue(policyName, selection);
    }
  };

  onPolicyLockActionChange = (policyName: string | undefined, policyLockAction: string): void => {
    const { updatePolicyLockAction } = this.props;
    if (updatePolicyLockAction) {
      updatePolicyLockAction(policyName, policyLockAction);
    }
  };

  onPolicySelectionChange = (policyName: string): void => {
    const { checkUncheckPolicy } = this.props;
    if (checkUncheckPolicy) {
      checkUncheckPolicy(policyName);
    }
  };

  getComboboxOptions = (): SelectOption[] => {
    const { formatMessage } = this.props.intl;

    return _.map(Object.keys(LockActionEnum), (key: string): SelectOption => {
      let icon: React.ReactNode;
      let label: string;
      switch (key) {
        case 'LOCK':
          icon = <LockClosed size="S" alt="lock-policy" />;
          label = formatMessage(messages.lock);
          break;
        case 'UNLOCK':
          icon = <LockOpen size="S" alt="unlock-policy" />;
          label = formatMessage(messages.unlock);
          break;
        case 'AS_IS':
        default:
          icon = <LockClosedOutline className="spectrum-Icon spectrum-Icon--sizeS" />;
          label = formatMessage(messages.keepAsIs);
          break;
      }
      return { label, value: key, icon };
    });
  };

  getCurrentLockActionParameters = (policyLockAction: LockActionEnum): [React.ReactNode, string] => {
    const { formatMessage } = this.props.intl;
    switch (policyLockAction) {
      case 'LOCK':
        return [<LockClosed size="S" className="LockActionIcon" />, formatMessage(messages.lock)];
      case 'UNLOCK':
        return [<LockOpen size="S" className="LockActionIcon" />, formatMessage(messages.unlock)];
      case 'AS_IS':
      default:
        return [
          <LockClosedOutline className="spectrum-Icon spectrum-Icon--sizeS LockActionIcon" />,
          formatMessage(messages.keepAsIs),
        ];
    }
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { policyName, policyDescription, policyValue, policyLockAction, isEditable, isChecked } = this.props;
    const { showComboboxMenu } = this.state;

    const policySwitchHandler = !isEditable
      ? undefined
      : (selection: boolean): void => {
          this.onPolicyValueChange(policyName, selection);
        };

    const [currentLockActionIcon, comboboxCurrentValue]: [React.ReactNode, string] =
      this.getCurrentLockActionParameters(policyLockAction);

    return (
      <TR>
        {isEditable && (
          <TD>
            <Checkbox
              defaultChecked={isChecked}
              onChange={(): void => this.onPolicySelectionChange(policyName)}
              aria-labelledby={policyName}
            />
          </TD>
        )}
        <TD>
          <FieldLabel label={policyDescription} id={policyName} className="PolicyFieldLabel" labelFor={policyName} />
        </TD>
        <TD className={isEditable ? 'table__td--switch-absence' : undefined}>
          {(!isEditable || (isEditable && isChecked)) && (
            <Switch
              checked={policyValue}
              disabled={!isEditable}
              onChange={policySwitchHandler}
              className="policies-ToggleSwitch"
              aria-labelledby={`AllowedOption${policyName}`}
            >
              <span id={`AllowedOption${policyName}`}>
                {policyValue ? formatMessage(messages.allowed) : formatMessage(messages.notAllowed)}
              </span>
            </Switch>
          )}
        </TD>
        <TD className={isEditable ? 'table__td--lock-action-absence' : undefined}>
          {isEditable ? (
            isChecked && (
              <div>
                <span id={`LockOption${policyName}`}>{currentLockActionIcon}</span>
                <ComboBox
                  quiet
                  options={this.getComboboxOptions()}
                  value={comboboxCurrentValue}
                  onSelect={(value: SelectOption): void => this.onPolicyLockActionChange(policyName, value.value)}
                  showMenu={showComboboxMenu}
                  onMenuToggle={(): void =>
                    this.setState((state) => {
                      return { showComboboxMenu: !state.showComboboxMenu };
                    })
                  }
                  onBlur={(): void => this.setState({ showComboboxMenu: true })}
                  onFocus={(): void => this.setState({ showComboboxMenu: false })}
                  aria-labelledby={`LockOption${policyName}`}
                />
              </div>
            )
          ) : (
            <React.Fragment>
              {currentLockActionIcon}
              {comboboxCurrentValue}
            </React.Fragment>
          )}
        </TD>
      </TR>
    );
  }
}

export default injectIntl(TemplatePolicyListRow);
