import * as _ from 'lodash';
import React, { Key, useCallback } from 'react';
import { Picker, Section, Item, Image, Text, ViewProps } from '@adobe/react-spectrum';

import { UProduct } from '../../../services/orgMaster/UProduct';
import SelectorSection from '../../../components/selectors/SelectorSection';
import { ProdAllocProductSelectorData } from '../../services/productAllocationData/ProductResourceMenu';

/**
 * ProdAllocProductSelector is an RSV3 Picker component that
 * displays and allows selections of products.  Products are split
 * into 2 sections: allocatable and unallocatable.
 */

interface ProdAllocProductSelectorProps extends ViewProps<any> {
  label: string; // placeholder label and aria-label for the selector
  selectedProduct: UProduct | undefined; // currently selected product
  options: ProdAllocProductSelectorData; // data for the selector (specifies products, groups, and which products are not editable)
  onProductSelect: (value: string) => void; // callback executed when a product is selected
}

function ProdAllocProductSelector(props: ProdAllocProductSelectorProps): React.ReactElement {
  const { width, label, options, selectedProduct, onProductSelect, ...otherProps } = props;
  const { disabledProductKeys, productMenuItemSections } = options;
  const onProductKeySelect = useCallback((key: Key) => onProductSelect(key as string), []);
  return (
    <Picker
      width={width}
      placeholder={label}
      selectedKey={selectedProduct?.id}
      items={productMenuItemSections}
      disabledKeys={disabledProductKeys}
      isDisabled={_.isEmpty(productMenuItemSections)}
      onSelectionChange={onProductKeySelect}
      aria-label={label}
      {...otherProps}
    >
      {(selectorGroup: SelectorSection<UProduct>) => (
        <Section title={selectorGroup.sectionName} key={selectorGroup.sectionName} items={selectorGroup.sectionData}>
          {(product: UProduct) => (
            <Item key={product.id} textValue={product.name}>
              <Image width="size-300" height="size-300" slot="icon" src={product.icons.svg ?? ''} alt="" />
              <Text>{product.name}</Text>
            </Item>
          )}
        </Section>
      )}
    </Picker>
  );
}
export default ProdAllocProductSelector;
