/**
 * Enumeration for the type of Toast.  Different toasts
 * have different colors indicating error, info, etc.
 */
export enum BanyanToastType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
