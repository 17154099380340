import _ from 'lodash';
import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Textfield from '@react/react-spectrum/Textfield';
import Textarea from '@react/react-spectrum/Textarea';
import Well from '@react/react-spectrum/Well';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import Wait from '@react/react-spectrum/Wait';
import AlertBanner from '../../../../components/AlertBanner/AlertBanner';
import Analytics from '../../../../Analytics/Analytics';
import TemplatePolicy, { LockActionEnum } from '../../../../models/TemplatePolicy';
import { OrganizationTemplateData } from '../../../../models/OrganizationTemplate';
import { OrganizationPolicy, UCompartmentPolicies } from '../../../../services/orgMaster/UCompartmentPolicy';
import TemplatePolicyList from '../TemplatePolicyList/TemplatePolicyList';
import CheckedPoliciesMap from '../CheckedPoliciesMap';
import TemplatePolicyMap from '../TemplatePolicyMap';
import BanyanCompartmentAPI from '../../../../providers/BanyanCompartmentAPI';
import Constants from '../../../../constants/Constants';
import { MESSAGES } from '../../../Messages';
import '../../../common.css';
import '../CreateTemplate/CreateTemplate.css';

interface EditTemplateProps extends WrappedComponentProps {
  orgId: string;
  templateId: string;
  templateName: string;
  updateTemplate: (templateId: string, templateBody: OrganizationTemplateData) => Promise<string | undefined>;
}

interface EditTemplateState {
  templateName: string;
  templateDescription: string;
  templatePolicyMap: TemplatePolicyMap;
  checkedPoliciesMap: CheckedPoliciesMap;
  isLoading: boolean;
  wasUpdated: boolean;
  loadErrorMsg?: string;
  updateErrorMsg?: string;
}

const messages = defineMessages({
  EditTemplateTitle: {
    id: 'EditCompartment.Templates.Edit.Title',
    defaultMessage: 'Edit {templateName}',
  },
  Update: {
    id: 'EditCompartment.Templates.Edit.Update',
    defaultMessage: 'Update now',
  },
  Cancel: {
    id: 'EditCompartment.Templates.Edit.Cancel',
    defaultMessage: 'Cancel',
  },
  NameLabel: {
    id: 'EditCompartment.Templates.Edit.NameLabel',
    defaultMessage: 'Policy Template Name',
  },
  NamePlaceholder: {
    id: 'EditCompartment.Templates.Edit.NamePlaceholder',
    defaultMessage: '1-100 characters',
  },
  DescLabel: {
    id: 'EditCompartment.Templates.Edit.DescriptionLabel',
    defaultMessage: 'Description',
  },
  DescPlaceholder: {
    id: 'EditCompartment.Templates.Edit.DescPlaceholder',
    defaultMessage: 'Descriptive text here',
  },
});

class EditTemplate extends React.Component<EditTemplateProps, EditTemplateState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted

  constructor(props: any) {
    super(props);
    Analytics.fireCTAEvent(`Edit template dialog opened`);
    const defaultTemplatePolicyMap = this.getDefaultTemplatePolicyMap();
    this.state = {
      templateName: '',
      templateDescription: '', // field is used.
      templatePolicyMap: defaultTemplatePolicyMap,
      checkedPoliciesMap: Object.keys(defaultTemplatePolicyMap).reduce(
        (policyNames, policyName) => ({ ...policyNames, [policyName]: false }),
        {}
      ),
      isLoading: false,
      wasUpdated: false,
      loadErrorMsg: undefined,
      updateErrorMsg: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.loadTemplate();
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  private loadTemplate = async (): Promise<void> => {
    const { orgId, templateId } = this.props;
    const { formatMessage } = this.props.intl;
    if (!templateId) {
      return;
    }
    this.setState({ isLoading: true, loadErrorMsg: undefined });
    try {
      const responseData: OrganizationTemplateData = await BanyanCompartmentAPI.getPolicyTemplate(orgId, templateId);
      if (responseData) {
        this.setState({
          templateName: responseData.name,
          templateDescription: responseData.description,
          templatePolicyMap: this.getTemplatePolicyMap(responseData),
        });
      }
    } catch (error) {
      this.setState({
        loadErrorMsg: `${formatMessage(MESSAGES.TemplateGETApiError)} : ${error.message}`,
      });
    }
    this.setState({ isLoading: false });
  };

  private isTemplateInValid = (): boolean => {
    if (
      _.isEmpty(this.state.templateName.trim()) ||
      this.state.templateName.trim.length > Constants.POLICY_TEMPLATE_NAME_MAX_LENGTH ||
      this.state.isLoading
    ) {
      return true;
    }
    if (_.isEmpty(Object.keys(this.state.checkedPoliciesMap))) {
      return true;
    }
    return !this.state.wasUpdated;
  };

  private changeTemplateName = (templateName: string): void => {
    this.setState({
      templateName,
      wasUpdated: true,
    });
  };

  private changeTemplateDescription = (templateDescription: string): void => {
    this.setState({
      templateDescription,
      wasUpdated: true,
    });
  };

  private getDefaultTemplatePolicyMap = (): TemplatePolicyMap => {
    const uCompartmentPolicies: UCompartmentPolicies = new UCompartmentPolicies();
    uCompartmentPolicies.setDefaultPolicies();
    const templatePolicyMap: TemplatePolicyMap = {};
    if (uCompartmentPolicies.policies) {
      Object.values(uCompartmentPolicies.policies).forEach((organizationPolicy: OrganizationPolicy) => {
        const templatePolicy: TemplatePolicy = new TemplatePolicy();
        templatePolicy.name = organizationPolicy.name || '';
        templatePolicy.type = organizationPolicy.type || '';
        templatePolicy.value = organizationPolicy.defaultValue || true;
        templatePolicy.lockAction = LockActionEnum.AS_IS;
        templatePolicyMap[templatePolicy.name] = templatePolicy;
      });
    }
    return templatePolicyMap;
  };

  private getTemplatePolicyMap = (template: OrganizationTemplateData): TemplatePolicyMap => {
    const templatePolicyMap: TemplatePolicyMap = { ...this.state.templatePolicyMap };
    this.setState((state) => {
      const checkedPoliciesMap: CheckedPoliciesMap = { ...state.checkedPoliciesMap };

      template.policies.forEach((templatePolicy: TemplatePolicy) => {
        templatePolicyMap[templatePolicy.name] = { ...templatePolicy };
        checkedPoliciesMap[templatePolicy.name] = true;
      });
      return { checkedPoliciesMap };
    });

    return templatePolicyMap;
  };

  private updateTemplate = async (): Promise<boolean> => {
    Analytics.fireCTAEvent(`Edit template dialog confirmed`);
    const { templateName, templateDescription, checkedPoliciesMap, templatePolicyMap } = this.state;
    const { templateId } = this.props;
    const { formatMessage } = this.props.intl;
    const templateBody: OrganizationTemplateData = {
      name: templateName,
      description: templateDescription,
      policies: [],
    };
    Object.keys(checkedPoliciesMap)
      .filter((policyName: string): boolean => checkedPoliciesMap[policyName])
      .forEach((policyName: string): void => {
        templateBody.policies.push(templatePolicyMap[policyName]);
      });
    this.setState({ updateErrorMsg: undefined, isLoading: true });
    let isSuccess = true;
    await this.props.updateTemplate(templateId, templateBody).then((data) => {
      if (!_.isEmpty(data)) {
        this.setState({ updateErrorMsg: `${formatMessage(MESSAGES.TemplatePATCHApiError)} : ${data}` });
        isSuccess = false;
      }
    });
    this.setState({ isLoading: false });
    return isSuccess;
  };

  updatePolicyValue = (policyName: string | undefined, selection: boolean): void => {
    if (policyName) {
      this.setState((state) => {
        const policyMap: TemplatePolicyMap = { ...state.templatePolicyMap }; // PL: Why is this done this way: flattening and then unflattening? Is this a copy and type conversion?
        // MD: Above is required as nested field of json object in state needs to be updated. If field to be updated was at first level, we wouldn't need this process. But since field is nested and located at level 2, we need this process. No type conversion.
        const policyToUpdate: TemplatePolicy = { ...policyMap[`${policyName}`] };
        policyToUpdate.value = selection;
        policyMap[`${policyName}`] = policyToUpdate;
        return {
          templatePolicyMap: policyMap,
          wasUpdated: true,
        };
      });
    }
  };

  updatePolicyLockAction = (policyName: string | undefined, policyLockAction: string): void => {
    if (policyName) {
      this.setState((state) => {
        const policyMap: TemplatePolicyMap = { ...state.templatePolicyMap };
        const policyToUpdate: TemplatePolicy = { ...policyMap[`${policyName}`] };
        if (policyLockAction === LockActionEnum.LOCK) {
          policyToUpdate.lockAction = LockActionEnum.LOCK;
        } else if (policyLockAction === LockActionEnum.UNLOCK) {
          policyToUpdate.lockAction = LockActionEnum.UNLOCK;
        } else {
          policyToUpdate.lockAction = LockActionEnum.AS_IS;
        }
        policyMap[`${policyName}`] = policyToUpdate;

        return {
          templatePolicyMap: policyMap,
          wasUpdated: true,
        };
      });
    }
  };

  checkUncheckPolicy = (policyName: string): void => {
    if (policyName) {
      this.setState((state) => {
        const checkedPoliciesMap: CheckedPoliciesMap = { ...state.checkedPoliciesMap };
        checkedPoliciesMap[policyName] = !checkedPoliciesMap[policyName];
        return {
          checkedPoliciesMap,
          wasUpdated: true,
        };
      });
    }
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const { templateName: templateNameFromProps } = this.props;
    const {
      templateName,
      templateDescription,
      isLoading,
      templatePolicyMap,
      checkedPoliciesMap,
      loadErrorMsg,
      updateErrorMsg,
    } = this.state;

    return (
      <Dialog
        {...this.props} // required as onClose() is provided by ModalTrigger
        title={formatMessage(messages.EditTemplateTitle, { templateName: templateNameFromProps })}
        confirmLabel={formatMessage(messages.Update)}
        cancelLabel={formatMessage(messages.Cancel)}
        role="dialog"
        onCancel={(): void => Analytics.fireCTAEvent(`Edit template dialog canceled`)}
        onConfirm={(): Promise<boolean> => this.updateTemplate()}
        confirmDisabled={this.isTemplateInValid()}
      >
        <div className="CreateTemplate__headerMessage">
          <FormattedMessage
            id="EditCompartment.Templates.Edit.Instructions"
            defaultMessage="Edit the policy template and select the policies (at least one) to include in this template. Then set values
          for the policies you selected."
          />
        </div>
        {loadErrorMsg ? (
          <AlertBanner variant="error">{loadErrorMsg}</AlertBanner>
        ) : (
          <React.Fragment>
            {updateErrorMsg && (
              <div>
                <AlertBanner variant="error" closeable onClose={() => this.setState({ updateErrorMsg: undefined })}>
                  {updateErrorMsg}
                </AlertBanner>
              </div>
            )}
            <div className="CreateTemplate__fieldGroup">
              <div className="CreateTemplate__fieldContainer">
                <FieldLabel
                  label={formatMessage(messages.NameLabel)}
                  className="CreateTemplate__fieldLabel"
                  necessity="required"
                  labelFor="edit-template-name-textfield"
                />
                <Textfield
                  id="edit-template-name-textfield"
                  placeholder={formatMessage(messages.NamePlaceholder)}
                  value={templateName}
                  onChange={(editedTemplateName): void => this.changeTemplateName(editedTemplateName)}
                  readOnly={isLoading}
                  autoFocus
                />
              </div>
              <div className="CreateTemplate__fieldContainer">
                <FieldLabel
                  label={formatMessage(messages.DescLabel)}
                  className="CreateTemplate__fieldLabel"
                  labelFor="edit-template-desc-textarea"
                />
                <Textarea
                  id="edit-template-desc-textarea"
                  className="CreateTemplate__text-area"
                  placeholder={formatMessage(messages.DescPlaceholder)}
                  value={templateDescription}
                  onChange={(editedTemplateDescription): void =>
                    this.changeTemplateDescription(editedTemplateDescription)
                  }
                  readOnly={isLoading}
                />
              </div>
            </div>
            <Well className="CreateTemplate__policyWell">
              {!isLoading ? (
                <TemplatePolicyList
                  templatePolicyMap={templatePolicyMap}
                  isEditable
                  checkedPoliciesMap={checkedPoliciesMap}
                  updatePolicyValue={this.updatePolicyValue}
                  updatePolicyLockAction={this.updatePolicyLockAction}
                  checkUncheckPolicy={this.checkUncheckPolicy}
                />
              ) : (
                <Wait className="Load_wait" />
              )}
            </Well>
          </React.Fragment>
        )}
      </Dialog>
    );
  }
}

export default injectIntl(EditTemplate);
