import React, { Component } from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import { Grid, GridColumn, GridRow } from '@react/react-spectrum/Grid';
import Button from '@react/react-spectrum/Button';

import AdobeAgentPaths from './components/AdobeAgentPaths';

import './OrgMigrationForAdobeAgent.css';
import '../App.css';
import Analytics from '../Analytics/Analytics';

import MoveStandAloneOrgIntoAHierarchy from './components/MoveStandAloneOrgIntoAHierarchy';
import ConvertChildOrgIntoStandaloneOrgs from './components/ConvertChildOrgsIntoStandaloneOrgs';
import ConvertETLAToAllocationContract from './components/ConvertETLAToAllocationContract';
import RevertAllocationContractToETLA from './components/RevertAllocationContractToETLA';

interface OrgMigrationForAdobeAgentState {
  selectedPath: AdobeAgentPaths;
}

interface OrgMigrationForAdobeAgentProps extends WrappedComponentProps {}

const messages = defineMessages({
  StandAloneOrgToParentOrg: {
    id: 'OrgMigrationForAA.StandAloneOrgToParentOrg',
    defaultMessage: 'Move standalone orgs into a hierarchy',
  },
  ChildOrgToStandAloneOrg: {
    id: 'OrgMigrationForAA.ChildOrgToStandAloneOrg',
    defaultMessage: 'Convert child orgs into standalone orgs',
  },
  OptionLabel: {
    id: 'OrgMigrationForAA.OptionLabel',
    defaultMessage: 'What would you like to do?',
  },
  ConvertETLAToAllocationContract: {
    id: 'OrgMigrationForAA.ConvertETLAToAllocationContract',
    defaultMessage: 'Convert ETLA to allocation contracts',
  },
  RevertAllocationContractsToETLA: {
    id: 'OrgMigrationForAA.RevertAllocationContractsToETLA',
    defaultMessage: 'Revert allocation contracts to ETLA',
  },
});

class OrgMigrationForAdobeAgent extends Component<OrgMigrationForAdobeAgentProps, OrgMigrationForAdobeAgentState> {
  constructor(props: OrgMigrationForAdobeAgentProps) {
    super(props);
    this.state = {
      selectedPath: AdobeAgentPaths.SelectPathToTake,
    };
  }

  /**
   * Displays the path to take screen and also clears any child and parent orgs if selected.
   */
  setPath = (): void => {
    this.setState({
      selectedPath: AdobeAgentPaths.SelectPathToTake,
    });
  };

  render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    switch (this.state.selectedPath) {
      case AdobeAgentPaths.ConvertChildOrgIntoStandaloneOrgs:
        return <ConvertChildOrgIntoStandaloneOrgs setPath={this.setPath} {...this.props} />;

      case AdobeAgentPaths.MoveStandaloneOrgsIntoAHeirarchy:
        return <MoveStandAloneOrgIntoAHierarchy setPath={this.setPath} {...this.props} />;

      case AdobeAgentPaths.ConvertETLAToAllocationContract:
        return <ConvertETLAToAllocationContract setPath={this.setPath} {...this.props} />;

      case AdobeAgentPaths.RevertAllocationContractToETLA:
        return <RevertAllocationContractToETLA setPath={this.setPath} {...this.props} />;

      default:
        return (
          <Grid variant="fixed">
            <GridRow
              align={{
                xs: 'center',
                sm: 'center',
                md: 'center',
              }}
            >
              <GridColumn size={[12, 12, 12]}>
                <div className="OrgMigrationSelect__pathToTake">
                  <div>
                    <h1>{formatMessage(messages.OptionLabel)}</h1>
                  </div>
                  <div className="OrgMigration__btncontainer">
                    <Button
                      label={formatMessage(messages.ChildOrgToStandAloneOrg)}
                      variant="primary"
                      onClick={(): void => {
                        Analytics.fireCTAEvent('Convert child orgs into standalone orgs selected');
                        this.setState({ selectedPath: AdobeAgentPaths.ConvertChildOrgIntoStandaloneOrgs });
                      }}
                      className="OrgMigration__btn"
                    />
                    <Button
                      label={formatMessage(messages.StandAloneOrgToParentOrg)}
                      variant="primary"
                      onClick={(): void => {
                        Analytics.fireCTAEvent('Move standalone orgs into a hierarchy selected');
                        this.setState({ selectedPath: AdobeAgentPaths.MoveStandaloneOrgsIntoAHeirarchy });
                      }}
                      className="OrgMigration__btn"
                    />
                    <Button
                      label={formatMessage(messages.ConvertETLAToAllocationContract)}
                      variant="primary"
                      onClick={(): void => {
                        Analytics.fireCTAEvent('Convert ETLA to allocation contracts');
                        this.setState({ selectedPath: AdobeAgentPaths.ConvertETLAToAllocationContract });
                      }}
                      className="OrgMigration__btn"
                    />
                    <Button
                      label={formatMessage(messages.RevertAllocationContractsToETLA)}
                      variant="primary"
                      onClick={(): void => {
                        Analytics.fireCTAEvent('Revert allocation contracts to ETLA');
                        this.setState({ selectedPath: AdobeAgentPaths.RevertAllocationContractToETLA });
                      }}
                      className="OrgMigration__btn"
                    />
                  </div>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        );
    }
  }
}

export default injectIntl(OrgMigrationForAdobeAgent);
