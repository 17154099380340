import React from 'react';

import _ from 'lodash';

import Dialog from '@react/react-spectrum/Dialog';
import Checkbox from '@react/react-spectrum/Checkbox';
import RadioGroup from '@react/react-spectrum/RadioGroup';
import Radio from '@react/react-spectrum/Radio';
import { Grid, GridRow } from '@react/react-spectrum/Grid';
import { defineMessages, FormattedMessage, WrappedComponentProps, IntlProvider } from 'react-intl';

import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import './ExportDialog.css';

import Analytics from '../../../Analytics/Analytics';
import ExportOptions from '../../../services/orgMaster/ExportOptions';
import UExportReport from '../../../services/orgMaster/UExportReport';
import BanyanJobsApi from '../../../providers/BanyanJobsApi';
import { ExportJob, JobProgressResponse, JobResultStatus } from '../../../services/jobs/JobData';
import BanyanJobs from '../../../services/jobs/BanyanJobs';
import { CancelPromise, CancelStatus } from '../../../services/utils/CancelPromise';
import ToastMaster from '../../../services/toastMaster/ToastMaster';
import { BanyanToastType } from '../../../components/BanyanToast/BanyanToastType';
import HeaderConsts from '../../../components/BanyanShell/HeaderConstants';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import { RouteComponentProps } from '../../../services/utils/withRouter';

interface ExportOptionsType {
  label: string;
  value: Details;
  selected: boolean;
}

interface ExportDialogState {
  format: string;
  exportOptions: ExportOptionsType[];
  errorMessage: string;
}

interface ExportDialogProps extends WrappedComponentProps, RouteComponentProps {
  orgID: string | null | undefined;
}

function ExportDialog(props: Omit<ExportDialogProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <ExportDialogInternal {...props} />
    </IntlProvider>
  );
}

// FYI: the enum name must match (sensitive) the @QueryParam of the getExportData fn in the
// com.adobe.banyansvc.resource.BanyanAPICompartment class of Banyan svc.
enum Details {
  organizations,
  products,
  productprofiles,
  admins,
  usergroups,
  domains,
  directories,
  orgpolicies,
}

const messages = defineMessages({
  Export: {
    id: 'Organizations.Export.Export',
    defaultMessage: 'Export',
  },
  Cancel: {
    id: 'Organizations.Export.Cancel',
    defaultMessage: 'Cancel',
  },
  Organizations: {
    id: 'Organizations.Export.Organizations',
    defaultMessage: 'Organizations',
  },
  Products: {
    id: 'Organizations.Export.Products',
    defaultMessage: 'Products',
  },
  ProductProfiles: {
    id: 'Organizations.Export.ProductProfiles',
    defaultMessage: 'Product Profiles',
  },
  Administrators: {
    id: 'Organizations.Export.Administrators',
    defaultMessage: 'Administrators',
  },
  UserGroups: {
    id: 'Organizations.Export.UserGroups',
    defaultMessage: 'User Groups',
  },
  Domains: {
    id: 'Organizations.Export.Domains',
    defaultMessage: 'Domains',
  },
  Directories: {
    id: 'Organizations.Export.Directories',
    defaultMessage: 'Directories',
  },
  Policies: {
    id: 'Organizations.Export.Policies',
    defaultMessage: 'Policies',
  },
  Download: {
    id: 'Organizations.Export.DownloadMessage',
    defaultMessage: 'File downloaded successfully. Please check the downloads.',
  },
  CsvLimit: {
    id: 'Organizations.Export.CsvLimitMessage',
    defaultMessage: 'For csv, select only one detail to export.',
  },
  CsvExportRestriction: {
    id: 'Organizations.Export.CsvRestrictionMessage',
    defaultMessage:
      'Products cannot be exported in csv format. Please use the "Product allocation" export functionality to export Products in csv format.',
  },
  ExportInProgressTitle: {
    id: 'Compartments.menu.Export.ExportInProgressTitle',
    defaultMessage: 'Export in progress',
  },
  OkLabel: {
    id: 'Compartments.menu.Export.OkLabel',
    defaultMessage: 'Ok',
  },
  ExportReportAvailableForDownloadFromInsights: {
    id: 'Compartments.menu.Export.ExportReportAvailableForDownload',
    defaultMessage: 'Export report is available for download from the Insights page',
  },
  ExportingReportFailed: {
    id: 'Compartments.menu.Export.ExportingReportFailed',
    defaultMessage: 'Exporting report failed. Please see the Insights page for more details.',
  },
  ViewLabel: {
    id: 'Compartments.menu.Export.ViewLabel',
    defaultMessage: 'View',
  },
  FailedToCreateExportJob: {
    id: 'Compartments.menu.Export.FailedToCreateExportJob',
    defaultMessage: 'Failed to export: {error}',
  },
});

export const Download = messages.Download.defaultMessage;

class ExportDialogInternal extends React.Component<ExportDialogProps, ExportDialogState> {
  constructor(props: ExportDialogProps) {
    super(props);
    Analytics.fireCTAEvent(`organizations export`);
    const { formatMessage } = props.intl;
    this.state = {
      errorMessage: '',
      // FYI: format (sensitive) must match the @QueryParam("format") of the getExportDataJSON fn in the
      // com.adobe.banyansvc.resource.BanyanAPICompartment class of Banyan svc.
      format: 'json',
      exportOptions: [
        { label: formatMessage(messages.Organizations), value: Details.organizations, selected: false },
        { label: formatMessage(messages.Products), value: Details.products, selected: false },
        { label: formatMessage(messages.ProductProfiles), value: Details.productprofiles, selected: false },
        { label: formatMessage(messages.Administrators), value: Details.admins, selected: false },
        { label: formatMessage(messages.UserGroups), value: Details.usergroups, selected: false },
        { label: formatMessage(messages.Domains), value: Details.domains, selected: false },
        { label: formatMessage(messages.Directories), value: Details.directories, selected: false },
        { label: formatMessage(messages.Policies), value: Details.orgpolicies, selected: false },
      ],
    };
  }

  componentDidMount(): void {
    this.validateSelection(this.state.format);
  }

  /**
   * return true if any exportOption.value is true.
   * exportOption is defined as { label: string; value: string; selected: boolean }
   */
  isSaveEnabled = (): boolean => {
    const { exportOptions, errorMessage } = this.state;
    if (errorMessage.length > 0) {
      return false;
    }
    return _.some(exportOptions, 'selected');
  };

  onExport = (): void => {
    const { formatMessage } = this.props.intl;
    this.exportAsAJob();
    ToastMaster.showToast(formatMessage(messages.ExportInProgressTitle), BanyanToastType.INFO);
  };

  private navigateToInsightsTab = (): void => {
    this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.INSIGHTS));
  };

  exportAsAJob = async (): Promise<void> => {
    if (this.props.orgID) {
      const exportOptions: ExportOptions = {
        exportOrganizations: this.isDetailChecked(Details.organizations),
        exportAdmins: this.isDetailChecked(Details.admins),
        exportDomains: this.isDetailChecked(Details.domains),
        exportDirectories: this.isDetailChecked(Details.directories),
        exportProducts: this.isDetailChecked(Details.products),
        exportOrgPolicies: this.isDetailChecked(Details.orgpolicies),
        exportProductProfiles: this.isDetailChecked(Details.productprofiles),
        exportPurchases: false,
        exportUserGroups: this.isDetailChecked(Details.usergroups),
        format: this.state.format,
      };
      const uExportReport: UExportReport = {
        orgId: this.props.orgID,
        exportOptions,
      };

      BanyanJobsApi.exportInProgress = true;
      let jobId = '';
      try {
        const response: ExportJob = await BanyanJobsApi.organizationExportJob(uExportReport);
        jobId = response.jobId;
      } catch (error) {
        // NOTE IMPORTANT: error.message here would be either an error code | a localized error message, therefore safe to display
        const { formatMessage } = this.props.intl;
        ToastMaster.showToast(
          formatMessage(messages.FailedToCreateExportJob, { error: error.message }),
          BanyanToastType.ERROR
        );
        BanyanJobsApi.exportInProgress = false; // mark exportInProgress=false to allow another export request
        return; // return once failed to create an export job
      }
      await this.waitForJobToCompleteAndDisplayToast(this.props.orgID, jobId);
      BanyanJobsApi.exportInProgress = false; // mark exportInProgress=false to allow another export request
    }
  };

  waitForJobToCompleteAndDisplayToast = async (orgId: string, jobId: string): Promise<void> => {
    const { formatMessage } = this.props.intl;
    const jobSucceeded = await this.jobSucceeded(orgId, jobId);
    if (jobSucceeded) {
      // display success toast when the job succeeds
      ToastMaster.showToastWithAction(
        formatMessage(messages.ExportReportAvailableForDownloadFromInsights),
        BanyanToastType.INFO,
        formatMessage(messages.ViewLabel),
        this.navigateToInsightsTab
      );
    } else {
      ToastMaster.showToastWithAction(
        formatMessage(messages.ExportingReportFailed),
        BanyanToastType.ERROR,
        formatMessage(messages.ViewLabel),
        this.navigateToInsightsTab
      );
    }
  };

  /**
   * @returns true if the 'detail' (organization, products, etc) is checked in the UI else false
   */
  isDetailChecked = (detail: Details): boolean => {
    const { exportOptions } = this.state;
    const selectedDetail = _.find(exportOptions, (ex: ExportOptionsType) => ex.value === detail);
    return selectedDetail !== undefined && selectedDetail.selected;
  };

  /**
   * Waits for job to complete and validate if the job succeeded
   */
  jobSucceeded = async (orgId: string, jobId: string): Promise<boolean> => {
    // NOTE: we do not want to cancel the job once the export dialog closes. The job must continue to executed in the background.
    // Therefore, creating a dummy abort signal
    const dummyAbortControllerSignal = new AbortController().signal;
    const jobProgress: CancelPromise<JobProgressResponse | CancelStatus> = BanyanJobs.getJobProgress(
      jobId,
      orgId,
      dummyAbortControllerSignal
    );
    const result: JobProgressResponse | CancelStatus | null = await jobProgress.promise;
    const jobProgResp = result as JobProgressResponse;
    if (jobProgResp.result.jobInfo.jobStatus === JobResultStatus.SUCCEEDED) {
      return true;
    }
    return false;
  };

  /**
   * get total count of details which are checked by the user
   */
  getCheckedCount = (): number => {
    return _.filter(this.state.exportOptions, (options: ExportOptionsType) => options.selected).length;
  };

  /**
   * update the selected field for the corresponding checkbox on the state
   */
  onCheckBoxChecked = (value: boolean, index: number): void => {
    const { exportOptions } = this.state;
    exportOptions[index].selected = value;
    this.setState({ exportOptions }, (): void => {
      this.validateSelection(this.state.format);
    });
  };

  /**
   * sets the value of a particular exportOption and returns all exportOptions
   * For e.g. optionName = 'products' & optionValue = false will set the products option field to false
   */
  getExportOptionValueSet = (optionName: Details, optionValue: boolean): ExportOptionsType[] => {
    const { exportOptions } = this.state;
    const index = _.findIndex(exportOptions, (option: ExportOptionsType): boolean => {
      return _.isEqual(option.value, optionName);
    });
    if (index > -1) {
      exportOptions[index].selected = optionValue;
    }
    return exportOptions;
  };

  /**
   * validates selection as per the format
   */
  validateSelection = (format: string): void => {
    let { exportOptions } = this.state;
    if (_.isEqual(format, 'csv')) {
      // for 'csv' format, Products are not exported and not selectable
      exportOptions = this.getExportOptionValueSet(Details.products, false);
    } else {
      // if the format is not csv, 'organizations' should always be checked
      exportOptions = this.getExportOptionValueSet(Details.organizations, true);
    }
    this.setState({ format, exportOptions }, (): void => {
      const { formatMessage } = this.props.intl;
      let { errorMessage } = this.state;
      // check if more than 1 detail is selected for csv format
      if (_.isEqual(this.state.format, 'csv') && this.getCheckedCount() > 1) {
        errorMessage = formatMessage(messages.CsvLimit); // 'For csv, select only one detail to export.'
      } else {
        errorMessage = '';
      }
      this.setState({ errorMessage });
    });
  };

  render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    // check if export is in progress
    const exportInProgress = BanyanJobsApi.exportInProgress;
    if (exportInProgress) {
      return (
        <Dialog
          title={formatMessage(messages.ExportInProgressTitle)}
          confirmLabel={formatMessage(messages.OkLabel)}
          {...this.props}
          role="dialog"
        >
          <FormattedMessage
            id="Compartments.menu.Export.ExportInProgress"
            defaultMessage="An export operation is already in progress. Please wait for it to complete."
          />
        </Dialog>
      );
    }

    const exportOptionsCheckboxList = this.state.exportOptions.map((option: ExportOptionsType, index: number) => {
      let disabled = false;
      let checked = option.selected;

      if (_.isEqual(Details.organizations, option.value) && !_.isEqual(this.state.format, 'csv')) {
        // for "json" and "xlsx", org details (Organizations) is always exported
        disabled = true;
        checked = true;
      } else if (_.isEqual(Details.products, option.value) && _.isEqual(this.state.format, 'csv')) {
        // for "csv", Product are not exported and not editable
        disabled = true;
        checked = false;
      }

      return (
        <GridRow key={option.label}>
          <Checkbox
            label={option.label}
            onChange={(value: boolean): void => this.onCheckBoxChecked(value, index)}
            disabled={disabled}
            checked={checked}
            data-testid={`exportdialog-${option.label.toLowerCase()}`}
          />
        </GridRow>
      );
    });

    return (
      <Dialog
        confirmLabel={formatMessage(messages.Export)}
        cancelLabel={formatMessage(messages.Cancel)}
        keyboardConfirm
        {...this.props}
        onConfirm={this.onExport}
        title={formatMessage(messages.Export)}
        confirmDisabled={!this.isSaveEnabled()}
        className="ExportDialog__container"
        role="dialog"
        data-testid="export-dialog"
      >
        <div>
          {_.isEqual(this.state.format, 'csv') && (
            /* For CSV, products cannot be exported */
            <div className="ExportDialog_successMessage">{formatMessage(messages.CsvExportRestriction)}</div>
          )}
          {this.state.errorMessage.length > 0 && (
            <div className="ExportDialog__errorMessage">{this.state.errorMessage}</div>
          )}
          <div>
            <span className="ExportDialog__title">
              <FormattedMessage id="Organizations.Export.ExportList" defaultMessage="Export" />
            </span>
            <Grid>{exportOptionsCheckboxList}</Grid>
          </div>
          <div className="ExportDialog__formatContainer">
            <span className="ExportDialog__title">
              <FormattedMessage id="Organizations.Export.Format" defaultMessage="Format" />
            </span>
            <div className="ExportDialog__Content">
              <RadioGroup
                vertical
                onChange={(value: string | number | string[]): void => this.validateSelection(value as string)}
              >
                <Radio label="csv" value="csv" data-testid="exportdialog-csv" />
                <Radio label="json" value="json" checked data-testid="exportdialog-json" />
                <Radio label="xlsx" value="xlsx" data-testid="exportdialog-xlsx" />
              </RadioGroup>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ExportDialog;
