import React from 'react';
import { useIntl, IntlProvider } from 'react-intl';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';

/**
 * This component could be used to avoid duplication of lot of i18n requirements for error messages.
 * TODO Use this component for error messages as applicable.
 */
interface ErrorMessageProps {
  msgId: {};
  showErrorMsg: boolean;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props: ErrorMessageProps) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  if (props.showErrorMsg) {
    return <span className={props.className}>{formatMessage(props.msgId)}</span>;
  }
  return <span />;
};

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function ErrorMessageIntl(props: Omit<ErrorMessageProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ErrorMessage {...props} />
    </IntlProvider>
  );
}

export default ErrorMessageIntl;
