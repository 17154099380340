import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Switch from '@react/react-spectrum/Switch';
import Heading from '@react/react-spectrum/Heading';
import Rule from '@react/react-spectrum/Rule';
import Provider from '@react/react-spectrum/Provider';
import * as _ from 'lodash';
import Textfield from '@react/react-spectrum/Textfield';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import Wait from '@react/react-spectrum/Wait';
import Alert from '@react/react-spectrum/Alert';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { FulfillableItemType, UResource } from '../../../../../services/orgMaster/UResource';
import EnabledServices from '../../common/EnabledServices/EnabledServices';
import { UProductProfile } from '../../../../../services/orgMaster/UProductProfile';
import { UAdmin } from '../../../../../services/orgMaster/UAdmin';
import { UUserGroup } from '../../../../../services/orgMaster/UUserGroup';
import './ProdProfileDialogContent.css';
import ProfileDialogService from './ProfileDialogService';
import { LocaleSettings } from '../../../../../services/locale/LocaleSettings';
import { ProductAttributes } from '../../../../../services/orgMaster/ProductAttributes';
import { CAP_UNLIMITED } from '../../../../../services/orgMaster/OrgMaster';
import { UOrgMaster } from '../../../../../services/orgMaster/UOrgMaster';
import Utils from '../../../../../services/utils/Utils';

/**
 * ViewProdProfileDialogContent receives these props from the ProfileRow component.
 * - 'profile' is initialized at the ProductRow component and passed through the child components
 */
interface ViewProdProfileDialogContentProps extends WrappedComponentProps {
  profile: UProductProfile;
  attributes: ProductAttributes;
  selectedOrg: UOrgMaster;
}

interface ViewProdProfileDialogContentState {
  adminErrorMessage: string;
  userGroupErrorMessage: string;
  profile: UProductProfile;
  adminsForProfile: UAdmin[];
}

const messages = defineMessages({
  Close: {
    id: 'EditCompartment.Products.Profiles.View.Close',
    defaultMessage: 'Close',
  },
  Name: {
    id: 'EditCompartment.Products.Profiles.View.Name',
    defaultMessage: 'Name',
  },
  Quota: {
    id: 'EditCompartment.Products.Profiles.View.Quota',
    defaultMessage: 'Quota',
  },
  Notifications: {
    id: 'EditCompartment.Products.Profiles.View.Notifications',
    defaultMessage: 'Notifications',
  },
  NoUG: {
    id: 'EditCompartment.Products.Profiles.View.NoUG',
    defaultMessage: 'No user groups',
  },
  NoAdmins: {
    id: 'EditCompartment.Products.Profiles.View.NoAdmins',
    defaultMessage: 'No admins',
  },
  CouldNotAdmins: {
    id: 'EditCompartment.Products.Profiles.View.CouldNotAdmins',
    defaultMessage: 'could not load admins for the profile: {error}',
  },
  CouldNotProfile: {
    id: 'EditCompartment.Products.Profiles.View.CouldNotProfile',
    defaultMessage: 'could not load user group for the profile: {error}',
  },
});

class ViewProdProfileDialogContent extends React.Component<
  ViewProdProfileDialogContentProps,
  ViewProdProfileDialogContentState
> {
  userGroups: UUserGroup[];

  constructor(props: ViewProdProfileDialogContentProps) {
    super(props);
    this.userGroups = this.getUserGroupsForProfile();
    this.state = {
      adminErrorMessage: '',
      userGroupErrorMessage: '',
      profile: this.props.profile,
      adminsForProfile: this.props.selectedOrg.getAdminsForProfile(this.props.profile.productId, this.props.profile.id),
    };
  }
  async updateOrg(): Promise<void> {
    this.setState((state): Pick<ViewProdProfileDialogContentState, never> => {
      let adminsForProfile: UAdmin[] = [];
      adminsForProfile = this.props.selectedOrg.getAdminsForProfile(state.profile.productId, state.profile.id);
      const updatedProfile = _.find(
        this.props.selectedOrg.getProfiles(),
        (orgProfile: UProductProfile): boolean => orgProfile.id === state.profile.id
      );
      if (updatedProfile) {
        return { profile: updatedProfile, adminsForProfile };
      }
      return { adminsForProfile };
    });
  }

  async loadDataForEditProfileDialog(): Promise<void> {
    const { formatMessage } = this.props.intl;
    try {
      await ProfileDialogService.loadProfileAdminsV2(this.props.selectedOrg, this.state.profile);
    } catch (error) {
      this.setState({ adminErrorMessage: formatMessage(messages.CouldNotAdmins, { error }) });
    }
    try {
      await ProfileDialogService.loadProfileUserGroups(this.props.selectedOrg, this.state.profile);
    } catch (error) {
      this.setState({ userGroupErrorMessage: formatMessage(messages.CouldNotProfile, { error }) });
    }
    this.updateOrg();
  }

  async componentDidMount(): Promise<void> {
    await this.loadDataForEditProfileDialog();
  }

  /*
   * Returns the list of user groups in the edited product profile
   */
  getUserGroupsForProfile = (): UUserGroup[] => {
    return _.filter(
      this.props.selectedOrg.userGroups,
      (each: UUserGroup): boolean => !!_.find(each.profiles, ['id', this.props.profile.id])
    );
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const resources = _.filter(
      this.props.profile.getEditableResources(),
      (resource: UResource): boolean =>
        resource.fulfillableItemType === FulfillableItemType.SERVICE ||
        resource.fulfillableItemType === FulfillableItemType.QUOTA
    );
    return (
      <Dialog
        className="ProdProfileDialogContent__container"
        title={this.props.profile.name}
        confirmLabel={formatMessage(messages.Close)}
        {...this.props}
        role="dialog"
      >
        {this.state.profile.adminsLoaded && this.state.profile.userGroupLoaded ? (
          <div>
            <FieldLabel position="left" label={formatMessage(messages.Name)} id="view-profile-dialog-name">
              <Textfield
                className="ProdProfileDialogContent__NameInput"
                value={this.props.profile.name}
                readOnly
                aria-labelledby="view-profile-dialog-name"
              />
            </FieldLabel>
            <div>
              {_.map(this.state.profile.resources, (resource: UResource): React.ReactNode => {
                let val;
                let resLabel = '';
                // Dont show license resource for Adobe Stock
                if (!this.props.attributes.stockProduct && UResource.isSeatResource(resource.code)) {
                  if (this.state.profile.cap === CAP_UNLIMITED) {
                    val = CAP_UNLIMITED;
                  } else {
                    val = Number(resource.cap);
                  }
                  resLabel = formatMessage(messages.Quota);
                } else if (this.props.attributes.stockProduct && resource.isAStockFI()) {
                  // non-stock products can have stock FIs, we have to check if it is a stock produc  t
                  val = Number(resource.cap);
                  resLabel = Utils.localizedResourceName(resource.name());
                }
                if (val) {
                  return (
                    <FieldLabel label={resLabel} position="left" id="view-profile-dialog-quota">
                      <br />
                      <Textfield
                        disabled
                        value={Utils.localizeUnlimitedValue(val)}
                        aria-labelledby="view-profile-dialog-quota"
                      />
                    </FieldLabel>
                  );
                }
              })}
            </div>
            <div>
              <FieldLabel
                position="left"
                label={formatMessage(messages.Notifications)}
                id="view-profile-dialog-notification"
              >
                <Switch
                  defaultChecked={this.props.profile.notifications}
                  disabled
                  aria-labelledby="view-profile-dialog-notification"
                />
              </FieldLabel>
            </div>
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage id="EditCompartment.ProductProfile.View.UserGroups" defaultMessage="User Groups" />
              </Heading>
              <Rule variant="medium" />
              {!_.isEmpty(this.state.userGroupErrorMessage) && (
                <Alert variant="error">{this.state.userGroupErrorMessage}</Alert>
              )}
              {_.isEmpty(this.state.userGroupErrorMessage) &&
                this.userGroups.length !== 0 &&
                this.userGroups.map((userGroup) => (
                  <Provider key={userGroup.name} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
                    <TagList readOnly>
                      <Tag>{userGroup.name} </Tag>
                    </TagList>
                  </Provider>
                ))}
              {_.isEmpty(this.state.userGroupErrorMessage) && this.userGroups.length === 0 && (
                <div className="ProdProfileDialogContent__noContent">{formatMessage(messages.NoUG)}</div>
              )}
            </section>
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage id="EditCompartment.ProductProfile.View.Admins" defaultMessage="Admins" />
              </Heading>
              <Rule variant="medium" />
              {!_.isEmpty(this.state.adminErrorMessage) && (
                <Alert variant="error">{this.state.adminErrorMessage}</Alert>
              )}
              {_.isEmpty(this.state.adminErrorMessage) &&
                this.state.adminsForProfile.length !== 0 &&
                this.state.adminsForProfile.map((admin: UAdmin): React.ReactNode => {
                  return (
                    <Provider key={admin.email} locale={LocaleSettings.getSelectedLanguageTagForProvider()}>
                      <TagList readOnly>
                        <Tag>{admin.email} </Tag>
                      </TagList>
                    </Provider>
                  );
                })}
              {_.isEmpty(this.state.adminErrorMessage) && this.state.adminsForProfile.length === 0 && (
                <div className="ProdProfileDialogContent__noContent">{formatMessage(messages.NoAdmins)}</div>
              )}
            </section>
            <section>
              <Heading variant="subtitle1">
                <FormattedMessage
                  id="EditCompartment.ProductProfile.View.EnabledServices"
                  defaultMessage="Enabled Services"
                />
              </Heading>
              <Rule variant="medium" />
            </section>
            <EnabledServices resources={resources} filterResourcesByProperty="delegationConfigurable" readOnly />
          </div>
        ) : (
          <Wait className="Load_wait" />
        )}
      </Dialog>
    );
  }
}
export default injectIntl(ViewProdProfileDialogContent);
