import configCommon from './configuration.common';

/**
 * If any configurations are added to the following env configuration files
 *  - configurations.dev.js
 *  - configurations.stage.js
 *  - configurations.prod.js
 * You must also update this EnvConfig interface for typing.
 *
 * The configuration.common.js file is for configurations that are the same in
 * all environments.
 *  - If there are duplicate configuration properties between configuration.common.js and any of the env configuration files,
 *    the same configuration in the env configuration file will take precedence.
 */

interface EnvConfig {
  banyansvc: {
    apiKey: string;
    url: string;
  };
  jilApi: {
    apiKey: string;
    url: string;
  };
  imsApi: {
    apiKey: string;
    url: string;
  };
  imsLib: {
    clientId: string;
    imsLibUrl: string;
    environment: string;
    scopes: string;
  };
  adminConsole: {
    url: string;
  };
  analytics: {
    url: string;
  };
  gainsight?: {
    tag: string;
  };
  floodgate: {
    apiKey: string;
    url: string;
  };
  reportBroker: {
    apiKey: string;
    reportType: string;
  };
  pandora: {
    environment: string;
  };
}

interface ConfigWindow {
  config: EnvConfig;
}

/*
 * Note that window.config is populated when index.html imports /public/configuration.js. configuration.js is copied
 * into place via package.json's 'prestart' and via the 99-nginx.sh script.
 * Whereas for Jest testing, window.config is populated by setupTests.js.
 */
declare const window: ConfigWindow;
if (!window.config) {
  throw new Error('Unable to load this application.  Configuration unavailable');
}

export default { ...configCommon, ...window.config };
