export enum Details {
  Organizations = 'Organizations',
  Products = 'Products',
  Resources = 'Resources',
  ProductProfiles = 'ProductProfiles',
  Admins = 'Admins',
  UserGroups = 'UserGroups',
  OrgPolicies = 'OrgPolicies',
}

export interface FileExtensionDetails {
  label: string;
  extName: string;
  mimeType: string;
}

export const FileExtension: { [key: string]: FileExtensionDetails } = {
  CSV: {
    label: 'CSV',
    extName: 'csv',
    mimeType: '.csv',
  },
  JSON: {
    label: 'JSON',
    extName: 'json',
    mimeType: '.json',
  },
  XLSX: {
    label: 'XLSX',
    extName: 'xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

// Name given to sheet of XLSX file
// NOTE: It should match the name given to sheets in banyan svc in
// class com.adobe.banyansvc.service_wrappers.ExportOrgDataXLSXService
export enum XLSXSheetNames {
  Admins = 'Admins',
  Domains = ' Domains',
  Product = 'Products',
  Resources = 'Resources',
  ProductProfiles = 'Product Profiles',
  UserGroups = 'User groups',
  OrgPolicies = 'Org policies',
  Organizations = 'Organizations',
}
