import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Heading from '@react/react-spectrum/Heading';

import AlertBanner from '../AlertBanner/AlertBanner';
import GoUrl, { GoUrlKeys } from '../GoUrl/GoUrl';

import { LicenseTuple } from '../../services/orgMaster/LicenseTuple';

import './ExpirationBanner.css';

const localeMessages = defineMessages({
  notificationLink: {
    id: 'productAllocation.expirationBanner.notificationLinkText',
    defaultMessage: 'See what you can do now',
  },
  graceLink: {
    id: 'productAllocation.expirationBanner.gracePeriodLinkText',
    defaultMessage: 'See what you need to do now',
  },
  postGraceLink: {
    id: 'productAllocation.expirationBanner.postGracePeriodLinkText',
    defaultMessage: 'See what you need to do now',
  },
});

interface ExpirationBannerProps {
  className?: string;
  // LicenseTuple used to determine the content and styling.  undefined LicenseTuple will result in no banner.
  licenseTuple: LicenseTuple | undefined;
}

/**
 * Generates the message content for the compliance banner.
 * Messages are based on the LicenseTuple.
 *
 * @param licenseTuple LicenseTuple used to determine the message.  undefined LicenseTuple will result in no content.
 * @returns The message content for the compliance banner.
 */
function createExpirationBannerMessage(licenseTuple: LicenseTuple | undefined): React.ReactNode {
  const { formatMessage } = useIntl();
  if (licenseTuple?.isNotificationPhase()) {
    return (
      <span data-testid="compliance-bannerMessage-notificationPhase">
        <FormattedMessage
          id="productAllocation.expirationBanner.notificationMessage"
          defaultMessage="{linkText} or contact your organization's administrator for assistance to prevent users from losing access to their apps."
          values={{
            linkText: (
              <GoUrl goUrlKey={GoUrlKeys.contractExpirationProducts} target="gac_help">
                {formatMessage(localeMessages.notificationLink)}
              </GoUrl>
            ),
          }}
        />
      </span>
    );
  }
  if (licenseTuple?.isGracePhase()) {
    return (
      <span data-testid="compliance-bannerMessage-gracePhase">
        <FormattedMessage
          id="productAllocation.expirationBanner.gracePeriodMessage"
          defaultMessage="{linkText} or contact your organization's administrator for assistance to prevent users from losing access to their apps."
          values={{
            linkText: (
              <GoUrl goUrlKey={GoUrlKeys.contractExpirationProducts} target="gac_help">
                {formatMessage(localeMessages.graceLink)}
              </GoUrl>
            ),
          }}
        />
      </span>
    );
  }
  if (licenseTuple?.isPostGracePhase()) {
    return (
      <span data-testid="compliance-bannerMessage-postGracePhase">
        <FormattedMessage
          id="productAllocation.expirationBanner.postGracePeriodMessage"
          defaultMessage="Users have lost access to their apps. {linkText} or contact your organization's administrator for assistance."
          values={{
            linkText: (
              <GoUrl goUrlKey={GoUrlKeys.contractExpirationProducts} target="gac_help">
                {formatMessage(localeMessages.postGraceLink)}
              </GoUrl>
            ),
          }}
        />
      </span>
    );
  }
  return undefined;
}

/**
 * Component that displays a banner depending on the compliance of a LicenseTuple
 * and also handles the content and styling of the banner.
 * Content such as the type of banner, the title, and the message are determined by the LicenseTuple.
 */
function ExpirationBanner(props: ExpirationBannerProps): React.ReactElement {
  const { licenseTuple, ...componentProps } = props;
  return (
    <span {...componentProps}>
      {licenseTuple?.shouldShowExpireMessages() && (
        <span data-testid="compliance-alertBanner">
          <AlertBanner variant={licenseTuple.isNotificationPhase() ? 'warning' : 'error'}>
            <Heading className="ExpirationBanner__heading" variant="subtitle2">
              {licenseTuple.isNotificationPhase() ? (
                <span data-testid="compliance-bannerTitle-expiring">
                  <FormattedMessage
                    id="productAllocation.expirationBanner.expiringTitle"
                    defaultMessage="Products from one or more contracts will expire soon"
                  />
                </span>
              ) : (
                <span data-testid="compliance-bannerTitle-expired">
                  <FormattedMessage
                    id="productAllocation.expirationBanner.expiredTitle"
                    defaultMessage="Products from one or more contracts have expired"
                  />
                </span>
              )}
            </Heading>
            {createExpirationBannerMessage(licenseTuple)}
          </AlertBanner>
        </span>
      )}
    </span>
  );
}
export default ExpirationBanner;
