import React, { Component } from 'react';

/**
 * Use as a default route when other URLs do not match.  In general this should never be seen by a user
 * unless they type a bogus URL into the browser.
 */
class NotFound extends Component<any> {
  public render(): React.ReactNode {
    return <div>Path not found</div>;
  }
}

export default NotFound;
