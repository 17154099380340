import { defineMessages, useIntl } from 'react-intl';
import { InsightsContentModel } from '@pandora/react-insights';

/*
construct the model for Insights Pandora component content strings with localization and return it
refer - https://git.corp.adobe.com/PandoraUI/administration/blob/master/packages/react-insights/src/InsightsContentModel.ts
Any new strings added to the model above provided in the link, SHOULD be added here. Otherwise an empty string will be rendered.
*/
export const useAuditLogsContent = () => {
  const messages = defineMessages({
    ActionFilterDateRange: {
      id: 'InsightsForGA.AuditLogs.Filters.DateRange',
      defaultMessage: 'Date Range',
    },
    ActionFilterDropdown: {
      id: 'InsightsForGA.AuditLogs.Filters.Dropdown',
      defaultMessage: 'Dropdown',
    },
    ActionFilterEventName: {
      id: 'InsightsForGA.AuditLogs.Filters.EventName',
      defaultMessage: 'Event Name',
    },
    ActionFilterEventType: {
      id: 'InsightsForGA.AuditLogs.Filters.EventType',
      defaultMessage: 'Event Type',
    },
    ActionFilterOrganization: {
      id: 'InsightsForGA.AuditLogs.Filters.Organization',
      defaultMessage: 'Organization',
    },
    ActionFilterSearch: {
      id: 'InsightsForGA.AuditLogs.Filters.Search',
      defaultMessage: 'Search',
    },
    EventTypeAdminRoleAssignUser: {
      id: 'InsightsForGA.AuditLogs.EventType.AdminRoleAssignUser',
      defaultMessage: 'Admin role assigned to user',
    },
    EventTypeAdminRoleRemoveUser: {
      id: 'InsightsForGA.AuditLogs.EventType.AdminRoleRemoveUser',
      defaultMessage: 'Admin role removed from user',
    },
    EventTypeAllEvents: {
      id: 'InsightsForGA.AuditLogs.EventType.AllEvents',
      defaultMessage: 'All events',
    },
    EventTypeAutoAccountCreation: {
      id: 'InsightsForGA.AuditLogs.EventType.AutoAccountCreation',
      defaultMessage: 'Auto-account creation',
    },
    EventTypeAutoProductProvisioning: {
      id: 'InsightsForGA.AuditLogs.EventType. EventTypeAutoProductProvisioning',
      defaultMessage: 'Automatic assignment rule',
    },
    EventTypeContractChanged: {
      id: 'InsightsForGA.AuditLogs.EventType.ContractChanged',
      defaultMessage: 'Contract changed',
    },
    EventTypeDelegatedLicenseChanged: {
      id: 'InsightsForGA.AuditLogs.EventType.DelegatedLicenseChanged',
      defaultMessage: 'Product assignment changed',
    },
    EventTypeEncryptionKeyEnabled: {
      id: 'InsightsForGA.AuditLogs.EventType.EncryptionKeyEnabled',
      defaultMessage: 'Encryption key enabled',
    },
    EventTypeEncryptionKeyRevoked: {
      id: 'InsightsForGA.AuditLogs.EventType.EncryptionKeyRevoked',
      defaultMessage: 'Encryption key disabled',
    },
    EventTypeImsMantleImpersonate: {
      id: 'InsightsForGA.AuditLogs.EventType.ImsMantleImpersonate',
      defaultMessage: 'Adobe Support impersonation',
    },
    EventTypeProductsIntegratedIntoHierarchy: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductsIntegratedIntoHierarchy',
      defaultMessage: 'Products integrated into organization hierarchy',
    },
    EventTypeOrgPolicyEdit: {
      id: 'InsightsForGA.AuditLogs.EventType.OrgPolicyEdit',
      defaultMessage: 'Organization Policy edited',
    },
    EventTypeOrgDetailsEdited: {
      id: 'InsightsForGA.AuditLogs.EventType.OrgDetailsEdited',
      defaultMessage: 'Organization details edited',
    },
    EventTypeOrgAdded: {
      id: 'InsightsForGA.AuditLogs.EventType.OrgAdded',
      defaultMessage: 'Organization added to hierarchy',
    },
    EventTypeOrgRemoved: {
      id: 'InsightsForGA.AuditLogs.EventType.OrgRemoved',
      defaultMessage: 'Organization removed from hierarchy',
    },
    EventTypeProductProfileAddUser: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileAddUser',
      defaultMessage: 'Product profile assigned to user',
    },
    EventTypeProductProfileAddUserGroup: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileAddUserGroup',
      defaultMessage: 'Product profile assigned to user group',
    },
    EventTypeProductProfileAddedToUser: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileAddedToUser',
      defaultMessage: 'Product profile added to user account',
    },
    EventTypeProductProfileCreate: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileCreate',
      defaultMessage: 'Product profile created',
    },
    EventTypeProductProfileDelete: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileDelete',
      defaultMessage: 'Product profile deleted',
    },
    EventTypeProductProfileEdit: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileEdit',
      defaultMessage: 'Product profile changed',
    },
    EventTypeProductProfileRemoveUser: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileRemoveUser',
      defaultMessage: 'Product profile removed from user',
    },
    EventTypeProductProfileRemoveUserGroup: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductProfileRemoveUserGroup',
      defaultMessage: 'Product profile removed from user group',
    },
    EventTypeProductQuotaChangedGrantee: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductQuotaChangedGrantee',
      defaultMessage: 'Product quantity changed by allocation from parent',
    },
    EventTypeProductQuotaChangedGrantor: {
      id: 'InsightsForGA.AuditLogs.EventType.ProductQuotaChangedGrantor',
      defaultMessage: 'Product quantity changed by allocation to child',
    },
    EventTypeRaAccessRequest: {
      id: 'InsightsForGA.AuditLogs.EventType.RaAccessRequest',
      defaultMessage: 'Product request',
    },
    EventTypeTermsAndConditions: {
      id: 'InsightsForGA.AuditLogs.EventType.TermsAndConditions',
      defaultMessage: 'Terms acceptance',
    },
    EventTypeUserAddedOrg: {
      id: 'InsightsForGA.AuditLogs.EventType.UserAddedOrg',
      defaultMessage: 'User added to organization',
    },
    EventTypeUserAddedUserGroup: {
      id: 'InsightsForGA.AuditLogs.EventType.UserAddedUserGroup',
      defaultMessage: 'User added to user group',
    },
    EventTypeUserDeniedAccessToProduct: {
      id: 'InsightsForGA.AuditLogs.EventType.UserDeniedAccessToProduct',
      defaultMessage: 'Failure to add product profile to user account',
    },
    EventTypeUserGroupCreate: {
      id: 'InsightsForGA.AuditLogs.EventType.UserGroupCreate',
      defaultMessage: 'User group created',
    },
    EventTypeUserGroupRemove: {
      id: 'InsightsForGA.AuditLogs.EventType.UserGroupRemove',
      defaultMessage: 'User group deleted',
    },
    EventTypeUserGroupAccessShared: {
      id: 'InsightsForGA.AuditLogs.EventType.UserGroupAccessShared',
      defaultMessage: 'User group access shared',
    },
    EventTypeUserGroupAccessRevoked: {
      id: 'InsightsForGA.AuditLogs.EventType.UserGroupAccessRevoked',
      defaultMessage: 'User group access revoked',
    },
    EventTypeUserRemovedOrg: {
      id: 'InsightsForGA.AuditLogs.EventType.UserRemovedOrg',
      defaultMessage: 'User removed from organization',
    },
    EventTypeUserRemovedUserGroup: {
      id: 'InsightsForGA.AuditLogs.EventType.UserRemovedUserGroup',
      defaultMessage: 'User removed from user group',
    },
    ActionExportButton: {
      id: 'InsightsForGA.AuditLogs.Action.Export.Button',
      defaultMessage: 'Export CSV',
    },
    ColumnAction: {
      id: 'InsightsForGA.AuditLogs.Columns.Action',
      defaultMessage: 'ACTION',
    },
    ColumnAdmin: {
      id: 'InsightsForGA.AuditLogs.Columns.Admin',
      defaultMessage: 'ADMIN',
    },
    ColumnAffectedOrg: {
      id: 'InsightsForGA.AuditLogs.Columns.AffectedOrg',
      defaultMessage: 'ORGANIZATION',
    },
    ColumnAffectedUser: {
      id: 'InsightsForGA.AuditLogs.Columns.AffectedUser',
      defaultMessage: 'AFFECTED USER',
    },
    ColumnCreatedOn: {
      id: 'InsightsForGA.AuditLogs.Columns.CreatedOn',
      defaultMessage: 'CREATED ON',
    },
    ColumnCreatedTime: {
      id: 'InsightsForGA.AuditLogs.Columns.CreatedTime',
      defaultMessage: 'CREATED TIME',
    },
    ColumnCreator: {
      id: 'InsightsForGA.AuditLogs.Columns.Creator',
      defaultMessage: 'CREATOR',
    },
    ColumnDate: {
      id: 'InsightsForGA.AuditLogs.Columns.Date',
      defaultMessage: 'DATE',
    },
    ColumnEventDetail: {
      id: 'InsightsForGA.AuditLogs.Columns.EventDetail',
      defaultMessage: 'EVENT DETAIL',
    },
    ColumnEventName: {
      id: 'InsightsForGA.AuditLogs.Columns.EventName',
      defaultMessage: 'EVENT NAME',
    },
    ColumnFiles: {
      id: 'InsightsForGA.AuditLogs.Columns.Files',
      defaultMessage: 'FILES',
    },
    ColumnId: {
      id: 'InsightsForGA.AuditLogs.Columns.Id',
      defaultMessage: 'ID',
    },
    ColumnIpAddress: {
      id: 'InsightsForGA.AuditLogs.Columns.IpAddress',
      defaultMessage: 'IP ADDRESS',
    },
    ColumnLastModified: {
      id: 'InsightsForGA.AuditLogs.Columns.LastModified',
      defaultMessage: 'LAST MODIFIED',
    },
    ColumnModifiedCount: {
      id: 'InsightsForGA.AuditLogs.Columns.ModifiedCount',
      defaultMessage: 'MODIFIED COUNT',
    },
    ColumnName: {
      id: 'InsightsForGA.AuditLogs.Columns.Name',
      defaultMessage: 'NAME',
    },
    ColumnObjectName: {
      id: 'InsightsForGA.AuditLogs.Columns.ObjectName',
      defaultMessage: 'OBJECT NAME',
    },
    ColumnPath: {
      id: 'InsightsForGA.AuditLogs.Columns.Path',
      defaultMessage: 'PATH',
    },
    ColumnSize: {
      id: 'InsightsForGA.AuditLogs.Columns.Size',
      defaultMessage: 'SIZE',
    },
    ColumnStatus: {
      id: 'InsightsForGA.AuditLogs.Columns.Status',
      defaultMessage: 'STATUS',
    },
    NoLogs: {
      id: 'InsightsForGA.AuditLogs.NoLogs',
      defaultMessage: 'No logs',
    },
    StatusDownload: {
      id: 'InsightsForGA.AuditLogs.Status.Download',
      defaultMessage: 'Download',
    },
    StatusError: {
      id: 'InsightsForGA.AuditLogs.Status.Error',
      defaultMessage: 'Error',
    },
    StatusInProgress: {
      id: 'InsightsForGA.AuditLogs.Status.InProgress',
      defaultMessage: 'Processing...',
    },
    StatusSuccess: {
      id: 'InsightsForGA.AuditLogs.Status.Success',
      defaultMessage: 'Success',
    },
    EventSubTypeAccepted: {
      id: 'InsightsForGA.AuditLogs.EventSubType.Accepted',
      defaultMessage: 'Accepted',
    },
    EventSubTypeApproved: {
      id: 'InsightsForGA.AuditLogs.EventSubType.Approved',
      defaultMessage: 'User request approved',
    },
    EventSubTypeAutoAssignAdd: {
      id: 'InsightsForGA.AuditLogs.EventSubType.AssignAdd',
      defaultMessage: 'New rule added',
    },
    EventSubTypeAutoAssignEdit: {
      id: 'InsightsForGA.AuditLogs.EventSubType.AssignEdit',
      defaultMessage: 'Existing rule edited',
    },
    EventSubTypeAutoAssignRemove: {
      id: 'InsightsForGA.AuditLogs.EventSubType.AssignRemove',
      defaultMessage: 'Existing rule removed',
    },
    EventSubTypeCompartmentAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.CompartmentAdmin',
      defaultMessage: 'Global Administrator',
    },
    EventTypeContractNameChanged: {
      id: 'InsightsForGA.AuditLogs.EventSubType.ContractNameChanged',
      defaultMessage: 'Contract name changed',
    },
    EventSubTypeCompleted: {
      id: 'InsightsForGA.AuditLogs.EventSubType.Completed',
      defaultMessage: 'Completed',
    },
    EventSubTypeDenied: {
      id: 'InsightsForGA.AuditLogs.EventSubType.Denied',
      defaultMessage: 'User request denied',
    },
    EventSubTypeDeploymentAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.DeploymentAdmin',
      defaultMessage: 'Deployment Administrator',
    },
    EventSubTypeDisabled: {
      id: 'InsightsForGA.AuditLogs.EventSubType.Disabled',
      defaultMessage: 'Disabled',
    },
    EventSubTypeEnabled: {
      id: 'InsightsForGA.AuditLogs.EventSubType.Enabled',
      defaultMessage: 'Enabled',
    },
    EventSubTypeImsMantleImpersonateUserApproval: {
      id: 'InsightsForGA.AuditLogs.EventSubType.ImsMantleImpersonateUserApproval',
      defaultMessage: 'User approved',
    },
    EventSubTypeLicenseAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.LicenseAdmin',
      defaultMessage: 'Product Profile Administrator',
    },
    EventSubTypeLicenseDevAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.LicenseDevAdmin',
      defaultMessage: 'Developer',
    },
    EventSubTypeNullSubCode: { id: 'InsightsForGA.AuditLogs.EventSubType.NullSubCode', defaultMessage: '—' },
    EventSubTypeOrgAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.OrgAdmin',
      defaultMessage: 'System Administrator',
    },
    EventSubTypeProductAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.ProductAdmin',
      defaultMessage: 'Product Administrator',
    },
    EventSubTypeProvisioned: { id: 'InsightsForGA.AuditLogs.EventSubType.Provisioned', defaultMessage: 'Assigned' },
    EventSubTypeRequestReceived: {
      id: 'InsightsForGA.AuditLogs.EventSubType.RequestReceived',
      defaultMessage: 'User request received',
    },
    EventSubTypesStorageAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.StorageAdmin',
      defaultMessage: 'Storage Administrator',
    },
    EventSubTypeSupportAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.SupportAdmin',
      defaultMessage: 'Support Administrator',
    },
    EventSubTypeUnprovisioned: { id: 'InsightsForGA.AuditLogs.EventSubType.Unprovisioned', defaultMessage: 'Removed' },
    EventSubTypeUserCreation: {
      id: 'InsightsForGA.AuditLogs.EventSubType.UserCreation',
      defaultMessage: 'User account created',
    },
    EventSubTypeUserGroupAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.UserGroupAdmin',
      defaultMessage: 'User Group Administrator',
    },
    EventSubTypeContractAdmin: {
      id: 'InsightsForGA.AuditLogs.EventSubType.ContractAdmin',
      defaultMessage: 'Contract Administrator',
    },
    FilterDescriptionAffectedOrg: {
      id: 'InsightsForGA.AuditLogs.FilterDescription.AffectedOrg',
      defaultMessage: 'Select the organization you would like to view the audit logs',
    },
    FilterDescriptionDateRange: {
      id: 'InsightsForGA.AuditLogs.FilterDescription.DateRange',
      defaultMessage: 'Audit logs only available for past 90 days',
    },
    FilterHeaderAffectedOrg: {
      id: 'InsightsForGA.AuditLogs.FilterHeader.AffectedOrg',
      defaultMessage: 'Select Organization',
    },
    NoItemMessage: { id: 'InsightsForGA.AuditLogs.NoItemMessage', defaultMessage: 'No events' },
    ModalButtonCancel: { id: 'InsightsForGA.AuditLogs.Modal.Button.Cancel', defaultMessage: 'Cancel' },
    ModalButtonApply: { id: 'InsightsForGA.AuditLogs.Modal.Button.Apply', defaultMessage: 'Apply' },
    DateRangeEndAfterMax: {
      id: 'InsightsForGA.AuditLogs.DateRange.EndAfterMax',
      defaultMessage: 'End date is after the maximum allowed date',
    },
    DateRangeStartBeforeMin: {
      id: 'InsightsForGA.AuditLogs.DateRange.StartBeforeMin',
      defaultMessage: 'Start date is before minimum allowed date',
    },
    DateRangeEndBeforeStart: {
      id: 'InsightsForGA.AuditLogs.DateRange.EndBeforeStart',
      defaultMessage: 'End date is before the start date',
    },
    OrganizationsSelected: {
      id: 'InsightsForGA.AuditLogs.Organization.OrganizationsSelected',
      defaultMessage: 'Organizations',
    },
    OrganizationSearch: {
      id: 'InsightsForGA.AuditLogs.Organization.Search',
      defaultMessage: 'Search for organization',
    },
  });
  const { formatMessage } = useIntl();
  return InsightsContentModel.createEntry({
    columns: {
      action: formatMessage(messages.ColumnAction),
      admin: formatMessage(messages.ColumnAdmin),
      affectedOrg: formatMessage(messages.ColumnAffectedOrg),
      affectedUser: formatMessage(messages.ColumnAffectedUser),
      createdOn: formatMessage(messages.ColumnCreatedOn),
      createdTime: formatMessage(messages.ColumnCreatedTime),
      creator: formatMessage(messages.ColumnCreator),
      date: formatMessage(messages.ColumnDate),
      eventDetail: formatMessage(messages.ColumnEventDetail),
      eventName: formatMessage(messages.ColumnEventName),
      files: formatMessage(messages.ColumnFiles),
      id: formatMessage(messages.ColumnId),
      ipAddress: formatMessage(messages.ColumnIpAddress),
      lastModified: formatMessage(messages.ColumnLastModified),
      modifiedCount: formatMessage(messages.ColumnModifiedCount),
      name: formatMessage(messages.ColumnName),
      objectName: formatMessage(messages.ColumnObjectName),
      path: formatMessage(messages.ColumnPath),
      size: formatMessage(messages.ColumnSize),
      status: formatMessage(messages.ColumnStatus),
    },
    dateRange: {
      errorMessages: {
        endAfterMax: formatMessage(messages.DateRangeEndAfterMax),
        endBeforeStart: formatMessage(messages.DateRangeEndBeforeStart),
        startBeforeMin: formatMessage(messages.DateRangeStartBeforeMin),
      },
    },
    action: {
      export: {
        button: formatMessage(messages.ActionExportButton),
      },
      filters: {
        title: {
          dateRange: formatMessage(messages.ActionFilterDateRange),
          dropdown: formatMessage(messages.ActionFilterDropdown),
          eventName: formatMessage(messages.ActionFilterEventName),
          eventType: formatMessage(messages.ActionFilterEventType),
          organization: formatMessage(messages.ActionFilterOrganization),
          search: formatMessage(messages.ActionFilterSearch),
        },
      },
    },
    logs: {
      auditLogs: {
        eventType: {
          admin_role_assign_user: formatMessage(messages.EventTypeAdminRoleAssignUser),
          admin_role_remove_user: formatMessage(messages.EventTypeAdminRoleRemoveUser),
          all_events: formatMessage(messages.EventTypeAllEvents),
          auto_account_creation: formatMessage(messages.EventTypeAutoAccountCreation),
          auto_product_provisioning: formatMessage(messages.EventTypeAutoProductProvisioning),
          contract_changed: formatMessage(messages.EventTypeContractChanged),
          delegated_license_changed: formatMessage(messages.EventTypeDelegatedLicenseChanged),
          encryption_key_enabled: formatMessage(messages.EventTypeEncryptionKeyEnabled),
          encryption_key_revoked: formatMessage(messages.EventTypeEncryptionKeyRevoked),
          ims_mantle_impersonate: formatMessage(messages.EventTypeImsMantleImpersonate),
          org_integrated_into_hierarchy: formatMessage(messages.EventTypeProductsIntegratedIntoHierarchy),
          org_policy_edit: formatMessage(messages.EventTypeOrgPolicyEdit),
          organization_details_edited: formatMessage(messages.EventTypeOrgDetailsEdited),
          organization_hierarchy_added: formatMessage(messages.EventTypeOrgAdded),
          organization_hierarchy_removed: formatMessage(messages.EventTypeOrgRemoved),
          product_profile_add_user: formatMessage(messages.EventTypeProductProfileAddUser),
          product_profile_add_user_group: formatMessage(messages.EventTypeProductProfileAddUserGroup),
          product_profile_added_to_user: formatMessage(messages.EventTypeProductProfileAddedToUser),
          product_profile_create: formatMessage(messages.EventTypeProductProfileCreate),
          product_profile_delete: formatMessage(messages.EventTypeProductProfileDelete),
          product_profile_edit: formatMessage(messages.EventTypeProductProfileEdit),
          product_profile_remove_user: formatMessage(messages.EventTypeProductProfileRemoveUser),
          product_profile_remove_user_group: formatMessage(messages.EventTypeProductProfileRemoveUserGroup),
          product_quota_changed_by_allocation_from_parent: formatMessage(messages.EventTypeProductQuotaChangedGrantee),
          product_quota_changed_by_allocation_to_child: formatMessage(messages.EventTypeProductQuotaChangedGrantor),
          ra_access_request: formatMessage(messages.EventTypeRaAccessRequest),
          terms_and_conditions: formatMessage(messages.EventTypeTermsAndConditions),
          user_added_org: formatMessage(messages.EventTypeUserAddedOrg),
          user_added_user_group: formatMessage(messages.EventTypeUserAddedUserGroup),
          user_denied_access_to_product: formatMessage(messages.EventTypeUserDeniedAccessToProduct),
          user_group_create: formatMessage(messages.EventTypeUserGroupCreate),
          user_group_remove: formatMessage(messages.EventTypeUserGroupRemove),
          user_group_access_shared: formatMessage(messages.EventTypeUserGroupAccessShared),
          user_group_access_revoked: formatMessage(messages.EventTypeUserGroupAccessRevoked),
          user_removed_org: formatMessage(messages.EventTypeUserRemovedOrg),
          user_removed_user_group: formatMessage(messages.EventTypeUserRemovedUserGroup),
        },
        eventSubType: {
          accepted: formatMessage(messages.EventSubTypeAccepted),
          approved: formatMessage(messages.EventSubTypeApproved),
          auto_assignment_rule_added: formatMessage(messages.EventSubTypeAutoAssignAdd),
          auto_assignment_rule_edited: formatMessage(messages.EventSubTypeAutoAssignEdit),
          auto_assignment_rule_removed: formatMessage(messages.EventSubTypeAutoAssignRemove),
          compartment_admin: formatMessage(messages.EventSubTypeCompartmentAdmin),
          contract_name_changed: formatMessage(messages.EventTypeContractNameChanged),
          completed: formatMessage(messages.EventSubTypeCompleted),
          denied: formatMessage(messages.EventSubTypeDenied),
          deployment_admin: formatMessage(messages.EventSubTypeDeploymentAdmin),
          disabled: formatMessage(messages.EventSubTypeDisabled),
          enabled: formatMessage(messages.EventSubTypeEnabled),
          ims_mantle_impersonate_user_approval: formatMessage(messages.EventTypeImsMantleImpersonate),
          license_admin: formatMessage(messages.EventSubTypeLicenseAdmin),
          license_dev_admin: formatMessage(messages.EventSubTypeLicenseDevAdmin),
          null_sub_code: formatMessage(messages.EventSubTypeNullSubCode),
          org_admin: formatMessage(messages.EventSubTypeOrgAdmin),
          product_admin: formatMessage(messages.EventSubTypeProductAdmin),
          provisioned: formatMessage(messages.EventSubTypeProvisioned),
          request_received: formatMessage(messages.EventSubTypeRequestReceived),
          storage_admin: formatMessage(messages.EventSubTypesStorageAdmin),
          support_admin: formatMessage(messages.EventSubTypeSupportAdmin),
          unprovisioned: formatMessage(messages.EventSubTypeUnprovisioned),
          user_creation: formatMessage(messages.EventSubTypeUserCreation),
          user_group_admin: formatMessage(messages.EventSubTypeUserGroupAdmin),
          contract_admin: formatMessage(messages.EventSubTypeContractAdmin),
        },
        filters: {
          description: {
            affectedOrg: formatMessage(messages.FilterDescriptionAffectedOrg),
            dateRange: formatMessage(messages.FilterDescriptionDateRange),
          },
          header: {
            affectedOrg: formatMessage(messages.FilterHeaderAffectedOrg),
          },
        },
        noItemMessage: formatMessage(messages.NoItemMessage),
      },
    },
    organization: {
      organizations: formatMessage(messages.OrganizationsSelected),
      search: formatMessage(messages.OrganizationSearch),
    },
    item: {
      detail: {
        noLogs: formatMessage(messages.NoLogs),
      },
    },
    status: {
      download: formatMessage(messages.StatusDownload),
      error: formatMessage(messages.StatusError),
      inprogress: formatMessage(messages.StatusInProgress),
      success: formatMessage(messages.StatusSuccess),
    },
    modal: {
      button: {
        cancel: formatMessage(messages.ModalButtonCancel),
        apply: formatMessage(messages.ModalButtonApply),
      },
    },
  });
};
