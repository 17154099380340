import { createContext, useContext, useMemo } from 'react';
import { NavigationContextProviderProps } from '../types';

const NavigationContext = createContext<NavigationContextProviderProps>({
  ctaButtonDisabled: true,
  toggleDisableCtaButton: () => {},
  nextButtonDisabled: true,
  toggleDisableNextButton: () => {},
  secondaryButtonDisabled: false,
  toggleDisableSecondaryButton: () => {},
});
const useNavigationContext = () => useContext<NavigationContextProviderProps>(NavigationContext);

const NavigationContextProvider = ({
  children,
  ctaButtonDisabled,
  toggleDisableCtaButton,
  nextButtonDisabled,
  toggleDisableNextButton,
  secondaryButtonDisabled,
  toggleDisableSecondaryButton,
}: NavigationContextProviderProps) => {
  const value = useMemo(
    () => ({
      ctaButtonDisabled,
      toggleDisableCtaButton,
      nextButtonDisabled,
      toggleDisableNextButton,
      secondaryButtonDisabled,
      toggleDisableSecondaryButton,
    }),
    [ctaButtonDisabled, nextButtonDisabled, secondaryButtonDisabled]
  );
  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export { NavigationContextProvider, useNavigationContext };
