import * as _ from 'lodash';
import * as log from 'loglevel';
import React from 'react';
import Dialog from '@react/react-spectrum/Dialog';
import Heading from '@react/react-spectrum/Heading';
import Well from '@react/react-spectrum/Well';
import Textfield from '@react/react-spectrum/Textfield';
import Select, { SelectOption } from '@react/react-spectrum/Select';
import Rule from '@react/react-spectrum/Rule';
import Switch from '@react/react-spectrum/Switch';
import Wait from '@react/react-spectrum/Wait';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import {
  UAdmin,
  AdminProductInfo,
  AdminProfileInfo,
  AdminUserGroupInfo,
  AdminContractInfo,
} from '../../../services/orgMaster/UAdmin';
import { OrgOperation } from '../../../services/orgMaster/OrgMaster';
import { UserTypeMap, UserTypeMapInterface } from './services/UserTypeMap';
import ContractSection from './ContractSection';
import ProductSection from './ProductSection';
import ProfileSection from './ProfileSection';
import UserGroupSection from './UserGroupSection';
import { UserType, OrgAdminType } from '../../../services/authentication/IMS';
import './Common.css';
import Analytics from '../../../Analytics/Analytics';
import EmailValidation from './services/EmailValidation';
import CountryList from '../../../services/countries/CountryList';
import TempIdGenerator from '../../../services/utils/TempIdGenerator';
import { CommandService } from '../../../services/Commands/CommandService';
import FloodgateService from '../../../services/floodgate/FloodgateService';

interface AddAdminContentProps extends WrappedComponentProps {
  update: () => void;
  adminList: UAdmin[];
  selectedOrg: UOrgMaster;
}

interface AddAdminContentState {
  validEmail: boolean | undefined;
  emailErrorMessage: string;
  countryErrorMessage: string;
  sysAdminEnabled: boolean;
  globalAdminEnabled: boolean;
  globalAdminReadOnlyEnabled: boolean;
  deploymentAdminEnabled: boolean;
  supportAdminEnabled: boolean;
  storageAdminEnabled: boolean;
  // List of contracts (indicated by info) currently associated with contract admin.
  contractInfoList: AdminContractInfo[];
  // List of products (indicated by info) currently associated with product admin.
  productInfoList: AdminProductInfo[];
  // List of profiles (indicated by info) currently associated with profile admin.
  profileInfoList: AdminProfileInfo[];
  // List of user groups (indicated by info) currently associated with user group admin.
  userGroupInfoList: AdminUserGroupInfo[];
  countrySelectList: SelectOption[];
  isCountryLoading: boolean; // if the list of countries is loading currently
}

interface Country {
  countryName: string;
  countryCode: string;
}

const messages = defineMessages({
  email_empty: {
    id: 'EditCompartment.Admins.EmailCannotBeEmpty',
    defaultMessage: 'Email can not be empty',
  },
  email_duplicate: {
    id: 'EditCompartment.Admins.EmailAlreadyExists',
    defaultMessage: 'Email already exists',
  },
  email_bad_format: {
    id: 'EditCompartment.Admins.EmailMustBeInFormat',
    defaultMessage: 'Email must be in the form of a@b.c',
  },
  email_too_long: {
    id: 'EditCompartment.Admins.EmailMaximumLength',
    defaultMessage: 'Exceeds maximum length',
  },
  email_field_placeholder: {
    id: 'EditCompartment.Admin.Add.EmailPlaceholder',
    defaultMessage: 'Email (Required)',
  },
  first_name_placeholder: {
    id: 'EditCompartment.Admin.Add.FirstNamePlaceholder',
    defaultMessage: 'First Name (Optional)',
  },
  last_name_placeholder: {
    id: 'EditCompartment.Admin.Add.LastNamePlaceholder',
    defaultMessage: 'Last Name (Optional)',
  },
  add_admin: {
    id: 'EditCompartment.Admin.Add.AddAdminTitle',
    defaultMessage: 'ADD ADMIN',
  },
  save: {
    id: 'EditCompartment.Admin.Add.Button.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'EditCompartment.Admin.Add.Button.Cancel',
    defaultMessage: 'Cancel',
  },
  admin_right_not_selected: {
    id: 'EditCompartment.Admin.Add.admin_role_not_selected',
    defaultMessage: 'Select at least one admin role',
  },
  country_list_not_loaded: {
    id: 'EditCompartment.Admins.CountriesNotLoaded',
    defaultMessage: 'Could not retrieve the list of countries',
  },
});

class AddAdminContent extends React.Component<AddAdminContentProps, AddAdminContentState> {
  admin: UAdmin;
  userTypeMap: UserTypeMapInterface;
  adminEmailRef: HTMLInputElement | undefined;

  constructor(props: AddAdminContentProps) {
    super(props);
    this.admin = new UAdmin({ id: TempIdGenerator.getTempIdAndIncrement() });
    this.admin.orgId = this.props.selectedOrg.organization.id;
    this.admin.countryCode = this.props.selectedOrg.organization.countryCode;
    this.admin.userType = undefined;
    this.admin.adminOrder = (this.props.selectedOrg.getNewAdminCount() + 1) * -1; // use negative numbering for new admins to keep the admin list in order. TODO: Remove this when admin data structure is refactored
    this.userTypeMap = UserTypeMap.get();
    this.state = {
      productInfoList: [],
      profileInfoList: [],
      userGroupInfoList: [],
      contractInfoList: [],
      sysAdminEnabled: false,
      globalAdminEnabled: false,
      globalAdminReadOnlyEnabled: false,
      deploymentAdminEnabled: false,
      supportAdminEnabled: false,
      storageAdminEnabled: false,
      validEmail: undefined,
      emailErrorMessage: '',
      countryErrorMessage: '',
      countrySelectList: [],
      isCountryLoading: true,
    };
  }

  async componentDidMount(): Promise<void> {
    this.setState({ isCountryLoading: true });
    try {
      const responseData: Country[] = await CountryList.getCountryList();
      const countrySelectList: SelectOption[] = _.map(
        responseData,
        (country: Country): SelectOption => ({ label: country.countryName, value: country.countryCode })
      );
      this.setState({ countrySelectList, isCountryLoading: false });
    } catch (error) {
      const { formatMessage } = this.props.intl;
      this.setState({ countryErrorMessage: formatMessage(messages.country_list_not_loaded), isCountryLoading: false });
    }
    this.setState({ isCountryLoading: false });
    if (this.adminEmailRef) {
      this.adminEmailRef.focus();
    }
    try {
      let org: UOrgMaster | undefined = this.props.selectedOrg;
      // if the org is new, find the ancestor with actual id, because the new org would inherit the ESM/non-ESM state from parent org.
      while (org.isNewOrg()) {
        org = org.getParentOrgMaster();
        if (org === undefined) {
          return;
        }
      }
    } catch (e) {
      log.debug(e);
    }
  }

  // update the list of current contracts selected
  private updateContractAdminRights = (
    contractInfoList: AdminContractInfo[],
    contractId: string,
    addRole: boolean
  ): void => {
    this.admin.updateContractAdminFor(contractId, addRole);
    this.setState({ contractInfoList });
  };

  // update the list of products selected (TODO: rename to 'updateProductAdminRights')
  private updateProductAdminRightsV2 = (
    productInfoList: AdminProductInfo[],
    productId: string,
    addRole: boolean
  ): void => {
    this.admin.updateProductAdminFor(productId, addRole);
    this.setState({ productInfoList });
  };

  // update the list of current profiles selected (TODO: rename to 'updateProfileAdminRights')
  private updateProfileAdminRightsV2 = (
    profileInfoList: AdminProfileInfo[],
    profileId: string,
    profileName: string | undefined,
    addRole: boolean
  ): void => {
    this.admin.updateProfileAdminFor(profileId, profileName, this.props.selectedOrg, addRole);
    this.setState({ profileInfoList });
  };

  // update the list of current user groups selected (TODO: rename to 'updateUserGroupAdminRights')
  private updateUserGroupAdminRightsV2 = (
    userGroupInfoList: AdminUserGroupInfo[],
    userGroupId: string,
    userGroupName: string | undefined,
    addRole: boolean
  ): void => {
    this.admin.updateUserGroupAdminFor(userGroupId, userGroupName, addRole);
    this.setState({ userGroupInfoList });
  };

  // update the current selection of system admin right
  private updateSystemAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.ORG_ADMIN, addRole);
    this.setState({ sysAdminEnabled: addRole });
  };

  // update the current selection of global admin right
  private updateGlobalAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.COMPARTMENT_ADMIN, addRole);
    this.setState({ globalAdminEnabled: addRole });
  };

  // update the current selection of global admin read-only right
  private updateGlobalAdminReadOnlyRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.COMPARTMENT_VIEWER, addRole);
    this.setState({ globalAdminReadOnlyEnabled: addRole });
  };

  // update the current selection of deployment admin right
  private updateDeploymentAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.DEPLOYMENT_ADMIN, addRole);
    this.setState({ deploymentAdminEnabled: addRole });
  };

  // update the current selection of support admin right
  private updateSupportAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.SUPPORT_ADMIN, addRole);
    this.setState({ supportAdminEnabled: addRole });
  };

  // update the current selection of storage admin right
  private updateStorageAdminRights = (addRole: boolean): void => {
    this.admin.updateOrgLevelAdminFor(OrgAdminType.STORAGE_ADMIN, addRole);
    this.setState({ storageAdminEnabled: addRole });
  };

  /**
   * Iterate over UserType enum, determine corresponding user facing text (Adobe Id, Enterprise Id, Federated Id),
   * and return array of SelectOption(s) with appropriate labels and values.
   * If Adobe Id type is not allowed (i.e corresponding organization policy - "allowAdobeId" is disabled),
   * do NOT include Adobe Id as SelectOption.
   *
   * Note: As part of BANY-2125, GAC UI should not display "Business Id" as an option. Hence, UserType.TYPE_TWOE is ignored.
   *
   * @returns array of SelectOption(s) with labels being (Adobe Id, Enterprise Id or Federated Id)
   * and values being (TYPE_ONE, TYPE_TWO or TYPE_THREE).
   */
  private getAccountTypeOptions = (): SelectOption[] => {
    const adminTypesOptionsToShow = _.filter(UserType, (userType: UserType): boolean => {
      return userType !== UserType.TYPE_TWOE; // BANY-2125
    });
    return _.map(adminTypesOptionsToShow, (userType: UserType): SelectOption => {
      const isDisabled: boolean =
        !this.props.selectedOrg.policiesLoaded ||
        (userType === UserType.TYPE_ONE &&
          this.props.selectedOrg.compartmentPolicy.policies.allowAdobeID &&
          !this.props.selectedOrg.compartmentPolicy.policies.allowAdobeID.value);
      return {
        label: this.userTypeMap[userType],
        value: userType,
        disabled: isDisabled,
      };
    });
  };

  /**
   * see: https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
   * validating criteria:
   * 1) should not be empty
   * 2) should not be duplicate
   * 3) be in the form of regex
   * 4) should not exceed the max length
   * this is the same criteria as Admin Console as of June, 2019: https://git.corp.adobe.com/admin-tribe/binky/blob/master/src/core/email-utils/email-utils.service.js#L26
   */
  private checkIfValidEmail = (email: string | undefined): void => {
    const { formatMessage } = this.props.intl;
    let emailErrorMessage = '';
    let validEmail = false;
    if (!email) {
      emailErrorMessage = formatMessage(messages.email_empty); // 'Email can not be empty';
    } else if (_.find(this.props.adminList, ['email', email])) {
      emailErrorMessage = formatMessage(messages.email_duplicate); // 'Email already exists';
    } else if (!EmailValidation.isEmailFormatValid(email)) {
      emailErrorMessage = formatMessage(messages.email_bad_format); // 'Email must be in the form of a@b.c';
    } else if (!EmailValidation.isEmailLengthValid(email)) {
      emailErrorMessage = formatMessage(messages.email_too_long); // 'Exceeds maximum length';
    } else {
      validEmail = true;
    }
    if (validEmail && email) {
      this.admin.email = email.trim();
    }
    this.setState({ validEmail, emailErrorMessage });
  };

  // disable save if email is not valid, Account Type invalid or there is no admin right selected
  private isConfirmDisabled = (): boolean => {
    if (!this.state.validEmail || !this.admin.userType) {
      return true;
    }
    return (
      !this.state.sysAdminEnabled &&
      !this.state.globalAdminEnabled &&
      !this.state.globalAdminReadOnlyEnabled &&
      !this.state.supportAdminEnabled &&
      !this.state.deploymentAdminEnabled &&
      !this.state.storageAdminEnabled &&
      _.isEmpty(this.state.userGroupInfoList) &&
      _.isEmpty(this.state.profileInfoList) &&
      _.isEmpty(this.state.productInfoList) &&
      _.isEmpty(this.state.contractInfoList)
    );
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    let validationProps = {};
    if (this.state.validEmail !== undefined) {
      if (this.state.validEmail) {
        validationProps = { validationState: 'valid' };
      } else {
        validationProps = { validationState: 'invalid' };
      }
    }

    const { selectedOrg } = this.props;
    const showSystemAdminRole = true;
    const showGlobalAdminRole = !selectedOrg.isReadOnlyOrg();
    const showGlobalViewerAdminRole = !selectedOrg.isReadOnlyOrg();
    const showProductAdminRole = !selectedOrg.isReadOnlyOrg();
    const showProductProfileAdminRole = !selectedOrg.isReadOnlyOrg();
    const showUserGroupAdminRole = !selectedOrg.isReadOnlyOrg();
    const showContractAdminRole =
      FloodgateService.isFeatureEnabled(FloodgateService.ENABLE_CONTRACT_ADMIN) && !selectedOrg.isReadOnlyOrg();
    const showDeploymentAdminRole = !selectedOrg.isReadOnlyOrg();
    const showSupportAdminRole = !selectedOrg.isReadOnlyOrg();
    const showStorageAdminRole = !selectedOrg.isReadOnlyOrg();

    const addAdmin: React.ReactNode = (
      <Well className="AdminDialogSection">
        <div className="AdminDialogSubHeading">
          <Heading variant="subtitle2">
            <FormattedMessage id="EditCompartment.Admin.Add.UserDetails" defaultMessage="User Details" />
          </Heading>
        </div>
        <Rule variant="medium" />
        <div>
          <div className="AdminDialogUserDetailSubSection">
            <div>
              <span className="AdminDialogUserDetailLabels" id="add-admin-email">
                <FormattedMessage id="EditCompartment.Admin.Add.Email" defaultMessage="Email" />
              </span>
              <Textfield
                className="AdminDialogUserDetailValue"
                onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
                  // set error message and validation state on blur
                  this.checkIfValidEmail(event.target.value);
                }}
                onFocus={(): void => {
                  // unset error message and validation state on focus
                  this.setState({ validEmail: undefined, emailErrorMessage: '' });
                }}
                placeholder={formatMessage(messages.email_field_placeholder)}
                {...validationProps}
                aria-describedby="AddAdmin_errorMessage"
                aria-labelledby="add-admin-email"
                ref={(ref: any): void => {
                  // store the ref for the input field to auto focus programatically.
                  // autoFocus won't work on a TextField which is rendered asynchronously. autoFocus could be tried on v3 of TextField
                  this.adminEmailRef = ref;
                }}
              />
            </div>
            <div className="AdminDialogErrorMessage" id="AddAdmin_errorMessage">
              {this.state.emailErrorMessage}
            </div>
          </div>
          <div className="AdminDialogUserDetailSubSection">
            <span className="AdminDialogUserDetailLabels" id="add-admin-firstname">
              <FormattedMessage id="EditCompartment.Admin.Add.FirstName" defaultMessage="First name" />
            </span>
            <Textfield
              className="AdminDialogUserDetailValue"
              onChange={(firstName: string): void => {
                this.admin.firstName = firstName;
              }}
              placeholder={formatMessage(messages.first_name_placeholder)}
              aria-labelledby="add-admin-firstname"
            />
          </div>
          <div className="AdminDialogUserDetailSubSection">
            <span className="AdminDialogUserDetailLabels" id="add-admin-lastname">
              <FormattedMessage id="EditCompartment.Admin.Add.LastName" defaultMessage="Last name" />
            </span>
            <Textfield
              className="AdminDialogUserDetailValue"
              onChange={(lastName: string): void => {
                this.admin.lastName = lastName;
              }}
              placeholder={formatMessage(messages.last_name_placeholder)}
              aria-labelledby="add-admin-lastname"
            />
          </div>
          <div className="AdminDialogUserDetailSubSection">
            <span className="AdminDialogUserDetailLabels" id="add-admin-account-type">
              <FormattedMessage id="EditCompartment.Admin.Add.AcctType" defaultMessage="Account type" />
            </span>
            <Select
              className="AdminDialogUserDetailValue"
              options={this.getAccountTypeOptions()}
              onChange={(type: string | string[]): void => {
                this.admin.userType = type as UserType;
                this.setState({}); // This ensures that component is re-rendered. Helps enable/disable "Save" button.
              }}
              defaultValue={this.admin.userType}
              aria-labelledby="add-admin-account-type"
              data-testid="add-admin-account-type-select"
            />
          </div>
          <div className="AdminDialogUserDetailSubSection">
            <span className="AdminDialogUserDetailLabels">
              <FormattedMessage id="EditCompartment.Admin.Add.CountryCode" defaultMessage="Country/Region" />
            </span>
            <Select
              className="AdminDialogUserDetailValue"
              options={this.state.countrySelectList}
              defaultValue={this.admin.countryCode}
              onChange={(countryCode: string | string[]): void => {
                this.admin.countryCode = countryCode as string;
              }}
              disabled={this.state.countrySelectList.length === 0}
            />
          </div>
          <div className="AdminDialogErrorMessage" id="AddAdmin_errorMessage">
            {this.state.countryErrorMessage}
          </div>
        </div>
        <div className="AccountTypeInfoMessage">
          <FormattedMessage
            id="EditCompartment.Admin.Add.message1a"
            defaultMessage="If you are trying to add an existing user as admin, choose the same account type as the existing user, otherwise the add operation will fail."
          />
        </div>
      </Well>
    );

    const adminRightSection: React.ReactNode = (
      <Well className="AdminDialogSection">
        <div className="AdminDialogSubHeading">
          <Heading variant="subtitle2" className="AddAdminContent_heading">
            <FormattedMessage id="EditCompartment.Admin.Add.AdminRoles" defaultMessage="Admin Roles" />
          </Heading>
          <div className="AdminInfoSubsection">
            {this.state.validEmail && this.isConfirmDisabled() ? (
              <span className="AdminDialogInfoMessage">{formatMessage(messages.admin_right_not_selected)}</span>
            ) : null}
            {!this.state.validEmail ? (
              <span className="AdminDialogInfoMessage">
                <FormattedMessage
                  id="EditCompartment.Admin.Add.EnterValidEmailBefore"
                  defaultMessage="Enter valid email before selecting admin roles"
                />
              </span>
            ) : null}
          </div>
        </div>
        <Rule variant="medium" />
        <div>
          {showSystemAdminRole && (
            <div className="AdminRightSubsection">
              <span id="add-admin-system">
                <FormattedMessage id="EditCompartment.Admin.Add.Role.System" defaultMessage="System" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateSystemAdminRights}
                defaultChecked={this.admin.isSysAdmin()}
                disabled={!this.state.validEmail}
                aria-labelledby="add-admin-system"
              />
            </div>
          )}
          {showGlobalAdminRole && (
            <div className="AdminRightSubsection">
              <span id="add-admin-global">
                <FormattedMessage id="EditCompartment.Admin.Add.Role.Global" defaultMessage="Global" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateGlobalAdminRights}
                defaultChecked={this.admin.isGlobalAdmin()}
                disabled={!this.state.validEmail}
                aria-labelledby="add-admin-global"
              />
            </div>
          )}
          {showGlobalViewerAdminRole && (
            <div className="AdminRightSubsection">
              <span id="add-admin-global-viewer">
                <FormattedMessage id="EditCompartment.Admin.Add.Role.GlobalRO" defaultMessage="Global Viewer" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateGlobalAdminReadOnlyRights}
                defaultChecked={this.admin.isGlobalAdminReadOnly()}
                disabled={!this.state.validEmail}
                aria-labelledby="add-admin-global-viewer"
              />
            </div>
          )}
          {showContractAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admin.Add.Role.Contract" defaultMessage="Contract" />
              </div>
              <ContractSection
                compartment={this.props.selectedOrg}
                disabled={!this.state.validEmail}
                updateContractInfoCallback={this.updateContractAdminRights}
                initialContractInfoList={this.state.contractInfoList}
              />
            </div>
          )}
          {showProductAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admin.Add.Role.Product" defaultMessage="Product" />
              </div>
              <ProductSection
                compartment={this.props.selectedOrg}
                disabled={!this.state.validEmail}
                productInfoList={this.state.productInfoList}
                updateProductInfoCallback={this.updateProductAdminRightsV2}
              />
            </div>
          )}
          {showProductProfileAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admin.Add.Role.ProductProfile" defaultMessage="Product Profile" />
              </div>
              <ProfileSection
                compartment={this.props.selectedOrg}
                disabled={!this.state.validEmail}
                updateProfileInfoCallback={this.updateProfileAdminRightsV2}
                profileInfoList={this.state.profileInfoList}
              />
            </div>
          )}
          {showUserGroupAdminRole && (
            <div className="AdminRightSubsection">
              <div>
                <FormattedMessage id="EditCompartment.Admin.Add.Role.UserGroup" defaultMessage="User Group" />
              </div>
              <UserGroupSection
                compartment={this.props.selectedOrg}
                disabled={!this.state.validEmail}
                updateUserGroupInfoCallback={this.updateUserGroupAdminRightsV2}
                userGroupInfoList={this.state.userGroupInfoList}
              />
            </div>
          )}
          {showDeploymentAdminRole && (
            <div className="AdminRightSubsection">
              <span id="add-admin-deployment">
                <FormattedMessage id="EditCompartment.Admin.Add.Role.Deployment" defaultMessage="Deployment" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateDeploymentAdminRights}
                defaultChecked={this.admin.isDeploymentAdmin()}
                disabled={!this.state.validEmail}
                aria-labelledby="add-admin-deployment"
              />
            </div>
          )}
          {showSupportAdminRole && (
            <div className="AdminRightSubsection">
              <span id="add-admin-support">
                <FormattedMessage id="EditCompartment.Admin.Add.Role.Support" defaultMessage="Support" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateSupportAdminRights}
                defaultChecked={this.admin.isSupportAdmin()}
                disabled={!this.state.validEmail}
                aria-labelledby="add-admin-support"
              />
            </div>
          )}
          {showStorageAdminRole && (
            <div className="AdminRightSubsection">
              <span id="add-admin-storage">
                <FormattedMessage id="EditCompartment.Admin.Add.Role.Storage" defaultMessage="Storage" />
              </span>
              <Switch
                className="OrgLevelAdminSwitch"
                onChange={this.updateStorageAdminRights}
                defaultChecked={this.admin.isStorageAdmin()}
                disabled={!this.state.validEmail}
                aria-labelledby="add-admin-storage"
              />
            </div>
          )}
        </div>
      </Well>
    );

    return this.state.isCountryLoading ? (
      <Wait />
    ) : (
      <Dialog
        title={formatMessage(messages.add_admin)}
        confirmLabel={formatMessage(messages.save)}
        cancelLabel={formatMessage(messages.cancel)}
        {...this.props}
        role="dialog"
        className="AdminDialog"
        onCancel={(): void => Analytics.fireCTAEvent('add admin dialog canceled')}
        onConfirm={(): void => {
          Analytics.fireCTAEvent('add admin dialog save clicked');
          CommandService.addAdminEdit(this.props.selectedOrg, _.cloneDeep(this.admin), OrgOperation.CREATE);
          this.props.update();
        }}
        confirmDisabled={this.isConfirmDisabled()}
      >
        <div>
          <div>{addAdmin}</div>
          <div>{adminRightSection}</div>
        </div>
      </Dialog>
    );
  }
}

export default injectIntl(AddAdminContent);
