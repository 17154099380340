import * as _ from 'lodash';

/* eslint-disable import/no-unresolved */
import TreeNode from 'primereact/components/treenode/TreeNode';
/* eslint-enable import/no-unresolved */

import { UProduct } from '../../../services/orgMaster/UProduct';
import { UResource, ResourceCalculationData } from '../../../services/orgMaster/UResource';

import { ColumnField } from '../productAllocationData/Columns';
import { ResourceCalculationMap, ResourceCalculationUtils } from '../calculation/ResourceCalculation';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';

/**
 * Utilities specific to the data in a TreeNode for a TreeTable component
 */

/**
 * Provides utilities related to the TreeTable component
 */
export class TreeTableDataUtils {
  static readonly RESOURCE_FIELD_NAME = 'resource';

  /**
   * Provides mapping key to lookup the column data in a TreeNode
   */
  static columnFieldName(resourceCode: string | undefined, name: ColumnField): string {
    return `${resourceCode}_${name}`;
  }

  /**
   * Provides a mapping key to lookup the resource data in a TreeNode
   */
  static resourceFieldName(resourceCode: string | undefined): string {
    return `${resourceCode}_${TreeTableDataUtils.RESOURCE_FIELD_NAME}`;
  }
}

/**
 * Collection of booleans which indicate whether certain column values
 * should be highlighted.
 */
export interface HighlightFields {
  totalAlloc: boolean;
  grantOver: boolean;
  localLicensedQuantity: boolean;
  totalUse: boolean;
  useOver: boolean;
  grant: boolean;
  localUse: boolean;
}

/**
 * Maps HighlightedFields (which column values should be highlighted) to orgIds.
 */
export class HighlightMap {
  private mapField: {
    [orgId: string]: HighlightFields;
  } = {};

  /**
   * Retrieves a collection indicating the highlighted column values by orgId.
   */
  get(orgId: string): HighlightFields | undefined {
    if (this.mapField[orgId]) {
      return this.mapField[orgId];
    }
    return undefined;
  }

  /**
   * Updates multiple mappings of orgIds to highlighted columns from
   * multiple TreeData.
   */
  update(data: TreeTableNodeData[]): void {
    _.forEach(data, (treeData: TreeTableNodeData): void => {
      this.mapField[treeData.org.organization.id] = treeData.highlight;
    });
  }
}

/**
 * Wraps the data in a TreeTable to provide typing and utilities
 * TreeData represents a TreeNode which is a row in the TreeTable
 */
export class TreeTableNodeData {
  private orgField: UOrgMaster;
  private productField?: UProduct;
  private resourceField?: UResource;
  private grantField?: number;
  private grantIsUnlimitedField: boolean;
  private totalAllocField?: number;
  private grantOverField?: number;
  private localLicensedQuantityField?: number;
  private localUseField?: number;
  private totalUseField?: number;
  private useOverField?: number;
  private allowOverAllocField?: boolean;
  private allowOverUseField?: boolean;
  private highlightField: HighlightFields;

  constructor(
    org: UOrgMaster,
    product?: UProduct,
    resource?: UResource,
    grant?: number,
    grantIsUnlimited?: boolean,
    totalAlloc?: number,
    grantOver?: number,
    localLicensedQuantity?: number,
    localUse?: number,
    totalUse?: number,
    useOver?: number,
    allowOverAlloc?: boolean,
    allowOverUse?: boolean,
    highlight?: HighlightFields
  ) {
    this.orgField = org;
    this.productField = product;
    this.resourceField = resource;
    this.grantField = grant;
    this.totalAllocField = totalAlloc;
    this.grantOverField = grantOver;
    this.grantIsUnlimitedField = grantIsUnlimited || false;
    this.localUseField = localUse;
    this.localLicensedQuantityField = localLicensedQuantity;
    this.totalUseField = totalUse;
    this.useOverField = useOver;
    this.allowOverAllocField = allowOverAlloc;
    this.allowOverUseField = allowOverUse;
    this.highlightField = highlight || {
      totalAlloc: false,
      grantOver: false,
      localLicensedQuantity: false,
      totalUse: false,
      useOver: false,
      grant: false,
      localUse: false,
    };
  }

  get org(): UOrgMaster {
    return this.orgField;
  }

  get orgName(): string {
    return this.orgField.organization.name;
  }

  get product(): UProduct | undefined {
    return this.productField;
  }

  get resource(): UResource | undefined {
    return this.resourceField;
  }

  get resourceCode(): string | undefined {
    if (this.resourceField) {
      return this.resourceField.code;
    }
    return undefined;
  }

  get grant(): number | undefined {
    return this.grantField;
  }

  get grantIsUnlimited(): boolean {
    return this.grantIsUnlimitedField;
  }

  get totalAlloc(): number | undefined {
    return this.totalAllocField;
  }

  get grantOver(): number | undefined {
    return this.grantOverField;
  }

  get localLicensedQuantity(): number | undefined {
    return this.localLicensedQuantityField;
  }

  get localUse(): number | undefined {
    return this.localUseField;
  }

  get totalUse(): number | undefined {
    return this.totalUseField;
  }

  get useOver(): number | undefined {
    return this.useOverField;
  }

  get allowOverAlloc(): boolean | undefined {
    return this.allowOverAllocField;
  }

  get allowOverUse(): boolean | undefined {
    return this.allowOverUseField;
  }

  get highlight(): HighlightFields {
    return this.highlightField;
  }

  /**
   * Creates a TreeData from a TreeNode
   */
  static createFromNode(node: TreeNode): TreeTableNodeData | null {
    const {
      org,
      product,
      resource,
      grant,
      grantIsUnlimited,
      totalAlloc,
      grantOver,
      localLicensedQuantity,
      localUse,
      totalUse,
      useOver,
      allowOverAlloc,
      allowOverUse,
      highlight,
    } = node.data;
    if (org) {
      const treeTableNodeData: TreeTableNodeData = new TreeTableNodeData(
        org,
        product,
        resource,
        grant,
        grantIsUnlimited,
        totalAlloc,
        grantOver,
        localLicensedQuantity,
        localUse,
        totalUse,
        useOver,
        allowOverAlloc,
        allowOverUse,
        highlight
      );
      return treeTableNodeData;
    }
    return null;
  }

  /**
   * Creates multiple child TreeData from the children of a TreeNode
   */
  static createChildrenFromNode(node: TreeNode): TreeTableNodeData[] {
    const children: TreeTableNodeData[] = [];
    _.forEach(node.children, (childNode): void => {
      const child: TreeTableNodeData | null = TreeTableNodeData.createFromNode(childNode);
      if (child) {
        children.push(child);
      }
    });
    return children;
  }

  /**
   * Creates a TreeTableNodeData given an org, actual product on the org, and actual resource on the org.
   * The given resource must be attached to the given org
   * The created TreeTableNodeData includes the org, product, resource, grant, allocRollup, useRollup, allowOverAlloc, allowOverUse, localUse, and availableAlloc data, and highlight data.
   * The created TreeTableNodeData only includes the org if the product, resource, resource code, or resource grantedQuantity  is not defined (or not available) or if the resource grantedQuantity is unlimited.
   */
  static createFromOrg(
    org: UOrgMaster,
    product: UProduct | undefined,
    resource: UResource | undefined,
    resourceCalculationMap: ResourceCalculationMap,
    highlightMap?: HighlightMap
  ): TreeTableNodeData {
    const treeTableNodeData: TreeTableNodeData = new TreeTableNodeData(org);
    if (product && resource && resource.validCode()) {
      const resourceCalculationData: ResourceCalculationData | undefined = resourceCalculationMap.get(
        org.organization.id
      );
      if (resourceCalculationData) {
        treeTableNodeData.productField = product;
        treeTableNodeData.resourceField = resource;
        treeTableNodeData.grantField = resource.grantAsNilInt(undefined);
        treeTableNodeData.grantIsUnlimitedField = resource.isUnlimited();
        treeTableNodeData.totalAllocField = resourceCalculationData.totalAlloc;
        treeTableNodeData.grantOverField = resourceCalculationData.grantOver;
        treeTableNodeData.localLicensedQuantityField = resourceCalculationData.localLicensedQuantity;
        treeTableNodeData.localUseField = resource.provisionedQuantity;
        treeTableNodeData.totalUseField = resourceCalculationData.totalUse;
        treeTableNodeData.useOverField = resourceCalculationData.useOver;
        treeTableNodeData.allowOverAllocField = !resource.isUnlimited() ? product.allowExceedQuotas : undefined;
        treeTableNodeData.allowOverUseField = !resource.isUnlimited() ? product.allowExceedUsage : undefined;
      }
      if (highlightMap) {
        const highlightFields: HighlightFields | undefined = highlightMap.get(org.organization.id);
        if (highlightFields) {
          treeTableNodeData.highlightField = highlightFields;
        }
      }
    }
    return treeTableNodeData;
  }

  /**
   * Creates the data object for a TreeNode from this TreeTableNodeData
   * The property names here correlate to the field attributes for the TreeTable columns if
   * the data is for displaying in the table.
   */
  convertToDataField(): any {
    return {
      org: this.orgField,
      orgName: this.orgField.organization.name,
      product: this.productField,
      resource: this.resourceField,
      grant: this.grantField,
      grantIsUnlimited: this.grantIsUnlimitedField,
      totalAlloc: this.totalAllocField,
      grantOver: this.grantOverField,
      localLicensedQuantity: this.localLicensedQuantityField,
      localUse: this.localUseField,
      totalUse: this.totalUseField,
      useOver: this.useOverField,
      allowOverAlloc: this.allowOverAllocField,
      allowOverUse: this.allowOverUseField,
      highlight: this.highlightField,
    };
  }
}

/**
 * Provides utilities for creating the TreeNode data for the TreeTable component
 * based off the org master tree data
 */
export class TreeTableData {
  /**
   * Create tree node for the TreeTable component for a given org and it's children
   *  - orgList: List of orgs
   *  - org: org to create a TreeNode for
   *  - rootOrParentProduct: Product of the parent org to the given org or the product on the given org (rootOrg) both relating to the product to show data for in the TreeTable
   *  - selectedResource: Selected resource to show data for in the TreeTable
   *  - resourceCalculationMap: ResourceCalculationMap associated with resources for the orgs to show data for in the TreeTable
   *  - highlightMap: Mapping which indicates which column values should be highlighted in the TreeTable
   */
  private static getTreeData = (
    orgList: UOrgMaster[],
    org: UOrgMaster,
    selectedProduct: UProduct | undefined,
    selectedResource: UResource,
    resourceCalculationMap: ResourceCalculationMap,
    highlightMap?: HighlightMap
  ): TreeNode => {
    let childrenList: UOrgMaster[] = _.filter(
      orgList,
      (entry: UOrgMaster): boolean => entry.organization.parentOrgId === org.organization.id
    );
    childrenList = _.sortBy(childrenList, (child: UOrgMaster): string => _.toLower(child.organization.name));

    // find current org's product that matches selectedProduct
    let product: UProduct | undefined;
    let resource: UResource | undefined;
    if (selectedProduct) {
      product = _.find(org.products, (orgProduct: UProduct): boolean => {
        return orgProduct.isMatchingProduct(selectedProduct);
      });
      if (product) {
        resource = ResourceCalculationUtils.orgResourceFromProduct(product, selectedResource);
      }
    }
    return {
      key: org.organization.id,
      data: TreeTableNodeData.createFromOrg(
        org,
        product,
        resource,
        resourceCalculationMap,
        highlightMap
      ).convertToDataField(),
      children: _.map(
        childrenList,
        (child: UOrgMaster): TreeNode =>
          TreeTableData.getTreeData(
            orgList,
            child,
            selectedProduct,
            selectedResource,
            resourceCalculationMap,
            highlightMap
          )
      ),
    };
  };

  /**
   * Create a tree node for the TreeTable component based off the
   * org master tree data
   *  - orgList: List of orgs
   *  - selectedProduct: Selected product to show data for in the TreeTable
   *  - selectedResource: Selected resource to show data for in the TreeTable
   *  - resourceCalculationMap: ResourceCalculationMap associated with resources for the orgs to show data for in the TreeTable
   *  - highlightMap: Mapping which indicates which column values should be highlighted in the TreeTable
   */
  static createTreeTableData(
    orgList: UOrgMaster[],
    selectedProduct: UProduct,
    selectedResource: UResource,
    resourceCalculationMap: ResourceCalculationMap,
    highlightMap?: HighlightMap
  ): TreeNode {
    if (orgList.length === 0) {
      throw new Error('createTreeTableData orgList is empty');
    }
    return TreeTableData.getTreeData(
      orgList,
      orgList[0],
      selectedProduct,
      selectedResource,
      resourceCalculationMap,
      highlightMap
    );
  }
}
