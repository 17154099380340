import React from 'react';
import Search from '@react/react-spectrum/Search';
import Wait from '@react/react-spectrum/Wait';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Button from '@react/react-spectrum/Button';
import { defineMessages, FormattedMessage, injectIntl, IntlProvider, WrappedComponentProps } from 'react-intl';
import _ from 'lodash';
import Analytics from '../../../Analytics/Analytics';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import CreateTemplate from './CreateTemplate/CreateTemplate';
import TemplateTable from './TemplateTable/TemplateTable';
import BriefOrganizationTemplate, { BriefOrganizationTemplateData } from '../../../models/BriefOrganizationTemplate';
import BanyanCompartmentAPI from '../../../providers/BanyanCompartmentAPI';
import { LocaleSettings } from '../../../services/locale/LocaleSettings';
import { OrganizationTemplateData } from '../../../models/OrganizationTemplate';
import { MESSAGES } from '../../Messages';
import AlertBanner from '../../../components/AlertBanner/AlertBanner';
import Constants from '../../../constants/Constants';
import { GoUrlKeys } from '../../../components/GoUrl/GoUrl';
import GoHelpBubble from '../../../components/HelpBubble/HelpBubble';
import AdminPermission from '../../../services/authentication/AdminPermission';
import ToastMaster from '../../../services/toastMaster/ToastMaster';
import ScrollableContent from '../Widgets/ScrollableContent';
import '../../common.css';
import './OrganizationTemplate.css';
import { BanyanToastType } from '../../../components/BanyanToast/BanyanToastType';

interface OrganizationTemplateProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
}

interface OrganizationTemplateState {
  templates: BriefOrganizationTemplate[];
  searchInput: string;
  searchResultTemplates: BriefOrganizationTemplate[];
  isLoading: boolean;
  templatesLoadError: string | undefined;
}

interface CreateTemplateIntlProps extends OrganizationTemplateProps {
  createTemplate: (templateBody: OrganizationTemplateData) => Promise<string | undefined>;
}

interface TemplateTableIntlProps extends OrganizationTemplateProps {
  templates: BriefOrganizationTemplate[];
  orgId: string;
  deleteTemplate: (templateId: string, templateName: string) => Promise<void>;
  updateTemplate: (templateId: string, templateBody: OrganizationTemplateData) => Promise<string | undefined>;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function CreateTemplateIntl(props: Omit<CreateTemplateIntlProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <CreateTemplate {...props} />
    </IntlProvider>
  );
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function TemplateTableIntl(props: Omit<TemplateTableIntlProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <TemplateTable {...props} />
    </IntlProvider>
  );
}

const messages = defineMessages({
  SearchPlaceholder: {
    id: 'EditCompartment.Templates.SearchPlaceholder',
    defaultMessage: 'Search',
  },
  PaneHelp: {
    id: 'Organizations.PolicyTemplate.Helptext',
    defaultMessage:
      'A policy template is a group of policy settings.  You can setup and save, edit, and delete policy templates. ' +
      'Templates can be applied to one or more organizations (you can select which) and have the effect of setting policy values' +
      ' in those organizations.  This can streamline setup and facilitate consistent policy management across your organizations.',
  },
  CreateButtonTooltipForNewOrg: {
    id: 'Organizations.PolicyTemplate.CreateButtonTooltipForNewOrg',
    defaultMessage: `Templates may be created after the organization is created.`,
  },
});

class OrganizationTemplate extends React.Component<OrganizationTemplateProps, OrganizationTemplateState> {
  private abortController = new AbortController(); // to avoid calling setState() when unmounted

  constructor(props: OrganizationTemplateProps) {
    super(props);
    this.state = {
      templates: [],
      searchInput: '',
      searchResultTemplates: [],
      isLoading: false,
      templatesLoadError: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.loadTemplates();
  }

  async componentDidUpdate(prevProps: OrganizationTemplateProps): Promise<void> {
    if (prevProps.selectedOrg.id !== this.props.selectedOrg.id) {
      await this.loadTemplates();
    }
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  private loadTemplates = async (): Promise<void> => {
    if (this.props.selectedOrg.isNewOrg()) {
      this.setState({ templates: [], searchResultTemplates: [] });
      return;
    }
    const { id } = this.props.selectedOrg;
    this.setState({ isLoading: true });
    try {
      // TODO: Can we cache the the organization templates instead of fetching it every time the organization is selected
      const responseData: BriefOrganizationTemplateData = await BanyanCompartmentAPI.getPolicyTemplates(
        id,
        this.abortController.signal
      );
      if (this.abortController.signal.aborted) return;
      if (responseData && responseData.result) {
        this.setState({ templates: responseData.result, searchResultTemplates: responseData.result });
      } else {
        this.setState({ templates: [], searchResultTemplates: [] });
      }
    } catch (error) {
      if (this.abortController.signal.aborted) return; // do not call setState on unmounted component, React gives a warning on console if you do so
      this.setState({ templates: [], searchResultTemplates: [] });

      if (!_.startsWith(error.message, Constants.NOT_FOUND_HTTP_STATUS.toString())) {
        this.setState({ templatesLoadError: error.message });
      }
    }
    this.setState({ isLoading: false });
  };

  private createTemplate = async (templateBody: OrganizationTemplateData): Promise<string | undefined> => {
    const { id } = this.props.selectedOrg;
    const { formatMessage } = this.props.intl;
    this.setState({
      isLoading: true,
      searchInput: '',
    });
    let toReturn;
    try {
      await BanyanCompartmentAPI.createPolicyTemplate(id, templateBody);
      // Re-fetch all templates including newly created
      await this.loadTemplates();
      const successMsg = formatMessage(MESSAGES.TemplatePOSTApiSuccess, { templateName: templateBody.name });
      ToastMaster.showToast(successMsg, BanyanToastType.SUCCESS);
    } catch (error) {
      toReturn = error.message;
    }
    this.setState({ isLoading: false });
    return toReturn;
  };

  private deleteTemplate = async (templateId: string, templateName: string): Promise<void> => {
    Analytics.fireCTAEvent(`delete template`);
    const { id } = this.props.selectedOrg;
    const { formatMessage } = this.props.intl;
    this.setState({
      isLoading: true,
      searchInput: '',
    });
    try {
      await BanyanCompartmentAPI.deletePolicyTemplate(id, templateId);
      // Re-fetch all templates after deletion
      await this.loadTemplates();
      const successMsg = formatMessage(MESSAGES.TemplateDELETEApiSuccess, { templateName });
      ToastMaster.showToast(successMsg, BanyanToastType.SUCCESS);
    } catch (error) {
      this.setState((state) => {
        return {
          templates: state.templates,
          searchResultTemplates: state.templates,
        };
      });
      const errorMsg = `${formatMessage(MESSAGES.TemplateDELETEApiError, { templateName })} : ${error.message}`;
      ToastMaster.showToast(errorMsg, BanyanToastType.ERROR);
    }
    this.setState({ isLoading: false });
  };

  private updateTemplate = async (
    templateId: string,
    templateBody: OrganizationTemplateData
  ): Promise<string | undefined> => {
    const { id } = this.props.selectedOrg;
    const { formatMessage } = this.props.intl;
    this.setState({
      isLoading: true,
      searchInput: '',
    });
    let toReturn;
    try {
      await BanyanCompartmentAPI.updatePolicyTemplate(id, templateId, templateBody);
      // Re-fetch all templates to include updated template
      await this.loadTemplates();
      const successMsg = formatMessage(MESSAGES.TemplatePATCHApiSuccess, { templateName: templateBody.name });
      ToastMaster.showToast(successMsg, BanyanToastType.SUCCESS);
    } catch (error) {
      toReturn = error.message;
    }
    this.setState({ isLoading: false });
    return toReturn;
  };

  private onSearchQueryChange = (searchInput: string): void => {
    const filterQuery = _.trim(searchInput);
    if (_.isEmpty(filterQuery)) {
      // reset templates if the searchInput is empty
      this.setState((state) => {
        return {
          searchResultTemplates: state.templates,
        };
      });
    } else {
      Analytics.fireCTAEvent(`search templates`);
      this.setState((state) => {
        const searchResultTemplates = _.filter(state.templates, (template: BriefOrganizationTemplate): boolean => {
          return _.includes(_.toLower(template.name), _.toLower(filterQuery));
        });
        return {
          searchResultTemplates,
        };
      });
    }
    this.setState({
      searchInput,
    });
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const isNewOrg = this.props.selectedOrg.isNewOrg();
    if (!_.isEmpty(this.state.templatesLoadError)) {
      return (
        <AlertBanner variant="error">
          {formatMessage(MESSAGES.TemplateGETApiError)} : {this.state.templatesLoadError}
        </AlertBanner>
      );
    }
    return !this.state.isLoading ? (
      <React.Fragment>
        <div className="EditCompartment_tabSectionHeader EditCompartment__margin--bottom">
          {isNewOrg ? (
            <span className="OrganizationTemplate__infoMessage">
              {formatMessage(messages.CreateButtonTooltipForNewOrg)}
            </span>
          ) : (
            <Search
              placeholder={formatMessage(messages.SearchPlaceholder)}
              onChange={this.onSearchQueryChange}
              value={this.state.searchInput}
              data-testid="search--organization-templates"
              className="EditCompartment__TableSearch"
            />
          )}
          <span className="EditCompartment__tabSection--rightAligned">
            <ModalTrigger>
              <Button
                variant="primary"
                className="EditCompartment_pageButton"
                disabled={this.props.selectedOrg.isNewOrg() || AdminPermission.readOnlyMode()}
              >
                <FormattedMessage id="EditCompartment.Templates.Button.Create" defaultMessage="Create template" />
              </Button>
              <CreateTemplateIntl {...this.props} createTemplate={this.createTemplate} />
            </ModalTrigger>
            <GoHelpBubble goUrlKey={GoUrlKeys.organizationsTemplates}>
              <p>{formatMessage(messages.PaneHelp)}</p>
            </GoHelpBubble>
          </span>
        </div>
        {!isNewOrg && (
          <ScrollableContent uniqueId="EditCompartment_TemplatesTableID" className="EditCompartment_tabContent">
            <TemplateTableIntl
              {...this.props}
              templates={this.state.searchResultTemplates}
              orgId={this.props.selectedOrg.id}
              update={this.props.update}
              deleteTemplate={this.deleteTemplate}
              updateTemplate={this.updateTemplate}
            />
          </ScrollableContent>
        )}
      </React.Fragment>
    ) : (
      <Wait className="Load_wait" />
    );
  }
}

export default injectIntl(OrganizationTemplate);
