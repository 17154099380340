import * as _ from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';

import { ImportOrganizationsDM, ImportOrgPoliciesDM } from '../DataModelTypes/DataModelTypes';
import ImportOperationsUtils from '../../../../services/utils/ConvertToDataModel/ImportOperationsUtils';
import ImportUtils, { ChangeCount } from '../ImportUtils';
import ImportOrganizations from './ImportOrganizations';
import { LoadOrgDataService } from '../../../../services/orgMaster/LoadOrgDataService';
import { UCompartmentPolicies } from '../../../../services/orgMaster/UCompartmentPolicy';
import { ObjectTypes, OrgOperation } from '../../../../services/orgMaster/OrgMaster';
import { UOrgMaster } from '../../../../services/orgMaster/UOrgMaster';
import HierarchyManager from '../../../../services/organization/HierarchyManager';
import { CommandService } from '../../../../services/Commands/CommandService';
import CmdDescriptionUtils from '../../../../services/Codes/CmdDescriptionUtils';

const messages = defineMessages({
  OrgPoliciesLabel: {
    id: 'Organizations.Import.OrgPolicies.OrgPoliciesLabel',
    defaultMessage: 'Org Policies',
  },
  OrgIdBlankOrNull: {
    id: 'Organizations.Import.OrgPolicies.OrgIdBlankOrNull',
    defaultMessage: 'orgId is not valid for record at index {index}',
  },
  MultipleOrgPolicyRecord: {
    id: 'Organizations.Import.OrgPolicies.MultipleOrgPolicyRecord',
    defaultMessage: 'The following orgIds have multiple org policy records (invalid operation):',
  },
  OrgIdNotExist: {
    id: 'Organizations.Import.OrgPolicies.OrgIdNotExist',
    defaultMessage: "For org policies with 'UPDATE' operation, the following orgIds do not exist:",
  },
  OrgPolicyExist: {
    id: 'Organizations.Import.OrgPolicies.OrgPolicyExist',
    defaultMessage: 'New policies can only be created for new orgs. Following orgIds already exist in the hierarchy:',
  },
  OrgPolicyOperationNotValidOnTypeTOrg: {
    id: 'Organizations.Import.OrgPolicies.OrgPolicyOperationNotValidOnTypeTOrg',
    defaultMessage: 'Policies of the following orgs cannot be set or updated because of their org type:',
  },
});

class ImportOrgPolicies {
  static verifyNullCheck(allOrgPolicies: ImportOrgPoliciesDM[], intl: IntlShape): void {
    const errorMessages: string[] = [];
    const { formatMessage } = intl;
    _.forEach(allOrgPolicies, (policy: ImportOrgPoliciesDM, index: number): void => {
      if (ImportOperationsUtils.isOperationValid(policy.operation) && ImportUtils.isNullOrEmpty(policy.orgId)) {
        errorMessages.push(formatMessage(messages.OrgIdBlankOrNull, { index: index + 2 }));
      }
    });

    ImportUtils.displayIfError(formatMessage(messages.OrgPoliciesLabel), errorMessages);
  }

  /**
   * Validate that the policies to be created are only for new orgs. This is because, orgs existing in the
   * hierarchy already have a default policy attached to them
   * @param allOrgPolicies all org policies from the input file
   * @param allOrgs all orgs from the input file
   */
  static validateOrgsForPoliciesToBeCreated(
    allOrgPolicies: ImportOrgPoliciesDM[],
    allOrgs: ImportOrganizationsDM[],
    intl: IntlShape
  ): void {
    const invalidOrgIds = new Set<string>();
    _.forEach(allOrgPolicies, (policy: ImportOrgPoliciesDM): void => {
      // validate that the policy is with operation = 'CREATE' and is for a new org only
      if (
        ImportOperationsUtils.isOperationCreate(policy.operation) &&
        !ImportOrganizations.isOrgANewOrg(allOrgs, policy.orgId)
      ) {
        invalidOrgIds.add(policy.orgId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrgPoliciesLabel),
      formatMessage(messages.OrgPolicyExist),
      Array.from(invalidOrgIds)
    );
  }

  /**
   * Validate that an org can have only one org policy to it. If multiple policies are found for an org,
   * an error is thrown.
   * @param allOrgPolicies all org policies from input file
   */
  static validateDuplicateOrgPolicies(allOrgPolicies: ImportOrgPoliciesDM[], intl: IntlShape): void {
    const orgIdsFound = new Set<string>();
    const duplicateOrgIds = new Set<string>();

    _.forEach(allOrgPolicies, (policy: ImportOrgPoliciesDM): void => {
      if (orgIdsFound.has(policy.orgId)) {
        duplicateOrgIds.add(policy.orgId);
      }
      orgIdsFound.add(policy.orgId);
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrgPoliciesLabel),
      formatMessage(messages.MultipleOrgPolicyRecord),
      Array.from(duplicateOrgIds)
    );
  }

  /**
   * For policies with 'update' | 'delete' operation, validate that the org is already present in the
   * hierarchy.
   * @param allOrgPolicies all orgs from input file
   */
  static validateOrgsForUpdateOperation(allOrgPolicies: ImportOrgPoliciesDM[], intl: IntlShape): void {
    const orgsToBeUpdated = _.filter(allOrgPolicies, (policy: ImportOrgPoliciesDM): boolean =>
      ImportOperationsUtils.isOperationUpdate(policy.operation)
    );

    const invalidOrgIds = new Set<string>();

    _.forEach(orgsToBeUpdated, (policy: ImportOrgPoliciesDM): void => {
      // check if org exist in the hierarchy
      if (ImportOrganizations.getOrgFromHierarchy(policy.orgId) === undefined) {
        invalidOrgIds.add(policy.orgId);
      }
    });

    const { formatMessage } = intl;
    ImportUtils.displayIfErrorWithHeader(
      formatMessage(messages.OrgPoliciesLabel),
      formatMessage(messages.OrgIdNotExist),
      Array.from(invalidOrgIds)
    );
  }

  /**
   * Validate that no operations are performed on a type t orgs
   * @param allOrgPolicies all org policies from the input file
   */
  static async validateReadOnlyOrgs(allOrgPolicies: ImportOrgPoliciesDM[], intl: IntlShape): Promise<void> {
    const orgIds = new Set<string>();
    _.forEach(allOrgPolicies, (orgPolicy: ImportOrgPoliciesDM): void => {
      if (ImportOperationsUtils.isOperationValid(orgPolicy.operation)) {
        orgIds.add(orgPolicy.orgId);
      }
    });

    const { formatMessage } = intl;
    await ImportUtils.throwIfExistingOrgIsReadOnly(
      formatMessage(messages.OrgPoliciesLabel),
      formatMessage(messages.OrgPolicyOperationNotValidOnTypeTOrg),
      Array.from(orgIds)
    );
  }

  /**
   * Load org policies for all concerned orgs in the input file
   * Orgs down the hierarchy might not have the policies loaded for them when the import is performed.
   * Hence, load the policies for such orgs
   * @returns fn[] where each fn returns a promise to load policies for one org
   */
  public static loadOrgPolicies(allOrgPolicies: ImportOrgPoliciesDM[]): { (): Promise<void> }[] {
    const allUniqueOrgIds = allOrgPolicies
      .map((policy: ImportOrgPoliciesDM): string => policy.orgId)
      .filter((orgId: string, index: number, self: string[]): boolean => {
        return self.indexOf(orgId) === index;
      });

    const loadOrgPolicies: { (): Promise<void> }[] = [];
    _.forEach(allUniqueOrgIds, (orgId: string): void => {
      const org = ImportOrganizations.getOrgFromHierarchy(orgId);
      if (org !== undefined) {
        loadOrgPolicies.push(() => LoadOrgDataService.loadPolicies(orgId));
      }
    });

    return loadOrgPolicies;
  }

  static async validate(
    allOrgPolicies: ImportOrgPoliciesDM[],
    allOrgs: ImportOrganizationsDM[],
    intl: IntlShape
  ): Promise<void> {
    ImportOrgPolicies.verifyNullCheck(allOrgPolicies, intl);
    ImportOrgPolicies.validateDuplicateOrgPolicies(allOrgPolicies, intl);
    ImportOrgPolicies.validateOrgsForPoliciesToBeCreated(allOrgPolicies, allOrgs, intl);
    ImportOrgPolicies.validateOrgsForUpdateOperation(allOrgPolicies, intl);
    await ImportOrgPolicies.validateReadOnlyOrgs(allOrgPolicies, intl);
  }

  /**
   * Get UCompartmentPolicy from the org
   */
  static getUCompartmentPolicy(orgId: string): UCompartmentPolicies | undefined {
    const orgIdInHierarchy = ImportOrganizations.getOrgIdInHierarchy(orgId);
    let orgMaster = HierarchyManager.getOrg(orgIdInHierarchy);
    // if the org is a new org, its would not have a original org Master, so get the edited org Master
    if (orgMaster === undefined) {
      orgMaster = HierarchyManager.getOrg(orgIdInHierarchy);
    }
    return orgMaster !== undefined ? orgMaster.compartmentPolicy : undefined;
  }

  static updateOrgPolicy(
    originalOrgPolicy: UCompartmentPolicies | undefined,
    importPolicy: ImportOrgPoliciesDM
  ): boolean {
    if (originalOrgPolicy !== undefined) {
      let updateCount = 0;
      const editedOrgPolicy = _.cloneDeep(originalOrgPolicy);
      Object.keys(importPolicy).forEach((key: string): void => {
        if (editedOrgPolicy.policies[key] !== undefined) {
          // check if imported and existing policy values are different
          const sanitizedBoolean = ImportUtils.sanitizeBoolean((importPolicy as any)[key]);
          if (!_.isEqual(sanitizedBoolean, editedOrgPolicy.policies[key].value)) {
            // eslint-disable-next-line no-param-reassign
            editedOrgPolicy.policies[key].value = sanitizedBoolean;
            updateCount++;
          }
        }
      });

      if (updateCount > 0) {
        const orgId = ImportOrganizations.getOrgIdInHierarchy(importPolicy.orgId);
        // Note: The id field for policies is ignored on backend. Its only required for uniquely identifying commands on frontend.
        editedOrgPolicy.setId(`${UCompartmentPolicies.POLICY_PREFIX}${orgId}`);
        CommandService.addEdit(
          HierarchyManager.getOrg(orgId) as UOrgMaster,
          editedOrgPolicy,
          ObjectTypes.COMPARTMENT_POLICY,
          OrgOperation.UPDATE,
          originalOrgPolicy,
          'UPDATE_POLICY',
          [CmdDescriptionUtils.getPathname(orgId)]
        );
        return true;
      }
    }
    return false;
  }

  static import(allOrgPolicies: ImportOrgPoliciesDM[]): ChangeCount {
    // NOTE: Policies marked for deletion are just ignored

    const updateCount = _.map(
      ImportUtils.filterToBeUpdatedItems(allOrgPolicies) as ImportOrgPoliciesDM[],
      (policy: ImportOrgPoliciesDM): number => {
        // NOTE: the org policy diff should be made against the original Org Policy object for existing orgs
        if (this.updateOrgPolicy(this.getUCompartmentPolicy(policy.orgId), policy)) {
          return 1;
        }
        return 0;
      }
    ).reduce((a, b) => a + b, 0);

    const createCount = _.map(
      ImportUtils.filterToBeCreatedItems(allOrgPolicies) as ImportOrgPoliciesDM[],
      (policy: ImportOrgPoliciesDM): number => {
        // NOTE: the org policy diff should be made against the original Org Policy object for existing orgs
        if (this.updateOrgPolicy(this.getUCompartmentPolicy(policy.orgId), policy)) {
          return 1;
        }
        return 0;
      }
    ).reduce((a, b) => a + b, 0);

    return {
      deleteCount: 0,
      updateCount,
      createCount,
    };
  }
}

export default ImportOrgPolicies;
