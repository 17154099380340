import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps, IntlProvider } from 'react-intl';
import { Table, THead, TH, TR, TD, TBody } from '@react/react-spectrum/Table';
import Dropdown from '@react/react-spectrum/Dropdown';
import Button from '@react/react-spectrum/Button';
import More from '@react/react-spectrum/Icon/More';
import { Menu, MenuItem, MenuItemProps } from '@react/react-spectrum/Menu';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import UserMessageDialogIntl from '../../UserMessage/UserMessageDialog';
import { MESSAGES } from '../../../Messages';
import BriefOrganizationTemplate from '../../../../models/BriefOrganizationTemplate';
import ViewTemplate from '../ViewTemplate/ViewTemplate';
import EditTemplate from '../EditTemplate/EditTemplate';
import ApplyTemplate from '../ApplyTemplate/ApplyTemplate';
import { OrganizationTemplateData } from '../../../../models/OrganizationTemplate';
import { LocaleSettings } from '../../../../services/locale/LocaleSettings';
import AdminPermission from '../../../../services/authentication/AdminPermission';
import '../../../common.css';

const POLICY_TEMPLATE_TEST_ID = 'policy-template-test-id-';

interface TemplateTableProps extends WrappedComponentProps {
  templates: BriefOrganizationTemplate[];
  orgId: string;
  update: () => void;
  deleteTemplate: (templateId: string, templateName: string) => Promise<void>;
  updateTemplate: (templateId: string, templateBody: OrganizationTemplateData) => Promise<string | undefined>;
}

interface ApplyTemplateIntlProps extends TemplateTableProps {
  rootOrgId: string;
  templateId: string;
  templateName: string;
}

interface ViewTemplateProps extends TemplateTableProps {
  templateId: string;
  templateName: string;
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function EditTemplateIntl(props: Omit<ViewTemplateProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <EditTemplate {...props} />
    </IntlProvider>
  );
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function ApplyTemplateIntl(props: Omit<ApplyTemplateIntlProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ApplyTemplate {...props} />
    </IntlProvider>
  );
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function ViewTemplateIntl(props: Omit<ViewTemplateProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ViewTemplate {...props} />
    </IntlProvider>
  );
}

// Function to create internationalized component that bridges intl and other properties across <IntlProvider> node.  Needed
// in cases the component is rooted out of the normal hierarchy.
function MenuItemIntl(props: Omit<MenuItemProps, 'ref'>): React.ReactElement {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <MenuItem {...props} />
    </IntlProvider>
  );
}

class TemplateTable extends React.Component<TemplateTableProps> {
  public render(): React.ReactNode {
    const { templates, orgId, update } = this.props;
    const readOnly = AdminPermission.readOnlyMode();
    return (
      <Table>
        <THead className="EditCompartment__stickyHeader">
          <TH>
            <FormattedMessage id="EditCompartment.Templates.NameHeading" defaultMessage="NAME" />
          </TH>
          <TH>
            <FormattedMessage id="EditCompartment.Templates.CustomHeading" defaultMessage="CUSTOM POLICIES" />
          </TH>
          <TH>
            <FormattedMessage id="EditCompartment.Templates.UpdatedHeading" defaultMessage="UPDATED BY" />
          </TH>
          <TH />
        </THead>
        <TBody>
          {templates.map((template) => {
            return (
              <TR key={template.id} data-testid={`${POLICY_TEMPLATE_TEST_ID}${template.name}`}>
                <TD>{template.name}</TD>
                <TD>{template.policyCount}</TD>
                <TD>{template.updatedBy}</TD>
                <TD className="MoreOptionsTableCell">
                  <Dropdown alignRight closeOnSelect className="MoreDropdown">
                    <Button
                      dropdownTrigger
                      className="MoreButton"
                      data-testid="organization-template-menu-options"
                      aria-label="organization template menu options"
                    >
                      <More size="S" />
                    </Button>
                    <Menu dropdownMenu>
                      <ModalTrigger>
                        <MenuItemIntl disabled={readOnly}>
                          <FormattedMessage
                            id="EditCompartment.Templates.Menu.Apply"
                            defaultMessage="Apply template to organization"
                          />
                        </MenuItemIntl>
                        <ApplyTemplateIntl
                          {...this.props}
                          rootOrgId={orgId}
                          templateId={template.id}
                          templateName={template.name}
                          update={update}
                        />
                      </ModalTrigger>
                      <ModalTrigger>
                        <MenuItemIntl>
                          <FormattedMessage id="EditCompartment.Templates.Menu.View" defaultMessage="View template" />
                        </MenuItemIntl>
                        <ViewTemplateIntl
                          templateId={template.id}
                          templateName={template.name}
                          {...this.props} // this will provide orgId and updateTemplate. no explicit declaration required
                        />
                      </ModalTrigger>
                      <ModalTrigger>
                        <MenuItemIntl disabled={readOnly}>
                          <FormattedMessage id="EditCompartment.Templates.Menu.Edit" defaultMessage="Edit template" />
                        </MenuItemIntl>
                        <EditTemplateIntl
                          {...this.props}
                          orgId={orgId}
                          templateId={template.id}
                          templateName={template.name}
                          updateTemplate={this.props.updateTemplate}
                        />
                      </ModalTrigger>
                      <ModalTrigger>
                        <MenuItemIntl disabled={readOnly}>
                          <FormattedMessage
                            id="EditCompartment.Templates.Menu.Delete"
                            defaultMessage="Delete template"
                          />
                        </MenuItemIntl>
                        <UserMessageDialogIntl
                          msgId={MESSAGES.TemplateDeleteConfirmation}
                          variant="destructive"
                          messageData={{ templateName: template.name }}
                          onProceed={(): Promise<void> => this.props.deleteTemplate(template.id, template.name)}
                        />
                      </ModalTrigger>
                    </Menu>
                  </Dropdown>
                </TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    );
  }
}

export default injectIntl(TemplateTable);
export { POLICY_TEMPLATE_TEST_ID };
