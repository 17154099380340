import { UBasic, UBasicData } from './UBasic';
import { DirectoryType } from './UDirectory';

/**
 * These correspond to
 * https://git.corp.adobe.com/JIL-v2/jil-core/blob/ready_to_test/jil-organizations-entities/src/main/java/com/adobe/jil/organizations/entities/domains/OrganizationDomainStatus.java
 */
export enum DomainStatus {
  RESERVED = 'RESERVED',
  UNCLAIMED = 'UNCLAIMED',
  CLAIMED = 'CLAIMED',
  VALIDATED = 'VALIDATED',
  WITHDRAWN = 'WITHDRAWN',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  UNDEFINED = 'UNDEFINED',
}

export interface UDomainData extends UBasicData {
  domainName?: string;
  directoryName?: string;
  directoryType?: DirectoryType;
  domainStatus?: DomainStatus;
  orgId?: string;
}

export class UDomain extends UBasic implements UDomainData {
  domainName: string = '';
  directoryName: string = '';
  domainStatus: DomainStatus = DomainStatus.UNDEFINED;
  orgId: string = '';
  directoryType: DirectoryType | undefined;

  constructor(domain?: UDomainData) {
    super(domain);
    if (domain) {
      this.domainName = domain.domainName || this.domainName;
      this.directoryName = domain.directoryName || this.directoryName;
      this.directoryType = domain.directoryType;
      this.domainStatus = domain.domainStatus || this.domainStatus;
      this.orgId = domain.orgId || this.orgId;
    }
  }
}
