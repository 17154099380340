/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { UUserGroup } from '../../services/orgMaster/UUserGroup';
import { defineMessages, useIntl } from 'react-intl';
import React, { ReactNode } from 'react';
import {
  ActionButton,
  Cell,
  Column,
  Flex,
  Heading,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  Tooltip,
  TooltipTrigger,
} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import GoUrl, { GoUrlKeys } from '../GoUrl/GoUrl';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import GoToAdminConsoleDialog from '../GoToAdminConsoleDialog/GoToAdminConsoleDialog';
import config from '../../configurations/config';
import { UOrgMaster } from '../../services/orgMaster/UOrgMaster';
import { ReviewSelectedUserGroupsViewProps } from './types';

const messages = defineMessages({
  Heading: {
    id: 'ReviewSelectedUserGroupsView.Heading',
    defaultMessage: 'Review user groups to share with another organization',
  },
  Intro: {
    id: 'ReviewSelectedUserGroupsView.Intro',
    defaultMessage:
      'When you share a user group with another organization, all the users in the group will be added to that organization unless that organization has policies that prevent this.',
  },
  EditingReminder: {
    id: 'ReviewSelectedUserGroupsView.EditingReminder',
    defaultMessage: "Your shared user groups can't be edited by the other organization.",
  },
  LearnMoreLinkText: {
    id: 'ReviewSelectedUserGroupsView.LearnMoreLink.Text',
    defaultMessage: 'Learn more about sharing user groups',
  },
  UserGroupNameHeading: {
    id: 'ReviewSelectedUserGroupsView.Table.Heading.UserGroupName',
    defaultMessage: 'User Group Name',
  },
  UserCountHeading: {
    id: 'ReviewSelectedUserGroupsView.Table.Heading.UserCount',
    defaultMessage: 'Users',
  },
  LinkOutLabel: {
    id: 'ReviewSelectedUserGroupsView.Table.LinkOut.Label',
    defaultMessage: 'Open in a new tab',
  },
  TableLabel: {
    id: 'ReviewSelectedUserGroupsView.Table.Label',
    defaultMessage: 'Selected user groups to review',
  },
  TooltipLabel: {
    id: 'ReviewSelectedUserGroupsView.Table.Tooltip.Label',
    defaultMessage:
      'View user group inside Admin Console. Note: you must be a system administrator for this organization',
  },
});

export const ReviewSelectedUserGroupsView: React.FC<ReviewSelectedUserGroupsViewProps> = ({
  selectedUserGroups,
  hideHeading = false,
}) => {
  const intl = useIntl();

  function handleLinkOut(context: ReactNode, userGroup: UUserGroup) {
    const goToAdminConsoleDialogRef = ModalContainer.show(
      <GoToAdminConsoleDialog
        selectedOrg={{ id: userGroup.orgId } as unknown as UOrgMaster}
        onClose={(): void => {
          ModalContainer.hide(goToAdminConsoleDialogRef as number);
        }}
        url={`${config.adminConsole.url}/${userGroup.orgId}/users/user-groups/${userGroup.id}`}
      />,
      context
    );
  }

  return (
    <>
      <Heading isHidden={hideHeading}>{intl.formatMessage(messages.Heading)}</Heading>
      <p>{intl.formatMessage(messages.Intro)}</p>
      <p>
        {intl.formatMessage(messages.EditingReminder)}
        &nbsp;
        <GoUrl goUrlKey={GoUrlKeys.userGroupsShare} target="gac_organizationUserGroups">
          {intl.formatMessage(messages.LearnMoreLinkText)}
        </GoUrl>
      </p>
      <TableView aria-label={intl.formatMessage(messages.TableLabel)}>
        <TableHeader>
          <Column width={'60%'}>{intl.formatMessage(messages.UserGroupNameHeading)}</Column>
          <Column width={'40%'}>{intl.formatMessage(messages.UserCountHeading)}</Column>
        </TableHeader>
        <TableBody>
          {selectedUserGroups.map((userGroup) => (
            <Row key={userGroup.id}>
              <Cell>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Text>{userGroup.name}</Text>
                  <TooltipTrigger delay={0} placement="top">
                    <ActionButton
                      data-testid={`link-out-${userGroup.id}`}
                      isQuiet
                      aria-label={intl.formatMessage(messages.LinkOutLabel)}
                      onPress={() => handleLinkOut(this, userGroup)}
                    >
                      <LinkOutIcon size={'S'} />
                    </ActionButton>
                    <Tooltip data-testid={`tooltip-${userGroup.id}`}>
                      {intl.formatMessage(messages.TooltipLabel)}
                    </Tooltip>
                  </TooltipTrigger>
                </Flex>
              </Cell>
              <Cell>{intl.formatNumber(userGroup.userCount)}</Cell>
            </Row>
          ))}
        </TableBody>
      </TableView>
    </>
  );
};
