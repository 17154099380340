import React from 'react';
import * as _ from 'lodash';
import Rule from '@react/react-spectrum/Rule';
import Switch from '@react/react-spectrum/Switch';
import { FormattedMessage } from 'react-intl';

import { UResource } from '../../../../../services/orgMaster/UResource';
import Utils from '../../../../../services/utils/Utils';
import './EnabledServices.css';

/*
 * EnabledServices receives these props from product and product profile edit dialog components
 * - 'onServiceChange' callback function that updates the resource object on the compartment when the
 *                      switch is edited, in the case that the field is editable
 * - 'resources' list of editable resource objects that gets passed from dialog components
 * - 'filterResourcesByProperty' value that determines if the resource is editable, passed from the dialog components
 *   // e.g. fulfillmentConfigurable or delegationConfigurable
 * - 'readOnly' boolean value that determines if the services could be toggled on/off
 * */
interface EnabledServicesProps {
  onServiceChange?: (selection: boolean, resource: UResource) => void;
  resources: UResource[];
  filterResourcesByProperty: string;
  readOnly: boolean;
}

class EnabledServices extends React.Component<EnabledServicesProps, any> {
  public render(): React.ReactNode {
    // In the case when there are no services, show empty row
    const noServicesRow = (
      <span className="EnabledServices__emptyRow">
        <FormattedMessage id="EditCompartment.Products.ProfileDialog.NoServices" defaultMessage="No Services" />
      </span>
    );

    let serviceResources = _.filter(this.props.resources, ['fulfillableItemType', 'SERVICE']);
    serviceResources = _.sortBy(serviceResources, 'enterpriseName');

    // TODO: "community" resources need to be grouped into a single row.
    const enabledServicesRows = serviceResources.map((resource: UResource): React.ReactNode => {
      const isEditable = !this.props.readOnly && _.get(resource, this.props.filterResourcesByProperty);
      return (
        <React.Fragment key={resource.code}>
          <div className="EnabledServices__row">
            <img
              src={resource.icons ? resource.icons.svg : undefined}
              alt={Utils.localizedResourceName(resource.name())}
              className="EnabledServices__image"
            />
            <span className="EnabledServices__label" id={`enabled-services-${resource.code}`}>
              {Utils.localizedResourceName(resource.name())}
            </span>
            <Switch
              className="EnabledServices__switch"
              disabled={!isEditable}
              defaultChecked={resource.selected}
              onChange={(selection: boolean): void => {
                if (this.props.onServiceChange) {
                  this.props.onServiceChange(selection, resource);
                }
              }}
              aria-labelledby={`enabled-services-${resource.code}`}
            />
          </div>
          <Rule variant="small" />
        </React.Fragment>
      );
    });
    return <React.Fragment>{enabledServicesRows.length === 0 ? noServicesRow : enabledServicesRows}</React.Fragment>;
  }
}
export default EnabledServices;
