import React from 'react';
import { Button, ButtonGroup, Content, Dialog, Divider, Form, Heading } from '@adobe/react-spectrum';
import * as _ from 'lodash';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import OrgNameEdit from '../Widgets/OrgNameEdit';
import Analytics from '../../../Analytics/Analytics';

import '../../common.css';
import { ObjectTypes, OrgOperation } from '../../../services/orgMaster/OrgMaster';
import { CommandService } from '../../../services/Commands/CommandService';
import OrgTreeCache from '../../OrgTree/OrgTreeCache';
import CmdDescriptionUtils from '../../../services/Codes/CmdDescriptionUtils';

interface EditOrganizationProps extends WrappedComponentProps {
  update: () => void;
  selectedOrg: UOrgMaster;
  close: () => void;
}

interface EditOrganizationState {
  orgName: string;
  orgNameValidationState: undefined | 'valid' | 'invalid';
}

const messages = defineMessages({
  Title: {
    id: 'Organizations.Edit.title',
    defaultMessage: 'Edit organization',
  },
});

class EditOrganization extends React.Component<EditOrganizationProps, EditOrganizationState> {
  constructor(props: EditOrganizationProps) {
    super(props);
    this.state = {
      orgName: this.props.selectedOrg.organization.name,
      orgNameValidationState: 'valid',
    };
  }

  /**
   * save changes on confirm
   */
  onSave = (): void => {
    Analytics.fireCTAEvent(`edit org dialog save clicked`);
    if (this.state.orgName !== this.props.selectedOrg.organization.name) {
      const originalOrg = _.cloneDeep(this.props.selectedOrg);
      const editedCompartment = _.cloneDeep(this.props.selectedOrg);
      if (editedCompartment.organization.name !== this.state.orgName) {
        const oldOrgPathName = CmdDescriptionUtils.getPathname(editedCompartment.organization.id);
        const oldSimpleName = editedCompartment.organization.name;
        editedCompartment.organization.name = this.state.orgName;
        CommandService.addEdit(
          this.props.selectedOrg,
          editedCompartment.organization,
          ObjectTypes.ORGANIZATION,
          OrgOperation.UPDATE,
          originalOrg.organization,
          'UPDATE_ORG_NAME',
          [oldOrgPathName, oldSimpleName, editedCompartment.organization.name]
        );
      }
      // update org tree if the org name is edited otherwise the tree will show the old name
      // and Prime React does not allow updating of a single node.
      OrgTreeCache.clear();
      this.props.update();
    }
  };

  /**
   * update callback - can be called by OrgNameEdit to update orgNameValidationState
   * orgNameValidationState controls enable/disable state for the Save button
   */
  private updateOrgNameValidationState = (orgNameValidationState: 'valid' | 'invalid' | undefined): void => {
    this.setState({ orgNameValidationState });
  };

  public render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    const parent: UOrgMaster | undefined = this.props.selectedOrg.getParentOrgMaster();
    return (
      <Dialog size="S">
        <Heading>{formatMessage(messages.Title)}</Heading>
        <Divider />
        <Content>
          <Form>
            <OrgNameEdit
              parentId={parent ? parent.id : undefined}
              currentName={this.props.selectedOrg.organization.name}
              updateOrgName={(orgName: string): void => this.setState({ orgName })}
              updateValidationState={this.updateOrgNameValidationState}
            />
          </Form>
        </Content>
        <ButtonGroup>
          <Button
            variant="secondary"
            onPress={(): void => {
              Analytics.fireCTAEvent(`edit org dialog canceled`);
              this.props.close();
            }}
          >
            <FormattedMessage id="Organizations.Edit.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            variant="cta"
            onPress={(): void => {
              this.onSave();
              this.props.close();
            }}
            isDisabled={this.state.orgNameValidationState === 'invalid'}
          >
            <FormattedMessage id="Organizations.Edit.Save" defaultMessage="Save" />
          </Button>
        </ButtonGroup>
      </Dialog>
    );
  }
}

export default injectIntl(EditOrganization);
