import _ from 'lodash';
import log from 'loglevel';
import { AuthWindow, OrgAdminType, UserProfile, UserRole } from './IMS';
import { UserProfile as UserProfilePandora } from '@pandora/react-auth-provider';

declare const window: AuthWindow;

/**
 * UserProfileService caches the user profile information each time a call is made to refresh the user profile.
 * It also contains all the methods which are dependent on user profile information. This is preferred because each
 * method would have the updated profile information.
 *
 * UserProfileService imports ImsProviderGetProfiles imports AuthenticatedUser
 * If UserProfileService was refactored into AuthenticatedUser, it would create a circular dependency:
 * ImsProviderGetProfiles import AuthenticatedUser import ImsProviderGetProfiles import AuthenticatedUser
 */
class UserProfileService {
  public static userProfile: UserProfile | undefined;

  public static async initializeUserProfile(): Promise<void> {
    if (!window.adobeIMS) {
      log.error('adobeIms not defined while initializing userProfile');
      return undefined;
    }
    await window.adobeIMS.refreshToken(); // Refresh token to fetch new roles that might have been added since last token generation. Takes 400-550ms on stage.
    this.userProfile = await window.adobeIMS.getProfile();
  }

  // Note: This method assumes userProfile was already set via initializeUserProfile()
  // Prerequisite of invoking initializeUserProfile() could be removed if getUserProfile() could be changed to be async
  public static getUserProfile(): UserProfile | undefined {
    if (!window.adobeIMS) {
      return undefined;
    }
    return this.userProfile;
  }

  public static getUserProfilePandora(): Partial<UserProfilePandora> {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return { userId: userProfile.userId };
    }
    return {};
  }

  static getUserEmail(): string | undefined {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return userProfile.email;
    }
    return undefined;
  }

  static getUserName(): string | undefined {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return userProfile.name;
    }
    return undefined;
  }

  static getUserRoles(orgId: string): UserRole[] | undefined {
    const userProfile = this.getUserProfile();
    if (userProfile === undefined) {
      return undefined;
    }
    return _.filter(userProfile.roles, (role: UserRole): boolean => role.organization === orgId);
  }

  static getUserRolesAcrossHierarchy(): UserRole[] | undefined {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return userProfile.roles;
    }
    return undefined;
  }

  static getUserId(): string | undefined {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return userProfile.userId;
    }
    return undefined;
  }

  static getUserType(): string | undefined {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return userProfile.account_type;
    }
    return undefined;
  }

  static getUserLocales(): string[] | null {
    const userProfile = this.getUserProfile();
    if (userProfile) {
      return userProfile.preferred_languages;
    }
    return null;
  }

  /**
   * Uses authenticated user profile "roles" to find a first org for which user has any of following two roles:
   * Admin roles: "COMPARTMENT_ADMIN", "COMPARTMENT_VIEWER"
   * @returns ID of an org for which user has admin rights of "COMPARTMENT_ADMIN" or "COMPARTMENT_VIEWER" " Or undefined if the user has no such roles (could be Adobe Agent)"
   */
  static getAnyOrgIdWithAdminRightsForCurrentProfile(): string | undefined {
    const adminRoleInfo: UserRole | undefined = _.find(
      this.getUserProfile()?.roles,
      (roleObject: UserRole): boolean => {
        const namedRoleAllUpper = _.toUpper(roleObject.named_role);
        return (
          namedRoleAllUpper === OrgAdminType.COMPARTMENT_ADMIN || namedRoleAllUpper === OrgAdminType.COMPARTMENT_VIEWER
        );
      }
    );
    return adminRoleInfo?.organization;
  }

  /**
   * Checks if current user profile has global admin or global viewer access to orgId.
   * THIS IS NOT T2E-SAFE. A T2E user might be a Global Admin of the orgId via different profile, but this does not check other profiles.
   */
  static isCurrentUserProfileGlobalAdminOrViewerOf(orgId: string): boolean {
    const roles = this.getUserRoles(orgId);
    return _.some(roles, (role: UserRole): boolean => {
      const namedRoleAllUpper = _.toUpper(role.named_role);
      return (
        namedRoleAllUpper === OrgAdminType.COMPARTMENT_ADMIN || namedRoleAllUpper === OrgAdminType.COMPARTMENT_VIEWER
      );
    });
  }
}

export default UserProfileService;
