export interface UContractSwitchData {
  id: string;
  orgName: string;
  orgId: string;
  verboseErrorMessage: boolean;
}

class UContractSwitch implements UContractSwitchData {
  id: string;
  orgName: string;
  orgId: string;
  verboseErrorMessage: boolean;

  constructor(contractSwitchData: UContractSwitchData) {
    this.orgId = contractSwitchData.orgId;
    this.id = contractSwitchData.id;
    this.orgName = contractSwitchData.orgName;
    this.verboseErrorMessage = contractSwitchData.verboseErrorMessage;
  }

  /**
   * compare if this contract switch is same as the other contract switch
   */
  isEqual(otherContSwitch: UContractSwitch) {
    return this.orgId === otherContSwitch.orgId && this.orgName === otherContSwitch.orgName;
  }
}

export default UContractSwitch;
