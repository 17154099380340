/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { Key, SpectrumDialogProps } from '@adobe/react-spectrum';
import { ContentEntryInterface } from '@pandora/react-content-provider';
import { UUserGroup } from '../../services/orgMaster/UUserGroup';
import { ReactNode } from 'react';

export type ManageUserGroupShareDialogProps = Omit<SpectrumDialogProps, 'children'> & {
  sourceOrgUserGroup: UUserGroup;
  content?: ContentEntryInterface;
  initSelectedKeys?: Key[];
  disableSecondaryButton?: boolean;
  update: () => void;
};

export type SelectOrganizationsStepProps = Omit<SpectrumDialogProps, 'children'> & {
  sourceOrgUserGroup: UUserGroup;
  content?: ContentEntryInterface;
  initSelectedKeys?: Key[];
  disabled: boolean;
};

export type SelectRevokeTypeStepProps = Omit<SpectrumDialogProps, 'children'> & {
  sourceOrgUserGroup: UUserGroup;
  content?: ContentEntryInterface;
};

export type RevokeContextProviderProps = {
  children?: ReactNode;
  selectedOrgUserGroups: string[];
  revokeType: string | undefined;
  persistSelectedOrgUserGroups: Function;
  persistRevokeType: Function;
};

export type NavigationContextProviderProps = {
  children?: ReactNode;
  ctaButtonDisabled: boolean;
  toggleDisableCtaButton: Function;
  nextButtonDisabled: boolean;
  toggleDisableNextButton: Function;
  secondaryButtonDisabled: boolean;
  toggleDisableSecondaryButton: Function;
};

export type RowData = {
  id: string;
  orgPath: string;
};

export enum RevokeType {
  HARD_DELETE = 'HARD_DELETE',
  SOFT_DELETE = 'SOFT_DELETE',
}
