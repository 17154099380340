import { Table, TBody, TD, TH, THead, TR } from '@react/react-spectrum/Table';
import React from 'react';
import _ from 'lodash';
import {
  defineMessages,
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import Button from '@react/react-spectrum/Button';
import StatusLight from '@react/react-spectrum/StatusLight';
import { ReportInfoData } from '../../../services/orgMaster/ReportInfo';
import Utils from '../../../services/utils/Utils';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import BanyanCompartmentAPI from '../../../providers/BanyanCompartmentAPI';
import Download from '../../../services/utils/Download';
import '../common.css';
import ScrollableContent from '../../../Compartments/EditCompartment/Widgets/ScrollableContent';
import ReportStatus from '../../../services/orgMaster/ReportStatus';

interface ReportInfoTableProps extends WrappedComponentProps {
  reportInfoData: ReportInfoData[];
  loadNextPage: () => Promise<void>;
  nextPageAvailable: boolean;
}

const messages = defineMessages({
  ReportInfoTableDownloadLabel: {
    id: 'InsightsForGA.ReportInfoTable.ReportInfoTableDownloadLabel',
    defaultMessage: 'Download',
  },
  ReportInfoTablePendingLabel: {
    id: 'InsightsForGA.ReportInfoTable.ReportInfoTablePendingLabel',
    defaultMessage: 'Pending',
  },
});

/**
 * Generate the user friendly fileName for the report
 * for e.g. for reportTimeStamp="2021-11-07T11:28:50.52Z"
 * => '11/7/2021 11:28 PM"
 * @param reportTimeStamp timestamp when report was generated
 * @returns user friendly display name for report
 */
function getDisplayNameForReport(reportTimeStamp: string): any {
  const date = new Date(reportTimeStamp);
  return (
    <React.Fragment>
      <FormattedDate value={date} />
      &nbsp;&nbsp;
      <FormattedTime value={date} />
    </React.Fragment>
  );
}

/**
 * convert the size in Bytes to Bytes, KB, or MB depending on the file size in Bytes
 */
function getSize(sizeInBytes: number): any {
  if (sizeInBytes === 0) {
    return `-`;
  }
  const k = 1024;
  const sizeType = ['Bytes', 'KB', 'MB'];
  const power = Math.floor(Math.log(sizeInBytes) / Math.log(k));
  const parseSize = parseFloat((sizeInBytes / k ** power).toFixed(2));
  const sizeWithUnit = `${parseSize}  ${sizeType[power]}`;
  return sizeWithUnit;
}

function getFileName(fileName: string, fileExt: string): string {
  const date: Date = new Date(fileName);
  const formattedDate = Utils.localizeDateAsString(date);
  const formattedTime = Utils.localizeTimeAsString(date);
  return `${formattedDate} ${formattedTime}.${fileExt}`;
}

/**
 * downloads the storage report
 */
async function downloadReport(reportInfo: ReportInfoData): Promise<void> {
  const orgId = OrgPickerController.getActiveOrgId();
  if (orgId != null) {
    const downloadUrl = await BanyanCompartmentAPI.getPresignedFileURL(
      orgId,
      reportInfo.fileKey,
      getFileName(reportInfo.reportTimeStamp, reportInfo.fileExt)
    );
    Download.download(downloadUrl, '');
  }
}

/**
 * This function helps display the action status in generalized manner
 * that is either Download (when status is completed) , pending or errorMsg
 */
function getReportStatus(reportInfo: ReportInfoData, props: ReportInfoTableProps): any {
  const { formatMessage } = props.intl;
  if (_.toLower(reportInfo.status) === ReportStatus.COMPLETED) {
    return (
      <Button onClick={() => downloadReport(reportInfo)}>{formatMessage(messages.ReportInfoTableDownloadLabel)}</Button>
    );
  }

  let variant: 'negative' | 'neutral' = 'neutral';
  let message = reportInfo.status.toString();
  if (_.toLower(reportInfo.status) === ReportStatus.ERROR) {
    variant = 'negative';
    message = Utils.localizeErrorData(reportInfo.errorData);
  } else if (_.toLower(reportInfo.status) === ReportStatus.PENDING) {
    message = formatMessage(messages.ReportInfoTablePendingLabel);
  }

  return (
    <StatusLight variant={variant}>
      <span className="ReportsInfoTable__CapitalizeWord">{message}</span>
    </StatusLight>
  );
}

const FileExtension = {
  CSV: {
    ext: 'csv',
    displayLabel: 'CSV',
  },
  JSON: {
    ext: 'zip',
    displayLabel: 'JSON',
  },
  XLSX: {
    ext: 'xlsx',
    displayLabel: 'XLSX',
  },
};

function getReportFormat(format: string): string {
  if (format.endsWith(FileExtension.XLSX.ext)) {
    return FileExtension.XLSX.displayLabel;
  }
  if (format.endsWith(FileExtension.JSON.ext)) {
    return FileExtension.JSON.displayLabel;
  }
  if (format.endsWith(FileExtension.CSV.ext)) {
    return FileExtension.CSV.displayLabel;
  }
  return '';
}

function ReportInfoTable(props: ReportInfoTableProps) {
  return (
    <div>
      <ScrollableContent
        uniqueId="InsightsForGA__ReportInfo"
        onScroll={props.loadNextPage}
        enableInfiniteScroll
        hasMorePages={props.nextPageAvailable}
        height="35rem"
        className="ReportInfo__scrollableContainer"
        dataTestId="ExportReports_reportInfoList"
      >
        <Table className="ReportsInfoTable__Table">
          <THead>
            <TH className="ReportsInfoTable__Name">
              <FormattedMessage id="ReportInfoTable.TableHeader.ReportName" defaultMessage="Report" />
            </TH>
            <TH className="ReportsInfoTable__SmallInfo">
              <FormattedMessage id="ReportInfoTable.TableHeader.ReportFormat" defaultMessage="Format" />
            </TH>
            <TH className="ReportsInfoTable__SmallInfo">
              <FormattedMessage id="ReportInfoTable.TableHeader.ReportSize" defaultMessage="Size" />
            </TH>
            <TH className="ReportsInfoTable__SmallInfo">
              <FormattedMessage id="ReportInfoTable.TableHeader.ReportCreatedBy" defaultMessage="Created By" />
            </TH>
            <TH className="ReportsInfoTable__Action">
              <FormattedMessage id="ReportInfoTable.TableHeader.ReportAction" defaultMessage="Action" />
            </TH>
          </THead>
          <TBody>
            {props.reportInfoData.map((repoInfo: ReportInfoData): React.ReactNode => {
              return (
                <TR className="ReportsInfoTable__Row" key={repoInfo.reportTimeStamp}>
                  <TD className="ReportsInfoTable__Name">{getDisplayNameForReport(repoInfo.reportTimeStamp)}</TD>
                  <TD className="ReportsInfoTable__SmallInfo">{getReportFormat(repoInfo.fileExt)}</TD>
                  <TD className="ReportsInfoTable__SmallInfo">{getSize(repoInfo.fileSize)}</TD>
                  <TD className="ReportsInfoTable__SmallInfo">{repoInfo.createdBy}</TD>
                  <TD className="ReportsInfoTable__Action">{getReportStatus(repoInfo, props)}</TD>
                </TR>
              );
            })}
          </TBody>
        </Table>
      </ScrollableContent>
      <div className="ReportInfo__totalCountLabel">
        <FormattedMessage id="ReportInfoTable.TotalRecords" defaultMessage="Total reports:" />
        &nbsp;{props.reportInfoData.length}
      </div>
    </div>
  );
}

export default injectIntl(ReportInfoTable);
