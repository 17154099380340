import * as log from 'loglevel';
import config from '../configurations/config';

/**
 * reference:
 * API documentation https://p13n.corp.adobe.com/platform/help/get-fg-api-v3-feature/
 * Floodgate help https://p13n.corp.adobe.com/fg/help/
 *
 * Stage feature flags: https://stage.sophia.corp.adobe.com/sophia/#features/feature-flags/634
 * Prod feature flags: https://sophia.corp.adobe.com/sophia/#features/feature-flags/205
 *
 */

export interface FloodGateRelease {
  features: string[]; // Feature flags (key) user is eligible for.
  release_name: string; // Release or Feature Group name user is eligible for
  bit_index: number; // IMS bit index associated with the release. It will be negative if the release is in a full rollout state.
  release_analytics_params: any[]; // An Array containing the analytics data (metadata viz. feature id, release id, feature name etc.) of all the features present in the release.app_idId of the applicationrelease_idRelease idbit_indexIMS bit index associated with the release. It will be negative if the release is in a full rollout state.variant_id0 if the user belongs to Control group. Non-Zero, otherwise.feature_idFeature idf_keyFeature Key
  // This is commented out because we're not currently using 'meta'.
  // meta?: String; // Optional meta data associated to the feature, this will be Base64 encoded. Client will need to decode it before using.
}

export interface FloodGateResponse {
  releases: FloodGateRelease[];
  api_version: string;
}

class FloodgateProvider {
  static async getFeatureFlags(): Promise<string[]> {
    const headers: Headers = new Headers();
    headers.append('x-api-key', config.floodgate.apiKey);
    headers.append('Content-Type', 'application/json');
    const response = await fetch(`${config.floodgate.url}/fg/api/v3/feature?clientId=${config.floodgate.apiKey}`, {
      method: 'GET',
      headers,
    });
    const floodGateResponse: FloodGateResponse = await response.json();
    if (!response.ok) {
      log.error('error in fetching feature flags', floodGateResponse);
      return [];
    }
    if (floodGateResponse.releases.length === 0) {
      return [];
    }
    // NOTE: If you plan on using releases to manage feature flags, change the following code 'releases[0]'
    // Learn more about releases and feature flags at:
    // https://p13n.corp.adobe.com/floodgate/features-feature-groups-releases-in-floodgate/
    // https://p13n.corp.adobe.com/floodgate/setting-a-feature-to-gradually-roll-out/
    // https://p13n.corp.adobe.com/floodgate/release-workflow-end-to-end-flow/
    return floodGateResponse.releases[0].features;
  }

  static async getFeaturesForOrg(orgId: string): Promise<FloodGateResponse> {
    const headers: Headers = new Headers();
    headers.append('x-api-key', config.floodgate.apiKey);
    headers.append('Content-Type', 'application/json');

    const queryParams: URLSearchParams = new URLSearchParams();
    queryParams.append('clientId', config.floodgate.apiKey);
    queryParams.append('ignore_rf', String(true));
    queryParams.append('comOrgId', orgId);

    const response: Response = await fetch(`${config.floodgate.url}/fg/api/v3/feature?${queryParams.toString()}`, {
      method: 'GET',
      headers,
    });

    const floodGateResponse: FloodGateResponse = await response.json();
    if (!response.ok) {
      log.error('error in fetching feature flags', floodGateResponse);
    }
    return floodGateResponse;
  }
}

export default FloodgateProvider;
