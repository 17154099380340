import * as _ from 'lodash';
import * as log from 'loglevel';
import HierarchyManager from '../organization/HierarchyManager';
import { UOrgMaster } from '../orgMaster/UOrgMaster';
import { UAdmin } from '../orgMaster/UAdmin';
import {
  ADMIN_TYPES,
  CMD_DESCRIPTIONS,
  CmdDescriptionCodesData,
  cmdDescrpnCodeKeys,
  cmdDescrpnCodeKeysForAdmins,
  POLICIES,
  UNITS,
} from './MessageImages';
import Utils from '../utils/Utils';

class CmdDescriptionUtils {
  // Compute the pathname of an org using the global org model
  static getPathname(id: string): string {
    const thisOrg = HierarchyManager.getOrg(id) as UOrgMaster;
    let pathname = '';
    if (thisOrg) {
      const parent = thisOrg.getParentOrgMaster();
      if (parent) {
        pathname = `${parent.getPathname()}/${thisOrg.organization.name}`;
      } else {
        pathname = thisOrg.organization.name; // Couldn't find parent, use simple name.
      }
      return pathname;
    }
    // we will mostly not reach here as we are calculating pathnames for orgs in the global org model and
    // the model has all the data received from banyansvc
    return id;
  }

  /**
   * Takes in a command description, which was previously computed and stored when the command was
   * created and uses it to compute the localized description for displaying to the user.
   * Returns the string which is a computed localized description of the command.
   * ex: for input - {cmddescrnCode, cmdDescrpnParams}
   *    output will be - computed and localized description
   * @param cmdDescription
   * @private
   */
  static computeAndLocalizeCommandDescriptionsFromStoredImages(cmdDescription: CmdDescriptionCodesData): string {
    const { formatMessage } = Utils.intl;
    // for a given command, for every description , compute the localized message string
    let locDescrpn: string = '';
    const code = cmdDescription.cmdDescriptionCode;
    // check if the command description code is valid
    if (_.includes(cmdDescrpnCodeKeys, code)) {
      const mappedDescrpnParams = cmdDescription.cmdDescriptionParams
        ? Utils.formatErrorParams(cmdDescription.cmdDescriptionParams)
        : {};
      // for add and updating product quantities, the resource list parameter has to be localized separately
      // params[2] for add product and update product quantity descriptions conatains to the resource list in english
      // - Refer CMD_DESCRIPTIONS in MessageImages.ts
      if (code === 'ADD_PRODUCT' || code === 'UPDATE_PRODUCT_QUANTITY') {
        const resourceList = mappedDescrpnParams[2];
        mappedDescrpnParams[2] = CmdDescriptionUtils.computeAndLocalizeResList(resourceList);
      }
      // for any type of action with admins, the admin type parameter has to be localized separately
      // params[2] for all admin descriptions contains the admin type in english - Refer CMD_DESCRIPTIONS
      // in MessageImages.ts
      if (_.includes(cmdDescrpnCodeKeysForAdmins, code)) {
        if (code === 'ADD_ADMIN_ROLES' || code === 'UPDATE_ADMIN_ROLES') {
          // For UAdmin specific codes, an entire list of admin types has to be localized
          const adminTypesStr = mappedDescrpnParams[2] as string;
          const adminTypes = adminTypesStr.split(UAdmin.ROLE_SEPARATOR);
          const localizedAdminTypes = _.map(adminTypes, (adminType: string): string =>
            CmdDescriptionUtils.localizeAdminType(adminType)
          );
          mappedDescrpnParams[2] = localizedAdminTypes.join(UAdmin.ROLE_SEPARATOR);
          mappedDescrpnParams.rolesCount = adminTypes.length; // assign number of admins to rolesCount field to get proper pluralization
        } else {
          const adminType = mappedDescrpnParams[2];
          mappedDescrpnParams[2] = CmdDescriptionUtils.localizeAdminType(adminType);
        }
      }

      // for updating policies, the policy image has to be localized separately
      // params[1] contains the policy changes info in english - Refer CMD_DESCRIPTIONS in MessageImages.ts
      if (code === 'UPDATE_POLICY') {
        const policyImage = mappedDescrpnParams[1];
        mappedDescrpnParams[1] = CmdDescriptionUtils.computeAndLocalizePolicyImage(policyImage);
      }
      // localize the message
      locDescrpn = formatMessage(CMD_DESCRIPTIONS[code], mappedDescrpnParams);
    } else {
      log.error('Unknown command description code', code);
    }
    return locDescrpn;
  }

  /**
   * computes the localized policy image to be shown in the command descriptions for update policy command
   * Takes in the command description parameter generated and stored during the creation of the command
   * NOTE: in variable names used here, loc-localized, eng-english
   *
   * ex: for 'createChildren:allowed, claimDomains:allowed,locked' output will be
   * "(loc)Create child organizations = (loc)allowed' '(loc)Claim domains = (loc)allowed,(loc)lock"
   * @param policyString
   */
  static computeAndLocalizePolicyImage = (policyString: string): string => {
    // every policy changed is combined and stored separated by commas. Split the string to get the policies separated
    const policyValues = _.split(policyString, ',');
    const { formatMessage } = Utils.intl;
    const localizedPolicyImage: string[] = ['\n'];
    // for every policy , compute the localized message
    _.forEach(policyValues, (polVal: string) => {
      // the parameters are stored as policyName:value1:value2 -> ex: 'createChildren:allowed:lock'
      // value2 is optional as the user can make just one value change for a policy
      const splitPolValue = _.split(polVal, ':');
      const { length } = splitPolValue;
      let locMessage: string = '';
      if (length >= 2) {
        // ex: 'createChildren:allowed:lock' index - 0 is the name of the policy, 1 and 2 are the edited values
        // edited values can be allowed/not allowed, lock/unlock
        const engPolName = splitPolValue[0];
        const engValue1 = splitPolValue[1];
        // get the full policy localized description from the name. If the name key cannot be found then keep
        // the english name itself by default
        const locPolDescrpn = POLICIES[Utils.convertPolicyNameToKeyForLocalization(engPolName)]
          ? formatMessage(POLICIES[Utils.convertPolicyNameToKeyForLocalization(engPolName)])
          : engPolName;
        // get the first edited value and localize it. If key not found, then keep the english value
        // ex: 'allowed', 'lock' - the keys are stored as ALLOWED, LOCK (uppercase)- Refer  POLICIES in MessageImages.ts
        const locValue1 = POLICIES[engValue1.toUpperCase()]
          ? formatMessage(POLICIES[engValue1.toUpperCase()])
          : engValue1;
        // start building the localized description as - 'policy descrpn = editedvalue1,editedvalue2'
        locMessage = `${locPolDescrpn} = ${locValue1}`;
        let locValue2;
        // sometimes only the allowed/notallowed is edited. Or only the lock/unlock is edited.
        // check if there is another value and proceed to localize it
        if (length === 3) {
          const engValue2 = splitPolValue[2];
          locValue2 = POLICIES[engValue2.toUpperCase()]
            ? formatMessage(POLICIES[engValue2.toUpperCase()])
            : splitPolValue[2];
          locMessage += `, ${locValue2}`;
        }
      }
      localizedPolicyImage.push(`${locMessage}`);
    });
    return _.join(localizedPolicyImage, '\n');
  };

  /**
   * localizes the admin type string
   * ex - for 'ORG_ADMIN' output will be '(loc)ORG_ADMIN'
   * @param adminType
   */
  static localizeAdminType = (adminType: string): string => {
    const { formatMessage } = Utils.intl;
    // return the input english string if it cannot be localized
    return ADMIN_TYPES[adminType] ? formatMessage(ADMIN_TYPES[adminType]) : adminType;
  };

  /**
   * compute the localized string for the resource list.
   * ex- for parameter string - 'UNLIMITED:Users,5:transactions' - localize the numbers and the units and separate the
   * resources by commas. output will be - 'UNLIMITED (loc)Users, (loc)5 (loc)transactions'
   *
   * NOTE: 'UNLIMITED' is in english for now. Can be localized in the future if needed.
   * NOTE: in variable names used here, loc-localized, eng-english
   * @param paramList
   * @private
   */
  static computeAndLocalizeResList(paramList: string): string {
    const localizedValues: string[] = [];
    const { formatNumber } = Utils.intl;
    const { formatMessage } = Utils.intl;
    // split the parameters ex: UNLIMITED:Users,10:transactions
    const resourceValues = _.split(paramList, ',');
    _.forEach(resourceValues, (resVal: string) => {
      // split the resource value into quantity and unit
      const splitResValue = _.split(resVal, ':');
      if (splitResValue.length >= 2) {
        let qtty = splitResValue[0];
        const engUnit = splitResValue[1];
        // get localized unit string
        const locUnit = UNITS[Utils.convertKeyForLocalization(engUnit)]
          ? formatMessage(UNITS[Utils.convertKeyForLocalization(engUnit)])
          : engUnit;
        // if the quantity is a number, then localize it
        if (Utils.canParseInt(qtty)) {
          qtty = formatNumber(parseInt(qtty, 10));
        }
        localizedValues.push(`${qtty} ${locUnit}`);
      }
    });
    return _.join(localizedValues, ', ');
  }
}
export default CmdDescriptionUtils;
