/**
 * As of React 16, errors that were not caught by any error boundary will result in unmounting of the whole React component tree.
 * Use <ErrorBoundary> to show a simple message and log to console.
 * Usage:
 * <ErrorBoundary>
 *    <ComponentThatMayThrowAnError />
 * </ErrorBoundary>
 * Does NOT catch errors for: event handlers or async code
 *
 * For reference see https://reactjs.org/docs/error-boundaries.html
 *
 * IMPORTANT: dev mode vs production mode:
 * The create-react-app package has a tool called the react-overlay-error. This shows error messages from the console
 * as an overlay over your app so you can easily check the stack trace and debug.
 * Click 'X' or Esc to make it go away.
 * This won't show up in production mode, it's just a development tool duplicating the normal browser console.
 */
import * as log from 'loglevel';
import React, { Component } from 'react';
import FullErrorPage from '../FullErrorPage/FullErrorPage';

interface State {
  error: Error | undefined;
}

class ErrorBoundary extends Component<any, State> {
  public constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(error: Error): object {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch = (error: Error): void => {
    log.error(error.message);
  };

  public render(): React.ReactNode {
    if (this.state.error) {
      // fallback UI
      return <FullErrorPage errorMessage={this.state.error.message} />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
