/**
 * Maximum length is enforced in Renga, see this https://renga.corp.adobe.com/files/docs/javadocs/renga-client-2.3.81/ click on the Group link from left menu.
 * Profile name length is also enforced in Admin Console, see this https://git.corp.adobe.com/admin-tribe/onesie/blob/master/src/app/widgets/products/product-profiles/update-details/product-profile-update-details.component.html#L48
 * User Group name length is also enforced in Admin Console, see this https://git.corp.adobe.com/admin-tribe/onesie/blob/28f14211af9663db8efae653555f38bc6e53b194/src/app/widgets/user-groups/save/user-group-save-modal.component.html#L30
 */
export const MAX_NAME_ALLOWED_LENGTH = 255;

/*
  The number 35 was found empirically to be the maximum character display length for profile/user group/admin name in table.
  It is equal to the number of characters that can fit in the width defined by EditCompartmentTabs__NameTooltip.
*/
export const MAX_DISPLAY_CHARACTER_LENGTH = 35;
