/**
 * https://wiki.corp.adobe.com/display/DMaG11n/Locales
 * https://tools.ietf.org/html/bcp47
 *  - locale tags should be handled case insensitively
 *  - producers should return locale tags with hyphen
 *  - consumers should take as input locale tags with either hyphen or underscore
 */

import * as _ from 'lodash';
import * as log from 'loglevel';
import Utils from '../utils/Utils';

/**
 * Controls the localization
 */

// import the localization json files
import cs_CZ_Locale from '../../locales/cs_cz.json';
import da_DK_Locale from '../../locales/da_dk.json';
import de_DE_Locale from '../../locales/de_de.json';
import es_ES_Locale from '../../locales/es_es.json';
import fi_FI_Locale from '../../locales/fi_fi.json';
import fr_FR_Locale from '../../locales/fr_fr.json';
import it_IT_Locale from '../../locales/it_it.json';
import nb_NO_Locale from '../../locales/nb_no.json';
import nl_NL_Locale from '../../locales/nl_nl.json';
import pl_PL_Locale from '../../locales/pl_pl.json';
import pt_BR_Locale from '../../locales/pt_br.json';
import ru_RU_Locale from '../../locales/ru_ru.json';
import sv_SE_Locale from '../../locales/sv_se.json';
import tr_TR_Locale from '../../locales/tr_tr.json';
// import uk_UA_Locale from '../../locales/uk_ua.json';
import zh_CN_Locale from '../../locales/zh_cn.json';
import zh_TW_Locale from '../../locales/zh_tw.json';
import ja_JP_Locale from '../../locales/ja_jp.json';
import ko_KR_Locale from '../../locales/ko_kr.json';
import kk_KK_Locale from '../../locales/kk_kk.json';
import UserProfileService from '../authentication/UserProfileService';

export const enableLocaleSelector = true; // If turned off, this removes the LocaleSelector in the footer

/**
 * Describes the format of the json in each localization file (string pairs)
 */
interface LocaleMap {
  [id: string]: string;
}

/**
 * Maps the LocaleMap json data to a LocaleSymbol
 */
interface LocaleDataSet {
  cs_CZ: LocaleMap;
  da_DK: LocaleMap;
  de_DE: LocaleMap;
  en_US: LocaleMap;
  es_ES: LocaleMap;
  fi_FI: LocaleMap;
  fr_FR: LocaleMap;
  it_IT: LocaleMap;
  nb_NO: LocaleMap;
  nl_NL: LocaleMap;
  pl_PL: LocaleMap;
  pt_BR: LocaleMap;
  ru_RU: LocaleMap;
  sv_SE: LocaleMap;
  tr_TR: LocaleMap;
  // uk_UA: LocaleMap;
  zh_CN: LocaleMap;
  zh_TW: LocaleMap;
  ja_JP: LocaleMap;
  ko_KR: LocaleMap;
  kk_KK: LocaleMap;
}
type LocaleSymbol = keyof LocaleDataSet; // Symbol that represents only supported locales (of the form: en_US).  LocaleSymbols are primarily for mapping locale data
// The LocaleSymbol is also in the format that AdobeIntl expects for looking up the data

/**
 * Collection of all the localization json data with LocaleSymbols as keys
 */
export const LocaleData: LocaleDataSet = {
  cs_CZ: cs_CZ_Locale,
  da_DK: da_DK_Locale,
  de_DE: de_DE_Locale,
  en_US: {}, // gets data from defaultMessages
  es_ES: es_ES_Locale,
  fi_FI: fi_FI_Locale,
  fr_FR: fr_FR_Locale,
  it_IT: it_IT_Locale,
  nb_NO: nb_NO_Locale,
  nl_NL: nl_NL_Locale,
  pl_PL: pl_PL_Locale,
  pt_BR: pt_BR_Locale,
  ru_RU: ru_RU_Locale,
  sv_SE: sv_SE_Locale,
  tr_TR: tr_TR_Locale,
  // uk_UA: uk_UA_Locale,
  zh_CN: zh_CN_Locale,
  zh_TW: zh_TW_Locale,
  ja_JP: ja_JP_Locale,
  ko_KR: ko_KR_Locale,
  kk_KK: kk_KK_Locale,
};

type LanguageAttribute = Pick<{ [key in LocaleSymbol]: string }, 'en_US' | 'ja_JP' | 'ko_KR' | 'zh_CN' | 'zh_TW'>; // Type representing mapping of locale symbols to language attribute string (only the ones that need a separate attribute string)
type LanguageAttributeKeys = keyof LanguageAttribute; // Type representing only the key strings of LanguageAttribute
const LanguageAttributeData: LanguageAttribute = {
  en_US: 'en',
  ja_JP: 'ja',
  ko_KR: 'ko',
  zh_CN: 'zh-Hans',
  zh_TW: 'zh-Hant',
}; // Mapping of locale symbols to language attribute strings

/**
 * Controls the locale for the app and provides some utilities
 */
export class LocaleSettings {
  private static readonly DEFAULT_LOCALE: LocaleSymbol = 'en_US'; // Default locale is English (US)
  private static readonly LOCALE_KEY = 'locale'; // key name of the get argument parameter for locale
  private static readonly ADOBE_LOCALE_COOKIE = 'international'; // name of the adobe.com locale cookie
  private static selectedLocale: LocaleSymbol = LocaleSettings.DEFAULT_LOCALE; // Currently selected LocaleSymbol
  private static localeChanged: () => void; // Callback for when the locale is changed.  This is usually used to re-render the app

  /**
   * Converts a LocaleSymbol to a LanguageTag.  Language tag is the BCP47 standard for representing
   * languages.  The LanguageTag usually has a '-' (ex: en-US).  AdobeIntl expects locale symbol mapping with '_' hence
   * the reason for converting.
   * This method is used for producing methods that return a LanguageTag.  Best practices state that
   * the tag should be returned with a '-' and the casing doesn't matter.
   * @param localeSymbol Given localeSymbol (de_DE), returns its languageTag (de-DE)
   */
  private static convertToLanguageTag(localeSymbol: LocaleSymbol): string {
    return localeSymbol.replace(/_/g, '-');
  }

  /**
   * Converts a LanguageTag to a LocaleSymbol or undefined if it can't be converted.  This method is used for consuming methods that
   * take in a LanguageTag.  Best practices state that we should handle case insensitively and that the
   * tag can have either '-' or '_'.
   * @param languageTag Given strings de-DE or de_DE, returns its matching LocaleSymbol de_DE
   */
  private static convertToLocaleSymbolOrUndefined(languageTag: string): LocaleSymbol | undefined {
    // matches appropriate language tag
    if (languageTag.match(/[a-zA-Z]{2}[-_][a-zA-Z]{2}/)) {
      let localeStr: string = languageTag;
      // matches the language tag with '-'
      localeStr = localeStr.replace(/-/g, '_');
      const splitLocale: string[] = localeStr.split('_');
      splitLocale[0] = splitLocale[0].toLowerCase();
      splitLocale[1] = splitLocale[1].toUpperCase();
      const localeSymbol: LocaleSymbol = splitLocale.join('_') as LocaleSymbol;
      if (LocaleData[localeSymbol]) {
        return localeSymbol;
      }
    }
    // Try to match 2 character tag
    const match = Object.keys(LocaleData).find((locale) => locale.startsWith(languageTag));
    if (match && match.length > 0) {
      log.debug(`Locale ${languageTag} adjusted to nearest match ${match}`);
      return match as LocaleSymbol;
    }
    log.debug(`Locale ${languageTag} not found`);
    return undefined; // No match at all, return undefined
  }

  /**
   * Converts a LanguageTag to a LocaleSymbol.  This method is used for consuming methods that
   * take in a LanguageTag.  Best practices state that we should handle case insensitively and that the
   * tag can have either '-' or '_'.
   * @param languageTag Given strings de-DE or de_DE, returns its matching LocaleSymbol de_DE
   * @throws Error if given languageTag is not valid (not 5 characters or missing either - or _)
   */
  private static convertToLocaleSymbol(languageTag: string): LocaleSymbol {
    const localeSymbol: LocaleSymbol | undefined = LocaleSettings.convertToLocaleSymbolOrUndefined(languageTag);
    // if no match found for languageTag, provide default Locale
    if (!localeSymbol) {
      log.debug(`Locale ${languageTag} not found, using ${LocaleSettings.DEFAULT_LOCALE}`);
      return LocaleSettings.DEFAULT_LOCALE; // No match at all, use en_US
    }
    return localeSymbol;
  }

  /**
   * Converts a LocaleSymbol to a language tag compatible with Go Urls.
   * Go Urls use a 2 character language tag (ex: fr_FR would be fr and ja_jp would be ja)
   * The exception to this rule is zh_CN and zh_TW which remain the same (as 4 character tags but lower cased)
   *
   * useLowercaseLanguageTags sets whether to return 4 character language tags in the form xx_xx instead of xx_XX
   *
   * See: https://git.corp.adobe.com/admin-tribe/binky-ui/blob/master/src/widgets/common/go-url/go-url.filter.js
   */
  private static convertLocaleSymbolToGoUrlLocale(
    localeSymbol: LocaleSymbol,
    useLowercaseLanguageTags: boolean
  ): string {
    if (localeSymbol === 'zh_CN' || localeSymbol === 'zh_TW') {
      return useLowercaseLanguageTags ? _.toLower(localeSymbol) : localeSymbol;
    }
    return localeSymbol.split('_')[0]; // LocaleSymbols always have underscore with 4 characters
  }

  /**
   * Get the locale from the get url parameter
   */
  private static urlParameterLocale(): LocaleSymbol | undefined {
    const searchString: string = window.location.search;
    const searchParams = new URLSearchParams(searchString);
    const localeParamValue: string | null = searchParams.get(LocaleSettings.LOCALE_KEY);
    if (!localeParamValue) {
      return undefined;
    }
    return LocaleSettings.convertToLocaleSymbolOrUndefined(localeParamValue);
  }

  /**
   * Get the locale from locale storage
   */
  private static localStorageLocale(): LocaleSymbol | undefined {
    const locale: string | null = localStorage.getItem(LocaleSettings.LOCALE_KEY);
    if (locale) {
      return LocaleSettings.convertToLocaleSymbolOrUndefined(locale);
    }
    return undefined;
  }

  /**
   * Get the locale from IMS preferred languages
   */
  private static imsLocale(): LocaleSymbol | undefined {
    const locales: string[] | null = UserProfileService.getUserLocales();
    if (locales) {
      for (let i: number = 0; i < locales.length; i++) {
        const locale: string = locales[i];
        const localeSymbol: LocaleSymbol | undefined = LocaleSettings.convertToLocaleSymbolOrUndefined(locale);
        if (localeSymbol) {
          return localeSymbol;
        }
      }
    }
    return undefined;
  }

  /**
   * Get the locale from the adobe.com cookie
   */
  private static adobeCookieLocale(): LocaleSymbol | undefined {
    const locale: string | undefined = Utils.getCookieValue(LocaleSettings.ADOBE_LOCALE_COOKIE);
    if (locale) {
      return LocaleSettings.convertToLocaleSymbolOrUndefined(locale);
    }
    return undefined;
  }

  /**
   * Get the locale from the browser settings
   */
  private static browserLocale(): LocaleSymbol | undefined {
    if (navigator.languages && Array.isArray(navigator.languages)) {
      for (let i: number = 0; i < navigator.languages.length; i++) {
        const locale: string = navigator.languages[i];
        const localeSymbol: LocaleSymbol | undefined = LocaleSettings.convertToLocaleSymbolOrUndefined(locale);
        if (localeSymbol) {
          return localeSymbol;
        }
      }
    } else if (navigator.language) {
      return LocaleSettings.convertToLocaleSymbolOrUndefined(navigator.language);
    }
    return undefined;
  }

  /**
   * Retrieves the preferred Language from the browser
   */
  /* private static browserLocale(): string {
    // TODO: if navigator.languages is an array should really try each entry for an exact match and then partial match.
    // Right now, we just use the first entry.
    return (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';
  } */

  /**
   * Finds the initial locale for the user upon initialization the UI.
   * Steps to determine the locale follow these steps
   *  1. Locale Storage (key name: locale)
   *  2. URl GET parameter (variable name: locale)
   *  3. IMS preferred languages
   *  4. Adobe.com cookie (cookie name: international)
   *  5. Browser settings
   *  6. Default to en-US
   *
   * Returns both the initial locale symbol and whether or not the locale defaulted to en-US
   */
  private static initialLocale(): { localeSymbol: LocaleSymbol; defaultUsed: boolean } {
    let localeSymbol: LocaleSymbol | undefined;
    localeSymbol = LocaleSettings.localStorageLocale();
    if (localeSymbol) {
      return { localeSymbol, defaultUsed: false };
    }
    localeSymbol = LocaleSettings.urlParameterLocale();
    if (localeSymbol) {
      return { localeSymbol, defaultUsed: false };
    }
    localeSymbol = LocaleSettings.imsLocale();
    if (localeSymbol) {
      return { localeSymbol, defaultUsed: false };
    }
    localeSymbol = LocaleSettings.adobeCookieLocale();
    if (localeSymbol) {
      return { localeSymbol, defaultUsed: false };
    }
    localeSymbol = LocaleSettings.browserLocale();
    if (localeSymbol) {
      return { localeSymbol, defaultUsed: false };
    }
    return { localeSymbol: LocaleSettings.DEFAULT_LOCALE, defaultUsed: true };
  }

  /**
   * Initializes the LocaleSetting.  This must be called before localization can be used (in App.tsx).
   * The specified localeChanged callback is executed when the locale changes.  Usually this method is used
   * to re-render the app.
   * Initially selected locale is set to be whatever the preferred language is for the user and if none is provided, it is en-US.
   *
   * Returns true if the default fallback en-US was set
   */
  static init(localeChanged?: () => void): boolean {
    if (localeChanged) {
      LocaleSettings.localeChanged = localeChanged;
    }
    const { localeSymbol, defaultUsed } = LocaleSettings.initialLocale();
    LocaleSettings.selectedLocale = localeSymbol;
    localStorage.setItem(LocaleSettings.LOCALE_KEY, LocaleSettings.selectedLocale);
    Utils.setIntl(LocaleSettings.getSelectedLanguageTagForProvider(), LocaleData[LocaleSettings.selectedLocale]);
    this.updateLangAttribute(LocaleSettings.selectedLocale);
    return defaultUsed;
  }

  /**
   * Retrieves the LanguageTag of the currently selected locale.
   */
  static getSelectedLanguageTag(): string {
    return LocaleSettings.convertToLanguageTag(LocaleSettings.selectedLocale);
  }

  /**
   * Retrieves the LanguageTag for the currently selected locale and
   * filters it for the default language.
   * 'en-US' is our default language but 'en' is the default language
   * for react-intl.  This means 'en-US' gets converted to 'en' for react-intl provider.
   * Note: react-intl only uses 'en' as the default
   * This is mostly for providing the language for the react-intl provider.
   */
  static getSelectedLanguageTagForProvider(): string {
    let languageTag = LocaleSettings.getSelectedLanguageTag();
    if (languageTag === 'en-US') {
      languageTag = 'en';
    }
    return languageTag;
  }

  /**
   * Retrieve the Adobe Go Url locale based on the selected locale
   *
   * useLowercaseLanguageTags sets whether you want 4 character language tags in the form xx_xx instead of xx_XX
   */
  static getSelectedLanguageTagForAdobeGoUrls(useLowercaseLanguageTags: boolean): string {
    return LocaleSettings.convertLocaleSymbolToGoUrlLocale(LocaleSettings.selectedLocale, useLowercaseLanguageTags);
  }

  /**
   * Retrieves the LocaleMap of the currently selected locale.
   */
  static getSelectedLocale(): LocaleMap {
    return LocaleData[LocaleSettings.selectedLocale];
  }

  static getLocale(): LocaleSymbol {
    return LocaleSettings.selectedLocale;
  }

  /**
   * Changes the curently selected locale.  Selected by LanguageTag.
   */
  static changeLocale(languageTag: string): void {
    LocaleSettings.selectedLocale = LocaleSettings.convertToLocaleSymbol(languageTag);
    this.updateLangAttribute(LocaleSettings.selectedLocale);
    localStorage.setItem(LocaleSettings.LOCALE_KEY, LocaleSettings.selectedLocale);
    Utils.setIntl(LocaleSettings.getSelectedLanguageTagForProvider(), LocaleData[LocaleSettings.selectedLocale]);
    LocaleSettings.localeChanged();
  }

  /**
   * Given a locale symbol, changes the language attribute in the html tag
   * Changing language attribute is necessary for rendering CCJK locales
   *  - https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/fonts/03_using_adobe_clean_fonts_for_web_app.md
   */
  static updateLangAttribute(localeSymbol: LocaleSymbol): void {
    // Determine whether there are any locale symbols that are not included in LanguageAttribute (find locale symbols that don't need a language attribute)
    const notSupportedLanguageAttr: boolean = !_.find(
      _.keys(LanguageAttributeData),
      (key: string): boolean => key === localeSymbol
    );
    // Set the language attribute based off the locale symbol.  Only the Asian locales need a specific language attribute other locales can use en attribute
    let localeSymbolAttr: LanguageAttributeKeys = 'en_US';
    if (_.isEmpty(notSupportedLanguageAttr)) {
      localeSymbolAttr = localeSymbol as LanguageAttributeKeys; // locale symbol will match a LanguageAttributeKey because otherwise the above condition would fail (if any locale symbol not in LanguageAttribute was found)
    }
    const languageAttribute: string = LanguageAttributeData[localeSymbolAttr];
    document.documentElement.lang = languageAttribute;
  }
}
