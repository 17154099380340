/*
 *  *************************************************************************
 *  ADOBE CONFIDENTIAL
 *  ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 *  NOTICE: All information contained herein is, and remains
 *  the property of Adobe and its suppliers, if any. The intellectual
 *  and technical concepts contained herein are proprietary to Adobe
 *  and its suppliers and are protected by all applicable intellectual
 *  property laws, including trade secret and copyright laws.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Adobe.
 *  *************************************************************************
 */
import BanyanCompartmentAPI from './BanyanCompartmentAPI';

class UserGroupSharingService {
  static async isT2EOrg(orgId: string, signal?: AbortSignal): Promise<boolean> {
    const response = await BanyanCompartmentAPI.checkT2EESM(orgId, true, signal);
    return response.isT2EOrg ?? false;
  }

  static async hasSharedUserGroupsFeature(orgId: string, signal?: AbortSignal): Promise<boolean> {
    return await UserGroupSharingService.isT2EOrg(orgId, signal);
  }
}

export default UserGroupSharingService;
