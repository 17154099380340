import ModalContainer from '@react/react-spectrum/ModalContainer';
import { SelectOption } from '@react/react-spectrum/Select';
import React from 'react';
import { defineMessages, IntlProvider, WrappedComponentProps } from 'react-intl';
import HeaderConsts from '../../components/BanyanShell/HeaderConstants';
import { LocaleSettings } from '../../services/locale/LocaleSettings';
import OrgPickerController from '../../services/organization/OrgPickerController';
import OrgHierarchyHelper from '../helpers/OrgHierarchyHelper';
import { MESSAGES } from '../Messages';
import AdobeAgentPaths from './AdobeAgentPaths';
import DeleteProductWarningDialog from './DeleteProductWarningDialog/DeleteProductWarningDialog';
import OrgActionSummary from './OrgActionSummary/OrgActionSummary';
import SearchOrg from './SearchOrg/SearchOrg';
import StepControl from './StepControl/StepControl';
import withRouter, { RouteComponentProps } from '../../services/utils/withRouter';

interface ConvertChildOrgIntoStandaloneOrgsInternalProps extends RouteComponentProps, WrappedComponentProps {
  setPath: () => void;
}

interface ConvertChildOrgIntoStandaloneOrgsInternalState {
  selectedOrgs: SelectOption[];
  disallowOrgSelection: boolean;
  errorMessage: React.ReactNode;
  warningMessage: React.ReactNode;
  hasDeleteWarning: boolean;
}

const messages = defineMessages({
  ConvertToStandAloneOrg: {
    id: 'OrgMigrationForAA.ConvertChildOrgIntoStandaloneOrgs.ConvertToStandAloneOrg',
    defaultMessage: 'Select child orgs to convert to standalone',
  },
});

class ConvertChildOrgIntoStandaloneOrgsInternal extends React.Component<
  ConvertChildOrgIntoStandaloneOrgsInternalProps,
  ConvertChildOrgIntoStandaloneOrgsInternalState
> {
  constructor(props: ConvertChildOrgIntoStandaloneOrgsInternalProps) {
    super(props);
    this.state = {
      selectedOrgs: [],
      disallowOrgSelection: false,
      errorMessage: undefined,
      warningMessage: undefined,
      hasDeleteWarning: false,
    };
  }

  enableNextOrReviewPendingBtn = (step: number): boolean => {
    // on step 0, if child org is not selected, disable the next button
    if (step === 0 && this.state.selectedOrgs.length <= 0) {
      return false;
    }
    // on last step, if any errorMessage is displayed, disable 'Review Pending Changes' btn
    if (step === 1 && this.state.errorMessage !== undefined) {
      return false;
    }
    return true;
  };

  /**
   * Add the new Orgs to the list if not already added
   */
  addSelectedOrgIds = (newSelectedOrgs: SelectOption[]): void => {
    this.setState(
      (
        prevState: ConvertChildOrgIntoStandaloneOrgsInternalState
      ): Pick<ConvertChildOrgIntoStandaloneOrgsInternalState, never> => {
        return { selectedOrgs: OrgHierarchyHelper.addSelectedValues(prevState.selectedOrgs, newSelectedOrgs) };
      }
    );
  };

  /**
   * removes the orgs from the list of selected child Orgs
   */
  removeSelectedOrgIds = (toBeRemovedOrgs: string[]): void => {
    this.setState(
      (
        prevState: ConvertChildOrgIntoStandaloneOrgsInternalState
      ): Pick<ConvertChildOrgIntoStandaloneOrgsInternalState, never> => {
        return { selectedOrgs: OrgHierarchyHelper.removeSelectedValues(prevState.selectedOrgs, toBeRemovedOrgs) };
      }
    );
  };

  /**
   * Validates if user permissions and org properties are valid (Please see: validateOrgAndUserPermissions for more info)
   * if a problem is detected, errorMessage will be set. That affects enableNextOrReviewPendingBtn (see above).
   */
  validate = async (): Promise<void> => {
    // set the disallowOrgSelection = true during which the user
    // cannot alter the org selected (child or parent org whichever applicable)
    this.setState({ disallowOrgSelection: true });

    const errorElem = await OrgHierarchyHelper.validateOrgAndUserPermissions(this.state.selectedOrgs);

    this.setState({ errorMessage: errorElem, disallowOrgSelection: false });
  };

  submit = (): void => {
    OrgHierarchyHelper.generateAndAddOrgUpdateCommand(
      AdobeAgentPaths.ConvertChildOrgIntoStandaloneOrgs,
      this.state.selectedOrgs
    );
    this.props.navigate(OrgPickerController.getDeepLinkBasedOnActiveOrg(HeaderConsts.JOB_EXECUTION_URL));
  };

  onDialogClose = (): void => {
    if (this.deleteWarningDialogRef) {
      ModalContainer.hide(this.deleteWarningDialogRef);
    }
  };

  deleteWarningDialogRef: number | undefined;
  verifyBeforeSubmit = (): void => {
    if (this.state.hasDeleteWarning) {
      this.deleteWarningDialogRef = ModalContainer.show(
        <DeleteProductWarningDialog onClose={this.onDialogClose} submit={this.submit} />,
        this
      );
    } else {
      this.submit();
    }
  };

  render(): React.ReactNode {
    const { formatMessage } = this.props.intl;
    return (
      <StepControl
        pageTitle={formatMessage(messages.ConvertToStandAloneOrg)}
        enableNextOrReviewPendingBtn={this.enableNextOrReviewPendingBtn}
        backout={this.props.setPath}
        onSubmit={this.verifyBeforeSubmit}
        validate={this.validate}
        disallowOrgSelection={this.state.disallowOrgSelection}
      >
        <SearchOrg
          selectedOrgs={this.state.selectedOrgs}
          addSelectedOrgIds={this.addSelectedOrgIds}
          removeSelectedOrgIds={this.removeSelectedOrgIds}
          multipleSelection
          label={formatMessage(messages.ConvertToStandAloneOrg)}
          disallowOrgSelection={this.state.disallowOrgSelection}
          displaySelectedOrgsAsTagList
          scrollableHeight="30rem"
        />
        <OrgActionSummary
          selectedOrgIds={this.state.selectedOrgs}
          label={formatMessage(MESSAGES.ReviewLabel)}
          errorMessage={this.state.errorMessage}
          warningMessage={this.state.warningMessage}
        />
      </StepControl>
    );
  }
}

function ConvertChildOrgIntoStandaloneOrgs(
  props: Omit<ConvertChildOrgIntoStandaloneOrgsInternalProps, 'ref'>
): JSX.Element {
  return (
    <IntlProvider
      locale={LocaleSettings.getSelectedLanguageTagForProvider()}
      messages={LocaleSettings.getSelectedLocale()}
    >
      <ConvertChildOrgIntoStandaloneOrgsInternal {...props} />
    </IntlProvider>
  );
}

export default withRouter(ConvertChildOrgIntoStandaloneOrgs);
