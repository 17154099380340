import AdminPermission from '../../../services/authentication/AdminPermission';
import OrgPickerController from '../../../services/organization/OrgPickerController';
import { UOrgMaster } from '../../../services/orgMaster/UOrgMaster';
import { UProduct } from '../../../services/orgMaster/UProduct';

class ProductPermission {
  /**
   * Determine if user has permission to edit product (edit grant value, add or del product).
   *
   * 1) product is not a purchase
   * 2) org is not the selected active org (current root for the users hierarchy)
   * 3) user must have global admin permissions on the selected active org
   * 4) modify_product policy is allowed
   *
   * Note that purchased products may not be edited or deleted even if Adobe Agent (banyansvc throws JobFatalException)
   *
   * @param currentOrDestOrg org of product to be modified. Or destination org of product to add.
   */
  static canEditProduct(currentOrDestOrg: UOrgMaster, product: UProduct | undefined = undefined): boolean {
    if (
      !currentOrDestOrg ||
      !currentOrDestOrg.policiesLoaded ||
      (product && (product.isPurchase() || currentOrDestOrg.isIndirectAllocation(product))) ||
      currentOrDestOrg.organization.id === OrgPickerController.getActiveOrgId() ||
      AdminPermission.readOnlyMode()
    ) {
      // org must be defined, policies must be loaded, must not be a purchase, must not be the selected active org, and must not be read-only at the root level
      return false;
    }
    return currentOrDestOrg.compartmentPolicy.policies.manageProducts.value;
  }

  static canEditProductAndOrg(currentOrDestOrg: UOrgMaster, product: UProduct | undefined = undefined): boolean {
    return ProductPermission.canEditProduct(currentOrDestOrg, product) && !currentOrDestOrg.isReadOnlyOrg();
  }

  static canAddProduct(destOrg: UOrgMaster, parentProduct: UProduct): boolean {
    return parentProduct.redistributable && ProductPermission.canEditProductAndOrg(destOrg);
  }
}
export default ProductPermission;
